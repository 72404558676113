import { Box, Stack } from '@mui/material';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { usePrograms } from '../hooks/program/queries';
import CollapsibleProgramsTable from './CollapsibleProgramsTable';

function ListPartnerPrograms() {
  const { clientId } = useClientAuth();
  const programQueries = usePrograms({
    clientId: clientId || '',
    // clientId: '6c26d34b-fe1a-446f-9668-6b7cb5515ef1',
    page: 0,
    pageSize: 10,
    // partnerId : 'partnerId'
  });

  return (
    <Box>
      <Box my={1}>
        <Stack>
          <Box>Search By</Box>
        </Stack>
      </Box>
      <CollapsibleProgramsTable rows={programQueries.data?.records || []} />
    </Box>
  );
}

export default ListPartnerPrograms;
