import { Box, Grid } from '@mui/material';
import { VegaTextField } from '../../../../../components/common';
import { IConsolidatedAccountData } from '../../../../types/Commons';

import { ICommonProps } from '../../../../../types/commonType';

interface IProps extends ICommonProps {
  state: IConsolidatedAccountData | null;
}

const CustomerProfile = ({ handleChange, state }: IProps) => {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state?.customer?.firstName}
            onChange={(e) => handleChange('firstName', e.target.value)}
            key="firstName"
            label="Enter Name"
            type={'text'}
            placeholder="Enter Name"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state?.customer?.emailId}
            onChange={(e) => handleChange('emailId', e.target.value)}
            key="emailId"
            label="Enter Email"
            type={'email'}
            placeholder="Enter Email"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state?.customer?.mobileNumber}
            onChange={(e) => handleChange('mobileNumber', e.target.value)}
            key="mobileNumber"
            label="Enter Mobile No"
            type={'number'}
            placeholder="Enter Mobile No"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <VegaTextField
            value={state?.customer?.currentAddress.line1 || ''}
            onChange={(e) => handleChange('address', e.target.value)}
            key="address"
            label="Enter address"
            type={'text'}
            placeholder="Enter address"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerProfile;
