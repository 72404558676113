import axios from 'axios';
import { ApiScope } from '../../../types/client';
import { BASE_URL, ROLES } from './endpoints';

const ROLES_URL = `${BASE_URL}${ROLES.BASE}`;

export const fetchRolesForClient = (clientId: string) => {
  return axios.get(`${ROLES_URL}/listRolesForClient/${clientId}`);
};
export const fetchUserForClient = (
  clientId: string,
  includeRolesAndResources = true
) => {
  return axios.get(`${ROLES_URL}/listUsersByClient/${clientId}`, {
    params: { includeRolesAndResources },
  });
};
export const fetchUserWithStatusForClient = (data: any) => {
  return axios.post(`${ROLES_URL}/listUsersByClientAndStatus`, data);
};
export const addRole = (data: any) => {
  return axios.post(`${ROLES_URL}/addRole`, data);
};
export const addUser = (data: IAddUserRequest) => {
  return axios.post(`${ROLES_URL}/addUser`, data);
};
export const addRoleForUser = (data: any) => {
  return axios.post(`${ROLES_URL}/addRoleForUser`, data);
};

export const makerCheckerForUser = (data: any) => {
  return axios.post(`${ROLES_URL}/checker_maker`, data);
};
export const updateStatusForUser = (data: any) => {
  return axios.patch(`${ROLES_URL}/checker_maker/status`, data);
};

export type IAddUserRequest = {
  clientId: string;
  platformId: string;
  platformName: UserPlatformEnums | string | null;
  mccUser: boolean;
  name: string;
  mobileNumber: string;
  email: string;
  address?: string;
  officialEmailId?: string;
  idType: string;
  idNumber: string;
  status: string;
  team: UserTeamType;
  designation?: string;
  apiScope?: ApiScope;
  isAdmin: boolean;
};

export enum UserRoleEnums {
  BRANCH_ADMIN = 'BRANCH_ADMIN',
  BRANCH_USER = 'BRANCH_USER',
  CORPORATE_ADMIN = 'CORPORATE_ADMIN',
  HQ_USER = 'HQ_USER',
}
export enum CIMSRoleEnums {
  HQ_ADMIN = 'HQ ROLE',
  BC_AGENT = 'BC AGENT ROLE',
  BC_ADMIN = 'BC ROLE',
}

export enum UserPlatformEnums {
  FOREX_HQ = 'FOREX_HQ',
  FOREX_BRANCH = 'FOREX_BRANCH',
  FOREX_CORPORATE = 'FOREX_CORPORATE',
}

export enum UserTeamType {
  OPERATIONS = 'OPERATIONS',
  COLLECTIONS = 'COLLECTIONS',
  FINANCE = 'FINANCE',
  SETTLEMENT = 'SETTLEMENT',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  DESIGN = 'DESIGN',
  DEVELOPER = 'DEVELOPER',
  CXOS = 'CXOS',
  MARKETING_AND_BRANDING = 'MARKETING_AND_BRANDING',
  SUPPORT = 'SUPPORT',
  BUSINESS_INTELLIGENCE = 'BUSINESS_INTELLIGENCE',
  BUSINESS = 'BUSINESS',
  RISK = 'RISK',
  AGENCY_COLLECTION = 'AGENCY_COLLECTION',
  AGENCY_SUPPORT = 'AGENCY_SUPPORT',
  AGENCY_OPERATIONS = 'AGENCY_OPERATIONS',
}
