import { Grid, Box, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { VegaCard, VegaAccordian } from '../../common';
import {
  KycStepStatus,
  RADIO_OPTIONS,
  VegaKycBaseStep,
  VegaKycStepPartner,
} from './KYCFlowDataTypes';
import { KycStepAccordianContent } from './KycStepAccordianContent';

interface IKycStepCardProps {
  stepNumber?: number;
  step: VegaKycBaseStep;
  showStepCard?: boolean;
  updateStepData: (updatedData: VegaKycBaseStep) => void;
}

export function KycStepCard({
  stepNumber = 1,
  step,
  showStepCard = true,
  updateStepData,
}: IKycStepCardProps) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [stepData, setStepData] = useState<VegaKycBaseStep>(step);

  function updateStepPartnersData(data: {
    selectedPartner: VegaKycStepPartner;
    updatedStatus: KycStepStatus;
  }) {
    const prevPartnersState = stepData.metaData.partners;
    if (!prevPartnersState) return;

    const partnerToModify = prevPartnersState.find(
      (partner) => partner.name === data.selectedPartner.name
    );
    if (!partnerToModify) return;
    const modifiedPartner = {
      ...partnerToModify,
      status: data.updatedStatus,
    };
    const modifiedPartners = prevPartnersState.map((partner) =>
      partner.name === data.selectedPartner.name ? modifiedPartner : partner
    );
    const partnersSelectedCount = modifiedPartners.filter(
      (item) => item.status == KycStepStatus.ACTIVE
    ).length;

    setStepData((prevState) => {
      const updatedStepData: VegaKycBaseStep = {
        ...prevState,
        workFlowDecision:
          partnersSelectedCount > 1
            ? RADIO_OPTIONS[1].value
            : RADIO_OPTIONS[0].value,
        metaData: {
          ...prevState.metaData,
          partners: modifiedPartners,
        },
      };
      updateStepData(updatedStepData);
      return updatedStepData;
    });
  }

  function updateWorkFlowDescion(selectedWorkFlow: string | null) {
    if (!selectedWorkFlow) return;
    setStepData((prevState) => {
      const updatedStepData = {
        ...prevState,
        workFlowDecision: selectedWorkFlow,
      };
      updateStepData(updatedStepData);
      return updatedStepData;
    });
  }

  function didExpand(expanded: boolean) {
    setStepData((prevState) => {
      const updatedStepData = {
        ...prevState,
        status: expanded ? KycStepStatus.ACTIVE : KycStepStatus.INACTIVE,
      };
      updateStepData(updatedStepData);
      setExpanded(expanded);
      return updatedStepData;
    });
  }

  useEffect(() => {
    setStepData(step);
    setExpanded(step.status == KycStepStatus.ACTIVE);
  }, []);

  useEffect(() => {
    setStepData(step);
  }, [step]);

  return (
    <>
      {showStepCard && (
        <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
          <Box
            sx={{
              bgcolor: 'white',
              py: 1.5,
              px: 2,
              borderRadius: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography className="font-aspekta-500" variant="body2">
              {' '}
              Step {stepNumber}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={9} md={10} lg={10} xl={11}>
        <VegaCard noPad>
          <VegaAccordian
            showSwitch
            accordianSummaryMargin="8px 0px"
            expanded={expanded}
            showAccordianDetails={true}
            header={_.startCase(_.toLower(step.name))}
            onChange={(e: any, expanded: boolean) => {
              didExpand(expanded);
            }}
            content={
              <KycStepAccordianContent
                step={stepData}
                updatePartnerStatus={(partner, status) => {
                  updateStepPartnersData({
                    selectedPartner: partner,
                    updatedStatus: status,
                  });
                }}
                updateWorkFlowDescion={updateWorkFlowDescion}
                updateSubStepData={(data) => updateStepData(data)}
              />
            }
          />
        </VegaCard>
      </Grid>
    </>
  );
}
