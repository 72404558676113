/** @format */

import axios from 'axios';
import dayjs from 'dayjs';
import { BASE_URL, BILL } from './endpoints';

const BILL_BASE_URL = BASE_URL + BILL.BASE;

export const generateBillForAccount = (accountId: string, date: string) => {
  const data = {
    accountId,
    billDateStr: dayjs(date).format('YYYY-MM-DD'),
  };
  return axios.post(BILL_BASE_URL, data);
};

export const getBillsForAccount = (accountId: string) => {
  return axios.get(BILL_BASE_URL + '/list', {
    params: {
      accountId,
      startDate: '2019-10-10T00:00:00Z',
      endDate: '2024-10-10T00:00:00Z',
      page: 0,
      pageSize: 100000,
    },
  });
};

export const getBillPdf = (billId: string) => {
  return axios.get(BILL_BASE_URL + `/pdf/${billId}`);
};
export const getLatestBill = (accountId: string) => {
  return axios.get(
    BILL.BILL_LATEST_BY_ACCOUNT_ID.replace('accountId', accountId)
  );
};
