import { Stack, Typography } from '@mui/material';

export interface IRowData {
  rowData: string[];
}

export interface IPolicySummaryData {
  headers: string[];
  rows: IRowData[];
}

const Header = (data: { headers: string[] }) => {
  return (
    <Stack
      direction={'row'}
      justifyContent="space-between"
      alignItems={'center'}
    >
      {data.headers.map((item) => {
        return (
          <Typography style={{ flex: 1, fontWeight: 'bold' }}>
            {item}
          </Typography>
        );
      })}
    </Stack>
  );
};

const ListItem = (data: { items: string[] }) => {
  return (
    <Stack
      direction={'row'}
      justifyContent="space-between"
      alignItems={'center'}
    >
      {data.items.map((item) => {
        return <Typography style={{ flex: 1 }}>{item}</Typography>;
      })}
    </Stack>
  );
};

export const PolicySummaryTable = ({ headers, rows }: IPolicySummaryData) => {
  return (
    <>
      <Header headers={headers} />
      {rows.map((item) => {
        return <ListItem items={item.rowData} />;
      })}
    </>
  );
};
