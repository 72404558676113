import { Autocomplete, TextField } from '@mui/material';

function Recipients({ emailOptions, selectedEmails, onChange }: any) {
  return (
    <Autocomplete
      fullWidth
      multiple
      id="sendTo"
      options={emailOptions}
      onChange={(e, newVal: any) => {
        onChange('recipients', newVal.toString());
      }}
      // onChange={(e, newVal: any) => console.log('recipients', newVal)}
      defaultValue={selectedEmails ? selectedEmails.split(',') : []}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select emails"
          style={{
            padding: 0,
          }}
        />
      )}
      style={{
        padding: 0,
      }}
    />
  );
}

export default Recipients;
