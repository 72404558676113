import { Typography } from '@mui/material';
import { VegaTable } from '../../../../components/common';
import { IBinProps } from '../../../types/Bin';

interface IProps {
  data: IBinProps[];
  loading: boolean;
  page: number;
  size: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}

const ListOfInventoryManagementForClient = ({
  data,
  loading,
  page,
  size,
  rowCount,
  onPageChange,
  onPageSizeChange,
}: IProps) => {
  console.log('ListOfInventoryManagementForClient', data);
  const getKitsValue = (value: number | null) => {
    if (value === null) {
      return 0;
    }
    return value;
  };
  const COLUMN = [
    {
      field: 'id',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bin No
        </Typography>
      ),
      flex: 1,
    },
    // {
    //   field: 'programName',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Program Name</Typography>,
    //   flex: 1,
    // },
    {
      field: 'noOfKitsAvailable',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Unsold
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const unSoldKits =
          getKitsValue(props.row.noOfKitsAvailable) +
          getKitsValue(props.row.noOfKitsAllocated) -
          (getKitsValue(props.row.noOfKitsDamaged) +
            getKitsValue(props.row.noOfKitsSold));
        console.log(
          '🚀 ~ file: ListOfInventoryManagementForClient.tsx:50 ~ unSoldKits:',
          unSoldKits
        );
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {unSoldKits}
          </Typography>
        );
      },
    },
    {
      field: 'noOfKitsSold',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Sold
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        console.log('first', props.row.noOfKitsSold);
        // if (!props.row.noOfKitsSold) return '--';
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {getKitsValue(props.row.noOfKitsSold)}
          </Typography>
        );
      },
    },

    {
      field: 'totalKits',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Total
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        if (!props.row.totalKits) return '--';
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {props.row.totalKits}
          </Typography>
        );
      },
    },
  ];
  return (
    <VegaTable
      data={data}
      columnDef={COLUMN}
      idColumn="id"
      loading={loading}
      paginationMode={'server'}
      page={page}
      pageSize={size}
      rowCount={rowCount}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      // disableSelectionOnClick={true}
      // checkboxSelection={true}
      // onRowSelect={(e) => console.log('e', e)}
    />
  );
};

export default ListOfInventoryManagementForClient;
