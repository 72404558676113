/* eslint-disable react/jsx-pascal-case */
import { Stack, Typography } from '@mui/material';
import { GridColumns, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useClientAuth } from '../../../providers/ClientProvider';
import { CardIndentationOrderDto } from '../../../types/card';
import { CardAllocationService } from '../../apis';
import CIMS_DataGrid from '../../components/common/CIMS_DataGrid';
import SummaryTab from './SummaryTab';

interface IProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}
const ListOfVendor = ({ selectedTab, setSelectedTab }: IProps) => {
  const [pageSize, setPageSize] = useState<number>(5);
  const [page, setPage] = useState(0);
  const { clientId } = useClientAuth();

  const listIndentationOrderQuery = useQuery({
    queryKey: [
      'list_indentation_order_vendors',
      {
        page,
        pageSize,
        clientId,
      },
    ],
    queryFn: () => {
      return CardAllocationService.listCardIndentationOrder({
        size: pageSize,
        populateDivision: true,
        populateProgram: true,
        populateBin: true,
        clientId: clientId || '',
        page,
      });
    },
    keepPreviousData: true,
  });

  const COLUMN_DEF: GridColumns<CardIndentationOrderDto> = [
    {
      field: 'code',
      headerName: 'BC Code',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.destinationDivisionDto?.divisionCode || '--';
      },
      renderCell: (props) => {
        return <Typography variant="body1">{props.value || '-'}</Typography>;
      },
    },
    {
      field: 'bcName',
      headerName: 'BC Name',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.destinationDivisionDto?.name || '--';
      },
      renderCell: (props) => {
        return <Typography variant="body1">{props.value || '-'}</Typography>;
      },
    },
    {
      field: 'binValue',
      headerName: 'Bin Value',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.binDto?.binValue;
      },
      renderCell: (props) => {
        return <Typography variant="body1">{props.value}</Typography>;
      },
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 2,
      minWidth: 200,
      valueGetter: (props) => {
        return props.row.programDto?.name || '-';
      },
      renderCell: (props) => {
        return <Typography variant="body1">{props.value}</Typography>;
      },
    },
    {
      field: 'requestedKits',
      headerName: 'Requested Cards',
      flex: 1,
      minWidth: 140,
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.requestedKits || 0}
          </Typography>
        );
      },
    },
    {
      field: 'totalKits',
      headerName: 'Delivered Cards',
      flex: 1,
      minWidth: 140,
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.totalKitsRecieved || 0}
          </Typography>
        );
      },
    },
    {
      field: 'noOfKitsDamaged',
      headerName: 'Damaged Kits',
      flex: 1,
      minWidth: 120,
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.noOfKitsDamaged || 0}
          </Typography>
        );
      },
    },

    // {
    //   field: 'noOfKitsAvailable',
    //   headerName: 'Cards Available',
    //   flex: 2,
    //   minWidth: 150,
    //   renderCell: (props) => {
    //     return (
    //       <Typography variant="body1">
    //         {props.row.noOfKitsAvailable || 0}
    //       </Typography>
    //     );
    //   },
    // },
  ];
  return (
    <>
      <CIMS_DataGrid
        data={listIndentationOrderQuery.data?.records || []}
        columns={COLUMN_DEF}
        idColumn={'id'}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        paginationMode="server"
        bgColor="white"
        loading={listIndentationOrderQuery.isFetching}
        rowCount={listIndentationOrderQuery.data?.totalItems ?? 0}
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                justifyContent={'space-between'}
                alignItems={'center'}
                py={1}
              >
                {/* <VegaTab
                  placement="vertical"
                  color={'#1047DC'}
                  tabData={['HQ', 'Bc Agent', 'Business Correspondent']}
                  selected={selectedTab}
                  onSelect={(select) => setSelectedTab(select)}
                /> */}
                <SummaryTab
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
                <GridToolbarQuickFilter
                  autoFocus
                  variant="outlined"
                  sx={{
                    bgcolor: 'white',
                    color: '#999DA8',
                    height: '35px',
                    minWidth: '320px',
                    borderRadius: '9px',
                  }}
                />
              </Stack>
            );
          },
        }}
      />
    </>
  );
};

export default ListOfVendor;
