import { useState } from 'react';
import AddRuleModal from '../../../components/RewardsLoyalty/Campaigns/RulesConfiguration/Point Allocation/AddNewRule/AddRuleModal';
import { useCampaign } from '../../../components/RewardsLoyalty/providers/CampaignProvider';
import {
  AddBaseRuleFormData,
  CampaignRule,
} from '../../../types/RewardsLoyalty';
import CampaignBaseRuleInput from './CampaignBaseRuleInput';
import CampaignRuleList from './CampaignRuleList';

type ShowEditRuleModal = {
  show: boolean;
  rule: CampaignRule | null;
};
function PointAllocationTabPanel() {
  const { saveBaseRule, selectedCampaign } = useCampaign();
  const [showAddRuleModal, setShowAddRuleModal] = useState<boolean>(false);
  const [showEditRuleModal, setShowEditRuleModal] = useState<ShowEditRuleModal>(
    {
      show: false,
      rule: null,
    }
  );

  const getBaseRule = () => {
    const rewardPoint = selectedCampaign?.rewardPoint;
    const perAmount = selectedCampaign?.perAmount;
    const maximumPoint = selectedCampaign?.maximumPoint;
    const iteration = selectedCampaign?.timeframe;

    if (!rewardPoint || !perAmount || !maximumPoint || !iteration)
      return undefined;

    const data: AddBaseRuleFormData = {
      rewardPoint: rewardPoint,
      perAmount: perAmount,
      maximumPoint: maximumPoint,
      iteration: iteration,
    };
    return data;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CampaignBaseRuleInput
        value={getBaseRule()}
        onSaveClick={function (data: Partial<AddBaseRuleFormData>): void {
          saveBaseRule(data);
        }}
      />
      <div style={{ flex: 1, flexGrow: 1 }}>
        <CampaignRuleList
          onEdit={function (rule: CampaignRule): void {
            setShowEditRuleModal({
              show: true,
              rule: rule,
            });
          }}
          onClick={function (rule: CampaignRule): void {}}
          onAddRuleClick={function (): void {
            setShowAddRuleModal(true);
          }}
        />
      </div>

      <AddRuleModal
        onClose={function (): void {
          setShowAddRuleModal(false);
        }}
        open={showAddRuleModal}
      />
      <AddRuleModal
        rule={showEditRuleModal.rule}
        onClose={function (): void {
          setShowEditRuleModal({
            show: false,
            rule: null,
          });
        }}
        open={showEditRuleModal.show}
      />
    </div>
  );
}

export default PointAllocationTabPanel;
