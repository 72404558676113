import Box from '@mui/material/Box';
import React from 'react';
import LineCharts from '../../common/VegaCharts/LineCharts/LineCharts';
import Typography from '@mui/material/Typography';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import { VPPRIMARY } from '../../../ui-components/theme';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';

type Props = {};

const mockOptions = ['2023', '2022'].map((item) => {
  const option: VegaSelectOptionV2 = {
    id: item,
    value: item,
    label: item,
  };
  return option;
});
// TODO: DATA IN THIS PAGE IS HARD CODED, NEED TO INTEGRATE WITH API
function Analytics({}: Props) {
  return (
    <Box
      sx={{
        padding: {
          xs: 1,
          sm: 3.25,
        },
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography className="font-aspekta-600" fontSize={17}>
          Payment Due Date
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <DataTile title="GMV TD" value="12432" />
          </Grid>
          <Grid item xs={6} md={4}>
            <DataTile title="Card TD" value="78934" />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
          }}
        >
          <Typography className="font-aspekta-600" fontSize={17}>
            Active Users
          </Typography>
          <VegaSelectV2 options={mockOptions} value={'2023'} />
        </Box>
        <LineCharts
          width={470}
          series={[
            {
              name: 'Amount in thousands',
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 100, 120, 78],
            },
          ]}
          options={{
            colors: [VPPRIMARY.ORANGE],
            xaxis: {
              labels: {
                show: true,
              },
              title: {
                text: 'Month',
              },
              categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
            },
            yaxis: {
              title: {
                text: 'Active Accounts (in lakhs)',
              },
            },
            markers: {
              size: 4,
            },
            grid: {
              show: false,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default Analytics;
