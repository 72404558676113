/* eslint-disable react/jsx-pascal-case */
import { useProfitLoss } from '../../../providers/ProfitLossProvider';
import ProfitLossAccountLendingPage from './modules/ProfitLossAccountLendingPage';
import ProfitLossLendingPage from './modules/ProfitLossLendingPage';

const ProfitLoss = () => {
  const { isSelectedProgram } = useProfitLoss();
  return (
    <>
      {isSelectedProgram ? (
        <ProfitLossAccountLendingPage />
      ) : (
        <ProfitLossLendingPage />
      )}
    </>
  );
};

export default ProfitLoss;
