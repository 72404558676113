import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { BLUE, BORDER_GRAY, GREY } from '../../../../constants/style';
import VegaLabel from '../../../../components/common/VegaLabel';
import { ICardFundLoadProps } from '../../../types/Card';

interface IProps {
  value: ICardFundLoadProps;
  collapsedRows: any[];
}

const CorporateTxnDetails = ({ value, collapsedRows }: IProps) => {
  return (
    <Box
      className={`accordion-item ${
        collapsedRows.includes(value.id) ? '' : 'collapsed'
      }`}
    >
      <Box sx={{ p: 3, bgcolor: BLUE.lightest }}>
        <Grid
          container
          sx={{
            borderTop: BORDER_GRAY,
            borderBottom: BORDER_GRAY,
          }}
        >
          <Grid
            item
            sm={4}
            sx={{
              borderRight: BORDER_GRAY,
              textAlign: 'center',
              py: 1,
              bgcolor: GREY.light,
            }}
          >
            <Typography>Source currency</Typography>
          </Grid>
          <Grid
            item
            sm={4}
            sx={{
              borderRight: BORDER_GRAY,
              textAlign: 'center',
              py: 1,
              bgcolor: GREY.light,
            }}
          >
            <Typography>Destination currency</Typography>
          </Grid>
          <Grid
            item
            sm={4}
            sx={{
              textAlign: 'center',
              py: 1,
              bgcolor: GREY.light,
            }}
          >
            <Typography>Amount</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            sm={4}
            sx={{
              borderRight: BORDER_GRAY,
              textAlign: 'center',
              py: 1,
            }}
          >
            <VegaLabel color="primary" text={value.sourceCurrency} />
          </Grid>
          <Grid
            item
            sm={4}
            sx={{
              borderRight: BORDER_GRAY,
              textAlign: 'center',
              py: 1,
            }}
          >
            <VegaLabel color="primary" text={value.destinationCurrency} />
          </Grid>
          <Grid item sm={4} sx={{ textAlign: 'center', py: 1 }}>
            <VegaLabel color="primary" text={value.amount} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CorporateTxnDetails;
