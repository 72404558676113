/** @format */

import { Button, Typography } from '@mui/material';
import { GridCallbackDetails } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { createCustomerAccount, getAccount } from '../../api/account';
import { fetchAuditrByCustomerId, listForCustomers } from '../../api/customer';
import { useCustomerAccountData } from '../../providers/CustomerDataProvider';
import { useProgramData } from '../../providers/ProgramDataProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { Loading, NoResults, VegaCard, VegaTable } from '../common';
import VegaDrawer from '../common/VegaDrawer';
import AuditLogDetails from './AuditLogDetails';
import CreateAccount from './CreateAccount';
import ViewAccount from './ViewAccount';
import ViewCustomer from './ViewCustomer';

export interface IAuditLogProps {
  accountId: string;
  createdAt: any;
  customerId: string;
  entityType: string;
  eventDescription: string;
  eventName: string;
  eventType: string;
  fieldName: string;
  fromValue: string;
  id: string;
  programId: string;
  toValue: string;
}

export interface IAuditLogProps {
  accountId: string;
  createdAt: any;
  customerId: string;
  entityType: string;
  eventDescription: string;
  eventName: string;
  eventType: string;
  fieldName: string;
  fromValue: string;
  id: string;
  programId: string;
  toValue: string;
}

type Props = {
  reload: number;
};

const ListOfCustomers = ({ reload }: Props) => {
  const { fetchCustomerAccounts } = useCustomerAccountData();
  const { setSnackbar } = useSnackbar();
  const { selectedProgram } = useProgramData();
  const [open, setOpen] = useState(false);
  const [isData, setIsData] = useState(false);
  const [openAudit, setOpenAudit] = useState(false);
  const [openCreateAcc, setOpenCreateAcc] = useState(false);
  const [openViewAcc, setOpenViewAcc] = useState(false);
  const [autoFill, setAutoFill] = useState<boolean>(false);
  const [showCustomerData, setShowCustomerData] = useState<any>({});
  const [showAuditLogData, setShowAuditLogData] = useState<IAuditLogProps[]>(
    []
  );
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [data, setData] = React.useState<any>({
    loading: true,
    rows: [],
    totalRows: 0,
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 10,
    page: 0,
  });

  const columnDef = [
    {
      field: 'firstName',
      headerName: 'Customer Name',

      flex: 1,
    },
    {
      field: 'mobileNumber',
      headerName: 'Mobile',

      flex: 1,
    },
    {
      field: 'emailId',
      headerName: 'Email',

      flex: 1,
    },
    {
      field: 'identity',
      headerName: 'ID Number',
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.identity && props.row.identity[0]?.idNumber}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'audit',
      headerName: 'Audit Log',
      renderCell: (props: any) => {
        return (
          <Button
            color="info"
            variant="text"
            onClick={() => showAuditLog(props.row)}
            sx={{ textTransform: 'capitalize' }}
          >
            View Audit Log
          </Button>
        );
      },
      flex: 1,
    },
    {
      field: 'other',
      headerName: 'Customer Details',
      renderCell: (props: any) => {
        return (
          <Button
            color="info"
            variant="text"
            onClick={() => showCustomer(props.row)}
          >
            View Customer
          </Button>
        );
      },
      flex: 1,
    },
    {
      field: 'account',
      headerName: 'Customer Details',
      renderCell: (props: any) => {
        return props.row.account === null ? (
          <Button
            color="info"
            variant="text"
            onClick={() => createAccount(props.row)}
          >
            Create Account
          </Button>
        ) : (
          <Button
            color="info"
            variant="text"
            onClick={() => viewAccount(props.row)}
          >
            View Account
          </Button>
        );
      },
      flex: 1,
    },
  ];

  const updateData = (k: any, v: any) =>
    setData((prev: any) => ({ ...prev, [k]: v }));

  const onPageChange = (pageNumber: number, details: GridCallbackDetails) => {
    updateData('page', pageNumber);
  };
  const onPageSizeChange = (
    pageSizeNumber: number,
    details: GridCallbackDetails
  ) => {
    updateData('pageSize', pageSizeNumber);
  };

  const fetchCustomersForClient = () => {
    if (
      selectedProgram &&
      selectedProgram.programId &&
      selectedProgram.clientId
    ) {
      updateData('loading', true);
      listForCustomers(
        selectedProgram?.programId as string,
        selectedProgram?.clientId as string,
        data.page,
        data.pageSize
      )
        .then((res) => {
          const customersData = res?.data?.records;
          if (!customersData) {
            console.error('Failed to fetch customers for program');
            setSnackbar('Failed to fetch customers for client', 'error');
          } else {
            updateData('loading', false);
            updateData('rows', customersData);
            updateData('rowCount', res.data.totalItems);
          }
        })
        .catch((err) => {
          updateData('loading', false);
          console.error('Failed to fetch customers for program', err);
          setSnackbar(
            getErrorMessageFromErrorObj(
              err,
              'Failed to fetch customers for program'
            ),
            'error'
          );
        });
    }
  };

  const showCustomer = (data: any) => {
    setOpen(!open);
    setShowCustomerData(data);
  };
  const showAuditLog = (data: any) => {
    setOpenAudit(!openAudit);
    fetchAuditrByCustomerId(data.customerId)
      .then((res) => {
        setShowAuditLogData(res.data);
        res.data.length && setIsData(true);
      })
      .catch((err) => setIsData(false));
  };
  const createAccount = (data: any) => {
    setOpenCreateAcc(!openCreateAcc);
    setShowCustomerData(data);
  };
  const viewAccount = (data: any) => {
    const accountId = data?.account?.accountId;
    if (accountId) {
      setLoadingAccount(true);
      setOpenViewAcc(!openViewAcc);
      getAccount(accountId)
        .then((res) => {
          console.log('Account response', res.data);
          const account = res.data;
          setShowCustomerData({ account: account });
        })
        .catch((err) => {
          console.log('Error', err);
        })
        .finally(() => {
          setLoadingAccount(false);
        });
    }
  };

  const addAccountForCustomer = (accountData: any) => {
    const customerIndex = data?.rows?.findIndex(
      (customer: any) => accountData.customerId === customer.customerId
    );
    const edited = { ...data?.rows[customerIndex], account: accountData };
    const copy = [...data.rows];
    copy.splice(customerIndex, 1, edited);
    updateData('rows', copy);
  };

  const handleCreateAccountClick = () => {
    const data = {
      customerId: showCustomerData.customerId,
      programId: selectedProgram?.programId as string,
      accountLimit: showCustomerData.accountLimit,
      perTransactionLimit: showCustomerData.perTransactionLimit,
      accountOriginationMode: 'online',
      billingCycleFrequency: 'MONTHLY',
      currency: 'INR',
      lenderId: '6a087119-bad0-417b-8511-9658bd4d8945',
    };
    createCustomerAccount(data)
      .then((res) => {
        setSnackbar('Account created!');
        fetchCustomerAccounts();
        addAccountForCustomer(res.data);
        setOpenCreateAcc(!openCreateAcc);
      })
      .catch((err) => setSnackbar(getErrorMessageFromErrorObj(err), 'error'));
  };

  console.log(data);

  React.useEffect(() => {
    if (selectedProgram?.programId) fetchCustomersForClient();
  }, [reload, selectedProgram, data.page, data.pageSize]);

  if (!data || data?.row?.length === 0) {
    return (
      <NoResults text="You have no customers" subtext="Create new customer?" />
    );
  }
  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable
          data={data.rows}
          columnDef={columnDef}
          idColumn={'customerId'}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          page={data.page}
          pageSize={data.pageSize}
          rowCount={data.rowCount}
          loading={data.loading}
          paginationMode="server"
        />
      </VegaCard>
      <VegaDrawer
        open={open}
        onClose={() => setOpen(!open)}
        anchor="right"
        isSubmit={true}
        onSubmit={() => setOpen(!open)}
        submitText="Got It!"
        header="Customer Details"
      >
        <ViewCustomer
          showCustomerData={{ ...showCustomerData, account: null }}
        />
      </VegaDrawer>
      <VegaDrawer
        open={openCreateAcc}
        onClose={() => setOpenCreateAcc(!openCreateAcc)}
        anchor="right"
        isSubmit={true}
        isClose={true}
        onSubmit={handleCreateAccountClick}
        submitText="Create"
        closeText="Close"
        header="Create Account"
      >
        <CreateAccount
          handleChange={(key: string, value: number) => {
            setShowCustomerData({ ...showCustomerData, [key]: value });
          }}
          showCustomerData={
            showCustomerData?.limit !== undefined
              ? showCustomerData
              : { ...showCustomerData, ...{ limit: '', perTxnLimit: '' } }
          }
          setAutoFill={setAutoFill}
          setShowCustomerData={setShowCustomerData}
        />
      </VegaDrawer>
      <VegaDrawer
        open={openViewAcc}
        onClose={() => setOpenViewAcc(!openViewAcc)}
        anchor="right"
        isSubmit={true}
        onSubmit={() => setOpenViewAcc(!openViewAcc)}
        submitText="Got It!"
        header="Account Details"
      >
        <ViewAccount
          showCustomerData={showCustomerData.account}
          loading={loadingAccount}
        />
      </VegaDrawer>
      <VegaDrawer
        open={openAudit}
        onClose={() => setOpenAudit(!openAudit)}
        anchor="right"
        isSubmit={true}
        onSubmit={() => setOpenAudit(!openAudit)}
        submitText="Got It!"
        header="Audit Log Details"
        isTableShow={isData}
      >
        {showAuditLogData.length ? (
          <AuditLogDetails state={showAuditLogData} />
        ) : (
          <NoResults text="This customer have not Audit log" />
        )}
      </VegaDrawer>
    </>
  );
};

export default ListOfCustomers;
