import React, { useEffect, useMemo, useState } from 'react';
import type { AnyPolicyType } from '../../../types/policy';
import { fetchAllPolicesForProgram } from '../../../api/policy';
import { AxiosError } from 'axios';
import Box from '@mui/material/Box';
import { getProgramPoliciesColumns } from '../columns';
import ProgramDataGrid from '../containers/ProgramDataGrid';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setPolicies } from '../../../store/common/programManagementSlice';

type PoliciesProps = {
  programId: string;
  viewPolicy: (policy: AnyPolicyType) => void;
};

const formatVisiblePolicies = (policies: AnyPolicyType[]) => {
  return policies.filter(
    (policy) =>
      !['TCS', 'PASSWORD_POLICY', 'FRM_POLICY'].includes(policy?.policy_type)
  );
};

const fetchPolicies = (programId: string) => {
  return new Promise((resolve, reject) => {
    fetchAllPolicesForProgram(programId)
      .then((res) => {
        const allPolicies = res?.data?.all_policies;
        if (!allPolicies) {
          reject(new AxiosError('Failed to fetch policies of program'));
        }
        resolve(allPolicies);
      })
      .catch(reject);
  });
};

function Policies({ programId, viewPolicy }: PoliciesProps) {
  const { setSnackbar } = useSnackbar();
  const { policies, selectedProgram } = useAppSelector(
    (state) => state.programManagement
  );
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!policies.length) {
      fetchPolicies(programId)
        .then((res) => {
          dispatch(setPolicies(res as AnyPolicyType[]));
        })
        .catch((err) => {
          setSnackbar('Failed to fetch policies!', 'error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const columnsRef = useMemo(
    () =>
      getProgramPoliciesColumns({
        viewPolicy,
        checker: selectedProgram?.checker,
      }),
    [selectedProgram?.checker]
  );

  const formattedPolicies = useMemo(
    () => formatVisiblePolicies(policies),
    [policies]
  );

  return (
    <Box
      sx={{
        padding: {
          xs: 1,
          sm: 3.25,
        },
        flex: 1,
      }}
    >
      <ProgramDataGrid
        data={formattedPolicies ?? []}
        columns={columnsRef}
        idColumn={'policy_id'}
        loading={isLoading}
      />
    </Box>
  );
}

export default Policies;
