import { DataGrid, DataGridProps } from '@mui/x-data-grid';

interface IProps extends DataGridProps {}

export default function UnstyledDataGrid(props: IProps) {
  return (
    <DataGrid
      autoHeight
      sx={{
        border: 0,
        '& .MuiDataGrid-cell': {
          borderBottom: 0,
        },
        '>* :focus-within': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row:hover': {
          bgcolor: 'white',
        },
        '& .MuiDataGrid-columnHeaders': {
          borderBottom: 0,
          borderTop: 'none !important',
        },
        '& .MuiDataGrid-iconSeparator': {
          display: 'none !important',
        },
        '& .MuiDataGrid-sortIcon': {
          display: 'none !important',
        },
      }}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableDensitySelector
      hideFooter
      {...props}
    />
  );
}
