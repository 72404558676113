/** @format */

import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { BORDER_GRAY } from '../../../constants/style';
import { NON_ROUTES } from '../../../routes';
import { IRolesProps, IUsersProps } from '../../../types/userAccess';
import { VegaPage } from '../../common';
import VegaDrawer from '../../common/VegaDrawer';
import AddUser from '../../../components/User Access/AddNewUser/AddUser';
import ListOfTeam from './ListOfTeam';

export interface ITeamProps {
  users: any;
  onChange: (name: string, value: any) => void;
  onSubmit: (data: IUsersProps) => void;
  state: IUsersProps;
  roles: IRolesProps[];
}

const Team = ({ users, onChange, onSubmit, state, roles }: ITeamProps) => {
  const [showMoreRolesDrawer, setShowMoreRolesDrawer] =
    useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<IRolesProps[]>();

  function showDrawer(roles: IRolesProps[]) {
    setUserRoles(roles);
    setShowMoreRolesDrawer(true);
  }

  function hideDrawer() {
    setUserRoles([]);
    setShowMoreRolesDrawer(false);
  }

  return (
    <Box id={NON_ROUTES.TEAM}>
      <VegaPage>
        <AddUser
          onChange={onChange}
          showBulkUpload={false}
          state={state}
          onSubmit={onSubmit}
        />
        <ListOfTeam
          data={users}
          onViewMoreClick={(roles) => showDrawer(roles)}
        />
      </VegaPage>

      <VegaDrawer
        open={showMoreRolesDrawer}
        onClose={() => hideDrawer()}
        anchor="right"
        isSubmit={false}
        isClose={true}
        closeText="Got It!"
        header="Roles"
      >
        {userRoles?.map((item) => {
          return (
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  border: BORDER_GRAY,
                  borderRadius: '4px',
                  px: 1,
                }}
              >
                <Typography variant="button" padding={2}>
                  {_.startCase(_.toLower(item.roleName))}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </VegaDrawer>
    </Box>
  );
};

export default Team;
