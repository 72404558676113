import { Stack, SelectChangeEvent } from '@mui/material';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import Vega_Select from '../../../../../common/v3/Vega_Select';
import Vega_Text from '../../../../../common/v3/Vega_Text';

interface IProps {
  header: string;
  children: JSX.Element;
  handleChange: (value: string) => void;
  selected: string;
}

const CardControlsContainer = ({
  header,
  children,
  selected,
  handleChange,
}: IProps) => {
  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Vega_Text
            text={header}
            fontWeight={600}
            color={NEW_PRIMARY.BLACK}
            fontSize={17}
          />
          <Vega_Select
            sx={{ width: '140px' }}
            value={selected}
            options={['DOMESTIC', 'INTERNATIONAL']}
            onChange={(e: SelectChangeEvent<any>) =>
              handleChange(e.target.value)
            }
          />
        </Stack>
      </Stack>
      {children}
    </>
  );
};

export default CardControlsContainer;
