import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { VegaWebHook } from '../../types/webhook';
import { isValidUrl } from '../../utils/stringUtils';
import { VegaTextField } from '../common';
import VegaDialog from '../common/VegaDialog';

interface AddWebhookEventDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  webhookEvent?: VegaWebHook | null;
  onSubmit: (webhook: VegaWebHook) => void;
}
enum InputType {
  SECRET = 'secret',
  URL = 'url',
}
export function AddWebhookEventDetailsDialog({
  open,
  onClose,
  webhookEvent,
  onSubmit,
}: AddWebhookEventDetailsDialogProps) {
  const [webhook, setWebhook] = useState<VegaWebHook | null>(null);

  useEffect(() => {
    if (!webhookEvent) return;
    setWebhook(webhookEvent);
  }, [webhookEvent]);

  function updateWebhookDetails(type: InputType, updatedValue: string) {
    if (!webhook?.programId) return;
    const updatedWebhook: VegaWebHook = {
      ...webhook,
      programId: webhook.programId,
    };

    setWebhook(() => {
      return {
        ...updatedWebhook,
        [type]: updatedValue,
      };
    });
  }

  function didSubmit() {
    if (webhook) {
      onSubmit(webhook);
      onClose();
    }
  }

  const isUserInputValid = () => {
    if (!webhook?.url || !webhook.secret) return false;
    let isUrlValid: boolean = isValidUrl(webhook.url);
    let isSecretValid: boolean = webhook.secret.length > 0;
    return isUrlValid && isSecretValid;
  };

  return (
    <VegaDialog
      handleClose={() => onClose()}
      open={open}
      title={'Add Details'}
      subtitle={webhook?.description}
      onSubmit={didSubmit}
      submitButtonDisabled={isUserInputValid() == false}
    >
      <Stack spacing={2}>
        <VegaTextField
          focused
          disabled
          value={webhook?.event ?? ''}
          key={'eventName'}
        />
        <VegaTextField
          focused
          label="URL"
          placeholder="Enter URL"
          value={webhook?.url ?? ''}
          key={'url'}
          onChange={(e) => updateWebhookDetails(InputType.URL, e.target.value)}
        />
        <VegaTextField
          label="Secret"
          focused
          key="secret"
          value={webhook?.secret ?? ''}
          placeholder="Enter Secret"
          textFieldWidth={400}
          onChange={(e) =>
            updateWebhookDetails(InputType.SECRET, e.target.value)
          }
        />
      </Stack>
    </VegaDialog>
  );
}
