/** @format */

import { Add, DeleteOutline } from '@mui/icons-material';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { useClientAuth } from '../../providers/ClientProvider';
import { InterestRateSlabInterface } from '../../types/policy';
import { VegaTextField } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import ColumnsInputGreyHeader from './ColumnsInputGreyHeader';
import { DEFAULT_SLAB_RULE } from './InterestPolicy';

type Props = {
  slabs: InterestRateSlabInterface[];
  onChange: (slabs: InterestRateSlabInterface[]) => void;
};

const COL_WIDTHS = { xs: [4, 4, 4, 0], sm: [4, 4, 4, 0] };

const InterestSlabSelector = ({ slabs, onChange }: Props) => {
  const { isAdmin } = useClientAuth();
  const handleChange = (
    index: number,
    key: keyof InterestRateSlabInterface,
    value: any
  ) => {
    const edited = { ...slabs[index], [key]: value };
    const copy = [...slabs];
    copy.splice(index, 1, edited);
    onChange(copy);
  };
  const handleDeleteSlab = (index: number) => {
    const copy = [...slabs];
    copy.splice(index, 1);
    onChange(copy);
  };
  const handleAddSlab = () => {
    const currentEnd = slabs[slabs.length - 1].to_amount;
    const newRow = {
      ...DEFAULT_SLAB_RULE,
      from_amount: currentEnd + 1,
      to_amount: currentEnd + 100,
    };
    onChange([...slabs, newRow]);
  };

  return (
    <>
      <ColumnsInputGreyHeader
        headerTexts={['From amount', 'To Amount', 'Rate']}
        columnWidths={COL_WIDTHS}
      />
      {slabs?.map((slab, index) => {
        return (
          <Grid container mb={1} alignItems={'center'}>
            <Grid item xs={4}>
              <VegaTextField
                value={slab.from_amount}
                onChange={(e) =>
                  handleChange(
                    index,
                    'from_amount',
                    e.target.value && parseFloat(e.target.value)
                  )
                }
                type="number"
                key={`from_amount`}
                size="small"
                textFieldWidth={120}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={4}>
              <VegaTextField
                value={slab.to_amount}
                onChange={(e) =>
                  handleChange(
                    index,
                    'to_amount',
                    e.target.value && parseFloat(e.target.value)
                  )
                }
                type="number"
                key={`to_amount`}
                size="small"
                textFieldWidth={120}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={3.5}>
              <VegaTextField
                value={slab.rate}
                onChange={(e) =>
                  handleChange(
                    index,
                    'rate',
                    e.target.value && parseFloat(e.target.value)
                  )
                }
                type="number"
                key={`rate`}
                size="small"
                textFieldWidth={120}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={0.5}>
              {index > 0 && (
                <IconButton
                  onClick={() => handleDeleteSlab(index)}
                  disabled={!isAdmin}
                >
                  <DeleteOutline fontSize="small" color={'error'} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Box sx={{ display: 'flex', width: '100', justifyContent: 'flex-end' }}>
        <VegaContainedButton
          text="Slab"
          startIcon={<Add />}
          onClick={handleAddSlab}
          isPrimary
          disabled={!isAdmin}
        />
      </Box>
    </>
  );
};

export default InterestSlabSelector;
