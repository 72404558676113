import { Box, Button, Divider, Stack, styled, Typography } from '@mui/material';
import { useState } from 'react';

import { Dayjs } from 'dayjs';
import { BACKGROUND, BORDER_GRAY, GREY } from '../../../constants/style';
import { VegaCard } from '../../common';
import BarCharts from '../../common/VegaCharts/Bar/BarCharts';
import ColumnCharts from '../../common/VegaCharts/Bar/ColumnCharts';
import ChartsWrapper from '../../common/VegaCharts/ChartsWrapper';
import LineCharts from '../../common/VegaCharts/LineCharts/LineCharts';
import DonutsChart from '../../common/VegaCharts/PieCharts/DonutsChart';
import TransactionDate from './TransactionDate';
import { ChoroplethMap } from '../../common/VegaCharts/Maps/ChoroplethMap';

const StyledButton = styled(Button)({
  border: '0.5px solid ',
  borderColor: BORDER_GRAY,
  textTransform: 'capitalize',

  '&:focus': {
    backgroundColor: BACKGROUND.LIGHT_BLUE,
  },
});

const StyledBlockQuote = styled(Box)({
  background:
    'linear-gradient(180deg, #F7F7F7 0%, rgba(245, 245, 245, 0) 100%)',
  borderLeft: '6px solid #4198D7',
  height: '100px',
  width: '200px',
  alignItems: 'center',

  padding: 15,

  overflow: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

const Quotes = ({
  heading,
  subHeading,
}: {
  heading: string;
  subHeading: string;
}) => {
  return (
    <StyledBlockQuote>
      <Stack>
        <Typography fontWeight={'bold'} letterSpacing={0.7}>
          {heading}
        </Typography>
        <Typography>{subHeading}</Typography>
      </Stack>
    </StyledBlockQuote>
  );
};

function RevenueOverview() {
  const [activeTab, setActiveTab] = useState<
    'INCOMING_FUNDS' | 'OUTGOING_FUNDS'
  >('INCOMING_FUNDS');

  const [transactionDates, setTransactionDates] = useState<{
    start: Dayjs | null;
    end: Dayjs | null;
  }>({
    start: null,
    end: null,
  });

  const [quotesData, setQuotes] = useState([
    {
      heading: '200K',
      subHeading: 'Net Interchange Revenue',
    },
    {
      heading: '200K',
      subHeading: 'Transaction Amount',
    },
    {
      heading: '200K',
      subHeading: 'Net Interchange Revenue',
    },
  ]);

  const handleChange = (value: 'INCOMING_FUNDS' | 'OUTGOING_FUNDS') => {
    setActiveTab(value);
  };

  return (
    <VegaCard>
      <Box
        className=""
        style={{
          paddingBottom: '20px',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: '500', color: GREY.darkest }}
        >
          Revenue
        </Typography>

        <Typography
          variant="body2"
          sx={{ fontWeight: '500', color: GREY.lighter }}
        >
          Create multi-level, flexible reports at ease.
        </Typography>
      </Box>

      <Stack my={2} spacing={3}>
        <ChartsWrapper headingText="Interchange Revenue">
          <Stack spacing={2}>
            <Stack direction={'row'} justifyContent="space-between" px={2}>
              <Stack direction="row" spacing={6}>
                {quotesData.map((data) => {
                  return (
                    <Quotes
                      heading={data.heading}
                      subHeading={data.subHeading}
                    />
                  );
                })}
              </Stack>
              <Box
                sx={{
                  border: '1px solid #C7C7C7',
                  textTransform: 'capitalize',
                  backgroundColor: '#F5F5F5',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2,
                  borderRadius: 1,
                }}
              >
                <Stack justifyContent={'center'}>
                  <Typography
                    fontWeight={'bold'}
                    letterSpacing={0.7}
                    textAlign="center"
                  >
                    30K
                  </Typography>
                  <Typography textAlign="center">Net Incoming Funds</Typography>
                </Stack>
              </Box>
            </Stack>
            <LineCharts
              series={[
                {
                  name: 'Amount in thounds',
                  data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                },
              ]}
            />
          </Stack>
        </ChartsWrapper>
        <Stack direction={'row'} spacing={2}>
          <ChartsWrapper headingText="Net Income Funds">
            <DonutsChart
              series={[44, 55, 41, 17, 15]}
              labelData={[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Fridayss',
              ]}
              fillColor={[
                '#1abc9c',
                '#2ecc71',
                '#3498db',
                '#9b59b6',
                '#34495e',
              ]}
            />
          </ChartsWrapper>
          <ChartsWrapper headingText="By time of date">
            <ColumnCharts
              series={[
                {
                  name: 'Net Profit',
                  data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
                },
              ]}
            />
          </ChartsWrapper>
        </Stack>

        <ChartsWrapper>
          <BarCharts
            series={[
              {
                name: 'sales',
                data: [
                  {
                    x: '2019/01/01',
                    y: 400,
                  },
                  {
                    x: '2019/04/01',
                    y: 430,
                  },
                  {
                    x: '2019/07/01',
                    y: 448,
                  },
                  {
                    x: '2019/10/01',
                    y: 470,
                  },
                  {
                    x: '2020/01/01',
                    y: 540,
                  },
                  {
                    x: '2020/04/01',
                    y: 580,
                  },
                  {
                    x: '2020/07/01',
                    y: 690,
                  },
                  {
                    x: '2020/10/01',
                    y: 690,
                  },
                ],
              },
            ]}
          />
        </ChartsWrapper>

        <ChoroplethMap />
      </Stack>
    </VegaCard>
  );
}

export default RevenueOverview;
