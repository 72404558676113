/* eslint-disable react/jsx-pascal-case */
import Stack from '@mui/material/Stack';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardIndentationOrderDto,
  CardIndentationOrderStatus,
  UpdateCardIndentationOrderStatusRequest,
} from '../../../../types/card';
import { CardAllocationService } from '../../../apis';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import { useMutation } from 'react-query';
import { useCardIndentationOrder } from '../context';
import { useState } from 'react';
import LosText from '../../../../components/common/deprecated/LosText';
import VegaCheckBoxV2 from '../../../../components/common/v2/VegaCheckBoxV2';

type MarkAsLostModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  orderData?: CardIndentationOrderDto;
};

function MarkAsLostModal({
  isOpen,
  handleClose,
  orderData,
}: MarkAsLostModalProps) {
  const [selected, setSelected] = useState(false);

  const { setSnackbar } = useSnackbar();
  const { listIndentationOrderQuery } = useCardIndentationOrder();

  const updateAllocationStatusMutation = useMutation({
    mutationFn: (data: UpdateCardIndentationOrderStatusRequest) => {
      return CardAllocationService.updateCardIndentationOrderCardIndentationStatus(
        data
      );
    },
    onSuccess: () => {
      listIndentationOrderQuery?.refetch();
      setSnackbar('Successfully updated the request');
      handleClose();
    },
    onError: (error) => {
      setSnackbar('Error while updating status', 'error');
      handleClose();
    },
  });

  const onSubmit = () => {
    if (orderData) {
      updateAllocationStatusMutation.mutate({
        cardIndentationOrderId: orderData?.id,
        status: CardIndentationOrderStatus.NOT_DELIVERED,
      });
    }
  };

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Cancel',
      onSubmit: () => {
        handleClose();
      },
      icon: '',
      isOutline: true,
    },
    {
      text: 'Mark as Lost',
      onSubmit: onSubmit,
      disabled: !selected,
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <CIMS_Modal
      maxWidth="sm"
      open={isOpen}
      handleClose={handleClose}
      header="Mark as Acknowledged"
      buttonGroup={buttons}
    >
      <Stack sx={{ px: 3, pt: 2, pb: 6 }} spacing={1}>
        <LosText text="I confirm that:" fontWeight={500} />

        <Stack mt={2}>
          <Stack direction={'row'} spacing="1" alignItems={'start'}>
            <VegaCheckBoxV2
              sx={{
                '&.MuiButtonBase-root': {
                  paddingLeft: '0px !important',
                  paddingTop: '0px !important',
                },
                color: 'blue',
              }}
              checked={selected}
              onChange={(e, checked) => {
                setSelected(checked);
              }}
            />
            <LosText style={{ fontSize: '.9rem', lineHeight: '130%' }}>
              I didn't receive the cards . It seems to have lost .
            </LosText>
          </Stack>
        </Stack>
      </Stack>
    </CIMS_Modal>
  );
}

export default MarkAsLostModal;
