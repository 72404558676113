/** @format */

import axios from 'axios';
import { BASE_URL, LEDGER } from './endpoints';

const LEDGER_BASE_URL = BASE_URL + LEDGER.BASE;

interface GetPostingeMetaDataForAccountProps {
  accountId: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  pageSize?: number;
}

interface GetPostingMetaDataResponse {
  records: [];
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
}

export async function getPostingeMetaDataForAccount(
  data: GetPostingeMetaDataForAccountProps
): Promise<GetPostingMetaDataResponse> {
  const endpoint =
    LEDGER_BASE_URL +
    LEDGER.POSTING_META_DATA_FOR_ACCOUNT.replace('{accountId}', data.accountId) +
    `?pageNo=${data.page}&pageSize=${data.pageSize}`;

  try {
    const response = await axios.get(endpoint);
    const responseData = response.data as GetPostingMetaDataResponse;
    return responseData;
  } catch (error) {
    throw error;
  }
}
export async function getTcsPostings(accountId: string): Promise<any> {
  try {
    const endpoint = LEDGER_BASE_URL + LEDGER.TCS.replace('{accountId}', accountId);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getTcsSummary(accountId: string): Promise<any> {
  try {
    const endpoint = LEDGER_BASE_URL + LEDGER.TCS_SUMMARY.replace('{accountId}', accountId);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
