import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { BORDER_GRAY, GREEN, PURPLE } from '../../../../../../constants/style';
import VegaText from '../../../../../../components/common/VegaText';
import VegaSelect from '../../../../../../components/common/VegaSelect';
import { VegaTextField } from '../../../../../../components/common';
import { ITimeProps, ITransactionRulesProps } from '../TransactionLimit';
import { toLowerCase } from '../../../../../../constants/commonFunction';

interface IProps {
  rule: ITransactionRulesProps | null | any;
  code: string;
  handleChange: (key: string, value: any) => void;
}

const VelocityTransactionLimitTable = ({
  rule,
  code,
  handleChange,
}: IProps) => {
  const transactionAmountLimitArr =
    rule?.transactionLimitRule?.transactionAmountLimitRuleList;
  const handleChargeChange = (
    key: string,
    time: any,
    value: any,
    index: number
  ) => {
    const arr = [...rule?.velocityRuleList];
    const newObj = {
      timePeriod: time,
      transactionCountLimit: value,
    };
    arr.splice(index, 1, newObj);
    // console.log('handleChargeChange', arr);
    handleChange('velocityRuleList', arr);
  };

  const handleTransactionTimeChange = (
    key: string,
    time: any,
    value: any,
    index: number
  ) => {
    const arr = [...rule?.transactionLimitRule?.transactionAmountLimitRuleList];
    const newObj = {
      timePeriod: time,
      transactionAmountLimit: value,
    };
    arr.splice(index, 1, newObj);
    let newCharge = {
      ...rule['transactionLimitRule'],
      ['transactionAmountLimitRuleList']: arr,
    };

    handleChange('transactionLimitRule', newCharge);
  };

  const handleTransactionChange = (
    key: string,
    name: string,
    value: string | number
  ) => {
    let newCharge = { ...rule[key], [name]: value };
    handleChange(key, newCharge);
  };
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: 0.4 }}>
          <Box sx={{ p: 2, pl: 4, bgcolor: GREEN.lightest }}>
            Velocity - No. of txns in a timeframe
          </Box>
          <Grid container py={2} px={1}>
            {rule?.velocityRuleList.map((val: ITimeProps, i: number) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                p={1}
                sx={{
                  ...(rule.velocityRuleList[
                    rule.velocityRuleList.length - 1
                  ] && { borderRight: BORDER_GRAY }),
                  textAlign: 'center',
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <VegaText text={toLowerCase(val.timePeriod)} fs="16px" />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <VegaTextField
                    isFixedHeight
                    value={val.transactionCountLimit ?? ''}
                    onChange={(e) =>
                      handleChargeChange(
                        'velocityRuleList',
                        val.timePeriod,
                        e.target.value && parseInt(e.target.value),
                        i
                      )
                    }
                    key="value"
                    type={'text'}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ flex: 0.6 }}>
          <Box sx={{ p: 2, pl: 4, bgcolor: PURPLE.lightest }}>
            Transactional Amount Limits
          </Box>
          <Box>
            <Grid container sx={{ borderBottom: BORDER_GRAY }}>
              <Grid item xs={12} sm={12} md={6} lg={6} p={2}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <VegaText text="Floor/ Transaction" fs="16px" />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 1 }}>$</Typography>
                    <Box sx={{ width: '100px' }}>
                      <VegaTextField
                        isFixedHeight
                        value={
                          rule?.transactionLimitRule.minTransactionAmount ?? ''
                        }
                        onChange={(e) =>
                          handleTransactionChange(
                            'transactionLimitRule',
                            'minTransactionAmount',
                            e.target.value && parseInt(e.target.value)
                          )
                        }
                        key="value"
                        type={'text'}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} p={2}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <VegaText text="Ceiling/Transaction" fs="16px" />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 1 }}>$</Typography>
                    <Box sx={{ width: '100px' }}>
                      <VegaTextField
                        isFixedHeight
                        value={
                          rule?.transactionLimitRule.maxTransactionAmount ?? ''
                        }
                        onChange={(e) =>
                          handleTransactionChange(
                            'transactionLimitRule',
                            'maxTransactionAmount',
                            e.target.value && parseInt(e.target.value)
                          )
                        }
                        key="value"
                        type={'text'}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              {rule?.transactionLimitRule?.transactionAmountLimitRuleList.map(
                (val: ITimeProps, i: number) => {
                  console.log('val', val);
                  return (
                    <Grid item xs={12} sm={12} md={8} lg={4} p={2}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ flex: 1 }}>
                          <VegaText
                            text={toLowerCase(val.timePeriod)}
                            fs="16px"
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={{ mr: 1 }}>$</Typography>
                          <Box sx={{ width: '100px' }}>
                            <VegaTextField
                              isFixedHeight
                              value={val.transactionAmountLimit ?? ''}
                              onChange={(e) =>
                                handleTransactionTimeChange(
                                  'transactionAmountLimitRuleList',
                                  val.timePeriod,
                                  e.target.value && parseInt(e.target.value),
                                  i
                                )
                              }
                              key="value"
                              type={'text'}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VelocityTransactionLimitTable;
