export enum VegaWebhookStatus {
  NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export interface VegaWebHook {
  id?: string;
  programId: string;
  url?: string;
  event: string;
  secret?: string;
  description?: string;
  status: VegaWebhookStatus;
}
