import React from 'react';
import { Typography } from '@mui/material';
import { VegaCard } from '../common';
import { GREY } from '../../constants/style';
import VegaHorizontalSelector, {
  VegaHorizontalSelectorDataType,
} from '../common/VegaHorizontalSelector';

type IProps = {
  heading?: string;
  subHeading?: string;
  data: VegaHorizontalSelectorDataType[];
  setActiveState: (item: VegaHorizontalSelectorDataType) => void;
  active: VegaHorizontalSelectorDataType;
};

function AnalyticsSelection({
  heading,
  subHeading,
  data,
  setActiveState,
  active,
}: IProps) {
  return (
    <VegaCard>
      <div
        className=""
        style={{
          paddingBottom: subHeading || heading ? '20px' : '',
        }}
      >
        {heading && (
          <Typography
            variant="h6"
            sx={{ fontWeight: '500', color: GREY.darkest }}
          >
            {heading}
          </Typography>
        )}
        {subHeading && (
          <Typography
            variant="body2"
            sx={{ fontWeight: '500', color: GREY.lighter }}
          >
            {subHeading}
          </Typography>
        )}
      </div>

      <VegaHorizontalSelector
        hideScroll
        data={data}
        selected={active}
        onChange={setActiveState}
      />
    </VegaCard>
  );
}

export default AnalyticsSelection;
