import './Styles/__styles.css';
import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { ChevronDown } from '../../icon/Icons';
import VegaTextV2 from './VegaTextV2';

export interface VegaSelectOptionV2 {
  id: string;
  value: string;
  label: string;
  icon?: JSX.Element;
}

interface VegaSelectPropsV2 {
  placeholder?: string;
  hideDropDownIcon?: boolean;
  dropDownIcon?: ReactNode;
  options?: VegaSelectOptionV2[];
  renderOptions?: () => ReactNode;
  renderSelectedValue?: (selected: string) => ReactNode;
  hidePlaceholder?: boolean;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}

const StyledPlaceholder = styled(VegaTextV2)(({ theme }) => {
  return {
    fontSize: '0.8125rem',
    fontFamily: 'Aspekta !important',
    fontWeight: 450,
    lineHeight: '120%',
    color: 'rgba(58, 74, 95, 1)',
  };
});

function VegaSelectV2({
  options,
  placeholder,
  renderOptions,
  renderSelectedValue,
  hideDropDownIcon,
  onScroll,
  hidePlaceholder = false,
  ...rest
}: VegaSelectPropsV2 & SelectProps) {
  const theme = useTheme();
  const getLabelForSelectedValue = (selected: string) => {
    const selectedOption =
      options?.filter((item) => item.value == selected) ?? [];

    if (selectedOption.length > 0) {
      if (selectedOption[0].icon) {
        return <>{selectedOption[0].icon}</>;
      }
      return (
        <VegaTextV2
          text={selectedOption[0].label}
          style={{ fontSize: '13px', lineHeight: '120%' }}
        />
      );
    }
    return (
      <VegaTextV2
        text={selected}
        style={{ fontSize: '13px', lineHeight: '120%' }}
      />
    );
  };

  return (
    <FormControl fullWidth={rest.fullWidth ?? false}>
      <Select
        {...rest}
        sx={{
          borderRadius: '.5rem',
          maxHeight: '32px',

          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          ...rest.sx,
        }}
        MenuProps={{
          PaperProps: {
            onScroll: (event: React.UIEvent<HTMLDivElement>) => {
              onScroll && onScroll(event);
            },
          },
          style: onScroll ? { maxHeight: 300 } : { maxHeight: 'none' },
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={hideDropDownIcon ? () => null : undefined}
        // TODO: Remove default icon and add custom icon, removed this because on clicking icon select was not opening
        // IconComponent={() =>
        //   hideDropDownIcon ? null : (
        //     <div
        //       style={{
        //         padding: 5,
        //         display: 'flex',
        //         justifyContent: 'center',
        //         alignItems: 'center',
        //       }}
        //     >
        //       <ChevronDown />
        //     </div>
        //   )
        // }
        renderValue={(selected: any) => {
          console.log('selected', selected);
          if (
            hidePlaceholder == false &&
            (!selected || selected?.length <= 0)
          ) {
            return (
              <StyledPlaceholder text={placeholder ?? ''} variant="caption" />
            );
          }
          if (renderSelectedValue) {
            return renderSelectedValue(selected);
          }
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                height: '30px',
              }}
            >
              {getLabelForSelectedValue(selected)}
            </div>
          );
        }}
      >
        {renderOptions && renderOptions()}
        {options?.map((option) => {
          return (
            <MenuItem
              key={option.id}
              value={option.value}
              style={{ fontFamily: 'Aspekta !important' }}
            >
              <Stack direction={'row'} gap={2} alignItems="center">
                {option.icon && option.icon}
                <VegaTextV2 style={{ fontSize: '14px' }}>
                  {option.label}
                </VegaTextV2>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default VegaSelectV2;
