import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import debounce from 'lodash/debounce';
import { GridEventListener } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { VPDataGrid, VPHeader } from '../ui-components/common';
import FilterProgramManagement from '../components/ProgramManagement/filters/FilterProgramManagement';
import {
  VegaProgramCategoryType,
  VegaProgramWithMetaDataType,
} from '../types/program';
import { VegaPage } from '../components/common';
import { listForClientWithMetaData } from '../api/program';
import { useClientAuth } from '../providers/ClientProvider';
import { getProgramManagementDashboardColumns } from '../components/ProgramManagement/columns';
import { useSnackbar } from '../providers/SnackbarProvider';
import type { PaginatedResponse } from '../mcc/types/Commons';
import { NoResultsIcon } from '../ui-components/icon/VPIcons';
import ProgramDetailPage from '../components/ProgramManagement/ProgramDetailPage';
import { useAppDispatch, useAppSelector } from '../store/hook';
import {
  clearProgramManagement,
  setSelectedProgram,
} from '../store/common/programManagementSlice';

type ProgramsWithMetaDataPayload = {
  clientId: string;
  page?: number;
  pageSize?: number;
  type?: string;
  category?: VegaProgramCategoryType;
  name?: string;
};

function fetchProgramsWithMetaData(
  data: ProgramsWithMetaDataPayload
): Promise<PaginatedResponse<VegaProgramWithMetaDataType>> {
  return new Promise((resolve, reject) => {
    listForClientWithMetaData(data)
      .then((resp) => {
        const programsFromResponse = resp.data;
        if (!programsFromResponse) {
          reject(new AxiosError('Failed to fetch programs for client'));
        }
        resolve(programsFromResponse);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function ProgramManagement() {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();

  const [activeTableType, setActiveTableType] =
    useState<VegaProgramCategoryType>(VegaProgramCategoryType.CREDIT_CARD);
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState<VegaProgramWithMetaDataType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const activeProgram = useAppSelector(
    (state) => state.programManagement.selectedProgram
  );
  const dispatch = useAppDispatch();

  const fetchPrograms = useCallback((data: ProgramsWithMetaDataPayload) => {
    setIsLoading(true);
    fetchProgramsWithMetaData({
      ...data,
    })
      .then((res) => {
        setPrograms(res.records);
        setRowCount(res.totalItems);
      })
      .catch((err) => {
        setSnackbar(
          'Unfortunately, there was an error. Please try again!',
          'error'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const debouncedFetch = React.useRef(debounce(fetchPrograms, 500)).current;

  useEffect(() => {
    if (!clientId) return;

    fetchPrograms({
      clientId,
      category: activeTableType,
      page,
      pageSize,
      name: searchValue,
    });
  }, [activeTableType, page, pageSize]);

  useEffect(() => {
    return () => {
      dispatch(clearProgramManagement());
    };
  }, []);

  const columnsRef = useMemo(() => getProgramManagementDashboardColumns(), []);

  const onSearchValueChange = (value: string) => {
    if (!clientId) return;

    setSearchValue(value);

    debouncedFetch({
      clientId,
      category: activeTableType,
      page,
      pageSize,
      name: value,
    });
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dispatch(setSelectedProgram(params.row));
  };

  const resetActiveProgram = () => {
    dispatch(clearProgramManagement());
  };

  if (activeProgram) {
    return <ProgramDetailPage handleOnBreadcrumbClick={resetActiveProgram} />;
  }

  return (
    <VegaPage>
      <VPHeader text="Program Management" />
      <Box
        sx={{
          px: 3.25,
        }}
      >
        <FilterProgramManagement
          activeTable={activeTableType}
          onTableChange={setActiveTableType}
          searchValue={searchValue}
          handlesSearchValueChange={onSearchValueChange}
        />
        {!isLoading && programs.length === 0 ? (
          <Box
            sx={{
              borderRadius: 1,
              border: '1px solid #E1E4EB',
              background: '#FAFAFA',
              py: 5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '264px',
              }}
            >
              <NoResultsIcon />
              <Typography className="font-aspekta-500" fontSize={14} mt={1.5}>
                No cards to show here
              </Typography>
            </Box>
          </Box>
        ) : (
          <VPDataGrid
            data={programs ?? []}
            columns={columnsRef}
            idColumn={'programId'}
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            rowCount={rowCount}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            loading={isLoading}
            bgColor="transparent"
            getRowClassName={() => 'vegatable--white'}
            disableColumnFilter
            onRowClick={handleRowClick}
            sx={{
              // disable cell selection style
              '.MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              // pointer cursor on ALL rows
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
            }}
          />
        )}
      </Box>
    </VegaPage>
  );
}

export default ProgramManagement;
