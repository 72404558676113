import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Grid, Stack, SxProps, Theme, Typography } from '@mui/material';
import { VPBORDER, VPPRIMARY } from '../theme';

interface IProps {
  text: string;
  nestedText?: string;
  component?: JSX.Element;
  sx?: SxProps<Theme>;
  onClick?: () => void;
  wrapperSx?: SxProps<Theme>;
}

const VPHeader = ({
  text,
  component,
  sx,
  onClick,
  nestedText,
  wrapperSx,
}: IProps) => {
  return (
    <Box
      sx={{ borderBottom: VPBORDER.GRAY, pt: 5, px: 3.25, pb: 1, ...wrapperSx }}
    >
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Box onClick={onClick ? onClick : () => {}}>
              <Typography
                className="font-aspekta-600"
                color={'#222222'}
                fontSize={17}
                sx={{
                  cursor: 'pointer',
                  ...sx,
                }}
              >
                {text}
              </Typography>
            </Box>
            {nestedText && (
              <>
                <ArrowForwardIos sx={{ color: VPPRIMARY.BLUE, fontSize: 10 }} />
                <Typography
                  fontSize={13}
                  className="font-aspekta-500"
                  color={VPPRIMARY.BLUE}
                >
                  Configure Policy
                </Typography>
              </>
            )}
          </Stack>
        </Grid>
        {component && (
          <Grid item xs={12} sm={6}>
            {component}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default VPHeader;
