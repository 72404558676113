import { AddCircleOutline } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AntSwitch, toLowerCase } from '../../../constants/commonFunction';
import { BORDER_GRAY, COMMON, GREY, PRIMARY } from '../../../constants/style';
import {
  GIFT_VOUCHER,
  IinventotyProps,
} from '../../../pages/RewardLoyalty/components/BenefitsInventory';
import NoVoucherAndRewards from '../../../pages/RewardLoyalty/components/NoVoucherAndRewards';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { ICreateInventoryProps } from '../../../types/RewardsLoyalty';
import { VegaCard, VegaPage, VegaTextField } from '../../common';
import VegaContainedButton from '../../common/VegaContainedButton';
import VegaModal from '../../common/VegaModal';
import VegaOutlineButton from '../../common/VegaOutlineButton';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../common/VegaSelect';
import ListOfGiftVoucher from './components/ListOfGiftVoucher';
import VendorList from '../components/VendorList';
import AddCoupons from '../components/AddCoupons';

const DEFAULT_GIF_VOUCHER = {
  programId: '',
  vendorId: '1231231',
  vendorName: 'AMAZON',
  giftType: 'GIFT_VOUCHER',
  giftVoucherAmount: {
    amount: 50,
  },
  quantity: 0,
  totalValue: 2000000,
  assigned: 0,
  remaining: 0,
  autoRenew: true,
} as ICreateInventoryProps;

// interface IProps

const GiftVoucher = ({
  data,
  onVoucherSubmit,
  updateRenewInventory,
}: IinventotyProps) => {
  const { selectedProgram } = useProgramData();
  const [open, setOpen] = useState<boolean>(false);
  const [giftVoucher, setGiftVoucher] = useState<ICreateInventoryProps>({
    ...DEFAULT_GIF_VOUCHER,
  });

  const handleClose = () => {
    setOpen(!open);
  };

  const handleChange = (name: keyof ICreateInventoryProps, value: any) => {
    setGiftVoucher({ ...giftVoucher, [name]: value });
  };

  const handleAmountChange = (name: string, value: any) => {
    const newObj = {
      ...giftVoucher['giftVoucherAmount'],
      [name]: value,
    };
    handleChange('giftVoucherAmount', newObj);
  };

  const onSubmit = async () => {
    const findVendor = await GIFT_VOUCHER.find(
      (voucher: VegaSelectSelectOptionType) =>
        voucher.id === giftVoucher.vendorId
    );

    Object.assign(giftVoucher, {
      programId: selectedProgram?.programId,
      vendorName: findVendor?.name,
    });
    await onVoucherSubmit(giftVoucher);
    handleClose();
  };
  const isInputValid = () => {
    const isAmountValid = (giftVoucher.giftVoucherAmount?.amount ?? 0) > 0;
    const isQuantityValid = giftVoucher.quantity > 0;
    return isAmountValid && isQuantityValid;
  };

  return (
    <VegaPage>
      <VendorList />
      <VegaCard noPad sx={{ boxShadow: 'none' }}>
        <AddCoupons
          buttonText="Add Gift Coupon"
          onClick={() => setOpen(!open)}
        />
        {data.length ? (
          <ListOfGiftVoucher
            data={data}
            updateRenewInventory={updateRenewInventory}
          />
        ) : (
          <NoVoucherAndRewards text="No Gift Voucher" />
        )}
      </VegaCard>
      <VegaModal
        header="Coupons Inventory"
        open={open}
        handleClose={handleClose}
        submitText="Add Gift Coupon"
        handleSubmit={onSubmit}
        buttonIcon={<AddCircleOutline />}
        switchText="Auto Renew"
        disabled={isInputValid() == false}
        switchComponent={
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            <Typography sx={{ fontSize: 12, color: GREY.dark, mr: 1 }}>
              Auto Renew
            </Typography>
            <AntSwitch
              checked={giftVoucher.autoRenew}
              onChange={(e) => handleChange('autoRenew', e.target.checked)}
            />
          </Box>
        }
      >
        <Box sx={{ mx: 2 }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <VegaSelect
                className="font-aspekta-400"
                isFixedHeight
                options={GIFT_VOUCHER}
                key="vendorId"
                selected={giftVoucher.vendorId}
                placeholder="Select Vendor"
                // onChange={(value) => handleVoucherChange(value)}
                onChange={(value) => handleChange('vendorId', value)}
                headerText="select vendor"
              />
            </Grid>
            <Grid item sm={12}>
              <VegaTextField
                className="font-aspekta-400"
                isFixedHeight
                value={giftVoucher.giftVoucherAmount?.amount ?? 0}
                onChange={(e) =>
                  handleAmountChange(
                    'amount',
                    e.target.value && parseInt(e.target.value)
                  )
                }
                key="amount"
                type={'number'}
                placeholder="Enter amount"
                headerText="Enter amount"
              />
            </Grid>
            <Grid item sm={12}>
              <VegaTextField
                isFixedHeight
                value={giftVoucher.quantity}
                onChange={(e) =>
                  handleChange(
                    'quantity',
                    e.target.value && parseInt(e.target.value)
                  )
                }
                key="quantity"
                type={'number'}
                placeholder="Enter Quantity"
                headerText="Enter Quantity"
              />
            </Grid>
          </Grid>
          {/* <NewCampaign state={state} handleChange={handleChange} /> */}
        </Box>
      </VegaModal>
    </VegaPage>
  );
};

export default GiftVoucher;
