export const SUNDAY_HOLIDAYS_MOCK_DATA = [
  { date: "1 January 2023", name: "Sunday", id: 0 },
  { date: "8 January 2023", name: "Sunday", id: 1 },
  { date: "15 January 2023", name: "Sunday", id: 2 },
  { date: "22 January 2023", name: "Sunday", id: 3 },
  { date: "29 January 2023", name: "Sunday", id: 4 },
  { date: "5 February 2023", name: "Sunday", id: 5 },
  { date: "12 February 2023", name: "Sunday", id: 6 },
  { date: "19 February 2023", name: "Sunday", id: 7 },
  { date: "26 February 2023", name: "Sunday", id: 8 },
  { date: "5 March 2023", name: "Sunday", id: 9 },
  { date: "12 March 2023", name: "Sunday", id: 10 },
  { date: "19 March 2023", name: "Sunday", id: 11 },
  { date: "26 March 2023", name: "Sunday", id: 12 },
  { date: "2 April 2023", name: "Sunday", id: 13 },
  { date: "9 April 2023", name: "Sunday", id: 14 },
  { date: "16 April 2023", name: "Sunday", id: 15 },
  { date: "23 April 2023", name: "Sunday", id: 16 },
  { date: "30 April 2023", name: "Sunday", id: 17 },
  { date: "7 May 2023", name: "Sunday", id: 18 },
  { date: "14 May 2023", name: "Sunday", id: 19 },
  { date: "21 May 2023", name: "Sunday", id: 20 },
  { date: "28 May 2023", name: "Sunday", id: 21 },
  { date: "4 June 2023", name: "Sunday", id: 22 },
  { date: "11 June 2023", name: "Sunday", id: 23 },
  { date: "18 June 2023", name: "Sunday", id: 24 },
  { date: "25 June 2023", name: "Sunday", id: 25 },
  { date: "2 July 2023", name: "Sunday", id: 26 },
  { date: "9 July 2023", name: "Sunday", id: 27 },
  { date: "16 July 2023", name: "Sunday", id: 28 },
  { date: "23 July 2023", name: "Sunday", id: 29 },
  { date: "30 July 2023", name: "Sunday", id: 30 },
  { date: "6 August 2023", name: "Sunday", id: 31 },
  { date: "13 August 2023", name: "Sunday", id: 32 },
  { date: "20 August 2023", name: "Sunday", id: 33 },
  { date: "27 August 2023", name: "Sunday", id: 34 },
  { date: "3 September 2023", name: "Sunday", id: 35 },
  { date: "10 September 2023", name: "Sunday", id: 36 },
  { date: "17 September 2023", name: "Sunday", id: 37 },
  { date: "24 September 2023", name: "Sunday", id: 38 },
  { date: "1 October 2023", name: "Sunday", id: 39 },
  { date: "8 October 2023", name: "Sunday", id: 40 },
  { date: "15 October 2002", name: "Sunday", id: 41 },
  { date: "22 October 2023", name: "Sunday", id: 42 },
  { date: "29 October 2023", name: "Sunday", id: 43 },
  { date: "5 November 2023", name: "Sunday", id: 44 },
  { date: "12 November 2023", name: "Sunday", id: 45 },
  { date: "19 November 2023", name: "Sunday", id: 46 },
  { date: "26 November 2023", name: "Sunday", id: 47 },
  { date: "3 December 2023", name: "Sunday", id: 48 },
  { date: "10 December 2023", name: "Sunday", id: 49 },
  { date: "17 December 2023", name: "Sunday", id: 50 },
  { date: "24 December 2023", name: "Sunday", id: 51 },
  { date: "31 December 2023", name: "Sunday", id: 52 },
];
