/* eslint-disable react/jsx-pascal-case */
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { VegaTextField } from '../../../../components/common';
import VegaAspektaText from '../../../../components/common/VegaAspektaText';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../../../components/common/VegaSelect';
import { COMMON, GREY } from '../../../../constants/style';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  CIMSRoleEnums,
  UserRoleEnums,
} from '../../../Program-Dashboard/api/roles';
import BC_CodeSelector from '../../../components/BC_CodeSelector';
import { IAddUserState } from '../../pages/MCCTeamManagement';
import { upperCase } from 'lodash';
import { useQuery } from 'react-query';
import { RoleManagementService } from '../../../../api/roleManagement';

interface IApplicationProps {
  state: IAddUserState;
  rolesList: string[];
  handleChange: (key: keyof IAddUserState, value: string | File | null) => void;
  setBranchCode?: any;
  setIsEmptyKey: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUser = ({
  state,
  handleChange,
  rolesList,
  setBranchCode,
  setIsEmptyKey,
}: IApplicationProps) => {
  const { mccPlatformId, clientId } = useClientAuth();
  const [selectedRole, setSelectedRole] = useState<string>('');
  const { data: rolesData } = useQuery({
    queryKey: ['getAllRolesForClientWithFilter', clientId],
    queryFn: () =>
      RoleManagementService.getAllRolesForClientWithFilter({
        clientId: clientId ?? '',
        size: 100,
      }),
    enabled: !!clientId,
    select(data) {
      return data.records.map((role) => ({
        id: role.id,
        name: upperCase(role.name),
      }));
    },
  });
  const handleRoleChange = (value: string) => {
    handleChange('roleType', value);
    if (rolesData) {
      const roleId = rolesData.find((role) => role.name === value)?.id;
      if (roleId) {
        handleChange('roleId', roleId);
      }
    }

    // const type = rolesList?.find((v) =>
    //   typeof v === 'string' ? v === value : v.id === value
    // );
    // if (type) {
    //   handleChange('roleType', typeof type === 'string' ? type : type.name);
    // }

    // if (value === UserRoleEnums.HQ_USER) {
    //   handleChange('platformId', mccPlatformId);
    // }
  };

  // const handleBranch = (name: keyof IAddUserRequest, value: any) => {
  //   setBranchCode(value.branchCode);
  //   handleChange(name, value.branchId || '');
  // };
  const handleCorporate = (id: string) => {
    // handleChange('platformId', value?.id || '');
    handleChange('platformId', id);
  };
  useEffect(() => {}, []);

  return (
    <>
      <Box sx={{ px: 3 }} mb={4}>
        <Box mb={1}>
          <VegaAspektaText
            text={'Personal Information'}
            fontSize={14}
            fontWeight={'600'}
            color={COMMON.gray}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.name}
              onChange={(e) => handleChange('name', e.target.value)}
              key="name"
              label="Enter Name"
              type={'text'}
              placeholder="Enter Name"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.mobileNumber}
              onChange={(e) => handleChange('mobileNumber', e.target.value)}
              key="mobileNumber"
              label="Enter Mobile No"
              type={'number'}
              placeholder="Enter Mobile No"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.email}
              onChange={(e) => handleChange('email', e.target.value)}
              key="email"
              label="Enter Email"
              type={'email'}
              placeholder="Enter Email"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <VegaTextField
              value={state.address}
              onChange={(e) => handleChange('address', e.target.value)}
              key="address"
              label="Enter address"
              type={'text'}
              placeholder="Enter address"
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ px: 3, borderBottom: `1px solid ${GREY.grey}` }} pb={2}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6}>
            <VegaSelect
              isCurrency
              options={rolesList}
              key="roleType"
              selected={state.roleType}
              label="Select Role"
              onChange={(value) => handleRoleChange(value)}
            />
          </Grid>
          {/* {state.roleType === UserRoleEnums.BRANCH_USER && !isHQ && (
            <Grid item xs={12} sm={6}>
              <VegaTextField
                value={state.platformId}
                onChange={(e) => handleChange('platformId', e.target.value)}
                key="branchId"
                label="Enter branch"
                type={'text'}
                placeholder="Enter branch"
                disabled
              />
            </Grid>
          )} */}
          {/* {(state.roleType === UserRoleEnums.BRANCH_ADMIN ||
            state.roleType === UserRoleEnums.BRANCH_USER) && (
            <Grid item xs={12} sm={6}>
              <BranchSelector
                name="branchId"
                selected={state.platformId ?? ''}
                handleChange={(value) => handleBranch('platformId', value)}
              />
            </Grid>
          )} */}
          {state.roleType === CIMSRoleEnums.BC_ADMIN && (
            <Grid item xs={12} sm={6}>
              <BC_CodeSelector
                value="BUSINESS_CORRESPONDENT"
                name="corporateId"
                selected={state.platformId}
                handleChange={(value) => handleCorporate(value)}
              />
            </Grid>
          )}
          {state.roleType === CIMSRoleEnums.BC_AGENT && (
            <Grid item xs={12} sm={6}>
              <BC_CodeSelector
                value="BC_AGENT"
                name="corporateId"
                selected={state.platformId}
                handleChange={(value) => handleCorporate(value)}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={{ px: 3 }} mt={2}>
        <Box mb={1}>
          <VegaAspektaText
            text={'Goverment ID Proof'}
            fontSize={14}
            fontWeight={'600'}
            color={COMMON.gray}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              options={['PAN', 'AADHAR']}
              key="idType"
              selected={state.idType}
              label="Select ID"
              onChange={(value) => handleChange('idType', value)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.idNumber}
              onChange={(e) => handleChange('idNumber', e.target.value)}
              key="idNumber"
              label="ID Number"
              type={'text'}
              placeholder="ID Number"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddUser;
