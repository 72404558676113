/** @format */

import { useEffect, useMemo } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  ACCESS_MAP,
  HOSTNAME,
  URL,
  getUrl,
  isCurrentPathCims,
} from '../../constants/url';
import ClientDashboardContainer from '../../containers/ClientDashboardContainer';
import CorporateContainer from '../../containers/CorporateContainer';
import CrmContainer from '../../containers/CrmContainer';
import MCCBackOfficeContainer from '../../containers/MCCBackOfficeContainer';
import MCCSimulatorContainer from '../../containers/MCCSimulatorContainer';
import MCCProgramContainer from '../../containers/MccProgramContainer';
import ProgramContainer from '../../containers/ProgramContainer';
import SettingContainer from '../../containers/SettingContainer';
import SimulatorContainer from '../../containers/SimulatorContainer';
import ForgotPassord from '../../pages/ForgotPassord';
import Login from '../../pages/Login';
import ResetPassword from '../../pages/ResetPassword';
import SetNewPassword from '../../pages/SetNewPassword';
import { useClientAuth } from '../../providers/ClientProvider';
import { DASHBOARD_ROUTES, ROUTES, ROUTE_CODE } from '../../routes';
import { PrivateRoute } from './PrivateRoute';
import CimsContainer from '../../containers/CIMS/CimsContainerNew';
import CIMS_Login from '../../cims/components/LoginContainer/CIMS_Login';
import { VegaUser } from '../../types/user';

const isUnderCimsScope = (user?: VegaUser | null) => {
  if (!user || !user.allResourceAccess) {
    return false;
  }

  return user.allResourceAccess.some((value) =>
    value.listOfResourceWithAccess?.some(
      (res) => res.resource.interFace === 'vegapay_cims_dashboard'
    )
  );
};

type Props = {};

const RoutingComponent = (props: Props) => {
  const {
    isAuth,
    canAccessModule,
    getCurrentModule,
    scope,
    mccUser,
    forexAdmin,
    user,
    logout,
  } = useClientAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const isCimsScope = useMemo(() => isUnderCimsScope(user), [user]);

  const currentHost = window.location.hostname;
  const currentPath = window.location.pathname;
  const isDev = currentHost === 'localhost' || currentHost === URL.DEV;

  useEffect(() => {
    if (isAuth) {
      navigate(DASHBOARD_ROUTES.CIMS);
    } else {
      navigate(`${ROUTES.LOGIN}?${searchParams.toString()}`);
    }
  }, [isAuth]);

  const navigateToApplicableRouteForDashboard = () => {
    if (isDev || (!isDev && [ROUTES.LOGIN, '/', ''].includes(currentPath))) {
      if (mccUser) {
        if (
          (forexAdmin && currentHost == HOSTNAME.CLIENT) ||
          scope.scope['FOREX_HQ']
        ) {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_PROGRAM, {
            replace: true,
          });
        } else if (
          (forexAdmin && currentHost == HOSTNAME.FOREX_BRANCH) ||
          scope.scope['FOREX_BRANCH']
        ) {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_BACK_OFFICE, {
            replace: true,
          });
        } else if (
          (forexAdmin && currentHost == HOSTNAME.FOREX_CORPORATE) ||
          scope.scope['FOREX_CORPORATE']
        ) {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_CORPORATE, {
            replace: true,
          });
        } else if (
          (forexAdmin && currentHost == HOSTNAME.PROTOTYPE) ||
          scope.scope['FOREX_SIMULATOR']
        ) {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_SIMULATOR, {
            replace: true,
          });
        } else if (isCimsScope) {
          navigate(DASHBOARD_ROUTES.CIMS);
        } else {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_SIMULATOR, {
            replace: true,
          });
        }

        return;
      } else if (isCimsScope) {
        navigate(DASHBOARD_ROUTES.CIMS);
        return;
      }

      navigate(ROUTE_CODE.CCMS + '/' + ACCESS_MAP[currentHost], {
        replace: true,
      });
    } else if (isCimsScope) {
      navigate(DASHBOARD_ROUTES.CIMS);
      return;
    }
  };

  const navigateToAccessibleModule = () => {
    const module: any = Object.keys(scope.scope ?? {})?.[0] || 'BACK_OFFICE';
    const url = getUrl(module);
    if (isDev) {
      if (mccUser) {
        navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_SIMULATOR, {
          replace: true,
        });
      } else if (isCimsScope) {
        navigate(DASHBOARD_ROUTES.CIMS);
      } else {
        navigate(`${ROUTE_CODE.CCMS}/${DASHBOARD_ROUTES.CLIENT_DASHBOARD}`);
      }
    } else if (isCimsScope) {
      navigate(DASHBOARD_ROUTES.CIMS);
    } else {
      if (currentHost !== url) {
        window.location.replace(url);
      }
    }
  };

  return (
    <Routes>
      <Route
        path={ROUTES.LOGIN}
        element={isCurrentPathCims ? <CIMS_Login /> : <Login />}
      />
      <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassord />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route
        path={ROUTE_CODE.CCMS}
        // element={<Navigate to={DASHBOARD_ROUTES.CLIENT_DASHBOARD} replace />}
      >
        <Route
          path={DASHBOARD_ROUTES.BACK_OFFICE}
          element={
            <PrivateRoute>
              <CrmContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.SIMULATOR}
          element={
            <PrivateRoute>
              <SimulatorContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.PROGRAM_DASHBOARD}
          element={
            <PrivateRoute>
              <ProgramContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.CLIENT_DASHBOARD}
          element={
            <PrivateRoute>
              <ClientDashboardContainer />
            </PrivateRoute>
          }
        />
      </Route>

      <Route
        path={ROUTES.SETTING}
        element={
          <PrivateRoute>
            <SettingContainer />
          </PrivateRoute>
        }
      />

      {/*-------------------------------- MCC-------------------------------- */}

      <Route path={ROUTE_CODE.MCC}>
        <Route
          path={DASHBOARD_ROUTES.MCC_PROGRAM}
          element={
            <PrivateRoute>
              <MCCProgramContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.MCC_BACK_OFFICE}
          element={
            <PrivateRoute>
              <MCCBackOfficeContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.MCC_CORPORATE}
          element={
            <PrivateRoute>
              <CorporateContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.MCC_SIMULATOR}
          element={
            <PrivateRoute>
              <MCCSimulatorContainer />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path={DASHBOARD_ROUTES.CIMS}
        element={
          <>
            <CimsContainer />
          </>
        }
      />
    </Routes>
    // <RouterProvider router={router} />
  );
};

export default RoutingComponent;
