import Stack from '@mui/material/Stack';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardIndentationOrderDto,
  CardIndentationOrderStatus,
} from '../../../../types/card';
import CimsButton from '../../../components/common/CimsButton';

import { useClientAuth } from '../../../../providers/ClientProvider';
import { Level } from '../../../../types/client';
import { useCardIndentationOrder } from '../context';
import { CardIndentationRequestAction } from './columns';

export const CardIndentationRenderActions = ({
  data,
  triggerAction,
}: {
  data: CardIndentationOrderDto;
  triggerAction: (action: CardIndentationRequestAction, data: any) => void;
}) => {
  const { userDivisionType } = useClientAuth();
  const { indentationStatus } = data;

  if (
    indentationStatus === CardIndentationOrderStatus.APPROVED &&
    userDivisionType === 'VENDOR'
  ) {
    return (
      <CimsButton
        variant="soft"
        color="secondary"
        onClick={() => {
          triggerAction(CardIndentationRequestAction.MarkAsDelivered, data);
        }}
      >
        Mark as Delivered
      </CimsButton>
    );
  }

  if (
    indentationStatus === CardIndentationOrderStatus.DELIVERED &&
    userDivisionType === 'CLIENT'
  ) {
    return (
      <Stack direction={'row'} spacing={1}>
        <CimsButton
          variant="soft"
          color="secondary"
          onClick={() => {
            triggerAction(CardIndentationRequestAction.Acknowledge, data);
          }}
        >
          Acknowledge
        </CimsButton>
        <CimsButton
          variant="soft"
          color="warning"
          onClick={() => {
            triggerAction(CardIndentationRequestAction.MarkAsLost, data);
          }}
        >
          Mark as Lost
        </CimsButton>
      </Stack>
    );
  }
  return indentationStatus === CardIndentationOrderStatus.REQUESTED &&
    userDivisionType === 'CLIENT' ? (
    <Stack direction={'row'} spacing={1}>
      <CimsButton
        variant="soft"
        color="success"
        onClick={() => {
          triggerAction(CardIndentationRequestAction.ApproveRequest, data);
        }}
      >
        Approve
      </CimsButton>
      <CimsButton
        variant="soft"
        color="error"
        onClick={() => {
          triggerAction(CardIndentationRequestAction.RejectRequest, data);
        }}
      >
        Reject
      </CimsButton>
    </Stack>
  ) : (
    <>--</>
  );
};
