/* eslint-disable react/jsx-pascal-case */
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { issueCard } from '../../../../api/card';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { CardDto } from '../../../../types/card';
import { CIMS_BORDER, CIMS_PRIMARY } from '../../../assets/style';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import CIMS_TextField from '../../../components/common/CIMS_TextField';
import { VALIDATE_REGEX } from '../../../../utils/validation.utils';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

interface IProps {
  data: CardDto | null;
  handleClose: () => void;
  open: boolean;
  buttonGroup: IButtonGroupProps[];
}

const AssignKitModal = ({ data, open, handleClose }: IProps) => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const { userDivisionId } = useClientAuth();
  const hookForm = useForm<{
    customerName: string;
    cifNo: string;
    externalAccountId: string;
  }>({
    mode: 'all',
  });

  const buttons: IButtonGroupProps[] = [
    {
      text: 'submit',
      disabled: !hookForm.formState.isValid,
      onSubmit: () => {
        if (data && userDivisionId) {
          issueCard({
            clientId: clientId || '',
            cardId: data?.id,
            externalCustomerId: hookForm.getValues('cifNo'),
            externalAccountId: hookForm.getValues('externalAccountId'),
            divisionId: userDivisionId,
            customerName: hookForm.getValues('customerName'),
          })
            .then((res) => {
              setSnackbar('Issued card');
            })
            .catch((err) => {
              setSnackbar(
                getErrorMessageFromErrorObj(
                  err,
                  'Error while issuing the card'
                ),
                'error'
              );
            })
            .finally(() => {
              handleClose();
              hookForm.reset();
            });
        } else {
          setSnackbar('Data not valid', 'error');
        }
      },
      icon: '',
      isOutline: false,
    },
  ];

  return (
    <CIMS_Modal
      maxWidth="sm"
      open={open}
      handleClose={handleClose}
      header="Assign Kit"
      buttonGroup={buttons}
      subTitleContainer={
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <Stack
                direction={'row'}
                gap={1}
                sx={{ borderRight: CIMS_BORDER.DARKGRAY }}
              >
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={CIMS_PRIMARY.GRAY}
                >
                  KIT No:
                </Typography>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={'#222222'}
                >
                  {data?.kitId}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={3}>
              <Stack
                direction={'row'}
                gap={1}
                sx={{ borderRight: CIMS_BORDER.DARKGRAY }}
              >
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={CIMS_PRIMARY.GRAY}
                >
                  Card Type:
                </Typography>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={'#222222'}
                >
                  {toLowerCase(data?.productType || '')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={6}>
              <Stack direction={'row'} gap={1}>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={CIMS_PRIMARY.GRAY}
                >
                  Program Name:
                </Typography>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={'#222222'}
                >
                  {toLowerCase(data?.programDto?.name || '')}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      }
    >
      <Box sx={{ px: 3, pt: 2, pb: 6 }}>
        <Box my={2}>
          <Controller
            name="customerName"
            control={hookForm.control}
            rules={{
              required: 'Required',
              pattern: {
                value: VALIDATE_REGEX.NAME,
                message: 'Not a valid name',
              },
            }}
            render={({ field }) => (
              <CIMS_TextField
                header="customer name"
                variant="outlined"
                {...field}
                error={!!hookForm.formState.errors.customerName}
                helperText={hookForm.formState.errors.customerName?.message}
              />
            )}
          />
        </Box>
        <Box my={2}>
          <Controller
            name="cifNo"
            control={hookForm.control}
            rules={{
              required: 'Required',
              minLength: {
                message: 'Please enter valid cif number (Min length - 12)',
                value: 12,
              },
            }}
            render={({ field, fieldState }) => (
              <CIMS_TextField
                header="cif number"
                variant="outlined"
                type="number"
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box my={2}>
          <Controller
            name="externalAccountId"
            control={hookForm.control}
            rules={{
              required: 'Required',
              minLength: {
                message: 'Please enter valid account number (Min length - 12)',
                value: 12,
              },
            }}
            render={({ field, fieldState }) => (
              <CIMS_TextField
                header="External Account Id"
                variant="outlined"
                type="number"
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </Box>
    </CIMS_Modal>
  );
};

export default AssignKitModal;
