/* eslint-disable react/jsx-pascal-case */
import { Box, Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useClientAuth } from '../../providers/ClientProvider';
import { Level } from '../../types/client';
import { BinService } from '../apis';
import { CIMS_BORDER, CIMS_PRIMARY } from '../assets/style';

import {
  Card_Container,
  ListOfBankCorrespondent,
  ListOfBranch,
  ListOfHq,
  Notifications,
  QuickAccess,
} from '../modules/CIMS_Home';
import ListOfVendor from '../modules/CIMS_Home/ListOfVendor';
import { checkResourceAccess } from '../../utils/userAccessUtils';
import {
  CIMS_RESOURCE_MODULES,
  CIMS_SUB_MODULES,
} from '../../constants/modules';

const OPTIONS = {
  // ALL: Level.l1,
  HQ: 0,
  BC: 1,
  BRANCH: 2,
};

export const CIMS_Home = () => {
  const [selectedTab, setSelectedTab] = useState<string>('HQ');

  const [selected, setSelected] = useState<keyof typeof OPTIONS>('HQ');
  const { clientId, user } = useClientAuth();

  const hasAccessToNotifications = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.HOME.NOTIFICATIONS,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.HOME,
      }),
    [user?.allResourceAccess]
  );
  const hasAccessToQuickAccess = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.HOME.QUICK_ACCESS,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.HOME,
      }),
    [user?.allResourceAccess]
  );
  const hasAccessToSummary = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.HOME.SUMMARY_TABLE,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.HOME,
      }),
    [user?.allResourceAccess]
  );

  const binAggregatedQuery = useQuery({
    queryKey: ['binAggregatedQuery', clientId, OPTIONS[selected]],
    queryFn: () => {
      return BinService.listBinAllocationAggregated({
        clientId: clientId || '',
        depth: OPTIONS[selected],
      });
    },
    enabled: !!clientId,
  });

  return (
    <>
      <Box
        sx={{
          px: 3,
          background: CIMS_PRIMARY.MAIN,
        }}
      >
        {/* <Typography
          className="font-aspekta-600"
          fontSize={24}
          color={'#FFFFFF'}
          pt={4}
        >
          Welcome back, {userPreferredName()}!
        </Typography> */}
        <Box
          sx={{
            pt: 3,
            pb: 4,
          }}
        >
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Card_Container
                title="Total Cards Printed TD"
                number={binAggregatedQuery.data?.totalKits || '0'}
                // percentage={7.67}
                handleChange={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Card_Container
                title="Cards Issued to Customers"
                // number={'3456'}
                number={binAggregatedQuery.data?.noOfKitsSold || '0'}
                // percentage={7.67}
                handleChange={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Card_Container
                title="Total Cards Rejected"
                // number={'3567'}
                // percentage={7.67}
                number={binAggregatedQuery.data?.noOfKitsDamaged || '0'}
                handleChange={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Card_Container
                // number={'30567'}
                // percentage={-7.67}
                title="Total Cards in Stock"
                number={binAggregatedQuery.data?.noOfKitsAvailable || 0}
                handleChange={() => {}}
                // isOptions
                // options={Object.keys(OPTIONS)}
                // selected={selected}
                // handleChange={(selected) =>
                //   setSelected(selected as keyof typeof OPTIONS)
                // }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          px: 3,
          py: 3,
        }}
      >
        <Grid container spacing={2}>
          {hasAccessToQuickAccess && (
            <Grid item xs={12} sm={6} md={7} lg={8} xl={8}>
              <Box
                sx={{
                  p: 2,
                  border: CIMS_BORDER.DARKGRAY,
                  borderRadius: '6px',
                  bgcolor: 'white',
                }}
              >
                <QuickAccess />
              </Box>
            </Grid>
          )}
          {hasAccessToNotifications && (
            <Grid item xs={12} sm={6} md={5} lg={4} xl={4}>
              <Box
                sx={{
                  border: CIMS_BORDER.DARKGRAY,
                  borderRadius: '6px',
                  bgcolor: 'white',
                  height: '100%',
                }}
              >
                <Notifications />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {hasAccessToSummary && (
        <Box
          sx={{
            px: 3,
            py: 1,
          }}
        >
          <Box
            sx={{
              p: 2,
              border: CIMS_BORDER.DARKGRAY,
              borderRadius: '6px',
              bgcolor: 'white',
              overflow: 'auto',
            }}
          >
            <Typography
              className="font-aspekta-500"
              fontSize={14}
              color={'black'}
            >
              Summary Table
            </Typography>
            <Box mt={2}>
              {selectedTab === 'HQ' ? (
                <ListOfHq
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              ) : selectedTab === 'Vendor' ? (
                <ListOfVendor
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              ) : selectedTab === 'Business Correspondent' ? (
                <ListOfBankCorrespondent
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              ) : (
                <ListOfBranch
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
