import { Stack } from '@mui/material';
import { useState } from 'react';
import AddOnCardDetailsPage from '../components/CardManagement/AddOnCard/AddOnCardDetailsPage';
import CardBinDetails from '../components/CardManagement/CardBinDetails';
import CardSettings from '../components/CardManagement/CardSettings';
import { VegaPage } from '../components/common';
import VegaPrimaryButton from '../components/common/v2/VegaPrimaryButton';
import VegaTabBar from '../components/common/v2/VegaTabBar';
import VegaTabBarItem from '../components/common/v2/VegaTabBarItem';
import { useProgramData } from '../providers/ProgramDataProvider';

function CardManagement() {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { selectedProgram } = useProgramData();
  return (
    <VegaPage>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent="space-between"
        alignItems={'center'}
        style={{ paddingRight: 8 }}
      >
        <VegaTabBar
          value={selectedTab}
          onChange={(e, selected) => {
            setSelectedTab(selected);
          }}
        >
          <VegaTabBarItem label="Bin Setting"></VegaTabBarItem>
          <VegaTabBarItem label="Card Setting"></VegaTabBarItem>
          <VegaTabBarItem label="Add on card"></VegaTabBarItem>
        </VegaTabBar>

        {/* <VegaPrimaryButton onClick={() => {}} text={'Submit'} size="small" /> */}
      </Stack>

      {selectedTab == 0 && <CardBinDetails selectedProgram={selectedProgram} />}
      {selectedTab == 1 && <CardSettings selectedProgram={selectedProgram} />}
      {selectedTab == 2 && (
        <AddOnCardDetailsPage selectedProgram={selectedProgram} />
      )}
    </VegaPage>
  );
}

export default CardManagement;
