import { GridColumns } from '@mui/x-data-grid';
import React from 'react';
import Vega_Datagrid from '../../../../../common/v3/Vega_Datagrid';

const ListOfCurrentDelinquncy = () => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Current DPD',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },

    {
      field: 'startDateTime',
      headerName: 'Amount Due',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'endDateTime',
      headerName: 'Entry Date',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'Transaction Time',
      headerName: 'Bill Date',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'Transaction Status',
      headerName: 'Action',
      flex: 1,
      minWidth: 100,
    },
  ];
  return (
    <>
      <Vega_Datagrid data={[]} idColumn="id" columns={COLUMN_DEF} />
    </>
  );
};

export default ListOfCurrentDelinquncy;
