import { createContext, useContext, useEffect, useState } from 'react';
import { VoucherInventoryService } from '../../../api/voucherInventory';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import {
  GiftVoucherType,
  VoucherInventory,
} from '../../../types/RewardsLoyalty';

interface VoucherInventoryContextType {
  vouchers: VoucherInventory[];
  giftVouchers: VoucherInventory[];
  subscriptionsVouchers: VoucherInventory[];
  discountVouchers: VoucherInventory[];
}

const InventoryContext = createContext<VoucherInventoryContextType | null>(
  null
);

export const useVoucherInventory = () =>
  useContext(InventoryContext) as VoucherInventoryContextType;

export const VoucherInventoryProvider = ({ children }: any) => {
  const { selectedProgram } = useProgramData();
  const [inventory, setInventory] = useState<VoucherInventory[]>([]);

  function _getVouchersOfType(type: GiftVoucherType): VoucherInventory[] {
    return inventory.filter((item) => item.giftType == type);
  }

  async function _getInventory(programId: string) {
    try {
      const response = await VoucherInventoryService.getForProgram(programId);
      setInventory(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const programId = selectedProgram?.programId;
    if (!programId) return;
    _getInventory(programId);
  }, [selectedProgram]);

  return (
    <InventoryContext.Provider
      value={{
        vouchers: inventory,
        giftVouchers: _getVouchersOfType(GiftVoucherType.GIFT_VOUCHER),
        subscriptionsVouchers: _getVouchersOfType(GiftVoucherType.SUBSCRIPTION),
        discountVouchers: _getVouchersOfType(GiftVoucherType.DISCOUNT_VOUCHER),
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
