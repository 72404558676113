import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { PolicySelections } from '../../types/policy';
import { VegaAccordian } from '../common';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import AccountPolicySummary from './PolicySummary/AccountPolicySummary';
import BillingPolicySummary from './PolicySummary/BillingPolicySummary';
import ChargePolicySummary from './PolicySummary/ChargePolicySummary';
import InterestPolicySummary from './PolicySummary/InterestPolicySummary';
import MadTadPolicySummary from './PolicySummary/MadTadPolicySummary';
import RepaymentPolicySummary from './PolicySummary/RepaymentPolicySummary';
import TaxPolicySummary from './PolicySummary/TaxPolicySummary';
import TransactionLimitPolicySummary from './PolicySummary/TransactionLimitSummary';
import TransactionToEmiPolicySummary from './PolicySummary/TransactionToEmiSummary';

interface AllPolicySummaryProps {
  policies: PolicySelections;
}

function AllPolicySummary({ policies }: AllPolicySummaryProps) {
  return (
    <Stack spacing={2}>
      <VegaConditionalWrapper
        condition={!!policies.BILLING}
        ifTrue={
          <VegaAccordian
            header={'Billing'}
            content={<BillingPolicySummary policy={policies.BILLING} />}
          />
        }
      />

      <VegaConditionalWrapper
        condition={!!policies.MAD}
        ifTrue={
          <VegaAccordian
            header={'Mad Tad'}
            content={<MadTadPolicySummary policy={policies.MAD} />}
          />
        }
      />

      <VegaConditionalWrapper
        condition={!!policies.ACCOUNT}
        ifTrue={
          <VegaAccordian
            header={'Account'}
            content={<AccountPolicySummary policy={policies.ACCOUNT} />}
          />
        }
      />

      <VegaConditionalWrapper
        condition={!!policies.INTEREST}
        ifTrue={
          <VegaAccordian
            header={'Interest'}
            noPad
            content={<InterestPolicySummary policy={policies.INTEREST} />}
          />
        }
      />

      <VegaConditionalWrapper
        condition={!!policies.CHARGES}
        ifTrue={
          <VegaAccordian
            header={'Charges'}
            noPad
            content={<ChargePolicySummary policy={policies.CHARGES} />}
          />
        }
      />

      <VegaConditionalWrapper
        condition={!!policies.TAX}
        ifTrue={
          <VegaAccordian
            header={'Tax'}
            content={<TaxPolicySummary policy={policies.TAX} />}
          />
        }
      />

      <VegaConditionalWrapper
        condition={!!policies.REPAYMENT_WATERFALL}
        ifTrue={
          <VegaAccordian
            header={'Repayment'}
            content={
              <RepaymentPolicySummary policy={policies.REPAYMENT_WATERFALL} />
            }
          />
        }
      />

      <VegaConditionalWrapper
        condition={!!policies.TRANSACTION_TO_INSTALLMENT}
        ifTrue={
          <VegaAccordian
            header={'Transaction To EMI'}
            content={
              <TransactionToEmiPolicySummary
                policy={policies.TRANSACTION_TO_INSTALLMENT}
              />
            }
          />
        }
      />

      <VegaConditionalWrapper
        condition={!!policies.TRANSACTION}
        ifTrue={
          <VegaAccordian
            header={'Transaction Limit'}
            content={
              <TransactionLimitPolicySummary policy={policies.TRANSACTION} />
            }
          />
        }
      />
    </Stack>
  );
}

export default AllPolicySummary;
