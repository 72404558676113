import React, { useEffect, useState } from 'react';
import { getPreSignURL } from '../../../../api/card';
interface IProps {
  url: string;
}

const DocumentImages = ({ url }: IProps) => {
  const [image, setImage] = useState<string>('');

  const getURL = async (url: string) => {
    try {
      const response = await getPreSignURL(url);
      setImage(response.data);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    url && getURL(url);
  }, [url]);
  return (
    <img
      style={{
        borderRadius: '9px',
      }}
      src={image}
      width={'80%'}
      height={170}
    />
  );
};

export default DocumentImages;
