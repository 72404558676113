import { CheckerMakerStatusEnum } from '../enums/commons';
import { CARD_NETWORK } from '../mcc/types/Card';
import { CardType } from '../providers/CardConfigProvider';
import { Level } from './client';
import { DivisionDto } from './division';
import { EntityDto, LevelEntityDto } from './entity';
import { VegaProgramType } from './program';

export interface VegaCustomerCard {
  id: string;
  accountId?: string;
  cardProcessorVendor: string;
  kitId: string;
  cardType: string;
  network: string;
  cardStatus: string;
  cardNumber?: string;
  lastFourDigits: string;
  pinStatus: string;
  replacedCardId?: string;
  nameOnCard: string;
  countryCode: string;
  mobileNumber: string;
  createdAt: string;
  updatedAt: string;
  cardCategory: string;
}

export interface CardBin {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  binType: BinType;
  cardFormFactorId?: string;
  startKitNumber?: number;
  endKitNumber?: number;
  lastAllocatedKitNumber?: number;
  totalKits?: number;
  allocatedKits?: number;
  unallocatedKits?: number;
  onHoldKits?: number;
  availableKits?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface NewCardBin {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  network: string;
  productType: string;
  binType: BinType;
  cardProcessorConfigId?: string;
  totalKits?: number;
  noOfKitsSold?: number;
  maxNoOfKits?: number;
  noOfKitsDamaged?: number;
  noOfKitsAllocated?: number;
  noOfKitsAvailable?: number;

  createdAt?: Date;
  updatedAt?: Date;
}

export enum BinType {
  SHARED = 'SHARED',
  DEDICATED = 'DEDICATED',
}
export interface BinSettings {
  programId: string;
  currency: Currency;
  binId: number;
  cardAllocated?: number;
}

export enum Currency {
  INR = 'INR',
}

export interface CardSettings {
  programId: string;
  cardDigit: number;
  expiryMonth: number;
  cvvType: string;
  cvvLength: number;
  physicalCardActivationProcess: string;
  virtualCardActivationProcess: string;
}

export enum CvvType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export enum CardCategory {
  PRIMARY_CARD = 'PRIMARY_CARD',
  SUPPLEMENT_CARD = 'SUPPLEMENT_CARD',
  ADD_ON_CARD = 'ADD_ON_CARD',
}
export interface AddOnCardConfiguration {
  programId: string;
  addOnCardSchemeName: string;
  supplementaryCardSchemeName: string;
  firstName: boolean;
  lastName: boolean;
  relationShip: boolean;
  address: boolean;
  phoneNumber: boolean;
  email: boolean;
  gender: boolean;
  panNo: boolean;
  aadhaarNo: boolean;
  addOnCardEcommerceStatus: boolean;
  addOnCardPosStatus: boolean;
  addOnCardCashWithdrawalStatus: boolean;
  addOnCardCount: number;
  supplementaryCardCount: number;
}

export interface BinAllocation {
  id: string;
  binId: number;
  binEntityType: any;
  entityId: string;
  divisionId: string;
  kitStart: number;
  kitEnd: number;
  totalKits: number;
  allocatedKits: number;
  unallocatedKits: number;
  onHoldKits: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface PhysicalCardOrder {
  id: string;
  accountId: string;
  cardId: string;
  binId: number;
  clientId: string;
  trackingId: string;
  nameOnCard: string;
  nameOnLetter: string;
  nameOnEnvelop: string;
  customerAddress?: Partial<Address>;
  vendorAddress?: Partial<Address>;
  deliveryPartner: string;
  rtoAddress?: Partial<Address>;
  otpDelivery: boolean;
  physicalCardOrderStatus: string;
  eta: string;
  createdAt: string;
  updatedAt: string;
}

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
}

export interface CardBlockInfo {
  id: string;
  clientId: string;
  totalCardsCount: number;
  successBlockedCount: number | null;
  alreadyBlockedCount: number | null;
  doesNotExistCount: number;
  blockType: string;
  status: string;
  reportFileName: string;
  reportFileUrl: string;
  embossingFileName: string;
  embossingFileUrl: string;
  createdAt: string;
  updatedAt: string;
}

export enum CardActivationOptions {
  GREEN_PIN = 'GREEN_PIN',
  PIN_THROUGH_SMS = 'PIN_THROUGH_SMS',
  OTP_ON_MOBILE = 'OTP_ON_MOBILE',
  OTP_ON_EMAIL = 'OTP_ON_EMAIL',
  OTP_ON_MOBILE_AND_EMAIL = 'OTP_ON_MOBILE_AND_EMAIL',
}

export enum PhysicalCardOrderStatusType {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  PLACED = 'PLACED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PRINTING_STARTED = 'PRINTING_STARTED',
  PACKED = 'PACKED',
  DISPATCHED = 'DISPATCHED',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  DELIVERED = 'DELIVERED',
  RTO = 'RTO',
}

export enum CardAllotmentStatusEnum {
  PLACED = 'PLACED',
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  DISPATCHED = 'DISPATCHED',
  DELIVERED = 'DELIVERED',
  ACCEPTED = 'ACCEPTED',
  REVOKED = 'REVOKED',
  REJECTED = 'REJECTED',
  NOT_DELIVERED = 'NOT_DELIVERED',
}

export const CardAllotmentStatusMapping: Record<string, string> = {
  PLACED: 'ASSIGNED',
  DISPATCHED: 'SHIPPED',
  IN_TRANSIT: 'IN_TRANSIT',
  DELIVERED: 'DELIVERED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
};

export const CardAllotmentStatusMappingReverse: Record<
  string,
  CardAllotmentStatusEnum
> = {
  ASSIGNED: CardAllotmentStatusEnum.PLACED,
  SHIPPED: CardAllotmentStatusEnum.DISPATCHED,
  APPROVED: CardAllotmentStatusEnum.APPROVED,
  DELIVERED: CardAllotmentStatusEnum.DELIVERED,
  REJECTED: CardAllotmentStatusEnum.REJECTED,
  ACCEPTED: CardAllotmentStatusEnum.ACCEPTED,
};

export interface AddCardAllotmentOrderRequest {
  binId: string;
  cardPrintOrderId?: string | null;
  sourceDivisionId: string;
  destinationDivisionId: string;
  numberOfKits: string;
  allotmentStatus?: CardAllotmentStatusEnum;
  mailerVendorId?: string;
  mailerVendorTrackingId?: string;

  programId?: string;
  successUpdate?: number;
  failureUpdate?: number;
  allotmentFile?: File;
  assignCards?: boolean;
}

export interface ListCardAllotmentOrderRequest {
  binId: number | null;
  clientId: string;
  cardPrintOrderId: string;
  sourceDivisionId: string | null;
  destinationDivisionId: string | null;
  allotmentStatusList: CardAllotmentStatusEnum[];
  mailerVendorId: string;
  page: number;
  size: number;
  allotmentCategoryList: CardAllotmentCategory[];
  populateBin: true;
  populateProgram: true;
  populateDivision: true;
}

export interface UpdateCardAllotmentOrderStatusRequest {
  cardAllotmentOrderId: string;
  status: CardAllotmentStatusEnum;
  cardsWasted?: string | number;
  numberOfKits?: number;
  rejectionReason?: string;
  mailerVendorId?: string;
  mailerVendorTrackingId?: string;
}
export interface UpdateCardAllotmentOrderRequest {
  cardAllotmentOrderId: string;
  mailerVendorId: string;
  mailerVendorTrackingId: string;
}

export enum CardAllotmentCategory {
  ASSIGN = 'ASSIGN',
  REQUEST = 'REQUEST',
}

export interface CardAllotmentOrderDto {
  sr_no: number;
  id: string;
  binId: number;
  cardPrintOrderId: string;
  sourceDivisionId: string;
  sourceDivisionDto: DivisionDto;
  destinationDivisionId: string;
  destinationDivisionDto: DivisionDto;
  requestedKits: number;
  totalKits: number | null;
  noOfKitsDamaged: number | null;
  allotmentStatus: CardAllotmentStatusEnum;
  mailerVendorId: string;
  mailerVendorTrackingId: string;
  createdAt: string;
  updatedAt: string;
  clientId: string;
  cardAllotmentFileUrl: string;
  successUpdate: number | null;
  failureUpdate: number | null;
  sourceUserEmail: string;
  destinationUserEmail: string;
  allotmentCategory: CardAllotmentCategory;
  programId: string;
  rejectionReason: string;
  programDto: VegaProgramType;
  binDto: BinDto;

  receivedBy: string | null;
  approvedBy: string | null;
  dispatchedAt: string | null;
  approvedAt: string | null;
  receivedAt: string | null;
  createdBy: string | null;
}

export interface ApproveCardAllotmentOrderRequest {
  cardAllotmentOrderId: string;
  numberOfKits: number;
}
export interface RejectCardAllotmentOrderRequest {
  cardAllotmentOrderId: string;
  rejectionReason: string;
}

export interface UpdateBulkCardAllocationOrderRequest {
  binId: string;
  entityLevel: Level;
  entityId: string;
  bulkCardUpdateFile: File;
}
export interface AddBulkCardUploadOrderRequest {
  binId: string;
  programId?: string;
  divisionId: string;
  bulkCardUploadFile: File;
}

export interface UpdateBulkCardRejectOrderRequest {
  divisionId: string;
  clientId: string;
  bulkCardRejectFile: File | null;
}

export interface BulkProcessResponse {
  filename: string;
  validEntriesFileName: string;
  invalidEntriesFileName: string;
  uploadDate: string;
  totalEntries: number;
  validEntriesCount: number;
  invalidEntriesCount: number;
  validEntriesFileUrl: string;
  invalidEntriesFileUrl: string;
}

export interface BulkProcessResponse {
  filename: string;
  validEntriesFileName: string;
  invalidEntriesFileName: string;
  uploadDate: string;
  totalEntries: number;
  validEntriesCount: number;
  invalidEntriesCount: number;
  validEntriesFileUrl: string;
  invalidEntriesFileUrl: string;
}
export interface ListCardPrintOrderRequest {
  clientId: string;
  binId: string;
  printingVendorId: string;
  cardDesignId: string;
  page: number;
  size: number;
  populateBin: true;
  populateProgram: true;
}

export interface ListBulkRejectRequest {
  clientId: string;
  divisionId: string;
  page: number;
  size: number;
}

export enum CardPrintingStatus {
  PLACED = 'PLACED',
  IN_PROGRESS = 'IN_PROGRESS',
  UPLOADED = 'UPLOADED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PRINTING_STARTED = 'PRINTING_STARTED',
  PRINTED = 'PRINTED',
  FAILED = 'FAILED',
}
export enum BinEntityType {
  BRANCH = 'BRANCH',
  BC = 'BC',
}

export interface AddCardPrintOrderRequest {
  binId: string;
  programId: string;
  numberOfKits: string;
  successUpload?: string;
  failureUpload?: string;
  printingVendorId: string;
  cardDesignId?: string;
  embossingFile: File;
}

export interface CardPrintOrderDto {
  id: string;
  binId: number;
  totalKits: number | null;
  noOfKitsDamaged: number | null;
  successUpload: number | null;
  failureUpload: number | null;
  printingStatus: CardPrintingStatus;
  printingVendorId: string;
  cardDesignId: string;
  filename: string;
  embossingFileUrl: string;
  createdAt: string;
  updatedAt: string;

  programId: string;
  clientId: string;
  reportFileName: string;
  reportFileUrl: string;
  validEntriesFileUrl: string;
  embossingFileName: string;
  programDto?: VegaProgramType;
  binDto?: BinDto;
}

export interface CardRejectOrderDto {
  id: string;
  divisionId: string;
  clientId: string;
  totalKitEntries: number;
  successRejectCount: number;
  failureCount: number;
  rejectType: CardStatus;
  status: CardBlockingStatus;
  reportFileName: string;
  reportFileUrl: string;
  embossingFileName: string;
  embossingFileUrl: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

export enum CardBlockingStatus {
  PLACED = 'PLACED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum BinAllocationOrderStatus {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  ALLOCATED = 'ALLOCATED',
  REJECTED = 'REJECTED',
}

export interface ListBinAllocationOrderRequest {
  binId: number;
  binValue: string;
  clientId: string;
  binEntityType: BinEntityType;
  entityId: string;
  binAllocationOrderStatusList: BinAllocationOrderStatus[];
  makerCheckerStatusList: CheckerMakerStatusEnum[];
}

export interface ListBinAllocationRequest {
  binId: number;
  clientId: string;
  programId: string;
  divisionId: string;
  depth: number;
  page: number;
  size: number;
  populateBin?: boolean;
  populateProgram?: boolean;
  populateDivision?: boolean;
}

export interface ListBinAllocationAggregatedOrderRequest {
  clientId: string;
  divisionId: string;
  totalKits: string | number;
  noOfKitsSold: string | number;
  noOfKitsDamaged: string | number;
  noOfKitsAllocated: string | number;
  noOfKitsAvailable: string | number;
}
export interface ListBinAllocationAggregatedOrder {
  clientId: string;
  divisionId: string;
  totalKits: string | number;
  noOfKitsSold: string | number;
  noOfKitsDamaged: string | number;
  noOfKitsAllocated: string | number;
  noOfKitsAvailable: string | number;
}

export interface BinAllocationOrderDto {
  id: string;
  binId: number;
  programId: string;
  divisionId: string;
  numberOfKits: number | null;
  binAllocationOrderStatus: BinAllocationOrderStatus;
  makerCheckerStatus: CheckerMakerStatusEnum;
  makerCheckerId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export enum ProductType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  PREPAID = 'PREPAID',
  FOREX = 'FOREX',
}

export enum CardStatus {
  ISSUED = 'ISSUED',
  REJECTED = 'REJECTED',
  TEMP_BLOCKED = 'TEMP_BLOCKED',
  PERM_BLOCKED = 'PERM_BLOCKED',
  ASSIGNED = 'ASSIGNED',
  IN_TRANSIT = 'IN_TRANSIT',
  IN_STOCK = 'IN_STOCK',
  REPLACED = 'REPLACED',
  CANCELLED = 'CANCELLED',
}

export interface ListCardRequest {
  divisionId: string;
  binId: number | null;
  programId: string[] | null;
  productTypeList: ProductType[] | null;
  cardTypeList: CardType[] | null;
  cardStatusList: CardStatus[] | null;
  pageNumber: number | null;
  pageSize: number | null;
}

export interface SearchCardsRequest {
  value: string;
  clientId: string;
  divisionId: string;
  includeSubDivision?: boolean;
  programId: string[] | null;
  productTypeList: ProductType[];
  cardTypeList: CardType[] | null;
  cardStatusList: CardStatus[] | null;
  page: number;
  pageSize: number;
}

export enum CardProcessorVendor {
  M2P = 'M2P',
  PISMO = 'PISMO',
}

export enum PinStatus {
  NA = 'NA',
  SET = 'SET',
  NOT_SET = 'NOT_SET',
}
export enum CustomerPreferenceStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
export enum DebitMessageType {
  DUAL = 'DUAL,',
  SINGLE = 'SINGLE,',
}

export interface CardDto {
  id: string;
  binId: number | null;
  productType: ProductType;
  network: CARD_NETWORK;
  cardCategory: CardCategory;
  cardType: CardType;
  cardStatus: CardStatus;
  cardNumber: string;
  kitId: string;
  cardProcessorVendor: CardProcessorVendor;
  lastFourDigits: string;
  expiryDate: string;
  customerId: string;
  accountId: string;
  programId: string;
  externalCustomerId: string;
  externalAccountId: string;
  pinStatus: PinStatus;
  replacedCardId: string;
  replacementReason: string;
  discardReasonId: string;
  cardAllotmentOrderId: string;
  cardDeliveryTrackingId: string;
  nameOnCard: string;
  divisionCode: string;
  clientId: string;
  divisionId: string;
  atm: CustomerPreferenceStatus;
  pos: CustomerPreferenceStatus;
  ecom: CustomerPreferenceStatus;
  international: CustomerPreferenceStatus;
  dcc: CustomerPreferenceStatus;
  contactless: CustomerPreferenceStatus;
  dualOrSingleMessage: DebitMessageType;
  divisionDto: DivisionDto | null;
  createdAt: string;
  updatedAt: string;
  programDto: VegaProgramType;
  declaration?: string | null;
  declaredBy?: string | null;
}

export interface BinDto {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  binType: BinType;
  network: CARD_NETWORK;
  productType: ProductType;
  totalKits: number | null;
  noOfKitsSold: number | null;
  noOfKitsDamaged: number | null;
  noOfKitsAllocated: number | null;
  noOfKitsAvailable: number | null;
  cardProcessorConfigId: string;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface AddCardDiscardReasonRequest {
  clientId: string;
  discardReasonCode: string;
  discardReasonDisplayText: string;
  discardReasonDescription: string;
}

export interface CardDiscardReasonDto {
  id: string;
  clientId: string;
  discardReasonCode: string;
  discardReasonDisplayText: string;
  discardReasonDescription: string;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface IssueCardRequest {
  clientId: string;
  cardId: string;
  customerId: string;
  customerName: string;
  accountId: string;
  programId: string;
  externalCustomerId: string;
  externalAccountId: string;
  divisionId: string;
}

export interface RejectCardRequest {
  clientId: string;
  cardId: string;
  discardReasonId: string;
  divisionId: string;
  hasDeclaration: boolean;
  declaration?: string;
  declaredBy?: string;
}

export interface BinAllocationDto {
  id: string;
  binId: number;
  binDto: BinDto;
  clientId: string;
  programId: string;
  programDto: VegaProgramType;
  totalKits: number | null;
  noOfKitsSold: number | null;
  noOfKitsDamaged: number | null;
  noOfKitsAllocated: number | null;
  noOfKitsAvailable: number | null;
  minThreshold: number | null;
  maxThreshold: number | null;
  // entityDto: EntityDto;
  divisionDto: DivisionDto;
  divisionId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface AggregatedBinAllocationDto {
  clientId: string;
  divisionId: string;
  totalKits: number | null;
  noOfKitsSold: number | null;
  noOfKitsDamaged: number | null;
  noOfKitsAllocated: number | null;
  noOfKitsAvailable: number | null;
}

export interface ListBinProgramRequest {
  binId: number | string;
  clientId: string;
  programId: string;
  page: number;
  size: number;
  populateBin: true;
  populateProgram: true;
}

export interface BinProgramDto {
  id: string;
  binId: number;
  clientId: string;
  programId: string;
  totalKits: number;
  noOfKitsSold: number;
  noOfKitsDamaged: number;
  noOfKitsAllocated: number;
  noOfKitsAvailable: number;
  minThreshold: number;
  maxThreshold: number;
  programDto: VegaProgramType;
  binDto: BinDto;
  createdAt: string; // You can use the string type for representing dates
  updatedAt: string; // Alternatively, you can use Date type if using Date objects
}

export enum BinAllocationNotificationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export interface ListBinAllocationNotificationRequest {
  clientId: string;
  divisionId: string;
  status: BinAllocationNotificationStatus;
  page: number;
  size: number;
}

export interface BinAllocationNotificationDto {
  id: string;
  clientId: string;
  divisionId: string;
  message: string;
  createdAt: string;
  updatedAt: string;
}

export enum CardIndentationOrderStatus {
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  UNDER_DELIVERY = 'UNDER_DELIVERY',
  DELIVERED = 'DELIVERED',
  NOT_DELIVERED = 'NOT_DELIVERED',
  ACCEPTED = 'ACCEPTED',
}

export interface AddCardIndentationOrderRequest {
  binId: string;
  cardPrintOrderId: string;
  programId: string;
  sourceDivisionId: string;
  destinationDivisionId: string;
  numberOfKits: string | number;
  successUpdate?: number | null;
  failureUpdate?: number | null;
  indentationOrderStatus: CardIndentationOrderStatus;
  mailerVendorId: string;
  mailerVendorTrackingId: string;
  indentationFile: File;
}

export interface UpdateCardIndentationOrderStatusRequest {
  cardIndentationOrderId: string;
  status: CardIndentationOrderStatus;
  mailerVendorId?: string;
  mailerVendorTrackingId?: string;
}

export interface ListCardIndentationOrderRequest {
  binId: number | null;
  clientId: string | null;
  cardPrintOrderId: string | null;
  sourceDivisionId: string | null;
  destinationDivisionId: string | null;
  indentationOrderStatusList: CardIndentationOrderStatus[] | null;
  mailerVendorId: string | null;
  page: number | null;
  size: number | null;
  populateBin: true;
  populateProgram: true;
  populateDivision: true;
}
export interface CardIndentationOrderDto {
  id: string;
  binId: number;
  clientId: string;
  programId: string;
  cardIndentationFileUrl: string;
  cardIndentationFileName: string;
  cardPrintOrderId: string;
  sourceDivisionId: string;
  sourceDivisionDto: DivisionDto;
  sourceUserEmail: string;
  destinationDivisionId: string;
  destinationDivisionDto: DivisionDto;
  destinationUserEmail: string;
  requestedKits: number;
  totalKits: number;
  noOfKitsDamaged: number;
  successUpdate: number;
  failureUpdate: number;
  indentationStatus: CardIndentationOrderStatus;
  rejectionReason: string;
  mailerVendorId: string;
  mailerVendorTrackingId: string;
  programDto: VegaProgramType;
  binDto: BinDto;
  receivedBy: string;
  updatedBy: string;
  dispatchedAt: string;
  approvedAt: string;
  receivedAt: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string | null;
  totalKitsRecieved: number;
}

// export enum CardPrintingOrderStatus {
//   Created = 'Created',
//   Underprinting = 'Underprinting',
//   Confirmed = 'Confirmed',
//   Completed = 'Completed',
// }

// export interface CardPrintOrderDto {
//   id: string;
//   binId: number;
//   cardType: string;
//   printingVendor: string;
//   quantity: number;
//   status: CardPrintingOrderStatus;
//   programName: string;
//   createdAt: string;
//   updatedAt: string;
// }

// export enum CardOrderStatus {
//   Requested = 'Requested',
//   Approved = 'Approved',
//   Shipped = 'Shipped',
//   Rejected = 'Rejected',
//   Delivered = 'Delivered',
// }

// export interface CardAllotmentOrderDto {
//   id: number;
//   requestID: number;
//   requestedBy: string;
//   programName: string;
//   cardType: string;
//   quantity: number;
//   status: CardOrderStatus;
//   remarks?: string;
//   createdAt?: Date;
//   updatedAt?: Date;
// }
