import { Box, Typography, Stack } from '@mui/material';
import React from 'react';
import { COMMON, GREY } from '../../../constants/style';
import { toLowerCase } from '../../../constants/commonFunction';
import Vega_Text from './Vega_Text';

export interface ISelectOptionProps {
  rule: string;
  subTxt: string;
  id: string;
}

interface ITabProps {
  tabData: string[] | ISelectOptionProps[];
  selected: string;
  color?: string;
  onSelect: (select: any) => void;
}

const Vega_Tab = ({
  tabData,
  selected,
  onSelect,
  color = '#FF7B01',
}: ITabProps) => {
  const getValue = (rule: string | ISelectOptionProps) => {
    if (typeof rule === 'string') {
      return rule;
    } else {
      return rule.id;
    }
  };
  console.log('selected', selected);
  return (
    <Stack direction={'row'} gap={2}>
      {tabData.map((rule: string | ISelectOptionProps) => {
        return (
          <Box
            sx={{
              px: 1,
              pb: 1,
              cursor: 'pointer',
              ...(selected === getValue(rule) && {
                borderBottom: `2px solid ${color}`,
              }),
            }}
            onClick={() => onSelect(getValue(rule))}
          >
            <Vega_Text
              text={
                typeof rule === 'string'
                  ? toLowerCase(rule)
                  : toLowerCase(rule.id)
              }
              fontWeight={500}
              fontSize={12}
              sx={{
                ...(selected === getValue(rule)
                  ? { color: color }
                  : { color: COMMON.gray }),
              }}
            />
          </Box>
        );
      })}
    </Stack>
  );
};

export default Vega_Tab;
