import axios from 'axios';
import { LoanDto, TransactionToEmiPreview } from '../types/transaction';
import { BASE_URL, INSTALLMENT } from './endpoints';

const INSTALLMENT_BASE_URL = BASE_URL + INSTALLMENT.BASE;

export async function listInstallmentsForAccount(data: {
  accountId: string;
}): Promise<LoanDto[]> {
  const endpoint = INSTALLMENT_BASE_URL + INSTALLMENT.LIST_FOR_ACCOUNT;

  try {
    const response = await axios.get(endpoint + data.accountId);
    if (response.data) {
      const preview = response.data as LoanDto[];
      return preview;
    }
    return Promise.reject('No response data received');
  } catch (error) {
    throw error;
  }
}

export async function fetchPreviewTransactionToEMI(data: {
  transactionId: string;
}): Promise<TransactionToEmiPreview> {
  const endpoint = INSTALLMENT_BASE_URL + INSTALLMENT.PREVIEW;

  try {
    const response = await axios.post(endpoint, data);
    if (response.data) {
      const preview = response.data as TransactionToEmiPreview;
      return preview;
    }
    return Promise.reject('No response data received');
  } catch (error) {
    throw error;
  }
}

export async function convertTransactionToEmi(data: {
  transactionId: string;
  tenure: number;
}) {
  const endpoint = INSTALLMENT_BASE_URL + INSTALLMENT.CONVERT_TO_EMI;
  try {
    const response = await axios.post(endpoint, data);
    return response;
  } catch (error) {
    throw error;
  }
}
