/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import Vega_Button from '../../../../../components/common/v3/Vega_Button';
import Vega_FilterSection from '../../../../../components/common/v3/Vega_FilterSection';
import Vega_PageHeader from '../../../../../components/common/v3/Vega_PageHeader';
import ListOfDailyProfitLoss from './ListOfDailyProfitLoss';
import ProfitLossDownloadReportDialog from '../ProfitLossDownloadReportDialog';
import { useProfitLoss } from '../../../../../providers/ProfitLossProvider';

const DailyProfitLoss = () => {
  const { setIsOpenDownloadReportDialog } = useProfitLoss();
  return (
    <div>
      <Vega_PageHeader
        sx={{ borderBottom: 'none', px: 0 }}
        title="Daily P&L"
        renderRightView={() => (
          <Stack direction={'row'} gap={2}>
            <Vega_FilterSection setSearch={() => {}} />
            <Vega_Button
              text="Download Report"
              onClick={() => setIsOpenDownloadReportDialog(true)}
            />
          </Stack>
        )}
      />
      <ListOfDailyProfitLoss />
      <ProfitLossDownloadReportDialog />
    </div>
  );
};

export default DailyProfitLoss;
