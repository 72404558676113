/** @format */

import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useCustomerAccountData } from '../../providers/CustomerDataProvider';
import VegaSelect from '../common/VegaSelect';

type Props = {
  disabledOn: boolean;
};

const AccountSelector = ({ disabledOn }: Props) => {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('view');
  const { selectedAccount, setSelectedCustomerAccount, accounts } =
    useCustomerAccountData();

  if (
    !accounts ||
    accounts.length == 0 ||
    !selectedAccount ||
    !disabledOn ||
    currentView == ''
  ) {
    return <></>;
  }

  const customerOptions = accounts?.map((customerAccount) => ({
    id: customerAccount.customerId as string,
    name: `${customerAccount.firstName} ${customerAccount.lastName}`,
  }));
  const selectedOption = {
    id: selectedAccount?.customerId as string,
    name: `${selectedAccount.firstName} ${selectedAccount.lastName}`,
  };

  const handleChange = (selected: string) => {
    const newSelection = accounts.filter(
      (customerAccount) => customerAccount.customerId === selected
    )?.[0];
    if (newSelection) {
      setSelectedCustomerAccount(newSelection);
    }
  };

  return (
    <Box paddingBottom={2}>
      <VegaSelect
        label=""
        textFieldWidth={250}
        options={customerOptions}
        selected={selectedOption}
        onChange={(selected) => handleChange(selected)}
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '9px',
          color: 'primary',
        }}
        darkLabel
      />
    </Box>
  );
};

export default AccountSelector;
