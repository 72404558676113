/* eslint-disable react/jsx-pascal-case */
import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import VegaHeader from '../../../../components/common/VegaHeader';

import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import CimsButton from '../../../components/common/CimsButton';
import { EditFormFiled } from '../../../../components/common/deprecated/EditFormFiled';
import LosSelect from '../../../../components/common/deprecated/LosSelect';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { Level } from '../../../../types/client';

interface IProps {
  onSubmit: (data: {
    shipment: {
      mailerVendorId: string;
      trackingId: string;
      cardsWasted?: string;
    };
  }) => void;
}

function MarkAsShippedModal({ onSubmit }: IProps) {
  const [open, setOpen] = useState(false);
  const { userDivisionType } = useClientAuth();
  const isVendor = userDivisionType === 'VENDOR';

  const hookForm = useForm<{
    shipment: {
      mailerVendorId: string;
      trackingId: string;
      cardsWasted?: string;
    };
  }>();
  const mailerVendorId = hookForm.watch('shipment.mailerVendorId');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const buttons: IButtonGroupProps[] = [
    {
      text: 'Mark as shipped',
      disabled: !hookForm.formState.isValid,
      onSubmit: () => {
        // hookForm.handleSubmit((data) => data && onSubmit && onSubmit(data));
        onSubmit && onSubmit(hookForm.getValues());
        handleClose();
      },
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <Box>
      <CimsButton onClick={handleClickOpen} variant="soft">
        Mark as shipped
      </CimsButton>
      <CIMS_Modal
        maxWidth="sm"
        open={open}
        handleClose={handleClose}
        header="Mark as shipped"
        // subTitleContainer={<></>}
        buttonGroup={buttons}
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <Stack
            // style={{
            //   border: '1px solid #E1E4EB',
            //   padding: '1rem',

            //   borderRadius: '0.75rem',
            //   background: 'white',
            // }}
            spacing={2}
          >
            <Controller
              name="shipment.mailerVendorId"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Box>
                  <VegaHeader text={'partner'} />
                  <LosSelect
                    options={getCardTypeOptions()}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder={'Select Partner'}
                  />
                </Box>
              )}
            />

            {mailerVendorId !== 'handDelivered' && (
              <Controller
                name="shipment.trackingId"
                control={hookForm.control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <EditFormFiled
                    title={'TRACKING ID'}
                    description={field.value}
                    onChange={(val) => {
                      field.onChange(val);
                    }}
                    placeHolder={'Enter Tracking ID'}
                  />
                )}
              />
            )}
            {isVendor && (
              <Controller
                name="shipment.cardsWasted"
                control={hookForm.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <EditFormFiled
                    title={'Cards Wasted'}
                    type="number"
                    description={field.value}
                    onChange={(val) => {
                      field.onChange(val);
                    }}
                    placeHolder={'Enter No of Cards Wasted'}
                  />
                )}
              />
            )}
          </Stack>
        </Box>
      </CIMS_Modal>
    </Box>
  );
}

export default MarkAsShippedModal;

const getCardTypeOptions = () => {
  return [
    {
      label: 'Delhivery',
      value: 'delhivery',
    },
    {
      label: 'Indiapost',
      value: 'indiapost',
    },
    {
      label: 'Bluedart',
      value: 'bluedart',
    },
    {
      label: 'Ecom express',
      value: 'ecomExpress',
    },
    {
      label: 'Hand Delivered',
      value: 'handDelivered',
    },
  ];
};
