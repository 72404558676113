import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
  gap?: number;
  flexRow?: boolean;
  sx?: SxProps<Theme>;
};

const VegaPage = ({ children, gap, flexRow, sx }: Props) => {
  return (
    <Box
      sx={{
        gap: gap || 5,
        // minHeight: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: flexRow ? 'row' : 'column',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default VegaPage;
