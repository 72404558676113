import {
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import React from 'react';
import VegaHeader from '../../../components/common/VegaHeader';

import { CIMS_PRIMARY } from '../../assets/style';
import {
  RejectedOutlined,
  ApprovedOutlined,
} from '../../../ui-components/icon/VPIcons';

export type ICIMSInputProps = {
  header?: string;
  sideText?: string;
  labelText?: string;
  isFixedHeight?: boolean;
  isTextArea?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
} & TextFieldProps;

const CIMS_TextField = ({
  header,
  isFixedHeight,
  isTextArea,
  startAdornment,
  endAdornment,
  sideText,
  labelText,
  ...rest
}: ICIMSInputProps) => {
  const { type, sx, fullWidth } = rest;
  console.log('rest.error', rest.error);
  return (
    <>
      {header && <VegaHeader text={header} />}
      <Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <TextField
            fullWidth
            onWheel={(e: any) => e.target.blur()}
            autoComplete={'off'}
            InputLabelProps={{
              style: { color: '#AFAFAF' },
            }}
            InputProps={
              isFixedHeight
                ? {
                    style: {
                      borderRadius: '9px',
                      height: '35px',
                    },
                    endAdornment: endAdornment,
                    startAdornment: startAdornment,
                  }
                : {
                    style: {
                      borderRadius: '9px',
                      height: isTextArea ? '' : '40px',
                    },
                    endAdornment: endAdornment ? (
                      endAdornment
                    ) : rest.error ? (
                      <InputAdornment position="end">
                        <RejectedOutlined />
                      </InputAdornment>
                    ) : (
                      rest.error !== undefined && (
                        <InputAdornment position="end">
                          <ApprovedOutlined />
                        </InputAdornment>
                      )
                    ),
                    startAdornment: startAdornment,
                  }
            }
            type={type}
            sx={{
              // fontSize: { fontSize },
              ...(isFixedHeight
                ? {
                    '& .MuiInputLabel-root': {
                      transform: 'translate(14px, 6px) scale(1)',
                      // fontFamily: 'Aspekta-450 !important',
                      // fontSize: 13,
                    },
                  }
                : {
                    '& .MuiInputLabel-root': {
                      transform: 'translate(14px, 13px) scale(1)',
                    },
                  }),
              '& .MuiInputLabel-shrink': {
                transform: 'translate(14px, -9px) scale(0.75)',
              },
              '& .MuiFormLabel-root': {
                '&.Mui-focused': {
                  color: `${CIMS_PRIMARY.GRAY} !important`,
                },
              },
              '& .MuiInputBase-input': {
                fontFamily: `var(--font-Aspekta-450) !important`,
                fontSize: '13px',
                lineHeight: '120%',
                color: '#1B1D22',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#AFAFAF',
                  borderWidth: '1px',
                  borderRadius: '9px',
                },
                ':hover fieldset': {
                  borderColor: CIMS_PRIMARY.GRAY,
                },
              },
              ...sx,
            }}
            // {...(rest.error && {
            //   InputProps: {
            //     endAdornment: (
            //       <InputAdornment position="end">
            //         <RejectedOutlined />
            //       </InputAdornment>
            //     ),
            //   },
            // })}
            {...rest}
          />
          {sideText && (
            <Typography
              className="font-aspekta-450"
              fontSize={13}
              color={CIMS_PRIMARY.GRAY}
            >
              {sideText}
            </Typography>
          )}
        </Stack>
        {labelText && (
          <Typography
            className="font-aspekta-450"
            fontSize={10}
            sx={(theme) => ({
              color: rest.error
                ? theme.palette.error.main
                : theme.palette.text.secondary,
            })}
          >
            {labelText}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default CIMS_TextField;
