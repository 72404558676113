import { Box, BoxProps, styled } from '@mui/material';

interface DateBoxProps extends BoxProps {
  selected?: boolean;
  disabled?: boolean;
}

export const DateBox = styled(Box)<DateBoxProps>(
  ({ theme, selected = false, disabled = false }) => {
    return {
      backgroundColor: selected
        ? 'rgba(19, 20, 50, 1)'
        : 'rgba(249, 249, 249, 1)',
      height: '3.5rem',
      width: '3.5rem',
      margin: '2px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '6px',
      color: selected ? 'white' : 'black',
      cursor: 'pointer',
      transition: 'transform 450ms',
      marginBottom: '8px',
      ...(disabled == false && {
        '&:hover': {
          backgroundColor: 'rgba(19, 20, 50, 1)',
          color: 'white',
          transform: 'scale(1.02)',
        },
      }),
    };
  }
);
