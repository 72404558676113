import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { COMMON } from '../../../constants/style';
import { NewCardBin } from '../../../types/card';
import VegaHeader from '../../common/VegaHeader';

interface IProps {
  loading: boolean;
  binData: NewCardBin[];
}

const BinSummary = ({ loading, binData }: IProps) => {
  const summaryData = [
    {
      key: 'total bin range',
      value: binData[0]?.maxNoOfKits ?? 0,
    },
    {
      key: 'BIN Range Assigned',
      value: binData[0]?.noOfKitsSold ?? 0,
    },
    {
      key: 'BIN range unassigned',
      value: binData[0]?.noOfKitsAvailable ?? 0,
    },
  ];
  return (
    <>
      <Typography
        className="font-aspekta-500"
        fontSize={14}
        color={COMMON.gray}
        py={1}
      >
        Bin Summary
      </Typography>
      <Box
        sx={{
          py: 1,
        }}
      >
        <Grid container spacing={2}>
          {summaryData.map((bin) => (
            <Grid key={bin.key} item xs={12} sm={6} md={4} lg={3} xl={3}>
              {loading ? (
                <Skeleton variant="text" height={50} />
              ) : (
                <>
                  <VegaHeader text={bin.key} mb={0} />
                  <Typography
                    className="font-aspekta-450"
                    color={COMMON.darkGray}
                    fontSize={13}
                  >
                    {bin.value}
                  </Typography>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default BinSummary;
