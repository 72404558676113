import { ReactNode, createContext, useContext, useState } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../services/ChargeBackService';
import { ChargebackDto } from '../../../types/chargeBack';
import { PaginatedResponse } from '../../../utils/api';

type IProps = {
  children: ReactNode;
};
type IChargeBackContext = {
  selectedChargeBack: ChargebackDto | null;
  setSelectedChargeBack: React.Dispatch<
    React.SetStateAction<ChargebackDto | null>
  >;
  listChargeBackForProgram: UseQueryResult<
    PaginatedResponse<ChargebackDto>,
    unknown
  >;
  paginationData: {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    pageSize: number;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
  };
  //   actions: {
  //     handleCancelChargeback: (data: UpdateChargebackStatusRequest) => void;
  //   };
};
const ChargeBackContext = createContext<IChargeBackContext | null>(null);

export const useChargeBack = () =>
  useContext(ChargeBackContext) as IChargeBackContext;

export const ChargeBackProvider = ({ children }: IProps) => {
  const [selectedChargeBack, setSelectedChargeBack] =
    useState<ChargebackDto | null>(null);

  const { selectedProgram } = useProgramData();
  const { clientId } = useClientAuth();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const listChargeBackForProgram = useQuery({
    queryKey: ['listChargeBackForProgram', selectedProgram?.programId],
    queryFn: () => {
      return ChargebackService.listChargeback({
        programId: selectedProgram?.programId || '',
        clientId: clientId || '',

        pageSize: pageSize,
        pageNumber: page,
      });
    },
    enabled: !!clientId && !!selectedProgram?.programId,
    keepPreviousData: true,
  });

  const { setSnackbar } = useSnackbar();

  //   const handleCancelChargeback = (data: UpdateChargebackStatusRequest) => {
  //     ChargebackService.updateChargebackStatus(data)
  //       .then((res) => {
  //         setSnackbar('Successfully cancelled chargeback', 'success');
  //       })
  //       .catch((err) => {
  //         setSnackbar(
  //           getErrorMessageFromErrorObj(err, 'Error while cancelling chargeback'),
  //           'error'
  //         );
  //       });
  //   };

  return (
    <ChargeBackContext.Provider
      value={{
        selectedChargeBack,
        setSelectedChargeBack,
        listChargeBackForProgram,
        paginationData: {
          page,
          setPage,
          pageSize,
          setPageSize,
        },
        // actions: {
        //   handleCancelChargeback,
        // },
      }}
    >
      {children}
    </ChargeBackContext.Provider>
  );
};
