export enum AccountClosureType {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export enum AccountClosureStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export enum AccountClosureChecklist {
  NO_DUES = 'NO_DUES',
  ACCOUNT_BALANCE = 'ACCOUNT_BALANCE',
  DISPUTE_TRANSACTION = 'DISPUTE_TRANSACTION',
  AUTOMATIC_BILL_PAYMENT = 'AUTOMATIC_BILL_PAYMENT',
  PENDING_REWARDS = 'PENDING_REWARDS',
}
