/** @format */

import { ToggleOff } from '@mui/icons-material';
import _ from 'lodash';
import {
  ChargeRuleInterface,
  ChargeRuleInterfaceKeyTypes,
  ChargeRuleInterfaceValueTypes,
} from '../../types/policy';
import { VegaAccordian } from '../common';
import OneTimeCharges from './OneTimeCharges';
import PolicyCard from './PolicyCard';
import RecurringCharges from './RecurringCharges';
import SlabCharge from './SlabCharge';

export type Props = {
  rule: ChargeRuleInterface | null;
  headerPrefix?: string;
  code: string;
  onActivationClick: (code: string) => void;
  disabled?: boolean;
  handleChange: (
    code: string,
    key: ChargeRuleInterfaceKeyTypes,
    value: ChargeRuleInterfaceValueTypes
  ) => void;
};

export const TAXATION_TYPES = [
  'INCLUSIVE_OF_TAXES',
  'EXCLUSIVE_OF_TAXES',
  'NO_TAX',
];
export const CHARGE_TYPES = ['FIX_CHARGE', 'SLAB_CHARGES'];
export const EVENT = ['ADVANCE', 'ARREAR'];
export const FREQUENCY = ['DAILY', 'MONTHLY', 'YEARLY', 'ONE_TIME'];

const CHARGE_CODE_TO_TYPE = {
  ANNUAL_MEMBERSHIP_FEE: 'RECURRING',
  LATE_FEE: 'DEPENDENT',
  OVERLIMIT_FEE: 'DEPENDENT',
  CASH_ADVANCE_FEE: 'DEPENDENT',
  LOAN_PROCESSING_FEE: 'DEPENDENT',
  PHYSICAL_STATEMENT_FEE: 'INDEPENDENT',
  REWARDS_REDEMPTION_FEE: 'DEPENDENT',
  DISPUTE_FEE: 'DEPENDENT',
  ADDITIONAL_CARD_FEE: 'INDEPENDENT',
  REISSUE_CARD_FEE: 'INDEPENDENT',
  LOAN_PRE_CLOSURE_FEE: 'DEPENDENT',
  FOREIGN_CURRENCY_FEE: 'DEPENDENT',
  BALANCE_TRANSFER_PROCESSING_FEE: 'DEPENDENT',
  CHEQUE_ECS_NACH_RETURN_FEE: 'INDEPENDENT',
  CHARGE_SUMMARY_RETRIEVAL_FEE: 'INDEPENDENT',
  CARD_VALIDATION_FEE: 'INDEPENDENT',
  FOREIGN_CURRENCY_DCC: 'INDEPENDENT',
  FOREIGN_CURRENCY_CCC: 'INDEPENDENT',
} as { [key: string]: 'RECURRING' | 'DEPENDENT' | 'INDEPENDENT' };

const ChargePolicyCard = ({
  rule,
  code,
  onActivationClick,
  handleChange,
  headerPrefix,
  disabled,
}: Props) => {
  return (
    <PolicyCard noPad>
      <VegaAccordian
        expandIcon={
          <ToggleOff fontSize="large" color={!!rule ? 'success' : 'disabled'} />
        }
        expanded={!!rule}
        onChange={() => {
          if (disabled) return;
          onActivationClick(code);
        }}
        header={_.startCase(
          _.toLower(`${headerPrefix ? headerPrefix : ''} ${code}`)
        )}
        content={
          rule && (
            <>
              {CHARGE_CODE_TO_TYPE[code] === 'RECURRING' ? (
                <RecurringCharges
                  code={code}
                  chargeRule={rule}
                  onChange={(key, value) => handleChange(code, key, value)}
                  disabled={disabled}
                />
              ) : CHARGE_CODE_TO_TYPE[code] === 'DEPENDENT' ? (
                <SlabCharge
                  chargeRule={rule}
                  code={code}
                  handleChange={(key, value) => handleChange(code, key, value)}
                  disabled={disabled}
                />
              ) : (
                <OneTimeCharges
                  code={code}
                  chargeRule={rule}
                  onChange={(key, value) => handleChange(code, key, value)}
                  disabled={disabled}
                />
              )}
            </>
          )
        }
      />
    </PolicyCard>
  );
};

export default ChargePolicyCard;
