import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MakerCheckerItem from '../MakerCheckerItem';
import { VegaUser } from '../../../types/user';

type MakerCheckerProps = {
  makers?: VegaUser[];
  checker?: VegaUser;
};

function MakerChecker({ makers, checker }: MakerCheckerProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pb: 2,
        }}
      >
        <Typography className="font-aspekta-600" fontSize={17}>
          Maker
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          {makers?.map((maker, index) => (
            <MakerCheckerItem isPrimary={index === 0} user={maker} />
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pb: 2,
        }}
      >
        <Typography className="font-aspekta-600" fontSize={17}>
          Checker
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          {checker ? <MakerCheckerItem isChecker user={checker} /> : null}
        </Box>
      </Box>
    </Box>
  );
}

export default MakerChecker;
