export class FileUtils {
  static downloadJsonFile = (data: { data: any; fileName: string }) => {
    const myData = JSON.stringify(data.data);

    // create file in browser
    const fileName = data.fileName;
    const json = JSON.stringify(myData, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };
  static downloadFileFromURI(uri: string, name: string): void {
    const link = document.createElement('a');
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export function downloadFileFromURI(uri: string, name: string): void {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
