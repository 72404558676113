import { Box, Stack } from '@mui/material';
import VegaTextV2 from '../VegaTextV2';
import ColorSelectorItem from './ColorSelectorItem';
import HexInput from './HexInput';

export type VegaColorSelectorRowData = {
  title: string;
  colors: string[];
};

type Props = {
  rows: VegaColorSelectorRowData[];
  showHexInput?: boolean;
  selectedColor?: string;
  onChange: (selectedColor: string) => void;
};

function VegaColorSelector({
  rows,
  onChange,
  selectedColor,
  showHexInput = false,
}: Props) {
  return (
    <Box
      style={{
        padding: 12,
        display: 'flex',
        gap: 20,
        flexDirection: 'column',
      }}
    >
      {rows.map((item) => {
        return (
          <Stack spacing={1}>
            <VegaTextV2 text={item.title}></VegaTextV2>
            <Stack direction={'row'} spacing={2}>
              {item.colors.map((colorItem) => {
                return (
                  <ColorSelectorItem
                    background={colorItem}
                    isSelected={selectedColor == colorItem}
                    handleClick={() => {
                      onChange(colorItem);
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
        );
      })}
      {showHexInput && (
        <HexInput
          value={selectedColor?.startsWith('#') ? selectedColor : undefined}
          onChange={function (value: string): void {
            onChange(value);
          }}
        />
      )}
    </Box>
  );
}

export default VegaColorSelector;
