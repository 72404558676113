/** @format */

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Switch,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { BLUE, BORDER_GRAY } from '../../constants/style';

type Props = {
  header: string | number;
  content: ReactNode;
  expandIcon?: ReactNode;
  expanded?: boolean;
  onChange?: any;
  disabled?: boolean;
  defaultExpanded?: boolean;
  showSwitch?: boolean;
  noPad?: boolean;
  accordianSummaryMargin?: string;
  showAccordianDetails?: boolean;
};

const VegaAccordian = ({
  header,
  content,
  expandIcon = <ExpandMoreIcon />,
  expanded,
  onChange,
  disabled,
  defaultExpanded = false,
  showSwitch = false,
  accordianSummaryMargin = '12px 0px',
  showAccordianDetails = true,
  noPad,
}: Props) => {
  return (
    <Accordion
      sx={{
        overflow: 'hidden',
        borderRadius: '10px !important',
        border: BORDER_GRAY,
        '&.MuiPaper-root': {
          boxShadow: 'none !important',
        },
        '& .MuiAccordionSummary-root': {
          bgcolor: BLUE.lightest,
          borderRadius: '10px',
          '&.Mui-expanded': {
            minHeight: '35px !important',
          },
        },
        '& .Mui-expanded': {
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
        },
        '& .MuiAccordionSummary-content': {
          margin: `${accordianSummaryMargin} !important`,
        },
        '&.MuiAccordion-root': {
          borderRadius: '10px !important',
          border: BORDER_GRAY,
        },
      }}
      expanded={expanded}
      onChange={onChange}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={showSwitch ? null : expandIcon}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{header}</Typography>
        {showSwitch && (
          <Box sx={{ ml: 'auto' }}>
            <Switch color="primary" checked={expanded} />
          </Box>
        )}
      </AccordionSummary>

      {showAccordianDetails && (
        <AccordionDetails sx={{ ...(noPad && { p: 0 }) }}>
          {content}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default VegaAccordian;
