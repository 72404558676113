import React, { useEffect, useState } from 'react';
import { fetchMCCProgramList } from '../../../mcc/Program-Dashboard/api/Program';
import { useClientAuth } from '../../../providers/ClientProvider';
import VegaSelect from '../VegaSelect';
import VegaSelectV2 from './VegaSelectV2';

interface IProps {
  selected: any;
  handleChange: (value: any) => void;
  name: string;
}

const VegaProgramSelectV2 = ({ name, selected, handleChange }: IProps) => {
  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [programsList, setProgram] = useState<any[]>([]);

  const loadMoreItems = (event: any) => {
    console.log('event', event);
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== programsList.length) {
        setPage(page + 1);
      }
    }
  };

  const getProgramList = async () => {
    fetchMCCProgramList(clientId as string, page, 10).then((res) => {
      setProgram([...programsList, ...res.data.records]);
      setTotalItem(res.data.totalItems);
    });
  };

  useEffect(() => {
    getProgramList();
  }, [page]);

  return (
    <>
      <VegaSelectV2
        fullWidth
        placeholder="Program Name"
        size="small"
        key={name}
        value={selected ?? ''}
        onChange={(value) => handleChange(value.target.value)}
        options={programsList.map((program: any) => ({
          id: program.programId,
          value: program.programId,
          label: program.name,
        }))}
        onScroll={loadMoreItems}
      />
    </>
  );
};

export default VegaProgramSelectV2;
