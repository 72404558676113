import React, { useState } from 'react';
import Dialog from '../../primitives/Dialog';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { ListGroupHeadingTypo } from '../../common/Typography';
import { TransactionIndicator } from '../../../enums/transaction';

interface IClientAdjustmentDialogProps {
  open: boolean;
  setOpenFalse: () => void;
}

const ClientAdjustmentDialog = ({
  open,
  setOpenFalse,
}: IClientAdjustmentDialogProps) => {
  const [valueType, setvalueType] = useState(TransactionIndicator.DEBIT);
  const handleRadioSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setvalueType(e.target.value as TransactionIndicator);
  };

  return (
    <Dialog
      open={open}
      title="Client Adjustment Request"
      onClose={setOpenFalse}
      actionBtnSecondaryText={'Submit'}
      closeBtnRequired
      actionBtnSecondaryProps={{
        onClick: setOpenFalse,
      }}
    >
      <Stack padding={'20px 40px'} gap={4}>
        <Stack gap={1}>
          <ListGroupHeadingTypo>Adjustment on -</ListGroupHeadingTypo>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={valueType}
              name="radio-buttons-group"
              onChange={handleRadioSelect}
            >
              <FormControlLabel
                value={TransactionIndicator.DEBIT}
                control={
                  <Radio
                    size="small"
                    sx={{
                      '&:not(.Mui-checked)': {
                        color: 'gray',
                      },
                      padding: '4px 9px',
                    }}
                  />
                }
                label="Debit"
                sx={{ span: { fontSize: '13px' } }}
              />
              <FormControlLabel
                value={TransactionIndicator.CREDIT}
                control={
                  <Radio
                    size="small"
                    sx={{
                      '&:not(.Mui-checked)': {
                        color: 'darkgray',
                      },
                      padding: '4px 9px',
                    }}
                  />
                }
                label="Credit"
                sx={{ span: { fontSize: '13px' } }}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Box>
          <TextField
            id="outlined-basic"
            label="Value"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />
        </Box>
      </Stack>
    </Dialog>
  );
};

export default ClientAdjustmentDialog;
