import { Stack, StackProps } from '@mui/material';
import VegaTextV2 from '../../components/common/v2/VegaTextV2';
import { NEUTRAL_COLORS } from '../theme';

interface IProps extends StackProps {
  label: string;
  value: string;
  orientation?: 'horizontal' | 'vertical';
}
function LableValueDisplay({
  label,
  value,
  orientation = 'horizontal',
  ...rest
}: IProps) {
  return (
    <Stack
      direction={orientation === 'horizontal' ? 'row' : 'column'}
      spacing={0.5}
      {...rest}
      margin={0}
    >
      <VegaTextV2
        text={label}
        fontWeight={500}
        fontSize={13}
        color={NEUTRAL_COLORS.TEXT[200]}
      />
      <VegaTextV2
        text={value}
        fontWeight={600}
        fontSize={13}
        color={'rgba(34, 34, 34, 1)'}
      />
    </Stack>
  );
}

export default LableValueDisplay;
