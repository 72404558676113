import { useQuery } from 'react-query';
import { listForClientWithMetaData } from '../../../../../../api/program';

export const usePrograms = (request: {
  clientId: string;
  page: number;
  pageSize: number;
  status?: string;
  type?: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['programs', request.page],

    queryFn: async () => await listForClientWithMetaData(request),
    keepPreviousData: false,
  });

  return { ...data, ...rest };
};
