/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useMediaQuery, useTheme } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSnackbar } from '../../providers/SnackbarProvider';
import Vega_Button from '../../components/common/v3/Vega_Button';
import Vega_TextField from '../../components/common/v3/Vega_TextField';

type ForgotPasswordProps = {
  changePageHandler: (state: string) => void;
  setActiveUserEmail: Dispatch<SetStateAction<string>>;
};

function ForgotPassword({
  changePageHandler,
  setActiveUserEmail,
}: ForgotPasswordProps) {
  const { setSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isDisabled = !email.length || !isValidEmail;

  const resendCode = () => {
    if (email) {
      Auth.forgotPassword(email)
        .then((user) => {
          setActiveUserEmail(email);
          changePageHandler('NEW_PASSWORD');
          setSnackbar(`Code send to ${email}`);
        })
        .catch((error) => {
          setSnackbar(`${error.message}`, 'error');
          console.error('Cannot login', error);
          changePageHandler('LOGIN');
        });
    } else {
      changePageHandler('LOGIN');
    }
  };

  const onEmailChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resendCode();
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <Button
            startIcon={<ArrowBackIos />}
            size="small"
            onClick={() => changePageHandler('LOGIN')}
            sx={{
              '&.MuiButton-startIcon': {
                padding: 1,
              },
              '&.MuiButton-text': {
                fontSize: 14,
                textTransform: 'capitalize',
              },
            }}
          >
            Back
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '330px' : '400px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography fontSize={17} className="font-aspekta-600">
            Reset your password
          </Typography>
          <Box my={3} component={'form'} onSubmit={onFormSubmit}>
            <Box mb={2}>
              <Typography
                fontSize={12}
                textTransform="uppercase"
                className="font-aspekta-700"
                color="#676B76"
                paddingBottom={1}
              >
                Email ID
              </Typography>
              <Vega_TextField
                type="email"
                key={'login-email'}
                value={email}
                onChange={onEmailChange}
                placeholder="Enter email address"
              />
            </Box>

            <Stack spacing={2}>
              <Vega_Button
                type="submit"
                text="Submit"
                disabled={isDisabled}
                sx={{
                  paddingY: 1.2,
                  fontSize: 14,
                  lineHeight: '130%',
                  ':disabled': {
                    bgcolor: '#bbbbbb',
                    opacity: 1,
                  },
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ForgotPassword;
