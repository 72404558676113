/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { ArrowBackIos } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import usePassword from '../../hooks/usePassword';
import { useSnackbar } from '../../providers/SnackbarProvider';
import Vega_Button from '../../components/common/v3/Vega_Button';
import Vega_TextField from '../../components/common/v3/Vega_TextField';

type NewPasswordProps = {
  changePageHandler: (state: string) => void;
  email: string;
};

function NewPassword({ changePageHandler, email }: NewPasswordProps) {
  const { password, setPassword, renderValidations, isValidPassword } =
    usePassword();
  const { setSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [code, setCode] = useState<string>('');

  const isDisabled = !email || !isValidPassword();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Auth.forgotPasswordSubmit(email, code, password)
      .then((res) => {
        changePageHandler('LOGIN');
      })
      .catch((err) => {
        setSnackbar(err.message, 'error');
      });
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <Button
            startIcon={<ArrowBackIos />}
            size="small"
            onClick={() => changePageHandler('LOGIN')}
            sx={{
              '&.MuiButton-startIcon': {
                padding: 1,
              },
              '&.MuiButton-text': {
                fontSize: 14,
                textTransform: 'capitalize',
              },
            }}
          >
            Back
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '330px' : '400px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography fontSize={17} className="font-aspekta-600">
            Enter your new password
          </Typography>

          <Box my={3} component={'form'} onSubmit={onSubmit}>
            <Box mb={2}>
              <Typography
                fontSize={12}
                textTransform="uppercase"
                className="font-aspekta-700"
                color="#676B76"
                paddingBottom={1}
              >
                Code
              </Typography>
              <Vega_TextField
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Box>
            <Box mb={2}>
              <Typography
                fontSize={12}
                textTransform="uppercase"
                className="font-aspekta-700"
                color="#676B76"
                paddingBottom={1}
              >
                Password
              </Typography>
              <Vega_TextField
                type={'password'}
                key={'login-pass'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </Box>
            <Box mt={1} maxWidth={400}>
              {renderValidations()}
            </Box>
            <Stack spacing={2} paddingTop={3}>
              <Vega_Button
                type="submit"
                text="Continue"
                disabled={isDisabled}
                sx={{
                  paddingY: 1.2,
                  fontSize: 14,
                  lineHeight: '130%',
                  ':disabled': {
                    bgcolor: '#bbbbbb',
                    opacity: 1,
                  },
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NewPassword;
