/* eslint-disable react/jsx-pascal-case */
import { useAuth } from 'react-oidc-context';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useClientAuth } from '../../../providers/ClientProvider';
import { CIMS_PRIMARY } from '../../assets/style';
import CIMS_Button from '../common/CIMS_Button';
import CIMS_TextField from '../common/CIMS_TextField';

interface IProps {
  forgotPasswordHandler: (state: string) => void;
}

const LoginContainer = ({ forgotPasswordHandler }: IProps) => {
  const { login } = useClientAuth();
  const auth = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const isDisabled = () => {
    if (auth.isLoading) return true;

    if (isValidEmail) {
      if (!email.length) {
        return true;
      } else if (!password.length) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!email.length) {
      setIsValidEmail(false);
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          maxWidth: isMobile ? '330px' : '464px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            border: `2px solid ${CIMS_PRIMARY.GOLD}`,
            padding: 4,
            borderRadius: 3,
          }}
        >
          <Typography
            fontSize={17}
            className="font-aspekta-600"
            color={CIMS_PRIMARY.BLACK}
          >
            Login with your email ID
          </Typography>
          <Box
            my={3}
            component={'form'}
            onSubmit={(e) => {
              e.preventDefault();
              // dispatch(setIsAuth(true));
              login(e, email, password);
            }}
          >
            <Box mb={2}>
              <CIMS_TextField
                header="email id"
                type="email"
                error={email.length > 0 ? !isValidEmail : undefined}
                value={email}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setEmail(inputValue);

                  setIsValidEmail(validateEmail(inputValue));
                }}
              />
            </Box>
            <Box mb={3}>
              <CIMS_TextField
                header="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                value={password}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setPassword(inputValue);
                }}
              />
              <Box
                sx={{
                  textAlign: 'end',
                }}
              >
                <Typography
                  fontSize={12}
                  className="font-aspekta-600"
                  sx={{
                    color: CIMS_PRIMARY.BLUE,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  component={'span'}
                  onClick={() => forgotPasswordHandler('FORGOT')}
                  textAlign={'center'}
                >
                  Forgot Password ?
                </Typography>
              </Box>
            </Box>
            <Stack spacing={2}>
              <CIMS_Button
                type="submit"
                text="Login"
                sx={{
                  bgcolor: CIMS_PRIMARY.MAIN,
                  ':hover': {
                    bgcolor: CIMS_PRIMARY.GOLD,
                    color: CIMS_PRIMARY.MAIN,
                  },
                }}
                disabled={isDisabled()}
              />
              <Typography
                fontSize={14}
                className="font-aspekta-600"
                textAlign={'center'}
              >
                OR
              </Typography>

              <CIMS_Button
                type="button"
                onClick={() => {
                  auth.signinRedirect();
                }}
                text="Login with SSO"
                sx={{
                  bgcolor: CIMS_PRIMARY.MAIN,
                  ':hover': {
                    bgcolor: CIMS_PRIMARY.GOLD,
                    color: CIMS_PRIMARY.MAIN,
                  },
                }}
                disabled={auth.isLoading}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginContainer;
