import { IPartner } from '../../../../../types/partner';
import { VegaUser } from '../../../../../types/user';
import { ACCESS_FEATURES } from '../../../../role';
import {
  IAddUserRequest,
  UserPlatformEnums,
  UserTeamType,
  addRole,
  addRoleForUser,
  addUser,
} from '../../../api/roles';

export const getAccessFeaturesForPartner = (partnerId: string) => {
  return ACCESS_FEATURES.PARTNER_HQ.map((feature: string) => ({
    entityRoles: [
      {
        entityId: partnerId,
        roles: ['EDIT'],
      },
    ],
    feature: feature,
  }));
};

export const addUserAndRoleForPartner = (data: {
  partner: IPartner;
  user: IAddUserRequest;
  currentUser?: VegaUser | null;
}) => {
  const userPromise = addUser({
    ...data.user,
    clientId: data.partner.clientId,
    platformId: data.partner.id,
    platformName: UserPlatformEnums.FOREX_HQ,
    mccUser: true,
    idType: 'MOBILE_NO',
    idNumber: data.user.mobileNumber,
    status: 'ACTIVE',
    team: UserTeamType.OPERATIONS,
  });

  const rolePromise = addRole({
    clientId: data.partner.clientId,
    roleName: data.partner.id + '_ADMIN',
    createdBy: data.currentUser?.name || '',
    status: 'ACTIVE',
    accessFeatures: getAccessFeaturesForPartner(data.partner.id),
  });

  return Promise.all([userPromise, rolePromise]).then((res) => {
    const user = res[0].data;
    const role = res[1].data;

    const roleForUser = {
      userId: user.userId,
      roleId: role.id,
      roleName: role?.roleName,
      checkerMakerFlag: true,
    };
    return addRoleForUser(roleForUser);
  });
};
