import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, modalClasses } from '@mui/material';
import { COMMON, PRIMARY } from '../../constants/style';
import VegaContainedButton from './VegaContainedButton';
import VegaTextButton from './VegaTextButton';

interface IModalProps {
  open: boolean;
  disabled?: boolean;
  isButton?: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  children: JSX.Element;
  switchComponent?: JSX.Element;
  header: string;
  switchText?: string;
  cancelText?: string;
  submitText?: string;
  width?: number;
  buttonIcon?: JSX.Element;
  zIndex?: number;
}

const style = {};

const VegaModal = ({
  open,
  handleClose,
  handleSubmit,
  children,
  header,
  switchText,
  submitText = 'Save',
  cancelText = '',
  switchComponent,
  buttonIcon,
  width = 500,
  disabled = false,
  isButton = true,
  zIndex,
}: IModalProps) => {
  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: zIndex,
          overflow: 'scroll',
          ['& .' + modalClasses.root]: {
            opacity: 0.8, // Set the desired opacity value here
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: { width },
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '10px',
            overflow: 'scroll',
            maxHeight: '90vh',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none', // Hide the scrollbar for Chrome, Safari, and Opera
            },
            '-ms-overflow-style': 'none', // Hide the scrollbar for Internet Explorer and Edge
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 1,
              // borderBottom: `2px solid ${GREY.grey}`,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <label
                className="font-aspekta-500"
                style={{
                  fontSize: 14,
                  color: COMMON.gray,
                  lineHeight: '15.6px',
                }}
              >
                {header}
              </label>
              {switchText?.length ? switchComponent : ''}
            </Box>
            <IconButton onClick={handleClose}>
              <Close sx={{ color: '#3A4A5F' }} />
            </IconButton>
          </Box>
          <Box py={1} px={1}>
            {children}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              px: 2,
              py: 1.5,
              // borderTop: `2px solid ${GREY.grey}`,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cancelText?.length ? (
                <VegaTextButton
                  text={cancelText}
                  className="font-aspekta-500"
                  color={PRIMARY.darkBlue}
                  onClick={handleClose}
                />
              ) : (
                ''
              )}
              {isButton && (
                <VegaContainedButton
                  text={submitText}
                  isPurple
                  className="font-aspekta-500"
                  py={0.7}
                  onClick={handleSubmit}
                  startIcon={buttonIcon ?? <></>}
                  disabled={disabled}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default VegaModal;
