/** @format */

import axios, { AxiosPromise } from 'axios';

import { GETWAY } from '../../Back-office/api/endpoints';
import { CORPORATE, CORPORATE_CONSOLIDATED, SEARCH } from './Endpoints';
import { API_BASE_URL } from '../../../api/axiosConfig';
import { ICorporateEntity } from '../../types/CorporateCustomer';

const CORPORATE_URL = `${API_BASE_URL}${GETWAY}${CORPORATE.BASE}`;
const CORPORATE_ACCOUNT_URL = `${API_BASE_URL}${GETWAY}${CORPORATE.ACCOUNT}`;

export const fetchMCCCorporatesList = (
  data: Record<string, string> | Object
) => {
  return axios.post(CORPORATE_URL + CORPORATE.LIST, data);
};
export const fetchMCCCorporatesListForAccount = (
  data: Record<string, string> | Object
) => {
  return axios.post(CORPORATE_ACCOUNT_URL + CORPORATE.LIST, data);
};

export const listCorporateAccounts = (data: any) => {
  return axios.post(`${API_BASE_URL}${GETWAY}${SEARCH.ACCOUNT}`, data);
};

export const fetchCorporateById = (
  corporateId: string
): AxiosPromise<ICorporateEntity> => {
  return axios.get(`${CORPORATE_URL}/` + corporateId);
};

export const fetchCorporateConsolidatedInfo = (accountId: string) => {
  return axios.post(`${CORPORATE_ACCOUNT_URL}${CORPORATE_CONSOLIDATED.ACCOUNT}/${accountId}`);
}
