import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { BLUE } from '../../constants/style';
import { VegaCard, VegaTable } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';

interface TransactionFromProps {}

enum TransactionFrmCheck {
  LIMIT,
  CUMMULATIVE_LIMIT,
  TRANSACTION,
  MCC_CODE,
  HIGH_RISK_MCG,
  OVERLIMIT_ON_MCC,
}

const HighlightedText = (data: { text: string }) => {
  return (
    <span
      style={{
        fontWeight: 900,
        color: BLUE.lighter,
        marginLeft: 4,
        marginRight: 4,
      }}
    >
      {data.text}
    </span>
  );
};

const OverlimitOnMcc = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
      The customer cannot consume Overlimit on MCC code from
      <HighlightedText text={'3000'} /> - <HighlightedText text={'3499'} />
    </div>
  );
};

const HightRiskMCG = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
      The customer cannot consume in a high risk MCG [from institution
      resources] more than
      <HighlightedText text={'20%'} />
      of the limit in a single transaction.
    </div>
  );
};

const MCCCodeCheck = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
      The customer cannot consume in the MCC code from
      <HighlightedText text={'3300'} />
      -
      <HighlightedText text={'3499'} />
      - more than
      <HighlightedText text={'50%'} />
      of the limits or
      <HighlightedText text={'50k'} />
      whichever is higher in a single transaction.
    </div>
  );
};

const TransactionLimit = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
      Customer cannot consume more than <HighlightedText text={'10%'} />
      the limit in the first
      <HighlightedText text={'24 hours'} />
      hours of activation in a single transaction.
    </div>
  );
};

const CummilativeLimit = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
      The customer cannot consume more than
      <HighlightedText text={'80%'} />
      of the cummulative limit in the first
      <HighlightedText text={'24 hours'} />
      of activation.
    </div>
  );
};

const TransactionCountLimit = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
      The customer cannot make more than
      <HighlightedText text={'10'} />[ this should not include charges, taxes
      etc] transactions in the first
      <HighlightedText text={'24 hours'} />
      hours of activation.
    </div>
  );
};

let columnDef = [
  {
    field: 'id',
    headerName: 'S No.',
  },
  {
    field: '',
    headerName: 'Condition/Statement',
    flex: 2,
    renderCell: (props: any) => {
      const type = props.row.type as TransactionFrmCheck;
      switch (type) {
        case TransactionFrmCheck.LIMIT:
          return <TransactionLimit />;
        case TransactionFrmCheck.CUMMULATIVE_LIMIT:
          return <CummilativeLimit />;
        case TransactionFrmCheck.TRANSACTION:
          return <TransactionCountLimit />;
        case TransactionFrmCheck.MCC_CODE:
          return <MCCCodeCheck />;
        case TransactionFrmCheck.HIGH_RISK_MCG:
          return <HightRiskMCG />;
        case TransactionFrmCheck.OVERLIMIT_ON_MCC:
          return <OverlimitOnMcc />;
      }
    },
  },
];

function TransactionFrm(props: TransactionFromProps) {
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        columnDef={columnDef}
        idColumn={'id'}
        data={[
          { id: 1, type: TransactionFrmCheck.LIMIT },
          { id: 2, type: TransactionFrmCheck.CUMMULATIVE_LIMIT },
          { id: 3, type: TransactionFrmCheck.TRANSACTION },
          { id: 4, type: TransactionFrmCheck.MCC_CODE },
          { id: 5, type: TransactionFrmCheck.HIGH_RISK_MCG },
          { id: 6, type: TransactionFrmCheck.OVERLIMIT_ON_MCC },
        ]}
      />
    </VegaCard>
  );
}

export default TransactionFrm;
