import axios, { AxiosResponse } from 'axios';
import { Mcg } from '../types/resources';
import { BASE_URL, RESOURCES } from './endpoints';

const RESOURCES_BASE_URL = BASE_URL + RESOURCES.BASE;

export const fetchListOfCountries = (page: number, pageSize: number) => {
  return axios.get(
    `${RESOURCES_BASE_URL}/country?page=${page}&pageSize=${pageSize}`
  );
};
export const fetchListOfTimeZone = (page: number, pageSize: number) => {
  return axios.get(
    `${RESOURCES_BASE_URL}/timezone?page=${page}&pageSize=${pageSize}`
  );
};
export const fetchListOfCurrency = (page: number, pageSize: number) => {
  return axios.get(
    `${RESOURCES_BASE_URL}/currency?page=${page}&pageSize=${pageSize}`
  );
};
export const fetchListOfPinCode = (page: number, pageSize: number) => {
  return axios.get(
    `${RESOURCES_BASE_URL}/pincode?page=${page}&pageSize=${pageSize}`
  );
};

export type IPincodeRes = {
  records: Array<{
    id: number;
    pincode: string;
    state: string;
    city: string;
    country: string;
  }>;
  pageNumber: number;
  numberOfItems: number;
  totalPages: number;
  totalItems: number;
};

export const fetchStateOnPincode = (
  pincode: string
): Promise<AxiosResponse<IPincodeRes, any>> => {
  return axios.get(`${RESOURCES_BASE_URL}/pincode?pincode=${pincode}`);
};
export const fetchListOfResources = (
  page: number,
  pageSize: number,
  URL: string,
  key?: string,
  value?: string
) => {
  return axios.get(
    `${RESOURCES_BASE_URL}/${URL}?page=${page}&pageSize=${pageSize}&${key}=${value}`
  );
};

export async function updateMcgRisk(data: {
  id: string;
  riskProfile: string;
}): Promise<Mcg> {
  try {
    const endpoint = RESOURCES_BASE_URL + RESOURCES.UPDATE_MCG;
    const response = await axios.put(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
