/** @format */

import axios from 'axios';
import { BASE_URL, GETWAY, BRANCH } from './endpoints';

const PROGRAM_URL = `${BASE_URL}${GETWAY}${BRANCH.BASE}`;

export const createBranch = (data: any) => {
  return axios.post(PROGRAM_URL, data);
};

export const fetchBranchById = (branchId: string) => {
  return axios.get(PROGRAM_URL + '/' + branchId);
};
export const updateBranch = (data: any) => {
  return axios.put(PROGRAM_URL, data);
};
export const updateBranchStatus = (data: any) => {
  return axios.patch(PROGRAM_URL + '/' + 'status', data);
};

export const fetchBranchList = (data: any) => {
  return axios.post(PROGRAM_URL + `/list`, data);
};
