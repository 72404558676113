import { CommentBankOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { EntityComment } from '../../types/entityAuth';
import { VegaCard } from '../common';

interface PolicyCommentCardProps {
  onPastCommentClick: () => void;
  comments?: EntityComment[];
  noMargin?: boolean;
}

function PolicyCommentCard({
  onPastCommentClick,
  comments,
  noMargin,
}: PolicyCommentCardProps) {
  const [latestComment, setLatestComment] = useState<EntityComment>();

  useEffect(() => {
    if (comments && comments.length > 0) {
      setLatestComment(comments[0]);
    } else {
      setLatestComment(undefined);
    }
  }, [comments]);

  return (
    <VegaCard
      noPad
      sx={{
        marginBottom: 4,
        ...(noMargin && { marginBottom: 0 }),
      }}
    >
      <div
        style={{
          padding: 20,
        }}
      >
        <Stack spacing={1}>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Stack direction={'row'} spacing={2} alignItems="center">
              <CommentBankOutlined fontSize="small" />
              <Typography
                style={{
                  fontSize: '.875rem',
                  fontWeight: 500,
                }}
              >
                New comments from checker
              </Typography>
            </Stack>
            <Typography
              variant="caption"
              style={{
                cursor: 'pointer',
                color: '#4785FF',
              }}
              onClick={() => {
                onPastCommentClick();
              }}
            >
              View past comments
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={2} alignItems="center">
            <Typography
              style={{
                fontSize: '.8125rem',
                fontWeight: 400,
              }}
            >
              {dayjs(latestComment?.createdAt).format('MMM D, YYYY')}
            </Typography>
            <Typography
              style={{
                fontSize: '.8125rem',
                fontWeight: 400,
              }}
            >
              {latestComment?.comments}
            </Typography>
          </Stack>
        </Stack>
      </div>
    </VegaCard>
  );
}

export default PolicyCommentCard;
