import axios from 'axios';
import { BASE_URL, CUSTOMER } from './endpoints';

const CUSTOMER_URL = `${BASE_URL}${CUSTOMER.BASE}`;

export const bulkUploadCustomers = (formData: any) => {
  return axios.post(`${CUSTOMER_URL}/bulk/process`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const bulkSaveCustomers = (data: any) => {
  return axios.post(`${CUSTOMER_URL}/bulk/save`, data);
};
export const fetchPastUploadForCustomers = (branchId: string) => {
  return axios.post(`${CUSTOMER_URL}/bulk/past/uploads/${branchId}`);
};
