/** @format */

import Agents from '../components/collections/Agents/Agents';
import LeadsByDPDbucket from '../components/collections/LeadByDPDbucket/LeadsByDPDbucket';
import Summary from '../components/collections/Summary/Summary';
import { VegaPage } from '../components/common';

export interface ICollectionProps {
  customerId: number;
  OBbalance: string;
  ODbalance: string;
  dpdBucket: string;
  dpd: string;
  risk: string;
  agentName: string;
}

const Collections = () => {
  return (
    <>
      <VegaPage>
        <LeadsByDPDbucket />
        <Agents />
        <Summary />
      </VegaPage>
    </>
  );
};

export default Collections;
