import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import {
  InterestChargeConfig,
  TxnToEmiPolicyInterface,
} from '../../../types/policy';
import ProgramDataGrid from './ProgramDataGrid';
import { useAppSelector } from '../../../store/hook';
import { convertCamelCase } from '../../../utils/stringUtils';

const getTransactionToEmiPolicyTableColumns =
  (): GridColDef<InterestChargeConfig>[] => [
    {
      field: 'duration_in_months',
      headerName: 'Duration in months',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
    {
      field: 'interest_rate',
      headerName: 'Interest Rate',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
  ];

function ViewTransactionToEmiPolicy() {
  const columnsRef = useMemo(() => getTransactionToEmiPolicyTableColumns(), []);
  const activePolicy = useAppSelector(
    (state) => state.programManagement.activePolicy
  ) as TxnToEmiPolicyInterface;

  const { installment_policy } = activePolicy;

  return (
    <Box
      sx={{
        p: {
          xs: 1,
          sm: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {installment_policy?.map((policy, index) => (
        <Box
          key={index}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4}>
              <DataTile
                title="Minimum Amount"
                value={`${activePolicy.minimum_amount ?? '-'}`}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DataTile title="MCC" value={policy?.mcc?.join(',') ?? '-'} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DataTile
                title="Amortization Type"
                value={policy?.amortizationType ?? '-'}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DataTile
                title="Interest Type"
                value={
                  policy?.interestType
                    ? convertCamelCase(policy.interestType)
                    : '-'
                }
              />
            </Grid>
          </Grid>
          <ProgramDataGrid
            data={policy.interestChargeConfig ?? []}
            columns={columnsRef}
            idColumn={'policy_id'}
            getRowId={(row: any) =>
              `${row.duration_in_months}-${row.interest_rate}`
            }
          />
        </Box>
      ))}
    </Box>
  );
}

export default ViewTransactionToEmiPolicy;
