/** @format */

import { Typography } from '@mui/material';
import { GREY } from '../../constants/style';

interface ITextProps {
  mb?: number;
  mt?: number;
  my?: number;
  fw?: string | number;
  mx?: number;
  mr?: number;
  ml?: number;
  text?: string;
  fs?: string;
}

const VegaText = ({
  mb,
  mt,
  my,
  mx,
  ml,
  mr,
  fw,
  text,
  fs = '18px',
}: ITextProps) => {
  return (
    <Typography
      my={my}
      mb={mb}
      mt={mt}
      mx={mx}
      mr={mr}
      ml={ml}
      sx={{ fontWeight: fw, fontSize: fs, color: GREY.darkest }}
    >
      {text}
    </Typography>
  );
};

export default VegaText;
