/** @format */

import { Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getAccount } from '../../api/account';
import { DATE_FORMATE } from '../../constants/url';
import { useCustomerAccountData } from '../../providers/CustomerDataProvider';
import { Loading, NoResults, VegaCard, VegaTable } from '../common';
import VegaDrawer from '../common/VegaDrawer';
import ViewAccount from '../customers/ViewAccount';
import ViewCustomer from '../customers/ViewCustomer';

type Props = {};

const ListOfAccounts = (props: Props) => {
  const { accounts, loading } = useCustomerAccountData();

  const [showCustomer, setShowCustomer] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [customerAccountDisplayData, setCustomerAccountDisplayData] =
    useState<any>({});
  const [loadingAccount, setLoadingAccount] = useState(false);

  if (loading) {
    return <Loading />;
  }

  if (!accounts || accounts.length === 0) {
    return (
      <NoResults text="You have no Accounts" subtext="Create new account?" />
    );
  }

  const columnDef = [
    {
      field: 'firstName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Name
        </Typography>
      ),

      flex: 1,
    },
    {
      field: 'accountLimit',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Account Limit
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.account && props.row.account?.accountLimit}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'cashLimit',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Cash Limit
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.account && props.row.account?.cashLimit}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'overlimit',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Over Limit
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.account && props.row.account?.overlimit}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'gracePeriod',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Grace Day
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.account && props.row.account?.gracePeriod}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'paymentDeviation',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Payment Deviation
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.account && `${props.row.account?.paymentDeviation} %`}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Created At
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.account &&
              dayjs(props.row.account?.createdAt).format(DATE_FORMATE)}
          </div>
        );
      },
      flex: 1,
    },

    {
      field: 'other',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Details
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Button
            color="info"
            variant="text"
            onClick={() => handleViewCustomerClick(props.row)}
          >
            View Customer
          </Button>
        );
      },
      flex: 1,
    },
    {
      field: 'account',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Details
        </Typography>
      ),
      renderCell: (props: any) => {
        return props.row.account === null ? (
          <Button
            color="info"
            variant="text"
            // onClick={() => createAccount(props.row)}
          >
            Create Account
          </Button>
        ) : (
          <Button
            color="info"
            variant="text"
            onClick={() => handleViewAccountClick(props.row)}
          >
            View Account
          </Button>
        );
      },
      flex: 1,
    },
  ];

  const handleViewCustomerClick = (data: any) => {
    setShowCustomer(true);
    setCustomerAccountDisplayData(data);
  };
  const handleViewAccountClick = (data: any) => {
    const accountId = data?.account?.accountId;
    if (accountId) {
      setLoadingAccount(true);
      setShowAccount(true);
      getAccount(accountId)
        .then((res) => {
          console.log('Account response', res.data);
          const account = res.data;
          setCustomerAccountDisplayData({ account: account });
        })
        .catch((err) => {
          console.log('Error', err);
        })
        .finally(() => {
          setLoadingAccount(false);
        });
    }
  };
  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable
          data={accounts}
          columnDef={columnDef}
          idColumn={'customerId'}
        />
      </VegaCard>
      <VegaDrawer
        open={showCustomer}
        onClose={() => setShowCustomer(false)}
        anchor="right"
        isSubmit={true}
        isClose={true}
        onSubmit={() => setShowCustomer(false)}
        submitText="Got It!"
        closeText="Close"
        header="Customer Details"
      >
        <ViewCustomer
          showCustomerData={{ ...customerAccountDisplayData, account: null }}
        />
      </VegaDrawer>
      <VegaDrawer
        open={showAccount}
        onClose={() => setShowAccount(false)}
        anchor="right"
        isSubmit={true}
        onSubmit={() => setShowAccount(false)}
        submitText="Got It!"
        header="Account Details"
      >
        <ViewAccount
          showCustomerData={customerAccountDisplayData.account}
          loading={loadingAccount}
        />
      </VegaDrawer>
    </>
  );
};

export default ListOfAccounts;
