import { useEffect, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useForm } from 'react-hook-form';
import {
  ICorporateAccountWalletDataProps,
  ICreateCorporateCustomer,
} from '../../types/CorporateCustomer';

import { useClientAuth } from '../../../providers/ClientProvider';

import { fetchStateOnPincode } from '../../../api/resources';
import {
  createCorporateEntityForMCC,
  fetchAccountListForCorporate,
} from '../api/corporateEntity';
import AddCorporateCustomer from '../components/MCCCorportateManagement/AddCorporateCustomer';
import ListOfMCCCorporateCustomer from '../components/MCCCorportateManagement/ListOfMCCCorporatecustomer';
import SubmitFormWrapper from '../components/SubmitFormWrapper';
import { nameValidation } from '../../../constants/Validations';

const INITIAL_USER_DATA: ICreateCorporateCustomer = {
  clientId: '',
  corporateName: '',
  mobileNumber: '',
  emailId: '',
  address: '',
  pinCode: '',
  state: '',
  city: '',
  cardNetwork: '',
  gstNumber: '',
  gstFile: null,
  balanceSheetFile: null,
  accountLimitType: 'PREPAID',
  accountLimit: '',
  // .split(' ')[0]
  billingCycle: '',

  // declaration: '',
};

export const NETWORK_OPTIONS = [
  'RUPAY',
  'VISA',
  'MASTERCARD',
  'AMERICAN_EXPRESS',
  'MAESTRO',
  'UNION_PAY',
];
export const BILLING_OPTIONS = [3, 5, 7, 15, 30];

const MCCCorporateManagement = () => {
  const { clientId, mccPlatformId: branchId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [corporateEntities, setCorporateEntities] = useState<
    ICorporateAccountWalletDataProps[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [customerData, setCustomerData] = useState<ICreateCorporateCustomer>({
    ...INITIAL_USER_DATA,
  });

  const hookForm = useForm<ICreateCorporateCustomer>({});

  const openAddCorporate = () => {
    setOpen(!open);
    onClear();
  };

  const handleChange = (
    key: keyof ICreateCorporateCustomer,
    value: string | File | null
  ) => {
    setCustomerData({ ...customerData, [key]: value });
  };

  const onClear = () => {
    setCustomerData({ ...INITIAL_USER_DATA });
  };

  const onAddUser = (data: ICreateCorporateCustomer) => {
    const validation = nameValidation(data.corporateName, 'corporateName');
    setError(validation.error);
    setIsLoading(true);
    const formData = new FormData();

    const newObj: any = {
      ...data,
      clientId,
      // accountLimitType: customerData.accountLimitType.toUpperCase(),
      branchId: branchId,
      countryCode: 'IN',
      customerType: 'CORPORATE_CUSTOMER',
      // .split(' ')[0]
      // billingCycle: customerData.billingCycle.split(' ')[0],
    };
    // delete newObj['accountLimitType'];
    // delete newObj['accountLimit'];
    // delete newObj['billingCycle'];

    Object.keys(newObj).forEach((value: any) => {
      newObj[value] && formData.append(value, newObj[value]);
    });

    createCorporateEntityForMCC(formData)
      .then(async (res) => {
        setSnackbar('Customer added!');
        setIsLoading(false);
        // getCustomerList();
        onClear();
        getCorporateEntitiesList();
        openAddCorporate();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add customer'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const getCorporateEntitiesList = async () => {
    setListLoading(true);
    fetchAccountListForCorporate({ branchId })
      .then((res) => {
        const corporateList = res.data as ICorporateAccountWalletDataProps[];
        const newCorporateList = corporateList.map(
          (corporateData: ICorporateAccountWalletDataProps) => ({
            ...corporateData,
            id: corporateData.corporate?.id,
          })
        );
        setCorporateEntities(newCorporateList);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getCorporateEntitiesList();
  }, []);

  useEffect(() => {
    if (customerData.pinCode) {
      const getListAll = setTimeout(() => {
        fetchStateOnPincode(customerData.pinCode)
          .then((res) => {
            const stateList = res.data?.records[0]?.state;
            const cityList = res.data?.records[0]?.city;
            setCustomerData({
              ...customerData,
              state: stateList,
              city: cityList,
            });
          })
          .catch((err) => {
            setSnackbar(getErrorMessageFromErrorObj(err), 'error');
          });
      }, 1000);
      return () => clearTimeout(getListAll);
    }
  }, [customerData.pinCode]);

  return (
    <>
      <VegaPage>
        <VegaCard noPad>
          <SubmitFormWrapper
            titleText="Add Corporate Profile"
            submitText="Add Corporate"
            cancelText="Clear"
            onSubmit={hookForm.handleSubmit(onAddUser)}
            onClear={hookForm.reset}
            toggle={openAddCorporate}
            isOpen={open}
            loading={isLoading}
            disabled={false}
          >
            <AddCorporateCustomer
              state={customerData}
              handleChange={handleChange}
              hookForm={hookForm}
              error={error}
            />
          </SubmitFormWrapper>
        </VegaCard>
        <ListOfMCCCorporateCustomer
          data={corporateEntities}
          loading={listLoading}
          getCorporateEntitiesList={getCorporateEntitiesList}
        />
      </VegaPage>
    </>
  );
};

export default MCCCorporateManagement;
