import { AddCircleOutline } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { GREY, PRIMARY } from '../../../constants/style';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { useClientAuth } from '../../../providers/ClientProvider';
import { BinCharterRoute } from '../../../routes';
import { IProgramDtoProps } from '../../../types/binType';
import { VegaNewBin } from '../../../types/client';
import { VegaProgramType } from '../../../types/program';
import VegaTextButton from '../../common/VegaTextButton';
import VegaDataGridV2 from '../../common/v2/VegaDataGridV2';
import { getLogoForNetwork } from '../../icon/Icons';

type Props = {
  bins: VegaNewBin[];
  programs: VegaProgramType[];
  onAddClick: (id: string) => void;
  page: number;
  size: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
};

const ListOfBinCharter = () => {
  const {
    page,
    size,
    rowCount,
    binList,
    listLoading,
    navigateTo,
    openAddProgram,
    onPageChange,
    onPageSizeChange,
    getListBinForClient,
    setIsBinDetails,
    setSelectedBinId,
    getFormFactorIdForClient,
  } = useBinCharter();
  const { clientId } = useClientAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (clientId) {
      getFormFactorIdForClient(clientId);
    }
  }, [clientId]);

  const getBinList = async (clientId: string) => {
    setLoading(true);
    await getListBinForClient(clientId);
    setLoading(false);
  };
  useEffect(() => {
    if (clientId) {
      getBinList(clientId);
    }
  }, [clientId, page, size]);

  const onViewDetails = (id: any) => {
    setSelectedBinId(id);
    // setIsBinDetails(true);
    navigateTo(BinCharterRoute.BIN_DETAILS);
  };

  const columnRef: GridColumns = [
    {
      field: 'binName',
      headerName: 'Bin Name',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'binValue',
      headerName: 'Bin value',
      flex: 0.5,
      minWidth: 80,
    },
    {
      field: 'binType',
      headerName: 'Bin type',
      flex: 0.6,
      minWidth: 100,

      renderCell: (props: any) => {
        return (
          <Typography className="font-aspekta-500" fontSize={14}>
            {props.row.binType && _.startCase(_.toLower(props.row.binType))}
          </Typography>
        );
      },
    },
    {
      field: 'network',
      headerName: 'Network',
      flex: 0.5,
      minWidth: 80,

      renderCell: (props: any) => {
        const network = props.row.network;
        if (network) {
          return <div>{getLogoForNetwork(network)}</div>;
        }
        return <>-</>;
      },
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 1,
      minWidth: 100,

      renderCell: (props: any) => {
        const programList = props.row.programName as IProgramDtoProps[];
        if (!programList.length) return '--';
        return (
          <Stack direction={'row'} gap={0.5} sx={{ flexWrap: 'wrap' }}>
            {renderProgramList(programList)}
          </Stack>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 100,
      cellClassName: 'autoHeight',
      renderCell: (props: any) => {
        return props.row.binType === 'SHARED' ? (
          <VegaTextButton
            text="Add Program"
            onClick={() => openAddProgram(props.row)}
            color={PRIMARY.darkBlue}
            startIcon={<AddCircleOutline />}
          />
        ) : !props.row?.programName.length ? (
          <VegaTextButton
            text="Add Program"
            onClick={() => openAddProgram(props.row)}
            color={PRIMARY.darkBlue}
            startIcon={<AddCircleOutline />}
          />
        ) : (
          ''
        );
      },
    },
  ];

  return (
    <VegaDataGridV2
      bgColor="#FFFFF"
      columns={columnRef}
      data={binList}
      idColumn={'id'}
      paginationMode={'server'}
      page={page}
      pageSize={size}
      classes={{ row: 'customRow' }}
      rowCount={rowCount}
      loading={listLoading}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onCellClick={(params: GridCellParams<any>) => {
        if (params.field !== 'action') {
          onViewDetails(params.id);
        }
      }}
    />
  );
};

export default ListOfBinCharter;

const tooltipTextComponent = (title: string, value: string) => {
  return (
    <Tooltip title={title}>
      <Stack direction={'row'} gap={0.5} mr={1} alignItems={'center'}>
        <Box
          sx={{
            bgcolor: GREY.dark,
            width: '6px',
            height: '6px',
            borderRadius: '50%',
          }}
        ></Box>
        <Typography
          className="font-aspekta-500"
          fontSize={14}
          sx={{
            cursor: 'pointer',
          }}
        >
          {value}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

const renderProgramList = (programDto: any[]) => {
  if (programDto.length <= 2) {
    return programDto.map((program: any) =>
      tooltipTextComponent(program.id, program.programDto?.name)
    );
  } else {
    const newArr = programDto
      .filter((program: any) => programDto[0].id !== program.id)
      .filter((program: any) => programDto[1].id !== program.id);
    return (
      <>
        {tooltipTextComponent(programDto[0].id, programDto[0].programDto?.name)}
        {tooltipTextComponent(programDto[1].id, programDto[1].programDto?.name)}

        <Typography className="font-aspekta-500" fontSize={14}>
          +{newArr.length}
        </Typography>
      </>
    );
  }
};
