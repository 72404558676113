import React from 'react';
import { VegaCard } from '../../../../../components/common';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import {
  BLUE,
  BORDER_BLUE,
  BORDER_GRAY,
  GREEN,
  GREY,
  ORANGE,
  PURPLE,
  YELLOW,
} from '../../../../../constants/style';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { AttachMoney, Percent } from '@mui/icons-material';
import { IPaginationProps } from '../../../pages/MCCPolicies';

export const getValue = (
  isAmount = false,
  value: any,
  isPercentage = false
) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: BLUE.light,
        color: BLUE.lighter,
        px: 0.5,
        mx: 0.3,
        width: 'fit-content',
        borderRadius: '5px',
      }}
    >
      {isAmount && <AttachMoney sx={{ fontSize: '16px' }} />}{' '}
      <Typography>{value}</Typography>{' '}
      {isPercentage && <Percent sx={{ fontSize: '16px' }} />}
    </Box>
  );
};

interface IProps extends IPaginationProps {
  data: any;
}

const ListOfMarkupFees = ({ data, page, count, onPageChange }: IProps) => {
  console.log('data?.markup_fee_component_list', data.length - 1);
  return (
    <VegaCard fullHeight noPad>
      <Grid container>
        <Grid
          item
          sm={2}
          sx={{
            p: 2,
            borderRight: BORDER_GRAY,
          }}
        ></Grid>
        <Grid
          item
          sm={2}
          sx={{
            p: 2,
            borderRight: BORDER_GRAY,
          }}
        ></Grid>
        <Grid item sm={4} sx={{ p: 2, borderRight: BORDER_GRAY }}>
          Fees
        </Grid>
        <Grid item sm={4} sx={{ p: 2 }}>
          Waiver
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          sm={2}
          sx={{
            p: 2,
            borderRight: BORDER_GRAY,
            bgcolor: YELLOW.lightest,
          }}
        >
          Plan Name
        </Grid>
        <Grid
          item
          sm={2}
          sx={{
            // flex: 0.3,
            // minWidth: '130px',
            // width: '180px',
            p: 2,
            borderRight: BORDER_GRAY,
            bgcolor: ORANGE.lightest,
          }}
        >
          Mode
        </Grid>
        <Grid
          item
          container
          sm={4}
          sx={{ p: 2, borderRight: BORDER_GRAY, bgcolor: GREEN.lightest }}
        >
          <Grid item sm={6}>
            Value Type
          </Grid>
          <Grid item sm={6}>
            Value
          </Grid>
        </Grid>
        <Grid item sm={4} sx={{ p: 2, bgcolor: PURPLE.lightest }}>
          No of Transcations
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={2}
          sx={{
            p: 2,
            borderRight: BORDER_GRAY,
          }}
        ></Grid>
        <Grid
          item
          sm={2}
          sx={{
            // flex: 0.3,
            // minWidth: '130px',
            // width: '180px',
            p: 2,
            borderRight: BORDER_GRAY,
          }}
        ></Grid>
        <Grid
          item
          sm={4}
          sx={{ p: 2, borderRight: BORDER_GRAY, borderBottom: BORDER_GRAY }}
        ></Grid>
        <Grid item container sm={4} sx={{ p: 2, borderBottom: BORDER_GRAY }}>
          <Grid item sm={4}>
            Waiver (%ge)
          </Grid>
          <Grid item sm={4}>
            Daily Limit
          </Grid>
          <Grid item sm={4}>
            Monthly Limit
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {data.map((value: any, index: number) => (
          <>
            <Grid
              item
              sm={2}
              sx={{
                px: 2,
                py: 0.5,
                borderRight: BORDER_GRAY,
                ...(index !== data.length - 1 && { borderBottom: BORDER_GRAY }),
              }}
            >
              <Typography>{value.policy_name ?? ''}</Typography>
            </Grid>
            <Grid item container sm={10}>
              {/* loop here */}
              {value?.markup_fee_component_list?.map(
                (markup: any, i: number) => (
                  <>
                    <Grid
                      item
                      sm={2.4}
                      sx={{
                        px: 2,
                        py: 0.5,
                        borderRight: BORDER_GRAY,
                        ...(i === value?.markup_fee_component_list.length - 1 &&
                          index !== data.length - 1 && {
                            borderBottom: BORDER_GRAY,
                          }),
                      }}
                    >
                      {' '}
                      {toLowerCase(markup.transactionCode)}
                    </Grid>
                    <Grid
                      item
                      container
                      sm={4.8}
                      sx={{
                        px: 2,
                        py: 0.5,
                        borderRight: BORDER_GRAY,
                        ...(i === value?.markup_fee_component_list.length - 1 &&
                          index !== data.length - 1 && {
                            borderBottom: BORDER_GRAY,
                          }),
                      }}
                    >
                      <Grid item sm={6}>
                        {toLowerCase(markup.fee.valueType)}
                      </Grid>
                      <Grid item sm={6}>
                        {getValue(
                          markup.fee.valueType === 'AMOUNT',
                          markup.fee.value,
                          markup.fee.valueType === 'PERCENTAGE'
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      sm={4.8}
                      sx={{
                        px: 2,
                        py: 0.5,
                        ...(i === value?.markup_fee_component_list.length - 1 &&
                          index !== data.length - 1 && {
                            borderBottom: BORDER_GRAY,
                          }),
                      }}
                    >
                      <Grid item sm={4}>
                        {markup.waiver.value}
                      </Grid>
                      <Grid item sm={4}>
                        {markup.waiver.dailyLimit}
                      </Grid>
                      <Grid item sm={4}>
                        {markup.waiver.monthlyLimit}
                      </Grid>
                    </Grid>
                  </>
                )
              )}
              {/* loop here */}
            </Grid>
          </>
        ))}
      </Grid>
      <Grid container>
        <Box
          sx={{ p: 2, width: '100%', display: 'flex', justifyContent: 'end' }}
        >
          <Pagination
            color="primary"
            page={page}
            count={count}
            onChange={onPageChange}
          />
        </Box>
      </Grid>
    </VegaCard>
  );
};

export default ListOfMarkupFees;
