import { Close } from '@mui/icons-material';
import { Dialog, Toolbar, IconButton, Box } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import {
  useVegaFullScreenDialog,
  VegaFullScreenDialogState,
} from '../../../providers/VegaFullScreenDialogProvider';
import VegaConditionalWrapper from '../VegaConditionalWrapper';

import VegaTextV2 from './VegaTextV2';

interface VegaFullScreenDialogProps {
  open: boolean;
  title?: string;
  onClose: () => void;
  children: ReactNode;
  footerActions?: ReactNode;
  renderTitle?: () => ReactNode;
}

function VegaFullScreenDialog({
  open,
  onClose,
  children,
  footerActions,
  title,
  renderTitle,
}: VegaFullScreenDialogProps) {
  const { onVisiblityChanged } = useVegaFullScreenDialog();

  useEffect(() => {
    onVisiblityChanged(
      open
        ? VegaFullScreenDialogState.Visible
        : VegaFullScreenDialogState.Hidden
    );
  }, [open]);

  return (
    <Dialog
      fullScreen
      aria-labelledby="customized-dialog-title"
      fullWidth
      open={open}
      onClose={onClose}
      sx={{}}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <Close />
        </IconButton>

        {renderTitle && renderTitle()}
        {renderTitle == null && <VegaTextV2 text={title} variant="h6" />}
      </Toolbar>
      <Box
        style={{
          backgroundColor: '#EBEFF4',
          height: '100%',
          overflow: 'scroll',
        }}
      >
        {children}
      </Box>
      {footerActions && (
        <Box
          style={{
            borderTop: '1px solid #E6E6E6',
            alignItems: 'center',
          }}
        >
          {footerActions}
        </Box>
      )}
    </Dialog>
  );
}

export default VegaFullScreenDialog;
