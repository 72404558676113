import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  getReportsByCategory,
  getReportsConfig,
} from '../../../../api/reports';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  IReportCategory,
  ReportConfigDto,
  ReportDto,
} from '../../../../types/reports';
import { convertCamelCase } from '../../../../utils/stringUtils';
import CimsAllReportConfigs from './CimsAllReportConfigs';

type IProps = {
  reportCategoriesMap: Record<string, any>;
  selectedCategory: IReportCategory;
};

export interface IReportConfigMap {
  id: string;
  reportDto: ReportDto;
  reportConfig: ReportConfigDto | null;
}

const CimsReportsTable = ({
  reportCategoriesMap,
  selectedCategory,
}: IProps) => {
  const [reports, setReports] = useState<ReportDto[]>([]);
  const [reportConfigMaps, setReportConfigMaps] = useState<
    Record<string, IReportConfigMap>
  >({});
  // const [selectedReport, setSelectedReport] = useState<null | IReport>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReportConfigMap, setSelectedReportConfigMap] =
    useState<IReportConfigMap | null>(null);
  const [showReportRuns, setShowReportRuns] = useState<boolean>(false);

  const { setSnackbar } = useSnackbar();
  const title = useMemo(() => {
    return selectedCategory.reportCategory;
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedReportConfigMap(null);
  }, [selectedCategory]);

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      const res = await getReportsByCategory(selectedCategory.categoryId);
      const reportsData = res.data as ReportDto[];
      let finalReportConfigs: Record<string, IReportConfigMap> = {};

      for (const report of reportsData) {
        try {
          const res2 = await getReportsConfig(report.reportId);
          const reportConfigRes = res2.data as ReportConfigDto[];

          const reportConfigs =
            reportConfigRes.length === 0
              ? {
                  [report.reportId]: {
                    id: report.reportId,
                    reportDto: report,
                    reportConfig: null,
                  },
                }
              : reportConfigRes.reduce((pre, curr, index) => {
                  return {
                    ...pre,
                    [report.reportId]: {
                      id:
                        report.reportId + '_' + (curr.reportConfigId || index),
                      reportDto: report,
                      reportConfig: curr,
                    },
                  };
                }, {} as Record<string, IReportConfigMap>);

          finalReportConfigs = { ...finalReportConfigs, ...reportConfigs };
          // setReportConfigMaps((pre) => ({
          //   ...pre,
          //   ...reportConfigs,
          // }));
        } catch (err) {
          setSnackbar(
            'Error in fetching the report config for - ' + report.reportId,
            'error'
          );
        }
      }
      setReportConfigMaps(finalReportConfigs);
      setReports(reportsData);
    } catch (err) {
      console.log('getReportsByCategory error', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [selectedCategory.categoryId, reportCategoriesMap]);
  return (
    <Box mt={4}>
      <Box my={1}>
        {title && (
          <Box display={'flex'} flexDirection="row">
            <Typography
              className="font-aspekta-600"
              fontSize={14}
              sx={{
                ':hover': selectedReportConfigMap && {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
              onClick={() => setSelectedReportConfigMap(null)}
            >
              {`${convertCamelCase(title)} Reports`}
            </Typography>
            <Typography variant="body2" fontWeight={'bold'}>
              {selectedReportConfigMap && showReportRuns && (
                <span style={{ padding: '0 8px' }}>
                  {`  >    ${convertCamelCase(
                    selectedReportConfigMap.reportDto?.reportName
                  )}`}
                </span>
              )}
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        <CimsAllReportConfigs
          isLoading={isLoading}
          reportConfigMaps={reportConfigMaps}
          setReportConfigMaps={setReportConfigMaps}
          selectedReportConfigMap={selectedReportConfigMap}
          setSelectedReportConfigMap={setSelectedReportConfigMap}
          showReportRuns={showReportRuns}
          setShowReportRuns={setShowReportRuns}
        />
      </Box>
    </Box>
  );
};

export default CimsReportsTable;
