import { Box, Checkbox, styled } from '@mui/material';

type Props = {
  url?: string;
  isSelected?: boolean;
  handleClick?: () => void;
};

const StyledCheckbox = styled(Checkbox)({
  color: 'white',
  '&.Mui-checked': {
    color: 'rgba(54, 145, 63, 1)',
  },
  borderRadius: '10rem',
});

function ImageSelectorItem({ url, isSelected, handleClick }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '6.25rem',
        width: '6.25rem',
        minHeight: '6.25rem',
        minWidth: '6.25rem',
        borderRadius: '.5rem',
        backgroundColor: '#f1f1f1',
        border: isSelected ? '2px solid #000000' : 'none',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
          height: '6.5rem',
          width: '6.5rem',
        },
      }}
      onClick={handleClick}
    >
      <img
        src={url}
        height={'80%'}
        width={'80%'}
        style={{ objectFit: 'contain' }}
      />
      <StyledCheckbox
        checked={isSelected}
        onChange={() => {}}
        style={{ position: 'absolute', bottom: '0px', right: '0px' }}
      />
    </Box>
  );
}

export default ImageSelectorItem;
