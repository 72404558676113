import React from 'react';
import { Box, Grid, Typography, Stack } from '@mui/material';
import { BORDER_GRAY, GREY } from '../../../../constants/style';
import { Description } from '@mui/icons-material';
import VegaContainedButton from '../../../common/VegaContainedButton';

interface IDocumentsProps {
  name: string;
  value: string;
}

const DocumentsDetails = () => {
  const Salary_Slip: IDocumentsProps[] = [
    {
      name: 'abc1.pdf',
      value: 'APKPC1234R',
    },
    {
      name: 'abc2.pdf',
      value: 'APKPC1234R',
    },
    {
      name: 'abc3.pdf',
      value: 'APKPC1234R',
    },
    {
      name: 'abc4.pdf',
      value: 'APKPC1234R',
    },
    {
      name: 'abc5.pdf',
      value: 'APKPC1234R',
    },
    {
      name: 'abc6.pdf',
      value: 'APKPC1234R',
    },
  ];
  return (
    <>
      <Box mb={3}>
        <Typography
          fontSize={14}
          className="font-opensans-700"
          color={GREY.darkest}
          mb={2}
        >
          Salary Slips
        </Typography>
        <Grid container spacing={2}>
          {Salary_Slip.map((slip: IDocumentsProps) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  border: BORDER_GRAY,
                  borderRadius: '9px',
                  position: 'relative',
                  p: 1,
                }}
              >
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <Description
                    sx={{
                      color: GREY.grey_light,
                      fontSize: 16,
                    }}
                  />

                  <Typography
                    className="font-opensans-400"
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    whiteSpace={'nowrap'}
                    fontSize={14}
                    width={'100px'}
                    color={GREY.grey_light}
                  >
                    {slip.name != null ? slip.name : '--'}
                  </Typography>
                </Stack>
                <VegaContainedButton text="Download" isPrimary />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box pb={7}>
        <Typography
          fontSize={14}
          className="font-opensans-700"
          color={GREY.darkest}
          mb={2}
        >
          Bank Statements
        </Typography>
        <Grid container spacing={2}>
          {Salary_Slip.map((slip: IDocumentsProps) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  border: BORDER_GRAY,
                  borderRadius: '9px',
                  position: 'relative',
                  p: 1,
                }}
              >
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <Description
                    sx={{
                      color: GREY.grey_light,
                      fontSize: 16,
                    }}
                  />

                  <Typography
                    className="font-opensans-400"
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    whiteSpace={'nowrap'}
                    fontSize={14}
                    width={'100px'}
                    color={GREY.grey_light}
                  >
                    {slip.name != null ? slip.name : '--'}
                  </Typography>
                </Stack>
                <VegaContainedButton text="Download" isPrimary />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default DocumentsDetails;
