export const SECOND_AND_FOURTH_SAT_HOLIDAYS_MOCK_DATA = [
  {
    date: "14 January 2023",
    name: "Second Saturday",
    description: "All",
    id: 0,
  },
  {
    date: "28 January 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 1,
  },
  {
    date: "11 February 2023",
    name: "Second Saturday",
    description: "All",
    id: 2,
  },
  {
    date: "25 February 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 3,
  },
  {
    date: "11 March 2023",
    name: "Second Saturday",
    description: "All",
    id: 4,
  },
  {
    date: "25 March 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 5,
  },
  {
    date: "8 April 2023",
    name: "Second Saturday",
    description: "All",
    id: 6,
  },
  {
    date: "22 April 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 7,
  },
  {
    date: "13 May 2023",
    name: "Second Saturday",
    description: "All",
    id: 8,
  },
  {
    date: "27 May 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 9,
  },
  {
    date: "10 June 2023",
    name: "Second Saturday",
    description: "All",
    id: 10,
  },
  {
    date: "24 June 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 11,
  },
  {
    date: "8 July 2023",
    name: "Second Saturday",
    description: "All",
    id: 12,
  },
  {
    date: "22 July 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 13,
  },
  {
    date: "12 August 2023",
    name: "Second Saturday",
    description: "All",
    id: 14,
  },
  {
    date: "26 August 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 15,
  },
  {
    date: "9 September 2023",
    name: "Second Saturday",
    description: "All",
    id: 16,
  },
  {
    date: "23 September 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 17,
  },
  {
    date: "14 October 2023",
    name: "Second Saturday",
    description: "All",
    id: 18,
  },
  {
    date: "28 October 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 19,
  },
  {
    date: "11 November 2023",
    name: "Second Saturday",
    description: "All",
    id: 20,
  },
  {
    date: "25 November 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 21,
  },
  {
    date: "9 December 2023",
    name: "Second Saturday",
    description: "All",
    id: 22,
  },
  {
    date: "23 December 2023",
    name: "Fourth Saturday",
    description: "All",
    id: 23,
  },
];
