import { AddCircleOutline } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { updateUserStatus } from '../../../../api/roleManagement';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaLabel from '../../../../components/common/VegaLabel';
import VegaModal from '../../../../components/common/VegaModal';
import VegaSelect from '../../../../components/common/VegaSelect';
import VegaSwitchV2 from '../../../../components/common/v2/VegaSwitchV2';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { VegaUser, UserRoleDto, VegaUserStatus } from '../../../../types/user';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import {
  UserPlatformEnums,
  UserRoleEnums,
} from '../../../Program-Dashboard/api/roles';
import BranchSelector from '../../../components/BranchSelector';
import CorporateSelector from '../../../components/CorporateSelector';
import { ICorporateEntity } from '../../../types/CorporateCustomer';
import {
  INITIAL_DATA,
  ITeamsProps,
  IUserRoleProps,
} from '../../pages/MCCTeamManagement';
import { VegaToolTipAndCopy } from '../../../../components/common/VegaToolTipNCopy';
import { convertCamelCase } from '../../../../utils/stringUtils';

interface IRowData extends VegaUser {
  userRoles: UserRoleDto[];
}
interface IProps {
  state: IRowData[];
  loading: boolean;
  onSendChecker: (data: any) => void;
  onUpdateSendChecker: (data: any) => void;
  allocateRoleForUser: (userId: string, platformId: string) => void;
  setRoleDetails: React.Dispatch<React.SetStateAction<IUserRoleProps>>;
  defaultBranchUser: IUserRoleProps;
  defaultCorporateAdmin: IUserRoleProps;
  refetchList: () => void;
  showAction?: boolean;
}

interface IAllocationRoleProps {
  roleType: string;
  platformId: string;
  platformName: string;
}

const ListOfTeamManagement = ({
  state,
  onSendChecker,
  onUpdateSendChecker,
  loading,
  setRoleDetails,
  defaultBranchUser,
  defaultCorporateAdmin,
  allocateRoleForUser,
  refetchList,
  showAction,
}: IProps) => {
  const { mccPlatformId } = useClientAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [openAllocateRole, setOpenAllocateRole] = useState<boolean>(false);
  const [allocation, setAllocation] = useState<IAllocationRoleProps>({
    platformId: '',
    roleType: '',
    platformName: UserPlatformEnums.FOREX_BRANCH,
  });
  const { setSnackbar } = useSnackbar();

  const [customerDetails, setCustomerDetails] = useState<ITeamsProps>({
    ...INITIAL_DATA,
  });

  const getButtons = (
    status: string,
    checkerMakerStatus: string,
    data: any
  ) => {
    if (status === 'DRAFT') {
      return {
        action: (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => onSendChecker(data)}
            isPrimary
          />
        ),
      };
    }
    if (status === 'UNDER_REVIEW' && checkerMakerStatus === 'CHECKER_PENDING') {
      return {
        action: (
          <VegaContainedButton text="sent to checker" isPrimary disabled />
        ),
      };
    }
    if (
      status === 'UNDER_REVIEW' &&
      checkerMakerStatus === 'CHANGES_REQUESTED'
    ) {
      return {
        action: (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => onUpdateSendChecker(data)}
            isPrimary
          />
        ),
      };
    }
    if (checkerMakerStatus === 'APPROVED') {
      return {
        action: (
          <VegaContainedButton
            text={toLowerCase(checkerMakerStatus)}
            isSuccess
            disabled
          />
        ),
      };
    }
  };
  const columnDef: GridColDef<IRowData>[] = [
    {
      field: 'name',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 1.5,
      minWidth: 150,
    },
    {
      field: 'userId',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          User ID
        </Typography>
      ),

      flex: 1.5,
      minWidth: 200,
    },
    {
      field: 'email',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Email
        </Typography>
      ),

      flex: 1.5,
      minWidth: 200,
      renderCell: (props) => {
        return <VegaToolTipAndCopy value={props.value} />;
      },
    },
    {
      field: 'mobileNo',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Mobile Number
        </Typography>
      ),
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => {
        return props.row.mobileNumber || '--';
      },
    },
    {
      field: 'roleName',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Role Name
        </Typography>
      ),
      flex: 1.5,
      minWidth: 200,
      renderCell: (props) => {
        return (
          <Typography>
            {convertCamelCase(
              props.row.completeRoleProgramEntityDtos?.at(0)?.roleDto?.name ??
                '--'
            )}
          </Typography>
        );
      },
    },
    {
      field: 'entityType',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Division Type
        </Typography>
      ),
      flex: 1.5,
      minWidth: 200,
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.completeRoleProgramEntityDtos?.at(0)?.divisionDto
              ?.divisionType ?? '--'}
          </Typography>
        );
      },
    },
    {
      field: 'entityName',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Division Name
        </Typography>
      ),
      flex: 1.2,
      minWidth: 180,
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.completeRoleProgramEntityDtos?.at(0)?.divisionDto
              ?.name ?? '--'}
          </Typography>
        );
      },
    },
    {
      field: 'entityCode',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Division Code
        </Typography>
      ),
      flex: 1,
      minWidth: 120,
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.completeRoleProgramEntityDtos?.at(0)?.divisionDto
              ?.divisionCode ?? '--'}
          </Typography>
        );
      },
    },
    // {
    //   field: 'userRoles',
    //   renderHeader: () => (
    //     <Typography className="font-aspekta-500" variant="body2">
    //       Alloted Roles
    //     </Typography>
    //   ),
    //   flex: 1,
    //   minWidth: 180,
    //   cellClassName: 'tooltip',
    //   renderCell: (props: any) => {
    //     return props.row.userRoles.length ? (
    //       props.row.userRoles.map((role: any) => (
    //         <div className="" title={toLowerCase(role?.status)}>
    //           <VegaLabel text={role?.roleName} color={'primary'} />
    //         </div>
    //       ))
    //     ) : (
    //       <VegaContainedButton
    //         text="Allocate Role"
    //         isPrimary
    //         onClick={() => allocateRole(props.row)}
    //       />
    //     );
    //   },
    // },
    {
      field: 'activity',
      type: 'actions',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Activity
        </Typography>
      ),
      renderCell: (props) => {
        return (
          <VegaSwitchV2
            size="small"
            checked={props.row.status === 'ACTIVE'}
            onChange={(e, checked) => {
              updateUserStatus({
                userId: props.row.userId,
                status: checked
                  ? VegaUserStatus.ACTIVE
                  : VegaUserStatus.INACTIVE,
              })
                .then((res) => {
                  setSnackbar('Updated status successfully');
                  refetchList();
                })
                .catch((err) => {
                  setSnackbar(
                    getErrorMessageFromErrorObj(
                      err,
                      'Error in updating status'
                    ),
                    'error'
                  );
                });
            }}
          />
        );
      },

      flex: 1,
    },
    // {
    //   field: 'activationDate',
    //   renderHeader:()=> <Typography className="font-aspekta-500" variant="body2">Activation Date</Typography>,
    //   flex: 0.7,
    //   renderCell: (props: any) => {
    //     return (
    //       <VegaLabel text={dateFormat(props.row.createdAt)} color={'success'} />
    //     );
    //   },
    // },
    // {
    //   field: 'status',
    //   headerName: (
    //     <Typography className="font-aspekta-500" variant="body2">
    //       Status
    //     </Typography>
    //   ),
    //   flex: 0.7,
    //   renderCell: (props: any) => {
    //     const roles = props.row.userRoles[0];
    //     const status = props.row.userRoles[0]?.status;
    //     const checkerMakerStatus = props.row.userRoles[0]?.checkerMakerStatus;
    //     return getButtons(status, checkerMakerStatus, roles)?.status;
    //   },
    // },
    ...(showAction
      ? [
          {
            field: 'isDraft',
            headerName: 'Action',
            renderCell: (props: any) => {
              const roles = props.row.userRoles[0];
              const status = props.row.userRoles[0]?.status;
              const checkerMakerStatus =
                props.row.userRoles[0]?.checkerMakerStatus;
              return props.row.userRoles.length
                ? getButtons(status, checkerMakerStatus, roles)?.action
                : // <VegaContainedButton
                  //   text="View Roles"
                  //   onClick={() => showDetails(props.row.userRoles)}
                  //   isPrimary
                  // />
                  '';
            },
            flex: 0.7,
          },
        ]
      : []),

    // {
    //   field: 'action',
    //   renderHeader:()=> <Typography className="font-aspekta-500" variant="body2">Action</Typography>,
    //   renderCell: (props: any) => {
    //     return (
    //       <VegaContainedButton
    //         text="edit"
    //         onClick={() => showDetails(props.row)}
    //         isPrimary
    //       />
    //     );
    //   },
    //   flex: 0.7,
    // },
  ];
  const roleColumn = [
    {
      field: 'roleName',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Role Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return <Typography>{toLowerCase(props.row.roleName)}</Typography>;
      },
    },

    {
      field: 'status',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <Box position={'relative'}>
            <VegaLabel text={toLowerCase(props.row.status)} color={'primary'} />
            {show && (
              <Box position={'absolute'} bottom={0}>
                <VegaLabel
                  text={toLowerCase(props.row.status)}
                  color={'primary'}
                />
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: 'isDraft',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          props.row.status === 'DRAFT' && (
            <VegaContainedButton
              text="Send Checker"
              onClick={() => {
                onSendChecker(props.row);
                setOpen(!open);
              }}
              isPrimary
            />
          )
        );
      },
      flex: 0.7,
    },
  ];

  const handleRoleChange = (value: UserRoleEnums) => {
    handleChange('roleType', value);
    handleChange('platformId', '');

    if (value === UserRoleEnums.HQ_USER) {
      handleChange('platformId', mccPlatformId);
    }
  };

  const handleBranch = (name: keyof IAllocationRoleProps, value: any) => {
    // setBranchCode(value.branchCode);
    handleChange(name, value.branchId || '');
  };
  const handleCorporate = (value: ICorporateEntity | null) => {
    handleChange('platformId', value?.id || '');
  };

  const handleChange = (
    key: keyof IAllocationRoleProps,
    value: string | File | null
  ) => {
    // apply filter on platformName -> UserPlatformEnums
    setAllocation((pre) => {
      let platformName = pre.platformName;
      if (key === 'roleType') {
        switch (value) {
          // case UserRoleEnums.HQ_USER:
          //   platformName = UserPlatformEnums.FOREX_HQ;
          //   setRoleDetails({ ...DEFAUL_HQ_USERS });
          //   break;
          case UserRoleEnums.CORPORATE_ADMIN:
            platformName = UserPlatformEnums.FOREX_CORPORATE;
            setRoleDetails({ ...defaultCorporateAdmin });
            break;
          // case UserRoleEnums.BRANCH_ADMIN:
          //   setRoleDetails({ ...DEFUALT_BRANCH_ADMIN });
          //   break;
          default:
            // default branch user
            platformName = UserPlatformEnums.FOREX_BRANCH;
            setRoleDetails({ ...defaultBranchUser });
        }
      }

      return {
        ...pre,
        [key]: value,
        platformName,
      };
    });
  };

  const showDetails = (data: ITeamsProps) => {
    setCustomerDetails(data);
    setOpen(!open);
  };

  const onAllocateOpen = () => {
    setOpenAllocateRole(!openAllocateRole);
  };
  const allocateRole = (data: ITeamsProps) => {
    setUserId(data.userId);
    onAllocateOpen();
  };
  const decline = () => {
    setOpen(!open);
  };
  const onClose = () => {
    onAllocateOpen();
    setAllocation({ platformId: '', roleType: '', platformName: '' });
  };

  const isButtonDisabled = allocation.platformId === '';
  console.log('isButtonDisabled', isButtonDisabled, allocation);
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={state}
        columnDef={columnDef}
        idColumn={'userId'}
        loading={loading}
      />
      <VegaDrawer
        open={open}
        header={'User Id'}
        closeText="Close"
        isClose
        onClose={decline}
      >
        <VegaTable
          data={customerDetails}
          columnDef={roleColumn}
          idColumn={'id'}
        />
      </VegaDrawer>
      <VegaModal
        header={'Allocate Role'}
        open={openAllocateRole}
        handleClose={onClose}
        submitText={'Allocate'}
        disabled={isButtonDisabled}
        handleSubmit={async () => {
          await allocateRoleForUser(userId, allocation.platformId);
          onClose();
        }}
        buttonIcon={<AddCircleOutline />}
      >
        <Box mx={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <VegaSelect
                options={['BRANCH_USER', 'CORPORATE_ADMIN']}
                key="roleType"
                selected={allocation.roleType}
                label="Select Role"
                onChange={(value) => handleRoleChange(value as UserRoleEnums)}
              />
            </Grid>

            {(allocation.roleType === UserRoleEnums.BRANCH_ADMIN ||
              allocation.roleType === UserRoleEnums.BRANCH_USER) && (
              <Grid item xs={12} sm={12}>
                <BranchSelector
                  name="branchId"
                  selected={allocation.platformId ?? ''}
                  handleChange={(value) => handleBranch('platformId', value)}
                />
              </Grid>
            )}
            {allocation.roleType === UserRoleEnums.CORPORATE_ADMIN && (
              <Grid item xs={12} sm={12}>
                <CorporateSelector
                  name="corporateId"
                  selected={allocation.platformId}
                  handleChange={(value) => handleCorporate(value)}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </VegaModal>
    </VegaCard>
  );
};

export default ListOfTeamManagement;
