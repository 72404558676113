import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { CardType, useCardConfig } from '../../providers/CardConfigProvider';
import ColorSelectorItem from '../common/v2/ColorSelector/ColorSelectorItem';
import VegaColorSelector from '../common/v2/ColorSelector/VegaColorSelector';
import VegaSelectV2 from '../common/v2/VegaSelectV2';

type Props = {
  cardType: CardType;
};
function CardTextColorSelector({ cardType }: Props) {
  const {
    onTextColorChange,
    availableTextColors,
    physicalCardConfig,
    virtualCardConfig,
  } = useCardConfig();

  const getTextColor = () => {
    const textColor =
      cardType == CardType.PHYSICAL
        ? physicalCardConfig.textColor
        : virtualCardConfig.textColor;
    return textColor ?? availableTextColors[0];
  };

  return (
    <VegaSelectV2
      placeholder="Select Text color"
      hidePlaceholder={Boolean(getTextColor())}
      renderOptions={() => (
        <VegaColorSelector
          showHexInput
          rows={[
            {
              title: 'Predefined Colors',
              colors: availableTextColors,
            },
          ]}
          selectedColor={getTextColor()}
          onChange={function (selectedColor: string): void {
            onTextColorChange(selectedColor, cardType);
          }}
        />
      )}
      renderSelectedValue={(selected) => {
        return (
          <Stack direction={'row'} spacing={2} alignItems="center">
            <ColorSelectorItem background={getTextColor() ?? ''} />
          </Stack>
        );
      }}
      value={getTextColor()}
    />
  );
}

export default CardTextColorSelector;
