import { Box, Grid, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { memo, useEffect } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { fetchStateOnPincode } from '../../../../api/resources';
import { VegaTextField } from '../../../../components/common';
import {
  VALIDATE_REGEX,
  ValidationRules,
} from '../../../../utils/validation.utils';

import VegaUploadNPreview from '../../../../components/common/VegaUploadNPreview';
import { toLowerCase } from '../../../../constants/commonFunction';
import { PRIMARY } from '../../../../constants/style';
import { CompanyTypeEnum, EntityStatusEnum } from '../../../../enums/commons';

type ISelectCompanyProps = {
  field: ControllerRenderProps<
    {
      partner: IBasePartner;
    },
    'partner.companyType'
  >;
};

const SelectCompanyType = ({ field }: ISelectCompanyProps) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel
        id="select-company-type-label"
        sx={{
          bgcolor: 'white',
          zIndex: 2601,
        }}
      >
        COMPANY TYPE *
      </InputLabel>
      <Select
        id="select-company-type"
        placeholder="Company Type"
        label="COMPANY TYPE"
        {...field}
        input={<OutlinedInput label="Age11" />}
        fullWidth
        sx={{
          zIndex: 2600,
          borderRadius: '9px',
          height: '50px',
          '& .MuiInputLabel-root': {
            transform: 'translate(14px, 13px) scale(1)',
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          '& .MuiFormLabel-root': {
            '&.Mui-focused': {
              color: `${PRIMARY.main} !important`,
            },
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#AFAFAF',
              borderWidth: '1px',
              borderRadius: '9px',
            },
            ':hover fieldset': {
              borderColor: PRIMARY.main,
            },
          },
        }}
        MenuProps={{
          style: { zIndex: 2600 },
        }}
      >
        {Object.values(CompanyTypeEnum).map((option, index) => (
          <MenuItem
            value={option}
            sx={{
              zIndex: 2600,
            }}
          >
            {toLowerCase(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
interface IBasePartner {
  id: string;
  clientId: string;
  name: string;
  programType: 'RETAIL' | 'CORPORATE' | 'BOTH';
  url?: string;
  email: string;
  status?: EntityStatusEnum;
  companyType: CompanyTypeEnum;
  role: string;
  mobileNo: string;
  cinNo?: string;
  gstNo: string;
  panNo: string;
  line1?: string;
  line2?: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
  agreement?: File | null;
  statementAppLogo?: File | null;
  crmLogo?: File | null;
  mobileAppLogo?: File | null;
  noBgLogo?: File;
  withBgLogo?: File;

  logos: File[] | null;
}

interface IPartnerProps<T> {
  initialValue?: T;
}

const CreatePartner = <T extends IBasePartner>({
  initialValue,
}: IPartnerProps<T>) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<{ partner: IBasePartner }>();

  const onPincodeChange = (pincode: any) => {
    return new Promise((res) => {
      if (pincode?.length === 6) {
        fetchStateOnPincode(pincode)
          .then((result) => {
            if (result.data?.records?.length) {
              const stateList = result.data?.records[0]?.state;
              const cityList = result.data?.records[0]?.city;
              const counteryList = result.data?.records[0]?.country;
              setValue('partner.city', cityList);
              setValue('partner.state', stateList);
              setValue('partner.country', counteryList.toLocaleUpperCase());
              res(true);
            } else {
              res(false);
            }
          })
          .catch((err) => {
            res(false);
          });
      } else {
        res(true);
      }
    });
  };

  useEffect(() => {
    if (initialValue) {
      setValue('partner.id', initialValue.id);
      setValue('partner.name', initialValue.name);
      setValue('partner.panNo', initialValue.panNo);
      setValue('partner.gstNo', initialValue.gstNo);
      setValue('partner.cinNo', initialValue.cinNo);
      setValue('partner.line1', initialValue.line1);
      setValue('partner.line2', initialValue.line2);
      setValue('partner.pincode', initialValue.pincode);
      setValue('partner.city', initialValue.city);
      setValue('partner.state', initialValue.state);
      setValue('partner.country', initialValue.country);
      setValue('partner.companyType', initialValue.companyType);
      setValue('partner.logos', initialValue.logos);
    }
  }, [initialValue?.id]);

  return (
    <Box sx={{ p: 3, pt: 0 }}>
      <Stack spacing={1} component="form">
        <Grid
          container
          spacing={2}
          mt={1}
          sx={{
            pb: 1,
          }}
        >
          <Grid item xs={12}>
            <Controller
              name="partner.name"
              control={control}
              defaultValue=""
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="COMPANY NAME *"
                  variant="outlined"
                  error={!!errors.partner?.name}
                  helperText={errors.partner?.name?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="partner.panNo"
              control={control}
              defaultValue=""
              rules={{
                required: 'PAN number is required',
                pattern: {
                  value: VALIDATE_REGEX.PAN,
                  message: 'Not a valid pan',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="PAN NO. *"
                  variant="outlined"
                  error={!!errors.partner?.panNo}
                  helperText={errors.partner?.panNo?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="partner.gstNo"
              control={control}
              defaultValue=""
              rules={{
                required: 'Gst number is required',
                pattern: {
                  value: VALIDATE_REGEX.GST_NO,
                  message: 'Not a valid GST NO.',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="GST NO. *"
                  variant="outlined"
                  error={!!errors.partner?.gstNo}
                  helperText={errors.partner?.gstNo?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="partner.cinNo"
              control={control}
              defaultValue=""
              rules={{
                required: 'CIN No number is required',
                pattern: {
                  value: VALIDATE_REGEX.CIN_NO,
                  message: 'Not a valid CIN NO.',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="CIN NO. *"
                  variant="outlined"
                  error={!!errors.partner?.cinNo}
                  helperText={errors.partner?.cinNo?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="partner.companyType"
              control={control}
              defaultValue={CompanyTypeEnum.PARTNERSHIP_FIRMS}
              rules={{ required: ValidationRules.required }}
              render={({ field }) => <SelectCompanyType field={field} />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="partner.line1"
              control={control}
              defaultValue=""
              rules={{ required: ValidationRules.required }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="ADDRESS 1 *"
                  type="text"
                  placeholder="ADDRESS 1"
                  error={!!errors.partner?.line1}
                  helperText={errors.partner?.line1?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="partner.line2"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="ADDRESS 2"
                  type="text"
                  placeholder="ADDRESS 2"
                  error={!!errors.partner?.line2}
                  helperText={errors.partner?.line2?.message}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="partner.pincode"
              control={control}
              rules={{
                required: ValidationRules.required,
                maxLength: {
                  value: 6,
                  message: 'Pincode should be 6 digits',
                },
                minLength: {
                  value: 6,
                  message: 'Pincode should be 6 digits',
                },
                validate: {
                  require: async (e) => {
                    const res = await (onPincodeChange(e) as Promise<boolean>);
                    return res || 'Please provide valid Pincode';
                  },
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="PIN CODE *"
                  type="number"
                  placeholder="Pin code"
                  error={!!errors.partner?.pincode}
                  helperText={errors.partner?.pincode?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="partner.city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="CITY"
                  type="text"
                  disabled={true}
                  placeholder="City"
                  error={!!errors.partner?.city}
                  helperText={errors.partner?.city?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="partner.state"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="STATE"
                  type="text"
                  disabled={true}
                  placeholder="State"
                  error={!!errors.partner?.state}
                  helperText={errors.partner?.state?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="partner.country"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="COUNTRY"
                  type="text"
                  disabled={true}
                  placeholder="Country"
                  error={!!errors.partner?.country}
                  helperText={errors.partner?.country?.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="partner.logos"
            control={control}
            // rules={{ required: ValidationRules.required }}
            render={({ field }) => (
              <>
                <VegaUploadNPreview
                  value={field.value || []}
                  onChange={(val) => field.onChange(val)}
                  label="LOGOS (OPTIONAL)"
                  helperText={
                    errors.partner?.logos?.message ||
                    'You can upload files in png/jpeg/svg format'
                  }
                  isPreview={true}
                  max={5}
                />
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="partner.agreement"
            control={control}
            // rules={{ required: ValidationRules.required }}
            render={({ field }) => (
              <>
                <VegaUploadNPreview
                  value={field.value ? [field.value] : []}
                  onChange={(val) => field.onChange(val.pop())}
                  label="AGGREMENT (OPTIONAL)"
                  helperText={
                    errors.partner?.agreement?.message ||
                    'You can upload file in pdf format'
                  }
                  isPreview={true}
                />
              </>
            )}
          />
        </Grid>
      </Stack>
    </Box>
  );
};

export default memo(CreatePartner);
