import React, { useState } from 'react';
import { VegaCard } from '../../../../components/common';
import { Box, Typography, Grid } from '@mui/material';
import VegaText from '../../../../components/common/VegaText';
import { GREY } from '../../../../constants/style';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../../../components/common/VegaSelect';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDatePicker from '../../../../components/common/VegaDatePicker';
import ProgramSelect from '../../../components/ProgramSelect';
import TransactionFeePlanSelector from '../../../components/TransactionFeePlanSelector';
import MarkupFeePlanSelector from '../../../components/MarkupFeePlanSelector';
import ChargesFeePlanSelector from '../../../components/ChargesFeePlanSelector';
import WalletFeePlanSelector from '../../../components/WalletFeePlanSelector';
interface IAllocatePoliciesProps {
  state: any;
  errorMsg: any;
  loading: boolean;
  handleChange: (name: string, value: any) => void;
  onMapProgram: () => void;
  // onSubmit: () => void;
  // loading: boolean;
}

const AllocatePolicies = ({
  state,
  handleChange,
  onMapProgram,
  loading,
  errorMsg,
}: // onScroll,
IAllocatePoliciesProps) => {
  const planTypeArr = [
    {
      planType: '',
      allocatePolicy: '',
      startDate: '',
      endDate: '',
    },
  ];
  const [plan, setPlan] = useState(planTypeArr);
  const addPlan = () => {
    setPlan([
      ...plan,
      {
        planType: '',
        allocatePolicy: '',
        startDate: '',
        endDate: '',
      },
    ]);
  };

  const handlePlanChange = (name: string, value: any, index: number) => {
    let copy: any = [...plan];
    copy[index][name] = value;
    setPlan(copy);
  };
  const removePlan = (index: number) => {
    let copy: any = [...plan];
    copy.splice(index, 1);
    setPlan(copy);
  };

  return (
    <VegaCard noPad>
      <Box p={3}>
        <VegaText fw={500} text={'Allocate Policies to the Program'} />
        <Typography
          variant="body2"
          sx={{ fontWeight: '500', color: GREY.lighter }}
        >
          All resources which are used in the Vegapay ecosystem
        </Typography>
      </Box>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} px={2} py={2}>
            <Box>
              <ProgramSelect
                name="entityId"
                selected={state.entityId}
                handleChange={(value) => handleChange('entityId', value)}
              />

              <Typography sx={{ color: 'red', fontSize: '14px' }}>
                {errorMsg['entityId']}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} px={2} py={2}>
            <Box>
              <MarkupFeePlanSelector
                name="MARKUP_FEE_PLAN"
                selected={state.MARKUP_FEE_PLAN}
                handleChange={(value) => handleChange('MARKUP_FEE_PLAN', value)}
              />

              <Typography sx={{ color: 'red', fontSize: '14px' }}>
                {errorMsg['MARKUP_FEE_PLAN']}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} px={2} py={2}>
            <Box>
              <TransactionFeePlanSelector
                name="TRANSACTION_LIMIT_PLAN"
                selected={state.TRANSACTION_LIMIT_PLAN}
                handleChange={(value) =>
                  handleChange('TRANSACTION_LIMIT_PLAN', value)
                }
              />

              <Typography sx={{ color: 'red', fontSize: '14px' }}>
                {errorMsg['TRANSACTION_LIMIT_PLAN']}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} px={2} py={2}>
            <Box>
              <WalletFeePlanSelector
                name="WALLET_CONFIGURATION_PLAN"
                selected={state.WALLET_CONFIGURATION_PLAN}
                handleChange={(value) =>
                  handleChange('WALLET_CONFIGURATION_PLAN', value)
                }
              />

              <Typography sx={{ color: 'red', fontSize: '14px' }}>
                {errorMsg['WALLET_CONFIGURATION_PLAN']}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} px={2} py={2}>
            <Box>
              <ChargesFeePlanSelector
                name="CHARGES_PLAN"
                selected={state.CHARGES_PLAN}
                handleChange={(value) => handleChange('CHARGES_PLAN', value)}
              />

              <Typography sx={{ color: 'red', fontSize: '14px' }}>
                {errorMsg['CHARGES_PLAN']}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container mb={2} justifyContent={'end'}>
          <VegaContainedButton
            text="Map Program"
            isPrimary
            onClick={onMapProgram}
            loading={loading}
          />
        </Grid>
      </Box>
    </VegaCard>
  );
};

export default AllocatePolicies;
