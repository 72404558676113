import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { VegaTextField } from '../../../../../components/common';
import VegaSelect from '../../../../../components/common/VegaSelect';
import { BLUE, YELLOW } from '../../../../../constants/style';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_INR } from '../../../../variable';
import { convertCurrency } from '../../../api/transaction';
import { IDetailsProps } from '../ListOfCardTransaction';

interface ICommonProps {
  state: any;
  handleChange: (name: string, value: any) => void;
}

interface IProps extends ICommonProps  {
  isShow?: boolean;
}

const BASE_URL = 'https://api.exchangerate.host/latest';

const CardReloadDetails = ({ handleChange, state, isShow }: IProps) => {
  const [exchangeRate, setExchangeRate] = useState<any>();
  const [filterData, setFilterData] = useState<any>({});

  const converter = () => {
    const obj = {
      sourceCurrency: state.sourceCurrency,
      destinationCurrency: state.destinationCurrency,
      timestamp: new Date(),
    };
    convertCurrency(obj).then((res) =>
      setExchangeRate(res.data.conversionRate)
    );
  };

  useEffect(() => {
    if (state.sourceCurrency && state.destinationCurrency) {
      converter();
    }
  }, [state.sourceCurrency, state.destinationCurrency]);

  const convertValue = (
    markupValueType: string,
    value: number,
    convert: number
  ) => {
    const toFixedValue: any = value?.toFixed(2);

    if (markupValueType === 'PERCENTAGE') {
      const percentValue = toFixedValue - toFixedValue * (convert / 100);
      return percentValue?.toFixed(2);
    } else {
      const amountValue = toFixedValue - convert;
      return amountValue;
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={state?.accountId}
            onChange={(e) => handleChange('accountId', e.target.value)}
            key="accountId"
            label="Account ID"
            type={'text'}
            placeholder="Account ID"
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={state.amount}
            onChange={(e) =>
              handleChange(
                'amount',
                e.target.value && parseFloat(e.target.value)
              )
            }
            key="amount"
            label="Amount"
            type={'number'}
            placeholder="Amount"
            disabled={isShow}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={DEFAULT_CURRENCY_INR}
            key="sourceCurrency"
            isCurrency
            selected={state.sourceCurrency}
            label="Select Source Currency"
            onChange={(value) => handleChange('sourceCurrency', value)}
            disabled={isShow}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            size="small"
            key={'markupValueType'}
            selected={state.markupValueType ?? ''}
            onChange={(value: string) => handleChange('markupValueType', value)}
            options={['PERCENTAGE', 'AMOUNT']}
            label="Loading Markup Value Type"
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 1 }}>
              {state.markupValueType === 'AMOUNT' ? '$' : ''}
            </Typography>
            <VegaTextField
              value={state.markupValue ?? ''}
              onChange={(e) => {
                let value: any = e.target.value;
                if (state.markupValueType === 'PERCENTAGE') {
                  if (value > 100) value = 100;
                  if (value < 0) value = 0;
                }
                handleChange('markupValue', value && parseFloat(value));
              }}
              key="markupValue"
              type={'number'}
              label="Loading Markup Value "
            />
            <Typography sx={{ ml: 1 }}>
              {state.markupValueType === 'PERCENTAGE' ? '%' : ''}
            </Typography>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={DEFAULT_CURRENCY}
            key="destinationCurrency"
            isCurrency
            selected={state.destinationCurrency}
            label="Select Destination Currency"
            onChange={(value) => handleChange('destinationCurrency', value)}
            disabled={isShow}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Box
            sx={{
              bgcolor: BLUE.lightest,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {`Conversion 1 ${state.sourceCurrency} = ${exchangeRate?.toFixed(
              2
            )} ${state.destinationCurrency}`}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Box
            sx={{
              bgcolor: YELLOW.lighter,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            Amount : {state.amount * exchangeRate?.toFixed(2)}
            {/* {convertValue(
              state.markupValueType,
              state.amount * exchangeRate,
              state.value
            )} */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardReloadDetails;
