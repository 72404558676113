import React, { useState, useEffect } from 'react';
import { VegaPage } from '../../../components/common';
import VegaSelector from '../../../components/common/VegaSelector';
import { CreditCardIcon, UserIcon } from '../../../components/icon/Icons';
import { BACKGROUND } from '../../../constants/style';
import { VegaHorizontalSelectorDataType } from '../../../components/common/VegaHorizontalSelector';
import MarkupFee from '../components/MCCPolicies/MarkupFee/MarkupFee';
import TransactionLimit from '../components/MCCPolicies/TransactionLimit/TransactionLimit';
import WalletConfiguration from '../components/MCCPolicies/WalletConfiguration/WalletConfiguration';
import Charges from '../components/MCCPolicies/Charges/Charges';
import { addPlan, fetchPlanData } from '../api/PlanPolicies';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useClientAuth } from '../../../providers/ClientProvider';
import OpenCreditCharge from '../components/MCCPolicies/OpenCreditCharge/OpenCreditCharge';

const tileData = [
  {
    id: 'MARKUP_FEE_PLAN',
    text: 'Markup Fee',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'TRANSACTION_LIMIT_PLAN',
    text: 'Transaction limits',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'WALLET_CONFIGURATION_PLAN',
    text: 'Wallet Configuration',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'CHARGES_PLAN',
    text: 'Charges',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'OPEN_CREDIT_CHARGE_PLAN',
    text: 'Open Credit Charges',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
] as VegaHorizontalSelectorDataType[];

export interface IPaginationProps {
  onPageChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  onWalletPageChange?: (page: number) => void;
  count?: number;
  page: number;
}

export interface ICommonPlanProps {
  onCreate: (plan: any) => void;
}

const MCCPolicies = () => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [plans, setPlans] = useState<any>([]);

  const [activeState, setActiveState] =
    useState<VegaHorizontalSelectorDataType>(tileData[0]);

  const selectedTab = (data: any) => {
    setActiveState(data);
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    getPlanList(clientId as string, activeState.id, page - 1);
  };
  const onWalletPageChange = async (pageNumber: number) => {
    setPage(pageNumber);
    await getPlanList(clientId as string, activeState.id, pageNumber);
  };

  const getPlanList = async (
    clientId: string,
    policyType: string,
    pageNumber: number
  ) => {
    setLoading(true);
    fetchPlanData({ clientId, policyType, page: pageNumber, pageSize: 10 })
      .then((res) => {
        setPlans(res.data?.records);
        setTotalPage(res.data.totalPages);
        setTotalItems(res.data.totalItems);
        if (activeState.id !== 'WALLET_CONFIGURATION_PLAN') {
          setPage(res.data.pageNumber + 1);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const addPlanForClient = (plan: any) => {
    console.log('plan', plan);
    addPlan(clientId as string, plan)
      .then(async (res) => {
        setSnackbar('Add Plan!');
        setPlans([...plans, res.data]);
        // await getPlanList(clientId as string, activeState.id, page);
        setIsLoading(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add Plan'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const getPolicies = (active: VegaHorizontalSelectorDataType) => {
    if (active.id === 'MARKUP_FEE_PLAN') {
      return (
        <MarkupFee
          onCreate={addPlanForClient}
          plans={plans}
          page={page}
          count={totalPage}
          onPageChange={onPageChange}
        />
      );
    } else if (active.id === 'TRANSACTION_LIMIT_PLAN') {
      return (
        <TransactionLimit
          onCreate={addPlanForClient}
          plans={plans}
          page={page}
          count={totalPage}
          onPageChange={onPageChange}
        />
      );
    } else if (active.id === 'WALLET_CONFIGURATION_PLAN') {
      return (
        <WalletConfiguration
          onCreate={addPlanForClient}
          plans={plans}
          page={page}
          count={totalItems}
          onWalletPageChange={onWalletPageChange}
        />
      );
    } else if (active.id === 'CHARGES_PLAN') {
      return (
        <Charges
          onCreate={addPlanForClient}
          plans={plans}
          page={page}
          count={totalPage}
          onPageChange={onPageChange}
        />
      );
    } else if (active.id === 'OPEN_CREDIT_CHARGE_PLAN') {
      return (
        <OpenCreditCharge
          onCreate={addPlanForClient}
          plans={plans}
          page={page}
          count={totalPage}
          onPageChange={onPageChange}
        />
      );
    }
  };

  useEffect(() => {
    getPlanList(clientId as string, activeState.id, 0);
  }, [activeState]);

  return (
    <VegaPage>
      <VegaSelector
        data={tileData}
        setActiveState={selectedTab}
        active={activeState}
        header={`Plan`}
        subHeader={`All resources which are used in the Vegapay ecosystem`}
      />
      {getPolicies(activeState)}
    </VegaPage>
  );
};

export default MCCPolicies;
