import { Box, TextareaAutosize, Typography } from '@mui/material';
import React from 'react';

interface IProps {
  onHandleChange: (name: any, value: string) => void;
  disputeReason: any;
}

const CreateDispute = ({ onHandleChange, disputeReason }: IProps) => {
  return (
    <>
      <Box sx={{ mx: 1, height: '100%', overflowX: 'auto' }}>
        <Typography>Reason</Typography>
        <TextareaAutosize
          onChange={(e: any) => onHandleChange('reason', e.target.value)}
          value={disputeReason?.reason}
          minRows={5}
          style={{ width: '100%' }}
        />
      </Box>
    </>
  );
};

export default CreateDispute;
