/* eslint-disable react/jsx-pascal-case */
import { EditOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { Level } from '../../../types/client';
import { DEFAULT_CARD_THRESHOLD_POLICY } from '../../../types/defaultPolicy';
import {
  CardThresholdPolicy,
  CardThresholdV2Policy,
  PolicyType,
} from '../../../types/policy';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { EntityService, PolicyService } from '../../apis';
import { CIMS_BORDER, CIMS_PRIMARY } from '../../assets/style';
import CIMS_Button from '../../components/common/CIMS_Button';
import { EditThresholdPolicies } from './Tables/EditThresholdPolicies';
import { ViewThresholdPolicies } from './Tables/ViewThresholdPolicies';
import {
  CIMS_SUB_MODULES,
  CIMS_RESOURCE_MODULES,
} from '../../../constants/modules';
import { checkResourceAccess } from '../../../utils/userAccessUtils';
import { DivisionService } from '../../../api/division';

const BCCardThreshold = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { clientId, user } = useClientAuth();
  const [pageSize, setPageSize] = useState<number>(100);
  const [page, setPage] = useState(0);
  const { setSnackbar } = useSnackbar();
  const hasAccessToEdit = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.POLICIES.EDIT,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.POLICIES,
      }),
    [user?.allResourceAccess]
  );
  const listPolicyQuery = useQuery({
    queryKey: ['list_policies'],
    queryFn: () => {
      return PolicyService.listPolicies({
        clientId: clientId || '',
        policyType: PolicyType.CARD_THRESHOLD_V2_POLICY,
        page: 0,
        pageSize: 1,
      });
    },
    select: (data) => {
      console.log(data);

      return data.records[0] as CardThresholdV2Policy;
    },
    enabled: !!clientId,
  });

  const divisionQuery = useQuery({
    queryKey: ['fetchDivisions', { clientId }],
    queryFn: () =>
      DivisionService.fetchDivisions({
        clientId: clientId || '',
        divisionType: 'BUSINESS_CORRESPONDENT',
        page: page,
        size: pageSize,
      }),
    enabled: !!clientId,
  });

  const hookForm = useForm<CardThresholdV2Policy>({
    defaultValues: listPolicyQuery.data || {},
    mode: 'onBlur',
  });

  const saveHandler: SubmitHandler<CardThresholdV2Policy> = (data) => {
    if (!listPolicyQuery.data) {
      const defaultPolicy = DEFAULT_CARD_THRESHOLD_POLICY(
        'CARD_THRESHOLD_POLICY policy',
        clientId ?? ''
      );
      PolicyService.createPolicy(clientId || '', {
        ...defaultPolicy,
        ...data,
      })
        .then((res) => {
          listPolicyQuery.refetch();
          setSnackbar('Policy Created Successfully');
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Policy Creation Failed'),
            'error'
          );
        });
    } else {
      PolicyService.updatePolicies({
        ...listPolicyQuery.data,
        ...data,
      })
        .then((res) => {
          listPolicyQuery.refetch();
          setSnackbar('Policy Updated Successfully');
          listPolicyQuery.refetch();
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Policy update Failed'),
            'error'
          );
        });
    }
    setIsEdit(!isEdit);
  };

  return (
    <>
      <Box
        sx={{
          border: CIMS_BORDER.DARKGRAY,
          borderRadius: '6px',
          bgcolor: 'white',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            borderBottom: CIMS_BORDER.GRAY,
            px: 2,
            py: 1,
          }}
        >
          <Typography
            className="font-aspekta-500"
            fontSize={14}
            color={CIMS_PRIMARY.BLACK}
          >
            BC Card Threshold
          </Typography>
          {hasAccessToEdit && (
            <>
              {isEdit ? (
                <Stack direction={'row'}>
                  <CIMS_Button
                    variant="text"
                    text="cancel"
                    onClick={() => setIsEdit(!isEdit)}
                  />{' '}
                  <CIMS_Button
                    text="save"
                    onClick={() => {
                      hookForm.handleSubmit(saveHandler)();
                    }}
                    disabled={!hookForm.formState.isValid}
                  />
                </Stack>
              ) : (
                <CIMS_Button
                  text="Edit"
                  startIcon={<EditOutlined />}
                  onClick={() => {
                    hookForm.setValue(
                      'card_threshold_map',
                      listPolicyQuery.data?.card_threshold_map
                    );
                    setIsEdit(true);
                  }}
                  disabled={
                    listPolicyQuery.isLoading || listPolicyQuery.isLoading
                  }
                />
              )}
            </>
          )}
        </Stack>
        <Box
          sx={{
            px: 2,
            py: 1,
          }}
        >
          {listPolicyQuery.isLoading || listPolicyQuery.isLoading ? (
            <Stack
              justifyContent={'center'}
              width={'100%'}
              alignItems={'center'}
              p={2}
            >
              <CircularProgress />
            </Stack>
          ) : isEdit ? (
            <Box>
              <Stack spacing={1}>
                {divisionQuery.isLoading ? (
                  <CircularProgress />
                ) : (
                  <EditThresholdPolicies
                    listPolicyQuery={listPolicyQuery}
                    divisionQuery={divisionQuery}
                    hookForm={hookForm}
                  />
                )}
              </Stack>
            </Box>
          ) : (
            <Stack spacing={1}>
              <ViewThresholdPolicies
                listPolicyQuery={listPolicyQuery}
                divisionQuery={divisionQuery}
              />
            </Stack>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BCCardThreshold;
