import { Card, Divider, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { InterestPolicyInterface } from '../../../types/policy';
import {
  IPolicySummaryData,
  IRowData,
  PolicySummaryTable,
} from './common/SummaryTable';

interface Props {
  policy?: InterestPolicyInterface | null;
}

interface InterestRateRule {
  posting_category_to_interest_rate_value: PostingToInterestRateRule;
}

interface PostingToInterestRateRule {
  RETAIL_PRINCIPAL: InterestRateRuleConfig;
  CASH_PRINCIPAL: InterestRateRuleConfig;
  EMI_PRINCIPAL: InterestRateRuleConfig;
}

interface InterestRateRuleConfig {
  interest_rate_value_type: string;
  default_rate: number;
}

const InterestPolicyListItem = (data: {
  heading: string;
  summaryData: IPolicySummaryData;
}) => {
  return (
    <Card elevation={0} style={{ borderRadius: 0 }}>
      <Typography style={{ padding: 12 }}>{data.heading}</Typography>
      <Divider />
      <div style={{ padding: 12 }}>
        <PolicySummaryTable
          headers={data.summaryData.headers}
          rows={data.summaryData.rows}
        />
      </div>
      <Divider />
    </Card>
  );
};

const getInterestRateRuleSummaryRows = (rateRule: InterestRateRule) => {
  const summaryRows: IRowData[] = [];
  if (!rateRule) return [];
  const postingToRateRule = rateRule.posting_category_to_interest_rate_value;
  const cash = postingToRateRule.CASH_PRINCIPAL;
  const nonCash = postingToRateRule.RETAIL_PRINCIPAL;
  const emi = postingToRateRule.EMI_PRINCIPAL;
  if (cash) {
    summaryRows.push({
      rowData: [
        'Cash',
        _.startCase(_.toLower(cash.interest_rate_value_type)),
        cash.default_rate.toString(),
      ],
    });
  }
  if (nonCash) {
    summaryRows.push({
      rowData: [
        'Non Cash',
        _.startCase(_.toLower(nonCash.interest_rate_value_type)),
        nonCash.default_rate.toString(),
      ],
    });
  }
  if (emi) {
    summaryRows.push({
      rowData: [
        'EMI',
        _.startCase(_.toLower(emi.interest_rate_value_type)),
        emi.default_rate.toString(),
      ],
    });
  }

  return summaryRows;
};

const getRateRuleHeaders = () => [
  'Position Type',
  'Interest Rate Value Type',
  'Default APR',
];

function InterestPolicySummary({ policy }: Props) {
  return (
    <Stack spacing={2}>
      <InterestPolicyListItem
        heading={'Transactor'}
        summaryData={{
          headers: getRateRuleHeaders(),
          rows: getInterestRateRuleSummaryRows(
            policy?.default_interest_rate_rule as InterestRateRule
          ),
        }}
      />

      <InterestPolicyListItem
        heading={'Deliquent'}
        summaryData={{
          headers: getRateRuleHeaders(),
          rows: getInterestRateRuleSummaryRows(
            policy?.interest_status_to_interest_rate_rules?.DELINQUENT
          ),
        }}
      />

      <InterestPolicyListItem
        heading={'Revolving'}
        summaryData={{
          headers: getRateRuleHeaders(),
          rows: getInterestRateRuleSummaryRows(
            policy?.interest_status_to_interest_rate_rules?.REVOLVING
          ),
        }}
      />
    </Stack>
  );
}

export default InterestPolicySummary;
