import { useEffect, useState } from 'react';
import VegaSelect from '../../components/common/VegaSelect';
import { useClientAuth } from '../../providers/ClientProvider';
import { fetchMCCCorporatesList } from '../corporate/api/Corporate';
import { ICorporateEntity } from '../types/CorporateCustomer';

interface IProps {
  selected: string;
  handleChange: (value: ICorporateEntity | null) => void;
  name: string;
}

const CorporateSelector = ({ name, selected, handleChange }: IProps) => {
  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [corporates, setCorporates] = useState<Array<ICorporateEntity>>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== corporates.length) {
        setPage(page + 1);
      }
    }
  };

  const getCorporatesList = async () => {
    // commented for now
    const data = {
      clientId,
    };

    fetchMCCCorporatesList(data).then((res) => {
      setCorporates([...corporates, ...res.data]);
      setTotalItem(res.data.size);
    });
  };

  const corporateHandleChange = (id: string) => {
    const findCorporate = corporates.find((val) => val.id === id);
    handleChange(findCorporate || null);
  };

  useEffect(() => {
    getCorporatesList();
  }, [page]);
  return (
    <>
      <VegaSelect
        placeholder="Corportate Name"
        label="Corporate Name"
        size="small"
        key={name}
        selected={selected ?? ''}
        onChange={(value) => corporateHandleChange(value)}
        options={corporates.map((corporate) => ({
          id: corporate.id,
          name: corporate.corporateName,
        }))}
        onScroll={loadMoreItems}
      />
    </>
  );
};

export default CorporateSelector;
