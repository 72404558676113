import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Pagination,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaLabel from '../../../../components/common/VegaLabel';
import VegaLoader from '../../../../components/common/VegaLoader';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import {
  BLUE,
  BORDER_GRAY,
  COMMON,
  GREEN,
  GREY,
  RED,
} from '../../../../constants/style';
import { ICardRefundOrder } from '../../../../types/cardRefund';
import { ISectionProps } from '../../../Back-office/pages/MCCUserManagement';
import StatusModal from '../../../components/StatusModal';
import { ICardFundLoadProps } from '../../../types/Card';
import { IManualAdjustment } from '../../../types/ManualAdjustment';
import { STATUS_DATA } from '../../../variable';

type Selected<T> = {
  selected?: boolean;
} & T;
export type ITransCheckerRowElement =
  | Selected<ICardFundLoadProps>
  | Selected<ICardRefundOrder>
  | Selected<IManualAdjustment>;

export type ITransCheckerRowData = Array<ITransCheckerRowElement>;

interface IProps {
  data: ITransCheckerRowData;
  loading: boolean;
  onStatusChange: (data: ITransCheckerRowElement, status: string) => void;
  activeState: ISectionProps;
  setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
  setCustomerData: React.Dispatch<React.SetStateAction<any>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  selectedRows: any;
  currentPage: number;
}

interface ICardColumnProps {
  key: keyof ITransCheckerRowElement | string | number;
  header: string;
  flex: number;
}

const COLUMN: ICardColumnProps[] = [
  {
    key: 'firstName',
    header: 'Customer Name',
    flex: 1,
  },
  {
    key: 'customerType',
    header: 'Customer Type',
    flex: 1,
  },
  {
    key: 'cardNumber',
    header: 'Card No.',
    flex: 1,
  },
  {
    key: 'expiryDate',
    header: 'Card Expiry',
    flex: 1,
  },
  {
    key: 'cardNetwork',
    header: 'Card Network',
    flex: 1,
  },
  {
    key: 'makerCheckerStatus',
    header: 'Status',
    flex: 1,
  },
  {
    key: 'action',
    header: 'Action',
    flex: 0.7,
  },
];

const ListOfCheckerCardTransaction = ({
  data,
  onStatusChange,
  activeState,
  loading,
  setSelectedRows,
  setCurrentPage,
  setCustomerData,
  currentPage,
  selectedRows,
}: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [selectedData, setSelectedData] = useState<any>([]);
  const [fundLoadDetails, setFundLoadDetails] = useState<any>({});
  const [collapsedRows, setCollapsedRows] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const startIndex = (currentPage - 1) * 15;
  const endIndex = startIndex + 15;

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const checkStatus = (value: ITransCheckerRowElement) => {
    let status = '';
    if (value.hasOwnProperty('fundLoadOrderStatus')) {
      status = (value as Selected<ICardFundLoadProps>).fundLoadOrderStatus;
    } else if (value.hasOwnProperty('cardRefundOrderStatus')) {
      status = (value as Selected<ICardRefundOrder>).cardRefundOrderStatus;
    } else {
      status = (value as Selected<IManualAdjustment>).status;
    }

    const makerCheckerStatus = value.makerCheckerStatus;
    if (
      status === 'DRAFT' ||
      (status === 'UNDER_REVIEW' && makerCheckerStatus === 'CHECKER_PENDING')
    ) {
      return (
        <VegaContainedButton
          text="Edit"
          onClick={() => showDetails(value)}
          isPrimary
        />
      );
    } else {
      return <VegaContainedButton text="Sent" isPrimary disabled />;
    }
  };
  const filteredData = data.filter(
    (val) => val.makerCheckerStatus === 'CHECKER_PENDING'
  );

  const showDetails = (data: ITransCheckerRowElement) => {
    setFundLoadDetails(data);
    setOpen(!open);
  };

  const approve = async () => {
    await onStatusChange(fundLoadDetails, status);
    setOpen(!open);
    setStatus('');
  };

  const getCustomizedValue = (value: ITransCheckerRowElement) => {
    if (value.accountConsolidatedDto && value.accountConsolidatedDto.card) {
      return {
        cardNumber: (
          <VegaLabel
            text={parseInt(value.accountConsolidatedDto.card.cardNumber || '0')}
            color="primary"
          />
        ),
        cardExpiry: (
          <VegaLabel
            text={value.accountConsolidatedDto.card.expiryDate}
            color="primary"
          />
        ),
        cardNetwork: getLogoForNetwork(
          value.accountConsolidatedDto.card.cardNetwork
        ),
      };
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: any
  ) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id: string) => id !== rowId));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectAll(event.target.checked);

    if (event.target.checked) {
      const allRowIds = data
        .filter(
          (card: ITransCheckerRowElement) =>
            card.makerCheckerStatus === 'CHECKER_PENDING'
        )
        .map((row: ITransCheckerRowElement) => row.id);

      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
  };

  const toggleCollapse = (rowId: any) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((id) => id !== rowId));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const getStyle = (card: ITransCheckerRowElement) => {
    if (card.id === undefined) {
      return;
    }
    if (selectedRows.includes(card.id)) {
      return {
        backgroundColor: '#F6F9FF',
      };
    }
    if (collapsedRows.includes(card.id)) {
      return {
        backgroundColor: '#F6F9FF',
        shadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      };
    }
  };

  const customizeRow = (
    column: ICardColumnProps,
    card: ITransCheckerRowElement,
    index: number
  ) => {
    if (!card) {
      return;
    }
    if (column.key === 'cardNumber') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          {getCustomizedValue(card)?.cardNumber}
        </td>
      );
    } else if (column.key === 'expiryDate') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          {getCustomizedValue(card)?.cardExpiry}
        </td>
      );
    } else if (column.key === 'cardNetwork') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          {getCustomizedValue(card)?.cardNetwork}
        </td>
      );
    } else if (column.key === 'action') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          {checkStatus(card)}
        </td>
      );
    } else if (column.key === 'firstName') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'left',
          }}
        >
          <Typography className="font-aspekta-400" fontSize={14} pl={2}>
            {card.accountConsolidatedDto?.customer?.firstName}
          </Typography>
        </td>
      );
    } else if (column.key === 'customerType') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'left',
          }}
        >
          <Typography className="font-aspekta-400" fontSize={14} pl={2}>
            {toLowerCase(
              card.accountConsolidatedDto?.customer?.customerType ?? ''
            )}
          </Typography>
        </td>
      );
    } else if (column.key === 'makerCheckerStatus') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          <Box
            className="font-aspekta-400"
            fontSize={14}
            py={0.5}
            sx={{
              ...(card.makerCheckerStatus === 'APPROVED'
                ? {
                    bgcolor: GREEN.light,
                    color: GREEN.dark,
                  }
                : card.makerCheckerStatus === 'REJECTED'
                ? { bgcolor: RED.light, color: RED.dark }
                : {
                    bgcolor: BLUE.light,
                    color: BLUE.lighter,
                  }),

              borderRadius: '5px',
            }}
          >
            {toLowerCase(card[column.key])}
          </Box>
        </td>
      );
    } else {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          {card[column.key]}
        </td>
      );
    }
  };
  return (
    <>
      <Box component={'div'} sx={{ overflowX: 'auto' }}>
        <table
          style={{
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              background: GREY.light,
            }}
          >
            <tr>
              <th>
                <Checkbox
                  disabled={!filteredData.length}
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  sx={{
                    color: GREY.dark,
                    '&.Mui-checked': {
                      color: GREEN.dark,
                    },
                  }}
                />
              </th>
              {COLUMN.map((column: any) => (
                <th style={{ minWidth: '150px' }}>
                  <Typography className="font-aspekta-500" fontSize={14}>
                    {column.header}
                  </Typography>
                </th>
              ))}
              <th className="font-aspekta-400"></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={12}>
                  <VegaLoader />
                </td>
              </tr>
            ) : data.length ? (
              data
                .slice(startIndex, endIndex)
                .map((value: ITransCheckerRowElement, index: number) => (
                  <React.Fragment key={index}>
                    <tr
                      style={{
                        backgroundColor: getStyle(value)?.backgroundColor,
                        borderBottom: collapsedRows.includes(value.id)
                          ? 'none'
                          : `2px solid ${GREY.light}`,
                      }}
                    >
                      <td
                        style={{
                          textAlign: 'center',
                          backgroundColor: getStyle(value)?.backgroundColor,
                        }}
                      >
                        <Checkbox
                          disabled={
                            value.makerCheckerStatus !== 'CHECKER_PENDING'
                          }
                          checked={selectedRows.includes(value?.id)}
                          onChange={(event) =>
                            handleRowCheckboxChange(event, value.id)
                          }
                          sx={{
                            color: GREY.dark,
                            '&.Mui-checked': {
                              color: GREEN.dark,
                            },
                          }}
                        />
                      </td>
                      {COLUMN.map((val: ICardColumnProps) =>
                        customizeRow(val, value, index)
                      )}
                      <td>
                        <IconButton onClick={() => toggleCollapse(value.id)}>
                          <KeyboardArrowDown
                            sx={{
                              transition: 'all 500ms ease-in-out',
                              ...(collapsedRows.includes(value.id) && {
                                rotate: '180deg',
                              }),
                            }}
                          />
                        </IconButton>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={12}
                        style={{
                          borderBottom: !collapsedRows.includes(value.id)
                            ? 'none'
                            : `2px solid ${BLUE.light}`,
                        }}
                      >
                        {collapsedRows.includes(value.id) && (
                          <>
                            {activeState.id === 'ADJUSTMENT' ? (
                              <ManualAdjustmentComp
                                value={value as IManualAdjustment}
                              />
                            ) : (
                              <RefundNReloadComp
                                value={
                                  value as ICardRefundOrder | ICardFundLoadProps
                                }
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))
            ) : (
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      height: '500px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      className="font-aspekta-450"
                      color={COMMON.darkGray}
                    >
                      No Result
                    </Typography>
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Grid container>
        <Box
          sx={{
            p: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            bgcolor: GREY.light,
            borderRadius: '0px 0px 20px 20px',
          }}
        >
          <Pagination
            color="primary"
            page={currentPage}
            count={Math.ceil(data.length / 15)}
            onChange={onPageChange}
          />
        </Box>
      </Grid>

      <StatusModal
        header="Status "
        onSubmit={approve}
        open={open}
        radioData={STATUS_DATA}
        name="status"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        labelPlacement={'right'}
        handleClose={() => {
          setOpen(!open);
          setStatus('');
        }}
        color={GREEN.dark}
      />
    </>
  );
};

export default ListOfCheckerCardTransaction;

const ManualAdjustmentComp = ({ value }: { value: IManualAdjustment }) => {
  return (
    <Box sx={{ p: 3, bgcolor: BLUE.lightest }}>
      <Grid
        container
        sx={{
          borderTop: BORDER_GRAY,
          borderBottom: BORDER_GRAY,
        }}
      >
        <Grid
          item
          sm={4}
          sx={{
            borderRight: BORDER_GRAY,
            textAlign: 'center',
            py: 1,
            bgcolor: GREY.light,
          }}
        >
          <Typography>Currency</Typography>
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            borderRight: BORDER_GRAY,
            textAlign: 'center',
            py: 1,
            bgcolor: GREY.light,
          }}
        >
          <Typography>Adjustment Type</Typography>
        </Grid>
        <Grid
          item
          sm={4}
          sx={{ textAlign: 'center', py: 1, bgcolor: GREY.light }}
        >
          <Typography>Amount</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={4}
          sx={{
            borderRight: BORDER_GRAY,
            textAlign: 'center',
            py: 1,
          }}
        >
          <VegaLabel
            color="primary"
            text={(value as IManualAdjustment).currency}
          />
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            borderRight: BORDER_GRAY,
            textAlign: 'center',
            py: 1,
          }}
        >
          <VegaLabel color="primary" text={value.adjustmentType} />
        </Grid>
        <Grid item sm={4} sx={{ textAlign: 'center', py: 1 }}>
          <VegaLabel color="primary" text={value.amount} />
        </Grid>
      </Grid>
    </Box>
  );
};

const RefundNReloadComp = ({
  value,
}: {
  value: ICardRefundOrder | ICardFundLoadProps;
}) => {
  return (
    <Box sx={{ p: 3, bgcolor: BLUE.lightest }}>
      <Grid
        container
        sx={{
          borderTop: BORDER_GRAY,
          borderBottom: BORDER_GRAY,
        }}
      >
        <Grid
          item
          sm={4}
          sx={{
            borderRight: BORDER_GRAY,
            textAlign: 'center',
            py: 1,
            bgcolor: GREY.light,
          }}
        >
          <Typography>Source currency</Typography>
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            borderRight: BORDER_GRAY,
            textAlign: 'center',
            py: 1,
            bgcolor: GREY.light,
          }}
        >
          <Typography>Destination currency</Typography>
        </Grid>
        <Grid
          item
          sm={4}
          sx={{ textAlign: 'center', py: 1, bgcolor: GREY.light }}
        >
          <Typography>Amount</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={4}
          sx={{
            borderRight: BORDER_GRAY,
            textAlign: 'center',
            py: 1,
          }}
        >
          <VegaLabel color="primary" text={value.sourceCurrency} />
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            borderRight: BORDER_GRAY,
            textAlign: 'center',
            py: 1,
          }}
        >
          <VegaLabel color="primary" text={value.destinationCurrency} />
        </Grid>
        <Grid item sm={4} sx={{ textAlign: 'center', py: 1 }}>
          <VegaLabel color="primary" text={value.amount} />
        </Grid>
      </Grid>
    </Box>
  );
};
