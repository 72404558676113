/* eslint-disable react/jsx-pascal-case */
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { listDiscardReasons, rejectCard } from '../../../../api/card';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { CardDto } from '../../../../types/card';
import { CIMS_BORDER, CIMS_PRIMARY } from '../../../assets/style';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import CIMS_Select from '../../../components/common/CIMS_Select';
import CIMS_TextField from '../../../components/common/CIMS_TextField';
import VegaCheckBoxV2 from '../../../../components/common/v2/VegaCheckBoxV2';
import LosText from '../../../../components/common/deprecated/LosText';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

interface IProps {
  data: CardDto | null;
  handleClose: () => void;
  open: boolean;
  buttonGroup: IButtonGroupProps[];
}

const declaration =
  'I hereby declare that we have also destroyed the kits physically';

const RejectKitModal = ({ data, open, handleClose, buttonGroup }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const { userDivisionId, clientId, loggedInUserDetails } = useClientAuth();

  const hookForm = useForm<{
    discardReasonId: string;
    remark: string;
    hasDeclaration?: boolean;
  }>();

  const listDiscardReasonsQuery = useQuery({
    queryKey: 'discardReasons',
    queryFn: () => {
      return listDiscardReasons({
        clientId: clientId || '',
        page: 0,
        size: 100,
      });
    },
    enabled: !!clientId,
    select: (data) => {
      return data.records.map((item, index: number) => {
        return {
          id: item.id,
          name: item.discardReasonDisplayText,
        };
      });
    },
  });

  const buttons: IButtonGroupProps[] = [
    {
      text: 'submit',
      disabled: !hookForm.formState.isValid,
      onSubmit: () => {
        if (data && userDivisionId) {
          const hasDeclaration = hookForm.getValues('hasDeclaration');

          rejectCard({
            clientId: clientId || '',
            cardId: data?.id,
            discardReasonId: hookForm.getValues('discardReasonId'),
            divisionId: userDivisionId,
            hasDeclaration: !!hasDeclaration,

            ...(hasDeclaration
              ? {
                  declaration: declaration,
                  declaredBy: loggedInUserDetails?.name,
                }
              : {}),
          })
            .then((res) => {
              setSnackbar('Rejected card successfully', 'success');
            })
            .catch((err) => {
              setSnackbar(
                getErrorMessageFromErrorObj(
                  err,
                  'Error while rejecting the card'
                ),
                'error'
              );
            })
            .finally(() => {
              handleClose();
              hookForm.reset();
            });
        } else {
          setSnackbar('Data not valid', 'error');
        }
      },
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <CIMS_Modal
      maxWidth="sm"
      open={open}
      handleClose={handleClose}
      header="Reject Kit"
      buttonGroup={buttons}
      subTitleContainer={
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <Stack
                direction={'row'}
                gap={1}
                sx={{ borderRight: CIMS_BORDER.DARKGRAY }}
              >
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={CIMS_PRIMARY.GRAY}
                >
                  KIT No:
                </Typography>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={'#222222'}
                >
                  {data?.kitId}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={3}>
              <Stack
                direction={'row'}
                gap={1}
                sx={{ borderRight: CIMS_BORDER.DARKGRAY }}
              >
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={CIMS_PRIMARY.GRAY}
                >
                  Card Type:
                </Typography>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={'#222222'}
                >
                  {toLowerCase(data?.productType || '')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={6}>
              <Stack direction={'row'} gap={1}>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={CIMS_PRIMARY.GRAY}
                >
                  Program Name:
                </Typography>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={'#222222'}
                >
                  {toLowerCase(data?.programDto?.name || '')}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      }
    >
      <Box sx={{ px: 3, pt: 2, pb: 6 }}>
        <Box my={2}>
          <Controller
            name="discardReasonId"
            control={hookForm.control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <CIMS_Select
                placeholder="Select Rejection reason"
                header="rejection reason"
                options={listDiscardReasonsQuery.data || []}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box my={2}>
          <Controller
            name="remark"
            control={hookForm.control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <CIMS_TextField
                placeholder="Type your remark.."
                isTextArea
                multiline
                rows={5}
                header="remark"
                variant="outlined"
                {...field}
              />
            )}
          />
        </Box>
        <Box my={2}>
          <Controller
            name="hasDeclaration"
            control={hookForm.control}
            defaultValue={false}
            rules={{ required: false }}
            render={({ field }) => (
              <Stack direction={'row'} spacing="1" alignItems={'start'}>
                <VegaCheckBoxV2
                  sx={{
                    '&.MuiButtonBase-root': {
                      paddingLeft: '0px !important',
                      paddingTop: '0px !important',
                    },
                    color: 'blue',
                  }}
                  {...field}
                  checked={!!field.value}
                  onChange={(e, checked) => {
                    field.onChange(checked);
                  }}
                />
                <LosText style={{ fontSize: '.9rem', lineHeight: '130%' }}>
                  I hereby declare that we have also destroyed the kits
                  physically
                </LosText>
              </Stack>
            )}
          />
        </Box>
      </Box>
    </CIMS_Modal>
  );
};

export default RejectKitModal;
