import React from 'react';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import VegaAspektaText from '../../../components/common/VegaAspektaText';
import { COMMON } from '../../../constants/style';
import { Add, KeyboardArrowDown } from '@mui/icons-material';
import VegaOutlineButton from '../../../components/common/VegaOutlineButton';
import VegaContainedButton from '../../../components/common/VegaContainedButton';
import VegaHamburgerMenu from '../../../components/common/v2/VegaHamburgerMenu';

interface IProps {
  children: JSX.Element;
  toggle: () => void;
  onClear: () => void;
  onSubmit: () => void;
  isOpen: boolean;
  disabled?: boolean;
  loading?: boolean;
  titleText: string;
  cancelText: string;
  submitText: string;
}

const SubmitFormWrapper = ({
  children,
  titleText,
  toggle,
  onClear,
  onSubmit,
  isOpen,
  disabled,
  loading,
  cancelText,
  submitText,
}: IProps) => {
  return (
    <>
      <Box
        sx={{
          py: 1,
          px: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          transition: 'height 500ms ease-in-out',
        }}
      >
        <VegaAspektaText
          text={titleText}
          fontSize={14}
          fontWeight={'600'}
          color={COMMON.gray}
        />

        <VegaHamburgerMenu onClick={toggle} open={isOpen} />
      </Box>

      <Box
        component={'div'}
        sx={{
          transition: 'all 500ms ease-in-out',
          overflow: 'hidden',
          ...(isOpen
            ? {
                maxHeight: '100rem',
              }
            : {
                maxHeight: '0px',
              }),
        }}
      >
        {children}
        <Grid container justifyContent={'end'} pb={2} px={3}>
          <Grid item>
            <VegaOutlineButton
              className="font-aspekta-500"
              py={0.7}
              text={cancelText}
              isDanger
              onClick={onClear}
            />
          </Grid>
          <Grid item>
            <VegaContainedButton
              text={submitText}
              isPrimary
              className="font-aspekta-500"
              py={0.7}
              disabled={disabled}
              loading={loading}
              startIcon={<Add />}
              mx="0"
              onClick={onSubmit}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SubmitFormWrapper;
