import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../services/ChargeBackService';
import {
  ChargebackStatus,
  ReopenChargebackRequest,
} from '../../../types/chargeBack';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useChargeBack } from '../hooks';
import { RemarkDocsModal } from './RemarkDocsModal';

interface IProps {}

export function ReopenTicketModal({}: IProps) {
  const {
    selectedChargeBack,
    setSelectedChargeBack,
    listChargeBackForProgram,
  } = useChargeBack();
  const { setSnackbar } = useSnackbar();

  const onSubmit = (data: ReopenChargebackRequest) => {
    ChargebackService.reopenChargeback({
      ...data,
      chargebackId: selectedChargeBack?.id || '',
      chargebackStatus:
        selectedChargeBack?.chargebackStatus ===
        ChargebackStatus.REQUEST_CANCELLED
          ? ChargebackStatus.REQUEST_CREATED
          : ChargebackStatus.CHARGEBACK_REINITIATED,
    })
      .then((res) => {
        setSnackbar('Successfully reopened', 'success');
        setSelectedChargeBack(res);
        listChargeBackForProgram.refetch();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Error while reopning chargeback'),
          'error'
        );
      });
  };
  return (
    <RemarkDocsModal
      onSubmit={onSubmit}
      header="Reopen Ticket"
      triggerLabel="Reopen Ticket"
    />
  );
}
