import { Stack } from '@mui/material';
import VegaAspektaText from '../../../components/common/VegaAspektaText';
import VegaContainedButton from '../../../components/common/VegaContainedButton';
import VegaOutlineButton from '../../../components/common/VegaOutlineButton';
import VegaTextButton from '../../../components/common/VegaTextButton';
import { UploadBulkIcon } from '../../../components/icon/Icons';
import { BLUE, COMMON } from '../../../constants/style';
import { downloadFile } from '../../utils';

interface IProps {
  templateUrl?: string;
  onFetchPastUpload: () => void;
  onOpen: () => void;
}

const BulkUploadWrapper = ({
  onFetchPastUpload,
  onOpen,
  templateUrl,
}: IProps) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <VegaAspektaText
        text="Bulk Upload"
        fontWeight={'500'}
        color={COMMON.gray}
        fontSize={14}
      />

      <Stack direction={'row'}>
        <VegaTextButton
          text="Download sample file"
          className="font-aspekta-500"
          color={BLUE.lighter}
          onClick={() => templateUrl && downloadFile(templateUrl)}
        />
        <VegaOutlineButton
          text="View past uploads"
          className="font-aspekta-500"
          py={0.7}
          isPrimary
          onClick={onFetchPastUpload}
        />
        <VegaContainedButton
          text="Bulk Upload"
          className="font-aspekta-500"
          py={0.7}
          isSuccess
          // loading={isLoading}
          startIcon={<UploadBulkIcon />}
          onClick={onOpen}
        />
      </Stack>
    </Stack>
  );
};

export default BulkUploadWrapper;
