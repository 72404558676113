/** @format */

import axios from 'axios';
import { CardBin, NewCardBin } from '../types/card';
import { VegaBin, VegaNewBin } from '../types/client';
import { BASE_URL, BIN } from './endpoints';

const BIN_BASE_URL = BASE_URL + BIN.BASE;
/// change post API
export const listBinForClient = (
  clientId: string,
  page: number,
  size: number
) => {
  return axios.post(`${BIN_BASE_URL}/listForClient`, { clientId, page, size });
};

export const listBinAllocations = (binId: string | number) => {
  return axios.post(`${BIN_BASE_URL}/binProgram/list`, { binId });
};

export const listCardFormFactorForClient = (clientId: string) => {
  return axios.get(`${BIN_BASE_URL}/cardProcessorConfig/list/${clientId}`);
};

export const addCardFormFactorForClient = (clientId: string) => {
  const data = {
    clientId: clientId,
    cardProcessorVendor: 'M2P',
    cardProcessorVendorId: '35edd4c3-1743-452e-aeea-f76e540240ee',
    productType: 'CREDIT',
    vendorMetadata: {
      appGuid: '123dase',
      business: 'LQVEGAPAY',
      channelName: 'MIN_KYC',
      entityType: 'CUSTOMER',
      cardType: 'VIRTUAL',
      cardCategory: 'PREPAID',
      cardRegStatus: 'ACTIVE',
    },
  };

  return axios.post(`${BIN_BASE_URL}/cardProcessorConfig`, data);
};

export async function addBinForClient(
  bin: Partial<VegaBin | VegaNewBin>
): Promise<VegaBin | VegaNewBin> {
  try {
    const endpoint = `${BIN_BASE_URL}/add`;
    const response = await axios.post(endpoint, bin);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const addProgramForBin = (
  binId: number,
  programId: any,
  binRangeRegex: string
) => {
  const data = {
    binId,
    programId,
    binRangeRegex,
  };
  return axios.post(`${BIN_BASE_URL}/addProgram`, data);
};

export const allocateBin = (
  binId: number,
  programId: any,
  numberOfKits: number
) => {
  const data = {
    binId: binId,
    programId: programId,
    numberOfKits: numberOfKits,
  };

  return axios.post(`${BIN_BASE_URL}/binProgram`, data);
};

export async function listBinsByIds(ids: number[]): Promise<NewCardBin[]> {
  try {
    const endpoint = BIN_BASE_URL + BIN.LIST_BY_IDS;
    const response = await axios.post(endpoint, { ids: ids });
    return response.data.records;
  } catch (error) {
    throw error;
  }
}
