import { Close } from '@mui/icons-material';
import { Box, Drawer, Grid, Typography, IconButton } from '@mui/material';
import { CIMS_BORDER } from '../../assets/style';

interface IDrawerProps {
  header: string;
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
  submitButton?: JSX.Element;
  clearButton?: JSX.Element;
}

const CIMS_Drawer = ({
  open,
  header,
  onClose,
  children,
  submitButton,
  clearButton,
}: IDrawerProps) => {
  return (
    <div>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '42%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 4,
            py: 3,
            bgcolor: '#E5F7FC',
          }}
        >
          <Typography
            className="font-aspekta-600"
            fontSize={17}
            color={'#222222'}
          >
            {header}
          </Typography>
          <IconButton sx={{}} onClick={onClose}>
            <Close sx={{ color: '#3A4A5F' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 4,
            py: 3,
            bgcolor: 'white',
            borderRadius: 1,
            height: '88%',
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: clearButton ? 'space-between' : 'end',
            p: 3,
            borderTop: CIMS_BORDER.GRAY,
          }}
        >
          {clearButton && clearButton}
          {submitButton && submitButton}
        </Grid>
      </Drawer>
    </div>
  );
};

export default CIMS_Drawer;
