import { CustomerAddress } from './customers';
import { CompanyType } from './partner';

export const DivisionTypes = {
  CLIENT: 'CLIENT',
  BUSINESS_CORRESPONDENT: 'BUSINESS_CORRESPONDENT',
  PARTNER: 'PARTNER',
  BRANCH: 'BRANCH',
  CORPORATE: 'CORPORATE',
  FOREX_ENTITY: 'FOREX_ENTITY',
  BC_AGENT: 'BC_AGENT',
  VENDOR: 'VENDOR',
  HEAD_OFFICE: 'HEAD_OFFICE',
} as const;

export type DivisionType = (typeof DivisionTypes)[keyof typeof DivisionTypes];

export const DivisionStatusEnums = {
  DRAFT: 'DRAFT',
  UNDER_REVIEW: 'UNDER_REVIEW',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REJECTED: 'REJECTED',
} as const;

export type DivisionStatusEnum =
  (typeof DivisionStatusEnums)[keyof typeof DivisionStatusEnums];

export type DivisionDto = {
  id: string;
  clientId: string;
  divisionType: DivisionType;
  divisionCode: string;
  parentDivisionId: string;
  depth: number;
  parentDivisionDto: DivisionDto | null;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  url: string;
  email: string;
  status: DivisionStatusEnum;
  companyType: CompanyType;
  role: string;
  mobileNo: string;
  cinNo: string;
  gstNo: string;
  panNo: string;
  addressId: string;
  address: CustomerAddress;
  agreement: string;
  logo: string;
  mobileAppLogo: string;
  statementAppLogo: string;
  crmLogo: string;
  noBgLogo: string;
  withBgLogo: string;
  spocName: string;
};

export type FetchDivisionPayload = {
  id?: string;
  clientId?: string;
  divisionType?: DivisionType;
  divisionCode?: string;
  divisionStatusList?: DivisionStatusEnum[];
  includeAddressDto?: boolean;
  page?: number;
  size?: number;
};

export type FetchChildDivisionPayload = {
  divisionId: string;
  includeItself?: boolean;
  divisionType?: DivisionType;
  page?: number;
  pageSize?: number;
};

export interface UpdateDivisionStatusRequest {
  divisionId: string;
  status: DivisionStatusEnum;
}

export type CreateDivisionPayload = Partial<DivisionDto>;
