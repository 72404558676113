import { Grid, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  addClientVendorAccountCredentials,
  addClientVendorAccountLink,
  fetchClientVendorAccountCredentials,
  fetchListOfVendorAccountCredentialSpecs,
  fetchListOfVendorAccountType,
  fetchVendorAccountLink,
} from '../../api/vendorService';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import {
  AddVendorAccountCredentialData,
  CredentialAccountType,
  VendorAccountCredential,
  VendorAccountCredentialSpecs,
  VendorAccountLink,
} from '../../types/vendor';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { VegaCard, VegaTable, VegaTextField } from '../common';
import VegaDrawer from '../common/VegaDrawer';
import VegaLabel from '../common/VegaLabel';
import VegaSelectItem from '../common/VegaSelectItem';
import AccountLinkDrawer from './AccountLinkDrawer';
// Comment

type AccountLinkDrawerData = {
  open: boolean;
  vendorId?: string;
};
const ListOfServiceSetup = ({ data }: any) => {
  const [showDrawer, setShowDrawer] = useState<AccountLinkDrawerData>({
    open: false,
  });

  const theme = useTheme();

  const columnRef = [
    {
      field: 'vendorName',
      headerName: 'Partner',

      flex: 1,
    },

    {
      field: 'services',
      headerName: 'Services',

      flex: 1,
    },
    {
      field: 'serviceType',
      headerName: 'Services Type',
      renderCell: (props: any) => {
        const service = props.row.serviceType.split(', ');
        return service.map((val: any) => (
          <VegaLabel text={_.startCase(_.toLower(val))} color="primary" />
        ));
      },
      flex: 1,
    },
    {
      field: 'activation',
      headerName: 'Activation',
      renderCell: (props: any) => {
        return (
          <Box
            onClick={() => {
              setShowDrawer({ open: true, vendorId: props.row.id });
            }}
          >
            <div
              style={{
                color: theme.palette.primary.light,
                paddingTop: 20,
                paddingBottom: 20,
                cursor: 'pointer',
              }}
            >
              Link Account
            </div>
          </Box>
        );
      },
      flex: 1,
    },
  ];

  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable data={data} columnDef={columnRef} idColumn={'id'} />
      </VegaCard>
      <AccountLinkDrawer
        open={showDrawer.open}
        vendorId={showDrawer.vendorId}
        onClose={function (): void {
          setShowDrawer({ open: false, vendorId: undefined });
        }}
      />
    </>
  );
};

export default ListOfServiceSetup;
