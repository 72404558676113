import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Stack, Typography, alpha } from '@mui/material';
import { useState } from 'react';
import {
  toLowerCase,
  useClickOutSide,
} from '../../../constants/commonFunction';
import { CIMS_BORDER, CIMS_PRIMARY } from '../../assets/style';

interface IProps {
  title: string;
  selected?: string;
  number: string | number;
  percentage?: number;
  isOptions?: boolean;
  options?: string[];
  handleChange: (select: string) => void;
}

const Card_Container = ({
  title,
  number,
  percentage,
  isOptions,
  options,
  selected,
  handleChange,
}: IProps) => {
  const [show, setShow] = useState(false);
  let domNode = useClickOutSide(() => {
    setShow(false);
  });

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: '6px',
        boxShadow: `${alpha(CIMS_PRIMARY.GOLD, 0.08)} 0px 2px 12px 0px`,
        border: `1px solid ${CIMS_PRIMARY.GOLD}`,
        height: '120px',
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography
          className="font-aspekta-500"
          fontSize={14}
          color={CIMS_PRIMARY.GOLD}
          lineHeight={'19.6px'}
        >
          {title}
        </Typography>
        {isOptions && (
          <Box ref={domNode} position={'relative'}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={1}
              onClick={() => setShow(!show)}
            >
              <Typography
                className="font-aspekta-400"
                fontSize={12}
                color={CIMS_PRIMARY.GRAY}
              >
                {toLowerCase(selected ?? '')}
              </Typography>
              {show ? (
                <KeyboardArrowUp sx={{ fontSize: 17 }} />
              ) : (
                <KeyboardArrowDown sx={{ fontSize: 17 }} />
              )}
            </Stack>
            {show && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 20,
                  right: 0,
                  px: 1,
                  border: CIMS_BORDER.GRAY,
                  borderRadius: '6px',
                  bgcolor: 'white',
                }}
              >
                {options?.map((option: string) => (
                  <Typography
                    className="font-aspekta-400"
                    fontSize={12}
                    my={0.3}
                    sx={{
                      cursor: 'pointer',
                    }}
                    color={CIMS_PRIMARY.GRAY}
                    onClick={() => {
                      handleChange(option);
                      setShow(!show);
                    }}
                  >
                    {toLowerCase(option)}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Stack>
      <Typography
        className="font-aspekta-600"
        fontSize={24}
        my={1}
        color={CIMS_PRIMARY.BLACK}
        lineHeight={'28.8px'}
      >
        {number}
      </Typography>
      {/* <Stack direction={'row'} alignItems={'center'} gap={1}>
        {percentage < 0 ? <DownArrow /> : <UpArrow />}
        <Typography
          className="font-aspekta-500"
          fontSize={13}
          color={percentage < 0 ? CIMS_PRIMARY.RED : CIMS_PRIMARY.GREEN}
        >
          {Math.abs(percentage)} %
        </Typography>
        <Typography
          className="font-aspekta-400"
          fontSize={12}
          color={CIMS_PRIMARY.GRAY}
        >
          {' '}
          from last month
        </Typography>
      </Stack> */}
    </Box>
  );
};

export default Card_Container;
