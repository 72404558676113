import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';

interface TextProps extends TypographyProps {
  component?: React.ElementType;
}

export const PageHeadingTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-600 ' + (className ?? '')}
      sx={{
        fontSize: '17px',
        lineHeight: '20.4px',
        ...sx,
      }}
    />
  );
};

export const ActionMediumTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontSize: '12px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ListTableHead = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-700 ' + (className ?? '')}
      sx={{
        fontSize: '13px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ListHead2Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontSize: '12px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ComponentSubtitleTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-600 ' + (className ?? '')}
      sx={{
        fontSize: '10px',
        lineHeight: '12px',
        ...sx,
      }}
    />
  );
};

export const SectionHeadingTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontSize: '14px',
        lineHeight: '19.6px',
        ...sx,
      }}
    />
  );
};

export const ListGroupHeadingTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-700 ' + (className ?? '')}
      sx={{
        fontSize: '10px',
        lineHeight: '12.4px',
        letterSpacing: '0.07em',
        textTransform: 'uppercase',
        ...sx,
      }}
    />
  );
};

export const ComponentBody1Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-450 ' + (className ?? '')}
      sx={{
        fontSize: '13px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ComponentBody2Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-400 ' + (className ?? '')}
      sx={{
        fontSize: '12px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};
