import { useEffect, useState, ChangeEvent } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import VegaSelector from '../../../components/common/VegaSelector';
import { CreditCardIcon, UserIcon } from '../../../components/icon/Icons';
import { toLowerCase } from '../../../constants/commonFunction';
import { BACKGROUND } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import ManualAdjustmentService from '../../../services/ManualAdjustmentService';
import { ICardRefundOrder } from '../../../types/cardRefund';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { IConsolidatedAccountData } from '../../types/Commons';
import { fetchAccountList } from '../api/account';
import {
  addFunds,
  bulkSaveForFundLoad,
  bulkUploadProcessForFundLoad,
  fetchListFundLoad,
  fetchPastUploadForFundLoad,
  listCardRefundOrder,
} from '../api/transaction';
import MCCFilterSection from '../components/MCCUserManagement/MCCFilterSection';
import CardRefundList from '../components/MCCcardTransaction/CardRefund/CardRefundList';
import ListOfCardTransaction from '../components/MCCcardTransaction/ListOfCardTransaction';
import ListOfCustomerAccount from '../components/MCCcardTransaction/ListOfCustomerAccount';
import ManualAdjustment from '../components/MCCcardTransaction/ManualAdjustment/ManualAdjustment';
import BulkUploadWrapper from '../components/BulkUploadWrapper';
import {
  bulkSaveCustomers,
  bulkUploadCustomers,
  fetchPastUploadForCustomers,
} from '../api/cutomer';
import VegaModal from '../../../components/common/VegaModal';
import { AddCircleOutline } from '@mui/icons-material';
import BulkUpload from '../components/MCCAplicationManagement/BulkUpload';
import ReviewEntity from '../components/MCCAplicationManagement/ReviewEntity';
import PastUpload from '../components/MCCAplicationManagement/PastUpload';
import {
  IBulkUploadProps,
  IPastUploadFilesProps,
} from '../../types/applicationManagement';
import { BULK_UPLOAD_TEMPLATE } from '../../constants';
import { FileValidatorBuilder, FileExtension } from '../../../utils/FileValidator';

let tileData = [
  {
    id: 'RELOAD',
    text: 'Card Reload',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'REFUND',
    text: 'Card Refund',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'ADJUSTMENT',
    text: 'Manual Adjustment',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];

const RELOAD_DATA = [
  {
    id: 1,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'APPROVED',
  },
  {
    id: 2,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 2',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'REJECTED',
  },
  {
    id: 3,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 3',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'PENDING',
  },
];
const REFUND_DATA = [
  {
    id: 1,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'APPROVED',
  },
  {
    id: 2,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'REJECTED',
  },
  {
    id: 3,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'PENDING',
  },
];
const ADJUSTMENT_DATA = [
  {
    id: 1,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'APPROVED',
  },
  {
    id: 2,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'REJECTED',
  },
  {
    id: 3,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    totalBalance: '1,00,000',
    status: 'PENDING',
  },
];

const bulkUploadProps: IBulkUploadProps = {
  filename: '',
  invalidEntriesCount: null,
  invalidEntriesFileName: '',
  invalidEntriesFileUrl: '',
  totalEntries: null,
  uploadDate: '',
  validEntriesCount: null,
  validEntriesFileName: '',
  validEntriesFileUrl: '',
};

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.EXCEL, FileExtension.CSV])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const MCCcardTransaction = () => {
  const { setSnackbar } = useSnackbar();
  const [data, setData] = useState<any>([...RELOAD_DATA]);
  const { mccPlatformId: branchId } = useClientAuth();
  const [cardReloadData, setCardReloadData] = useState<any>([]);
  const [cardRefundData, setCardRefundData] = useState<ICardRefundOrder[]>([]);
  const [manualAdjustData, setManualAdjustData] = useState<any>([]);
  const [customerAccountData, setCustomerAccountData] = useState<
    IConsolidatedAccountData[]
  >([]);
  const [filterData, setFilterData] = useState<any>({});
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isCustomerAccountListShow, setIsCustomerAccountListShow] =
    useState<boolean>(false);
  const [activeState, setActiveState] = useState<any>(tileData[0]);

  ///////////// bulkUpload state \\\\\\\\\\\\\\\\\\\\\\\
  const [fileName, setFileName] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openPastUpload, setOpenPastUpload] = useState<boolean>(false);
  const [openEntities, setOpenEntities] = useState<boolean>(false);
  const [toggleButton, setToggleButton] = useState<boolean>(false);

  const [pastUploadFiles, setPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [passedPastUploadFiles, setPassedPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [failedPastUploadFiles, setFailedPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [bulkUploadData, setBulkUploadData] = useState<IBulkUploadProps>({
    ...bulkUploadProps,
  });
  const [error, setError] = useState<string>('');
  ///////////// bulkUpload state \\\\\\\\\\\\\\\\\\\\\\\

  const handleChange = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const selected = (data: any) => {
    setActiveState(data);
    if (data.id === 'REFUND') {
      setData([...REFUND_DATA]);
    } else if (data.id === 'ADJUSTMENT') {
      setData([...ADJUSTMENT_DATA]);
    } else {
      setData([...RELOAD_DATA]);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (
        fileType === 'text/csv' ||
        fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileSize <= 2) {
          setSelectedFile(file || null);
        } else {
          setError('File size exceeds 2MB. Please upload a smaller file.');
        }
      } else {
        setError('Please upload only CSV or XLSX files.');
      }
    }
  };

  const handleOnError = (message: string | null) => {
    setSelectedFile(null);
    setError(message ?? 'Something went wrong');
  };

  const onFileProceed = () => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);

    bulkUploadProcessForFundLoad(formData).then(async (res) => {
      setBulkUploadData(res.data);
      // const invalidFileURL = res.data.invalidEntriesFileUrl;
      setFileName(res.data.validEntriesFileName);
      setToggleButton(true);
    });
  };

  const onFileSave = (url: string) => {
    if (!url) {
      return;
    }

    const obj = {
      branchId,
      fileName,
      url,
    };

    bulkSaveForFundLoad(obj).then(async (res) => {
      onEntitiesOpen();
      setBulkUploadData({ ...bulkUploadProps });
      getCustomerAccountList();
      getRefundList();
      getFundList();
    });
  };

  const onFetchPastUpload = () => {
    fetchPastUploadForFundLoad(branchId).then((res) => {
      const singleArray = [
        ...res.data.completed,
        ...res.data.unknown,
        ...res.data.failed,
      ];
      setPastUploadFiles(singleArray);

      setPassedPastUploadFiles(res.data.completed);

      setFailedPastUploadFiles([...res.data.unknown, ...res.data.failed]);
    });
    onPastOpen();
  };

  const onOpen = () => {
    setOpen(!open);
    setSelectedFile(null);
  };

  const onEntitiesOpen = () => {
    setOpenEntities(!openEntities);
    setSelectedFile(null);
  };
  const onPastOpen = () => {
    setOpenPastUpload(!openPastUpload);
    setSelectedFile(null);
  };

  const getCustomerAccountList = async () => {
    setListLoading(true);
    console.log('filterData', filterData);
    fetchAccountList({
      value: filterData.mobile ? filterData.mobile : branchId,
      page: 0,
      pageSize: 50,
    })
      .then((res) => {
        const getData = res.data.records
          ?.map((value: any) => ({
            ...value,
            id: value?.account?.id ?? value?.customer?.customerId,
          }))
          .filter((val: any) => val.account !== null)
          .filter((val: any) => val.card !== null);
        setCustomerAccountData(getData);
        setIsCustomerAccountListShow(true);

        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  const onReload = (data: any) => {
    addFunds(data)
      .then((res) => {
        setSnackbar('Funds added!');
        setIsLoading(false);
        setFilterData({});
        getFundList();
        setIsCustomerAccountListShow(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add Funds'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const getRefundList = () => {
    listCardRefundOrder({
      branchId: branchId,
      // makerCheckerStatusList: [CheckerMakerStatusEnum.APPROVED],
    })
      .then((res) => {
        setCardRefundData(res.data);
      })
      .catch((err) => {
        setSnackbar('Error in fetching the refund list ', 'error');
      });
  };

  const getManualAdjustmentList = () => {
    ManualAdjustmentService.list({
      branchId: branchId,
      // makerCheckerStatusList: [CheckerMakerStatusEnum.APPROVED],
    })
      .then((res) => {
        setManualAdjustData(res);
      })
      .catch((err) => {
        setSnackbar('Error in fetching the manual adjustment list ', 'error');
      });
  };

  const getFundList = async () => {
    setListLoading(true);
    fetchListFundLoad({ branchId })
      .then((res) => {
        // const getData = res.data?.map((value: any) => ({
        //   ...value,
        //   id: value?.account?.id ?? value?.customer?.id,
        // }));
        setCardReloadData(res.data);
        // setCardRefundData([...REFUND_DATA]);
        // setManualAdjustData([...ADJUSTMENT_DATA]);

        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getFundList();
    getRefundList();
    getManualAdjustmentList();
    getCustomerAccountList();
  }, []);

  return (
    <>
      <VegaPage>
        <VegaSelector
          data={tileData}
          setActiveState={selected}
          active={activeState}
          header={`Card ${toLowerCase(activeState.id)}`}
          subHeader={` ${toLowerCase(activeState.id)} balance in the wallet`}
        />
        {activeState.id === 'RELOAD' && (
          <VegaCard>
            <BulkUploadWrapper
              onFetchPastUpload={onFetchPastUpload}
              onOpen={onOpen}
              templateUrl={BULK_UPLOAD_TEMPLATE.BRANCH_FUND_RELOAD}
            />
          </VegaCard>
        )}

        <MCCFilterSection
          state={filterData}
          handleChange={handleChange}
          onSearch={getCustomerAccountList}
          isSearch
        />

        {activeState.id === 'ADJUSTMENT' ? (
          <ManualAdjustment
            accountsData={customerAccountData}
            manualAdjustmentList={manualAdjustData}
            refetch={getManualAdjustmentList}
          />
        ) : (
          <>
            {isCustomerAccountListShow && (
              <ListOfCustomerAccount
                data={customerAccountData}
                onReload={onReload}
                activeState={activeState}
                getRefundList={getRefundList}
              />
            )}

            {activeState.id === 'REFUND' ? (
              <CardRefundList
                cardRefundData={cardRefundData}
                getRefundList={getRefundList}
              />
            ) : (
              <ListOfCardTransaction
                manualAdjustData={manualAdjustData}
                cardRefundData={cardRefundData}
                cardReloadData={cardReloadData}
                active={activeState}
                loading={listLoading}
                getFundList={getFundList}
              />
            )}
          </>
        )}
      </VegaPage>
      <VegaModal
        header={'Bulk Upload'}
        open={open}
        handleClose={onOpen}
        submitText={toggleButton ? 'Review Entity' : 'Process'}
        // disabled={!selectedFile || error.length > 0}
        isButton={true}
        handleSubmit={
          toggleButton
            ? () => {
                onOpen();
                onEntitiesOpen();
                setToggleButton(false);
              }
            : onFileProceed
        }
        buttonIcon={<AddCircleOutline />}
      >
        <BulkUpload
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          error={error}
          bulkUploadData={bulkUploadData}
          onError={(message) => {
            handleOnError(message);
          }}
          fileValidator={fileValidatorConfig}
        />
      </VegaModal>
      <VegaModal
        header={'Review Entiry'}
        open={openEntities}
        handleClose={() => {
          onEntitiesOpen();
          setBulkUploadData({ ...bulkUploadProps });
        }}
        // submitText={getComponents(status).submitText}
        width={900}
        isButton={false}
        // disabled={!selectedFile || error.length > 0}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
      >
        <ReviewEntity
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          bulkUploadData={bulkUploadData}
          onSaveFile={onFileSave}
        />
      </VegaModal>
      <VegaModal
        header={'Past Upload'}
        open={openPastUpload}
        handleClose={onPastOpen}
        // submitText={getComponents(status).submitText}
        width={900}
        isButton={false}
        // disabled={!selectedFile || error.length > 0}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
      >
        <PastUpload
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          pastUploadFiles={pastUploadFiles}
          passedPastUploadFiles={passedPastUploadFiles}
          failedPastUploadFiles={failedPastUploadFiles}
          // bulkUploadData={bulkUploadData}
        />
      </VegaModal>
    </>
  );
};

export default MCCcardTransaction;
