/** @format */

import { Typography } from '@mui/material';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import {
  ResourceType,
  useInstitutionalResources,
} from '../../hooks/useInstitutionalResources';
import { Merchant } from '../../types/resources';
import VegaSearchBy from '../common/VegaSearchBy';
import VegaTable from '../common/VegaTable';
import VegaTextV2 from '../common/v2/VegaTextV2';
import { VegaCard } from '../common';

function MerchantTable() {
  const { loading, fetchResource } = useInstitutionalResources();
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [filter, setFilter] = useState<{ search: string; searchBy: string }>({
    search: '',
    searchBy: MERCHANT_CLOUMNS[0].value,
  });

  async function fetchMerchants() {
    try {
      const response = await fetchResource<Merchant>({
        type: ResourceType.MERCHANT,
        page: page,
        pageSize: pageSize,
        searchBy: filter.searchBy,
        searchQuery: filter.search,
      });
      setMerchants(response.records);
      setRowCount(response.totalItems);
    } catch (error) {}
  }

  const onFilterChange = (name: string, value: string) => {
    if (name === 'searchBy') {
      setFilter((preVal: any) => ({
        ...preVal,
        ['search']: '',
        ['searchBy']: value,
      }));
    } else {
      setFilter((preVal: any) => ({
        ...preVal,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    fetchMerchants();
  }, [page, pageSize, filter.search, filter.searchBy]);

  return (
    <Fragment>
      <VegaSearchBy
        placeholder="Search"
        data={MERCHANT_CLOUMNS}
        searchByname="searchBy"
        searchName="search"
        key="search"
        state={filter}
        handleChange={onFilterChange}
      />
      <VegaCard noPad>
        <VegaTable
          data={merchants}
          columnDef={columnMerchant}
          idColumn={'id'}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
          loading={loading}
        />
      </VegaCard>
    </Fragment>
  );
}

export default MerchantTable;
const MERCHANT_CLOUMNS = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'MID',
    value: 'mid',
  },
  {
    text: 'TID',
    value: 'tid',
  },
];

const columnMerchant = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'logo',
    headerName: 'Logo',
    flex: 0.5,
    renderCell: (props: any) => {
      const logoUrl = props.row.logo ?? '';
      const hasLogo = logoUrl.length > 0;

      if (hasLogo) {
        return (
          <div
            style={{
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              height={'80%'}
              width={'80%'}
              style={{ objectFit: 'contain' }}
              src={logoUrl}
            ></img>
          </div>
        );
      }

      return <VegaTextV2 text="-" />;
    },
  },
  {
    field: 'mid',
    headerName: 'MIDs',
    flex: 1,
    renderCell: (props: any) => {
      const merchant = props.row as Merchant;
      const mids = merchant.mids;
      const commaSepratedIds = mids?.map((item) => item.mid).join(',');
      return <Typography>{commaSepratedIds}</Typography>;
    },
  },
  {
    field: 'tid',
    headerName: 'TIDs',
    flex: 1,
    renderCell: (props: any) => {
      const merchant = props.row as Merchant;
      const tids = merchant.tids;
      const commaSepratedIds = tids?.map((item) => item.tid).join(',');
      return <Typography>{commaSepratedIds}</Typography>;
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    renderCell: (props: any) => {
      const merchant = props.row as Merchant;
      const categories = merchant.mccCodes ?? [];
      const categoriesNames = new Set();
      categories?.forEach((item) => {
        categoriesNames.add(_.startCase(_.toLower(item.category?.name)));
      });
      const commaSepratedNames = Array.from(categoriesNames).join(', ');
      return <Typography>{commaSepratedNames}</Typography>;
    },
  },

  {
    field: 'action',
    headerName: '',
    flex: 0.1,
    renderCell: (props: any) => {
      return <></>;
    },
  },
];
