import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useEffect, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { VegaListItem } from '../common';
import { SidebarListItemType } from './SidebarLayout';

interface IProps {
  index: number;
  item: SidebarListItemType;
  isDrawerOpen: boolean;
}
const SELECTED_VIEW = 'view';

function GroupListItems({ item, index, isDrawerOpen }: IProps) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleClick = () => {
    setOpen(!open);
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const handleItemClick = (item: SidebarListItemType) => {
    // setSelectedItem(item);
    setSearchParams(createSearchParams({ [SELECTED_VIEW]: item.id }));
  };

  useEffect(() => {
    if (isDrawerOpen) {
      setOpen(false);
    }
  }, [isDrawerOpen]);
  return (
    <>
      <VegaListItem
        key={index}
        isMobile={isMobile}
        isSamllScreen={isSmallScreen}
        open={isDrawerOpen}
        id={item.id}
        isChecker={item.isChecker}
        icon={item.icon}
        label={item.label}
        selected={searchParams.get(SELECTED_VIEW) === item.id}
        onItemClick={() => handleClick()}
        rightIcon={open ? <ExpandLess /> : <ExpandMore />}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 2 }}>
          {item.children?.map((child, childIndex) => (
            <VegaListItem
              key={childIndex}
              isMobile={isMobile}
              isSamllScreen={isSmallScreen}
              open={isDrawerOpen}
              id={child.id}
              isChecker={child.isChecker}
              icon={child.icon}
              label={child.label}
              selected={searchParams.get(SELECTED_VIEW) === child.id}
              onItemClick={() => handleItemClick(child)}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default GroupListItems;
