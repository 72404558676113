import { useEffect, useState } from 'react';
import {
  Stack,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  BLUE,
  BORDER_GRAY,
  GREEN,
  GREY,
  RED,
  YELLOW,
} from '../../../../constants/style';
import VegaContainedButton from '../../../common/VegaContainedButton';
import VegaOutlineButton from '../../../common/VegaOutlineButton';
import dayjs from 'dayjs';
import {
  toLowerCase,
  useClickOutSide,
} from '../../../../constants/commonFunction';
import { InfoOutlined } from '@mui/icons-material';
import { useApplicationManagmentConfig } from '../../../../providers/ApplicationManagmentProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { dateFormat } from '../../../../mcc/variable';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import { VegaLimitOfferStatus } from '../../../../types/los';

interface IProps {
  onApprove: () => void;
  onRject: () => void;
}

interface IApplicationDetailsProps {
  key: string;
  value: string;
  color?: string;
  className?: string;
}

const ApplicationSummary = ({ onApprove, onRject }: IProps) => {
  const {
    selectedOfferData,
    getCustomerByCustomerId,
    getApplicationByApplicationId,
    customer,
  } = useApplicationManagmentConfig();
  const { selectedProgram } = useProgramData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setSnackbar } = useSnackbar();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const creationDate = customer?.createdAt
    ? new Date((customer?.createdAt as number) * 1000)
    : new Date();

  const ApplicationDetails: IApplicationDetailsProps[] = [
    {
      key: 'Application creation date',
      value: dateFormat(creationDate),
    },
    {
      key: 'Application ID',
      value: customer?.customerId ?? '--',
    },
    {
      key: 'Program Name',
      value: selectedProgram?.name ?? '--',
    },
  ];
  const getColorCode = (status: string | undefined) => {
    if (!status) return GREEN.dark;
    if (status === VegaLimitOfferStatus.ACCEPTED) {
      return GREEN.dark;
    } else if (
      status === VegaLimitOfferStatus.REJECTED ||
      status === VegaLimitOfferStatus.EXPIRED
    ) {
      return RED.dark;
    } else if (status === VegaLimitOfferStatus.PENDING) {
      return YELLOW.main;
    }
  };
  const ApplicationStatus: IApplicationDetailsProps[] = [
    {
      key: 'Status',
      value: toLowerCase(selectedOfferData.offerStatus ?? '--'),
      color: getColorCode(selectedOfferData.offerStatus),
      className: 'font-opensans-700',
    },
    {
      key: 'Action by',
      value: customer?.fullName ?? '--',
      color: GREY.grey_light,
      className: 'font-opensans-400',
    },
    {
      key: 'Date',
      value: dayjs(creationDate).format('DD MMMM YYYY'),
      color: GREY.grey_light,
      className: 'font-opensans-400',
    },
  ];

  const domNode = useClickOutSide(() => {
    setShowInfo(false);
  });

  useEffect(() => {
    if (!selectedOfferData?.applicationId) return;
    getApplicationByApplicationId(selectedOfferData?.applicationId);
  }, [selectedOfferData?.applicationId]);
  return (
    <Box>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={1}
        sx={{
          bgcolor: BLUE.lightest,
          borderTopLeftRadius: isMobile ? 10 : 20,
          borderTopRightRadius: isMobile ? 10 : 20,
          px: isMobile ? 2 : 4,
          py: 2,
        }}
      >
        <Typography
          className="font-opensans-700"
          fontSize={isMobile ? 12 : 18}
          color={GREY.darkest}
        >
          Application Summary
        </Typography>
        <Typography
          className="font-opensans-700"
          fontSize={isMobile ? 12 : 18}
          color={GREEN.dark}
        >
          New Customer
        </Typography>
      </Stack>
      <Box
        sx={{
          bgcolor: 'white',
          borderBottomLeftRadius: isMobile ? 10 : 20,
          borderBottomRightRadius: isMobile ? 10 : 20,
          px: isMobile ? 2 : 4,
          py: 2,
        }}
      >
        <Grid container>
          <Grid item container xs={12} sm={12} md={12} lg={8} xl={9}>
            <Grid item xs={12} sm={7} md={7} lg={6} xl={6}>
              {ApplicationDetails.map(
                (application: IApplicationDetailsProps) => (
                  <Stack direction={'row'} gap={1} sx={{ my: 0.5 }}>
                    <Typography
                      className="font-opensans-400"
                      fontSize={isMobile ? 12 : 14}
                      color={GREY.darkest}
                    >
                      {application.key}:
                    </Typography>
                    <Typography
                      className="font-opensans-400"
                      fontSize={isMobile ? 12 : 14}
                      color={GREY.grey_light}
                    >
                      {application.value}
                    </Typography>
                  </Stack>
                )
              )}
            </Grid>
            {selectedOfferData.offerStatus ===
              VegaLimitOfferStatus.ACCEPTED && (
              <Grid item xs={12} sm={5} md={5} lg={6} xl={6}>
                {ApplicationStatus.map(
                  (application: IApplicationDetailsProps) => (
                    <Stack direction={'row'} gap={1} sx={{ my: 0.5 }}>
                      <Typography
                        className="font-opensans-400"
                        fontSize={isMobile ? 12 : 14}
                        color={GREY.darkest}
                      >
                        {application.key}:
                      </Typography>
                      <Typography
                        className={application.className}
                        fontSize={isMobile ? 12 : 14}
                        color={application.color}
                      >
                        {application.value}
                      </Typography>
                    </Stack>
                  )
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
            {selectedOfferData.offerStatus === VegaLimitOfferStatus.REJECTED ? (
              <Box
                sx={{ position: 'relative', mt: isMobile ? 1 : 0 }}
                ref={domNode}
              >
                <Typography
                  className="font-opensans-400"
                  fontSize={isMobile ? 12 : 14}
                  color={GREY.darkest}
                >
                  Reason for Rejection:
                </Typography>
                <Stack direction={'row'} gap={1} sx={{ my: 0.5 }}>
                  <Typography
                    className="font-opensans-400"
                    fontSize={isMobile ? 12 : 14}
                    color={GREY.grey_light}
                  >
                    KYC documents not proper
                  </Typography>
                  <InfoOutlined
                    sx={{ color: GREY.grey_light, cursor: 'pointer' }}
                    onClick={() => setShowInfo(!showInfo)}
                  />
                </Stack>
                {showInfo && (
                  <Box
                    sx={{
                      border: BORDER_GRAY,
                      borderRadius: '9px',
                      bgcolor: 'white',
                      width: '100%',
                      p: 2,
                      height: '160px',
                      position: 'absolute',
                      top: 55,
                      zIndex: 9999,
                    }}
                  >
                    Remarks come here
                  </Box>
                )}
              </Box>
            ) : (
              selectedOfferData.offerStatus ===
                VegaLimitOfferStatus.PENDING && (
                <Box
                  mt={isMobile ? 2 : 0}
                  mb={1}
                  height={'100%'}
                  sx={{ position: 'relative' }}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <VegaOutlineButton
                    // sx={{ ...(isMobile && { width: '100%' }) }}
                    isPrimary
                    fullWidth
                    text="Reject"
                    py={1.5}
                    onClick={onRject}
                  />
                  <VegaContainedButton
                    sx={{ width: '100%' }}
                    isPrimary
                    text="Approve"
                    py={1.5}
                    onClick={onApprove}
                  />
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ApplicationSummary;
