import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { EntityStatusEnum } from '../../../../enums/commons';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { AddCardAllotmentOrderRequest } from '../../../../types/card';
import { Level } from '../../../../types/client';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { CardAllocationService, EntityService } from '../../../apis';
import CimsDrawer from '../../../components/CimsDrawer';
import CimsButton from '../../../components/common/CimsButton';
import { getParentLevelForALevel } from '../../../utils';
import SelectProgramAndBin from '../../Update_Card_Inventory/SelectProgramAndBin';
import { useCardIndentationOrder } from '../context';
import { EditFormFiled } from '../../../../components/common/deprecated/EditFormFiled';
import { DivisionService } from '../../../../api/division';

interface IProp {
  isOpen: boolean;
  onClose: () => void;
}

function RequestCardModal({ isOpen, onClose }: IProp) {
  const hookForm = useForm<AddCardAllotmentOrderRequest>();
  const { control } = hookForm;
  const { clientId, userDivisionId, user, userDivisionType } = useClientAuth();

  const isUserBC = userDivisionType === 'BUSINESS_CORRESPONDENT';
  const { setSnackbar } = useSnackbar();
  const { listIndentationOrderQuery } = useCardIndentationOrder();

  const parentEntityId = useMemo(
    () =>
      user?.completeRoleProgramEntityDtos?.at(0)?.divisionDto?.parentDivisionId,
    [user?.completeRoleProgramEntityDtos]
  );

  const onSubmit = () => {
    const data = hookForm.getValues();

    CardAllocationService.addCardIndentationOrder({
      binId: data.binId,
      programId: data.programId || '',
      sourceDivisionId: data.sourceDivisionId || parentEntityId,
      destinationDivisionId: userDivisionId || '',
      numberOfKits: data.numberOfKits || 0,
    })
      .then((res) => {
        setSnackbar('Cards Requested Successfully', 'success');
        listIndentationOrderQuery && listIndentationOrderQuery.refetch();
        hookForm.reset();
      })
      .catch((err: any) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err) || 'Error in requesting cards',
          'error'
        );
      });
    onClose();
  };

  const divisionQuery = useQuery({
    queryKey: ['fetchDivisions', { clientId }],
    queryFn: () =>
      DivisionService.fetchDivisions({
        clientId: clientId || '',
        id: parentEntityId,
        size: 100,
      }),
    enabled: !!clientId && !!parentEntityId,
    select(data) {
      return data.records.map((division) => ({
        value: division.id,
        label: division.divisionCode,
      }));
    },
  });

  return (
    <CimsDrawer
      open={isOpen}
      header="Request Card"
      onClose={onClose}
      isSubmit
      submitButton={
        <CimsButton
          variant="contained"
          onClick={onSubmit}
          type="submit"
          autoFocus={true}
          disabled={!hookForm.formState.isValid}
        >
          Submit
        </CimsButton>
      }
    >
      <FormProvider {...hookForm}>
        <Stack spacing={3}>
          {isUserBC ? (
            <Controller
              name="sourceDivisionId"
              control={control}
              render={({ field }) => (
                <EditFormFiled
                  {...field}
                  title={'ADMIN'}
                  description={field.value}
                  placeHolder={'ADMIN'}
                  disabled
                />
              )}
            />
          ) : (
            <Controller
              name="sourceDivisionId"
              control={hookForm.control}
              rules={{ required: isUserBC ? false : true }}
              render={({ field }) => (
                <EditFormFiled
                  title="BRANCH CORRESPONDENT"
                  description={field.value}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                  placeHolder={'Select BC'}
                  options={divisionQuery.data ?? []}
                />
              )}
            />
          )}

          <SelectProgramAndBin divisionId={userDivisionId} />

          <Controller
            name="numberOfKits"
            control={control}
            rules={{
              required: 'Required',
              min: 1,
              pattern: { value: /^[0-9]*$/, message: 'Invalid no of cards' },
            }}
            render={({ field }) => (
              <EditFormFiled
                title={'NO.OF CARDS ASSIGNED '}
                description={field.value}
                {...field}
                type="text"
                onChange={(val) => {
                  const parsedVal = parseInt(val || '');
                  const count = isNaN(parsedVal) ? '' : parsedVal.toString();
                  field.onChange(count);
                }}
                placeHolder={'Enter number of Cards'}
              />
            )}
          />
        </Stack>
      </FormProvider>
    </CimsDrawer>
  );
}

export default RequestCardModal;
