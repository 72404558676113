/** @format */

import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PRIMARY } from '../../constants/style';

type Props = {
  value?: number | string | any;
  onChange: (event: any) => void;
  placeholder?: string;
  label?: string;
  key?: string;
  disabled?: boolean;
  minDate?: number | string | any;
} & TextFieldProps;

const VegaDatePicker = ({
  value,
  onChange,
  label,
  disabled,
  minDate,
  ...inputProps
}: Props) => {
  return (
    <FormControl fullWidth sx={{ height: '50px' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value={value}
          onChange={onChange}
          inputFormat="DD-MM-YYYY"
          disabled={disabled}
          minDate={minDate}
          renderInput={(params) => (
            <TextField
              size="medium"
              style={{ borderRadius: '7px', height: '50px' }}
              InputProps={{
                style: { borderRadius: '9px' },
              }}
              sx={{
                '& .MuiInputBase-input, .MuiInputLabel-root': {
                  fontFamily: `var(--font-Aspekta-450) !important`,
                  fontSize: '13px',
                  lineHeight: '120%',
                  color: '#1B1D22',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#C5C8D0',
                    borderWidth: '1px',
                    borderRadius: '6px',
                  },
                  ':hover fieldset': {
                    borderColor: '#C5C8D0',
                  },
                },
                '& .MuiInputBase-root': {
                  height: '100%',
                },
                '& .MuiInputLabel-root': {
                  // transform: `translate(14px, 16px) scale(1)`,
                },
                '& .MuiInputLabel-shrink': {
                  transform: 'translate(14px, -6px) scale(0.75)',
                },
                '& .MuiFormLabel-root': {
                  fontFamily: 'var(--font-Aspekta-500) !important',
                  fontSize: 13,
                  '&.Mui-focused': {
                    color: `${PRIMARY.main} !important`,
                  },
                },
              }}
              fullWidth
              InputLabelProps={{
                style: { color: '#AFAFAF', textAlign: 'center' },
              }}
              {...params}
              className="myDatePicker"
              {...inputProps}
            />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default VegaDatePicker;
