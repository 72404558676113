import { Edit } from '@mui/icons-material';
import { Box, Stack, Grid, styled } from '@mui/material';
import { CardType, useCardConfig } from '../../providers/CardConfigProvider';
import VegaCardV2 from '../common/v2/VegaCardV2';
import VegaPrimaryButton from '../common/v2/VegaPrimaryButton';
import VegaTextV2 from '../common/v2/VegaTextV2';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import { AddOnCardConfigIcon } from '../icon/Icons';
import { CardSizeVairant } from './ProgramCard/CardComponents';
import ProgramCardBack from './ProgramCard/ProgramCardBack';
import ProgramCardFront from './ProgramCard/ProgramCardFront';

interface CardConfigPreviewCardProps {
  title: string;
  description: string;
  onConfigureClick: () => void;
  cardType: CardType;
}

const NoCardConfiguredView = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <VegaTextV2 text="No card configured"></VegaTextV2>
    </div>
  );
};

const CardPreviewContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };
});

function CardConfigPreviewCard({
  title,
  description,
  onConfigureClick,
  cardType,
}: CardConfigPreviewCardProps) {
  const { physicalCardConfig, virtualCardConfig } = useCardConfig();

  const cardConfigExists = () => {
    var hasConfig = false;
    if (physicalCardConfig.programId || virtualCardConfig.programId) {
      hasConfig = true;
    }
    return hasConfig;
  };

  const renderCardPreview = () => {
    if (cardType == CardType.PHYSICAL) {
      return (
        <CardPreviewContainer>
          <VegaConditionalWrapper
            condition={!!physicalCardConfig}
            ifTrue={
              <Stack
                direction={'row'}
                spacing={2}
                alignItems="center"
                justifyContent={'center'}
              >
                <ProgramCardFront
                  variant={CardSizeVairant.SMALL}
                  cardType={cardType}
                />
                <ProgramCardBack variant={CardSizeVairant.SMALL} />
              </Stack>
            }
            ifFalse={<NoCardConfiguredView />}
          />
        </CardPreviewContainer>
      );
    }
    if (cardType == CardType.VIRTUAL) {
      return (
        <CardPreviewContainer>
          <VegaConditionalWrapper
            condition={!!virtualCardConfig}
            ifTrue={
              <ProgramCardFront
                variant={CardSizeVairant.SMALL}
                cardType={CardType.VIRTUAL}
              />
            }
            ifFalse={<NoCardConfiguredView />}
          />
        </CardPreviewContainer>
      );
    }
    return <></>;
  };

  return (
    <VegaCardV2 fullHeight>
      {cardConfigExists() && (
        <VegaCardV2>
          <Box style={{ height: '7.75rem' }}>{renderCardPreview()}</Box>
        </VegaCardV2>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: '0.75rem',
        }}
      >
        <Stack flex={2}>
          <VegaTextV2 text={title} />
          <VegaTextV2 text={description} variant="caption" />
        </Stack>
        <Grid
          container
          columns={3}
          justifyContent="flex-end"
          alignItems={'end'}
        >
          <Grid item>
            <VegaPrimaryButton
              onClick={onConfigureClick}
              variant="outlined"
              startIcon={
                cardConfigExists() ? <Edit /> : <AddOnCardConfigIcon />
              }
              text={cardConfigExists() ? 'Edit' : 'Configure Card Design'}
            />
          </Grid>
        </Grid>
      </div>
    </VegaCardV2>
  );
}

export default CardConfigPreviewCard;
