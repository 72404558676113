import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogProps, IconButton, Stack } from '@mui/material';

import { COMMON } from '../../constants/style';
import { VPBORDER } from '../theme';
import VPButton from './VPButton';

export interface IButtonGroupProps {
  text: string;
  onSubmit: () => void;
  icon: JSX.Element | string;
  isOutline: boolean;
  disabled?: boolean;
}

interface IModalProps {
  open: boolean;
  children?: JSX.Element;
  header: string;
  handleClose: () => void;
  buttonGroup?: IButtonGroupProps[];
  maxWidth: DialogProps['maxWidth'];
  subTitleContainer?: JSX.Element;
  footer?: JSX.Element;
}

const VPModal = ({
  open,
  children,
  header,
  handleClose,
  buttonGroup,
  maxWidth,
  subTitleContainer,
  footer,
}: IModalProps) => {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        sx={{ borderRadius: '20px' }}
        onClose={handleClose}
        open={open}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: '#E5F7FC',
            borderBottom: VPBORDER.DARKGRAY,
            position: 'relative',
          }}
        >
          <Box sx={{ p: 3, width: '100%' }}>
            <Stack direction={'row'} alignItems={'center'}>
              <label
                className="font-aspekta-600"
                style={{
                  fontSize: 17,
                  color: COMMON.darkGray,
                  lineHeight: '15.6px',
                  marginRight: '10px',
                }}
              >
                {header}
              </label>
            </Stack>
            {subTitleContainer && subTitleContainer}
          </Box>
          <IconButton
            sx={{ position: 'absolute', right: 10, top: 5 }}
            onClick={handleClose}
          >
            <Close sx={{ color: '#3A4A5F' }} />
          </IconButton>
        </Box>
        <Box>{children}</Box>

        {(buttonGroup?.length || footer) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              px: 2,
              py: 1.5,
              borderTop: VPBORDER.DARKGRAY,
            }}
          >
            <Stack direction={'row'} spacing={2}>
              {buttonGroup?.length
                ? buttonGroup?.map(
                    (button: IButtonGroupProps, index: number) => {
                      return (
                        <VPButton
                          disabled={button.disabled}
                          onClick={button.onSubmit}
                          variant={button.isOutline ? 'outlined' : 'contained'}
                          key={`${index}-${button.text}`}
                        >
                          {button.text}
                        </VPButton>
                      );
                    }
                  )
                : footer}
            </Stack>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default VPModal;
