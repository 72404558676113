/** @format */

import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  createNewProgram,
  deleteProgramAgreementTwo,
  deleteProgramLoanEMIAgreement,
  deleteProgramSanctionLetter,
  getProgramDefaultFormatDocumentUrl,
  getProgramFormatDocumentUrl,
  listForClient,
  updateExistingProgram,
  updateProgramAgreementTwo,
  updateProgramLoanEMIAgreement,
  updateProgramSanctionLetter,
  updateProgramStatus,
} from '../api/program';
import { SELECTED_VIEW } from '../containers/ClientDashboardContainer';
import {
  VegaCreateProgramType,
  VegaDeleteProgramDocument,
  VegaProgramDocumentType,
  VegaProgramDocumentUrlRequest,
  VegaProgramType,
  VegaUpdateProgramDocument,
  VegaUpdateProgramType,
} from '../types/program';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useClientAuth } from './ClientProvider';
import { useSnackbar } from './SnackbarProvider';

export type ProgramDataContextType = {
  programs: VegaProgramType[];
  allowedPrograms: VegaProgramType[];
  selectedProgram: VegaProgramType | null;
  setSelectedProgram: (program: VegaProgramType) => void;
  fetchPrograms: () => void;
  loading: boolean;
  isLoading: boolean;
  error: boolean;
  addProgram: (program: VegaCreateProgramType) => Promise<VegaProgramType>;
  updateProgram: (program: VegaUpdateProgramType) => void;
  updateProgramStatusLocal: (programId: string, newStatus: string) => void;
  updateStatusLoading: boolean;
  updateAgreementTwo: (data: VegaUpdateProgramDocument) => void;
  updateSanctionLetter: (data: VegaUpdateProgramDocument) => void;
  updateLoanEMIAgreement: (data: VegaUpdateProgramDocument) => void;
  deleteAgreementTwo: (data: VegaDeleteProgramDocument) => void;
  deleteSanctionLetter: (data: VegaDeleteProgramDocument) => void;
  deleteLoanEMIAgreement: (data: VegaDeleteProgramDocument) => void;
  getFormatDocumentUrl: (data: VegaProgramDocumentUrlRequest) => any;
  getDefaultFormatDocumentUrl: (documentType: VegaProgramDocumentType) => any;
};

type Props = {};

export const ProgramDataContext =
  React.createContext<ProgramDataContextType | null>(null);
export const useProgramData = () =>
  React.useContext(ProgramDataContext) as ProgramDataContextType;

const ProgramDataProvider = (props: any) => {
  const { clientId, getAllowedProgramRolesForSubmodule } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const [programs, setPrograms] = React.useState<VegaProgramType[]>([]);
  const [allowedPrograms, setAllowedPrograms] = React.useState<
    VegaProgramType[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [updateStatusLoading, setUpdateStatusLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedProgram, _setSelectedProgram] =
    React.useState<VegaProgramType | null>(null);

  React.useEffect(() => {
    if (!loading) fetchPrograms();
  }, [clientId]);

  React.useEffect(() => {
    handleAllowedProgramsForViewFiltering();
  }, [searchParams, programs]);

  const fetchPrograms = () => {
    if (clientId) {
      setLoading(true);
      setError(false);
      listForClient(clientId)
        .then((resp) => {
          const programsFromResponse = resp?.data?.records?.reverse();
          if (!programsFromResponse) {
            console.error('Failed to fetch programs for client');
            setError(true);
          } else {
            setPrograms(programsFromResponse);
            const selectedProgram = programsFromResponse?.[0];
            setSelectedProgram(selectedProgram);
          }
        })
        .catch((err) => setError(true))
        .finally(() => setLoading(false));
    }
  };

  const handleAllowedProgramsForViewFiltering = () => {
    const currentView = searchParams.get(SELECTED_VIEW);
    if (currentView && programs?.length > 0) {
      const programsForSubmodule =
        getAllowedProgramRolesForSubmodule(currentView);
      if (programsForSubmodule) {
        const listOfPrograms = Object.keys(programsForSubmodule);
        if (!listOfPrograms.includes('ALL')) {
          const filteredPrograms = programs.filter((program) =>
            listOfPrograms.includes(program.programId)
          );
          setAllowedPrograms(filteredPrograms);
          setSelectedProgram(filteredPrograms?.[0]);
        } else {
          setAllowedPrograms([...programs]);
        }
      }
    } else {
      setAllowedPrograms([...programs]);
    }
  };

  const setSelectedProgram = (program: VegaProgramType) => {
    _setSelectedProgram(program);
  };

  function addProgram(
    newProgram: VegaCreateProgramType
  ): Promise<VegaProgramType> {
    return new Promise((resolve, reject) => {
      if (clientId) {
        setIsLoading(true);
        createNewProgram({ ...newProgram, clientId })
          .then((res) => {
            const createdProgram = res.data as VegaProgramType;
            addNewProgramToList(createdProgram);
            setSnackbar('Program Created!');
            setIsLoading(false);
            resolve(createdProgram);
          })
          .catch((err) => {
            console.error('failed to create program');
            setSnackbar(getErrorMessageFromErrorObj(err), 'error');
            setIsLoading(false);
            reject(err);
          });
      }
    });
  }

  const updateProgram = (updateRequest: VegaUpdateProgramType) => {
    updateExistingProgram(updateRequest)
      .then((res) => {
        const createdProgram = res.data as VegaProgramType;
        updateProgramInList(createdProgram);
      })
      .catch((err) => {
        console.error('failed to update program');
      });
  };

  const addNewProgramToList = (
    newProgram: VegaProgramType,
    setAsSelected = true
  ) => {
    const newProgramList = [newProgram, ...programs];
    if (setAsSelected) setSelectedProgram(newProgram);
    setPrograms(newProgramList);
  };

  const updateProgramInList = (updatedProgram: VegaProgramType) => {
    const updatedProgramList = [
      ...programs.map((program) => {
        if (program.programId === updatedProgram.programId) {
          return updatedProgram;
        }
        return program;
      }),
    ];
    setPrograms(updatedProgramList);
    setSelectedProgram(updatedProgram);
  };

  const updateProgramStatusLocal = (progarmId: string, newStatus: string) => {
    setUpdateStatusLoading(true);
    updateProgramStatus(progarmId, newStatus)
      .then((res) => {
        setSnackbar('Status Updatd Successfully');
        const updatedProgram = programs.filter(
          (program) => program.programId === progarmId
        )[0];
        updateProgramInList({ ...updatedProgram, status: newStatus });
      })
      .catch((err) => setSnackbar(getErrorMessageFromErrorObj(err), 'error'))
      .finally(() => setUpdateStatusLoading(false));
  };

  const updateAgreementTwo = (data: VegaUpdateProgramDocument) => {
    setIsLoading(true);
    updateProgramAgreementTwo({
      programId: data.programId,
      file: data.file,
    })
      .then((response) => {
        const updatedProgram = response.data as VegaProgramType;
        updateProgramInList(updatedProgram);
        setSnackbar('Agreement Added Successfully');
      })
      .catch((error) => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateSanctionLetter = (data: VegaUpdateProgramDocument) => {
    updateProgramSanctionLetter({
      programId: data.programId,
      file: data.file,
    })
      .then((response) => {
        setSnackbar('Sanction Letter Added Successfully');
        const updatedProgram = response.data as VegaProgramType;
        updateProgramInList(updatedProgram);
      })
      .catch((error) => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateLoanEMIAgreement = (data: VegaUpdateProgramDocument) => {
    updateProgramLoanEMIAgreement({
      programId: data.programId,
      file: data.file,
    })
      .then((response) => {
        setSnackbar('Loan EMI Agreement Added Successfully');
        const updatedProgram = response.data as VegaProgramType;
        updateProgramInList(updatedProgram);
      })
      .catch((error) => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteAgreementTwo = (data: VegaDeleteProgramDocument) => {
    deleteProgramAgreementTwo({ programId: data.programId })
      .then((response) => {
        const updatedProgram = response.data as VegaProgramType;
        updateProgramInList(updatedProgram);
        setSnackbar('Agreement Deleted Successfully');
      })
      .catch((error) => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteSanctionLetter = (data: VegaDeleteProgramDocument) => {
    deleteProgramSanctionLetter({ programId: data.programId })
      .then((response) => {
        const updatedProgram = response.data as VegaProgramType;
        updateProgramInList(updatedProgram);
        setSnackbar('Sanction Letter Deleted Successfully');
      })
      .catch((error) => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteLoanEMIAgreement = (data: VegaDeleteProgramDocument) => {
    deleteProgramLoanEMIAgreement({ programId: data.programId })
      .then((response) => {
        const updatedProgram = response.data as VegaProgramType;
        updateProgramInList(updatedProgram);
        setSnackbar('Loan EMI Agreement Deleted Successfully');
      })
      .catch((error) => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFormatDocumentUrl = async (data: VegaProgramDocumentUrlRequest) => {
    const responseData = await getProgramFormatDocumentUrl(data);
    return responseData.data;
  };

  const getDefaultFormatDocumentUrl = async (
    documentType: VegaProgramDocumentType
  ) => {
    const responseData = await getProgramDefaultFormatDocumentUrl(documentType);
    return responseData.data;
  };

  return (
    <ProgramDataContext.Provider
      value={{
        programs,
        allowedPrograms,
        selectedProgram,
        setSelectedProgram,
        fetchPrograms,
        isLoading,
        loading,
        error,
        addProgram,
        updateProgram,
        updateProgramStatusLocal,
        updateStatusLoading,
        updateAgreementTwo,
        updateSanctionLetter,
        updateLoanEMIAgreement,
        deleteAgreementTwo,
        deleteSanctionLetter,
        deleteLoanEMIAgreement,
        getFormatDocumentUrl,
        getDefaultFormatDocumentUrl,
      }}
    >
      {props.children}
    </ProgramDataContext.Provider>
  );
};

export default ProgramDataProvider;
