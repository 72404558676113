import { Box } from '@mui/material';
import { useState } from 'react';
import { ListCardRequest } from '../../../../types/card';
import CIMS_Header from '../../../components/common/CIMS_Header';
import { CardInvetoryFilterModal, ListOfCardInventory } from '../../../modules';

const CardInventory = () => {
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [filterData, setFilterData] = useState<Partial<ListCardRequest>>({});
  const toggleFilter = () => {
    setIsFilterShow(!isFilterShow);
  };
  const onFilter = () => {
    toggleFilter();
  };

  return (
    <>
      <Box>
        <CIMS_Header text="Card Inventory" />
        <Box
          sx={{
            p: 2,
            pt: 1,
          }}
        >
          <Box>
            <ListOfCardInventory onFilter={onFilter} filterData={filterData} />
          </Box>
        </Box>
      </Box>

      <CardInvetoryFilterModal
        isFilterShow={isFilterShow}
        setIsFilterShow={setIsFilterShow}
        toggleFilter={toggleFilter}
        setFilterData={setFilterData}
      />
    </>
  );
};

export default CardInventory;
