import { Typography } from '@mui/material';
import { VegaTable } from '../../../../components/common';
import { BinAllocation } from '../../../../types/card';
import { useState, useEffect, useMemo } from 'react';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  IPaginationProps,
  fetchBinAlloctionListForClient,
} from '../../../Back-office/api/inventory';
import { fetchBranchById } from '../../api/Branch';
import { IBranchProps } from '../../pages/MCCBranch';
import { IFilterProps } from '../../../Back-office/pages/MCCAplicationManagement';

type IExtAllocatedBins = {
  branchName: string;
} & BinAllocation;

interface IProps {
  loading: boolean;
  filterData: IFilterProps;
}

const ListOfAllocatedBinsForAClient = ({ filterData, loading }: IProps) => {
  const { clientId } = useClientAuth();
  const [allocatedBinsForClient, setAllocatedBinsForClient] = useState<
    Array<IExtAllocatedBins>
  >([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [branchesMap, setBranchesMap] =
    useState<Record<string, IBranchProps>>();

  const onPageChange = (page: number) => {
    setPage(page);
  };
  const onPageSizeChange = (pageSize: number) => {
    setSize(pageSize);
  };

  const filteredAllocatedBinsForClient = useMemo(() => {
    if (!allocatedBinsForClient.length) return [];
    return allocatedBinsForClient.filter((binData) => {
      return binData.branchName.toLowerCase().includes(filterData.mobile);
    });
  }, [filterData, allocatedBinsForClient]);

  const getBranchForId = async (branchId: string): Promise<IBranchProps> => {
    if (branchesMap && branchesMap[branchId]) {
      return branchesMap[branchId];
    }

    const res = await fetchBranchById(branchId);

    setBranchesMap((pre) => ({
      ...pre,
      [branchId]: res.data,
    }));

    return res.data;
  };

  const getBinsForClient = async () => {
    const data = {
      clientId,
      page,
      size,
      entityLevel: 'CLIENT',
    } as IPaginationProps;
    fetchBinAlloctionListForClient(data).then(async (res) => {
      const allocatedBins: IExtAllocatedBins[] = res.data.records;
      setRowCount(res.data.totalItems);
      for (let i = 0; i < allocatedBins.length; i++) {
        const branch = await getBranchForId(allocatedBins[i].entityId);
        allocatedBins[i].branchName = branch.branchName;
      }
      setAllocatedBinsForClient(allocatedBins);
    });
  };

  useEffect(() => {
    clientId && getBinsForClient();
  }, [clientId, page, size]);
  return (
    <VegaTable
      data={filteredAllocatedBinsForClient}
      columnDef={COLUMN}
      idColumn="id"
      loading={loading}
      paginationMode={'server'}
      page={page}
      pageSize={size}
      rowCount={rowCount}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      // disableSelectionOnClick={true}
      // checkboxSelection={true}
      // onRowSelect={(e) => console.log('e', e)}
    />
  );
};

export default ListOfAllocatedBinsForAClient;

const COLUMN = [
  {
    field: 'branchName',
    headerName: <Typography variant="body2">Branch Name</Typography>,
    flex: 1.2,
  },
  {
    field: 'binId',
    headerName: <Typography variant="body2">Bin No</Typography>,
    flex: 1.5,
  },
  // {
  //   field: 'programName',
  //   headerName: <Typography variant="body2">Program Name</Typography>,
  //   flex: 1,
  // },
  {
    field: 'noOfKitsAvailable',
    headerName: (
      <Typography className="font-aspekta-500" variant="body2">
        Unsold
      </Typography>
    ),
    flex: 1,
    renderCell: (props: any) => {
      if (!props.row.noOfKitsAvailable) return '--';
      return (
        <Typography className="font-aspekta-500" variant="body2">
          {props.row.noOfKitsAvailable}
        </Typography>
      );
    },
  },
  {
    field: 'noOfKitsSold',
    headerName: (
      <Typography className="font-aspekta-500" variant="body2">
        Sold
      </Typography>
    ),
    flex: 1,
    renderCell: (props: any) => {
      if (!props.row.noOfKitsSold) return '--';
      return (
        <Typography className="font-aspekta-500" variant="body2">
          {props.row.noOfKitsSold}
        </Typography>
      );
    },
  },

  {
    field: 'totalKits',
    headerName: (
      <Typography className="font-aspekta-500" variant="body2">
        {' '}
        Total
      </Typography>
    ),
    flex: 0.7,
    renderCell: (props: any) => {
      if (!props.row.totalKits) return '--';
      return (
        <Typography className="font-aspekta-500" variant="body2">
          {props.row.totalKits}
        </Typography>
      );
    },
  },
];
