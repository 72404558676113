import { Box, Grid } from '@mui/material';
import { VegaTextField } from '../../../../../components/common';
import VegaSelect from '../../../../../components/common/VegaSelect';

import { Controller, UseFormReturn } from 'react-hook-form';
import { TransactionIndicator } from '../../../../../enums/transaction';
import { IManualAdjustmentRequest } from '../../../../types/ManualAdjustment';

interface IProps {
  isDisabled?: boolean;
  currencies: Array<string>;
  hookForm: UseFormReturn<IManualAdjustmentRequest, any, undefined>;
}

function ManualAdjustmentDrawer({ isDisabled, currencies, hookForm }: IProps) {
  const {
    control,

    formState: { errors },
  } = hookForm;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} mt={2}>
          <Controller
            name="accountId"
            control={control}
            defaultValue=""
            rules={{ required: 'Account Id is required' }}
            render={({ field }) => (
              <VegaTextField
                {...field}
                label="Account ID"
                type="text"
                placeholder="Account ID"
                disabled
                fullWidth
                error={!!errors.accountId}
                helperText={errors.accountId?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Controller
            name="adjustmentType"
            control={control}
            defaultValue={TransactionIndicator.CREDIT}
            rules={{ required: 'AdjustmentType is required' }}
            render={({ field }) => (
              <VegaSelect
                {...field}
                options={Object.keys(TransactionIndicator)}
                key="adjustmentType"
                selected={field.value}
                label="Adjustment Type"
                disabled={isDisabled}
                error={!!errors.adjustmentType}
                errorHelperText={errors.adjustmentType?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Controller
            name="adjustmentReason"
            control={control}
            defaultValue=""
            rules={{ required: 'Adjustment reason is required' }}
            render={({ field }) => (
              <VegaTextField
                {...field}
                label="Adjustment Reason"
                placeholder="Reason"
                disabled={isDisabled}
                fullWidth
                error={!!errors.adjustmentReason}
                helperText={errors.adjustmentReason?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Controller
            name="amount"
            control={control}
            defaultValue={0}
            rules={{ required: 'Amount is required', min: 0 }}
            render={({ field }) => (
              <VegaTextField
                {...field}
                label="Amount"
                type="number"
                placeholder="Amount"
                disabled={isDisabled}
                fullWidth
                error={!!errors.amount}
                helperText={errors.amount?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Controller
            name="currency"
            control={control}
            defaultValue=""
            rules={{ required: 'Currency is required' }}
            render={({ field }) => (
              <VegaSelect
                {...field}
                options={currencies}
                key="currency"
                selected={field.value}
                label="Source Currency"
                disabled={isDisabled}
                error={!!errors.currency}
                errorHelperText={errors.currency?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ManualAdjustmentDrawer;
