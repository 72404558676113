import axios from 'axios';
import { BASE_URL, CLIENT, DIVISION } from './endpoints';
import {
  CreateDivisionPayload,
  DivisionDto,
  FetchChildDivisionPayload,
  FetchDivisionPayload,
  UpdateDivisionStatusRequest,
} from '../types/division';
import { PaginatedResponse } from '../utils/api';

const DIVISION_BASE_URL = BASE_URL + CLIENT.BASE + DIVISION.BASE;

export class DivisionService {
  static async fetchDivisions(payload: FetchDivisionPayload) {
    const response = await axios.post<PaginatedResponse<DivisionDto>>(
      DIVISION_BASE_URL + DIVISION.FETCH_DIVISIONS,
      payload
    );

    return response.data;
  }

  static async fetchChildDivisions(payload: FetchChildDivisionPayload) {
    const response = await axios.get<PaginatedResponse<DivisionDto>>(
      DIVISION_BASE_URL + DIVISION.FETCH_CHILD_DIVISIONS,
      { params: payload }
    );

    return response.data;
  }

  static async updateDivisionStatus(payload: UpdateDivisionStatusRequest) {
    const response = await axios.patch<DivisionDto>(
      DIVISION_BASE_URL + DIVISION.UPDATE_STATUS,
      payload
    );

    return response.data;
  }

  static async createDivision(payload: CreateDivisionPayload) {
    const response = await axios.post<DivisionDto>(DIVISION_BASE_URL, payload);

    return response.data;
  }
}
