import { ChevronRight } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Avatar, Chip, IconButton, Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import { memo } from 'react';
import { VegaCard, VegaTable } from '../../../../../components/common';
import { BACKGROUND } from '../../../../../constants/style';
import { EntityStatusEnum } from '../../../../../enums/commons';
import { IPartner } from '../../../../../types/partner';
import { getInitials } from '../../../../../utils/stringUtils';

type IProps = {
  data: IPartner[];
  isLoading?: boolean;
  setSelectedPartner: React.Dispatch<React.SetStateAction<IPartner | null>>;
};

function ListPartners({ data, isLoading, setSelectedPartner }: IProps) {
  return (
    <VegaCard noPad>
      <VegaTable
        data={data}
        columnDef={getColumnDef(setSelectedPartner)}
        idColumn="id"
        loading={isLoading}
        disableSelectionOnClick={true}
      />
    </VegaCard>
  );
}

export default memo(ListPartners);

const getColumnDef = (
  setSelectedPartner: React.Dispatch<React.SetStateAction<IPartner | null>>
): GridColDef<IPartner>[] => [
  // {
  //   field: 'srNo',
  //   renderHeader: () => (
  //     <Typography variant="body2" className="font-aspekta-500">
  //       S. No.
  //     </Typography>
  //   ),
  //   flex: 1,
  //   renderCell: (props) => {
  //     console.log('props in row', props);

  //     return <Typography>{props.id}</Typography>;
  //   },
  // },

  {
    field: 'name',
    headerName: 'Partner',
    flex: 1,

    renderCell: (props) => {
      console.log('props in row', props);

      return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Avatar
            sx={{
              bgcolor: BACKGROUND.GERY,
              border: '1.5px solid',
              width: 30,
              height: 30,
              fontSize: 15,
            }}
          >
            {getInitials(props.row?.name || '')}
          </Avatar>
          <Typography>{props.row.name}</Typography>
        </Stack>
      );
    },
  },
  {
    field: 'panNo',
    headerName: 'PAN Number',
    flex: 1,
    renderCell: (props) => {
      return <Typography>{props.row.panNo?.toUpperCase()}</Typography>;
    },
  },
  {
    field: 'type',
    headerName: 'Company Type',
    flex: 1.3,
    renderCell: (props) => {
      return <Typography>{props.row.companyType}</Typography>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.8,
    renderCell: (props) => {
      return (
        <Chip
          sx={{
            borderRadius: '8px',
            backgroundColor:
              props.row.status === EntityStatusEnum.ACTIVE ? green[50] : '',
            color:
              props.row.status === EntityStatusEnum.ACTIVE ? green[600] : '',
          }}
          icon={
            <FiberManualRecordIcon
              fontSize="small"
              sx={{
                mr: 1,
                color: '#11A75C',
              }}
            />
          }
          label={props.row.status}
          color={
            props.row.status === EntityStatusEnum.ACTIVE ? 'success' : 'default'
          }
        />
      );
    },
  },
  // {
  //   field: 'program',
  //   renderHeader: () => (
  //     <Typography variant="body2" className="font-aspekta-500">
  //       Program Created
  //     </Typography>
  //   ),
  //   flex: 0.8,
  //   renderCell: (props) => {
  //     return <Typography>{8}</Typography>;
  //   },
  // },
  // {
  //   field: 'program_live',
  //   renderHeader: () => (
  //     <Typography variant="body2" className="font-aspekta-500">
  //       Program Live
  //     </Typography>
  //   ),
  //   flex: 0.8,
  //   renderCell: (props) => {
  //     return <Typography>{3}</Typography>;
  //   },
  // },
  // {
  //   field: 'admin',
  //   renderHeader: () => (
  //     <Typography variant="body2" className="font-aspekta-500">
  //       Admin
  //     </Typography>
  //   ),
  //   flex: 1,
  //   renderCell: (props) => {
  //     return <Typography>{'Puneet'}</Typography>;
  //   },
  // },
  {
    field: 'action',
    renderHeader: () => (
      <Typography variant="body2" className="font-aspekta-500">
        {/* Action */}
      </Typography>
    ),
    flex: 0.3,
    disableReorder: true,
    renderCell: (props) => {
      return (
        <IconButton
          onClick={() => {
            setSelectedPartner(props.row);
          }}
        >
          <ChevronRight />
        </IconButton>
      );
    },
  },
];
