import { Divider, Skeleton, Stack } from '@mui/material';
import {
  ActionMediumTypo,
  ListGroupHeadingTypo,
  ListHead2Typo,
  SectionHeadingTypo,
} from '../../common/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAppSelector } from '../../../store/hook';
import { IAccountClosureRequestDetails } from '../../../types/accountClosure';

export type IDetailsV1 = {
  label: string;
  value: string;
};

const AccountCloseDetailsMain = () => {
  const { accCloseDetails, isLoading } = useAppSelector(
    ({ accountClosure }) => ({
      accCloseDetails: accountClosure.accountClosureRequestDetails.payload,
      isLoading:
        accountClosure.accountClosureRequestDetails.apiStatus?.isLoading,
    })
  );

  const accDetails = structuredDetails(accCloseDetails);

  // to do: complete the fucntion
  const go2AccountDetails = () => {};

  return (
    <Stack gap={2}>
      <SectionHeadingTypo>Account details</SectionHeadingTypo>
      <Stack direction="row" alignItems="center" gap={3}>
        {isLoading
          ? [1, 2, 3, 4].map((detail, idx) => (
              <Stack
                key={idx}
                flexGrow={1}
                flexBasis={0}
                direction="row"
                justifyContent="space-between"
              >
                <Stack gap={0.5} width={'100%'} pr={1}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="10px"
                    sx={{ borderRadius: '5px' }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="10px"
                    sx={{ borderRadius: '5px' }}
                  />
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: '#E1E4EB', height: '12px' }}
                  variant="middle"
                />
              </Stack>
            ))
          : accDetails.map((detail, idx) => (
              <Stack
                key={idx}
                flexGrow={1}
                flexBasis={0}
                direction="row"
                justifyContent="space-between"
              >
                <Stack gap={0.5} pr={1}>
                  <ListGroupHeadingTypo sx={{ color: '#676B76' }}>
                    {detail.label}
                  </ListGroupHeadingTypo>
                  <ListHead2Typo>{detail.value}</ListHead2Typo>
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: '#E1E4EB', height: '12px' }}
                  variant="middle"
                />
              </Stack>
            ))}
        {isLoading ? (
          <Stack direction="row" alignItems="center" flexGrow={1} flexBasis={0}>
            <Stack
              direction="row"
              alignItems="center"
              onClick={go2AccountDetails}
              width={'100%'}
            >
              <Skeleton
                variant="rectangular"
                width="100%"
                height="10px"
                sx={{ borderRadius: '5px' }}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" flexGrow={1} flexBasis={0}>
            <Stack
              direction="row"
              alignItems="center"
              onClick={go2AccountDetails}
            >
              <ActionMediumTypo
                sx={{ color: '#1047DC' }}
                className="cursor-pointer"
              >
                Check Account Details
              </ActionMediumTypo>
              <ChevronRightIcon
                sx={{ fontSize: '16px', color: '#1047DC' }}
                className="cursor-pointer"
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

function structuredDetails(accCloseDetails?: IAccountClosureRequestDetails) {
  return [
    {
      label: 'accound id',
      value: accCloseDetails?.accountId || '--',
    },
    {
      label: 'verified name',
      value: accCloseDetails?.firstName
        ? accCloseDetails?.firstName + (accCloseDetails?.lastName || '')
        : '--',
    },
    {
      label: 'Mobile Number',
      value: accCloseDetails?.mobileNumber || '--',
    },
    {
      label: 'Email ID',
      value: accCloseDetails?.emailId || '--',
    },
  ];
}

export default AccountCloseDetailsMain;
