import { Box } from '@mui/material';
import AccountDetailsTopContainer from './AccountDetailsTopContainer';
import AccountDetailsActionContainer from './AccountDetailsActionContainer';
import { useRef, useEffect, useState } from 'react';
import { useSupport } from '../../../providers/SupportProvider';

const AccountDetailsAction = () => {
  const ref = useRef<any>(null);
  const { setElementHeight } = useSupport();

  const updateElementHeight = () => {
    if (ref.current) {
      const height = ref.current.clientHeight;
      setElementHeight(height);
    }
  };

  useEffect(() => {
    updateElementHeight();
    window.addEventListener('resize', updateElementHeight);
    return () => {
      window.removeEventListener('resize', updateElementHeight);
    };
  }, []);
  return (
    <Box
      ref={ref}
      sx={{
        bgcolor: 'white',
        px: 2.5,
        py: '17px',
        borderBottom: '1px solid #EEEEEE',
        position: 'relative',
      }}
    >
      <AccountDetailsTopContainer />
      <AccountDetailsActionContainer />
    </Box>
  );
};

export default AccountDetailsAction;
