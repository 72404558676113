import axios, { AxiosResponse } from 'axios';
import { UpdateClientLogoRequestData } from '../types/client';
import { VegaUser } from '../types/user';
import { BASE_URL, CLIENT, ROLE_MANAGEMENT } from './endpoints';

const CLIENT_BASE_URL = BASE_URL + CLIENT.BASE;
const FOREX_CLIENT_BASE_URL = BASE_URL + CLIENT.FOREX_BASE;
// const CLIENT_BASE_URL = 'https://api.vegapay.tech/alpha' + CLIENT.BASE;
const USER_BASE_URL = BASE_URL + ROLE_MANAGEMENT.BASE;
// const USER_BASE_URL = 'https://api.vegapay.tech/alpha' + ROLE_MANAGEMENT.BASE;

export const getClient = (clientId: string) => {
  return axios.get(CLIENT_BASE_URL + '/' + clientId);
};
// export const getForexClient = (clientId: string) => {
//   return axios.get(FOREX_CLIENT_BASE_URL + '/' + clientId);
// };

export const fetchUserData = (
  email: string
): Promise<AxiosResponse<VegaUser, any>> => {
  return axios.get(USER_BASE_URL + '/user/' + email);
};
export const fetchUsersForClient = async (
  clientId: string
): Promise<VegaUser[]> => {
  const res = await axios.get(USER_BASE_URL + '/listUsersByClient/' + clientId);
  return res.data;
};

export const updateClientLogo = (updatedData: UpdateClientLogoRequestData) => {
  const formData: FormData = new FormData();
  formData.append('clientId', updatedData.clientId);
  formData.append('logoType', updatedData.logoType);
  formData.append('logo', updatedData.logo);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.patch(CLIENT_BASE_URL + CLIENT.UPDATE_LOGO, formData, config);
};
