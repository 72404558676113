import { useQuery } from 'react-query';
import { fetchCorporateById } from '../api/Corporate';

export const useCorporate = (corporateId: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['corporateId'],
    queryFn: async () => await fetchCorporateById(corporateId),
    enabled: !!corporateId,
  });

  return { data: data?.data, ...rest };
};
