/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import CIMS_Outline_TextField from './common/CIMS_Outline_TextField';
import { Close, Search } from '@mui/icons-material';
import { IconButton, OutlinedInputProps } from '@mui/material';

interface IProps extends OutlinedInputProps {
  value: string;
  handleChange: (val: string) => void;
  onClear?: () => void;
}
export const SearchTextField = ({
  value,
  handleChange,
  onClear,
  ...rest
}: IProps) => {
  return (
    <CIMS_Outline_TextField
      autoFocus
      fixWidth={320}
      placeholder="Search"
      name="search"
      startAdornment={<Search sx={{ color: '#999DA8', fontSize: 16, mr: 1 }} />}
      endAdornment={
        value ? (
          <IconButton
            size="small"
            onClick={() => {
              onClear ? onClear() : handleChange('');
            }}
          >
            <Close sx={{ color: '#999DA8', fontSize: 16 }} />
          </IconButton>
        ) : null
      }
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      {...rest}
    />
  );
};
