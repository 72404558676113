import { useState } from 'react';
import { fetchListOfResources, updateMcgRisk } from '../api/resources';
import { useSnackbar } from '../providers/SnackbarProvider';
import { Mcg } from '../types/resources';
import { PaginatedResponse, getErrorMessageFromErrorObj } from '../utils/api';

export enum ResourceType {
  MERCHANT,
  MCC,
  MCG,
  PINCODE,
  COUNTRY_CODE,
  CURRENCY_CODE,
  TIMEZONE,
}

export const useInstitutionalResources = () => {
  const [loading, setLoading] = useState<boolean>();
  const { setSnackbar } = useSnackbar();

  const getResourceUrl = (resourceType: ResourceType) => {
    switch (resourceType) {
      case ResourceType.MERCHANT:
        return 'merchant';
      case ResourceType.MCC:
        return 'mccCodes';
      case ResourceType.MCG:
        return 'mcg';
      case ResourceType.PINCODE:
        return 'pincode';
      case ResourceType.COUNTRY_CODE:
        return 'country';
      case ResourceType.CURRENCY_CODE:
        return 'currency';
      case ResourceType.TIMEZONE:
        return 'timezone';
    }
  };

  async function fetchResource<T>(data: {
    type: ResourceType;
    page: number;
    pageSize: number;
    searchBy?: string;
    searchQuery?: string;
  }): Promise<PaginatedResponse<T>> {
    try {
      setLoading(true);
      const resourceUrl = getResourceUrl(data.type);
      let response;
      if ((data.searchQuery ?? '').length > 0) {
        response = await fetchListOfResources(
          data.page,
          data.pageSize,
          resourceUrl,
          data.searchBy,
          data.searchQuery
        );
      } else {
        response = await fetchListOfResources(
          data.page,
          data.pageSize,
          resourceUrl
        );
      }
      return response.data as PaginatedResponse<T>;
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function updateMcgRiskProfile(data: {
    id: string;
    riskProfile: string;
  }): Promise<Mcg> {
    try {
      setLoading(true);
      const response = await updateMcgRisk(data);
      return response;
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      throw error;
    } finally {
      setLoading(false);
    }
  }

  return { fetchResource, loading, updateMcgRiskProfile };
};
