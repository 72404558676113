import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { VegaCard } from '../../../../components/common';
import VegaDatePicker from '../../../../components/common/VegaDatePicker';
import VegaOutLineTextField from '../../../../components/common/VegaOutLineTextField';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import VegaSelect from '../../../../components/common/VegaSelect';

interface IFilterProps {
  handleChange: (name: string, value: any) => void;
  onSearch?: () => void;
  state: any;
  programOption?: any;
  statusOption?: any;
  isProgramFilter?: boolean;
  isStatusFilter?: boolean;
  isDateFilter?: boolean;
  isSearch?: boolean;
  isSearchFilter?: boolean;
  label?: string;
}

const MCCFilterSection = ({
  handleChange,
  onSearch,
  state,
  isDateFilter,
  isSearch,
  isProgramFilter,
  isStatusFilter,
  programOption,
  statusOption,
  isSearchFilter = true,
  label = 'Search by Mobile or Customer ID or Emaill',
}: IFilterProps) => {
  return (
    <VegaCard>
      <Grid container spacing={2}>
        {isSearchFilter && (
          <Grid item xs={12} sm={3}>
            <VegaOutLineTextField
              value={state.mobile}
              onChange={(e) => handleChange('mobile', e.target.value)}
              key="mobile"
              label={label}
              type={'text'}
              placeholder={label}
              Icon={<Search />}
            />
          </Grid>
        )}
        <Grid item container spacing={2} xs={12} sm={5}>
          {isStatusFilter ? (
            <Grid item xs={12} sm={6}>
              <VegaSelect
                options={statusOption}
                key="status"
                selected={state.status}
                label="Select Status"
                onChange={(value) => handleChange('status', value)}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              {' '}
            </Grid>
          )}
          {isProgramFilter ? (
            <Grid item xs={12} sm={6}>
              <VegaSelect
                options={programOption}
                key="programId"
                selected={state.programId}
                label="Select Program"
                onChange={(value) => handleChange('programId', value)}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              {' '}
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={2} xs={12} sm={4} alignItems={'end'}>
          {isDateFilter ? (
            <Grid item xs={12} sm={8}>
              <VegaDatePicker
                value={state.expiryDate}
                onChange={(e) => handleChange('expiryDate', e)}
                key="expiryDate"
                label="Select Month or Year: Card Expiry"
                placeholder="Select Month or Year: Card Expiry"
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={8}>
              {' '}
            </Grid>
          )}
          {isSearch && (
            <Grid item xs={12} sm={4}>
              <VegaOutlineButton text={'Search'} isPrimary onClick={onSearch} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </VegaCard>
  );
};

export default MCCFilterSection;
