import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';

const STEPS = ['Entity Details', 'Admin Details'];

interface IProp<T> {
  steps?: string[];
  onSubmit?: (data: T) => void;
  onCancel: () => void;
  optionalSteps?: number[];
  components: React.ReactNode[];
  isLoading?: boolean;
}

const AddPartner = <T extends {}>({
  onSubmit,
  steps = STEPS,
  optionalSteps,
  components = [],
  onCancel,
  isLoading,
}: IProp<T>) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const hookForm = useForm<T>({
    mode: 'onTouched',
  });

  const isStepOptional = (step: number) => {
    return !optionalSteps ? false : optionalSteps.indexOf(step) !== -1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const goToNextStep = () => {
    if (activeStep !== steps.length - 1) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setSkipped(newSkipped);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return true;
    }

    return false;
  };

  const handleNext = (data: any) => {
    const isNextStep = goToNextStep();

    if (!isNextStep) {
      onSubmit?.(data);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    hookForm.trigger();
  };

  const renderComponent = () => {
    return components[activeStep];
  };

  return (
    <Box sx={{ flex: 1 }}>
      <FormProvider {...hookForm}>
        {steps.length > 1 && (
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              pb: 2,
            }}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
        {
          <form onSubmit={hookForm.handleSubmit(handleNext)}>
            <Stack>{renderComponent()}</Stack>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button color="inherit" onClick={onCancel} sx={{ mr: 1 }}>
                Cancel
              </Button>
              <VegaContainedButton
                text={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                disabled={!hookForm.formState.isValid}
                isPrimary
                type="submit"
                loading={isLoading}
              />
            </Box>
          </form>
        }
      </FormProvider>
    </Box>
  );
};
export default AddPartner;
