import { Box, Link, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  createInventory,
  fetchInventoryByProgramIdAndGiftType,
  updateInventoryRenewStatus,
} from '../../../api/rewardsLoyalty';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  AmazoneIcon,
  AmazonIcon,
  PayTmIcon,
  PhonePe,
} from '../../../components/icon/Icons';
import DiscountVoucher from '../../../components/BenefitInventory/DiscountVoucher/DiscountVoucher';
import GiftVoucher from '../../../components/BenefitInventory/GiftVoucher/GiftVoucher';
import Subscription from '../../../components/BenefitInventory/Subscription/Subscription';
import { VegaSelectSelectOptionType } from '../../../components/common/VegaSelect';
import VegaBreadcrumbs from '../../../components/common/v2/VegaBreadcrumbs';
import VegaTabBar from '../../../components/common/v2/VegaTabBar';
import VegaTabBarItem from '../../../components/common/v2/VegaTabBarItem';
import VegaTextV2 from '../../../components/common/v2/VegaTextV2';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useRewardsNavigation } from '../../../providers/RewardsNavigationProvider';
import { RewardsRoute } from '../../../routes';
import { GiftType, ICreateInventoryProps } from '../../../types/RewardsLoyalty';
interface IPointBaseConfigProps {
  navigateBack: () => void;
}

export interface IinventotyProps {
  data: ICreateInventoryProps[];
  onVoucherSubmit: (voucher: ICreateInventoryProps) => void;
  updateRenewInventory: (id: string | undefined, renew: boolean) => void;
}

export const getIconForVendor = (name: string) => {
  if (name == 'AMAZON') return <AmazonIcon />;
  else if (name == 'PHONEPE') return <PhonePe />;
  else if (name == 'PAYTM') return <PayTmIcon />;
};

export const GIFT_VOUCHER = [
  {
    name: 'AMAZON',
    id: '1231231',
    icon: getIconForVendor('AMAZON'),
  },
  {
    name: 'PHONEPE',
    id: '222222',
    icon: getIconForVendor('PHONEPE'),
  },
  {
    name: 'PAYTM',
    id: '335555',
    icon: getIconForVendor('PAYTM'),
  },
] as VegaSelectSelectOptionType[];

const BenefitsInventory = () => {
  const { navigateTo } = useRewardsNavigation();
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [inventoryList, setInventoryList] = useState<ICreateInventoryProps[]>(
    []
  );
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedVendor, setSelectedVendor] = useState<string>(
    GiftType.GIFT_VOUCHER
  );

  const updateRenewInventory = (id: string | undefined, renew: boolean) => {
    if (id !== undefined) {
      updateInventoryRenewStatus({ id, renew })
        .then((res) => {
          console.log('res.data', res.data);
          getListOfInventory(selectedProgram?.programId, selectedVendor);
        })
        .catch((err) => {
          setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        });
    }
  };

  const getListOfInventory = (id: any, giftType: string) => {
    setLoading(true);
    fetchInventoryByProgramIdAndGiftType(id, giftType)
      .then((res) => {
        setLoading(false);
        setInventoryList(res.data);
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        setLoading(false);
      });
  };

  useEffect(() => {
    getListOfInventory(selectedProgram?.programId, selectedVendor);
  }, [selectedVendor, selectedProgram]);

  const onVoucherSubmit = (voucherData: ICreateInventoryProps) => {
    createInventory(voucherData)
      .then((res) => {
        setSnackbar('Added Voucher !!');
        setInventoryList([...inventoryList, res.data]);
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };
  return (
    <>
      <Box
        style={{
          backgroundColor: 'white',
          paddingTop: '12px',
          paddingLeft: '24px',
        }}
      >
        <VegaBreadcrumbs>
          <Link
            underline="hover"
            key="1"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigateTo(RewardsRoute.REWARDS_HOME);
            }}
          >
            <VegaTextV2
              fontSize={'14px'}
              lineHeight="19.6px"
              color={'rgba(51, 51, 51, 1)'}
              text=" All Campaigns"
            />
          </Link>
          <VegaTextV2
            fontSize={'14px'}
            lineHeight="19.6px"
            color={'rgba(51, 51, 51, 1)'}
            text={'Inventory'}
          />
        </VegaBreadcrumbs>
      </Box>
      <Stack
        direction={'row'}
        spacing={2}
        px={3}
        py={2}
        justifyContent="space-between"
        alignItems={'center'}
        style={{ paddingRight: 8 }}
      >
        <VegaTabBar
          value={selectedTab}
          onChange={(e, selected) => {
            setSelectedTab(selected);
            if (selected === 0) {
              setSelectedVendor(GiftType.GIFT_VOUCHER);
            } else if (selected === 1) {
              setSelectedVendor(GiftType.DISCOUNT_VOUCHER);
            } else {
              setSelectedVendor(GiftType.SUBSCRIPTION);
            }
          }}
        >
          <VegaTabBarItem label="Gift Voucher"></VegaTabBarItem>
          <VegaTabBarItem label="Discount Voucher"></VegaTabBarItem>
          <VegaTabBarItem label="Subscription"></VegaTabBarItem>
        </VegaTabBar>
      </Stack>
      {selectedVendor === GiftType.DISCOUNT_VOUCHER ? (
        <DiscountVoucher
          data={inventoryList}
          onVoucherSubmit={onVoucherSubmit}
          updateRenewInventory={updateRenewInventory}
        />
      ) : selectedVendor === GiftType.SUBSCRIPTION ? (
        <Subscription
          data={inventoryList}
          onVoucherSubmit={onVoucherSubmit}
          updateRenewInventory={updateRenewInventory}
        />
      ) : (
        <GiftVoucher
          data={inventoryList}
          onVoucherSubmit={onVoucherSubmit}
          updateRenewInventory={updateRenewInventory}
        />
      )}
    </>
  );
};

export default BenefitsInventory;
