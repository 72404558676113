/** @format */

import {
  AdminPanelSettings,
  AssessmentOutlined,
  FolderShared,
  LibraryBooksOutlined,
  SupportAgent,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import { InvoiceOutlineIcon, MediaOutlineIcon, SettingIcon, TeamOutlineIcon, UserIcon, UserOutlineIcon, UserOutlineRoundedIcon } from '../components/icon/Icons';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import Collections from '../pages/Collections';
import CrmProgram from '../pages/CrmProgram';
import Policy from '../pages/Policy';
import Reports from '../pages/Reports';
import Support from '../pages/Support';
import { useClientAuth } from '../providers/ClientProvider';
import { NON_ROUTES, ROUTES } from '../routes';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: NON_ROUTES.COMPANY_PROFILE,
    icon: <UserOutlineRoundedIcon />,
    label: 'Company Profile',
    isProgramSelector: false,
    isAccountSelector: false,
    isSetting: true,
  },
  {
    id: NON_ROUTES.MEDIA,
    icon: <MediaOutlineIcon />,
    label: 'Media',
    isProgramSelector: false,
    isAccountSelector: false,
    isSetting: true,
  },
  {
    id: NON_ROUTES.USER_PROFILE,
    icon: <UserOutlineIcon />,
    label: 'User Profile',
    isProgramSelector: false,
    isAccountSelector: false,
    isSetting: true,
  },
  {
    id: NON_ROUTES.TEAM,
    icon: <TeamOutlineIcon />,
    label: 'Team',
    isProgramSelector: false,
    isAccountSelector: false,
    isSetting: true,
  },
  {
    id: NON_ROUTES.INVOICES,
    icon: <InvoiceOutlineIcon />,
    label: 'Invoices',
    isProgramSelector: false,
    isAccountSelector: false,
    isSetting: true,
  },
];

const SettingContainer = (props: Props) => {
  const { isAdmin } = useClientAuth();
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  );

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default SettingContainer;
