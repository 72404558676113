/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import { addReportConfig, updateReportConfig } from '../../../api/reports';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../cims/components/common/CIMS_Modal';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ReportConfigDto, tempReportConfig } from '../../../types/reports';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import ScheduleModal from './ScheduleModal';
import { REPORT_SAMPLE_LINKS } from '../constants';

type Props = {
  openScheduleModal: any;
  setOpenScheduleModal: any;
  selectedReportName?: string;
  selectedReportId?: string;
  fetchReportsConfig?: any;
  initialReportConfig?: ReportConfigDto | null;
};

function ReportsConfigModal({
  openScheduleModal,
  setOpenScheduleModal,
  selectedReportId = '',
  fetchReportsConfig,
  initialReportConfig = null,
  selectedReportName = '',
}: Props) {
  const [reportConfig, setReportConfig] = useState<ReportConfigDto>({
    ...tempReportConfig,
    reportId: selectedReportId,
  });
  const { setSnackbar } = useSnackbar();

  const closeModalHandler = () => {
    setOpenScheduleModal(!openScheduleModal);
  };

  const { client } = useClientAuth();

  const submitHandler = () => {
    if (reportConfig) {
      addReportConfig({
        reportId: reportConfig.reportId,
        sampleReportLink:
          reportConfig.sampleReportLink || selectedReportName
            ? REPORT_SAMPLE_LINKS[selectedReportName]
            : '',
        status: reportConfig.status,
        frequency: reportConfig.frequency,
        hours: reportConfig.hours,
        minutes: reportConfig.minutes,
        recipients: reportConfig.recipients,
        dayOfMonth: reportConfig.dayOfMonth,
        dayOfWeek: reportConfig.dayOfWeek,
        subject: reportConfig.subject,
        description: reportConfig.description,
        createdBy: reportConfig.createdBy || client?.clientName || 'Alpha User',
        format: reportConfig.format,
      });
    }
    setOpenScheduleModal(!openScheduleModal);
    fetchReportsConfig();
  };

  const updateHandler = () => {
    if (reportConfig && initialReportConfig) {
      updateReportConfig({
        reportConfigId: initialReportConfig?.reportConfigId,
        reportId: reportConfig.reportId,
        sampleReportLink:
          reportConfig.sampleReportLink || selectedReportName
            ? REPORT_SAMPLE_LINKS[selectedReportName]
            : '',
        status: reportConfig.status,
        frequency: reportConfig.frequency,
        hours: reportConfig.hours,
        minutes: reportConfig.minutes,
        recipients: reportConfig.recipients,
        dayOfMonth: reportConfig.dayOfMonth,
        dayOfWeek: reportConfig.dayOfWeek,
        subject: reportConfig.subject,
        description: reportConfig.description,
        createdBy: reportConfig.createdBy,
        format: reportConfig.format,
      })
        .then((res) => {
          fetchReportsConfig();
          setSnackbar('Updated Successfully', 'success');
          setOpenScheduleModal(!openScheduleModal);
        })
        .catch((err) => {
          fetchReportsConfig();
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Error in update'),
            'error'
          );
        });
    }
  };

  useEffect(() => {
    if (selectedReportId !== reportConfig.reportId) {
      setReportConfig((pre) => ({
        ...pre,
        reportId: selectedReportId,
      }));
    }
  }, [selectedReportId]);

  useEffect(() => {
    if (initialReportConfig) {
      setReportConfig({
        ...initialReportConfig,
      });
    } else {
      setReportConfig(tempReportConfig);
    }
  }, [initialReportConfig, selectedReportName]);

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Update',
      disabled: false,
      onSubmit: () => {
        updateHandler();
      },
      icon: '',
      isOutline: false,
    },
    {
      text: 'Cancel',
      disabled: false,
      onSubmit: () => {
        closeModalHandler();
      },
      icon: '',
      isOutline: true,
    },
  ];
  const ScheduleButtons: IButtonGroupProps[] = [
    {
      text: 'Schedule',
      disabled: false,
      onSubmit: () => {
        submitHandler();
      },
      icon: '',
      isOutline: false,
    },
    {
      text: 'Cancel',
      disabled: false,
      onSubmit: () => {
        closeModalHandler();
      },
      icon: '',
      isOutline: true,
    },
  ];

  return (
    <>
      {initialReportConfig ? (
        <CIMS_Modal
          maxWidth="md"
          open={openScheduleModal}
          handleClose={closeModalHandler}
          header="Update this Report"
          // subTitleContainer={<></>}
          buttonGroup={buttons}
        >
          <ScheduleModal
            reportConfig={reportConfig}
            setReportConfig={setReportConfig}
          />
        </CIMS_Modal>
      ) : (
        <CIMS_Modal
          maxWidth="md"
          open={openScheduleModal}
          handleClose={closeModalHandler}
          header="Schedule this Report"
          // subTitleContainer={<></>}
          buttonGroup={ScheduleButtons}
        >
          <ScheduleModal
            reportConfig={reportConfig}
            setReportConfig={setReportConfig}
          />
        </CIMS_Modal>
      )}
    </>
  );
}

export default ReportsConfigModal;
