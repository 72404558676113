import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import VegaCheckBoxV2 from '../../../../components/common/v2/VegaCheckBoxV2';
import {
  CardAllotmentOrderDto,
  CardAllotmentStatusEnum,
  UpdateCardAllotmentOrderStatusRequest,
} from '../../../../types/card';
import CIMSModal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import CimsButton from '../../../components/common/CimsButton';
import LosText from '../../../../components/common/deprecated/LosText';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { CardAllocationService } from '../../../apis';

interface Props {
  data: CardAllotmentOrderDto;
}

const MarkAsDelivered = ({ data }: Props) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const updateAllocationStatusMutation = useMutation({
    mutationFn: (data: UpdateCardAllotmentOrderStatusRequest) => {
      return CardAllocationService.updateAllocationStatus(data);
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['cardAllocation'] });
      setSnackbar('Mark as delivered successfully');
    },
    onError: (error) => {
      setSnackbar('Error while updating status (Delivered)', 'error');
    },
  });

  const onSubmit = () => {
    updateAllocationStatusMutation.mutate({
      cardAllotmentOrderId: data.id,
      status: CardAllotmentStatusEnum.DELIVERED,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const buttons: IButtonGroupProps[] = [
    {
      text: 'Confirm Order',
      disabled: !selected,
      onSubmit: () => {
        onSubmit();
        setOpen(false);
      },
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <Box>
      <CimsButton variant="soft" onClick={handleClickOpen} color="secondary">
        Mark as Delivered
      </CimsButton>

      <CIMSModal
        maxWidth="sm"
        open={open}
        handleClose={handleClose}
        header="Confirm Order"
        subTitleContainer={<></>}
        buttonGroup={buttons}
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <LosText text="I confirm that:" fontWeight={500} />

          <Stack mt={2}>
            <Stack direction={'row'} spacing="1" alignItems={'start'}>
              <VegaCheckBoxV2
                sx={{
                  '&.MuiButtonBase-root': {
                    paddingLeft: '0px !important',
                    paddingTop: '0px !important',
                  },
                  color: 'blue',
                }}
                checked={selected}
                onChange={(e, checked) => {
                  setSelected(checked);
                }}
              />
              <LosText style={{ fontSize: '.9rem', lineHeight: '130%' }}>
                The mentioned
                <b> quantity </b>
                and<b> quality </b>
                of cards has been delivered.
              </LosText>
            </Stack>
          </Stack>
        </Box>
      </CIMSModal>
    </Box>
  );
};

export default MarkAsDelivered;
