/** @format */

import { MenuItem, SxProps, TextField, Theme } from '@mui/material';
import _ from 'lodash';
import { useRef } from 'react';
import { BLUE, PRIMARY } from '../../constants/style';

export type VegaSelectSelectOptionType = {
  id: string;
  name: string;
};

type Props = {
  options: string[] | VegaSelectSelectOptionType[];
  selected: string | VegaSelectSelectOptionType;
  onChange: (selected: string) => void;
  label?: string;
  placeholder?: string;
  textFieldWidth?: number;
  disabled?: boolean;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
  variant?: 'standard' | 'filled' | 'outlined';
  maxPopperHeight?: number;
  darkLabel?: boolean;
};

const VegaSelectSidebar = ({
  options,
  selected,
  onChange,
  label,
  placeholder,
  textFieldWidth,
  disabled,
  size,
  sx,
  variant,
  maxPopperHeight,
  darkLabel,
}: Props) => {
  const menuItemRef = useRef(null);
  return (
    <TextField
      select
      // label={label}
      value={typeof selected === 'string' ? selected : selected.id}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      defaultValue={label}
      fullWidth={!textFieldWidth}
      disabled={disabled}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: { maxHeight: maxPopperHeight || 300, left: '0px !important' },
          },
        },
      }}
      InputLabelProps={{
        style: { color: darkLabel ? PRIMARY.main : '#AFAFAF' },
      }}
      size={size || 'small'}
      sx={{
        '&.MuiFormControl-root': {
          borderRadius: '0px',
          border: 'none',
          color: PRIMARY.main,
          bgcolor: BLUE.light,
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiInputBase-input, .MuiInputLabel-root': {
          fontFamily: `var(--font-Aspekta-450) !important`,
          fontSize: '13px',
          lineHeight: '120%',
          color: PRIMARY.main,
        },

        '&.MuiInputLabel-root': {
          color: PRIMARY.main,
        },
        ...(textFieldWidth && { width: textFieldWidth }),
        ...(sx ? sx : {}),
      }}
      variant={variant}
    >
      {options.map((option) => (
        <MenuItem
          className={'font-aspekta-500'}
          ref={menuItemRef}
          key={typeof option === 'string' ? option : option.id}
          value={typeof option === 'string' ? option : option.id}
          sx={{
            ...(textFieldWidth && { width: textFieldWidth }),
            whiteSpace: 'normal',
            fontSize: 12,
          }}
        >
          {_.startCase(
            _.toLower(typeof option === 'string' ? option : option.name)
          )}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default VegaSelectSidebar;
