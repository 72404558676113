import { Typography, Box } from '@mui/material';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import { VegaCard, VegaTable } from '../../../../components/common';

const ListOfReleaseTransction = ({ data }: any) => {
  const COLUMN = [
    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer ID
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'customerName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Name
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'transactionId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Transaction ID
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'sourceCurrency',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Source Currency
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'destinationCurrency',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Destination Currency
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'amount',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Amount
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'time',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Time
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'details',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Action
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="Authorize"
            // onClick={() => showDetails(props.row)}
            isPrimary
          />
        );
      },
    },
  ];
  return (
    <VegaCard fullHeight noPad>
      <VegaTable data={data} columnDef={COLUMN} idColumn={'id'} />
    </VegaCard>
  );
};

export default ListOfReleaseTransction;
