import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { VPButton, VPModal } from '../../../ui-components/common';
import { Box, Stack, Typography } from '@mui/material';
import { ChargebackService } from '../../../services/ChargeBackService';
import { useChargeBack } from '../hooks';
import { ChargebackStatus } from '../../../types/chargeBack';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

enum RESOLUTION {
  ISSUER = 'ISSUER',
  ACQUIRE = 'ACQUIRE',
}
export default function ArbritrationResolutions() {
  const { selectedChargeBack, setSelectedChargeBack } = useChargeBack();
  const { setSnackbar } = useSnackbar();
  const [selectedResolution, setSelectedResolution] =
    React.useState<RESOLUTION>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <VPButton
        id="resolution-menu-button"
        aria-controls={open ? 'resolution-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Resolution
      </VPButton>
      <Menu
        id="resolution-menu"
        MenuListProps={{
          'aria-labelledby': 'resolution-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setSelectedResolution(RESOLUTION.ISSUER)}>
          Issuer’s Won
        </MenuItem>
        <MenuItem onClick={() => setSelectedResolution(RESOLUTION.ACQUIRE)}>
          Acquirer’s Won
        </MenuItem>
      </Menu>
      <>
        <VPModal
          maxWidth="sm"
          open={selectedResolution === RESOLUTION.ISSUER}
          handleClose={() => {
            setSelectedResolution(undefined);
          }}
          header={'Change status'}
          footer={
            <Stack spacing={1} direction={'row'}>
              <VPButton
                onClick={() => {
                  ChargebackService.updateChargebackStatus({
                    chargebackId: selectedChargeBack?.id,
                    status: ChargebackStatus.ARBITRATION_ACCEPTED,
                  })
                    .then((res) => {
                      setSnackbar('Updated sucessfully');
                      setSelectedChargeBack(res);
                    })
                    .catch((err) => {
                      setSnackbar(
                        getErrorMessageFromErrorObj(
                          err,
                          'Error while updating chargeback status'
                        ),
                        'error'
                      );
                    });
                }}
              >
                Confirm
              </VPButton>
            </Stack>
          }
        >
          <Box sx={{ px: 3, pt: 2, pb: 6 }}>
            <Stack spacing={2}>
              <Typography>
                Do you want to confirm Issuer has won the chargeback
              </Typography>
            </Stack>
          </Box>
        </VPModal>

        <VPModal
          maxWidth="sm"
          open={selectedResolution === RESOLUTION.ACQUIRE}
          handleClose={() => {
            setSelectedResolution(undefined);
          }}
          header={'Change status'}
          footer={
            <Stack spacing={1} direction={'row'}>
              <VPButton
                onClick={() => {
                  ChargebackService.updateChargebackStatus({
                    chargebackId: selectedChargeBack?.id,
                    status: ChargebackStatus.ARBITRATION_DECLINED,
                  })
                    .then((res) => {
                      setSnackbar('Updated sucessfully');
                      setSelectedChargeBack(res);
                    })
                    .catch((err) => {
                      setSnackbar(
                        getErrorMessageFromErrorObj(
                          err,
                          'Error while updating chargeback status'
                        ),
                        'error'
                      );
                    });
                }}
              >
                Confirm
              </VPButton>
            </Stack>
          }
        >
          <Box sx={{ px: 3, pt: 2, pb: 6 }}>
            <Stack spacing={2}>
              <Typography>
                Do you want to confirm Acquirer has won the chargeback
              </Typography>
            </Stack>
          </Box>
        </VPModal>
      </>
    </div>
  );
}
