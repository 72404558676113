import { VegaCard, VegaTable } from '../../common';

import VegaDrawer from '../../common/VegaDrawer';
import ViewCustomer from '../../customers/ViewCustomer';
import useOffer from './useOffer';

type IProps = {
  activeStateId: string;
};

export default function OfferManagement() {
  const {
    offerTableData: data,
    offersColDef,
    isOfferDrawerOpen: open,
    setIsOfferDrawerOpen: setOpen,
    selectedOfferData: selectedData,
  } = useOffer();
  return (
    <>
      <VegaCard fullHeight noPad sx={{ mt: 4 }}>
        <VegaTable data={data} columnDef={offersColDef} idColumn="id" />
      </VegaCard>

      <VegaDrawer
        open={open}
        onClose={() => setOpen(false)}
        anchor="right"
        isSubmit={true}
        isClose={true}
        onSubmit={() => setOpen(false)}
        submitText="Go It"
        closeText="Decline"
        header={
          <div>
            Profile Details
            {/* <IconButton sx={{ mx: 1 }}>
              <InfoIcon />
            </IconButton> */}
          </div>
        }
      >
        <ViewCustomer showCustomerData={{ ...selectedData, account: null }} />
      </VegaDrawer>
    </>
  );
}
