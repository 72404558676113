/* eslint-disable react/jsx-pascal-case */
import { useState } from 'react';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
  listProgramWithMetadata,
  mapProgramMetadata,
} from '../../../../api/program';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import CIMS_Modal from '../../../components/common/CIMS_Modal';
import CimsButton from '../../../components/common/CimsButton';
import { MapEntityProgram } from './MapEntityProgram';

import AddIcon from '@mui/icons-material/Add';
import LosTextField from '../../../../components/common/deprecated/LosTextField';
interface IProps {
  divisionId: string;
}
export const MapProgramModal = ({ divisionId }: IProps) => {
  const { clientId } = useClientAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { setSnackbar } = useSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const hookForm = useForm<{
    // programs: Array<{
    //   name: string;
    //   programId: string;
    // }>;
    programId?: string;
  }>({
    mode: 'all',
  });

  const entityProgramsQuery = useQuery({
    queryKey: ['program_entities', { divisionId, clientId, isOpen }],
    queryFn: () =>
      listProgramWithMetadata({
        metaDataDto: {
          clientId: clientId || '',
          divisionId: divisionId,
        },
        page: 0,
        size: 100,
      }),
    enabled: isOpen && !!divisionId && !!clientId,
    select: (data) => {
      // const programs =
      //   data.data.records.map((program) => ({
      //     name: program.name,
      //     programId: program.programId,
      //   })) || [];
      data.data.records.length &&
        // programs.length !== hookForm.getValues().programs.length &&
        hookForm.setValue('programId', data.data.records[0].programId);
      return data.data.records;
    },

    onError: (data) => {
      setSnackbar(
        getErrorMessageFromErrorObj(
          data,
          'Error in fetching programs for entity'
        ),
        'error'
      );
    },
  });

  const handleOpen = () => {
    // entityProgramsQuery.data?.length && hookForm.setValue('programs', entityProgramsQuery.data);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const mapProgramsHandle = () => {
    setIsUpdating(true);
    const data = hookForm.getValues();
    data.programId &&
      mapProgramMetadata({
        programId: data.programId,
        divisionId: divisionId,
        clientId: clientId || '',
      })
        .then((res) => {
          hookForm.reset();
          setIsOpen(false);
          setSnackbar(
            'Successfully mapped for programId - ' + res.data.programId,
            'success'
          );
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Error in mapping the programs'),
            'error'
          );
        })
        .finally(() => {
          setIsUpdating(false);
        });
  };

  return (
    <>
      <CimsButton startIcon={<AddIcon />} onClick={handleOpen} variant="text">
        Map Programs
      </CimsButton>
      {/* <IconButton size="small" color="info" onClick={handleOpen}>
        <LaunchIcon fontSize="inherit" />
      </IconButton> */}
      <CIMS_Modal
        maxWidth="sm"
        open={isOpen}
        handleClose={handleClose}
        header="Entity Program Mapping "
        // subTitleContainer={<></>}
        footer={
          <Stack direction={'row'} spacing={2}>
            <CimsButton
              disabled={false}
              onClick={handleClose}
              variant={'outlined'}
              key={`cancel`}
            >
              Cancel
            </CimsButton>
            <CimsButton
              disabled={
                !!entityProgramsQuery.data?.length ||
                !hookForm.formState.isDirty
              }
              onClick={mapProgramsHandle}
              key={`update`}
              isLoading={isUpdating}
            >
              Update
            </CimsButton>
          </Stack>
        }
      >
        <Box p={3}>
          {entityProgramsQuery.isLoading ? (
            <Stack justifyContent={'center'} alignItems={'center'}>
              <CircularProgress />
            </Stack>
          ) : entityProgramsQuery.isSuccess ? (
            entityProgramsQuery.data.length > 0 ? (
              <LosTextField
                value={entityProgramsQuery.data[0].name}
                placeholder={'Program Name'}
                disabled
                sx={{
                  width: '100%',
                }}
              />
            ) : (
              <FormProvider {...hookForm}>
                <MapEntityProgram initialValues={entityProgramsQuery.data} />
              </FormProvider>
            )
          ) : (
            <Typography>Something went wrong</Typography>
          )}
        </Box>
      </CIMS_Modal>
    </>
  );
};
