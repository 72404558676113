import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ChargeBackRequest,
  PastChargeBackRequest,
  StatusChangeTimeLine,
} from '../components/ChargeBack';
import { ChargeBackModalActions } from '../components/ChargeBack/ChargeBackModalActions';
import {
  VegaFullScreenDialogState,
  useVegaFullScreenDialog,
} from '../providers/VegaFullScreenDialogProvider';
import { ChargebackDto } from '../types/chargeBack';
import { VPButton } from '../ui-components/common';
import { VPBORDER } from '../ui-components/theme';
import { useChargeBack } from '../components/ChargeBack/hooks';
import { useQuery } from 'react-query';
import { ChargebackService } from '../services/ChargeBackService';

interface IChargeBackProps {
  triggerLabel?: string;
  chargeBackDto: ChargebackDto;
}

/**
 * TODO :
 * Need to convert to routing component
 * redirect to /details page
 */

function ChargeBackDetailsModal({
  triggerLabel = 'Chargeback Request',
  chargeBackDto,
}: // chargeBackDto,
IChargeBackProps) {
  const { selectedChargeBack, setSelectedChargeBack } = useChargeBack();
  const [open, setOpen] = useState(false);
  const { onVisiblityChanged } = useVegaFullScreenDialog();

  const listChargeBackQuery = useQuery({
    queryKey: ['listChargeBack_for_transacation', chargeBackDto.transactionId],
    queryFn: () => {
      return ChargebackService.listChargeback({
        transactionId: chargeBackDto.transactionId,
      });
    },
    enabled: !!chargeBackDto.transactionId,
    keepPreviousData: true,
  });

  const handleClickOpen = () => {
    setSelectedChargeBack(chargeBackDto);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    onVisiblityChanged(
      open
        ? VegaFullScreenDialogState.Visible
        : VegaFullScreenDialogState.Hidden
    );
  }, [open]);

  return (
    <>
      <VPButton variant="text" onClick={handleClickOpen}>
        {triggerLabel}
      </VPButton>

      <Dialog
        fullScreen
        aria-labelledby="customized-dialog-title"
        fullWidth
        open={open}
        onClose={onClose}
        sx={{}}
      >
        <Stack>
          {/* ------------------------HEADER------------------------ */}
          <Box
            borderTop={1}
            borderBottom={1}
            p={1.5}
            // px={1.5}
            sx={{
              border: VPBORDER.GRAY,
            }}
            bgcolor={'white'}
          >
            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <IconButton size="small" onClick={onClose}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography
                  className="font-aspekta-600"
                  color={'#222222'}
                  fontSize={17}
                >
                  Chargeback Request
                </Typography>
              </Stack>

              <ChargeBackModalActions chargeBackDto={selectedChargeBack} />
            </Stack>
          </Box>
          {/* </Toolbar> */}
          <Box
            style={{
              backgroundColor: 'rgba(250, 250, 250, 1)',
              height: '100%',
              overflow: 'scroll',
            }}
          >
            <Box p={2}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                spacing={2}
              >
                <Stack spacing={1.5}>
                  {/* ------------------------PAST REQUESTS------------------------ */}
                  <PastChargeBackRequest
                    pastRequests={listChargeBackQuery.data?.records || []}
                  />
                  <ChargeBackRequest />
                </Stack>
                <StatusChangeTimeLine />
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
}

export default ChargeBackDetailsModal;
