import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

type IProps = {
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  options?: ApexCharts.ApexOptions | undefined;
  labelData?: string[];
  fillColor?: string[];
  position?: any;
};

function DonutsChart({
  series,
  options,
  labelData,
  fillColor,
  position = 'right',
}: IProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // series: [44, 55, 41, 17, 15],
  // options: {
  //   chart: {
  //     type: 'donut',
  //   },
  //   responsive: [{
  //     breakpoint: 480,
  //     options: {
  //       chart: {
  //         width: 200
  //       },
  //       legend: {
  //         position: 'bottom'
  //       }
  //     }
  //   }]
  // },

  return (
    <ReactApexChart
      options={{
        chart: {
          width: '100%',
          type: 'pie',
        },

        theme: {
          monochrome: {
            enabled: true,
          },
        },
        colors: fillColor,
        fill: {
          colors: fillColor,
        },
        markers: {},
        labels: labelData,

        dataLabels: {
          enabled: true,
        },
        legend: {
          position: isMobile ? 'bottom' : position,
          // position: 'bottom',

          horizontalAlign: position,
        },
      }}
      series={series}
      type="donut"
      // width={300}
    />
  );
}

export default DonutsChart;
