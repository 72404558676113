import { Box, Button, Divider, Stack, styled, Typography } from '@mui/material';
import { useState } from 'react';

import { Dayjs } from 'dayjs';
import { BACKGROUND, BORDER_GRAY, GREY } from '../../../constants/style';
import { VegaCard } from '../../common';
import BarCharts from '../../common/VegaCharts/Bar/BarCharts';
import ColumnCharts from '../../common/VegaCharts/Bar/ColumnCharts';
import ChartsWrapper from '../../common/VegaCharts/ChartsWrapper';
import LineCharts from '../../common/VegaCharts/LineCharts/LineCharts';
import DonutsChart from '../../common/VegaCharts/PieCharts/DonutsChart';
import TransactionDate from './TransactionDate';

const StyledButton = styled(Button)({
  border: '0.5px solid ',
  borderColor: BORDER_GRAY,
  textTransform: 'capitalize',

  '&:focus': {
    backgroundColor: BACKGROUND.LIGHT_BLUE,
  },
});

function LoanDisbursals() {
  const [activeTab, setActiveTab] = useState<
    'INCOMING_FUNDS' | 'OUTGOING_FUNDS'
  >('INCOMING_FUNDS');

  const [transactionDates, setTransactionDates] = useState<{
    start: Dayjs | null;
    end: Dayjs | null;
  }>({
    start: null,
    end: null,
  });

  const handleChange = (value: 'INCOMING_FUNDS' | 'OUTGOING_FUNDS') => {
    setActiveTab(value);
  };

  return (
    <VegaCard>
      <Box
        className=""
        style={{
          paddingBottom: '20px',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: '500', color: GREY.darkest }}
        >
          Transactions
        </Typography>

        <Typography
          variant="body2"
          sx={{ fontWeight: '500', color: GREY.lighter }}
        >
          Create multi-level, flexible reports at ease.
        </Typography>
      </Box>
      <Box>
        <Stack direction="row" spacing={1}>
          <StyledButton
            sx={{
              background:
                activeTab === 'INCOMING_FUNDS' ? BACKGROUND.LIGHT_BLUE : '',
            }}
            onClick={() => handleChange('INCOMING_FUNDS')}
          >
            GTV
          </StyledButton>
          <StyledButton
            sx={{
              background:
                activeTab === 'OUTGOING_FUNDS' ? BACKGROUND.LIGHT_BLUE : '',
            }}
            onClick={() => handleChange('OUTGOING_FUNDS')}
          >
            Transaction Amount
          </StyledButton>
        </Stack>

        <Divider
          sx={{
            my: 3,
          }}
        />

        <TransactionDate
          transactionDates={transactionDates}
          setTransactionDates={setTransactionDates}
        />
      </Box>

      <Stack my={3} spacing={3}>
        <ChartsWrapper headingText="Net Income Funds">
          <LineCharts
            series={[
              {
                name: 'Amount in thounds',
                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
              },
            ]}
          />
        </ChartsWrapper>
        <Stack direction={'row'} spacing={2}>
          <ChartsWrapper headingText="Net Income Funds">
            <DonutsChart
              series={[44, 55, 41, 17, 15]}
              labelData={[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Fridayss',
              ]}
              fillColor={[
                '#1abc9c',
                '#2ecc71',
                '#3498db',
                '#9b59b6',
                '#34495e',
              ]}
            />
          </ChartsWrapper>
          <ChartsWrapper headingText="By time of date">
            <ColumnCharts
              series={[
                {
                  name: 'Net Profit',
                  data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
                },
              ]}
            />
          </ChartsWrapper>
        </Stack>

        <ChartsWrapper>
          <BarCharts
            series={[
              {
                name: 'sales',
                data: [
                  {
                    x: '2019/01/01',
                    y: 400,
                  },
                  {
                    x: '2019/04/01',
                    y: 430,
                  },
                  {
                    x: '2019/07/01',
                    y: 448,
                  },
                  {
                    x: '2019/10/01',
                    y: 470,
                  },
                  {
                    x: '2020/01/01',
                    y: 540,
                  },
                  {
                    x: '2020/04/01',
                    y: 580,
                  },
                  {
                    x: '2020/07/01',
                    y: 690,
                  },
                  {
                    x: '2020/10/01',
                    y: 690,
                  },
                ],
              },
            ]}
          />
        </ChartsWrapper>
      </Stack>
    </VegaCard>
  );
}

export default LoanDisbursals;
