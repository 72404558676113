export enum EntityStatus {
  DRAFT = 'DRAFT',
  REVIEWER_PENDING = 'REVIEWER_PENDING',
  CHECKER_PENDING = 'CHECKER_PENDING',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_DEFINED = 'NOT_DEFINED',
  DRAFT_APPROVED = 'DRAFT_APPROVED',
  DRAFT_REJECTED = 'DRAFT_REJECTED',
}

export interface EntityAuthStatus {
  id: string;
  entityType: string;
  userIdOfMaker: string;
  programId: string;
  entityId: string;
  status: EntityStatus;
  commentsList: EntityComment[];
  createdAt: Date;
  updatedAt: Date;
}

export enum UserMakerCheckerRole {
  MAKER = 'MAKER',
  CHECKER = 'CHECKER',
  NOT_DEFINED = 'NOT_DEFINED',
}

export interface EntityComment {
  id: string;
  comments: string;
  authorizationRequestId: string;
  createdAt?: Date;
  updatedAt?: Date;
  status: EntityCommentStatus;
}

export enum EntityCommentStatus {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
}
