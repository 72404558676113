import { Button } from '@mui/material';
import React from 'react';

interface IButtonProps {
  text: string;
  startIcon?: React.ReactNode;
  className?: string;
  endIcon?: React.ReactNode;
  color: string;
  onClick?: (e: any) => void;
}

const VegaTextButton = ({
  text,
  startIcon,
  endIcon,
  className,
  color,
  onClick,
}: IButtonProps) => {
  return (
    <Button
      variant="text"
      startIcon={startIcon}
      endIcon={endIcon}
      className={className}
      sx={{
        textTransform: 'capitalize',
        py: 0.5,
        px: 0,
        borderRadius: '5px',
        color: color,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default VegaTextButton;
