import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { VegaCard, VegaTextField } from '../../../../components/common';
import VegaText from '../../../../components/common/VegaText';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../../../components/common/VegaSelect';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import BranchSelector from '../../../components/BranchSelector';
import { ITransactionsInfoProps } from '../../pages/Transactions';
import VegaButton from '../../../../components/common/VegaButton';
import WalletBalanceView from './BalanceView';
import VegaDrawer from '../../../../components/common/VegaDrawer';

interface IApplicationProps {
  isLoading: boolean;
  state: ITransactionsInfoProps;
  customerList: VegaSelectSelectOptionType[];
  accountList: any[];
  walletList: any[];
  handleChange: (name: string, value: any) => void;
  onClear: () => void;
  onSubmit: () => void;
}

const CHARGE_TYPE = [
  'ATM_BALANCE_ENQUIRY',
  'REPLACEMENT_CARD_FEE',
  'DISPUTE_CHARGE_SLIP_RETRIEVAL_FEE',
  'DISPUTE_CHARGEBACK_FEE',
  'INACTIVITY_FEES',
  'MONTHLY_SMS_CHARGES',
];

const AddTransaction = ({
  isLoading,
  state,
  handleChange,
  onClear,
  onSubmit,
  customerList,
  accountList,
  walletList
}: IApplicationProps) => {
  console.log("accountList: ", accountList);
  const accountData = accountList.find(a => a?.id == state?.accountId);
  console.log("accountdata: ", accountData);
  const walletDetails = walletList?.[0] ?? [];
  const walletCurrency =  walletList.filter(w => w).filter(w => w?.[0]?.walletId == accountData?.walletId)?.[0] ?? [];
  const [open, setOpen] = useState<boolean>(false);
  const decline = () => {
    setOpen(!open);
  };
  return (
    <>
    <VegaCard>
      <Box mb={4}>
        <Box mb={0.5}>
          <VegaText text="Personal Information" />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              options={customerList}
              key="customerId"
              selected={state.customerId}
              label="Select Customer"
              onChange={(value) => handleChange('customerId', value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              options={[ 'TRANSACTION', 'CHARGES']}
              key="txnType"
              selected={state.txnType}
              label="Select Transaction Type"
              onChange={(value) => handleChange('txnType', value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
          {
            state.txnType === 'TRANSACTION' ? (
            <VegaSelect
              options={[ 'CASH_AT_POS', 'ATM', 'CONTACTLESS', 'ECOM', 'POS', 'WALLET_TO_WALLET']}
              key="transactionCode"
              selected={state.transactionCode}
              label="Select Transaction Type"
              onChange={(value) => handleChange('transactionCode', value)}
            />
            )
            : state.txnType === 'CHARGES' &&
            <VegaSelect
            options={CHARGE_TYPE}
            key="chargeType"
            selected={state.chargeType}
            label="Select Charge Type"
            onChange={(value) => handleChange('chargeType', value)}
          />
          }
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <VegaSelect
              options={accountList.filter(a => a).map(a => a?.id)}
              key="accountId"
              selected={state.accountId}
              label="Select Account"
              onChange={(value) => handleChange('accountId', value)}
            />
            {state.accountId && (<Grid container marginTop={'10px'}>
            <VegaButton 
              text="View Balance"
              variant='text'
              isPrimary 
              mx='10px'
              onClick={() => setOpen(!open)} 
            />
            </Grid>)}
            
          </Grid>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              options={walletCurrency?.filter((w: { currency: any} )=> !state.destinationCurrency || w.currency.toUpperCase() !== state.destinationCurrency.toUpperCase()).map((w: { currency: any}) => w.currency.toUpperCase())}
              key="sourceCurrency"
              selected={state.sourceCurrency.toUpperCase()}
              label="Select Source Currency"
              onChange={(value) => handleChange('sourceCurrency', value)}
            />
          </Grid>
          {
            state.transactionCode === 'WALLET_TO_WALLET' &&
            state.txnType === 'TRANSACTION' &&
            <Grid item xs={12} sm={4}>
            <VegaSelect
              options={walletCurrency?.filter((w: { currency: any} )=> !state.sourceCurrency || w.currency.toUpperCase() !== state.sourceCurrency.toUpperCase()).map((w: { currency: any}) => w.currency.toUpperCase())}
              key="destinationCurrency"
              selected={state?.destinationCurrency.toUpperCase()}
              label="Select Destination Currency"
              onChange={(value) => handleChange('destinationCurrency', value)}
            />
          </Grid>
          }
          {state.txnType !== 'CHARGES' && <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.amount}
              onChange={(e) => handleChange('amount', e.target.value)}
              key="amount"
              label="Enter Amount"
              type={'number'}
              placeholder="Enter Amount"
            />
          </Grid>}
        </Grid>
      </Box>
    
      <Grid container justifyContent={'end'}>
        <Grid item>
          <VegaOutlineButton text="Clear" isDanger onClick={onClear} />
        </Grid>
        <Grid item>
          <VegaContainedButton
            text="Add Transaction"
            isPrimary
            loading={isLoading}
            startIcon={<Add />}
            mx="0"
            onClick={onSubmit}
          />
        </Grid>
      </Grid>
    </VegaCard>
    <VegaDrawer
    open={open}
    header={'Wallet Balances'}
    closeText="Clear"
    isClose
    isSubmit
    submitText="Update"
    onClose={decline}
    onSubmit={() => {}}
  >
    <WalletBalanceView state = {{walletDetails}}></WalletBalanceView>
  </VegaDrawer>
    </>
  );
};

export default AddTransaction;
