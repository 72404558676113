import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import VegaSelect from '../../../components/common/VegaSelect';
import VegaContainedButton from '../../../components/common/VegaContainedButton';
import VegaOutlineButton from '../../../components/common/VegaOutlineButton';
import { VegaSelectSelectOptionType } from '../../../components/common/VegaSelect';

interface IFilterActionProps {
  options: string[] | VegaSelectSelectOptionType[];
  selected: string | VegaSelectSelectOptionType;
  selectedCount: string | number;
  onApprove: () => void;
  onReject: () => void;
}

const FilterAction = ({
  options,
  selected,
  selectedCount,
  onApprove,
  onReject,
}: IFilterActionProps) => {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          sm={12}
          alignItems="center"
          justifyContent={'end'}
        >
          <VegaContainedButton
            text={`Selected Application  (${selectedCount})`}
            isPrimary
          />
          {selectedCount ? (
            <>
              <VegaOutlineButton
                text="Bulk Approve"
                isSuccess
                onClick={onApprove}
                // disabled={!selectedCount}
              />
              <VegaOutlineButton
                text="Bulk Reject"
                isDanger
                onClick={onReject}
                // disabled={!selectedCount}
              />
            </>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterAction;
