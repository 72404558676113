/** @format */

import axios from 'axios';
import { ACCOUNT, BASE_URL } from './endpoints';

const ACCOUNT_BASE_URL = BASE_URL + ACCOUNT.BASE;

export const createCustomerAccount = (data: any) => {
  return axios.post(ACCOUNT_BASE_URL, data);
};

export const getAccount = (accountId: string) => {
  return axios.get(`${ACCOUNT_BASE_URL}/${accountId}`);
};

export const fetchAccountForCustomer = (customerId: string) => {
  return axios.get(ACCOUNT_BASE_URL + '/list/' + customerId);
};

export const listActiveOffer = (data: { accountId: string; activeDate?: string }) => {
  console.log(
    'activeoffer',

    ACCOUNT_BASE_URL + ACCOUNT.LIMIT_OFFER + '/listActiveOffer'
  );
  return axios.get(ACCOUNT_BASE_URL + ACCOUNT.LIMIT_OFFER + '/listActiveOffer', { params: data });
};

export const listActiveOfferByProgramId = (programId: string) => {
  return axios.get(ACCOUNT_BASE_URL + ACCOUNT.LIMIT_OFFER + '/listActiveOfferByProgram', { params: { programId } });
};

export const listAccountsForProgram = (programId: string) => {
  return axios.get(ACCOUNT_BASE_URL + ACCOUNT.LIST.replace('{programId}', programId));
};
