import { FilterList } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import VegaTab from '../../../../components/common/VegaTab';
import { BLUE } from '../../../../constants/style';

export enum CardOrderRequestTabTypes {
  RequestCards = 'Request Cards',
  PendingOrders = 'Pending Orders',
}

type FilterCardOrderRequestProps = {
  onTabChange: (select: any) => void;
  activeTab: CardOrderRequestTabTypes;
  shouldShowTabs: boolean;
};

function FilterCardOrderRequest({
  onTabChange,
  activeTab,
  shouldShowTabs,
}: FilterCardOrderRequestProps) {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={shouldShowTabs ? 'space-between' : 'flex-end'}
        my={2}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        {shouldShowTabs ? (
          <VegaTab
            placement="vertical"
            color={BLUE.lighter}
            tabData={[
              CardOrderRequestTabTypes.RequestCards,
              CardOrderRequestTabTypes.PendingOrders,
            ]}
            selected={activeTab}
            onSelect={onTabChange}
            tabStyle={{
              flex: { xs: '1', md: 'none' },
            }}
          />
        ) : null}

        <Stack
          direction={'row'}
          className="dataGrid"
          alignItems={'center'}
          justifyContent={'flex-end'}
          spacing={2}
        >
          {/* <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            sx={{ cursor: 'pointer' }}
          >
            <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
            <VegaTextV2 fontSize={12} color={'#676B76'}>
              Filter
            </VegaTextV2>
          </Stack> */}
          <GridToolbarQuickFilter
            autoFocus
            variant="outlined"
            sx={{
              bgcolor: 'white',
              color: '#999DA8',
              height: '35px',
              minWidth: '320px',
              borderRadius: '9px',
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default FilterCardOrderRequest;
