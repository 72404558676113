export enum VegaKycStepType {
  MOBILE_OTP_VALIDATION = 'MOBILE_OTP_VALIDATION',
  FULL_KYC = 'Full KYC',
  Application_Form = 'APPLICATION_FORM',
  Bank_Statement_Verification = 'Bank Statement Verification',
  SELFIE_VERIFICATION = 'SELFIE_VERIFICATION',
  PAN_VALIDATION = 'PAN_VALIDATION',
  SET_BILLING_DATE = 'SET_BILLING_DATE',
  PIN_SETUP = 'PIN_SETUP',
  APPLICATION_APPROVED = 'APPLICATION_APPROVED',
  AADHAR_VERIFICATION_EKYC = 'AADHAR_VERIFICATION_EKYC',
  AVAILABLE_PRODUCTS = 'AVAILABLE_PRODUCTS',
  OFFER = 'OFFER',
  BANK_STATEMENT = 'BANK_STATEMENT',
}

export interface VegaKycStepResponse {
  id?: string;
  programId: string;
  flow: VegaKycBaseStep[];
  createdAt?: Date;
  updatedAt?: Date;
  resetApplicationFlow: boolean;
}

export interface VegaKycBaseStep {
  id: string;
  programId: string;
  name: string;
  workFlowDecision: string;
  status: KycStepStatus;
  metaData: VegaKycStepMetaData;
  parentKycStepId: string;
  order: number;
  stepUpdated: boolean;
}

export interface VegaKycStepMetaData {
  partners?: VegaKycStepPartner[];
  fields?: VegaKycStepField[];
  subSteps?: VegaKycBaseStep[];
}

export interface VegaKycStepPartner {
  id: string;
  kycStepId: string;
  name: string;
  status: KycStepStatus;
  linked: boolean;
}

export interface VegaKycStepField {
  id: string;
  kycStepId: string;
  name: string;
  order: number;
}

export const RADIO_OPTIONS = [
  {
    label: 'Fixed',
    value: 'FIXED',
  },
  {
    label: 'Equal Split',
    value: 'EQUAL_SPLIT',
  },
  {
    label: 'Custom Logic',
    value: 'CUSTOM_LOGIC',
  },
];

export enum KycStepStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
