import { Box, Typography } from '@mui/material';
import { ICampaignSelectorProps } from '../../../types/RewardsLoyalty';
import { GREY } from '../../../constants/style';

interface IProps extends ICampaignSelectorProps {
  onClick: () => void;
}

const CampaignSelector = ({
  title,
  text,
  IconImage,
  bgColor,
  onClick,
}: IProps) => {
  return (
    <Box
      sx={{
        p: 2,
        bgcolor: bgColor,
        borderRadius: '9px',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
      onClick={onClick}
    >
      <Box>{IconImage}</Box>
      <Box sx={{ ml: 2 }}>
        <Typography
          fontSize={14}
          mb={0.5}
          className="font-aspekta-500"
          lineHeight={'19px'}
        >
          {title}
        </Typography>
        <Typography
          lineHeight={'16px'}
          className="font-aspekta-450"
          fontSize={12}
          fontWeight={500}
          color={GREY.grey_light}
        >
          {text}{' '}
        </Typography>
      </Box>
    </Box>
  );
};

export default CampaignSelector;
