import { Clear } from "@mui/icons-material";
import {
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { BACKGROUND, PRIMARY } from "../../../../constants/style";
import { truncateFilename } from "../../../../utils/stringUtils";

interface FileCardProps {
  fileName: string;
  fileSize?: string;
  loading: boolean;
  onCancelButtonClick?: () => void;
}

export function FileCard(props: FileCardProps) {
  return (
    <Box
      sx={{
        bgcolor: BACKGROUND.GERY,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderRadius: "7px",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        mx={1}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography color={PRIMARY.light} mr={0.5} p={2} variant={"body1"}>
          {truncateFilename(props.fileName)}
        </Typography>
        {props.fileSize && (
          <Typography color={PRIMARY.main} variant={"caption"}>
            ({props.fileSize})
          </Typography>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          paddingRight: 8,
          gap: 10,
        }}
      >
        {props.loading == false && (
          <IconButton onClick={props.onCancelButtonClick}>
            <Clear color="primary" fontSize={"small"} />
          </IconButton>
        )}
        {props.loading && <CircularProgress thickness={3} />}
      </Box>
    </Box>
  );
}
