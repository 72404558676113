/** @format */

import {
  ArrowForwardIos,
  KeyboardArrowDown,
  Menu,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COMMON, GREY } from '../../constants/style';
import { useAppBar } from '../../providers/AppBarProvider';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSearchHeader } from '../../providers/SearchHeader';
import { ROUTES } from '../../routes';
import { VegaTextField } from '../common';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { getState, setHeader } from '../../store/common/stateSlice';
import { NEUTRAL_COLORS } from '../../cims/theme/theme';
import { CIMS_PRIMARY } from '../../cims/assets/style';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: any;
  isSmallScreen: any;
};

const CimsHeader = (props: Props) => {
  const { setAppBarHeight } = useAppBar();
  const dispatch = useAppDispatch();
  const { header } = useAppSelector(getState);
  const { setOpen, isSmallScreen, isMobile } = props;
  const navigation = useNavigate();
  const {
    client,
    logout,
    loggedInUserDetails,
    userDetails,
    preferedUserName,
    user,
  } = useClientAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [ipAddress, setIpAddress] = useState();

  const {
    searchValue,
    isHeaderSearch,
    onSearchValueChange,
    headerPlaceHolder,
  } = useSearchHeader();

  const headerRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const resetNavigator = () => {
    navigation(ROUTES.RESET_PASSWORD);
  };
  useEffect(() => {
    if (headerRef.current) {
      const updatedHeight = headerRef.current.offsetHeight;
      setAppBarHeight(updatedHeight);
    }
  }, []);

  const userPreferredName = () => {
    const userDetailsName = userDetails.name;
    return user?.name || userDetailsName || 'User';
  };

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;
        setIpAddress(ipAddress);
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
  }, []);

  return (
    <Box
      ref={headerRef}
      sx={{
        width: '-webkit-fill-available',
        bgcolor: CIMS_PRIMARY.MAIN,
        py: 2,
        px: 5,
        zIndex: 100,
        borderBottom: `1px solid ${CIMS_PRIMARY.ACTIVE_MAIN}`,
        display: 'flex',
        ...(isSmallScreen || isMobile
          ? { justifyContent: 'space-between' }
          : header
          ? { justifyContent: 'space-between' }
          : { justifyContent: 'flex-end' }),
      }}
    >
      {(isSmallScreen || isMobile) && (
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => setOpen((preVal: boolean) => !preVal)}
        >
          <Menu />
        </IconButton>
      )}
      {!(isMobile || isSmallScreen) && (
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Typography
            color={NEUTRAL_COLORS.BG[50]}
            className="font-aspekta-600"
            fontSize={24}
            px={2}
            borderRadius={1}
          >
            Welcome back, {userPreferredName()}!
          </Typography>
        </Stack>
      )}
      {header && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            className="font-aspekta-500"
            color={COMMON.gray}
            sx={{ cursor: 'pointer', mr: 1 }}
            onClick={() => {
              dispatch(setHeader(''));
            }}
          >
            {header?.split('_')[0]}
          </Typography>
          <ArrowForwardIos fontSize="small" />
          <Typography
            className="font-aspekta-500"
            color={COMMON.gray}
            sx={{ ml: 1 }}
          >
            {header.split('_')[1]}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          // gap: 1,
        }}
      >
        {/* <GridViewOutlined color="primary" />
        <NotificationsNoneOutlined color="primary" /> */}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            columnGap: 2,
          }}
        >
          {isHeaderSearch && (
            <VegaTextField
              key={'searchHeader'}
              value={searchValue}
              onChange={(e) => onSearchValueChange(e.target.value)}
              placeholder={headerPlaceHolder}
              //   size="small"
              type={'text'}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              textFieldWidth={300}
            />
          )}

          <Box>
            <Typography
              variant="body2"
              sx={{
                mr: 1,
                display: 'flex',
                flexDirection: 'column',
                color: GREY.light,
              }}
            >
              {userPreferredName()}
              <Typography
                variant="caption"
                sx={{ color: GREY.grey }}
                style={{ lineHeight: 1 }}
              >
                Last Login : {loggedInUserDetails?.loginTime?.toLocaleString()}
              </Typography>
            </Typography>
          </Box>

          <KeyboardArrowDown
            // color="action"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
              color: 'white',
            }}
          />
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ marginTop: 22 }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <Typography
            sx={{ fontSize: 14, paddingBottom: 1 }}
            borderBottom="0.4px solid #D1D1D1"
          >
            {loggedInUserDetails?.email}
          </Typography>
          <Typography sx={{ fontSize: 14, marginTop: 1 }}>
            Mob No:{' '}
            {loggedInUserDetails?.mobileNumber || userDetails?.mobileNumber}
          </Typography>
          <Stack spacing={1}>
            <Button
              sx={{
                marginTop: 2,
                minWidth: 220,
                backgroundColor: '#4785FF',
                color: 'white',
                fontWeight: 'bold',
              }}
              onClick={logout}
              // onClick={() => {
              //   dispatch(setIsAuth(false));
              // }}
            >
              logout
            </Button>
            <Button variant="outlined" onClick={resetNavigator}>
              Reset Password
            </Button>
            <Typography fontStyle={'italic'}>
              Id Address: {ipAddress}
            </Typography>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
};

export default CimsHeader;
