/* eslint-disable react/jsx-pascal-case */
import { Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { listDiscardReasons } from '../../../api/card';
import { useClientAuth } from '../../../providers/ClientProvider';
import CIMS_DataGrid from '../../components/common/CIMS_DataGrid';

interface IProps {
  setRefetchList: any;
}
const ListOfCardRejectionStatus = ({ setRefetchList }: IProps) => {
  const { clientId } = useClientAuth();
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(0);

  const listDiscardReasonsQuery = useQuery({
    queryKey: [
      'discardReasons',
      {
        page,
        pageSize,
      },
    ],
    queryFn: () => {
      return listDiscardReasons({
        clientId: clientId || '',
        size: pageSize,
        page: page,
      });
    },
    enabled: !!clientId,
    select: (data) => {
      const { records, ...rest } = data;

      return {
        ...rest,
        records:
          records.map((item: any, index: number) => {
            return {
              ...item,
              no: index + 1,
            };
          }) || [],
      };
    },
  });

  const COLUMN_DEF: GridColumns = [
    {
      field: 'no',
      headerName: 'S.No.',
      flex: 0.5,
      minWidth: 80,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'discardReasonCode',
      headerName: 'Rejection Code',
      flex: 0.6,
      minWidth: 150,
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'discardReasonDisplayText',
      headerName: 'Rejection Reason',
      flex: 0.6,
      minWidth: 150,
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'discardReasonDescription',
      headerName: 'Remarks',
      flex: 2,
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
  ];

  useEffect(() => {
    setRefetchList(() => listDiscardReasonsQuery.refetch);
    return () => {
      setRefetchList(null);
    };
  });
  return (
    <>
      <CIMS_DataGrid
        data={listDiscardReasonsQuery.data?.records || []}
        columns={COLUMN_DEF}
        idColumn={'id'}
        loading={listDiscardReasonsQuery.isLoading}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        page={page}
        onPageChange={(page) => {
          setPage(page);
        }}
        rowCount={listDiscardReasonsQuery.data?.totalItems}
        paginationMode="server"
      />
    </>
  );
};

export default ListOfCardRejectionStatus;
