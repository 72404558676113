/** @format */

import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState } from 'react';
import { DATE_FORMATE } from '../../../constants/url';
import { AccessForProgram } from '../../../pages/RolesAndAccess';
import { getAccessFeaturesForProgramObjFromResponse } from '../../../utils/userAccessUtils';
import { NoResults, VegaCard, VegaTable } from '../../common';
import VegaLabel from '../../common/VegaLabel';
import AccessFeaturesControl from './AccessFeaturesControl';

interface IListOfRoleProps {
  data: any;
  onChange: (name: string, value: any) => void;
  onEdit: (value: any) => void;
}

const ListOfRole = ({ data, onChange, onEdit }: IListOfRoleProps) => {
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [selecteAccessFeatures, setSelectedAccessFeatuers] =
    useState<AccessForProgram>({});

  const handleViewMoreClick = (rowInfo: any) => {
    console.log('Row: ', rowInfo);
    const featuresForProgram = getAccessFeaturesForProgramObjFromResponse(
      rowInfo.accessFeatures
    );
    console.log('Features For program: ', featuresForProgram);
    setSelectedAccessFeatuers(featuresForProgram);
    setViewMore(true);
  };

  const columnRef = [
    {
      field: 'roleName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Role Name
        </Typography>
      ),

      flex: 1,
    },

    {
      field: 'programAccess',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Program Access
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {props.row.accessFeatures
              ? props.row.accessFeatures.map((data: any, index: number) => (
                  <VegaLabel
                    text={_.startCase(_.toLower(data.feature))}
                    color="primary"
                    mx={index == 0 ? 0 : 0.5}
                    py={0.5}
                    px={0.5}
                  />
                ))
              : ''}
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: 'featureAccess',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Feature Access
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Button
            color="info"
            variant="text"
            onClick={() => handleViewMoreClick(props.row)}
          >
            View More
          </Button>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Creation Date
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {dayjs(props.row.createdAt).format(DATE_FORMATE)}
          </Typography>
        );
      },
      flex: 1,
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <VegaLabel
            text={_.startCase(_.toLower(props.row.status))}
            color={props.row.status === 'ACTIVE' ? 'success' : 'danger'}
            mx={0}
            py={0.5}
            px={0.5}
          />
        );
      },
      flex: 0.5,
    },
  ];
  if (!data || data.length === 0) {
    return <NoResults text="You have no Role" subtext="Add new Role?" />;
  }

  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable data={data} columnDef={columnRef} idColumn={'id'} />
      </VegaCard>
      {viewMore && (
        <AccessFeaturesControl
          open={viewMore}
          onClose={() => setViewMore(false)}
          featuresForProgram={selecteAccessFeatures}
        />
      )}
    </>
  );
};

export default ListOfRole;
