import { useEffect, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import VegaSelector from '../../../components/common/VegaSelector';
import { CreditCardIcon, UserIcon } from '../../../components/icon/Icons';
import { BACKGROUND } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  fetchfilterConsolidatedAccountList,
  updateCheckerMakerStatusForAccount,
} from '../../Back-office/api/account';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { IFilterProps } from '../../Back-office/pages/MCCAplicationManagement';
import { ISectionProps } from '../../Back-office/pages/MCCUserManagement';
import { IConsolidatedAccountData } from '../../types/Commons';
import { STATUS } from '../../variable';
import FilterAction from '../components/FilterAction';
import ListOfCardChecker from '../components/MCCcardSaleChecker/ListOfCardChecker';

let tileData = [
  {
    id: 'KIT',
    text: 'Kit',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'PERSONALISED_SALE',
    text: 'Personalised Sale',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'REISSUE',
    text: 'Reissue',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];

export interface ICardCheckerProps {
  kitNo: string;
  name: string;
  programName: string;
  customerId: string;
  cardNo: string;
  expiryDate: string;
  activationDate: string;
  status: string;
  id: number;
}
const MCCcardSaleChecker = () => {
  const { userId, clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [rowMultiSelect, setRowMultiSelect] = useState<string[]>([]);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [accountData, setAccountData] = useState<IConsolidatedAccountData[]>(
    []
  );
  const [activeState, setActiveState] = useState<ISectionProps>(tileData[0]);
  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });

  const selectedTab = (data: any) => {
    setActiveState(data);
  };

  const handleFilter = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const onSubmitStatus = (data: any) => {
    updateCheckerMakerStatusForAccount(data)
      .then((res) => {
        setSnackbar('Status change!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to change status'),
          'error'
        );
      });
  };

  const onApprove = () => {
    rowMultiSelect.map((val: any) => {
      const obj = { status: 'APPROVED', userId, entityId: val };
      onSubmitStatus(obj);
    });
  };
  const onReject = () => {
    rowMultiSelect.map((val: any) => {
      const obj = { status: 'REJECTED', userId, entityId: val };
      onSubmitStatus(obj);
    });
  };

  const onStatusChange = async (data: any, status: string) => {
    const obj = { status, userId, entityId: data.id };

    onSubmitStatus(obj);
  };

  const getFilterData = (arr: any[]) => {
    const newArr = arr.filter((val: any) => {
      if (filterData.mobile === '') {
        return val;
      } else if (
        val.customer &&
        val.customer.firstName
          .toLowerCase()
          .includes(filterData.mobile.toLowerCase())
      ) {
        return val;
      } else if (
        val.customer &&
        val.customer.mobileNumber
          .toLowerCase()
          .includes(filterData.mobile.toLowerCase())
      ) {
        return val;
      } else if (
        val.customer &&
        val.customer.emailId
          .toLowerCase()
          .includes(filterData.mobile.toLowerCase())
      ) {
        return val;
      }
      return false;
    });

    return newArr;
  };

  const filteredCustomers = accountData.filter((val: any) => {
    if (filterData.status === 'ALL') {
      return val;
    } else if (val.account.checkerMakerStatus === filterData.status) {
      return val;
    }
  });

  const getCustomerList = () => {
    setListLoading(true);
    fetchfilterConsolidatedAccountList({
      clientId,
    })
      .then((res) => {
        const getData = res.data?.map((value: any) => ({
          ...value,
          id: value?.account?.id ?? value?.customer?.id,
        }));
        const checkerPendingAccounts = (
          res.data as IConsolidatedAccountData[]
        ).reduce((pre, curr) => {
          return curr.account?.checkerMakerStatus === 'CHECKER_PENDING'
            ? [
                ...pre,
                {
                  ...curr,
                  id: curr.account.id,
                },
              ]
            : pre;
        }, [] as IConsolidatedAccountData[]);

        setAccountData(getData);

        setListLoading(false);
      })

      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getCustomerList();
  }, []);
  console.log('filteredCustomers', filteredCustomers);
  return (
    <VegaPage>
      <VegaSelector
        data={tileData}
        setActiveState={selectedTab}
        active={activeState}
        header="New Card Sale"
        subHeader="Issue New Card"
      />
      <MCCFilterSection
        isStatusFilter
        statusOption={STATUS}
        state={filterData}
        handleChange={handleFilter}
        // onSearch={onSearch}
      />
      <VegaCard fullHeight noPad>
        <FilterAction
          options={['PENDING', 'APPROVED', 'REJECTED']}
          selected={'PENDING'}
          onApprove={onApprove}
          onReject={onReject}
          selectedCount={rowMultiSelect.length}
        />
        <ListOfCardChecker
          data={getFilterData(filteredCustomers)}
          loading={listLoading}
          onStatusChange={onStatusChange}
          setRowMultiSelect={setRowMultiSelect}
        />
      </VegaCard>
    </VegaPage>
  );
};

export default MCCcardSaleChecker;
