/* eslint-disable react/jsx-pascal-case */
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import Vega_Card from '../../../../../components/common/v3/Vega_Card';
import Vega_Datagrid from '../../../../../components/common/v3/Vega_Datagrid';
import Vega_Select, {
  ISelectOptionType,
} from '../../../../../components/common/v3/Vega_Select';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import Vega_Text from '../../../../../components/common/v3/Vega_Text';
import Vega_Button from '../../../../../components/common/v3/Vega_Button';
import { BLUE, GREEN, RED } from '../../../../../constants/style';
import { fetchInvoice } from '../../../../../api/setting';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { Vega_Pill } from '../../../../../components/common/v3/Vega_Pill';
import {
  ISelecteAccountProps,
  useProfitLoss,
} from '../../../../../providers/ProfitLossProvider';

export interface InvoicePorps {
  clientId: string;
  year: number;
  month: number;
  invoiceS3Link: string;
}
const thirdPartyInvoice = [
  {
    programName: 'Vegapay Elite Credit Card',
    lastUpdated: '12/09/23',
    progrmaId: 'a9b47278-5f0c-4c39-b10a-8997c4a73b8d',
    productType: 'Credit Card',
    liveOn: '12/09/23',
    status: 'Active',
    income: 52000,
    cost: 12000,
  },
  {
    programName: 'Vegapay Elite Credit Card',
    lastUpdated: '12/09/23',
    progrmaId: 'a9b47278-5f0c-4c39-b10a-8997c4a73b3r',
    productType: 'Credit Card',
    liveOn: '12/09/23',
    status: 'Active',
    income: 56000,
    cost: 76000,
  },
];

const ListOfAllAccount = () => {
  const { setIsSelectedProgram, setSelectAccount } = useProfitLoss();
  const onViewAccountDetails = (data: ISelecteAccountProps) => {
    setIsSelectedProgram(true);
    setSelectAccount(data);
  };
  return (
    <>
      <Box mt={2}>
        <Vega_Datagrid
          data={thirdPartyInvoice}
          idColumn="progrmaId"
          columns={COLUMN_DEF}
          classes={{ row: 'customRow' }}
          loading={false}
          onCellClick={(params: GridCellParams<any>) => {
            if (params.field !== 'action') {
              onViewAccountDetails(params.row);
            }
          }}
        />
      </Box>
    </>
  );
};

export default ListOfAllAccount;

const COLUMN_DEF: GridColumns = [
  {
    field: 'programName',
    headerName: 'Program Name',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    flex: 1,
    minWidth: 100,
  },

  {
    field: 'progrmaId',
    headerName: 'Program ID',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'productType',
    headerName: 'Product Type',
    flex: 1,
    minWidth: 100,
  },

  {
    field: 'liveOn',
    headerName: 'Live on',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'status',
    headerName: 'Current Status',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      const status = props.row.status;
      return (
        <Vega_Pill
          text={status}
          borderColor={GREEN.dark}
          textColor={GREEN.dark}
          backgroundColor={GREEN.light}
        />
      );
    },
  },
  {
    field: 'income',
    headerName: 'Income',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    flex: 1,
    minWidth: 100,
  },

  {
    field: 'profitLoss',
    headerName: 'P&L',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      const income = props.row.income;
      const cost = props.row.cost;
      const pnl = income - cost;
      return (
        <Vega_Text
          text={pnl}
          fontColor={income < cost ? RED.dark : GREEN.dark}
        />
      );
    },
  },
];
