import { Grid, InputAdornment, Stack } from '@mui/material';
import VegaCardV2 from '../../../../../../../../common/v2/VegaCardV2';
import {
  RewardsInputType,
  useCampaignRule,
} from '../../../../../../../providers/CampaignRuleProvider';
import { useVoucherInventory } from '../../../../../../../providers/VoucherInventoryProvider';
import VoucherLists from './Voucher/VoucherLists';
import VegaFormInputFieldV2 from '../../../../../../../../common/v2/VegaFormInputField';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../../common/v2/VegaSelectV2';
import { IterationType } from '../../../../../../../../../types/RewardsLoyalty';
import _ from 'lodash';
import VegaTextFieldV2 from '../../../../../../../../common/v2/VegaTextFieldV2';
import { RuppesIcon } from '../../../../../../../../icon/Icons';

function NonPointBasedTransactionalRewards() {
  const {
    onRuleRewardsDataChange,
    addVoucher,
    updateVoucher,
    deleteLastVoucher,
    giftVouchers,
    subscriptionVouchers,
    discountVouchers,
    rewardsData,
  } = useCampaignRule();
  const {
    giftVouchers: allGiftVouchers,
    discountVouchers: allDiscountVouchers,
    subscriptionsVouchers: allSubscriptionVouchers,
  } = useVoucherInventory();

  return (
    <Stack spacing={'12px'}>
      <VegaCardV2>
        <Grid container columns={8} spacing={2}>
          <Grid item xs={2}>
            <VegaFormInputFieldV2 label="Transaction Amount">
              <VegaTextFieldV2
                value={rewardsData.perAmount ?? ''}
                placeholder="Enter Amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RuppesIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onRuleRewardsDataChange({
                    inputType: RewardsInputType.PER_AMOUNT,
                    value: e.target.value,
                  });
                }}
              />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={2}>
            <VegaFormInputFieldV2 label="Iteration">
              <VegaSelectV2
                value={rewardsData.iteration ?? ''}
                placeholder="Select Iteration"
                options={iterationOptions()}
                onChange={(e) => {
                  onRuleRewardsDataChange({
                    inputType: RewardsInputType.ITERATION,
                    value: e.target.value as string,
                  });
                }}
              />
            </VegaFormInputFieldV2>
          </Grid>
        </Grid>
      </VegaCardV2>
      <VegaCardV2>
        <VoucherLists
          addVoucher={addVoucher}
          deleteLastVoucher={deleteLastVoucher}
          updateVoucher={updateVoucher}
          giftVouchers={giftVouchers}
          discountVouchers={discountVouchers}
          subscriptionVouchers={subscriptionVouchers}
          allGiftVouchers={allGiftVouchers}
          allDiscountVouchers={allDiscountVouchers}
          allSubscriptionVouchers={allSubscriptionVouchers}
        />
      </VegaCardV2>
    </Stack>
  );
}

export default NonPointBasedTransactionalRewards;

const iterationOptions = () =>
  Object.values(IterationType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
