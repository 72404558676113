import { Button, Drawer, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GREY } from '../../constants/style';
import { useAppBar } from '../../providers/AppBarProvider';
import VegaContainedButton from './VegaContainedButton';
import VegaOutlineButton from './VegaOutlineButton';

type IDrawerType = {
  anchor?: string | any;
  closeText?: string | any;
  submitText?: string | any;
  header?: string | any;
  open?: boolean;
  isClose?: boolean;
  loading?: boolean;
  isSubmit?: boolean;
  isTableShow?: boolean;
  onClose?: (e: any, buttonClicked: boolean) => void;
  onSubmit?: (e: any) => void;
  children?: JSX.Element | any;
  submitButton?: JSX.Element;
  disableSubmitButton?: boolean;
};

const VegaDrawer = ({
  anchor,
  open,
  onClose,
  onSubmit,
  children,
  isClose,
  isSubmit,
  submitText,
  closeText,
  header,
  loading,
  submitButton,
  isTableShow,
  disableSubmitButton = false,
}: IDrawerType) => {
  const { appBarHeight } = useAppBar();

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            height: `${window.innerHeight - appBarHeight}px`,
            top: appBarHeight,
            ...(isTableShow ? { width: '45%' } : { width: '42%' }),
          },
        }}
        anchor={anchor ?? 'right'}
        open={open}
        onClose={(e) => {
          if (onClose) {
            onClose(e, false);
          }
        }}
      >
        <Box sx={{ p: 2, bgcolor: GREY.light }}>
          <Typography
            style={{
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '23px',
              color: '#333333',
              paddingLeft: '18px',
            }}
          >
            {header}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            bgcolor: 'white',
            borderRadius: 1,
            height: '88%',
            overflow: 'auto',
            padding: '31px',
          }}
        >
          {children}
        </Box>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'end',
            p: 1,
            boxShadow: '0px -4px 3px rgba(0, 0, 0, 0.08)',
          }}
        >
          {isClose && (
            <VegaOutlineButton
              text={closeText}
              onClick={(e) => {
                if (onClose) {
                  onClose(e, true);
                }
              }}
              isDanger
            />
          )}
          {isSubmit && (
            <>
              {submitButton ? (
                submitButton
              ) : (
                <VegaContainedButton
                  disabled={disableSubmitButton}
                  text={submitText}
                  onClick={onSubmit}
                  isPrimary
                  loading={loading}
                />
              )}
            </>
          )}
        </Grid>
      </Drawer>
    </div>
  );
};

export default VegaDrawer;
