import React from 'react';

import { Grid } from '@mui/material';
import VegaSelect from '../../../../components/common/VegaSelect';
import { VegaTextField } from '../../../../components/common';
import { IBranchProps } from '../../pages/MCCBranch';

interface IProps {
  state: IBranchProps;
  handleChange: (name: string, value: any) => void;
}
const BranchDetails = ({ state, handleChange }: IProps) => {
  return (
    <>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} sm={6} mb={1}>
          <VegaTextField
            value={state?.branchName}
            onChange={(e) => handleChange('branchName', e.target.value)}
            key="branchName"
            label="Branch Name"
            type={'text'}
            placeholder="Branch Name"
            // disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={1}>
          <VegaTextField
            value={state?.branchCode}
            onChange={(e) => handleChange('branchCode', e.target.value)}
            key="branchCode"
            label="Branch Code"
            type={'text'}
            placeholder="Branch Code"
            // disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} mb={1}>
          <VegaTextField
            value={state?.branchAddress}
            onChange={(e) => handleChange('branchAddress', e.target.value)}
            key="branchAddress"
            label="Branch Address"
            type={'text'}
            placeholder="Branch Address"
            // disabled
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} mb={1}>
          <VegaTextField
            value={''}
            onChange={(e) => handleChange('totalUsers', e.target.value)}
            key="totalUsers"
            label="Total User"
            type={'text'}
            placeholder="Total User"
            // disabled
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={12} mb={1}>
          <VegaSelect
            options={['ACTIVE', 'INACTIVE']}
            key="status"
            selected={state?.status}
            label="Status"
            onChange={(value) => handleChange('status', value)}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} mb={1}>
          <VegaTextField
            value={state?.pincode}
            onChange={(e) => handleChange('pincode', e.target.value)}
            key="pincode"
            label="Pin Code"
            type={'text'}
            placeholder="Pin Code"
            // disabled
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BranchDetails;
