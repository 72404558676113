import axios from 'axios';
import { AnyPolicyType, ListPolicyRequest } from '../../types/policy';
import { PaginatedResponse } from '../../utils/api';

// const BIN_BASE_URL = BASE_URL + 'bin';
const POLICY_BASE_URL = '/program/v1/program/policy';

const ENDPOINTS = {
  CREATE: '/',
  LIST: '/list',
};

export class PolicyService {
  static async createPolicy(
    clientId: string,
    data: AnyPolicyType
  ): Promise<AnyPolicyType> {
    const endpoint = POLICY_BASE_URL + ENDPOINTS.CREATE;
    const response = await axios.post(endpoint, data, {
      params: {
        clientId,
      },
    });
    return response.data;
  }
  static async updatePolicies(data: AnyPolicyType): Promise<AnyPolicyType> {
    const endpoint = POLICY_BASE_URL + ENDPOINTS.CREATE;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async listPolicies(
    data: ListPolicyRequest
  ): Promise<PaginatedResponse<AnyPolicyType>> {
    const endpoint = POLICY_BASE_URL + ENDPOINTS.LIST;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
}
