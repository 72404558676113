import { Box, Grid } from '@mui/material';
import { VegaAccordian, VegaTextField } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaText from '../../../../components/common/VegaText';
import { FIleIcon } from '../../../../components/icon/Icons';
import { BORDER_GRAY } from '../../../../constants/style';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../../utils/FileValidator';
import {
  ICustomerDetailsProps,
  IUploadCustomerDocs,
} from './ListOfMCCcustomer';
import PreviewNUploadFile from './PreviewNUploadFile';

interface IProps {
  customer: ICustomerDetailsProps | null;
  handleChange: (name: keyof ICustomerDetailsProps, value: any) => void;
  show?: boolean;
  customerDocs?: IUploadCustomerDocs;
  handleDocsChange?: (
    key: keyof IUploadCustomerDocs,
    value: File | null
  ) => void;
}

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([
    FileExtension.PNG,
    FileExtension.JPEG,
    FileExtension.JPG,
    FileExtension.SVG,
    FileExtension.PDF,
  ])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const ViewCustomer = ({
  customer,
  handleChange,
  show = false,
  customerDocs,
  handleDocsChange,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  function toDataURL(url: string) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
  const download = async (url: any) => {
    if (url) {
      const a = document.createElement('a');
      const urlBlob = await toDataURL(url);

      a.href = urlBlob;
      a.download = 'myImage.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getImage = (url: string, serverUrl: any) => {
    console.log('url, serverUrl', url, serverUrl);
    if (url === undefined) {
      return serverUrl;
    } else return url;
  };

  function handleDocumentationError(
    type: keyof IUploadCustomerDocs,
    message: string | null
  ) {
    setSnackbar(message ?? 'Something went wrong');
    handleDocsChange?.(type, null);
  }

  return (
    <div>
      <Box my={2}>
        <VegaAccordian
          header={'Basic Details'}
          content={
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <VegaTextField
                    label="Customer Name"
                    key="firstName"
                    value={customer?.firstName}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                    disabled={show}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VegaTextField
                    label="Mobile Number"
                    key="mobileNumber"
                    value={customer?.mobileNumber}
                    onChange={(e) =>
                      handleChange('mobileNumber', e.target.value)
                    }
                    disabled={show}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <VegaTextField
                    label="Customer Email"
                    key="emailId"
                    value={customer?.emailId}
                    onChange={(e) => handleChange('emailId', e.target.value)}
                    disabled={show}
                  />
                </Grid>
              </Grid>
            </Box>
          }
        />
      </Box>

      <Box my={2}>
        <VegaAccordian
          header={'KYC'}
          content={
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <PreviewNUploadFile
                    imageUrl={customer?.panUrl || ''}
                    selectedFile={customerDocs?.panFile || null}
                    handleChange={(file) => {
                      handleDocsChange && handleDocsChange('panFile', file);
                    }}
                    title="Pan file"
                    fileValidator={fileValidatorConfig}
                    onError={(message) => {
                      handleDocumentationError('panFile', message);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PreviewNUploadFile
                    imageUrl={customer?.aadhaarUrl || ''}
                    selectedFile={customerDocs?.aadhaarFile || null}
                    handleChange={(file) => {
                      handleDocsChange && handleDocsChange('aadhaarFile', file);
                    }}
                    title="Aadhar file"
                    fileValidator={fileValidatorConfig}
                    onError={(message) => {
                      handleDocumentationError('aadhaarFile', message);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PreviewNUploadFile
                    imageUrl={customer?.passportUrl || ''}
                    selectedFile={customerDocs?.passportFile || null}
                    handleChange={(file) => {
                      handleDocsChange &&
                        handleDocsChange('passportFile', file);
                    }}
                    title="Passport file"
                    fileValidator={fileValidatorConfig}
                    onError={(message) => {
                      handleDocumentationError('passportFile', message);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          }
        />
      </Box>
      <Box my={2}>
        <VegaAccordian
          header={'Document'}
          content={
            <>
              <Box my={2}>
                <VegaText text="Invoice" />
                <Box
                  sx={{
                    p: 1,
                    my: 1,
                    border: BORDER_GRAY,
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FIleIcon />
                      <Box mx={1}>
                        <VegaText text="Visa.pdf" />
                      </Box>
                    </Box>
                    <Box>
                      <VegaContainedButton text="Download" isPrimary />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box my={2}>
                <VegaText text="Document" />
                <Box
                  sx={{
                    p: 1,
                    my: 1,
                    border: BORDER_GRAY,
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FIleIcon />
                      <Box mx={1}>
                        <VegaText text="Visa.pdf" />
                      </Box>
                    </Box>
                    <Box>
                      <VegaContainedButton text="Download" isPrimary />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box my={2}>
                <VegaText text="Form" />
                <Box
                  sx={{
                    p: 1,
                    my: 1,
                    border: BORDER_GRAY,
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FIleIcon />
                      <Box mx={1}>
                        <VegaText text="Visa.pdf" />
                      </Box>
                    </Box>
                    <Box>
                      <VegaContainedButton text="Download" isPrimary />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          }
        />
      </Box>
    </div>
  );
};

export default ViewCustomer;
