/** @format */

import {
  AccountBalanceOutlined,
  CurrencyExchangeOutlined,
  ReceiptLongOutlined,
  SupervisorAccountOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import { MCC_ROUTES } from '../routes';
import Transactions from '../mcc/simulator/pages/Transactions';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: MCC_ROUTES.SIMULATOR_TRANSACTION,
    icon: <CurrencyExchangeOutlined />,
    label: 'Transactions',
    component: <Transactions />
  },
];

const MCCSimulatorContainer = (props: Props) => {
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  );

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default MCCSimulatorContainer;
