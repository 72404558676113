import { CancelOutlined } from '@mui/icons-material';
import {
  Dialog,
  Stack,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Mcg, RiskProfiles } from '../../types/resources';
import VegaFormInputFieldV2 from '../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../common/v2/VegaPrimaryButton';
import VegaSelectV2, { VegaSelectOptionV2 } from '../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../common/v2/VegaTextFieldV2';

type Props = {
  open: boolean;
  onClose: () => void;
  category?: Mcg | null;
  onSubmit: (riskProfile: string) => void;
};

function EditCategoryRiskModal({ category, onSubmit, open, onClose }: Props) {
  const [selectedRisk, setSelectedRisk] = useState<string>(
    category?.riskProfile ?? ''
  );

  const isInputValid = () => {
    let isRiskValid = selectedRisk.length > 0;
    return isRiskValid;
  };

  function _onSubmit() {
    if (isInputValid() == false) return;
    onSubmit(selectedRisk);
    setSelectedRisk('');
  }

  const categoryDisplayName =
    (category?.name ?? '').length > 0
      ? _.startCase(_.toLower(category?.name))
      : '';

  useEffect(() => {
    setSelectedRisk(category?.riskProfile ?? '');
  }, [category]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      style={{ maxHeight: '80vh', marginTop: '7.5rem' }}
      scroll="paper"
    >
      <Stack style={{ marginLeft: '1.5rem', marginTop: '1rem' }}>
        <Typography>Edit</Typography>
      </Stack>
      <DialogContent>
        <Stack spacing={2}>
          <VegaFormInputFieldV2 label="Category">
            <VegaTextFieldV2 defaultValue={categoryDisplayName} disabled />
          </VegaFormInputFieldV2>

          <VegaFormInputFieldV2 label="Risk">
            <VegaSelectV2
              options={riskProfileOptions()}
              value={selectedRisk}
              onChange={(e) => setSelectedRisk(e.target.value as string)}
            />
          </VegaFormInputFieldV2>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'}>
          <VegaPrimaryButton
            text={'Close'}
            variant={'text'}
            onClick={onClose}
          />
          <VegaPrimaryButton
            disabled={isInputValid() == false}
            text={'Submit'}
            onClick={_onSubmit}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default EditCategoryRiskModal;

const riskProfileOptions = () =>
  Object.values(RiskProfiles).map((value) => {
    const option: VegaSelectOptionV2 = {
      id: value,
      label: _.startCase(_.toLower(value)),
      value: value,
    };
    return option;
  });
