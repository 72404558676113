import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { VegaTextField } from '../../../../components/common';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../../../components/common/VegaSelect';
import { fetchListForInventory } from '../../api/inventory';
import { IBinAllocationOrderProps, IBinProps } from '../../../types/Bin';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { listBinForClient } from '../../../../api/bin';
import BinSelector from '../../../components/BinSelector';

interface IProps {
  handleChange: (name: string, value: any) => void;
  state: IBinAllocationOrderProps;
  isShow: boolean;
}

const OrderInventory = ({ state, handleChange, isShow }: IProps) => {
  const { clientId } = useClientAuth();
  const [binData, setBinData] = useState<VegaSelectSelectOptionType[]>([]);
  const [listLoading, setListLoading] = useState<boolean>(false);

  // const getInventoryList = () => {
  //   setListLoading(true);
  //   // fetchListForInventory(clientId as string)
  //   listBinForClient(clientId as string, 0, 100)
  //     .then((res) => {
  //       const binArr = res.data?.records?.map((val: any) => ({
  //         id: val.id,
  //         name: val.binName,
  //       }));
  //       setBinData(binArr);
  //       setListLoading(false);
  //     })
  //     .catch((err) => {
  //       setListLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   getInventoryList();
  // }, []);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} mt={2}>
          <BinSelector
            selected={state.binId}
            name="binId"
            handleChange={(value) => handleChange('binId', value)}
            disabled={isShow}
          />
          {/* <VegaSelect
            options={binData}
            key="binId"
            selected={state.binId}
            label="Select Bin Value"
            onChange={(value) => handleChange('binId', value)}
            disabled={isShow}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12} mt={2}>
          <VegaTextField
            value={state.numberOfKits}
            onChange={(e) =>
              handleChange(
                'numberOfKits',
                e.target.value && parseFloat(e.target.value)
              )
            }
            key="numberOfKits"
            label="Number Of Kit"
            type={'number'}
            placeholder="Number Of Kit"
            disabled={isShow}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderInventory;
