import { Box, ThemeProvider, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { VEGA_BOX_SHADOW } from '../../../constants/style';
import ChargeBackDetailsModal from '../../../pages/ChargeBackDetailsModal';
import { ChargebackDto } from '../../../types/chargeBack';
import { VP_THEME } from '../../../ui-components/theme';
import { getCustomerName } from '../../../utils/common.utils';
import { StatusDisplayText } from '../../ChargeBack/StatusChangeCards/StatusChangeCards';
import { useChargeBack } from '../../ChargeBack/hooks';
import { VegaTable } from '../../common';

function ChargeBackRequestList() {
  const { listChargeBackForProgram, paginationData } = useChargeBack();

  const COLUMN_DEF: GridColumns<ChargebackDto> = [
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => {
        return props.row.raisedBy;
      },
      renderCell: (props) =>
        props.value ? <Typography>{props.value}</Typography> : '--',
    },
    {
      field: 'id',
      headerName: 'Charge Back Id',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (props) => props.row.id,
      renderCell: (props) =>
        props.value ? <Typography>{props.value}</Typography> : '--',
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => getCustomerName(props.row.customerDto),
      renderCell: (props) =>
        props.value ? <Typography>{props.value}</Typography> : '--',
    },
    {
      field: 'tatStatus',
      headerName: 'TAT Status',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => props.row.tatStatus,
      renderCell: (props) =>
        props.value ? <Typography>{props.value}</Typography> : '--',
    },

    {
      field: 'tatValue',
      headerName: 'TAT Value',

      flex: 0.8,
      minWidth: 80,
      valueGetter: (props) => props.row.tatValue,
      renderCell: (props) =>
        props.value ? <Typography>{props.value}</Typography> : '--',
    },
    {
      field: 'chargeBackStatus',
      headerName: 'Charge Back Status',
      flex: 2,
      minWidth: 200,
      valueGetter: (props) => props.row.chargebackStatus,
      renderCell: (props) =>
        props.value ? (
          <StatusDisplayText status={props.row.chargebackStatus} />
        ) : (
          '--'
        ),
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      minWidth: 200,
      renderCell: (props) => {
        return (
          <ChargeBackDetailsModal
            triggerLabel="View Details"
            chargeBackDto={props.row}
          />
        );
      },
    },
  ];

  return (
    <ThemeProvider theme={VP_THEME}>
      <Box
        sx={{
          boxShadow: VEGA_BOX_SHADOW,
          borderRadius: '20px',
          bgcolor: 'background.paper',
        }}
      >
        <Typography
          variant="body1"
          style={{
            backgroundColor: '#F9F9F9',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          }}
          sx={{ px: 5, py: 2 }}
          fontWeight={600}
        >
          Chargeback Request
        </Typography>

        <VegaTable
          columnDef={COLUMN_DEF}
          idColumn={'id'}
          data={listChargeBackForProgram.data?.records || []}
          headerColor="#E5F6FF"
          headerFontWeight={500}
          noTopRadius
          loading={
            listChargeBackForProgram.isLoading ||
            listChargeBackForProgram.isPreviousData
          }
          {...paginationData}
        />
      </Box>
    </ThemeProvider>
  );
}

export default ChargeBackRequestList;
