/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Vega_BreadCrumb from '../../../../components/common/v3/Vega_BreadCrumb';
import { useProfitLoss } from '../../../../providers/ProfitLossProvider';
import Vega_PageHeader from '../../../../components/common/v3/Vega_PageHeader';
import { Grid, Stack } from '@mui/material';
import { Vega_Pill } from '../../../../components/common/v3/Vega_Pill';
import { GREEN } from '../../../../constants/style';
import Vega_Text from '../../../../components/common/v3/Vega_Text';
import Vega_PageContent from '../../../../components/common/v3/Vega_PageContent';
import ProfitLossSummaryContainer from './Summary/ProfitLossSummaryContainer';
import DailyProfitLoss from './DailyProfitLoss/DailyProfitLoss';
import GraphsContainer from './Graphs/GraphsContainer';

const ProfitLossAccountLendingPage = () => {
  const { setIsSelectedProgram, selecteAccount } = useProfitLoss();
  return (
    <>
      <Vega_PageHeader
        sx={{
          bgcolor: 'white',
          py: 1,
          minHeight: '2rem',
        }}
        renderLeftView={() => (
          <Vega_BreadCrumb
            items={[
              {
                label: 'Profit & Loss',
                onClick: () => setIsSelectedProgram(false),
              },
              { label: selecteAccount ? selecteAccount.programName ?? '' : '' },
            ]}
          />
        )}
      />
      <Vega_PageHeader
        sx={{
          bgcolor: 'white',
          py: 1,
          minHeight: '3.3rem',
        }}
        renderLeftView={() => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextWrapper
                name="PROGRAM ID"
                value={selecteAccount.progrmaId}
                isBorder
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextWrapper
                name="PROGRAM TYPE"
                value={selecteAccount.productType}
                isBorder
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextWrapper name="lIVE DATE" value={selecteAccount.liveOn} />
            </Grid>
          </Grid>
        )}
        renderRightView={() => (
          <Vega_Pill
            text="Active"
            borderColor={GREEN.dark}
            textColor={GREEN.dark}
            backgroundColor={GREEN.light}
          />
        )}
      />
      <Vega_PageContent sx={{ my: 2 }}>
        <ProfitLossSummaryContainer
          title="Program Summary"
          lastUpdated={selecteAccount.lastUpdated ?? ''}
          income={selecteAccount.income ?? 0}
          cost={selecteAccount.cost ?? 0}
        />
      </Vega_PageContent>
      <Vega_PageContent sx={{ my: 2 }}>
        <DailyProfitLoss />
      </Vega_PageContent>
      <Vega_PageContent sx={{ my: 2 }}>
        <GraphsContainer />
      </Vega_PageContent>
    </>
  );
};

export default ProfitLossAccountLendingPage;

interface ITextWrapperProps {
  name: string;
  value: string | number | undefined;
  isBorder?: boolean;
}

const TextWrapper = ({ name, value, isBorder }: ITextWrapperProps) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      gap={1}
      sx={{ borderRight: isBorder ? '1px solid #EEEEEE' : 'none' }}
    >
      <Vega_Text
        text={`${name} :`}
        fontWeight={700}
        fontSize={10}
        fontColor="#7B8694"
        sx={{ letterSpacing: 1 }}
      />
      <Vega_Text
        text={value ?? ''}
        fontWeight={450}
        fontSize={13}
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          flex: 1,
        }}
      />
    </Stack>
  );
};
