export const GREEN = {
  green: '#36913F',
  lightgreen: '#EBF4EC',
};
export const RED = {
  red: '#BA1C1C ',
  lightred: '#F8E8E8',
};
export const YELLOW = {
  yellow: '#DDBA08',
  lightyellow: '#FCF8E6',
};
