import { CancelOutlined, CheckCircleOutlineRounded } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { GREY } from '../../constants/style';
import { usePolicy } from '../../providers/PolicyProvider';
import {
  EntityAuthStatus,
  EntityComment,
  EntityStatus,
  UserMakerCheckerRole,
} from '../../types/entityAuth';
import { AnyPolicyType } from '../../types/policy';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaOutlineButton from '../common/VegaOutlineButton';
import CheckerCommentInput, {
  CommentInputViewState,
} from './CheckerComment/CheckerCommentInput';

interface PolicyCardFooterButtonProps {
  role?: UserMakerCheckerRole;
  policy?: AnyPolicyType;
  policyEntityAuth?: EntityAuthStatus | null;
  onSaveClick?: () => void;
  onApproveClick?: () => void;
  onCommentAddClick?: (comment: string) => void;
  onCommentEdit?: (commentId: string, comment: string) => void;
  onPastCommentsClick?: (entityId?: string) => void;
  isLoading: boolean;
  isSaveDisabled: boolean;
}

type CommentsViewStateData = {
  show: boolean;
  initialViewState: CommentInputViewState;
};

export const PolicyFooterActions = ({
  role,
  policy,
  onSaveClick,
  onApproveClick,
  isLoading,
  isSaveDisabled,
  onCommentAddClick,
  onCommentEdit,
  // policyEntityAuth,
  onPastCommentsClick,
}: PolicyCardFooterButtonProps) => {
  const { areAllPoliciesApproved, entityAuthStatuses } = usePolicy();
  const [showCommentView, setShowCommentView] = useState<CommentsViewStateData>(
    {
      show: false,
      initialViewState: CommentInputViewState.INITIAL,
    }
  );
  const [comments, setComments] = useState<EntityComment[]>();
  const [policyEntityAuth, setPolicyEntityAuth] =
    useState<EntityAuthStatus | null>();

  useEffect(() => {
    const policyId = policy?.policy_id;
    if (!policyId) return;
    const currentPolicyStatus = entityAuthStatuses.get(policyId);
    setPolicyEntityAuth(currentPolicyStatus);
  }, [entityAuthStatuses]);

  useEffect(() => {
    if (!policyEntityAuth) return;
    checkShouldShowCommentsView();
  }, [policyEntityAuth]);

  function checkShouldShowCommentsView() {
    const comments = policyEntityAuth?.commentsList;
    setComments(comments);
    const isChecker = role == UserMakerCheckerRole.CHECKER;
    if (isChecker) {
      const isRejected =
        policyEntityAuth?.status == EntityStatus.DRAFT_REJECTED;
      if (isRejected) {
        const hasComments = (comments ?? []).length > 0;
        setShowCommentView({
          show: true,
          initialViewState: hasComments
            ? CommentInputViewState.COMMENT_ADDED
            : CommentInputViewState.INITIAL,
        });
        return;
      }
      setShowCommentView({
        show: false,
        initialViewState: CommentInputViewState.INITIAL,
      });
    }
  }

  const getLatestComment = () => {
    if (!comments || comments.length <= 0) {
      return null;
    }
    return comments[0];
  };

  const canShowPastCommentsLink = () => {
    const hasComments = (policyEntityAuth?.commentsList ?? []).length > 0;
    if (role == UserMakerCheckerRole.MAKER) {
      return areAllPoliciesApproved && hasComments;
    }
    return hasComments;
  };

  const getPolicyStatusText = () => {
    if (role == UserMakerCheckerRole.MAKER) {
      let status = policyEntityAuth?.status;
      if (
        status == EntityStatus.DRAFT_APPROVED ||
        status == EntityStatus.DRAFT_REJECTED
      ) {
        return '';
      }
    }
    return policyEntityAuth?.status;
  };

  const MakerLayout = () => {
    if (isSaveDisabled) return <></>;
    return (
      <Stack direction={'row'} spacing={2}>
        <VegaContainedButton
          text="Save"
          onClick={onSaveClick}
          isPrimary
          loading={isLoading}
          disabled={isSaveDisabled}
        />
      </Stack>
    );
  };

  const ApproveButton = () => {
    return (
      <VegaOutlineButton
        text="Approve"
        onClick={onApproveClick}
        isSuccess
        loading={isLoading}
        px={5}
        startIcon={<CheckCircleOutlineRounded fontSize="small" />}
        disabled={isSaveDisabled}
      />
    );
  };

  const DisapproveButton = () => {
    return (
      <VegaOutlineButton
        text="Disapprove"
        onClick={() => {
          setShowCommentView({
            show: true,
            initialViewState: CommentInputViewState.INITIAL,
          });
        }}
        isDanger
        loading={isLoading}
        px={5}
        startIcon={<CancelOutlined fontSize="small" />}
        disabled={isSaveDisabled}
      />
    );
  };

  const CheckerLayout = () => {
    if (isSaveDisabled) return <></>;
    const status = policyEntityAuth?.status;
    if (!status) return <></>;
    if (status == EntityStatus.DRAFT_APPROVED) {
      return <DisapproveButton />;
    }
    if (status == EntityStatus.DRAFT_REJECTED) {
      return <ApproveButton />;
    }
    if (status == EntityStatus.CHECKER_PENDING) {
      return (
        <Stack direction={'row'} spacing={2}>
          <DisapproveButton />
          <ApproveButton />
        </Stack>
      );
    }
    return <></>;
  };

  if (showCommentView.show) {
    return (
      <Box
        sx={{
          marginTop: '1.25rem',
        }}
      >
        <CheckerCommentInput
          initialState={showCommentView.initialViewState}
          initialValue={getLatestComment()}
          onAddCommentClick={(comment) => {
            onCommentAddClick?.(comment);
          }}
          onCancelClick={() =>
            setShowCommentView({
              show: false,
              initialViewState: CommentInputViewState.INITIAL,
            })
          }
          onApprovedClick={() => {
            if (onApproveClick) onApproveClick();
          }}
          disabled={isSaveDisabled}
          onCommentEdit={(commentId, comment) => {
            onCommentEdit?.(commentId, comment);
          }}
        />
        <Stack
          direction={'row'}
          justifyContent="flex-end"
          alignItems={'center'}
        >
          <Stack direction={'row'} spacing={2}>
            <Typography
              sx={{
                color: GREY.grey,
                fontSize: 14,
              }}
            >
              {_.startCase(_.toLower(getPolicyStatusText()))}
            </Typography>
            <Typography sx={{ color: GREY.grey, fontSize: 14 }}>
              {policy?.updated_at ? (
                <>
                  Last updated:{' '}
                  <b>{dayjs(policy?.updated_at).format('MMM D, YYYY')}</b>
                </>
              ) : (
                'policy not set'
              )}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: '1.25rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: canShowPastCommentsLink()
            ? 'space-between'
            : 'flex-end',
          alignItems: 'center',
        }}
      >
        {canShowPastCommentsLink() && (
          <Typography
            variant="caption"
            style={{
              cursor: 'pointer',
              color: '#4785FF',
            }}
            onClick={() => {
              onPastCommentsClick?.(policyEntityAuth?.entityId);
            }}
          >
            View past comments
          </Typography>
        )}
        <Stack
          alignContent={'end'}
          alignItems="end"
          justifyContent="end"
          spacing={1}
        >
          <VegaConditionalWrapper
            condition={
              role == UserMakerCheckerRole.NOT_DEFINED || areAllPoliciesApproved
            }
            ifFalse={
              <VegaConditionalWrapper
                condition={role == UserMakerCheckerRole.MAKER}
                ifTrue={<MakerLayout />}
                ifFalse={<CheckerLayout />}
              />
            }
          />
          <Stack direction={'row'} spacing={2}>
            <Typography
              sx={{
                color: GREY.grey,
                fontSize: 14,
              }}
            >
              {_.startCase(_.toLower(getPolicyStatusText()))}
            </Typography>
            <Typography sx={{ color: GREY.grey, fontSize: 14 }}>
              {policy?.updated_at ? (
                <>
                  Last updated:{' '}
                  <b>{dayjs(policy?.updated_at).format('MMM D, YYYY')}</b>
                </>
              ) : (
                'policy not set'
              )}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
