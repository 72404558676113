import React, { useEffect, useState } from 'react';
import VegaSelect from '../../components/common/VegaSelect';
import { useClientAuth } from '../../providers/ClientProvider';
import { fetchBranchList } from '../Program-Dashboard/api/Branch';

interface IProps {
  selected: any;
  handleChange: (value: any) => void;
  name: string;
  label?: string;
  disabled?: boolean;
}

const BranchSelector = ({
  name,
  selected,
  handleChange,
  label,
  disabled,
}: IProps) => {
  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [branch, setBranch] = useState<any[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== branch.length) {
        setPage(page + 1);
      }
    }
  };

  const getProgramList = async () => {
    fetchBranchList({ clientId, page, pageSize: 10 }).then((res) => {
      const newArr = res.data?.records?.map((val: any) => ({
        name: val.branchName,
        id: val.id,
        branchCode: val.branchCode,
      }));
      setBranch([...branch, ...newArr]);
      setTotalItem(res.data.totalItems);
    });
  };

  const handleValue = (id: string) => {
    const findBranch = branch.find((val: any) => val.id === id);
    console.log('findBranch', findBranch);
    handleChange({
      branchCode: findBranch.branchCode,
      branchId: findBranch.id,
      branchName: findBranch.name,
    });
  };

  useEffect(() => {
    getProgramList();
  }, [page]);
  return (
    <VegaSelect
      placeholder={label ?? 'Branch Name'}
      label={label ?? 'Branch Name'}
      size="small"
      key={name}
      selected={selected ?? ''}
      onChange={(value) => handleValue(value)}
      options={branch}
      onScroll={loadMoreItems}
      disabled={disabled}
    />
  );
};

export default BranchSelector;
