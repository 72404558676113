/** @format */

import { Typography, Box, Theme, SxProps } from '@mui/material';
import React from 'react';
import VegaCard from './VegaCard';

type Props = {
  text?: string;
  subtext?: string;
  image?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const NoResults = ({ text, subtext, image, sx }: Props) => {
  return (
    <VegaCard
      fullHeight
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      {image && <Box sx={{ marginBottom: 5 }}>{image}</Box>}
      <Typography color={'primary.main'} variant="subtitle1" mb={1}>
        {text || 'No Results'}
      </Typography>
      {subtext && (
        <Typography color={'primary.main'} variant="body2">
          {subtext}
        </Typography>
      )}
    </VegaCard>
  );
};

export default NoResults;
