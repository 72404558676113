import { Box, Typography } from '@mui/material';
import { AntSwitch, toLowerCase } from '../../../../constants/commonFunction';
import { COMMON } from '../../../../constants/style';
import { getIconForVendor } from '../../../../pages/RewardLoyalty/components/BenefitsInventory';
import {
  ICreateInventoryProps,
  IDiscountVoucherProps,
  IGiftVoucherProps,
  ISubscriptionProps,
} from '../../../../types/RewardsLoyalty';
import { AmazoneIcon } from '../../../icon/Icons';

export interface IVoucherColumn {
  key:
    | keyof ICreateInventoryProps
    | keyof IGiftVoucherProps
    | keyof ISubscriptionProps
    | keyof IDiscountVoucherProps
    | string;
  header: string;
  flex: number;
  // Other properties of the column
}

const COLUMN: IVoucherColumn[] = [
  {
    key: 'vendorName',
    header: 'Vendor',
    flex: 1,
  },
  {
    key: 'amount',
    header: 'Amount',
    flex: 0.5,
  },
  {
    key: 'quantity',
    header: 'Quantity',
    flex: 0.5,
  },

  {
    key: 'totalValue',
    header: 'Total value',
    flex: 0.5,
  },
  {
    key: 'assigned',
    header: 'Assigned',
    flex: 0.5,
  },
  {
    key: 'remaining',
    header: 'Remaining',
    flex: 0.5,
  },
  {
    key: 'autoRenew',
    header: 'Auto Renew',
    flex: 0.5,
  },
];

interface IProps {
  data: ICreateInventoryProps[];
  updateRenewInventory: (id: string | undefined, renew: boolean) => void;
}

const ListOfGiftVoucher = ({ data, updateRenewInventory }: IProps) => {
  return (
    <Box sx={{ px: 2 }}>
      <Box
        sx={{
          display: 'flex',

          //   bgcolor: GREY.light,
        }}
      >
        {COLUMN.map((column: IVoucherColumn) => (
          <Box
            className="font-aspekta-500"
            sx={{
              flex: column.flex,
              py: 2,
              px: 2,
              fontSize: 13,
              color: COMMON.gray,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {toLowerCase(column.header)}
          </Box>
        ))}
      </Box>

      {data.map((voucher: ICreateInventoryProps) => (
        <Box
          sx={{
            display: 'flex',
            bgcolor: '#F7F7F7',
            my: 1.5,
            borderRadius: '15px',
            cursor: 'pointer',
          }}
          //   onClick={() => selectCampaign(voucher)}
        >
          {COLUMN.map((column: IVoucherColumn) => {
            // const kycURL = getURL(value.kycUrl);

            return (
              <>
                <Box
                  sx={{
                    flex: column.flex,
                    py: 1.5,
                    px: 2,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {column.key === 'autoRenew' ? (
                    <Box>
                      <AntSwitch
                        sx={{ mx: 1 }}
                        checked={voucher.autoRenew}
                        onChange={(e) =>
                          updateRenewInventory(voucher?.id, e.target.checked)
                        }
                      />
                    </Box>
                  ) : column.key === 'amount' ? (
                    <Typography fontSize={14} className="font-aspekta-500">
                      {voucher?.giftVoucherAmount?.amount}
                    </Typography>
                  ) : column.key === 'vendorName' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {getIconForVendor(voucher[column.key])}
                      <Typography
                        fontSize={14}
                        ml={1}
                        className="font-aspekta-500"
                      >
                        {toLowerCase(voucher[column.key])}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography fontSize={14} className="font-aspekta-500">
                      {toLowerCase(voucher[column.key])}
                    </Typography>
                  )}

                  {column.key === 'action' && (
                    <Typography fontSize={14} className="font-aspekta-500">
                      action
                    </Typography>
                  )}
                </Box>
              </>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

export default ListOfGiftVoucher;
