import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import VegaLabel from '../../../../components/common/VegaLabel';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import { toLowerCase } from '../../../../constants/commonFunction';
import {
  INITIAL_DATA,
  ITeamsProps,
} from '../../../Back-office/pages/MCCTeamManagement';
import VegaSelect from '../../../../components/common/VegaSelect';
import { BLUE, BORDER_GRAY, GREEN, GREY } from '../../../../constants/style';
import { STATUS_DATA, dateFormat } from '../../../variable';
import StatusModal from '../../../components/StatusModal';

interface IProps {
  onStatusChange: (data: any, status: string) => void;
  state: any;
}

const ListOfTeamManagementChecker = ({ state, onStatusChange }: IProps) => {
  console.log(
    '🚀 ~ file: ListOfTeamManagementChecker.tsx:23 ~ ListOfTeamManagementChecker ~ state:',
    state
  );
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [customerDetails, setCustomerDetails] = useState<ITeamsProps>({
    ...INITIAL_DATA,
  });

  const checkStatus = (
    status: string,
    makerCheckerStatus: string,
    value: any
  ) => {
    if (
      status === 'DRAFT' ||
      (status === 'UNDER_REVIEW' && makerCheckerStatus === 'CHECKER_PENDING')
    ) {
      return (
        <VegaContainedButton
          text="Edit"
          onClick={() => showDetails(value)}
          isPrimary
        />
      );
    } else {
      return <VegaContainedButton text="Sent" isPrimary disabled />;
    }
  };

  const columnDef = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 0.6,
    },
    {
      field: 'userId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          User ID
        </Typography>
      ),

      flex: 0.5,
    },
    {
      field: 'email',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Email
        </Typography>
      ),

      flex: 1,
    },
    {
      field: 'userRoles',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Alloted Roles
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Box
            sx={{
              border: BORDER_GRAY,
              borderRadius: '5px',
              // p: 0.5,
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {props.row.userRoles.length ? (
              props.row.userRoles.map((role: any) => (
                <div className="" title={toLowerCase(role?.status)}>
                  <VegaLabel
                    text={toLowerCase(role?.roleName)}
                    color={'primary'}
                  />
                </div>
              ))
            ) : (
              <VegaLabel text="No Roles" color={'primary'} />
            )}
          </Box>
        );
      },
    },
    {
      field: 'activationDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Activation Date
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <VegaLabel text={dateFormat(props.row.createdAt)} color={'success'} />
        );
      },
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        const checkerMakerStatus =
          props.row.userRoles[0].checkerMakerStatus ?? '-';
        return (
          <VegaLabel text={toLowerCase(checkerMakerStatus)} color={'primary'} />
        );
      },
    },

    {
      field: 'action',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        const status = props.row.userRoles[0]?.status;
        const checkerMakerStatus = props.row.userRoles[0]?.checkerMakerStatus;
        return checkStatus(status, checkerMakerStatus, props.row);
      },
      flex: 0.7,
    },
  ];

  const showDetails = (data: ITeamsProps) => {
    setCustomerDetails(data);

    setOpen(!open);
  };
  const decline = () => {
    setOpen(!open);
    setStatus('');
  };
  const approve = async () => {
    await onStatusChange(customerDetails?.userRoles[0]?.id, status);
    setOpen(!open);
  };
  console.log('customerDetails', customerDetails);
  return (
    <VegaCard fullHeight noPad>
      <VegaTable data={state} columnDef={columnDef} idColumn={'userId'} />
      <StatusModal
        header="Status "
        onSubmit={approve}
        open={open}
        radioData={STATUS_DATA}
        name="status"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        labelPlacement={'right'}
        handleClose={() => {
          setOpen(!open);
          setStatus('');
        }}
        color={GREEN.dark}
      />
      {/* <VegaDrawer
        open={open}
        header={customerDetails.name}
        closeText="Decline"
        isClose
        onClose={decline}
        // onSubmit={approve}
      >
        {customerDetails.userRoles.length ? (
          <>
            <Grid container sx={{ borderRadius: '5px' }}>
              {customerDetails.userRoles.map((val: any) =>
                val.status === 'UNDER_REVIEW' ? (
                  <>
                    <Grid
                      item
                      sm={5}
                      sx={{
                        bgcolor: BLUE.lightest,
                        color: BLUE.lighter,
                        px: 2,
                        py: 1,
                        mb: 1,
                      }}
                    >
                      Role Name
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        bgcolor: BLUE.lightest,
                        color: BLUE.lighter,
                        px: 2,
                        py: 1,
                        mb: 1,
                      }}
                    >
                      Status
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        bgcolor: BLUE.lightest,
                        color: BLUE.lighter,
                        px: 2,
                        py: 1,
                        mb: 1,
                      }}
                    >
                      Action
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        bgcolor: BLUE.lightest,
                        color: BLUE.lighter,
                        px: 2,
                        py: 1,
                        mb: 1,
                      }}
                    ></Grid>
                    <Grid item sm={5}>
                      <Typography
                        variant="body2"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          width: '100%',
                        }}
                      >
                        {toLowerCase(val.roleName)}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <VegaLabel
                        text={toLowerCase(val.status)}
                        color="primary"
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <VegaSelect
                        isFixedHeight
                        options={['APPROVED', 'REJECTED', 'CHANGES_REQUESTED']}
                        key="status"
                        selected={status}
                        onChange={(value) => setStatus(value)}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <VegaContainedButton
                        text={'Update'}
                        disabled={!status.length}
                        isPrimary
                        onClick={() => approve(val.id)}
                      />
                    </Grid>
                  </>
                ) : (
                  'No Under review'
                )
              )}
            </Grid>
          </>
        ) : (
          ''
        )}
      </VegaDrawer> */}
    </VegaCard>
  );
};

export default ListOfTeamManagementChecker;
