import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ReactElement } from 'react';
import { VegaTextField } from '../../common';

type Props = {
  name: string;
  value?: any;
  onChange?: any;
  children?: ReactElement;
};

function ScheduleInputItem({ name, value, onChange, children }: Props) {
  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={3} alignItems="center" display={'flex'}>
        <Typography sx={{ mr: 2, my: 1 }}>{name}</Typography>
      </Grid>
      <Grid item xs={9} alignItems="center" display={'flex'}>
        {children ? (
          children
        ) : (
          <VegaTextField
            type="text"
            key="subject"
            onChange={onChange}
            value={value}
          ></VegaTextField>
        )}
      </Grid>
    </Grid>
  );
}

export default ScheduleInputItem;
