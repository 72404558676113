import { Save } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getCardSettings, saveCardSettings } from '../../api/card';
import CardConfigProvider, {
  CardType,
} from '../../providers/CardConfigProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { CardActivationOptions, CvvType } from '../../types/card';
import { VegaProgramType } from '../../types/program';
import { VegaPage } from '../common';
import VegaCardV2 from '../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../common/v2/VegaPrimaryButton';
import VegaSelectV2, { VegaSelectOptionV2 } from '../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../common/v2/VegaTextFieldV2';
import CardConfigModal from './CardConfigModal/CardConfigModal';
import CardConfigPreviewCard from './CardConfigPreviewCard';
import { CardHeading } from './CardHeading';

type FormData = {
  physicalCardActivationProcess: string;
  virtualCardActivationProcess: string;
  cardDigit: string;
  expiryMonth: string;
  cvvType: string;
  cvvLength: string;
};

enum InputType {
  CARD_DIGIT = 'cardDigit',
  EXPIRY_MONTH = 'expiryMonth',
  CVV_TYPE = 'cvvType',
  CVV_LENGTH = 'cvvLength',
  PHYSICAL_CARD_PROCESS = 'physicalCardActivationProcess',
  VIRTUAL_CARD_PROCESS = 'virtualCardActivationProcess',
}

type Props = {
  selectedProgram: VegaProgramType | null;
};

const INTITIAL = {
  physicalCardActivationProcess: '',
  virtualCardActivationProcess: '',
  cardDigit: '',
  expiryMonth: '',
  cvvType: '',
  cvvLength: '',
};

function CardSettings({ selectedProgram }: Props) {
  const [formData, setFormData] = useState<FormData>(INTITIAL);
  const [showPhysicalCardConfigModal, setShowPhysicalCardConfigModal] =
    useState<boolean>(false);
  const [showVirtualCardConfigModal, setShowVirtualCardConfigModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();

  function updateFormData(type: InputType, updatedValue: string) {
    setFormData((prevState) => {
      return {
        ...prevState,
        [type]: updatedValue,
      };
    });
  }

  function isInputValid() {
    const items = Array.from(Object.entries(formData));
    const invalidItems = items.filter((item) => {
      const isEntryValid = Boolean(item[1]);
      return isEntryValid == false;
    });
    return invalidItems.length == 0;
  }

  function onSave() {
    const programId = selectedProgram?.programId;
    const cardDigit = formData.cardDigit;
    const expiryMonth = formData.expiryMonth;
    const cvvType = formData.cvvType;
    const cvvLength = formData.cvvLength;
    const physicalProcess = formData.physicalCardActivationProcess;
    const virtualProcess = formData.virtualCardActivationProcess;
    if (
      !programId ||
      !cardDigit ||
      !expiryMonth ||
      !cvvType ||
      !cvvLength ||
      !physicalProcess ||
      !virtualProcess
    ) {
      console.error('Input is not valid');
      setSnackbar('Invalid Input', 'error');
      return;
    }
    setLoading(true);
    saveCardSettings({
      programId: programId,
      cardDigit: Number(cardDigit),
      expiryMonth: Number(expiryMonth),
      cvvType: cvvType,
      cvvLength: Number(cvvLength),
      physicalCardActivationProcess: physicalProcess,
      virtualCardActivationProcess: virtualProcess,
    })
      .then((res) => {
        setSnackbar('Saved Settings');
      })
      .catch((err) => {
        console.error(err);
        setSnackbar('Failed to save settings', 'error');
      })
      .finally(() => setLoading(false));
  }

  function fetchSettings() {
    setFormData(INTITIAL);
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    setLoading(true);
    getCardSettings({ programId: programId })
      .then((res) => {
        setFormData({
          cvvLength: res.cvvLength.toString(),
          cvvType: res.cvvType,
          expiryMonth: res.expiryMonth.toString(),
          cardDigit: res.cardDigit.toString(),
          virtualCardActivationProcess: res.virtualCardActivationProcess,
          physicalCardActivationProcess: res.physicalCardActivationProcess,
        });
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSettings();
  }, [selectedProgram]);

  return (
    <CardConfigProvider>
      <VegaPage>
        <VegaCardV2>
          <CardHeading text={'Card Details'} bold />
          <Grid container>
            <Grid item xs={6}>
              <Grid
                container
                rowSpacing={2}
                columns={5}
                style={{ marginLeft: '0' }}
              >
                <Grid item xs={2}>
                  <VegaFormInputFieldV2 label="Card digit">
                    <VegaSelectV2
                      placeholder="Select Card Digits"
                      options={cardDigitOptions()}
                      value={Number(formData.cardDigit)}
                      onChange={(e) => {
                        updateFormData(
                          InputType.CARD_DIGIT,
                          e.target.value as string
                        );
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
                <Grid item xs={2} marginLeft={'8px'}>
                  <VegaFormInputFieldV2 label="Expiry Month (No. of Months)">
                    <VegaTextFieldV2
                      value={
                        formData.expiryMonth == ''
                          ? ''
                          : Number(formData.expiryMonth)
                      }
                      defaultValue={formData.expiryMonth == '' ? '' : undefined}
                      onChange={(e) => {
                        updateFormData(InputType.EXPIRY_MONTH, e.target.value);
                      }}
                      type="number"
                    />
                  </VegaFormInputFieldV2>
                </Grid>
                <Grid item xs={2}>
                  <VegaFormInputFieldV2 label="Cvv">
                    <VegaSelectV2
                      placeholder="Select CVV Type"
                      options={cvvOptions()}
                      value={formData.cvvType}
                      onChange={(e) => {
                        updateFormData(
                          InputType.CVV_TYPE,
                          e.target.value as string
                        );
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
                <Grid item xs={2} marginLeft={'8px'}>
                  <VegaFormInputFieldV2 label="CVV Digit">
                    <VegaSelectV2
                      placeholder="Select Digit"
                      options={digitOptions()}
                      value={Number(formData.cvvLength)}
                      onChange={(e) => {
                        updateFormData(
                          InputType.CVV_LENGTH,
                          e.target.value as string
                        );
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VegaCardV2>

        <VegaCardV2>
          <CardHeading text={'Card Activation Process'} bold />

          <Grid container>
            <Grid item xs={6}>
              <Grid
                container
                rowSpacing={2}
                columns={5}
                style={{ marginLeft: '0' }}
              >
                <Grid item xs={2}>
                  <VegaFormInputFieldV2 label="Physical Card">
                    <VegaSelectV2
                      placeholder="Select Physical Card"
                      options={physicalCardActivationOptions()}
                      value={formData.physicalCardActivationProcess}
                      onChange={(e) => {
                        updateFormData(
                          InputType.PHYSICAL_CARD_PROCESS,
                          e.target.value as string
                        );
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
                <Grid item xs={2} marginLeft={'8px'}>
                  <VegaFormInputFieldV2 label="Virtual Card">
                    <VegaSelectV2
                      placeholder="Select Virtual Card"
                      options={virtualCardActivationOptions()}
                      value={formData.virtualCardActivationProcess}
                      onChange={(e) => {
                        updateFormData(
                          InputType.VIRTUAL_CARD_PROCESS,
                          e.target.value as string
                        );
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VegaCardV2>

        <VegaCardV2>
          <CardHeading text={'Card Configuration'} bold />

          <Grid
            container
            rowSpacing={2}
            columns={10}
            style={{
              marginLeft: '0',
              alignItems: 'stretch',
            }}
          >
            <Grid item xs={4}>
              <CardConfigPreviewCard
                title={'Physical Card'}
                description={
                  'Configure physical card design for your customer to use offline'
                }
                onConfigureClick={() => {
                  setShowPhysicalCardConfigModal(true);
                }}
                cardType={CardType.PHYSICAL}
              />
            </Grid>
            <Grid item xs={4} marginLeft={'8px'}>
              <CardConfigPreviewCard
                title={'Virtual Card'}
                description={
                  'Configure virtual card design which will be visible to customers on mobile app'
                }
                onConfigureClick={() => setShowVirtualCardConfigModal(true)}
                cardType={CardType.VIRTUAL}
              />
            </Grid>
          </Grid>
        </VegaCardV2>

        <Stack direction={'row'} justifyContent="end" style={{ padding: 12 }}>
          <VegaPrimaryButton
            text="Save"
            startIcon={<Save />}
            disabled={isInputValid() == false}
            onClick={onSave}
            loading={loading}
          />
        </Stack>

        <CardConfigModal
          open={showPhysicalCardConfigModal}
          cardType={CardType.PHYSICAL}
          onClose={function (): void {
            setShowPhysicalCardConfigModal(false);
          }}
        />
        <CardConfigModal
          open={showVirtualCardConfigModal}
          cardType={CardType.VIRTUAL}
          onClose={function (): void {
            setShowVirtualCardConfigModal(false);
          }}
        />
      </VegaPage>
    </CardConfigProvider>
  );
}

export default CardSettings;

const virtualCardActivationOptions = () =>
  [
    CardActivationOptions.OTP_ON_EMAIL,
    CardActivationOptions.OTP_ON_MOBILE,
    CardActivationOptions.OTP_ON_MOBILE_AND_EMAIL,
  ].map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.lowerCase(item)),
    };
    return option;
  });

const physicalCardActivationOptions = () =>
  [CardActivationOptions.GREEN_PIN, CardActivationOptions.PIN_THROUGH_SMS].map(
    (item) => {
      const option: VegaSelectOptionV2 = {
        id: item,
        value: item,
        label: _.startCase(_.lowerCase(item)),
      };
      return option;
    }
  );

const digitOptions = () =>
  ['3', '4'].map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.lowerCase(item)),
    };
    return option;
  });

const cardDigitOptions = () =>
  ['15', '16'].map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.lowerCase(item)),
    };
    return option;
  });

const cvvOptions = () =>
  Object.values(CvvType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.lowerCase(item)),
    };
    return option;
  });
