import { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import VegaSelector from '../../../components/common/VegaSelector';
import {
  CorporateCardIcon,
  CreditCardIcon,
} from '../../../components/icon/Icons';
import { sorting, toLowerCase } from '../../../constants/commonFunction';
import { BACKGROUND } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  IBinAllocationOrderListProps,
  IBinAllocationOrderProps,
  IBinProps,
  IStockTranseferListProps,
} from '../../types/Bin';
import { STATUS } from '../../variable';
import {
  IPaginationProps,
  addBinAllocationSubmit,
  addInventoryOrder,
  addStockTransferRequest,
  fetchBinAlloctionListForClient,
  fetchListForBinAlloctionEntity,
  fetchListForBinAlloctionOrder,
  fetchListForStockTransfer,
  initialCheckerMakerFortransfer,
  updateBinAllocationOrder,
  updateBinAllocationStatus,
  updateStockTransferRequest,
  updateStockTransferStatus,
} from '../api/inventory';
import ListOfBinAllocationOrder from '../components/MCCInventoryManagement/ListOfBinAllocationOrder';
import ListOfInventoryManagement from '../components/MCCInventoryManagement/ListOfInventoryManagement';
import ListOfStockTransfer from '../components/MCCInventoryManagement/ListOfStockTransfer';
import MCCFilterSection from '../components/MCCUserManagement/MCCFilterSection';
import { IFilterProps } from './MCCAplicationManagement';
import { ISectionProps } from './MCCUserManagement';

export const CARDS = [
  {
    id: 'ORDER_INVENTORY',
    text: 'Order/View Inventory',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'STOCK_TRANSFER',
    text: 'Stock Transfer',
    image: <CorporateCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];

const MCCInventoryManagement = () => {
  const { setSnackbar } = useSnackbar();
  const { userId, mccPlatformId: branchId, clientId } = useClientAuth();
  const [binData, setBinData] = useState<IBinProps[]>([]);
  const [stockTransferData, setStockTransferData] = useState<
    IStockTranseferListProps[]
  >([]);
  const [binOrderData, setBinOrderData] = useState<
    IBinAllocationOrderListProps[]
  >([]);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [stockListLoading, setStockListLoading] = useState<boolean>(false);
  const [activeState, setActiveState] = useState<ISectionProps>(CARDS[0]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const onPageChange = (page: number) => {
    setPage(page);
  };
  const onPageSizeChange = (pageSize: number) => {
    setSize(pageSize);
  };

  const selectedTab = (data: any) => {
    setActiveState(data);
  };

  const onSendChecker = async (data: any) => {
    const obj = {
      binAllocationOrderId: data.id,
      userId,
    };
    console.log('obj', obj);
    addBinAllocationSubmit(obj)
      .then(async (res) => {
        setSnackbar('send Successfully!');
        await getBinAllocationOrderList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send'),
          'error'
        );
        setListLoading(false);
      });
  };
  // Order View Inventory
  const onUpdateSendChecker = async (data: any) => {
    const obj = {
      binAllocationOrderId: data.id,
      status: 'CHECKER_PENDING',
      userId,
    };
    console.log('obj', obj);
    updateBinAllocationStatus(obj)
      .then(async (res) => {
        setSnackbar('send Successfully!');
        await getBinAllocationOrderList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send'),
          'error'
        );
      });
  };

  const handleUpdate = (data: IBinAllocationOrderProps) => {
    console.log('data', data);
    const obj = {
      binAllocationOrderId: data.id,
      binId: data.binId,
      numberOfKits: data.numberOfKits,
      userId,
    };
    updateBinAllocationOrder(obj)
      .then((res) => {
        setSnackbar('Update Successfully!');
        getBinAllocationOrderList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to Update'),
          'error'
        );
        setListLoading(false);
      });
  };
  const onReoder = (data: IBinAllocationOrderProps) => {
    addInventoryOrder(data)
      .then((res) => {
        setSnackbar('Reorder Successfully!');
        getBinAllocationOrderList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to Reoder'),
          'error'
        );
        setListLoading(false);
      });
  };

  const getBinAllocationEntityList = () => {
    setListLoading(true);
    const data = {
      entityId: branchId,
      page,
      size,
      entityLevel: 'l1',
    } as IPaginationProps;
    fetchBinAlloctionListForClient(data)
      .then((res) => {
        setBinData(res.data.records);
        setRowCount(res.data.totalItems);
        setListLoading(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to fetch bin list1'),
          'error'
        );
        setListLoading(false);
      });
  };
  const getBinAllocationOrderList = () => {
    setListLoading(true);
    fetchListForBinAlloctionOrder({
      entityLevel: 'l1',
      entityId: branchId,
      binAllocationOrderStatusList: ['DRAFT', 'UNDER_REVIEW'],
    })
      .then((res) => {
        setBinOrderData(res.data);
        setListLoading(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to fetch bin list2'),
          'error'
        );
        setListLoading(false);
      });
  };

  // stock transfer

  const sendToCheckerForStockTransfer = (data: IStockTranseferListProps) => {
    const obj = {
      binAllocationTransferId: data.id,
      userId,
    };
    console.log('obj', obj);
    initialCheckerMakerFortransfer(obj)
      .then(async (res) => {
        setSnackbar('send Successfully!');
        await getStockTransferList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send'),
          'error'
        );
        setListLoading(false);
      });
  };

  const updateToCheckerForStockTransfer = (data: IStockTranseferListProps) => {
    const obj = {
      binAllocationTransferId: data.id,
      status: 'CHECKER_PENDING',
      userId,
    };
    console.log('obj', obj);
    updateStockTransferStatus(obj)
      .then(async (res) => {
        setSnackbar('send Successfully!');
        await getStockTransferList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send'),
          'error'
        );
        setListLoading(false);
      });
  };

  const updateStockTransfer = (data: any) => {
    updateStockTransferRequest(data)
      .then(async (res) => {
        setSnackbar('update Successfully!');
        await getStockTransferList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to update'),
          'error'
        );
        setListLoading(false);
      });
  };
  const addStockTransfer = (data: any) => {
    addStockTransferRequest(data)
      .then(async (res) => {
        console.log('addStockTransferRequest', res.data);
        setSnackbar('update Successfully!');
        await getStockTransferList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to update'),
          'error'
        );
        setListLoading(false);
      });
  };

  const getListofStockTransfer = async (obj: Object) => {
    try {
      const response = await fetchListForStockTransfer(obj);
      return response.data;
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getStockTransferList = async () => {
    setStockListLoading(true);
    const list1 = await getListofStockTransfer({
      clientId,
      destinationEntityLevel: 'l1',
      destinationDivisionId: branchId,
    });

    const list2 = await getListofStockTransfer({
      clientId,
      sourceEntityLevel: 'l1',
      sourceDivisionId: branchId,
    });
    const newArr = [...list1, ...list2];
    setStockTransferData(newArr);
  };

  useEffect(() => {
    if (activeState.id === 'STOCK_TRANSFER') {
      getStockTransferList();
    } else {
      getBinAllocationOrderList();
    }
  }, [activeState]);
  useEffect(() => {
    getBinAllocationEntityList();
  }, [page, size]);

  return (
    <VegaPage>
      <VegaSelector
        data={CARDS}
        setActiveState={selectedTab}
        active={activeState}
        header={toLowerCase(activeState.id)}
        subHeader="All Live programs till date."
      />

      <ListOfInventoryManagement
        binData={binData}
        loading={listLoading}
        active={activeState}
        onReoder={onReoder}
        addStockTransfer={addStockTransfer}
        page={page}
        size={size}
        rowCount={rowCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />

      {activeState.id === 'ORDER_INVENTORY' ? (
        <>
          <ListOfBinAllocationOrder
            data={binOrderData}
            loading={listLoading}
            active={activeState}
            onSendChecker={onSendChecker}
            onUpdateSendChecker={onUpdateSendChecker}
            handleUpdate={handleUpdate}
          />
        </>
      ) : (
        <ListOfStockTransfer
          data={stockTransferData}
          loading={stockListLoading}
          updateStockTransfer={updateStockTransfer}
          sendToCheckerForStockTransfer={sendToCheckerForStockTransfer}
          updateToCheckerForStockTransfer={updateToCheckerForStockTransfer}
        />
      )}
    </VegaPage>
  );
};

export default MCCInventoryManagement;
