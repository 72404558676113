/** @format */

import { AttachFile, Clear } from '@mui/icons-material';
import { Box, FormHelperText, IconButton, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import Dropzone from 'react-dropzone';
import { BACKGROUND, BLUE, PRIMARY } from '../../constants/style';
import { AcceptFileType } from '../../types/acceptFileType';
import { FileValidator, FileValidatorResult } from '../../utils/FileValidator';
import VegaHeader from './VegaHeader';

type Props = {
  file: File | null;
  onUpload: (file: File | null) => void;
  placeholder: string;
  headerText?: string;
  isFixedHeight?: boolean;
  acceptFileType?: AcceptFileType;
  errorHelperText?: string;
  fileValidator?: FileValidator;
  onError?: (errorMessage: string | null) => void;
};

const VegaFileUpload = ({
  onUpload,
  file,
  placeholder,
  acceptFileType,
  errorHelperText,
  isFixedHeight,
  headerText,
  fileValidator,
  onError,
}: Props) => {
  const hiddenFileInput: any = React.useRef(null);

  const handleFileSelect = async (selectedFile: File | null) => {
    if (!fileValidator) {
      onUpload(selectedFile);
      return;
    }

    if (selectedFile) {
      const validationResult: FileValidatorResult =
        await fileValidator.validateFile(selectedFile);

      if (validationResult.isValid) {
        onUpload(selectedFile);
      } else {
        onError?.(validationResult.errorMessage);
      }
    }
  };

  const handleClearClick = (e: any) => {
    e.stopPropagation();
    onUpload(null);
  };

  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles: any) => handleFileSelect(acceptedFiles[0])}
      >
        {({ getRootProps, getInputProps }) => (
          <Box>
            {headerText?.length && <VegaHeader text={headerText} />}
            <Box
              {...getRootProps()}
              sx={{
                ...(isFixedHeight ? { height: '35px' } : { height: '50px' }),

                borderRadius: '9px',
                display: 'flex',
                py: 2,
                px: 1.5,
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #AFAFAF',
                color: '#AFAFAF',
                width: '100%',
                ':hover': {
                  borderColor: BLUE.dark,
                  cursor: 'pointer',
                },
              }}
              // onClick={handleClick}
            >
              {!!file ? (
                <Box
                  sx={{
                    bgcolor: BACKGROUND.GERY,
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    borderRadius: '7px',
                  }}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    mx={1}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Typography
                      color={PRIMARY.light}
                      mr={0.5}
                      variant={'caption'}
                    >
                      {file?.name}
                    </Typography>
                    <Typography color={PRIMARY.main} variant={'caption'}>
                      ({file?.size && Math.round(file.size / 1024)}kb)
                    </Typography>
                  </Box>
                  <IconButton onClick={handleClearClick}>
                    <Clear color="primary" fontSize={'small'} />
                  </IconButton>
                </Box>
              ) : (
                <>
                  {placeholder || 'upload a file'}
                  <AttachFile color="primary" />
                </>
              )}
              <input
                {...getInputProps()}
                accept={acceptFileType ?? AcceptFileType.All}
              />
            </Box>
            <FormHelperText error id="upload-file-error">
              {errorHelperText}
            </FormHelperText>
          </Box>
        )}
      </Dropzone>
    </>
  );
};

export default VegaFileUpload;
