import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CurrentDelinquncyContainer from './components/CurrentDelinquncyContainer';
import DPDStatusContainer from './components/DPDStatusContainer';
import DelinquncyHistoryContainer from './components/DelinquncyHistoryContainer';
import ListOfCurrentDelinquncy from './components/ListOfCurrentDelinquncy';
import ListOfDPDStatus from './components/ListOfDPDStatus';
import ListOfDelinquncyHistory from './components/ListOfDelinquncyHistory';
import { useSupport } from '../../../../../providers/SupportProvider';
import {
  DelinquencyServices,
  IDelinquencyHistoryProps,
} from '../../../../../services/DelinquencyServices';

export interface IDelinquencyHistoryStateProps {
  data: IDelinquencyHistoryProps | null;
  loading: boolean;
}

const Delinquency = () => {
  const { accountData } = useSupport();
  const [delinquencyHistoryState, setDelinquencyHistoryState] =
    useState<IDelinquencyHistoryStateProps>({
      data: null,
      loading: false,
    });

  const getDelinquencyHistory = async (accountId: string) => {
    setDelinquencyHistoryState({ data: null, loading: true });
    try {
      const response =
        await DelinquencyServices.getDelinquencyHistoryByAccountId(accountId);
      setDelinquencyHistoryState({ data: response, loading: false });
    } catch (error) {
      setDelinquencyHistoryState({ data: null, loading: false });
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getDelinquencyHistory(accountData.accountId);
    }
  }, []);
  return (
    <>
      <CurrentDelinquncyContainer header="Current Delinquency ">
        <Box mt={2}>
          <ListOfCurrentDelinquncy />
        </Box>
      </CurrentDelinquncyContainer>
      <DPDStatusContainer header="DPD Stats">
        <Box mt={2}>
          <ListOfDPDStatus delinquencyHistoryState={delinquencyHistoryState} />
        </Box>
      </DPDStatusContainer>
      <DelinquncyHistoryContainer header="Delinquency History">
        <Box mt={2}>
          <ListOfDelinquncyHistory
            delinquencyHistoryState={delinquencyHistoryState}
          />
        </Box>
      </DelinquncyHistoryContainer>
    </>
  );
};

export default Delinquency;
