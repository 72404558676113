import React from 'react';
import { VegaCard } from '../../../../../components/common';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import {
  BLUE,
  BORDER_GRAY,
  GREEN,
  ORANGE,
  PURPLE,
  YELLOW,
} from '../../../../../constants/style';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { AttachMoney, Percent } from '@mui/icons-material';
import { getValue } from '../MarkupFee/ListOfMarkupFees';
import { IPaginationProps } from '../../../pages/MCCPolicies';

interface IProps extends IPaginationProps {
  data: any;
}

const ListOfTransactionLimit = ({
  data,
  page,
  count,
  onPageChange,
}: IProps) => {
  return (
    <VegaCard fullHeight noPad>
      <Grid container mt={6}>
        <Grid
          item
          sm={1.5}
          sx={{
            p: 2,
            borderRight: BORDER_GRAY,
            bgcolor: YELLOW.lightest,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Plan Name
        </Grid>
        <Grid
          item
          sm={1.5}
          sx={{
            // flex: 0.3,
            // minWidth: '130px',
            // width: '180px',
            p: 2,
            borderRight: BORDER_GRAY,
            bgcolor: ORANGE.lightest,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Mode
        </Grid>
        <Grid
          item
          sm={4}
          sx={{ p: 2, borderRight: BORDER_GRAY, bgcolor: GREEN.lightest }}
        >
          <Box sx={{ p: 1, textAlign: 'center' }}>Velocity</Box>
          <Grid container>
            <Grid item sm={4} p={1} textAlign={'center'}>
              Daily
            </Grid>
            <Grid item sm={4} p={1} textAlign={'center'}>
              Monthly
            </Grid>
            <Grid item sm={4} p={1} textAlign={'center'}>
              Yearly
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={5} sx={{ p: 2, bgcolor: PURPLE.lightest }}>
          <Box sx={{ p: 1, textAlign: 'center' }}> No of Transcations</Box>
          <Grid container>
            <Grid item sm={2.4} p={1} textAlign={'center'}>
              <Typography className="font-aspekta-500" variant="body2">
                Floor/Txn
              </Typography>
            </Grid>
            <Grid item sm={2.4} p={1} textAlign={'center'}>
              <Typography className="font-aspekta-500" variant="body2">
                Ceiling/Txn
              </Typography>
            </Grid>
            <Grid item sm={2.4} p={1} textAlign={'center'}>
              <Typography className="font-aspekta-500" variant="body2">
                {' '}
                Daily
              </Typography>
            </Grid>
            <Grid item sm={2.4} p={1} textAlign={'center'}>
              <Typography className="font-aspekta-500" variant="body2">
                {' '}
                Monthly
              </Typography>
            </Grid>
            <Grid item sm={2.4} p={1} textAlign={'center'}>
              <Typography className="font-aspekta-500" variant="body2">
                {' '}
                Yearly
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {data &&
          data.map((value: any, index: number) => (
            <>
              <Grid
                item
                sm={1.5}
                sx={{
                  py: 1,
                  borderRight: BORDER_GRAY,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  ...(index !== data.length - 1 && {
                    borderBottom: BORDER_GRAY,
                  }),
                }}
              >
                <Typography className="font-aspekta-500" variant="body2">
                  {value?.policy_name}
                </Typography>
              </Grid>
              <Grid item container sm={10.5}>
                {/* loop here */}
                {value?.transaction_limit_component_list &&
                  value?.transaction_limit_component_list.map(
                    (val: any, i: number) => (
                      <>
                        <Grid
                          item
                          sm={1.7}
                          textAlign={'center'}
                          sx={{
                            py: 1,
                            borderRight: BORDER_GRAY,
                            ...(i ===
                              value?.transaction_limit_component_list.length -
                                1 &&
                              index !== data.length - 1 && {
                                borderBottom: BORDER_GRAY,
                              }),
                          }}
                        >
                          <Typography
                            className="font-aspekta-500"
                            variant="body2"
                          >
                            {val?.transactionCode &&
                              toLowerCase(val?.transactionCode)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          sm={4.6}
                          sx={{
                            py: 1,
                            borderRight: BORDER_GRAY,
                            ...(i ===
                              value?.transaction_limit_component_list.length -
                                1 &&
                              index !== data.length - 1 && {
                                borderBottom: BORDER_GRAY,
                              }),
                          }}
                        >
                          <Grid
                            item
                            sm={4}
                            display={'flex'}
                            justifyContent={'center'}
                          >
                            {getValue(
                              false,
                              val?.velocityRuleList[0]?.transactionCountLimit ??
                                '0'
                            )}
                          </Grid>
                          <Grid
                            item
                            sm={4}
                            display={'flex'}
                            justifyContent={'center'}
                          >
                            {getValue(
                              false,
                              val?.velocityRuleList[1]?.transactionCountLimit ??
                                '0'
                            )}
                          </Grid>
                          <Grid
                            item
                            sm={4}
                            display={'flex'}
                            justifyContent={'center'}
                          >
                            {getValue(
                              false,
                              (val?.velocityRuleList &&
                                val?.velocityRuleList[2]
                                  ?.transactionCountLimit) ??
                                '0'
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          sm={5.7}
                          sx={{
                            py: 1,
                            ...(i ===
                              value?.transaction_limit_component_list.length -
                                1 &&
                              index !== data.length - 1 && {
                                borderBottom: BORDER_GRAY,
                              }),
                          }}
                        >
                          <Grid item sm={2.4} textAlign={'center'}>
                            {getValue(
                              true,
                              val?.transactionLimitRule?.minTransactionAmount ??
                                '0'
                            )}
                          </Grid>
                          <Grid item sm={2.4} textAlign={'center'}>
                            {getValue(
                              true,
                              val?.transactionLimitRule?.maxTransactionAmount ??
                                '0'
                            )}
                          </Grid>
                          <Grid item sm={2.4} textAlign={'center'}>
                            {getValue(
                              true,
                              val?.transactionLimitRule
                                ?.transactionAmountLimitRuleList[0]
                                ?.transactionAmountLimit ?? '0'
                            )}
                          </Grid>
                          <Grid item sm={2.4} textAlign={'center'}>
                            {getValue(
                              true,
                              val?.transactionLimitRule
                                ?.transactionAmountLimitRuleList[1]
                                ?.transactionAmountLimit ?? '0'
                            )}
                          </Grid>
                          <Grid item sm={2.4} textAlign={'center'}>
                            {getValue(
                              true,
                              val?.transactionLimitRule
                                ?.transactionAmountLimitRuleList[2]
                                ?.transactionAmountLimit ?? '0'
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )
                  )}
                {/* loop here */}
              </Grid>
            </>
          ))}
      </Grid>
      <Grid container>
        <Box
          sx={{ p: 2, width: '100%', display: 'flex', justifyContent: 'end' }}
        >
          <Pagination
            color="primary"
            page={page}
            count={count}
            onChange={onPageChange}
          />
        </Box>
      </Grid>
    </VegaCard>
  );
};

export default ListOfTransactionLimit;
