import { Divider, Grid, InputAdornment } from '@mui/material';
import _ from 'lodash';
import {
  InclusionExclusionConditionType,
  TransactionCategoryRewards,
} from '../../../../../../../../types/RewardsLoyalty';
import VegaCardV2 from '../../../../../../../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../../../../../common/v2/VegaFormInputField';
import { VegaMultiSelectOption } from '../../../../../../../common/v2/VegaMultiSelect';
import { VegaSelectOptionV2 } from '../../../../../../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../../../../../../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../../../../../../common/v2/VegaTextV2';
import { LessOrEqual as GreaterThenOrEqualTo } from '../../../../../../../icon/Icons';
import {
  InclusionExclusionInputType,
  useCampaignRule,
} from '../../../../../../providers/CampaignRuleProvider';
import { InclusionExclusionInput } from './InclusionExclusionInput';
import MerchantAndCategoryInclusionExclusionInput from './MerchantAndCategoryInclusionExclusionInput';

const InclusionExclusionTabPanel = () => {
  const {
    inclusionExclusionData,
    onInclusionExclusionConditionTypeChange,
    onInclusionExclusionSelectValuesChange,
    onInclusionExclusionTransactionAmountChange,
  } = useCampaignRule();

  function onTransactionSelectValuesChange(values: VegaMultiSelectOption[]) {
    onInclusionExclusionSelectValuesChange({
      type: InclusionExclusionInputType.TRANSACTION_TYPE,
      values: values.map((item) => item.value),
    });
  }

  const getSelectedTransactionCondition = () => {
    if (inclusionExclusionData.isTransactionInclusive == null) return '';
    return inclusionExclusionData.isTransactionInclusive
      ? InclusionExclusionConditionType.INCLUSION
      : InclusionExclusionConditionType.EXCLUSION;
  };

  function getSelectedTransactionTypes() {
    const selectcategories = inclusionExclusionData.transactionTypes ?? [];
    const categories = transactionCodeOptions().filter((item) =>
      selectcategories.includes(item.value)
    );
    return categories;
  }

  return (
    <VegaCardV2>
      <VegaTextV2 text="Transaction Details" variant="body1" />
      <Grid container columns={6}>
        <Grid item xs={1}>
          <VegaFormInputFieldV2 label="Transaction Amount">
            <VegaTextFieldV2
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GreaterThenOrEqualTo />
                  </InputAdornment>
                ),
              }}
              value={
                inclusionExclusionData.transactionAmount != null
                  ? inclusionExclusionData.transactionAmount
                  : ''
              }
              onChange={(e) => {
                const value = e.target.value;
                const numberValue = Number(value);
                onInclusionExclusionTransactionAmountChange(numberValue);
              }}
              type="number"
            />
          </VegaFormInputFieldV2>
        </Grid>
      </Grid>

      <InclusionExclusionInput
        conditionOptions={inclusionExclusionOptions()}
        valueOptions={transactionCodeOptions()}
        valueTitle={'Transaction Type'}
        onConditionChange={function (value: string): void {
          onInclusionExclusionConditionTypeChange({
            type: InclusionExclusionInputType.TRANSACTION_TYPE,
            condition: value,
          });
        }}
        onSelectValueChange={onTransactionSelectValuesChange}
        selectedCondition={getSelectedTransactionCondition()}
        selectedOptions={getSelectedTransactionTypes()}
      />
      <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />
      <MerchantAndCategoryInclusionExclusionInput />
    </VegaCardV2>
  );
};

export default InclusionExclusionTabPanel;

const inclusionExclusionOptions = () =>
  Object.values(InclusionExclusionConditionType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const transactionCodeOptions = () =>
  Object.values(TransactionCategoryRewards).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
