/* eslint-disable react/jsx-pascal-case */
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import VegaTab from '../../components/common/VegaTab';
import CIMS_Header from '../components/common/CIMS_Header';
import { ICardMasterEnum } from '../enums/admin';
import ListOfCardIndentation from '../modules/Card_Master/ListOfCardIndentation';
import ListOfCardType from '../modules/Card_Master/ListOfCardType';

export const Card_Master = () => {
  const [selectedTab, setSelectedTab] = useState<string>(ICardMasterEnum.Type);
  return (
    <Box>
      <CIMS_Header text="Card Master" />
      <Box
        sx={{
          p: 2,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <VegaTab
              placement="vertical"
              color={'#1047DC'}
              tabData={[ICardMasterEnum.Type, ICardMasterEnum.CardStatus]}
              selected={selectedTab}
              onSelect={(select) => setSelectedTab(select)}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          {selectedTab === ICardMasterEnum.Type ? (
            <ListOfCardType />
          ) : (
            <ListOfCardIndentation />
          )}
        </Box>
      </Box>
    </Box>
  );
};
