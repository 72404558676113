export const CORPORATE = {
  BASE: '/customer/corporate',
  ACCOUNT: '/account/corporate',
  LIST: '/filter',
};

export const SEARCH = {
  ACCOUNT: '/account/consolidated/filter',
};

export const CORPORATE_CONSOLIDATED = {
  ACCOUNT: '/consolidated'
}
