import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { VegaCard, VegaTable } from '../../../../../components/common';
import VegaContainedButton from '../../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../../components/common/VegaDrawer';
import VegaLabel from '../../../../../components/common/VegaLabel';
import { VegaToolTipAndCopy } from '../../../../../components/common/VegaToolTipNCopy';
import {
  CheckerMakerStatusEnum,
  EntityStatusEnum,
} from '../../../../../enums/commons';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import ManualAdjustmentService from '../../../../../services/ManualAdjustmentService';
import { getCurrenciesForWallet } from '../../../../../utils/wallet.utils';
import {
  IManualAdjustment,
  IManualAdjustmentRequest,
} from '../../../../types/ManualAdjustment';
import ManualAdjustmentDrawer from './ManualAdjustmentDrawer';

interface IProps {
  data: IManualAdjustment[];
  refetchData: () => void;
}

function ManualAdjustmentMakerList({ data, refetchData }: IProps) {
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [isDrawerDisabled, setIsDrawerDisabled] = useState<boolean>(false);
  const [showData, setShowData] = useState<Partial<IManualAdjustment>>(
    {} as IManualAdjustment
  );
  const [currencies, setCurrencies] = useState<Array<string>>([]);
  const hookForm = useForm<IManualAdjustmentRequest>({
    defaultValues: {},
  });
  const showDetails = (data: IManualAdjustment, isShow: boolean) => {
    const { accountConsolidatedDto, ...rest } = data;

    const currencies = getCurrenciesForWallet(
      data.accountConsolidatedDto.subWallets
    );
    setCurrencies(currencies);
    setShowData({
      ...rest,
    } as Partial<IManualAdjustment>);
    setOpen(!open);
    hookForm.setValue('accountId', data.accountId || '');
    hookForm.setValue('adjustmentType', data.adjustmentType || '');
    hookForm.setValue('adjustmentReason', data.adjustmentReason || '');
    hookForm.setValue('amount', data.amount || 0);
    hookForm.setValue('currency', data.currency || '');
    setOpen(!open);
    setIsDrawerDisabled(isShow);
  };

  const initiateCheckerMaker = (data: IManualAdjustment) => {
    ManualAdjustmentService.initiateMakerChecker({
      entityId: data.id,
      userId: userId || '',
    })
      .then((res) => {
        refetchData();
        setSnackbar('Send to checker');
      })
      .catch((err) => {
        setSnackbar('Failed to send to checker  ', 'error');
      });
  };
  const updateCheckerMaker = (
    data: IManualAdjustment,
    status: CheckerMakerStatusEnum = CheckerMakerStatusEnum.CHECKER_PENDING
  ) => {
    ManualAdjustmentService.updateMakerChecker({
      entityId: data.id,
      userId: userId || '',
      status: status,
    })
      .then((res) => {
        refetchData();
        setSnackbar('Updated status ' + status.toLocaleLowerCase());
      })
      .catch((err) => {
        setSnackbar(
          'Failed to update status ' + status.toLocaleLowerCase(),
          'error'
        );
      });
  };

  const getViewEditButtons = (data: IManualAdjustment) => {
    const { status, makerCheckerStatus } = data;

    if (
      status === EntityStatusEnum.ACTIVE ||
      makerCheckerStatus === 'APPROVED' ||
      status === EntityStatusEnum.UNDER_REVIEW ||
      makerCheckerStatus === 'CHECKER_PENDING'
    ) {
      return (
        <VegaContainedButton
          text="View"
          onClick={() => showDetails(data, true)}
          isPrimary
        />
      );
    }

    if (status === 'DRAFT') {
      return (
        <VegaContainedButton
          text="Edit"
          isPrimary
          onClick={() => showDetails(data, false)}
        />
      );
    }
  };

  const getButtons = (data: IManualAdjustment) => {
    const { status, makerCheckerStatus } = data;

    if (status === EntityStatusEnum.DRAFT && makerCheckerStatus === null) {
      return (
        <VegaContainedButton
          text="Send to Checker"
          onClick={() => initiateCheckerMaker(data)}
          isPrimary
        />
      );
    }
    if (
      status === EntityStatusEnum.UNDER_REVIEW &&
      makerCheckerStatus === CheckerMakerStatusEnum.REVIEWER_PENDING
    ) {
      return (
        <VegaContainedButton
          text="Send to Checker"
          onClick={() => updateCheckerMaker(data)}
          isPrimary
        />
      );
    }
    if (status === 'UNDER_REVIEW' && makerCheckerStatus === 'CHECKER_PENDING') {
      return <VegaContainedButton text="Sent to checker" isPrimary disabled />;
    }
    if (
      status === 'UNDER_REVIEW' &&
      makerCheckerStatus === CheckerMakerStatusEnum.CHANGES_REQUESTED
    ) {
      return (
        <VegaContainedButton
          text="Update Fund"
          isPrimary
          onClick={() => showDetails(data, false)}
        />
      );
    }
  };

  const onClose = () => {
    setOpen(!open);
  };
  const onSubmit = (data: IManualAdjustmentRequest) => {
    if (!data?.amount || data?.amount <= 0) {
      setSnackbar('Amount must be greater than 0', 'error');
      return;
    }

    ManualAdjustmentService.update({
      id: showData.id,
      branchId: showData.branchId,
      amount: data.amount,
      currency: data.currency,
      status: showData.status,
      adjustmentType: data.adjustmentType,
      adjustmentReason: data.adjustmentReason,
      userId: userId || '',
    })
      .then((res) => {
        refetchData();
        setSnackbar('Updated successfully ');
        onClose();
      })
      .catch((err) => {
        setSnackbar('Error in updating ', 'error');
      });
  };
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={getColumns(getButtons, getViewEditButtons)}
        idColumn={'id'}
      />
      <VegaDrawer
        open={open}
        isClose
        isSubmit
        header={'Manual Adjustment'}
        closeText={'Cancel'}
        submitText={'Submit'}
        onClose={onClose}
        onSubmit={hookForm.handleSubmit(onSubmit)}
      >
        <ManualAdjustmentDrawer
          isDisabled={isDrawerDisabled}
          currencies={currencies}
          hookForm={hookForm}
        />
      </VegaDrawer>
    </VegaCard>
  );
}

export default ManualAdjustmentMakerList;

const getColumns = (
  getButtons: (data: IManualAdjustment) => JSX.Element | undefined,
  getViewEditButtons: (data: IManualAdjustment) => JSX.Element | undefined
): GridColDef<IManualAdjustment>[] => [
  {
    field: 'accountId',
    renderHeader: () => <Typography variant="body2">Acccount Id</Typography>,
    flex: 1,
    renderCell: (props) => {
      return (
        <VegaToolTipAndCopy value={props.row.accountId} title="AccountId" />
      );
    },
  },
  {
    field: 'adjustmentType',
    renderHeader: () => (
      <Typography variant="body2">Adjustment Type</Typography>
    ),
    flex: 1,
    renderCell: (props) => {
      return (
        <VegaLabel
          text={props.row.adjustmentType}
          color="primary"
          px={1}
          py={0.5}
        />
      );
    },
  },
  {
    field: 'adjustmentReason',
    renderHeader: () => (
      <Typography variant="body2">Adjustment Reason</Typography>
    ),
    flex: 1,
  },
  {
    field: 'amount',
    renderHeader: () => <Typography variant="body2">Amount</Typography>,
    flex: 1,
  },
  {
    field: 'currency',
    renderHeader: () => <Typography variant="body2">Currency</Typography>,
    flex: 1,
  },

  {
    field: 'status',
    renderHeader: () => <Typography variant="body2"> Status</Typography>,
    flex: 0.7,
    renderCell: (props) => {
      return props.row.status === EntityStatusEnum.ACTIVE ? (
        <VegaContainedButton text="Adjusted" isSuccess disabled />
      ) : props.row.status === EntityStatusEnum.REJECTED ? (
        <VegaContainedButton text="Rejected" isDanger px={2.5} disabled />
      ) : (
        <VegaContainedButton text="Pending" isWarning px={2.7} disabled />
      );
    },
  },

  {
    field: 'request',
    renderHeader: () => <Typography variant="body2"> Action</Typography>,
    flex: 0.7,
    renderCell: (props) => {
      return getButtons(props.row);
    },
  },
  {
    field: 'view',
    renderHeader: () => <Typography variant="body2"> View/Edit</Typography>,
    flex: 0.7,
    renderCell: (props) => {
      return getViewEditButtons(props.row);
    },
  },
];
