import { IDataProps } from '../../pages/ApplicationManagment';
import { VegaCard } from '../common';
import VegaHorizontalSelector from '../common/VegaHorizontalSelector';
import Vega_HeaderWrapper from '../common/v3/Vega_HeaderWrapper';

const ApplicationManagTabs = ({ data, setActiveState, active }: IDataProps) => {
  return (
    <VegaCard>
      <Vega_HeaderWrapper
        text="Application management (LOS)"
        subText="Easily configure KYC journey and agreements"
      />

      <VegaHorizontalSelector
        hideScroll
        data={data}
        selected={active}
        onChange={setActiveState}
      />
    </VegaCard>
  );
};

export default ApplicationManagTabs;
