// create a new context

import { DataGridProps, GridCallbackDetails } from '@mui/x-data-grid';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { CardIndentationOrderDto } from '../../../../types/card';
import { Level } from '../../../../types/client';
import { PaginatedResponse } from '../../../../utils/api';
import { CardAllocationService } from '../../../apis';

export interface TablePagination {
  page: number;
  pageSize: number;
  onPageChange: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
}

export interface CardIndentationOrderContextProps {
  pagination: Partial<DataGridProps>;
  listIndentationOrderQuery: UseQueryResult<
    PaginatedResponse<CardIndentationOrderDto>,
    unknown
  > | null;
}

const defaultCardIndentationOrderContext: CardIndentationOrderContextProps = {
  pagination: {
    page: 0,
    pageSize: 10,
    onPageChange: (page, details) => {},
    onPageSizeChange: () => {},
  },
  listIndentationOrderQuery: null,
};

const CardIndentationOrderContext =
  createContext<CardIndentationOrderContextProps>(
    defaultCardIndentationOrderContext
  );

const useCardIndentationOrder = () =>
  useContext(CardIndentationOrderContext) as CardIndentationOrderContextProps;

interface IProps {
  children: ReactNode;
}
const CardIndentationOrderProvider = ({ children }: IProps) => {
  const { userDivisionType, userDivisionId, clientId } = useClientAuth();

  const canRequestCards = userDivisionType === 'CLIENT';
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const [filterData, setFilterData] = useState([]);

  const entityRequest = useMemo(() => {
    return userDivisionType === 'CLIENT'
      ? {
          sourceDivisionId: userDivisionId,
        }
      : userDivisionType === 'VENDOR'
      ? {
          sourceDivisionId: clientId,
        }
      : {
          destinationDivisionId: userDivisionId,
        };
  }, [clientId, userDivisionId, userDivisionType]);

  const listIndentationOrderQuery = useQuery({
    queryKey: [
      'list_indentation_order',
      {
        ...entityRequest,
        page,
        pageSize,
      },
    ],
    queryFn: () => {
      return CardAllocationService.listCardIndentationOrder({
        ...entityRequest,
        page: page,
        size: pageSize,
        populateDivision: true,
        populateProgram: true,
      });
    },
    keepPreviousData: true,
  });
  return (
    <CardIndentationOrderContext.Provider
      value={{
        pagination: {
          pageSize: pageSize,
          onPageSizeChange: setPageSize,
          page: page,
          onPageChange: setPage,
        },
        listIndentationOrderQuery,
      }}
    >
      {children}
    </CardIndentationOrderContext.Provider>
  );
};

export {
  CardIndentationOrderContext as CardIndentationOrderContextProps,
  CardIndentationOrderProvider,
  useCardIndentationOrder,
};
