import { Add, AddCircleOutline } from '@mui/icons-material';
import { Box, Stack, Switch, Typography } from '@mui/material';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { VegaPage, VegaTable } from '../../../../../components/common';
import VegaModal from '../../../../../components/common/VegaModal';
import VegaOutlineButton from '../../../../../components/common/VegaOutlineButton';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { IPartner } from '../../../../../types/partner';
import { IAddUserRequest } from '../../../api/roles';
import AddPartner from '../AddPartner';
import CreateUser from '../CreateUser';
import { useListUsers } from '../hooks/roleManagement/queries';
import { addUserAndRoleForPartner } from '../utils/addUserForPartner';

type IProps = {
  partner: IPartner | null;
};

function ListOfTeamMembers({ partner }: IProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { clientId, user } = useClientAuth();

  const usersListQuery = useListUsers({
    clientId: clientId || '',
  });

  const partnerUsers = useMemo(() => {
    return (
      (usersListQuery.data as IAddUserRequest[])?.filter(
        (user) => user.platformId === partner?.id
      ) || []
    );
  }, [usersListQuery.data, partner]);

  //   return <Box>Team members</Box>;

  const onSubmit = (data: { user: IAddUserRequest }) => {
    setIsLoading(true);
    partner &&
      addUserAndRoleForPartner({
        partner: partner,
        user: data.user,
        currentUser: user,
      })
        .then((res) => {
          console.log('🚀 ~ file: ListOfTeamMembers.tsx:64 ~ res:', res);
        })
        .catch((err) => {
          console.log('🚀 ~ file: ListOfTeamMembers.tsx:66 ~ err:', err);
        })
        .finally(() => {
          setIsLoading(false);
          setIsOpen(false);
          usersListQuery.refetch();
        });
  };

  return (
    <Stack>
      <VegaPage>
        <VegaTable
          // noTopRadius
          data={partnerUsers}
          columnDef={getColumnDef()}
          idColumn="userId"
          loading={usersListQuery.isLoading}
          disableSelectionOnClick={true}
          components={{
            Toolbar: (props) => (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                my={1}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <Typography>Search By</Typography>
                  <Box>
                    <GridToolbarQuickFilter {...props} />
                  </Box>
                </Stack>
                <VegaOutlineButton
                  text={'Add Team Member'}
                  isPrimary
                  startIcon={<Add />}
                  onClick={() => setIsOpen(true)}
                />
              </Stack>
            ),
          }}
        />
      </VegaPage>

      <VegaModal
        header={'Add Team Member'}
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        isButton={false}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
        zIndex={2500}
      >
        <AddPartner
          components={[<CreateUser />]}
          onSubmit={onSubmit}
          onCancel={() => {
            setIsOpen(false);
          }}
          steps={['Add Team Member']}
          isLoading={isLoading}
        />
      </VegaModal>
    </Stack>
  );
}

export default ListOfTeamMembers;

const getColumnDef = (): GridColDef<IAddUserRequest>[] => [
  //      {
  //     field: 'srNo',
  //     renderHeader: () => (
  //       <Typography variant="body2" className="font-aspekta-500">
  //         S. No.
  //       </Typography>
  //     ),
  //     flex: 1,
  //     renderCell: (props) => {
  //       console.log('props in row', props);
  //       return <Typography>{props.id}</Typography>;
  //     },
  //   },

  {
    field: 'name',
    renderHeader: () => (
      <Typography variant="body2" className="font-aspekta-500">
        Member Name
      </Typography>
    ),
    flex: 1,

    renderCell: (props) => {
      console.log('props in row', props);

      return <Typography>{props.row.name}</Typography>;
    },
  },

  {
    field: 'email',
    renderHeader: () => (
      <Typography variant="body2" className="font-aspekta-500">
        Email Id
      </Typography>
    ),
    flex: 1,

    renderCell: (props) => {
      return <Typography>{props.row.email}</Typography>;
    },
  },
  {
    field: 'mobileNumber',
    renderHeader: () => (
      <Typography variant="body2" className="font-aspekta-500">
        Mobile No.
      </Typography>
    ),
    flex: 1,
    renderCell: (props) => {
      return <Typography>{props.row.mobileNumber}</Typography>;
    },
  },
  {
    field: 'access',
    renderHeader: () => (
      <Typography variant="body2" className="font-aspekta-500">
        Access
      </Typography>
    ),
    flex: 1,
    renderCell: (props) => {
      return (
        <Switch
          defaultChecked
          // fetch role for the user Id
          //checked={props.row}
        />
      );
    },
  },
];
