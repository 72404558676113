import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import Typography from '@mui/material/Typography';
import ProgramStatus from './ProgramStatus';
import { VegaProgramWithMetaDataType } from '../../types/program';
import { AnyPolicyType, PolicyStatus } from '../../types/policy';
import dayjs from 'dayjs';
import { VPButton } from '../../ui-components/common';
import { VPPRIMARY } from '../../ui-components/theme';
import { getLogoForNetwork } from '../icon/Icons';
import { VegaUser } from '../../types/user';

type ProgramManagementDashboardColumnTypes =
  GridColDef<VegaProgramWithMetaDataType>[];

export const getProgramManagementDashboardColumns =
  (): ProgramManagementDashboardColumnTypes => {
    return [
      {
        field: 'name',
        headerName: 'Program Name',
        flex: 1,
        sortable: false,
        minWidth: 180,
      },

      {
        field: 'programId',
        headerName: 'Program ID',
        flex: 1.8,
        minWidth: 180,
        sortable: false,
      },
      {
        field: 'type',
        headerName: 'Program Type',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: (props) => props.row.type,
        renderCell: ({ value }) => {
          return (
            <Typography sx={{ fontSize: 14 }}>
              {value ? startCase(toLower(value)) : '-'}
            </Typography>
          );
        },
      },
      {
        field: 'coBrandPartnerName',
        headerName: 'Co-branding Partner',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: (props) => props.row.coBrandPartnerName,
        renderCell: ({ value }) => {
          return (
            <Typography sx={{ fontSize: 14 }}>
              {value ? startCase(toLower(value)) : '-'}
            </Typography>
          );
        },
      },
      {
        field: 'network',
        headerName: 'Network',
        sortable: false,
        flex: 1,
        valueGetter: (props) => props.row.network,
        renderCell: ({ value }) => {
          return value ? <Box>{getLogoForNetwork(value)}</Box> : '-';
        },
      },
      {
        field: 'binDetails',
        headerName: 'Bin Details',
        sortable: false,
        flex: 1,
        minWidth: 100,
        valueGetter: (props) => props.row.bin?.binValue,
        renderCell: ({ value }) => {
          return <Typography sx={{ fontSize: 14 }}>{value ?? '-'}</Typography>;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        flex: 1,
        minWidth: 100,
        valueGetter: (props) => props.row.status,
        renderCell: ({ value }) => {
          return value ? <ProgramStatus status={value} /> : '-';
        },
      },
    ];
  };

export const getProgramPoliciesColumns = ({
  viewPolicy,
  checker,
}: {
  viewPolicy: (policy: AnyPolicyType) => void;
  checker?: VegaUser;
}): GridColDef<AnyPolicyType>[] => {
  const isApproved = (status: PolicyStatus) =>
    [PolicyStatus.APPROVED, PolicyStatus.ACTIVE].includes(status) && checker;
  return [
    {
      field: 'policy_id',
      headerName: 'Policy ID',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
    {
      field: 'policy_name',
      headerName: 'Policy Name',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 1,
      sortable: false,
      minWidth: 120,
      valueGetter: (props) => props.row.updated_at,
      renderCell: ({ value }) => {
        return (
          <Typography
            sx={{ fontSize: 12, color: '#1B1D22' }}
            className="font-aspekta-500"
          >
            {value ? dayjs(value).format('DD/MM/YYYY') : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'approvedOn',
      headerName: 'Approved on',
      flex: 1,
      sortable: false,
      minWidth: 120,
      valueGetter: (props) => props.row.updated_at,
      renderCell: ({ value, row }) => {
        return (
          <Typography
            sx={{ fontSize: 12, color: '#1B1D22' }}
            className="font-aspekta-500"
          >
            {isApproved(row.status) && value
              ? dayjs(value).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'approvedBy',
      headerName: 'Approved By',
      flex: 1,
      sortable: false,
      minWidth: 180,
      renderCell: ({ value, row }) => {
        return (
          <Typography
            sx={{ fontSize: 12, color: '#1B1D22' }}
            className="font-aspekta-500"
          >
            {isApproved(row.status) && checker ? checker.email : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      minWidth: 120,
      renderCell: ({ value, row }) => {
        return row?.policy_type !== 'TCS' ? (
          <VPButton
            sx={{
              color: VPPRIMARY.BLUE,
            }}
            variant="text"
            onClick={() => viewPolicy(row)}
          >
            View Details
          </VPButton>
        ) : null;
      },
    },
  ];
};
