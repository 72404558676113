import { Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import VegaCardV2 from '../../../../../../../../../../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../../../../../../../../common/v2/VegaFormInputField';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../../../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../../../../../../../../../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../../../../../../../../../common/v2/VegaTextV2';

type Props = {
  index: number;
  price?: number;
  delayDays?: number;
  selectedVoucher?: string;
  vouchers: VegaSelectOptionV2[];
  onGiftVoucherSelect: (voucherId: string) => void;
  onDelayChange: (delay?: number) => void;
};

export const GiftVoucherListItem = ({
  index,
  onDelayChange,
  onGiftVoucherSelect,
  vouchers,
  price,
  selectedVoucher,
  delayDays,
}: Props) => {
  const [delay, setDelay] = useState<number>();

  function _onVoucherSelect(value: string) {
    onGiftVoucherSelect(value);
  }

  function _onDelayChange(delay?: number) {
    setDelay(delay);
    onDelayChange(delay);
  }

  useEffect(() => {
    setDelay(delayDays);
  }, [delayDays]);

  return (
    <VegaCardV2>
      <Stack spacing={2}>
        <VegaTextV2 text={`Voucher ${index + 1}`} />
        <VegaFormInputFieldV2 label="Company">
          <VegaSelectV2
            options={vouchers}
            value={selectedVoucher ?? ''}
            onChange={(e) => {
              _onVoucherSelect(e.target.value as string);
            }}
          />
        </VegaFormInputFieldV2>
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent={'stretch'}
          spacing={1}
        >
          <VegaFormInputFieldV2 label="Price">
            <VegaTextFieldV2 disabled value={price != null ? price : ''} />
          </VegaFormInputFieldV2>
          <VegaFormInputFieldV2 label="Delay">
            <VegaTextFieldV2
              value={!!delay ? delay : ''}
              placeholder="In Days, Max 60"
              onChange={(e) => {
                const value = e.target.value;
                const numberValue = Number(value);

                if (numberValue && numberValue > 60) return;
                _onDelayChange(numberValue);
              }}
            />
          </VegaFormInputFieldV2>
        </Stack>
      </Stack>
    </VegaCardV2>
  );
};
