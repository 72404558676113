import { CHECKER_MAKER_STATUS, ENTITY_STATUS } from './Commons';

export enum ICustomerType {
  RETAIL_CUSTOMER = 'RETAIL_CUSTOMER',
  CORPORATE = 'CORPORATE',
}

export interface IRetailCustomer {
  id: string;
  countryCode: string;
  emailId: string;
  clientId: string;
  branchId: string;
  status: ENTITY_STATUS;
  customerType: ICustomerType;
  checkerMakerId: string | null;
  checkerMakerStatus: CHECKER_MAKER_STATUS | null;
  createdAt: string | null;
  updatedAt: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  dob: string | null;
  mobileNumber: string;
  address: string | null;
  passportNo: string;
  panUrl: string | null;
  passportUrl: string | null;
  kycUrl: string | null;
  ticketUrl: string | null;
  visaUrl: string | null;
}
