import { Box } from '@mui/material';
import { NEW_PRIMARY } from '../../../constants/style';
import Vega_Text from '../../common/v3/Vega_Text';
import ListOfUserAuditLog from './ListOfUserAuditLog';

const SupportUserAuditDetails = () => {
  return (
    <>
      <Box
        sx={{
          border: `1px solid #E1E4EB`,
          borderRadius: '12px',
          bgcolor: 'white',
          py: 2,
          px: 2.5,
          mt: 1,
          overflow: 'scroll',
          height: '100%',
        }}
      >
        <Vega_Text
          text={'User Audit'}
          fontWeight={600}
          color={NEW_PRIMARY.BLACK}
          fontSize={17}
        />
        <Box mt={2}>
          <ListOfUserAuditLog />
        </Box>
      </Box>
    </>
  );
};

export default SupportUserAuditDetails;
