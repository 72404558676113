import { Grid } from '@mui/material';
import { BLUE, PURPLE } from '../../../constants/style';
import { RewardsRoute } from '../../../routes';
import { ICampaignSelectorProps } from '../../../types/RewardsLoyalty';
import { PointBaseIcon, NonPointBaseIcon } from '../../icon/Icons';
import CampaignSelector from '../Campaigns/CampaignSelector';

const OFFERS = [
  {
    title: 'Point Based Campaign',
    text: 'Cardholders earn points for every dollar they spend using their credit card.',
    IconImage: <PointBaseIcon />,
    bgColor: BLUE.brightBlue,
    path: RewardsRoute.POINT_BASED,
  },
  {
    title: 'Non-Point Based Campaign',
    text: 'Cardholders earn rewards based on specific actions or behaviors, such as signing up for a card',
    IconImage: <NonPointBaseIcon />,
    bgColor: PURPLE.violet,
    path: RewardsRoute.NON_POINT_BASED,
  },
] as ICampaignSelectorProps[];

type Props = {
  onPointBasedClick: () => void;
  onNonPointBasedClick: () => void;
};
function RewardOfferings({ onPointBasedClick, onNonPointBasedClick }: Props) {
  return (
    <Grid px={3} container spacing={2}>
      {OFFERS.map((offer: ICampaignSelectorProps) => (
        <Grid item xs={12} sm={6} lg={5} xl={4} sx={{ cursor: 'pointer' }}>
          <CampaignSelector
            title={offer.title}
            text={offer.text}
            IconImage={offer.IconImage}
            bgColor={offer.bgColor}
            onClick={() => {
              if (offer.title == 'Point Based Campaign') {
                onPointBasedClick();
              } else if (offer.title == 'Non-Point Based Campaign') {
                onNonPointBasedClick();
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default RewardOfferings;
