import { Box, Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getPresignedUrl } from '../../../../api/card';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { CardAllotmentOrderDto } from '../../../../types/card';
import { Level } from '../../../../types/client';
import { getAddressfromAdressDto } from '../../../../utils/common.utils';
import { downloadFileFromURI } from '../../../../utils/file';
import { BinService } from '../../../apis';
import CIMSModal from '../../../components/common/CIMS_Modal';
import CimsButton from '../../../components/common/CimsButton';
import LableValueDisplay from '../../../components/common/LableValueDisplay';

interface IProps {
  data?: CardAllotmentOrderDto;
}

const ViewOrderDetails = ({ data }: IProps) => {
  const { levelEntityMapping } = useClientAuth();

  const binQuery = useQuery({
    queryKey: ['bin', data?.binId],
    queryFn: () => {
      return BinService.listByIds({
        ids: [data?.binId || 0],
        page: 0,
        size: 10,
      });
    },
    enabled: !!data?.binId,
    select: (data) => {
      return data.records[0];
    },
  });

  const [open, setOpen] = useState(false);

  const showDeliveryDetails = () => {
    // show only for vendors (for now hard coded vendor to l4 )
    // return apiScope?.currrentLevel === Level.l3;
    return true;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <CimsButton onClick={handleClickOpen} variant="text">
        View Order Details
      </CimsButton>
      <CIMSModal
        maxWidth="sm"
        open={open}
        handleClose={handleClose}
        header="View Order  Details"
      >
        <Stack sx={{ px: 3, pt: 2, pb: 6 }} spacing={4}>
          <Stack spacing={2}>
            <VegaTextV2
              text="Order Details"
              bold
              color={'rgba(34, 34, 34, 1)'}
            />
            <Stack spacing={1}>
              <Stack direction={'row'} spacing={2}>
                <LableValueDisplay
                  label="Creation Date"
                  value={dayjs(data?.createdAt).format('DD/MM/YYYY')}
                />
                <Divider orientation="vertical" variant="middle" flexItem />
                <LableValueDisplay label="Order ID" value={data?.id || ''} />
              </Stack>
              <LableValueDisplay
                label="Allocated by"
                value={data?.sourceDivisionDto?.divisionType ?? '-'}
              />
            </Stack>
          </Stack>

          {showDeliveryDetails() && (
            <Stack spacing={1}>
              <Stack spacing={2}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <VegaTextV2
                    text="Delivery Details"
                    bold
                    color={'rgba(34, 34, 34, 1)'}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    divider={
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          mx: 2,
                        }}
                      />
                    }
                    // spacing={2}
                  >
                    {/* <LableValueDisplay
                      label="Entity Type"
                      value={
                        levelEntityMapping[
                          data?.destinationEntityLevel as Level
                        ] || 'CLIENT'
                      }
                    /> */}
                    <LableValueDisplay
                      label="Entity Code"
                      value={data?.destinationDivisionDto?.divisionCode || '-'}
                    />
                    <LableValueDisplay
                      label="Entity Name"
                      value={data?.destinationDivisionDto?.name || '-'}
                      sx={{
                        margin: '0 !important',
                      }}
                    />
                  </Stack>
                  <LableValueDisplay
                    label="Address"
                    value={
                      getAddressfromAdressDto(
                        data?.destinationDivisionDto?.address || null
                      ) || '-'
                    }
                  />
                  <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                  >
                    <LableValueDisplay
                      label="State"
                      value={
                        data?.destinationDivisionDto?.address?.state || '-'
                      }
                    />
                    <LableValueDisplay
                      label="Country"
                      value={
                        data?.destinationDivisionDto?.address?.country || '-'
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
              <LableValueDisplay
                label="Mobile No"
                value={data?.destinationDivisionDto?.mobileNo || '-'}
              />
            </Stack>
          )}

          {data?.dispatchedAt && (
            <Stack spacing={1}>
              <Stack spacing={2}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <VegaTextV2
                    text="Dispatch Details"
                    bold
                    color={'rgba(34, 34, 34, 1)'}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    divider={
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          mx: 2,
                        }}
                      />
                    }
                  >
                    <LableValueDisplay
                      label="Mailer Vendor Id"
                      value={data.mailerVendorId || '--'}
                    />
                    <LableValueDisplay
                      label="Mailer Tracking Id"
                      value={data?.mailerVendorTrackingId || '-'}
                    />
                    <LableValueDisplay
                      label="Dispatched On"
                      value={
                        dayjs(data.dispatchedAt).format('DD/MM/YYYY') || '-'
                      }
                      sx={{
                        margin: '0 !important',
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
          <Stack spacing={2}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <VegaTextV2
                text="Card Details"
                bold
                color={'rgba(34, 34, 34, 1)'}
              />

              <CimsButton
                variant="text"
                onClick={async () => {
                  const presignedUrlRes = await getPresignedUrl(
                    data?.cardAllotmentFileUrl || ''
                  );
                  downloadFileFromURI(presignedUrlRes.data, 'downloadKit.csv');
                }}
              >
                Download Kit ID
              </CimsButton>
            </Stack>
            <Stack spacing={1}>
              <Stack direction={'row'} spacing={2}>
                <LableValueDisplay
                  label="Card Type"
                  value={binQuery.data?.productType || '-'}
                />
                <Divider orientation="vertical" variant="middle" flexItem />
                <LableValueDisplay
                  label="No. of kits"
                  value={(data?.totalKits || 0).toString()}
                />
              </Stack>
              {/* <Stack direction={'row'} spacing={2}>
                <LableValueDisplay label="Card Type" value="Prepaid" />
                <Divider orientation="vertical" variant="middle" flexItem />
                <LableValueDisplay label="No. of kits" value="33455" />
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      </CIMSModal>
    </Box>
  );
};

export default ViewOrderDetails;
