import { useState } from 'react';
import AnalyticsSelection from '../components/Analytics/AnalyticsSelection';
import BusinessOverview from '../components/Analytics/BusinessOverview/BusinessOverview';
import { VegaPage } from '../components/common';
import { VegaHorizontalSelectorDataType } from '../components/common/VegaHorizontalSelector';
import {
  CorporateCardIcon,
  CreditCardIcon,
  UserIcon,
} from '../components/icon/Icons';
import { GREEN, STROKE_COLOR } from '../constants/style';

const ANALYTICS_CATEGORIES = {
  BUSINESS_OVERVIEW: 'BUSINESS_OVERVIEW',
  LEGAL_ACCOUNT_HOLDERS: 'LEGAL_ACCOUNT_HOLDERS',
  REAL_ACCOUNT_HOLDERS: 'REAL_ACCOUNT_HOLDERS',
  AGEING_REPORTS: 'AGEING_REPORTS',
};

const tileData: VegaHorizontalSelectorDataType[] = [
  {
    id: ANALYTICS_CATEGORIES.BUSINESS_OVERVIEW,
    text: 'Business overview',
    image: <CorporateCardIcon strokeColor={STROKE_COLOR} />,
    bgColor: '#D8E7FF',
  },
  {
    id: ANALYTICS_CATEGORIES.LEGAL_ACCOUNT_HOLDERS,
    text: 'Legal account holders',
    image: <CreditCardIcon strokeColor={STROKE_COLOR} />,
    bgColor: '#FFCBE7',
  },
  {
    id: ANALYTICS_CATEGORIES.REAL_ACCOUNT_HOLDERS,
    text: 'Real account holders',
    image: <UserIcon strokeColor={STROKE_COLOR} />,
    bgColor: '#FFDBCB',
  },
  {
    id: ANALYTICS_CATEGORIES.AGEING_REPORTS,
    text: 'Ageing reports',
    image: <UserIcon strokeColor={STROKE_COLOR} />,
    bgColor: GREEN.light,
  },
];

function Analytics() {
  const [activeState, setActiveState] =
    useState<VegaHorizontalSelectorDataType>(tileData[0]);

  const onChange = (val: VegaHorizontalSelectorDataType) => {
    if (val.id === ANALYTICS_CATEGORIES.BUSINESS_OVERVIEW) {
      setActiveState(val);
    }
  };

  return (
    <VegaPage gap={3}>
      <AnalyticsSelection
        heading="Analytics"
        subHeading="Create multi-level, flexible reports at ease."
        data={tileData}
        setActiveState={onChange}
        active={activeState}
      />

      {activeState.id === ANALYTICS_CATEGORIES.BUSINESS_OVERVIEW ? (
        <BusinessOverview />
      ) : (
        ''
      )}
    </VegaPage>
  );
}

export default Analytics;
