/** @format */

export const COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_BQjAePmed',
  APP_CLIENT_ID: '7j9br921aff0cspse6pjhc2vp7',
};

export const UAT_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_34XGNIbow',
  APP_CLIENT_ID: '3hh3u3le0u6m3d4g09l7lpte2n',
};

export const SBM_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_wEU2IDMBS',
  APP_CLIENT_ID: '4ucu21l39dl0kdlu9ubeb7hh92',
};

export const DEV_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_8afkpIMGf',
  APP_CLIENT_ID: '7qi6r2cf60ah35at75mc0tklrs',
};
