import React, { useState } from 'react';
import { VegaPage } from '../components/common';
import { CreditCardIcon, UserIcon } from '../components/icon/Icons';

import { BACKGROUND, BORDER_GRAY, GREEN } from '../constants/style';
import ListOfFinalSettlement from '../components/UniversalStandards/ListOfFinalSettlement';
import DateSelector from '../components/UniversalStandards/DateSelector/DateSelector';
import GenerateCalender from '../components/UniversalStandards/GenerateCalender';
import VegaDatePicker from '../components/common/VegaDatePicker';
import VegaSelector from '../components/common/VegaSelector';
import SettlementCalendar from '../components/UniversalStandards/SettlementCalendar';

export interface IUniversalStandardsProps {
  data: any;
  onChange: (name: string, value: any) => void;
}

let tileData = [
  {
    id: 'DUE_DATES',
    text: 'Payment Due Dates',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'SETTLEMENT',
    text: 'Final Settlement calendar',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];

const PaymentDueDate = () => {
  const [activeState, setActiveState] = useState<any>(tileData[0]);

  return (
    <>
      <VegaPage>
        <>
          <VegaSelector
            data={tileData}
            setActiveState={setActiveState}
            active={activeState}
            header="Payment Due Dates"
            subHeader="Configure program Due and Settlement Dates"
          />
          {activeState.id === 'DUE_DATES' ? (
            <>
              <DateSelector />
            </>
          ) : (
            <>
              <SettlementCalendar />
              {/* <GenerateCalender setIsGenerate={setIsGenerate} />
              {isGenerate && <ListOfFinalSettlement />} */}
            </>
          )}
        </>
      </VegaPage>
    </>
  );
};

export default PaymentDueDate;
