import { Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useClientAuth } from '../../../providers/ClientProvider';
import { BinService } from '../../apis';
import { listForClient } from '../../../api/program';
import { EditFormFiled } from '../../../components/common/deprecated/EditFormFiled';

export function SelectBinAndProgram() {
  const { clientId } = useClientAuth();

  const hookForm = useFormContext<{
    binId: string;
    programId: string;
  }>();
  const binId = hookForm.watch('binId');

  const binsQuery = useQuery({
    queryKey: ['list_bins', clientId],
    queryFn: () => {
      return BinService.listForClient({
        clientId: clientId || '',
        page: 0,
        size: 100,
      });
    },
    enabled: !!clientId,
    select(data) {
      return data.records.map((bin) => ({
        value: bin.id.toString(),
        label: bin.binValue,
      }));
    },
  });

  const listBinProgramsQuery = useQuery({
    queryKey: ['list_bin_programs', binId, clientId],
    queryFn: () => {
      return BinService.listBinProgram({
        binId: binId,
        clientId: clientId || '',
        populateProgram: true,
      });
    },
    enabled: !!binId && !!clientId,
    select(data) {
      return data.records.map((program) => ({
        value: program.programId + '',
        label: program.programDto?.name || '',
      }));
    },
  });

  return (
    <Stack spacing={2}>
      <Controller
        name="binId"
        control={hookForm.control}
        rules={{ required: true }}
        render={({ field }) => (
          <EditFormFiled
            title={'SELECT BIN '}
            description={field.value}
            onChange={(val) => {
              field.onChange(val);
              hookForm.setValue('programId', '');
            }}
            placeHolder={'Select Bin Value '}
            options={binsQuery.data || []}
          />
        )}
      />

      {binId && (
        <Controller
          name="programId"
          control={hookForm.control}
          rules={{ required: true }}
          render={({ field }) => (
            <EditFormFiled
              title={'SELECT PROGRAM '}
              description={field.value}
              {...field}
              placeHolder={'Select Program '}
              options={[
                // {
                //   value: '',
                //   label: 'Select None',
                // },
                ...(listBinProgramsQuery.data || []),
              ]}
            />
          )}
        />
      )}
    </Stack>
  );
}

export const SelectBinAndProgramForClient = () => {
  const { clientId } = useClientAuth();

  const hookForm = useFormContext<{
    binId: string;
    programId: string;
  }>();
  const binId = hookForm.watch('binId');
  const programId = hookForm.watch('programId');

  const listProgramsForEntity = useQuery({
    queryKey: ['list_programs_for_client', clientId],
    queryFn: () => {
      return listForClient(clientId || '', 0, 100);
    },
    enabled: !!clientId,
    select(data) {
      return data.data.records.map((program) => ({
        value: program.programId + '',
        label: program.name || '',
      }));
    },
  });

  const listBinProgramsQuery = useQuery({
    queryKey: ['list_bin_programs', programId, clientId],
    queryFn: () => {
      return BinService.listBinProgram({
        // binId: binId,
        programId: programId,
        clientId: clientId || '',
        populateBin: true,
      });
    },
    enabled: !!programId && !!clientId,
    select(data) {
      return data.records.map((programBin) => ({
        value: programBin.binDto.id + '',
        label: programBin.binDto?.binValue || '',
      }));
    },
  });

  return (
    <Stack spacing={2}>
      <Controller
        name="programId"
        control={hookForm.control}
        rules={{ required: true }}
        render={({ field }) => (
          <EditFormFiled
            title={'SELECT PROGRAM '}
            description={field.value}
            {...field}
            placeHolder={'Select Program '}
            onChange={(val) => {
              field.onChange(val);
              hookForm.setValue('binId', '');
            }}
            options={[
              // {
              //   value: '',
              //   label: 'Select None',
              // },
              ...(listProgramsForEntity.data || []),
            ]}
          />
        )}
      />
      {programId && (
        <Controller
          name="binId"
          control={hookForm.control}
          rules={{ required: true }}
          render={({ field }) => (
            <EditFormFiled
              title={'SELECT BIN '}
              description={field.value}
              onChange={(val) => {
                field.onChange(val);
                // hookForm.setValue('programId', '');
              }}
              placeHolder={'Select Bin Value '}
              options={listBinProgramsQuery.data || []}
            />
          )}
        />
      )}
    </Stack>
  );
};
