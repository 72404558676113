/** @format */

import { CopyrightRounded, KeyboardArrowLeft } from '@mui/icons-material';
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { SxProps, Theme, useTheme } from '@mui/system';
import React, { ReactNode, useState, useEffect } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { BORDER_GRAY_DARK } from '../../constants/style';
import Setting from '../../pages/Settings';
import { useClientAuth } from '../../providers/ClientProvider';
import {
  BinCharterRoute,
  NESTED_VIEW,
  ROUTES,
  SUB_ROUTES,
  SupportRoute,
} from '../../routes';
import { setHeader } from '../../store/common/stateSlice';
import { useAppDispatch } from '../../store/hook';
import AccountSelector from '../accounts/AccountSelector';
import { VegaListItem } from '../common';
import VegaScrollListItem from '../common/VegaScrollListItem';
import MainHeader from '../header/MainHeader';
import { HomeIcon, SettingIcon, VegaPayLogo } from '../icon/Icons';
import ProgramSelector from '../program/ProgramSelector';
import { CONSTANTS } from '../../enums/commons';
import SidebarBottomComponent from './SidebarBottomComponent';
import GroupListItems from './GroupListItems';

export enum DashboardType {
  PROGRAM = '/ccms/program',
  CLIENT = '/ccms/client',
  BACK_OFFICE = '/ccms/back_office',
  SIMULATOR = '/ccms/simulator',
  SETTINGS = '/ccms/settings',
  MCC_SIMULATOR = '/mcc/simulator',
  MCC_BRANCH = '/mcc/branch',
  MCC_HQ = '/mcc/hq',
  MCC_CORPORATE = '/mcc/corporate',
}

export type SidebarListItemType = {
  id: string;
  component?: ReactNode;
  icon?: string | ReactNode;
  label?: string | ReactNode;
  noRender?: boolean;
  isProgramSelector?: boolean;
  isAccountSelector?: boolean;
  isClientRoutes?: boolean;
  hideForNonAdmin?: boolean;
  hideForAdmin?: boolean;
  isSimulatorRoutes?: boolean;
  isProgramRoutes?: boolean;
  isChecker?: boolean;
  isCrmRoutes?: boolean;
  isSetting?: boolean;
  showBackButton?: boolean;
  showLabel?: boolean;
  isWhiteBgColor?: boolean;
  children?: SidebarListItemType[];
  sx?: SxProps<Theme>;
} & (
  | {
      icon: string | ReactNode;
    }
  | {
      label: string | ReactNode;
    }
);

type Props = {
  onToggle?: Function;
  sidebarListItems: SidebarListItemType[];
  sidebarListItem?: any;
  onItemClick?: (item: SidebarListItemType) => void;
  selectedItem?: any;
  setSelectedItem?: any;
};

const drawerWidth = 250;
const shrinkedDrawerWidth = 80;

const SidebarLayout = ({
  onToggle,
  sidebarListItems,
  sidebarListItem,
  onItemClick,
  selectedItem,
  setSelectedItem,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAdmin, client, canAccessSubModule } = useClientAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listItems, setListItems] = useState<SidebarListItemType[]>([
    ...sidebarListItems,
  ]);
  const { pathname } = useLocation();
  const mccPath = pathname.split('/')[1];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const container =
    window !== undefined ? () => window.document.body : undefined;
  const [open, setOpen] = React.useState(false);
  const currentNestedView = searchParams.get(NESTED_VIEW);
  const currentView = searchParams.get(CONSTANTS.SELECTED_VIEW);
  const isOnProgramManagementRoute = selectedItem?.id === ROUTES.PROGRAM_MANAGE;

  const [showSelector, setShowSelector] = useState<boolean>(true);

  useEffect(() => {
    console.log('sidebarListItems', pathname, sidebarListItem);
    let selected;
    const filteredSideBarItems = getFilteredListItems();

    const isSetting = sidebarListItems.some((value) => value.isSetting);
    setListItems(filteredSideBarItems);
    if (currentView) {
      if (currentView == DashboardType.PROGRAM) {
        selected = sidebarListItem;
      } else {
        selected = sidebarListItems.filter(
          (item) => item.id === currentView
        )[0];
      }
    } else {
      if (pathname == DashboardType.PROGRAM) {
        selected = sidebarListItem;
      } else {
        selected = filteredSideBarItems[0];
      }
    }
    if (!isSetting && selected) {
      setSelectedItem(selected);
      setSearchParams(
        createSearchParams({
          [CONSTANTS.SELECTED_VIEW]: selected.id,
        })
      );
    }
  }, []);

  const getFilteredListItems = () => {
    console.log('sidebarListItems: ', sidebarListItems);
    const filteredList = sidebarListItems.filter((item) => {
      console.log('item----', canAccessSubModule(item.id.replace('/', '')));
      return canAccessSubModule(item.id.replace('/', ''));
    });
    console.log('Filtered items : ', filteredList);
    if (filteredList.length === 0) return sidebarListItems;
    else return filteredList;
  };

  const handleDrawerClose = () => {
    const shouldCloseDrawer: boolean = !open;
    setShowSelector(shouldCloseDrawer == false);
    setOpen(shouldCloseDrawer);
    onToggle?.();
  };
  const handleItemClick = (item: SidebarListItemType) => {
    dispatch(setHeader(''));
    if (item.isSetting) {
      navigate('/client');
    } else {
      if (onItemClick) {
        onItemClick(item);
      } else {
        setSelectedItem(item);
        setSearchParams(
          createSearchParams({ [CONSTANTS.SELECTED_VIEW]: item.id })
        );
      }
    }
  };

  const getBackgroundColor = () => {
    if (
      currentView === ROUTES.CARD_MANAGEMENT ||
      `/${currentNestedView}` === ROUTES.REWARDS_LOYALTY ||
      `${currentNestedView}` === BinCharterRoute.BIN_CONFIGURATION ||
      `${currentNestedView}` === 'benefit-inventory'
    ) {
      return 'white';
    } else if (
      currentView === SUB_ROUTES.INVOICE ||
      currentView === SUB_ROUTES.PROFIT_LOSS ||
      `${currentNestedView}` === SupportRoute.SUPPORT ||
      `${currentNestedView}` === SupportRoute.ACCOUNT_DETIAILS ||
      isOnProgramManagementRoute
    ) {
      return '#FAFAFA';
    }
    return '#EBEFF4';
  };

  function findObjectById(
    arr: SidebarListItemType[],
    targetId: string
  ): SidebarListItemType | null {
    for (const obj of arr) {
      if (obj.id === targetId) {
        return obj;
      }

      if (obj.children && obj.children.length > 0) {
        const foundInChildren = findObjectById(obj.children, targetId);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  }

  useEffect(() => {
    if (!currentNestedView) {
      const selectedId = currentView || '';
      const selectedItem = findObjectById(listItems, selectedId);
      setSelectedItem(selectedItem ? selectedItem : listItems[0]);
    }
  }, [currentNestedView, currentView]);

  return selectedItem ? (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{
          bgcolor: 'primary.main',
          width: { md: open ? shrinkedDrawerWidth : drawerWidth },
          height: '100vh',
          flexShrink: { md: 0 },
          position: 'relative',
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant={isMobile || isSmallScreen ? 'temporary' : 'permanent'}
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            height: '100vh',
            '& .MuiDrawer-paper': {
              color: 'text.secondary',
              bgcolor: 'primary.main',
              boxSizing: 'border-box',
              borderRight: 'none',
              ...(!open
                ? { width: drawerWidth }
                : isMobile || isSmallScreen
                ? { width: drawerWidth }
                : { width: shrinkedDrawerWidth }),
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box>
              <List sx={{ borderBottom: BORDER_GRAY_DARK }}>
                <ListItem
                  sx={{
                    display: 'flex',
                    transition: 'all 500ms ease-in-out',
                    ...(!open
                      ? { px: 5, py: 2, justifyContent: 'space-between' }
                      : isMobile || isSmallScreen
                      ? { px: 5, py: 2, justifyContent: 'space-between' }
                      : { px: 1, py: 2 }),
                  }}
                >
                  {!open ? (
                    mccPath === 'mcc' ? (
                      <Box onClick={() => handleItemClick(listItems[0])}>
                        <VegaPayLogo />
                      </Box>
                    ) : (
                      // <img
                      //   onClick={() => handleItemClick(listItems[0])}
                      //   // height={30}
                      //   // src="https://theunitybank.com/images/logos/footer-logo.svg"
                      //   src={ebixLogo}
                      //   alt=""
                      //   style={{
                      //     cursor: 'pointer',
                      //     maxWidth: '200px',
                      //     maxHeight: '30px',
                      //   }}
                      // />
                      <img
                        onClick={() => handleItemClick(listItems[0])}
                        // height={30}
                        // src="https://theunitybank.com/images/logos/footer-logo.svg"
                        src={addTimestampIfAwsUrl(client?.logo)}
                        alt=""
                        style={{
                          cursor: 'pointer',
                          maxWidth: '100px',
                          maxHeight: '25px',
                        }}
                      />
                    )
                  ) : isMobile || isSmallScreen ? (
                    <img
                      onClick={() => navigate('/client')}
                      width={30}
                      height={30}
                      // src="https://theunitybank.com/images/logos/footer-logo.svg"
                      src={addTimestampIfAwsUrl(client?.logo)}
                      alt=""
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <Box sx={{ height: '25px' }}></Box>
                  )}

                  <IconButton
                    onClick={handleDrawerClose}
                    sx={{
                      // position: 'absolute',
                      // right: '10px',
                      bgcolor: '#232346',
                      ':hover': { bgcolor: '#232346' },
                      mx: open ? 'auto' : 'inherit',
                      // top: '10px',
                      // zIndex: '9999',
                    }}
                  >
                    <KeyboardArrowLeft
                      fontSize="small"
                      sx={{
                        color: 'white',
                        transition: '0.75s',
                        ...(!open
                          ? { transform: 'rotate(0deg)' }
                          : { transform: 'rotate(180deg)' }),
                      }}
                    />
                  </IconButton>
                </ListItem>

                {pathname != DashboardType.CLIENT &&
                  pathname !== '/setting' &&
                  pathname != DashboardType.BACK_OFFICE &&
                  pathname != DashboardType.MCC_SIMULATOR &&
                  pathname != DashboardType.MCC_BRANCH &&
                  pathname != DashboardType.MCC_HQ &&
                  pathname != DashboardType.MCC_CORPORATE &&
                  showSelector && (
                    <ProgramSelector disabledOn={true} maxWidth={drawerWidth} />
                  )}

                {pathname === '/setting' && (
                  <ListItem
                    sx={{
                      bgcolor: '#232346',
                      border: '8px solid #08A6FF',
                      borderRight: 'none',
                      borderTop: 'none',
                      borderBottom: 'none',
                      cursor: 'pointer',
                      ...(open
                        ? { paddingLeft: '9px' }
                        : { paddingLeft: '25px' }),
                    }}
                    onClick={() => navigate('/client')}
                  >
                    <Grid container>
                      {!open ? (
                        <ListItemText>
                          <Typography
                            sx={{
                              color: '#08A6FF',
                              bgcolor: 'inherit',
                              fontWeight: '500',
                            }}
                          >
                            Home{' '}
                            <span style={{ color: 'white' }}>
                              {'>'} Settings
                            </span>
                          </Typography>
                        </ListItemText>
                      ) : isMobile || isSmallScreen ? (
                        <ListItemText
                          sx={{ color: 'text.secondary', bgcolor: 'inherit' }}
                          primary={'Home'}
                        />
                      ) : (
                        <HomeIcon />
                      )}
                    </Grid>
                  </ListItem>
                )}

                {listItems.map((item, index) =>
                  (!isAdmin && item.hideForNonAdmin) ||
                  (isAdmin && item.hideForAdmin) ? (
                    <></>
                  ) : item.isSetting ? (
                    <VegaScrollListItem
                      key={index}
                      isMobile={isMobile}
                      isSamllScreen={isSmallScreen}
                      open={open}
                      id={item.id}
                      icon={item.icon}
                      label={item.label}
                      selected={selectedItem?.id === item.id}
                      // onItemClick={() => handleItemClick(item)}
                    />
                  ) : item.children ? (
                    <GroupListItems
                      item={item}
                      index={index}
                      isDrawerOpen={open}
                    />
                  ) : (
                    <VegaListItem
                      key={index}
                      isMobile={isMobile}
                      isSamllScreen={isSmallScreen}
                      open={open}
                      id={item.id}
                      isChecker={item.isChecker}
                      icon={item.icon}
                      label={item.label}
                      // selected={selectedItem?.id === item.id}
                      selected={
                        searchParams.get(CONSTANTS.SELECTED_VIEW) === item.id
                      }
                      onItemClick={() => handleItemClick(item)}
                    />
                  )
                )}
              </List>
            </Box>
            <Box
              sx={{
                mb: 3,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {pathname === DashboardType.PROGRAM && (
                <List>
                  <VegaListItem
                    isMobile={isMobile}
                    isSamllScreen={isSmallScreen}
                    open={open}
                    id={ROUTES.PROGRAM}
                    icon={<SettingIcon />}
                    label={'Program Summary'}
                    selected={selectedItem?.id === sidebarListItem.id}
                    onItemClick={() => handleItemClick(sidebarListItem)}
                    key={'setting'}
                  />
                </List>
              )}
              {!open ? (
                <Stack spacing={1}>
                  <SidebarBottomComponent open={!open} />
                </Stack>
              ) : isMobile || isSmallScreen ? (
                <>
                  <SidebarBottomComponent open={open} />
                </>
              ) : pathname === DashboardType.BACK_OFFICE ? (
                <>
                  <SidebarBottomComponent open={!open} />
                </>
              ) : (
                <List>
                  <VegaListItem
                    isMobile={isMobile}
                    isSamllScreen={isSmallScreen}
                    open={open}
                    id={'Simulator version'}
                    icon={<CopyrightRounded />}
                    label={'Copyright Vegapay technology pvt. ltd.'}
                    key={'SIMULATOR'}
                  />
                </List>
              )}
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          height: '100vh !important',
          position: 'relative',
          bgcolor: getBackgroundColor(),
          // bgcolor: 'white',
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          // ...(selectedItem.isWhiteBgColor
          //   ? { bgcolor: 'white' }
          //   : { bgcolor: '#EBEFF4' }),
        }}
      >
        <MainHeader
          setOpen={setOpen}
          isSmallScreen={isSmallScreen}
          isMobile={isMobile}
          backButtonRequired={selectedItem.showBackButton}
          pageTitle={selectedItem.showLabel ? selectedItem.label : undefined}
        />
        <Box
          height={`calc(100vh - ${
            selectedItem.showLabel || selectedItem.backButtonRequired
              ? '118'
              : '69'
          }px)`}
          sx={{
            overflow: 'auto',
          }}
          px={
            `/${currentNestedView}` == ROUTES.REWARDS_LOYALTY ||
            `${currentView}` == ROUTES.BIN_CHARTER ||
            `${currentView}` == SUB_ROUTES.INVOICE ||
            `${currentView}` == SUB_ROUTES.PROFIT_LOSS ||
            `${currentNestedView}` == SupportRoute.SUPPORT ||
            `${currentNestedView}` == SupportRoute.ACCOUNT_DETIAILS ||
            currentNestedView === 'campaign-configuration' ||
            currentNestedView === 'benefit-inventory' ||
            isOnProgramManagementRoute
              ? 0
              : isMobile
              ? 2
              : 5
          }
          pb={
            `/${currentNestedView}` == ROUTES.REWARDS_LOYALTY ||
            `${currentView}` == ROUTES.BIN_CHARTER ||
            `${currentView}` == SUB_ROUTES.INVOICE ||
            `${currentView}` == SUB_ROUTES.PROFIT_LOSS ||
            `${currentNestedView}` == SupportRoute.SUPPORT ||
            `${currentNestedView}` == SupportRoute.ACCOUNT_DETIAILS ||
            currentNestedView === 'campaign-configuration' ||
            currentNestedView === 'benefit-inventory' ||
            isOnProgramManagementRoute
              ? 0
              : 5
          }
          pt={
            `/${currentNestedView}` == ROUTES.REWARDS_LOYALTY ||
            `${currentView}` == ROUTES.BIN_CHARTER ||
            `${currentView}` == SUB_ROUTES.INVOICE ||
            `${currentView}` == SUB_ROUTES.PROFIT_LOSS ||
            `${currentNestedView}` == SupportRoute.SUPPORT ||
            `${currentNestedView}` == SupportRoute.ACCOUNT_DETIAILS ||
            currentNestedView === 'campaign-configuration' ||
            currentNestedView === 'benefit-inventory'
              ? 0
              : isOnProgramManagementRoute
              ? 0
              : 5
          }
        >
          {selectedItem.isSetting ? (
            <Setting />
          ) : selectedItem.noRender ? (
            <></>
          ) : (
            <>
              <Box display={'flex'} gap={2}>
                {pathname == DashboardType.BACK_OFFICE && (
                  <ProgramSelector
                    disabledOn={selectedItem?.isProgramSelector}
                    maxWidth={drawerWidth}
                  />
                )}
                <AccountSelector disabledOn={selectedItem?.isAccountSelector} />
              </Box>
              {selectedItem.component}
            </>
          )}
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default SidebarLayout;
/**
 * THIS WAS REQUIRED AS BROWSER WAS CACHING IMAGES
 *
 *
 * Adds a timestamp query parameter to an AWS URL, if the URL is from AWS.
 *
 * @param url - The URL to check and modify.
 * @returns The modified URL with a timestamp query parameter, or the original URL if it's not from AWS.
 */
function addTimestampIfAwsUrl(url?: string): string {
  if (!url) return '';
  const awsUrlPattern = /^https?:\/\/.*\.amazonaws\.com/;
  if (awsUrlPattern.test(url)) {
    const timestamp = Date.now();
    return `${url}?t=${timestamp}`;
  }
  return url;
}
