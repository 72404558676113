import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { VegaTextField } from '../../../../../components/common';
import VegaSelect from '../../../../../components/common/VegaSelect';
import { BLUE, YELLOW } from '../../../../../constants/style';
import { ICommonProps } from '../../../../../types/commonType';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_INR } from '../../../../variable';
import { convertCurrency } from '../../../api/transaction';
import { IReloadCardProps } from '../../../../types/CorporateTransaction';
import { MoneyIcon } from '../../../../../components/icon/Icons';

interface IProps {
  isShow?: boolean;
  state: IReloadCardProps;
  handleChange: (name: keyof IReloadCardProps, value: string | number) => void;
}

const BASE_URL = 'https://api.exchangerate.host/latest';

const CardReload = ({ handleChange, state, isShow }: IProps) => {
  const [exchangeRate, setExchangeRate] = useState<any>();
  const [filterData, setFilterData] = useState<any>({});

  const converter = () => {
    const obj = {
      sourceCurrency: state.sourceCurrency,
      destinationCurrency: state.destinationCurrency,
      timestamp: new Date(),
    };
    convertCurrency(obj).then((res) =>
      setExchangeRate(res.data.conversionRate)
    );
  };

  useEffect(() => {
    if (state.sourceCurrency && state.destinationCurrency) {
      converter();
    }
  }, [state.sourceCurrency, state.destinationCurrency]);

  const convertValue = (
    markupValueType: string,
    value: number,
    convert: number
  ) => {
    const toFixedValue: any = value?.toFixed(2);

    if (markupValueType === 'PERCENTAGE') {
      const percentValue = toFixedValue - toFixedValue * (convert / 100);
      return percentValue?.toFixed(2);
    } else {
      const amountValue = toFixedValue - convert;
      return amountValue;
    }
  };

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <VegaSelect
            options={DEFAULT_CURRENCY_INR}
            key="sourceCurrency"
            isCurrency
            selected={state.sourceCurrency}
            label="Select Source Currency"
            onChange={(value) => handleChange('sourceCurrency', value)}
            disabled={isShow}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state.amount}
            onChange={(e) =>
              handleChange(
                'amount',
                e.target.value && parseFloat(e.target.value)
              )
            }
            key="amount"
            label="Amount"
            type={'number'}
            placeholder="Amount"
            // disabled={isShow}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <VegaSelect
            options={DEFAULT_CURRENCY}
            key="destinationCurrency"
            isCurrency
            selected={state.destinationCurrency}
            label="Select Destination Currency"
            onChange={(value) => handleChange('destinationCurrency', value)}
            disabled={isShow}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              bgcolor: YELLOW.lighter,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            Amount : {(state.amount as number) * exchangeRate?.toFixed(2)}
            {/* {convertValue(
              state.markupValueType,
              state.amount * exchangeRate,
              state.value
            )} */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              bgcolor: '#DCF7FF',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
              py: 1,
              fontWeight: 'bold',
            }}
          >
            {`Conversion 1 ${state.sourceCurrency} = ${exchangeRate?.toFixed(
              2
            )} ${state.destinationCurrency}`}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              bgcolor: YELLOW.lighter,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
              py: 1,
              fontWeight: 'bold',
            }}
          >
            {`Net Amount Loaded : ${(
              (state.amount as number) * exchangeRate
            ).toFixed(3)} INR`}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardReload;
