import { Menu } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { VegaTextField } from '../../../../../components/common';
import { IPLanTypeProps } from '../../../../types/plan';
import { fetchAccountDetailsList } from '../../../api/account';
import { ICurrencyProps } from '../ListOfUserMenagement';
import { ICommonProps } from '../../../../../types/commonType';

interface IProps extends ICommonProps {
  setState: React.Dispatch<React.SetStateAction<ICurrencyProps[]>>;
  setPlanData: React.Dispatch<React.SetStateAction<IPLanTypeProps>>;
  listState: ICurrencyProps[];
}

const WalletSummary = ({
  state,
  handleChange,
  setState,
  setPlanData,
  listState,
}: IProps) => {
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();
  const [selectedList, setSelectedList] = useState<any>([]);

  const drop = (e: any) => {
    const copyListItems = [...listState];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    console.log('copyListItems', copyListItems);
    setState(copyListItems);
  };

  const handleValueChange = (e: any, index: number) => {
    const arr = [...listState];
    const obj = {
      currency: e,
      priority: index,
    } as ICurrencyProps;
    arr.splice(index, 1, obj);
    setState(arr);
  };

  const getAccountDetails = async () => {
    await fetchAccountDetailsList({
      entityId: state?.account?.id || '',
      entityType: 'ACCOUNT',
    }).then((res) => {
      const getWalletPlan = res.data?.filter(
        (val: any) => val.planType === 'WALLET_CONFIGURATION_PLAN'
      );
      setPlanData(getWalletPlan[0]);
      setState(getWalletPlan[0]?.walletList);
    });
  };
  console.log('listState', listState);
  useEffect(() => {
    getAccountDetails();
  }, []);

  const getSelected = (value: number, arr: any[]) => {
    return arr.some((item: any) => value === item);
  };

  const onSelectList = (value: any) => {
    const isSelected = getSelected(value, selectedList);

    if (!isSelected) {
      setSelectedList([...selectedList, value]);
    } else {
      const filterDPDbucket = selectedList.filter(
        (item: any) => item !== value
      );

      setSelectedList(filterDPDbucket);
    }
  };

  console.log('listState', listState);

  return (
    <div>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <VegaTextField
              value={state?.account?.customerId || ''}
              onChange={(e) => handleChange('customerId', e.target.value)}
              key="customerId"
              label="Customer Id"
              type={'text'}
              placeholder="Customer Id"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaTextField
              value={state?.card?.cardNumber || ''}
              onChange={(e) => handleChange('cardNo', e.target.value)}
              key="cardNo"
              label="Card No"
              type={'text'}
              placeholder="Card No"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <VegaTextField
              value={state?.customer?.firstName}
              onChange={(e) => handleChange('name', e.target.value)}
              key="name"
              label="Customer Name"
              type={'text'}
              placeholder="Customer Name"
              disabled
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>
        {listState?.map((value: ICurrencyProps, index: number) => {
          console.log('ICurrencyProps', value);
          return (
            <Box sx={{ display: 'flex', my: 1 }}>
              <div
                key={index}
                style={{ display: 'flex', width: '100%', alignItems: 'center' }}
                draggable
                onDragStart={(e) => (dragItem.current = index)}
                onDragEnter={(e) => (dragOverItem.current = index)}
                onDragEnd={drop}
                onDragOver={(e) => e.preventDefault()}
              >
                <Menu className="draggable-list__item" />
                <Box key={index} sx={{ flex: 1, mx: 2 }}>
                  <VegaTextField
                    key="curn"
                    // onScroll={loadMoreItems}
                    // isCurrency
                    // options={DEFAULT_CURRENCY}
                    // onChange={(e: any) => handleValueChange(e, index)}
                    // selected={value?.currency}
                    value={value?.currency}
                    // disabled={
                    //   getSelected(value.priority, selectedList) ? true : false
                    // }
                  />
                </Box>
                {/* <Box key={index} sx={{}}>
                  {getSelected(value.priority, selectedList) ? (
                    <VegaContainedButton
                      text="Disable"
                      isDanger
                      onClick={() => onSelectList(value.priority)}
                    />
                  ) : (
                    <VegaContainedButton
                      text="Active"
                      isPrimary
                      px={2.3}
                      onClick={() => onSelectList(value.priority)}
                    />
                  )}
                </Box> */}
              </div>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default WalletSummary;
