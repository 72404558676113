import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { BORDER, GREEN } from '../../../constants/style';
import { DelinquencySummary } from '../../../types/collection';
import VegaText from '../../common/VegaText';

type Props = {
  summary?: DelinquencySummary | null;
  loading?: boolean;
};

const SummaryCardItem = (data: {
  title: string;
  value: string | number;
  loading?: boolean;
}) => {
  return (
    <Grid item xs={12} sm={3}>
      <Box sx={{ border: BORDER, borderRadius: '20px' }}>
        <Box
          sx={{
            px: 3,
            py: 2,
            bgcolor: GREEN.lighter,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            textAlign: 'center',
          }}
        >
          <VegaText text={data.title} />
        </Box>
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Box mb={2}>
            <Typography lineHeight={1.2} fontSize={30} color="green">
              {data.loading ? <CircularProgress /> : data.value}
            </Typography>
          </Box>
          <Box>
            <Typography lineHeight={1.2} fontSize={20}>
              Today
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const SummaryCards = ({ summary, loading }: Props) => {
  return (
    <Grid container spacing={2}>
      <SummaryCardItem
        title={'Total Customers'}
        value={summary?.delinquent_accounts ?? '-'}
        loading={loading}
      />
      <SummaryCardItem
        title={'Total O/B'}
        value={summary?.outstanding_amount ?? '-'}
        loading={loading}
      />
      <SummaryCardItem
        title={'Total O/D'}
        value={summary?.overdue_amount ?? '-'}
        loading={loading}
      />
      <SummaryCardItem
        title={'Performance'}
        value={`${summary?.performance?.toFixed(2) ?? '-'}%`}
        loading={loading}
      />
    </Grid>
  );
};

export default SummaryCards;
