import React from 'react';
import Vega_PageHeader from '../../../../components/common/v3/Vega_PageHeader';
import Vega_PageContent from '../../../../components/common/v3/Vega_PageContent';
import ProfitLossSummaryContainer from './Summary/ProfitLossSummaryContainer';
import ProfitLossAccounts from './AllAccount/ProfitLossAccounts';

const ProfitLossLendingPage = () => {
  return (
    <>
      <Vega_PageHeader sx={{ pb: 0.5, pt: 3 }} title="Profit & Loss" />
      <Vega_PageContent sx={{ my: 2 }}>
        <ProfitLossSummaryContainer
          title="Bank Summary"
          lastUpdated="04/08/2023"
          income={330000}
          cost={310000}
        />
      </Vega_PageContent>
      <Vega_PageContent sx={{ my: 2 }}>
        <ProfitLossAccounts />
      </Vega_PageContent>
    </>
  );
};

export default ProfitLossLendingPage;
