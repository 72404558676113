/** @format */

import { Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaLabel from '../../../../components/common/VegaLabel';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import { ISectionProps } from '../../../Back-office/pages/MCCUserManagement';
import { dateFormat } from '../../../variable';
import CorporateCardSaleDrawerContent from './CorporateCardSaleDrawerContent';

interface IProps {
  active: ISectionProps;
  data: any;
  loading?: boolean;
  isMapCardShow: boolean;
  hide?: boolean;
  onCreate: (data: any) => void;
  onGenerateOTP: (data: any) => void;
  onCheckerStatus: (data: any) => void;
  onAllocateCard: (data: any) => void;
}

export const checkNullValue = (value: any) => {
  if (value !== null || value !== undefined) {
    return value;
  } else return false;
};

const ListOfCardSaleMakerCorporate = ({
  active,
  data,
  loading,
  isMapCardShow,
  hide,
  onCreate,
  onGenerateOTP,
  onCheckerStatus,
  onAllocateCard,
}: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const getBinId: any = sessionStorage.getItem('binId');
  const [checkValue, setCheckValue] = useState<string>('');
  const [showData, setShowData] = useState<any>({});

  const getButtons = (
    status: string,
    checkerMakerStatus: string,
    data: any,
    isCard?: boolean
  ) => {
    if (status === 'DRAFT') {
      return (
        <VegaContainedButton
          text="Send Reviewer"
          onClick={() => onCheckerStatus(data)}
          isPrimary
        />
      );
    }
    if (status === 'ACTIVE' && checkerMakerStatus === 'APPROVED' && isCard) {
      return <VegaContainedButton text="Active" disabled isPrimary />;
    }
    if (status === 'INACTIVE') {
      return <VegaContainedButton text="Inactive" isPrimary disabled />;
    }
    if (
      status === 'UNDER_REVIEW' &&
      checkerMakerStatus === 'CHANGES_REQUESTED'
    ) {
      return (
        <VegaContainedButton
          text="Send Checker"
          onClick={() => showDetails(data, 'EDIT')} // updateChecker
          // onClick={() => onCheckerStatus(data)} // updateChecker
          isPrimary
        />
      );
    }
    if (
      status === 'UNDER_REVIEW' &&
      (checkerMakerStatus === 'CHECKER_PENDING' ||
        checkerMakerStatus === 'REVIEWER_PENDING')
    ) {
      return (
        <VegaContainedButton
          text="Sent to Checker"
          //  onClick={() => onCheckerStatus(data)} // updateChecker
          isPrimary
          disabled
        />
      );
    }
  };

  const getShow = (isMatchId: boolean, flag: boolean) => {
    if (flag) {
      if (isMatchId && flag) {
        return true;
      } else return false;
    } else return true;
  };

  const KIT_COLUMN = [
    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Typography>
            {checkNullValue(props.row.customer?.firstName)}
          </Typography>
        );
      },
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <VegaLabel
            text={props?.row?.card?.cardNumber ?? '--'}
            color="primary"
          />
        );
      },
    },

    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card Expiry
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props: any) => {
        return (
          <VegaLabel
            text={props?.row?.card?.expiryDate ?? ''}
            color="primary"
          />
        );
      },
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card Status
        </Typography>
      ),
      flex: 0.6,
      renderCell: (props: any) => {
        return (
          <VegaLabel
            text={toLowerCase(props?.row?.card?.cardStatus ?? '')}
            color="primary"
          />
        );
      },
    },
    {
      field: 'emaiId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Email Id
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Typography>{checkNullValue(props.row.customer?.emailId)}</Typography>
        );
      },
    },
    {
      field: 'mobileNumber',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Mobile No.
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Typography>
            {checkNullValue(props.row.customer?.mobileNumber)}
          </Typography>
        );
      },
    },
    {
      field: 'activationDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Activation Date
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props: any) => {
        return (
          <VegaLabel
            text={
              props?.row?.card?.createdAt
                ? dateFormat(props?.row?.card?.createdAt)
                : ''
            }
            color="primary"
          />
        );
      },
    },
    {
      field: 'cardNetwork',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card Network
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props: any) => {
        const networks = props.row.card?.cardNetwork;
        if (!networks || networks.length <= 0) {
          return;
        }
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      },
    },

    {
      field: 'Request',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Action
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const accountData = props.row.account;
        const cardData = props.row.card;
        return checkNullValue(accountData) ? (
          checkNullValue(cardData) ? (
            <VegaContainedButton
              text="View"
              onClick={() => showDetails(props.row, 'VIEW')}
              isPrimary
            />
          ) : (
            getButtons(
              props.row.account?.status,
              props.row.account?.checkerMakerStatus,
              accountData,
              !checkNullValue(cardData)
            )
          )
        ) : (
          <VegaContainedButton
            text="Create Account"
            onClick={() => showDetails(props.row, 'CREATE_ACCOUNT')}
            isPrimary
          />
        );
      },
    },
    // {
    //   field: 'Action',
    //   headerName: <Typography className="font-aspekta-500" variant="body2"> Action</Typography>,
    //   flex: 0.7,
    //   renderCell: (props: any) => {
    //     const accountData = props.row.account;
    //     const cardData = props.row.card;

    //     return (
    //       checkNullValue(accountData) &&
    //       checkNullValue(accountData).status === 'ACTIVE' &&
    //       checkNullValue(accountData).checkerMakerStatus === 'APPROVED' &&
    //       !checkNullValue(cardData) && (
    //         <VegaContainedButton
    //           text="Map Card"
    //           onClick={() => showDetails(props.row, 'MAP_CARD')}
    //           isPrimary
    //           disabled={getShow(showData.id !== accountData.id, isMapCardShow)}
    //         />
    //       )
    //     );
    //   },
    // },
  ];
  const PERSONALISED_COLUMN = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'programName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Program Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer ID
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Expiry Date
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'activationDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Activation Date
        </Typography>
      ),
      flex: 0.5,
    },

    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Status
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return props.row.status === 'APPROVED' ? (
          <VegaContainedButton
            text="Approved"
            // onClick={() => showBalance(props.row)}
            isSuccess
            disabled
          />
        ) : props.row.status === 'REJECTED' ? (
          <VegaContainedButton
            text="Rejected"
            // onClick={() => showBalance(props.row)}
            isDanger
            px={2.5}
            disabled
          />
        ) : (
          <VegaContainedButton
            text="Pending"
            // onClick={() => showBalance(props.row)}
            isWarning
            px={2.7}
            disabled
          />
        );
      },
    },
    {
      field: 'details',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Details
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="View"
            onClick={() => showDetails(props.row, '')}
            isPrimary
          />
        );
      },
    },
  ];

  const REISSUE_COLUMN = [
    {
      field: 'kitNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Kit No
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'programName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Program Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer ID
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Expiry Date
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'activationDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Activation Date
        </Typography>
      ),
      flex: 0.5,
    },

    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Status
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return props.row.status === 'APPROVED' ? (
          <VegaContainedButton
            text="Approved"
            // onClick={() => showBalance(props.row)}
            isSuccess
            disabled
          />
        ) : props.row.status === 'REJECTED' ? (
          <VegaContainedButton
            text="Rejected"
            // onClick={() => showBalance(props.row)}
            isDanger
            px={2.5}
            disabled
          />
        ) : (
          <VegaContainedButton
            text="Pending"
            // onClick={() => showBalance(props.row)}
            isWarning
            px={2.7}
            disabled
          />
        );
      },
    },
    {
      field: 'details',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Details
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="Issue New"
            onClick={() => showDetails(props.row, '')}
            isPrimary
          />
        );
      },
    },
  ];

  const showDetails = (data: any, value: string) => {
    const newObj = { ...data, binId: getBinId ? parseInt(getBinId) : '' };
    setCheckValue(value);
    setOpen(!open);
    setShowData(newObj);
  };

  const onClose = () => {
    setOpen(!open);
  };

  const onSubmit = () => {};
  const createAccount = async () => {
    if (checkValue === 'CREATE_ACCOUNT') {
      await onCreate(showData);
    } else if (checkValue === 'GENERATE_OTP') {
      await onGenerateOTP(showData);
    } else if (checkValue === 'MAP_CARD') {
      await onAllocateCard(showData);
    }

    onClose();
  };
  const onChange = (name: string, value: any) => {
    if (name === 'binId') {
      sessionStorage.setItem('binId', value);
    }
    setShowData({ ...showData, [name]: value });
  };

  // const getValue = (id: string) => {
  //   return {
  //     column: KIT_COLUMN,
  //     header: toLowerCase(checkValue),
  //     component: (
  //       <KitDretails
  //         state={showData}
  //         handleChange={onChange}
  //         checkValue={checkValue}
  //       />
  //     ),
  //     onSubmit: createAccount,
  //   };
  // };

  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={KIT_COLUMN}
        idColumn={'id'}
        loading={loading}
      />
      <VegaDrawer
        open={open}
        isClose
        isSubmit={checkValue !== 'VIEW'}
        header={toLowerCase(checkValue)}
        closeText={checkValue === 'VIEW' ? 'Got it!' : 'Cancel'}
        submitText={'Submit'}
        onClose={onClose}
        onSubmit={createAccount}
      >
        <CorporateCardSaleDrawerContent
          state={showData}
          handleChange={onChange}
          checkValue={checkValue}
        />
      </VegaDrawer>
    </VegaCard>
  );
};

export default ListOfCardSaleMakerCorporate;
