import { FRMCriteriaConfig } from '../../types/policy';
import BooleanSelect from './BooleanSelect';
import { NumberInputWithDropdown } from './NumberInputWithDropdown';

export const CriteriaConfigCell = (data: {
  config?: FRMCriteriaConfig | null;
  onValueChange: (updatedValue: string) => void;
  onSelectValueChange: (updatedValue: string) => void;
  selectedOperator: string;
  showSuffix: boolean;
  operatorOptions: string[];
}) => {
  const criteriaConfig = data.config;
  const minValue = criteriaConfig?.min;
  const maxValue = criteriaConfig?.max;
  const exactValue = criteriaConfig?.exact;

  if (typeof exactValue === 'number') {
    return (
      <BooleanSelect
        selectedValue={exactValue}
        onChange={function (value: number): void {
          const stringValue = value.toString();
          data.onValueChange(stringValue);
        }}
      />
    );
  }

  return (
    <NumberInputWithDropdown
      options={data.operatorOptions}
      selectedOption={data.selectedOperator}
      suffix={data.showSuffix ? '%' : ''}
      value={(minValue || maxValue)?.toString() ?? ''}
      onChange={data.onValueChange}
      maxValue={100}
      onSelectOptionChange={data.onSelectValueChange}
    />
  );
};
