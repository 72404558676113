import axios from 'axios';
import {
  ICreatePartnerRequest,
  IPartner,
  ListPartnerRequest,
  UpdatePartnerRequest,
} from '../types/partner';
import { PaginatedResponse } from '../utils/api';
import { getFormData } from '../utils/common.utils';

const BASE_URL = '/client/entity/partner';

class PartnerService {
  static async createPartner(
    request: ICreatePartnerRequest
  ): Promise<IPartner> {
    const response = await axios.post(BASE_URL, getFormData(request));
    return response.data;
  }

  static async getPartner(id: string): Promise<IPartner> {
    const response = await axios.get(`${BASE_URL}/${id}`);
    return response.data;
  }

  static async listPartners(
    request: Partial<ListPartnerRequest>
  ): Promise<PaginatedResponse<IPartner>> {
    const response = await axios.post(`${BASE_URL}/list`, request);
    return response.data;
  }

  static async updatePartner(request: UpdatePartnerRequest): Promise<IPartner> {
    const response = await axios.put(BASE_URL, getFormData(request));
    return response.data;
  }

  static async deletePartner(id: string) {
    const response = await axios.delete(`${BASE_URL}/${id}`);
    return response.data;
  }
}

export default PartnerService;
