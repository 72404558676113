import React from 'react';
import { IOpenCreditListProps } from '../../../types/CorporateTransaction';
import { Box, Typography } from '@mui/material';
import { BLUE, GREY } from '../../../../constants/style';
import VegaLabel from '../../../../components/common/VegaLabel';
import { dateFormat } from '../../../variable';
import { toLowerCase } from '../../../../constants/commonFunction';
import { NoResults } from '../../../../components/common';

interface IProps {
  openCreditList: IOpenCreditListProps[];
}

interface ICorporateColumnProps {
  key: keyof IOpenCreditListProps | string | number;
  header: string;
  flex: number;
}

const COLUMN: ICorporateColumnProps[] = [
  {
    key: 'createdAt',
    header: 'Invoice Date',
    flex: 1,
  },
  {
    key: 'dueAmount',
    header: 'Amount',
    flex: 1,
  },
  {
    key: 'updatedAt',
    header: 'Due Date',
    flex: 1,
  },
  // {
  //   key: 'overdueDate',
  //   header: 'Overdue Date',
  //   flex: 1,
  // },
  {
    key: 'dueCharge',
    header: 'Charges',
    flex: 0.7,
  },
  {
    key: 'creditStatus',
    header: 'Payment Status',
    flex: 1,
  },
  {
    key: 'invoiceType',
    header: 'Invoice Type',
    flex: 1,
  },
  {
    key: 'billingCycle',
    header: 'Billing Cycle',
    flex: 1,
  },
  {
    key: 'invoice',
    header: 'Invoice',
    flex: 1,
  },
  {
    key: 'action',
    header: 'Action',
    flex: 1,
  },
];

const ListOfOpenCredit = ({ openCreditList }: IProps) => {
  return (
    <>
      {openCreditList.length ? (
        openCreditList.map((value: IOpenCreditListProps, index: number) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  //   borderTop: `2px solid ${GREY.grey}`,
                  bgcolor: BLUE.lightest,
                  transition: 'all 500ms ease-in-out',
                }}
              >
                {COLUMN.map((column: ICorporateColumnProps) => {
                  // const kycURL = getURL(value.kycUrl);

                  return (
                    <>
                      <Box
                        sx={{
                          flex: column.flex,
                          py: 2,
                          px: 2,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          fontSize: 14,
                        }}
                      >
                        {column.key === 'createdAt' ||
                        column.key === 'updatedAt' ? (
                          <VegaLabel
                            text={dateFormat(value[column.key])}
                            color="primary"
                          />
                        ) : column.key === 'creditStatus' ? (
                          <VegaLabel
                            text={toLowerCase(value[column.key])}
                            color="primary"
                          />
                        ) : (
                          value[column.key]
                        )}
                        {column.key === 'invoice' && ''}
                        {column.key === 'action' && ''}
                      </Box>
                    </>
                  );
                })}
                <Box sx={{ py: 2, px: 2, flex: 0.3 }}></Box>
              </Box>
            </>
          );
        })
      ) : (
        <NoResults sx={{ borderRadius: 0 }} />
      )}
    </>
  );
};

export default ListOfOpenCredit;
