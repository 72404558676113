/** @format */

import { MenuItem, Select, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export const NumberInputWithDropdown = (data: {
  options: string[];
  selectedOption: string;
  hideSelector?: boolean;
  suffix: string;
  value: string;
  maxValue: number;
  onChange: (updatedValue: string) => void;
  onSelectOptionChange: (updatedValue: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState(data.value ?? '');

  useEffect(() => {
    if (data?.value !== textFieldValue) {
      setTextFieldValue(data?.value);
    }
  }, [data.value]);

  const handleOpen = (val: boolean) => () => {
    setOpen(val);
  };

  const SelectOptions = () => {
    return (
      <Select
        open={open}
        defaultValue={data.selectedOption}
        onChange={(e) => data.onSelectOptionChange(e.target.value)}
        onClickCapture={handleOpen(true)}
        onClose={handleOpen(false)}
        onSelect={handleOpen(false)}
        style={{ flex: 1, width: '70px' }}
        fullWidth
        displayEmpty
      >
        {data.options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const handleOnChange = (e: any) => {
    setTextFieldValue(e.target.value);
    data?.onChange?.(e.target.value);
  };

  return (
    <Stack
      direction={'row'}
      spacing={1}
      justifyContent="center"
      alignItems={'center'}
    >
      <div hidden={data.hideSelector} style={{ flex: 1 }}>
        <SelectOptions />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexGrow: 2,
          marginRight: 40,
        }}
      >
        <TextField
          type="number"
          defaultValue={textFieldValue}
          value={textFieldValue}
          style={{ flex: 1 }}
          onChange={handleOnChange}
          InputProps={{
            inputProps: { min: 0, max: data.maxValue },
            endAdornment: <div>{data.suffix}</div>,
          }}
        />
      </div>
    </Stack>
  );
};
