import { CircularProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useState } from 'react';
import { getEntityStatus } from '../../api/entityAuth';
import { usePolicy } from '../../providers/PolicyProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { EntityComment, UserMakerCheckerRole } from '../../types/entityAuth';

interface EntityCommentsListProps {
  entityId?: string;
}

const CommentListItem = (data: { comment: EntityComment }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
        borderBottom: '1px solid',
      }}
    >
      <Typography style={{ flex: 1 }}>{data.comment.comments}</Typography>
      <Typography style={{ flex: 1, textAlign: 'end' }}>
        {dayjs(data.comment.updatedAt).format('MMM D, YYYY h:mm A')}
      </Typography>
    </div>
  );
};

const CommentListHeader = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
      }}
    >
      <Typography style={{ flex: 1 }}>COMMENT</Typography>
      <Typography style={{ flex: 1, textAlign: 'end' }}>DATE</Typography>
    </div>
  );
};

function EntityCommentsList({ entityId }: EntityCommentsListProps) {
  const { setSnackbar } = useSnackbar();
  const [comments, setComments] = useState<EntityComment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { loggedInMakerCheckerUser } = usePolicy();

  function fetchEntityComments() {
    setComments([]);
    let role = loggedInMakerCheckerUser?.role;
    console.log('====================================');
    console.log(loggedInMakerCheckerUser);
    console.log('====================================');
    if (!entityId || !role) return;
    setLoading(true);
    getEntityStatus({
      entityId: entityId,
      role: role,
    })
      .then((res) => {
        const comments = res.commentsList;
        if (comments.length > 0) {
          setComments(comments);
        } else {
          setComments([]);
        }
      })
      .catch((err) => {
        setSnackbar('Failed to get comments', 'error');
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchEntityComments();
  }, [entityId]);

  if (comments.length <= 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>No Comments</Typography>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <CommentListHeader />
      {comments.map((item) => {
        return <CommentListItem comment={item} />;
      })}
    </div>
  );
}

export default EntityCommentsList;
