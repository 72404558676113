import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { updateResolutionStatus } from '../../../api/dispute';
import { PRIMARY } from '../../../constants/style';
import { VegaAccordian, VegaTextField } from '../../common';

function ServiceActions({ serviceData, setOpen, fetchDisputes }: any) {
  const resolutionHandler = (
    status: 'CUSTOMER_FAILURE' | 'CUSTOMER_SUCCESS'
  ) => {
    updateResolutionStatus({
      disputeId: serviceData.id,
      resolutionStatus: status,
    })
      .then((res) => {
        console.log('resolution status', res.data);
        fetchDisputes();
      })
      .catch((err) => {
        console.log('resolution , err', err);
      });
  };
  return (
    <Stack spacing={2}>
      <Box>Type Dispute</Box>
      <Stack>
        <VegaAccordian
          defaultExpanded
          header={'Dispute Transaction'}
          content={
            <Stack spacing={2} p={1}>
              <VegaTextField
                value={serviceData.transactionId}
                onChange={() => {}}
                key="txnId"
                label="Txn ID"
              ></VegaTextField>
              <VegaTextField
                value={serviceData.transactionAmount}
                onChange={() => {}}
                key="TxnAmount"
                label="Txn Amount"
              ></VegaTextField>
            </Stack>
          }
        ></VegaAccordian>
      </Stack>
      <Stack>
        <VegaAccordian
          defaultExpanded
          header={'Submit Proof'}
          content={
            <Box my={2}>
              Pan Card
              <Box
                p={1}
                my={1}
                display="flex"
                justifyContent={'space-between'}
                columnGap={1}
                border="1px solid #E4E4E4"
                borderRadius={2}
                alignItems="center"
              >
                <Box display={'flex'} columnGap={2}>
                  <FolderOutlinedIcon />
                  <Typography className="font-aspekta-500" variant="body2">
                    Pancard1.pdf
                  </Typography>
                </Box>
                <Button
                  sx={{
                    bgcolor: PRIMARY.light,
                    ':hover': { bgcolor: PRIMARY.light },
                    '&.Mui-disabled': {
                      color: PRIMARY.light,
                      bgcolor: PRIMARY.lighter,
                    },
                    color: 'white',
                  }}
                  size="small"
                >
                  Download
                </Button>
              </Box>
              Addhar Card
              <Box
                p={1}
                my={1}
                display="flex"
                justifyContent={'space-between'}
                columnGap={1}
                border="1px solid #E4E4E4"
                borderRadius={2}
                alignItems="center"
              >
                <Box display={'flex'} columnGap={2}>
                  <FolderOutlinedIcon />
                  <Typography className="font-aspekta-500" variant="body2">
                    Addharcard.pdf
                  </Typography>
                </Box>
                <Button
                  sx={{
                    bgcolor: PRIMARY.light,
                    ':hover': { bgcolor: PRIMARY.light },
                    '&.Mui-disabled': {
                      color: PRIMARY.light,
                      bgcolor: PRIMARY.lighter,
                    },
                    color: 'white',
                  }}
                  size="small"
                >
                  Download
                </Button>
              </Box>
            </Box>
          }
        />
      </Stack>
      <Stack>
        {serviceData.disputeStatus === 'ACQUIRED' && (
          <VegaAccordian
            defaultExpanded
            header={'Resolution'}
            content={
              <Box
                my={2}
                p={4}
                display="flex"
                justifyContent="space-between"
                px={12}
              >
                <Button
                  sx={{
                    bgcolor: PRIMARY.light,
                    ':hover': { bgcolor: PRIMARY.light },
                    '&.Mui-disabled': {
                      color: PRIMARY.light,
                      bgcolor: PRIMARY.lighter,
                    },
                    color: 'white',
                  }}
                  size="small"
                  onClick={() => {
                    resolutionHandler('CUSTOMER_SUCCESS');
                    setOpen(false);
                  }}
                >
                  Customer Win
                </Button>
                <Button
                  sx={{
                    bgcolor: PRIMARY.light,
                    ':hover': { bgcolor: PRIMARY.light },
                    '&.Mui-disabled': {
                      color: PRIMARY.light,
                      bgcolor: PRIMARY.lighter,
                    },
                    color: 'white',
                  }}
                  size="small"
                  onClick={() => {
                    resolutionHandler('CUSTOMER_FAILURE');

                    setOpen(false);
                  }}
                >
                  Merchant Win
                </Button>
              </Box>
            }
          />
        )}
      </Stack>
    </Stack>
  );
}

export default ServiceActions;
