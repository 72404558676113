/* eslint-disable react/jsx-pascal-case */
import { toLowerCase } from '../../../../constants/commonFunction';
import { PRIMARY } from '../../../../constants/style';
import { CIMS_PRIMARY } from '../../../assets/style';
import CIMS_Text from '../../../components/common/CIMS_Text';

const statusMapping: Record<string, string> = {
  PLACED: 'ASSIGNED',
  DISPATCHED: 'SHIPPED',
  APPROVED: 'APPROVED',
  DELIVERED: 'DELIVERED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
};

const colorMapping: Record<string, string> = {
  ISSUED: CIMS_PRIMARY.BLUE,
  REJECTED: CIMS_PRIMARY.RED,
  TEMP_BLOCKED: CIMS_PRIMARY.RED,
  PERM_BLOCKED: CIMS_PRIMARY.RED,
  ASSIGNED: CIMS_PRIMARY.GOLD,
  IN_TRANSIT: CIMS_PRIMARY.YELLOW,
  IN_STOCK: CIMS_PRIMARY.GREEN,
  REPLACED: CIMS_PRIMARY.GRAY,
};
const getColorForAStatus = (status: string) => {
  return colorMapping[status] || PRIMARY.darkBlue;
};

interface IProps {
  status: string;
}

export function CardStatusDisplay({ status }: IProps) {
  return (
    <CIMS_Text
      text={toLowerCase(status.toUpperCase())}
      color={getColorForAStatus(status.toUpperCase())}
    />
  );
}
