/** @format */

import { Box, Checkbox, Collapse, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { BLUE, GREEN, GREY } from '../../constants/style';
import { useClientAuth } from '../../providers/ClientProvider';
import { VegaCard } from '../common';
import VegaOutLineTextField from '../common/VegaOutLineTextField';
import VegaSelectItem from '../common/VegaSelectItem';
import VegaText from '../common/VegaText';
import { DEFAULT_SLAB_RULE, POSTING_CATEGORIES } from './InterestPolicy';
import InterestSlabSelector from './InterestSlabSelector';

type Props = {
  headerPrefix?: string;
  rateRule: any;
  onChange: any;
  onToggle: (posting: string) => void;
  disabled: boolean;
};

const RATE_TYPES = [
  {
    text: 'Equal',
    value: 'EQUAL',
  },
  {
    text: 'Amount Slab Based',
    value: 'AMOUNT_SLAB_BASED',
  },
];

const InterestPolicyCard = ({
  headerPrefix,
  rateRule,
  onChange,
  onToggle,
  disabled,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (posting: string, key: string, value: any) => {
    console.log('handleChange', posting, key, value);
    const edited = {
      ...rateRule[posting],
      [key]: value,
      ...(value === 'AMOUNT_SLAB_BASED'
        ? { amount_slab_interest_rates: [{ ...DEFAULT_SLAB_RULE }] }
        : {}),
    };
    onChange({ ...rateRule, [posting]: edited });
  };

  const handleCharge = (name: any, value: boolean) => {
    setOpen(value);
  };
  const getHeaderNameFromPostingType = (posting: any) => {
    if (posting === 'RETAIL_PRINCIPAL') return 'Non Cash';
    return 'Cash';
  };

  return (
    <>
      <VegaCard>
        <VegaText fw={'bold'} text={headerPrefix} />
        <Grid container my={1} bgcolor={BLUE.light} borderRadius={1}>
          <Grid item xs={12} sm={3} p={1}>
            <Typography>Position Type</Typography>
          </Grid>
          <Grid item xs={12} sm={3} p={1}>
            <Typography>Interest Rate Value Type</Typography>
          </Grid>
          <Grid item xs={12} sm={3} p={1}>
            <Typography>Default APR</Typography>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
        {POSTING_CATEGORIES.map((posting) => {
          const rule = rateRule?.[posting];
          return (
            <Box
              sx={{
                px: 1,
                borderRadius: '5px',
              }}
            >
              <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={3}>
                  <VegaOutLineTextField
                    key={posting}
                    value={getHeaderNameFromPostingType(posting)}
                    readOnly
                    textFieldWidth={150}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box sx={{ width: '200px' }}>
                    <VegaSelectItem
                      data={RATE_TYPES}
                      disabled={disabled}
                      value={
                        rule !== undefined && rule['interest_rate_value_type']
                      }
                      onChange={(e: any) =>
                        handleChange(
                          posting,
                          'interest_rate_value_type',
                          e.target.value
                        )
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <VegaOutLineTextField
                    value={rule !== undefined && rule['default_rate']}
                    onChange={(e) => {
                      handleChange(
                        posting,
                        'default_rate',
                        e.target.value && parseFloat(e.target.value)
                      );
                    }}
                    size="small"
                    key={`default_rate`}
                    type="number"
                    textFieldWidth={150}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              {rule && (
                <Box sx={{ mx: 2 }}>
                  <Collapse
                    in={
                      rule !== undefined &&
                      rule['interest_rate_value_type'] === 'AMOUNT_SLAB_BASED'
                    }
                    mountOnEnter
                    unmountOnExit
                  >
                    <InterestSlabSelector
                      slabs={rule['amount_slab_interest_rates']}
                      onChange={(slabs) =>
                        handleChange(
                          posting,
                          'amount_slab_interest_rates',
                          slabs
                        )
                      }
                    />
                  </Collapse>
                </Box>
              )}
            </Box>
          );
        })}
        <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
          <Checkbox
            disabled={disabled}
            onChange={(e: any) => handleCharge(headerPrefix, e.target.checked)}
            sx={{
              color: GREY.dark,
              '&.Mui-checked': {
                color: GREEN.dark,
              },
            }}
          />
          <VegaText text="Interest Charge on Fees" />
        </Box>
        {open &&
          POSTING_CATEGORIES.map((posting) => {
            console.log('posting :>> ', posting);
            const rule = rateRule?.[posting];

            return (
              <Box
                sx={{
                  px: 1,
                  borderRadius: '5px',
                }}
              >
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={12} sm={3}>
                    <VegaOutLineTextField
                      key={posting}
                      value={getHeaderNameFromPostingType(posting)}
                      readOnly
                      textFieldWidth={150}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Box sx={{ width: '200px' }}>
                      <VegaSelectItem
                        data={RATE_TYPES}
                        value={rule['interest_rate_value_type']}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <VegaOutLineTextField
                      value={rule['default_rate']}
                      size="small"
                      key={`default_rate`}
                      type="text"
                      textFieldWidth={150}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </VegaCard>
    </>
  );
};

export default InterestPolicyCard;
