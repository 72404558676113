/* eslint-disable react/jsx-pascal-case */
import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPresignedUrl } from '../../../api/card';
import { VegaToolTipAndCopy } from '../../../components/common/VegaToolTipNCopy';
import { toLowerCase } from '../../../constants/commonFunction';
import { useClientAuth } from '../../../providers/ClientProvider';
import { CardBlockingStatus, CardRejectOrderDto } from '../../../types/card';
import { downloadFileFromURI } from '../../../utils/file';
import { CardAllocationService } from '../../apis';
import { CIMS_PRIMARY } from '../../assets/style';
import CIMS_DataGrid from '../../components/common/CIMS_DataGrid';
import CIMS_Text from '../../components/common/CIMS_Text';
import CimsButton from '../../components/common/CimsButton';

interface IProps {
  setRefetchList: any;
}
const ListOfBulkCardReject = ({ setRefetchList }: IProps) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(0);

  const { clientId, userDivisionId } = useClientAuth();
  // fetch print list
  const bulkRejectListQuery = useQuery({
    queryKey: [
      'bulk_reject_list',
      { page, pageSize, userDivisionId, clientId },
    ],
    queryFn: () => {
      return CardAllocationService.listBulkReject({
        clientId: clientId || '',
        divisionId: userDivisionId ?? '',
        page: page,
        size: pageSize,
      });
    },
    enabled: !!clientId && !!userDivisionId,
    keepPreviousData: true,
  });

  const COLUMN_DEF: GridColumns<CardRejectOrderDto> = [
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => {
        return dayjs(props.row.createdAt).format('DD/MM/YYYY');
      },
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    },
    {
      field: 'id',
      headerName: 'Request Id',
      flex: 2,
      minWidth: 200,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    },
    {
      field: 'createdBy',
      headerName: 'uploadedBy',
      flex: 2,
      minWidth: 200,
      valueGetter: (props) => props.row.createdBy,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    },
    {
      field: 'reportFileName',
      headerName: 'File Name',
      flex: 2.5,
      minWidth: 250,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    },
    {
      field: 'totalKitEntries',
      headerName: 'Total Entries',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => props.row.totalKitEntries,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : 0,
    },

    {
      field: 'successRejectCount',
      headerName: 'Success',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => props.row.successRejectCount,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : 0,
    },
    {
      field: 'failureCount',
      headerName: 'Failure',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => props.row.failureCount,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : 0,
    },
    // {
    //   field: 'createdBy',
    //   headerName: 'Uploaded By',
    //   flex: 1.8,
    //   minWidth: 180,
    //   renderCell: (props) =>
    //     props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    // },
    // {
    //   field: 'sourceFile',
    //   headerName: 'Source File',
    //   flex: 1.5,
    //   minWidth: 150,
    //   renderCell: (props) => {
    //     return (
    //       <CimsButton
    //         onClick={async () => {
    //           const url = await getPresignedUrl(props.row.embossingFileUrl);
    //           downloadFileFromURI(url.data, 'embossing_file');
    //         }}
    //         variant="text"
    //       >
    //         Source File
    //       </CimsButton>
    //     );
    //   },
    // },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => {
        return props.row.status === CardBlockingStatus.SUCCESS ? (
          <CimsButton
            onClick={async () => {
              const url = await getPresignedUrl(props.row.reportFileUrl);
              downloadFileFromURI(url.data, 'report_file');
            }}
            variant="soft"
            disabled={!props.row.reportFileUrl}
          >
            Download
          </CimsButton>
        ) : (
          <CIMS_Text
            text={toLowerCase(props.row.status)}
            color={CIMS_PRIMARY.ORANGE}
          />
        );
      },
    },
  ];
  useEffect(() => {
    setRefetchList(() => bulkRejectListQuery.refetch);

    return () => {
      setRefetchList(null);
    };
  }, []);

  return (
    <>
      <CIMS_DataGrid
        data={bulkRejectListQuery.data?.records || []}
        columns={COLUMN_DEF}
        idColumn={'id'}
        loading={
          bulkRejectListQuery?.isLoading || bulkRejectListQuery.isPreviousData
        }
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        page={page}
        onPageChange={(page) => {
          setPage(page);
        }}
        rowCount={bulkRejectListQuery.data?.totalItems || 0}
        paginationMode="server"
      />
    </>
  );
};

export default ListOfBulkCardReject;
