import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaLabel from '../../../../components/common/VegaLabel';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import { IPLanTypeProps } from '../../../types/plan';
import { ISectionProps } from '../../pages/MCCUserManagement';
import BalanceView from './Components/BalanceView';
import CardSummary from './Components/CardSummary';
import CustomerProfile from './Components/CustomerProfile';
import WalletSummary from './Components/WalletSummary';

import { GridColDef } from '@mui/x-data-grid';

import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import CustomerService from '../../../../services/CustomerService';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { getCustomerName } from '../../../../utils/common.utils';
import { addPlanAllocation } from '../../../Program-Dashboard/api/PlanAllocation';
import { addPlan } from '../../../Program-Dashboard/api/PlanPolicies';
import { IConsolidatedAccountData } from '../../../types/Commons';
import { dateFormat } from '../../../variable';

interface IProps {
  active: ISectionProps;
  customerData: IConsolidatedAccountData[];
  loading: boolean;
  getCustomerList: () => void;
}

export interface ICurrencyProps {
  currency: string;
  priority: number;
}

const DEFUALT_PLAN = {
  clientId: '0b9fafc9-0f42-4fb9-89d4-10dccd80c643',
  planName: '',
  id: '',
  planType: 'WALLET_CONFIGURATION_PLAN',
  planDescription: 'planDescription',
  isDefault: true,
  effectiveStartTime: '',
  effectiveEndTime: '',
  status: 'ACTIVE',
  waterfallAllowed: false,
} as IPLanTypeProps;

const ListOfUserMenagement = ({
  active,
  loading,
  customerData,
  getCustomerList,
}: IProps) => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openBalance, setOpenBalance] = useState<boolean>(false);
  const [showData, setShowData] = useState<IConsolidatedAccountData | null>(
    null
  );
  const [list, setList] = useState<ICurrencyProps[]>([]);
  const [planData, setPlanData] = useState<IPLanTypeProps>({ ...DEFUALT_PLAN });
  const [newPlanData, setNewPlanData] = useState<IPLanTypeProps>({ ...DEFUALT_PLAN });
  const [showCardData, setShowCardData] = useState<any>({});

  const CUSTOMER_PROFILE_COLUMN: GridColDef<IConsolidatedAccountData>[] = [
    {
      field: 'name',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Customer Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return <Typography>{getCustomerName(props.row.customer)}</Typography>;
      },
    },

    {
      field: 'mobileNumber',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Mobile
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props) => {
        return <Typography>{props?.row?.customer?.mobileNumber}</Typography>;
      },
    },
    {
      field: 'emailId',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Email
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return <Typography>{props?.row?.customer?.emailId}</Typography>;
      },
    },
    {
      field: 'identity',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Identity
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props) => {
        return (
          <Typography>{props?.row?.customer?.identity[0]?.idNumber}</Typography>
        );
      },
    },
    {
      field: 'dob',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          DOB
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props) => {
        return <Typography>{props?.row?.customer?.dob}</Typography>;
      },
    },

    // {
    //   field: 'roles',
    //   renderHeader : () => <Typography variant="body2"  className="font-aspekta-500">Alloted Roles</Typography>,
    //   flex: 1,
    //   renderCell: (props) => {
    //     return (
    //       <Box
    //         sx={{
    //           border: BORDER_GRAY,
    //           borderRadius: '5px',
    //           // p: 0.5,
    //           width: '100%',
    //         }}
    //       >
    //         {props.row.role.map((role: any) => (
    //           <VegaLabel text={role} color={'primary'} />
    //         ))}
    //       </Box>
    //     );
    //   },
    // },

    {
      field: 'action',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Details
        </Typography>
      ),
      renderCell: (props) => {
        return (
          <VegaContainedButton
            text="edit"
            onClick={() => showDetails(props.row)}
            isPrimary
          />
        );
      },
      flex: 0.7,
    },
  ];
  const CARD_SUMMARY_COLUMN: GridColDef[] = [
    {
      field: 'customerId',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Customer Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return <Typography>{props?.row?.customer.firstName}</Typography>;
      },
    },
    {
      field: 'cardNo',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Card No
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={props?.row?.card?.cardNumber ?? '--'}
            color="primary"
          />
        );
      },
    },

    {
      field: 'expiryDate',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Card Expiry
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={props?.row?.card?.expiryDate ?? ''}
            color="primary"
          />
        );
      },
    },
    {
      field: 'status',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Card Status
        </Typography>
      ),
      flex: 0.6,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={toLowerCase(props?.row?.card?.cardStatus ?? '')}
            color="primary"
          />
        );
      },
    },
    {
      field: 'accountId',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Account Id
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return <Typography>{props?.row?.account.id}</Typography>;
      },
    },
    {
      field: 'activationDate',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Activation Date
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={dateFormat(props?.row?.card?.createdAt ?? '')}
            color="primary"
          />
        );
      },
    },
    {
      field: 'cardNetwork',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Card Network
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        const networks = props.row.card?.cardNetwork;
        if (!networks || networks.length <= 0) {
          return;
        }
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      },
    },

    {
      field: 'Action',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Action
        </Typography>
      ),
      renderCell: (props) => {
        return (
          <VegaContainedButton
            text="view/ edit"
            onClick={() => showDetails(props.row)}
            isPrimary
          />
        );
      },
      flex: 0.7,
    },
  ];
  const WALLET_SUMMARY_COLUMN: GridColDef[] = [
    {
      field: 'customerId',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Customer Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return <Typography>{props?.row?.customer.firstName}</Typography>;
      },
    },
    {
      field: 'cardNo',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Card No
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={props?.row?.card?.cardNumber ?? '--'}
            color="primary"
          />
        );
      },
    },

    {
      field: 'expiryDate',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Card Expiry
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={props?.row?.card?.expiryDate ?? ''}
            color="primary"
          />
        );
      },
    },
    {
      field: 'status',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Card Status
        </Typography>
      ),
      flex: 0.6,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={toLowerCase(props?.row?.card?.cardStatus ?? '')}
            color="primary"
          />
        );
      },
    },
    {
      field: 'accountId',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Account Id
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return <Typography>{props?.row?.account.id}</Typography>;
      },
    },
    {
      field: 'activationDate',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Activation Date
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={dateFormat(props?.row?.card?.createdAt ?? '')}
            color="primary"
          />
        );
      },
    },
    {
      field: 'cardNetwork',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Card Network
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        const networks = props.row.card?.cardNetwork;
        if (!networks || networks.length <= 0) {
          return;
        }
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      },
    },
    {
      field: 'balance',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          {' '}
          Balance
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        return (
          <VegaOutlineButton
            text="view"
            onClick={() => showBalance(props.row)}
            isPrimary
          />
        );
      },
    },

    {
      field: 'wallet config',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Action
        </Typography>
      ),
      renderCell: (props) => {
        return (
          <VegaContainedButton
            text="view/ edit"
            onClick={() => showDetails(props.row)}
            isPrimary
          />
        );
      },
      flex: 0.7,
    },
  ];

  const showDetails = async (data: IConsolidatedAccountData) => {
    setOpen(!open);
    console.log('data', data);
    setShowData(data);
  };
  const showBalance = (data: any) => {
    setOpenBalance(!openBalance);
    setShowData(data);
  };

  const onClose = () => {
    setOpen(!open);
  };
  const onCloseBalance = () => {
    setOpenBalance(!openBalance);
  };

  const submitWallet = async (wallet: IPLanTypeProps) => {
    try {
      const newPlan = await addPlan(clientId as string, wallet);
      setSnackbar('Add Plan!');
        setIsLoading(false);
        setNewPlanData(newPlan.data);
      return newPlan;
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to add Plan'),
        'error'
      );
      setIsLoading(false);
    }
  };

  const submitPlanAllocation = (plan: any) => {
    addPlanAllocation(plan)
      .then((res) => {
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const onWalletUpdate = async () => {
    const walletList = list.map((value: ICurrencyProps, index: number) => ({
      currency: value.currency,
      priority: index,
    }));
    const newObj = { ...planData, walletList };
    const newPlanResponse = await submitWallet(newObj);
    const obj = {
      planId: newPlanResponse?.data?.id ?? '',
      planType: newPlanResponse?.data?.planType,
      entityType: 'ACCOUNT',
      entityId: showData?.account?.id,
    };
    console.log('newObj', newObj);
    console.log('onSubmit===>', obj, showData?.account?.id);
    await submitPlanAllocation(obj);
    getCustomerList();
    onClose();
  };
  console.log('showData', showData);
  console.log('showData', customerData);
  const onSubmit = () => {};

  const OnCardProfile = () => {
    showData &&
      CustomerService.updateCustomerInfo({
        customerId: showData.customer?.customerId || '',
        emailId: showData.customer?.emailId,
        mobileNumber: showData.customer?.mobileNumber,
      })
        .then((res) => {
          setSnackbar('Update Successfully!');
          getCustomerList();
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Failed to update'),
            'error'
          );
        });
    onClose();
  };
  const onChange = (name: string, value: any) => {
    setShowData((pre) => {
      return pre
        ? {
            ...pre,
            customer: pre.customer
              ? {
                  ...pre.customer,
                  [name]: value,
                }
              : null,
          }
        : null;
    });
  };

  const getValue = (id: string) => {
    if (id === 'CARD_SUMMARY') {
      return {
        column: CARD_SUMMARY_COLUMN,
        header: 'Card Details',
        component: <CardSummary state={showData} handleChange={onChange} />,
        idColumn: 'id',
        data: customerData.filter((val: any) => val.card !== null),
        onSubmit,
      };
    } else if (id === 'WALLET_SUMMARY') {
      return {
        column: WALLET_SUMMARY_COLUMN,
        header: 'Wallet Details',
        component: (
          <WalletSummary
            state={showData}
            handleChange={onChange}
            setState={setList}
            listState={list}
            setPlanData={setPlanData}
          />
        ),
        idColumn: 'id',
        data: customerData.filter((val: any) => val.card !== null),
        onSubmit: onWalletUpdate,
      };
    } else {
      return {
        column: CUSTOMER_PROFILE_COLUMN,
        header: 'Customer Details',
        component: <CustomerProfile state={showData} handleChange={onChange} />,
        idColumn: 'id',
        data: customerData,
        onSubmit: OnCardProfile,
      };
    }
  };
  console.log(
    'customerData.filter((val: any) => val.card !== null)',
    customerData.filter((val: any) => val.card !== null)
  );
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={getValue(active.id).data}
        columnDef={getValue(active.id).column}
        idColumn={getValue(active.id).idColumn}
        loading={loading}
      />
      <VegaDrawer
        open={open}
        isClose
        isSubmit
        header={getValue(active.id).header}
        closeText={'Cancel'}
        submitText={'Submit'}
        onClose={onClose}
        onSubmit={getValue(active.id).onSubmit}
      >
        {getValue(active.id).component}
      </VegaDrawer>
      <VegaDrawer
        open={openBalance}
        isClose
        isSubmit
        header={'Balance Details'}
        closeText={'Cancel'}
        submitText={'Submit'}
        onClose={onCloseBalance}
        onSubmit={onSubmit}
      >
        <BalanceView state={showData} handleChange={onChange} />
      </VegaDrawer>
    </VegaCard>
  );
};

export default ListOfUserMenagement;
