import Stack from '@mui/material/Stack';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardAllotmentOrderDto,
  CardAllotmentStatusEnum,
} from '../../../../types/card';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { CardAllocationService } from '../../../apis';
import CimsButton from '../../../components/common/CimsButton';
import MarkAsShippedModal from '../../AssignCards/List/MarkAsShippedModal';
import { useCardOrderRequest } from '../context';
import { CardOrderRequestAction } from './columns';
import MarkAsLost from '../../InventoryManagement/CardAllotment/MarkAsLost';

export const PendingOrderRenderActions = ({
  data,
  triggerAction,
}: {
  data: CardAllotmentOrderDto;
  triggerAction: (
    action: CardOrderRequestAction,
    data: CardAllotmentOrderDto
  ) => void;
}) => {
  const { allotmentStatus } = data;
  const { setSnackbar } = useSnackbar();
  const { listAllotmentQuery } = useCardOrderRequest();
  const onSubmit = (shipmentData: {
    shipment: {
      mailerVendorId: string;
      trackingId: string;
    };
  }) => {
    if (data) {
      CardAllocationService.updateAllocationOrder({
        cardAllotmentOrderId: data.id,
        mailerVendorId: shipmentData.shipment?.mailerVendorId,
        mailerVendorTrackingId: shipmentData.shipment.trackingId,
      });
      data &&
        CardAllocationService.updateAllocationStatus({
          cardAllotmentOrderId: data?.id,
          status: CardAllotmentStatusEnum.DISPATCHED,
        })
          .then((res) => {
            listAllotmentQuery?.refetch();
            setSnackbar('Mark as shipped successfully');
          })
          .catch((err) => {
            setSnackbar(
              getErrorMessageFromErrorObj(
                err,
                'Error while updating status (Shipped)'
              ),
              'error'
            );
          });
    }
  };

  if (allotmentStatus === CardAllotmentStatusEnum.REQUESTED) {
    return (
      <Stack direction="row" spacing={1}>
        <CimsButton
          variant="soft"
          color="success"
          onClick={() =>
            triggerAction(CardOrderRequestAction.ApproveRequest, data)
          }
        >
          Approve
        </CimsButton>
        <CimsButton
          color="error"
          variant="soft"
          onClick={() =>
            triggerAction(CardOrderRequestAction.RejectRequest, data)
          }
        >
          Reject
        </CimsButton>
      </Stack>
    );
  } else if (allotmentStatus === CardAllotmentStatusEnum.APPROVED) {
    return (
      // <CimsButton
      //   onClick={() =>
      //     triggerAction(CardOrderRequestAction.MarkAsShipped, data)
      //   }
      //   variant="soft"
      // >
      //   Mark as shipped
      // </CimsButton>
      <MarkAsShippedModal onSubmit={onSubmit} />
    );
  }

  if (allotmentStatus === CardAllotmentStatusEnum.DISPATCHED) {
    return (
      <CimsButton
        variant="soft"
        color="secondary"
        onClick={() =>
          triggerAction(CardOrderRequestAction.MarkAsDelivered, data)
        }
      >
        Mark As Delivered
      </CimsButton>
    );
  }
  return <>-</>;
};

export const RequestCardsRenderActions = ({
  data,
  triggerAction,
}: {
  data: CardAllotmentOrderDto;
  triggerAction: (action: CardOrderRequestAction, data: any) => void;
}) => {
  const { setSnackbar } = useSnackbar();
  const { listAllotmentQuery } = useCardOrderRequest();
  const { allotmentStatus } = data;

  const handleMarkAsDelivered = () => {
    CardAllocationService.updateAllocationStatus({
      cardAllotmentOrderId: data.id,
      status: CardAllotmentStatusEnum.DELIVERED,
    })
      .then(() => {
        setSnackbar('The card has been delivered ', 'success');
        CardAllocationService.updateAllocationStatus({
          cardAllotmentOrderId: data.id,
          status: CardAllotmentStatusEnum.ACCEPTED,
        })
          .then(() => {
            listAllotmentQuery?.refetch();
            setSnackbar('The card has been accepted ', 'success');
          })
          .catch(() => {
            listAllotmentQuery?.refetch();
            setSnackbar('Failed to accept the card', 'error');
          });
      })
      .catch(() => {
        setSnackbar('Failed to update status as Delivered', 'error');
      });
  };

  const handleMarkAsLost = () => {
    CardAllocationService.updateAllocationStatus({
      cardAllotmentOrderId: data.id,
      status: CardAllotmentStatusEnum.NOT_DELIVERED,
    })
      .then(() => {
        listAllotmentQuery?.refetch();
        setSnackbar('The card has been mark as not delivered ', 'success');
      })
      .catch(() => {
        listAllotmentQuery?.refetch();
        setSnackbar('Failed to update status as not delivered', 'error');
      });
  };

  if (allotmentStatus === CardAllotmentStatusEnum.REQUESTED) {
    return (
      <CimsButton
        variant="soft"
        onClick={() => {
          triggerAction(CardOrderRequestAction.RevokeRequest, data);
        }}
      >
        Revoke Request
      </CimsButton>
    );
  } else if (allotmentStatus === CardAllotmentStatusEnum.DELIVERED) {
    return (
      <Stack direction={'row'} spacing={2}>
        <MarkAsLost data={data} onSubmit={handleMarkAsLost} />
        <CimsButton
          variant="soft"
          onClick={() => {
            triggerAction(CardOrderRequestAction.Acknowledge, data);
          }}
        >
          Acknowledge
        </CimsButton>
      </Stack>
    );
  }
  // else if (allotmentStatus === CardAllotmentStatusEnum.DELIVERED) {
  //   return (
  //     <CimsButton
  //       onClick={() =>
  //         triggerAction(CardOrderRequestAction.MarkAsShipped, data)
  //       }
  //       variant="soft"
  //     >
  //       Mark as shipped
  //     </CimsButton>
  //   );
  // }
  return <>-</>;
};
