import { Box, CircularProgress, ThemeProvider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { SidebarListItemType } from '../../components/layouts/SidebarLayout';

import { createSearchParams, useSearchParams } from 'react-router-dom';
import CIMS_Login from '../../cims/components/LoginContainer/CIMS_Login';
import { CIMS_THEME } from '../../cims/theme/theme';
import CIMSSidebarLayout from '../../components/layouts/CIMSSidebarLayout';
import { useClientAuth } from '../../providers/ClientProvider';
import { UserAllResourceAccess } from '../../types/user';
import { CIMS_SUB_MODULE_ROUTES } from './CimsRoutesNew';
import { checkResourceAccess } from '../../utils/userAccessUtils';

const getFilteredModules = (
  resourceAccess?: UserAllResourceAccess[] | null | undefined
) => {
  return CIMS_SUB_MODULE_ROUTES.filter((route) => {
    if (route.children && route.children.length > 0) {
      return route.children.some((subRoute) =>
        checkResourceAccess({
          name: subRoute.resourceName ?? '',
          accessLevel: 'module',
          resourceAccess,
          checkAccessForAnyProgram: true,
        })
      );
    }

    return checkResourceAccess({
      name: route.resourceName ?? '',
      accessLevel: 'module',
      resourceAccess,
      checkAccessForAnyProgram: true,
    });
  });
};

const CimsContainer = () => {
  const { isAuth, user } = useClientAuth();

  const [_, setSearchParams] = useSearchParams();
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    null
  );

  const modules = useMemo(
    () => getFilteredModules(user?.allResourceAccess),
    [user?.allResourceAccess]
  );

  useEffect(() => {
    if (!selectedItem && modules.length > 0) {
      setSelectedItem(modules[0]);
      setSearchParams(
        createSearchParams({
          view: modules[0].id,
        })
      );
    }
  }, [modules]);

  return (
    <Box sx={{ height: '100%' }}>
      <ThemeProvider theme={CIMS_THEME}>
        {selectedItem ? (
          <CIMSSidebarLayout
            sidebarListItems={modules}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
        ) : isAuth ? (
          // <div>Not Authorized</div>
          // temporary redirect to login
          <CIMS_Login />
        ) : (
          <CircularProgress />
        )}
      </ThemeProvider>
    </Box>
  );
};

export default CimsContainer;
