/** @format */

import { Typography } from '@mui/material';
import React, { SetStateAction } from 'react';
import { GREY, NEW_PRIMARY } from '../../constants/style';
import { ITileDataProps } from '../../pages/Resources';
import { VegaCard } from '../common';
import VegaHorizontalSelector from '../common/VegaHorizontalSelector';
import Vega_Text from '../common/v3/Vega_Text';
import Vega_HeaderWrapper from '../common/v3/Vega_HeaderWrapper';
interface IResourcesProps {
  data: ITileDataProps[];
  active: any;
  setState: React.Dispatch<SetStateAction<ITileDataProps>>;
}

const ResourcesSection = ({ data, setState, active }: IResourcesProps) => {
  return (
    <VegaCard>
      <Vega_HeaderWrapper
        text={`Resources`}
        subText={'All resources which are used in the Vegapay ecosystem'}
      />

      <VegaHorizontalSelector
        hideScroll
        data={data}
        selected={active}
        onChange={(selected: any) => {
          setState(selected);
        }}
        ignoreCaseForLodash={['MCC', 'MCG']}
      />
    </VegaCard>
  );
};

export default ResourcesSection;
