import React from 'react';
import {
  Stack,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { VegaTextField } from '../../../common';
import { GREY } from '../../../../constants/style';
import { dateFormat } from '../../../../mcc/variable';
import { useApplicationManagmentConfig } from '../../../../providers/ApplicationManagmentProvider';

interface IBasicDetailsProps {
  lable: string;
  value: string | number | null;
  disable?: boolean;
}

const BasicDetails = () => {
  const { customer } = useApplicationManagmentConfig();
  const DOB = customer?.dob ? new Date(customer?.dob as number) : new Date();

  const CUSTOMER_DETAILS: IBasicDetailsProps[] = [
    {
      lable: 'Customer Name',
      value: getText(customer?.fullName),
      disable: true,
    },
    {
      lable: 'Customer ID',
      value: getText(customer?.customerId),
      disable: true,
    },
    {
      lable: 'Mobile No',
      value: getText(customer?.mobileNumber),
      disable: true,
    },
    {
      lable: 'Customer Email',
      value: getText(customer?.emailId),
      disable: true,
    },
    {
      lable: 'Gender',
      value: getText(customer?.gender),
      disable: true,
    },
    {
      lable: 'Date of Birth',
      value: dateFormat(DOB),
      disable: true,
    },
    {
      lable: 'Education',
      value: 'BSc Economics',
      disable: true,
    },
  ];
  const CURRENT_ADDRESS_DETAILS: IBasicDetailsProps[] = [
    {
      lable: 'Line 1',
      value: getText(customer?.currentAddress.line1),
      disable: true,
    },
    {
      lable: 'Line 2',
      value: getText(customer?.currentAddress.line2),
      disable: true,
    },

    {
      lable: 'Landmark',
      value: getText(customer?.currentAddress.line1),
      disable: true,
    },
    {
      lable: 'Pincode',
      value: getText(customer?.currentAddress.pincode),
      disable: true,
    },
    {
      lable: 'State',
      value: getText(customer?.currentAddress.state),
      disable: true,
    },
    {
      lable: 'City',
      value: getText(customer?.currentAddress.city),
      disable: true,
    },
  ];
  const PERMANENT_ADDRESS_DETAILS: IBasicDetailsProps[] = [
    {
      lable: 'Line 1',
      value: getText(customer?.permanentAddress.line1),
      disable: true,
    },
    {
      lable: 'Line 2',
      value: getText(customer?.permanentAddress.line2),
      disable: true,
    },

    {
      lable: 'Landmark',
      value: getText(customer?.permanentAddress.line1),
      disable: true,
    },
    {
      lable: 'Pincode',
      value: getText(customer?.permanentAddress.pincode),
      disable: true,
    },
    {
      lable: 'State',
      value: getText(customer?.permanentAddress.state),
      disable: true,
    },
    {
      lable: 'City',
      value: getText(customer?.permanentAddress.city),
      disable: true,
    },
  ];
  return (
    <>
      <Box pt={1} pb={3}>
        <Grid container spacing={3}>
          {CUSTOMER_DETAILS.map((customer: IBasicDetailsProps) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <VegaTextField
                key={customer.lable}
                label={customer.lable}
                value={customer.value}
                disabled={customer.disable}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <Typography
          className="font-opensans-700"
          fontSize={14}
          color={GREY.darkest}
        >
          Current Address
        </Typography>
        <Box pt={2} pb={3}>
          <Grid container spacing={3}>
            {CURRENT_ADDRESS_DETAILS.map((customer: IBasicDetailsProps) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <VegaTextField
                  key={customer.lable}
                  label={customer.lable}
                  value={customer.value}
                  disabled={customer.disable}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box>
        <Typography
          className="font-opensans-700"
          fontSize={14}
          color={GREY.darkest}
        >
          Permanent Address
        </Typography>
        <Box pt={2} pb={3}>
          <Grid container spacing={3}>
            {PERMANENT_ADDRESS_DETAILS.map((customer: IBasicDetailsProps) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <VegaTextField
                  key={customer.lable}
                  label={customer.lable}
                  value={customer.value}
                  disabled={customer.disable}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default BasicDetails;

const getText = (text: string | number | null | undefined) => {
  if (!text) return '--';

  if (text === 'M') {
    return 'Male';
  } else if (text === 'F') {
    return 'Female';
  } else {
    return text;
  }
};
