import { Box, Typography, Button } from '@mui/material';

import { BACKGROUND, GREEN } from '../../../../constants/style';
import {
  BulkUploadIcon,
  UploadFIleIcon,
  UploadIcon,
} from '../../../icon/Icons';

export function DropZoneView(props: { dragActive: any; onButtonClick: any }) {
  return (
    <label
      id="label-file-upload"
      htmlFor="input-file-upload"
      className={props.dragActive ? 'drag-active' : ''}
    >
      <div className="p-2">
        <Box className="">
          <UploadFIleIcon />
          <Typography className="">
            {props.dragActive ? 'Drag here' : 'Drag and Drop File'}
          </Typography>
        </Box>
        <Typography sx={{ my: 2 }}>OR</Typography>
        <Button
          sx={{
            bgcolor: BACKGROUND.PURPLE,
            color: 'white',
            ':hover': { bgcolor: BACKGROUND.PURPLE },
            textTransform: 'capitalize',
          }}
          startIcon={<UploadIcon />}
          onClick={props.onButtonClick}
        >
          Upload a Document
        </Button>
      </div>
    </label>
  );
}
