import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { VPButton } from '../../../../ui-components/common';
import { AquirerAccepts } from './AquirerAccepts';
import { AquirerRejects } from './AquirerRejects';

enum RESOLUTION {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}
export default function ResolutionMenu() {
  const [selectedResolution, setSelectedResolution] =
    React.useState<RESOLUTION>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <VPButton
        id="resolution-menu-button"
        aria-controls={open ? 'resolution-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Resolution
      </VPButton>
      <Menu
        id="resolution-menu"
        MenuListProps={{
          'aria-labelledby': 'resolution-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setSelectedResolution(RESOLUTION.ACCEPTED);
            handleClose();
          }}
        >
          Acquirer Accepted Chargeback
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedResolution(RESOLUTION.REJECTED);
            handleClose();
          }}
        >
          Acquirer Rejected Chargeback
        </MenuItem>
      </Menu>
      <>
        <AquirerAccepts
          isOpen={selectedResolution === RESOLUTION.ACCEPTED}
          handleClose={() => setSelectedResolution(undefined)}
        />
        <AquirerRejects
          isOpen={selectedResolution === RESOLUTION.REJECTED}
          handleClose={() => setSelectedResolution(undefined)}
        />
      </>
    </div>
  );
}
