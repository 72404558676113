import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { toLowerCase } from '../../constants/commonFunction';
import { BLUE, BORDER_BLUE, BORDER_GRAY } from '../../constants/style';
import { VegaCard, VegaTable } from '../common';
import VegaText from '../common/VegaText';
import { IAuditLogProps } from './ListOfCustomers';

interface IProps {
  state: IAuditLogProps[];
}

const AuditLogDetails = ({ state }: IProps) => {
  const columnDef = [
    {
      field: 'eventName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Event Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => (
        <Typography>{toLowerCase(props.row.eventName)}</Typography>
      ),
    },
    {
      field: 'fromValue',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          From Value
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => (
        <Typography>{toLowerCase(props.row.fromValue)}</Typography>
      ),
    },
    {
      field: 'toValue',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          To Value
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'fieldName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Field Name
        </Typography>
      ),
      flex: 1,
    },
  ];
  return (
    <Box sx={{ border: BORDER_GRAY, borderRadius: '5px' }}>
      <Grid container sx={{ bgcolor: BLUE.light }}>
        <Grid item xs={12} sm={3}>
          <Typography px={2} py={1}>
            Event Name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography px={2} py={1}>
            From Value
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography px={2} py={1}>
            {' '}
            To Value
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography px={2} py={1}>
            Field Name
          </Typography>
        </Grid>
      </Grid>
      {state.map((data: IAuditLogProps) => {
        return (
          <Grid container sx={{ borderBottom: BORDER_BLUE }}>
            <Grid item xs={12} sm={3}>
              <Typography fontSize={14} sx={{}} px={2}>
                {toLowerCase(data.eventName)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography fontSize={14} sx={{}} px={2}>
                {toLowerCase(data.fromValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography fontSize={14} px={2} sx={{ overflow: 'auto' }}>
                {data.toValue}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography fontSize={14} sx={{}} px={2}>
                {data.fieldName}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default AuditLogDetails;
