import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaLabel from '../../../../components/common/VegaLabel';
import { URLtoFile } from '../../../../constants/commonFunction';
import {
  CheckerMakerStatusEnum,
  CustomerIdentityTypes,
} from '../../../../enums/commons';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import CustomerService from '../../../../services/CustomerService';
import { ICustomer } from '../../../../types/customers';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { dateFormat } from '../../../variable';
import ViewCustomer from './ViewCustomer';

export interface ICustomerDetailsProps extends ICustomer {
  passportFile?: File;
  panFile?: File;
  kycFile?: File;
  ticketFile?: File;
  visaFile?: File;
  panUrl: string;
  aadhaarUrl: string;
  passportUrl: string;
  visaUrl: string;
}

interface IProps {
  data: ICustomer[];
  loading: boolean;
  getCustomerList: () => void;
}

export type IUploadCustomerDocs = {
  panFile: File | null;
  aadhaarFile: File | null;
  passportFile: File | null;
  visaFile: File | null;
};

const ListOfMCCcustomer = ({ data, loading, getCustomerList }: IProps) => {
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [customerDetails, setCustomerDetails] =
    useState<ICustomerDetailsProps | null>(null);
  const [open, setOpen] = useState<boolean>(true);
  const [status, setStatus] = useState<boolean>(false);
  const [customerDocs, setCustomerDocs] = useState<IUploadCustomerDocs>({
    panFile: null,
    aadhaarFile: null,
    passportFile: null,
    visaFile: null,
  });

  const handleDocsChange = (
    key: keyof IUploadCustomerDocs,
    value: File | null
  ) => {
    setCustomerDocs((pre) => ({
      ...pre,
      [key]: value,
    }));
  };
  const columnDef: GridColDef[] = [
    {
      field: 'firstName',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Name
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props) => {
        const fName = props.row.firstName !== null ? props.row.firstName : '';
        const mName = props.row.middleName !== null ? props.row.middleName : '';
        const lName = props.row.lastName !== null ? props.row.lastName : '';
        const customerName = fName + ' ' + mName + ' ' + lName;
        return <Typography>{customerName}</Typography>;
      },
    },
    // {
    //   field: 'afterTransactionBalance',
    //   renderHeader: () => <Typography className="font-aspekta-500" variant="body2">Balance After Txn</Typography>,

    //   flex: 0.7,
    // },
    {
      field: 'mobileNumber',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Mobile No
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'emailId',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Email ID
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'dob',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          DOB
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props) => {
        return (
          <VegaLabel text={dateFormat(props.row?.dob ?? '-')} color="primary" />
        );
      },
    },
    // {
    //   field: 'status',
    //   renderHeader: () => <Typography className="font-aspekta-500" variant="body2">Status</Typography>,
    //   flex: 0.7,
    //   renderCell: (props) => {
    //     return (
    //       <VegaLabel
    //         text={toLowerCase(props.row?.status ?? '-')}
    //         color="primary"
    //       />
    //     );
    //   },
    // },

    {
      field: 'action2',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props) => {
        return props.row?.status === 'DRAFT' ? (
          props.row?.corporateId ? (
            <VegaContainedButton
              text={'Draft in Corporate'}
              isPrimary
              disabled
            />
          ) : (
            <VegaContainedButton
              text={'Send to Checker'}
              onClick={() => sendChecker(props.row)}
              isPrimary
              px={2.3}
            />
          )
        ) : props.row?.status === 'UNDER_REVIEW' &&
          props.row?.makerCheckerStatus === 'CHANGES_REQUESTED' ? (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => updateChecker(props.row)}
            isPrimary
            px={3}
          />
        ) : props.row?.status === 'UNDER_REVIEW' &&
          props.row?.makerCheckerStatus === 'REVIEWER_PENDING' ? (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => updateChecker(props.row)}
            isPrimary
            px={3}
          />
        ) : props.row?.status === 'ACTIVE' &&
          props.row?.makerCheckerStatus === 'APPROVED' ? (
          <VegaContainedButton text="Approved" isSuccess disabled />
        ) : (
          <VegaContainedButton text="Sent to Checker" isPrimary disabled />
        );
      },
      flex: 0.7,
    },

    {
      field: 'action',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          View/Edit
        </Typography>
      ),
      renderCell: (props) => {
        if (props.row.corporateId) {
          return (
            <VegaContainedButton
              text="View"
              onClick={() => showDetails(props.row, true)}
              isPrimary
            />
          );
        }

        return props.row?.checkerMakerStatus === 'CHECKER_PENDING' ||
          (props.row?.status === 'ACTIVE' &&
            props.row?.checkerMakerStatus === 'APPROVED') ? (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(props.row, true)}
            isPrimary
          />
        ) : (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(props.row, false)}
            isPrimary
          />
        );
      },
      flex: 0.5,
    },
  ];

  const sendToReviwer = async (data: ICustomer) => {
    CustomerService.initiateCheckerMaker({
      userId: userId || '',
      entityId: data.customerId,
      initialStatus: CheckerMakerStatusEnum.REVIEWER_PENDING,
    })
      .then(() => {
        setSnackbar('Send Successfully!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send'),
          'error'
        );
      });
  };
  const sendChecker = async (data: ICustomer) => {
    CustomerService.initiateCheckerMaker({
      userId: userId || '',
      entityId: data.customerId,
    })
      .then((res) => {
        setSnackbar('Send Successfully!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send'),
          'error'
        );
      });
  };
  const updateChecker = async (data: ICustomer) => {
    CustomerService.updateCheckerMaker({
      userId: userId || '',
      entityId: data.customerId,
      status: CheckerMakerStatusEnum.CHECKER_PENDING,
    })
      .then((res) => {
        setSnackbar('Update status Successfully!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to update'),
          'error'
        );
      });
  };

  // const handleChecked = async (e: any, data: any) => {
  //   updateCustomerStatusForMCC({
  //     customerId: data.id,
  //     status: e.target.checked ? 'ACTIVE' : 'INACTIVE',
  //   })
  //     .then((res) => {
  //       setSnackbar('Change Status Successfully!');
  //       getCustomerList();
  //     })
  //     .catch((err) => {
  //       setSnackbar(
  //         getErrorMessageFromErrorObj(err, 'Failed to status change'),
  //         'error'
  //       );
  //     });
  // };

  const handleChange = (name: keyof ICustomerDetailsProps, value: any) => {
    // if (
    //   name === 'passportUrl' ||
    //   name === 'aadhaarUrl' ||
    //   name === 'panUrl' ||
    //   name === 'visaUrl'
    // ) {
    //   console.log('name,value', name, value);
    //   const file = value[0];
    //   const fileURL = URL.createObjectURL(file);
    //   file.fileURL = fileURL;

    //   setCustomerDetails((pre) => {
    //     if (pre) {
    //       return {
    //         ...pre,
    //         [name]: fileURL,
    //       };
    //     }
    //     return null;
    //   });
    // } else {
    //   setCustomerDetails((pre) => {
    //     return pre
    //       ? {
    //           ...pre,
    //           [name]: value,
    //         }
    //       : null;
    //   });
    // }

    setCustomerDetails((pre) => {
      return pre
        ? {
            ...pre,
            [name]: value,
          }
        : null;
    });
  };
  const showDetails = async (data: ICustomerDetailsProps, status: boolean) => {
    setStatus(status);

    // if (data.kycUrl) {
    //   data.kycUrl = (await getProgramDocsSignedUrl(data.kycUrl)).data;
    // }
    // if (data.panUrl) {
    //   data.panUrl = (await getProgramDocsSignedUrl(data.panUrl)).data;
    // }
    // if (data.passportUrl) {
    //   data.passportUrl = (await getProgramDocsSignedUrl(data.passportUrl)).data;
    // }
    // if (data.ticketUrl) {
    //   data.ticketUrl = (await getProgramDocsSignedUrl(data.ticketUrl)).data;
    // }

    data.identity.forEach((custIdentity) => {
      switch (custIdentity.idType) {
        case CustomerIdentityTypes.PAN:
          data.panUrl = custIdentity.url || '';
          break;
        case CustomerIdentityTypes.PASSPORT:
          data.passportUrl = custIdentity.url || '';
          break;
        case CustomerIdentityTypes.AADHAAR:
          data.aadhaarUrl = custIdentity.url || '';
          break;
      }
    });

    setCustomerDetails(data);
    setOpen(!open);
  };
  const decline = () => {
    setOpen(!open);
  };

  const convertBlob = async (url: any) => {
    if (url !== undefined) {
      if (url.includes('blob')) {
        const convetedURL = await URLtoFile(url);
        return convetedURL;
      } else {
        return false;
      }
    } else return false;
  };
  const OnUpdate = async () => {
    // const formData = new FormData();
    // const panFile = await convertBlob(customerDetails.panFile);
    // const kycFile = await convertBlob(customerDetails.kycFile);
    // const passportFile = await convertBlob(customerDetails.passportFile);
    // const newObj: any = {
    //   customerId: customerDetails.id,
    //   emailId: customerDetails.emailId,
    //   firstName: customerDetails.firstName,
    //   mobileNumber: customerDetails.mobileNumber,
    //   panFile,
    //   kycFile,
    //   passportFile,
    // };

    // if (newObj.panFile === false) {
    //   delete newObj['panFile'];
    // }
    // if (newObj.kycFile === false) {
    //   delete newObj['kycFile'];
    // }
    // if (newObj.passportFile === false) {
    //   delete newObj['passportFile'];
    // }
    // Object.keys(newObj).map((value: any) => {
    //   formData.append(value, newObj[value]);
    // });
    console.log('customerDetails', customerDetails);

    try {
      await CustomerService.updateCustomerInfo({
        customerId: customerDetails?.customerId || '',
        title: customerDetails?.title || '',
        firstName: customerDetails?.firstName || '',
        middleName: customerDetails?.middleName || '',
        lastName: customerDetails?.lastName || '',
        countryCode: customerDetails?.countryCode || '',
        mobileNumber: customerDetails?.mobileNumber || '',
        emailId: customerDetails?.emailId || '',
        dob: customerDetails?.dob || '',
        gender: customerDetails?.gender || '',
      });
      // .then((res) => {
      //   setSnackbar('Update Successfully!');
      //   getCustomerList();
      // })
      // .catch((err) => {
      //   setSnackbar(
      //     getErrorMessageFromErrorObj(err, 'Failed to update'),
      //     'error'
      //   );
      // });
      if (customerDetails) {
        await CustomerService.uploadCustomerDocs({
          customerId: customerDetails.customerId,
          panFile: customerDetails.panFile,
          passportFile: customerDetails.passportFile,
          kycFile: customerDetails.kycFile,
          ticketFile: customerDetails.ticketFile,
          visaFile: customerDetails.visaFile,
        });
      }
      setSnackbar('Update Successfully!');
    } catch (err) {
      setSnackbar('Error in customer update', 'error');
    } finally {
      setOpen(!open);
    }
  };
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={columnDef}
        idColumn={'customerId'}
        loading={loading}
      />
      <VegaDrawer
        open={open}
        header={'Customer Details'}
        closeText="Decline"
        isClose
        isSubmit={!status}
        submitText={!status && 'Save'}
        onClose={decline}
        onSubmit={OnUpdate}
      >
        <ViewCustomer
          customer={customerDetails}
          handleChange={handleChange}
          show={status}
          customerDocs={customerDocs}
          handleDocsChange={handleDocsChange}
        />
      </VegaDrawer>
    </VegaCard>
  );
};

export default ListOfMCCcustomer;
