import {
  CardRefundOrderStatus,
  CardRefundType,
  PaymentMode,
} from '../enums/card';
import { CheckerMakerStatusEnum } from '../enums/commons';
import { IConsolidatedAccountData } from '../mcc/types/Commons';

export enum Currency {
  INR = 'INR',
  USD = 'USD',
  EUR = 'EUR',
  AUD = 'AUD',
}

export interface ICardRefundOrder {
  id: string;
  accountId: string;
  sourceCurrency: Currency;
  destinationCurrency: Currency;
  amount: number;
  refundType: CardRefundType;
  paymentMode: PaymentMode;
  transactionTime: string;
  makerCheckerId: string;
  userId: string;
  cardRefundOrderStatus: CardRefundOrderStatus;
  makerCheckerStatus: CheckerMakerStatusEnum;
  branchId: string;
  createdAt: string;
  updatedAt: string;
  accountConsolidatedDto: IConsolidatedAccountData;
  [key: string]: any;
}

export interface CreateCardRefundOrderReq {
  accountId: string;
  branchId: string;
  clientId: string;
  sourceCurrency: string;
  destinationCurrency: string;
  amount: number;
  paymentMode: PaymentMode;
  refundType: CardRefundType;
  transactionTime: string;
}

export interface InitiateCardRefundMakerCheckerReq {
  cardRefundOrderId: string;
  userId: string;
}

export interface UpdateCardRefundOrderStatusRequest {
  cardRefundOrderId: string;
  status: CheckerMakerStatusEnum;
  userId: string;
}

export interface UpdateCardRefundOrderRequest {
  cardRefundOrderId: string;
  accountId: string;
  branchId: string;
  sourceCurrency: string;
  destinationCurrency: string;
  amount: number;
  paymentMode: PaymentMode;
  refundType: CardRefundType;
  transactionTime?: string;
  userId: string;
}

export interface ListCardRefundOrderRequest {
  accountId: string;
  branchId: string;
  clientId: string;
  cardRefundOrderStatusList: CardRefundOrderStatus[];
  makerCheckerStatusList: CheckerMakerStatusEnum[];
}
