/** @format */

import { VegaPage } from '../components/common';
import ListOfPrograms from '../components/program/ListOfPrograms';

type Props = {};

const CrmProgram = (props: Props) => {
  return (
    <VegaPage>
      <ListOfPrograms />
    </VegaPage>
  );
};

export default CrmProgram;
