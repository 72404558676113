import { PermIdentity } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import IdentityFrm from '../components/RiskAndFraudManagement/IdentityFrm';
import TransactionFrm from '../components/RiskAndFraudManagement/TransactionFrm';
import { VegaCard, VegaPage } from '../components/common';
import VegaHorizontalSelector from '../components/common/VegaHorizontalSelector';
import Vega_HeaderWrapper from '../components/common/v3/Vega_HeaderWrapper';
import { TransactionIcon } from '../components/icon/Icons';

enum TabType {
  IDENTITY_FRM = 'Identity FRM',
  TRANSACTION_FRM = 'Transaction FRM',
}

interface TabProp {
  text: string;
  id: string;
  bgColor: string;
  image: JSX.Element;
}

let TABS: TabProp[] = [
  {
    text: TabType.IDENTITY_FRM,
    id: TabType.IDENTITY_FRM,
    image: <PermIdentity />,
    bgColor: 'transparent',
  },
  {
    text: TabType.TRANSACTION_FRM,
    id: TabType.TRANSACTION_FRM,
    image: <TransactionIcon />,
    bgColor: 'transparent',
  },
];

function FraudAndRiskManagement() {
  const [selectedTab, setSelectedTab] = useState<TabProp>(TABS[0]);

  useEffect(() => {
    setSelectedTab(TABS[0]);
  }, []);

  return (
    <VegaPage>
      <VegaCard>
        <Vega_HeaderWrapper text="Fraud & Risk Management" />

        <VegaHorizontalSelector
          hideScroll
          data={TABS}
          selected={selectedTab}
          ignoreCaseForLodash={[TabType.IDENTITY_FRM, TabType.TRANSACTION_FRM]}
          onChange={(selected: any) => {
            setSelectedTab(selected);
          }}
        />
      </VegaCard>
      {selectedTab.id == TabType.IDENTITY_FRM && <IdentityFrm />}
      {selectedTab.id == TabType.TRANSACTION_FRM && <TransactionFrm />}
    </VegaPage>
  );
}

export default FraudAndRiskManagement;
