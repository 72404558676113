/* eslint-disable react/jsx-pascal-case */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import VegaHeader from '../../../../components/common/VegaHeader';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { EditFormFiled } from '../../../../components/common/deprecated/EditFormFiled';
import LosSelect from '../../../../components/common/deprecated/LosSelect';
import {
  CardIndentationOrderDto,
  CardIndentationOrderStatus,
  UpdateCardIndentationOrderStatusRequest,
} from '../../../../types/card';
import { CardAllocationService } from '../../../apis';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import { useCardIndentationOrder } from '../context';

type MarkAsDeliveredModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  orderData?: CardIndentationOrderDto;
};

const DELIVERY_PARTNERS = [
  {
    label: 'Delhivery',
    value: 'delhivery',
  },
  {
    label: 'Indiapost',
    value: 'indiapost',
  },
  {
    label: 'Bluedart',
    value: 'bluedart',
  },
  {
    label: 'Ecom express',
    value: 'ecomExpress',
  },
  {
    label: 'Hand Delivered',
    value: 'handDelivered',
  },
];

function MarkAsDeliveredModal({
  isOpen,
  handleClose,
  orderData,
}: MarkAsDeliveredModalProps) {
  const hookForm = useForm<{
    shipment: {
      mailerVendorId: string;
      trackingId: string;
    };
  }>();
  const mailerVendorId = hookForm.watch('shipment.mailerVendorId');

  const { setSnackbar } = useSnackbar();
  const { listIndentationOrderQuery } = useCardIndentationOrder();

  const updateAllocationStatusMutation = useMutation({
    mutationFn: (data: UpdateCardIndentationOrderStatusRequest) => {
      return CardAllocationService.updateCardIndentationOrderCardIndentationStatus(
        data
      );
    },
    onSuccess: () => {
      listIndentationOrderQuery?.refetch();
      setSnackbar('Mark as delivered successfully');
    },
    onError: (error) => {
      setSnackbar('Error while updating status (Delivered)', 'error');
    },
  });

  const onSubmit = (data: {
    shipment: {
      mailerVendorId: string;
      trackingId: string;
    };
  }) => {
    if (orderData) {
      updateAllocationStatusMutation.mutate({
        cardIndentationOrderId: orderData?.id,
        mailerVendorId: data.shipment?.mailerVendorId,
        mailerVendorTrackingId: data.shipment.trackingId,
        status: CardIndentationOrderStatus.DELIVERED,
      });
    }
  };

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Submit',
      disabled: !hookForm.formState.isValid,
      onSubmit: () => {
        onSubmit && onSubmit(hookForm.getValues());
        handleClose();
      },
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <CIMS_Modal
      maxWidth="sm"
      open={isOpen}
      handleClose={handleClose}
      header="Mark as shipped"
      buttonGroup={buttons}
    >
      <Box sx={{ px: 3, pt: 2, pb: 6 }}>
        <Stack spacing={2}>
          <Controller
            name="shipment.mailerVendorId"
            control={hookForm.control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <Box>
                <VegaHeader text="Delivery Partner Name" />
                <LosSelect
                  options={DELIVERY_PARTNERS}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Add Delivery Partner Name"
                />
              </Box>
            )}
          />
          {mailerVendorId !== 'handDelivered' && (
            <Controller
              name="shipment.trackingId"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <EditFormFiled
                  title={'TRACKING ID'}
                  description={field.value}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                  placeHolder={'Enter Tracking ID'}
                />
              )}
            />
          )}
        </Stack>
      </Box>
    </CIMS_Modal>
  );
}
export default MarkAsDeliveredModal;
