import React, { useMemo } from 'react';
import { VegaCard, VegaTextField } from '../../../../components/common';
import { Grid, Box, Typography } from '@mui/material';
import { GREY } from '../../../../constants/style';
import VegaSelect from '../../../../components/common/VegaSelect';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import { IBranchProps } from '../../pages/MCCBranch';

interface IProps {
  state: IBranchProps;
  handleChange: (name: string, value: any) => void;
  onCreate: () => void;
  loading: boolean;
}

const CreateBranch = ({ state, loading, handleChange, onCreate }: IProps) => {
  const isValidInput = useMemo(() => {
    if (
      !state.branchAddress ||
      !state.branchCode ||
      !state.branchName ||
      !state.city ||
      !state.pincode ||
      !state.state
    )
      return false;

    return true;
  }, [state]);

  return (
    <VegaCard>
      <Box style={{ paddingBottom: '20px' }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: '500', color: GREY.darkest }}
        >
          Create Branch
        </Typography>
      </Box>
      {/* ------------------------------------CREATE BRANCH ------------------------------------ */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state.branchName}
            onChange={(e) => handleChange('branchName', e.target.value)}
            key="branchName"
            type={'text'}
            label="Branch Name"
            placeholder="Branch Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <VegaSelect
            label="Branch Code"
            size="small"
            key={'branchCode'}
            selected={state.branchCode}
            onChange={(value) => handleChange('branchCode', value)}
            options={['1234', 'Abc34']}
            placeholder="Branch Code"
          /> */}
          <VegaTextField
            value={state.branchCode}
            onChange={(e) => handleChange('branchCode', e.target.value)}
            key="branchCode"
            type={'text'}
            label="Branch Code"
            placeholder="Branch Code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state.branchAddress}
            onChange={(e) => handleChange('branchAddress', e.target.value)}
            key="branchAddress"
            type={'text'}
            label="Branch Address"
            placeholder="Branch Address"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} sm={4}>
          <VegaTextField
            value={state.pincode}
            onChange={(e) => handleChange('pincode', e.target.value)}
            key="pincode"
            type={'number'}
            label="Pin Code"
            placeholder="Pin Code"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VegaTextField
            value={state.state ?? ''}
            onChange={(e) => handleChange('state', e.target.value)}
            key="state"
            type={'text'}
            label="State"
            placeholder="State"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VegaTextField
            value={state.city ?? ''}
            onChange={(e) => handleChange('city', e.target.value)}
            key="city"
            type={'text'}
            label="City"
            placeholder="City"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state.description ?? ''}
            onChange={(e) => handleChange('description', e.target.value)}
            key="description"
            type={'text'}
            label="Description"
            placeholder="Description"
          />
        </Grid> */}
      </Grid>
      <Grid container justifyContent="end" mt={2}>
        <VegaContainedButton
          text="+  Create Branch"
          isPrimary
          mx="0"
          onClick={onCreate}
          loading={loading}
          disabled={!isValidInput}
        />
      </Grid>
    </VegaCard>
  );
};

export default CreateBranch;
