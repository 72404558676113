import { Download } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import VegaText from '../../../../components/common/VegaText';
import { FIleIcon } from '../../../../components/icon/Icons';
import { BLUE, BORDER_GRAY } from '../../../../constants/style';
import {
  FileValidator,
  FileValidatorResult,
} from '../../../../utils/FileValidator';

interface FilePreviewProps {
  imageUrl: string;
  selectedFile: File | null;
  handleChange: (file: File | null) => void;
  title?: string;
  isReadOnly?: boolean;
  fileValidator?: FileValidator;
  onError?: (message: string | null) => void;
}

const PreviewNUploadFile: React.FC<FilePreviewProps> = ({
  imageUrl,
  selectedFile,
  title,
  handleChange,
  isReadOnly = false,
  fileValidator,
  onError,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    handleChange(file || null);
    file && setPreviewUrl(URL.createObjectURL(file));
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (!fileValidator) {
      handleFileChange(event);
      return;
    }

    if (selectedFile) {
      const validationResult: FileValidatorResult =
        await fileValidator.validateFile(selectedFile);

      if (validationResult.isValid) {
        handleFileChange(event);
      } else {
        onError?.(validationResult.errorMessage);
      }
    }
  };

  function toDataURL(url: string) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  const download = async (url: string) => {
    if (url) {
      const a = document.createElement('a');
      const urlBlob = await toDataURL(url);

      a.href = urlBlob;
      a.download = 'myImage.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const renderPreview = () => {
    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        return (
          <img
            src={previewUrl}
            alt="Preview"
            style={{ width: '100%', height: 'auto', maxHeight: '200px' }}
          />
        );
      } else if (selectedFile.type === 'application/pdf') {
        return (
          <embed
            src={previewUrl}
            width="100%"
            height="500px"
            type="application/pdf"
          />
        );
      }
    }

    return (
      <img
        src={imageUrl}
        alt="Preview"
        style={{ width: '100%', height: 'auto' }}
      />
    );
  };

  return (
    <Box>
      <Box sx={{ border: BORDER_GRAY, borderRadius: '5px', p: 2 }}>
        <Box sx={{ mb: 0.5 }}>
          <Stack>
            <VegaText text={title} />
          </Stack>
        </Box>
        <Box className="snip1205">
          {renderPreview()}
          <i style={{ cursor: 'pointer' }} onClick={() => download(imageUrl)}>
            <Download />
          </i>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            my: 0.5,
          }}
        ></Box>
      </Box>

      <Box>
        <Box
          sx={{
            p: 1,
            my: 1,
            border: BORDER_GRAY,
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FIleIcon />
              <Box mx={1}>
                <VegaText text="Visa.pdf" />
              </Box>
            </Box>
            <Box>
              <input
                type="file"
                id="kycFile"
                hidden
                onChange={handleFileSelect}
                disabled={isReadOnly}
              />
              <label
                style={{
                  color: 'white',
                  backgroundColor: BLUE.lighter,
                  paddingTop: '2px',
                  paddingBottom: '2px',
                  paddingRight: '8px',
                  paddingLeft: '8px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: 16,
                }}
                htmlFor="kycFile"
              >
                Upload
              </label>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewNUploadFile;
