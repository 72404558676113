export enum CardRefundType {
  PARTIAL = 'PARTIAL',
  COMPLETE = 'COMPLETE',
}

export enum PaymentMode {
  CASH = 'CASH',
  BANK = 'BANK',
}

export enum CardRefundOrderStatus {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  CARD_REFUNDED = 'CARD_REFUNDED',
  REJECTED = 'REJECTED',
}
