/** @format */

import { useEffect, useState } from 'react';
import {
  addRoleForClient,
  fetchListOfRolesForClient,
  fetchListOfUsersForClient,
} from '../api/roleManagement';
import { VegaPage } from '../components/common';

import AddRole from '../components/User Access/AddRole/AddRole';
import ListOfRole from '../components/User Access/AddRole/ListOfRole';
import { useClientAuth } from '../providers/ClientProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { IAccessProps, IRolesProps, IUsersProps } from '../types/userAccess';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { getRequestAccessFeatureObjFromProgramFeatues } from '../utils/userAccessUtils';

export interface IUserAccessProps {
  data: any;
  onChange: (name: string, value: any) => void;
  viewMore: (value: any) => void;
  onEdit: (value: any) => void;
}

const INITIAL_USER = {
  clientId: '',
  name: '',
  mobileNumber: '',
  email: '',
  address: '',
  officialEmailId: '',
  idType: '',
  idNumber: '',
  status: 'ACTIVE',
  team: '',
};

const INITIAL_ROLE = {
  clientId: '',
  roleName: '',
  accessFeatures: [],
  createdBy: '',
  status: 'ACTIVE',
};

export const ACCESS_FEATURE_OPTIONS = [
  'SUPPORT',
  'COLLECTIONS',
  'ANALYTICS',
  'REPORTS',
  'OPERATIONS',
];

export interface AccessForProgram {
  [programId: string]: string[];
}

const RolesAndAccess = () => {
  const { clientId } = useClientAuth();

  const { setSnackbar } = useSnackbar();
  const [addUser, setAddUser] = useState<IUsersProps>({
    ...INITIAL_USER,
    clientId,
  });
  const [addRole, setAddRole] = useState<IRolesProps>({
    ...INITIAL_ROLE,
    clientId,
  });
  const [users, setUsers] = useState<IUsersProps[]>([]);
  const [roles, setRoles] = useState<IRolesProps[]>([]);
  const [featuresForProgram, setFeaturesForProgram] =
    useState<AccessForProgram>({});

  const onChange = (name: string, value: any) => {
    setAddUser((preState: IUsersProps) => ({
      ...preState,
      [name]: value,
    }));
  };

  const getUsersForClient = async () => {
    await fetchListOfUsersForClient(clientId)
      .then((res) => setUsers(res.data))
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  const onChangeRole = (name: string, value: any) => {
    setAddRole((preState: IRolesProps) => ({
      ...preState,
      [name]: value,
    }));
  };

  const handleAccessForProgramChange = (
    programId: string,
    features: string[],
    removeProgram = false
  ) => {
    if (removeProgram) {
      const copy = { ...featuresForProgram };
      delete copy[programId];
      setFeaturesForProgram({ ...copy });
    } else {
      setFeaturesForProgram({ ...featuresForProgram, [programId]: features });
    }
  };

  const editRoleForClient = async (data: any) => {
    const newArr = data?.accessFeatures.map((val: any) => ({
      ...val,
      programId: data.programId,
    }));
    const obj = { ...data, accessFeatures: newArr };
    await addRoleForClient(obj)
      .then((res) => {
        setSnackbar('Role Updated!');
        getRolesForClient();
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  const onSubmitRole = async () => {
    const accessFeatures =
      getRequestAccessFeatureObjFromProgramFeatues(featuresForProgram);
    const roleObjWithAccessFeatues = { ...addRole, accessFeatures };

    await addRoleForClient(roleObjWithAccessFeatues)
      .then((res) => {
        setSnackbar('Role added!');
        getRolesForClient();
        setAddRole({
          ...INITIAL_ROLE,
          clientId,
        });
        setFeaturesForProgram({});
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        setAddRole({
          ...INITIAL_ROLE,
          clientId,
        });
      });
  };

  const getRolesForClient = async () => {
    await fetchListOfRolesForClient(clientId)
      .then((res) => setRoles(res.data))
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  const isInputValid = () => {
    const isRoleNameValid = true;
    const isAccessSelected = Object.values(featuresForProgram).some(
      (values) => values.length > 0
    );
    return isRoleNameValid && isAccessSelected;
  };

  useEffect(() => {
    if (clientId) {
      getUsersForClient();
      getRolesForClient();
    }
  }, []);

  return (
    <>
      <VegaPage>
        <AddRole
          onChange={onChangeRole}
          onSubmitRole={onSubmitRole}
          state={addRole}
          onAccessFeaturesChange={handleAccessForProgramChange}
          featuresForProgram={featuresForProgram}
          isInputValid={isInputValid()}
        />
        <ListOfRole
          data={roles}
          onChange={onChange}
          onEdit={editRoleForClient}
        />
      </VegaPage>
    </>
  );
};

export default RolesAndAccess;
