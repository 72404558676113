import React, { useEffect, useState } from 'react';
import VegaSelect from '../../components/common/VegaSelect';
import { useClientAuth } from '../../providers/ClientProvider';
import { fetchBranchList } from '../Program-Dashboard/api/Branch';
import { fetchPlanData } from '../Program-Dashboard/api/PlanPolicies';

interface IProps {
  selected: any;
  handleChange: (value: any) => void;
  name: string;
}

const TransactionFeePlanSelector = ({
  name,
  selected,
  handleChange,
}: IProps) => {
  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [transactionFee, setTransactionFee] = useState<any[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== transactionFee.length) {
        setPage(page + 1);
      }
    }
  };

  const getFeePlanList = (policyType: string) => {
    fetchPlanData({ clientId, policyType, page, pageSize: 10 }).then((res) => {
      const planArr = res.data?.records?.map((val: any) => ({
        id: val.policy_id,
        name: val.policy_name,
      }));
      setTransactionFee([...transactionFee, ...planArr]);
      setTotalItem(res.data.totalItems);
    });
  };

  useEffect(() => {
    getFeePlanList('TRANSACTION_LIMIT_PLAN');
  }, [page]);
  return (
    <VegaSelect
      placeholder="Transaction fee plan"
      label="Transaction fee plan"
      size="small"
      key={name}
      selected={selected ?? ''}
      onChange={(value) => handleChange(value)}
      options={transactionFee}
      onScroll={loadMoreItems}
    />
  );
};

export default TransactionFeePlanSelector;
