import { Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { VegaWebHook, VegaWebhookStatus } from '../../types/webhook';
import { VegaCard, VegaTable } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaOutlineButton from '../common/VegaOutlineButton';

interface ListOfEventProps {
  data: any;
  onSubscribeClick: (webhook: VegaWebHook) => void;
  onUnsubscribeClick: (webhookId: string) => void;
  loading?: boolean;
}

const ToolTipWithText = (data: { text: string }) => {
  return (
    <Tooltip title={data.text} arrow placement="left">
      <div>{`${data.text}`}</div>
    </Tooltip>
  );
};

const ListOfEvent = ({
  data,
  onSubscribeClick,
  onUnsubscribeClick,
  loading = false,
}: ListOfEventProps) => {
  const columnRef = [
    {
      field: 'event',
      headerName: '  Event Name',
      flex: 1,
      renderCell: (props: any) => {
        const event = props.row.event;
        return <ToolTipWithText text={event} />;
      },
    },

    {
      field: 'description',
      headerName: '  Description',
      flex: 1,
      renderCell: (props: any) => {
        const description = props.row.description;
        return <ToolTipWithText text={description} />;
      },
    },
    {
      field: 'url',
      headerName: '  url',
      flex: 1,
      renderCell: (props: any) => {
        const url = props.row.url;
        return <ToolTipWithText text={url ?? '-'} />;
      },
    },
    {
      field: 'Subscription',
      headerName: '  Status',
      renderCell: (props: any) => {
        const isSubscribed = props.row.status == VegaWebhookStatus.ACTIVE;
        if (isSubscribed) {
          return (
            <VegaOutlineButton
              text={'Unsubscribe'}
              isSuccess
              px={1.5}
              onClick={() => {
                onUnsubscribeClick(props.row.id);
              }}
            />
          );
        } else {
          return (
            <VegaContainedButton
              text={'Subscribe'}
              isPrimary
              px={1.5}
              onClick={() => onSubscribeClick(props.row)}
            />
          );
        }
      },
      flex: 1,
    },
  ];

  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={columnRef}
        idColumn={'event'}
        loading={loading}
      />
    </VegaCard>
  );
};

export default ListOfEvent;
