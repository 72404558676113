import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import { VegaSettlementCalendarDate } from '../../types/program';
import VegaTextV2 from '../common/v2/VegaTextV2';

type Props = {
  changedDatesWithMonth: Map<number, VegaSettlementCalendarDate[]>;
  selectedMonth: number;
};

const ChangedDateListItem = (data: {
  key: string;
  title: string;
  description: string;
}) => {
  return (
    <Stack
      key={data.key}
      style={{
        borderBottom: '1px solid rgba(209, 209, 209, 1)',
        padding: ' 0.5rem 1.25rem 0.5rem 1.25rem',
      }}
      spacing={1}
    >
      <VegaTextV2
        text={data.title}
        fontSize={'.875rem'}
        lineHeight={'19.6px'}
      />
      <VegaTextV2 text={data.description} variant="caption" />
    </Stack>
  );
};

function ChangedDates({ changedDatesWithMonth, selectedMonth }: Props) {
  const getChangedDatesForCurrentMonth = () => {
    return changedDatesWithMonth.get(Number(selectedMonth));
  };

  return (
    <div style={{ flex: 1 }}>
      <Box
        style={{
          backgroundColor: 'rgba(239, 242, 247, 1)',
          display: 'flex',
          alignItems: 'center',
          height: '60px',
          justifyContent: 'center',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          border: '1px solid rgba(209, 209, 209, 1)',
          borderCollapse: 'collapse',
          borderBottom: 'none',
        }}
      >
        <VegaTextV2 text="Changed Dates" variant="body1" />
      </Box>
      <Box
        style={{
          border: '1px solid rgba(209, 209, 209, 1)',
          borderCollapse: 'collapse',
          borderTop: 'none',
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
          maxHeight: '420px',
          minHeight: '420px',
          overflow: 'scroll',
        }}
      >
        {getChangedDatesForCurrentMonth()?.map((item) => {
          return (
            <ChangedDateListItem
              key={item.id}
              title={`${item.actualDay} ${_.startCase(
                _.toLower(item.actualMonth)
              )} -> ${item.effectiveDay} ${_.startCase(
                _.toLower(item.effectiveMonth)
              )}`}
              description={item.reason ?? '-'}
            />
          );
        })}
      </Box>
    </div>
  );
}

export default ChangedDates;
