export const BULK_UPLOAD_TEMPLATE = {
  BRANCH_FUND_RELOAD:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/bulk-upload-samples/Fund_Reload_Bulk_Template.csv',
  BRANCH_APPLICATION:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/bulk-upload-samples/Application_Bulk_Template.csv',
  BRANCH_TEAM_MANAGEMENT:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/bulk-upload-samples/Team_Management_Bulk_Template.csv',
};

export const REPORT_NAME_URL = {
  FUND_RELOAD:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/report-samples/BRANCH_FUND_RELOAD.csv',
  CARD_SALE:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/report-samples/BRANCH_CARD_SALE.csv',
  CARD_REFUND:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/report-samples/BRANCH_CARD_REFUND_REPORT.csv',
  HQ_CARD_SALE: '',
  HQ_CARD_REFUND:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/report-samples/HQ_CARD_REFUND_REPORT.csv',
  HQ_FUND_RELOAD:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/report-samples/HQ_FUND_RELOAD_REPORT.csv',
  CORPORATE_FUND_RELOAD:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/report-samples/CORPORATE_FUND_RELOAD.csv',
  CORPORATE_CARD_SALE:
    'https://prod-image-assets.s3.ap-south-1.amazonaws.com/report-samples/CORPORATE_CARD_SALE.csv',
  CORPORATE_CARD_REFUND: '',
};
