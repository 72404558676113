/* eslint-disable react/jsx-pascal-case */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
  listForClient,
  listProgramWithMetadata,
} from '../../../../api/program';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { VegaProgramType } from '../../../../types/program';
import { EditFormFiled } from '../../../../components/common/deprecated/EditFormFiled';
import { LosSelectOption } from '../../../../components/common/deprecated/LosSelect';
import { CreateDivisionForm } from '../CreateDivision';

interface IProgramItem {
  name: string;
  programId: string;
}
interface IProps {
  initialValues?: VegaProgramType[];
  parentEntityId?: string;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function MapEntityProgram({ initialValues, parentEntityId }: IProps) {
  const hookForm = useFormContext<CreateDivisionForm>();
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();

  const programsForClientQuery = useQuery({
    queryKey: ['list_programs', { clientId }],
    queryFn: () => {
      return listForClient(clientId || '');
    },
    enabled: !!clientId,

    select: (data) => {
      return data.data.records.reduce((pre, curr) => {
        return [
          ...pre,
          {
            value: curr.programId,
            label: curr.name,
          },
        ];
      }, [] as LosSelectOption[]);
    },
    onError: () => {
      setSnackbar('Error in fetching programs for client', 'error');
    },
  });
  const programMetadataQuery = useQuery({
    queryKey: ['listProgramWithMetadata', clientId, parentEntityId],
    queryFn: () => {
      return listProgramWithMetadata({
        metaDataDto: {
          clientId: clientId || '',
          ...(parentEntityId ? { divisionId: parentEntityId } : {}),
        },
        size: 100,
      });
    },
    enabled: !!clientId && !!parentEntityId,
    select: (data) => {
      return data.data.records.reduce((pre, curr) => {
        return [
          ...pre,
          {
            value: curr.programId,
            label: curr.name,
          },
        ];
      }, [] as LosSelectOption[]);
    },
    onError: () => {
      setSnackbar('Error in fetching programs for client', 'error');
    },
  });

  return (
    <Controller
      name={'programId'}
      control={hookForm.control}
      rules={{ required: false }}
      render={({ field }) => (
        // <Stack spacing={0.5}>

        //   <VegaHeader text={'Select Programs'} />

        //   <Autocomplete<IProgramItem, true>
        //     placeholder="Select Programs"
        //     disableCloseOnSelect
        //     limitTags={2}
        //     fullWidth
        //     // multiple
        //     id="programs"
        //     renderInput={(params) => <CIMS_TextField {...params} />}
        //     options={programsForClientQuery.data || []}
        //     style={{
        //       padding: 0,
        //     }}
        //     value={field.value || []}
        //     onChange={(event, newValue) => {
        //       field.onChange(newValue);
        //     }}
        //     defaultValue={initialValues}
        //     isOptionEqualToValue={(option, value) =>
        //       option.programId === value.programId
        //     }
        //     renderTags={(tagValue, getTagProps) =>
        //       tagValue.map((option, index) => (
        //         <Chip
        //           label={option.name}
        //           {...getTagProps({ index })}
        //           disabled={
        //             initialValues
        //               ? initialValues?.findIndex(
        //                   (val) => val.programId === option.programId
        //                 ) !== -1
        //               : false
        //           }
        //         />
        //       ))
        //     }
        //     getOptionLabel={(option) => `${option.name}`}
        //     renderOption={(props, option, { selected }) => {
        //       return (
        //         <li
        //           {...props}
        //           onClick={
        //             initialValues
        //               ? initialValues?.findIndex(
        //                   (val) => val.programId === option.programId
        //                 ) === -1
        //                 ? props.onClick
        //                 : undefined
        //               : props.onClick
        //           }
        //         >
        //           <VegaCheckBoxV2
        //             icon={icon}
        //             checkedIcon={checkedIcon}
        //             style={{ marginRight: 8 }}
        //             checked={selected}
        //             onChange={() => {}}
        //             disabled={
        //               initialValues?.findIndex(
        //                 (val) => val.programId === option.programId
        //               ) !== -1
        //             }
        //           />
        //           {option.name}
        //         </li>
        //       );
        //     }}
        //     sx={{
        //       width: '100%',
        //       minWidth: 150,
        //       borderRadius: '29px',
        //     }}
        //   />
        // </Stack>
        <EditFormFiled
          title={'Select Program'}
          placeholder="Select Program"
          description={field.value}
          onChange={(val) => {
            field.onChange(val);
          }}
          ref={field.ref}
          options={
            parentEntityId
              ? programMetadataQuery.data
              : programsForClientQuery.data
          }
          isLoadingOptions={
            parentEntityId
              ? programMetadataQuery.isFetching
              : programsForClientQuery.isLoading
          }
        />
      )}
    />
  );
}
