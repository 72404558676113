import React, { useState } from 'react';
import { VegaPage } from '../../../components/common';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import VegaSelector from '../../../components/common/VegaSelector';
import { CreditCardIcon, UserIcon } from '../../../components/icon/Icons';
import { BACKGROUND } from '../../../constants/style';
import ListOfInstituteResources from '../components/MCCInstitutionalResources/ListOfInstituteResources';

let tileData = [
  {
    id: 'MCC',
    text: 'MCC',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'TRANSACTION_REQUIRMENT',
    text: 'Transaction Requirement',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'EVENT',
    text: 'Event',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];
const KIT_DATA = [
  {
    id: 1,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'APPROVED',
  },
  {
    id: 2,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 2',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'REJECTED',
  },
  {
    id: 3,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 3',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'PENDING',
  },
];
const PERSONALISED_DATA = [
  {
    id: 1,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'APPROVED',
  },
  {
    id: 2,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'REJECTED',
  },
  {
    id: 3,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'PENDING',
  },
];
const REISSUE_DATA = [
  {
    id: 1,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'APPROVED',
  },
  {
    id: 2,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'REJECTED',
  },
  {
    id: 3,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'PENDING',
  },
];

const MCCInstitutionalResources = () => {
  const [data, setData] = useState<any>([...KIT_DATA]);
  const [filterData, setFilterData] = useState<string>('');
  const [activeState, setActiveState] = useState<any>(tileData[0]);

  const handleChange = (value: string) => {
    setFilterData(value);
  };
  const selectedTab = (data: any) => {
    setActiveState(data);
    if (data.id === 'PERSONALISED_SALE') {
      setData([...PERSONALISED_DATA]);
    } else if (data.id === 'REISSUE') {
      setData([...REISSUE_DATA]);
    } else {
      setData([...KIT_DATA]);
    }
  };
  return (
    <VegaPage>
      <MCCFilterSection
        state={filterData}
        handleChange={handleChange}
        label="Search by Program Name or Plans "
      />
      <VegaSelector
        data={tileData}
        setActiveState={selectedTab}
        active={activeState}
        header="Institute Resourcese"
        subHeader="All resources which are used in the Vegapay ecosystem"
      />
      <ListOfInstituteResources data={data} active={activeState} />
    </VegaPage>
  );
};

export default MCCInstitutionalResources;
