import {
  Edit,
  Save,
  WarningAmberOutlined,
  WarningOutlined,
  WarningRounded,
} from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { saveAddOnCardConfiguration } from '../../../api/card';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { AddOnCardConfiguration } from '../../../types/card';
import VegaAccordianV2 from '../../common/v2/VegaAccordianV2';
import VegaCheckboxGroup, {
  VegaCheckboxOption,
} from '../../common/v2/VegaCheckboxGroup';
import VegaFormInputFieldV2 from '../../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../common/v2/VegaPrimaryButton';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';
import VegaSwitchGroup from '../../common/v2/VegaSwitchGroup';
import VegaTextFieldV2 from '../../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import { AddOnCardConfigIcon, WarningRoundedOutlined } from '../../icon/Icons';

enum ApplicationFormDetailType {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  RELATION_SHIP = 'RELATION_SHIP',
  ADDRESS = 'ADDRESS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  GENDER = 'GENDER',
}

enum KycDocumentType {
  PAN_NO = 'PAN_NO',
  AADHAAR_NO = 'AADHAAR_NO',
}

enum TransactionType {
  E_COMMERCE = 'ADD_ON_CARD_ECOMMERCE_STATUS',
  POS = 'ADD_ON_CARD_POS_STATUS',
  CASH_WITHDRAWAl = 'ADD_ON_CARD_CASH_WITHDRAWAL_STATUS',
}

interface AddOnCardDetailsAccordianProps {
  programId?: string;
  initial?: Partial<AddOnCardConfiguration> | null;
}

function AddOnCardDetailsAccordian({
  programId,
  initial,
}: AddOnCardDetailsAccordianProps) {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [schemeName, setSchemeName] = useState<string>();
  const [maxCardsToBeAllocated, setMaxCardsToBeAllocated] = useState<string>();
  const [selectedFormOptions, setSelectedFormOptions] = useState<string[]>([]);
  const [selectedKycOptions, setSelectedKycOptions] = useState<string[]>([]);
  const [selectedTransactionOptions, setSelectedTransactionOptions] = useState<
    string[]
  >([]);
  const [settings, setSettings] = useState<Partial<AddOnCardConfiguration>>();

  function toggleExpansion() {
    setIsExpanded(!isExpanded);
  }

  function isInputValid() {
    let isSchemenNameValid = Boolean(schemeName);
    let areFormOptionsSelected = selectedFormOptions.length > 0;
    let maxCardsIsValid = (maxCardsToBeAllocated ?? '').length > 0;
    let isValid =
      isSchemenNameValid && areFormOptionsSelected && maxCardsIsValid;
    return isValid;
  }

  useEffect(() => {
    if (!initial) {
      console.log('====================================');
      console.log('No iunput value');
      console.log('====================================');
      clearInputs();
      return;
    }
    setSettings(initial);
  }, [initial]);

  function onSaveClick() {
    setLoading(true);
    const data: Partial<AddOnCardConfiguration> = {
      programId: programId,
      addOnCardSchemeName: schemeName,
      firstName: selectedFormOptions.includes(
        ApplicationFormDetailType.FIRST_NAME
      ),
      lastName: selectedFormOptions.includes(
        ApplicationFormDetailType.LAST_NAME
      ),
      relationShip: selectedFormOptions.includes(
        ApplicationFormDetailType.RELATION_SHIP
      ),
      address: selectedFormOptions.includes(ApplicationFormDetailType.ADDRESS),
      phoneNumber: selectedFormOptions.includes(
        ApplicationFormDetailType.PHONE_NUMBER
      ),
      email: selectedFormOptions.includes(ApplicationFormDetailType.EMAIL),
      gender: selectedFormOptions.includes(ApplicationFormDetailType.GENDER),
      panNo: selectedKycOptions.includes(KycDocumentType.PAN_NO),
      aadhaarNo: selectedKycOptions.includes(KycDocumentType.AADHAAR_NO),
      addOnCardCashWithdrawalStatus: selectedTransactionOptions.includes(
        TransactionType.CASH_WITHDRAWAl
      ),
      addOnCardEcommerceStatus: selectedTransactionOptions.includes(
        TransactionType.E_COMMERCE
      ),
      addOnCardPosStatus: selectedTransactionOptions.includes(
        TransactionType.POS
      ),
    };

    if (maxCardsToBeAllocated) {
      data.addOnCardCount = Number(maxCardsToBeAllocated);
    }

    saveAddOnCardConfiguration(data)
      .then((res) => {
        setSettings(res);
        setSnackbar('Settings saved');
      })
      .catch((err) => {
        setSnackbar('Failed to save settings', 'error');
      })
      .finally(() => {
        setLoading(false);
        toggleExpansion();
      });
  }

  function processSettings(cardSettings?: Partial<AddOnCardConfiguration>) {
    setSchemeName(cardSettings?.addOnCardSchemeName);
    const cardCount = cardSettings?.addOnCardCount;
    if (cardCount) {
      setMaxCardsToBeAllocated(cardCount.toString());
    }
    const initialOptions: Partial<AddOnCardConfiguration> = {
      firstName: cardSettings?.firstName,
      lastName: cardSettings?.lastName,
      relationShip: cardSettings?.relationShip,
      address: cardSettings?.address,
      phoneNumber: cardSettings?.phoneNumber,
      email: cardSettings?.email,
      gender: cardSettings?.gender,
      aadhaarNo: cardSettings?.aadhaarNo,
      panNo: cardSettings?.panNo,
      addOnCardCashWithdrawalStatus:
        cardSettings?.addOnCardCashWithdrawalStatus,
      addOnCardEcommerceStatus: cardSettings?.addOnCardEcommerceStatus,
      addOnCardPosStatus: cardSettings?.addOnCardPosStatus,
    };

    const formItems: string[] = [];
    for (const item in initialOptions) {
      const isSelected = initialOptions[item as keyof AddOnCardConfiguration];
      if (isSelected) {
        formItems.push(_.snakeCase(item).toUpperCase());
      }
    }

    const kycItems: string[] = [];
    if (formItems.includes(KycDocumentType.PAN_NO)) {
      kycItems.push(KycDocumentType.PAN_NO);
    }
    if (formItems.includes(KycDocumentType.AADHAAR_NO)) {
      kycItems.push(KycDocumentType.AADHAAR_NO);
    }
    setSelectedKycOptions(kycItems);

    const transactionItems: string[] = [];
    if (formItems.includes(TransactionType.CASH_WITHDRAWAl)) {
      transactionItems.push(TransactionType.CASH_WITHDRAWAl);
    }
    if (formItems.includes(TransactionType.POS.toUpperCase())) {
      transactionItems.push(TransactionType.POS);
    }
    if (formItems.includes(TransactionType.E_COMMERCE.toUpperCase())) {
      transactionItems.push(TransactionType.E_COMMERCE);
    }

    setSelectedTransactionOptions(transactionItems);

    const filteredFormOptions = formItems.filter((item) => {
      if (item == KycDocumentType.AADHAAR_NO) return false;
      if (item == KycDocumentType.PAN_NO) return false;
      if (item == TransactionType.E_COMMERCE) return false;
      if (item == TransactionType.CASH_WITHDRAWAl) return false;
      if (item == TransactionType.POS) return false;
      return true;
    });

    setSelectedFormOptions(filteredFormOptions);
  }

  function clearInputs() {
    setSelectedFormOptions([]);
    setMaxCardsToBeAllocated('');
    setSelectedKycOptions([]);
    setSelectedTransactionOptions([]);
    setSchemeName('');
    setSettings(undefined);
  }

  useEffect(() => {
    if (!settings) return;
    processSettings(settings);
  }, [settings]);

  return (
    <VegaAccordianV2
      expanded={isExpanded}
      collapsedView={
        <>
          <Stack flex={2}>
            <VegaTextV2 text="Add on card" />
            <VegaTextV2
              text="Additional card issued to relatives of the primary holder as per request"
              variant="caption"
            />
          </Stack>
        </>
      }
      collapsedViewActions={
        <VegaPrimaryButton
          variant="outlined"
          onClick={() => {
            toggleExpansion();
          }}
          text={settings ? 'Edit' : 'Configure Add on card'}
          startIcon={settings ? <Edit /> : <AddOnCardConfigIcon />}
        />
      }
      expandedViewActions={
        <Stack direction={'row'} spacing={2}>
          <VegaPrimaryButton
            variant="text"
            onClick={() => {
              toggleExpansion();
            }}
            text={'Cancel'}
          />
          <VegaPrimaryButton
            disabled={isInputValid() == false}
            loading={loading}
            onClick={() => {
              onSaveClick();
            }}
            text={'Save'}
            startIcon={<Save />}
          />
        </Stack>
      }
      expandedView={
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <VegaFormInputFieldV2 label="Scheme Name">
              <VegaTextFieldV2
                value={schemeName}
                onChange={(e) => setSchemeName(e.target.value)}
              />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputFieldV2 label="Application Form details">
              <VegaCheckboxGroup
                options={applicationFormOptions()}
                selectedValues={selectedFormOptions}
                onChange={setSelectedFormOptions}
              />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputFieldV2 label="Upload KYC Document (optional)">
              <VegaCheckboxGroup
                options={kycOptions()}
                selectedValues={selectedKycOptions}
                onChange={setSelectedKycOptions}
              />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={4}>
            <VegaFormInputFieldV2 label="Max cards to be alloted">
              <VegaSelectV2
                options={cardCountOptions()}
                value={maxCardsToBeAllocated ?? ''}
                onChange={(e) => {
                  setMaxCardsToBeAllocated(e.target.value as string);
                }}
              />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputFieldV2 label="Transaction Type (optional)">
              <VegaSwitchGroup
                options={transactionOptions()}
                selectedValues={selectedTransactionOptions}
                onChange={setSelectedTransactionOptions}
              />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <WarningRoundedOutlined />
              <VegaTextV2
                text="Charges can be configure in CMS setup"
                variant="caption"
              />
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
}

export default AddOnCardDetailsAccordian;

const cardCountOptions = () =>
  ['1', '2', '3', '4', '5'].map((item) => {
    const option: VegaCheckboxOption = {
      id: item,
      value: item,
      label: item,
    };
    return option;
  });

const applicationFormOptions = () =>
  Object.keys(ApplicationFormDetailType).map((item) => {
    const option: VegaCheckboxOption = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const kycOptions = () =>
  Object.keys(KycDocumentType).map((item) => {
    const option: VegaCheckboxOption = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const transactionOptions = () =>
  Object.values(TransactionType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: TRANSACTION_TYPE_COPY[item],
    };
    return option;
  });

const TRANSACTION_TYPE_COPY = {
  [TransactionType.E_COMMERCE]: 'E-Commerce',
  [TransactionType.POS]: 'POS',
  [TransactionType.CASH_WITHDRAWAl]: 'Cash Withdrawal',
};
