import { CancelOutlined } from '@mui/icons-material';
import {
  Dialog,
  Stack,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { PolicySelections } from '../../types/policy';
import VegaContainedButton from '../common/VegaContainedButton';
import AllPolicySummary from './AllPolicySummary';

interface AllPolicyModalProps {
  onClose: () => void;
  open: boolean;
  programName: string;
  policies: PolicySelections;
}

function AllPolicyModal({
  open,
  onClose,
  programName,
  policies,
}: AllPolicyModalProps) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      style={{ maxHeight: '80vh', marginTop: '7.5rem' }}
      scroll="paper"
    >
      <Stack style={{ marginLeft: '1.5rem', marginTop: '1rem' }}>
        <Typography>All Policies - {programName}</Typography>
      </Stack>
      <DialogContent>
        <AllPolicySummary policies={policies} />
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'}>
          <VegaContainedButton
            text={'Close'}
            onClick={() => {
              onClose();
            }}
            isPrimary
            startIcon={<CancelOutlined />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default AllPolicyModal;
