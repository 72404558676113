import { Stack } from '@mui/material';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import SelectProgramAndBin from '../Update_Card_Inventory/SelectProgramAndBin';
import { EditFormFiled } from '../../../components/common/deprecated/EditFormFiled';

export interface ICardDetails {
  cardType?: string;
  noOfCards: string;
}
function AssignCardsForm() {
  const hookForm = useFormContext<{
    cardDetails: ICardDetails;
    entity: {
      entityId: string;
      entityLevel: string;
    };
  }>();

  const entityId = hookForm.watch('entity.entityId');

  return (
    <Stack>
      <Stack spacing={2}>
        <FormProvider {...hookForm}>
          <SelectProgramAndBin divisionId={entityId} />
        </FormProvider>

        <Controller
          name="cardDetails.noOfCards"
          control={hookForm.control}
          rules={{
            required: 'Required',
            min: 1,
            pattern: { value: /^[0-9]*$/, message: 'Invalid no of cards' },
          }}
          render={({ field }) => (
            <EditFormFiled
              title={'NO.OF CARDS ASSIGNED '}
              description={field.value}
              {...field}
              type="text"
              onChange={(val) => {
                const parsedVal = parseInt(val || '');
                const count = isNaN(parsedVal) ? '' : parsedVal.toString();
                field.onChange(count);
              }}
              error={!!hookForm.formState.errors.cardDetails?.noOfCards}
              helperText={
                hookForm.formState.errors.cardDetails?.noOfCards?.message
              }
              placeHolder={'Enter No. of card assigned'}
            />
          )}
        />
      </Stack>
    </Stack>
  );
}

export default AssignCardsForm;
