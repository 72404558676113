/** @format */

import axios from 'axios';
import { REWARDS } from './endpoints';

export const getRewardsForAccount = (accountId: string) => {
  return axios.get(REWARDS.LIST_FOR_ACCOUNT.replace('{accountId}', accountId));
};
export const fetchCampaignList = (programId: string) => {
  return axios.get(`${REWARDS.CAMPAIGN}/program/${programId}`);
};
