/* eslint-disable react/jsx-pascal-case */
import React, { ChangeEvent, useState } from 'react';
import { Box, Stack } from '@mui/material';
import Vega_Text from '../common/v3/Vega_Text';
import { COMMON, NEW_PRIMARY } from '../../constants/style';
import Vega_OutlineTextField from '../common/v3/Vega_OutlineTextField';
import { Search } from '@mui/icons-material';
import Vega_Button from '../common/v3/Vega_Button';
import { useSupport } from '../../providers/SupportProvider';
import { SupportStep } from '../../pages/Support/Support';

const Searchbar = () => {
  const {
    loading,
    filter,
    setFilter,
    throttledGetFilterCustomer,
    setSelected,
    setIsEmptyFilter,
    setIsDisabled,
    isDisabled,
    setFilteredCustomer,
    setLoading,
  } = useSupport();

  return (
    <Box
      sx={{
        height: '96px',
        bgcolor: 'white',
        px: 2.5,
        py: 1.5,
        borderBottom: '1px solid #EEEEEE',
      }}
    >
      <Vega_Text
        text={'Support'}
        fontWeight={600}
        color={NEW_PRIMARY.BLACK}
        fontSize={17}
      />
      <Stack direction={'row'} gap={1} mt={1}>
        <Vega_OutlineTextField
          fullWidth
          fixWidth={422}
          value={filter}
          startAdornment={<Search sx={{ color: '#999DA8' }} />}
          placeholder="Search by Email ID/ Mobile Number"
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setFilter(e.target.value);
            setIsDisabled(false);
          }}
        />
        <Vega_Button
          text="Search"
          onClick={() => {
            setLoading(true);
            throttledGetFilterCustomer();

            setSelected(SupportStep.ACCOUNT);
          }}
          loading={loading}
          disabled={isDisabled}
        />
      </Stack>
    </Box>
  );
};

export default Searchbar;
