import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { COMMON } from '../../../constants/style';
import VegaContainedButton from '../../common/VegaContainedButton';

const ViewPastActivities = () => {
  return (
    <>
      <Accordion
        sx={{
          '&.MuiAccordion-root': {
            borderRadius: '10px !important',
            boxShadow: 'none !important',
            border: `1px solid #EEEEEE !important`,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box>
            <Typography
              className="font-aspekta-500"
              fontSize={14}
              color={COMMON.gray}
            >
              View Past Activities
            </Typography>
            <Typography
              className="font-aspekta-400"
              fontSize={12}
              color={'#7B8694'}
            >
              Keep track of past card orders
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '&.MuiAccordionDetails-root': {
              borderTop: `1px solid #EEEEEE`,
            },
          }}
        >
          <Stack direction={'row'} my={1} gap={2}>
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                bgcolor: '#A4ACB5',
              }}
            ></Box>
            <Box>
              <Stack direction={'row'} gap={1}>
                <Typography
                  className="font-aspekta-700"
                  fontSize={13}
                  color={'#7B8694'}
                >
                  28-08-2023
                </Typography>
                <Typography
                  className="font-aspekta-450"
                  fontSize={13}
                  color={'#7B8694'}
                >
                  New Program added
                </Typography>
                <Typography
                  className="font-aspekta-450"
                  fontSize={13}
                  color={'#36913F'}
                >
                  Successfully
                </Typography>
              </Stack>
              <Stack direction={'row'} mt={0.5} gap={1}>
                <Typography
                  className="font-aspekta-500"
                  fontSize={12}
                  color={COMMON.darkGray}
                >
                  Program name :
                </Typography>
                <Typography
                  className="font-aspekta-400"
                  fontSize={12}
                  color={'#7B8694'}
                >
                  Kotak PVR Tickets 4x Rewards
                </Typography>
              </Stack>
            </Box>
          </Stack>
          <Stack direction={'row'} my={1} gap={2}>
            <Box
              sx={{
                width: '16px',
                height: '16px',
              }}
            ></Box>
            <VegaContainedButton mx="0" text="Manual Adjustment" isPurple />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ViewPastActivities;
