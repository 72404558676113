/** @format */

import { useEffect } from 'react';
import { VegaPage } from '../components/common';
import { Box } from '@mui/material';
import { useState } from 'react';
import { ChargeBackProvider } from '../components/ChargeBack/hooks';
import ApplicationManagement from '../components/Operations/ApplicationManagement/ApplicationManagement';
import ApplicationViewWrapper from '../components/Operations/ApplicationManagement/ApplicationView/ApplicationViewWrapper';
import BulkCardBlock from '../components/Operations/BulkCardBlock/BulkCardBlock';
import ChargeBackRequestList from '../components/Operations/ChargeBack/ChargeBackRequestList';
import OfferManagement from '../components/Operations/Offer/OfferManagement';
import OperationsSelction from '../components/Operations/OperationsSelction';
import SearchByCustomerId1 from '../components/Operations/SearchByCustomerId';
import ServiceRequestManagement from '../components/Operations/Service/ServiceRequestManagement';
import {
  ChargeBackIcon,
  CorporateCardIcon,
  CreditCardIcon,
  SecurityCard,
} from '../components/icon/Icons';
import ProgramSelector from '../components/program/ProgramSelector';
import { useSearchHeader } from '../providers/SearchHeader';
import { AccountClosureTableContainer } from '../components/Operations/Account Closure/AccountClosureTableContainer';
import { useSearchParams } from 'react-router-dom';

interface IOperationseProps {
  navigate2Details: (req_id: string) => void;
}

export const ActiveStateId = {
  APPLICATION_MANAGEMENT: 'APPLICATION_MANAGEMENT',
  OFFERS_MANAGEMENT: 'OFFERS_MANAGEMENT',
  SERVICE_REQUEST_MANAGEMENT: 'SERVICE_REQUEST_MANAGEMENT',
  SEARCH_BY_CUSTOMER: 'SEARCH_BY_CUSTOMER',
  BULK_CARD_BLOCK: 'BULK_CARD_BLOCK',
  CHARGE_BACK_REQUEST: 'CHARGE_BACK_REQUEST',
  ACCOUNT_CLOSURE: 'ACCOUNT_CLOSURE',
};

let tileData = [
  {
    id: ActiveStateId.APPLICATION_MANAGEMENT,
    text: 'Application Management',
    image: <CorporateCardIcon strokeColor="black" />,
    bgColor: '#D8E7FF',
    // color: "white",
  },
  {
    id: ActiveStateId.OFFERS_MANAGEMENT,
    text: 'Offers Managment',
    image: <CreditCardIcon strokeColor="black" />,
    bgColor: '#FFCBE7',
  },
  // {
  //   id: 'SERVICE_REQUEST_MANAGEMENT',
  //   text: 'Service Request Manegment',
  //   image: <UserIcon />,
  //   bgColor: '#FFDBCB',
  // },
  {
    id: ActiveStateId.BULK_CARD_BLOCK,
    text: 'Bulk Card Block',
    image: <SecurityCard />,
    bgColor: '#D0FFD2',
  },
  {
    id: ActiveStateId.CHARGE_BACK_REQUEST,
    text: 'Charge Back Request',
    image: <ChargeBackIcon />,
    bgColor: '#FFDBCB',
  },
  {
    id: ActiveStateId.ACCOUNT_CLOSURE,
    text: 'Account Closure Report',
    image: <ChargeBackIcon />,
    bgColor: '#D8E7FF',
  },
];

export const OPEARATION_ROUTES = {
  MAIN: 'MAIN',
  APPLICATION_VIEW: 'APPLICATION_VIEW',
};

function Operations({ navigate2Details }: IOperationseProps) {
  const {
    searchValue,
    setIsHeaderSearch,
    setHeaderPlaceHolder,
    onSearchValueChange,
  } = useSearchHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const op_state = searchParams.get('op_state');
  const [activeState, setActiveState] = useState<any>(
    tileData.find((el) => el.id === op_state) || tileData[0]
  );
  const [show, setShow] = useState<string>(OPEARATION_ROUTES.MAIN);

  useEffect(() => {
    setIsHeaderSearch(true);
    setHeaderPlaceHolder('Search by Customers');
    return () => {
      setIsHeaderSearch(false);
      setHeaderPlaceHolder('');
      onSearchValueChange('');
    };
  }, []);

  useEffect(() => {
    if (op_state !== activeState) {
      console.log('here');
      const stateId = (tileData.find((el) => el.id === op_state) || tileData[0])
        .id;
      setActiveState(tileData.find((el) => el.id === op_state) || tileData[0]);
      searchParams.set('op_state', stateId);
      setSearchParams(searchParams);
    }
  }, [op_state]);

  const navigateOnCardSelection = (val: any) => {
    searchParams.set('op_state', val?.id);
    setSearchParams(searchParams);
  };

  return (
    <>
      {show === OPEARATION_ROUTES.APPLICATION_VIEW ? (
        <ApplicationViewWrapper setShow={setShow} />
      ) : (
        <Box>
          <ProgramSelector disabledOn={true} maxWidth={400} />
          <VegaPage>
            {!searchValue && (
              <OperationsSelction
                data={tileData}
                setActiveState={navigateOnCardSelection}
                active={activeState}
              />
            )}

            {searchValue ? (
              // <SearchByCustomerId />
              <SearchByCustomerId1 />
            ) : (
              <>
                {activeState.id === ActiveStateId.APPLICATION_MANAGEMENT ? (
                  <ApplicationManagement setShow={setShow} />
                ) : activeState.id === ActiveStateId.OFFERS_MANAGEMENT ? (
                  <OfferManagement />
                ) : activeState.id === ActiveStateId.BULK_CARD_BLOCK ? (
                  <BulkCardBlock />
                ) : activeState.id === ActiveStateId.ACCOUNT_CLOSURE ? (
                  <AccountClosureTableContainer
                    navigate2Details={navigate2Details}
                  />
                ) : activeState.id === ActiveStateId.CHARGE_BACK_REQUEST ? (
                  <ChargeBackProvider>
                    <ChargeBackRequestList />
                  </ChargeBackProvider>
                ) : (
                  <ServiceRequestManagement />
                )}
              </>
            )}
          </VegaPage>
        </Box>
      )}
    </>
  );
}

export default Operations;
