import { FormGroup, FormControlLabel, Grid } from '@mui/material';
import _ from 'lodash';
import VegaSwitchV2 from './VegaSwitchV2';

export interface VegaSwitchOption {
  label: string;
  value: string;
}

interface VegaCheckboxGroupProps {
  options: VegaSwitchOption[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
  switchSize?: 'small' | 'medium';
}

function VegaSwitchGroup({
  options,
  selectedValues,
  onChange,
  switchSize = 'small',
}: VegaCheckboxGroupProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      onChange([...selectedValues, value]);
    } else {
      onChange(
        selectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  return (
    <FormGroup>
      <Grid container>
        {options.map((option) => (
          <Grid item key={option.value}>
            <FormControlLabel
              key={option.value}
              control={
                <VegaSwitchV2
                  checked={selectedValues.includes(option.value)}
                  onChange={handleChange}
                  value={option.value}
                  size={switchSize}
                />
              }
              label={option.label}
              labelPlacement="end"
              style={{
                fontFamily: 'Aspekta !important',
                marginLeft: '0.75rem',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
}

export default VegaSwitchGroup;
