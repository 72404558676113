/* eslint-disable react/jsx-pascal-case */
import { Grid, Stack } from '@mui/material';
import Vega_Text from '../../../../../components/common/v3/Vega_Text';
import { GREEN, RED } from '../../../../../constants/style';
import ProfitLossInputs from './ProfitLossInputs';

interface IProps {
  title: string;
  lastUpdated: string;
  income: number;
  cost: number;
}

const ProfitLossSummaryContainer = ({
  title,
  lastUpdated,
  income,
  cost,
}: IProps) => {
  const pnl = income - cost;
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      sx={{
        minHeight: '108px',
        border: '1px solid #EBEDEF',
        borderLeft: '4px solid #1047DC',
        borderRadius: '8px',
        bgcolor: 'white',
        px: 2,
      }}
    >
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <Stack gap={1}>
            <Vega_Text text={title} fontWeight={500} fontSize={14} />
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Vega_Text
                text={'Last updated on:'}
                fontWeight={600}
                fontColor="#5F6D7E"
                fontSize={10}
              />
              <Vega_Text
                text={lastUpdated}
                fontWeight={400}
                fontColor="#5F6D7E"
                fontSize={12}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <ProfitLossInputs
            header={'income'}
            inputValue={income}
            calculateSyns={'-'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <ProfitLossInputs
            header="cost"
            inputValue={cost}
            calculateSyns={'='}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <ProfitLossInputs
            header="P&L"
            inputValue={pnl}
            borderColor={income < cost ? RED.dark : GREEN.dark}
            color={income < cost ? RED.dark : GREEN.dark}
            calculateSyns={''}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ProfitLossSummaryContainer;
