import { Box, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../../services/ChargeBackService';
import {
  AcquirerRejectionRequest,
  ChargebackStatus,
} from '../../../../types/chargeBack';
import {
  VPButton,
  VPModal,
  VPTextField,
  VegaUploadNPreview,
} from '../../../../ui-components/common';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import VegaHeader from '../../../common/VegaHeader';
import { useChargeBack } from '../../hooks';

type IProps = {
  isOpen: boolean;
  handleClose: () => void;
};
export const AquirerRejects = ({ isOpen, handleClose }: IProps) => {
  const { selectedChargeBack, setSelectedChargeBack } = useChargeBack();
  const { setSnackbar } = useSnackbar();

  const hookForm = useForm<AcquirerRejectionRequest>({
    defaultValues: {
      chargebackId: selectedChargeBack?.id,
      chargebackStatus:
        selectedChargeBack?.chargebackStatus ===
        ChargebackStatus.CHARGEBACK_CREATED
          ? ChargebackStatus.FIRST_PRESENTMENT
          : ChargebackStatus.SECOND_PRESENTMENT,
    },
  });
  const onSubmit = () => {
    const data = hookForm.getValues();

    ChargebackService.rejectByAcquirer(data)
      .then((res) => {
        setSnackbar('Aquirer rejected', 'success');
        setSelectedChargeBack(res);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Error while rejecting by aquirer'),
          'error'
        );
      });
  };
  return (
    <Box>
      <VPModal
        maxWidth="sm"
        open={isOpen}
        handleClose={handleClose}
        header={'Acquirer rejects chargeback'}
        footer={
          <Stack spacing={1} direction={'row'}>
            <VPButton onClick={onSubmit}>Confirm</VPButton>
          </Stack>
        }
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <Stack spacing={2}>
            <Controller
              name="reason"
              control={hookForm.control}
              render={({ field }) => (
                <VPTextField
                  header="Reason (optional)"
                  variant="outlined"
                  {...field}
                  placeholder="Add Reason"
                />
              )}
            />
            <Controller
              name="acquirerRejectionProofs"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Box>
                  <VegaHeader text="Upload a document, if any" />
                  <VegaUploadNPreview
                    value={field.value || []}
                    onChange={field.onChange}
                    preview2
                    max={5}
                    accept={{
                      'application/pdf': ['.pdf'],
                    }}
                  />
                </Box>
              )}
            />
          </Stack>
        </Box>
      </VPModal>
    </Box>
  );
};
