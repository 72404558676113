import { EditOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import VegaFormInputFieldV2 from '../../../../components/common/v2/VegaFormInputField';
import VegaPill from '../../../../components/common/v2/VegaPill';
import VegaPrimaryButton from '../../../../components/common/v2/VegaPrimaryButton';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import VegaConditionalWrapper from '../../../../components/common/VegaConditionalWrapper';
import { useCampaign } from '../../../../components/RewardsLoyalty/providers/CampaignProvider';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import { CampaignType } from '../../../../types/RewardsLoyalty';

type Props = {
  onEditClick: () => void;
  onSubmitClick: () => void;
};
function CampaignSummaryHeader({ onEditClick, onSubmitClick }: Props) {
  const { selectedCampaign, hasBaseRule, hasRedemptionRule, isCampaignActive } =
    useCampaign();
  const { selectedProgram } = useProgramData();
  const hasImage = () => (selectedCampaign?.campaignImageLink ?? '').length > 0;

  const hasRules = () => (selectedCampaign?.ruleList ?? []).length > 0;

  const canEnableSubmit = () => {
    const isStatusValid = isCampaignActive == false;
    const rulesAdded = hasRules();

    const campaignType = selectedCampaign?.campaignType;

    if (!campaignType) return false;
    switch (campaignType) {
      case CampaignType.POINT_BASED:
        return isStatusValid && rulesAdded && hasBaseRule && hasRedemptionRule;
      case CampaignType.NON_POINT_BASED:
        return isStatusValid && rulesAdded;
    }

    return false;
  };

  return (
    <Stack
      style={{
        backgroundColor: 'white',
        paddingBottom: '12px',
        paddingTop: '12px',
        paddingLeft: '24px',
        paddingRight: '24px',
      }}
      direction="row"
      alignItems={'end'}
      spacing={1}
      justifyContent={'space-between'}
    >
      <Stack direction={'row'} alignItems="center" spacing={2}>
        <Box
          sx={{
            width: '64px',
            height: '64px',
            borderRadius: '14px',
            backgroundColor: hasImage()
              ? 'transparent'
              : 'rgba(225, 225, 225, 1)',
          }}
        >
          {hasImage() && (
            <img
              src={selectedCampaign?.campaignImageLink ?? ''}
              width={'100%'}
              height={'100%'}
            />
          )}
        </Box>
        <Stack spacing={'12px'}>
          <Stack direction={'row'} spacing={1} alignItems="center">
            <VegaTextV2
              fontWeight={700}
              text={selectedCampaign?.campaignName}
              fontSize={'13px'}
              lineHeight={'120%'}
            />
            <VegaPill
              backgroundColor="rgba(238, 234, 251, 1)"
              textColor="rgba(80, 41, 219, 1)"
            >
              <VegaTextV2
                text={`#${selectedCampaign?.id}`}
                fontSize="0.5rem"
                fontWeight={600}
              />
            </VegaPill>
          </Stack>
          <Stack direction={'row'} spacing={3}>
            <VegaFormInputFieldV2 label="Program name" isReadOnly>
              <VegaTextV2 variant="caption">{selectedProgram?.name}</VegaTextV2>
            </VegaFormInputFieldV2>
            <VegaFormInputFieldV2 label="Campaign Type" isReadOnly>
              <VegaTextV2 variant="caption">
                {_.startCase(_.toLower(selectedCampaign?.campaignType ?? '-'))}
              </VegaTextV2>
            </VegaFormInputFieldV2>
            <VegaFormInputFieldV2 label="Redemption Type" isReadOnly>
              <VegaTextV2 variant="caption">
                {selectedCampaign?.redemptionType}
              </VegaTextV2>
            </VegaFormInputFieldV2>
          </Stack>
        </Stack>
      </Stack>
      <VegaConditionalWrapper
        condition={isCampaignActive == false}
        ifTrue={
          <Stack direction={'row'} spacing={1} alignItems="end">
            <VegaPrimaryButton
              size="small"
              text="Edit"
              startIcon={<EditOutlined />}
              variant="text"
              sx={{ color: 'rgba(255, 123, 1, 1)' }}
              onClick={onEditClick}
            />
            <VegaPrimaryButton
              size="small"
              text={'Submit'}
              disabled={canEnableSubmit() == false}
              onClick={onSubmitClick}
            />
          </Stack>
        }
      ></VegaConditionalWrapper>
    </Stack>
  );
}

export default CampaignSummaryHeader;
