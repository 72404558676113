import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { VegaCard, VegaTable } from '../../../../components/common';
import { fetchMCCProgramList } from '../../api/Program';
import { useClientAuth } from '../../../../providers/ClientProvider';

const DUMMY = [
  {
    programId: 'Test Program for mcc client',
    markupFeePlan: 'Markup Demo',
    transactionLimitPlan: 'Transaction Limit Demo',
    walletConfigurationPlan: 'Wallet Demo',
    charges: 'Charges Demo',
  },
  {
    programId: 'Card Program Test2',
    markupFeePlan: 'Markup Demo',
    transactionLimitPlan: 'Transaction Limit Demo',
    walletConfigurationPlan: 'Wallet Demo',
    charges: 'Charges Demo',
  },
];

const ListOfAllocatePolicies = ({ data, mapProgram }: any) => {
  const { clientId } = useClientAuth();
  const [programsList, setProgram] = useState<any[]>([]);

  const getProgramList = async () => {
    fetchMCCProgramList(clientId as string, 0, 1000).then((res) => {
      setProgram(res.data.records);
    });
  };

  useEffect(() => {
    getProgramList();
  }, []);

  const getProgramName = (id: any) => {
    const findProgramName = programsList.find((val: any) => val.id === id);
    return findProgramName.name;
  };

  const columns: GridColDef[] = [
    {
      field: 'programId',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Program Name{' '}
        </Typography>
      ),
      // renderCell: (props: any) => {
      //   return (
      //     <Typography className="font-aspekta-500" variant="body2">
      //       {getProgramName(props.row?.programId)}
      //     </Typography>
      //   );
      // },

      flex: 1,
    },
    {
      field: 'markupFeePlan',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Markup Fee Plan
        </Typography>
      ),
      flex: 1,
      // renderCell: (props: any) => {
      //   return (
      //     <Typography className="font-aspekta-500" variant="body2">
      //       {props?.row?.planDtoList?.find((p: { planType: string; }) => p?.planType === 'MARKUP_FEE_PLAN')?.planName}
      //     </Typography>
      //   );
      // },
    },
    {
      field: 'transactionLimitPlan',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Transaction Limit Plan
        </Typography>
      ),
      flex: 1,
      // renderCell: (props: any) => {
      //   return (
      //     <Typography className="font-aspekta-500" variant="body2">
      //       {props?.row?.planDtoList?.find((p: { planType: string; }) => p?.planType === 'TRANSACTION_LIMIT_PLAN')?.planName}
      //     </Typography>
      //   );
      // },
    },
    {
      field: 'walletConfigurationPlan',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Wallet Configuration Plan{' '}
        </Typography>
      ),
      flex: 1,
      // renderCell: (props: any) => {
      //   return (
      //     <Typography className="font-aspekta-500" variant="body2">
      //       {props?.row?.planDtoList?.find((p: { planType: string; }) => p?.planType === 'WALLET_CONFIGURATION_PLAN')?.planName}
      //     </Typography>
      //   );
      // },
    },
    {
      field: 'charges',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Charges Plan{' '}
        </Typography>
      ),
      flex: 1,
      // renderCell: (props: any) => {
      //   return (
      //     <Typography className="font-aspekta-500" variant="body2">
      //       {props?.row?.planDtoList?.find((p: { planType: string; }) => p?.planType === 'CHARGES_PLAN')?.planName}
      //     </Typography>
      //   );
      // },
    },
  ];
  return (
    <VegaCard fullHeight noPad>
      <VegaTable columnDef={columns} idColumn="programId" data={DUMMY} />
    </VegaCard>
  );
};

export default ListOfAllocatePolicies;
