import { Download, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaLabel from '../../../../components/common/VegaLabel';
import VegaText from '../../../../components/common/VegaText';
import { toLowerCase } from '../../../../constants/commonFunction';
import {
  BLUE,
  BORDER_GRAY,
  COMMON,
  GREEN,
  GREY,
  RED,
} from '../../../../constants/style';
import { CustomerIdentityTypes } from '../../../../enums/commons';
import { ICustomer } from '../../../../types/customers';
import StatusModal from '../../../components/StatusModal';
import { IColumnProps } from '../../../types/applicationManagement';
import { STATUS_DATA } from '../../../variable';
import { getProgramDocsSignedUrl } from '../../api/Program';
import VegaLoader from '../../../../components/common/VegaLoader';
import CorporateTxnDetails from '../CheckerCorporateTransaction/CorporateTxnDetails';

interface IExtendedCus extends ICustomer {
  selected: boolean;
}

interface IProps {
  onStatusChange: (data: any, status: string) => void;
  data: IExtendedCus[];
  setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
  setCustomerData: React.Dispatch<React.SetStateAction<any>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  selectedRows: any;
  currentPage: number;
  loading: boolean;
}

interface IApplicationColumnProps {
  key: keyof ICustomer | string | number;
  header: string;
  flex: number;
}

const COLUMN: IApplicationColumnProps[] = [
  {
    key: 'firstName',
    header: 'Customer Name',
    flex: 1,
  },
  // {
  //   key: 'customerType',
  //   header: 'Customer Type',
  //   flex: 1,
  // },
  {
    key: 'mobileNumber',
    header: 'Mobile Number',
    flex: 1,
  },
  {
    key: 'emailId',
    header: 'Email ID',
    flex: 1,
  },
  {
    key: 'makerCheckerStatus',
    header: 'Status',
    flex: 1,
  },
  {
    key: 'action',
    header: 'Action',
    flex: 1,
  },
];

const ListOfApplicationManagement = ({
  data,
  onStatusChange,
  setCustomerData,
  setSelectedRows,
  setCurrentPage,
  currentPage,
  selectedRows,
  loading,
}: IProps) => {
  console.log('ListOfApplicationManagement', data);
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [selectedData, setSelectedData] = useState<any>([]);
  const [status, setStatus] = useState<string>('');
  const [kycUrl, setKycUrl] = useState<string>('');
  const [panUrl, setPanUrl] = useState<string>('');
  const [passportUrl, setPassportUrl] = useState<string>('');
  const [ticketUrl, setTicketUrl] = useState<string>('');
  const [visaUrl, setVisUrl] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [IDs, setIDs] = useState<number>(-1);

  const [collapsedRows, setCollapsedRows] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const showDetails = (data: any) => {
    setCustomerDetails(data);

    setOpen(!open);
  };
  const decline = () => {
    setOpen(!open);
  };
  const approve = async () => {
    await onStatusChange(customerDetails, status);
    setOpen(!open);
    setStatus('');
  };

  const handleStatusChange = (e: any) => {
    setStatus(e.target.value);
  };

  const getStyle = (card: ICustomer) => {
    if (card.customerId === undefined) {
      return;
    }
    if (selectedRows.includes(card.customerId)) {
      return {
        backgroundColor: '#F6F9FF',
      };
    }
    if (collapsedRows.includes(card.customerId)) {
      return {
        backgroundColor: '#F6F9FF',
      };
    }
  };

  const checkStatus = (
    status: string,
    makerCheckerStatus: string | null,
    value: any
  ) => {
    if (
      status === 'DRAFT' ||
      (status === 'UNDER_REVIEW' && makerCheckerStatus === 'CHECKER_PENDING')
    ) {
      return (
        <VegaContainedButton
          text="Edit"
          onClick={() => showDetails(value)}
          isPrimary
        />
      );
    } else {
      return <VegaContainedButton text="Sent" isPrimary disabled />;
    }
  };
  const filteredData = data.filter(
    (val: any) => val.makerCheckerStatus === 'CHECKER_PENDING'
  );

  const customizeRow = (
    column: IApplicationColumnProps,
    application: ICustomer,
    index: number
  ) => {
    if (column.key === 'makerCheckerStatus') {
      if (!application.makerCheckerStatus) {
        return (
          <td
            style={{
              minWidth: '150px',
              padding: '0 0 0 10px',
              textAlign: 'start',
            }}
          ></td>
        );
      }
      return (
        <td
          style={{
            minWidth: '150px',
            padding: '0 0 0 10px',
            textAlign: 'center',
          }}
        >
          <Box
            className="font-aspekta-400"
            fontSize={14}
            py={0.5}
            sx={{
              ...(application.makerCheckerStatus === 'APPROVED'
                ? {
                    bgcolor: GREEN.light,
                    color: GREEN.dark,
                  }
                : application.makerCheckerStatus === 'REJECTED'
                ? { bgcolor: RED.light, color: RED.dark }
                : {
                    bgcolor: BLUE.light,
                    color: BLUE.lighter,
                  }),

              borderRadius: '5px',
            }}
          >
            {toLowerCase(application.makerCheckerStatus)}
          </Box>
        </td>
      );
    } else if (column.key === 'action') {
      if (!application.makerCheckerStatus) {
        return (
          <td
            style={{
              minWidth: '150px',
              padding: '0 0 0 10px',
              textAlign: 'start',
            }}
          ></td>
        );
      }
      return (
        <td
          style={{
            minWidth: '150px',
            padding: '0 0 0 10px',
            textAlign: 'start',
          }}
        >
          {checkStatus(
            application['status'],
            application['makerCheckerStatus'],
            application
          )}
        </td>
      );
    } else {
      return (
        <td
          style={{
            minWidth: '150px',
            padding: '0 0 0 10px',
            textAlign: 'start',
          }}
        >
          {application[column.key]}
        </td>
      );
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: any
  ) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id: string) => id !== rowId));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectAll(event.target.checked);

    if (event.target.checked) {
      const allRowIds = data
        .filter(
          (card: ICustomer) => card.makerCheckerStatus === 'CHECKER_PENDING'
        )
        .map((row: ICustomer) => row.customerId);

      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
  };

  const toggleCollapse = (rowId: any) => {
    console.log('rowID:: ', rowId);
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((id) => id !== rowId));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  return (
    <>
      <Box component={'div'} sx={{ overflowX: 'auto' }}>
        <table
          style={{
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              background: GREY.light,
            }}
          >
            <tr>
              <th>
                <Checkbox
                  disabled={!filteredData.length}
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  sx={{
                    color: GREY.dark,
                    '&.Mui-checked': {
                      color: GREEN.dark,
                    },
                  }}
                />
              </th>
              {COLUMN.map((column: any) => (
                <th
                  style={{
                    minWidth: '150px',
                    textAlign: 'start',
                    padding: '0 0 0 10px',
                  }}
                >
                  <Typography className="font-aspekta-500">
                    {column.header}
                  </Typography>
                </th>
              ))}
              <th className="font-aspekta-400"></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={12}>
                  <VegaLoader />
                </td>
              </tr>
            ) : data.length ? (
              data.map((value: ICustomer, index: number) => (
                <React.Fragment key={index}>
                  <tr
                    style={{
                      backgroundColor: getStyle(value)?.backgroundColor,
                      borderBottom: collapsedRows.includes(value.customerId)
                        ? 'none'
                        : `2px solid ${GREY.light}`,
                    }}
                  >
                    <td
                      style={{
                        textAlign: 'center',
                        backgroundColor: getStyle(value)?.backgroundColor,
                      }}
                    >
                      <Checkbox
                        disabled={
                          value.makerCheckerStatus !== 'CHECKER_PENDING'
                        }
                        checked={selectedRows.includes(value?.customerId)}
                        onChange={(event) =>
                          handleRowCheckboxChange(event, value.customerId)
                        }
                        sx={{
                          color: GREY.dark,
                          '&.Mui-checked': {
                            color: GREEN.dark,
                          },
                        }}
                      />
                    </td>
                    {COLUMN.map((val: IApplicationColumnProps) =>
                      customizeRow(val, value, index)
                    )}
                    <td style={{}}>
                      <IconButton
                        onClick={() => toggleCollapse(value.customerId)}
                      >
                        <KeyboardArrowDown
                          sx={{
                            transition: 'all 500ms ease-in-out',
                            ...(collapsedRows.includes(value.customerId) && {
                              rotate: '180deg',
                            }),
                          }}
                        />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={12}
                      style={{
                        borderBottom: !collapsedRows.includes(value.customerId)
                          ? 'none'
                          : `2px solid ${BLUE.light}`,
                      }}
                    >
                      {collapsedRows.includes(value.customerId) && (
                        <Box sx={{ p: 3, bgcolor: BLUE.lightest }}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <Box
                                sx={{
                                  border: BORDER_GRAY,
                                  borderRadius: '5px',
                                  p: 2,
                                }}
                              >
                                <Box sx={{ mb: 0.5 }}>
                                  <VegaText text="KYC" />
                                </Box>

                                {value.kycUrl ? (
                                  <Box className="snip1205">
                                    <img
                                      src={kycUrl ?? ''}
                                      alt="hh"
                                      width={200}
                                      height={100}
                                    />
                                    <i style={{ cursor: 'pointer' }}>
                                      <Download />
                                    </i>
                                  </Box>
                                ) : (
                                  'No File'
                                )}
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    my: 0.5,
                                  }}
                                ></Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Box
                                sx={{
                                  border: BORDER_GRAY,
                                  borderRadius: '5px',
                                  p: 2,
                                }}
                              >
                                <Box sx={{ mb: 0.5 }}>
                                  <VegaText text="PAN" />
                                </Box>
                                {value.panUrl ? (
                                  <Box className="snip1205">
                                    <img alt="hh" width={200} height={100} />
                                    <i style={{ cursor: 'pointer' }}>
                                      <Download />
                                    </i>
                                  </Box>
                                ) : (
                                  'No File'
                                )}

                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    my: 0.5,
                                  }}
                                ></Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Box
                                sx={{
                                  border: BORDER_GRAY,
                                  borderRadius: '5px',
                                  p: 2,
                                }}
                              >
                                <Box sx={{ mb: 0.5 }}>
                                  <VegaText text="PASSPORT" />
                                </Box>
                                {value.passportUrl ? (
                                  <Box className="snip1205">
                                    <img alt="hh" width={200} height={100} />
                                    <i style={{ cursor: 'pointer' }}>
                                      <Download />
                                    </i>
                                  </Box>
                                ) : (
                                  'No File'
                                )}
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    my: 0.5,
                                  }}
                                ></Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Box
                                sx={{
                                  border: BORDER_GRAY,
                                  borderRadius: '5px',
                                  p: 2,
                                }}
                              >
                                <Box sx={{ mb: 0.5 }}>
                                  <VegaText text="VISA" />
                                </Box>
                                {value.visaUrl ? (
                                  <Box className="snip1205">
                                    <img alt="hh" width={200} height={100} />
                                    <i style={{ cursor: 'pointer' }}>
                                      <Download />
                                    </i>
                                  </Box>
                                ) : (
                                  'No File'
                                )}
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    my: 0.5,
                                  }}
                                ></Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Box
                                sx={{
                                  border: BORDER_GRAY,
                                  borderRadius: '5px',
                                  p: 2,
                                }}
                              >
                                <Box sx={{ mb: 0.5 }}>
                                  <VegaText text="TICKET" />
                                </Box>
                                {value.ticketUrl ? (
                                  <Box className="snip1205">
                                    <img alt="hh" width={200} height={100} />
                                    <i style={{ cursor: 'pointer' }}>
                                      <Download />
                                    </i>
                                  </Box>
                                ) : (
                                  'No File'
                                )}
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    my: 0.5,
                                  }}
                                ></Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      height: '500px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      className="font-aspekta-450"
                      color={COMMON.darkGray}
                    >
                      No Result
                    </Typography>
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>

      <StatusModal
        header="Status "
        onSubmit={approve}
        open={open}
        radioData={STATUS_DATA}
        name="status"
        value={status}
        onChange={handleStatusChange}
        labelPlacement={'right'}
        handleClose={() => {
          setOpen(!open);
          setStatus('');
        }}
        color={GREEN.dark}
      />
    </>
  );
};

export default ListOfApplicationManagement;
