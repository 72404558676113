import { AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig: AuthProviderProps = {
  authority:
    'https://vb814turbc.execute-api.ap-south-1.amazonaws.com/UAT/SAAS/auth',
  client_id: '4121715261877918',
  client_secret: 'ebhLGAeBj7sp8ckX4L8WejXzB0xWC2hQ',
  scope: 'email profile user openid',
  redirect_uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://sbmcms-uat.esbeeyem.com',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  // redirectUri: 'https://sbm.vegapay.tech'
};

export const oidcProdConfig: AuthProviderProps = {
  authority:
    'https://vct7163r4d.execute-api.ap-south-1.amazonaws.com/prod/SAAS/auth',
  client_id: '8011716452499888',
  client_secret: '1Qfyd7CJwDLFudBm2gLf90IEduclUp1s',
  scope: 'email profile user openid',
  redirect_uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://sbmcms.sbmbank.co.in',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  // redirectUri: 'https://sbm.vegapay.tech'
};
