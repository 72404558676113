import { Box, Stack } from '@mui/material';
import React from 'react';

type IProps = {
  labels: Array<string>;
  colors: Array<string>;
};

const FilledCircle = ({ color }: { color: string }) => (
  <Box
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      width: 20,
      height: 20,
      bgcolor: color,
      borderRadius: '100%',
    }}
  ></Box>
);

function Labels({ colors, labels }: IProps) {
  return (
    <Stack spacing={2} width="140">
      {labels.map((el, index) => {
        return (
          <Stack direction={'row'} spacing={2} alignItems="center">
            <FilledCircle color={colors[index]} />
            <Box>{el}</Box>
          </Stack>
        );
      })}
    </Stack>
  );
}

export default Labels;
