import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import VegaPrimaryButton from '../../../components/common/v2/VegaPrimaryButton';
import VegaTextV2 from '../../../components/common/v2/VegaTextV2';
import CampaignRuleListItem from '../../../components/RewardsLoyalty/Campaigns/CampaignRuleListItem';
import { useCampaign } from '../../../components/RewardsLoyalty/providers/CampaignProvider';
import { CampaignRule, CampaignStatus } from '../../../types/RewardsLoyalty';

type Props = {
  onEdit: (rule: CampaignRule) => void;
  onClick: (rule: CampaignRule) => void;
  onAddRuleClick: () => void;
};
function CampaignRuleList({ onClick, onEdit, onAddRuleClick }: Props) {
  const { loading, selectedCampaign } = useCampaign();
  const [rules, setRules] = useState<CampaignRule[]>([]);

  const isCampaignActive = () =>
    selectedCampaign?.status == CampaignStatus.ACTIVE;

  useEffect(() => {
    setRules(selectedCampaign?.ruleList ?? []);
  }, [selectedCampaign]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const hasRules = () => {
    return rules.length > 0;
  };

  return (
    <div>
      <Stepper orientation="vertical" connector={<VerticalConnector />}>
        {rules.map((item, index) => (
          <Step
            key={index}
            completed
            sx={{
              margin: '0px !important',
              padding: '0px !important',
            }}
          >
            <StepLabel
              style={{ marginLeft: 10 }}
              StepIconComponent={StepIconComponent}
            >
              <CampaignRuleListItem
                key={item.id}
                rule={item}
                onEditClick={function (): void {
                  onEdit(item);
                }}
                onClick={function (): void {
                  onClick(item);
                }}
              />
            </StepLabel>
          </Step>
        ))}
        {isCampaignActive() == false && (
          <Step
            completed
            style={{
              ...(hasRules() && {
                marginTop: '20px',
              }),
            }}
          >
            <StepLabel StepIconComponent={AddRuleStepIconComponent}>
              <Stack direction={'row'}>
                <Box
                  style={{ cursor: 'pointer', paddingRight: '20px' }}
                  onClick={() => {
                    onAddRuleClick();
                  }}
                >
                  <VegaTextV2
                    style={{ marginLeft: 10 }}
                    text="Add Rule"
                    variant="caption"
                    color={'rgba(255, 123, 1, 1)'}
                  />
                </Box>
              </Stack>
            </StepLabel>
          </Step>
        )}
      </Stepper>
    </div>
  );
}

export default CampaignRuleList;

const VerticalConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.lineVertical}`]: {
    position: 'absolute',
    height: '55px',
    transform: 'translateY(-50%)',
    borderRadius: 1,
  },
}));

const StepIconComponent = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(58, 74, 95, 1)',
        height: '5px',
        width: '5px',
        borderRadius: '5px',
      }}
    />
  );
};
const AddRuleStepIconComponent = () => {
  return (
    <div
      style={{
        borderColor: 'rgba(255, 123, 1, 1)',
        border: '1px solid rgba(255, 123, 1, 1)',
        borderRadius: '30px',
        height: '16px',
        width: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        transform: 'translateX(30%)',
      }}
    >
      <Add
        sx={{
          position: 'relative',
          transform: 'translateX(-5%)',
          height: '10px',
          width: '10px',
          color: 'rgba(255, 123, 1, 1)',
        }}
      />
    </div>
  );
};
