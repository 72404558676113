import * as React from 'react';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import VegaPill from './VegaPill';
import { IconButton, Stack } from '@mui/material';
import VegaCheckBoxV2 from './VegaCheckBoxV2';
import VegaTextV2 from './VegaTextV2';
import { Close } from '@mui/icons-material';
import { useEffect } from 'react';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    // fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 8,
  width: 300,
  zIndex: theme.zIndex.modal,
  //   fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${
    theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
  }`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${
      theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
    // fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light'
          ? 'rgba(3, 102, 214, 0.3)'
          : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

export type VegaMultiSelectOption = {
  label: string;
  value: string;
  id?: string;
};

type Props = {
  options: VegaMultiSelectOption[];
  selectedOptions?: VegaMultiSelectOption[];
  onSelect?: (options: VegaMultiSelectOption[]) => void;
  maxTags?: number;
};

export default function VegaMultiSelect({
  options,
  selectedOptions,
  maxTags = 2,
  onSelect,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<VegaMultiSelectOption[]>([]);
  const [pendingValue, setPendingValue] = React.useState<
    VegaMultiSelectOption[]
  >([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleCancelClickOnChip = (chipItem: VegaMultiSelectOption) => {
    setValue((prev) => {
      const updatedValues = prev.filter((item) => item.value != chipItem.value);
      onSelect?.(updatedValues);
      return updatedValues;
    });
  };

  const handleClose = () => {
    setValue(pendingValue);
    onSelect?.(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'vega-label' : undefined;

  useEffect(() => {
    if (!selectedOptions) return;
    setValue(selectedOptions);
  }, [selectedOptions]);

  return (
    <React.Fragment>
      <Box
        style={{
          minHeight: '32px',
          maxHeight: '32px',
          borderRadius: '.5rem',
          border: '1px solid gray',
          display: 'flex',
          overflowX: 'hidden',
        }}
        onClick={handleClick}
      >
        <Stack direction={'row'} spacing={1} alignItems="center" paddingX={1}>
          {value.slice(0, maxTags).map((item) => (
            <VegaPill
              key={item.value}
              backgroundColor="rgba(235, 237, 239, 1)"
              textColor="rgba(82, 82, 82, 1)"
              borderRadius="8"
            >
              <Stack direction={'row'} spacing={1} alignItems="center">
                <VegaTextV2
                  text={item.label}
                  fontWeight={600}
                  fontSize={'10px'}
                />

                <IconButton
                  key={'CloseIcon'}
                  id="closeButton"
                  style={{ height: '12px', width: '12px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCancelClickOnChip(item);
                  }}
                >
                  <Close style={{ height: '12px', width: '12px' }} />
                </IconButton>
              </Stack>
            </VegaPill>
          ))}
          {value.length > maxTags && (
            <VegaTextV2 text={`+${value.length - maxTags}`} variant="caption" />
          )}
        </Stack>
      </Box>

      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              isOptionEqualToValue={(a, b) => {
                if (a.value === b.value) return true;
                return a.id === b.id;
              }}
              onClose={(
                event: React.ChangeEvent<{}>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    border: 'none',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <VegaTextV2 text={option.label} />
                  <VegaCheckBoxV2 checked={selected} onChange={() => {}} />
                </li>
              )}
              options={options}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Search"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
