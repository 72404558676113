import { Divider, Skeleton, Stack } from '@mui/material';
import {
  ListGroupHeadingTypo,
  ListHead2Typo,
  SectionHeadingTypo,
} from '../../common/Typography';
import { IAccountClosureRequestDetails } from '../../../types/accountClosure';
import { useAppSelector } from '../../../store/hook';
import dayjs from 'dayjs';

export type IDetailsV1 = {
  label: string;
  value: string;
};

const AccounCloseRequestDetails = () => {
  const { accCloseDetails, isLoading } = useAppSelector(
    ({ accountClosure }) => ({
      accCloseDetails: accountClosure.accountClosureRequestDetails.payload,
      isLoading:
        accountClosure.accountClosureRequestDetails.apiStatus?.isLoading,
    })
  );

  const reqDetails = structuredDetails(accCloseDetails);

  return (
    <Stack gap={2}>
      <SectionHeadingTypo>Request details</SectionHeadingTypo>
      <Stack direction="row" alignItems="center" gap={3}>
        {isLoading &&
          [1, 2, 3, 4, 5].map((_, idx) => (
            <Stack
              key={idx}
              flexGrow={1}
              flexBasis={0}
              direction="row"
              justifyContent="space-between"
            >
              <Stack gap={0.5} width={'100%'} pr={1}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="10px"
                  sx={{ borderRadius: '5px' }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="10px"
                  sx={{ borderRadius: '5px' }}
                />
              </Stack>
              {4 !== idx && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: '#E1E4EB', height: '12px' }}
                  variant="middle"
                />
              )}
            </Stack>
          ))}
        {!isLoading &&
          reqDetails.map((detail, idx) => (
            <Stack
              key={idx}
              flexGrow={1}
              flexBasis={0}
              direction="row"
              justifyContent="space-between"
            >
              <Stack gap={0.5} pr={1}>
                <ListGroupHeadingTypo sx={{ color: '#676B76' }}>
                  {detail.label}
                </ListGroupHeadingTypo>
                <ListHead2Typo>{detail.value}</ListHead2Typo>
              </Stack>
              {reqDetails.length - 1 !== idx && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: '#E1E4EB', height: '12px' }}
                  variant="middle"
                />
              )}
            </Stack>
          ))}
        {!isLoading &&
          reqDetails.slice(0, 2).map((detail, idx) => (
            <Stack
              key={idx}
              flexGrow={1}
              flexBasis={0}
              direction="row"
              justifyContent="space-between"
              sx={{ visibility: 'hidden' }}
            >
              <Stack gap={0.5} pr={1}>
                <ListGroupHeadingTypo sx={{ color: '#676B76' }}>
                  {detail.label}
                </ListGroupHeadingTypo>
                <ListHead2Typo>{detail.value}</ListHead2Typo>
              </Stack>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ backgroundColor: '#E1E4EB', height: '12px' }}
                variant="middle"
              />
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

function structuredDetails(accCloseDetails?: IAccountClosureRequestDetails) {
  return [
    {
      label: 'Request ID',
      value: accCloseDetails?.id || '--',
    },
    {
      label: 'created on',
      value: accCloseDetails?.createdAt
        ? dayjs(accCloseDetails?.createdAt).format('DD/MM/YYYY')
        : '--',
    },
    {
      label: 'created by',
      value: accCloseDetails?.createdBy || '--',
    },
  ];
}

export default AccounCloseRequestDetails;
