import { useState } from 'react';
import { listBinsByIds } from '../api/bin';
import { getAllocatedBinsForProgram } from '../mcc/Back-office/api/inventory';

import { BinAllocation, CardBin, NewCardBin } from '../types/card';

export const useBins = () => {
  const [loading, setLoading] = useState<boolean>();

  async function fetchAllocatedBinsForProgram(
    programId: string,
    page: number,
    size: number
  ): Promise<BinAllocation[]> {
    try {
      const response = await getAllocatedBinsForProgram({
        programId: programId,
        page,
        size,
      });
      const binIds = response.map((item) => item.binId);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function fetchBinsWithIds(ids: number[]): Promise<NewCardBin[]> {
    try {
      const response = await listBinsByIds(ids);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function fetchBinsForProgram(
    programId: string,
    page: number,
    size: number
  ): Promise<NewCardBin[]> {
    try {
      setLoading(true);
      const allocatedBins = await fetchAllocatedBinsForProgram(
        programId,
        page,
        size
      );
      const binIds = allocatedBins.map((item) => item.binId);
      const bins = await fetchBinsWithIds(binIds);
      console.log('🚀 ~ file: useBins.tsx:53 ~ useBins ~ bins:', bins);
      return bins;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  return {
    fetchAllocatedBinsForProgram,
    fetchBinsForProgram,
    fetchBinsWithIds,
    loading,
  };
};
