import { GovernmentIdType } from '../enums/commons';

export const isValidPan = (pan: string) => {
  const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return regpan.test(pan.toUpperCase());
};

export const VALIDATE_REGEX = {
  CIN_NO:
    /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
  GST_NO: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  NAME: /^(?!.*[-_ ]{2})[A-Za-z_-][A-Za-z .']{2,}$/,
  FULL_NAME: /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z\s]$)/,

  [GovernmentIdType.PAN]: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  [GovernmentIdType.DRIVING_LICENCES]:
    /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
  [GovernmentIdType.AADHAR]: /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/,
};

export const ValidationRules = {
  required: 'This field is required',
};
