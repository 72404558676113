/** @format */

import { Add, DeleteOutline } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { usePolicy } from '../../providers/PolicyProvider';
import { DEFAULT_INSTALLMENT_POLICY } from '../../types/defaultPolicy';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import {
  InstallmentPolicy,
  InterestChargeConfig,
  PolicyComponentType,
  PolicyType,
  TxnToEmiPolicyInterface,
} from '../../types/policy';
import { VegaTextField } from '../common';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import VegaOutlineButton from '../common/VegaOutlineButton';
import VegaSelect from '../common/VegaSelect';
import ColumnsInputGreyHeader from './ColumnsInputGreyHeader';
import PolicyCard from './PolicyCard';
import PolicyCommentCard from './PolicyCommentCard';
import { PolicyFooterActions } from './PolicyFooterActions';
import { PolicyWrapper } from './PolicyWrapper';

const HEADERS = ['Minimum Amount', 'MCC', 'Amortization Type', 'Interest Type'];
const COL_WIDTHS = { xs: [3, 3, 3, 3], sm: [3, 3, 3, 3] };
const CHARGE_CONFIG_HEADERS = ['Duration in months', 'Interest Rate', '', ''];
const CHARGE_CONFIG_COL_WIDTHS = { xs: [4, 4, 0, 0], sm: [4, 4, 0, 0] };

const TxnToEmiPolicy = ({
  initialValue,
  onSubmit,
  isLoading,
  onApprove,
  onReject,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    checkPolicyEditable,
    checkPolicyStatusUpdatable,
    entityAuthStatuses,
    loggedInMakerCheckerUser,
    addCommentOnPolicy,
    updateCommentOnPolicy,
    areAllPoliciesApproved,
    getDefaultPolicy,
  } = usePolicy();
  const [policy, setPolicy] = useState<TxnToEmiPolicyInterface | null>();
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );
  const [installmentPolicies, setIntstallmentPolicies] = useState<
    InstallmentPolicy[]
  >([DEFAULT_INSTALLMENT_POLICY]);

  const handleChange = (
    key: keyof TxnToEmiPolicyInterface,
    value: string | number | InstallmentPolicy[]
  ) => {
    if (!policy) return;
    setPolicy({ ...policy, [key]: value });
  };

  const handleInterestChargeConfigChange = (
    installmentPolicyCardIndex: number,
    index: number,
    key: keyof InterestChargeConfig,
    value: number
  ) => {
    const copy = [
      ...installmentPolicies[installmentPolicyCardIndex].interestChargeConfig,
    ];
    const editedConfig = copy[index];
    copy.splice(index, 1, { ...editedConfig, [key]: value });
    handleInstallmentPolicyChange(
      installmentPolicyCardIndex,
      'interestChargeConfig',
      copy
    );
  };

  const handleMccChange = (installmentPolicyCardIndex: number, mcc: string) => {
    const splitStr = mcc.split(',');
    const normalisdMccArrr = splitStr.map((str) => (str === '' ? '' : +str));
    handleInstallmentPolicyChange(
      installmentPolicyCardIndex,
      'mcc',
      normalisdMccArrr
    );
  };

  const handleRemoveChargeConfigRow = (
    installmentPolicyCardIndex: number,
    index: number
  ) => {
    const copy = [
      ...installmentPolicies[installmentPolicyCardIndex].interestChargeConfig,
    ];
    copy.splice(index, 1);
    handleInstallmentPolicyChange(
      installmentPolicyCardIndex,
      'interestChargeConfig',
      [...copy]
    );
  };

  const handleAddChargeConfigRow = (installmentPolicyCardIndex: number) => {
    const copy = [
      ...installmentPolicies[installmentPolicyCardIndex].interestChargeConfig,
    ];
    const lastConfig = copy[copy.length - 1];
    const newRow = {
      interest_rate: 12,
      duration_in_months: lastConfig.duration_in_months + 1,
    };
    handleInstallmentPolicyChange(
      installmentPolicyCardIndex,
      'interestChargeConfig',
      [...copy, newRow]
    );
  };

  const addInstallmentPolicyCard = () => {
    setIntstallmentPolicies([
      ...installmentPolicies,
      DEFAULT_INSTALLMENT_POLICY,
    ]);
  };

  const deleteInstallmentPolicyCard = (index: number) => {
    const copy = [...installmentPolicies];
    copy.splice(index, 1);
    setIntstallmentPolicies([...copy]);
  };

  const handleInstallmentPolicyChange = (
    index: number,
    key: keyof InstallmentPolicy,
    value: any
  ) => {
    const copy = [...installmentPolicies];
    const edited = copy[index];
    copy.splice(index, 1, { ...edited, [key]: value });
    setIntstallmentPolicies([...copy]);
  };

  function didSumit() {
    if (!policy) return;
    onSubmit({
      ...policy,
      ['installment_policy']: installmentPolicies,
    });
  }

  function didClickApprove() {
    if (policy) onApprove(policy);
  }

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = policy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(policyId);
    const canUpdateStatus = await checkPolicyStatusUpdatable(policyId);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  function fetchDefaultPolicy() {
    getDefaultPolicy(PolicyType.TRANSACTION_TO_INSTALLMENT)
      .then((defaultPolicy) => {
        if (defaultPolicy) {
          const installmentPolicy = defaultPolicy as TxnToEmiPolicyInterface;
          setPolicy(installmentPolicy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    getEntityStatusAndCheckIfCanEdit();
    if (policy) {
      const installmentPolicies = policy.installment_policy ?? [];
      setIntstallmentPolicies(installmentPolicies);
    }
  }, [policy, loggedInMakerCheckerUser]);

  useEffect(() => {
    if (initialValue) {
      setPolicy({ ...initialValue });
    } else {
      fetchDefaultPolicy();
    }
  }, [initialValue]);

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={policy?.policy_id}
      policyView={
        <>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                noMargin
                onPastCommentClick={() => {
                  onPastCommentsClick(policy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
          />

          {installmentPolicies?.length &&
            installmentPolicies.map(
              (item: InstallmentPolicy, cardIndex: number) => {
                return (
                  <PolicyCard
                    title="Transaction To EMI Configuration"
                    onDeleteClick={() => deleteInstallmentPolicyCard(cardIndex)}
                    isLoading={isLoading}
                    isAdd={true}
                    isDelete={true}
                    policyEntityAuth={entityAuthStatus}
                    isSaveDisabled={canEditPolicy == false}
                  >
                    <ColumnsInputGreyHeader
                      headerTexts={HEADERS}
                      columnWidths={COL_WIDTHS}
                    />
                    <Grid container mb={5}>
                      <Grid item xs={3}>
                        <VegaTextField
                          type={'number'}
                          key={'min-amt-txn-to-emi'}
                          value={policy?.minimum_amount ?? ''}
                          onChange={(e) =>
                            handleChange(
                              'minimum_amount',
                              parseFloat(e?.target?.value)
                            )
                          }
                          textFieldWidth={120}
                          disabled={canEditPolicy == false}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <VegaTextField
                          key={'mcc-txn-to-emi'}
                          value={item.mcc?.join(',') || ''}
                          onChange={(e) =>
                            handleMccChange(cardIndex, e.target.value)
                          }
                          textFieldWidth={175}
                          disabled={canEditPolicy == false}
                          placeholder={'comma seperated MCC values'}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <VegaSelect
                          size="small"
                          key={'amortization-type-txn-to-emi'}
                          selected={item.amortizationType}
                          onChange={(selected) =>
                            handleInstallmentPolicyChange(
                              cardIndex,
                              'amortizationType',
                              selected as string
                            )
                          }
                          textFieldWidth={120}
                          options={['ARREAR', 'ADVANCE']}
                          disabled={canEditPolicy == false}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <VegaSelect
                          size="small"
                          key={'interset-type-txn-to-emi'}
                          selected={item.interestType}
                          onChange={(selected) =>
                            handleInstallmentPolicyChange(
                              cardIndex,
                              'interestType',
                              selected as string
                            )
                          }
                          textFieldWidth={200}
                          options={['REDUCING_BALANCE', 'FLAT']}
                          disabled={canEditPolicy == false}
                        />
                      </Grid>
                    </Grid>
                    <ColumnsInputGreyHeader
                      headerTexts={CHARGE_CONFIG_HEADERS}
                      columnWidths={CHARGE_CONFIG_COL_WIDTHS}
                    />
                    {item?.interestChargeConfig?.map((config, index) => {
                      return (
                        <Grid container mb={1}>
                          <Grid item xs={4}>
                            <VegaTextField
                              type={'number'}
                              key={`charge-config-txn-to-emi-duration_in_months-${index}`}
                              value={config.duration_in_months}
                              onChange={(e) =>
                                handleInterestChargeConfigChange(
                                  cardIndex,
                                  index,
                                  'duration_in_months',
                                  parseInt(e?.target?.value)
                                )
                              }
                              textFieldWidth={120}
                              disabled={canEditPolicy == false}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <VegaTextField
                              type={'number'}
                              key={`charge-config-txn-to-emi-interest_rate-${index}`}
                              value={config.interest_rate}
                              onChange={(e) =>
                                handleInterestChargeConfigChange(
                                  cardIndex,
                                  index,
                                  'interest_rate',
                                  parseFloat(e?.target?.value)
                                )
                              }
                              textFieldWidth={120}
                              disabled={canEditPolicy == false}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            {index > 0 && (
                              <IconButton
                                onClick={() =>
                                  handleRemoveChargeConfigRow(cardIndex, index)
                                }
                                disabled={canEditPolicy == false}
                              >
                                <DeleteOutline
                                  fontSize="small"
                                  color={'error'}
                                />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Button
                        onClick={() => handleAddChargeConfigRow(cardIndex)}
                        sx={{ color: 'primary.light' }}
                        disabled={canEditPolicy == false}
                      >
                        + config
                      </Button>
                    </Box>
                  </PolicyCard>
                );
              }
            )}
          <Stack>
            <Box style={{ display: 'flex', justifyContent: 'end' }}>
              <VegaOutlineButton
                text="Add"
                py={1}
                startIcon={<Add />}
                onClick={() => addInstallmentPolicyCard()}
                isPrimary
                loading={isLoading}
                disabled={canEditPolicy == false}
              />
            </Box>
            <PolicyFooterActions
              role={loggedInMakerCheckerUser?.role}
              policy={initialValue}
              onSaveClick={didSumit}
              onApproveClick={didClickApprove}
              isLoading={isLoading ?? false}
              isSaveDisabled={canUpdatePolicyStatus == false}
              onCommentAddClick={addCommentOnEntity}
              policyEntityAuth={entityAuthStatus}
              onPastCommentsClick={onPastCommentsClick}
              onCommentEdit={updateEntityComment}
            />
          </Stack>
        </>
      }
    />
  );
};

export default TxnToEmiPolicy;
