import { Fade, FadeProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props extends FadeProps {
  show?: boolean;
}

function VegaFadeWrapper({ show = false, children }: Props) {
  return (
    <Fade in={show} style={{ display: show ? '' : 'none' }}>
      {children}
    </Fade>
  );
}

export default VegaFadeWrapper;
