import React, { useState } from 'react';
import VegaTabBar from '../../../../components/common/v2/VegaTabBar';
import VegaTabBarItem from '../../../../components/common/v2/VegaTabBarItem';
import { Stack, Typography } from '@mui/material';
import { BLUE } from '../../../../constants/style';
import { VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import {
  IBulkUploadProps,
  IPastUploadFilesProps,
} from '../../../types/applicationManagement';
import VegaLabel from '../../../../components/common/VegaLabel';
import { dateFormat } from '../../../variable';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';

interface IProps {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  selectedTab: number;
  pastUploadFiles: IPastUploadFilesProps[];
  failedPastUploadFiles: IPastUploadFilesProps[];
  passedPastUploadFiles: IPastUploadFilesProps[];
}

const PastUpload = ({
  setSelectedTab,
  selectedTab,
  pastUploadFiles,
  passedPastUploadFiles,
  failedPastUploadFiles,
}: // bulkUploadData,
IProps) => {
  console.log('pastUploadFiles', pastUploadFiles);
  const columnAll = [
    {
      field: 'mobileNumber',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          S.No
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props: any) => {
        console.log('props.row', props);
        return (
          <VegaLabel
            text={props.api.getRowIndex(props.id) + 1}
            color="primary"
          />
        );
      },
    },
    {
      field: 'fileName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          File Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const fileName = props.row.key.split('/')[4];
        return <VegaLabel text={fileName} color="primary" />;
      },
    },
    {
      field: 'dob',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Date Uploaded
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <VegaLabel
            text={
              props.row?.uploadDate ? dateFormat(props.row?.uploadDate) : '--'
            }
            color="primary"
          />
        );
      },
    },

    {
      field: 'action2',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <VegaOutlineButton
            text="Download"
            onClick={() => handleDownload(props.row)}
            isPrimary
          />
        );
      },
      flex: 0.7,
    },
  ];
  const columnPassed = [
    {
      field: 'mobileNumber',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          S.No
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'emailId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          File Name
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'dob',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Date Uploaded
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'dob4',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Rows
        </Typography>
      ),
      flex: 0.5,
    },

    {
      field: 'action2',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="View/Edit"
            // onClick={() => showDetails(props.row, false)}
            isPrimary
          />
        );
      },
      flex: 0.7,
    },
  ];
  const columnFailed = [
    {
      field: 'mobileNumber',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          S.No
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'emailId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          File Name
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'dob',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Date Uploaded
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'dob4',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Rows
        </Typography>
      ),
      flex: 0.5,
    },

    {
      field: 'action2',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="View/Edit"
            // onClick={() => showDetails(props.row, false)}
            isPrimary
          />
        );
      },
      flex: 0.7,
    },
  ];

  const handleDownload = (data: IPastUploadFilesProps) => {
    if (!data.signedUrl) {
      return;
    }
    fetch(data.signedUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a download link using the blob
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'file.csv'; // Set the desired file name here
        link.click();
        URL.revokeObjectURL(url); // Clean up the URL object
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
  };

  const getTableComponent = () => {
    if (selectedTab === 0) {
      return {
        data: pastUploadFiles,
        column: columnAll,
        idColumn: 'key',
        loading: false,
      };
    } else if (selectedTab === 1) {
      return {
        data: passedPastUploadFiles,
        column: columnAll,
        idColumn: 'key',
        loading: false,
      };
    } else {
      return {
        data: failedPastUploadFiles,
        column: columnAll,
        idColumn: 'key',
        loading: false,
      };
    }
  };
  return (
    <>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent="space-between"
        alignItems={'center'}
        style={{ paddingRight: 8 }}
      >
        <VegaTabBar
          value={selectedTab}
          color={BLUE.lighter}
          onChange={(e, selected) => {
            setSelectedTab(selected);
          }}
        >
          <VegaTabBarItem color={BLUE.lighter} label="All"></VegaTabBarItem>
          <VegaTabBarItem color={BLUE.lighter} label="Passed"></VegaTabBarItem>
          <VegaTabBarItem color={BLUE.lighter} label="Failed"></VegaTabBarItem>
        </VegaTabBar>

        {/* <VegaPrimaryButton onClick={() => {}} text={'Submit'} size="small" /> */}
      </Stack>
      <VegaTable
        data={getTableComponent().data}
        columnDef={getTableComponent().column}
        idColumn={getTableComponent().idColumn}
        loading={getTableComponent().loading}
      />
    </>
  );
};

export default PastUpload;
