/** @format */

import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { VegaTextField } from '../common';

const CreateAccount = ({
  showCustomerData,
  handleChange,
  setAutoFill,
  setShowCustomerData,
}: any) => {
  const customerData = [
    {
      key: 'Customer Name',
      value: showCustomerData?.firstName,
    },
    {
      key: 'Mobile',
      value: showCustomerData?.mobileNumber,
    },
    {
      key: 'Email',
      value: showCustomerData?.emailId,
    },
    {
      key: 'ID Number',
      value:
        showCustomerData?.identity && showCustomerData?.identity[0]?.idNumber,
    },
  ];
  return (
    <>
      <Box sx={{ mx: 1, height: '100%' }}>
        <Grid
          container
          direction={'column'}
          sx={{ height: '100%' }}
          justifyContent="space-between"
        >
          <div className="">
            {customerData.map((customer: any, i: any) => (
              <Grid
                key={i}
                container
                sx={{
                  my: 1,
                  borderBottom: '1px solid lightgray',
                  py: 1,
                  px: 2,
                }}
              >
                <Grid item container spacing={2} sx={{ pt: 0 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {customer.key}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {customer.value}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            <Grid sx={{ my: 5 }}>
              <Button
                variant="contained"
                color="info"
                sx={{ width: '100%' }}
                onClick={() => {
                  setAutoFill((preVal: boolean) => !preVal);
                  setShowCustomerData({
                    ...showCustomerData,
                    accountLimit: 10000,
                    perTransactionLimit: 5000,
                  });
                }}
              >
                Derive Account Limit from Underwriting
              </Button>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid
                item
                container
                spacing={2}
                sx={{ pt: 0, alignItems: 'center' }}
              >
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    Account Limit
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <VegaTextField
                    value={showCustomerData.accountLimit}
                    onChange={(e) =>
                      handleChange('accountLimit', +e.target.value)
                    }
                    key="lastName"
                    type={'number'}
                    placeholder="Account Limit"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid
                item
                container
                spacing={2}
                sx={{ pt: 0, alignItems: 'center' }}
              >
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    Per Transaction Limit
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <VegaTextField
                    value={showCustomerData.perTransactionLimit}
                    onChange={(e) =>
                      handleChange('perTransactionLimit', +e.target.value)
                    }
                    key="lastName"
                    type={'number'}
                    placeholder="Per Transation Limit"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default CreateAccount;
