import Box from '@mui/material/Box';
import { useState } from 'react';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  CardAllotmentOrderDto,
  CardIndentationOrderDto,
} from '../../../../types/card';
import { EntityType } from '../../../../types/client';
import CIMSDataGrid from '../../../components/common/CIMS_DataGrid';
import { ApproveRequestModal, RejectRequestModal } from '../Modals';
import {
  CardIndentationRequestAction,
  getCardOrderRequestColumns,
} from './columns';

//TODO To be removed
import { FilterList } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import { useCardIndentationOrder } from '../context/CardIndentationOrderProvider';
import MarkAsDeliveredModal from '../Modals/MarkAsDeliveredModal';
import MarkAsAcknowledged from '../Modals/MarkAsAcknowledgedModal';
import MarkAsLostModal from '../Modals/MarkAsLostModal';

function CardIndentationOrderList() {
  const { pagination, listIndentationOrderQuery } = useCardIndentationOrder();

  const [activeModal, setActiveModal] =
    useState<CardIndentationRequestAction | null>(null);

  const [selectedCardOrder, setSelectedCardOrder] = useState<
    CardAllotmentOrderDto | undefined
  >(undefined);
  const [activeIndentationOrder, setActiveIndentationOrder] = useState<
    CardIndentationOrderDto | undefined
  >(undefined);

  const onModalClose = () => {
    setActiveModal(null);
  };

  const onActionRequested = (
    action: CardIndentationRequestAction,
    data: any
  ) => {
    setActiveIndentationOrder(data);
    setSelectedCardOrder(data);
    setActiveModal(action);
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          mt: 2,
        }}
      >
        <CIMSDataGrid
          data={listIndentationOrderQuery?.data?.records || []}
          columns={getCardOrderRequestColumns({
            triggerAction: onActionRequested,
          })}
          idColumn="id"
          loading={
            listIndentationOrderQuery?.isLoading ||
            listIndentationOrderQuery?.isPreviousData
          }
          {...pagination}
          rowCount={listIndentationOrderQuery?.data?.totalItems}
          paginationMode="server"
          components={{
            Toolbar: () => (
              <Stack
                direction={'row'}
                className="dataGrid"
                alignItems={'center'}
                justifyContent={'flex-end'}
                spacing={2}
              >
                {/* <Stack
                  direction={'row'}
                  alignItems={'center'}
                  gap={1}
                  sx={{ cursor: 'pointer' }}
                >
                  <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
                  <VegaTextV2 fontSize={12} color={'#676B76'}>
                    Filter
                  </VegaTextV2>
                </Stack> */}
                <GridToolbarQuickFilter
                  autoFocus
                  variant="outlined"
                  sx={{
                    bgcolor: 'white',
                    color: '#999DA8',
                    height: '35px',
                    minWidth: '320px',
                    borderRadius: '9px',
                  }}
                />
              </Stack>
            ),
          }}
        />
      </Box>

      <>
        <ApproveRequestModal
          isOpen={activeModal === CardIndentationRequestAction.ApproveRequest}
          handleClose={onModalClose}
          orderData={selectedCardOrder}
        />
        <RejectRequestModal
          isOpen={activeModal === CardIndentationRequestAction.RejectRequest}
          orderData={selectedCardOrder}
          handleClose={onModalClose}
        />
        <MarkAsDeliveredModal
          isOpen={activeModal === CardIndentationRequestAction.MarkAsDelivered}
          orderData={activeIndentationOrder}
          handleClose={onModalClose}
        />
        <MarkAsAcknowledged
          isOpen={activeModal === CardIndentationRequestAction.Acknowledge}
          orderData={activeIndentationOrder}
          handleClose={onModalClose}
        />
        <MarkAsLostModal
          isOpen={activeModal === CardIndentationRequestAction.MarkAsLost}
          orderData={activeIndentationOrder}
          handleClose={onModalClose}
        />
      </>

      {/* <MarkAsShippedModal
        isOpen={activeModal === CardOrderRequestAction.MarkAsShipped}
        handleClose={onModalClose}
        cardAllotmentOrder={selectedCardOrder}
      /> */}
    </>
  );
}

export default CardIndentationOrderList;
