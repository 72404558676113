import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Chip, Divider, Stack, TablePagination } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import * as React from 'react';
import { VegaProgramWithMetaDataType } from '../../../../../types/program';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: 0,
  '&:nth-of-type(even)': {
    backgroundColor: '#f7f9fe',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledChip = styled(Chip)(({ theme, color }) => ({
  borderRadius: '8px',
  backgroundColor: color === 'success' ? green[50] : '',
  color: color === 'success' ? green[600] : '',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //   border: 0,
  textAlign: 'left',
}));

function RowComponent<T extends VegaProgramWithMetaDataType>(props: {
  row: T;
  handleRowClick: (index: number) => void;
  index: number;
  expandedRow: number | null;
}) {
  const { row, handleRowClick, index, expandedRow } = props;
  //   const [open, setOpen] = React.useState(false);

  const open = expandedRow === index;

  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell>{index + 1}</StyledTableCell>
        <StyledTableCell>{row.name}</StyledTableCell>
        <StyledTableCell>{row.type}</StyledTableCell>
        <StyledTableCell>{row.category}</StyledTableCell>
        <StyledTableCell>
          <Typography>{dayjs(row.createdAt).format('DD/MM/YYYY')}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <StyledChip
            icon={
              <FiberManualRecordIcon
                fontSize="small"
                sx={{
                  mr: 1,
                  color: '#11A75C',
                }}
              />
            }
            label={props.row.status}
            color={
              props.row.status === 'PRODUCTION' || props.row.status === 'ACTIVE'
                ? 'success'
                : 'default'
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <Typography>{dayjs(row.updatedAt).format('DD/MM/YYYY')}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(index)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <Divider />
      <StyledTableRow>
        <StyledTableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="checker-maker">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell>MAKER</StyledTableCell>
                    <StyledTableCell>CHECKER</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={row.programId + '_collapse'}>
                    <StyledTableRow />
                    <StyledTableCell>
                      <Stack>{row.maker?.name}</Stack>
                      <Stack>
                        <Typography variant="caption">
                          {row.maker?.designation || 'Manager'}
                        </Typography>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack>{row.checker?.name}</Stack>
                      <Stack>
                        <Typography variant="caption">
                          {row.checker?.designation || 'Manager'}
                        </Typography>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

interface DataGridProps<T> {
  //   columns: Column[];
  rows: T[];
  pageSize?: number;
}

const CollapsibleProgramsTable = <T extends VegaProgramWithMetaDataType>({
  //   columns,
  rows,
  pageSize = 5,
}: DataGridProps<T>) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);
  const [expandedRow, setExpandedRow] = React.useState<number | null>(null);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '10px',
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="body2" className="font-aspekta-500">
                S. No.
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body2" className="font-aspekta-500">
                Program Name
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body2" className="font-aspekta-500">
                Program Type
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body2" className="font-aspekta-500">
                Product
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body2" className="font-aspekta-500">
                Created Date
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body2" className="font-aspekta-500">
                Status
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body2" className="font-aspekta-500">
                Live Date
              </Typography>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <RowComponent
              key={rowsPerPage * page + index}
              row={row}
              handleRowClick={handleRowClick}
              index={rowsPerPage * page + index}
              expandedRow={expandedRow}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
export default CollapsibleProgramsTable;
