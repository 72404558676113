import { EditOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { PolicySelections } from '../../types/policy';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaOutlineButton from '../common/VegaOutlineButton';
import AllPolicySummary from './AllPolicySummary';

interface SubmitToCheckerModalProps {
  onKeepEditingClick: () => void;
  onSubmitToCheckerClick: () => void;
  onClose: () => void;
  open: boolean;
  policies: PolicySelections;
}

function SubmitToCheckerModal({
  onKeepEditingClick,
  onSubmitToCheckerClick,
  open,
  onClose,
  policies,
}: SubmitToCheckerModalProps) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      style={{ maxHeight: '80vh', marginTop: '7.5rem' }}
      scroll="paper"
    >
      <Stack style={{ marginLeft: '1.5rem', marginTop: '1rem' }}>
        <Typography>Submit to checker</Typography>
        <Typography variant="caption">
          Would you like to submit a request to the checker? You will not be
          able to make any changes before receiving a response from the checker.
        </Typography>
      </Stack>
      <DialogContent>
        <AllPolicySummary policies={policies} />
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'}>
          <VegaOutlineButton
            text={'Keep Editing'}
            onClick={() => {
              onKeepEditingClick();
              onClose();
            }}
            isPrimary
            startIcon={<EditOutlined />}
          />
          <VegaContainedButton
            text={'Submit'}
            onClick={() => {
              onSubmitToCheckerClick();
              onClose();
            }}
            isPrimary
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default SubmitToCheckerModal;
