export enum EntityStatusEnum {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REJECTED = 'REJECTED',
}

export enum CheckerMakerStatusEnum {
  REVIEWER_PENDING = 'REVIEWER_PENDING',
  CHECKER_PENDING = 'CHECKER_PENDING',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export enum CustomerTypeEnum {
  RETAIL_CUSTOMER = 'RETAIL_CUSTOMER',
  CORPORATE_CUSTOMER = 'CORPORATE_CUSTOMER',
}

export enum CustomerStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  REJECTED = 'REJECTED',
}
export enum KycStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}
export enum CustomerType {
  DEPENDANT = 'DEPENDANT',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum CustomerIdentityTypes {
  PAN = 'PAN',
  AADHAAR = 'AADHAAR',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENCE = 'DRIVING_LICENCE',
  VOTER_ID = 'VOTER_ID',
}

export enum TransferType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum CompanyTypeEnum {
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  PRIVATE_LIMITED = 'PRIVATE_LIMITED',
  PARTNERSHIP_FIRMS = 'PARTNERSHIP_FIRMS',
}

export enum CONSTANTS {
  SELECTED_VIEW = 'view',
}

export enum CARD_REISSUE_REASON {
  BROKEN = 'BROKEN',
  RENAME = 'RENAME',
  CLIENT_ORDER = 'CLIENT_ORDER',
  SHAVED = 'SHAVED',
  RELIEF_LOSS = 'RELIEF_LOSS',
  LOST = 'LOST',
  ROBBED = 'ROBBED',
  THEFT = 'THEFT',
  UNRECEIVED = 'UNRECEIVED',
  DAMAGED = 'DAMAGED',
  DEFECT = 'DEFECT',
  INCORRECT_NAME = 'INCORRECT_NAME',
}

export enum GovernmentIdType {
  PAN = 'PAN',
  AADHAR = 'AADHAR',
  DRIVING_LICENCES = 'DRIVING_LICENCES',
}
