import axios from 'axios';
import { Campaign, CampaignStatus } from '../types/RewardsLoyalty';

const ENDPOINTS = {
  BASE: '/campaign',
  CREATE: '/',
  UPDATE: '/',
  UPDATE_CAMPAIGN_STATUS: '/{campaignId}/status',
  UPDATE_LOGO: '/{campaignId}/image',
  GET_BY_ID: '/{campaignId}',
  GET_FOR_PROGRAM: '/program/{programId}',
  DELETE_CAMPAIGN: '/{campaignId}',
};

const BASE_URL = ENDPOINTS.BASE;
export class CampaignService {
  static async createCampaign(data: CreateCampaignData): Promise<Campaign> {
    try {
      const endpoint = BASE_URL + ENDPOINTS.CREATE;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateCampaignLogo(data: {
    campaignId: string;
    logo: File;
  }): Promise<Campaign> {
    try {
      const endpoint =
        BASE_URL +
        ENDPOINTS.UPDATE_LOGO.replace('{campaignId}', data.campaignId);
      const formData = new FormData();
      formData.append('file', data.logo);
      const response = await axios.put(endpoint, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCampaignForProgram(programId: string): Promise<Campaign[]> {
    try {
      const endpoint =
        BASE_URL + ENDPOINTS.GET_FOR_PROGRAM.replace('{programId}', programId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCampaign(campaignId: string): Promise<Campaign> {
    try {
      const endpoint =
        BASE_URL + ENDPOINTS.GET_BY_ID.replace('{campaignId}', campaignId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateCampaign(campaign: Campaign): Promise<Campaign> {
    try {
      const endpoint = BASE_URL + ENDPOINTS.UPDATE;
      const response = await axios.put(endpoint, campaign);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteCampaign(campaignId: string) {
    try {
      const endpoint =
        BASE_URL +
        ENDPOINTS.DELETE_CAMPAIGN.replace('{campaignId}', campaignId);
      const response = await axios.delete(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateCampaignStatus(data: {
    campaignId: string;
    status: CampaignStatus;
  }): Promise<Campaign> {
    try {
      const endpoint =
        BASE_URL +
        ENDPOINTS.UPDATE_CAMPAIGN_STATUS.replace(
          '{campaignId}',
          data.campaignId
        );
      const response = await axios.put(
        endpoint,
        {},
        { params: { status: data.status } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

type CreateCampaignData = {
  campaignName: string;
  programId: string;
  campaignType: string;
  redemptionType: string;
};
