import { AddCircleOutline, Search } from '@mui/icons-material';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import { COMMON, PRIMARY } from '../../../constants/style';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { BinCharterRoute } from '../../../routes';
import VegaContainedButton from '../../common/VegaContainedButton';
import VegaOutLineTextField from '../../common/VegaOutLineTextField';
import VegaOutlineButton from '../../common/VegaOutlineButton';
import VegaTextButton from '../../common/VegaTextButton';
import VegaDialogV2 from '../../common/v2/VegaDialogV2';
import VegaFormInputFieldV2 from '../../common/v2/VegaFormInputField';
import VegaProgramSelectV2 from '../../common/v2/VegaProgramSelectV2';
import VegaTextFieldV2 from '../../common/v2/VegaTextFieldV2';
import AddBin from './AddBin';
import ListOfBinCharter from './ListOfBinCharter';

const BinConfiguration = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    bin,
    binRange,
    isProgramOpen,
    isAddBinOpen,
    programOptions,
    loading,
    binData,
    setBinRange,
    setIsBinDetails,
    setIsProgramOpen,
    toggleIsAddBinOpen,
    onChange,
    addProgram,
    addBin,
    navigateTo,
  } = useBinCharter();

  let isNameValid = !!bin.binName && bin.binName.length > 0;
  let isValueValid = !!bin.binValue && bin.binValue.length == 6;
  let isTypeValid = !!bin.binType && bin.binType.length > 0;
  const isNetworkValid = !!bin.network && bin.network.length > 0;

  const disabled = isNameValid && isValueValid && isTypeValid && isNetworkValid;
  return (
    <div>
      <Box
        sx={{
          borderBottom: `1px solid #EEEEEE`,
          bgcolor: 'white',
          px: 3,
          py: 2,
        }}
      >
        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography
            className="font-aspekta-500"
            fontSize={14}
            color={COMMON.gray}
            onClick={() => navigateTo(BinCharterRoute.BIN_DETAILS)}
          >
            Bin Configuration
          </Typography>

          <Stack
            direction={isMobile ? 'column' : 'row'}
            gap={1}
            justifyContent={'end'}
          >
            <Box>
              <VegaOutLineTextField
                key=""
                value={''}
                name={''}
                onChange={() => {}}
                height={38}
                Icon={<Search sx={{ fontSize: 16 }} />}
                placeholder="Search"
              />
            </Box>

            <VegaOutlineButton
              text={'Add bin'}
              mx={0}
              isPurple
              onClick={toggleIsAddBinOpen}
              startIcon={<AddCircleOutline />}
            />
          </Stack>
        </Stack>
      </Box>
      <Box my={2} mx={3}>
        <ListOfBinCharter />
      </Box>
      <VegaDialogV2
        maxWidth="xs"
        open={isProgramOpen}
        handleClose={() => {
          setBinRange({
            programId: '',
            numberOfKits: 0,
          });
          setIsProgramOpen(!isProgramOpen);
        }}
        title="Add program"
        buttonComponent={() => {
          return (
            <>
              <Stack
                direction={'row'}
                gap={1}
                justifyContent="end"
                alignItems={'center'}
              >
                <VegaTextButton
                  text="Cancel"
                  color={PRIMARY.darkBlue}
                  onClick={toggleIsAddBinOpen}
                />
                <VegaContainedButton
                  isPurple
                  text="Add Program"
                  startIcon={<AddCircleOutline />}
                  loading={loading}
                  onClick={addProgram}
                  py={1}
                  // disabled={disabled == false}
                />
              </Stack>
            </>
          );
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <VegaFormInputFieldV2 label="bin Name">
              <VegaTextFieldV2
                fullWidth
                type={'text'}
                key="numberOfKits "
                placeholder="Enter Number Of Kits"
                value={`${binData?.binName}(${toLowerCase(binData?.binType)})`}
                disabled
              />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={12} sm={12}>
            <VegaFormInputFieldV2 label="Select Program">
              <VegaProgramSelectV2
                name="programId"
                selected={binRange?.programId}
                handleChange={(value) => onChange('programId', value)}
              />
            </VegaFormInputFieldV2>
          </Grid>

          <Grid item xs={12} sm={12}>
            <VegaFormInputFieldV2 label="no. of cards">
              <VegaTextFieldV2
                fullWidth
                type={'number'}
                key="numberOfKits "
                placeholder="Enter Number Of Kits"
                value={binRange?.numberOfKits ?? 0}
                onChange={(e: any) =>
                  onChange(
                    'numberOfKits',
                    e.target.value && parseInt(e.target.value)
                  )
                }
              />
            </VegaFormInputFieldV2>
          </Grid>
        </Grid>
      </VegaDialogV2>
      <VegaDialogV2
        title="Add New Bin"
        maxWidth="xs"
        open={isAddBinOpen}
        handleClose={toggleIsAddBinOpen}
        buttonComponent={() => {
          return (
            <>
              <Stack
                direction={'row'}
                gap={1}
                justifyContent="end"
                alignItems={'center'}
              >
                <VegaTextButton
                  text="Cancel"
                  color={PRIMARY.darkBlue}
                  onClick={toggleIsAddBinOpen}
                />
                <VegaContainedButton
                  isPurple
                  text="Add bin"
                  startIcon={<AddCircleOutline />}
                  loading={loading}
                  onClick={() => {
                    addBin();
                    toggleIsAddBinOpen();
                  }}
                  py={1}
                  disabled={disabled == false}
                />
              </Stack>
            </>
          );
        }}
      >
        <AddBin />
      </VegaDialogV2>
    </div>
  );
};

export default BinConfiguration;
