export interface IErrorProps {}

export const ruleValidation = (data: any) => {
  let errorMsg: any = {
    error: {},
    isValid: true,
  };
  if (!data.ruleName) {
    errorMsg.isValid = false;
    errorMsg.error['ruleName'] = 'Enter rule name';
  }

  if (!data.values.maximumPoint) {
    errorMsg.isValid = false;
    errorMsg.error['maximumPoint'] = 'Enter maximum point';
  }
  if (!data.values.onSpends) {
    errorMsg.isValid = false;
    errorMsg.error['onSpends'] = 'Enter value';
  }
  if (!data.values.points) {
    errorMsg.isValid = false;
    errorMsg.error['points'] = 'Enter point';
  }
  if (!data.values.timeframe) {
    errorMsg.isValid = false;
    errorMsg.error['timeframe'] = 'Select time frame';
  }

  return errorMsg;
};
export const mileStoneRuleValidation = (data: any, rule: any) => {
  let errorMsg: any = {
    error: {},
    isValid: true,
  };
  if (!rule.ruleName) {
    errorMsg.isValid = false;
    errorMsg.error['ruleName'] = 'Enter rule name';
  }
  data.forEach((element: any, index: number) => {
    const values = element.values;
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === 'string' && value.trim() === '') {
        if (!errorMsg.error[index]) {
          errorMsg.error[index] = {};
        }
        errorMsg.error[index][key] = `Enter Value`;
        errorMsg.isValid = false;
      }
    });
  });

  return errorMsg;
};

export const nameValidation = (stateValue: string, name: string) => {
  let errorMsg: any = {
    error: {},
    isValid: true,
  };
  const regex = /[^A-Za-z]/;
  if (regex.test(stateValue)) {
    errorMsg.isValid = false;
    errorMsg.error[name] = 'Only letters (alphabets) are allowed!';
  }

  return errorMsg;
};
