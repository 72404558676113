import { useState } from 'react';
import { STROKE_COLOR } from '../../../constants/style';
import { VegaHorizontalSelectorDataType } from '../../common/VegaHorizontalSelector';
import { CorporateCardIcon, CreditCardIcon, UserIcon } from '../../icon/Icons';
import AnalyticsSelection from '../AnalyticsSelection';

import LoanCollection from './LoanCollection';
import LoanDisbursals from './LoanDisbursals';
import RevenueOverview from './RevenueOverview';

export const BUSINESS_OVERVIEWS = {
  LOAN_COLLECTION: 'LOAN_COLLECTION',
  LOAN_DISBURSALS: 'LOAN_DISBURSALS',
  REVENUE: 'REVENUE',
};

const overViewData: VegaHorizontalSelectorDataType[] = [
  {
    id: BUSINESS_OVERVIEWS.LOAN_COLLECTION,
    text: 'Loan collection',
    image: <CorporateCardIcon strokeColor={STROKE_COLOR} />,
    // bgColor: '#D8E7FF',
  },
  {
    id: BUSINESS_OVERVIEWS.LOAN_DISBURSALS,
    text: 'Loan disbursals',
    image: <CreditCardIcon strokeColor={STROKE_COLOR} />,
    // bgColor: '#FFCBE7',
  },
  {
    id: BUSINESS_OVERVIEWS.REVENUE,
    text: 'Revenue',
    image: <UserIcon strokeColor={STROKE_COLOR} />,
    // bgColor: '#FFDBCB',
  },
];
type IProps = {};

function BusinessOverview({}: IProps) {
  const [activeOverView, setActiveOverView] =
    useState<VegaHorizontalSelectorDataType>(overViewData[0]);

  return (
    <>
      <AnalyticsSelection
        heading="Business Overview"
        data={overViewData}
        setActiveState={setActiveOverView}
        active={activeOverView}
      />

      {activeOverView.id === BUSINESS_OVERVIEWS.LOAN_COLLECTION ? (
        <LoanCollection />
      ) : activeOverView.id === BUSINESS_OVERVIEWS.LOAN_DISBURSALS ? (
        <LoanDisbursals />
      ) : (
        <RevenueOverview />
      )}
    </>
  );
}

export default BusinessOverview;
