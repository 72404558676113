import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material';
import { COMMON, PRIMARY } from '../../../constants/style';
import VegaHeader from '../../common/VegaHeader';
import { getLogoForNetwork } from '../../icon/Icons';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { toLowerCase } from '../../../constants/commonFunction';
import { listBinsByIds } from '../../../api/bin';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { IBinDtoProps } from '../../../types/binType';
import { IBinProps } from './BinDetails';
import { NewCardBin } from '../../../types/card';

interface IProps {
  loading: boolean;
  binData: NewCardBin[];
}

const BinDetailsComponent = ({ loading, binData }: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { binDetails, selecteddBinId } = useBinCharter();
  const { setSnackbar } = useSnackbar();

  const BIN_DATA = [
    {
      key: 'bin name',
      value: binData[0]?.binName,
    },
    {
      key: 'bin value',
      value: binData[0]?.binValue,
    },
    {
      key: 'network',
      value: getLogoForNetwork(binData[0]?.network ?? ''),
      // value: binData[0]?.binName,
    },
    {
      key: 'bin type',
      value: toLowerCase(binData[0]?.binType ?? '--'),
    },
    {
      key: 'bin usage',
      value: `${toLowerCase(binData[0]?.productType ?? '--')} Card`,
    },
  ];

  return (
    <>
      <Typography
        className="font-aspekta-500"
        fontSize={13}
        color={COMMON.gray}
      >
        Bin Details
      </Typography>
      <Box
        sx={{
          py: 1,
        }}
      >
        <Grid container>
          {BIN_DATA.map((bin) => (
            <Grid item xs={6} sm={4} md={2} lg={1} xl={1}>
              {loading ? (
                <Skeleton variant="text" height={50} />
              ) : (
                <Box key={bin.key} pb={isMobile ? 2 : 0}>
                  <VegaHeader text={bin.key} mb={0} />
                  <Typography
                    className="font-aspekta-450"
                    color={COMMON.darkGray}
                    fontSize={13}
                  >
                    {bin.value}
                  </Typography>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default BinDetailsComponent;
