import { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import { VegaSelectSelectOptionType } from '../../../components/common/VegaSelect';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { IAddUserState } from '../../Back-office/pages/MCCTeamManagement';
import {
  UserPlatformEnums,
  UserRoleEnums,
  UserTeamType,
  fetchRolesForClient,
  fetchUserWithStatusForClient,
  updateStatusForUser,
} from '../api/roles';
import ListOfTeamManagementChecker from '../components/MCCTeamManagementChecker/ListOfTeamManagementChecker';

const INITIAL_USER_DATA: IAddUserState = {
  clientId: '',
  platformId: '',
  platformName: UserPlatformEnums.FOREX_BRANCH,
  mccUser: true,
  name: '',
  mobileNumber: '',
  email: '',
  address: '',
  officialEmailId: '',
  idType: 'PAN',
  idNumber: '',
  status: 'ACTIVE',
  team: UserTeamType.OPERATIONS,
  roleType: UserRoleEnums.HQ_USER,
  isAdmin: true,
};

const MCCTeamManagementChecker = () => {
  const { clientId, userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [branchUser, setBranchUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rolesList, setRolesList] = useState<VegaSelectSelectOptionType[]>([]);
  // const [userProfile, setUserProfile] = useState<IAddUserState>({
  //   ...INITIAL_USER_DATA,
  // });

  // const handleChange = (key: string, value: string | File | null) => {
  //   setUserProfile({ ...userProfile, [key]: value });
  // };
  // const onClear = () => {
  //   setUserProfile({ ...INITIAL_USER_DATA });
  // };

  // const onAddUser = async () => {
  //   Object.assign(userProfile, { clientId });
  //   const findRoleName = rolesList.find(
  //     (value: any) => value.id === userProfile.roleId
  //   );

  //   setIsLoading(true);
  //   addUser(userProfile)
  //     .then(async (res) => {
  //       const roleForUser = {
  //         userId: res.data?.userId,
  //         roleId: userProfile.roleId,
  //         roleName: findRoleName?.name,
  //         checkerMakerFlag: false,
  //       };
  //       console.log('findRoleName', roleForUser);
  //       addRoleForUser(roleForUser);
  //       setSnackbar('User added!');
  //       setIsLoading(false);

  //       onClear();
  //     })
  //     .catch((err) => {
  //       setSnackbar(
  //         getErrorMessageFromErrorObj(err, 'Failed to add user'),
  //         'error'
  //       );
  //       setIsLoading(false);
  //       onClear();
  //     });
  //   console.log('userProfile', userProfile);
  // };

  const getBranchUserList = async () => {
    setListLoading(true);
    fetchUserWithStatusForClient({
      clientId,
      userRoleStatusList: ['UNDER_REVIEW'],
    })
      .then((res) => {
        const copy = res.data.filter((val: any) => val.userRoles.length && val);
        console.log('copy', copy);
        setBranchUsers(copy);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  const getRoles = async () => {
    fetchRolesForClient(clientId as string)
      .then((res) => {
        const newArr = res.data?.map((val: any) => ({
          name: val.roleName,
          id: val.id,
        }));
        setRolesList(newArr);
      })
      .catch((err) => {});
  };

  const onStatusChange = async (id: string, status: string) => {
    const obj = { status, userId, entityId: id };

    updateStatusForUser(obj)
      .then((res) => {
        setSnackbar('Status change!');
        getBranchUserList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to change status'),
          'error'
        );
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    getBranchUserList();
  }, []);
  return (
    <VegaPage>
      {/* <AddUser
        isLoading={isLoading}
        state={userProfile}
        rolesList={rolesList}
        handleChange={handleChange}
        onClear={onClear}
        onSubmit={onAddUser}
      /> */}
      {/* <MCCFilterSection state={filterData} handleChange={handleChange} /> */}
      <ListOfTeamManagementChecker
        state={branchUser}
        onStatusChange={onStatusChange}
      />
    </VegaPage>
  );
};

export default MCCTeamManagementChecker;
