import { FilterList } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import VegaHeader from '../../../components/common/VegaHeader';
import VegaCheckBoxV2 from '../../../components/common/v2/VegaCheckBoxV2';
import VegaTextV2 from '../../../components/common/v2/VegaTextV2';
import { toLowerCase } from '../../../constants/commonFunction';
import CimsDrawer from '../../components/CimsDrawer';
import CimsButton from '../../components/common/CimsButton';

export enum CardTypeEnum {
  PREPAID = 'PREPAID',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}
export enum StatusEnum {
  ASSIGNED = 'ASSIGNED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
}
export interface CardAllotmentFilter {
  cardType: Record<string, boolean>;
  status: Record<string, boolean>;
  // status: {
  //   [StatusEnum.ASSIGNED]: boolean;
  //   [StatusEnum.SHIPPED]: boolean;
  //   [StatusEnum.DELIVERED]: boolean;
  // };
}

interface IProp {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handFilter: () => void;
}
const FilterCardAllotment = ({
  isOpen,
  onOpen,
  onClose,
  handFilter,
}: IProp) => {
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [search, setSearch] = useState<string>('');

  const {
    control,
    formState: { errors },
    reset,
  } = useFormContext<CardAllotmentFilter>();

  return (
    <>
      {/* <button onClick={onOpen}>Open Drawer</button> */}

      {/* <CIMS_FilterSection
        onFilter={onOpen}
        search={search}
        setSearch={setSearch}
      /> */}
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
      >
        {/* <CIMS_FilterSection search={search} setSearch={setSearch} /> */}
        <Stack
          direction={'row'}
          className="dataGrid"
          alignItems={'center'}
          spacing={2}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            sx={{ cursor: 'pointer' }}
            onClick={onOpen}
          >
            <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
            <VegaTextV2
              // className="font-aspekta-500"
              fontSize={12}
              color={'#676B76'}
            >
              Filter
            </VegaTextV2>
          </Stack>
          <GridToolbarQuickFilter
            autoFocus
            variant="outlined"
            sx={{
              bgcolor: 'white',
              color: '#999DA8',
              height: '35px',
              minWidth: '320px',
              borderRadius: '9px',
            }}
          />
        </Stack>
      </Stack>
      <CimsDrawer
        open={isOpen}
        onClose={onClose}
        header="Filters"
        onSubmit={() => {
          console.log('Submit clicked');
        }}
        footer={
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            flex={1}
            p={1}
          >
            <Button
              onClick={() => {
                reset();
                // handFilter();
                // onClose();
              }}
            >
              Clear All
            </Button>
            <CimsButton
              onClick={() => {
                handFilter();
                onClose();
              }}
            >
              Submit
            </CimsButton>
          </Stack>
        }
      >
        <Stack spacing={3}>
          {/* -------------------- No Card type Filter exists on allocation table ------- */}
          {/* <Stack>
            <VegaHeader mb={0} text={'CARD TYPE'} />
            <Stack color={'#1B1D22'} mt={1} direction={'row'} flexWrap={'wrap'}>
              {Object.values(CardTypeEnum).map((value) => {
                return (
                  <Stack
                    direction={'row'}
                    spacing={0.5}
                    alignItems={'center'}
                    mr={1.5}
                  >
                    <Controller
                      name={'cardType'}
                      control={control}
                      render={({ field }) => (
                        <VegaCheckBoxV2
                          sx={{
                            color: 'blue',
                            '&.MuiButtonBase-root': {
                              pl: 0,
                            },
                          }}
                          checked={field.value[value]}
                          onChange={(e, checked) => {
                            field.onChange({
                              ...field.value,
                              [value]: checked,
                            });
                          }}
                        />
                      )}
                    />

                    <VegaTextV2
                      text={toLowerCase(value)}
                      fontSize={'.9rem'}
                    ></VegaTextV2>
                  </Stack>
                );
              })}
            </Stack>
          </Stack> */}
          <Stack>
            <Stack>
              <VegaHeader mb={0} text={'STATUS'} />
              <Stack
                color={'#1B1D22'}
                mt={1}
                direction={'row'}
                flexWrap={'wrap'}
              >
                {Object.values(StatusEnum).map((value) => {
                  return (
                    <Stack
                      direction={'row'}
                      spacing={0.5}
                      alignItems={'center'}
                      mr={1.5}
                    >
                      <Controller
                        name={'status'}
                        control={control}
                        render={({ field }) => (
                          <VegaCheckBoxV2
                            sx={{
                              color: 'blue',
                              '&.MuiButtonBase-root': {
                                pl: 0,
                              },
                            }}
                            size="small"
                            checked={field.value[value]}
                            onChange={(e, checked) => {
                              field.onChange({
                                ...field.value,
                                [value]: checked,
                              });
                            }}
                          />
                        )}
                      />

                      <VegaTextV2
                        text={toLowerCase(value)}
                        fontSize={'.9rem'}
                      ></VegaTextV2>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* <Divider /> */}
      </CimsDrawer>
    </>
  );
};

export default FilterCardAllotment;
