import { useState } from 'react';
import VegaTabBar from '../../../../components/common/v2/VegaTabBar';
import VegaTabBarItem from '../../../../components/common/v2/VegaTabBarItem';

type Props = {
  onTabSelected: (tab: number) => void;
};

function CampaignConfigurationTabBar({ onTabSelected }: Props) {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  function onTabChange(selected: number) {
    setSelectedTab(selected);
    onTabSelected(selected);
  }

  return (
    <VegaTabBar
      value={selectedTab}
      onChange={(e, selected) => {
        onTabChange(selected);
      }}
    >
      <VegaTabBarItem label={'Point Allocation'} />
      <VegaTabBarItem label="Redemption Rule" />
    </VegaTabBar>
  );
}

export default CampaignConfigurationTabBar;
