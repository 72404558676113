import { Box } from '@mui/material';
import React from 'react';
import {
  BLUE,
  GREEN,
  ORANGE,
  PURPLE,
  RED,
  VIOLET,
} from '../../constants/style';

interface ILabelProps {
  text: string | number;
  color?: 'success' | 'danger' | 'warning' | 'primary' | 'purple';
  mx?: number;
  px?: number;
  py?: number;
}

const VegaLabel = ({
  text,
  color = 'primary',
  mx = 0.5,
  px = 0.5,
  py = 0,
}: ILabelProps) => {
  return (
    <Box
      component={'span'}
      sx={{
        px: px,
        py: py,
        height: 'fit-content',
        borderRadius: '6px',
        mx: mx,
        my: 0.2,
        fontSize: 12,
        ...(color === 'success'
          ? { bgcolor: GREEN.light, color: GREEN.dark }
          : color === 'danger'
          ? { bgcolor: RED.light, color: RED.dark }
          : color === 'warning'
          ? { bgcolor: ORANGE.light, color: ORANGE }
          : color === 'purple'
          ? { bgcolor: PURPLE.violet, color: VIOLET.dark }
          : { bgcolor: BLUE.light, color: BLUE.lighter }),
      }}
    >
      {text}
    </Box>
  );
};

export default VegaLabel;
