import { Edit, EditOutlined, Save } from '@mui/icons-material';
import { Grid, Stack, styled } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import VegaCardV2 from '../../../components/common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../components/common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../../components/common/v2/VegaPrimaryButton';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../components/common/v2/VegaSelectV2';
import VegaSwitchV2 from '../../../components/common/v2/VegaSwitchV2';
import VegaTextFieldV2 from '../../../components/common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../../components/common/v2/VegaTextV2';
import VegaConditionalWrapper from '../../../components/common/VegaConditionalWrapper';
import { useCampaign } from '../../../components/RewardsLoyalty/providers/CampaignProvider';
import {
  CampaignRedemptionRule,
  IterationType,
} from '../../../types/RewardsLoyalty';

enum ViewState {
  READ_ONLY,
  EDIT,
}

type Props = {
  onSubmit: (data: CampaignRedemptionRule) => void;
  value?: CampaignRedemptionRule;
};

function CampaignRedemptionInput({ onSubmit, value }: Props) {
  const { isCampaignActive } = useCampaign();
  const [viewState, setViewState] = useState<ViewState>(ViewState.READ_ONLY);
  const [minPoints, setMinPoints] = useState<number>();
  const [maxPoints, setMaxPoints] = useState<number>();
  const [redemptionDelay, setRedemptionDelay] = useState<number>();
  const [expiry, setExpiry] = useState<number>();
  const [iteration, setIteration] = useState<string>('');
  const [redemptionDelayType, setRedemptionDelayType] = useState<string>('');
  const [expiryType, setExpiryType] = useState<string>('');
  const [enableExpiry, setEnableExpiry] = useState<boolean>(false);
  const [enableDelay, setEnableDelay] = useState<boolean>(false);

  const isInputValid = () => {
    const isMinPointsValid = minPoints != null && minPoints > 0;
    const isMaxPointsValid = maxPoints != null && maxPoints > 0;
    const isIterationValid = iteration != null && iteration.length > 0;
    var isDelayValid: boolean = true;
    var isExpiryValid = true;
    if (enableDelay) {
      const isRedemptionDelayValid =
        redemptionDelay != null && redemptionDelay > 0;
      const isDelayTypeValid =
        redemptionDelayType != null && redemptionDelayType.length > 0;
      isDelayValid = isRedemptionDelayValid && isDelayTypeValid;
    }
    if (enableExpiry) {
      const isRedemptionExpiryValid = expiry != null && expiry > 0;
      const isExpiryTypeValid = expiryType != null && expiryType.length > 0;
      isExpiryValid = isRedemptionExpiryValid && isExpiryTypeValid;
    }
    return (
      isMinPointsValid &&
      isMaxPointsValid &&
      isIterationValid &&
      isDelayValid &&
      isExpiryValid
    );
  };

  function updateViewState(viewState: ViewState) {
    setViewState(viewState);
  }

  function onSaveClick() {
    var data: CampaignRedemptionRule = {
      minRedemptionPoint: minPoints!,
      maxRedemptionPoint: maxPoints!,
      duration: iteration!,
    };
    if (enableDelay) {
      data.redemptionDelay = {
        enable: true,
        number: redemptionDelay!,
        temporalUnit: redemptionDelayType,
      };
    } else {
      data.redemptionDelay = {
        enable: false,
      };
    }

    if (enableExpiry) {
      data.expiry = {
        enable: true,
        number: expiry!,
        temporalUnit: expiryType,
      };
    } else {
      data.expiry = {
        enable: false,
      };
    }

    onSubmit(data);
    updateViewState(ViewState.READ_ONLY);
  }

  useEffect(() => {
    if (value) {
      updateViewState(ViewState.READ_ONLY);
      setMinPoints(value.minRedemptionPoint);
      setMaxPoints(value.maxRedemptionPoint);
      setIteration(value.duration);
      setEnableExpiry(value.expiry?.enable ?? false);
      setExpiry(value.expiry?.number);
      setExpiryType(value.expiry?.temporalUnit ?? '');
      setEnableDelay(value.redemptionDelay?.enable ?? false);
      setRedemptionDelay(value.redemptionDelay?.number);
      setRedemptionDelayType(value.redemptionDelay?.temporalUnit ?? '');
    } else {
      setMinPoints(undefined);
      setMaxPoints(undefined);
      setIteration('');
      setEnableExpiry(false);
      setExpiry(undefined);
      setExpiryType('');
      setEnableDelay(false);
      setRedemptionDelay(undefined);
      setRedemptionDelayType('');
      updateViewState(ViewState.EDIT);
    }
  }, [value]);

  if (viewState == ViewState.READ_ONLY || isCampaignActive) {
    return (
      <ReadOnlyView
        minPoints={minPoints}
        maxPoints={maxPoints}
        redemptionDelay={redemptionDelay}
        iteration={iteration}
        redemptionType={redemptionDelayType}
        expiry={expiry}
        expiryType={expiryType}
        onEditClick={function (): void {
          updateViewState(ViewState.EDIT);
        }}
        showEditButton={isCampaignActive == false}
      />
    );
  }

  return (
    <VegaCardV2>
      <VegaTextV2 text="Redemption Rule" />
      <Grid container columns={4} spacing={1} rowGap={2}>
        <Grid item xs={1}>
          <VegaFormInputFieldV2 label="Min Redemption Points">
            <VegaTextFieldV2
              placeholder="Enter Min Points"
              value={!!minPoints ? minPoints : ''}
              onChange={(e) => {
                setMinPoints(Number(e.target.value));
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={1}>
          <VegaFormInputFieldV2 label="Max Redemption Points">
            <VegaTextFieldV2
              placeholder="Enter Max Points"
              value={!!maxPoints ? maxPoints : ''}
              onChange={(e) => {
                setMaxPoints(Number(e.target.value));
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>

        <Grid item xs={1}>
          <Stack direction={'row'} width="100%" alignItems={'end'}>
            <VegaFormInputFieldV2
              flex={2}
              label="Redemption Delay"
              endAdornment={
                <VegaSwitchV2
                  size="small"
                  checked={enableDelay}
                  onChange={(e) => {
                    setEnableDelay(e.currentTarget.checked);
                  }}
                />
              }
            >
              <StyledTextField
                disabled={enableDelay == false}
                value={!!redemptionDelay ? redemptionDelay : ''}
                onChange={(e) => {
                  setRedemptionDelay(Number(e.target.value));
                }}
              />
            </VegaFormInputFieldV2>
            <StyledSelect
              style={{
                flex: 2,
              }}
              disabled={enableDelay == false}
              placeholder="Select Delay"
              options={timeOptions()}
              value={redemptionDelayType ?? ''}
              onChange={(e) => {
                setRedemptionDelayType(e.target.value as string);
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <VegaFormInputFieldV2 label="Iteration">
            <VegaSelectV2
              options={iterationOptions()}
              value={iteration ?? ''}
              onChange={(e) => {
                setIteration(e.target.value as string);
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={1}>
          <Stack direction={'row'} width="100%" alignItems={'end'}>
            <VegaFormInputFieldV2
              flex={2}
              label="Expiry"
              endAdornment={
                <VegaSwitchV2
                  size="small"
                  checked={enableExpiry}
                  onChange={(e) => {
                    setEnableExpiry(e.currentTarget.checked);
                  }}
                />
              }
            >
              <StyledTextField
                disabled={enableExpiry == false}
                value={!!expiry ? expiry : ''}
                onChange={(e) => {
                  setExpiry(Number(e.target.value));
                }}
              />
            </VegaFormInputFieldV2>
            <StyledSelect
              disabled={enableExpiry == false}
              placeholder="Select Expiry"
              options={timeOptions()}
              value={expiryType ?? ''}
              onChange={(e) => {
                setExpiryType(e.target.value as string);
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack direction={'row'} alignItems="center" justifyContent={'end'}>
        <VegaPrimaryButton
          size="small"
          text="Save"
          startIcon={<Save />}
          disabled={isInputValid() == false}
          onClick={() => onSaveClick()}
        ></VegaPrimaryButton>
      </Stack>
    </VegaCardV2>
  );
}

export default CampaignRedemptionInput;

type ReadonlyViewProps = {
  minPoints?: number;
  maxPoints?: number;
  redemptionDelay?: number;
  redemptionType?: string;
  expiry?: number;
  expiryType?: string;
  iteration?: string;
  onEditClick: () => void;
  showEditButton?: boolean;
};
const ReadOnlyView = ({
  minPoints,
  maxPoints,
  redemptionDelay,
  iteration,
  redemptionType,
  expiry,
  expiryType,
  onEditClick,
  showEditButton = true,
}: ReadonlyViewProps) => {
  return (
    <VegaCardV2>
      <VegaTextV2 text="Redemption Rule" />
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
      >
        <Grid container columns={5}>
          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Min Redemption Points" isReadOnly>
              <VegaTextV2 text={`${minPoints ?? '-'}`} variant="caption" />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Max Redemption Points" isReadOnly>
              <VegaTextV2 text={`${maxPoints ?? '-'}`} variant="caption" />
            </VegaFormInputFieldV2>
          </Grid>

          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Redemption Delay" isReadOnly>
              <VegaTextV2
                text={`${redemptionDelay ?? '-'} ${redemptionType ?? '-'}`}
                variant="caption"
              />
            </VegaFormInputFieldV2>
          </Grid>

          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Iteration" isReadOnly>
              <VegaTextV2 text={`${iteration ?? '-'}`} variant="caption" />
            </VegaFormInputFieldV2>
          </Grid>

          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Expiry" isReadOnly>
              <VegaTextV2
                text={`${expiry ?? '-'} ${expiryType ?? '-'}`}
                variant="caption"
              />
            </VegaFormInputFieldV2>
          </Grid>
        </Grid>
        <VegaConditionalWrapper
          condition={showEditButton}
          ifTrue={
            <VegaPrimaryButton
              size="small"
              text="Edit"
              startIcon={<EditOutlined />}
              variant="text"
              sx={{ color: 'rgba(255, 123, 1, 1)' }}
              onClick={onEditClick}
            />
          }
        />
      </Stack>
    </VegaCardV2>
  );
};

enum TemporalUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
const timeOptions = () =>
  Object.values(TemporalUnit).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const iterationOptions = () =>
  Object.values(IterationType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const StyledSelect = styled(VegaSelectV2)(({ theme }) => {
  return {
    '.MuiOutlinedInput-notchedOutline': {
      borderBottomLeftRadius: '0px',
      borderTopLeftRadius: '0px',
    },
  };
});

const StyledTextField = styled(VegaTextFieldV2)(({ theme }) => {
  return {
    '& .MuiOutlinedInput-root': {
      fieldset: {
        borderRight: 'none',
        borderCollapse: 'collapse',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: '0px',
      },
    },
  };
});
