import { Box } from '@mui/material';
import React, { useState } from 'react';
import { fetchInvoice } from '../../../api/setting';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { NON_ROUTES } from '../../../routes';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { VegaPage } from '../../common';
import VegaPayInvoices from './VegaPayInvoices';

export interface IData {
  clientId: string;
  invoiceS3Link: string;
  month: number;
  year: number;
}

interface IinvoicesProprs {
  data: IData[];
}

const Invoices = ({ data }: IinvoicesProprs) => {
  const { client, clientId } = useClientAuth();
  const [expanded, setExpanded] = React.useState<number | false>(false);
  // const [invoices, setInvoices] = useState<any>([]);
  const { setSnackbar } = useSnackbar();
  const [expandedThirdInvoice, setExpandedThirdInvoice] = React.useState<
    number | false
  >(false);
  const handleChange =
    (year: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? year : false);
      // fetchInvoice(clientId, year)
      //   .then((res) => setInvoices(res.data))
      //   .catch((err) => {
      //     setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      //   });
    };
  const handleChangeThirdInvoices =
    (year: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedThirdInvoice(isExpanded ? year : false);
    };
  return (
    <Box id={NON_ROUTES.INVOICES}>
      <VegaPage>
        <VegaPayInvoices
          text="Vegapay Invoices"
          data={data}
          expanded={expanded}
          handleChange={handleChange}
        />
        <VegaPayInvoices
          text="3rd Party Invoices"
          data={data}
          expanded={expandedThirdInvoice}
          handleChange={handleChangeThirdInvoices}
        />
      </VegaPage>
    </Box>
  );
};

export default Invoices;
