import React, { useEffect, useState } from 'react';
import VegaSelect from '../../components/common/VegaSelect';
import { useClientAuth } from '../../providers/ClientProvider';
import { fetchBranchList } from '../Program-Dashboard/api/Branch';
import { listBinForClient } from '../../api/bin';
import { VegaSelectSelectOptionType } from '../../components/common/VegaSelectSidebar';

interface IProps {
  selected: any;
  handleChange: (value: any) => void;
  name: string;
  label?: string;
  disabled?: boolean;
}

const BinSelector = ({
  name,
  selected,
  handleChange,
  label,
  disabled,
}: IProps) => {
  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [binData, setBinData] = useState<VegaSelectSelectOptionType[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== binData.length) {
        setPage(page + 1);
      }
    }
  };

  const getBinList = async () => {
    listBinForClient(clientId as string, page, 10)
      .then((res) => {
        const binArr = res.data?.records?.map((val: any) => ({
          id: val.id,
          name: val.binName,
        }));
        setBinData(binArr);
        setTotalItem(res.data.totalItems);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBinList();
  }, [page]);
  return (
    <VegaSelect
      placeholder={'Select Bin Value'}
      label={'Select Bin Value'}
      size="small"
      key={name}
      selected={selected ?? ''}
      onChange={(value) => handleChange(value)}
      options={binData}
      onScroll={loadMoreItems}
      disabled={disabled}
    />
  );
};

export default BinSelector;
