import { useMutation } from 'react-query';
import PartnerService from '../../../../../../services/PartnerService';
import { UpdatePartnerRequest } from '../../../../../../types/partner';

export const useUpdatePartner = () => {
  return useMutation({
    mutationFn: (request: UpdatePartnerRequest) => {
      return PartnerService.updatePartner(request);
    },
  });
};
