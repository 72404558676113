import Box from '@mui/material/Box';
import { useMemo, useState } from 'react';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { CardAllotmentOrderDto } from '../../../../types/card';
import { EntityType } from '../../../../types/client';
import CIMSDataGrid from '../../../components/common/CIMS_DataGrid';
import FilterCardOrderRequest, {
  CardOrderRequestTabTypes,
} from '../Filter/FilterCardOrderRequest';
import {
  ApproveRequestModal,
  MarkAsShippedModal,
  RejectRequestModal,
  RevokeRequestModal,
} from '../Modals';
import { CardOrderRequestAction, getCardOrderRequestColumns } from './columns';

//TODO To be removed
import { useCardOrderRequest } from '../context/CardOrderRequestProvider';
import MarkAsDeliveredModal from '../Modals/MarkAsDeliveredModal';
import MarkAsAcknowledged from '../Modals/MarkAsAcknowledgedModal';

function CardOrderRequestList() {
  const { userDivisionType } = useClientAuth();

  const shouldShowTabs = userDivisionType === 'BUSINESS_CORRESPONDENT';

  const { pagination, listAllotmentQuery, activeTab, setActiveTab } =
    useCardOrderRequest();

  const [activeModal, setActiveModal] = useState<CardOrderRequestAction | null>(
    null
  );

  const [selectedCardOrder, setSelectedCardOrder] = useState<
    CardAllotmentOrderDto | undefined
  >(undefined);

  const onModalClose = () => {
    setActiveModal(null);
  };

  const onActionRequested = (
    action: CardOrderRequestAction,
    data: CardAllotmentOrderDto
  ) => {
    setSelectedCardOrder(data);
    setActiveModal(action);
  };

  const columns = useMemo(
    () =>
      getCardOrderRequestColumns({
        activeTab,
        triggerAction: onActionRequested,
      }),
    [activeTab]
  );

  const components = useMemo(
    () => ({
      Toolbar: () => (
        <FilterCardOrderRequest
          onTabChange={setActiveTab}
          activeTab={activeTab}
          shouldShowTabs={shouldShowTabs}
        />
      ),
    }),
    [activeTab, shouldShowTabs]
  );

  return (
    <>
      <Box
        sx={{
          p: 2,
          mt: 2,
        }}
      >
        <CIMSDataGrid
          data={listAllotmentQuery?.data?.records || []}
          columns={columns}
          idColumn="id"
          loading={
            listAllotmentQuery?.isLoading || listAllotmentQuery?.isPreviousData
          }
          {...pagination}
          rowCount={listAllotmentQuery?.data?.totalItems}
          paginationMode="server"
          components={components}
        />
      </Box>
      {activeTab === CardOrderRequestTabTypes.PendingOrders ? (
        <>
          <ApproveRequestModal
            isOpen={activeModal === CardOrderRequestAction.ApproveRequest}
            handleClose={onModalClose}
            orderData={selectedCardOrder}
          />
          <RejectRequestModal
            isOpen={activeModal === CardOrderRequestAction.RejectRequest}
            orderData={selectedCardOrder}
            handleClose={onModalClose}
          />
          <MarkAsDeliveredModal
            isOpen={activeModal === CardOrderRequestAction.MarkAsDelivered}
            handleClose={onModalClose}
            cardAllotmentOrder={selectedCardOrder}
          />
        </>
      ) : (
        <>
          <RevokeRequestModal
            isOpen={activeModal === CardOrderRequestAction.RevokeRequest}
            handleClose={onModalClose}
            cardAllotmentOrder={selectedCardOrder}
          />
          <MarkAsAcknowledged
            isOpen={activeModal === CardOrderRequestAction.Acknowledge}
            handleClose={onModalClose}
            cardAllotmentOrder={selectedCardOrder}
          />
        </>
      )}
      <MarkAsShippedModal
        isOpen={activeModal === CardOrderRequestAction.MarkAsShipped}
        handleClose={onModalClose}
        cardAllotmentOrder={selectedCardOrder}
      />
    </>
  );
}

export default CardOrderRequestList;
