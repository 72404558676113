/** @format */

import { useEffect, useState } from 'react';
import {
  addUserForClient,
  fetchListOfRolesForClient,
  fetchListOfUsersForClient,
} from '../api/roleManagement';
import { VegaPage } from '../components/common';
import CompanyProfile from '../components/Setting/CompanyProfile';
import Media from '../components/Setting/Media';
import Team from '../components/Setting/Team/Team';
import UserProfile from '../components/Setting/UserProfile';
import { useClientAuth } from '../providers/ClientProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import {
  UpdateClientLogoRequestData,
  VegaClientInterface,
} from '../types/client';
import { IRolesProps, IUsersProps } from '../types/userAccess';
import { getErrorMessageFromErrorObj } from '../utils/api';

const INITIAL_USER = {
  clientId: '',
  name: '',
  mobileNumber: '',
  email: '',
  address: '',
  officialEmailId: '',
  idType: '',
  idNumber: '',
  status: 'ACTIVE',
  team: '',
};

export const DEFAULT_YEAR = [2023, 2022];
let dummy: any = [];
const MoreClientOptions = () => {
  const { client, clientId, updateClientLogo } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [users, setUsers] = useState<IUsersProps[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [clientData, setClientData] = useState<VegaClientInterface | null>(
    null
  );
  const [roles, setRoles] = useState<IRolesProps[]>([]);
  const [addUser, setAddUser] = useState<IUsersProps>({
    ...INITIAL_USER,
    clientId,
  });

  const handleChange = function (name: string, value: any, type?: string) {
    // event.preventDefault();
    if (type === 'file') {
      let id = name;
      let file_reader = new FileReader();
      let file = value;
      file_reader.onload = () => {
        setFiles([...files, { file_id: id, fileURL: file_reader.result }]);
      };
      file_reader.readAsDataURL(file);
    } else {
      setClientData((preState: any) => ({ ...preState, [name]: value }));
    }
  };

  const getUsersForClient = async () => {
    await fetchListOfUsersForClient(clientId)
      .then((res) => setUsers(res.data))
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  const onChange = (name: string, value: any) => {
    setAddUser((preState: IUsersProps) => ({
      ...preState,
      [name]: value,
    }));
  };

  const getRolesForClient = async () => {
    await fetchListOfRolesForClient(clientId)
      .then((res) => setRoles(res.data))
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  const updateClientLogos = (logos: UpdateClientLogoRequestData[]) => {
    logos.forEach((item) => updateClientLogo(item));
  };

  useEffect(() => {
    if (clientId) {
      getUsersForClient();
      getRolesForClient();
    }
    dummy = [];
  }, []);

  useEffect(() => {
    setClientData(client);
  }, [client]);

  const submitUser = async (data: IUsersProps) => {
    const newObj = {
      ...data,
      clientId,
    };
    await addUserForClient(newObj)
      .then((res) => {
        setSnackbar('User added!');
        getUsersForClient();
        setAddUser({
          ...INITIAL_USER,
          clientId,
        });
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  return (
    <>
      <VegaPage>
        <CompanyProfile handleChange={handleChange} state={clientData} />
        <Media client={clientData} onSaveLogosClick={updateClientLogos} />
        <UserProfile />
        <Team
          users={users}
          onChange={onChange}
          onSubmit={submitUser}
          state={addUser}
          roles={roles}
        />
      </VegaPage>
    </>
  );
};

export default MoreClientOptions;
