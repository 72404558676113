import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { COMMON, PRIMARY } from '../../../constants/style';
import { GIFT_VOUCHER } from '../../../pages/RewardLoyalty/components/BenefitsInventory';
import { VegaSelectSelectOptionType } from '../../common/VegaSelect';
import { toLowerCase } from '../../../constants/commonFunction';

const VendorList = () => {
  return (
    <Box px={3}>
      <Typography
        color={COMMON.gray}
        fontSize={17}
        className="font-aspekta-600"
      >
        Available Vendors
      </Typography>
      <Box sx={{ display: 'flex', py: 1 }}>
        {GIFT_VOUCHER.map((voucher: VegaSelectSelectOptionType) => (
          <Box
            sx={{
              p: 1,
              bgcolor: PRIMARY.grey,
              borderRadius: '9px',
              textAlign: 'center',
              mx: 0.5,
              cursor: 'pointer',

              minWidth: 100,
            }}
          >
            <IconButton>{voucher.icon}</IconButton>
            <Typography
              className="font-aspekta-500"
              fontSize={14}
              color={COMMON.darkGray}
            >
              {toLowerCase(voucher.name)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default VendorList;
