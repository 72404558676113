import { VegaCard, VegaTable } from '../../common';

import { Button } from '@mui/material';
import { PRIMARY } from '../../../constants/style';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import VegaDrawer from '../../common/VegaDrawer';
import ViewCustomer from '../../customers/ViewCustomer';
import ServiceActions from './ServiceActions';
import useService from './useService';

type IProps = {
  filterData?: {
    key: string;
    value: string;
  };
};

function ServiceRequestManagement(props: IProps) {
  const { filterData = null } = props;

  const { selectedProgram } = useProgramData();

  const {
    serviceTableData,
    ServiceMngColDefs,
    isServiceDrawerOpen,
    serviceDrawerState,
    serviceDrawerData,
    setIsServiceDrawerOpen,
    markAquiredHandler,
    fetchDisputes,
  } = useService({
    filterData: filterData || {
      key: 'PROGRAM_ID',
      value:
        selectedProgram?.programId || '2b1c044c-5c0d-493d-bb55-c8ba5081496a',
      // value : '2b1c044c-5c0d-493d-bb55-c8ba5081496a'
    },
  });
  return (
    <>
      <VegaCard fullHeight noPad sx={{ mt: 4 }}>
        <VegaTable
          data={serviceTableData}
          columnDef={ServiceMngColDefs}
          idColumn="id"
        />
      </VegaCard>

      {serviceDrawerState === 'PROFILE' ? (
        <VegaDrawer
          open={isServiceDrawerOpen}
          onClose={() => setIsServiceDrawerOpen(false)}
          anchor="right"
          isSubmit={true}
          isClose={true}
          onSubmit={() => setIsServiceDrawerOpen(false)}
          submitText="Go It"
          closeText="Decline"
          header="Profile Details"
        >
          <ViewCustomer
            showCustomerData={{ ...serviceDrawerData, account: null }}
          />
        </VegaDrawer>
      ) : (
        <VegaDrawer
          open={isServiceDrawerOpen}
          onClose={() => setIsServiceDrawerOpen(false)}
          anchor="right"
          isSubmit={true}
          // isClose={true}
          onSubmit={() => setIsServiceDrawerOpen(false)}
          submitText="Go It"
          submitButton={
            <Button
              sx={{
                bgcolor: PRIMARY.light,
                ':hover': { bgcolor: PRIMARY.light },
                '&.Mui-disabled': {
                  color: PRIMARY.light,
                  bgcolor: PRIMARY.lighter,
                },
                color: 'white',
              }}
              size="small"
              onClick={() => markAquiredHandler(serviceDrawerData)}
              disabled={serviceDrawerData.disputeStatus === 'ACQUIRED'}
              // disabled={true}
            >
              Mark Acquired
            </Button>
          }
          closeText="Decline"
          header={'SRN- ' + serviceDrawerData.id}
        >
          <ServiceActions
            serviceData={serviceDrawerData}
            setOpen={setIsServiceDrawerOpen}
            fetchDisputes={fetchDisputes}
          />
        </VegaDrawer>
      )}
    </>
  );
}

export default ServiceRequestManagement;
