/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Vega_Dialog from '../../../../components/common/v3/Vega_Dialog';
import { Stack } from '@mui/material';
import Vega_Text from '../../../../components/common/v3/Vega_Text';
import { Close } from '@mui/icons-material';
import Vega_Button from '../../../../components/common/v3/Vega_Button';
import { useProfitLoss } from '../../../../providers/ProfitLossProvider';
import Vega_FormWrapper from '../../../../components/common/v3/Vega_FormWrapper';
import Vega_DatePicker from '../../../../components/common/v3/Vega_DatePicker';

const ProfitLossDownloadReportDialog = () => {
  const { setIsOpenDownloadReportDialog, isOpenDownloadReportDialog } =
    useProfitLoss();
  const handleClose = () => {
    setIsOpenDownloadReportDialog(false);
  };
  return (
    <Vega_Dialog
      fullWidth
      maxWidth="xs"
      open={isOpenDownloadReportDialog}
      handleClose={handleClose}
      headerComponent={() => (
        <>
          <Stack
            p={3}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'start'}
            bgcolor={'#E5F7FC'}
          >
            <Stack gap={1}>
              <Vega_Text
                text={'Download Now'}
                color={'#3A4A5F'}
                fontSize={14}
                fontWeight={500}
              />
            </Stack>
            <Close
              sx={{ color: '#3A4A5F', cursor: 'pointer' }}
              onClick={handleClose}
            />
          </Stack>
        </>
      )}
      buttonComponent={() => (
        <Stack
          direction={'row'}
          gap={1}
          px={2}
          py={2}
          justifyContent={'end'}
          borderTop={'1px solid #E1E4EB'}
          width={'100%'}
        >
          <Vega_Button text="Download" />
        </Stack>
      )}
    >
      <Stack px={3} py={3} gap={2}>
        <Vega_FormWrapper header="start date">
          <Vega_DatePicker onChange={() => {}} />
        </Vega_FormWrapper>
        <Vega_FormWrapper header="end date">
          <Vega_DatePicker onChange={() => {}} />
        </Vega_FormWrapper>
      </Stack>
    </Vega_Dialog>
  );
};

export default ProfitLossDownloadReportDialog;
