import axios, { AxiosResponse } from 'axios';
import { CheckerMakerStatusEnum } from '../../../enums/commons';
import {
  CreateCardRefundOrderReq,
  ICardRefundOrder,
  InitiateCardRefundMakerCheckerReq,
  ListCardRefundOrderRequest,
  UpdateCardRefundOrderRequest,
  UpdateCardRefundOrderStatusRequest,
} from '../../../types/cardRefund';
import { BASE_URL, GETWAY, RATE_MANAGER, TRANSACTION } from './endpoints';

const TRANSACTION_BASE_URL = `${BASE_URL}${GETWAY}${TRANSACTION.BASE}`;
const CURRENCY_CONVERT_URL = `${BASE_URL}${GETWAY}${RATE_MANAGER.CURRENCY_CONVERT}`;
const TRANSACTION_CORPORATE_URL = `${BASE_URL}${GETWAY}${TRANSACTION.CORPORATE}`;

export const fetchListFundLoad = (data: any) => {
  return axios.post(TRANSACTION_BASE_URL + '/listFundLoadOrder', data);
};
export const addFunds = (data: any) => {
  return axios.post(TRANSACTION_BASE_URL + '/fundLoad', data);
};
export const addFundForCorporate = (data: any) => {
  return axios.post(`${TRANSACTION_BASE_URL}/corporate/fundload`, data);
};
export const initiateCheckerMakerForfundLoad = (data: any) => {
  return axios.post(
    TRANSACTION_BASE_URL + '/fundLoad/initiateCheckerMaker',
    data
  );
};
export const updateCheckerMakerForfundLoad = (data: any) => {
  return axios.post(
    TRANSACTION_BASE_URL + '/fundLoad/updateFundLoadOrder',
    data
  );
};
export const updateFundLoadOrderStatus = (data: {
  userId: string;
  fundLoadOrderId: string;
  status: CheckerMakerStatusEnum;
}) => {
  return axios.patch(TRANSACTION_BASE_URL + '/updateFundLoadOrderStatus', data);
};

// currency
export const convertCurrency = (data: any) => {
  return axios.post(CURRENCY_CONVERT_URL, data);
};

//---------------------------------------BULK UPLOAD---------------------------
export const bulkUploadProcessForFundLoad = (formData: any) => {
  return axios.post(`${TRANSACTION_BASE_URL}/bulk/process`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const bulkSaveForFundLoad = (data: any) => {
  return axios.post(`${TRANSACTION_BASE_URL}/bulk/save`, data);
};

export const fetchPastUploadForFundLoad = (branchId: string) => {
  return axios.post(`${TRANSACTION_BASE_URL}/bulk/past/uploads/${branchId}`);
};

// ----------------------------------------REFUND----------------------------------------
export const createCardRefundOrder = (data: CreateCardRefundOrderReq) => {
  return axios.post(TRANSACTION_BASE_URL + '/cardRefund/order', data);
};

export const updateCardRefundOrder = (data: UpdateCardRefundOrderRequest) => {
  return axios.put(TRANSACTION_BASE_URL + '/updateCardRefundOrder', data);
};
export const listCardRefundOrder = (
  data: Partial<ListCardRefundOrderRequest>
): Promise<AxiosResponse<ICardRefundOrder[], any>> => {
  return axios.post(TRANSACTION_BASE_URL + '/listCardRefundOrder', data);
};

export const initiateCardRefundMakerChecker = (
  data: InitiateCardRefundMakerCheckerReq
) => {
  return axios.post(
    TRANSACTION_BASE_URL + '/cardRefund/initiateCheckerMaker',
    data
  );
};
export const updateCardRefundMakerChecker = (
  data: UpdateCardRefundOrderStatusRequest
) => {
  return axios.patch(
    TRANSACTION_BASE_URL + '/updateCardRefundOrderStatus',
    data
  );
};
