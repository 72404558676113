import { MenuItem, Select, Typography } from '@mui/material';

// TODO: @parmar replace this with VegaSelectV2 when Card Management is merged
type Props = {
  selectedValue: number | boolean;
  onChange: (value: number) => void;
};

function BooleanSelect({ selectedValue, onChange }: Props) {
  const getSelectedValue = () => {
    if (typeof selectedValue == 'number') {
      return selectedValue;
    }

    if (typeof selectedValue == 'boolean') {
      const value = selectedValue;
      if (value == false) {
        return 0;
      } else {
        return 1;
      }
    }
  };

  return (
    <Select
      defaultValue={getSelectedValue()}
      onChange={(e) => {
        const value = e.target.value;
        onChange(e.target.value as number);
      }}
      style={{ width: '70px' }}
    >
      <MenuItem key={1} value={1}>
        <Typography fontSize={'15px'} variant="body1">
          Yes
        </Typography>
      </MenuItem>
      <MenuItem key={0} value={0}>
        <Typography fontSize={'15px'} variant="body1">
          No
        </Typography>
      </MenuItem>
    </Select>
  );
}

export default BooleanSelect;
