import { Box, Typography, Grid, SxProps, Theme, Stack } from '@mui/material';
import React from 'react';
import { BORDER_GRAY } from '../../../constants/style';
import { CIMS_BORDER, CIMS_PRIMARY } from '../../assets/style';
import { ArrowForwardIos } from '@mui/icons-material';

interface IProps {
  text: string;
  nestedText?: string;
  component?: JSX.Element;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

const CIMS_Header = ({ text, component, sx, onClick, nestedText }: IProps) => {
  return (
    <Box sx={{ border: CIMS_BORDER.GRAY, pt: 4, px: 2, pb: 1 }}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Box onClick={onClick ? onClick : () => {}}>
              <Typography
                className="font-aspekta-600"
                color={'#222222'}
                fontSize={17}
                sx={{
                  cursor: 'pointer',
                  ...sx,
                }}
              >
                {text}
              </Typography>
            </Box>
            {nestedText && (
              <>
                <ArrowForwardIos
                  sx={{ color: CIMS_PRIMARY.BLUE, fontSize: 10 }}
                />
                <Typography
                  fontSize={13}
                  className="font-aspekta-500"
                  color={CIMS_PRIMARY.BLUE}
                >
                  Configure Policy
                </Typography>
              </>
            )}
          </Stack>
        </Grid>
        {component && (
          <Grid item xs={12} sm={6}>
            {component}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CIMS_Header;
