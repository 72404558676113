/** @format */

import { Box, Grid, TextField } from '@mui/material';
import _ from 'lodash';
import { useClientAuth } from '../../providers/ClientProvider';
import ColumnsInputGreyHeader from './ColumnsInputGreyHeader';

export const DEFAULT_COL_WIDTHS = { xs: [4, 4, 4], sm: [4, 3, 2, 2] };

export type ThreeColInputFieldsColumnWidthType = {
  xs: number[];
  sm: number[];
};

type Props = {
  headerTexts: string[];
  data: any[];
  disabledKeys?: string[];
  columnWidths: ThreeColInputFieldsColumnWidthType;
  onChange: (index: number, key: string, value: string | number) => void;
  min?: number | string;
  max?: number | string;
};

const ThreeColumnInputFields = ({ headerTexts, data, columnWidths, disabledKeys, onChange, min, max }: Props) => {
  // const { isAdmin } = useClientAuth();
  return (
    <Box>
      <ColumnsInputGreyHeader columnWidths={columnWidths} headerTexts={headerTexts} />
      {data.map((object, objectIdx) => {
        const keys = Object.keys(object);

        return (
          <Grid container spacing={4}>
            {keys.map((key, keyIdx) => {
              return (
                <Grid item sx={{ my: 1 }} xs={columnWidths.xs[keyIdx]} sm={columnWidths.sm[keyIdx]}>
                  <TextField
                    sx={{ borderRadius: '9px' }}
                    size="small"
                    value={typeof object[key] === 'string' ? _.startCase(_.toLower(object[key])) : object[key]}
                    disabled={
                      disabledKeys?.includes(key) ||
                      object.transaction_category === 'UNBILLED_EMI' ||
                      object.transaction_category === 'OVERLIMIT'
                      //  || !isAdmin
                    }
                    type={disabledKeys?.includes(key) ? 'text' : 'number'}
                    inputProps={{ min, max, step: 1 }}
                    onChange={(e) => onChange(objectIdx, key, e.target.value)}
                  />
                </Grid>
              );
            })}
            <Grid
              item
              xs={
                columnWidths.xs?.[3] || Math.max(12 - (columnWidths.xs[0] + columnWidths.xs[1] + columnWidths.xs[2]), 0)
              }
              sm={
                columnWidths.sm?.[3] || Math.max(12 - (columnWidths.sm[0] + columnWidths.sm[1] + columnWidths.sm[2]), 0)
              }
            ></Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default ThreeColumnInputFields;
