import { useState } from 'react';
import { VegaTable } from '../../common';
import VegaText from '../../common/VegaText';
import { Typography } from '@mui/material';
import { SUNDAY_HOLIDAYS_MOCK_DATA } from './SundayHolidaysMockData';
import Box from '@mui/material/Box';
import Vega_Text from '../../common/v3/Vega_Text';

const ListOfSundayHolidays = () => {
  const [holidays, setHolidays] = useState<any>(SUNDAY_HOLIDAYS_MOCK_DATA);

  const columnRef = [
    {
      field: 'name',
      headerName: '  Holiday',
      flex: 1,
    },
    {
      field: 'date',
      headerName: '  Date',
      flex: 1,
    },
  ];

  return (
    <>
      <Box sx={{ pt: 3, marginLeft: 3 }}>
        <Vega_Text text="Sundays" fontWeight={500} mb={2} />
      </Box>
      <VegaTable
        noTopRadius
        data={holidays}
        columnDef={columnRef}
        idColumn={'id'}
        pageSize={5}
      />
    </>
  );
};

export default ListOfSundayHolidays;
