import React from 'react';
import { VegaCard } from '../../../../components/common';
import PlanSelection from '../PlanSelection';
import { Grid } from '@mui/material';
import { BORDER_GRAY } from '../../../../constants/style';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import { INewPLanTypeProps, IPLanTypeProps } from '../../../types/plan';

interface IPlanPoliciesCardProps {
  children: JSX.Element;
  plan: IPLanTypeProps | INewPLanTypeProps;
  disabled?: boolean;
  title: string;
  errorMsg?: string;
  onCreate: () => void;
  handleChange: (name: string, value: any) => void;
}

const PlanPoliciesCard = ({
  plan,
  children,
  disabled,
  title,
  errorMsg,
  onCreate,
  handleChange,
}: IPlanPoliciesCardProps) => {
  return (
    <VegaCard>
      <PlanSelection
        state={plan}
        handleChange={handleChange}
        title={title}
        errorMsg={errorMsg}
      />
      {children}
      <Grid container justifyContent={'end'}>
        <VegaContainedButton
          text="Create"
          isPrimary
          onClick={onCreate}
          disabled={disabled}
        />
      </Grid>
    </VegaCard>
  );
};

export default PlanPoliciesCard;
