/** @format */

import axios from 'axios';
import { VegaCreateCustomersType } from '../types/customers';
import { PaginatedResponse } from '../utils/api';
import { BASE_URL, CUSTOMER } from './endpoints';
import { ISupportCustomerDtoProps } from '../types/support';

const CUSTOMER_BASE_URL = BASE_URL + CUSTOMER.BASE;
const CUSTOMER_AUDIT_URL = BASE_URL + CUSTOMER.AUDIT;

export const listForCustomers = (
  programId: string,
  clientId: string,
  page?: any,
  pageSize?: any,
  allAccounts?: boolean
) => {
  return axios.get(CUSTOMER_BASE_URL + CUSTOMER.LIST_WITH_ACCOUNT, {
    params: { programId, clientId, page, pageSize, allAccounts: !!allAccounts },
  });
};

export const listCustomersForClient = (
  clientId: string,
  currentMonth = false
) => {
  return axios.get(
    CUSTOMER_BASE_URL + CUSTOMER.LIST.replace('{clientId}', clientId),
    {
      params: { currentMonth },
    }
  );
};

export const createNewCustomer = (program: VegaCreateCustomersType) => {
  return axios.post(CUSTOMER_BASE_URL, program);
};

export const filterCustomer = (mobileNumber: string, clientId: any) => {
  return axios.get(CUSTOMER_BASE_URL + '/filter', {
    params: { mobileNumber, clientId },
    data: { mobileNumber: mobileNumber, clientId: clientId },
  });
};

export const fetchCustomerByCustomerId = (customerId: string) => {
  return axios.get(CUSTOMER_BASE_URL + '/' + customerId);
};
export const fetchAuditrByCustomerId = (customerId: string) => {
  return axios.get(`${CUSTOMER_AUDIT_URL}?customerId=${customerId}`);
};

type FilterCustomerData = {
  clientId: string;
  programId: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  emailId: string;
};

export async function getFilteredCustomers(
  data: Partial<FilterCustomerData>
): Promise<PaginatedResponse<ISupportCustomerDtoProps>> {
  try {
    const endpoint = CUSTOMER_BASE_URL + '/filter';
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
