import React from 'react';

interface ISwitchProps {
  checked: boolean;
  handleChecked: (value: any) => void;
}

const VegaSwitch = ({ checked, handleChecked }: ISwitchProps) => {
  return (
    <div>
      <label className="switch">
        <input
          type="checkbox"
          id="togBtn"
          onChange={handleChecked}
          checked={checked}
        />
        <div className="slider round">
          <span className="on">Active</span>
          <span className="off">Inactive</span>
        </div>
      </label>
    </div>
  );
};

export default VegaSwitch;
