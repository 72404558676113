import { Box } from '@mui/material';

function isColorWhite(color: string) {
  color = color.trim().toLowerCase();

  return (
    color == '#ffffff' || // Hex color code
    color == 'rgb(255, 255, 255)' || // RGB color
    color == 'rgba(255, 255, 255, 1)' || // RGBA color
    color == 'white' // Plain color name
  );
}

type Props = {
  background: string;
  showBorder?: boolean;
  isSelected?: boolean;
  handleClick?: () => void;
};

function ColorSelectorItem({
  background,
  isSelected,
  showBorder = false,
  handleClick,
}: Props) {
  const isWhite = isColorWhite(background);
  showBorder = isWhite;
  return (
    <Box
      sx={{
        background: background,
        height: '1.25rem',
        width: '1.25rem',
        borderRadius: '1.25rem',
        border: showBorder ? '1px solid #000000' : 'none',
        boxShadow: isSelected ? '0 0 0 2px white, 0 0 0 4px black' : 'none',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        '&:hover': {
          height: '1.4rem',
          width: '1.4rem',
        },
      }}
      onClick={handleClick}
    />
  );
}

export default ColorSelectorItem;
