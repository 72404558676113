import axios from 'axios';
import { BASE_URL, CUSTOMER, OPEN_CREDIT } from './endpoints';

const OPEN_CRDIT_URL = `${BASE_URL}${OPEN_CREDIT.BASE}`;

export const fetchInvoiceListForPostpaidCard = (
  data: Record<string, string> | Object
) => {
  return axios.post(`${OPEN_CRDIT_URL}/invoice/list`, data);
};
export const submitRePayment = (data: Record<string, string> | Object) => {
  return axios.post(`${OPEN_CRDIT_URL}/repayment`, data);
};
export const fetchOpenCreditList = (data: Record<string, string> | Object) => {
  return axios.post(`${OPEN_CRDIT_URL}/list`, data);
};
