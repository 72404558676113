import { Grid } from '@mui/material';
import {
  GiftVoucher,
  GiftVoucherType,
  VoucherInventory,
} from '../../../../../../../../../../types/RewardsLoyalty';
import DiscountVoucherList from './DiscountVoucher/DiscountVoucherList';
import GiftVoucherList from './GiftVoucher/GiftVoucherList';
import SubscriptionVoucherList from './SubscriptionVoucher/SubscriptionVoucherList';

type Props = {
  addVoucher: (data: {
    voucher: Partial<GiftVoucher>;
    type: GiftVoucherType;
  }) => void;
  deleteLastVoucher: (data: { type: GiftVoucherType }) => void;
  updateVoucher: (data: {
    voucher: Partial<GiftVoucher>;
    index: number;
    type: GiftVoucherType;
  }) => void;
  giftVouchers: Partial<GiftVoucher>[];
  discountVouchers: Partial<GiftVoucher>[];
  subscriptionVouchers: Partial<GiftVoucher>[];
  allGiftVouchers: VoucherInventory[];
  allDiscountVouchers: VoucherInventory[];
  allSubscriptionVouchers: VoucherInventory[];
};
function VoucherLists({
  addVoucher,
  updateVoucher,
  deleteLastVoucher,
  giftVouchers,
  discountVouchers,
  subscriptionVouchers,
  allDiscountVouchers,
  allGiftVouchers,
  allSubscriptionVouchers,
}: Props) {
  return (
    <Grid container columns={6.5} justifyContent="space-between">
      <Grid item xs={2}>
        <GiftVoucherList
          vouchers={giftVouchers}
          allAvailableVouchers={allGiftVouchers}
          addVoucher={(voucher) => {
            addVoucher({
              type: GiftVoucherType.GIFT_VOUCHER,
              voucher: voucher,
            });
          }}
          updateVoucher={(data) => {
            updateVoucher({
              voucher: data.updatedVoucher,
              index: data.index,
              type: GiftVoucherType.GIFT_VOUCHER,
            });
          }}
          deleteLastVoucher={function (): void {
            deleteLastVoucher({ type: GiftVoucherType.GIFT_VOUCHER });
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <DiscountVoucherList
          vouchers={discountVouchers}
          allAvailableVouchers={allDiscountVouchers}
          addVoucher={(voucher) => {
            addVoucher({
              type: GiftVoucherType.DISCOUNT_VOUCHER,
              voucher: voucher,
            });
          }}
          updateVoucher={(data) => {
            updateVoucher({
              voucher: data.updatedVoucher,
              index: data.index,
              type: GiftVoucherType.DISCOUNT_VOUCHER,
            });
          }}
          deleteLastVoucher={function (): void {
            deleteLastVoucher({ type: GiftVoucherType.DISCOUNT_VOUCHER });
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <SubscriptionVoucherList
          vouchers={subscriptionVouchers}
          allAvailableVouchers={allSubscriptionVouchers}
          addVoucher={(voucher) => {
            addVoucher({
              type: GiftVoucherType.SUBSCRIPTION,
              voucher: voucher,
            });
          }}
          updateVoucher={(data) => {
            updateVoucher({
              voucher: data.updatedVoucher,
              index: data.index,
              type: GiftVoucherType.SUBSCRIPTION,
            });
          }}
          deleteLastVoucher={function (): void {
            deleteLastVoucher({ type: GiftVoucherType.SUBSCRIPTION });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default VoucherLists;
