import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { PRIMARY } from '../../../constants/style';
import { VegaCreateCustomersType } from '../../../types/customers';
import { VegaToolTipAndCopy } from '../../common/VegaToolTipNCopy';
import { GreenCheckMark } from '../../icon/Icons';

function useApplicationMng() {
  const [isAppDrawerOpen, setIsAppDrawerOpen] = useState(false);

  const [filterOpts, setFilterOpts] = useState({
    customerId: '',
    docType: 'manual',
  });

  const [selectedCustomer, setSelectedCustomer] =
    useState<VegaCreateCustomersType | null>(null);

  const onChange = (key: keyof typeof filterOpts, value: any) => {
    setFilterOpts({ ...filterOpts, [key]: value });
  };

  const AppMngColumnDef: GridColDef[] = [
    {
      field: 'customerId',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Applicaction ID
        </Typography>
      ),
      flex: 1.5,

      renderCell: (props) => (
        <VegaToolTipAndCopy value={props.row.customerId.toString()} />
      ),
    },
    {
      field: 'name',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Name
        </Typography>
      ),
      renderCell: (props) => {
        console.log('props , applicatoin ', props);
        return <Box>{props.row.firstName + ' ' + props.row.lastName}</Box>;
      },
      flex: 1,
    },
    {
      field: 'mobileNumber',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Mobile No
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'emailId',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Email ID
        </Typography>
      ),
      flex: 1.2,
    },
    {
      field: 'kycStatus',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          KYC
        </Typography>
      ),
      flex: 0.6,
      renderCell(props) {
        return (
          <Box
            alignItems={'center'}
            justifyContent="space-between"
            display={'flex'}
            gap={1}
          >
            {props.row.kycStatus === 'VERIFIED' && <GreenCheckMark />}
            Auto
          </Box>
        );
      },
    },
    {
      field: 'DoC',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          DoC
        </Typography>
      ),
      renderCell(params) {
        return <Box>Auto </Box>;
      },
      flex: 0.6,
    },
    {
      field: 'actions',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Button
            size="small"
            sx={{
              bgcolor: PRIMARY.light,
              ':hover': { bgcolor: PRIMARY.light },
              '&.Mui-disabled': {
                color: PRIMARY.light,
                bgcolor: PRIMARY.lighter,
              },
              color: 'white',
            }}
            onClick={() => {
              setSelectedCustomer(props.row);
              setIsAppDrawerOpen(true);
            }}
          >
            View
          </Button>
        );
      },
      flex: 0.8,
    },
  ];

  return {
    filterOpts,
    onFilterChange: onChange,
    AppMngColumnDef,
    isAppDrawerOpen,
    selectedCustomer,
    setIsAppDrawerOpen,
  };
}

export default useApplicationMng;
