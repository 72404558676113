import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, CSSProperties } from 'react';

interface StyledBoxProps extends CSSProperties {
  noPad?: boolean;
  noTopBorderRadius?: boolean;
  noBottomBorderRadius?: boolean;
  fullHeight?: boolean;
  translate?: 'yes' | 'no';
}

export interface VegaCardV2Props extends StyledBoxProps {
  children: ReactNode;
}

const StyledBox = styled(Box)<StyledBoxProps>(
  ({
    border = '1px solid #E1E4EB',
    padding = '.75rem',
    borderRadius = '0.75rem',
    backgroundColor = '#FFFFFF',
    noPad = false,
    fullHeight = false,
    noTopBorderRadius = false,
    noBottomBorderRadius = false,
    display = 'flex',
    flexDirection = 'column',
    gap = '0.75rem',
  }) => ({
    border: border,
    borderRadius: borderRadius,
    padding: padding,
    backgroundColor: backgroundColor,
    display: display,
    flexDirection: flexDirection,
    borderCollapse: 'collapse',
    ...(noTopBorderRadius && {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }),
    ...(noBottomBorderRadius && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
    gap: gap,
    ...(noPad && { p: 0 }),
    ...(fullHeight && { height: '100%' }),
  })
);

function VegaCardV2({ children, ...rest }: VegaCardV2Props) {
  return <StyledBox {...rest}>{children}</StyledBox>;
}

export default VegaCardV2;
