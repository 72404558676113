/* eslint-disable react/jsx-pascal-case */
import { FilterList } from '@mui/icons-material';
import { Badge, Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { listDiscardReasons, searchCards } from '../../../../api/card';
import { VegaToolTipAndCopy } from '../../../../components/common/VegaToolTipNCopy';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  CardDiscardReasonDto,
  CardDto,
  CardStatus,
  ListCardRequest,
} from '../../../../types/card';
import { SearchTextField } from '../../../components/SearchTextField';
import CIMS_Button from '../../../components/common/CIMS_Button';
import CIMS_DataGrid from '../../../components/common/CIMS_DataGrid';
import { IButtonGroupProps } from '../../../components/common/CIMS_Modal';
import AssignKitModal from './AssignKitModal';
import { CardStatusDisplay } from './CardStatusDisplay';
import RejectKitModal from './RejectKitModal';
import { convertCamelCase } from '../../../../utils/stringUtils';
import { DivisionType } from '../../../../types/division';

interface IProps {
  onFilter: () => void;
  filterData: Partial<ListCardRequest>;
}

const getBadgeContent = (filterData: Partial<ListCardRequest>) => {
  return (
    (filterData.cardStatusList?.length || 0) +
    (filterData.cardTypeList?.length || 0) +
    (filterData.programId?.length || 0)
  );
};

const ListOfCardInventory = ({ onFilter, filterData }: IProps) => {
  const { clientId, userDivisionId, userDivisionType } = useClientAuth();
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);

  const [selectedRow, setSelectedRow] = useState<CardDto | null>(null);
  const [isAssign, setIsAssign] = useState<boolean>(false);
  const [isReject, setIsReject] = useState<boolean>(false);

  const searhCardsQuery = useQuery({
    queryKey: [
      'searchCard',
      searchTerm,
      page,
      pageSize,

      ...(filterData.cardStatusList || []),
      ...(filterData.cardTypeList || []),
      ...(filterData.programId || []),
    ],
    queryFn: () => {
      return searchCards({
        value: searchTerm,
        divisionId: userDivisionId,
        includeSubDivision: true,
        cardTypeList: filterData.cardTypeList?.length
          ? filterData.cardTypeList
          : null,
        cardStatusList: filterData.cardStatusList?.length
          ? filterData.cardStatusList
          : null,
        programId: filterData.programId?.length ? filterData.programId : null,
        page: page,
        pageSize: pageSize,
      });
    },
    // select: (data) => {
    //   const { records, ...rest } = data;
    //   return {
    //     ...rest,
    //     records: data.records.map((el, index) => ({
    //       ...el,
    //       sr_no: page * pageSize + index + 1,
    //     })),
    //   };
    // },
    enabled: !!clientId,
    staleTime: 2000,
    keepPreviousData: true,
  });

  const listDiscardReasonsQuery = useQuery({
    queryKey: ['discardReasons'],
    queryFn: () => {
      return listDiscardReasons({
        clientId: clientId || '',
        size: 100,
        page: 0,
      });
    },
    enabled: !!clientId,
    select: (data) => {
      return data.records.reduce((pre, curr) => {
        return {
          ...pre,
          [curr.id]: curr,
        };
      }, {} as Record<string, CardDiscardReasonDto>);
    },
  });

  const COLUMN_DEF: GridColumns<
    CardDto & {
      sr_no: number;
    }
  > = [
    // {
    //   field: 'sr_no',
    //   headerName: 'S.No.',
    //   flex: 0.8,
    //   minWidth: 80,
    //   cellClassName: 'padding',
    //   headerClassName: 'padding',
    //   renderCell: (props) => <VegaToolTipAndCopy value={props.value} />,
    // },
    {
      field: 'cardType',
      headerName: 'Card Type',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => (
        <VegaToolTipAndCopy
          isCopy
          value={props.value ? convertCamelCase(props.value) : '-'}
        />
      ),
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 2.5,
      minWidth: 250,
      valueGetter: (params) => {
        return params.row.programDto?.name;
      },
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },
    {
      field: 'network',
      headerName: 'Card Network',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.network;
      },
      renderCell: (props) => {
        const networks = props.row.network;
        if (!networks || networks.length <= 0) {
          return;
        }
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      },
    },
    {
      field: 'productType',
      headerName: 'Type',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },
    {
      field: 'kitId',
      headerName: 'kit No',
      flex: 1.5,
      minWidth: 100,
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry(MM/YY)',
      description: 'Format of expiry is MM/YY',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => (
        <VegaToolTipAndCopy isCopy value={props.value || '-'} />
      ),
    },

    {
      field: 'updatedAt',
      headerName: 'updated At',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (props) => {
        return dayjs(props.row.updatedAt).format('DD/MM/YYYY');
      },
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
      hide: userDivisionType === 'CLIENT',
    },
    {
      field: 'ownership',
      headerName: 'Ownership',
      flex: 1.5,
      minWidth: 200,
      valueGetter: (props) => {
        const entityCode = props.row.divisionCode
          ? ` (${props.row.divisionCode})`
          : '';
        return (
          toLowerCase(props.row.divisionDto?.divisionType ?? '') + entityCode
        );
      },
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
      hide:
        userDivisionType === 'CLIENT' ||
        userDivisionType === 'BUSINESS_CORRESPONDENT'
          ? false
          : true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => {
        return <CardStatusDisplay status={props.row.cardStatus} />;
      },
    },
    {
      field: 'action',
      headerName: 'Actions/Remark',
      renderCell: (props) => {
        return getActionButtons(
          props.row,
          onReject,
          onAssign,
          listDiscardReasonsQuery.data,
          userDivisionId,
          userDivisionType
        );
      },
      flex: 3,
      minWidth: 300,
      // hide: apiScope?.currrentLevel === Level.CLIENT ? true : false,
    },
  ];

  const toggleAssign = () => {
    if (isAssign) {
      searhCardsQuery.refetch();
    }
    setIsAssign(!isAssign);
    searhCardsQuery.refetch();
  };
  const toggleReject = () => {
    if (isReject) {
      searhCardsQuery.refetch();
    }

    setIsReject(!isReject);
  };

  const onAssign = (data: CardDto) => {
    setSelectedRow(data);
    toggleAssign();
  };
  const onReject = (data: CardDto) => {
    setSelectedRow(data);
    toggleReject();
  };

  const buttons: IButtonGroupProps[] = [
    {
      text: 'submit',
      disabled: false,
      onSubmit: () => {},
      icon: '',
      isOutline: false,
    },
  ];

  return (
    <div>
      {/* <CIMS_FilterSection /> */}
      <CIMS_DataGrid
        data={searhCardsQuery.data?.records || []}
        columns={COLUMN_DEF}
        idColumn={'id'}
        loading={searhCardsQuery.isLoading || searhCardsQuery.isPreviousData}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        page={page}
        onPageChange={(page) => {
          setPage(page);
        }}
        rowCount={searhCardsQuery.data?.totalItems}
        paginationMode="server"
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                my={2}
              >
                <Stack
                  direction={'row'}
                  className="dataGrid"
                  alignItems={'center'}
                  spacing={2}
                >
                  <Badge
                    badgeContent={getBadgeContent(filterData)}
                    color="primary"
                  >
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      gap={1}
                      sx={{ cursor: 'pointer' }}
                      onClick={onFilter}
                    >
                      <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
                      <VegaTextV2 fontSize={12} color={'#676B76'}>
                        Filter
                      </VegaTextV2>
                    </Stack>
                  </Badge>
                  <SearchTextField
                    value={searchTerm}
                    handleChange={setSearchTerm}
                  />
                </Stack>
              </Stack>
            );
          },
        }}
      />
      <AssignKitModal
        handleClose={toggleAssign}
        open={isAssign}
        buttonGroup={buttons}
        data={selectedRow}
      />
      <RejectKitModal
        handleClose={toggleReject}
        open={isReject}
        buttonGroup={buttons}
        data={selectedRow}
      />
    </div>
  );
};

export default ListOfCardInventory;

const getActionButtons = (
  rowData: CardDto,
  onReject: (data: CardDto) => void,
  onAssign: (data: CardDto) => void,
  discardReasons?: Record<string, CardDiscardReasonDto>,
  userDivisionId?: string,
  userDivisionType?: DivisionType
) => {
  // for Level Client - hide the column
  // for Level 1 -
  // can only reject card (if itself is the owner)

  const isOwner = userDivisionId === rowData.divisionId;
  const canUpdateStatus =
    rowData.cardStatus === CardStatus.IN_STOCK && userDivisionType !== 'CLIENT';

  return canUpdateStatus && isOwner ? (
    userDivisionType === 'BUSINESS_CORRESPONDENT' ? (
      <Stack direction={'row'} spacing="0.5rem">
        <CIMS_Button
          text="Reject"
          statusColor="rejected"
          onClick={() => onReject(rowData)}
        />
      </Stack>
    ) : (
      <Stack direction={'row'} spacing="0.5rem">
        <CIMS_Button
          text="Assign kit"
          statusColor="success"
          onClick={() => onAssign(rowData)}
        />
        <CIMS_Button
          text="Reject"
          statusColor="rejected"
          onClick={() => onReject(rowData)}
        />
      </Stack>
    )
  ) : rowData.cardStatus === CardStatus.ISSUED ? (
    rowData.externalCustomerId ? (
      <Stack>
        <VegaToolTipAndCopy
          value={`${toLowerCase(rowData.nameOnCard) || ''} (CIF:${
            rowData.externalCustomerId || ''
          })`}
        ></VegaToolTipAndCopy>
        <VegaToolTipAndCopy
          value={`Account id:${rowData.externalAccountId || ''}`}
        ></VegaToolTipAndCopy>
      </Stack>
    ) : (
      <>-</>
    )
  ) : rowData.cardStatus === CardStatus.REJECTED ||
    rowData.cardStatus === CardStatus.CANCELLED ? (
    <Typography>
      {rowData.discardReasonId &&
      discardReasons &&
      discardReasons[rowData.discardReasonId]
        ? `${
            discardReasons[rowData.discardReasonId]?.discardReasonDisplayText
          } (${
            discardReasons[rowData.discardReasonId]?.discardReasonDescription
          })`
        : 'Discarded '}
    </Typography>
  ) : (
    <>-</>
  );
};
