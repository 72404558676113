/** @format */

import { DeleteOutline } from '@mui/icons-material';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ReactNode } from 'react';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import { AnyPolicyType } from '../../types/policy';
import { VegaCard } from '../common';
import { PolicyFooterActions } from './PolicyFooterActions';

type Props = {
  onSaveClick?: () => void;
  onDeleteClick?: () => void;
  onApproveClick?: () => void;
  onPastCommentsClick?: () => void;
  onCommentAddClick?: (comment: string) => void;
  onCommentEdit?: (commentId: string, comment: string) => void;
  title?: string;
  subtitle?: string;
  children: ReactNode;
  sx?: SxProps<Theme>;
  noPad?: boolean;
  isAdd?: boolean;
  isDelete?: boolean;
  isLoading?: boolean;
  policy?: AnyPolicyType;
  policyEntityAuth?: EntityAuthStatus | null;
  isSaveDisabled?: boolean;
  viewFor?: UserMakerCheckerRole;
};

const PolicyCard = ({
  title,
  subtitle,
  children,
  sx,
  onSaveClick,
  noPad,
  isLoading,
  isAdd,
  isDelete,
  policy,
  onDeleteClick,
  isSaveDisabled,
  onApproveClick,
  viewFor,
  onCommentAddClick,
  policyEntityAuth,
  onPastCommentsClick,
  onCommentEdit,
}: Props) => {
  return (
    <Box>
      <VegaCard sx={sx} noPad={noPad}>
        {(title || subtitle) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ mb: 3 }}>
              {title && (
                <Typography className="font-aspekta-500" variant="body2">
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography variant="caption">{subtitle}</Typography>
              )}
            </Box>
            {isDelete && (
              <IconButton onClick={onDeleteClick} disabled={isSaveDisabled}>
                <DeleteOutline fontSize="small" color={'error'} />
              </IconButton>
            )}
          </Box>
        )}
        {children}

        {onSaveClick && !isAdd && (
          <PolicyFooterActions
            role={viewFor}
            policy={policy}
            onSaveClick={onSaveClick}
            onApproveClick={onApproveClick}
            isLoading={isLoading ?? false}
            isSaveDisabled={isSaveDisabled ?? false}
            onCommentAddClick={onCommentAddClick}
            policyEntityAuth={policyEntityAuth}
            onPastCommentsClick={onPastCommentsClick}
            onCommentEdit={onCommentEdit}
          />
        )}
      </VegaCard>
    </Box>
  );
};

export default PolicyCard;
