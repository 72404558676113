/** @format */

import { Typography } from '@mui/material';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import {
  ResourceType,
  useInstitutionalResources,
} from '../../hooks/useInstitutionalResources';
import { Mcg } from '../../types/resources';
import VegaSearchBy from '../common/VegaSearchBy';
import VegaTable from '../common/VegaTable';
import VegaPrimaryButton from '../common/v2/VegaPrimaryButton';
import VegaTextV2 from '../common/v2/VegaTextV2';
import EditCategoryRiskModal from './EditCategoryRiskModal';
import { VegaCard } from '../common';

type EditCategoryRiskModalData = {
  show: boolean;
  category: Mcg | null;
};

function McgTable() {
  const { loading, fetchResource, updateMcgRiskProfile } =
    useInstitutionalResources();
  const [mcgs, setMcgs] = useState<Mcg[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [filter, setFilter] = useState<{ search: string; searchBy: string }>({
    search: '',
    searchBy: MCG_CLOUMNS[0].value,
  });
  const [showEditCategory, setShowEditCategory] =
    useState<EditCategoryRiskModalData>({
      show: false,
      category: null,
    });

  function closeEditCategoryModal() {
    setShowEditCategory({
      show: false,
      category: null,
    });
  }

  async function onSubmit(risk: string) {
    const id = showEditCategory.category?.id;
    if (!id) return;
    setShowEditCategory({
      show: false,
      category: null,
    });

    try {
      const response = await updateMcgRiskProfile({
        id: id,
        riskProfile: risk,
      });
      const updatedMcgs = mcgs.map((item) => {
        if (item.id == id) return response;
        return item;
      });
      setMcgs(updatedMcgs);
    } catch (error) {
      console.error(error);
    }
  }

  const columnMcg = [
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      renderCell: (props: any) => {
        const category = props.row as Mcg;
        const displayText = _.startCase(_.toLower(category.name));
        return <VegaTextV2 text={displayText} />;
      },
    },
    {
      field: 'riskProfile',
      headerName: 'Risk Profile',
      renderCell: (props: any) => {
        const riskProfile = props.row.riskProfile;
        const displayText = riskProfile
          ? _.startCase(_.toLower(riskProfile))
          : '-';

        return <VegaTextV2 text={displayText} />;
      },
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (props: any) => {
        return (
          <VegaPrimaryButton
            text={'Edit'}
            onClick={() =>
              setShowEditCategory({
                show: true,
                category: props.row,
              })
            }
          />
        );
      },
      flex: 1,
    },
  ];

  async function fetchMcgs() {
    try {
      const response = await fetchResource<Mcg>({
        type: ResourceType.MCG,
        page: page,
        pageSize: pageSize,
        searchBy: filter.searchBy,
        searchQuery: filter.search.toUpperCase(),
      });
      setMcgs(response.records);
      setRowCount(response.totalItems);
    } catch (error) {}
  }

  const onFilterChange = (name: string, value: string) => {
    if (name === 'searchBy') {
      setFilter((preVal: any) => ({
        ...preVal,
        ['search']: '',
        ['searchBy']: value,
      }));
    } else {
      setFilter((preVal: any) => ({
        ...preVal,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    fetchMcgs();
  }, [page, pageSize, filter.search, filter.searchBy]);

  return (
    <div>
      <VegaSearchBy
        placeholder="Search"
        data={MCG_CLOUMNS}
        searchByname="searchBy"
        searchName="search"
        key="search"
        state={filter}
        handleChange={onFilterChange}
      />
      <div>
        <VegaCard noPad>
          <VegaTable
            data={mcgs}
            columnDef={columnMcg}
            idColumn={'id'}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            page={page}
            pageSize={pageSize}
            rowCount={rowCount}
            paginationMode="server"
            loading={loading}
          />
        </VegaCard>
        <EditCategoryRiskModal
          open={showEditCategory.show}
          onClose={function (): void {
            closeEditCategoryModal();
          }}
          category={showEditCategory.category}
          onSubmit={function (riskProfile: string): void {
            onSubmit(riskProfile);
          }}
        />
      </div>
    </div>
  );
}

export default McgTable;

const MCG_CLOUMNS = [
  {
    text: 'Category',
    value: 'category',
  },
  {
    text: 'Risk Profile',
    value: 'riskProfile',
  },
];
