import { GridColumns } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import Vega_Datagrid from '../../../../../common/v3/Vega_Datagrid';
import { useSupport } from '../../../../../../providers/SupportProvider';
import {
  getActiveCardList,
  getPhysicalCardOrders,
} from '../../../../../../api/card';
import { Address, PhysicalCardOrder } from '../../../../../../types/card';
import { dateFormat } from '../../../../../../mcc/variable';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import Vega_Button from '../../../../../common/v3/Vega_Button';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import Vega_Dialog from '../../../../../common/v3/Vega_Dialog';
import {
  CancelIcon,
  DelayIcon,
  Verify2Icon,
} from '../../../../icons/SupportIcons';
import { Box, Collapse, Stack } from '@mui/material';
import { Close, KeyboardArrowRightOutlined } from '@mui/icons-material';

interface IActiveCardsProps {
  data: PhysicalCardOrder[];
  loading: boolean;
}

const ListOfActiveCardOrders = () => {
  const { accountData } = useSupport();
  const [show, setShow] = useState<boolean>(false);
  const [activeCardsListState, setActiveCardsListState] =
    useState<IActiveCardsProps>({
      data: [],
      loading: false,
    });
  const COLUMN_DEF: GridColumns = [
    {
      field: 'id',
      headerName: 'Order ID',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },

    {
      field: 'createdAt',
      headerName: 'Creation Date',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const creationDate = props.row.createdAt
          ? dateFormat(props.row.createdAt)
          : '--';
        return <Vega_Text text={creationDate} />;
      },
    },
    {
      field: 'endDateTime',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const status = props.row.physicalCardOrderStatus
          ? props.row.physicalCardOrderStatus
          : '--';
        return <Vega_Text text={toLowerCase(status)} />;
      },
    },

    {
      field: 'Transaction Time',
      headerName: 'ETA',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const eta = props.row.eta ? dateFormat(props.row.eta) : '--';
        return <Vega_Text text={eta} />;
      },
    },
    {
      field: 'Transaction Status',
      headerName: 'Delivery Address',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const addressInfo = props.row.customerAddress as Address;
        const commaSeparatedString = `${addressInfo.line1}, ${addressInfo.line2}, ${addressInfo.city}, ${addressInfo.state}, ${addressInfo.pincode}, ${addressInfo.country}`;
        const address = addressInfo ? commaSeparatedString : '--';
        return <Vega_Text text={address} />;
      },
    },
    {
      field: 'Delivery Partner',
      headerName: 'Delivery Partner',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const deliveryPartner = props.row.deliveryPartner
          ? props.row.deliveryPartner
          : '--';
        return <Vega_Text text={deliveryPartner} />;
      },
    },
    {
      field: 'trackingId',
      headerName: 'Tracking ID',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const trackingId = props.row.trackingId ? props.row.trackingId : '--';
        return <Vega_Text text={trackingId} />;
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const trackingId = props.row.trackingId ? props.row.trackingId : '--';
        return (
          <Vega_Button
            variant="text"
            textColor={NEW_PRIMARY.BLUE}
            bgColor="transparant"
            text="View Journey"
            onClick={() => setShow(!show)}
          />
        );
      },
    },
  ];

  const getActiveCardsForAccount = async (accountId: string) => {
    setActiveCardsListState({ data: [], loading: true });
    try {
      const body = {
        accountId,
        physicalCardOrderStatusList: [
          'DRAFT',
          'UNDER_REVIEW',
          'PLACED',
          'ACCEPTED',
          'PRINTING_STARTED',
          'PACKED',
          'DISPATCHED',
        ],
      };
      const response = await getPhysicalCardOrders(body);
      setActiveCardsListState({ data: response, loading: false });
    } catch (error) {
      setActiveCardsListState({ data: [], loading: false });
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getActiveCardsForAccount(accountData.accountId);
    }
  }, []);
  return (
    <>
      <Vega_Datagrid
        data={activeCardsListState.data}
        loading={activeCardsListState.loading}
        idColumn="id"
        columns={COLUMN_DEF}
      />
      <CardJourney open={show} setOpen={setShow} />
    </>
  );
};

export default ListOfActiveCardOrders;

interface ICardJourneyProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardJourney = ({ open, setOpen }: ICardJourneyProps) => {
  const [collepsed, setCollepsed] = useState<number[]>([]);

  const handleCollapse = (id: number) => {
    if (collepsed.includes(id)) {
      setCollepsed(() => collepsed.filter((value: number) => value !== id));
    } else {
      setCollepsed([...collepsed, id]);
    }
  };

  interface IDeliveryStatusProps {
    id: number;
    status: string;
    statusDateTime: string;
    statusIcon: JSX.Element;
  }
  const deliveryStatus: IDeliveryStatusProps[] = [
    {
      id: 1,
      status: 'Card order received',
      statusDateTime: '12/03/2023 - 09:30 AM - Delivery partner got delayed',
      statusIcon: <Verify2Icon />,
    },
    {
      id: 2,
      status: 'Card ready to be deliver',
      statusDateTime: '12/03/2023 - 09:30 AM - Delivery partner got delayed',
      statusIcon: <Verify2Icon />,
    },
    {
      id: 3,
      status: 'Card out for delivery',
      statusDateTime: '12/03/2023 - 09:30 AM - Delivery partner got delayed',
      statusIcon: <Verify2Icon />,
    },
    {
      id: 4,
      status: 'Card delivery delayed',
      statusDateTime: '12/03/2023 - 09:30 AM - Delivery partner got delayed',
      statusIcon: <DelayIcon />,
    },
    {
      id: 5,
      status: 'Card delivery failed',
      statusDateTime: '12/03/2023 - 09:30 AM - Delivery partner got delayed',
      statusIcon: <CancelIcon />,
    },
    {
      id: 6,
      status: 'Card out for delivery',
      statusDateTime: '12/03/2023 - 09:30 AM - Delivery partner got delayed',
      statusIcon: <Verify2Icon />,
    },
    {
      id: 7,
      status: 'Card delivered',
      statusDateTime: '12/03/2023 - 09:30 AM - Delivery partner got delayed',
      statusIcon: (
        <Box
          sx={{
            width: 16,
            height: 16,
            bgcolor: '#3A4A5F',
            borderRadius: '50%',
          }}
        ></Box>
      ),
    },
  ];
  return (
    <Vega_Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      handleClose={() => setOpen(!open)}
      buttonComponent={() => <></>}
      headerComponent={() => (
        <Stack
          p={3}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'start'}
        >
          <Stack gap={1}>
            <Vega_Text
              text={'Card Journey : Card out for delivery'}
              color={'#3A4A5F'}
              fontSize={14}
              fontWeight={500}
            />
            <Vega_Text
              text={'Order will be delivered by 13/04/2023'}
              color={'#7B8694'}
              fontSize={12}
            />
          </Stack>
          <Close
            sx={{ color: '#3A4A5F', cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          />
        </Stack>
      )}
    >
      <Box px={3} pb={3}>
        {deliveryStatus.map((status: IDeliveryStatusProps) => (
          <>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              pb={collepsed.includes(status.id) ? 0 : 2}
              sx={{ transition: 'all 0.3s ease-in-out' }}
            >
              <Stack direction={'row'} alignItems={'center'} gap={2}>
                {status.statusIcon}
                <Vega_Text
                  text={status.status}
                  color={'#3A4A5F'}
                  fontSize={13}
                  fontWeight={500}
                />
              </Stack>

              <KeyboardArrowRightOutlined
                onClick={() => handleCollapse(status.id)}
                sx={{
                  cursor: 'pointer',
                  transition: 'all 0.3s ease-in-out',
                  ...(collepsed.includes(status.id) ? { rotate: '90deg' } : ''),
                }}
              />
            </Stack>
            <Collapse
              in={collepsed.includes(status.id)}
              timeout={300}
              sx={{ pl: 4 }}
            >
              <Vega_Text
                text={status.statusDateTime}
                color={'#7B8694'}
                fontSize={10}
                fontWeight={600}
                sx={{ pb: 2 }}
              />
            </Collapse>
          </>
        ))}
      </Box>
    </Vega_Dialog>
  );
};
