/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { getBillsForAccount } from '../../../../../api/bill';
import { NEW_PRIMARY } from '../../../../../constants/style';
import { dateFormat } from '../../../../../mcc/variable';
import { useSupport } from '../../../../../providers/SupportProvider';
import Vega_DataGridFilterSection from '../../../../common/v3/Vega_DataGridFilterSection';
import Vega_Datagrid from '../../../../common/v3/Vega_Datagrid';
import Vega_Text from '../../../../common/v3/Vega_Text';

export interface BillInfo {
  id: string;
  accountId: string;
  billAmount: number;
  billStartDate: string;
  billEndDate: string;
  billDate: string;
  billDueDate: string;
  billPaidDate: string | null;
  debitTxnCount: number;
  debitAmount: number;
  creditTxnCount: number;
  creditAmount: number;
  status: string;
  dueAmount: number;
  dueCharge: number;
  dueInterest: number;
  dueRetail: number;
  dueCash: number;
  dueEmi: number;
  dueOverlimit: number;
  mad: number;
  paidAmount: number;
  paidCharge: number;
  paidInterest: number;
  paidPrincipal: number;
  previousBillId: string | null;
  previousPendingBillAmount: number;
  billPdfLink: string;
  loanId: string | null;
  loanConversionDate: string | null;
  createdAt: string;
  updatedAt: string;
}

interface IBillsListStateProps {
  data: BillInfo[];
  loading: boolean;
}

const Transactions = () => {
  const { accountData } = useSupport();
  const [billListState, setBillListState] = useState<IBillsListStateProps>({
    data: [],
    loading: false,
  });
  const COLUMN_DEF: GridColumns = [
    {
      field: 'billAmount',
      headerName: 'Bill Amount',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },
    {
      field: 'billStartDate',
      headerName: 'Start Date',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) =>
        props.row.billEndDate ? dateFormat(props.row.billEndDate) : '--',
      renderCell: (props) => {
        const billStartDate = props.row.billStartDate
          ? dateFormat(props.row.billStartDate)
          : '--';
        return <Vega_Text text={billStartDate} />;
      },
    },
    {
      field: 'billEndDate',
      headerName: 'End Date',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) =>
        props.row.billStartDate ? dateFormat(props.row.billStartDate) : '--',
      renderCell: (props) => {
        const billEndDate = props.row.billEndDate
          ? dateFormat(props.row.billEndDate)
          : '--';
        return <Vega_Text text={billEndDate} />;
      },
    },
    {
      field: 'billDueDate',
      headerName: 'Due Date',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) =>
        props.row.billDueDate ? dateFormat(props.row.billDueDate) : '--',
      renderCell: (props) => {
        const billDueDate = props.row.billDueDate
          ? dateFormat(props.row.billDueDate)
          : '--';
        return <Vega_Text text={billDueDate} />;
      },
    },

    {
      field: 'billPaidDate',
      headerName: 'Paid Date',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) =>
        props.row.billPaidDate ? dateFormat(props.row.billPaidDate) : '--',
      renderCell: (props) => {
        const billPaidDate = props.row.billPaidDate
          ? dateFormat(props.row.billPaidDate)
          : '--';
        return <Vega_Text text={billPaidDate} />;
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      minWidth: 80,
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 0.7,
      minWidth: 80,
    },
  ];

  const getAllBillsForAccount = async (accountId: string) => {
    setBillListState({ data: [], loading: true });
    try {
      const response = await getBillsForAccount(accountId);
      setBillListState({ data: response.data.records, loading: false });
    } catch (error) {
      setBillListState({ data: [], loading: false });
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getAllBillsForAccount(accountData.accountId);
    }
  }, []);
  return (
    <>
      <Vega_Datagrid
        data={billListState.data}
        idColumn="id"
        columns={COLUMN_DEF}
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Vega_Text
                  text={'Bills'}
                  fontWeight={600}
                  color={NEW_PRIMARY.BLACK}
                  fontSize={17}
                />
                <Vega_DataGridFilterSection />
              </Stack>
            );
          },
        }}
      />
    </>
  );
};

export default Transactions;
