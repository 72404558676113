/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Stack } from '@mui/material';
import Vega_Text from '../../../../../components/common/v3/Vega_Text';
import Vega_FormWrapper from '../../../../../components/common/v3/Vega_FormWrapper';

interface IProfitLossInputProps {
  header: string;
  inputValue: number;
  color?: string;
  borderColor?: string;
  calculateSyns: string;
}

const ProfitLossInputs = ({
  header,
  inputValue,
  borderColor = '#EBEDEF',
  color = '#676B76',
  calculateSyns,
}: IProfitLossInputProps) => {
  return (
    <div>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
      >
        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          <Vega_Text
            text={'₹'}
            fontWeight={450}
            fontSize={12}
            fontColor="#676B76"
            sx={{
              mt: 2,
            }}
          />
          <Vega_FormWrapper header={header}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              sx={{
                height: '40px',
                width: '120px',
                border: `1px solid ${borderColor}`,
                borderRadius: '8px',
                bgcolor: '#FAFAFA',
              }}
            >
              <Vega_Text
                text={convertRupee(inputValue)}
                fontWeight={450}
                fontSize={13}
                fontColor={color}
                sx={{ pl: 1.5 }}
              />
            </Stack>
          </Vega_FormWrapper>
        </Stack>
        <Vega_Text
          text={calculateSyns}
          fontWeight={450}
          fontSize={12}
          fontColor="#676B76"
          sx={{
            mt: 2,
          }}
        />
      </Stack>
    </div>
  );
};

export default ProfitLossInputs;

const convertRupee = (number: number): string => {
  if (number < 0) {
    return '-' + convertRupee(-number);
  }
  if (number >= 100000) {
    return (number / 100000).toFixed(1) + ' L';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + ' k';
  } else {
    return number.toString();
  }
};
