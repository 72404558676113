/** @format */
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { listAccountsForProgram } from '../../api/account';
import { listCustomersForClient } from '../../api/customer';
import { listTransactionsForProgram } from '../../api/transaction';
import { BORDER_GRAY } from '../../constants/style';
import { useClientAuth } from '../../providers/ClientProvider';
import { VegaCreateCustomersType } from '../../types/customers';

type Props = {
  programId: string;
};

type DataPointCardProps = {
  loading: boolean;
  data: number | string;
  label: string;
};

type CustomerDataType = {
  loading: boolean;
  data: VegaCreateCustomersType[];
  error: string;
};

type AccountDataType = {
  loading: boolean;
  data: any[];
  error: string;
};

type TransactionDataType = {
  loading: boolean;
  data: any[];
  totalSpent: number;
  activeAccounts: number;
  error: string;
};

const DataPointCard = ({ loading, data, label }: DataPointCardProps) => {
  return (
    <Box
      sx={{
        border: BORDER_GRAY,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        textAlign: 'center',
      }}
    >
      {loading ? <Skeleton variant="text" width={50} /> : <Typography>{data}</Typography>}

      <Typography>{label}</Typography>
    </Box>
  );
};

const KeyDataPoints = ({ programId }: Props) => {
  const { clientId } = useClientAuth();
  const [customerData, setCustomerData] = useState<CustomerDataType>({
    loading: false,
    data: [],
    error: '',
  });
  const [accountData, setAccountData] = useState<AccountDataType>({
    loading: false,
    data: [],
    error: '',
  });
  const [transactionData, setTransactionData] = useState<TransactionDataType>({
    loading: false,
    data: [],
    totalSpent: 0,
    activeAccounts: 0,
    error: '',
  });
  const now = new Date();
  const startOfMonth = dayjs(now).startOf('month').toDate();

  useEffect(() => {
    fetchKeyDataPointsForProgram();
  }, [programId]);

  const fetchKeyDataPointsForProgram = () => {
    fetchCustomersForClient();
    fetchAccountsForProgram();
    fetchTransactionsForProgram();
  };

  const fetchCustomersForClient = () => {
    setCustomerData({ loading: true, data: [], error: '' });
    listCustomersForClient(clientId as string)
      .then((res: any) => {
        const customers =
          res?.data?.records ||
          [].filter(
            (customer: any) =>
              dayjs(customer.createdAt).isBefore(now) && dayjs(customer.createdAt).isAfter(startOfMonth)
          );
        setCustomerData({ loading: false, data: customers, error: '' });
      })
      .catch((error: any) => {
        setCustomerData({ loading: false, data: [], error: error });
      });
  };

  const fetchAccountsForProgram = () => {
    setAccountData({ loading: true, data: [], error: '' });
    listAccountsForProgram(programId)
      .then((res: any) => {
        const accounts =
          res?.data ||
          [].filter(
            (account: any) => dayjs(account.createdAt).isBefore(now) && dayjs(account.createdAt).isAfter(startOfMonth)
          );
        setAccountData({ loading: false, data: accounts, error: '' });
      })
      .catch((error: any) => {
        setAccountData({ loading: false, data: [], error: error });
      });
  };

  const fetchTransactionsForProgram = () => {
    setTransactionData({ loading: true, data: [], totalSpent: 0, activeAccounts: 0, error: '' });
    listTransactionsForProgram(programId as string)
      .then((res: any) => {
        const txns =
          res?.data?.records ||
          [].filter(
            (txns: any) =>
              dayjs(txns.transactionTime).isBefore(now) && dayjs(txns.transactionTime).isAfter(startOfMonth)
          );
        const activeAccountsMap: any = {};
        let activeAccounts = 0;
        let totalSpent = 0;
        txns.forEach((txn: any) => {
          if (txn.transactionIndicator === 'DEBIT' && txn.transactionCategory === 'CARD') {
            totalSpent += txn.amount;
          }
          if (!activeAccountsMap[txn.getAccountId]) {
            activeAccountsMap[txn.getAccountId] = txn;
            activeAccounts += 1;
          }
        });

        setTransactionData({
          loading: false,
          data: txns,
          totalSpent: totalSpent,
          activeAccounts: activeAccounts,
          error: '',
        });
      })
      .catch((error: any) => {
        setTransactionData({ loading: false, data: [], totalSpent: 0, activeAccounts: 0, error: error });
      });
  };

  return (
    <Box sx={{ px: 1, py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DataPointCard loading={customerData.loading} data={customerData?.data?.length || 0} label="New Users" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataPointCard loading={accountData.loading} data={accountData?.data?.length || 0} label="New Accounts" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DataPointCard
            loading={transactionData.loading}
            data={transactionData?.data?.length || 0}
            label="Transactions"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataPointCard loading={accountData.loading} data={accountData?.data?.length || 0} label="Cards Issued" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataPointCard
            loading={transactionData.loading}
            data={transactionData?.activeAccounts || 0}
            label="Active Customers"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DataPointCard
            loading={transactionData.loading}
            data={(transactionData?.totalSpent || 0).toLocaleString('en-IN', { currency: 'INR', style: 'currency' })}
            label="Spent"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeyDataPoints;
