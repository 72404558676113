import { Box } from '@mui/material';
interface IProps {
  height?: string;
}

const VegaLoader = ({ height = '600px' }: IProps) => {
  return (
    <Box className="loader-container" sx={{ height: height }}>
      <div className="loader"></div>
    </Box>
  );
};

export default VegaLoader;
