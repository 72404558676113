import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { fetchAuditLogs } from '../../../api/audit';
import { toLowerCase } from '../../../constants/commonFunction';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useSupport } from '../../../providers/SupportProvider';
import { AuditLog } from '../../../types/audit';
import { VegaProgramType } from '../../../types/program';
import { DATE_TIME_FORMATS } from '../../../utils/dateTime';
import Vega_Datagrid from '../../common/v3/Vega_Datagrid';
import Vega_Text from '../../common/v3/Vega_Text';
import Vega_Dialog from '../../common/v3/Vega_Dialog';
import { Box, Grid, Stack } from '@mui/material';
import Vega_FormWrapper from '../../common/v3/Vega_FormWrapper';
import { Close } from '@mui/icons-material';
import { NEW_PRIMARY } from '../../../constants/style';
import Vega_Button from '../../common/v3/Vega_Button';

const ListOfUserAuditLog = () => {
  const { filteredCustomer } = useSupport();
  const { programs } = useProgramData();
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [logs, setLogs] = useState<any>();

  const COLUMN_DEF: GridColumns = [
    {
      field: 'createdAt',
      headerName: 'Date & Time',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: (props) => {
        const dateTime = props.row.createdAt
          ? dayjs(props.row.createdAt).format(DATE_TIME_FORMATS.DATE_TIME)
          : '--';
        return <Vega_Text text={dateTime} />;
      },
    },
    {
      field: 'programId',
      headerName: 'Program Name',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const programId = props.row.programId;
        const findProgrmaName = programs.find(
          (program: VegaProgramType) => program.programId === programId
        );
        return <Vega_Text text={findProgrmaName?.name} />;
      },
    },
    {
      field: 'eventName',
      headerName: 'Event',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const eventName = props.row.eventName;

        return <Vega_Text text={toLowerCase(eventName ?? '--')} />;
      },
    },
    {
      field: 'endDateTime',
      headerName: 'Description',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const toValue = props.row.toValue;
        const regex = /\w+=\S+/g;
        const checkValue = regex.test(toValue);
        return (
          //
          checkValue ? (
            <Vega_Button
              variant="text"
              textColor={NEW_PRIMARY.BLUE}
              bgColor="transparant"
              text="View Description"
              onClick={() => {
                onClickAuditLog(toValue);
              }}
            />
          ) : (
            <Vega_Text text={toValue} />
          )
        );
      },
    },
  ];

  const handleClose = () => {
    setOpen(!open);
  };

  const seperateLetter = (letter: string) => {
    const formattedString: string = letter.replace(/([A-Z])/g, ' $1');
    return formattedString;
  };

  const onClickAuditLog = (logs: string) => {
    const keyValuePairs: string[] = logs.match(/\w+=\S+/g) || [];

    const accountDtoObject: { [key: string]: string | null } = {};

    keyValuePairs.forEach((keyValuePair) => {
      const [key, value] = keyValuePair.split('=');
      accountDtoObject[key] = value;
    });

    setLogs(accountDtoObject);
    handleClose();
  };
  const getAuditLogs = async (customerId: string) => {
    try {
      const response = await fetchAuditLogs({ customerId });
      setIsLoading(false);
      setAuditLogs(response);
    } catch (error) {
      setIsLoading(false);
    }
  };
  console.log('logs', logs);
  useEffect(() => {
    if (filteredCustomer?.customerId) {
      getAuditLogs(filteredCustomer?.customerId);
    }
  }, []);
  return (
    <>
      <Vega_Datagrid
        data={auditLogs}
        idColumn="id"
        columns={COLUMN_DEF}
        loading={isLoading}
      />

      <Vega_Dialog
        fullWidth
        maxWidth="md"
        open={open}
        handleClose={handleClose}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <>
            <Stack
              p={3}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'start'}
            >
              <Stack gap={1}>
                <Vega_Text
                  text={'Audit Logs'}
                  color={'#3A4A5F'}
                  fontSize={14}
                  fontWeight={500}
                />
              </Stack>
              <Close
                sx={{ color: '#3A4A5F', cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Stack>
          </>
        )}
      >
        <Box
          px={3}
          pb={2}
          sx={{
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          <Grid container spacing={2}>
            {logs &&
              Object.keys(logs).map((value: string) => (
                <Grid item xs={6} sm={6} md={4}>
                  <Vega_FormWrapper header={seperateLetter(value)}>
                    <Vega_Text
                      text={logs[value].replace(/,/g, '')}
                      fontWeight={450}
                      fontSize={13}
                      color={'#181F28'}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    />
                  </Vega_FormWrapper>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Vega_Dialog>
    </>
  );
};

export default ListOfUserAuditLog;
