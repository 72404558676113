import { ControllerRenderProps, FieldPath } from 'react-hook-form';

const sanitizeNumber = (number: string): string => {
  const sanitizedNumber = number?.replace(/[-()\s ]/gi, '');

  return sanitizedNumber?.length < 6
    ? sanitizedNumber
    : sanitizedNumber?.slice(sanitizedNumber?.length - 6);
};

function usePincode<
  TFieldValues extends Record<string, any>,
  TName extends FieldPath<TFieldValues>
>() {
  const onChange = (
    e: any,
    field: ControllerRenderProps<TFieldValues, TName>
  ) => {
    const parsedNumber = parseInt(e.target.value);
    const pincode = isNaN(parsedNumber) ? '' : parsedNumber.toString();
    const value = sanitizeNumber(pincode.substring(0, 6));
    if (value !== field.value) {
      field.onChange({
        ...e,
        target: {
          ...e.target,
          value,
        },
      });
    }
  };

  return {
    onChange,
  };
}

export default usePincode;
