import React from 'react';
import { Box, Grid } from '@mui/material';
import { Search } from '@mui/icons-material';
import { VegaCard } from '../../../../components/common';
import VegaText from '../../../../components/common/VegaText';
import VegaOutLineTextField from '../../../../components/common/VegaOutLineTextField';
import VegaSelect from '../../../../components/common/VegaSelect';
import VegaDatePicker from '../../../../components/common/VegaDatePicker';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import CorporateSelector from '../../../components/CorporateSelector';
import { ICorporateFilterProps } from '../../pages/MCCcorporateTransaction';
import {
  ICorporateAccountWalletDataProps,
  ICorporateEntity,
} from '../../../types/CorporateCustomer';

interface IFilterProps {
  handleChange: (name: keyof ICorporateFilterProps, value: any) => void;
  onSearch?: () => void;
  state: ICorporateFilterProps;
  corporates: ICorporateAccountWalletDataProps[];
}

const CorporateTransactionFilter = ({
  handleChange,
  onSearch,
  state,
  corporates,
}: IFilterProps) => {
  // const filterPrepaidCorporate = corporates.length ? corporates.filter((corporate:ICorporateEntity)=> )
  return (
    <VegaCard>
      <Grid container spacing={2}>
        <Grid item container spacing={2} xs={12} sm={6}>
          <Grid item xs={12} sm={6}>
            <VegaSelect
              placeholder="Corportate Name"
              label="Corporate Name"
              size="small"
              key={'corporateId'}
              selected={state.corporateId}
              onChange={(value) => handleChange('corporateId', value)}
              options={corporates.map(
                (corporate: ICorporateAccountWalletDataProps) => ({
                  id: corporate.corporate?.id as string,
                  name: corporate.corporate?.corporateName as string,
                })
              )}
            />
          </Grid>
        </Grid>
        {/* <Grid item container spacing={2} xs={12} sm={6} alignItems={'center'}>
          <Grid item xs={12} sm={5}>
            <VegaDatePicker
              value={state.fromDate}
              onChange={(e) => handleChange('fromDate', e)}
              key="fromDate"
              label="Select Month or Year: Card Expiry"
              placeholder="Select Month or Year: Card Expiry"
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <VegaDatePicker
              value={state.toDate}
              onChange={(e) => handleChange('toDate', e)}
              key="toDate"
              label="Select Month or Year: Card Expiry"
              placeholder="Select Month or Year: Card Expiry"
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <VegaOutlineButton text={'Search'} isPrimary onClick={onSearch} />
          </Grid>
        </Grid> */}
      </Grid>
    </VegaCard>
  );
};

export default CorporateTransactionFilter;
