import { Search } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { GREEN, GREY } from '../../../constants/style';
import { VegaCard } from '../../common';
import VegaOutLineTextField from '../../common/VegaOutLineTextField';
import VegaRadio from '../../common/VegaRadio';
import Vega_HeaderWrapper from '../../common/v3/Vega_HeaderWrapper';

const radioData = [
  {
    label: 'Automatic',
    value: 'automatic',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
];

const ApprovalProcess = () => {
  const [approval, setApproval] = useState<any>(radioData[0].value);

  const handleChange = (e: any) => {
    setApproval(e.target.value);
  };
  return (
    <VegaCard>
      <Vega_HeaderWrapper text="Select approval process" />
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={12} sm={3}>
          <VegaRadio
            name="approval"
            radioData={radioData}
            onChange={handleChange}
            value={approval}
            labelPlacement="end"
            color={GREEN.dark}
          />
        </Grid>
      </Grid>

      <Vega_HeaderWrapper text="Nominate employee for daily approval" />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <VegaOutLineTextField
            type="text"
            key=""
            value={''}
            onChange={() => console.log('e')}
            Icon={<Search />}
            placeholder="Enter employee name / employee ID"
          />
        </Grid>
      </Grid>
    </VegaCard>
  );
};

export default ApprovalProcess;
