import { useEffect, useState } from 'react';
import { getAddOnCardConfiguration } from '../../../api/card';
import { AddOnCardConfiguration } from '../../../types/card';
import { VegaProgramType } from '../../../types/program';
import { VegaPage } from '../../common';
import AddOnCardDetailsAccordian from './AddOnCardDetailsAccordian';
import SupplementaryCardDetailsAccordian from './SupplementaryCardDetailsAccordian';

type Props = {
  selectedProgram: VegaProgramType | null;
};

function AddOnCardDetailsPage({ selectedProgram }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [configuration, setConfiguration] =
    useState<AddOnCardConfiguration | null>();

  function fetchSettings() {
    const programId = selectedProgram?.programId;
    if (!programId) return;
    setLoading(true);
    getAddOnCardConfiguration({ programId: programId })
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => {
        setConfiguration(null);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSettings();
  }, [selectedProgram]);

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <VegaPage>
      <AddOnCardDetailsAccordian
        programId={selectedProgram?.programId}
        initial={configuration}
      />
      <SupplementaryCardDetailsAccordian
        programId={selectedProgram?.programId}
        initial={configuration}
      />
    </VegaPage>
  );
}

export default AddOnCardDetailsPage;
