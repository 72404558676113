import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../../components/common';
import VegaContainedButton from '../../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../../components/common/VegaDrawer';
import { TransactionIndicator } from '../../../../../enums/transaction';
import { getCustomerName } from '../../../../../utils/common.utils';
import {
  ENTITY_STATUS,
  IConsolidatedAccountData,
} from '../../../../types/Commons';

import ManualAdjustmentDrawer from './ManualAdjustmentDrawer';
import { getCurrenciesForWallet } from '../../../../../utils/wallet.utils';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useForm } from 'react-hook-form';
import { IManualAdjustmentRequest } from '../../../../types/ManualAdjustment';
import ManualAdjustmentService from '../../../../../services/ManualAdjustmentService';
import { useClientAuth } from '../../../../../providers/ClientProvider';

type IProps = {
  data: IConsolidatedAccountData[];
  isLoading?: boolean;
};

function ManualAdjustmentMaker({ data, isLoading = false }: IProps) {
  const { clientId, userId, mccPlatformId } = useClientAuth();

  data = data.filter((el) => el.account?.status === ENTITY_STATUS.ACTIVE);

  const [open, setOpen] = useState<boolean>(false);
  const [manualAdjustReq, setManualAdjustReq] = useState<
    Partial<IManualAdjustmentRequest>
  >({});
  const [currencies, setCurrencies] = useState<Array<string>>([]);
  const { setSnackbar } = useSnackbar();
  const hookForm = useForm<IManualAdjustmentRequest>({
    defaultValues: {
      // accountId: manualAdjustReq.accountId,
    },
  });

  const showDetails = (data: IConsolidatedAccountData) => {
    const currencies = getCurrenciesForWallet(data.subWallets);
    setCurrencies(currencies);
    setManualAdjustReq({
      accountId: data.account?.id || '',
      adjustmentType: TransactionIndicator.CREDIT,
      currency: currencies[0],
    });
    setOpen(!open);
    hookForm.setValue('accountId', data.account?.id || '');
  };

  const handleChange = (key: keyof IManualAdjustmentRequest, value: any) => {
    setManualAdjustReq((pre) => ({
      ...pre,
      [key]: value,
    }));
  };

  const onSubmit = async (data: IManualAdjustmentRequest) => {
    if (data.amount <= 0) {
      setSnackbar('Amount must be greater than zero', 'error');
      return;
    }
    ManualAdjustmentService.create({
      ...data,
      clientId: clientId || '',
      branchId: mccPlatformId,
    })
      .then((res) => {
        setSnackbar('Manual Adjust create successfully');
        setOpen(false);
      })
      .catch((err) => {
        setSnackbar('Error in creating manual transaction', 'error');
        // setOpen(false);
      });
  };
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={ADJUSTMENT_COLUMN(showDetails)}
        idColumn={'id'}
        loading={isLoading}
      />
      <VegaDrawer
        open={open}
        isClose
        isSubmit
        header={'Adjustment Details'}
        closeText={'Cancel'}
        submitText={'Submit'}
        onClose={() => setOpen(!open)}
        onSubmit={hookForm.handleSubmit(onSubmit)}
      >
        <ManualAdjustmentDrawer
          isDisabled={false}
          // state={manualAdjustReq}
          currencies={currencies}
          // handleChange={handleChange}
          hookForm={hookForm}
        />
      </VegaDrawer>
    </VegaCard>
  );
}

export default ManualAdjustmentMaker;

const ADJUSTMENT_COLUMN = (
  showDetails: (data: IConsolidatedAccountData) => void
): GridColDef<IConsolidatedAccountData>[] => [
  {
    field: 'kitNo',
    renderHeader: () => <Typography variant="body2">Kit No</Typography>,
    flex: 1,
    renderCell: (props) => {
      return <Typography>{props?.row?.card?.vendorCardNumber}</Typography>;
    },
  },
  {
    field: 'name',
    renderHeader: () => <Typography variant="body2">Name</Typography>,
    flex: 1,
    renderCell: (props) => {
      return <Typography>{getCustomerName(props?.row?.customer)}</Typography>;
    },
  },

  {
    field: 'customerId',
    renderHeader: () => <Typography variant="body2">Customer ID</Typography>,
    flex: 1,
    renderCell: (props) => {
      return <Typography>{props?.row?.customer?.customerId}</Typography>;
    },
  },
  {
    field: 'cardNo',
    renderHeader: () => <Typography variant="body2">Card No</Typography>,
    flex: 1,
    renderCell: (props) => {
      return <Typography>{props?.row?.card?.cardNumber}</Typography>;
    },
  },

  {
    field: 'status',
    renderHeader: () => <Typography variant="body2"> Status</Typography>,
    flex: 1,
    renderCell: (props) => {
      return (
        <VegaContainedButton
          text={props.row.account?.status || ''}
          isPrimary
          disabled
        />
      );
    },
  },
  {
    field: 'expiryDate',
    renderHeader: () => <Typography variant="body2"> Expiry Date</Typography>,
    flex: 0.5,
    renderCell: (props) => {
      return <Typography>{props?.row?.card?.expiryDate}</Typography>;
    },
  },
  {
    field: 'details',
    renderHeader: () => <Typography variant="body2"> Details</Typography>,
    flex: 1,
    renderCell: (props) => {
      return (
        <VegaContainedButton
          text="Adjustment"
          onClick={() => showDetails(props.row)}
          isPrimary
        />
      );
    },
  },
];
