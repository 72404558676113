import React from 'react';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FilterList } from '@mui/icons-material';
import { NEW_PRIMARY } from '../../../constants/style';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import { VegaProgramCategoryType } from '../../../types/program';
import { VPTextField } from '../../../ui-components/common';
import { SearchIcon } from '../../../ui-components/icon/VPIcons';

type FilterProgramManagementProps = {
  activeTable: VegaProgramCategoryType;
  onTableChange: React.Dispatch<React.SetStateAction<VegaProgramCategoryType>>;
  searchValue: string;
  handlesSearchValueChange: (value: string) => void;
};

function FilterProgramManagement({
  activeTable,
  onTableChange,
  searchValue,
  handlesSearchValueChange,
}: FilterProgramManagementProps) {
  const onSelectTableTypeChanged = (
    event: SelectChangeEvent<VegaProgramCategoryType>
  ) => {
    onTableChange(event.target.value as VegaProgramCategoryType);
  };

  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handlesSearchValueChange(e.target.value);
  };

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={'space-between'}
        my={2}
        gap={1}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <Select
          value={activeTable}
          onChange={onSelectTableTypeChanged}
          sx={{
            borderColor: NEW_PRIMARY.BLUE,
            '.MuiInputBase-input': {
              fontSize: 13,
            },
            '&.MuiInputBase-root fieldset': {
              border: '1px solid #E1E4EB',
              borderRadius: '6px',
              color: '#1047DC',
            },
            '.MuiSelect-outlined, .MuiSelect-icon': {
              color: '#1047DC',
              backgroundColor: 'white',
              borderRadius: '6px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#1047DC',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                boxShadow: 'none',
                border: '1px solid #E1E4EB',
                mt: 0.75,
                '& .MuiMenuItem-root': {
                  padding: 1.5,
                  fontSize: 14,
                },
                '& .MuiMenu-list': {
                  py: 0,
                },
              },
            },
          }}
        >
          <MenuItem value={VegaProgramCategoryType.CREDIT_CARD}>
            Credit Card
          </MenuItem>
          <MenuItem value={VegaProgramCategoryType.PREPAID_CARD}>
            Prepaid Card
          </MenuItem>
          <MenuItem value={VegaProgramCategoryType.LOANS}>LMS</MenuItem>
        </Select>
        <Stack
          direction={'row'}
          className="dataGrid"
          alignItems={'center'}
          justifyContent={'flex-end'}
          spacing={2}
        >
          {/* <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            sx={{ cursor: 'pointer' }}
          >
            <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
            <VegaTextV2 fontSize={12} color={'#676B76'}>
              Filter
            </VegaTextV2>
          </Stack> */}
          <VPTextField
            placeholder="Search..."
            value={searchValue}
            onChange={onSearchTextChange}
            InputProps={{
              sx: {
                borderRadius: '6px',
              },
              startAdornment: <SearchIcon />,
            }}
            sx={{
              minWidth: {
                xs: 0,
                sm: '320px',
              },
              '& .MuiInputBase-root': {
                pl: 1.5,
                pr: 1.75,
                py: 1,
                gap: 1,
                bgcolor: 'white',
              },
              '& .MuiInputBase-input': {
                fontFamily: `var(--font-Aspekta-450) !important`,
                fontSize: '14px',
                lineHeight: '140%',
                color: '#1B1D22',
                p: 0,
              },
              '& .MuiInputBase-input:placeholder': {
                color: '#999DA8',
              },
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default FilterProgramManagement;
