export const REPORT_SAMPLE_LINKS: Record<string, string> = {
    DPD_CUSTOMER:
      'https://vegapay-external-docs.s3.ap-south-1.amazonaws.com/reports/Collections-DPD-Customers.csv',
    DPD_PORTFOLIO_ANALYSIS:
      'https://vegapay-external-docs.s3.ap-south-1.amazonaws.com/reports/Collections-DPD-Portfolio-Analysis.csv',
    UNPAID_BILLS:
      'https://vegapay-external-docs.s3.ap-south-1.amazonaws.com/reports/Collections-Unpaid-Bills.csv',
    ACCOUNT_SUMMARY:
      'https://vegapay-external-docs.s3.ap-south-1.amazonaws.com/reports/CRM-Account-Summary.csv',
    CUSTOMER_ONBOARDING:
      'https://vegapay-external-docs.s3.ap-south-1.amazonaws.com/reports/CRM-Customer-Onboarding.csv',
    DEBIT:
      'https://vegapay-external-docs.s3.ap-south-1.amazonaws.com/reports/Lending-Debit.csv',
    REPAYMENT_REFUND_REVERSAL:
      'https://vegapay-external-docs.s3.ap-south-1.amazonaws.com/reports/Lending-Repayment-Refund-Reversal.csv',
    TOTAL_AUM:
      'https://vegapay-external-docs.s3.ap-south-1.amazonaws.com/reports/Lending-Total-AUM.csv',
  };
  
  
  export const reportingMailOptions = [
    'engineering@vegapay.tech',
    'suraj.kumar@vegapay.tech',
    'himanshu@vegapay.tech',
    'puneet@vegapay.tech',
    'vaishnav.agrawal@vegapay.tech',
    'ranjeet.kumar@vegapay.tech'
  ]