/* eslint-disable react/jsx-pascal-case */
import { Box } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { getActiveCardListByCustomer } from '../../../../../../api/card';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { VegaProgramNetworkType } from '../../../../../../types/program';
import Vega_Datagrid from '../../../../../common/v3/Vega_Datagrid';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { getLogoForNetwork } from '../../../../../icon/Icons';

// interface IrewardsListStateProps {
//   data: CardInfo[];
//   loading: boolean;
// }

const ActiveCard = () => {
  const { accountData, setCardsList, cardsList } = useSupport();
  // const [activeCardsListState, setActiveCardsListState] =
  //   useState<IrewardsListStateProps>({
  //     data: [],
  //     loading: false,
  //   });
  const COLUMN_DEF: GridColumns = [
    {
      field: 'binId',
      headerName: 'BIN',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },
    {
      field: 'holidayType',
      headerName: 'Network',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const network: VegaProgramNetworkType = props.row.network;
        return network ? getLogoForNetwork(network) : '--';
      },
    },
    {
      field: 'cardNumber',
      headerName: 'Card Number',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'kitId',
      headerName: 'Kit Number',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const kitId: VegaProgramNetworkType = props.row.kitId;
        return kitId ? kitId : '--';
      },
    },
  ];

  const getAllActiveCards = async (accountId: string) => {
    setCardsList({ data: [], isLoading: true });
    try {
      const response = await getActiveCardListByCustomer({ accountId });
      setCardsList({ data: response, isLoading: false });
    } catch (error) {
      setCardsList({ data: [], isLoading: false });
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getAllActiveCards(accountData.accountId);
    }
  }, []);
  return (
    <>
      <Vega_Text
        text={'Active Card'}
        fontWeight={600}
        color={NEW_PRIMARY.BLACK}
        fontSize={17}
      />
      <Box mt={2}>
        <Vega_Datagrid
          data={cardsList.data}
          idColumn="id"
          columns={COLUMN_DEF}
          loading={cardsList.isLoading}
        />
      </Box>
    </>
  );
};

export default ActiveCard;
