/** @format */

import { Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { getBillPdf } from '../../api/bill';
import { DATE_FORMATE } from '../../constants/url';
import { NoResults, VegaCard, VegaTable } from '../common';

type Props = {};

const ListOfBills = ({ bills }: any) => {
  if (!bills || bills.length === 0) {
    return <NoResults text="You have no bills" subtext="Create new bill?" />;
  }

  const columnDef = [
    {
      field: 'accountId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Account ID
        </Typography>
      ),

      flex: 1,
    },
    {
      field: 'billStartDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bill Start Date
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.billStartDate &&
              dayjs(props.row.billStartDate).format(DATE_FORMATE)}
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'billEndDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bill End Date
        </Typography>
      ),
      renderCell: (props: any) => {
        const splitDate = props.row.billEndDate.split('T')[0];
        return (
          <div className="">
            {props.row.billEndDate && dayjs(splitDate).format(DATE_FORMATE)}
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'billDueDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Due Date
        </Typography>
      ),
      renderCell: (props: any) => {
        const splitDate = props.row.billDueDate.split('T')[0];
        return (
          <div className="">
            {props.row.billDueDate && dayjs(splitDate).format(DATE_FORMATE)}
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'dueAmount',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Due Amount
        </Typography>
      ),

      flex: 0.5,
    },
    {
      field: 'mad',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          MAD
        </Typography>
      ),

      flex: 0.5,
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.status && _.startCase(_.toLower(props.row.status))}
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'billPdfLink',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          View Bill
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          props.row.billPdfLink && (
            <Button
              onClick={() => {
                getBillPdf(props.row.id).then((res) => {
                  const link = res.data?.url;
                  window.open(link);
                });
              }}
            >
              View
            </Button>
          )
        );
      },
      flex: 0.5,
    },
  ];

  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable data={bills} columnDef={columnDef} idColumn={'id'} />
      </VegaCard>
    </>
  );
};

export default ListOfBills;
