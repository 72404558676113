/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import { useState } from 'react';
import { AddIconRounded } from '../../../components/icon/Icons';
import CIMS_Button from '../../components/common/CIMS_Button';
import CIMS_Header from '../../components/common/CIMS_Header';
import OrderPhysicalCardsList from '../../modules/OrderPhysicalCards/List/OrderPhysicalCardsList';
import { CreateOrderModal } from '../../modules/OrderPhysicalCards/Modals';

function OrderPhysicalCard() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [refetchList, setRefetchList] = useState<any>();

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div>
      <CIMS_Header
        text="Order Physical Card"
        component={
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <CIMS_Button
              text="Create Order"
              onClick={handleOpenDrawer}
              startIcon={<AddIconRounded />}
            />
          </Stack>
        }
      />

      <CreateOrderModal
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        refetchList={refetchList}
      />

      <OrderPhysicalCardsList setRefetchList={setRefetchList} />
    </div>
  );
}

export default OrderPhysicalCard;
