import { Tab, TabProps } from '@mui/material';

interface VegaTabBarItemProps extends TabProps {}

function VegaTabBarItem({ ...rest }: VegaTabBarItemProps) {
  const { color = '#FF7B01' } = rest;
  return (
    <Tab
      sx={{
        ...rest.sx,
        '&.Mui-selected': {
          color: { color },
        },
        color: '#3A4A5F',
        fontSize: '.8125rem',
        fontFamily: 'Aspekta !important',
        fontWeight: 700,
        textTransform: 'none',
      }}
      {...rest}
    />
  );
}

export default VegaTabBarItem;
