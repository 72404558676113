/* eslint-disable react/jsx-pascal-case */
import { Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { mapProgramMetadata } from '../../../../api/program';
import { fetchStateOnPincode } from '../../../../api/resources';
import { toLowerCase } from '../../../../constants/commonFunction';
import { EntityStatusEnum } from '../../../../enums/commons';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { Level, LevelEntityMapping } from '../../../../types/client';
import {
  EntityDto,
  EntityType,
  LevelEntityDto,
} from '../../../../types/entity';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { EntityService } from '../../../apis';
import CimsDrawer from '../../../components/CimsDrawer';
import CimsButton from '../../../components/common/CimsButton';
import { useMobileNumber, usePincode } from '../../../hooks';
import { MapEntityProgram } from '../ProgramMapping';
import { EditFormFiled } from '../../../../components/common/deprecated/EditFormFiled';
import LosTextField from '../../../../components/common/deprecated/LosTextField';
import {
  DivisionDto,
  DivisionStatusEnums,
  DivisionType,
} from '../../../../types/division';
import { DivisionService } from '../../../../api/division';

export interface CreateDivisionForm {
  divisionDto: DivisionDto;
  programs?: Array<{ name: string; programId: string }>;
  programId?: string;
}

interface IProps {
  refetchList: any;
}

export const CreateDivisionModal = ({ refetchList }: IProps) => {
  const { clientId, userDivisionType } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [pageSize, setPageSize] = useState<number>(100);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const hookForm = useForm<CreateDivisionForm>({
    mode: 'all',
  });

  const { onChange: onMobileNoChange } = useMobileNumber<
    CreateDivisionForm,
    'divisionDto.mobileNo'
  >();
  const { onChange: onPincodeChange } = usePincode<
    CreateDivisionForm,
    'divisionDto.address.pincode'
  >();

  const divisionTypeOptions = useMemo(
    () => getDivisionTypeOptions(userDivisionType),
    [userDivisionType]
  );

  const watchingFields = hookForm.watch([
    'divisionDto.divisionType',
    'divisionDto.parentDivisionId',
    'divisionDto.address.pincode',
  ]);

  const selectedLevel = watchingFields[0];
  const parentDivisionId = watchingFields[1];
  const pinCodeValue = watchingFields[2];

  const divisionQuery = useQuery({
    queryKey: ['fetchDivisions', { clientId, selectedLevel, page, pageSize }],
    queryFn: () =>
      DivisionService.fetchDivisions({
        clientId: clientId || '',
        divisionType: getParentDivisionType(selectedLevel),
        page: page,
        size: pageSize,
      }),
    enabled: !!clientId && !!getParentDivisionType(selectedLevel),
    select: (data) => {
      return (
        data.records.reduce((pre, curr) => {
          return {
            ...pre,
            [curr.id]: curr,
          };
        }, {} as Record<string, DivisionDto>) || {}
      );
    },
  });

  const onSubmit = (data: CreateDivisionForm) => {
    DivisionService.createDivision({
      ...data.divisionDto,
      parentDivisionId:
        data.divisionDto.parentDivisionId || clientId || undefined,
      clientId: clientId || '',
      status: DivisionStatusEnums.ACTIVE,
    })
      .then((res) => {
        setSnackbar('Created Entity Successfully');
        // map program meta Data

        if (data.programId) {
          mapProgramMetadata({
            programId: data.programId,
            divisionId: res.id,
            clientId: clientId || '',
          })
            .then((res) => {
              setSnackbar('Successfully mapped programs', 'success');
              hookForm.reset();
              refetchList && refetchList();
              setIsOpen(false);
            })
            .catch((err) => {
              setSnackbar(
                getErrorMessageFromErrorObj(
                  err,
                  'Error in mapping the programs'
                ),
                'error'
              );
            })
            .finally(() => {
              refetchList && refetchList();
              hookForm.reset();
              setIsOpen(false);
            });
        } else {
          refetchList && refetchList();
          hookForm.reset();
          setIsOpen(false);
        }
        //
        // const promises = data.programs.map((program) =>
        //   mapProgramMetadata({
        //     programId: program.programId,
        //     divisionId: res.id,
        //     clientId: clientId || '',
        //   })
        // );

        // Promise.allSettled(promises)
        //   .then((values) => {
        //     values.forEach((val) => {
        //       val.status === 'rejected' &&
        //         setSnackbar(
        //           getErrorMessageFromErrorObj(
        //             val.reason,
        //             'Error in mapping program for '
        //           ),
        //           'error'
        //         );
        //     });
        //   })
        //   .then((res) => {
        //     setSnackbar('Successfully mapped programs', 'success');

        //     hookForm.reset();
        //     refetchList && refetchList();
        //     setIsOpen(false);
        //   })
        //   .catch((err) => {
        //     setSnackbar('Error in mapping the programs ', 'error');
        //   });
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Error while creating entity'),
          'error'
        );
      });
  };

  useEffect(() => {
    if (pinCodeValue?.length === 6) {
      const getListAll = setTimeout(() => {
        fetchStateOnPincode(pinCodeValue)
          .then((res) => {
            if (res.data?.records.length === 0) {
              setSnackbar('Not a valid pin code', 'error');
            }
            const stateList = res.data?.records[0]?.state;
            const cityList = res.data?.records[0]?.city;
            const counteryList = res.data?.records[0]?.country;
            hookForm.setValue(
              'divisionDto.address.city',
              toLowerCase(cityList)
            );
            hookForm.setValue(
              'divisionDto.address.state',
              toLowerCase(stateList)
            );
            hookForm.setValue(
              'divisionDto.address.country',
              toLowerCase(counteryList)
            );
          })
          .catch((err) => {
            setSnackbar('Not a valid pin code ', 'error');
          });
      }, 1000);
      return () => clearTimeout(getListAll);
    } else {
      hookForm.setValue('divisionDto.address.city', '');
      hookForm.setValue('divisionDto.address.state', '');
      hookForm.setValue('divisionDto.address.country', '');
    }
  }, [pinCodeValue, hookForm.setValue]);
  console.log(hookForm.formState.errors);

  return (
    <>
      <CimsButton onClick={() => setIsOpen(true)}>Create Division</CimsButton>
      <CimsDrawer
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        header="Add Division Details"
        footer={
          <Stack direction={'row'} justifyContent={'end'} flex={1} p={1}>
            <CimsButton
              variant="contained"
              onClick={hookForm.handleSubmit(onSubmit)}
              type="submit"
              disabled={!hookForm.formState.isValid}
              autoFocus={true}
            >
              Submit
            </CimsButton>
          </Stack>
        }
      >
        <Stack spacing={2}>
          {/* ---------------------------------------- Division Details---------------------------------------- */}
          <Typography variant="body1" fontWeight={600}>
            Division Details
          </Typography>
          <Controller
            name="divisionDto.divisionType"
            control={hookForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <EditFormFiled
                title={'DIVISION TYPE'}
                description={field.value}
                onChange={(val) => {
                  field.onChange(val);

                  hookForm.setValue('divisionDto.parentDivisionId', '');
                }}
                placeHolder={'Select Division type'}
                options={divisionTypeOptions}
                isLoadingOptions={divisionQuery.isLoading}
              />
            )}
          />
          <Controller
            name="divisionDto.divisionCode"
            control={hookForm.control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <EditFormFiled
                title={'DIVISION CODE'}
                description={field.value}
                {...field}
                placeHolder={'Enter division code'}
                error={!!hookForm.formState.errors.divisionDto?.divisionCode}
                helperText={
                  hookForm.formState.errors.divisionDto?.divisionCode?.message
                }
              />
            )}
          />
          <Controller
            name="divisionDto.name"
            control={hookForm.control}
            rules={{ required: 'Min required length is 3 ', minLength: 3 }}
            render={({ field }) => (
              <EditFormFiled
                title={'DIVISION NAME'}
                description={field.value}
                {...field}
                placeHolder={'Enter division name'}
                error={!!hookForm.formState.errors.divisionDto?.name}
                helperText={
                  hookForm.formState.errors.divisionDto?.name?.message
                }
              />
            )}
          />
          <Controller
            name="divisionDto.email"
            control={hookForm.control}
            rules={{
              required: 'Email is required',
              pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            }}
            render={({ field }) => (
              <EditFormFiled
                title={'DIVISION EMAIL'}
                description={field.value}
                {...field}
                placeHolder={'Enter division email'}
                error={!!hookForm.formState.errors.divisionDto?.email}
                helperText={
                  hookForm.formState.errors.divisionDto?.email?.message
                }
              />
            )}
          />
          <Controller
            name="divisionDto.mobileNo"
            control={hookForm.control}
            rules={{
              required: 'Not a valid mobile no',
              maxLength: 10,
              minLength: 10,
              pattern: {
                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                message: 'Not a valid mobile no',
              },
            }}
            render={({ field }) => (
              <EditFormFiled
                title={'MOBILE NUMBER'}
                description={field.value}
                onChange={(_, e) => {
                  onMobileNoChange(e, field);
                }}
                placeHolder={'Enter mobile no'}
                error={!!hookForm.formState.errors.divisionDto?.mobileNo}
                helperText={
                  hookForm.formState.errors.divisionDto?.mobileNo?.message
                }
              />
            )}
          />
          {/* ----------------------------------------Address----------------------------------------*/}
          <Stack spacing={1}>
            <Controller
              name="divisionDto.address.line1"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <EditFormFiled
                  title={'Address'}
                  description={field.value}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                  placeHolder={'line 1'}
                />
              )}
            />
            <Controller
              name="divisionDto.address.line2"
              control={hookForm.control}
              rules={{ required: false }}
              render={({ field }) => (
                <LosTextField {...field} placeholder={'line 2 '} />
              )}
            />
            <Stack spacing={1} direction={'row'}>
              <Controller
                name="divisionDto.address.pincode"
                control={hookForm.control}
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 6,
                    message: 'Pincode should be 6 digits',
                  },
                  minLength: {
                    value: 6,
                    message: 'Pincode should be 6 digits',
                  },
                }}
                render={({ field }) => (
                  <LosTextField
                    sx={{
                      width: '100%',
                    }}
                    type="text"
                    value={field.value}
                    onChange={(event) => {
                      onPincodeChange(event, field);
                    }}
                    placeholder={'Pincode'}
                    error={
                      !!hookForm.formState.errors.divisionDto?.address?.pincode
                    }
                    helperText={
                      hookForm.formState.errors.divisionDto?.address?.pincode
                        ?.message
                    }
                  />
                )}
              />

              <Controller
                name="divisionDto.address.city"
                control={hookForm.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <LosTextField
                    {...field}
                    placeholder={'City'}
                    sx={{
                      width: '100%',
                      textTransform: 'capitalize',
                    }}
                    disabled
                  />
                )}
              />
            </Stack>
            <Controller
              name="divisionDto.address.state"
              control={hookForm.control}
              rules={{ required: true }}
              render={({ field }) => (
                <LosTextField
                  {...field}
                  placeholder={'State'}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  disabled
                />
              )}
            />
            <Controller
              name="divisionDto.address.country"
              control={hookForm.control}
              rules={{ required: true }}
              render={({ field }) => (
                <LosTextField
                  {...field}
                  placeholder={'Country'}
                  sx={{
                    width: '100%',
                    textTransform: 'capitalize',
                  }}
                  disabled
                />
              )}
            />
          </Stack>

          {/* ----------------------------------------Hirearchy Details--------------------------------------*/}
          {getParentDivisionType(selectedLevel) && (
            <Stack spacing={1}>
              <Typography variant="body1" fontWeight={600}>
                Parent Division Details
              </Typography>
              <Stack spacing={2}>
                <Controller
                  name="divisionDto.parentDivisionId"
                  control={hookForm.control}
                  rules={{
                    required:
                      selectedLevel === 'BUSINESS_CORRESPONDENT' ? false : true,
                  }}
                  render={({ field }) => (
                    <EditFormFiled
                      title={'DIVISION ID'}
                      description={field.value}
                      onChange={(val) => {
                        field.onChange(val);
                        hookForm.setValue('programId', '');
                      }}
                      placeHolder={'Select Parent Division'}
                      options={
                        Object.values(divisionQuery.data || {}).map(
                          (division) => ({
                            value: division.id,
                            label: `${
                              division.name
                            } ( ${division.divisionCode.toUpperCase()} )`,
                          })
                        ) || []
                      }
                      isLoadingOptions={divisionQuery.isLoading}
                    />
                  )}
                />
                {parentDivisionId && (
                  <EditFormFiled
                    title={'DIVISION NAME'}
                    description={
                      divisionQuery.data
                        ? divisionQuery.data[parentDivisionId]?.name
                        : ''
                    }
                    onChange={(val) => {}}
                    placeHolder={'Parent Entity Name'}
                    disabled
                  />
                )}
              </Stack>
            </Stack>
          )}
          {/* ----------------------------------------Map Program--------------------------------------*/}

          <Stack spacing={1}>
            <Typography variant="body1" fontWeight={600}>
              Map Program
            </Typography>
            <Stack spacing={2}>
              <FormProvider {...hookForm}>
                <MapEntityProgram parentEntityId={parentDivisionId} />
              </FormProvider>
            </Stack>
          </Stack>
        </Stack>
      </CimsDrawer>
    </>
  );
};

const getEntityTypeOptions = (
  levelEntityMapping: LevelEntityMapping,
  currentLevel: Level
) => {
  switch (currentLevel) {
    case Level.CLIENT:
      return [Level.l1, Level.l2].map((level) => ({
        label: toLowerCase(levelEntityMapping[level]),
        value: level,
      }));

    case Level.l1:
      return [Level.l2].map((level) => ({
        label: toLowerCase(levelEntityMapping[level]),
        value: level,
      }));

    default:
      return [];
  }
};

const getDivisionTypeOptions = (divisionType?: DivisionType) => {
  switch (divisionType) {
    case 'CLIENT':
      return (['BUSINESS_CORRESPONDENT', 'BC_AGENT'] as DivisionType[]).map(
        (type) => ({
          label: toLowerCase(type),
          value: type,
        })
      );
    case 'BUSINESS_CORRESPONDENT':
      return (['BC_AGENT'] as DivisionType[]).map((type) => ({
        label: toLowerCase(type),
        value: type,
      }));

    default:
      return [];
  }
};

const getParentEntityLevel = (level: Level) => {
  switch (level) {
    case Level.l2:
      return Level.l1;
    default:
      return;
  }
};

const getParentDivisionType = (
  divisionType: DivisionType
): DivisionType | undefined => {
  switch (divisionType) {
    case 'BC_AGENT':
      return 'BUSINESS_CORRESPONDENT';
    default:
      return;
  }
};
