import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';
import { IReportRun } from '../../../../types/reports';
import { downloadURI } from '../../../../utils/download';
import { convertCamelCase } from '../../../../utils/stringUtils';

import { getPresignedUrl } from '../../../../api/card';
import useReportRunsForReport from '../../../../components/Reporting/hooks/useReportRunsForReport';
import CIMSDataGrid from '../../../components/common/CIMS_DataGrid';
import CimsButton from '../../../components/common/CimsButton';
import { IReportConfigMap } from './CimsReportsTable';

type IProps = {
  selectedReportConfigMap: IReportConfigMap | null;
};

function CimsPastReportsForReport({ selectedReportConfigMap }: IProps) {
  //   const { data: allReports } = useAllReports(selectedProgram?.value);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(0);

  // const { data: reportRuns } = useReportRunsForReport(
  //   selectedReportConfigMap?.reportDto?.reportId
  // );
  const reportRunsQuery = useReportRunsForReport(
    selectedReportConfigMap?.reportDto?.reportId
  );

  // const pastReportsRows = useMemo(() => {
  //   if (reportRuns) {
  //     const rows = Object.keys(reportRuns).reduce((pre, key) => {
  //       return [
  //         ...pre,
  //         {
  //           ...reportRuns[key],
  //           reportName: selectedReportConfigMap?.reportDto.reportName || '',
  //           // catalogue: reportCategories[
  //           //   allReports[reportRuns[key]?.reportId]?.categoryId
  //           // ]
  //           //   ? reportCategories[
  //           //       allReports[reportRuns[key].reportId].categoryId
  //           //     ].reportCategory
  //           //   : 'LENDING',
  //           generatedBy: selectedReportConfigMap?.reportConfig?.createdBy || '',
  //         },
  //       ];
  //     }, [] as IReportRunRow[]);

  //     rows.sort((a: any, b: any) => {
  //       const keyA = new Date(a.generatedOn);
  //       const keyB = new Date(b.generatedOn);

  //       if (keyA < keyB) return 1;
  //       if (keyA > keyB) return -1;
  //       return 0;
  //     });

  //     return rows;
  //   }
  //   return [];
  // }, [selectedReportConfigMap, reportRuns]);
  const pastReportsColumnDef: GridColDef<IReportRun>[] = [
    {
      field: 'generatedOn',
      renderHeader: () => 'Generated On',
      flex: 1.5,
      minWidth: 150,
      cellClassName: 'padding',
      headerClassName: 'padding',
      valueGetter: (props) =>
        props.row.generatedOn
          ? dayjs(props.row.generatedOn).format('DD/MM/YYYY HH:mm')
          : '-',
      renderCell: (props) => {
        return <Typography>{props.value}</Typography>;
      },
    },

    {
      field: 'reportName',
      renderHeader: () => 'Report Name',
      flex: 1.5,
      minWidth: 150,
      cellClassName: 'padding',
      headerClassName: 'padding',
      valueGetter: (props) =>
        selectedReportConfigMap?.reportDto.reportName || '',
      renderCell: (props) => {
        return <Typography>{convertCamelCase(props.value)}</Typography>;
      },
    },
    {
      field: 'generatedBy',
      renderHeader: () => 'Generated By',
      flex: 1.5,
      minWidth: 150,
      cellClassName: 'padding',
      headerClassName: 'padding',
      valueGetter: (props) =>
        selectedReportConfigMap?.reportConfig?.createdBy || '',
      renderCell: (props) => {
        return <Typography>{convertCamelCase(props.value)}</Typography>;
      },
    },
    {
      field: 'status',
      renderHeader: () => 'Status',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
      valueGetter: (props) => props.row.status,
      renderCell: (props) => {
        return <Typography>{convertCamelCase(props.value)}</Typography>;
      },
    },
    {
      field: 'reportLink',
      renderHeader: () => 'Actions',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: (props) => {
        return (
          <CimsButton
            size="small"
            onClick={async () => {
              const presignedUrl = await getPresignedUrl(props.row.reportLink);
              downloadURI(
                // props.row.reportLink,
                presignedUrl.data,
                selectedReportConfigMap?.reportDto?.reportName || '_'
              );
            }}
            disabled={props.row.status === 'FAILED'}
          >
            Download
          </CimsButton>
        );
      },
    },
  ];

  return (
    <Box mt={3}>
      <CIMSDataGrid
        data={reportRunsQuery.data || []}
        columns={pastReportsColumnDef}
        idColumn={'reportRunId'}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        page={page}
        onPageChange={(page) => {
          setPage(page);
        }}
        loading={reportRunsQuery.isLoading}
        rowCount={(reportRunsQuery.data || []).length}
        paginationMode="server"
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                justifyContent={'flex-end'}
                alignItems={'center'}
                pb={2}
              >
                <GridToolbarQuickFilter
                  autoFocus
                  variant="outlined"
                  sx={{
                    bgcolor: 'white',
                    color: '#999DA8',
                    height: '35px',
                    minWidth: '320px',
                    borderRadius: '9px',
                  }}
                />
              </Stack>
            );
          },
        }}
      />
    </Box>
  );
}

export default CimsPastReportsForReport;
