/** @format */

import axios from 'axios';
import {
  KycStepStatus,
  VegaKycStepField,
  VegaKycStepPartner,
  VegaKycStepResponse,
} from '../components/ApplicationManagment/KYCflow/KYCFlowDataTypes';
import {
  PassportVerificationResponse,
  VegaLimitOfferStatus,
  VegaLosLimitOffer,
} from '../types/los';
import { PaginatedResponse } from '../utils/api';
import { BASE_URL, LOS } from './endpoints';

const LOS_BASE_Url = BASE_URL + LOS.BASE;
const LOS_BASE_URL_V2 = BASE_URL + LOS.BASE_V2;

export async function fetchKYCFlow(
  programId: string
): Promise<VegaKycStepResponse> {
  const endpoint =
    LOS_BASE_Url +
    LOS.APPLICATION +
    LOS.GET_KYC_FLOW.replace('{programId}', programId);

  try {
    const response = await axios.get(endpoint);
    return response.data as VegaKycStepResponse;
  } catch (error) {
    throw error;
  }
}
export const fetchApplicationByApplicationId = async (
  applicationId: string
) => {
  const endpoint =
    LOS_BASE_URL_V2 +
    LOS.APPLICATION_BY_ID.replace('{applicationId}', applicationId);

  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchLimitOffersForProgramId = async (data: {
  programId: string;
}) => {
  const endpoint =
    LOS_BASE_Url +
    LOS.LIMIT_OFFER +
    LOS.GET_BY_PROGRAM.replace('{programId}', data.programId);

  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateLimitOfferStatus = async (data: {
  updatedStatus: VegaLimitOfferStatus;
  limitOfferId: string;
  acceptedLimit?: number;
  rejectionReason?: string;
}): Promise<VegaLosLimitOffer> => {
  const endpoint = LOS_BASE_Url + LOS.LIMIT_OFFER + '/' + data.limitOfferId;

  try {
    const response = await axios.put(endpoint, {
      limitOfferStatus: data.updatedStatus,
      acceptedLimit: data.acceptedLimit,
      rejectionReason: data.rejectionReason,
    });
    return response.data as VegaLosLimitOffer;
  } catch (error) {
    throw error;
  }
};
interface IRequestSaveKYCFlowData {
  name: string;
  workFlowDecision?: string | null;
  status: KycStepStatus;
  metaData?: IKycStepMetaData;
}

export interface IKycStepMetaData {
  partners?: VegaKycStepPartner[];
  fields?: VegaKycStepField[];
  subSteps?: IRequestSaveKYCFlowData[];
}

export const saveKYCFlow = (data: VegaKycStepResponse) => {
  return axios.post(LOS_BASE_Url + LOS.APPLICATION + LOS.KYC_FLOW, data);
};

export const saveNewVersionOfKycFlow = (data: VegaKycStepResponse) => {
  return axios.put(LOS_BASE_Url + LOS.APPLICATION + LOS.KYC_FLOW, data);
};

export const passportImageValidation = async (data: {
  email: string;
  imagesUrl: Array<string>;
}) => {
  const res = await axios.post(LOS_BASE_Url + '/passport/verification', data);
  const passportRes: PassportVerificationResponse = res.data;
  return passportRes.response;
};
