/** @format */

import { Grid, Typography } from '@mui/material';
import { VegaTextField } from '../common';

type Props = {
  label: string;
  valueType?: string;
  value?: number;
  onChange: (key: any, value: string | number) => void;
  disabled: boolean;
};

const AccountInputRows = ({
  valueType,
  value,
  label,
  onChange,
  disabled,
}: Props) => {
  return (
    <Grid container spacing={0} p={1} alignItems={'center'}>
      <Grid item xs={4} sm={4}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={4} sm={3}>
        <VegaTextField
          type={'text'}
          textFieldWidth={150}
          key={label}
          size="small"
          value={valueType ?? ''}
          disabled
          onChange={(e) => onChange('value_type', +e.target.value)}
        />
      </Grid>
      <Grid item xs={4} sm={3}>
        {' '}
        <VegaTextField
          key={label}
          textFieldWidth={150}
          type={'number'}
          size="small"
          value={value ?? ''}
          onChange={(e) =>
            onChange('value', e.target.value && parseFloat(e.target.value))
          }
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
    </Grid>
  );
};

export default AccountInputRows;
