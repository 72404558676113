import axios from 'axios';
import { MERCHANT, RULE } from './endpoints';
import {
  ICreateCampaignProps,
  ICreateInventoryProps,
  IPointBaseRulesProps,
} from '../types/RewardsLoyalty';
import { Merchant } from '../types/resources';
import { PaginatedResponse } from '../utils/api';

export const fetchAllMerchantList = (page: number, pageSize: number) => {
  return axios.get(`${MERCHANT.BASE}?page=${page}&pageSize=${pageSize}`);
};

export async function getAllMerchants(): Promise<PaginatedResponse<Merchant>> {
  try {
    const endpoint = MERCHANT.BASE;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchMerchantListByMid = (mid: number) => {
  return axios.get(`${MERCHANT.BASE}/filter?mid=${mid}`);
};
export const postRule = (rule: IPointBaseRulesProps) => {
  return axios.post(RULE.BASE, rule);
};
export const updateRule = (rule: IPointBaseRulesProps) => {
  return axios.put(RULE.BASE, rule);
};
export const fetchCampaignByProgramId = (programId: any) => {
  return axios.get(`${RULE.CAMPAIGN}/program/${programId}`);
};
export const fetchCampaignById = (id: any) => {
  return axios.get(`${RULE.CAMPAIGN}/${id}`);
};

export const createCampaign = (
  rule: IPointBaseRulesProps | ICreateCampaignProps
) => {
  return axios.post(RULE.CAMPAIGN, rule);
};

export const updateCampaign = (rule: any) => {
  return axios.put(RULE.CAMPAIGN, rule);
};

/////////////// INVENTORY \\\\\\\\\\\\\\\\\\\\\\\\\
export const fetchInventoryByProgramId = (id: any) => {
  return axios.get(`${RULE.INVENTORY}/program/${id}`);
};
export const fetchInventoryByProgramIdAndGiftType = (
  id: any,
  giftType: string
) => {
  return axios.get(
    `${RULE.INVENTORY}/program/${id}/giftType?giftType=${giftType}`
  );
};

export const createInventory = (inventory: ICreateInventoryProps) => {
  return axios.post(RULE.INVENTORY, inventory);
};

interface IRenewProps {
  id: string;
  renew?: boolean;
}
export const updateInventoryRenewStatus = ({ id, renew }: IRenewProps) => {
  return axios.put(`${RULE.INVENTORY}/${id}/renew?renew=${renew}`);
};
export const updateCampaignImage = (data: any) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.put(`${RULE.CAMPAIGN}/${data.id}/image`, data.formData, config);
};
