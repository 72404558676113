/* eslint-disable react/jsx-pascal-case */
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardAllotmentOrderDto,
  CardAllotmentStatusEnum,
} from '../../../../types/card';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { CardAllocationService } from '../../../apis';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import { useCardOrderRequest } from '../context';

type RevokeRequestModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  cardAllotmentOrder?: CardAllotmentOrderDto;
};

function RevokeRequestModal({
  isOpen,
  handleClose,
  cardAllotmentOrder,
}: RevokeRequestModalProps) {
  const { setSnackbar } = useSnackbar();
  const { listAllotmentQuery } = useCardOrderRequest();

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Cancel',
      onSubmit: () => {
        handleClose();
      },
      icon: '',
      isOutline: true,
    },
    {
      text: 'Revoke',
      onSubmit: () => {
        CardAllocationService.updateAllocationStatus({
          cardAllotmentOrderId: cardAllotmentOrder?.id || '',
          status: CardAllotmentStatusEnum.REVOKED,
        })
          .then((res) => {
            setSnackbar('Successfully Revoked the request');
          })
          .catch((err) => {
            setSnackbar(
              getErrorMessageFromErrorObj(err, 'Error in revoking request'),
              'error'
            );
          })
          .finally(() => {
            listAllotmentQuery?.refetch();
            handleClose();
          });
      },
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <CIMS_Modal
      maxWidth="sm"
      open={isOpen}
      handleClose={handleClose}
      header="Revoke Request"
      buttonGroup={buttons}
    >
      <Stack sx={{ px: 3, pt: 2, pb: 6 }} spacing={1}>
        <Typography fontSize={13}>
          Do you want to revoke the request?
        </Typography>
      </Stack>
    </CIMS_Modal>
  );
}

export default RevokeRequestModal;
