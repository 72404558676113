import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { VegaFileUpload, VegaTextField } from '../../../../components/common';
import VegaDatePicker from '../../../../components/common/VegaDatePicker';
import { BORDER_GRAY } from '../../../../constants/style';
import { CustomerIdentityTypes } from '../../../../enums/commons';
import { VALIDATE_REGEX } from '../../../../utils/validation.utils';
import { fetchStateOnPincode } from '../../../../api/resources';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import VegaSelect from '../../../../components/common/VegaSelect';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../../utils/FileValidator';

const validationRules = {
  required: 'This field is required',
};

const fileValidatorConfig = new FileValidatorBuilder()
  .withMultipleExtensionsCheck()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

export interface ICreateApplication {
  firstName: string;
  lastName: string;
  address: string;
  dob: string;
  //   passportNo: string;
  panNo: string;
  mobileNumber: string;
  emailId: string;
  passportFile: File;
  panFile: File;
  kycFile: File;
  ticketFile: File;
  visaFile: File;
  idType: CustomerIdentityTypes;
  idValue: string;
  state: string;
  gender: string;
  city: string;
  country: string;
  pincode: string;
}
interface IApplicationProps {
  state?: ICreateApplication;
  onSubmit: (data: ICreateApplication) => void;
  loading?: boolean;
  hookForm: UseFormReturn<ICreateApplication, any, undefined>;
}
const CreateNewApplication = ({
  onSubmit,
  loading,
  hookForm,
}: IApplicationProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = hookForm;

  const pinCodeValue = watch('pincode');
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    if (pinCodeValue?.length) {
      const getListAll = setTimeout(() => {
        fetchStateOnPincode(pinCodeValue)
          .then((res) => {
            const stateList = res.data?.records[0]?.state;
            const cityList = res.data?.records[0]?.city;
            const counteryList = res.data?.records[0]?.country;
            setValue('city', cityList);
            setValue('state', stateList);
            setValue('country', counteryList);
          })
          .catch((err) => {
            setSnackbar(getErrorMessageFromErrorObj(err), 'error');
          });
      }, 1000);
      return () => clearTimeout(getListAll);
    } else {
      setValue('city', '');
      setValue('state', '');
      setValue('country', '');
    }
  }, [pinCodeValue, setValue]);

  return (
    <>
      <Box sx={{ p: 3, pt: 0 }}>
        {/* <VegaText text="Add New Profile" /> */}

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            mt={1}
            sx={{
              borderBottom: BORDER_GRAY,
              pb: 3,
            }}
          >
            <Grid item xs={12} sm={4}>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                rules={{ required: validationRules.required }}
                render={({ field }) => (
                  <VegaSelect
                    {...field}
                    placeholder="Select Gender"
                    label="Gender"
                    error={!!errors.gender}
                    errorHelperText={errors.gender?.message || ''}
                    options={['MALE', 'FEMALE']}
                    selected={field.value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                rules={{ required: 'First name is required' }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="First Name"
                    variant="outlined"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                rules={{ required: 'Last name is required' }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="Last Name"
                    variant="outlined"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <Controller
                name="address"
                control={control}
                defaultValue=""
                rules={{ required: 'Address is required' }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="Address"
                    variant="outlined"
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name="dob"
                control={control}
                defaultValue=""
                rules={{ required: 'Date of birth is required' }}
                render={({ field }) => (
                  <VegaDatePicker
                    {...field}
                    label="Date of Birth"
                    placeholder="Date of Birth"
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    key="dob"
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} sm={4}>
              <Controller
                name="passportNo"
                control={control}
                defaultValue=""
                rules={{ required: 'Passport number is required' }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="Passport Number"
                    variant="outlined"
                    error={!!errors.passportNo}
                    helperText={errors.passportNo?.message}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} sm={4}>
              <Controller
                name="panNo"
                control={control}
                defaultValue=""
                rules={{
                  required: 'PAN number is required',
                  pattern: {
                    value: VALIDATE_REGEX.PAN,
                    message: 'Not a valid pan',
                  },
                }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="PAN Number"
                    variant="outlined"
                    error={!!errors.panNo}
                    helperText={errors.panNo?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name="mobileNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Mobile number is required',
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'Mobile number should be 10 digits',
                  },
                }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="Mobile Number"
                    variant="outlined"
                    error={!!errors.mobileNumber}
                    helperText={errors.mobileNumber?.message}
                    type="number"
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name="emailId"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    error={!!errors.emailId}
                    helperText={errors.emailId && errors.emailId.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name="pincode"
                control={control}
                rules={{
                  required: validationRules.required,
                  maxLength: {
                    value: 6,
                    message: 'Pincode should be 6 digits',
                  },
                  minLength: {
                    value: 6,
                    message: 'Pincode should be 6 digits',
                  },
                }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="Pin code"
                    type="number"
                    placeholder="Pin code"
                    error={!!errors.pincode}
                    helperText={errors.pincode?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="city"
                control={control}
                defaultValue=""
                rules={{ required: validationRules.required }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="City"
                    type="text"
                    placeholder="City"
                    error={!!errors.city}
                    helperText={errors.city?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="state"
                control={control}
                defaultValue=""
                rules={{ required: validationRules.required }}
                render={({ field }) => (
                  <VegaTextField
                    {...field}
                    label="State"
                    type="text"
                    placeholder="State"
                    error={!!errors.state}
                    helperText={errors.state?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box sx={{}}>
            <Grid container spacing={2} mt={0}>
              {UPLOAD_FILES.map((item) => (
                <Grid item xs={12} sm={4}>
                  <Controller
                    name={item.key}
                    control={control}
                    rules={{ required: item.key + ' is required' }}
                    render={({ field }) => (
                      <VegaFileUpload
                        placeholder={item.placeholder}
                        file={field.value as File}
                        onUpload={(file) => field.onChange(file)}
                        errorHelperText={errors[item.key]?.message}
                        onError={(message) => {
                          setSnackbar(
                            message ?? 'Something went wrong',
                            'error'
                          );
                          field.onChange(null);
                        }}
                        fileValidator={fileValidatorConfig}
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CreateNewApplication;
const UPLOAD_FILES: Array<{
  key: keyof ICreateApplication;
  placeholder: string;
}> = [
  {
    key: 'passportFile',
    placeholder: 'Upload Passport',
  },
  {
    key: 'panFile',
    placeholder: 'Add PAN',
  },
  {
    key: 'kycFile',
    placeholder: 'Add KYC',
  },
  {
    key: 'ticketFile',
    placeholder: 'Upload Tickets',
  },
  {
    key: 'visaFile',
    placeholder: 'Upload Visa',
  },
];
