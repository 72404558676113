import React from 'react';
import { Box, Typography } from '@mui/material';
import { GiftVoucherIcon } from '../../../components/icon/Icons';
import { GREY, PRIMARY } from '../../../constants/style';

interface IProps {
  subText?: string;
  text: string;
}

const NoVoucherAndRewards = ({ text, subText }: IProps) => {
  return (
    <Box
      sx={{
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <GiftVoucherIcon />
      <Box sx={{ my: 2, textAlign: 'center' }}>
        <Typography
          fontSize={18}
          className="font-aspekta-600"
          color={PRIMARY.darkBlue}
        >
          {text}
        </Typography>
        {subText && (
          <Typography
            fontSize={14}
            className="font-aspekta-600"
            color={GREY.dark}
          >
            {subText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default NoVoucherAndRewards;
