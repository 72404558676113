import { Box, Grid, Typography } from '@mui/material';
import { VegaAccordian, VegaTextField } from '../../../../../components/common';
import { BORDER_GRAY, GREY } from '../../../../../constants/style';
import { ISubwalletDataProps } from '../../../../types/Card';
import { IConsolidatedAccountData } from '../../../../types/Commons';
import { ICorporateAccountWalletDataProps } from '../../../../types/CorporateCustomer';

interface IProps {
  isShow?: boolean;
  state: ICorporateAccountWalletDataProps | null;
}

const DEFAULT_DATA: ISubwalletDataProps[] = [
  {
    currency: 'Dolllar (USD)',
    totalBalance: 1000,
    availableBalance: 600,
    holdBalance: 400,
  },
  {
    currency: 'Pound (GBP)',
    totalBalance: 1000,
    availableBalance: 750,
    holdBalance: 250,
  },
  {
    currency: 'Euro (EUR)',
    totalBalance: 1000,
    availableBalance: 800,
    holdBalance: 200,
  },
];

const BalanceView = ({ state }: IProps) => {
  console.log("Balance View props: ", state);
  return (
    <Box sx={{ p: 2 }}>
      {state?.subWallets &&
        state?.subWallets.map((balance: ISubwalletDataProps) => (
          <Box sx={{ my: 2 }}>
            <VegaAccordian
              noPad
              header={balance.currency}
              content={
                <>
                  <Grid
                    container
                    sx={{
                      borderTop: BORDER_GRAY,
                      borderBottom: BORDER_GRAY,
                    }}
                  >
                    <Grid
                      item
                      sm={4}
                      sx={{
                        borderRight: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                        bgcolor: GREY.light,
                      }}
                    >
                      <Typography>Account Balance</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      sx={{
                        borderRight: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                        bgcolor: GREY.light,
                      }}
                    >
                      <Typography>Available Balance</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      sx={{ textAlign: 'center', py: 1, bgcolor: GREY.light }}
                    >
                      <Typography>Hold Balance</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      sm={4}
                      sx={{
                        borderRight: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                      }}
                    >
                      <Typography>{balance.totalBalance}</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      sx={{
                        borderRight: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                      }}
                    >
                      <Typography>{balance.availableBalance}</Typography>
                    </Grid>
                    <Grid item sm={4} sx={{ textAlign: 'center', py: 1 }}>
                      <Typography>{balance.holdBalance}</Typography>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </Box>
        ))}
    </Box>
  );
};

export default BalanceView;
