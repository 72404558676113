import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { useState } from 'react';
import {
  FileValidator,
  FileValidatorResult,
} from '../../../utils/FileValidator';
import VegaTextV2 from './VegaTextV2';

interface VegaFileInputV2Props {
  acceptedExtension?: string;
  placeholder: string;
  onFileSelect: (file: File | null) => void;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  onError?: (errorMessage: string | null) => void;
  fileValidator?: FileValidator;
}

const StyledSelectField = styled(Box)(({ theme }) => ({
  border: '1px solid rgba(208, 212, 218, 1)',
  borderRadius: '.5rem',
  padding: '0.5rem 1rem 0.5rem 0.75rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  maxHeight: '32px',
  minHeight: '32px',
  textOverflow: 'ellipsis',
  cursour: 'pointer',
}));

function VegaFileInputV2({
  onFileSelect,
  endIcon,
  placeholder,
  acceptedExtension,
  startIcon,
  onError,
  fileValidator,
}: VegaFileInputV2Props) {
  const [filename, setFilename] = useState('');

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (!fileValidator) {
      setFilename(selectedFile?.name ?? '');
      onFileSelect(selectedFile);
      return;
    }

    if (selectedFile) {
      const validationResult: FileValidatorResult =
        await fileValidator.validateFile(selectedFile);

      if (validationResult.isValid) {
        setFilename(selectedFile.name);
        onFileSelect(selectedFile);
      } else {
        onError?.(validationResult.errorMessage);
      }
    }
  };

  return (
    <StyledSelectField>
      <label
        htmlFor="file-input"
        style={{
          flex: 1,
          fontFamily: 'Aspekta !important',
          textOverflow: 'ellipsis',
          msTextOverflow: 'ellipsis',
          overflow: 'hidden',
          maxHeight: '32px',
          minHeight: '32px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            maxHeight: '32px',
            minHeight: '32px',
            width: '100%',
            height: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {startIcon && (
            <div
              style={{
                padding: '4px',
                height: '32px',
                width: '32px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'start',
              }}
            >
              {startIcon}
            </div>
          )}
          <VegaTextV2
            text={filename || placeholder}
            fontSize={'13px'}
            lineHeight={'120%'}
            textOverflow="ellipsis"
          />
        </div>
      </label>
      {endIcon}
      <input
        id="file-input"
        type="file"
        accept={acceptedExtension ?? '.pdf'}
        onChange={handleFileSelect}
        style={{ display: 'none' }}
      />
    </StyledSelectField>
  );
}

export default VegaFileInputV2;
