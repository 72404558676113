/* eslint-disable react/jsx-pascal-case */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ProgramInfo from './DataTile';
import Typography from '@mui/material/Typography';
import { Vega_Switch } from '../common/v3/Vega_Switch';
import { VegaUser } from '../../types/user';

type MakerCheckerItemProps = {
  isPrimary?: boolean;
  isChecker?: boolean;
  user: VegaUser;
};

function MakerCheckerItem({
  isPrimary,
  isChecker,
  user,
}: MakerCheckerItemProps) {
  return (
    <Box
      sx={{
        px: 2,
        py: 1.5,
        borderRadius: 1,
        border: '1px solid #EEE',
        background: '#FAFAFA',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4} lg={2}>
          <ProgramInfo title="Name" value={user.name ?? '-'} />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <ProgramInfo title="Employee ID" value={user.corporateId ?? '-'} />
        </Grid>
        <Grid item xs={6} sm={5} lg={3}>
          <ProgramInfo title="Email ID" value={user.email ?? '-'} />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <ProgramInfo title="Phone Number" value={user.mobileNumber ?? '-'} />
        </Grid>
        {!isChecker ? (
          <>
            <Grid item xs={6} sm={3} lg={1}>
              <ProgramInfo title="Status">
                <Vega_Switch
                  checked={user.status === 'ACTIVE'}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </ProgramInfo>
            </Grid>
            {isPrimary ? (
              <Grid item xs={12} md={4} lg={1}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xs: 'center',
                      lg: 'flex-end',
                    },
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography
                    className="font-aspekta-700"
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: 10,
                      color: '#C9A907',
                    }}
                  >
                    Primary
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </>
        ) : null}
      </Grid>
    </Box>
  );
}

export default MakerCheckerItem;
