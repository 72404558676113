import { Card, Divider, Stack, Typography } from '@mui/material';
import _ from 'lodash';

import {
  ChargePolicyInterface,
  ChargeRuleInterface,
} from '../../../types/policy';
import {
  IPolicySummaryData,
  IRowData,
  PolicySummaryTable,
} from './common/SummaryTable';

interface Props {
  policy?: ChargePolicyInterface | null;
}

const ChargePolicyListItem = (data: {
  heading: string;
  summaryData: IPolicySummaryData;
}) => {
  return (
    <Card elevation={0} style={{ borderRadius: 0 }}>
      <Typography style={{ padding: 12 }}>{data.heading}</Typography>
      <Divider />
      <div style={{ padding: 12 }}>
        <PolicySummaryTable
          headers={data.summaryData.headers}
          rows={data.summaryData.rows}
        />
      </div>
      <Divider />
    </Card>
  );
};

function ChargePolicySummary({ policy }: Props) {
  const getChargeSummaryRows = (chargeRule: ChargeRuleInterface) => {
    const charge = chargeRule.charge;
    const summaryRows: IRowData[] = [];

    if (charge.value_type) {
      summaryRows.push({
        rowData: ['Value Type', _.startCase(_.toLower(charge.value_type))],
      });
    }

    if (charge.value) {
      summaryRows.push({ rowData: ['Value', charge.value.toString()] });
    }

    if (chargeRule.taxation_type) {
      summaryRows.push({
        rowData: [
          'Taxation Type',
          _.startCase(_.toLower(chargeRule.taxation_type)),
        ],
      });
    }

    if (charge.charge_type) {
      summaryRows.push({
        rowData: ['Charge Type', _.startCase(_.toLower(charge.charge_type))],
      });
    }

    if (chargeRule.frequency) {
      summaryRows.push({
        rowData: ['Frequency', _.startCase(_.toLower(chargeRule.frequency))],
      });
    }
    return summaryRows;
  };

  return (
    <Stack>
      {policy?.charge_rules.map((item) => {
        return (
          <ChargePolicyListItem
            heading={_.startCase(_.toLower(item.charge_code))}
            summaryData={{
              headers: [],
              rows: getChargeSummaryRows(item),
            }}
          />
        );
      })}
    </Stack>
  );
}

export default ChargePolicySummary;
