/* eslint-disable react/jsx-pascal-case */
import { ArrowBackIos } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import usePassword from '../../../hooks/usePassword';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { CIMS_PRIMARY } from '../../assets/style';
import CIMS_Button from '../common/CIMS_Button';
import CIMS_TextField from '../common/CIMS_TextField';

interface IProps {
  forgotPasswordHandler: (state: string) => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const NewPassword = ({ forgotPasswordHandler, email }: IProps) => {
  const { password, setPassword, renderValidations, isValidPassword } =
    usePassword();
  const { setSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [code, setCode] = useState<string>('');

  const isDisabled = () => {
    return !email || !code || !isValidPassword();
  };

  const onSubmit = () => {
    Auth.forgotPasswordSubmit(email, code, password)
      .then((res) => {
        forgotPasswordHandler('LOGIN');
      })
      .catch((err) => {
        setSnackbar(err.message, 'error');
      });
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton onClick={() => forgotPasswordHandler('FORGOT')}>
            <ArrowBackIos sx={{ color: 'black', fontSize: 14 }} />
          </IconButton>
          <Typography fontSize={14} className="font-aspekta-600">
            Back
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '330px' : '400px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography
            fontSize={17}
            className="font-aspekta-600"
            color={CIMS_PRIMARY.BLACK}
          >
            Forgot your password
          </Typography>
          <Box
            my={3}
            component={'form'}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Box mb={2}>
              <CIMS_TextField
                header="code"
                type="text"
                value={code}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setCode(inputValue);
                }}
              />
            </Box>
            <Box mb={2}>
              <CIMS_TextField
                header="new password"
                type="password"
                value={password}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setPassword(inputValue);
                }}
              />
            </Box>
            <Box mt={1} maxWidth={400}>
              {renderValidations()}
            </Box>
            <Stack spacing={2}>
              <CIMS_Button
                type="submit"
                text="Continue"
                disabled={isDisabled()}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewPassword;
