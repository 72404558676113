import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { VPButton, VPModal } from '../../../ui-components/common';
import { VPButtonProps } from '../../../ui-components/common/VPButton';

interface IProps {
  onSubmit: any;
  onCancel?: any;
  header?: string;
  triggerLabel?: string;
  hasConfirmCTA?: boolean;
  confirmText?: string;
  confirmCTAProps?: any;
  hasCancelCTA?: boolean;
  cancelText?: string;
  cancelCTAProps?: any;
  children: any;
  triggerProps?: VPButtonProps;
}

export function GenericModal({
  onSubmit,
  onCancel,
  header = 'Change Status',
  triggerLabel = 'Acknowledge Loss',
  triggerProps,
  ...props
}: IProps) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <VPButton variant="outlined" onClick={handleClickOpen} {...triggerProps}>
        {triggerLabel}
      </VPButton>
      <VPModal
        maxWidth="sm"
        open={open}
        handleClose={handleClose}
        header={header}
        footer={
          <Stack spacing={1} direction={'row'}>
            {props?.hasCancelCTA ? (
              <VPButton
                variant="text"
                {...props?.cancelCTAProps}
                onClick={() => {
                  onCancel();
                  handleClose();
                }}
              >
                {props?.cancelText}
              </VPButton>
            ) : null}
            {props?.hasConfirmCTA ? (
              <VPButton
                {...props?.confirmCTAProps}
                onClick={() => {
                  onSubmit();
                  handleClose();
                }}
              >
                {props?.confirmText}
              </VPButton>
            ) : null}
          </Stack>
        }
      >
        {props?.children}
      </VPModal>
    </Box>
  );
}

GenericModal.defaultProps = {
  hasConfirmCTA: true,
  confirmText: 'Confirm',
  hasCancelCTA: true,
  cancelText: 'Cancel',
};
