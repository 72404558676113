/** @format */

export const formContentType = {
  'content-type': 'multipart/form-data',
};

export const BASE_URL = '';

export const CLIENT = {
  BASE: '/onboarding/v1/client',
  FOREX_BASE: '/forex/client',
  UPDATE_LOGO: '/logo',
};

export const CARD_BASE = '/card/v1';

export const PROGRAM = {
  BASE: '/program/v1/program',
  PROGRAM_KEY: '/programKey',
  CREATE: '/create',
  LIST: '/list',
  LIST_WITH_META_DATA: '/listWithMetaData',
  UPDATE_STATUS: '/status',
  SANCTION_LETTER: '/sanction-letter',
  AGREEMENT_TWO: '/agreement-two',
  LOAN_EMI_AGREEMENT: '/loan-emi-agreement',
  DELETE_SANCTION_LETTER: '/{programId}/sanction-letter',
  DELETE_AGREEMENT_TWO: '/{programId}/agreement-two',
  DELETE_LOAN_EMI_AGREEMENT: '/{programId}/loan-emi-agreement',
  DOCUMENT_URL: '/{programId}/documentFormat',
  DEFAULT_DOCUMENT_URL: '/defaultDocumentFormat',
  PROGRAM_MITC: '/{programId}/mitc',
  UPDATE_BIN: '/bin',
  UPDATE_MAX_PDD_ALLOWED: '/max-ppd-allowed',
  GENERATE_SETTLEMENT_CALENDAR: '/settlementCalendar',
  GET_SETTLEMENT_CALENDAR: '/settlementCalendar',
};

export const POLICY = {
  BASE: '/program/{programId}/policy',
  PDD: '/program/{programId}/paymentDueDates',
  MAD: '/bill',
  POLICIES: '/program/client',
  ALL_FOR_PROGRAM: '/all',
  POLICY_TYPE: '/{policyType}',
  MULIPLE_POLICIES: '/multiple',
  SUBMIT_TO_CHECKER: '/program/policy/submit', // api does not take program id so had to define complete endpoint
  DISABLE_POLICY: '/program/policy/disable/{policyId}',
  DEFAULT_POLICY: '/program/defaultPolicy/{policyType}',
};

export const CUSTOMER = {
  BASE: '/onboarding/v1/customer',
  LIST: '/list/{clientId}',
  LIST_WITH_ACCOUNT: '/list/withAccount',
  AUDIT: '/audit',
};

export const ACCOUNT = {
  BASE: '/account',
  LIMIT_OFFER: '/limitoffer',
  LIST: '/list/program/{programId}',
};

export const TRANSACTION = {
  BASE: '/transaction',
  REPAYMENT: '/payment',
  LIST: 'list',
  DISPUTE: '/dispute',
  TICKETS: '/support/freshdesk/account',
};

export const BILL = {
  BASE: '/bill',
  LIST: '/list',
  BILL_LATEST_BY_ACCOUNT_ID: '/bill/latest/accountId',
};
export const BIN = {
  BASE: `${CARD_BASE}/bin`,
  LIST_BY_IDS: '/listByIds',
};
export const VENDOR_SERVICE = {
  BASE: '/credential/v1/credential/vendor-credential',
};
export const ROLE_MANAGEMENT = {
  BASE: '/credential/v1/credential/role-management',
  GET_BY_EMAIL: '/user/{email}',
  ADD_COGNITO_USER: '/addCognitoUser',
  GET_USER_BY_EMAIL: '/user/{email}',
  UPDATE_ROLE_FOR_USER: '/role',
  REVOKE_REFRESH_TOKEN: '/revokeToken',
  ADD_USER_WITH_ROLES: '/add-user-with-roles',
};
export const RESOURCES = {
  BASE: '/onboarding/v1/data',
  MCG_BASE: '/mcg',
  UPDATE_MCG: '/mcg',
};

export const REWARDS = {
  LIST_FOR_ACCOUNT:
    '/rewards/list/between?accountId={accountId}&startTime=2021-08-08T00:00:00Z&endTime=2025-11-21T00:00:00Z',
  CAMPAIGN: '/campaign',
};
export const REPORT = {
  BASE: '/reporting',
  CATEGORY: '/category',
  CATALOG: '/catalog',
  REPORTS: '/reports',
  // CREATE : '/report/'
  REPORT: '/report',
};

export const COLLECTION = {
  BASE: '/collection',
  QUERY_COLLECTIONS: '/query/get',
  GET_PROGRAM_DELINQUENCY: '/getProgramDelinquencies/{programId}',
  GET_SUMMARY_BY_DPD: '/summaryByDpd/{dpd}',
  GET_AGENTS: '/getAgents',
  GET_BUCKETS: '/buckets',
  ACCOUNT_DELINQUENCY_SUMMARY: '/delinquency/history/{accountId}',
};
export const UNIVERSSAL_STANDARD = {
  BASE: '/program/v1/program',
};

export const DISPUTE = {
  BASE: '/dispute',
  FILTER: '/filter',
  STATUS: '/status',
  ACQUIRE_DISPUTE: '/acquireDispute',
};

export const LOS = {
  BASE: '/los',
  BASE_V2: '/los/v2',
  APPLICATION: '/application',
  APPLICATION_BY_ID: '/application/{applicationId}',
  KYC_FLOW: '/kyc_flow',
  GET_KYC_FLOW: '/{programId}/kyc_flow',
  LIMIT_OFFER: '/limitOffer',
  GET_BY_PROGRAM: '/program/{programId}',
};

export const ENTITY_AUTH = {
  BASE: '/authorization',
  GET_STATUS: '/entityId?entityId={entityId}',
  UPDATE_STATUS: '/status',
  ADD_COMMENT: '/comments',
  UPDATE_COMMENT: '/comments/{commentId}',
  SUBMIT_TO_CHECKER: '/submitToChecker',
  SUBMIT_TO_MAKER: '/submitToMaker',
  LIST_BY_ENTITY_IDS: '/listByEntityIds',
};

export const INSTALLMENT = {
  BASE: '/installment',
  PREVIEW: '/preview/transaction',
  CONVERT_TO_EMI: '/convert/transaction',
  LIST_FOR_ACCOUNT: '/list/account/',
};

export const WEBHOOK = {
  BASE: '/webhook',
  LIST_BY_PROGRAM_ID: '/program/{programId}',
  CREATE: '/',
  UNSUBSCRIBE: '/unsubscribe/{webhookId}',
};

export const CARD_PROCESSOR = {
  BASE: `${CARD_BASE}/card`,
  GET_CARD_BY_ID: '/{cardId}',
  CARD_MANAGEMENT_BASE: `${CARD_BASE}/card/card-management`,
  LIST_CARD: '/listCard',
  UPDATE_CARD_STATUS: '/updateCardStatus',
  LIST_CARDS: '/listCard',
  LIST_CARDS_CURRENT: '/listCard/current',
  SEARCH_CARDS: '/searchCard',
  LIST_ACTIVE_CARD: '/listActiveCard',
  GET_BIN_SETTINGS: '/program/{programId}/binSettings',
  GET_ADD_ON_CARD_CONFIGURATION: '/program/{programId}/addOnCardConfiguration',
  GET_CARD_SETTINGS: '/program/{programId}/cardSettings',
  SAVE_BIN_SETTINGS: '/binSettings',
  SAVE_ADD_ON_CARD_CONFIGURATION: '/addOnCardConfiguration',
  SAVE_CARD_SETTINGS: '/cardSettings',
  SAVE_CARD_DESIGN_CONFIG: '/cardSettings/cardDesign',
  GET_CARD_DESIGN_CONFIG: '/cardSettings/cardDesign',
  LIST_PHYSICAL_CARD_ORDERS: '/listPhysicalCardOrders',
  UPLOAD_BULK_CARD: '/bulk/card/tempBlock',
  BULK_CARD_LIST: '/getOrder/block/list',
  UPDATE_CARD_PREFERANCE: '/updateCustomerPreference',
};

export const AUDIT = {
  BASE: '/audit',
  DATES: '/dates',
};

export const MERCHANT = {
  BASE: '/data/merchant',
};
export const RULE = {
  BASE: '/rule',
  CAMPAIGN: '/campaign',
  INVENTORY: '/inventory',
};

export const LEDGER = {
  BASE: '/ledger',
  POSTING_META_DATA_FOR_ACCOUNT: '/postingMetaData/accountId/{accountId}',
  TCS: '/tcs/{accountId}',
  TCS_SUMMARY: '/tcs/summary/{accountId}',
};

export const FRM = {
  BASE: '/frm',
  GET_DEFAULT_POLICY: '/default',
};

export const UAM = {
  BASE: '/credential/v1/credential/uam',
  ADD_ROLE_WITH_RESOURCES: '/role-with-resource-access',
  FETCH_ROLE: '/role/{roleId}',
  FETCH_ROLE_RESOURCES: '/role/{roleId}/resource/access',
  UPDATE_ROLE_STATUS: '/role/{roleId}/status',
  FETCH_ROLES_WITH_FILTER: '/role/list-with-filters',
};

export const DIVISION = {
  BASE: '/division',
  FETCH_DIVISIONS: '/fetch',
  FETCH_CHILD_DIVISIONS: '/fetch/child',
  UPDATE_STATUS: '/status',
};
