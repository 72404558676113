import { Stack } from '@mui/material';
import _ from 'lodash';
import {
  VoucherInventory,
  GiftVoucherType,
  GiftVoucher,
} from '../../../../../../../../../../../types/RewardsLoyalty';
import { VegaSelectOptionV2 } from '../../../../../../../../../../common/v2/VegaSelectV2';
import VoucherTab from '../VoucherTab';
import { SubscriptionVoucherListItem } from './SubscriptionVoucherListItem';

type Props = {
  vouchers: Partial<GiftVoucher>[];
  allAvailableVouchers: VoucherInventory[];
  addVoucher: (voucher: Partial<GiftVoucher>) => void;
  updateVoucher: (data: {
    updatedVoucher: Partial<GiftVoucher>;
    index: number;
  }) => void;
  deleteLastVoucher: () => void;
};
function SubscriptionVoucherList({
  vouchers,
  allAvailableVouchers,
  addVoucher,
  updateVoucher,
  deleteLastVoucher,
}: Props) {
  const canAddGift = () => {
    if (vouchers.length <= 0) return true;
    let allAvailableVouchersUsed =
      vouchers.length == allAvailableVouchers.length;
    if (allAvailableVouchersUsed) return false;
    const lastGift = vouchers.at(vouchers.length - 1);
    if (!lastGift) return false;
    let isDelayValid = lastGift.delayDays != null && lastGift.delayDays > 0;
    return isDelayValid;
  };

  function addGift() {
    if (!canAddGift()) return;
    addVoucher({});
  }

  const getVoucherById = (id: string) => {
    const filteredVouchers = allAvailableVouchers.filter(
      (item) => item.id == id
    );
    if (filteredVouchers.length <= 0) return;
    const selectedVoucher = filteredVouchers[0];
    return selectedVoucher;
  };

  function onVoucherVendorSelect(voucherId: string, index: number) {
    const voucher = getVoucherById(voucherId);
    const giftItem = vouchers.at(index);
    const updatedItem = { ...giftItem };
    updatedItem.giftId = voucher?.id;
    updatedItem.vendorName = voucher?.vendorName;
    updatedItem.giftType = GiftVoucherType.SUBSCRIPTION;
    updatedItem.amount = getPrice(updatedItem);
    updateVoucher({
      updatedVoucher: updatedItem,
      index: index,
    });
  }

  function onDelayChange(index: number, delay?: number) {
    const giftItem = vouchers.at(index);
    const updatedItem = { ...giftItem };
    updatedItem.delayDays = delay;
    updateVoucher({
      updatedVoucher: updatedItem,
      index: index,
    });
  }

  function removeGift() {
    deleteLastVoucher();
  }

  const getPrice = (item: Partial<GiftVoucher>) => {
    const id = item.giftId;
    if (!id) return;
    return getVoucherById(id)?.subscribtion.number;
  };

  return (
    <Stack spacing={2} style={{}}>
      <VoucherTab
        title={'Subscription Voucher'}
        itemsCount={vouchers.length}
        disabledAdd={canAddGift() == false}
        onAdd={function (): void {
          addGift();
        }}
        onRemove={function (): void {
          removeGift();
        }}
      />

      {vouchers.map((item, index) => {
        return (
          <SubscriptionVoucherListItem
            key={index}
            index={index}
            selectedVoucher={item.giftId}
            onGiftVoucherSelect={function (voucherId: string): void {
              onVoucherVendorSelect(voucherId, index);
            }}
            onDelayChange={function (delay?: number): void {
              onDelayChange(index, delay);
            }}
            vouchers={voucherOptions(allAvailableVouchers)}
            price={getPrice(item)}
            delayDays={item.delayDays}
          />
        );
      })}
    </Stack>
  );
}

export default SubscriptionVoucherList;

const voucherOptions = (giftVouchers: VoucherInventory[]) => {
  return giftVouchers.map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item.id,
      value: item.id,
      label: `${_.startCase(_.toLower(item.vendorName))} - ${
        item.subscribtion.number
      }`,
    };
    return option;
  });
};
