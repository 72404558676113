import { IAccountProps, ISubwalletDataProps } from './Card';

export interface ICreateCorporateCustomer {
  clientId: string;
  corporateName: string;
  mobileNumber: string;
  emailId: string;
  address: string;
  pinCode: string;
  state: string;
  city: string;
  cardNetwork: string;
  gstNumber: string;
  gstFile: File | null;
  balanceSheetFile: File | null;
  accountLimitType: AccountLimitType | string;
  accountLimit: string;
  billingCycle: string;
}

export interface ICorporateEntity {
  aadharNo?: string | null;
  panNo?: string | null;
  passportNo?: string | null;
  address: string;
  id: string;
  countryCode: string;
  emailId: string;
  clientId: string;
  branchId: string;
  status: string;
  customerType: string;
  checkerMakerId: string | null;
  checkerMakerStatus: string | null;
  mobileNumber: string;
  pinCode: any;
  state: string;
  city: string;
  createdAt: string;
  updatedAt: string;
  corporateName: string;
  cardNetwork: string;
  gstNumber: string;
  gstFileUrl: string | null;
  balanceSheetFileUrl: string | null;
  accountLimitType: AccountLimitType;
  accountLimit: number;
  billingCycle: number;
}
export interface IFilterCorporateCustomer {
  value: number | string | any;
  programId?: string;
  clientId?: string;
  startDate?: string;
  endDate?: string;
  binNumber?: string;
}

export interface ICorporateAccountWalletDataProps {
  account: null | IAccountProps;
  corporate: null | ICorporateEntity;
  subWallets: null | ISubwalletDataProps[];
}

export enum AccountLimitType {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID',
}

export interface ICreateCorporateAccountProps {
  corporateId: string;
  branchId: string;
  accountType: string;
  accountLimit?: string;
  billingCycle?: string;
}
