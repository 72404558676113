import { useEffect, useState } from 'react';
import { EntityService } from '../../cims/apis';
import VegaSelect from '../../components/common/VegaSelect';
import { useClientAuth } from '../../providers/ClientProvider';
import { EntityDto, FetchEntityRequestDto } from '../../types/entity';
import { EntityStatusEnum } from '../../enums/commons';
import {
  DivisionDto,
  DivisionType,
  FetchDivisionPayload,
} from '../../types/division';
import { DivisionService } from '../../api/division';

interface IProps {
  selected: string;
  handleChange: (value: string) => void;
  name: string;
  value: string;
}

const BC_CodeSelector = ({ name, selected, handleChange, value }: IProps) => {
  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [entityMetadata, setEntityMetadata] = useState<Array<DivisionDto>>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== entityMetadata.length) {
        setPage(page + 1);
      }
    }
  };

  const getBC_CodeList = async () => {
    // commented for now
    // const data = {
    //   clientId: clientId,
    //   entityType: value,
    //   statusList: [EntityStatusEnum.ACTIVE],
    // } as FetchEntityRequestDto;

    // EntityService.listWithMetaData({ entityRequest: data }).then((res) => {
    //   setEntityMetadata(res.records);
    // });

    const data: FetchDivisionPayload = {
      clientId: clientId ?? '',
      divisionType: value as DivisionType,
      divisionStatusList: ['ACTIVE'],
    };

    DivisionService.fetchDivisions(data).then((res) => {
      setEntityMetadata(res.records);
    });
  };

  const corporateHandleChange = (id: string) => {
    console.log('id', id);
    // const findCorporate = corporates.find((val) => val.id === id);
    handleChange(id);
  };

  useEffect(() => {
    getBC_CodeList();
  }, [page, value]);
  return (
    <>
      <VegaSelect
        placeholder="Division Code"
        label="Division Code"
        size="small"
        key={name}
        selected={selected ?? ''}
        onChange={(value) => corporateHandleChange(value)}
        options={entityMetadata.map((entity) => ({
          id: entity.id,
          name: `${entity.name || ''} (${entity.divisionCode})`,
        }))}
        onScroll={loadMoreItems}
        formatText={false}
      />
    </>
  );
};

export default BC_CodeSelector;
