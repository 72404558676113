import { useEffect, useState } from "react";

import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid";
import { listActiveOfferByProgramId } from "../../../api/account";
import { PRIMARY } from "../../../constants/style";
import { useProgramData } from "../../../providers/ProgramDataProvider";
import { VegaToolTipAndCopy } from "../../common/VegaToolTipNCopy";

function useOffer() {
    const { selectedProgram } = useProgramData();
    const [data, setData] = useState<any>([]);
    const [open, setOpen] = useState(false);
  
    const [selectedData, setSelectedData] = useState<any>({});

    const offersColDef: GridColDef[] = [
      {
        field: "id",
        renderHeader: () => (
          <Typography variant="body2" fontWeight={"bold"}>
            Applicaction ID
          </Typography>
        ),
        flex: 1.6,
        renderCell : (props) =><VegaToolTipAndCopy value={props.row.id} title = 'Application Id'/>
      },
    
      {
        field: "newLimit",
        renderHeader: () => (
          <Typography variant="body2" fontWeight={"bold"}>
            Offer
          </Typography>
        ),
        flex: .7,
      },
      {
        field: "limitType",
        renderHeader: () => (
          <Typography variant="body2" fontWeight={"bold"}>
            Offer Type
          </Typography>
        ),
        flex: 1.4,
        //   renderCell(props) {
        //     return <Typography>Jumbo Loan</Typography>;
        //   },
      },
      {
        field: "viewProfile",
        renderHeader: () => (
          <Typography variant="body2" fontWeight={"bold"}>
            View Profile
          </Typography>
        ),
        renderCell: (props) => {
          console.log("props , applicatoin ", props);
          return (
            <Box>
              <Button
                variant="outlined"
                color="info"
                style={{
                  borderRadius: 4,
                  padding: 1,
                }}
                onClick={() => {
                  setOpen(true);
                  setSelectedData(props.row);
                }}
              >
                View
              </Button>
            </Box>
          );
        },
        flex: .7,
      },
      {
        field: "generateCustomLink",
        renderHeader: () => (
          <Typography variant="body2" fontWeight={"bold"}>
            Generate Custom Link
          </Typography>
        ),
        flex: 1,
        renderCell(props) {
          return (
            <Box>
              <Button
                size="small"
                sx={{
                  bgcolor: PRIMARY.light,
                  ":hover": { bgcolor: PRIMARY.light },
                  "&.Mui-disabled": {
                    color: PRIMARY.light,
                    bgcolor: PRIMARY.lighter,
                  },
                  color: "white",
                }}
              >
                Send Link
              </Button>
            </Box>
          );
        },
      },
      
    ];
  
    useEffect(() => {
      if (selectedProgram?.programId) {
        listActiveOfferByProgramId(selectedProgram?.programId || '2b1c044c-5c0d-493d-bb55-c8ba5081496a')
        // listActiveOfferByProgramId("2b1c044c-5c0d-493d-bb55-c8ba5081496a")
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => {
            console.log("res for the offers managemenmt erro ", err);
          });
      }
    }, [selectedProgram]);


    return {
        offerTableData : data,
        offersColDef,
        isOfferDrawerOpen : open,
        setIsOfferDrawerOpen : setOpen,
        selectedOfferData : selectedData
    }
}

export default useOffer