import React from 'react';
import { Box, Stack } from '@mui/material';
import { NearMeIcon } from '../icons/SupportIcons';
import Vega_Text from '../../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../../constants/style';
import Vega_Button from '../../common/v3/Vega_Button';

const SupportQuickActions = () => {
  return (
    <Box>
      <Stack direction={'row'} alignItems={'center'} gap={1} mb={1.5}>
        <NearMeIcon />
        <Vega_Text
          text={'Quick Actions'}
          fontSize={13}
          fontWeight={500}
          color={NEW_PRIMARY.BLACK}
        />
      </Stack>
      <Vega_Button
        fullWidth
        text="Edit KYC Details"
        variant="outlined"
        bgColor="transparant"
        textColor="#1047DC"
      />
    </Box>
  );
};

export default SupportQuickActions;
