import { IAPIStatus, IErrorStatus } from '../types/commonType';
import { CustomerAddress, ICustomer } from '../types/customers';

export function getFormData(object: Record<string, any>) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (object[key] != null && object[key] !== undefined) {
      Array.isArray(object[key])
        ? (object[key] as Array<any>).forEach((element) => {
            element != null &&
              element !== undefined &&
              formData.append(key, element);
          })
        : formData.append(key, object[key]);
    }
  });
  return formData;
}

export const getCustomerName = (customer: ICustomer | null) => {
  if (!customer) return '';

  const fName = customer?.firstName || '';
  const mName = customer?.middleName || '';
  const lName = customer?.lastName || '';

  return (fName + ' ' + mName + ' ' + lName).trim();
};

export function getEmptyFields(obj: Object) {
  const emptyFields = Object.entries(obj)
    .filter(([key, value]) => value === '')
    .map(([key]) => key);

  return emptyFields;
}

export const getAPICommonPendingStatus = (
  currentAPIStatus: IAPIStatus = {}
) => {
  return {
    ...currentAPIStatus,
    isLoading: true,
    isError: false,
  } as IAPIStatus;
};

export const getAPICommonRejectedStatus = (
  currentAPIStatus: IAPIStatus = {},
  defErrMsg?: string
) => {
  return {
    ...currentAPIStatus,
    isLoading: false,
    isError: true,
    errMessage: defErrMsg || 'Unknown Error',
  } as IAPIStatus;
};

export const getAPICommonFulfilledStatus = (
  currentAPIStatus: IAPIStatus = {}
) => {
  return {
    ...currentAPIStatus,
    isLoading: false,
    isError: false,
    isFirstCallDone: true,
  } as IAPIStatus;
};

export const standard500ErrResponse = (err?: any) => {
  const errData = err?.response?.data?.apierror;
  return {
    apierror: (errData || {
      status: err?.code,
      message: err?.message,
    }) as IErrorStatus,
  };
};

export const getAddressfromAdressDto = (address?: CustomerAddress | null) => {
  if (address === null || address === undefined) {
    return '';
  }
  const { line1, line2, city, state, pincode, country } = address;
  const lines = [line1, line2, city, state, pincode, country].filter(Boolean);
  return lines.join(' , ');
};
