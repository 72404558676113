import { DeleteOutline, EditOutlined, Save } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  MilestoneEventType,
  PointBasedMilestone,
} from '../../../../../../../../../types/RewardsLoyalty';
import VegaCardV2 from '../../../../../../../../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../../../../../../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../../../../../../../common/v2/VegaPrimaryButton';
import VegaTextFieldV2 from '../../../../../../../../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../../../../../../../common/v2/VegaTextV2';
import VegaConditionalWrapper from '../../../../../../../../common/VegaConditionalWrapper';
import { useCampaignRule } from '../../../../../../../providers/CampaignRuleProvider';

enum ViewState {
  INPUT,
  READ_ONLY,
}

type Props = {
  index: number;
  value: Partial<PointBasedMilestone>;
  onSubmit: (data: Partial<PointBasedMilestone>) => void;
  onDelete: () => void;
};

function PointBasedMilestoneInput({ index, onSubmit, value, onDelete }: Props) {
  const { selectedEvent } = useCampaignRule();
  const [viewState, setViewState] = useState<ViewState>(ViewState.INPUT);
  const [count, setCount] = useState<number>();
  const [volume, setVolume] = useState<number>();
  const [rewardPoints, setRewardPoints] = useState<number>();

  function updateViewState(updatedState: ViewState) {
    setViewState(updatedState);
  }

  const isInputValid = () => {
    if (selectedEvent.length <= 0) return false;
    var isValid = false;
    const isCountValid = count != null && count > 0;
    const isVolumeValid = volume != null && volume > 0;
    const isRewardPointValid = rewardPoints != null && rewardPoints > 0;

    switch (selectedEvent) {
      case MilestoneEventType.BY_COUNT:
        isValid = isCountValid;
        break;
      case MilestoneEventType.BY_VOLUME:
        isValid = isVolumeValid;
        break;
      case MilestoneEventType.BOTH:
        isValid = isVolumeValid && isCountValid;
        break;
    }

    return isValid && isRewardPointValid;
  };

  function updateInputsBasedOnEventSelected(event: string) {
    switch (event) {
      case MilestoneEventType.BY_COUNT:
        setVolume(undefined);
        break;
      case MilestoneEventType.BY_VOLUME:
        setCount(undefined);
        break;
    }
  }

  const showCountInput = () => {
    return (
      selectedEvent == MilestoneEventType.BY_COUNT ||
      selectedEvent == MilestoneEventType.BOTH
    );
  };

  const showVolumeInput = () => {
    return (
      selectedEvent == MilestoneEventType.BY_VOLUME ||
      selectedEvent == MilestoneEventType.BOTH
    );
  };

  function onSaveClick() {
    if (!rewardPoints) return;
    updateViewState(ViewState.READ_ONLY);
    const data: Partial<PointBasedMilestone> = {
      rewardPoints: rewardPoints,
      count: count,
      volume: volume,
    };
    onSubmit(data);
  }

  useEffect(() => {
    if (selectedEvent.length > 0) {
      updateInputsBasedOnEventSelected(selectedEvent);
    }
  }, [selectedEvent]);

  useEffect(() => {
    const rewardPoints = value.rewardPoints;
    setCount(value.count);
    setRewardPoints(value.rewardPoints);
    setVolume(value.volume);
    if (rewardPoints && rewardPoints > 0) {
      updateViewState(ViewState.READ_ONLY);
    }
  }, [value]);

  if (viewState == ViewState.READ_ONLY) {
    return (
      <ReadOnlyView
        milestoneNumber={index}
        count={count}
        volume={volume}
        rewardPoints={rewardPoints}
        canShowCountInput={showCountInput()}
        canShowVolumeInput={showVolumeInput()}
        onEditClick={function (): void {
          updateViewState(ViewState.INPUT);
        }}
        onDelete={onDelete}
      />
    );
  }

  return (
    <VegaCardV2>
      <Stack
        direction={'row'}
        alignItems="end"
        justifyContent={'space-between'}
        spacing={1}
      >
        <Stack direction={'row'} spacing={3}>
          <VegaFormInputFieldV2 label="Milestone No">
            <VegaTextV2 text={`${index + 1}`} />
          </VegaFormInputFieldV2>
          <VegaConditionalWrapper
            condition={showCountInput()}
            ifTrue={
              <VegaFormInputFieldV2 label="Count">
                <VegaTextFieldV2
                  type={'number'}
                  placeholder="Enter Count"
                  value={count != null ? count : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numberValue = Number(value);
                    setCount(numberValue);
                  }}
                />
              </VegaFormInputFieldV2>
            }
          />

          <VegaConditionalWrapper
            condition={showVolumeInput()}
            ifTrue={
              <VegaFormInputFieldV2 label="Volume">
                <VegaTextFieldV2
                  type={'number'}
                  placeholder="Enter Volume"
                  value={volume != null ? volume : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numberValue = Number(value);
                    setVolume(numberValue);
                  }}
                />
              </VegaFormInputFieldV2>
            }
          />

          <VegaFormInputFieldV2 label="Reward Points">
            <VegaTextFieldV2
              type={'number'}
              placeholder="Enter Reward Points"
              value={rewardPoints != null ? rewardPoints : ''}
              onChange={(e) => {
                const value = e.target.value;
                const numberValue = Number(value);
                setRewardPoints(numberValue);
              }}
            />
          </VegaFormInputFieldV2>
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          alignItems="end"
          justifyContent={'end'}
        >
          <VegaPrimaryButton
            text="Save"
            startIcon={<Save />}
            size="small"
            disabled={isInputValid() == false}
            onClick={onSaveClick}
          />
        </Stack>
      </Stack>
    </VegaCardV2>
  );
}

export default PointBasedMilestoneInput;

type ReadOnlyViewProps = {
  milestoneNumber: number;
  count?: number;
  volume?: number;
  rewardPoints?: number;
  canShowCountInput: boolean;
  canShowVolumeInput: boolean;
  onEditClick: () => void;
  onDelete: () => void;
};
const ReadOnlyView = ({
  milestoneNumber,
  count,
  volume,
  rewardPoints,
  canShowCountInput,
  canShowVolumeInput,
  onEditClick,
  onDelete,
}: ReadOnlyViewProps) => {
  return (
    <VegaCardV2>
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        spacing={1}
      >
        <Stack direction={'row'} spacing={3}>
          <VegaFormInputFieldV2 label="Milestone No">
            <VegaTextV2 text={`${milestoneNumber + 1}`} />
          </VegaFormInputFieldV2>
          <VegaConditionalWrapper
            condition={canShowCountInput}
            ifTrue={
              <VegaFormInputFieldV2 label="Count">
                <VegaTextV2 text={`${count ?? '-'}`} />
              </VegaFormInputFieldV2>
            }
          />

          <VegaConditionalWrapper
            condition={canShowVolumeInput}
            ifTrue={
              <VegaFormInputFieldV2 label="Volume">
                <VegaTextV2 text={`${volume ?? '-'}`} />
              </VegaFormInputFieldV2>
            }
          />

          <VegaFormInputFieldV2 label="Reward Points">
            <VegaTextV2 text={`${rewardPoints ?? '-'}`} />
          </VegaFormInputFieldV2>
        </Stack>
        <Stack direction={'row'} spacing={2} alignItems="end">
          <IconButton color="error" onClick={onDelete}>
            <DeleteOutline
              fontSize="small"
              style={{
                strokeWidth: '8px',
              }}
            />
          </IconButton>
          <VegaPrimaryButton
            size="small"
            text="Edit"
            startIcon={<EditOutlined />}
            variant="text"
            sx={{ color: 'rgba(255, 123, 1, 1)' }}
            onClick={onEditClick}
          />
        </Stack>
      </Stack>
    </VegaCardV2>
  );
};
