import { GridColumns } from '@mui/x-data-grid';
import React from 'react';
import Vega_Datagrid from '../../../../../common/v3/Vega_Datagrid';
import { IDelinquencyHistoryStateProps } from '../Delinquency';
interface IProps {
  delinquencyHistoryState: IDelinquencyHistoryStateProps;
}

const ListOfDPDStatus = ({ delinquencyHistoryState }: IProps) => {
  const delinquencyHistoryData = [
    {
      maxLife: delinquencyHistoryState.data?.maxDpd,
      noOfTime: '-',
      lastSixMonth: delinquencyHistoryState.data?.maxDpd6Months,
      lastThreeMonth: delinquencyHistoryState.data?.maxDpd3Months,

      id: 1,
    },
  ];
  const COLUMN_DEF: GridColumns = [
    {
      field: 'maxLife',
      headerName: 'Max Lifetime DPD',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },

    {
      field: 'noOfTime',
      headerName: 'No. of time in DPD TD',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'lastSixMonth',
      headerName: 'Last 6 months max DPD',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'lastThreeMonth',
      headerName: 'Last 3 months max DPD',
      flex: 1,
      minWidth: 100,
    },
  ];
  return (
    <>
      <Vega_Datagrid
        data={delinquencyHistoryData}
        idColumn="id"
        columns={COLUMN_DEF}
      />
    </>
  );
};

export default ListOfDPDStatus;
