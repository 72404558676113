import { Box, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { PRIMARY } from '../../../constants/style';

type IProps = {
  transactionDates: {
    start: Dayjs | null;
    end: Dayjs | null;
  };
  setTransactionDates: React.Dispatch<
    React.SetStateAction<{
      start: Dayjs | null;
      end: Dayjs | null;
    }>
  >;
};

function TransactionDate({ transactionDates, setTransactionDates }: IProps) {
  //   const [start, setStart] = useState<Dayjs | null>(null);
  //   const [end, setEnd] = useState<Dayjs | null>(null);

  const changeHandler = (key: string, value: Dayjs | null) => {
    setTransactionDates({
      ...transactionDates,
      [key]: value,
    });
  };
  return (
    <Stack direction={'row'} spacing={1}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box>Transaction Date</Box>
          <DatePicker
            label="Start End"
            value={transactionDates.start}
            onChange={(newValue) => {
              changeHandler('start', newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <Typography>To</Typography>
          <DatePicker
            label="End Date"
            value={transactionDates.end}
            onChange={(newValue) => {
              changeHandler('end', newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    </Stack>
  );
}

export default TransactionDate;
