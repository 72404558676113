import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, BreadcrumbsProps, styled } from '@mui/material';

interface VegaBreadcrumbs extends BreadcrumbsProps {}

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => {
  return {};
});

function VegaBreadcrumbs({ separator, ...rest }: VegaBreadcrumbs) {
  return (
    <StyledBreadcrumbs
      separator={separator ?? <NavigateNext fontSize="small" color="action" />}
      {...rest}
    />
  );
}

export default VegaBreadcrumbs;
