/** @format */

import { AdminPanelSettings, FolderShared } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import CardAnalytics from '../mcc/corporate/pages/CardAnalytics';
import CorporateAplicationManagement from '../mcc/corporate/pages/CorporateApplicationMangement';
import CorporateCardTransaction from '../mcc/corporate/pages/CorporateCardTransaction';
import CorporateInvoices from '../mcc/corporate/pages/CorporateInvoices';
import Program from '../pages/Program';
import { MCC_ROUTES, ROUTES } from '../routes';
import CorporateCardSale from '../mcc/corporate/pages/CorporateCardSale';
import { Program_manage } from '../components/icon/Icons';
import MCCAnalytics from '../mcc/Back-office/pages/MCCAnalytics';
import MCCReport from '../mcc/Back-office/pages/MCCReport';
import { useClientAuth } from '../providers/ClientProvider';
import CorporateAnalytics from '../mcc/corporate/pages/CorporateAnalytics';

type Props = {};

const NAV_OPTION = {
  id: ROUTES.PROGRAM,
  icon: <FolderShared />,
  label: 'Program Summary',
  component: <Program />,
  isProgramSelector: false,
  isAccountSelector: false,
  isProgramRoutes: true,
};

const CorporateContainer = (props: Props) => {
  const { mccPlatformId } = useClientAuth();

  const NAV_OPTIONS: SidebarListItemType[] = [
    {
      id: MCC_ROUTES.CORPORATE_APPLICATION_MANAGEMENT,
      icon: <AdminPanelSettings />,
      label: 'Application Management',
      component: <CorporateAplicationManagement />,
    },
    {
      id: MCC_ROUTES.ANALYTICS,
      icon: <Program_manage />,
      label: 'Analytics',
      component: <CorporateAnalytics />,
    },
    {
      id: MCC_ROUTES.REPORT,
      icon: <Program_manage />,
      label: 'Report',
      component: <MCCReport authId={mccPlatformId} platform='CORPORATE' />,
    },
    {
      id: MCC_ROUTES.CORPORATE_CARD_SALE,
      icon: <AdminPanelSettings />,
      label: 'Card Sale',
      component: <CorporateCardSale />,
    },
    {
      id: MCC_ROUTES.CORPORATE_CARD_TRANSACTION,
      icon: <AdminPanelSettings />,
      label: 'Card Transaction',
      component: <CorporateCardTransaction />,
    },
    {
      id: MCC_ROUTES.CORPORATE_CARD_ANALYTICS,
      icon: <AdminPanelSettings />,
      label: 'Card Analytics',
      component: <CardAnalytics />,
    },
    {
      id: MCC_ROUTES.CORPORATE_INVOICES,
      icon: <AdminPanelSettings />,
      label: 'Invoices',
      component: <CorporateInvoices />,
    },
  ];
  const [selectedItem, setSelectedItem] =
    useState<SidebarListItemType>(NAV_OPTION);

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        sidebarListItem={NAV_OPTION}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default CorporateContainer;
