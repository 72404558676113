import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaModal from '../../../../components/common/VegaModal';
import { VegaToolTipAndCopy } from '../../../../components/common/VegaToolTipNCopy';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { addPlanAllocation } from '../../../Program-Dashboard/api/PlanAllocation';
import { getProgramDocsSignedUrl } from '../../../Program-Dashboard/api/Program';
import {
  AccountLimitType,
  ICorporateAccountWalletDataProps,
  ICorporateEntity,
} from '../../../types/CorporateCustomer';
import { ICustomerType } from '../../../types/RetailCustomer';
import { IPlanAllocationProps } from '../../../types/plan';
import { createCorporateAccount } from '../../api/corporateEntity';
import ViewCorporateCustomer from './ViewCorporateCustomer';
import AccountAdd from './components/AccountAdd';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../../utils/FileValidator';

export interface ICustomerDetailsProps {
  id: string;
  programName: string;
  firstName: string;
  mobileNumber: string | number;
  emailId: string;
  passportFile: any;
  panFile: any;
  kycFile: any;
  declaration: any;
}

export interface IAccountDataProps {
  id: string;
  accountType: string;
  markupFeePlanId: string;
  openCreditChargePlanId: string;
  walltetCofigurationPlanId: string;
  billingCycle: string;
  accountLimit: number;
}

const INITIAL_DATA = {
  id: '',
  programName: '',
  firstName: '',
  mobileNumber: '',
  emailId: '',
  passportFile: '',
  panFile: '',
  kycFile: '',
  declaration: '',
};
const INITIAL_ACCOUNT_DATA = {
  id: '',
  accountType: 'PREPAID',
  billingCycle: '',
  markupFeePlanId: '',
  openCreditChargePlanId: '',
  walltetCofigurationPlanId: '',
  accountLimit: 0,
} as IAccountDataProps;

interface IProps {
  data: ICorporateAccountWalletDataProps[];
  loading: boolean;
  getCorporateEntitiesList: () => void;
}

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([
    FileExtension.PNG,
    FileExtension.JPEG,
    FileExtension.JPG,
    FileExtension.SVG,
    FileExtension.PDF,
  ])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const ListOfMCCCorporateCustomer = ({
  data,
  loading,
  getCorporateEntitiesList,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  const { mccPlatformId: branchId } = useClientAuth();
  const [customerDetails, setCustomerDetails] = useState<ICustomerDetailsProps>(
    { ...INITIAL_DATA }
  );
  const [addAccount, setaddAccount] = useState<IAccountDataProps>({
    ...INITIAL_ACCOUNT_DATA,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [openAccount, setOpenAccount] = useState<boolean>(false);

  const columnDef: GridColDef[] = [
    {
      field: 'id',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          CorporateId
        </Typography>
      ),
      flex: 1.5,
      renderCell: (props) => {
        return <VegaToolTipAndCopy value={props.row.id} />;
      },
    },
    {
      field: 'corporateName',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Corporate Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const corporateData: ICorporateAccountWalletDataProps = props.row;
        return (
          <Typography>{corporateData.corporate?.corporateName}</Typography>
        );
      },
    },
    // {
    //   field: 'emailId',
    //   renderHeader: () => <Typography className="font-aspekta-500" variant="body2">Email ID</Typography>,
    //   flex: 1.1,
    // },
    {
      field: 'cardNetwork',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Network
        </Typography>
      ),
      flex: 0.6,
      renderCell: (props: any) => {
        const corporateData: ICorporateAccountWalletDataProps = props.row;
        return (
          <Box>{getLogoForNetwork(corporateData.corporate?.cardNetwork)}</Box>
        );
      },
    },
    {
      field: 'accountLimitType',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Account Type
        </Typography>
      ),
      flex: 0.8,
      renderCell: (props: any) => {
        const corporateData: ICorporateAccountWalletDataProps = props.row;
        return (
          <Typography>
            {corporateData.account
              ? toLowerCase(corporateData.account.accountType ?? '')
              : '--'}
          </Typography>
        );
      },
    },
    {
      field: 'accountLimit',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Account Limit
        </Typography>
      ),
      flex: 0.8,
      renderCell: (props: any) => {
        const corporateData: ICorporateAccountWalletDataProps = props.row;
        return (
          <Typography>
            {corporateData.account ? corporateData.account.accountLimit : '--'}
          </Typography>
        );
      },
    },
    {
      field: 'billingCycle',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Billing Cycle
        </Typography>
      ),
      flex: 0.8,
      renderCell: (props: any) => {
        const corporateData: ICorporateAccountWalletDataProps = props.row;
        return (
          <Typography>
            {corporateData.account ? corporateData.account.billingCycle : '--'}
          </Typography>
        );
      },
    },
    {
      field: 'gstNumber',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Gst No
        </Typography>
      ),
      flex: 0.8,
      renderCell: (props) => {
        const corporateData: ICorporateAccountWalletDataProps = props.row;
        return (
          <VegaToolTipAndCopy
            value={corporateData.corporate?.gstNumber ?? ''}
            title="Gst Number"
          />
        );
      },
    },

    {
      field: 'add',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Add Account{' '}
        </Typography>
      ),
      renderCell: (props: any) => {
        const corporateData: ICorporateAccountWalletDataProps = props.row;
        console.log('corporateData1: ', corporateData);
        if (corporateData.account === null) {
          return (
            <VegaContainedButton
              text="Add Account"
              px={0.5}
              onClick={() => openAddAccountAndAllocate(props.row)}
              isPrimary
            />
          );
        } else {
          return;
        }
      },
      flex: 1,
    },

    {
      field: 'action',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        const corporateData: ICorporateAccountWalletDataProps = props.row;
        return (
          <VegaContainedButton
            text="View/edit"
            onClick={() => showDetails(corporateData.corporate)}
            isPrimary
          />
        );
      },
      flex: 0.7,
    },
  ];

  const handleChange = (name: string, value: any) => {
    // if (name === 'balanceSheetFileUrl' || name === 'gstFileUrl') {
    //   let file = value[0];
    //   let fileURL = URL.createObjectURL(file);
    //   file.fileURL = fileURL;

    //   setCustomerDetails({
    //     ...customerDetails,
    //     [name]: fileURL,
    //   });
    // } else {
    //   setCustomerDetails({
    //     ...customerDetails,
    //     [name]: value,
    //   });
    // }
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };

  const handleFileChange = (name: string, file: File | null) => {
    var fileUrl = '';
    if (file) {
      fileUrl = URL.createObjectURL(file);
    }
    setCustomerDetails({
      ...customerDetails,
      [name]: fileUrl,
    });
  };

  const handleErrorOnFileChange = (
    type: string,
    errorMessage: string | null
  ) => {
    setSnackbar(errorMessage ?? 'Something went wrong');
    handleFileChange(type, null);
  };

  const handleAccountDataChange = (
    name: keyof IAccountDataProps,
    value: any
  ) => {
    setaddAccount({ ...addAccount, [name]: value });
  };

  const showAccount = () => {
    setaddAccount({ ...INITIAL_ACCOUNT_DATA });
    setOpenAccount(!openAccount);
  };

  const openAddAccountAndAllocate = async (data: ICorporateEntity) => {
    setaddAccount({ ...addAccount, id: data.id });
    setOpenAccount(!openAccount);
  };

  const allocatedPlan = async (plan: IPlanAllocationProps) => {
    addPlanAllocation(plan)
      .then((res) => {
        setSnackbar('Account Allocated !');
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to account allocated'),
          'error'
        );
      });
  };

  const createAccountForCorporate = async () => {
    const parsedBillingCycle = parseFloat(
      addAccount.billingCycle.split(' ')[0]
    );
    const corporateAccountObj = {
      corporateId: addAccount.id,
      branchId,
      accountType: addAccount.accountType,
    };
    if (addAccount.accountType === AccountLimitType.POSTPAID) {
      Object.assign(corporateAccountObj, {
        accountLimit: addAccount.accountLimit,
        billingCycle: parsedBillingCycle,
      });
    }
    createCorporateAccount(corporateAccountObj)
      .then((res) => {
        setSnackbar('Created account for corporate!');
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to create account'),
          'error'
        );
      });
  };

  const onAddAccount = async () => {
    const markupPlanObj = {
      planId: addAccount.markupFeePlanId,
      entityType: ICustomerType.CORPORATE,
      entityId: addAccount.id,
    };
    const walletConfigPlanObj = {
      planId: addAccount.walltetCofigurationPlanId,
      entityType: ICustomerType.CORPORATE,
      entityId: addAccount.id,
    };
    const openCreditChargePlanObj = {
      planId: addAccount.openCreditChargePlanId,
      entityType: ICustomerType.CORPORATE,
      entityId: addAccount.id,
    };
    if (addAccount.accountType === AccountLimitType.PREPAID) {
      await allocatedPlan(markupPlanObj);
      await allocatedPlan(walletConfigPlanObj);
      createAccountForCorporate();
      getCorporateEntitiesList();
    } else {
      await allocatedPlan(openCreditChargePlanObj);
      createAccountForCorporate();
      getCorporateEntitiesList();
    }
    showAccount();
  };

  const showDetails = async (data: any) => {
    if (data.gstFileUrl) {
      data.gstFileUrl = (await getProgramDocsSignedUrl(data.gstFileUrl)).data;
    }
    if (data.balanceSheetFileUrl) {
      data.balanceSheetFileUrl = (
        await getProgramDocsSignedUrl(data.balanceSheetFileUrl)
      ).data;
    }
    setCustomerDetails(data);
    setOpen(!open);
  };
  const decline = () => {
    setOpen(!open);
  };
  const approve = () => {
    setOpen(!open);
  };

  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={columnDef}
        idColumn={'id'}
        loading={loading}
      />
      <VegaDrawer
        open={open}
        header={'CorporateDetails Details'}
        closeText="Decline"
        isClose
        isSubmit
        submitText="Save"
        onClose={decline}
        onSubmit={approve}
      >
        <ViewCorporateCustomer
          customer={customerDetails}
          handleChange={handleChange}
          handleFileChange={handleFileChange}
          onError={(type, message) => {
            handleErrorOnFileChange(type, message);
          }}
          fileValidator={fileValidatorConfig}
        />
      </VegaDrawer>
      <VegaModal
        open={openAccount}
        header={'Add Account'}
        handleClose={showAccount}
        handleSubmit={onAddAccount}
        submitText={'Add Account'}
      >
        <AccountAdd
          handleChange={handleAccountDataChange}
          accountState={addAccount}
        />
      </VegaModal>
    </VegaCard>
  );
};

export default ListOfMCCCorporateCustomer;
