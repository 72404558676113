import { Typography } from '@mui/material';
import { GREY } from '../../../constants/style';
import { AcceptFileType } from '../../../types/acceptFileType';
import { VegaDocument } from '../../../types/program';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../utils/FileValidator';
import { VegaCard } from '../../common';
import VegaDropZone from '../../common/VegaDropZone/VegaDropZone';

interface AgreementProps {
  showLoader: boolean;
  showUploadButtonForFileCard: boolean;
  showDownloadFormatButton: boolean;
  agreementFiles: VegaDocument[] | null;
  onFilesSelected: (file: File[] | null) => void;
  onCancelFileButtonClick: () => void;
  onUploadFileButtonClick: () => void;
  onDownloadFormatButtonClick: () => void;
  onDownloadFileButtonClick: () => void;
  onFileSelectedError: (message: string | null) => void;
}

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.PDF])
  .withMultipleExtensionsCheck()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const Agreement = (props: AgreementProps) => {
  return (
    <>
      <VegaCard>
        <Typography
          variant="h6"
          sx={{ fontWeight: '500', color: GREY.darkest }}
        >
          Upload Document
        </Typography>
        <VegaDropZone
          documents={props.agreementFiles}
          acceptFileType={AcceptFileType.PDF}
          onFileSelected={props.onFilesSelected}
          showLoaderForFileCard={props.showLoader}
          showUploadButtonForFileCard={props.showUploadButtonForFileCard}
          onCancelFileButtonClick={props.onCancelFileButtonClick}
          onUploadFileButtonClick={props.onUploadFileButtonClick}
          onDownloadFileButtonClick={props.onDownloadFileButtonClick}
          fileValidator={fileValidatorConfig}
          onFileSelectedError={props.onFileSelectedError}
        />
      </VegaCard>
    </>
  );
};

export default Agreement;
