/** @format */

import axios from 'axios';
import { EntityAuthStatus } from '../types/entityAuth';
import { AnyPolicyType, PolicyType } from '../types/policy';
import { BASE_URL, POLICY } from './endpoints';

const POLICY_BASE_URL = BASE_URL + POLICY.BASE;
const POLICY_PDD_BASE_URL = BASE_URL + POLICY.PDD;
const POLICY_MAD_BASE_URL = BASE_URL + POLICY.MAD;

export const addOrUpdatePolicy = (programId: string, policy: any) => {
  return axios.post(POLICY_BASE_URL.replace('{programId}', programId), policy);
};

export const fetchPolicy = (programId: string, policyType: any) => {
  return axios.get(
    POLICY_BASE_URL.replace('{programId}', programId) + `/${policyType}`
  );
};

export const fetchAllPolicesForProgram = (programId: string) => {
  return axios.get(
    POLICY_BASE_URL.replace('{programId}', programId) + POLICY.ALL_FOR_PROGRAM
  );
};
export const fetchOnePolicyProgram = (
  programId: string,
  policyType: string
) => {
  return axios.get(
    POLICY_BASE_URL.replace('{programId}', programId) +
      POLICY.POLICY_TYPE.replace('{policyType}', policyType)
  );
};
export const fetchAllPDDList = (programId: string) => {
  return axios.get(POLICY_PDD_BASE_URL.replace('{programId}', programId));
};
export const fetchAllMADcategoryList = () => {
  return axios.get(`${POLICY_MAD_BASE_URL}/mad/listCategories`);
};

export async function fetchMultiplePoliciesOfType(data: {
  programId: string;
  type: string;
}): Promise<AnyPolicyType[]> {
  const endpoint =
    POLICY_BASE_URL.replace('{programId}', data.programId) +
    POLICY.MULIPLE_POLICIES;
  try {
    const response = await axios.get(endpoint, {
      params: { policyTypes: data.type },
    });
    if (response.data) {
      const allPolicies = response.data['all_policies'];
      const multiplePolicies = allPolicies as AnyPolicyType[];
      return multiplePolicies;
    }
    return [];
  } catch (error) {
    throw error;
  }
}
export async function submitAllPoliciesToChecker(data: {
  programId: string;
  userId: string;
}): Promise<EntityAuthStatus> {
  const endpoint = POLICY.SUBMIT_TO_CHECKER;

  try {
    const response = await axios.post(endpoint, null, {
      params: { programId: data.programId, userIdOfMaker: data.userId },
    });
    return response.data as EntityAuthStatus;
  } catch (error) {
    throw error;
  }
}

export function disablePolicy(data: { policyId: string }) {
  const endpoint = POLICY.DISABLE_POLICY.replace('{policyId}', data.policyId);
  return axios.patch(endpoint);
}

export async function getDefaultPolicy(data: {
  policyType: PolicyType;
}): Promise<AnyPolicyType> {
  try {
    const endpoint = POLICY.DEFAULT_POLICY.replace(
      '{policyType}',
      data.policyType
    );
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
