import React from 'react';
import { Box } from '@mui/material';
import VegaSelect from '../../common/VegaSelect';
import { VegaTextField } from '../../common';

const OrderPrintedCard = () => {
  return (
    <>
      <Box>
        <VegaSelect
          headerText="select printer"
          isFixedHeight
          key={''}
          options={['XYZ']}
          selected={'XYZ'}
          onChange={() => {}}
        />
      </Box>
      <Box mt={2}>
        <VegaTextField
          type="number"
          headerText="No. of card to be printed"
          isFixedHeight
          key={''}
          onChange={() => {}}
        />
      </Box>
    </>
  );
};

export default OrderPrintedCard;
