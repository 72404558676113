import { Box, Grid, Typography } from '@mui/material';
import { NETWORKS } from '../../../../components/ClientHome/AddProgram';
import {
  VegaCard,
  VegaFileUpload,
  VegaTextField,
} from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import VegaSelect from '../../../../components/common/VegaSelect';
import VegaText from '../../../../components/common/VegaText';
import { BORDER_GRAY, GREY } from '../../../../constants/style';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  FileExtension,
  FileValidatorBuilder,
} from '../../../../utils/FileValidator';
import { IProgramProps } from '../../pages/MCCProgram';

interface ICreateProgramProps {
  state: IProgramProps;
  handleChange: (name: string, value: any) => void;
  onClear: () => void;
  onSubmit: () => void;
  loading: boolean;
}

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.PDF])
  .withMultipleExtensionsCheck()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const CreateProgram = ({
  state,
  loading,
  handleChange,
  onClear,
  onSubmit,
}: ICreateProgramProps) => {
  const { setSnackbar } = useSnackbar();

  function handleErrorOnFileSelect(type: string, errorMessage: string | null) {
    setSnackbar(errorMessage ?? 'Something went wrong', 'error');
    handleChange(type, null);
  }

  return (
    <VegaCard noPad>
      <Box sx={{ p: 3, borderBottom: BORDER_GRAY }}>
        <Box mb={2}>
          <VegaText fw={500} text={'Create Program'} />
          <Typography
            variant="body2"
            sx={{ fontWeight: '500', color: GREY.lighter }}
          >
            Create a program type here
          </Typography>
        </Box>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.name}
              onChange={(e) => handleChange('name', e.target.value)}
              key="name"
              type={'text'}
              placeholder="Program Name"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              placeholder="Program Type"
              label="Program Type"
              size="small"
              key={'type'}
              selected={state.programCustomerType}
              onChange={(value) => handleChange('programCustomerType', value)}
              options={['CORPORATE', 'RETAIL']}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              label="Select Network"
              size="small"
              key={'network'}
              selected={state.network ?? ''}
              onChange={(value) => handleChange('network', value)}
              options={NETWORKS}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} sm={6}>
            <VegaFileUpload
              placeholder={'tnc'}
              file={state.tnc}
              onUpload={(file: any) => handleChange('tnc', file)}
              onError={(message) => handleErrorOnFileSelect('tnc', message)}
              fileValidator={fileValidatorConfig}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaFileUpload
              placeholder={'Agreement'}
              file={state.agreement}
              onUpload={(file: any) => handleChange('agreement', file)}
              onError={(message) =>
                handleErrorOnFileSelect('agreement', message)
              }
              fileValidator={fileValidatorConfig}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent={'end'} my={1} pb={3} px={3}>
        <Grid item>
          <VegaOutlineButton text="Clear" isDanger onClick={onClear} />
        </Grid>
        <Grid item>
          <VegaContainedButton
            text="Create"
            isPrimary
            mx="0"
            onClick={onSubmit}
            loading={loading}
          />
        </Grid>
      </Grid>
    </VegaCard>
  );
};

export default CreateProgram;
