/* eslint-disable react/jsx-pascal-case */
import { Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { listInstallmentsForAccount } from '../../../../../api/installment';
import { NEW_PRIMARY } from '../../../../../constants/style';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useSupport } from '../../../../../providers/SupportProvider';
import { LoanDto } from '../../../../../types/transaction';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { DATE_TIME_FORMATS } from '../../../../../utils/dateTime';
import Vega_DataGridFilterSection from '../../../../common/v3/Vega_DataGridFilterSection';
import Vega_Datagrid from '../../../../common/v3/Vega_Datagrid';
import Vega_Text from '../../../../common/v3/Vega_Text';

const LoanEmi = () => {
  const { accountData } = useSupport();
  const { setSnackbar } = useSnackbar();

  const listInstallMentsQuery = useQuery({
    queryKey: ['list_installments', accountData.accountId],
    queryFn: () => {
      return listInstallmentsForAccount({
        accountId: accountData?.accountId || '',
      });
    },
    enabled: !!accountData?.accountId,
    keepPreviousData: true,
    onError: (error) => {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    },
  });
  return (
    <>
      <Vega_Datagrid
        data={listInstallMentsQuery.data || []}
        idColumn="id"
        columns={COLUMN_DEF}
        loading={
          listInstallMentsQuery.isLoading ||
          listInstallMentsQuery.isPreviousData
        }
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Vega_Text
                  text={'LoanEmi'}
                  fontWeight={600}
                  color={NEW_PRIMARY.BLACK}
                  fontSize={17}
                />
                <Vega_DataGridFilterSection />
              </Stack>
            );
          },
        }}
      />
    </>
  );
};

export default LoanEmi;

const COLUMN_DEF: GridColumns<LoanDto> = [
  {
    field: 'loan_amount',
    headerName: 'Amount',
    flex: 0.6,
    minWidth: 60,
    valueGetter: (props) => {
      return props.row.loan_amount;
    },
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'interest_rate',
    headerName: 'Interest Rate',
    flex: 0.6,
    minWidth: 60,
    valueGetter: (props) => {
      return props.row.interest_rate;
    },
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'interest_type',
    headerName: 'Interest Type',
    flex: 1.2,
    minWidth: 120,
    valueGetter: (props) => {
      return props.row.interest_type;
    },
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'installment_count',
    headerName: 'Installment Count',
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      return props.row.installment_count;
    },
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'loan_start_date',
    headerName: 'Loan Start Date',
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      return dayjs(props.row.loan_start_date).format(
        DATE_TIME_FORMATS.DATE_TIME
      );
    },
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'amortization_type',
    headerName: 'Armortization Type',
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      return props.row.amortization_type;
    },
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'due_principal',
    headerName: 'Due Principal',
    flex: 0.6,
    minWidth: 60,
    valueGetter: (props) => {
      return props.row.due_principal;
    },
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
];
