import { useQuery } from 'react-query';
import { getAllReportsForProgram } from '../../../api/reports';
import { IReport } from '../../../types/reports';

const getReports = async (programId: string | undefined) => {
  if (!programId) return null;
  const { data } = await getAllReportsForProgram(programId);
  console.log('allReports ', data);
  const hash = await (data as IReport[]).reduce((pre, curr) => {
    return {
      ...pre,
      [curr.reportId]: curr,
    };
  }, {} as Record<string, IReport>);

  return hash;
};

export default function useAllReports(programId: string | undefined) {
  return useQuery(['reports', programId], () => getReports(programId));
}
