import { CheckerMakerStatusEnum } from '../enums/commons';
import { ApiScope, LevelHierarchy } from './client';
import { DivisionDto } from './division';
import { EntityDto } from './entity';
import { ResourceWithAccess, Role, RoleProgramEntityType } from './userAccess';

export interface VegaUser {
  userId: string;
  clientId: string;
  name: string;
  mobileNumber: string;
  email: string;
  address: string;
  officialEmailId: string;
  idType: string;
  idNumber: string;
  isAdmin: boolean;
  designation: string;
  status: VegaUserStatus;
  userRoles: UserRoleDto[];
  partnerId: string;
  branchId: string;
  platformId: string;
  platformName: UserPlatform;
  mccUser: boolean;
  team: TeamType;
  createdAt: Date;
  updatedAt: Date;
  corporateId?: string | null;
  apiScope: ApiScope;
  entityDto: EntityDto; // deprecated
  completeRoleProgramEntityDtos: RoleProgramEntityCompleteDtoType[] | null;
  allResourceAccess?: UserAllResourceAccess[] | null;
}

export type UserAllResourceAccess = {
  programId: string;
  listOfResourceWithAccess?: ResourceWithAccess[];
};

export type RoleProgramEntityCompleteDtoType = {
  programId: string;
  roleDto: Role;
  entityDto?: EntityDto | null;
  divisionDto?: DivisionDto | null;
};

export enum UserPlatform {
  FOREX_HQ = 'FOREX_HQ',
  FOREX_BRANCH = 'FOREX_BRANCH',
  FOREX_CORPORATE = 'FOREX_CORPORATE',
}

export enum VegaUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum VegaUserRoleStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface UserRoleDto {
  id: string;
  userId: string;
  roleId: string;
  roleName: string;
  status: VegaUserRoleStatus;
  createdAt: Date;
  updatedAt: Date;
  checkerMakerId: string;
  checkerMakerFlag: boolean;
  checkerMakerStatus: CheckerMakerStatusEnum;
}

export enum TeamType {
  OPERATIONS = 'OPERATIONS',
  COLLECTIONS = 'COLLECTIONS',
  FINANCE = 'FINANCE',
  SETTLEMENT = 'SETTLEMENT',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  DESIGN = 'DESIGN',
  DEVELOPER = 'DEVELOPER',
  CXOS = 'CXOS',
  MARKETING_AND_BRANDING = 'MARKETING_AND_BRANDING',
  SUPPORT = 'SUPPORT',
  BUSINESS_INTELLIGENCE = 'BUSINESS_INTELLIGENCE',
  BUSINESS = 'BUSINESS',
  RISK = 'RISK',
  AGENCY_COLLECTION = 'AGENCY_COLLECTION',
  AGENCY_SUPPORT = 'AGENCY_SUPPORT',
  AGENCY_OPERATIONS = 'AGENCY_OPERATIONS',
}

export interface VegaUserRole {
  id: string;
  userId: string;
  roleId: string;
  roleName: string;
  status: VegaUserRoleStatus;
  createdAt: Date;
  updatedAt: Date;
}

export type AddUserWithRolesPayloadType = {
  userDto: Partial<VegaUser>;
  listOfRoles?: RoleProgramEntityType[];
  levelEntityDto?: Partial<LevelHierarchy>;
  enableCheckerMaker?: boolean;
  overrideExistingRoles?: boolean;
};
