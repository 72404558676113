import { Box, Grid, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { toLowerCase } from '../../../../constants/commonFunction';
import { BLUE, GREY } from '../../../../constants/style';

import { KeyboardArrowDown } from '@mui/icons-material';
import { NoResults, VegaTextField } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaLabel from '../../../../components/common/VegaLabel';
import VegaModal from '../../../../components/common/VegaModal';
import VegaSelect from '../../../../components/common/VegaSelect';
import { FIleIcon } from '../../../../components/icon/Icons';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import {
  IOpenCreditListProps,
  IRePaymentProps,
  InvoiceListPostPaidCardProps,
} from '../../../types/CorporateTransaction';
import { dateFormat } from '../../../variable';
import { fetchOpenCreditList } from '../../api/open-credit';
import ListOfOpenCredit from './ListOfOpenCredit';

interface ICorporateColumnProps {
  key: keyof InvoiceListPostPaidCardProps | string | number;
  header: string;
  flex: number;
}

const COLUMN: ICorporateColumnProps[] = [
  {
    key: 'createdAt',
    header: 'Invoice Date',
    flex: 1,
  },
  {
    key: 'dueAmount',
    header: 'Amount',
    flex: 1,
  },
  {
    key: 'updatedAt',
    header: 'Due Date',
    flex: 1,
  },
  // {
  //   key: 'overdueDate',
  //   header: 'Overdue Date',
  //   flex: 1,
  // },
  {
    key: 'dueCharge',
    header: 'Charges',
    flex: 0.7,
  },
  {
    key: 'creditStatus',
    header: 'Payment Status',
    flex: 1,
  },
  {
    key: 'invoiceType',
    header: 'Invoice Type',
    flex: 1,
  },
  {
    key: 'billingCycle',
    header: 'Billing Cycle',
    flex: 1,
  },
  {
    key: 'invoice',
    header: 'Invoice',
    flex: 1,
  },
  {
    key: 'action',
    header: 'Action',
    flex: 1,
  },
];

interface IProps {
  invoiceList: InvoiceListPostPaidCardProps[];
  onRePayment: (rePayment: IRePaymentProps) => void;
}

const ListOfCorporateTransaction = ({ invoiceList, onRePayment }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [IDs, setIDs] = useState<number>(-1);
  const [open, setOpen] = useState<boolean>(false);
  const [openCreditList, setOpenCreditList] = useState<IOpenCreditListProps[]>(
    []
  );

  const [rePayment, setRePayment] = useState<IRePaymentProps>({
    invoiceId: '',
    currency: 'USD',
    amount: null,
    repaymentType: 'PARTIAL',
  });

  const handleChange = (
    name: keyof IRePaymentProps,
    value: string | number
  ) => {
    setRePayment({
      ...rePayment,
      [name]: value,
    });
  };

  const onSelect = async (
    index: number,
    invoice: InvoiceListPostPaidCardProps
  ) => {
    if (index === IDs) {
      setIDs(-1);
      return;
    }
    setRePayment({
      ...rePayment,
      invoiceId: invoice.id,
    });
    setIDs(index);
  };
  const onShow = () => {
    setOpen(!open);
  };
  const onSubmit = () => {
    onRePayment(rePayment);
    onShow();
  };
  const onEdit = (invoice: InvoiceListPostPaidCardProps) => {
    setRePayment({
      ...rePayment,
      invoiceId: invoice.id,
      amount: invoice.amount,
    });
    onShow();
  };

  const getOpenCreditList = () => {
    fetchOpenCreditList({ invoiceId: rePayment.invoiceId })
      .then((res) => {
        setOpenCreditList(res.data);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Something Went wrong !'),
          'error'
        );
      });
  };

  useEffect(() => {
    IDs !== -1 && getOpenCreditList();
  }, [IDs]);

  return (
    <>
      <Box sx={{ pb: 2, bgcolor: GREY.light, borderRadius: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            bgcolor: GREY.light,
            px: 1,
          }}
        >
          {COLUMN.map((column: ICorporateColumnProps) => (
            <Box
              sx={{
                flex: column.flex,
                py: 2,
                px: 1,
                fontWeight: 600,
                fontSize: 14,
                color: BLUE.dark,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {toLowerCase(column.header)}
            </Box>
          ))}
          <Box sx={{ py: 2, px: 1, flex: 0.3 }}></Box>
        </Box>
        {invoiceList.length ? (
          invoiceList.map(
            (value: InvoiceListPostPaidCardProps, index: number) => {
              return (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderTop: `2px solid ${GREY.grey}`,
                      transition: 'all 500ms ease-in-out',

                      ...(IDs === index
                        ? { height: 'auto', bgcolor: BLUE.lightest }
                        : { height: '50px', bgcolor: 'white' }),
                    }}
                  >
                    {COLUMN.map((column: ICorporateColumnProps) => {
                      // const kycURL = getURL(value.kycUrl);

                      return (
                        <>
                          <Box
                            sx={{
                              flex: column.flex,
                              py: 2,
                              px: 2,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              fontSize: 14,
                            }}
                          >
                            {column.key === 'createdAt' ||
                            column.key === 'updatedAt' ? (
                              <VegaLabel
                                text={dateFormat(value[column.key])}
                                color="primary"
                              />
                            ) : column.key === 'creditStatus' ? (
                              <VegaLabel
                                text={toLowerCase(value[column.key])}
                                color="primary"
                              />
                            ) : (
                              value[column.key]
                            )}
                            {column.key === 'invoice' && (
                              <VegaContainedButton
                                text={'Download'}
                                isPrimary
                                px={1}
                              />
                            )}
                            {column.key === 'action' &&
                              (value.creditStatus === 'PAID' ? (
                                ''
                              ) : (
                                <VegaContainedButton
                                  text={'Repayment'}
                                  isPrimary
                                  px={1}
                                  onClick={() => onEdit(value)}
                                />
                              ))}
                          </Box>
                        </>
                      );
                    })}
                    <Box sx={{ px: 1, flex: 0.3 }}>
                      <IconButton onClick={() => onSelect(index, value)}>
                        <KeyboardArrowDown
                          sx={{
                            transition: 'all 500ms ease-in-out',
                            ...(IDs === index && { rotate: '180deg' }),
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    className={`accordion-item ${
                      IDs === index ? '' : 'collapsed'
                    }`}
                  >
                    <ListOfOpenCredit openCreditList={openCreditList} />
                  </Box>
                </>
              );
            }
          )
        ) : (
          <NoResults sx={{ borderRadius: 0 }} />
        )}
      </Box>
      <VegaModal
        header="Edit Invoice"
        open={open}
        handleClose={onShow}
        submitText="Save"
        handleSubmit={onSubmit}
        buttonIcon={<FIleIcon strokeColor="white" />}
      >
        <Box p={2}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6}>
              <VegaDatePicker value={} onChange={() => console.log('first')} />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <VegaTextField
                value={rePayment.amount ?? 0}
                onChange={(e) =>
                  handleChange(
                    'amount',
                    e.target.value && parseInt(e.target.value)
                  )
                }
                key="amount"
                label="Amount"
                type={'number'}
                placeholder="Amount"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VegaSelect
                options={['FULL', 'PARTIAL']}
                key="repaymentType"
                selected={rePayment.repaymentType}
                label="Select Role"
                onChange={(value) => handleChange('repaymentType', value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VegaTextField
                value={''}
                onChange={(e) => console.log('name', e.target.value)}
                key="name"
                label="Waiver"
                type={'number'}
                placeholder="Waiver"
                disabled
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <VegaTextField
                value={''}
                onChange={(e) => console.log('name', e.target.value)}
                key="name"
                label="Amount"
                type={'number'}
                placeholder="Amount"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VegaTextField
                value={''}
                onChange={(e) => console.log('name', e.target.value)}
                key="name"
                label="Waiver"
                type={'number'}
                placeholder="Waiver"
              />
            </Grid> */}
          </Grid>
        </Box>
      </VegaModal>
    </>
  );
};

export default ListOfCorporateTransaction;
