import LaunchIcon from '@mui/icons-material/Launch';
import { Box, IconButton, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getPresignedUrl } from '../../../../api/card';
import CIMSDataGrid from '../../../components/common/CIMS_DataGrid';
import { IReportConfigMap } from './CimsReportsTable';

import { getReportsConfig } from '../../../../api/reports';
import ReportsConfigModal from '../../../../components/Reporting/reports/ReportsConfigModal';
import { toLowerCase } from '../../../../constants/commonFunction';
import { BLUE } from '../../../../constants/style';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { IReportConfig, ReportConfigDto } from '../../../../types/reports';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { downloadFileFromURI } from '../../../../utils/file';
import { convertCamelCase } from '../../../../utils/stringUtils';
import CimsButton from '../../../components/common/CimsButton';
import CimsPastReportsForReport from './CimsPastReportsForReport';

interface IProps {
  isLoading: boolean;
  reportConfigMaps: Record<string, IReportConfigMap>;
  setReportConfigMaps: React.Dispatch<
    React.SetStateAction<Record<string, IReportConfigMap>>
  >;
  selectedReportConfigMap: IReportConfigMap | null;
  setSelectedReportConfigMap: React.Dispatch<
    React.SetStateAction<IReportConfigMap | null>
  >;
  showReportRuns: boolean;
  setShowReportRuns: React.Dispatch<React.SetStateAction<boolean>>;
}
function CimsAllReportConfigs({
  isLoading,
  reportConfigMaps,
  setReportConfigMaps,
  selectedReportConfigMap,
  setSelectedReportConfigMap,
  showReportRuns,
  setShowReportRuns,
}: IProps) {
  const [initialReportsConfig, setInitialReportsConfig] =
    useState<ReportConfigDto | null>(null);

  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const { setSnackbar } = useSnackbar();

  const fetchReportsConfig = () => {
    if (!selectedReportConfigMap?.reportDto.reportId) return;
    getReportsConfig(selectedReportConfigMap.reportDto.reportId)
      .then((res) => {
        setReportConfigMaps((pre) => ({
          ...pre,
          [selectedReportConfigMap.reportDto.reportId]: {
            ...selectedReportConfigMap,
            reportConfig: res.data[0],
          },
        }));
      })
      .catch((err) => {
        console.log(
          'getReportsConfig Error occured in fetching the reports config',
          err
        );
        setSnackbar(
          getErrorMessageFromErrorObj(
            err,
            'Error in fetching the reports config'
          ),
          'error'
        );
      })
      .finally(() => {});
  };

  const columnDef: GridColumns<IReportConfigMap> = [
    {
      field: 'reportName',
      headerName: 'Report Name',
      flex: 2,
      minWidth: 220,
      valueGetter: (props) => props.row.reportDto.reportName,
      renderCell: (props) => {
        return (
          <Box
            onClick={() => {
              setSelectedReportConfigMap(props.row);
              setShowReportRuns(true);
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: BLUE.lighter,
            }}
          >
            <Typography>{toLowerCase(props.value) + ' Report'}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'reportId',
      headerName: 'report Id',
      flex: 2.5,
      minWidth: 250,
      valueGetter: (props) => props.row.reportDto.reportId,
    },

    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (props) => props.row.reportConfig?.createdBy || '-',
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (props) =>
        props.row.reportConfig?.createdAt
          ? dayjs(props.row.reportConfig?.createdAt).format('DD/MM/YYYY')
          : '-',
      renderCell: (props) => {
        return <Typography>{props.value}</Typography>;
      },
    },

    {
      field: 'sampleReportUrl',
      headerName: 'Sample Report',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => {
        return (
          <CimsButton
            onClick={async () => {
              if (props.row.reportDto?.sampleReportUrl) {
                const url = await getPresignedUrl(
                  props.row.reportDto?.sampleReportUrl
                );
                downloadFileFromURI(url.data, 'sample_report');
              }
            }}
            variant="soft"
            disabled={!props.row.reportDto?.sampleReportUrl}
          >
            Download
          </CimsButton>
        );
      },
    },
    {
      field: 'schedule',
      headerName: 'schedule',
      flex: 1,
      minWidth: 180,
      valueGetter: (props) =>
        props.row.reportConfig ? getScheduledTime(props.row.reportConfig) : '-',
      renderCell: (props) => {
        return <Typography>{props.value}</Typography>;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      minWidth: 200,
      renderCell: (props) => {
        return props.row.reportConfig ? (
          <CimsButton
            onClick={() => {
              setSelectedReportConfigMap(props.row);
              setInitialReportsConfig(props.row.reportConfig);
              setTimeout(() => {
                setOpenScheduleModal(true);
              }, 10);
            }}
          >
            Update
          </CimsButton>
        ) : (
          <Box display={'flex'} alignItems="center">
            <Typography>Schedule Report</Typography>
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => {
                setInitialReportsConfig(null);
                setSelectedReportConfigMap(props.row);
                setTimeout(() => {
                  setOpenScheduleModal(true);
                }, 10);
              }}
            >
              <LaunchIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (!selectedReportConfigMap) {
      setShowReportRuns(false);
    }
  }, [selectedReportConfigMap]);

  return (
    <>
      {showReportRuns ? (
        <CimsPastReportsForReport
          selectedReportConfigMap={selectedReportConfigMap}
        />
      ) : (
        <CIMSDataGrid
          data={Object.values(reportConfigMaps)}
          columns={columnDef}
          idColumn={'id'}
          loading={isLoading}
        />
      )}
      <ReportsConfigModal
        openScheduleModal={
          openScheduleModal && selectedReportConfigMap?.reportDto.reportId
        }
        setOpenScheduleModal={setOpenScheduleModal}
        selectedReportId={selectedReportConfigMap?.reportDto.reportId}
        fetchReportsConfig={fetchReportsConfig}
        initialReportConfig={initialReportsConfig}
        selectedReportName={selectedReportConfigMap?.reportDto.reportName}
      />
    </>
  );
}

export default CimsAllReportConfigs;

export const convertToDoubleDigitStr = (num: number) => {
  if (num < 10) {
    return '0' + num;
  }
  return num;
};

const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
export const ordinals = (number: number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
export const getScheduledTime = (data: IReportConfig) => {
  if (data.frequency === 'DAILY') {
    return `${convertCamelCase(data.frequency)} - ${convertToDoubleDigitStr(
      data.hours
    )}:${convertToDoubleDigitStr(data.minutes)}`;
  } else if (data.frequency === 'WEEKLY') {
    // return `${convertCamelCase(data.frequency)} - ${DAYS[data.dayOfWeek]} ${
    //   data.hours
    // }:${data.minutes}`;
    return `${convertCamelCase(data.frequency)} - ${DAYS[data.dayOfWeek - 1]}`;
  } else {
    return `${convertCamelCase(data.frequency)} - ${data.dayOfMonth}${ordinals(
      data.dayOfMonth
    )}`;
  }
};
