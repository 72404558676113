import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Avatar, Divider, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactNode, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getPresignedUrl } from '../../../api/card';
import { ChargebackService } from '../../../services/ChargeBackService';
import {
  ChargebackAuditDto,
  ChargebackStatus,
  RemarksType,
} from '../../../types/chargeBack';
import { FileIcon } from '../../../ui-components/icon/VPIcons';
import { VPBORDER, VPPRIMARY } from '../../../ui-components/theme';
import { DATE_TIME_FORMATS } from '../../../utils/dateTime';
import VegaHeader from '../../common/VegaHeader';

export const ChargeBackStatusColorMap: Record<ChargebackStatus, string> = {
  [ChargebackStatus.REQUEST_CREATED]: VPPRIMARY.YELLOW,
  [ChargebackStatus.REQUEST_CANCELLED]: VPPRIMARY.RED,
  [ChargebackStatus.CHARGEBACK_CREATED]: VPPRIMARY.ORANGE,
  [ChargebackStatus.CHARGEBACK_ACCEPTED]: VPPRIMARY.GREEN,
  [ChargebackStatus.REJECTED_BY_NETWORK]: VPPRIMARY.RED,
  [ChargebackStatus.FIRST_PRESENTMENT]: VPPRIMARY.ORANGE,
  [ChargebackStatus.ISSUER_LOST]: VPPRIMARY.ORANGE,
  [ChargebackStatus.CHARGEBACK_REINITIATED]: VPPRIMARY.ORANGE,
  [ChargebackStatus.SECOND_PRESENTMENT]: VPPRIMARY.ORANGE,
  [ChargebackStatus.ARBITRATION_PENDING]: VPPRIMARY.YELLOW,
  [ChargebackStatus.ARBITRATION_ACCEPTED]: VPPRIMARY.GREEN,
  [ChargebackStatus.ARBITRATION_DECLINED]: VPPRIMARY.RED,

  [ChargebackStatus.CHARGEBACK_CANCELLED]: VPPRIMARY.ORANGE,
  [ChargebackStatus.ARBITRATION_REJECTED]: VPPRIMARY.ORANGE,
};

const StatusDisplayText = ({ status }: { status: ChargebackStatus }) => {
  return (
    <Typography
      color={ChargeBackStatusColorMap[status]}
      fontWeight={600}
      lineHeight={'12px'}
      fontSize={11}
    >
      {status.toUpperCase()}
    </Typography>
  );
};

interface IStatusChangeCardHeader {
  oldStatus?: ChargebackStatus;
  newStatus: ChargebackStatus;
  updatedBy: string;
}
const StatusChangeCardHeader = ({
  oldStatus,
  newStatus,
  updatedBy,
}: IStatusChangeCardHeader) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {oldStatus && (
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <StatusDisplayText status={oldStatus} />
            <ArrowForwardIcon fontSize="small" htmlColor={VPPRIMARY.GRAY} />
          </Stack>
        )}
        <StatusDisplayText status={newStatus} />
      </Stack>
      {updatedBy && (
        <Avatar sx={{ width: 20, height: 20, fontSize: 13 }}>
          {updatedBy ? updatedBy[0].toUpperCase() : ''}
        </Avatar>
      )}
    </Stack>
  );
};
interface IStatusChangeCardWrapper {
  children: ReactNode;
}
const StatusChangeCardWrapper = ({ children }: IStatusChangeCardWrapper) => {
  return (
    <Stack
      border={1}
      borderRadius={3}
      p={1}
      sx={{
        border: VPBORDER.GRAY,
      }}
      bgcolor={'white'}
    >
      {children}
    </Stack>
  );
};
const RequestCreated = ({
  chargebackAuditDto,
}: {
  chargebackAuditDto: ChargebackAuditDto;
}) => {
  return (
    <StatusChangeCardWrapper>
      <StatusChangeCardHeader
        newStatus={chargebackAuditDto.toChargebackStatus}
        updatedBy={chargebackAuditDto.modifiedBy || ''}
      />
      <Divider
        sx={{
          // mt: 0.5,
          my: 1,
        }}
      />
      <Typography color={VPPRIMARY.GRAY} fontWeight={400}>
        {dayjs(chargebackAuditDto?.createdAt).format(
          DATE_TIME_FORMATS.DATE_TIME + ' a'
        )}
      </Typography>
    </StatusChangeCardWrapper>
  );
};
const ChargeBackCreated = ({
  chargebackAuditDto,
}: {
  chargebackAuditDto: ChargebackAuditDto;
}) => {
  return (
    <StatusChangeCardWrapper>
      <StatusChangeCardHeader
        oldStatus={chargebackAuditDto?.fromChargebackStatus}
        newStatus={chargebackAuditDto.toChargebackStatus}
        updatedBy={chargebackAuditDto?.modifiedBy || ''}
      />
      <Divider
        sx={{
          my: 1,
        }}
      />
      <Stack spacing={1.5}>
        <Stack>
          <VegaHeader text="claim id" />
          <Typography noWrap>
            {chargebackAuditDto.toChargebackDto.networkClaimId || '--'}
          </Typography>
        </Stack>
        <Stack>
          <VegaHeader text="REMARKS" />
          <Typography noWrap>
            {chargebackAuditDto.toChargebackDto.remarks || '--'}
          </Typography>
        </Stack>
      </Stack>
    </StatusChangeCardWrapper>
  );
};
const ChargeBackCard1 = ({
  chargebackAuditDto,
  isRemarks,
  isReason,
  isArbitrationProofs,
  isDisputeAmount,
  isDisputeProofs,
}: {
  chargebackAuditDto: ChargebackAuditDto;
  isDisputeAmount?: boolean;
  isReason?: boolean;
  isRemarks?: boolean;
  isArbitrationProofs?: boolean;
  isDisputeProofs?: boolean;
}) => {
  const rejectionReasonQuery = useQuery({
    queryKey: [
      'disputeReason',
      chargebackAuditDto?.toChargebackDto?.rejectionReasonId,
    ],
    queryFn: () => {
      return ChargebackService.getChargebackRejectionReason(
        chargebackAuditDto?.toChargebackDto.rejectionReasonId || ''
      );
    },
    enabled:
      chargebackAuditDto.remarksType === RemarksType.REJECTION_REASON &&
      !!chargebackAuditDto?.toChargebackDto?.rejectionReasonId &&
      isReason,
  });

  const disputeProofs = useMemo(() => {
    if (
      chargebackAuditDto.toChargebackDto.chargebackStatus ===
      ChargebackStatus.ARBITRATION_PENDING
    ) {
      return chargebackAuditDto.toChargebackDto.arbitrationProofs;
    } else if (
      chargebackAuditDto.toChargebackDto.chargebackStatus ===
        ChargebackStatus.FIRST_PRESENTMENT ||
      chargebackAuditDto.toChargebackDto.chargebackStatus ===
        ChargebackStatus.SECOND_PRESENTMENT
    ) {
      return chargebackAuditDto.toChargebackDto.acquirerRejectionProofs;
    }
    return chargebackAuditDto?.toChargebackDto.disputeProofs;
  }, [chargebackAuditDto.toChargebackDto.chargebackStatus, isDisputeProofs]);

  return (
    <StatusChangeCardWrapper>
      <StatusChangeCardHeader
        oldStatus={chargebackAuditDto?.fromChargebackStatus}
        newStatus={chargebackAuditDto?.toChargebackStatus}
        updatedBy={chargebackAuditDto?.modifiedBy || ''}
      />
      <Divider
        sx={{
          my: 1,
        }}
      />
      <Stack spacing={1.5}>
        {isDisputeAmount && (
          <Stack>
            <VegaHeader text="disputeAmount" />
            <Typography noWrap>
              {'₹ ' + chargebackAuditDto?.toChargebackDto.disputeAmount || '--'}
            </Typography>
          </Stack>
        )}
        {}
        {isReason && (
          <Stack>
            <VegaHeader text="reason" />
            <Typography noWrap>
              {chargebackAuditDto.remarksType === RemarksType.REJECTION_REASON
                ? rejectionReasonQuery.data?.reason
                : chargebackAuditDto?.toChargebackDto.reason || '--'}
            </Typography>
          </Stack>
        )}
        {isRemarks && (
          <Stack>
            <VegaHeader text="remarks" />
            <Typography noWrap>
              {chargebackAuditDto?.toChargebackDto.remarks || '--'}
            </Typography>
          </Stack>
        )}

        {isDisputeProofs && (
          <Stack>
            <VegaHeader text="Uploded Files" />
            <Stack direction={'row'} columnGap={1} flexWrap={'wrap'}>
              {disputeProofs.length
                ? disputeProofs.map((fileUrl) => {
                    return (
                      <IconButton
                        onClick={async () => {
                          const presignedUrlRes = await getPresignedUrl(
                            fileUrl || ''
                          );
                          window.open(presignedUrlRes.data, '_blank');
                        }}
                      >
                        <FileIcon />
                      </IconButton>
                    );
                  })
                : '--'}
            </Stack>
          </Stack>
        )}
        <Typography color={VPPRIMARY.GRAY} fontWeight={400}>
          {dayjs(chargebackAuditDto?.createdAt).format(
            DATE_TIME_FORMATS.DATE_TIME + ' a'
          )}
        </Typography>
      </Stack>
    </StatusChangeCardWrapper>
  );
};
const ChargeBackStatusCard = ({
  chargebackAuditDto,
}: {
  chargebackAuditDto: ChargebackAuditDto;
}) => {
  return chargebackAuditDto?.toChargebackStatus ===
    ChargebackStatus.REQUEST_CREATED ? (
    // <RequestCreated chargebackAuditDto={chargebackAuditDto} />
    <ChargeBackCard1 chargebackAuditDto={chargebackAuditDto} />
  ) : chargebackAuditDto?.toChargebackStatus ===
      ChargebackStatus.CHARGEBACK_CREATED &&
    chargebackAuditDto?.fromChargebackStatus ===
      ChargebackStatus.REQUEST_CREATED ? (
    <ChargeBackCreated chargebackAuditDto={chargebackAuditDto} />
  ) : chargebackAuditDto?.toChargebackStatus ===
    ChargebackStatus.REQUEST_CANCELLED ? (
    <ChargeBackCard1
      chargebackAuditDto={chargebackAuditDto}
      isReason
      isRemarks
    />
  ) : chargebackAuditDto?.toChargebackStatus ===
    ChargebackStatus.REJECTED_BY_NETWORK ? (
    <ChargeBackCard1 chargebackAuditDto={chargebackAuditDto} isRemarks />
  ) : chargebackAuditDto?.toChargebackStatus ===
    ChargebackStatus.FIRST_PRESENTMENT ? (
    <ChargeBackCard1
      chargebackAuditDto={chargebackAuditDto}
      isReason
      isDisputeProofs
    />
  ) : chargebackAuditDto?.toChargebackStatus ===
      ChargebackStatus.CHARGEBACK_REINITIATED ||
    chargebackAuditDto?.toChargebackStatus ===
      ChargebackStatus.SECOND_PRESENTMENT ? (
    <ChargeBackCard1
      chargebackAuditDto={chargebackAuditDto}
      isReason
      isDisputeProofs
    />
  ) : chargebackAuditDto?.toChargebackStatus ===
    ChargebackStatus.ARBITRATION_PENDING ? (
    <ChargeBackCard1
      chargebackAuditDto={chargebackAuditDto}
      isDisputeAmount
      isRemarks
      isDisputeProofs
    />
  ) : (
    <ChargeBackCard1 chargebackAuditDto={chargebackAuditDto} />
  );
};

// export all the consts

export {
  ChargeBackCreated,
  ChargeBackStatusCard,
  RequestCreated,
  StatusChangeCardHeader,
  StatusChangeCardWrapper,
  StatusDisplayText,
};
