import {
  CustomerIdentityTypes,
  CustomerStatus,
  CustomerType,
  KycStatus,
} from '../enums/commons';
import { AccountDataDto } from './account';

export type GetAllApplicationsForHQApiData = {
  status: string;
  page: number;
  pageSize: number;
  sortBy: string;
  sortDir: string;
  customerId: string;
};

export interface CustomerAddress {
  id?: number;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
}

export interface CustomerIdentificationInfoDto {
  idType: CustomerIdentityTypes;
  idNumber: string;
  idVerified: string;
  id?: number;
  customerId?: string;
  clientId?: string;
  url?: string;
}

export interface ISupportCustomerDtoProps {
  customerId: string;
  clientId: string;
  branchId: string;
  corporateId: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  customerType: CustomerType;
  countryCode: string;
  mobileNumber: string;
  emailId: string;
  identity: CustomerIdentificationInfoDto[];
  dob: string;
  gender: string;
  currentAddress: CustomerAddress;
  isCurrentAddressPermanent: string;
  permanentAddress: CustomerAddress;
  nationality: string;
  status: CustomerStatus;
  kycStatus: KycStatus;
  creationVector: string;
  selfieUrl: string;
  createdAt?: string;
  updatedAt?: string;
  communicationPinCode: number;
  riskScore: number;
  bureauScore: number;
  makerCheckerId?: string;
  makerCheckerStatus?: string;
  account?: AccountDataDto[] | null | undefined;

  docsUrl: Record<string, string>;
  [key: string]: any;
}

export interface IProgramDtoProps {
  programId: string | null;
  clientId: string | null;
  programType: string | null;
  programPolicyUrl: null;
  name: string | null;
  email: string | null;
  mobile: string | null;
  agreement: string | null;
  tnc: string | null;
  status: string | null;
  hold: [];
  updatedAt: string | null;
  createdAt: string | null;
  agreementTwo: null;
  sanctionLetterFormat: null;
  loanEmiAgreement: null;
  pddList: [];
  timezone: string | null;
  network: string | null;
  issuanceType: null;
  category: string | null;
  type: string | null;
  coBrandPartnerName: null;
  binId: string | null;
  makerUserId: null;
  checkerUserId: null;
  programCustomerType: null;
  metaData: null;
}

export enum CardStatus {
  ISSUED = 'ISSUED',
  REJECTED = 'REJECTED',
  TEMP_BLOCKED = 'TEMP_BLOCKED',
  PERM_BLOCKED = 'PERM_BLOCKED',
  ASSIGNED = 'ASSIGNED',
  IN_TRANSIT = 'IN_TRANSIT',
  IN_STOCK = 'IN_STOCK',
  REPLACED = 'REPLACED',
}

export interface ICardsForAccountProps {
  id: string;
  binId: number;
  productType: string;
  network: string | null;
  cardCategory: string | null;
  cardType: string;
  cardStatus: string;
  cardNumber: string;
  kitId: string | null;
  cardProcessorVendor: string | null;
  lastFourDigits: string;
  expiryDate: string;
  customerId: string | null;
  accountId: string;
  programId: string | null;
  externalCustomerId: string | null;
  externalAccountId: string | null;
  pinStatus: string;
  replacedCardId: string | null;
  replacementReason: string | null;
  discardReasonId: string | null;
  cardAllotmentOrderId: string | null;
  cardDeliveryTrackingId: string | null;
  nameOnCard: string;
  atm: string;
  pos: string;
  ecom: string;
  international: string;
  dcc: string;
  contactless: string;
  dualOrSingleMessage: string | null;
  levelEntityDto: any | null;
  programDto: any | null;
  entityCode: string | null;
  createdAt: string;
  updatedAt: string;
  accountStatus?: string;
  programName?: string;
}

export interface ICardState {
  data: ICardsForAccountProps[];
  isLoading: boolean;
}

export type GetAllApplicationsForAgentApiData = {
  agentId: string;
  status: string;
  page: number;
  pageSize: number;
  sortBy: string;
  programId: string;
  sortDir: string;
};
