import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import { VPAccordion } from '../../../ui-components/common';
import Grid from '@mui/material/Grid';
import DataTile from '../DataTile';
import Typography from '@mui/material/Typography';
import {
  ChargePolicyInterface,
  ChargeRuleInterface,
  SlabChargesInterface,
} from '../../../types/policy';
import { useAppSelector } from '../../../store/hook';
import { CHARGE_CODES_TO_RULE } from '../../../constants/policy';
import { convertCamelCase } from '../../../utils/stringUtils';
import { GridColDef, type GridValueFormatterParams } from '@mui/x-data-grid';
import ProgramDataGrid from './ProgramDataGrid';

const getSlabChargeTableColumns = (): GridColDef<SlabChargesInterface>[] => [
  {
    field: 'start_amount',
    headerName: 'From Amount',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'end_amount',
    headerName: 'To Amount',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'value_type',
    headerName: 'Value Type',
    flex: 1,
    sortable: false,
    minWidth: 180,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      convertCamelCase(params.value),
  },
  {
    field: 'value',
    headerName: 'Value',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
];

const ChargesAccordion = ({
  title,
  data,
  isActive,
}: {
  title: string;
  data?: ChargeRuleInterface;
  isActive?: boolean;
}) => {
  const columnsRef = useMemo(() => getSlabChargeTableColumns(), []);

  const isSlabCharges = data?.charge?.charge_type === 'SLAB_CHARGES';

  const fixChargeContent = [
    {
      header: 'Value Type',
      value: isSlabCharges
        ? 'Slab'
        : `${
            data?.charge?.value_type
              ? convertCamelCase(data.charge.value_type)
              : '-'
          }`,
    },
    ...(!isSlabCharges
      ? [
          {
            header: 'Value',
            value: `${data?.charge?.value ?? '-'}`,
          },
        ]
      : []),
    {
      header: 'Taxation Type',
      value: `${
        data?.taxation_type ? convertCamelCase(data.taxation_type) : '-'
      }`,
    },

    ...(!isSlabCharges
      ? [
          {
            header: 'Charge Type',
            value: `${
              data?.charge?.charge_type
                ? convertCamelCase(data.charge.charge_type)
                : '-'
            }`,
          },
        ]
      : []),
  ];

  return (
    <VPAccordion title={title} disabled={!isActive}>
      <VPAccordion.TailEnd>
        <Box
          sx={{
            px: 2,
            py: 0.5,
            backgroundColor: isActive ? '#1047DC1A' : 'transparent',
            borderRadius: '60px',
          }}
        >
          <Typography
            className="font-aspekta-700"
            fontSize={10}
            textTransform="uppercase"
            color={isActive ? '#1047DC' : '#7B8694'}
          >
            {isActive ? 'Active' : 'Inactive'}
          </Typography>
        </Box>
      </VPAccordion.TailEnd>
      <VPAccordion.Body>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 2,
          }}
        >
          <Grid container spacing={3}>
            {fixChargeContent.map(({ header, value }) => (
              <Grid item xs={6} sm={3} key={header}>
                <DataTile title={header} value={value} />
              </Grid>
            ))}
          </Grid>
          {isSlabCharges ? (
            <ProgramDataGrid
              data={data.charge.charges ?? []}
              columns={columnsRef}
              idColumn={'start_amount'}
            />
          ) : null}
        </Box>
      </VPAccordion.Body>
    </VPAccordion>
  );
};

function ViewChargesPolicy() {
  const activePolicy = useAppSelector(
    (state) => state.programManagement.activePolicy
  ) as ChargePolicyInterface;

  const chargesData = useMemo(
    () =>
      Object.keys(CHARGE_CODES_TO_RULE).map((code) => {
        const chargeRule = activePolicy.charge_rules.find(
          (rule) => rule.charge_code === code
        );
        return {
          title: convertCamelCase(code),
          isActive: !!chargeRule,
          data: chargeRule,
        };
      }),
    [activePolicy]
  );

  return (
    <Box
      sx={{
        p: {
          xs: 1,
          sm: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {chargesData.map((data) => (
        <ChargesAccordion
          key={data.title}
          title={data.title}
          isActive={data.isActive}
          data={data.data}
        />
      ))}
    </Box>
  );
}

export default ViewChargesPolicy;
