import { Box, Grid } from '@mui/material';
import React, { SetStateAction } from 'react';
import { BORDER, BORDER_GRAY, GREY, PURPLE } from '../../../constants/style';

interface IDPDselectProps {
  onSelect: (value: number) => void;
  state: number;
  data: number[];
}

const DPDSelector = ({ onSelect, state, data }: IDPDselectProps) => {
  return (
    <Grid container spacing={2}>
      {data.map((dpd: number) => (
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              px: 3,
              py: 2,
              borderRadius: '10px',
              textAlign: 'center',
              cursor: 'pointer',
              ...(state === dpd
                ? { bgcolor: PURPLE.main, border: BORDER, color: 'white' }
                : { bgcolor: GREY.light, border: BORDER_GRAY }),
            }}
            onClick={() => onSelect(dpd)}
          >
            {dpd} + DPD
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default DPDSelector;
