import { useEffect, useMemo, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import { sorting } from '../../../constants/commonFunction';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

import {
  fetchCorporateFunloadList,
  updateCorporateCheckerMakerForfundLoad,
} from '../../Back-office/api/corporateEntity';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { IFilterProps } from '../../Back-office/pages/MCCAplicationManagement';
import { ICardFundLoadProps } from '../../types/Card';
import { STATUS } from '../../variable';
import ListOfCheckerCorporateTransaction from '../components/CheckerCorporateTransaction/ListOfCheckerCorporateTransaction';
import FilterAction from '../components/FilterAction';

const CheckerCorporateTransaction = () => {
  const { userId, clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [rowMultiSelect, setRowMultiSelect] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [cardReloadData, setCardReloadData] = useState<ICardFundLoadProps[]>(
    []
  );
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });

  const handleFilter = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const onSubmitStatus = (data: any) => {
    updateCorporateCheckerMakerForfundLoad(data)
      .then((res) => {
        setSnackbar('Status change!');
        getFundList();
        setSelectedRows([]);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to change status'),
          'error'
        );
        setSelectedRows([]);
      });
  };

  const onApprove = () => {
    selectedRows.map((val: any) => {
      const obj = { status: 'APPROVED', userId, entityId: val };
      onSubmitStatus(obj);
    });
  };
  const onReject = () => {
    selectedRows.map((val: any) => {
      const obj = { status: 'REJECTED', userId, entityId: val };
      onSubmitStatus(obj);
    });
  };

  const onStatusChange = async (data: any, status: string) => {
    const obj = { status, userId, entityId: data.id };
    onSubmitStatus(obj);
  };

  const filteredData2 = useMemo(() => {
    setCurrentPage(1);

    return cardReloadData.filter((cus) => {
      if (cus?.accountConsolidatedDto === null) {
        return (
          filterData.status === 'ALL' ||
          filterData.status === cus.makerCheckerStatus
        );
      }
      return (
        (cus?.accountConsolidatedDto?.customer?.emailId
          .toLowerCase()
          .includes(filterData.mobile.toLowerCase()) ||
          cus?.accountConsolidatedDto?.customer?.emailId
            .toLowerCase()
            .includes(filterData.mobile.toLowerCase())) &&
        (filterData.status === 'ALL' ||
          filterData.status === cus.makerCheckerStatus)
      );
    });
  }, [filterData, cardReloadData]);

  const getFundList = async () => {
    setListLoading(true);
    fetchCorporateFunloadList({
      clientId,
    })
      .then(async (res) => {
        // const getData = res.data?.map((value: any) => ({
        //   ...value,
        //   id: value?.account?.id ?? value?.customer?.id,
        // }));
        const filteredCardCustomer = await res.data.filter(
          (val: any) => val.fundLoadOrderStatus !== 'DRAFT'
        );
        // .filter((val: any) => val.accountConsolidatedDto?.card !== null);
        
        const sortedData = sorting(filteredCardCustomer);

        const data = sortedData.map((d: any) => {
          const customerName = d?.corporateConsolidatedDto?.corporate?.corporateName || '';
          const cardNetwork = d?.corporateConsolidatedDto?.corporate?.cardNetwork || '';
          const customerType = d?.corporateConsolidatedDto?.corporate?.customerType || '';
          return {...d, customerName, cardNetwork, customerType}
        })

        setCardReloadData(
          data.map((val: ICardFundLoadProps) => ({
            ...val,
            selected: false,
          }))
        );
        // setCardReloadData(
        //   res.data.filter(
        //     (val: any) => val.accountConsolidatedDto.card !== null
        //   )
        // );

        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getFundList();
  }, []);
  return (
    <VegaPage>
      <MCCFilterSection
        isStatusFilter
        statusOption={STATUS}
        state={filterData}
        handleChange={handleFilter}
        // onSearch={onSearch}
      />
      <VegaCard fullHeight noPad>
        <FilterAction
          options={['PENDING', 'APPROVED', 'REJECTED']}
          selected={'PENDING'}
          selectedCount={selectedRows.length}
          onApprove={onApprove}
          onReject={onReject}
        />
        <ListOfCheckerCorporateTransaction
          data={filteredData2}
          onStatusChange={onStatusChange}
          loading={listLoading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </VegaCard>
    </VegaPage>
  );
};

export default CheckerCorporateTransaction;
