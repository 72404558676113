export const CIMS_PRIMARY = {
  MAIN: 'rgba(30, 56, 104, 1)',
  ACTIVE_MAIN: 'rgba(27, 51, 94, 1)',
  GOLD: '#C8A864',
  GRAY: '#676B76',
  BLUE: '#1047DC',
  BLACK: '#1B1D22',
  RED: '#E80101',
  GREEN: '#318439',
  YELLOW: '#C9A907',
  ORANGE: '#E87001',
  LIGHT_BLUE: '#1A89C9',
  SBM_BLUE: 'rgba(0, 178, 226, 1)',
};

export const CIMS_BORDER = {
  GRAY: `1px solid #EBEBEB`,
  DARKGRAY: `1px solid #E1E4EB`,
};
