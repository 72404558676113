import { useEffect, useMemo, useState } from 'react';

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import {
  filterDisputeByFilters,
  updateDisputeStatus,
} from '../../../api/dispute';
import { PRIMARY } from '../../../constants/style';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { VegaToolTipAndCopy } from '../../common/VegaToolTipNCopy';

const ServiceMngColDefs1: GridColDef[] = [
  {
    field: 'id',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Request Number
      </Typography>
    ),
    flex: 1.4,
    renderCell: (props) => (
      <VegaToolTipAndCopy value={props.row.id} title="Request No" />
    ),
  },
  {
    field: 'requestType',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Request Type
      </Typography>
    ),
    renderCell: (props) => {
      return <Box>Dispute</Box>;
    },
    flex: 0.7,
  },
  {
    field: 'accountId',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Account Id
      </Typography>
    ),
    flex: 1.5,
    renderCell: (props) => (
      <VegaToolTipAndCopy value={props.row.accountId} title="Account Id" />
    ),
  },
  {
    field: 'viewProfile',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Profile Details
      </Typography>
    ),

    flex: 0.6,
  },
  {
    field: 'update',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Action
      </Typography>
    ),
    flex: 0.7,
  },
];
type IProps = {
  filterData: {
    key: string;
    value: string;
  };
};

function useService(props: IProps) {
  const { filterData } = props;

  const { selectedProgram } = useProgramData();
  const [serviceTableData, setServiceData] = useState<any>([]);
  const [isServiceDrawerOpen, setIsServiceDrawerOpen] = useState(false);

  const [serviceDrawerState, setDrawerState] = useState<
    'PROFILE' | 'ACTION' | ''
  >('');

  const [serviceDrawerData, setServiceDrawerData] = useState<any>({});

  const ServiceMngColDefs = useMemo(() => {
    ServiceMngColDefs1[3].renderCell = (props) => {
      console.log('props , service  ', props.row);
      return (
        <Box>
          <Button
            variant="outlined"
            color="info"
            style={{
              borderRadius: 4,
              padding: 1,
            }}
            onClick={() => {
              console.log('onclicle  serviceDrawerData', props.row);
              setIsServiceDrawerOpen(true);
              setDrawerState('PROFILE');
              setServiceDrawerData(props.row);
            }}
          >
            View
          </Button>
        </Box>
      );
    };
    ServiceMngColDefs1[4].renderCell = (props) => {
      return (
        <Box>
          <Button
            size="small"
            sx={{
              bgcolor: PRIMARY.light,
              ':hover': { bgcolor: PRIMARY.light },
              '&.Mui-disabled': {
                color: PRIMARY.light,
                bgcolor: PRIMARY.lighter,
              },
              color: 'white',
            }}
            onClick={() => {
              setIsServiceDrawerOpen(true);
              setDrawerState('ACTION');
              setServiceDrawerData(props.row);
            }}
          >
            Update
          </Button>
        </Box>
      );
    };

    return ServiceMngColDefs1;
  }, []);

  const fetchDisputes = () => {
    filterDisputeByFilters({
      filter: filterData.key || 'PROGRAM_ID',
      // value: selectedProgram.programId,
      value: filterData.value || '2b1c044c-5c0d-493d-bb55-c8ba5081496a',
    })
      .then((res: any) => {
        console.log('res for the dispute managemenmt', res.data);
        setServiceData(res.data.records);
      })
      .catch((err: any) => {
        setServiceData([]);

        console.log('res for the dispute managemenmt erro ', err);
      });
  };

  const markAquiredHandler = (serviceDrawerData: any) => {
    console.log('serviceDrawerData', serviceDrawerData);
    updateDisputeStatus(serviceDrawerData.id)
      .then((res) => {
        console.log('disputeupdated ', res.data);
        setServiceDrawerData(res.data);
      })
      .catch((err) => {
        console.log('disputeupdated error ', err);
      });
  };

  useEffect(() => {
    console.log('filteredData ', filterData);
    if (selectedProgram?.programId) {
      //   listActiveOfferByProgramId(selectedProgram?.programId)
      fetchDisputes();
    }
  }, [selectedProgram, filterData.value]);

  return {
    serviceTableData,
    ServiceMngColDefs,
    isServiceDrawerOpen,
    serviceDrawerState,
    serviceDrawerData,
    markAquiredHandler,
    setIsServiceDrawerOpen,
    fetchDisputes,
  };
}

export default useService;
