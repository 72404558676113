import { Add } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { IterationType } from '../../../../../../../../../types/RewardsLoyalty';
import VegaFormInputFieldV2 from '../../../../../../../../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../../../../../../../common/v2/VegaPrimaryButton';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../../common/v2/VegaSelectV2';
import { useCampaignRule } from '../../../../../../../providers/CampaignRuleProvider';
import NonPointBasedMilestoneInput from './NonPointBasedMilestoneInput';

function NonPointBasedMilestoneRewards() {
  const {
    nonPointBasedMilestoneData,
    addNonPointBasedMilestone,
    updateNonPointBasedMilestoneIteration,
  } = useCampaignRule();

  function onAddMilestoneClick() {
    addNonPointBasedMilestone({});
  }

  function onIterationChange(value: string) {
    updateNonPointBasedMilestoneIteration(value);
  }

  useEffect(() => {
    if ((nonPointBasedMilestoneData.milestones ?? []).length <= 0) {
      addNonPointBasedMilestone({});
    }
  }, [nonPointBasedMilestoneData]);

  return (
    <Stack spacing={2}>
      <Grid container columns={6}>
        <Grid item xs={1}>
          <VegaFormInputFieldV2 label="Iteration">
            <VegaSelectV2
              sx={{ backgroundColor: 'white' }}
              options={iterationOptions()}
              onChange={(e) => onIterationChange(e.target.value as string)}
              value={nonPointBasedMilestoneData.iteration ?? ''}
            />
          </VegaFormInputFieldV2>
        </Grid>
      </Grid>

      {nonPointBasedMilestoneData.milestones?.map((item, index) => {
        return <NonPointBasedMilestoneInput index={index} value={item} />;
      })}
      <Stack direction={'row'} alignItems="center" justifyContent={'start'}>
        <VegaPrimaryButton
          variant="text"
          text="Add Milestone"
          startIcon={<Add />}
          sx={{
            color: 'rgba(255, 123, 1, 1)',
          }}
          onClick={onAddMilestoneClick}
        />
      </Stack>
    </Stack>
  );
}

export default NonPointBasedMilestoneRewards;

const iterationOptions = () =>
  Object.values(IterationType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
