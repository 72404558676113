import { VegaCard } from '../../common';
import { Box } from '@mui/material';
import { GREY } from '../../../constants/style';
import VegaText from '../../common/VegaText';
import ListOfAgents from './ListOfAgents';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useEffect, useState } from 'react';
import { VegaAgent } from '../../../types/collection';
import { getAgents } from '../../../api/collections';

const Agents = () => {
  const { selectedProgram } = useProgramData();
  const [loading, setLoading] = useState<boolean>(false);
  const [agents, setAgents] = useState<VegaAgent[]>([]);

  function fetchAgents() {
    setLoading(true);
    getAgents()
      .then((res) => {
        setAgents(res);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchAgents();
  }, [selectedProgram]);

  return (
    <VegaCard fullHeight noPad>
      <Box
        sx={{
          px: 3,
          py: 2,
          bgcolor: GREY.light,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        }}
      >
        <VegaText text="Agent / Agency" />
      </Box>
      <Box sx={{ p: 3 }}>
        <ListOfAgents data={agents} loading={loading} />
      </Box>
    </VegaCard>
  );
};

export default Agents;
