import { Info, Save, Update } from '@mui/icons-material';
import { Stack, DialogContent, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  CampaignRule,
  CampaignRuleType,
} from '../../../../../../types/RewardsLoyalty';
import VegaFullScreenDialog from '../../../../../common/v2/VegaFullScreenDialog';
import VegaPill from '../../../../../common/v2/VegaPill';
import VegaPrimaryButton from '../../../../../common/v2/VegaPrimaryButton';
import VegaSwitchV2 from '../../../../../common/v2/VegaSwitchV2';
import VegaTextV2 from '../../../../../common/v2/VegaTextV2';
import VegaConditionalWrapper from '../../../../../common/VegaConditionalWrapper';
import VegaDialog from '../../../../../common/VegaDialog';
import { useCampaignRule } from '../../../../providers/CampaignRuleProvider';
import RuleDetails from './RuleDetails';
import RuleTypeConfig from './RuleTypeConfig';

type Props = {
  rule?: CampaignRule | null;
  onClose: () => void;
  open: boolean;
};

function AddRuleModal({ open, onClose, rule }: Props) {
  const {
    isRulePaid,
    onRulePaidStatusChange,
    onRuleTypeChange,
    createRule,
    updateRule,
    setRule,
    reset,
    validationErrors,
  } = useCampaignRule();
  const [showValidationErrorModal, setShowValidationErrorModal] =
    useState<boolean>(false);

  const getRuleNameDisplayText = () => {
    if (!rule) return 'New Earn Rule';
    return rule.ruleName;
  };

  function updateRuleValues() {
    if (rule) {
      setRule(rule);
    } else {
      onRuleTypeChange(CampaignRuleType.TRANSACTIONAL);
    }
  }

  useEffect(() => updateRuleValues(), [open]);

  function _onClose() {
    reset();
    onClose();
  }

  function onSubmit() {
    if (rule) {
      updateRule();
      _onClose();
      return;
    }
    createRule();
    _onClose();
  }

  const validationErrorCount = () => {
    return (
      validationErrors.rewardDataErrors.length +
      validationErrors.commonErrors.length +
      validationErrors.contributionDataErrors.length +
      validationErrors.financialDataErrors.length +
      validationErrors.inclusionExclusionDataErrors.length
    );
  };

  return (
    <VegaFullScreenDialog
      onClose={_onClose}
      open={open}
      renderTitle={() => {
        return (
          <Stack>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mx: 2 }}>
                <VegaTextV2 fontSize={17}>
                  {getRuleNameDisplayText()}
                </VegaTextV2>
              </Box>
              <VegaConditionalWrapper
                condition={rule != null && rule.id != null}
                ifTrue={
                  <Box sx={{ mx: 3 }}>
                    <VegaPill
                      backgroundColor="rgba(238, 234, 251, 1)"
                      textColor="rgba(80, 41, 219, 1)"
                    >
                      <VegaTextV2
                        text={`#${rule?.id}`}
                        fontSize="0.5rem"
                        fontWeight={600}
                      />
                    </VegaPill>
                  </Box>
                }
              />
              <Stack direction="row" spacing={1} alignItems="center">
                <VegaTextV2 fontSize={14}>Free</VegaTextV2>
                <VegaSwitchV2
                  checked={isRulePaid}
                  onChange={(e) => onRulePaidStatusChange(!isRulePaid)}
                />
                <VegaTextV2 fontSize={14}>Paid</VegaTextV2>
              </Stack>
            </Box>
          </Stack>
        );
      }}
      footerActions={
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent="flex-end"
          alignItems={'center'}
          marginY={'.5rem'}
          marginX={'1.5rem'}
        >
          {/* <VegaConditionalWrapper
            condition={validationErrorCount() > 0}
            ifTrue={
              <VegaPill
                backgroundColor="rgba(200, 80, 120, 0.4)"
                onClick={() => setShowValidationErrorModal(true)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Info color="error" />
                  <VegaTextV2 text={`${validationErrorCount()}`} />
                </Stack>
              </VegaPill>
            }
          /> */}
          <VegaPrimaryButton
            size="small"
            onClick={_onClose}
            text={'Close'}
            variant="text"
          />
          <VegaPrimaryButton
            size="small"
            onClick={onSubmit}
            text={rule != null ? 'Update' : 'Save'}
            startIcon={rule != null ? <Update /> : <Save />}
            disabled={validationErrors.isInputValid == false}
          />
        </Stack>
      }
    >
      <DialogContent sx={{ p: 0 }}>
        <RuleDetails />
        <RuleTypeConfig />

        <VegaDialog
          handleClose={function (e: any): void {
            setShowValidationErrorModal(false);
          }}
          open={showValidationErrorModal}
          title={'Error'}
        >
          <Stack>
            {validationErrors.commonErrors.map((item, index) => {
              return <VegaTextV2 key={index} text={item} />;
            })}
            {validationErrors.contributionDataErrors.map((item, index) => {
              return <VegaTextV2 key={index} text={item} />;
            })}
            {validationErrors.financialDataErrors.map((item, index) => {
              return <VegaTextV2 key={index} text={item} />;
            })}
            {validationErrors.inclusionExclusionDataErrors.map(
              (item, index) => {
                return <VegaTextV2 key={index} text={item} />;
              }
            )}
          </Stack>
        </VegaDialog>
      </DialogContent>
    </VegaFullScreenDialog>
  );
}

export default AddRuleModal;
