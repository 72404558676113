import { useEffect, useMemo, useState } from 'react';
import {
  listReportCategories,
  listReportCategoriesForex,
} from '../../../../api/reports';
import { CATEGORIES_DATA } from '../../../../components/Reporting/catalog/ReportsIconMap';
import ReportsTable from '../../../../components/Reporting/reports/ReportsTable';
import { VegaPage } from '../../../../components/common';
import { VegaHorizontalSelectorDataType } from '../../../../components/common/VegaHorizontalSelector';
import VegaSelector from '../../../../components/common/VegaSelector';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { IReportCategory } from '../../../../types/reports';

interface IProps {
  authId: string | null;
  platform: string;
}

const ReportBody = ({ authId, platform }: IProps) => {
  const [activeState, setActiveState] =
    useState<VegaHorizontalSelectorDataType | null>(null);
  const [reportCategoriesMap, setReportCategoriesMap] = useState<Record<
    string,
    IReportCategory
  > | null>(null);

  const arrOfReportCategories = useMemo(() => {
    let temp: any = [];
    if (reportCategoriesMap) {
      temp = Object.keys(reportCategoriesMap).reduce((pre, key: string) => {
        const curr = reportCategoriesMap[key];
        return [
          ...pre,
          {
            id: curr.categoryId,
            text: curr.reportCategory,
            image: CATEGORIES_DATA[curr.reportCategory]?.icon,
            bgColor: CATEGORIES_DATA[curr.reportCategory]?.color,
          },
        ];
      }, [] as VegaHorizontalSelectorDataType[]);
    }
    setActiveState(temp[0]);

    return temp;
  }, [reportCategoriesMap]);

  const fetchReportCategories = async () => {
    const res = await listReportCategoriesForex(authId as string, platform);
    const temp = res.data.reduce(
      (pre: Record<string, IReportCategory>, curr: IReportCategory) => {
        return {
          ...pre,
          [curr.categoryId]: {
            ...curr,
            reportCategory:
              curr.reportCategory === 'CMS'
                ? 'Customer Management System'
                : curr.reportCategory,
          },
        };
      },
      {} as Record<string, IReportCategory>
    );
    setReportCategoriesMap(temp);
  };

  useEffect(() => {
    fetchReportCategories();
  }, []);

  return (
    <VegaPage>
      {reportCategoriesMap && arrOfReportCategories.length && (
        <>
          <VegaSelector
            data={arrOfReportCategories}
            setActiveState={(selected) => setActiveState(selected)}
            active={activeState}
            header="Report Catagory"
            subHeader="All Live programs till date."
          />
          {activeState && (
            <ReportsTable
              selectedCategory={reportCategoriesMap[activeState.id]}
              reportCategoriesMap={reportCategoriesMap}
            />
          )}
        </>
      )}
    </VegaPage>
  );
};

export default ReportBody;
