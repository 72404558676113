import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { VegaToolTipAndCopy } from '../../../components/common/VegaToolTipNCopy';

import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import VegaSwitchV2 from '../../../components/common/v2/VegaSwitchV2';
import { toLowerCase } from '../../../constants/commonFunction';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { EditFormFiled } from '../../../components/common/deprecated/EditFormFiled';
import { EntityDto } from '../../../types/entity';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { getAddressfromAdressDto } from '../../../utils/common.utils';
import { Dayjs } from '../../../utils/dayjsUtil';
import CIMSDataGrid from '../../components/common/CIMS_DataGrid';
import {
  DivisionDto,
  DivisionStatusEnums,
  DivisionType,
  UpdateDivisionStatusRequest,
} from '../../../types/division';
import { DivisionService } from '../../../api/division';

interface SerializedColumn extends EntityDto {
  sr_no: number;
}

interface IColProps {
  handleUpdateStatus: UseMutateFunction<
    DivisionDto,
    unknown,
    UpdateDivisionStatusRequest,
    unknown
  >;
  userDivisionType?: DivisionType;
}
const getColumns = ({
  handleUpdateStatus,
  userDivisionType,
}: IColProps): GridColDef<DivisionDto>[] => {
  return [
    // {
    //   field: 'sr_no',
    //   headerName: 'S.No.',
    //   flex: 0.5,
    //   minWidth: 50,
    //   renderCell: (props) => (
    //     <VegaToolTipAndCopy isCopy value={(props.row.sr_no || 0).toString()} />
    //   ),
    // },
    {
      field: 'entityType',
      headerName: 'Division Type',
      flex: 1.5,
      minWidth: 180,
      renderCell: (props) => (
        <VegaToolTipAndCopy
          isCopy
          value={toLowerCase(props.row.divisionType)}
        />
      ),
    },
    {
      field: 'entityCode',
      headerName: 'Division Code',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <VegaToolTipAndCopy isCopy value={props.row.divisionCode} />
      ),
    },
    // {
    //   field: 'parentEntityType',
    //   headerName: 'Parent Entity Type',
    //   flex: 1.5,
    //   minWidth: 150,
    //   valueGetter: (props) =>
    //     props.row.level === Level.CLIENT || props.row.level === Level.l1
    //       ? Level.CLIENT
    //       : props.row.parentEntityDto?.entityType || '--',
    //   renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    // },
    {
      field: 'parentEntityCode',
      headerName: 'Parent Division Code',
      flex: 1.4,
      minWidth: 140,
      valueGetter: (props) =>
        props.row.divisionType === 'CLIENT' ||
        props.row.divisionType === 'BUSINESS_CORRESPONDENT'
          ? 'CLIENT'
          : props.row.parentDivisionDto?.divisionCode || '--',
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },
    {
      field: 'name',
      headerName: 'Division Name',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => (
        <VegaToolTipAndCopy isCopy value={props.row.name ?? '-'} />
      ),
    },
    {
      field: 'email',
      headerName: 'Email ID',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => (
        <VegaToolTipAndCopy
          isCopy
          value={props.row.email?.toLowerCase() ?? '-'}
        />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (props) => {
        return Dayjs(props.row.createdAt).format('DD/MM/YYYY');
      },
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (props) => props.row.createdBy || '-',
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'mobileNo',
      headerName: 'Mobile No.',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (props) => props.row.mobileNo || '-',
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 4,
      minWidth: 400,
      valueGetter: (params) => getAddressfromAdressDto(params.row.address),
      renderCell: (props) => (
        <VegaToolTipAndCopy
          isCopy
          value={getAddressfromAdressDto(props.row.address)}
        />
      ),
    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Actions',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => (
        <VegaSwitchV2
          size="small"
          checked={props.row.status === DivisionStatusEnums.ACTIVE}
          onChange={(e, checked) => {
            handleUpdateStatus({
              divisionId: props.row.id,
              status: checked
                ? DivisionStatusEnums.ACTIVE
                : DivisionStatusEnums.INACTIVE,
            });
          }}
        />
      ),
      hide: userDivisionType !== 'CLIENT',
    },
    // {
    //   field: 'mapProgram',
    //   type: 'actions',
    //   headerName: 'Programs',
    //   flex: 2,
    //   minWidth: 200,
    //   renderCell: (props) => {
    //     return props.row.entityType !== EntityType.VENDOR ? (
    //       <MapProgramModal entityId={props.row.id} />
    //     ) : (
    //       <>--</>
    //     );
    //   },
    //   hide: apiScope?.currrentLevel !== Level.CLIENT,
    // },
    // {
    //   field: 'admin',
    //   headerName: 'Admin Name',
    //   flex: 1,
    //   minWidth: 100,
    //   renderCell: (props) => (
    //     // <VegaToolTipAndCopy value={props.row.admin.name} />
    //     <>-</>
    //   ),
    // },
    // {
    //   field: 'mobileNo',
    //   headerName: 'Admin Mobile No',
    //   flex: 1,
    //   minWidth: 100,
    //   renderCell: (props) => (
    //     <VegaToolTipAndCopy value={props.row.mobileNo || '-'} />
    //     // <>-</>
    //   ),
    // },
  ];
};

interface IProps {
  setRefetchFun: any;
}
function ListDivisions({ setRefetchFun }: IProps) {
  const { clientId, userDivisionType } = useClientAuth();
  const [selectedDivisionType, setSelectedDivisionType] =
    useState<DivisionType>('CLIENT');

  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(0);
  const { setSnackbar } = useSnackbar();

  const divisionQuery = useQuery({
    queryKey: [
      'fetchDivisions',
      { clientId, selectedDivisionType, page, pageSize },
    ],
    queryFn: () =>
      DivisionService.fetchDivisions({
        clientId: clientId || '',
        ...(selectedDivisionType !== 'CLIENT' && {
          divisionType: selectedDivisionType,
        }),
        includeAddressDto: true,
        page: page,
        size: pageSize,
      }),
    enabled: !!clientId && !!selectedDivisionType,
    keepPreviousData: true,
    onError: (err) => {
      setSnackbar(
        getErrorMessageFromErrorObj(err, 'Error in fetching the divisions'),
        'error'
      );
    },
  });

  const updateStatusMutation = useMutation({
    mutationFn: (data: UpdateDivisionStatusRequest) => {
      return DivisionService.updateDivisionStatus(data);
    },
    onSuccess: () => {
      divisionQuery.refetch();
      setSnackbar('Updated status successfully');
    },
    onError: (error, variables, context) => {
      setSnackbar('Error while updating status - ' + variables.status, 'error');
    },
  });

  useEffect(() => {
    setRefetchFun(() => divisionQuery.refetch);
    return () => {
      setRefetchFun(null);
    };
  });
  return (
    <Box
      sx={{
        p: 2,
        pt: 1,
      }}
    >
      {/* <CIMS_FilterSection
        onFilter={() => {}}
        search={search}
        setSearch={setSearch}
      /> */}
      <Box>
        <CIMSDataGrid
          data={divisionQuery.data?.records || []}
          columns={getColumns({
            handleUpdateStatus: updateStatusMutation.mutate,
            userDivisionType,
          })}
          idColumn={'id'}
          loading={divisionQuery.isLoading || divisionQuery.isPreviousData}
          pageSize={pageSize}
          onPageSizeChange={(size) => {
            setPageSize(size);
          }}
          page={page}
          onPageChange={(page) => {
            setPage(page);
          }}
          rowCount={divisionQuery.data?.totalItems}
          paginationMode="server"
          components={{
            Toolbar: (props) => {
              return (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  my={2}
                  spacing={2}
                >
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      {/* <VegaHeader text={'Select Entity Type'} /> */}
                      <EditFormFiled
                        title={'Select Division Type'}
                        description={selectedDivisionType}
                        onChange={(val) => {
                          setSelectedDivisionType(val as DivisionType);
                        }}
                        placeHolder={'Select Parent Entity'}
                        options={
                          (
                            [
                              'CLIENT',
                              'BUSINESS_CORRESPONDENT',
                              'BC_AGENT',
                              'VENDOR',
                            ] as DivisionType[]
                          ).map((entity) => ({
                            value: entity,
                            label:
                              entity === 'CLIENT' ? 'All' : toLowerCase(entity),
                          })) || []
                        }
                        sx={
                          {
                            // minWidth : '240px'
                          }
                        }
                        // isLoadingOptions={divisionQuery.isLoading}
                      />
                    </Stack>
                  </Stack>

                  <Stack
                    direction={'row'}
                    className="dataGrid"
                    alignItems={'center'}
                    spacing={2}
                  >
                    <GridToolbarQuickFilter
                      autoFocus
                      variant="outlined"
                      sx={{
                        bgcolor: 'white',
                        color: '#999DA8',
                        height: '35px',
                        minWidth: '320px',
                        borderRadius: '9px',
                      }}
                    />
                  </Stack>
                </Stack>
              );
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default ListDivisions;
