import React from 'react';
import VegaTab from '../../../components/common/VegaTab';

interface IProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

function SummaryTab({ selectedTab, setSelectedTab }: IProps) {
  return (
    <VegaTab
      placement="vertical"
      color={'#1047DC'}
      tabData={['HQ', 'Business Correspondent', 'Bc Agent', 'Vendor']}
      selected={selectedTab}
      onSelect={(select) => setSelectedTab(select)}
    />
  );
}

export default SummaryTab;
