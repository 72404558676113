/** @format */

import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { VegaCard, VegaTextField } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaSelectItem from '../common/VegaSelectItem';

interface ITransactionProps {
  onChange: (e: any, v: any) => void;
  transaction: any;
  isLoading: boolean;
  setTransaction: React.Dispatch<React.SetStateAction<any>>;
  onCreate: () => void;
}

const TRANSACTION_TYPE = [
  { text: 'CARD_CASH_WITHDRAW', value: 'CARD_CASH_WITHDRAW' },
  { text: 'CARD_ECOM', value: 'CARD_ECOM' },
];
const CreateTransaction = ({ onChange, onCreate, transaction, setTransaction, isLoading }: ITransactionProps) => {
  return (
    <VegaCard>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Transaction
      </Typography>

      <Grid container spacing={2} columns={24}>
        <Grid item xs={12} sm={5}>
          <VegaSelectItem
            value={transaction.code}
            onChange={(e: any) => onChange('code', e.target.value)}
            key="title"
            label="Type"
            placeholder="Type"
            data={TRANSACTION_TYPE}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <VegaTextField
            value={transaction.amount}
            onChange={(e) => onChange('amount', e.target.value)}
            key="amount-txn"
            label="Amount"
            type={'number'}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <VegaTextField
            value={transaction.mcc}
            onChange={(e) => onChange('mcc', e.target.value)}
            key="txn-mcc-code"
            label="MCC Code"
            type={'number'}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <VegaTextField
            value={transaction.merchantLocation}
            onChange={(e) => onChange('merchantLocation', e.target.value)}
            key="txn-merchantLocation-code"
            label="Merchant Location"
            type={'string'}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <VegaDatePicker
            value={transaction.time}
            onChange={(e) => {
              const selectedDate = dayjs(e);
              if (selectedDate.date() === dayjs().date()) {
                onChange('time', dayjs().add(5, 'minutes'));
              } else {
                onChange('time', dayjs(e).hour(5).minute(30).toISOString());
              }
            }}
            key="transactionTime"
            label="Date"
            placeholder=""
          />
        </Grid>
      </Grid>
      <Grid
        mt={2}
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'flex-end' },
        }}
      >
        <VegaContainedButton
          text="clear"
          onClick={() => setTransaction({ code: '', amount: '', time: '', mcc: '' })}
          isDanger
        />
        <VegaContainedButton text="Create" loading={isLoading} onClick={onCreate} isPrimary />
      </Grid>
    </VegaCard>
  );
};

export default CreateTransaction;
