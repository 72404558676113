import { IconButton, ListItem, ListItemText, Stack } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';

import './styles/_rewards.css';
import { DeleteOutline } from '@mui/icons-material';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Campaign, CampaignStatus } from '../../../types/RewardsLoyalty';
import { useCampaign } from '../providers/CampaignProvider';
import VegaPill from '../../common/v2/VegaPill';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import { useProgramData } from '../../../providers/ProgramDataProvider';

const CampaignNameAndId = (data: { campaign: Campaign }) => {
  const name = data.campaign.campaignName;
  const id = data.campaign.id;
  const image = data.campaign.campaignImageLink ?? '';
  const hasImage = image.length > 0;
  return (
    <ListItem className="campaign-name-col" style={{ gap: '8px' }}>
      <div
        style={{
          height: '24px',
          width: '24px',
          backgroundColor: hasImage ? 'transparent' : 'rgba(0, 0, 0, 0.2)',
          borderRadius: '6px',
        }}
      >
        {hasImage && (
          <img
            src={image}
            height={'100%'}
            width={'100%'}
            style={{ objectFit: 'contain' }}
          />
        )}
      </div>
      <ListItemText
        primary={name}
        secondary={`Id #${id}`}
        classes={{
          primary: 'heading',
          secondary: 'subtitle',
        }}
      />
    </ListItem>
  );
};

const STATUS_BACKGROUND_COLOR = {
  NOT_APPROVED: '#a91919',
  APPROVED: '#26672d',
  PENDING: '#c9a907',
  DRAFT: '#156b9d',
};

const CampaignStatusPill = (data: { status: string }) => {
  var color = '';
  switch (data.status) {
    case 'ACTIVE':
      color = STATUS_BACKGROUND_COLOR.APPROVED;
      break;
    case 'DRAFT':
      color = STATUS_BACKGROUND_COLOR.DRAFT;
      break;
    case 'INACTIVE':
      color = STATUS_BACKGROUND_COLOR.NOT_APPROVED;
      break;
  }
  return (
    <VegaPill backgroundColor={color}>
      <VegaTextV2
        text={_.startCase(_.toLower(data.status))}
        fontSize={'12px'}
        lineHeight="130%"
      />
    </VegaPill>
  );
};

const CampaignActions = (data: { onDeleteClick: () => void }) => {
  return (
    <Stack direction={'row'} spacing={1}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          data.onDeleteClick();
        }}
      >
        <DeleteOutline className="campaign-actions-icon " fontSize="small" />
      </IconButton>
    </Stack>
  );
};

const CampaignProgramName = (data: { campaign: Campaign }) => {
  const { selectedProgram } = useProgramData();
  const displayText =
    data.campaign.programId == selectedProgram?.programId
      ? selectedProgram.name
      : '-';
  return <div className="text">{displayText}</div>;
};

type Props = {
  onCampaignClick: (campaign: Campaign) => void;
};

function CampaignList({ onCampaignClick }: Props) {
  const { campaigns, onCampaignSelected, loading, deleteCampaign } =
    useCampaign();
  const COLUMN_DEF: GridColumns<Campaign> = [
    {
      field: 'firstCol',
      headerName: 'Name & ID',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const campaign = props.row as Campaign;
        return <CampaignNameAndId campaign={campaign} />;
      },
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 0.7,
      renderCell: (props: GridRenderCellParams) => {
        return <CampaignProgramName campaign={props.row} />;
      },
    },
    {
      field: 'campaignType',
      headerName: 'Campaign Type',
      flex: 0.7,
      renderCell: (props: GridRenderCellParams) => {
        const campaign = props.row as Campaign;
        const type = campaign.campaignType;
        return <div className="text">{_.startCase(_.toLower(type))}</div>;
      },
    },
    {
      field: 'redemptionType',
      headerName: 'Redemption Type',
      flex: 0.7,
      renderCell: (props: GridRenderCellParams) => {
        const campaign = props.row as Campaign;
        const type = campaign.redemptionType;
        return <div className="text">{_.startCase(_.toLower(type))}</div>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      flex: 0.5,
      renderCell: (props: GridRenderCellParams) => {
        const campaign = props.row as Campaign;
        const createdAt = campaign.createdAt ?? '';
        const displayText =
          createdAt.length > 0 ? dayjs(new Date()).format('DD/MM/YYYY') : '-';
        return <div className="text">{displayText}</div>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (props: GridRenderCellParams) => {
        const campaign = props.row as Campaign;
        const status = campaign.status;
        return <CampaignStatusPill status={status} />;
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (props: GridRenderCellParams) => {
        const campaign = props.row as Campaign;

        if (campaign.status == CampaignStatus.ACTIVE) {
          return <></>;
        }
        return (
          <CampaignActions
            onDeleteClick={() => {
              deleteCampaign(campaign);
            }}
          />
        );
      },
    },
  ];

  function onItemClick(campaign: Campaign) {
    onCampaignSelected(campaign);
    onCampaignClick(campaign);
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: '4px',
      }}
      className="main-campaign-ls"
    >
      <DataGrid
        sx={{
          overflowX: 'hidden',
          '& .MuiDataGrid-root::-webkit-scrollbar': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 'none !important',
            fontFamily: 'Aspekta !important',
            fontWeight: 700,
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none !important',
          },
          '& .MuiDataGrid-sortIcon': {
            display: 'none !important',
          },
        }}
        showCellRightBorder={false}
        hideFooter
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        rowHeight={64}
        classes={{ row: 'campaign-ls-rows', withBorder: 'no-border' }}
        rows={campaigns}
        columns={COLUMN_DEF}
        disableSelectionOnClick
        rowSpacingType="margin"
        loading={loading}
        onRowClick={(params) => {
          const selectedCampaign = params.row as Campaign;
          onItemClick(selectedCampaign);
        }}
      />
    </div>
  );
}

export default CampaignList;
