import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  generateSettlementCalendar,
  getSettlementCalendar,
  updateProgramMaxPddShiftAllowed,
} from '../../api/program';
import { useProgramData } from '../../providers/ProgramDataProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { VegaSettlementCalendarDate } from '../../types/program';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { DateUtility } from '../../utils/dateTime';
import VegaCardV2 from '../common/v2/VegaCardV2';
import VegaFadeWrapper from '../common/v2/VegaFadeWrapper';
import { Calendar } from './Calendar/Calendar';
import ChangedDates from './ChangedDates';
import SettlementCalendarTopActions from './SettlementCalendarTopActions';

function SettlementCalendar() {
  const [selectedDateSkips, setSelectedDateSkips] = useState<string>('1');
  const [selectedMonth, setSelectedMonth] = useState<number>(1);
  const [selectedYear, setSelectedYear] = useState<number>(2023);
  const [settlementCalendarDate, setSettlementCalendarDate] = useState<
    VegaSettlementCalendarDate[]
  >([]);
  const [effectiveDates, setEffectiveDates] = useState<Map<number, number[]>>(
    new Map()
  );
  const [changedDates, setChangedDates] = useState<
    Map<number, VegaSettlementCalendarDate[]>
  >(new Map());
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();

  const hasDatesGenerated = () => settlementCalendarDate.length > 0;

  const getEffectiveDatesForSelectedMonth = () => {
    const dates = effectiveDates.get(Number(selectedMonth)) ?? [];
    return dates;
  };

  async function onGenerateCalendarClick() {
    var programId = selectedProgram?.programId;
    if (!programId) return;
    try {
      setLoading(true);
      const response = await updateProgramMaxPddShiftAllowed({
        value: Number(selectedDateSkips),
        programId: programId,
      });

      const calendarResponse = await generateSettlementCalendar({
        programId: programId,
      });
      setSettlementCalendarDate(calendarResponse);
    } catch (error) {
      console.error(error);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function getCalendar() {
    var programId = selectedProgram?.programId;
    if (!programId) return;
    try {
      setLoading(true);
      const response = await getSettlementCalendar({
        programId: programId,
        financialYear: selectedYear,
      });
      setSettlementCalendarDate(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function mapSettlementCalendarToEffectiveDates() {
    const selectedDatesMap = new Map<number, number[]>();
    const changedDatesMap = new Map<number, VegaSettlementCalendarDate[]>();
    settlementCalendarDate.forEach((item) => {
      const currentMonth = DateUtility.monthNameToNumber(item.effectiveMonth);
      const actualDate = item.actualDay;
      const actualMonth = item.actualMonth;
      const effectiveDate = item.effectiveDay;
      const effectiveMonth = item.effectiveMonth;
      const existingDates = selectedDatesMap.get(currentMonth) ?? [];
      existingDates.push(item.effectiveDay);
      selectedDatesMap.set(currentMonth, existingDates);

      if (actualDate != effectiveDate || actualMonth != effectiveMonth) {
        const existingChangedDates = changedDatesMap.get(currentMonth) ?? [];
        existingChangedDates.push(item);
        changedDatesMap.set(currentMonth, existingChangedDates);
      }
    });
    setEffectiveDates(selectedDatesMap);
    setChangedDates(changedDatesMap);
  }

  useEffect(() => {
    getCalendar();
  }, [selectedProgram]);

  useEffect(() => {
    mapSettlementCalendarToEffectiveDates();
  }, [settlementCalendarDate]);

  return (
    <VegaCardV2
      display={'flex'}
      flexDirection="column"
      gap={'24px'}
      padding="20px"
    >
      <SettlementCalendarTopActions
        onGenerateCalendarClick={onGenerateCalendarClick}
        onDateSkipsSelected={setSelectedDateSkips}
        selectedDateSkips={Number(selectedDateSkips) ?? ''}
        isCalendarGenerated={hasDatesGenerated()}
        loading={loading}
      />
      <VegaFadeWrapper show={hasDatesGenerated()}>
        <Stack direction={'row'} spacing={3} alignItems="stretch">
          <div style={{ flex: 2 }}>
            <Calendar
              disabled
              selectedDates={getEffectiveDatesForSelectedMonth()}
              month={selectedMonth}
              year={selectedYear}
              onMonthChange={setSelectedMonth}
            />
          </div>

          <ChangedDates
            changedDatesWithMonth={changedDates}
            selectedMonth={selectedMonth}
          />
        </Stack>
      </VegaFadeWrapper>
    </VegaCardV2>
  );
}

export default SettlementCalendar;
