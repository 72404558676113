/** @format */

import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import _ from 'lodash';
import { BORDER_GRAY, GREEN, GREY } from '../../constants/style';

type OptionType = {
  name: string;
  id: string;
};

interface VegaSelectorListProps {
  options: OptionType[];
  onSelect: (isSelected: boolean, value: OptionType) => void;
  selected: OptionType[];
}

const SelectorWithObjOptions = ({ options, onSelect, selected }: VegaSelectorListProps) => {
  return (
    <>
      <Grid container spacing={2}>
        {options.map((option: OptionType) => {
          return (
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  border: BORDER_GRAY,
                  borderRadius: '4px',
                  px: 1,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        selected &&
                        selected.some((selectedElement) => {
                          return selectedElement.id === option.id;
                        })
                      }
                      onChange={(e: any) => onSelect(e.target.checked, option)}
                      sx={{
                        color: GREY.dark,
                        '&.Mui-checked': {
                          color: GREEN.dark,
                        },
                      }}
                    />
                  }
                  label={_.startCase(_.toLower(option.name))}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SelectorWithObjOptions;
