import {
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
  useMediaQuery,
} from '@mui/material';
import {
  ApprovedOutlined,
  RejectedOutlined,
} from '../../../ui-components/icon/VPIcons';

interface VegaTextFieldPropsV2 {
  isValid?: boolean;
}

const StyledTextField = styled(TextField)(({ theme, multiline, error }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return {
    '& .MuiInputBase-root': {
      minHeight: '40px',
      maxHeight: '40px',
      borderRadius: '.5rem',
      ...(multiline && {
        maxHeight: 'none',
      }),
      ...(isMobile == false && {
        backgroundColor: 'white',
      }),

      '& .MuiInputBase-input': {
        fontFamily: 'Aspekta !important',
        fontWeight: 450,
        fontSize: '13px',
        lineHeight: '120%',
        color: 'rgba(58, 74, 95, 1)',
      },
      '&:focus fieldset': {
        borderWidth: 1,
        borderColor: '#C5C8D0',
      },
      '&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: '#C5C8D0',
      },
      '&:hover fieldset': {
        borderWidth: 1,
        borderColor: '#C5C8D0',
      },
    },
  };
});

function LosTextField({
  isValid,
  ...rest
}: VegaTextFieldPropsV2 & TextFieldProps) {
  return (
    <StyledTextField
      autoComplete="off"
      {...rest}
      {...(isValid && {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <ApprovedOutlined />
            </InputAdornment>
          ),
        },
      })}
      {...(rest.error && {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <RejectedOutlined />
            </InputAdornment>
          ),
        },
      })}
    />
  );
}

export default LosTextField;
