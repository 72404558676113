/* eslint-disable react/jsx-pascal-case */
import { Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { listTransactionsForAccount } from '../../../../../api/transaction';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { NEW_PRIMARY } from '../../../../../constants/style';
import {
  TransactionIndicator,
  TransactionStatus,
} from '../../../../../enums/transaction';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useSupport } from '../../../../../providers/SupportProvider';
import { ChargebackStatus } from '../../../../../types/chargeBack';
import {
  TransactionCategory,
  TransactionDto,
} from '../../../../../types/transaction';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { ChargeBackProvider } from '../../../../ChargeBack/hooks';
import Vega_DataGridFilterSection from '../../../../common/v3/Vega_DataGridFilterSection';
import Vega_Datagrid from '../../../../common/v3/Vega_Datagrid';
import Vega_Text from '../../../../common/v3/Vega_Text';
import { RaiseADisputeDrawer } from '../../../../support/ChargeBack';
import { ViewDisputeDetailsModal } from '../../../../support/ChargeBack/ViewDisputeDetails';

interface ITransactionListStateProps {
  data: any;
  loading: boolean;
}

const Transactions = () => {
  const { accountData } = useSupport();
  const { setSnackbar } = useSnackbar();
  const [transactions, setTransactions] = useState<ITransactionListStateProps>({
    data: null,
    loading: false,
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const transactionsQuery = useQuery({
    queryKey: ['transactions_list', accountData.accountId],
    queryFn: () => {
      return listTransactionsForAccount(accountData?.accountId || '');
      // return listTransactionsForAccount('9bb4540a-cb30-4e8c-a53b-ec9ff5460010');
    },
    enabled: !!accountData?.accountId,
    keepPreviousData: true,
    onError: (error) => {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    },
  });

  return (
    <ChargeBackProvider>
      <Vega_Datagrid
        data={transactionsQuery.data?.data.records || []}
        idColumn="id"
        columns={COLUMN_DEF}
        loading={
          transactionsQuery.isLoading || transactionsQuery.isPreviousData
        }
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        paginationMode="client"
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Vega_Text
                  text={'Transactions'}
                  fontWeight={600}
                  color={NEW_PRIMARY.BLACK}
                  fontSize={17}
                />
                <Vega_DataGridFilterSection />
              </Stack>
            );
          },
        }}
      />
    </ChargeBackProvider>
  );
};

export default Transactions;

const ActionButtons = ({ data }: { data: TransactionDto }) => {
  const canCreateChargeBack =
    data.chargebackStatus === null &&
    data.status === TransactionStatus.SUCCESS &&
    data.transactionCategory === TransactionCategory.CARD &&
    data.transactionIndicator === TransactionIndicator.DEBIT;

  const isReissueStatus = [
    ChargebackStatus.CHARGEBACK_CANCELLED,
    ChargebackStatus.REJECTED_BY_NETWORK,
    ChargebackStatus.ARBITRATION_DECLINED,
    ChargebackStatus.ARBITRATION_REJECTED,
    ChargebackStatus.ISSUER_LOST,
  ];

  const showDetails = [];

  return canCreateChargeBack ? (
    <RaiseADisputeDrawer triggerLabel="Raise a dispute" data={data} />
  ) : isReissueStatus.find((status) => status === data.chargebackStatus) ? (
    <RaiseADisputeDrawer triggerLabel="Re-issue Chargeback" data={data} />
  ) : !!data.chargebackStatus ? (
    <ViewDisputeDetailsModal
      triggerLabel="View Dispute Details"
      transactionDto={data}
    />
  ) : (
    <>--</>
  );
};

const COLUMN_DEF: GridColumns<TransactionDto> = [
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 0.6,
    minWidth: 60,
    valueGetter: (props) => {
      return props.row.amount;
    },
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'overlimitUsed',
    headerName: 'Overlimit Used',
    flex: 1,
    minWidth: 130,
    valueGetter: (props) => props.row.overlimitUsed,
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'transactionCode',
    headerName: 'Transaction Code',
    flex: 1,
    minWidth: 120,
    valueGetter: (props) => props.row.transactionCode,
    renderCell: (props) =>
      props.value ? <Typography>{toLowerCase(props.value)}</Typography> : '--',
  },
  {
    field: 'transactionIndicator',
    headerName: 'Transaction Indicator',
    flex: 1,
    minWidth: 120,
    valueGetter: (props) => props.row.transactionIndicator,
    renderCell: (props) =>
      props.value ? <Typography>{toLowerCase(props.value)}</Typography> : '--',
  },
  {
    field: 'merchantName',
    headerName: 'Merchant Name',
    flex: 1,
    minWidth: 130,
    valueGetter: (props) => props.row.merchantName,
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },

  {
    field: 'status',
    headerName: 'Status',

    flex: 0.6,
    minWidth: 60,
    valueGetter: (props) => props.row.status,
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'transcationTime',
    headerName: 'Transaction Time',
    flex: 1,
    minWidth: 120,
    valueGetter: (props) =>
      dayjs(props.row.transactionTime).format('DD/MM/YYYY'),
    renderCell: (props) =>
      props.value ? <Typography>{props.value}</Typography> : '--',
  },
  {
    field: 'transactionCategory',
    headerName: 'Transaction Category',
    flex: 1,
    minWidth: 120,
    valueGetter: (props) => props.row.transactionCategory,
    renderCell: (props) =>
      props.value ? <Typography>{toLowerCase(props.value)}</Typography> : '--',
  },
  {
    field: 'action',
    headerName: 'Action',
    flex: 1.5,
    minWidth: 150,
    renderCell: (props) => {
      return <ActionButtons data={props.row} />;
    },
  },
];
