import { ChangeEvent, DragEvent, useRef, useState } from 'react';
import { AcceptFileType } from '../../../types/acceptFileType';
import { VegaDocument } from '../../../types/program';
import {
  FileValidator,
  FileValidatorResult,
} from '../../../utils/FileValidator';
import { DropZoneView } from './components/DropZoneView';
import { FilesSelectedView } from './components/FilesSelectedView';

interface VegaDropZoneProps {
  documents: VegaDocument[] | null;
  showLoaderForFileCard?: boolean;
  showUploadButtonForFileCard?: boolean;
  acceptFileType?: AcceptFileType;
  onFileSelected: (files: File[] | null) => void;
  onFileSelectedError?: (error: string | null) => void;
  onUploadFileButtonClick?: () => void;
  onCancelFileButtonClick?: () => void;
  onDownloadFileButtonClick?: () => void;
  loading?: boolean;
  fileValidator?: FileValidator;
}

const VegaDropZone = (props: VegaDropZoneProps) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<any>(null);

  function getFile(event: ChangeEvent<HTMLInputElement>) {
    const file =
      event.target.files &&
      event.target.files.length > 0 &&
      event.target.files[0];

    return file;
  }

  function getFileFromDrag(event: DragEvent) {
    const file =
      event.dataTransfer.files &&
      event.dataTransfer.files.length > 0 &&
      event.dataTransfer.files[0];
    return file;
  }

  const handleFileSelect = async (file: File) => {
    if (file) {
      if (!props.fileValidator) {
        props.onFileSelected([file]);
        return;
      }

      const validationResult: FileValidatorResult =
        await props.fileValidator.validateFile(file);

      if (validationResult.isValid) {
        props.onFileSelected([file]);
      } else {
        props.onFileSelectedError?.(validationResult.errorMessage);
      }
    } else {
      props.onFileSelected(null);
    }
  };

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    const file = getFile(event);
    if (file) {
      handleFileSelect(file);
    } else {
      props.onFileSelected(null);
    }
  }

  function handleFileDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const file = getFileFromDrag(event);
    if (file) {
      handleFileSelect(file);
    } else {
      props.onFileSelected(null);
    }
  }

  function handleFileDrag(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  }

  function onButtonClick() {
    inputRef.current.click();
  }

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleFileDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        accept={props.acceptFileType ?? AcceptFileType.All}
        id="input-file-upload"
        onChange={handleFileChange}
      />

      {!props.documents && (
        <DropZoneView dragActive={dragActive} onButtonClick={onButtonClick} />
      )}

      {props.documents && props.documents.length > 0 && (
        <FilesSelectedView
          documents={props.documents}
          showLoader={props.showLoaderForFileCard}
          showDownloadButton={!props.showUploadButtonForFileCard}
          showUploadButton={props.showUploadButtonForFileCard}
          onUploadButtonClick={props.onUploadFileButtonClick}
          onCancelButtonClick={props.onCancelFileButtonClick}
          onDownloadButtonClick={props.onDownloadFileButtonClick}
          loading={props.loading}
        />
      )}

      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleFileDrag}
          onDragLeave={handleFileDrag}
          onDragOver={handleFileDrag}
          onDrop={handleFileDrop}
        ></div>
      )}
    </form>
  );
};

export default VegaDropZone;
