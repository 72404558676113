import { Box, Grid, Typography } from '@mui/material';
import { VegaAccordian, VegaTextField } from '../../../../../components/common';
import { BORDER_GRAY, GREY } from '../../../../../constants/style';
import { ISubwalletDataProps } from '../../../../types/Card';
import { IConsolidatedAccountData } from '../../../../types/Commons';

interface IProps {
  isShow?: boolean;
  state: IConsolidatedAccountData | null;
  handleChange?: (name: string, value: any) => void;
  isPhoneNumber?: boolean;
}

const DEFULT_DATA = [
  {
    currancyType: 'Dolllar (USD)',
    accountBalance: 1000,
    availableBalance: 600,
    holdBalance: 400,
  },
  {
    currancyType: 'Pound (GBP)',
    accountBalance: 1000,
    availableBalance: 750,
    holdBalance: 250,
  },
  {
    currancyType: 'Euro (EUR)',
    accountBalance: 1000,
    availableBalance: 800,
    holdBalance: 200,
  },
];

const BalanceView = ({ state, isPhoneNumber }: IProps) => {
  const fName =
    state?.customer?.firstName !== null ? state?.customer?.firstName : '';
  const mName =
    state?.customer?.middleName !== null ? state?.customer?.middleName : '';
  const lName =
    state?.customer?.lastName !== null ? state?.customer?.lastName : '';
  const customerName = fName + ' ' + mName + ' ' + lName;

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state?.customer?.customerId ?? ''}
            onChange={(e) => console.log('customerId', e.target.value)}
            key="customerId"
            label="Customer Id"
            type={'text'}
            placeholder="Customer Id"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {isPhoneNumber ? (
            <VegaTextField
              value={state?.customer?.mobileNumber ?? ''}
              onChange={(e) => console.log('mobileNumber', e.target.value)}
              key="mobileNumber"
              label="Phone No"
              type={'number'}
              placeholder="Phone No"
              disabled
            />
          ) : (
            <VegaTextField
              value={state?.account?.primaryCardId ?? ''}
              onChange={(e) => console.log('cardNo', e.target.value)}
              key="cardNo"
              label="Card No"
              type={'text'}
              placeholder="Card No"
              disabled
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <VegaTextField
            value={customerName}
            onChange={(e) => console.log('name', e.target.value)}
            key="name"
            label="Customer Name"
            type={'text'}
            placeholder="Customer Name"
            disabled
          />
        </Grid>
      </Grid>
      {state?.subWallets &&
        state.subWallets.map((balance: ISubwalletDataProps) => (
          <Box sx={{ my: 2 }}>
            <VegaAccordian
              noPad
              header={balance.currency}
              content={
                <>
                  <Grid
                    container
                    sx={{
                      borderTop: BORDER_GRAY,
                      borderBottom: BORDER_GRAY,
                    }}
                  >
                    <Grid
                      item
                      sm={4}
                      sx={{
                        borderRight: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                        bgcolor: GREY.light,
                      }}
                    >
                      <Typography>Account Balance</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      sx={{
                        borderRight: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                        bgcolor: GREY.light,
                      }}
                    >
                      <Typography>Available Balance</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      sx={{ textAlign: 'center', py: 1, bgcolor: GREY.light }}
                    >
                      <Typography>Hold Balance</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      sm={4}
                      sx={{
                        borderRight: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                      }}
                    >
                      <Typography>{balance.totalBalance}</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      sx={{
                        borderRight: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                      }}
                    >
                      <Typography>{balance.availableBalance}</Typography>
                    </Grid>
                    <Grid item sm={4} sx={{ textAlign: 'center', py: 1 }}>
                      <Typography>{balance.holdBalance}</Typography>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </Box>
        ))}
    </Box>
  );
};

export default BalanceView;
