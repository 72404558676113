import { useState } from 'react';
import {
  FileCheckIcon,
  FileDownIcon,
  FileLockIcon,
  FileShieldIcon,
} from '../../../ui-components/icon/VPIcons';
import VPMenuDrivenContent from '../../../ui-components/common/VPMenuDrivenContent';
import {
  AgreementProcess,
  ApprovalProcess,
  KycFlow,
  StatementPassword,
} from '../containers';

type OnboardingJourneyProps = {};

const listItems = [
  { id: 'kycFlow', title: 'KYC Flow', Icon: FileShieldIcon },
  { id: 'approvalProcess', title: 'Approval Process', Icon: FileCheckIcon },
  { id: 'agreement', title: 'Agreement & Sanction Letter', Icon: FileDownIcon },
  { id: 'statement', title: 'Statement Password', Icon: FileLockIcon },
];

const ActiveTab = ({ id }: { id: string }) => {
  switch (id) {
    case 'kycFlow':
      return <KycFlow />;
    case 'approvalProcess':
      return <ApprovalProcess />;
    case 'agreement':
      return <AgreementProcess />;
    case 'statement':
      return <StatementPassword />;
    default:
      return null;
  }
};

function OnboardingJourney(props: OnboardingJourneyProps) {
  const [selectedListItem, setSelectedListItem] = useState(listItems[0].id);

  const handleListItemClick = (id: string) => {
    setSelectedListItem(id);
  };

  return (
    <VPMenuDrivenContent
      menuItems={listItems}
      selectedMenuItem={selectedListItem}
      onMenuItemChange={handleListItemClick}
    >
      <ActiveTab id={selectedListItem} />
    </VPMenuDrivenContent>
  );
}

export default OnboardingJourney;
