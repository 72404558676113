import { Grid } from '@mui/material';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Merchant } from '../../../../../../../../types/resources';
import { InclusionExclusionConditionType } from '../../../../../../../../types/RewardsLoyalty';
import VegaMultiSelect, {
  VegaMultiSelectOption,
} from '../../../../../../../common/v2/VegaMultiSelect';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../common/v2/VegaSelectV2';
import VegaTextV2 from '../../../../../../../common/v2/VegaTextV2';
import {
  InclusionExclusionInputType,
  useCampaignRule,
} from '../../../../../../providers/CampaignRuleProvider';

enum MerchantCategoryOption {
  Merchant = 'Merchant',
  Category = 'Category',
}

type FilterData = {
  type: string;
  options: VegaMultiSelectOption[];
};

function MerchantAndCategoryInclusionExclusionInput() {
  const {
    inclusionExclusionData,
    merchants,
    onInclusionExclusionConditionTypeChange,
    onInclusionExclusionSelectValuesChange,
  } = useCampaignRule();

  const [inclusionData, setInclusionData] = useState<FilterData>({
    type: MerchantCategoryOption.Merchant,
    options: [],
  });
  const [exclusionData, setExclusionData] = useState<FilterData>({
    type: MerchantCategoryOption.Category,
    options: [],
  });

  function updateInclusionType(type: string) {
    const isMerchant = type === MerchantCategoryOption.Merchant;
    setInclusionData({
      type: type,
      options: [],
    });
    setExclusionData({
      type: isMerchant
        ? MerchantCategoryOption.Category
        : MerchantCategoryOption.Merchant,
      options: [],
    });

    onInclusionExclusionConditionTypeChange({
      type: isMerchant
        ? InclusionExclusionInputType.MERCHANT
        : InclusionExclusionInputType.CATEGORY,
      condition: InclusionExclusionConditionType.INCLUSION,
    });
  }

  function updateInclusionSelectValues(values: VegaMultiSelectOption[]) {
    setInclusionData((prev) => {
      return {
        ...prev,
        options: values,
      };
    });
    onSelectValuesChange({ values: values, type: inclusionData.type });

    // hacky code need to be refactored
    if (inclusionData.type == MerchantCategoryOption.Merchant) {
      const merchantIds: string[] = values
        .map((item) => item?.id ?? '')
        .filter((item) => item.length > 0);

      const availableCategories = categoryOptions(merchants, merchantIds);
      const actualCategories = exclusionData.options;

      const validCategories = actualCategories.filter((category) =>
        availableCategories.some(
          (availableCategory) => availableCategory.id === category.id
        )
      );

      setExclusionData((prev) => {
        return {
          ...prev,
          options: validCategories,
        };
      });
    }
  }

  function updateExclusionSelectValues(values: VegaMultiSelectOption[]) {
    setExclusionData((prev) => {
      return {
        ...prev,
        options: values,
      };
    });
    onSelectValuesChange({ values: values, type: exclusionData.type });
  }

  function onSelectValuesChange(data: {
    type: string;
    values: VegaMultiSelectOption[];
  }) {
    const isMerchant = data.type === MerchantCategoryOption.Merchant;
    onInclusionExclusionSelectValuesChange({
      type: isMerchant
        ? InclusionExclusionInputType.MERCHANT
        : InclusionExclusionInputType.CATEGORY,
      values: data.values.map((item) => item.value),
    });
  }

  const getSelectedMerchants = () => {
    const merchantIds = inclusionExclusionData.merchants ?? [];
    const merchantOptions = merchants
      .filter((item) => merchantIds.includes(item.id))
      .map((item) => {
        const option: VegaMultiSelectOption = {
          value: item.id,
          label: item.name,
          id: item.id,
        };
        return option;
      });
    return merchantOptions;
  };

  const getSelectedCategories = () => {
    const selectedCategoryNames = inclusionExclusionData.categories ?? [];
    const allMerchantIds = merchants.map((item) => item.id);
    const categories = categoryOptions(merchants, allMerchantIds).filter(
      (item) => selectedCategoryNames.includes(item.value)
    );
    return categories;
  };

  const getExclusionSelectionOptions = () => {
    const isMerchantSelected =
      inclusionData.type == MerchantCategoryOption.Merchant;
    if (isMerchantSelected) {
      const data: VegaSelectOptionV2 = {
        id: MerchantCategoryOption.Category,
        value: MerchantCategoryOption.Category,
        label: MerchantCategoryOption.Category,
      };
      return [data];
    }
    const data: VegaSelectOptionV2 = {
      id: MerchantCategoryOption.Merchant,
      value: MerchantCategoryOption.Merchant,
      label: MerchantCategoryOption.Merchant,
    };
    return [data];
  };

  const getInclusionOptions = () => {
    if (inclusionData.type == MerchantCategoryOption.Merchant) {
      return merchantOptions(merchants);
    }
    const allMerchantIds = merchants.map((item) => item.id);
    return categoryOptions(merchants, allMerchantIds);
  };

  const getExlusionOptions = () => {
    if (inclusionData.type == MerchantCategoryOption.Merchant) {
      const selectedMerchants: string[] = inclusionData.options
        .filter((item) => item.id !== undefined)
        .map((item) => item.id as string);

      const options = categoryOptions(merchants, selectedMerchants);
      return options;
    }
    return merchantOptions(merchants);
  };

  const getSelectedInclusionValues = () => {
    return inclusionData.options;
  };

  const getSelectedExlusionValues = () => {
    return exclusionData.options;
  };

  useEffect(() => {
    const isMerchantInclusive = inclusionExclusionData.isMerchantInclusive;
    const isCategoryInclusive = inclusionExclusionData.isCategoriesInclusive;

    if (isMerchantInclusive == true) {
      setInclusionData((prev) => {
        return {
          type: MerchantCategoryOption.Merchant,
          options: getSelectedMerchants(),
        };
      });
    } else {
      setExclusionData((prev) => {
        return {
          type: MerchantCategoryOption.Merchant,
          options: getSelectedMerchants(),
        };
      });
    }

    if (isCategoryInclusive) {
      setInclusionData((prev) => {
        return {
          type: MerchantCategoryOption.Category,
          options: getSelectedCategories(),
        };
      });
    } else {
      setExclusionData((prev) => {
        return {
          type: MerchantCategoryOption.Category,
          options: getSelectedCategories(),
        };
      });
    }
  }, []);

  return (
    <Stack spacing={2}>
      <VegaTextV2 text="Merchant & Category" variant="body1" />
      <Grid container alignItems={'center'} columns={10}>
        <Grid item xs={1}>
          <VegaTextV2 text="Include" variant="body1" />
        </Grid>
        <Grid item xs={4}>
          <Stack direction={'row'} spacing={2}>
            <VegaSelectV2
              fullWidth
              options={merchantCategoryOptions()}
              value={inclusionData.type ?? ''}
              onChange={(e) => {
                updateInclusionType(e.target.value as string);
              }}
            />
            <div style={{ flex: 1 }}>
              <VegaMultiSelect
                options={getInclusionOptions()}
                selectedOptions={getSelectedInclusionValues()}
                onSelect={updateInclusionSelectValues}
              />
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'} columns={10}>
        <Grid item xs={1}>
          <VegaTextV2 text="Exclude" variant="body1" />
        </Grid>
        <Grid item xs={4}>
          <Stack direction={'row'} spacing={2}>
            <VegaSelectV2
              fullWidth
              options={getExclusionSelectionOptions()}
              value={exclusionData.type ?? ''}
            />
            <div style={{ flex: 1 }}>
              <VegaMultiSelect
                options={getExlusionOptions()}
                selectedOptions={getSelectedExlusionValues()}
                onSelect={updateExclusionSelectValues}
              />
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default MerchantAndCategoryInclusionExclusionInput;

const merchantOptions = (merchants: Merchant[]) => {
  return merchants.map((item) => {
    const option: VegaMultiSelectOption = {
      id: item.id,
      value: item.id,
      label: _.startCase(_.toLower(item.name)),
    };
    return option;
  });
};

const categoryOptions = (
  merchants: Merchant[],
  selectedMerchantIds: string[]
) => {
  const selectedMerchants = merchants.filter((item) =>
    selectedMerchantIds.includes(item.id)
  );

  const categoryMap = new Map<string, VegaMultiSelectOption>();

  selectedMerchants.forEach((item) => {
    item.mccCodes.forEach((category) => {
      const option: VegaMultiSelectOption = {
        id: category.category.id,
        value: category.category.id,
        label: _.startCase(_.toLower(category.category.name)),
      };

      if (option.id) {
        categoryMap.set(option.id, option);
      }
    });
  });

  return Array.from(categoryMap.values());
};

const merchantCategoryOptions = () =>
  Object.values(MerchantCategoryOption).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: item,
    };
    return option;
  });
