import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Button, Card, CircularProgress, Grid } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { fetchCustomerByCustomerId } from '../../../api/customer';
import { PRIMARY } from '../../../constants/style';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { VegaCreateCustomersType } from '../../../types/customers';
import { VegaAccordian, VegaTextField } from '../../common';

type IProps = {
  preFilledCustomerDetails?: VegaCreateCustomersType | null;
  customerId?: string | null;
};

function ApplicationDetails({ customerId, preFilledCustomerDetails }: IProps) {
  const { setSnackbar } = useSnackbar();

  const [customer, setCustomer] = useState<VegaCreateCustomersType | null>(
    preFilledCustomerDetails ?? null
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!customerId) return;
    setLoading(true);
    fetchCustomerByCustomerId(customerId)
      .then((response) => {
        const customer = response.data as VegaCreateCustomersType;
        setCustomer(customer);
      })
      .catch((error) => {
        setSnackbar('Failed to load customer', 'error');
      })
      .finally(() => setLoading(false));
  }, [customerId]);

  return (
    <Box pb={2}>
      {loading == true && <CircularProgress />}
      {customer && <CustomerDetails customer={customer} />}
    </Box>
  );
}

const CustomerDetails = (props: { customer: VegaCreateCustomersType }) => {
  const customer = props.customer;
  return (
    <Box display={'flex'} flexDirection="column" rowGap={1}>
      <VegaAccordian
        defaultExpanded
        header={'Basic Details'}
        content={
          <Box p={2} gap={2} display="flex" flexDirection={'column'}>
            <VegaTextField
              value={customer?.firstName + ' ' + customer?.lastName}
              key="customerName"
              label="Customer Name"
            />
            <Box display={'flex'} gap={2}>
              <VegaTextField
                value={customer?.customerId || ''}
                key="applicationId"
                label="Customer Id"
              />
              <VegaTextField
                value={customer?.mobileNumber}
                key="mobileNumber"
                label="Mobile Number"
              />
            </Box>
            <VegaTextField
              value={customer.emailId}
              key="email"
              label="Customer Email"
            />
          </Box>
        }
      />
      <VegaAccordian
        defaultExpanded
        header={'KYC'}
        content={
          <Grid
            container
            columns={17}
            columnGap={1}
            justifyContent="space-between"
          >
            <Grid xs={8}>
              <Box>Pan Card</Box>
              <Card
                style={{
                  padding: 10,
                  paddingTop: 24,
                  marginTop: 12,
                  border: '0.5px solid #E4E4E4',
                  borderRadius: 'lg',
                  height: '280px',
                }}
              >
                <Box
                  borderRadius={'md'}
                  pt={6}
                  px={2}
                  overflow="hidden"
                  width={'100%'}
                >
                  <CardMedia
                    style={{
                      borderRadius: 'md',
                      marginBottom: 16,
                      width: '100%',
                    }}
                    component="img"
                    image="https://mybillbook.in/blog/wp-content/uploads/2022/07/pan-card.png"
                    alt="Paella dish"
                  />
                </Box>
                <Box width={'100%'} justifyContent="center" display={'flex'}>
                  <Button
                    startIcon={<RemoveRedEyeOutlinedIcon />}
                    variant="outlined"
                  >
                    View
                  </Button>
                </Box>
              </Card>

              <Box>
                <Box
                  p={1}
                  my={1}
                  display="flex"
                  justifyContent={'space-between'}
                  columnGap={1}
                  border="1px solid #E4E4E4"
                  borderRadius={2}
                  alignItems="center"
                >
                  <FolderOutlinedIcon />
                  <Typography className="font-aspekta-500" variant="body2">
                    Pancard1.pdf
                  </Typography>
                  <Button
                    sx={{
                      bgcolor: PRIMARY.light,
                      ':hover': { bgcolor: PRIMARY.light },
                      '&.Mui-disabled': {
                        color: PRIMARY.light,
                        bgcolor: PRIMARY.lighter,
                      },
                      color: 'white',
                    }}
                    size="small"
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </Grid>{' '}
            <Grid xs={8}>
              <Box>Pan Card</Box>
              <Card
                style={{
                  padding: 10,
                  paddingTop: 24,
                  marginTop: 12,
                  border: '0.5px solid #E4E4E4',
                  borderRadius: 'lg',
                  height: '280px',
                }}
              >
                <Box
                  borderRadius={'md'}
                  pt={6}
                  px={2}
                  overflow="hidden"
                  width={'100%'}
                >
                  <CardMedia
                    style={{
                      borderRadius: 'md',
                      marginBottom: 16,
                      width: '100%',
                    }}
                    component="img"
                    image="https://adarshc.com/data/user/index/ent/document/aadhar/banner.jpg"
                    alt="Adhar Card"
                  />
                </Box>
                <Box width={'100%'} justifyContent="center" display={'flex'}>
                  <Button
                    startIcon={<RemoveRedEyeOutlinedIcon />}
                    variant="outlined"
                  >
                    View
                  </Button>
                </Box>
              </Card>

              <Box>
                <Box
                  p={1}
                  my={1}
                  display="flex"
                  justifyContent={'space-between'}
                  columnGap={1}
                  border="1px solid #E4E4E4"
                  borderRadius={2}
                  alignItems="center"
                >
                  <FolderOutlinedIcon />
                  <Typography className="font-aspekta-500" variant="body2">
                    Addhar.pdf
                  </Typography>
                  <Button
                    size="small"
                    sx={{
                      bgcolor: PRIMARY.light,
                      ':hover': { bgcolor: PRIMARY.light },
                      '&.Mui-disabled': {
                        color: PRIMARY.light,
                        bgcolor: PRIMARY.lighter,
                      },
                      color: 'white',
                    }}
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        }
      />

      <VegaAccordian
        defaultExpanded
        header={'Documents'}
        content={
          <Box my={2}>
            Invoice
            <Box
              p={1}
              my={1}
              display="flex"
              justifyContent={'space-between'}
              columnGap={1}
              border="1px solid #E4E4E4"
              borderRadius={2}
              alignItems="center"
            >
              <Box display={'flex'} columnGap={2}>
                <FolderOutlinedIcon />
                <Typography className="font-aspekta-500" variant="body2">
                  salary slip.pdf
                </Typography>
              </Box>
              <Button
                sx={{
                  bgcolor: PRIMARY.light,
                  ':hover': { bgcolor: PRIMARY.light },
                  '&.Mui-disabled': {
                    color: PRIMARY.light,
                    bgcolor: PRIMARY.lighter,
                  },
                  color: 'white',
                }}
                size="small"
              >
                Download
              </Button>
            </Box>
            Document
            <Box
              p={1}
              my={1}
              display="flex"
              justifyContent={'space-between'}
              columnGap={1}
              border="1px solid #E4E4E4"
              borderRadius={2}
              alignItems="center"
            >
              <Box display={'flex'} columnGap={2}>
                <FolderOutlinedIcon />
                <Typography className="font-aspekta-500" variant="body2">
                  bank statement.pdf
                </Typography>
              </Box>
              <Button
                sx={{
                  bgcolor: PRIMARY.light,
                  ':hover': { bgcolor: PRIMARY.light },
                  '&.Mui-disabled': {
                    color: PRIMARY.light,
                    bgcolor: PRIMARY.lighter,
                  },
                  color: 'white',
                }}
                size="small"
              >
                Download
              </Button>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default ApplicationDetails;
