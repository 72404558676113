/** @format */

import { createContext, useContext, useState } from 'react';
import { LosSelectOption } from '../../components/common/deprecated/LosSelect';
import { isCimsProd, isCimsUAT } from '../../constants/url';

export interface CimsProgramContextType {
  selectedProgram: LosSelectOption | null;
  setSelectedProgram: (program: LosSelectOption) => void;
}

export const CimsProgramContext = createContext<CimsProgramContextType | null>(
  null
);

const CimsReportProvider = ({ children }: any) => {
  const [selectedProgram, setSelectedProgram] =
    useState<LosSelectOption | null>({
      value: isCimsProd
        ? '2761717131963617'
        : isCimsUAT
        ? '5751714033739405'
        : '1c3dbe7d-7877-4048-8a44-e42a3510de44',
      label: 'Sbm Cims Program Test1',
    });

  return (
    <CimsProgramContext.Provider
      value={{
        selectedProgram: selectedProgram,
        setSelectedProgram: setSelectedProgram,
      }}
    >
      {children}
    </CimsProgramContext.Provider>
  );
};

export const useCimsProgram = () =>
  useContext(CimsProgramContext) as CimsProgramContextType;

export default CimsReportProvider;
