import { useEffect } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { VegaFileUpload, VegaTextField } from '../../../../components/common';
import VegaSelect from '../../../../components/common/VegaSelect';

import { Controller, UseFormReturn } from 'react-hook-form';
import { NETWORKS } from '../../../../components/ClientHome/AddProgram';
import { VALIDATE_REGEX } from '../../../../utils/validation.utils';
import { ICreateCorporateCustomer } from '../../../types/CorporateCustomer';
import { fetchStateOnPincode } from '../../../../api/resources';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../../utils/FileValidator';

const validationRules = {
  required: 'This field is required',
};

interface IApplicationProps {
  state: ICreateCorporateCustomer;
  handleChange: (name: keyof ICreateCorporateCustomer, value: any) => void;
  hookForm: UseFormReturn<ICreateCorporateCustomer, any, undefined>;
  error?: any;
}

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.PDF])
  .withMultipleExtensionsCheck()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const AddCorporateCustomer = ({
  state,
  handleChange,
  hookForm,
  error,
}: IApplicationProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = hookForm;

  const pinCodeValue = watch('pinCode');
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    if (pinCodeValue) {
      const getListAll = setTimeout(() => {
        fetchStateOnPincode(pinCodeValue)
          .then((res) => {
            const stateList = res.data?.records[0]?.state;
            const cityList = res.data?.records[0]?.city;
            setValue('city', cityList);
            setValue('state', stateList);
          })
          .catch((err) => {
            setSnackbar(getErrorMessageFromErrorObj(err), 'error');
          });
      }, 1000);
      return () => clearTimeout(getListAll);
    } else {
      setValue('city', '');
      setValue('state', '');
    }
  }, [pinCodeValue, setValue]);
  return (
    <>
      <Box sx={{ p: 3 }}>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              name="corporateName"
              control={control}
              defaultValue=""
              rules={{ required: validationRules.required }}
              render={({ field }) => {
                return (
                  <VegaTextField
                    {...field}
                    label="Enter Name"
                    type="text"
                    placeholder="Enter Name"
                    error={!!errors.corporateName}
                    helperText={errors.corporateName?.message}
                    errorText={error['corporateName']}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="mobileNumber"
              control={control}
              defaultValue=""
              rules={{
                required: validationRules.required,
                maxLength: {
                  value: 10,
                  message: 'Mobile number should be 10 digits',
                },
                minLength: {
                  value: 10,
                  message: 'Mobile number should be 10 digits',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="Mobile Number"
                  type="number"
                  placeholder="Mobile Number"
                  error={!!errors.mobileNumber}
                  helperText={errors.mobileNumber?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="emailId"
              control={control}
              defaultValue=""
              rules={{
                required: validationRules.required,
                pattern: {
                  value: VALIDATE_REGEX.EMAIL,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="Email"
                  type="text"
                  placeholder="Email"
                  error={!!errors.emailId}
                  helperText={errors.emailId?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{ required: validationRules.required }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="Address"
                  type="text"
                  placeholder="Address"
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="pinCode"
              control={control}
              rules={{
                required: validationRules.required,
                maxLength: {
                  value: 6,
                  message: 'Pincode should be 6 digits',
                },
                minLength: {
                  value: 6,
                  message: 'Pincode should be 6 digits',
                },
              }}
              render={({ field }) => {
                return (
                  <VegaTextField
                    {...field}
                    label="Pin code"
                    type="number"
                    placeholder="Pin code"
                    error={!!errors.pinCode}
                    helperText={errors.pinCode?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={{ required: validationRules.required }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="City"
                  type="text"
                  placeholder="City"
                  error={!!errors.city}
                  helperText={errors.city?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="state"
              control={control}
              defaultValue=""
              rules={{ required: validationRules.required }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="State"
                  type="text"
                  placeholder="State"
                  error={!!errors.state}
                  helperText={errors.state?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="cardNetwork"
              control={control}
              defaultValue=""
              rules={{ required: validationRules.required }}
              render={({ field }) => (
                <VegaSelect
                  {...field}
                  placeholder="Select Network"
                  label="Network"
                  error={!!errors.cardNetwork}
                  errorHelperText={errors.cardNetwork?.message || ''}
                  options={NETWORKS}
                  selected={field.value}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Grid container spacing={2} p={3} mb={1}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="gstNumber"
            control={control}
            defaultValue=""
            rules={{ required: validationRules.required }}
            render={({ field }) => (
              <VegaTextField
                {...field}
                label="GST Number"
                type="text"
                placeholder="Enter GST No"
                error={!!errors.gstNumber}
                helperText={errors.gstNumber?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="gstFile"
            control={control}
            rules={{ required: validationRules.required }}
            render={({ field }) => (
              <VegaFileUpload
                placeholder="GST File"
                file={field.value as File}
                onUpload={(file) => field.onChange(file)}
                errorHelperText={errors.gstFile?.message}
                onError={(message) => {
                  setSnackbar(message ?? 'Something went wrong', 'error');
                  field.onChange(null);
                }}
                fileValidator={fileValidatorConfig}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="balanceSheetFile"
            control={control}
            rules={{ required: validationRules.required }}
            render={({ field }) => (
              <VegaFileUpload
                placeholder="Balance Sheet"
                file={field.value as File}
                onUpload={(file) => field.onChange(file)}
                errorHelperText={errors?.balanceSheetFile?.message}
                onError={(message) => {
                  setSnackbar(message ?? 'Something went wrong', 'error');
                  field.onChange(null);
                }}
                fileValidator={fileValidatorConfig}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddCorporateCustomer;
