import { AccountCircle, Edit, EditOutlined, Save } from '@mui/icons-material';
import { Stack, Grid, InputAdornment, TextField, styled } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import VegaCardV2 from '../../../components/common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../components/common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../../components/common/v2/VegaPrimaryButton';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../components/common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../../components/common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../../components/common/v2/VegaTextV2';
import VegaConditionalWrapper from '../../../components/common/VegaConditionalWrapper';
import {
  EqualIcon,
  MoneyIcon,
  RuppesIcon,
} from '../../../components/icon/Icons';
import { useCampaign } from '../../../components/RewardsLoyalty/providers/CampaignProvider';
import {
  AddBaseRuleFormData,
  IterationType,
} from '../../../types/RewardsLoyalty';

enum ViewState {
  EDIT,
  READ_ONLY,
}

type Props = {
  onSaveClick: (data: Partial<AddBaseRuleFormData>) => void;
  value?: AddBaseRuleFormData;
};

function CampaignBaseRuleInput({ onSaveClick, value }: Props) {
  const { isCampaignActive } = useCampaign();
  const [points, setPoints] = useState<number>();
  const [perAmount, setPerAmount] = useState<number>();
  const [maxPoints, setMaxPoints] = useState<number>();
  const [iteration, setIteration] = useState<string>('');
  const [viewState, setViewState] = useState<ViewState>(ViewState.READ_ONLY);

  const isInputValid = () => {
    const isPointsValid = points != null && points > 0;
    const isPerAmountValid = perAmount != null && perAmount > 0;
    const isMaxPointValid = maxPoints != null && maxPoints > 0;
    const isIterationValid = iteration != null && iteration.length > 0;
    return (
      isPointsValid && isPerAmountValid && isMaxPointValid && isIterationValid
    );
  };

  function _onSaveClick() {
    onSaveClick({
      perAmount: perAmount,
      maximumPoint: maxPoints,
      iteration: iteration,
      rewardPoint: points,
    });
    updateViewState(ViewState.READ_ONLY);
  }

  function updateViewState(updatedState: ViewState) {
    setViewState(updatedState);
  }

  useEffect(() => {
    if (value) {
      setPoints(value.rewardPoint);
      setPerAmount(value.perAmount);
      setMaxPoints(value.maximumPoint);
      setIteration(value.iteration ?? '');
    } else {
      setPoints(undefined);
      setPerAmount(undefined);
      setMaxPoints(undefined);
      setIteration('');
      updateViewState(ViewState.EDIT);
    }
  }, [value]);

  if (viewState == ViewState.READ_ONLY || isCampaignActive) {
    return (
      <ReadOnlyView
        rewardPoints={points}
        perAmount={perAmount}
        maxPoints={maxPoints}
        iteration={iteration}
        onEditClick={function (): void {
          updateViewState(ViewState.EDIT);
        }}
        showEditButton={isCampaignActive == false}
      />
    );
  }

  return (
    <VegaCardV2>
      <VegaTextV2 text="Base Rule" />
      <Stack
        direction={'row'}
        alignItems="end"
        justifyContent={'space-between'}
      >
        <Grid container columns={5} spacing={2}>
          <Grid item xs={1.5}>
            <Stack direction={'row'} width={'100%'} alignItems="center">
              <VegaFormInputFieldV2 flex={2} label="Reward Points">
                <StyledTextField
                  isRight={true}
                  placeholder="Enter Reward Points"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MoneyIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <EqualIcon />
                      </InputAdornment>
                    ),
                  }}
                  type={'number'}
                  value={!!points ? points : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPoints(Number(value));
                  }}
                />
              </VegaFormInputFieldV2>

              <VegaFormInputFieldV2 flex={1} label="Amount">
                <StyledTextField
                  isLeft={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RuppesIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Amount"
                  type={'number'}
                  value={!!perAmount ? perAmount : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPerAmount(Number(value));
                  }}
                />
              </VegaFormInputFieldV2>
            </Stack>
          </Grid>

          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Maximum Points">
              <VegaTextFieldV2
                placeholder="Enter Maximum Points"
                type={'number'}
                value={!!maxPoints ? maxPoints : ''}
                onChange={(e) => {
                  const value = e.target.value;
                  setMaxPoints(Number(value));
                }}
              />
            </VegaFormInputFieldV2>
          </Grid>

          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Iteration">
              <VegaSelectV2
                options={iterationOptions()}
                placeholder="Select Iteration"
                value={iteration ?? ''}
                onChange={(e) => {
                  setIteration(e.target.value as string);
                }}
              />
            </VegaFormInputFieldV2>
          </Grid>
        </Grid>
        <VegaPrimaryButton
          size="small"
          text="Save"
          startIcon={<Save />}
          onClick={_onSaveClick}
          disabled={isInputValid() == false}
        />
      </Stack>
    </VegaCardV2>
  );
}

export default CampaignBaseRuleInput;

type ReadOnlyProps = {
  onEditClick: () => void;
  rewardPoints?: number;
  perAmount?: number;
  maxPoints?: number;
  iteration?: string;
  showEditButton?: boolean;
};
const ReadOnlyView = ({
  onEditClick,
  rewardPoints,
  perAmount,
  maxPoints,
  iteration,
  showEditButton = true,
}: ReadOnlyProps) => {
  return (
    <VegaCardV2>
      <VegaTextV2 text="Base Rule" />
      <Stack
        direction={'row'}
        alignItems="end"
        justifyContent={'space-between'}
      >
        <Grid container columns={6} spacing={2}>
          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Reward Points" isReadOnly>
              <VegaTextV2 text={`${rewardPoints ?? '-'}`} variant="caption" />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Amount" isReadOnly>
              <VegaTextV2 text={`${perAmount ?? '-'}`} variant="caption" />
            </VegaFormInputFieldV2>
          </Grid>

          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Maximum Points" isReadOnly>
              <VegaTextV2 text={`${maxPoints ?? '-'}`} variant="caption" />
            </VegaFormInputFieldV2>
          </Grid>

          <Grid item xs={1}>
            <VegaFormInputFieldV2 label="Iteration" isReadOnly>
              <VegaTextV2 text={`${iteration ?? '-'}`} variant="caption" />
            </VegaFormInputFieldV2>
          </Grid>
        </Grid>
        <VegaConditionalWrapper
          condition={showEditButton}
          ifTrue={
            <VegaPrimaryButton
              size="small"
              text="Edit"
              startIcon={<EditOutlined />}
              variant="text"
              sx={{ color: 'rgba(255, 123, 1, 1)' }}
              onClick={onEditClick}
            />
          }
        ></VegaConditionalWrapper>
      </Stack>
    </VegaCardV2>
  );
};

const iterationOptions = () =>
  Object.values(IterationType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

type StyledTextFieldProps = {
  isLeft?: boolean;
  isRight?: boolean;
};
const StyledTextField = styled(VegaTextFieldV2)<StyledTextFieldProps>(
  ({ theme, isLeft = false, isRight = false }) => {
    return {
      '& .MuiOutlinedInput-root': {
        fieldset: {
          borderColor: theme.palette.grey[400],
          ...(isRight && {
            borderRight: 'none !important',
            borderBottomRightRadius: '0px',
            borderTopRightRadius: '0px',
          }),

          ...(isLeft && {
            borderCollapse: 'collapse',
            borderBottomLeftRadius: '0px',
            borderTopLeftRadius: '0px',
          }),

          borderCollapse: 'collapse',
        },
      },
    };
  }
);
