import { Box, Grid, Typography } from '@mui/material';
import { VegaTextField } from '../../../components/common';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';

interface IProps {
  state: any;
  handleChange: (name: string, value: any) => void;
  title: string;
  errorMsg?: string;
}

const PlanSelection = ({ state, handleChange, title, errorMsg }: IProps) => {
  return (
    <>
      <Grid container spacing={2} mt={2} pb={3}>
        <Grid item xs={12} sm={6}>
          <Box display={'flex'} width={'100%'} alignItems={'center'}>
            <VegaText text={toLowerCase(title)} />
            <Box sx={{ flexGrow: 1, ml: 2 }}>
              <VegaTextField
                isFixedHeight
                value={state.policy_name}
                onChange={(e) => handleChange('policy_name', e.target.value)}
                key="policy_name"
                label={`${toLowerCase(title)} Name`}
                type={'text'}
                placeholder={`${toLowerCase(title)} Name`}
              />
              <Typography sx={{ color: 'red' }}>
                {errorMsg && errorMsg}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={8}>
          <Box
            display={'flex'}
            width={'100%'}
            alignItems={'center'}
            justifyContent={'end'}
          >
            <VegaText text="Effective Date Range" />
            <Box sx={{ width: '150px', ml: 2 }}>
              <VegaDatePicker
                value={state.startDate}
                onChange={(e) => handleChange('startDate', e)}
                key="startDate"
                label="Select Date"
                placeholder="Select Date"
              />
            </Box>
            <Box mx={2}>
              <VegaText text="To" />
            </Box>
            <Box sx={{ width: '150px' }}>
              <VegaDatePicker
                value={state.endDate}
                onChange={(e) => handleChange('endDate', e)}
                key="endDate"
                label="Select Date"
                placeholder="Select Date"
              />
            </Box>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default PlanSelection;
