import React, { useState } from 'react';
import Vega_Card from '../../../../../components/common/v3/Vega_Card';
import GraphFilterSection from './GraphFilterSection';
import Vega_Select from '../../../../../components/common/v3/Vega_Select';
import LineCharts from '../../../../../components/common/VegaCharts/LineCharts/LineCharts';

const categories = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const ProfitLossGraphContainer = () => {
  const [selectedYear, setSelectedYear] = useState<string>('2022');
  return (
    <>
      <Vega_Card sx={{ mx: 0, p: '1.5rem' }}>
        <>
          <GraphFilterSection
            title="P&L Graph"
            renderRightView={() => {
              return (
                <Vega_Select
                  options={['2022', '2023']}
                  sx={{ width: 100 }}
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value as string)}
                />
              );
            }}
          />
          <LineCharts
            width={'100%'}
            categories={categories}
            series={[
              {
                name: 'Amount in thounds',
                data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 45, 65, 130],
              },
            ]}
          />
        </>
      </Vega_Card>
    </>
  );
};

export default ProfitLossGraphContainer;
