import { ISVGProps } from '../../mcc/types/Commons';

const CallIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? '16'}
      height={props.height ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.58081 4.21739C9.38073 4.35145 10.1074 4.72926 10.6875 5.30817C11.2676 5.88708 11.6431 6.61224 11.7805 7.41053M8.70295 1.6001C10.1257 1.8408 11.4233 2.51417 12.4553 3.54097C13.4872 4.57082 14.1589 5.86575 14.4001 7.2856M13.2887 12.9345C13.2887 12.9345 12.5163 13.6932 12.327 13.9156C12.0186 14.2447 11.6553 14.4001 11.179 14.4001C11.1332 14.4001 11.0844 14.4001 11.0386 14.397C10.1318 14.3392 9.28913 13.9857 8.65713 13.6841C6.92906 12.8492 5.41165 11.664 4.15071 10.1619C3.10959 8.90959 2.41348 7.75177 1.95246 6.50864C1.66851 5.74997 1.56471 5.15887 1.61051 4.60129C1.64104 4.2448 1.77843 3.94925 2.03184 3.69636L3.07295 2.65737C3.22256 2.51721 3.38132 2.44104 3.53703 2.44104C3.72938 2.44104 3.88509 2.55682 3.98279 2.65432C3.98584 2.65737 3.98889 2.66042 3.99195 2.66346C4.17819 2.83713 4.35527 3.0169 4.54151 3.20886C4.63616 3.30636 4.73386 3.40386 4.83156 3.5044L5.66506 4.33621C5.9887 4.65918 5.9887 4.95777 5.66506 5.28074C5.57652 5.3691 5.49103 5.45746 5.40249 5.54278C5.14603 5.80481 5.3475 5.60375 5.08188 5.84141C5.07577 5.8475 5.06966 5.85055 5.06661 5.85665C4.80404 6.11868 4.85289 6.37462 4.90785 6.54829C4.9109 6.55743 4.91395 6.56657 4.91701 6.57571C5.13378 7.09978 5.43909 7.59337 5.90317 8.18142L5.90622 8.18447C6.74888 9.22042 7.63735 10.0278 8.6174 10.6464C8.74258 10.7256 8.87081 10.7896 8.99294 10.8505C9.10285 10.9053 9.20665 10.9571 9.2952 11.012C9.30741 11.0181 9.31962 11.0272 9.33183 11.0333C9.43564 11.0851 9.53334 11.1095 9.63409 11.1095C9.8875 11.1095 10.0463 10.9511 10.0982 10.8993L10.6966 10.302C10.8004 10.1984 10.9653 10.0735 11.1576 10.0735C11.3469 10.0735 11.5026 10.1923 11.5973 10.2959C11.6003 10.299 11.6004 10.299 11.6034 10.302L13.2857 11.9809C13.6002 12.2916 13.2887 12.9345 13.2887 12.9345Z"
        stroke="#1047DC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CallIcon;
