import './Styles/__styles.css';
import { Stack, styled, Typography } from '@mui/material';
import React from 'react';

const StyledInputHeading = styled(Typography)(({ theme }) => ({
  fontSize: '.625rem',
  fontFamily: 'Aspekta !important',
  color: '#7b8694',
  fontWeight: 700,
  lineHeight: '124%',
  letterSpacing: '0.07em',
  textTransform: 'uppercase',
}));

interface VegaFormInputFieldPropsV2 {
  label: string;
  children: React.ReactNode;
  endAdornment?: React.ReactNode;
  flex?: number;
  spacing?: string;
  isReadOnly?: boolean;
}

function VegaFormInputFieldV2({
  label,
  children,
  endAdornment,
  flex,
  spacing,
  isReadOnly = false,
}: VegaFormInputFieldPropsV2) {
  const getSpacing = () => {
    if (spacing) return spacing;
    if (isReadOnly) return '0.25rem';
    return '0.5rem';
  };
  return (
    <Stack
      spacing={getSpacing()}
      style={{
        ...(flex && {
          flex: flex,
        }),
      }}
    >
      <Stack
        direction={'row'}
        spacing={1}
        justifyContent="space-between"
        alignItems={'center'}
        maxHeight="0.75rem"
      >
        <StyledInputHeading>{label}</StyledInputHeading> {endAdornment}
      </Stack>
      {children}
    </Stack>
  );
}

export default VegaFormInputFieldV2;
