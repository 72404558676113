import React, { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import { Typography } from '@mui/material';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import BalanceView from './TransactionDetails';

const ListOfSimulatorTransactions = ({
  onPageChange,
  onPageSizeChange,
  page,
  pageSize,
  data,
  rowCount,
  updateProgram,
  loading = false,
}: any) => {
  const COLUMN = [
    {
      field: 'id',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Transaction Id
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'amount',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Amount
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'transactionIndicator',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Transaction Indicator
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'transactionCode',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Transaction Code
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'transactionDetails',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Transaction Details
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          props.row.status != 'FAILED' && (
            <VegaContainedButton
              text="View"
              isPrimary
              mx="0"
              onClick={() => {
                setOpen(!open);
                setTransactionRefId(props.row.id);
                setParentTransactionId(props.row.parentTransactionId ?? '');
              }}
            />
          )
        );
      },
      flex: 1,
    },
  ];
  const [transactionRefId, setTransactionRefId] = useState<string>('');
  const [parentTransactionId, setParentTransactionId] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const decline = () => {
    setOpen(!open);
  };

  const onUpdate = async () => {
    setOpen(!open);
  };
  return (
    <>
      <VegaCard
        noPad
        sx={{
          mt: 4,
        }}
      >
        <VegaTable
          columnDef={COLUMN}
          data={data}
          idColumn="id"
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
          loading={loading}
        />
      </VegaCard>
      <VegaDrawer
        open={open}
        header={'Transaction Details'}
        closeText="Clear"
        isClose
        onClose={decline}
        onSubmit={onUpdate}
      >
        <BalanceView
          state={{ transactionRefId, parentTransactionId }}
        ></BalanceView>
      </VegaDrawer>
    </>
  );
};

export default ListOfSimulatorTransactions;
