import { useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import Box from '@mui/material/Box';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { MadComponent, MadPolicyInterface } from '../../../types/policy';
import ProgramDataGrid from './ProgramDataGrid';
import { useAppSelector } from '../../../store/hook';
import { convertCamelCase } from '../../../utils/stringUtils';

const getMadTadTableColumns = (): GridColDef<MadComponent>[] => [
  {
    field: 'transaction_category',
    headerName: 'Transaction Category',
    flex: 1,
    sortable: false,
    minWidth: 180,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      convertCamelCase(params.value),
  },
  {
    field: 'value_type',
    headerName: 'Value Type',
    flex: 1,
    sortable: false,
    minWidth: 180,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      capitalize(params.value),
  },
  {
    field: 'value',
    headerName: 'Value',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
];

function ViewMadTadPolicy() {
  const columnsRef = useMemo(() => getMadTadTableColumns(), []);

  const activePolicy = useAppSelector(
    (state) => state.programManagement.activePolicy
  ) as MadPolicyInterface;

  if (!activePolicy) {
    return null;
  }

  const madComponents = activePolicy.mad_components;

  return (
    <Box
      sx={{
        p: {
          xs: 1,
          sm: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4}>
          <DataTile
            title="Min Mad Amount"
            value={`${activePolicy.minimum_mad ?? '-'}`}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <DataTile
            title="Min Tad Amount"
            value={`${activePolicy.minimum_tad ?? '-'}`}
          />
        </Grid>
      </Grid>
      <ProgramDataGrid
        data={madComponents}
        columns={columnsRef}
        idColumn={'transaction_category'}
      />
    </Box>
  );
}

export default ViewMadTadPolicy;
