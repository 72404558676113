import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { BORDER_GRAY, COMMON } from '../../../constants/style';
import VegaContainedButton from '../../common/VegaContainedButton';
import { AddCircleOutline } from '@mui/icons-material';

interface IProps {
  buttonText: string;
  onClick: () => void;
}

const AddCoupons = ({ buttonText, onClick }: IProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: 2,
        px: 3,
        borderBottom: BORDER_GRAY,
      }}
    >
      <Typography
        className="font-aspekta-500"
        fontSize={14}
        color={COMMON.gray}
      >
        Coupons Inventory
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'cneter' }}>
        <Box sx={{}}>
          <VegaContainedButton
            text={buttonText}
            className="font-aspekta-500"
            py={0.7}
            startIcon={<AddCircleOutline />}
            onClick={onClick}
            isPurple
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddCoupons;
