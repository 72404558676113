import React from 'react';
import Modal from '@mui/material/Modal';
import VegaRadio from '../../components/common/VegaRadio';
import { Box, Typography } from '@mui/material';
import VegaContainedButton from '../../components/common/VegaContainedButton';
import { BORDER_GRAY } from '../../constants/style';

interface IModalProps {
  open: boolean;
  value: string;
  header: string;
  name: string;
  handleClose: () => void;
  onSubmit: () => void;
  radioData: any;
  labelPlacement: any;
  color?: any;
  onChange: (e: any) => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const StatusModal = ({
  open,
  header,
  handleClose,
  onSubmit,
  radioData,
  value,
  name,
  labelPlacement,
  color,
  onChange,
}: IModalProps) => {
  console.log('value', typeof value);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ px: 3, py: 2, borderBottom: BORDER_GRAY }}>
          <Typography>{header}</Typography>
        </Box>
        <Box sx={{ p: 3 }}>
          <VegaRadio
            radioData={radioData}
            value={value}
            name={name}
            labelPlacement={labelPlacement}
            color={color}
            onChange={onChange}
            isRow={false}
          />
        </Box>
        <Box sx={{ px: 3, py: 2, borderTop: BORDER_GRAY, textAlign: 'end' }}>
          <VegaContainedButton
            text="Submit"
            onClick={onSubmit}
            isPrimary
            disabled={value.length ? false : true}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default StatusModal;
