import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { VegaCard, VegaTable } from '../../../../../components/common';
import { ICurrencyProps } from './WalletConfiguration';
import { BLUE, BORDER_GRAY, GREY } from '../../../../../constants/style';
import { IPaginationProps } from '../../../pages/MCCPolicies';

interface IProps extends IPaginationProps {
  data: any;
  loading?: boolean;
}

const ListOfWalletConfig = ({
  data,
  loading = false,
  page,
  count,
  onWalletPageChange,
}: IProps) => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();

  const ProgramsListColumDef: GridColDef[] = [
    {
      field: 'policy_name',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Plan Name
        </Typography>
      ),

      flex: 0.5,
    },
    {
      field: 'id',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Waterfall Type
        </Typography>
      ),
      renderCell: (props: any) => {
        return props.row.waterfall_allowed ? 'Yes' : 'No';
      },
      flex: 0.5,
    },
    {
      field: 'wallet_list',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Currencies
        </Typography>
      ),
      flex: 2,
      renderCell: (props: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 1,
              border: BORDER_GRAY,
              borderRadius: '5px',
            }}
          >
            {props.row.wallet_list &&
              props.row.wallet_list.map((val: ICurrencyProps) => (
                <Box sx={{ position: 'relative' }}>
                  <Box
                    sx={{
                      ...(val.priority === 0
                        ? { bgcolor: BLUE.light, color: BLUE.lighter }
                        : { bgcolor: GREY.grey }),
                      px: 3,
                      py: 0.5,
                      borderRadius: '5px',
                      mx: 0.5,
                    }}
                  >
                    {val.currency}
                  </Box>
                  {/* {val.priority === 0 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bgcolor: BLUE.lighter,
                        color: 'white',
                        fontSize: '10px',
                      }}
                    >
                      Default
                    </Box>
                  )} */}
                </Box>
              ))}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <VegaCard noPad>
        <VegaTable
          columnDef={ProgramsListColumDef}
          data={data}
          idColumn="policy_id"
          loading={loading}
          onPageChange={onWalletPageChange}
          page={page}
          pageSize={10}
          rowCount={count}
          paginationMode="server"
        />
      </VegaCard>
    </>
  );
};

export default ListOfWalletConfig;
