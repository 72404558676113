import React, { useState } from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import Typography from '@mui/material/Typography';
import { VegaPage } from '../../common';
import { VPPRIMARY } from '../../../ui-components/theme';
import VegaTab from '../../common/VegaTab';
import DataTile from '../DataTile';
import { AnyPolicyType, PolicyStatus } from '../../../types/policy';
import ViewBillingPolicy from './ViewBillingPolicy';
import ViewMadTadPolicy from './ViewMadTadPolicy';
import ViewAccountPolicy from './ViewAccountPolicy';
import ViewInterestPolicy from './ViewInterestPolicy';
import ViewTaxPolicy from './ViewTaxPolicy';
import ViewRepaymentPolicy from './ViewRepaymentPolicy';
import ViewTransactionToEmiPolicy from './ViewTransactionToEmiPolicy';
import ViewTransactionLimitPolicy from './ViewTransactionLimitPolicy';
import ViewChargesPolicy from './ViewChargesPolicy';
import { useAppSelector } from '../../../store/hook';

type PolicyViewProps = {
  policy: AnyPolicyType;
  handleGoBack: () => void;
};

enum PolicyViewTabs {
  Ongoing = 'Ongoing Policies',
  Earlier = 'Earlier Policies',
  Upcoming = 'Upcoming Policies',
}

const getTitle = (policy_type: string | null): string => {
  if (!policy_type) {
    return '-';
  }
  switch (policy_type) {
    case 'MAD':
      return 'Mad Tad';
    case 'REPAYMENT_WATERFALL':
      return 'Repayment Waterfall';
    case 'TRANSACTION_TO_INSTALLMENT':
      return 'Transaction to EMI';
    case 'TRANSACTION':
      return 'Transaction Limit';
    case 'INTEREST':
      return 'Interest';
    case 'TAX':
      return 'Tax';
    case 'ACCOUNT':
      return 'Account';
    case 'BILLING':
      return 'Billing';
    case 'CHARGES':
      return 'Charges';
    default:
      return policy_type;
  }
};

function PolicyView({ policy, handleGoBack }: PolicyViewProps) {
  const [activeTab, setActiveTab] = useState(PolicyViewTabs.Ongoing);
  const title = getTitle(policy.policy_type);
  const selectedProgram = useAppSelector(
    (state) => state.programManagement.selectedProgram
  );

  const isApproved =
    [PolicyStatus.APPROVED, PolicyStatus.ACTIVE].includes(policy.status) &&
    !!selectedProgram?.checker;

  const onTabChange = (select: any) => {
    setActiveTab(select);
  };

  return (
    <VegaPage
      sx={{
        minHeight: '100vh',
        gap: 2,
      }}
    >
      <Box
        sx={{
          px: 3.25,
          bgcolor: 'white',
          pt: 3,
          pb: 1.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          borderBottom: '1px solid #EEE',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <IconButton size="large" onClick={handleGoBack}>
            <ArrowBackRounded />
          </IconButton>
          <Typography
            className="font-aspekta-600"
            sx={{
              color: '#1B1D22',
              fontSize: 17,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            gap: {
              xs: 2,
              sm: 1,
            },
            py: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              gap: {
                xs: 1,
                sm: 0,
              },
            }}
          >
            <DataTile
              title="Policy ID"
              value={policy.policy_id || '-'}
              hasSeparator
            />
            <DataTile
              title="Approved On"
              value={
                isApproved && policy.updated_at
                  ? dayjs(policy.updated_at).format('DD/MM/YYYY')
                  : '-'
              }
              hasSeparator
            />
            <DataTile
              title="Approved By"
              value={isApproved ? selectedProgram?.checker?.email : '-'}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: 3.25,
        }}
      >
        <VegaTab
          placement="vertical"
          color={VPPRIMARY.BLUE}
          tabData={Object.values(PolicyViewTabs)}
          selected={activeTab}
          onSelect={onTabChange}
          forceTextCase
        />
      </Box>
      <Box
        sx={{
          bgcolor: 'white',
          mx: 3.25,
          display: 'flex',
          borderRadius: 1.5,
          mb: { xs: 2, sm: 8 },
          flex: 1,
          border: '1px solid #EEE',
        }}
      >
        <ActivePolicyTab activeTab={activeTab} policy={policy} />
      </Box>
    </VegaPage>
  );
}

function ActivePolicyTab({
  activeTab,
  policy,
}: {
  activeTab: PolicyViewTabs;
  policy: AnyPolicyType;
}) {
  switch (policy.policy_type) {
    case 'BILLING':
      return <ViewBillingPolicy />;
    case 'MAD':
      return <ViewMadTadPolicy />;
    case 'ACCOUNT':
      return <ViewAccountPolicy />;
    case 'INTEREST':
      return <ViewInterestPolicy />;
    case 'TAX':
      return <ViewTaxPolicy />;
    case 'REPAYMENT_WATERFALL':
      return <ViewRepaymentPolicy />;
    case 'TRANSACTION_TO_INSTALLMENT':
      return <ViewTransactionToEmiPolicy />;
    case 'TRANSACTION':
      return <ViewTransactionLimitPolicy />;
    case 'CHARGES':
      return <ViewChargesPolicy />;
    default:
      break;
  }
  return null;
}
export default PolicyView;
