/** @format */

import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { BLUE, GREY } from '../../constants/style';
import { ThreeColInputFieldsColumnWidthType } from './ThreeColumnInputFields';

type Props = {
  headerTexts: string[];
  columnWidths: ThreeColInputFieldsColumnWidthType;
};

const ColumnsInputGreyHeader = ({ headerTexts, columnWidths }: Props) => {
  const baseArr =
    columnWidths.sm.length > columnWidths.xs.length
      ? columnWidths.sm
      : columnWidths.xs;
  const transformedColWidthsArray = baseArr.map((element, index) => {
    return {
      xs: columnWidths?.xs?.[index] ?? null,
      sm: columnWidths?.sm?.[index] ?? null,
    };
  });
  return (
    <Grid
      container
      spacing={0}
      p={1}
      bgcolor={BLUE.lightest}
      borderRadius={'5px'}
      mb={2}
    >
      {transformedColWidthsArray.map((width, index) => {
        return (
          <Grid item xs={width?.xs} sm={width?.sm}>
            <Typography style={{ color: BLUE.lighter }}>
              {_.startCase(_.toLower(headerTexts?.[index]))}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ColumnsInputGreyHeader;
