import { CatalogType } from '../providers/ReportsProvider';

export type ISelectedReportsData = {
  selectedCatalogType: keyof typeof CatalogType;
  selectedCategoryId: string;
  selectedReportId: string;
  selectedReportConfigId: string;
};

export type ICategory = {
  categoryId: string;
  programId: string;
  reportCategory: string;
  status: string;
  Icon: any;
  color: string;
};

export type IReportCategory = {
  categoryId: string;
  programId: string;
  reportCategory: string;
};

export enum ReportConfigStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ReportFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}
export enum ReportFormat {
  HTML = 'HTML',
  CSV = 'CSV',
  PDF = 'PDF',
}

export type IScheduleCategories = ICategory[];
export type IReportCatalogCategories = ICategory[];

export type IReportsContext = {
  reportCategories: Record<string, ICategory>;
  selectedCatalogType: keyof typeof CatalogType;
  selectedCategoryId: string;
  selectedReportId: string;
  selectedReportConfigId: string;

  setSelectedReportsData: any;

  updateSelectedReportsData: (
    key: keyof ISelectedReportsData,
    value: string
  ) => void;
};

export type IReport = {
  reportId: string;
  categoryId: string;
  reportName: string;
  createdBy: string;
  createdAt: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
};

export type IReportRun = {
  reportRunId: string;
  reportId: string;
  reportConfigId: string;
  status: 'COMPLETED' | 'PENDING' | 'FAILED';
  reportTime: string;
  generatedOn: string;
  reportLink: string;
};

export const tempReportConfigReponse = {
  reportConfigId: '2d755671-ad6f-46fb-a181-d37841492715',
  reportId: '03c724ee-beb6-4461-9d88-0c3a1c6ea202',
  createdBy: 'Ashok',
  createdAt: '2023-01-21T07:22:59.428+00:00',
  sampleReportLink: 'sample-csv-report-S3-link',
  status: ReportConfigStatus.ACTIVE,
  frequency: ReportFrequency.DAILY,
  dayOfWeek: 0,
  dayOfMonth: 0,
  hours: 5,
  minutes: 30,
  recipients: '',
  subject: 'subject',
  description: 'description',
  format: ReportFormat.CSV,
};

export type IReportConfig = typeof tempReportConfig;
export type IReportConfigReponse = typeof tempReportConfigReponse;

export interface IReportConfigRequest {
  reportConfigId: string;
  reportId: string;
  sampleReportLink: string;
  status: string;
  frequency: string;
  hours: number;
  minutes: number;
  recipients: string;
  subject: string;
  description: string;
  createdBy: string;
  format: string;
}

export interface ReportDto {
  reportId: string;
  categoryId: string;
  reportName: string;
  sampleReportUrl: string;
  createdBy: string;
  createdAt: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}

export interface ReportConfigDto {
  reportConfigId?: string;
  reportId: string;
  createdBy: string;
  createdAt?: string;
  updatedAt?: string;
  sampleReportLink: string;
  status: ReportConfigStatus;
  frequency: ReportFrequency;
  dayOfWeek: number;
  dayOfMonth: number;
  hours: number;
  minutes: number;
  recipients: string;
  subject: string;
  description: string;
  format: ReportFormat;
  eventId?: string;
}

export const tempReportConfig: ReportConfigDto = {
  reportId: '',
  createdBy: '',
  createdAt: '',
  sampleReportLink: '',
  status: ReportConfigStatus.ACTIVE,
  frequency: ReportFrequency.DAILY,
  dayOfWeek: 0,
  dayOfMonth: 0,
  hours: 5,
  minutes: 30,
  recipients: '',
  subject: '',
  description: '',
  format: ReportFormat.CSV,
  reportConfigId: '',
};
