import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import DonutsChart from '../../../components/common/VegaCharts/PieCharts/DonutsChart';
import { BLUE, GREEN, PURPLE } from '../../../constants/style';
import CorporateFilterSection from '../components/CorporateFilterSection';
import ChartsWrapper from '../../../components/common/VegaCharts/ChartsWrapper';
import ColumnCharts from '../../../components/common/VegaCharts/Bar/ColumnCharts';
import BarCharts from '../../../components/common/VegaCharts/Bar/BarCharts';
import MaxLimit from '../components/MaxLimit';

interface ISummaryProps {
  count: string | number;
  summaryType: string;
  border: string;
}

const CREDIT_SUMMARY = [
  {
    count: '50,000',
    summaryType: 'Total Credit Limit',
    border: PURPLE.dark,
  },
  {
    count: '20,000',
    summaryType: 'Available Credit Limit ',
    border: GREEN.main,
  },
  {
    count: '10,000',
    summaryType: 'Utilised Credit Limit ',
    border: BLUE.main,
  },
] as ISummaryProps[];

const CardAnalytics = () => {
  const [filterData, setFilterData] = useState<string>('');
  return (
    <VegaPage>
      <CorporateFilterSection
        state={filterData}
        handleChange={() => console.log('first')}
        programOption={['Ebix 1']}
      />
      <Grid container spacing={3}>
        {CREDIT_SUMMARY.map((summary: ISummaryProps) => (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box
              sx={{
                bgcolor: 'white',
                p: 3,
                borderRadius: '20px',
                borderRight: `39px solid ${summary.border}`,
              }}
            >
              <Typography>{summary.summaryType}</Typography>
              <Typography fontSize={30} fontWeight={'bold'} sx={{}}>
                {summary.count}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaCard>
            <DonutsChart
              series={[10000, 5000, 3000]}
              labelData={[
                'Total Credit limit in program1',
                'Total Credit limit in program2',
                'Total Credit limit in program3',
              ]}
              fillColor={['#E585A2', '#EDBC34', '#4A84C4']}
              position={'bottom'}
            />
          </VegaCard>
        </Grid>
        <Grid item container spacing={2} xs={12} sm={6} md={8} lg={8}>
          <Grid item xs={12} sm={12}>
            <VegaCard>
              <ColumnCharts
                series={[
                  {
                    name: 'Net Profit',
                    data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
                  },
                ]}
              />
            </VegaCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaCard>
              <MaxLimit bgColor="#4EAA9F" />
            </VegaCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaCard>
              <MaxLimit bgColor="#4E60AA" />
            </VegaCard>
          </Grid>
        </Grid>
      </Grid>
    </VegaPage>
  );
};

export default CardAnalytics;
