import { Box, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../services/ChargeBackService';
import {
  ChargebackStatus,
  RemarksType,
  UpdateChargebackStatusRequest,
} from '../../../types/chargeBack';
import { VPSelect, VPTextField } from '../../../ui-components/common';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useChargeBack } from '../hooks';
import { GenericModal } from './GenericModal';

interface IProps {
  onSubmit?: any;
  triggerLabel?: string;
}

export function CancelRequestModal({
  triggerLabel = 'Cancel Request',
}: IProps) {
  const {
    selectedChargeBack,
    setSelectedChargeBack,
    listChargeBackForProgram,
  } = useChargeBack();
  const { setSnackbar } = useSnackbar();
  const disputeReasonsQuery = useQuery({
    queryKey: ['disputes'],
    queryFn: () =>
      ChargebackService.listChargebackRejectionReason({
        pageNumber: 0,
        pageSize: 100,
      }),
    select: (data) => {
      return data.records.reduce((pre, curr) => {
        return [
          ...pre,
          {
            name: `${curr.reason} - (${curr.rejectionReasonCode})`,
            id: curr.id,
          },
        ];
      }, [] as any[]);
    },
  });

  const hookForm = useForm<UpdateChargebackStatusRequest>({
    defaultValues: {
      chargebackId: selectedChargeBack?.id,
      remarksType: RemarksType.REMARKS,
      status: ChargebackStatus.REQUEST_CANCELLED,
    },
  });

  const handleCancelChargeback = (data: UpdateChargebackStatusRequest) => {
    ChargebackService.updateChargebackStatus(data)
      .then((res) => {
        setSnackbar('Successfully cancelled chargeback', 'success');
        setSelectedChargeBack(res);
        listChargeBackForProgram.refetch();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Error while cancelling chargeback'),
          'error'
        );
      });
  };

  return (
    <>
      <GenericModal
        onSubmit={() => handleCancelChargeback(hookForm.getValues())}
        hasConfirmCTA
        triggerLabel={triggerLabel}
        header="Cancel Request"
        confirmCTAProps={{
          disabled: !hookForm.formState.isValid,
        }}
        confirmText="Cancel"
        hasCancelCTA={false}
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <Stack spacing={2}>
            <Controller
              name="rejectionReasonId"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <VPSelect
                  header="reason"
                  variant="outlined"
                  {...field}
                  placeholder="Select Reason"
                  disabled={disputeReasonsQuery.data?.length === 0}
                  options={disputeReasonsQuery.data || []}
                />
              )}
            />
            <Controller
              name="remarks"
              control={hookForm.control}
              render={({ field }) => (
                <VPTextField
                  header="remark (optional)"
                  variant="outlined"
                  {...field}
                  placeholder="Add Remark"
                />
              )}
            />
          </Stack>
        </Box>
      </GenericModal>
    </>
  );
}
