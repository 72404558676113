/** @format */

import { KeyboardArrowDown, Menu } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GREY } from '../../constants/style';
import { useAppBar } from '../../providers/AppBarProvider';
import { useClientAuth } from '../../providers/ClientProvider';
import {
  VegaFullScreenDialogState,
  useVegaFullScreenDialog,
} from '../../providers/VegaFullScreenDialogProvider';
import { DASHBOARD_ROUTES, ROUTES } from '../../routes';
import { useAppSelector } from '../../store/hook';
import { getState } from '../../store/common/stateSlice';
import VegapayLogo from '../icon/VegapayLogo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SectionHeadingTypo } from '../common/Typography';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: any;
  isSmallScreen: any;
  pageTitle?: string;
  backButtonRequired?: boolean;
};

const MainHeader = (props: Props) => {
  const { pathname } = useLocation();
  const { setAppBarHeight } = useAppBar();
  const { showLendingPage } = useAppSelector(getState);
  const navigate = useNavigate();
  const { visiblityState: dialogVisiblityState } = useVegaFullScreenDialog();
  const { setOpen, isSmallScreen, isMobile, pageTitle, backButtonRequired } =
    props;
  const navigation = useNavigate();
  const { client, logout, loggedInUserDetails, userDetails } = useClientAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const resetNavigator = () => {
    navigation(ROUTES.RESET_PASSWORD);
  };

  const getHeaderZIndex = () => {
    switch (dialogVisiblityState) {
      case VegaFullScreenDialogState.Visible:
        return 0;
    }
    return 1500;
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (headerRef.current) {
      const updatedHeight = headerRef.current.offsetHeight;
      setAppBarHeight(updatedHeight);
    }
  }, []);

  return (
    <Box>
      <Stack
        sx={{
          width: '-webkit-fill-available',
          bgcolor: 'background.paper',
          py: 2,
          px: 5,
          zIndex: getHeaderZIndex(),
          borderBottom: '4px solid #EBEBEB',
        }}
      >
        <Box
          ref={headerRef}
          sx={{
            display: 'flex',
            ...(isSmallScreen || isMobile
              ? { justifyContent: 'space-between' }
              : {
                  justifyContent:
                    showLendingPage && pathname === DASHBOARD_ROUTES.BACK_OFFICE
                      ? 'space-between'
                      : 'flex-end',
                }),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              ...(isSmallScreen || isMobile
                ? { justifyContent: 'space-between' }
                : {
                    justifyContent: showLendingPage
                      ? 'space-between'
                      : 'flex-end',
                  }),
            }}
          >
            {(isSmallScreen || isMobile) && !showLendingPage && (
              <IconButton onClick={() => setOpen((preVal: boolean) => !preVal)}>
                <Menu />
              </IconButton>
            )}
            {showLendingPage && pathname === DASHBOARD_ROUTES.BACK_OFFICE && (
              <VegapayLogo width="82" height="28" />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                // gap: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  columnGap: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ mr: 1, display: 'flex', flexDirection: 'column' }}
                  >
                    {loggedInUserDetails?.name ||
                      client?.clientName ||
                      'Hi User!'}
                    <Typography
                      variant="caption"
                      sx={{ color: GREY.grey }}
                      style={{ lineHeight: 1 }}
                    >
                      Last Login :{' '}
                      {loggedInUserDetails?.loginTime?.toLocaleString()}
                    </Typography>
                  </Typography>
                </Box>

                <KeyboardArrowDown color="primary" onClick={handleClick} />
              </Box>
            </Box>
          </Box>
        </Box>
        {(pageTitle || backButtonRequired) && (
          <>
            <Divider
              sx={{
                height: '1px',
                width: 'calc(100% + 80px)',
                margin: '12px -40px',
              }}
            />
            <Stack gap={1.5} direction="row" alignItems={'center'}>
              {backButtonRequired && (
                <Box
                  width={24}
                  height={24}
                  borderRadius={12}
                  sx={{
                    backgroundColor: '#F7F7F7',
                    display: 'grid',
                    placeContent: 'center',
                  }}
                  className="cursor-pointer"
                  onClick={goBack}
                >
                  <ArrowBackIcon sx={{ fontSize: '16px', color: '#7B8694' }} />
                </Box>
              )}
              {Boolean(pageTitle) && (
                <SectionHeadingTypo>{pageTitle}</SectionHeadingTypo>
              )}
            </Stack>
          </>
        )}
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ marginTop: 22 }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <Typography
            sx={{ fontSize: 14, paddingBottom: 1 }}
            borderBottom="0.4px solid #D1D1D1"
          >
            {loggedInUserDetails?.email}
          </Typography>
          <Typography sx={{ fontSize: 14, marginTop: 1 }}>
            Mob No:{' '}
            {loggedInUserDetails?.mobileNumber || userDetails?.mobileNumber}
          </Typography>
          <Stack spacing={1}>
            <Button
              sx={{
                marginTop: 2,
                minWidth: 220,
                backgroundColor: '#4785FF',
                color: 'white',
                fontWeight: 'bold',
              }}
              onClick={logout}
            >
              logout
            </Button>
            <Button variant="outlined" onClick={resetNavigator}>
              Reset Password
            </Button>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
};

export default MainHeader;
