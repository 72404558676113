import axios from 'axios';
import { BASE_URL, TRANSACTION } from '../api/endpoints';
import { GETWAY } from '../mcc/Back-office/api/endpoints';
import {
  IManualAdjustment,
  IManualAdjustmentListReq,
  IManualAdjustmentRequest,
  IUpdateManualAdjustmentReq,
} from '../mcc/types/ManualAdjustment';
import {
  IInitiateCheckerMakerReq,
  IUpdateCheckerMakerReq,
} from '../types/customers';

const BASE_ENDPOINT = `${BASE_URL}${GETWAY}${TRANSACTION.BASE}/manualAdjustment`;

enum ENDPOINTS {
  GET = '/{id}',
  CREATE = '',
  UPDATE = '',
  LIST = '/list',
  INITIATE_MAKER_CHECKER = '/makerChecker',
  UPDATE_MAKER_CHECKER = '/makerChecker',
}

class ManualAdjustmentService {
  static async getById(id: string): Promise<IManualAdjustment> {
    const endpoint = BASE_ENDPOINT + ENDPOINTS.GET.replace('{id}', id);
    const response = await axios.get(endpoint);
    const responseData = response.data;
    return responseData;
  }

  static async create(
    data: IManualAdjustmentRequest
  ): Promise<IManualAdjustment> {
    const endpoint = BASE_ENDPOINT + ENDPOINTS.CREATE;
    const response = await axios.post(endpoint, data);
    const responseData = response.data;
    return responseData;
  }

  static async update(
    data: Partial<IUpdateManualAdjustmentReq>
  ): Promise<IManualAdjustment> {
    const endpoint = BASE_ENDPOINT + ENDPOINTS.UPDATE;
    const response = await axios.patch(endpoint, data);
    const responseData = response.data;
    return responseData;
  }

  static async list(
    data: Partial<IManualAdjustmentListReq>
  ): Promise<Array<IManualAdjustment>> {
    const endpoint = BASE_ENDPOINT + ENDPOINTS.LIST;
    const response = await axios.post(endpoint, data);
    const responseData = response.data;
    return responseData;
  }

  static async initiateMakerChecker(
    data: IInitiateCheckerMakerReq
  ): Promise<IManualAdjustment> {
    const endpoint = BASE_ENDPOINT + ENDPOINTS.INITIATE_MAKER_CHECKER;
    const response = await axios.post(endpoint, data);
    const responseData = response.data;
    return responseData;
  }
  static async updateMakerChecker(
    data: IUpdateCheckerMakerReq
  ): Promise<IManualAdjustment> {
    const endpoint = BASE_ENDPOINT + ENDPOINTS.INITIATE_MAKER_CHECKER;
    const response = await axios.patch(endpoint, data);
    const responseData = response.data;
    return responseData;
  }
}

export default ManualAdjustmentService;
