/* eslint-disable react/jsx-pascal-case */
import { ArrowForward } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import Vega_Text from '../../components/common/v3/Vega_Text';
import { Support_Icon } from '../../components/icon/BackOfficeIcon';
import { SidebarListItemType } from '../../components/layouts/SidebarLayout';
import { useAppDispatch } from '../../store/hook';
import { setShowLendingPage } from '../../store/common/stateSlice';

interface IProps {
  route: SidebarListItemType;
  onSelect: (route: SidebarListItemType) => void;
}

const BackOfficeRouteCard = ({ route, onSelect }: IProps) => {
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        borderRadius: '6px',
        px: 2,
        py: 4,
        mb: 2,
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
        position: 'relative',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        ':hover': {
          transform: 'scale(1.01)',
        },
      }}
      onClick={() => {
        onSelect(route);
        dispatch(setShowLendingPage(false));
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          {route.icon}
          <Vega_Text
            text={route.label}
            color={'#061F62'}
            fontWeight={500}
            fontSize={24}
          />
        </Stack>
        <ArrowForward sx={{ color: '#DB7600' }} />
      </Stack>
      <Vega_Text
        text={
          'CRM Access to view and support customer queries, raise tickets and manage customer relation'
        }
        color={'#676B76'}
        fontWeight={450}
        fontSize={12}
        sx={{
          py: 2,
        }}
      />
      <Stack direction={'row'} gap={1} sx={{}}>
        <Box
          sx={{
            borderRadius: '14px',
            bgcolor: '#DB76001A',
            px: 2,
            py: 1,
          }}
        >
          <Vega_Text
            text={'Search by Customer'}
            color={'#DB7600'}
            fontWeight={600}
            fontSize={8}
            sx={{ letterSpacing: '0.5px' }}
          />
        </Box>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          bottom: -8,
        }}
      >
        <img
          src={require('../../assets/images/BackOfficeCardVector.png')}
          alt=""
        />
      </Box>
    </Box>
  );
};

export default BackOfficeRouteCard;
