import axios from 'axios';
import { BASE_URL, GETWAY, PLANS } from './endpoints';

const PLAN_BASE_URL = `${BASE_URL}${PLANS.BASE}`;

export const addPlanAllocation = (data: any) => {
  return axios.post(`${PLAN_BASE_URL}/allocation`, data);
};
export const fetchALlPlanAllocation = (clientId: string) => {
  return axios.get(`${PLAN_BASE_URL}/allocation/list/client/${clientId}`);
};
