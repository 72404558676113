import { Box, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../services/ChargeBackService';
import { FileArbitrationRequest } from '../../../types/chargeBack';
import { VPTextField, VegaUploadNPreview } from '../../../ui-components/common';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import VegaHeader from '../../common/VegaHeader';
import { useChargeBack } from '../hooks';
import { GenericModal } from './GenericModal';

interface IProps {}

export function FileArbitrageModal(props: IProps) {
  const { selectedChargeBack, setSelectedChargeBack } = useChargeBack();

  const hookForm = useForm<FileArbitrationRequest>({
    defaultValues: {
      chargebackId: selectedChargeBack?.id,
      disputeAmount: 0,
      remarks: '',
      arbitrationProofs: [],
    },
  });

  const { setSnackbar } = useSnackbar();

  const onSubmit = () => {
    const data: FileArbitrationRequest = hookForm.getValues();
    ChargebackService.fileArbitration(data)
      .then((res) => {
        setSnackbar('Successfully filed arbitration', 'success');
        setSelectedChargeBack(res);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Error while filing arbitration'),
          'error'
        );
      });
  };

  return (
    <>
      <GenericModal
        triggerLabel="File Arbitrage"
        triggerProps={{
          variant: 'contained',
        }}
        header="File Arbitrage"
        onSubmit={onSubmit}
        hasConfirmCTA
        confirmText="Submit"
        confirmCTAProps={{
          disabled: !hookForm.formState.isValid,
        }}
        hasCancelCTA={false}
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <Stack spacing={2}>
            <Controller
              name="disputeAmount"
              control={hookForm.control}
              rules={{
                required: 'Required',
                max: selectedChargeBack?.disputeAmount,
              }}
              render={({ field }) => (
                <Stack>
                  <VPTextField
                    header="NEW dISPUTE AMOUNT"
                    variant="outlined"
                    {...field}
                    placeholder="Enter Dispute Amount"
                  />
                  <Stack textAlign={'end'} mt={0.5}>
                    <Typography>
                      {`Max Amount: ${selectedChargeBack?.disputeAmount}`}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            />
            <Controller
              name="remarks"
              control={hookForm.control}
              render={({ field }) => (
                <Stack>
                  <VPTextField
                    header="Rermarks"
                    variant="outlined"
                    {...field}
                    minRows={3}
                    maxRows={3}
                    placeholder="Add Remark"
                  />
                </Stack>
              )}
            />
            <Controller
              name="arbitrationProofs"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Box>
                  <VegaHeader text="Upload a document, if any" />
                  <VegaUploadNPreview
                    value={field.value || []}
                    onChange={field.onChange}
                    preview2
                    max={5}
                    accept={{
                      'application/pdf': ['.pdf'],
                    }}
                  />
                </Box>
              )}
            />
          </Stack>
        </Box>
      </GenericModal>
    </>
  );
}
