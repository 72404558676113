/* eslint-disable react/jsx-pascal-case */
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { useClientAuth } from '../../../providers/ClientProvider';
import { EntityType } from '../../../types/client';
import CIMS_Button from '../../components/common/CIMS_Button';
import CIMS_Header from '../../components/common/CIMS_Header';
import { CardOrderRequestProvider } from '../../modules/CardOrderRequest';
import CardOrderRequestList from '../../modules/CardOrderRequest/List/CardOrderRequestList';
import { RequestCardModal } from '../../modules/CardOrderRequest/Modals';
import { DivisionTypes } from '../../../types/division';

function CardOrderRequest() {
  const { userDivisionType } = useClientAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const canRequestCards =
    userDivisionType === DivisionTypes.BUSINESS_CORRESPONDENT ||
    userDivisionType === DivisionTypes.BC_AGENT;

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div>
      <CIMS_Header
        text="Card Order Request"
        component={
          canRequestCards ? (
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <CIMS_Button text="Request cards" onClick={handleOpenDrawer} />
            </Stack>
          ) : undefined
        }
      />
      <CardOrderRequestProvider>
        <RequestCardModal isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
        <CardOrderRequestList />
      </CardOrderRequestProvider>
    </div>
  );
}

export default CardOrderRequest;
