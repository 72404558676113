import { VerifiedOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import Agreement from '../components/ApplicationManagment/Agreement/Agreement';
import ApplicationManagTabs from '../components/ApplicationManagment/ApplicationManagTabs';
import ApprovalProcess from '../components/ApplicationManagment/ApprovalProcess/ApprovalProcess';
import KYCflow from '../components/ApplicationManagment/KYCflow/KYCflow';
import LoanEMIAgreement from '../components/ApplicationManagment/LoanEMIAgreement/LoanEMIAgreement';
import SanctionLatterFormat from '../components/ApplicationManagment/SanctionLatterFormat/SanctionLatterFormat';
import { VegaPage } from '../components/common';
import { VegaHorizontalSelectorDataType } from '../components/common/VegaHorizontalSelector';
import { BLUE, GREEN, ORANGE, PINK, PURPLE, RED } from '../constants/style';
import { useProgramData } from '../providers/ProgramDataProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { VegaDocument, VegaProgramDocumentType } from '../types/program';
import StatementPasswordSetup from './StatementPasswordSetup';

export interface IDataProps {
  data: VegaHorizontalSelectorDataType[];
  active: VegaHorizontalSelectorDataType;
  setActiveState: (e: any) => void;
}

enum TabId {
  KYC_FLOW = 'KYC_FLOW',
  AGREEMENT = 'AGREEMENT',
  SANCTION_LETTER_FORMAT = 'SANCTION_LETTER_FORMAT',
  LOAN_EMI_AGREEMENT = 'LOAN_EMI_AGREEMENT',
  APPROVAL_PROCESS = 'APPROVAL_PROCESS',
  STATEMENT_PASSWORD = 'STATEMENT_PASSWORD',
}

let tileData = [
  {
    text: 'KYC Flow',
    id: TabId.KYC_FLOW,
    image: <VerifiedOutlined />,
    bgColor: BLUE.light,
  },
  {
    text: 'Agreement',
    id: TabId.AGREEMENT,
    image: <VerifiedOutlined />,
    bgColor: PINK.light,
  },
  {
    text: 'Sanction Letter Format',
    id: TabId.SANCTION_LETTER_FORMAT,
    image: <VerifiedOutlined />,
    bgColor: ORANGE.light,
  },
  {
    text: 'Loan EMI Agreement',
    id: TabId.LOAN_EMI_AGREEMENT,
    image: <VerifiedOutlined />,
    bgColor: RED.light,
  },
  {
    text: 'Approval process',
    id: TabId.APPROVAL_PROCESS,
    image: <VerifiedOutlined />,
    bgColor: GREEN.light,
  },
  {
    text: 'Statement Password',
    id: TabId.STATEMENT_PASSWORD,
    image: <VerifiedOutlined />,
    bgColor: PURPLE.main,
  },
];

const ApplicationManagment = () => {
  const { setSnackbar } = useSnackbar();
  const {
    selectedProgram,
    updateAgreementTwo,
    updateSanctionLetter,
    updateLoanEMIAgreement,
    deleteAgreementTwo,
    deleteSanctionLetter,
    deleteLoanEMIAgreement,
    isLoading,
    getFormatDocumentUrl,
    getDefaultFormatDocumentUrl,
  } = useProgramData();
  const [activeState, setActiveState] = useState<any>(tileData[0]);
  const [agreementFiles, setAgreementFiles] = useState<VegaDocument[] | null>(
    null
  );
  const [sanctionLetterFiles, setSanctionLetterFiles] = useState<
    VegaDocument[] | null
  >(null);
  const [loanEMIAgreementFiles, setLoanEMIAgreementFiles] = useState<
    VegaDocument[] | null
  >(null);

  function showUploadButtonForAgreement() {
    const isUploading = isLoading == true;
    const hasExistingAgreement = agreementFiles && !agreementFiles[0].file;
    if (hasExistingAgreement || isUploading) {
      return false;
    }
    return true;
  }

  function showUploadButtonForSanctionLetter() {
    const isUploading = isLoading == true;
    const hasExistingSanctionLetter =
      sanctionLetterFiles && !sanctionLetterFiles[0].file;
    if (hasExistingSanctionLetter || isUploading) {
      return false;
    }
    return true;
  }

  function showUploadButtonForLoanEMIAgreement() {
    const isUploading = isLoading == true;
    const hasExistingLoanEMIAgreement =
      loanEMIAgreementFiles && !loanEMIAgreementFiles[0].file;
    if (hasExistingLoanEMIAgreement || isUploading) {
      return false;
    }
    return true;
  }

  const populateExistingLoanEMIAgreements = () => {
    const existingAgreement = selectedProgram?.agreementTwo;

    if (existingAgreement) {
      setAgreementFiles([{ name: `${selectedProgram.name} - Agreement 1` }]);
    } else {
      setAgreementFiles(null);
    }
  };

  const populateExistingAgreements = () => {
    const existingAgreement = selectedProgram?.loanEmiAgreement;

    if (existingAgreement) {
      setLoanEMIAgreementFiles([
        { name: `${selectedProgram.name} - Loan EMI Agreement 1` },
      ]);
    } else {
      setLoanEMIAgreementFiles(null);
    }
  };

  const populateExistingSanctionLetters = () => {
    const existingSanctionLetter = selectedProgram?.sanctionLetterFormat;

    if (existingSanctionLetter) {
      setSanctionLetterFiles([
        { name: `${selectedProgram.name} - Sanction Letter 1` },
      ]);
    } else {
      setSanctionLetterFiles(null);
    }
  };

  const convertToVegaDocuments = (files: File[] | null) => {
    if (files) {
      const documents: VegaDocument[] = files.map((item: File) => {
        return {
          name: item.name,
          file: item,
        };
      });

      return documents;
    }

    return null;
  };

  function _updateSanctionLetter() {
    const programId = selectedProgram?.programId;

    if (
      !programId ||
      !sanctionLetterFiles ||
      sanctionLetterFiles.length <= 0 ||
      !sanctionLetterFiles[0].file
    ) {
      return;
    }

    updateSanctionLetter({
      programId: programId,
      file: sanctionLetterFiles[0].file,
    });
  }

  function _updateAgreementFiles() {
    const programId = selectedProgram?.programId;

    if (
      !programId ||
      !agreementFiles ||
      agreementFiles.length <= 0 ||
      !agreementFiles[0].file
    ) {
      return;
    }

    updateAgreementTwo({
      programId: programId,
      file: agreementFiles[0].file,
    });
  }

  function _updateLoanEMIAgreement() {
    const programId = selectedProgram?.programId;

    if (
      !programId ||
      !loanEMIAgreementFiles ||
      loanEMIAgreementFiles.length <= 0 ||
      !loanEMIAgreementFiles[0].file
    ) {
      return;
    }

    updateLoanEMIAgreement({
      programId: programId,
      file: loanEMIAgreementFiles[0].file,
    });
  }

  function _deleteAgreementFiles() {
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    deleteAgreementTwo({ programId: programId });
  }

  function _deleteSanctionLetter() {
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    deleteSanctionLetter({ programId: programId });
  }

  function _deleteLoanEMIAgreement() {
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    deleteLoanEMIAgreement({ programId: programId });
  }

  async function downloadFormatDocument(data: {
    documentType: VegaProgramDocumentType;
    defaultDocument: boolean;
  }) {
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    var documentUrl = '';

    if (data.defaultDocument) {
      const responseData = await getDefaultFormatDocumentUrl(data.documentType);
      documentUrl = responseData.documentUrl;
    } else {
      const responseData = await getFormatDocumentUrl({
        documentType: data.documentType,
        programId: programId,
      });
      documentUrl = responseData.documentUrl;
    }

    if (documentUrl) {
      window.open(documentUrl, '__blank__');
    }
  }

  useEffect(() => {
    populateExistingAgreements();
    populateExistingLoanEMIAgreements();
    populateExistingSanctionLetters();
  }, [selectedProgram]);

  return (
    <div>
      <VegaPage>
        <ApplicationManagTabs
          data={tileData}
          setActiveState={setActiveState}
          active={activeState}
        />
        {activeState.id === TabId.AGREEMENT ? (
          <Agreement
            agreementFiles={agreementFiles}
            onFilesSelected={(files) =>
              setAgreementFiles(convertToVegaDocuments(files))
            }
            showLoader={isLoading}
            showUploadButtonForFileCard={
              showUploadButtonForAgreement() ?? false
            }
            onCancelFileButtonClick={() => _deleteAgreementFiles()}
            onUploadFileButtonClick={() => _updateAgreementFiles()}
            onDownloadFormatButtonClick={() => {}}
            showDownloadFormatButton={true}
            onDownloadFileButtonClick={() => {
              downloadFormatDocument({
                defaultDocument: false,
                documentType: VegaProgramDocumentType.AGREEMENT_TWO,
              });
            }}
            onFileSelectedError={(message) => {
              setSnackbar(message ?? 'Something went wrong', 'error');
              setAgreementFiles(null);
            }}
          />
        ) : activeState.id === TabId.SANCTION_LETTER_FORMAT ? (
          <SanctionLatterFormat
            sanctionLetterFiles={sanctionLetterFiles}
            onFilesSelected={(files) =>
              setSanctionLetterFiles(convertToVegaDocuments(files))
            }
            showLoaderForFileCard={isLoading}
            showDownloadFormatButton={true}
            showUploadButtonForFileCard={showUploadButtonForSanctionLetter()}
            onCancelFileButtonClick={() => _deleteSanctionLetter()}
            onUploadFileButtonClick={() => _updateSanctionLetter()}
            onDownloadFormatButtonClick={() =>
              downloadFormatDocument({
                defaultDocument: true,
                documentType: VegaProgramDocumentType.SANCTION_LETTER,
              })
            }
            onDownloadFileButtonClick={() =>
              downloadFormatDocument({
                defaultDocument: false,
                documentType: VegaProgramDocumentType.SANCTION_LETTER,
              })
            }
            onFileSelectedError={(message) => {
              setSnackbar(message ?? 'Something went wrong', 'error');
              setSanctionLetterFiles(null);
            }}
          />
        ) : activeState.id === TabId.LOAN_EMI_AGREEMENT ? (
          <LoanEMIAgreement
            sanctionLetterFiles={loanEMIAgreementFiles}
            onFilesSelected={(files) =>
              setLoanEMIAgreementFiles(convertToVegaDocuments(files))
            }
            showLoaderForFileCard={isLoading}
            showDownloadFormatButton={true}
            showUploadButtonForFileCard={showUploadButtonForLoanEMIAgreement()}
            onCancelFileButtonClick={() => _deleteLoanEMIAgreement()}
            onUploadFileButtonClick={() => _updateLoanEMIAgreement()}
            onDownloadFormatButtonClick={() =>
              downloadFormatDocument({
                defaultDocument: true,
                documentType: VegaProgramDocumentType.LOAN_EMI_AGREEMENT,
              })
            }
            onDownloadFileButtonClick={() =>
              downloadFormatDocument({
                defaultDocument: false,
                documentType: VegaProgramDocumentType.LOAN_EMI_AGREEMENT,
              })
            }
            onFileSelectedError={(message) => {
              setSnackbar(message ?? 'Something went wrong', 'error');
              setLoanEMIAgreementFiles(null);
            }}
          />
        ) : activeState.id === TabId.APPROVAL_PROCESS ? (
          <ApprovalProcess />
        ) : activeState.id === TabId.KYC_FLOW ? (
          <KYCflow />
        ) : (
          <StatementPasswordSetup
            programId={selectedProgram?.programId}
            onSampleDownloadClick={() => {
              downloadFormatDocument({
                defaultDocument: true,
                documentType: VegaProgramDocumentType.BILL_STATEMENT,
              });
            }}
          />
        )}
      </VegaPage>
    </div>
  );
};

export default ApplicationManagment;
