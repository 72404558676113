import { KeyboardArrowDown } from '@mui/icons-material';
import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Stack,
} from '@mui/material';
import _ from 'lodash';
import { COMMON } from '../../../constants/style';

export type ISelectOptionType = {
  id: string;
  name: string;
  icon?: JSX.Element;
};

interface ISelectProps extends SelectProps {
  options: string[] | ISelectOptionType[];
}

const Vega_Select = ({ options, ...rest }: ISelectProps) => {
  return (
    <>
      <FormControl sx={rest.sx}>
        <Select
          {...rest}
          fullWidth
          displayEmpty
          style={{
            borderRadius: '7px',
            color: '#AFAFAF',
            height: rest.multiple ? 'auto' : '35px',
          }}
          IconComponent={KeyboardArrowDown}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: '#C5C8D0',
            },
            '& .MuiSvgIcon-root': {
              width: '1rem',
              height: '1rem',
              right: '0.625rem',
              top: '0.5625rem',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: '#C5C8D0',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: '#C5C8D0',
            },
          }}
        >
          {options &&
            options.map((option, index) => (
              <MenuItem
                key={index}
                value={typeof option === 'string' ? option : option.id}
              >
                <Stack
                  direction={'row'}
                  gap={2}
                  color={COMMON.gray}
                  alignItems="center"
                  className="font-aspekta-500"
                  fontSize={12}
                >
                  {typeof option !== 'string' && option.icon && option.icon}
                  {typeof option === 'string'
                    ? _.startCase(_.toLower(option))
                    : _.startCase(_.toLower(option.name))}
                </Stack>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Vega_Select;
