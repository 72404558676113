export const GAZZETTED_HOLIDAYS_MOCK_DATA = [
  {
    date: '01 January 2023',
    name: "New Year's Day",
    id: 0,
    description:
      'Arunachal Pradesh, Meghalaya, Manipur, Mizoram, Nagaland, Puducherry, Rajasthan, Sikkim, Telangana & Tamil Nadu',
  },
  {
    date: '02 January 2023',
    name: 'New Year Holiday',
    id: 1,
    description: 'Mizoram',
  },
  {
    date: '04 January ',
    name: 'Odisha Day',
    id: 2,
    description: 'Odisha',
  },
  {
    date: '05 January 2023',
    name: 'Guru Gobind Singh Jayanti',
    id: 3,
    description: 'Chandigarh & Haryana',
  },
  {
    date: '11 January 2023',
    name: 'Missionary Day',
    id: 4,
    description: 'Mizoram',
  },
  {
    date: '12 January 2023',
    name: 'Swami Vivekananda Jayanti',
    id: 5,
    description: 'West Bengal',
  },
  {
    date: '13 January 2023',
    name: 'Boghi',
    id: 6,
    description:
      'Tamil Nadu, Andhra Pradesh, Telangana, Karnataka and Maharashtra',
  },
  {
    date: '14 January 2023',
    name: 'Makara Sankranti',
    id: 7,
    description: 'Gujarat, Karnataka, Sikkim, Telangana & Tamil Nadu',
  },
  {
    date: '15 January 2023',
    name: 'Magh Bihu',
    id: 8,
    description: 'Assam',
  },
  {
    date: '15 January 2023',
    name: 'Surya Pongal',
    id: 9,
    description: 'Andhra Pradesh, Arunachal Pradesh, Puducherry, Tamil Nadu',
  },
  {
    date: '15 January 2023',
    name: 'Thiruvalluvar Day',
    id: 10,
    description: 'Tamil Nadu',
  },
  {
    date: '16 January 2023',
    name: 'Mattu Pongal',
    id: 11,
    description: 'Tamil Nadu',
  },
  {
    date: '16 January 2023',
    name: 'Uzhavar Thirunal',
    id: 12,
    description: 'Puducherry, & Tamil Nadu',
  },
  {
    date: '17 January 2023',
    name: 'Kaanum Pongal',
    id: 13,
    description: 'Tamil Nadu',
  },
  {
    date: '22 January 2023',
    name: 'Sonam Losar',
    id: 14,
    description: 'Sikkim',
  },
  {
    date: '23 January 23',
    name: 'Netaji Subhas Chandra Bose Jayanti',
    id: 15,
    description: 'Odisha, Tripura & West Bengal',
  },
  {
    date: '25 January 2023',
    name: 'State Day',
    id: 16,
    description: 'Himachal Pradesh',
  },
  {
    date: '26 January 2023',
    name: 'Republic Day',
    id: 17,
    description: 'National',
  },
  {
    date: '26 January 2023',
    name: 'Vasant Panchami',
    id: 18,
    description: 'Haryana, Odisha, Punjab, Tripura & West Bengal',
  },
  {
    date: '31 January 2023',
    name: 'Me-Dum-Me-Phi',
    id: 19,
    description: 'Assam',
  },
  {
    date: '02 February 2023',
    name: 'Sonam Losar',
    id: 20,
    description: 'Sikkim',
  },
  {
    date: '05 February 2023',
    name: 'Hazrat Ali Jayanti',
    id: 21,
    description: 'Uttar Pradesh',
  },
  {
    date: '18 February 2023',
    name: 'Maha Shivaratri',
    id: 22,
    description:
      ' Andaman and Nicobar Islands, Arunachal Pradesh, Assam, Bihar, Goa, Lakshadweep, Madhya Pradesh, Manipur, Meghalaya, Mizoram, Nagaland, Puducherry, Sikkim, Tamil Nadu & West Bengal',
  },
  {
    date: '20 February 2023',
    name: 'State Day',
    id: 23,
    description: 'Arunachal Pradesh & Mizoram',
  },
  {
    date: '21 Feb 2023',
    name: 'Losar',
    id: 24,
    description: 'Sikkim',
  },
  {
    date: '05 March 2023',
    name: 'Panchayatiraj Divas',
    id: 25,
    description: 'Odisha',
  },
  {
    date: '08 March 2023',
    name: 'Holi',
    id: 26,
    description:
      'Delhi, Karnataka, Kerala, Lakshadweep, Manipur, Puducherry, Tamil Nadu & West Bengal',
  },
  {
    date: '10 March 2023',
    name: 'Chhatrapati Shivaji Maharaj Jayanti',
    id: 27,
    description: 'Maharashtra',
  },
  { date: '18 March 2023', name: 'Holi', id: 28, description: 'Delhi' },
  {
    date: '18 March 2023',
    name: 'Doljatra',
    id: 29,
    description: 'West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'Bihar Day',
    id: 30,
    description: 'Bihar',
  },
  {
    date: '22 March 2023',
    name: 'Gudi Padwa',
    id: 31,
    description: 'Daman and Diu, DN & Maharashtra',
  },
  {
    date: '22 March 2023',
    name: 'Ugadi',
    id: 32,
    description:
      'Andhra Pradesh, Goa, Gujarat, Jharkhand, Karnataka, Rajasthan & Telangana',
  },
  {
    date: '22 March 2023',
    name: "S. Bhagat Singh's Martyrdom Day",
    id: 33,
    description: 'Haryana',
  },
  {
    date: '22 March 2023',
    name: 'Sarhul',
    id: 34,
    description: 'Jharkhand',
  },
  {
    date: '22 March 2023',
    name: 'Ram Navami',
    id: 35,
    description:
      'Andaman and Nicobar Islands, Andhra Pradesh, Bihar, Chandigarh, Daman and Diu, DN, Gujarat, Himachal Pradesh, Haryana, Maharashtra, Madhya Pradesh, Odisha, Punjab, Rajasthan, Sikkim, Telangana, Uttarakhand & Uttar Pradesh',
  },
  {
    date: '04 February 2023',
    name: 'Telugu New Year',
    id: 36,
    description: 'Tamil Nadu',
  },
  {
    date: '04 April 2023',
    name: 'Mahavir Jayanti',
    id: 37,
    description:
      'Chandigarh, Chandigarh, Daman and Diu, Delhi, DN, Gujarat, Haryana, Jharkhand, Karnataka, Lakshadweep, Maharashtra, Madhya Pradesh, Punjab, Rajasthan, Tamil Nadu & Uttar Pradesh',
  },
  {
    date: '04 May 2023',
    name: 'Babu Jagjivan Ram Jayanti',
    id: 38,
    description: 'Andhra Pradesh & Telangana',
  },
  {
    date: '04 July 2023',
    name: 'Good Friday',
    id: 39,
    description: 'Haryana, Jharkhand',
  },
  {
    date: '04 August 2023',
    name: 'Easter Saturday',
    id: 40,
    description: 'Nagaland',
  },
  {
    date: '04 September 2023',
    name: 'Easter Sunday',
    id: 41,
    description: 'Kerala, Nagaland',
  },
  {
    date: '22 March 2023',
    name: 'Bohag Bihu Holiday',
    id: 42,
    description: 'Assam',
  },
  {
    date: '22 March 2023',
    name: 'Vaisakh',
    id: 43,
    description: 'Jharkhand, Punjab',
  },
  {
    date: '22 March 2023',
    name: 'Dr Ambedkar Jayanti',
    id: 44,
    description:
      'Andhra Pradesh, Arunachal Pradesh, Bihar, Chhattisgarh, Goa, Gujarat, Haryana, Himachal Pradesh, Jammu & kashmir, Jharkhand, Karnataka, Kerala, Madhya Pradesh, Odisha, Punjab, Rajasthan, Sikkim, Tamil Nadu, Telangana, Uttarakhand, Uttar Pradesh & West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'TamilTamil New Year',
    id: 45,
    description: 'Tamil Nadu',
  },
  {
    date: '22 March 2023',
    name: 'Maha Vishuba Sankranti',
    id: 46,
    description: 'Odisha',
  },
  {
    date: '22 March 2023',
    name: 'Bohag Bihu',
    id: 47,
    description: 'Arunachal Pradesh, Assam',
  },
  {
    date: '22 March 2023',
    name: 'Cheiraoba',
    id: 48,
    description: 'Manipur',
  },
  {
    date: '22 March 2023',
    name: 'Vishu',
    id: 49,
    description: 'Kerala',
  },
  {
    date: '22 March 2023',
    name: 'Bengali New Year',
    id: 50,
    description: 'Tripura & West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'Himachal Day',
    id: 51,
    description: 'Himachal Pradesh',
  },
  {
    date: '22 March 2023',
    name: 'Patriots Day',
    id: 52,
    description: 'Manipur',
  },
  {
    date: '22 March 2023',
    name: 'Garia Puja',
    id: 53,
    description: 'Tripura',
  },
  {
    date: '22 March 2023',
    name: 'Maharshi Parasuram Jayanti',
    id: 54,
    description:
      'Gujarat, Himachal Pradesh, Haryana, Madhya Pradesh & Rajasthan',
  },
  {
    date: '22 March 2023',
    name: 'Idul Fitr',
    id: 55,
    description: 'National',
  },
  {
    date: '22 March 2023',
    name: 'Basava Jayanti',
    id: 56,
    description: 'Karnataka',
  },
  {
    date: '22 March 2023',
    name: 'Shab-i-Qadr',
    id: 57,
    description: 'Jharkhand',
  },
  {
    date: '22 March 2023',
    name: 'Jumat-ul-Wida',
    id: 58,
    description: 'Jharkhand',
  },
  {
    date: '05 January 2023',
    name: 'Maharashtra Day',
    id: 59,
    description: 'Maharashtra',
  },
  {
    date: '05 January 2023',
    name: 'May Day',
    id: 60,
    description:
      'Assam, Bihar, Goa, Karnataka, Kerala, Manipur, Puducherry, Telangana, Tamil Nadu, Tripura & West Bengal',
  },
  {
    date: '05 February 2023',
    name: 'Guru Ravidas Jayanti',
    id: 61,
    description: 'Himachal Pradesh, Haryana & Punjab',
  },
  {
    date: '05 May 2023',
    name: 'Buddha Purnima',
    id: 62,
    description:
      'Andaman and Nicobar Islands, Arunachal Pradesh, Chandigarh, Delhi, Himachal Pradesh, HaryanaR, Jharkhand, Jharkhand, Maharashtra, Madhya Pradesh, Mizoram, Tripura, Uttarakhand, Uttar Pradesh & West Bengal',
  },
  {
    date: '05 April 2023',
    name: 'Idul Fitr Holiday',
    id: 63,
    description: 'Telangana',
  },
  {
    date: '22 March 2023',
    name: 'State Day',
    id: 64,
    description: 'Sikkim',
  },
  {
    date: '22 March 2023',
    name: 'Maharana Pratap Jayanti',
    id: 65,
    description: 'Himachal Pradesh, Haryana & Rajasthan',
  },
  {
    date: '22 March 2023',
    name: 'Kazi Nazrul Islam Jayanti',
    id: 66,
    description: 'Tripura',
  },
  {
    date: '06 February 2023',
    name: 'Telangana Formation Day',
    id: 67,
    description: 'Telangana',
  },
  {
    date: '06 March 2023',
    name: "Sri Guru Arjun Dev Ji's Martyrdom Day",
    id: 68,
    description: 'Punjab',
  },
  {
    date: '06 April 2023',
    name: 'Sant Guru Kabir Jayanti',
    id: 69,
    description: 'Chandigarh, Himachal Pradesh, Haryana & Punjab',
  },
  {
    date: '22 March 2023',
    name: 'Pahili Raja',
    id: 70,
    description: 'Odisha',
  },
  {
    date: '22 March 2023',
    name: 'Raja Sankranti',
    id: 71,
    description: 'Odisha',
  },
  {
    date: '22 March 2023',
    name: 'YMA Day',
    id: 72,
    description: 'Mizoram',
  },
  {
    date: '22 March 2023',
    name: 'Ratha Yathra',
    id: 73,
    description: 'Odisha',
  },
  {
    date: '22 March 2023',
    name: 'Bonalu',
    id: 74,
    description: 'Telangana',
  },
  {
    date: '22 March 2023',
    name: 'Kharchi Puja',
    id: 75,
    description: 'Tripura',
  },
  {
    date: '22 March 2023',
    name: 'Bakrid / Eid al Adha',
    id: 76,
    description:
      'Andhra Pradesh, Andaman and Nicobar Islands, Arunachala Pradesh, Assam, Bihar, Chandigarh, Chhattisgarh, Delhi, Goa, Gujarat, Haryana, Himachal Pradesh, Jammu & kashmir, Jharkhand, Karnataka, Kerala, Lakshadweep, Madhya Pradesh, Manipur, Meghalaya, Mizoram, Nagaland, Odisha, Punjab, Rajasthan, Tamil Nadu, Telangana, Tripura, Uttarakhand, Uttar Pradesh & West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'Remna Ni',
    id: 77,
    description: 'Mizoram',
  },
  {
    date: '01 January 1970',
    name: "Guru Hargobind Ji's Birthday",
    id: 78,
    description: 'Jharkhand',
  },
  {
    date: '07 June 2023',
    name: 'MHIP Day',
    id: 79,
    description: 'Mizoram',
  },
  {
    date: '07 November 2023',
    name: 'Bakrid / Eid al Adha Holiday',
    id: 80,
    description: 'Jharkhand',
  },
  {
    date: '22 March 2023',
    name: "Martyrs' Day",
    id: 81,
    description: 'Jharkhand',
  },
  {
    date: '22 March 2023',
    name: 'Bhanu Jayanti',
    id: 82,
    description: 'Sikkim',
  },
  {
    date: '22 March 2023',
    name: 'U Tirot Sing Day',
    id: 83,
    description: 'Meghalaya',
  },
  {
    date: '22 March 2023',
    name: 'Muharram',
    id: 84,
    description:
      'Andaman and Nicobar, Andhra Pradesh, Bihar, Chandigarh, Chhattisgarh, Delhi, Gujarat, Haryana, Himachal Pradesh, Jammu & kashmir, Jharkhand, Karnataka, Lakshadweep, Madhya Pradesh, Maharashtra, Odisha, Rajasthan, Tamil Nadu, Telangana, Tripura & Uttar Pradesh.',
  },
  {
    date: '22 March 2023',
    name: "Shaheed Udham Singh's Martyrdom Day",
    id: 85,
    description: 'Haryana',
  },
  {
    date: '08 August 2023',
    name: 'Tendong Lho Rum Faat',
    id: 86,
    description: 'Sikkim',
  },
  {
    date: '22 March 2023',
    name: 'Patriots Day',
    id: 87,
    description: 'Manipur',
  },
  {
    date: '22 March 2023',
    name: 'Independence Day',
    id: 88,
    description: 'National',
  },
  {
    date: '22 March 2023',
    name: 'De Jure Transfer Day',
    id: 89,
    description: 'Puducherry',
  },
  {
    date: '22 March 2023',
    name: 'Parsi New Year',
    id: 90,
    description: 'Daman and Diu, Dadra and Nagar Haveli, Gujarat & Maharashtra',
  },
  {
    date: '22 March 2023',
    name: 'First Onam',
    id: 91,
    description: 'Kerala',
  },
  {
    date: '22 March 2023',
    name: 'Thiruvonam',
    id: 92,
    description: 'Kerala',
  },
  {
    date: '22 March 2023',
    name: 'Parkash Utsav Sri Guru Granth Sahib Ji',
    id: 93,
    description: 'Punjab',
  },
  {
    date: '22 March 2023',
    name: 'Hartalika Teej',
    id: 94,
    description: 'Chandigarh & Sikkim',
  },
  {
    date: '22 March 2023',
    name: 'Jhulan Purnima',
    id: 95,
    description: 'Odisha',
  },
  {
    date: '22 March 2023',
    name: 'Raksha Bandhan',
    id: 96,
    description:
      'Chandigarh, Daman and Diu, Gujarat, Haryana, Madhya Pradesh, Rajasthan, Uttarakhand & Uttar Pradesh',
  },
  {
    date: '09 January 2023',
    name: 'Ganesh Chaturthi Holiday',
    id: 97,
    description: 'Goa',
  },
  {
    date: '09 May 2023',
    name: 'Guru Rabindranath Jayanti',
    id: 98,
    description: 'Tripura & West Bengal',
  },
  {
    date: '09 July 2023',
    name: 'Janmashtami',
    id: 99,
    description:
      'Andaman and Nicobar, Andhra Pradesh, Bihar, Chandigarh, Chhattisgarh, Daman And Diu, Delhi, Gujarat, Haryana, Himachal Pradesh, Jammu & kashmir, Jharkhand, Madhya Pradesh, Meghalaya, Nagaland, Odisha, Punjab, Rajasthan, Sikkim, Tamil Nadu, Telangana, Tripura, Uttarakhand & Uttar Pradesh',
  },
  {
    date: '22 March 2023',
    name: 'Haryali Teej',
    id: 100,
    description: 'Haryana',
  },
  {
    date: '22 March 2023',
    name: 'Ganesh Chaturthi',
    id: 101,
    description:
      'Andhra Pradesh, Daman and Diu, Dadra and Nagar Haveli, Goa, Gujarat, Karnataka, Maharashtra, Odisha, Puducherry, Telangana & Tamil Nadu',
  },
  {
    date: '22 March 2023',
    name: 'Nuakhai',
    id: 102,
    description: 'Odisha',
  },
  {
    date: '22 March 2023',
    name: 'Sree Narayana Guru Samadhi',
    id: 103,
    description: 'Kerala',
  },
  {
    date: '22 March 2023',
    name: "Heroes' Martyrdom Day",
    id: 104,
    description: 'Haryana',
  },
  {
    date: '22 March 2023',
    name: 'Ramdev Jayanti',
    id: 105,
    description: 'Rajasthan',
  },
  {
    date: '22 March 2023',
    name: 'Teja Dashmi',
    id: 106,
    description: 'Rajasthan',
  },
  {
    date: '22 March 2023',
    name: 'Indra Jatra',
    id: 107,
    description: 'Sikkim',
  },
  {
    date: '22 March 2023',
    name: 'S. Bhagat Singh Ji Jayanti',
    id: 108,
    description: 'Punjab',
  },
  {
    date: '22 March 2023',
    name: 'Eid e Milad',
    id: 109,
    description:
      'Andaman and Nicobar, AR, Assam, Bihar, CH, Goa, Himachal Pradesh, Manipur, Meghalaya, Mizoram, Punjab, SK & West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'Sree Narayana Guru Jayanti',
    id: 110,
    description: 'Kerala',
  },
  {
    date: '10 February 2023',
    name: 'Gandhi Jayanti',
    id: 111,
    description: 'National',
  },
  {
    date: '10 September 2023',
    name: 'Lakshmi Puja',
    id: 112,
    description: 'Odisha, Tripura & West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'Friday Following Eid e Milad',
    id: 113,
    description: 'Jharkhand',
  },
  {
    date: '22 March 2023',
    name: 'First Day of Bathukamma',
    id: 114,
    description: 'Telangana',
  },
  {
    date: '22 March 2023',
    name: 'Mahalaya Amavasye',
    id: 115,
    description: 'Karnataka, Odisha, Tripura & West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'Maharaja Agrasen Jayanti',
    id: 116,
    description: 'Haryana',
  },
  {
    date: '22 March 2023',
    name: 'Ghatasthapana',
    id: 117,
    description: 'Rajasthan',
  },
  {
    date: '22 March 2023',
    name: 'Maha Saptami',
    id: 118,
    description: 'Odisha, Sikkim, Tripura & West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'Vijaya Dashami',
    id: 119,
    description:
      'Andaman and Nicobar, Andhra Pradesh, Arunachal Pradesh, Assam, Bihar, Chandigarh, Chhattisgarh, Daman and Diu, Delhi, Goa, Gujarat, Haryana, Himachal Pradesh, Jammu & kashmir, Jharkhand, Madhya Pradesh, Meghalaya, Nagaland, Odisha, Punjab, Rajasthan, Sikkim, Tamil Nadu, Telangana, Tripura, Uttarakhand, Uttar Pradesh & West Bengal',
  },
  {
    date: '22 March 2023',
    name: 'Maharishi Valmiki Jayanti',
    id: 120,
    description:
      'CH, Himachal Pradesh, Haryana, Karnataka, Madhya Pradesh & Punjab',
  },
  {
    date: '22 March 2023',
    name: 'Deepavali Holiday',
    id: 121,
    description:
      'Daman and Diu, Haryana, Karnataka, Maharashtra, Rajasthan, Uttarakhand & Uttar Pradesh',
  },
  {
    date: '22 March 2023',
    name: 'Sardar Vallabhbhai Patel Jayanti',
    id: 122,
    description: 'Gujarat',
  },
  {
    date: '11 January 2023',
    name: 'Kut',
    id: 123,
    description: 'Manipur',
  },
  {
    date: '11 January 2023',
    name: 'Puducherry Liberation Day',
    id: 124,
    description: 'Puducherry',
  },
  {
    date: '11 January 2023',
    name: 'Haryana Day',
    id: 125,
    description: 'Haryana',
  },
  {
    date: '11 January 2023',
    name: 'Kannada Rajyothsava',
    id: 126,
    description: 'Karnataka',
  },
  {
    date: '11 April 2023',
    name: 'Lhabab Duchen',
    id: 127,
    description: 'Sikkim',
  },
  {
    date: '11 December 2023',
    name: 'Diwali',
    id: 128,
    description:
      'Andhra Pradesh, Delhi, Goa, Karnataka, Kerala, Puducherry, Telangana & Tamil Nadu',
  },
  {
    date: '22 March 2023',
    name: 'Diwali',
    id: 129,
    description:
      'Andaman and Nicobar, Arunachal Pradesh, Assam, Bihar, Chandigarh, Chhattisgarh, Daman and Diu, Dadra and Nagar Haveli, Gujarat, Haryana, Himachal Pradesh, Jammu & kashmir, Jharkhand, Madhya Pradesh, Manipur, Meghalaya, Mizoram, Nagaland, Odisha, Punjab, Rajasthan, Sikkim, Telangana, Tripura, Uttarakhand, Uttar Pradesh & West Bengal.',
  },
  {
    date: '22 March 2023',
    name: 'Vikram Samvat New Year',
    id: 130,
    description: 'Gujarat',
  },
  {
    date: '22 March 2023',
    name: 'Bhai Dooj',
    id: 131,
    description: 'Gujarat, Rajasthan, SK, Uttarakhand & Uttar Pradesh',
  },
  {
    date: '22 March 2023',
    name: 'Chhath Puja',
    id: 132,
    description: 'Bihar, Chandigarh & Jharkhand',
  },
  {
    date: '22 March 2023',
    name: 'Chhath Puja Holiday',
    id: 133,
    description: 'Bihar',
  },
  {
    date: '22 March 2023',
    name: 'Seng Kut Snem',
    id: 134,
    description: 'Meghalaya',
  },
  {
    date: '22 March 2023',
    name: 'Karthika Purnima',
    id: 135,
    description: 'Odisha & Telangana',
  },
  {
    date: '22 March 2023',
    name: 'Guru Nanak Jayanti',
    id: 136,
    description:
      'Andaman and Nicobar, Arunachal Pradesh, Assam, Chandigarh, Chhattisgarh, Delhi, Gujarat, Haryana, Himachal Pradesh, Jammu & kashmir, Jharkhand, Madhya Pradesh, Nagaland, Punjab, Rajasthan, Telangana, Uttarakhand, Uttar Pradesh & West Bengal.',
  },
  {
    date: '22 March 2023',
    name: "Sri Guru Teg Bahadur Ji's Martyrdom Day",
    id: 137,
    description: 'Punjab',
  },
  {
    date: '22 March 2023',
    name: 'Kanakadasa Jayanti',
    id: 138,
    description: 'Karnataka',
  },
  {
    date: '12 January 2023',
    name: 'Indigenous Faith Day',
    id: 139,
    description: 'Arunachal Pradesh',
  },
  {
    date: '12 March 2023',
    name: 'Feast of St Francis Xavier',
    id: 140,
    description: 'Goa',
  },
  {
    date: '12 May 2023',
    name: 'Sheikh Muhammad Abdullah Jayanti',
    id: 141,
    description: 'Jharkhand',
  },
  {
    date: '12 December 2023',
    name: 'Pa Togan Nengminza Sangma',
    id: 142,
    description: 'Meghalaya',
  },
  {
    date: '22 March 2023',
    name: 'Death Anniversary of U SoSo Tham',
    id: 143,
    description: 'Meghalaya',
  },
  {
    date: '22 March 2023',
    name: 'Guru Ghasidas Jayanti',
    id: 144,
    description: 'Chandigarh',
  },
  {
    date: '22 March 2023',
    name: 'Liberation Day',
    id: 145,
    description: 'Daman and Diu & Goa',
  },
  {
    date: '22 March 2023',
    name: 'Christmas Holiday',
    id: 146,
    description: 'Meghalaya & Mizoram',
  },
  {
    date: '22 March 2023',
    name: 'Christmas Day',
    id: 147,
    description: 'National',
  },
  {
    date: '22 March 2023',
    name: 'Shaheed Udham Singh Jayanti',
    id: 148,
    description: 'Haryana',
  },
  {
    date: '22 March 2023',
    name: 'Christmas Holiday',
    id: 149,
    description: 'Meghalaya, Mizoram & Telangana',
  },
  {
    date: '22 March 2023',
    name: 'Guru Gobind Singh Jayanti',
    id: 150,
    description: 'Meghalaya & Haryana',
  },
  {
    date: '22 March 2023',
    name: 'U Kiang Nangbah',
    id: 151,
    description: 'Meghalaya',
  },
  {
    date: '22 March 2023',
    name: 'Tamu Losar',
    id: 152,
    description: 'Sikkim',
  },
  {
    date: '22 March 2023',
    name: "New Year's Eve",
    id: 153,
    description: 'Manipur',
  },
];
