/** @format */

import ListOfAccounts from '../components/accounts/ListOfAccounts';
import { VegaPage } from '../components/common';

type Props = {};
const Accounts = (props: Props) => {
  return (
    <div>
      <VegaPage>
        {/* <FilterAccount /> */}
        <ListOfAccounts />
      </VegaPage>
    </div>
  );
};

export default Accounts;
