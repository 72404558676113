/** @format */

import { Box, Grid } from '@mui/material';
import { ChargeRuleInterface, ChargeRuleInterfaceKeyTypes, ChargeRuleInterfaceValueTypes } from '../../types/policy';
import VegaOutLineTextField from '../common/VegaOutLineTextField';
import VegaSelect from '../common/VegaSelect';
import VegaText from '../common/VegaText';
import { EVENT, TAXATION_TYPES } from './ChargePolicyCard';

type Props = {
  code: string;
  chargeRule: ChargeRuleInterface;
  onChange: (key: ChargeRuleInterfaceKeyTypes, value: ChargeRuleInterfaceValueTypes) => void;
  disabled?: boolean;
};

const OneTimeCharges = ({ code, chargeRule, onChange, disabled }: Props) => {
  const handleChargeChange = (key: string, value: string | number) => {
    let newCharge = { ...chargeRule?.charge, [key]: value };
    onChange('charge', newCharge);
  };

  return (
    <Box sx={{ mx: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <VegaText text="Amount(INR)" mr={2} />
          <VegaOutLineTextField
            value={chargeRule.charge.value ?? ''}
            key="charge-one-time-amount"
            textFieldWidth={200}
            onChange={(e) => handleChargeChange('value', e.target.value && parseFloat(e.target.value))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <VegaText text="Tax type" mr={2} />
          <VegaSelect
            options={TAXATION_TYPES}
            selected={chargeRule.taxation_type}
            onChange={(value: string) => onChange('taxation_type', value)}
            textFieldWidth={200}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <VegaText text="Event" mr={2} />
          <VegaSelect
            options={EVENT}
            selected={chargeRule.deduction_type ?? ''}
            onChange={(value: string) => onChange('deduction_type', value)}
            textFieldWidth={200}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OneTimeCharges;
