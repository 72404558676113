import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { VPAccordion } from '../../../ui-components/common';
import Grid from '@mui/material/Grid';
import DueDateCalendar from '../DueDateCalendar';
import { useAppSelector } from '../../../store/hook';

function PaymentDueDate() {
  const { selectedProgram } = useAppSelector(
    (state) => state.programManagement
  );
  const pddList = selectedProgram?.pddList ?? [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Typography className="font-aspekta-600" fontSize={17}>
        Payment Due Date
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <VPAccordion title="Due Dates" expandable={false}>
            <VPAccordion.Body>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <DueDateCalendar selectDate={pddList} />
              </Box>
            </VPAccordion.Body>
          </VPAccordion>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,

              borderRadius: '8px',
              bgcolor: '#FAFAFA',
              gap: 2,
            })}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 1.5,
                bgcolor: '#EBEDEF',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
            >
              <Typography
                className="font-aspekta-500"
                sx={{
                  fontSize: 14,
                  color: '#1B1D22',
                }}
              >
                Due Dates
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
              }}
            >
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                {pddList.map((date, index) => (
                  <li key={index}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: '#1B1D22',
                      }}
                    >{`${date} of every month`}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentDueDate;
