import { Box, Grid } from '@mui/material';
import { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { VegaTextField } from '../../../../components/common';
import { BORDER_GRAY } from '../../../../constants/style';
import {
  VALIDATE_REGEX,
  ValidationRules,
} from '../../../../utils/validation.utils';
import { IAddUserRequest } from '../../api/roles';

type IUser = {
  name: string;
  mobileNumber: string;
  email: string;
  designation: string;
};

type IProps = {
  initialValue?: IAddUserRequest;
};

function CreateUser({ initialValue }: IProps) {
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext<{ user: IUser }>();

  useEffect(() => {
    if (initialValue) {
      setValue('user.name', initialValue.name);
      setValue('user.email', initialValue.email);
      setValue('user.mobileNumber', initialValue.mobileNumber);
      setValue('user.designation', initialValue.designation || 'Manager');
    }
  }, [initialValue]);

  useEffect(() => {
    trigger();
  }, []);

  return (
    <Box sx={{ p: 3, pt: 0 }}>
      <Box
        component="form"
        // onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          spacing={2}
          mt={1}
          sx={{
            borderBottom: BORDER_GRAY,
            pb: 3,
          }}
        >
          <Grid item xs={12}>
            <Controller
              name="user.name"
              control={control}
              defaultValue=""
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="NAME *"
                  variant="outlined"
                  error={!!errors.user?.name}
                  helperText={errors.user?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="user.email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Email is required',
                pattern: {
                  value: VALIDATE_REGEX.EMAIL,
                  message: 'Not a valid email',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="EMAIL - ID *"
                  variant="outlined"
                  error={!!errors.user?.email}
                  helperText={errors.user?.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="user.mobileNumber"
              control={control}
              defaultValue=""
              rules={{
                required: 'Mobile number is required',
                pattern: {
                  value: /^\d{10}$/,
                  message: 'Mobile number should be 10 digits',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="MOBILE NO. *"
                  variant="outlined"
                  error={!!errors.user?.mobileNumber}
                  helperText={errors.user?.mobileNumber?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="user.designation"
              control={control}
              defaultValue=""
              rules={{
                required: ValidationRules.required,
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="DESIGNATION *"
                  variant="outlined"
                  error={!!errors.user?.designation}
                  helperText={errors.user?.designation?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default memo(CreateUser);
