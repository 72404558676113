import { Stack } from '@mui/material';
import _ from 'lodash';
import { TaxPolicyInterface } from '../../../types/policy';
import { IRowData, PolicySummaryTable } from './common/SummaryTable';

interface Props {
  policy?: TaxPolicyInterface | null;
}

function TaxPolicySummary({ policy }: Props) {
  const getSummaryRows = () => {
    const summaryRows: IRowData[] = [];
    if (!policy) return [];
    const taxes = policy.tax_components.flatMap((components) => {
      const taxItemSummary = components.taxes.map((taxItem) => {
        return [
          taxItem.name,
          _.startCase(_.toLower(taxItem.value_type)),
          taxItem.value.toString(),
        ];
      });
      return taxItemSummary;
    });

    taxes.forEach((item) => {
      summaryRows.push({ rowData: item });
    });
    return summaryRows;
  };

  return (
    <Stack spacing={2}>
      <PolicySummaryTable
        headers={['Position Type', 'Interest Rate Value Type', ' Default APR']}
        rows={getSummaryRows()}
      />
    </Stack>
  );
}

export default TaxPolicySummary;
