import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { CIMS_PRIMARY } from '../../assets/style';
// import { SBM_logo } from '../icon/CIMS_SidebarIcons';
import { VegaPayLogo } from '../../../components/icon/Icons';
import ForgotPassword from './ForgotPassword';
import LoginContainer from './LoginContainer';
import NewPassword from './NewPassword';
import { SbmLogo } from '../icon/CIMS_Icons';

const CIMS_Login = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState<string>('');
  const [stateRoute, setStateRoute] = useState('LOGIN');

  const forgotPasswordHandler = (route: string) => {
    // setEmail('');
    setStateRoute(route);
  };

  return (
    <div>
      <Grid
        container
        style={{
          maxHeight: '100vh',
          height: '100vh',
        }}
      >
        <Grid
          sx={{
            bgcolor: CIMS_PRIMARY.MAIN,
            ...(isMobile && { display: 'none' }),
            position: 'relative',
          }}
          item
          xs={0}
          sm={6}
          lg={6}
          md={6}
        >
          <Box
            px={4.5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              position: 'relative',
            }}
          >
            <Box
              p={4.5}
              sx={{
                bgcolor: CIMS_PRIMARY.MAIN,
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <SbmLogo />
            </Box>
            <Box>
              <Typography
                fontSize={48}
                className="font-aspekta-300"
                color={'white'}
              >
                Welcome to
              </Typography>
              <Typography
                fontSize={48}
                className="font-aspekta-700"
                color={CIMS_PRIMARY.GOLD}
              >
                SBM Bank Card Management Host
              </Typography>
              <Box
                sx={{
                  width: '52px',
                  height: '3px',
                  bgcolor: 'white',
                  my: 3,
                }}
              ></Box>
              <Typography
                fontSize={14}
                className="font-aspekta-500"
                color={'white'}
              >
                Card Inventory Management System
              </Typography>
            </Box>
            <Stack
              direction={'row'}
              gap={3}
              p={4.5}
              sx={{
                bgcolor: CIMS_PRIMARY.MAIN,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            >
              <Typography
                fontSize={8}
                className="font-aspekta-600"
                color={'white'}
                letterSpacing={'1px'}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                terms and conditions
              </Typography>
              <Typography
                fontSize={8}
                className="font-aspekta-600"
                color={'white'}
                letterSpacing={'1px'}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                privacy policy
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          style={{
            background: '#F6F9FF',
            position: 'relative',
          }}
        >
          {stateRoute === 'FORGOT' ? (
            <ForgotPassword
              forgotPasswordHandler={forgotPasswordHandler}
              email={email}
              setEmail={setEmail}
            />
          ) : stateRoute === 'NEW_PASSWORD' ? (
            <NewPassword
              forgotPasswordHandler={forgotPasswordHandler}
              email={email}
              setEmail={setEmail}
            />
          ) : (
            <LoginContainer forgotPasswordHandler={forgotPasswordHandler} />
          )}
          {isMobile && (
            <Box
              p={4.5}
              sx={{
                bgcolor: CIMS_PRIMARY.MAIN,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              }}
            >
              <VegaPayLogo />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CIMS_Login;
