import React, { useState } from 'react';
import { VegaPage } from '../../../components/common';
import MCCFilterSection from '../components/MCCUserManagement/MCCFilterSection';
import CustomerDetails from '../components/MCCServices/CustomerDetails';
import ListOfServices from '../components/MCCServices/ListOfServices';

const DATA = [
  {
    userId: 'ABCD1234',
    customerName: 'Ronit Bhandari',
    programName: '6123557235253',
    sourceCurrency: 'USD',
    services: 'INR',
    amount: '10000',
    requestDate: '12 Aug 2023 | 02:30 PM',
    id: 1,
  },
  {
    userId: 'ABCD1235',
    customerName: 'Puneet Sharma',
    programName: '6123557235253',
    sourceCurrency: 'USD',
    services: 'CAN',
    amount: '10000',
    requestDate: '12 Aug 2023 | 02:30 PM',
    id: 2,
  },
];

const MCCServices = () => {
  const [filterData, setFilterData] = useState<string>('');

  const handleChange = (value: string) => {
    setFilterData(value);
  };
  return (
    <VegaPage>
      <MCCFilterSection state={filterData} handleChange={handleChange} />
      <CustomerDetails state={filterData} handleChange={handleChange} />
      <ListOfServices data={DATA} />
    </VegaPage>
  );
};

export default MCCServices;
