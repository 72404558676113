/** @format */

import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { usePolicy } from '../../providers/PolicyProvider';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import {
  PolicyComponentType,
  PolicyType,
  TaxPolicyInterface,
} from '../../types/policy';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import AccountInputRows from './AccountInputRows';
import ColumnsInputGreyHeader from './ColumnsInputGreyHeader';
import PolicyCard from './PolicyCard';
import PolicyCommentCard from './PolicyCommentCard';
import { PolicyWrapper } from './PolicyWrapper';
import { DEFAULT_COL_WIDTHS } from './ThreeColumnInputFields';

const HEADERS = ['Tax Type', 'Value Type', 'Value'];
const TaxPolicy = ({
  initialValue,
  onSubmit,
  isLoading,
  onApprove,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    entityAuthStatuses,
    checkPolicyStatusUpdatable,
    checkPolicyEditable,
    loggedInMakerCheckerUser,
    addCommentOnPolicy,
    updateCommentOnPolicy,
    areAllPoliciesApproved,
    getDefaultPolicy,
  } = usePolicy();
  const [policy, setPolicy] = useState<TaxPolicyInterface | null>();
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );

  const handleChange = (value: any) => {
    if (!policy || !policy.tax_components || !policy.tax_components[0].taxes) {
      return;
    }

    const updatedPolicy = { ...policy };

    if (
      updatedPolicy.tax_components.length > 0 &&
      updatedPolicy.tax_components[0].taxes.length > 0
    ) {
      updatedPolicy.tax_components[0].taxes[0].value = value;
      setPolicy(updatedPolicy);
    }
  };

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = policy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(policyId);
    const canUpdateStatus = await checkPolicyStatusUpdatable(policyId);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  function didSubmit() {
    if (policy) {
      onSubmit(policy);
    }
  }

  function didClickApprove() {
    if (policy) onApprove(policy);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  function fetchDefaultPolicy() {
    getDefaultPolicy(PolicyType.TAX)
      .then((defaultPolicy) => {
        if (defaultPolicy) {
          const taxPolicy = defaultPolicy as TaxPolicyInterface;
          setPolicy(taxPolicy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (initialValue) {
      const value = initialValue as TaxPolicyInterface;
      setPolicy(value);
    } else {
      fetchDefaultPolicy();
    }
  }, [initialValue]);

  useEffect(() => {
    getEntityStatusAndCheckIfCanEdit();
  }, [policy, loggedInMakerCheckerUser]);

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={policy?.policy_id}
      policyView={
        <Box>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                onPastCommentClick={() => {
                  onPastCommentsClick(policy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
            ifFalse={undefined}
          />

          <PolicyCard
            policy={initialValue}
            onSaveClick={didSubmit}
            isLoading={isLoading}
            isSaveDisabled={canUpdatePolicyStatus == false}
            onPastCommentsClick={onPastCommentsClick}
            onApproveClick={didClickApprove}
            viewFor={loggedInMakerCheckerUser?.role}
            onCommentAddClick={addCommentOnEntity}
            policyEntityAuth={entityAuthStatus}
            onCommentEdit={updateEntityComment}
          >
            <Typography mb={3}>Tax Configuration</Typography>
            <ColumnsInputGreyHeader
              headerTexts={HEADERS}
              columnWidths={DEFAULT_COL_WIDTHS}
            />
            <AccountInputRows
              label="GST"
              valueType={policy?.tax_components[0].taxes[0].value_type}
              value={policy?.tax_components[0].taxes[0].value}
              onChange={(key, value) => handleChange(value)}
              disabled={canEditPolicy == false}
            />
          </PolicyCard>
        </Box>
      }
    />
  );
};

export default TaxPolicy;
