import React from 'react';
import AgentSummary from '../components/AgentView/AgentSummary';
import ListOfAgent from '../components/AgentView/ListOfAgent';
import { VegaPage } from '../components/common';

export interface ISummaryListProps {
  id: number;
  customerId: string;
  customerName: string;
  lastCall: string;
  nextCall: string;
  ODBalance: number;
  DPDBucket: string;
}

const AGENT_SUMMARY = [
  {
    count: '440',
    summaryType: 'Total Loans',
  },
  {
    count: '65,00,00',
    summaryType: 'Total Loan Amount',
  },
  {
    count: '5,2,3483',
    summaryType: 'Total Loans',
  },
  {
    count: '3,23,635',
    summaryType: 'Total Loans',
  },
];

const SUMMARY_LIST = [
  {
    id: 1,
    customerId: 'ABCD1234',
    customerName: 'Alexandra Bauch',
    lastCall: '14 Jan 2023 | 02:23 PM',
    nextCall: '14 Jan 2023 | 02:23 PM',
    ODBalance: 2000,
    DPDBucket: '0-29',
  },
  {
    id: 2,
    customerId: 'ABCD1234',
    customerName: 'Alexandra Bauch',
    lastCall: '14 Jan 2023 | 02:23 PM',
    nextCall: '14 Jan 2023 | 02:23 PM',
    ODBalance: 2000,
    DPDBucket: '30-59',
  },
  {
    id: 3,
    customerId: 'ABCD1234',
    customerName: 'Alexandra Bauch',
    lastCall: '14 Jan 2023 | 02:23 PM',
    nextCall: '14 Jan 2023 | 02:23 PM',
    ODBalance: 2000,
    DPDBucket: '60-89',
  },
  {
    id: 4,
    customerId: 'ABCD1234',
    customerName: 'Alexandra Bauch',
    lastCall: '14 Jan 2023 | 02:23 PM',
    nextCall: '14 Jan 2023 | 02:23 PM',
    ODBalance: 2000,
    DPDBucket: '38.35',
  },
  {
    id: 5,
    customerId: 'ABCD1234',
    customerName: 'Alexandra Bauch',
    lastCall: '14 Jan 2023 | 02:23 PM',
    nextCall: '14 Jan 2023 | 02:23 PM',
    ODBalance: 2000,
    DPDBucket: '0-29',
  },
] as ISummaryListProps[];

const AgentView = () => {
  return (
    <VegaPage>
      <AgentSummary data={AGENT_SUMMARY} />
      <ListOfAgent data={SUMMARY_LIST} />
    </VegaPage>
  );
};

export default AgentView;
