/* eslint-disable react/jsx-pascal-case */
import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPresignedUrl } from '../../../api/card';
import { VegaToolTipAndCopy } from '../../../components/common/VegaToolTipNCopy';
import { toLowerCase } from '../../../constants/commonFunction';
import { useClientAuth } from '../../../providers/ClientProvider';
import { CardPrintOrderDto, CardPrintingStatus } from '../../../types/card';
import { downloadFileFromURI } from '../../../utils/file';
import { CardAllocationService } from '../../apis';
import { CIMS_PRIMARY } from '../../assets/style';
import CIMS_DataGrid from '../../components/common/CIMS_DataGrid';
import CIMS_Text from '../../components/common/CIMS_Text';
import CimsButton from '../../components/common/CimsButton';
import { Typography } from '@mui/material';

interface IProps {
  setRefetchList: any;
}
const ListOfUpdateCardInventory = ({ setRefetchList }: IProps) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(0);

  const { clientId } = useClientAuth();
  // fetch print list
  const printListQuery = useQuery({
    queryKey: ['print_list', { page, pageSize }],
    queryFn: () => {
      return CardAllocationService.listPrintOrder({
        clientId: clientId || '',
        page: page,
        size: pageSize,
        populateBin: true,
        populateProgram: true,
      });
    },
    keepPreviousData: true,
  });

  const COLUMN_DEF: GridColumns<CardPrintOrderDto> = [
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => {
        return dayjs(props.row.createdAt).format('DD/MM/YYYY');
      },
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    },
    {
      field: 'id',
      headerName: 'Request Id',
      flex: 1.2,
      minWidth: 120,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    },
    {
      field: 'binValue',
      headerName: 'Bin Value',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => props.row.binDto?.binValue || '--',
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 1.8,
      minWidth: 180,
      valueGetter: (props) => props.row.programDto?.name || '--',
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'reportFileName',
      headerName: 'File Name',
      flex: 2.5,
      minWidth: 250,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    },
    {
      field: 'totalKits',
      headerName: 'Total Entries',
      flex: 1,
      minWidth: 140,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : 0,
    },

    {
      field: 'successUpload',
      headerName: 'Success',

      flex: 1,
      minWidth: 100,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : 0,
    },
    {
      field: 'failureUpload',
      headerName: 'Failure',
      flex: 1,
      minWidth: 100,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : 0,
    },
    {
      field: 'createdBy',
      headerName: 'Uploaded By',
      flex: 1.8,
      minWidth: 180,
      renderCell: (props) =>
        props.value ? <VegaToolTipAndCopy isCopy value={props.value} /> : '-',
    },
    // {
    //   field: 'sourceFile',
    //   headerName: 'Source File',
    //   flex: 1.5,
    //   minWidth: 150,
    //   renderCell: (props) => {
    //     return (
    //       <CimsButton
    //         onClick={async () => {
    //           const url = await getPresignedUrl(props.row.embossingFileUrl);
    //           downloadFileFromURI(url.data, 'embossing_file');
    //         }}
    //         variant="text"
    //       >
    //         Source File
    //       </CimsButton>
    //     );
    //   },
    // },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => {
        return props.row.printingStatus === CardPrintingStatus.UPLOADED ? (
          <CimsButton
            onClick={async () => {
              const url = await getPresignedUrl(props.row.reportFileUrl);
              downloadFileFromURI(url.data, 'report_file');
            }}
            variant="soft"
          >
            Download
          </CimsButton>
        ) : (
          <CIMS_Text
            text={toLowerCase(props.row.printingStatus)}
            color={CIMS_PRIMARY.ORANGE}
          />
        );
      },
    },
  ];
  useEffect(() => {
    setRefetchList(() => printListQuery.refetch);

    return () => {
      setRefetchList(null);
    };
  }, []);

  return (
    <>
      <CIMS_DataGrid
        data={printListQuery.data?.records || []}
        columns={COLUMN_DEF}
        idColumn={'id'}
        loading={printListQuery?.isLoading || printListQuery.isPreviousData}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        page={page}
        onPageChange={(page) => {
          setPage(page);
        }}
        rowCount={printListQuery.data?.totalItems || 0}
        paginationMode="server"
      />
    </>
  );
};

export default ListOfUpdateCardInventory;
