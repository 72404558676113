import { ApprovalOutlined, CancelOutlined } from '@mui/icons-material';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export type ITextFieldProps = {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
} & TextFieldProps;

const Vega_TextField = ({
  startAdornment,
  endAdornment,
  ...rest
}: ITextFieldProps) => {
  const { type, sx } = rest;

  return (
    <>
      <TextField
        fullWidth
        onWheel={(e: any) => e.target.blur()}
        autoComplete={'off'}
        type={type}
        sx={{
          '& .MuiInputBase-input': {
            fontFamily: `var(--font-Aspekta-450) !important`,
            fontSize: '13px',
            lineHeight: '120%',
            color: '#1B1D22',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px',
            '&.Mui-focused fieldset': {
              borderColor: '#C5C8D0',
              borderWidth: '1px',
              borderRadius: '6px',
            },
            ':hover fieldset': {
              borderColor: '#C5C8D0',
            },
          },
          ...sx,
        }}
        InputProps={{
          endAdornment: endAdornment ? (
            endAdornment
          ) : rest.error ? (
            <InputAdornment position="end">
              <CancelOutlined />
            </InputAdornment>
          ) : (
            rest.error !== undefined && (
              <InputAdornment position="end">
                <ApprovalOutlined />
              </InputAdornment>
            )
          ),
          startAdornment: startAdornment,
        }}
        {...rest}
      />
    </>
  );
};

export default Vega_TextField;
