import React, { useState } from 'react';
import { VegaPage } from '../../../components/common';
import FilterReleaseTransction from '../components/MCCReleaseTranscation/FilterReleaseTransction';
import ListOfReleaseTransction from '../components/MCCReleaseTranscation/ListOfReleaseTransction';

const DATA = [
  {
    customerId: 'ABCD1234',
    customerName: 'Ronit Bhandari',
    transactionId: '6123557235253',
    sourceCurrency: 'USD',
    destinationCurrency: 'INR',
    amount: '10000',
    time: '12 Aug 2023 | 02:30 PM',
    id: 1,
  },
  {
    customerId: 'ABCD1235',
    customerName: 'Puneet Sharma',
    transactionId: '6123557235253',
    sourceCurrency: 'USD',
    destinationCurrency: 'CAN',
    amount: '10000',
    time: '12 Aug 2023 | 02:30 PM',
    id: 2,
  },
];

const MCCReleaseTransaction = () => {
  const [filterData, setFilterData] = useState<any>({});
  const handleChange = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };
  return (
    <VegaPage>
      <FilterReleaseTransction state={filterData} handleChange={handleChange} />
      <ListOfReleaseTransction data={DATA} />
    </VegaPage>
  );
};

export default MCCReleaseTransaction;
