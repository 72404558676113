import React, { useEffect, useState } from 'react';
import VegaSelect from '../../components/common/VegaSelect';
import { useClientAuth } from '../../providers/ClientProvider';
import { fetchBranchList } from '../Program-Dashboard/api/Branch';
import { fetchPlanData } from '../Program-Dashboard/api/PlanPolicies';

interface IProps {
  selected: any;
  handleChange: (value: any) => void;
  name: string;
}

const WalletFeePlanSelector = ({ name, selected, handleChange }: IProps) => {
  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [walletFeePlan, setWalletFeePlan] = useState<any[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== walletFeePlan.length) {
        setPage(page + 1);
      }
    }
  };

  const getFeePlanList = (policyType: string) => {
    fetchPlanData({ clientId, policyType, page, pageSize: 10 }).then((res) => {
      const planArr = res.data?.records?.map((val: any) => ({
        id: val.policy_id,
        name: val.policy_name,
      }));
      setWalletFeePlan([...walletFeePlan, ...planArr]);
      setTotalItem(res.data.totalItems);
    });
  };

  useEffect(() => {
    getFeePlanList('WALLET_CONFIGURATION_PLAN');
  }, [page]);
  return (
    <VegaSelect
      placeholder="Wallet fee plan"
      label="Wallet fee plan"
      size="small"
      key={name}
      selected={selected ?? ''}
      onChange={(value) => handleChange(value)}
      options={walletFeePlan}
      onScroll={loadMoreItems}
    />
  );
};

export default WalletFeePlanSelector;
