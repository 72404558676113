// Hack to navigate back to rewards home if program selection changes

import { throttle } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { fetchAccountForCustomer } from '../api/account';
import { getFilteredCustomers } from '../api/customer';
import { SupportStep } from '../pages/Support/Support';
import { NESTED_VIEW, SupportRoute } from '../routes';
import { AccountDataDto } from '../types/account';
import {
  ICardState,
  ICardsForAccountProps,
  ISupportCustomerDtoProps,
} from '../types/support';
import { useClientAuth } from './ClientProvider';
import { useProgramData } from './ProgramDataProvider';
import { useSnackbar } from './SnackbarProvider';

type SupportContextType = {
  currentRoute: string;
  filter: string;
  loading: boolean;
  isEmptyFilter: boolean;
  isDisabled: boolean;
  selected: string;
  accountData: Partial<AccountDataDto>;
  cardsList: ICardState;
  setCardsList: React.Dispatch<React.SetStateAction<ICardState>>;
  filteredCustomer: ISupportCustomerDtoProps | null;
  elementHeight: number | null;
  setElementHeight: React.Dispatch<React.SetStateAction<number | null>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setIsEmptyFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setFilteredCustomer: React.Dispatch<
    React.SetStateAction<ISupportCustomerDtoProps | null>
  >;
  navigateTo: (route: SupportRoute, id?: AccountDataDto) => void;
  throttledGetFilterCustomer: () => void;
};

const SupportContext = createContext<SupportContextType | null>(null);

export const useSupport = () =>
  useContext(SupportContext) as SupportContextType;

export const SupportProvider = ({ children }: any) => {
  const { clientId } = useClientAuth();
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  const [filteredCustomer, setFilteredCustomer] =
    useState<ISupportCustomerDtoProps | null>(null);
  const [currentRoute, setCurrentRoute] = useState<string>(
    SupportRoute.SUPPORT
  );
  const [selected, setSelected] = useState<string>(SupportStep.ACCOUNT);
  const [accountData, setAccountData] = useState<Partial<AccountDataDto>>({});
  const [filter, setFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [elementHeight, setElementHeight] = useState<number | null>(null);

  const [cardsList, setCardsList] = useState<ICardState>({
    data: [],
    isLoading: false,
  });

  function _navigateTo(route: SupportRoute, id?: AccountDataDto) {
    if (id) {
      setAccountData(id);
    }
    setSearchParams(createSearchParams({ [NESTED_VIEW]: route }));
  }

  //////////  handle events \\\\\\\\\\\\\\\\\

  //////////  handle events \\\\\\\\\\\\\\\\\

  const getAccountForCustomer = async (customerId: string) => {
    try {
      const response = await fetchAccountForCustomer(customerId as string);
      return response.data.records;
    } catch (error) {
      setLoading(false);
      setIsEmptyFilter(false);
      throw error;
    }
  };

  const isValidEmail = (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(value);
  };

  const getFilterCustomer = async (value: string, clientId: string) => {
    const checkValue = parseInt(value);

    try {
      setLoading(true);
      const emailBody = {
        clientId: clientId,
        emailId: value,
      };
      const mobileBody = {
        clientId: clientId,
        mobileNumber: value,
      };

      const paginatedResponse = await getFilteredCustomers(
        isNaN(checkValue) ? emailBody : mobileBody
      );
      const customerRecords = paginatedResponse.records;
      if (customerRecords.length <= 0) {
        console.error('Customer is undefined');
        setFilteredCustomer(null);
        setLoading(false);
        setIsEmptyFilter(false);
        setIsDisabled(true);
        return;
      }
      const customer = customerRecords[0];

      setLoading(false);
      const accountsResponse = await getAccountForCustomer(customer.customerId);

      if (accountsResponse.length) {
        setFilteredCustomer({
          ...customer,
          account: accountsResponse,
        });
        // const accountId = accountsResponse[0].accountId;
        // await getCardListFOrAccount(accountId);

        setLoading(false);
      } else {
        setFilteredCustomer({
          ...customer,
          account: null,
        });
      }
      setIsDisabled(true);
      setIsEmptyFilter(false);
    } catch (err) {
      console.error(err);
      setIsDisabled(true);
    } finally {
      setLoading(false);
      setIsDisabled(true);
    }
  };

  const throttledGetFilterCustomer = throttle(async () => {
    if (!clientId) return;
    if (filter.length) {
      await getFilterCustomer(filter, clientId);
    } else {
      setFilteredCustomer(null);
    }
  }, 200);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const queryParams = Object.fromEntries(params.entries());
    const view = queryParams.nested_view;
    setCurrentRoute(view);
  }, [searchParams]);

  useEffect(() => {
    _navigateTo(SupportRoute.SUPPORT);
  }, [selectedProgram]);

  return (
    <SupportContext.Provider
      value={{
        filter,
        elementHeight,
        loading,
        filteredCustomer,
        cardsList,
        setCardsList,
        selected,
        accountData,
        isEmptyFilter,
        isDisabled,
        setFilteredCustomer,
        setIsEmptyFilter,
        setIsDisabled,
        setLoading,
        setSelected,
        setElementHeight,
        setFilter,
        currentRoute: currentRoute,
        navigateTo: _navigateTo,
        throttledGetFilterCustomer,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};
