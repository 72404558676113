import { useEffect, useMemo, useState } from 'react';
import { Loading, NoResults, VegaCard, VegaTable } from '../../common';

import { Typography, Stack } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { getReportsByCategory } from '../../../api/reports';
import { IReport, IReportCategory } from '../../../types/reports';
import { convertCamelCase } from '../../../utils/stringUtils';
import ReporConfig from './ReportConfig';
import { BLUE } from '../../../constants/style';

type IProps = {
  reportCategoriesMap: Record<string, any>;
  selectedCategory: IReportCategory;
};

function ReportsTable({ reportCategoriesMap, selectedCategory }: IProps) {
  const [reports, setReports] = useState<any>([]);

  const [selectedReport, setSelectedReport] = useState<null | IReport>(null);
  const [isLoading, setIsLoading] = useState(false);

  const title = useMemo(() => {
    return selectedCategory.reportCategory;
  }, [selectedCategory]);

  const columnDef = [
    {
      field: 'reportName',
      headerName: 'Report Name',
      renderCell: (props: any) => {
        return (
          <Box
            onClick={() => {
              setSelectedReport(props.row);
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: BLUE.lighter,
            }}
          >
            {convertCamelCase(props.row.reportName) + ' Report'}
          </Box>
        );
      },
      flex: 1.5,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',

      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      renderCell: (props: any) => {
        return (
          <Typography variant="body1">
            {dayjs(props.row.createdAt).format('DD/MM/YYYY')}
          </Typography>
        );
      },
      flex: 1,
    },
    {
      field: 'lastModifiedBy',
      headerName: 'Last Modified By',
      flex: 1,
    },
    {
      field: 'lastModifiedDate',
      headerName: 'Last Modified Date',
      renderCell: (props: any) => {
        return (
          <Typography variant="body1">
            {dayjs(props.row.createdAt).format('DD/MM/YYYY')}
          </Typography>
        );
      },

      flex: 1,
    },
  ];

  useEffect(() => {
    setSelectedReport(null);
  }, [selectedCategory]);

  const fetchReports = () => {
    setIsLoading(true);
    getReportsByCategory(selectedCategory.categoryId)
      .then((res) => {
        setReports(res.data);
      })
      .catch((err) => {
        console.log('getReportsByCategory eer', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchReports();
  }, [selectedCategory.categoryId, reportCategoriesMap]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack gap={2} pt={2}>
          <VegaCard>
            {title && (
              <Box display={'flex'} flexDirection="row">
                <Typography
                  variant="body2"
                  fontWeight={'bold'}
                  sx={{
                    ':hover': selectedReport && {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => setSelectedReport(null)}
                >
                  {`${convertCamelCase(title)} Reports`}
                </Typography>
                <Typography variant="body2" fontWeight={'bold'}>
                  {selectedReport && (
                    <span style={{ padding: '0 8px' }}>
                      {`  >    ${convertCamelCase(selectedReport?.reportName)}`}
                    </span>
                  )}
                </Typography>
              </Box>
            )}
          </VegaCard>

          <>
            {!selectedReport ? (
              <Box>
                <VegaCard fullHeight noPad>
                  {reports?.length ? (
                    <VegaTable
                      data={reports}
                      columnDef={columnDef}
                      idColumn={'reportId'}
                    />
                  ) : (
                    <NoResults />
                  )}
                </VegaCard>
              </Box>
            ) : (
              <Box>
                <ReporConfig selectedReport={selectedReport} />
              </Box>
            )}
          </>
        </Stack>
      )}
    </>
  );
}

export default ReportsTable;
