/** @format */

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import _ from 'lodash';

interface IData {
  text?: string;
  value?: string | number;
}

type Props = {
  value?: number | string;
  onChange?: (event: SelectChangeEvent<string | number>) => void;
  placeholder?: string;
  label?: string;
  key?: any;
  data?: IData[];
  disabled?: boolean;
};

const VegaSelectItem = ({
  value,
  onChange,
  data,
  placeholder,
  label,
  key,
  disabled,
}: Props) => {
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel size="small" sx={{ color: '#AFAFAF' }} htmlFor={key}>
          {label}
        </InputLabel>
        <Select
          placeholder={placeholder}
          value={value}
          label={label}
          onChange={onChange}
          fullWidth
          size="medium"
          style={{ borderRadius: '7px', color: '#AFAFAF', height: '50px' }}
          id={key}
          disabled={disabled}
        >
          {data &&
            data.map((value: IData) => (
              <MenuItem value={value?.value}>
                {_.startCase(_.toLower(value?.text))}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default VegaSelectItem;
