import { EditOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  CampaignRule,
  CampaignRuleType,
  RuleMonetizationType,
} from '../../../types/RewardsLoyalty';
import VegaPill from '../../common/v2/VegaPill';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import {
  FreeRuleIcon,
  HashTagPurpleColored,
  NonTransactionalIcon,
  PaidRuleIcon,
  TransactionalIcon,
} from '../../icon/Icons';
import { useCampaign } from '../providers/CampaignProvider';

type Props = {
  rule: CampaignRule;
  onEditClick: () => void;
  onClick: () => void;
};

const TRANSACTIONAL_COLORS = {
  textColor: 'rgba(29, 151, 221, 1)',
  backgroundColor: 'rgba(232, 245, 252, 1)',
};
const NON_TRANSACTIONAL_COLORS = {
  textColor: 'rgba(221, 186, 8, 1)',
  backgroundColor: 'rgba(252, 248, 230, 1)',
};
const MILESTONE_COLORS = {
  textColor: 'rgba(29, 151, 221, 1)',
  backgroundColor: 'rgba(232, 245, 252, 1)',
};

function CampaignRuleListItem({ rule, onEditClick, onClick }: Props) {
  const { isCampaignActive } = useCampaign();
  const isPaid = () => rule.paymentType == RuleMonetizationType.PAID;
  const ruleTypeColorData = () => {
    switch (rule.ruleType) {
      case CampaignRuleType.TRANSACTIONAL:
        return TRANSACTIONAL_COLORS;
      case CampaignRuleType.NON_TRANSACTIONAL:
        return NON_TRANSACTIONAL_COLORS;
      case CampaignRuleType.MILESTONE:
        return MILESTONE_COLORS;
    }
  };

  const getRuleTypeIcon = () => {
    switch (rule.ruleType) {
      case CampaignRuleType.TRANSACTIONAL:
        return <TransactionalIcon />;
      case CampaignRuleType.NON_TRANSACTIONAL:
        return <NonTransactionalIcon />;
      case CampaignRuleType.MILESTONE:
        return <TransactionalIcon />;
    }
  };

  const getDateDisplayText = () => {
    const creationDate = rule.createdAt;
    if (!creationDate) return '-';
    return dayjs(creationDate).format('DD/MM/YYYY');
  };
  return (
    <Box
      onClick={onClick}
      sx={{
        border: '1px solid #EEEEEE',
        borderRadius: '0.75rem',
        paddingX: '.75rem',
        paddingY: '0.5rem',
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
      }}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <VegaTextV2
          text={rule.ruleName}
          fontSize={'14px'}
          color={'rgba(58, 74, 95, 1)'}
          lineHeight="140%"
          fontWeight={500}
        />
        <VegaPill
          backgroundColor="rgba(238, 234, 251, 1)"
          textColor="rgba(80, 41, 219, 1)"
        >
          <Stack direction={'row'}>
            <HashTagPurpleColored />
            <VegaTextV2
              text={`${rule?.id}`}
              fontSize="0.5rem"
              fontWeight={600}
            />
          </Stack>
        </VegaPill>
        <VegaPill
          backgroundColor={
            isPaid() ? 'rgba(235, 244, 236, 1)' : 'rgba(234, 245, 246, 1)'
          }
          textColor={
            isPaid() ? 'rgba(54, 145, 63, 1)' : 'rgba(48, 158, 166, 1)'
          }
        >
          <Stack direction={'row'} spacing={0.2}>
            {isPaid() ? <PaidRuleIcon /> : <FreeRuleIcon />}
            <VegaTextV2
              fontSize="0.5rem"
              fontWeight={600}
              text={isPaid() ? 'Paid' : 'Free'}
            />
          </Stack>
        </VegaPill>
        <VegaPill
          backgroundColor={ruleTypeColorData()?.backgroundColor}
          textColor={ruleTypeColorData()?.textColor}
        >
          <Stack direction={'row'} spacing={0.2}>
            {getRuleTypeIcon()}
            <VegaTextV2
              text={_.startCase(_.toLower(rule.ruleType))}
              fontSize="0.5rem"
              fontWeight={600}
            />
          </Stack>
        </VegaPill>

        <VegaTextV2
          text={`Creation date ${getDateDisplayText()}`}
          variant="caption"
          style={{
            fontWeight: 600,
            fontSize: '.5rem',
            lineHeight: '120%',
            letterSpacing: '0.01em',
          }}
        />
        <Stack
          flex={2}
          direction="row"
          alignItems={'center'}
          justifyContent="end"
          display={isCampaignActive ? 'none' : 'flex'}
        >
          <IconButton size="small" onClick={onEditClick}>
            <EditOutlined
              fontSize="small"
              sx={{ color: 'rgba(255, 123, 1, 1)' }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default CampaignRuleListItem;
