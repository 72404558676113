import { ReactNode } from 'react';

interface VegaConditionalWrapperProps {
  condition: boolean;
  ifTrue?: ReactNode;
  ifFalse?: ReactNode;
}

const VegaConditionalWrapper = ({
  condition,
  ifTrue,
  ifFalse,
}: VegaConditionalWrapperProps) => {
  return condition ? <>{ifTrue}</> : <>{ifFalse}</>;
};

export default VegaConditionalWrapper;
