import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../services/ChargeBackService';
import { ChargebackStatus } from '../../../types/chargeBack';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useChargeBack } from '../hooks';
import { RemarkDocsModal } from './RemarkDocsModal';

interface IProps {}

export function ReInitiateChargebackModal({}: IProps) {
  const { selectedChargeBack, setSelectedChargeBack } = useChargeBack();
  const { setSnackbar } = useSnackbar();

  const onSubmit = (data: { remarks: string; disputeProofs: File[] }) => {
    ChargebackService.reopenChargeback({
      chargebackId: selectedChargeBack?.id || '',
      chargebackStatus: ChargebackStatus.CHARGEBACK_REINITIATED,
      remarks: data.remarks,
      disputeProofs: data.disputeProofs,
    })
      .then((res) => {
        setSnackbar('Successfully re-initiated chargeback', 'success');
        setSelectedChargeBack(res);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(
            err,
            'Error while re-initiating chargeback'
          ),
          'error'
        );
      });
  };
  return (
    <RemarkDocsModal
      onSubmit={onSubmit}
      header="Re-initiate Chargeback"
      triggerLabel="Re-initiate Chargeback"
    />
  );
}
