/** @format */

import {
  AdminPanelSettings,
  Analytics as AnalyticsIcon,
  AssessmentOutlined,
  FolderShared,
  LibraryBooksOutlined,
  SupportAgent,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import AgentView from '../pages/AgentView';
import Analytics from '../pages/Analytics';
import Collections from '../pages/Collections';
import CrmProgram from '../pages/CrmProgram';
import Operations from '../pages/Operations';
import Policy from '../pages/Policy';
import Reports from '../pages/Reports';
import ApplicationManagmentProvider from '../providers/ApplicationManagmentProvider';
import { useClientAuth } from '../providers/ClientProvider';
import { BACK_OFFICE_ROUTES, ROUTES } from '../routes';
import { SupportProvider } from '../providers/SupportProvider';
import SupportLending from '../pages/Support/SupportLending';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { CONSTANTS } from '../enums/commons';
import { AccountCloseDetails } from '../components/Operations/Account Closure/AccountCloseDetails';
import BackOfficeLendingPage from './BackOffice/BackOfficeLendingPage';
import { useAppSelector } from '../store/hook';
import { getState } from '../store/common/stateSlice';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: ROUTES.SEARCH_BY_CUSTOMER,
    icon: <SupportAgent sx={{ fontSize: 18 }} />,
    label: 'Search By Customer',
    component: (
      <SupportProvider>
        <SupportLending />
      </SupportProvider>
    ),
    isProgramSelector: false,
    isAccountSelector: false,
    isCrmRoutes: true,
  },
  // {
  //   id: ROUTES.SUPPORT,
  //   icon: <SupportAgent />,
  //   label: 'Support',
  //   component: <Support />,
  //   isProgramSelector: true,
  //   isAccountSelector: false,
  //   isCrmRoutes: true,
  // },
  {
    id: ROUTES.COLLECTIONS,
    icon: <LibraryBooksOutlined sx={{ fontSize: 18 }} />,
    label: 'Collections',
    component: <Collections />,
    isProgramSelector: true,
    isAccountSelector: false,
    isCrmRoutes: true,
  },
  {
    id: ROUTES.AGENT_VIEW,
    icon: <LibraryBooksOutlined sx={{ fontSize: 18 }} />,
    label: 'Agent View',
    component: <AgentView />,
    isProgramSelector: true,
    isAccountSelector: false,
    isCrmRoutes: true,
  },
  {
    id: ROUTES.REPORTS,
    icon: <AssessmentOutlined sx={{ fontSize: 18 }} />,
    label: 'Reports',
    component: <Reports />,
    isProgramSelector: true,
    isAccountSelector: false,
    isCrmRoutes: true,
  },
  {
    id: ROUTES.OPERATIONS,
    icon: <AssessmentOutlined sx={{ fontSize: 18 }} />,
    label: 'Operations',
    component: <></>,
    isProgramSelector: false,
    isAccountSelector: false,
    isCrmRoutes: true,
  },
  {
    id: ROUTES.ANALYTICS,
    icon: <AnalyticsIcon sx={{ fontSize: 18 }} />,
    label: 'Analytics',
    component: <Analytics />,
    isProgramSelector: true,
    isAccountSelector: false,
    isCrmRoutes: true,
  },
  {
    id: '/' + BACK_OFFICE_ROUTES.ACC_CLOSE_DETAILS,
    label: 'Check Request Account Closure',
    component: <AccountCloseDetails />,
    isProgramSelector: false,
    isAccountSelector: false,
    isCrmRoutes: true,
    hideForAdmin: true,
    hideForNonAdmin: true,
    showBackButton: true,
    showLabel: true,
  },
];

const NON_ADMIN = [
  {
    id: ROUTES.PROGRAM,
    icon: <FolderShared />,
    label: 'Programs',
    component: <CrmProgram />,
    hideForAdmin: true,
  },
  {
    id: ROUTES.POLICY,
    icon: <AdminPanelSettings />,
    label: 'Policy',
    component: <Policy />,
    hideForAdmin: true,
  },
];

const CrmContainer = (props: Props) => {
  const { isAdmin } = useClientAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showLendingPage } = useAppSelector(getState);
  const view = searchParams.get('view');

  const navigate2Details = (req_id: string) => {
    setSelectedItem(NAV_OPTIONS[6]);
    setSearchParams(
      createSearchParams({
        [CONSTANTS.SELECTED_VIEW]: NAV_OPTIONS[6].id,
        req_id,
      })
    );
  };

  const NAV_OPTIONS_EDITED = NAV_OPTIONS.map((el) =>
    el.id === ROUTES.OPERATIONS
      ? {
          ...el,
          component: (
            <ApplicationManagmentProvider>
              <Operations navigate2Details={navigate2Details} />
            </ApplicationManagmentProvider>
          ),
        }
      : el
  );

  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS_EDITED.find((el) => el.id === view) || NAV_OPTIONS[0]
  );

  useEffect(() => {
    setSelectedItem(
      NAV_OPTIONS_EDITED.find((el) => el.id === view) || NAV_OPTIONS[0]
    );
  }, [view]);

  return (
    <Box sx={{ height: '100%' }}>
      {showLendingPage ? (
        <BackOfficeLendingPage setSelectedItem={setSelectedItem} />
      ) : (
        <SidebarLayout
          sidebarListItems={
            isAdmin ? NAV_OPTIONS_EDITED : [...NON_ADMIN, ...NAV_OPTIONS_EDITED]
          }
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        />
      )}
    </Box>
  );
};

export default CrmContainer;
