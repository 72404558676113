import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Drawer, Grid, IconButton, Toolbar } from '@mui/material';
import Box from '@mui/material/Box/Box';
import VegaContainedButton from '../../components/common/VegaContainedButton';
import VegaOutlineButton from '../../components/common/VegaOutlineButton';
import VegaTextV2 from '../../components/common/v2/VegaTextV2';
type IDrawerType = {
  anchor?: string | any;
  closeText?: string | any;
  submitText?: string | any;
  header?: string | any;
  open?: boolean;
  isClose?: boolean;
  loading?: boolean;
  isSubmit?: boolean;
  isTableShow?: boolean;
  onClose?: (e?: any) => void;
  onSubmit?: (e: any) => void;
  children?: JSX.Element | any;
  footer?: JSX.Element | any;
  submitButton?: JSX.Element;
  disableSubmitButton?: boolean;
};

const CimsDrawer = ({
  anchor,
  open,
  onClose,
  onSubmit,
  children,
  footer,
  isClose,
  isSubmit,
  submitText,
  closeText,
  header,
  loading,
  submitButton,
  isTableShow,
  disableSubmitButton = false,
}: IDrawerType) => {
  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            width: '42%',
            minWidth: 360,
          },
        }}
        anchor={anchor ?? 'right'}
        open={open}
        onClose={(e) => {
          if (onClose) {
            onClose(e);
          }
        }}
      >
        <AppBar
          position="static"
          sx={{
            bgcolor: '#E5F7FC',
          }}
        >
          <Toolbar>
            <VegaTextV2
              color={'#222222'}
              sx={{
                flexGrow: 1,
              }}
              bold
            >
              {header}
            </VegaTextV2>

            {onClose && (
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                onClick={onClose}
                sx={{
                  color: '#222222',
                  mr: 2,
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>

        <Box
          sx={{
            p: 2,
            bgcolor: 'white',
            borderRadius: 1,
            height: '88%',
            overflow: 'auto',
            padding: '31px',
            '::-webkit-scrollbar': {
              width: 0,
            },
            '::-moz-scrollbar': {
              width: 0,
            },
          }}
        >
          {children}
        </Box>

        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'end',
            p: 1,
            boxShadow: '0px -4px 3px rgba(0, 0, 0, 0.08)',
          }}
        >
          {footer
            ? footer
            : isClose && (
                <VegaOutlineButton
                  text={closeText}
                  onClick={(e) => {
                    if (onClose) {
                      onClose(e);
                    }
                  }}
                  isDanger
                />
              )}
          {isSubmit && (
            <>
              {submitButton ? (
                submitButton
              ) : (
                <VegaContainedButton
                  disabled={disableSubmitButton}
                  text={submitText}
                  onClick={onSubmit}
                  isPrimary
                  loading={loading}
                />
              )}
            </>
          )}
        </Grid>
      </Drawer>
    </div>
  );
};

export default CimsDrawer;
