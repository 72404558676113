import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { PRIMARY } from '../../constants/style';
type IProps = {
  isPreview?: boolean;
  accept?: Accept;

  value: File[];
  onChange: (value: File[]) => void;
  label?: string;
  helperText?: string;
  max?: number;
};

function VegaUploadNPreview({
  isPreview = false,
  accept,
  label,
  helperText,
  value,
  onChange,
  max = 10,
}: IProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      //   'image/*': [],
      //   'application/*': ['pdf'],
      ...accept,
    },
    onDrop: (acceptedFiles) => {
      //   setFiles((pre) => [...pre, ...acceptedFiles]);
      onChange([...value, ...acceptedFiles]);
    },
    maxFiles: max,
    disabled: value.length >= max,
  });

  const onDelete = (index: number) => {
    // setFiles((pre) => {
    //   pre.splice(index, 1);
    //   return [...pre];
    // });
    const newFiles = [...value];
    newFiles.splice(index, 1);
    onChange(newFiles || []);
  };

  useEffect(() => {
    const objectUrls = value.map((file) => URL.createObjectURL(file));
    return () => {
      objectUrls.map((url) => URL.revokeObjectURL(url));
    };
  }, [value]);

  return (
    <Stack direction={'column'} spacing={0.5}>
      <Typography
        variant="caption"
        sx={{
          fontWeight: 'bold',
        }}
      >
        {label}
      </Typography>

      <Tooltip title={value.length >= max ? 'Max files can be ' + max : ''}>
        <Stack
          direction={'column'}
          alignItems={'center'}
          {...getRootProps({ className: 'dropzone' })}
          sx={{
            border: '2px dashed #4785FF',
            borderRadius: '9px',
            py: 1,
            cursor: 'pointer',
            ...(value.length >= max && {
              opacity: 0.5,
              cursor: 'not-allowed',
            }),
          }}
        >
          <input {...getInputProps()} />
          <Stack direction="row" spacing={1}>
            <FileUploadIcon
              color={'primary'}
              sx={{
                color: PRIMARY.blue,
              }}
            />
            <Typography variant="body1" color={PRIMARY.blue}>
              Upload Files
            </Typography>
          </Stack>
        </Stack>
      </Tooltip>
      <Typography
        sx={{
          fontSize: '10px',
          color: '#656565',
        }}
      >
        {helperText}
      </Typography>

      {isPreview && (
        <Stack spacing={1} sx={{ mt: 3 }}>
          <Stack direction="row" width={'100%'} flexWrap={'wrap'} spacing={2}>
            {value.map((file, index) => {
              if (file.type.includes('image')) {
                return (
                  <div
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <Avatar
                      src={URL.createObjectURL(file)}
                      alt="Image Preview"
                      style={{ width: 50, height: 50 }}
                    />
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        padding: 0,
                        background: 'white',
                      }}
                      size="small"
                      onClick={(event) => onDelete(index)}
                    >
                      <CloseIcon fontSize={'small'} />
                    </IconButton>
                  </div>
                );
              }
            })}
          </Stack>

          <Stack>
            {value.map((file, index) => {
              console.log(file.type);
              if (!file.type.includes('image')) {
                return (
                  <>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <Box>
                        <Typography
                          fontWeight={'bold'}
                          fontSize={12}
                          fontStyle={'italic'}
                        >
                          {file.name.toLocaleUpperCase()}
                        </Typography>
                      </Box>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                      >
                        <CheckCircleOutlineIcon
                          color="success"
                          fontSize={'small'}
                        />
                        <IconButton
                          style={{}}
                          size="small"
                          onClick={(event) => onDelete(index)}
                        >
                          <CloseIcon fontSize={'small'} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </>
                );
              }
            })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default VegaUploadNPreview;
