import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    TransitionProps={{ unmountOnExit: true }}
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px !important',
  '& .MuiPaper-root': {
    borderRadius: '8px',
  },
  '& .MuiAccordion-rounded': {
    borderRadius: '8px',
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-disabled': {
    borderColor: '#eee',
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: '#EBEDEF',
  padding: 0,
  borderRadius: '8px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    order: 1,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '12px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .MuiAccordionSummary-content.Mui-expanded .expandIcon': {
    transition: 'transform 200ms',
    transform: 'rotate(180deg)',
  },
  '& .MuiIconButton-root': {
    padding: 0,
  },
  '&.Mui-disabled': {
    opacity: 1,
    backgroundColor: '#FAFAFA',
  },
  '&.Mui-expanded': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#FAFAFA',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
}));

type VPAccordionProps = {
  title: string;
  children?: React.ReactElement | React.ReactElement[];
  disabled?: boolean;
  expandable?: boolean;
} & AccordionProps;

function VPAccordion({
  title,
  children,
  disabled,
  expandable = true,
  ...rest
}: VPAccordionProps) {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement[];
  const tailEnd =
    childrenArray.find((child) => child.type === VPAccordion.TailEnd) ?? null;
  const body =
    childrenArray.find((child) => child.type === VPAccordion.Body) ?? null;

  return (
    <Accordion
      disabled={disabled}
      {...(!expandable && !disabled && { expanded: true })}
      {...rest}
    >
      <AccordionSummary>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            className="font-aspekta-500"
            sx={{
              fontSize: 14,
              color: '#1B1D22',
            }}
          >
            {title}
          </Typography>
          {!disabled && expandable && (
            <IconButton className="expandIcon" size="small">
              <ExpandMoreIcon />
            </IconButton>
          )}
        </Box>
        {tailEnd}
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
}

VPAccordion.TailEnd = ({ children }: { children?: React.ReactElement }) => {
  return children ?? null;
};

VPAccordion.Body = ({ children }: { children?: React.ReactElement }) => {
  return children ?? null;
};

export default VPAccordion;
