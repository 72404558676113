import { GridColumns } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Vega_Datagrid from '../../../../../common/v3/Vega_Datagrid';
import { getPhysicalCardOrders } from '../../../../../../api/card';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { PhysicalCardOrder } from '../../../../../../types/card';
import { dateFormat } from '../../../../../../mcc/variable';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { toLowerCase } from '../../../../../../constants/commonFunction';

interface IPastCardsProps {
  data: PhysicalCardOrder[];
  loading: boolean;
}

const ListOfPastCardOrders = () => {
  const { accountData } = useSupport();
  const [pastCardsListState, setPastCardsListState] = useState<IPastCardsProps>(
    {
      data: [],
      loading: false,
    }
  );
  const COLUMN_DEF: GridColumns = [
    {
      field: 'id',
      headerName: 'Order ID',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },

    {
      field: 'startDateTime',
      headerName: 'Creation Date',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const creationDate = props.row.createdAt
          ? dateFormat(props.row.createdAt)
          : '--';
        return <Vega_Text text={creationDate} />;
      },
    },
    {
      field: 'Delivery Partner',
      headerName: 'Delivery Partner',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const deliveryPartner = props.row.deliveryPartner
          ? props.row.deliveryPartner
          : '--';
        return <Vega_Text text={deliveryPartner} />;
      },
    },
    {
      field: 'Tracking ID',
      headerName: 'Tracking ID',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const trackingId = props.row.trackingId ? props.row.trackingId : '--';
        return <Vega_Text text={trackingId} />;
      },
    },
    {
      field: 'endDateTime',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const status = props.row.physicalCardOrderStatus
          ? props.row.physicalCardOrderStatus
          : '--';
        return <Vega_Text text={toLowerCase(status)} />;
      },
    },

    {
      field: 'Transaction Time',
      headerName: 'Last Updated Date',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const updatedAt = props.row.updatedAt
          ? dateFormat(props.row.updatedAt)
          : '--';
        return <Vega_Text text={updatedAt} />;
      },
    },
  ];

  const getPastCardsForAccount = async (accountId: string) => {
    setPastCardsListState({ data: [], loading: true });
    try {
      const body = {
        accountId,
        physicalCardOrderStatusList: ['REJECTED', 'DELIVERED', 'RTO'],
      };
      const response = await getPhysicalCardOrders(body);
      setPastCardsListState({ data: response, loading: false });
    } catch (error) {
      setPastCardsListState({ data: [], loading: false });
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getPastCardsForAccount(accountData.accountId);
    }
  }, []);
  return (
    <>
      <Vega_Datagrid
        data={pastCardsListState.data}
        loading={pastCardsListState.loading}
        idColumn="id"
        columns={COLUMN_DEF}
      />
    </>
  );
};

export default ListOfPastCardOrders;
