import { Box } from '@mui/material';
import { DataGrid, GridCallbackDetails, DataGridProps } from '@mui/x-data-grid';

import {
  BORDER,
  COMMON,
  GREEN,
  GREY,
  NEW_PRIMARY,
} from '../../constants/style';

interface Props extends Partial<DataGridProps> {
  data?: any;
  columnDef: any[];
  pageSize?: number;
  page?: number;
  idColumn: string;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
  onCellClick?: (val: any) => void;
  onRowSelect?: (val: any) => void;
  paginationMode?: any;
  rowCount?: any;
  props?: any;
  border?: boolean;
  noTopRadius?: boolean;
  disablePagination?: boolean;
  disableSelectionOnClick?: boolean;
  checkboxSelection?: boolean;
  loading?: boolean;
  headerColor?: string;
  headerFontWeight?: number;
  noFooter?: boolean;
  isSelected?: any;
}

const VegaTable = ({
  data,
  columnDef,
  idColumn,
  pageSize,
  page,
  onPageChange,
  onPageSizeChange,
  onRowSelect,
  paginationMode,
  rowCount,
  onCellClick,
  border,
  noTopRadius,
  disablePagination = false,
  disableSelectionOnClick = false,
  checkboxSelection,
  loading = false,
  headerColor = GREY.light,
  headerFontWeight = 600,
  noFooter = false,
  isSelected,
  ...props
}: Props) => {
  const { rows, columns, ...rest } = props;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          '& .vegatable--blue': {
            bgcolor: '#F7F7F7',
            '&:hover': {
              bgcolor: '#F7F7F7',
            },
          },
          '& .vegatable--white': {
            bgcolor: '#FFFFFF',
            '&:hover': {
              bgcolor: '#FFFFFF',
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            overflowX: 'scroll',
            border: 'none',
            '& ::-webkit-scrollbar': {
              width: '2px !important',
              height: '2px !important',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: 'transparent !important',
            },
            '& .MuiDataGrid-main': {
              border: '1px solid #E1E4EB',
              borderRadius: '0.75rem',
            },
            '& .MuiDataGrid-row:hover': {
              bgcolor: 'white',
            },
            '& .MuiDataGrid-cellContent, .MuiDataGrid-cell p': {
              color: '#1B1D22',
              fontSize: '13px',
              fontFamily: `var(--font-Aspekta-500) !important`,
            },
            '& .MuiDataGrid-columnHeaders': {
              borderTop: 'none !important',
              borderBottom: '1px solid #E1E4EB',
              backgroundColor: '#F7F7F7',
              maxHeight: '2.75rem !important',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '13px',
              textTransform: 'capitalize',

              lineHeight: '124%',
              color: '#676B76',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none !important',
            },
            '& .MuiDataGrid-sortIcon': {
              display: 'none !important',
            },
            '& .MuiTablePagination-toolbar': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '13px',
              '& .MuiTablePagination-select': {
                marginTop: '2px',
                paddingBottom: '0px',
              },
            },

            '& .MuiTablePagination-selectLabel': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '10px',
              color: NEW_PRIMARY.GRAY,
            },
            '& .MuiTablePagination-displayedRows': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '10px',
              color: NEW_PRIMARY.GRAY,
            },
            '& .MuiDataGrid-footerContainer': {
              border: 'none !important',
              backgroundColor: 'transaparant',
              margin: '0px !important',
              padding: '0px !important',
            },
          }}
          page={page ?? undefined}
          pageSize={pageSize ?? 15}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onCellClick={onCellClick}
          onSelectionModelChange={onRowSelect}
          paginationMode={paginationMode ?? 'client'}
          pagination={!disablePagination ? undefined : true}
          rows={data}
          rowCount={rowCount}
          columns={columnDef}
          getRowId={(row: any) => {
            return row[idColumn];
          }}
          getRowClassName={(params: any) => {
            return `vegatable--row ${
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'vegatable--white'
                : 'vegatable--blue'
            }`;
          }}
          loading={loading}
          isRowSelectable={isSelected}
          disableSelectionOnClick={disableSelectionOnClick}
          checkboxSelection={checkboxSelection}
          disableColumnSelector
          disableColumnMenu
          hideFooterSelectedRowCount
          rowsPerPageOptions={paginationMode ? [5, 10, 15] : []}
          disableDensitySelector
          {...rest}
        />
      </Box>
    </>
  );
};

export default VegaTable;
