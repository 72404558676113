/* eslint-disable react/jsx-pascal-case */
import { Stack, Typography } from '@mui/material';
import { GridColumns, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useClientAuth } from '../../../providers/ClientProvider';
import { BinAllocationDto } from '../../../types/card';
import { Level } from '../../../types/client';
import { BinService } from '../../apis';
import CIMS_DataGrid from '../../components/common/CIMS_DataGrid';
import SummaryTab from './SummaryTab';

interface IProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}
const ListOfBankCorrespondent = ({ selectedTab, setSelectedTab }: IProps) => {
  const [pageSize, setPageSize] = useState<number>(5);
  const [page, setPage] = useState(0);
  const { clientId } = useClientAuth();
  const listBinAllocationQuery = useQuery({
    queryKey: ['listBinAllocation', { page, pageSize }],
    queryFn: () => {
      return BinService.listBinAllocation({
        clientId: clientId || '',
        depth: 1, // Level l1
        page: page,
        size: pageSize,
        populateBin: true,
        populateProgram: true,
        populateDivision: true,
      });
    },
  });

  const COLUMN_DEF: GridColumns<BinAllocationDto> = [
    {
      field: 'code',
      headerName: 'BC Code',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.divisionDto?.divisionCode;
      },
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.divisionDto?.divisionCode || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'bcName',
      headerName: 'BC Name',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.divisionDto?.name;
      },
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.divisionDto?.name || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'binValue',
      headerName: 'Bin Value',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.binDto?.binValue;
      },
      renderCell: (props) => {
        return <Typography variant="body1">{props.value}</Typography>;
      },
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.programDto?.name || '-';
      },
      renderCell: (props) => {
        return <Typography variant="body1">{props.value}</Typography>;
      },
    },
    {
      field: 'totalKits',
      headerName: 'Total Cards',
      flex: 2,
      minWidth: 150,
      renderCell: (props) => {
        return (
          <Typography variant="body1">{props.row.totalKits || 0}</Typography>
        );
      },
    },
    // {
    //   field: 'noOfKitsAllocated',
    //   headerName: 'Allocated to Branches',
    //   flex: 2,
    //   minWidth: 150,
    //   renderCell: (props) => {
    //     return (
    //       <Typography variant="body1">
    //         {props.row.noOfKitsAllocated || 0}
    //       </Typography>
    //     );
    //   },
    // },
    // {
    //   field: 'noOfKitsAllocated',
    //   headerName: 'Allocated',
    //   flex: 2,
    //   minWidth: 150,
    //   renderCell: (props) => {
    //     return (
    //       <Typography variant="body1">
    //         {/* {props.row.totalKits||
    //           0 - (props.row.noOfKitsAvailable || 0)} */}
    //         {props.row.noOfKitsAllocated || 0}
    //       </Typography>
    //     );
    //   },
    // },

    {
      field: 'noOfKitsDamaged',
      headerName: 'Cards Damaged',
      flex: 2,
      minWidth: 150,
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.noOfKitsDamaged || 0}
          </Typography>
        );
      },
    },
    {
      field: 'noOfKitsAvailable',
      headerName: 'Cards In Stock',
      flex: 2,
      minWidth: 150,
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.noOfKitsAvailable || 0}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <CIMS_DataGrid
        data={listBinAllocationQuery.data?.records || []}
        columns={COLUMN_DEF}
        bgColor="white"
        //
        idColumn={'id'}
        loading={listBinAllocationQuery.isLoading}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        page={page}
        onPageChange={(page) => {
          setPage(page);
        }}
        rowCount={listBinAllocationQuery.data?.totalItems}
        paginationMode="server"
        //
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                justifyContent={'space-between'}
                alignItems={'center'}
                py={1}
              >
                <SummaryTab
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />

                <GridToolbarQuickFilter
                  autoFocus
                  variant="outlined"
                  sx={{
                    bgcolor: 'white',
                    color: '#999DA8',
                    height: '35px',
                    minWidth: '320px',
                    borderRadius: '9px',
                  }}
                />
              </Stack>
            );
          },
        }}
      />
    </>
  );
};

export default ListOfBankCorrespondent;
