interface IProps {
  color?: string;
}

export const NearMeIcon = ({ color = 'black' }: IProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.13083 7.00291L6.24768 10.2333C6.45703 10.8388 7.30596 10.8591 7.54393 10.2642L10.748 2.25514C10.9709 1.69798 10.4273 1.14065 9.86477 1.34959L1.6502 4.40052C1.0499 4.62347 1.04857 5.47206 1.64815 5.69691L5.13083 7.00291Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const UserIcon = ({ color = 'black' }: IProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2005 10.7992L10.2006 8.99938C10.2007 8.0052 9.3948 7.19922 8.40062 7.19922H3.60098C2.60695 7.19922 1.8011 8.00498 1.80098 8.99901L1.80078 10.7992M7.80078 2.99922C7.80078 3.99333 6.99489 4.79922 6.00078 4.79922C5.00667 4.79922 4.20078 3.99333 4.20078 2.99922C4.20078 2.00511 5.00667 1.19922 6.00078 1.19922C6.99489 1.19922 7.80078 2.00511 7.80078 2.99922Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const VerifyIcon = ({ color = '#36913F' }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3996 7.99766C14.3996 11.5323 11.5342 14.3977 7.99961 14.3977C4.46499 14.3977 1.59961 11.5323 1.59961 7.99766C1.59961 4.46303 4.46499 1.59766 7.99961 1.59766C9.00374 1.59766 9.95385 1.8289 10.7996 2.24104M13.1996 3.99766L7.59961 9.59766L5.99961 7.99766"
        stroke={color}
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Verify2Icon = ({ color = '#36913F' }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#1AA260" />
      <path
        d="M10.3996 6.19922L6.81985 9.79922L5.59961 8.57208"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const CancelIcon = ({ color = '#36913F' }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#FF4747" />
      <path d="M11 5L5 11M11 11L5 5" stroke="white" stroke-linecap="round" />
    </svg>
  );
};
export const DelayIcon = ({ color = '#36913F' }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#FFE147" />
      <path
        d="M11.3101 4.10914C11.1148 3.91388 10.7982 3.91388 10.603 4.10914C10.4077 4.3044 10.4077 4.62098 10.603 4.81625L11.3101 4.10914ZM11.6464 5.85972C11.8417 6.05499 12.1583 6.05499 12.3536 5.85972C12.5488 5.66446 12.5488 5.34788 12.3536 5.15262L11.6464 5.85972ZM8.32609 7.03516C8.32609 6.75901 8.10223 6.53516 7.82609 6.53516C7.54994 6.53516 7.32609 6.75901 7.32609 7.03516H8.32609ZM7.32609 8.5424C7.32609 8.81854 7.54994 9.0424 7.82609 9.0424C8.10223 9.0424 8.32609 8.81854 8.32609 8.5424H7.32609ZM6.66667 3.03516C6.39052 3.03516 6.16667 3.25901 6.16667 3.53516C6.16667 3.8113 6.39052 4.03516 6.66667 4.03516V3.03516ZM8.86956 4.03516C9.14571 4.03516 9.36956 3.8113 9.36956 3.53516C9.36956 3.25901 9.14571 3.03516 8.86956 3.03516V4.03516ZM10.2551 5.50044C10.0599 5.69571 10.0599 6.01229 10.2551 6.20755C10.4504 6.40281 10.767 6.40281 10.9622 6.20755L10.2551 5.50044ZM11.4783 4.98443L11.8318 4.63088L11.4783 4.98443ZM7.32609 7.03516V8.5424H8.32609V7.03516H7.32609ZM6.66667 4.03516H8.86956V3.03516H6.66667V4.03516ZM10.9622 6.20755L11.8318 5.33798L11.1247 4.63088L10.2551 5.50044L10.9622 6.20755ZM11.1522 8.63661C11.1522 10.4736 9.66303 11.9627 7.82609 11.9627V12.9627C10.2153 12.9627 12.1522 11.0258 12.1522 8.63661H11.1522ZM7.82609 11.9627C5.98914 11.9627 4.5 10.4736 4.5 8.63661H3.5C3.5 11.0258 5.43685 12.9627 7.82609 12.9627V11.9627ZM4.5 8.63661C4.5 6.79966 5.98914 5.31052 7.82609 5.31052V4.31052C5.43685 4.31052 3.5 6.24737 3.5 8.63661H4.5ZM7.82609 5.31052C9.66303 5.31052 11.1522 6.79966 11.1522 8.63661H12.1522C12.1522 6.24737 10.2153 4.31052 7.82609 4.31052V5.31052ZM10.603 4.81625L11.1247 5.33798L11.8318 4.63088L11.3101 4.10914L10.603 4.81625ZM11.1247 5.33798L11.6464 5.85972L12.3536 5.15262L11.8318 4.63088L11.1247 5.33798Z"
        fill="white"
      />
    </svg>
  );
};
