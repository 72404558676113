/** @format */

import { Box, Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import { useEffect, useState } from 'react';
import { getTcsPostings, getTcsSummary } from '../../../../../../api/ledger';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { GridColumns } from '@mui/x-data-grid';
import Vega_Datagrid from '../../../../../common/v3/Vega_Datagrid';
import { dateFormat } from '../../../../../../mcc/variable';
import { toLowerCase } from '../../../../../../constants/commonFunction';

interface Transaction {
  id: string;
  accountId: string;
  transactionId: string;
  amount: number;
  currency: string;
  merchantLocation: string;
  merchantCategory: string;
  txnAmount: number;
  tcsEligibleTxnAmount: number;
  createdAt: string;
}

export type CustomerDataGridRow = {
  key: string;
  value: string | number;
};

interface ITcsListStateProps {
  data: Transaction[];
  loading: boolean;
}

const ListOfTCS = () => {
  const { accountData } = useSupport();
  const [tcsListState, setTcsListState] = useState<ITcsListStateProps>({
    data: [],
    loading: false,
  });

  const COLUMN_DEF: GridColumns = [
    {
      field: 'transactionId',
      headerName: 'Txn ID',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },

    {
      field: 'createdAt',
      headerName: 'Transaction Time',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        return <Vega_Text text={dateFormat(props.row.createdAt)} />;
      },
    },
    {
      field: 'merchantCategory',
      headerName: 'Merchant Category',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        return <Vega_Text text={toLowerCase(props.row.merchantCategory)} />;
      },
    },

    {
      field: 'txnAmount',
      headerName: 'Txn Ampunt',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'tcsEligibleTxnAmount',
      headerName: 'TCS eligible txn amount',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'amount',
      headerName: 'TCS Collected',
      flex: 1,
      minWidth: 100,
    },
  ];

  const getTcsList = async (accountId: string) => {
    setTcsListState({ data: [], loading: true });
    try {
      const response = await getTcsPostings(accountId);
      setTcsListState({ data: response, loading: false });
    } catch (error) {
      setTcsListState({ data: [], loading: false });
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getTcsList(accountData.accountId);
    }
  }, []);
  return (
    <>
      <Vega_Datagrid
        data={tcsListState.data}
        idColumn="id"
        columns={COLUMN_DEF}
        loading={tcsListState.loading}
      />
    </>
  );
};

export default ListOfTCS;
