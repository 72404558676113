import axios from 'axios';
import { BASE_URL, GETWAY, PLANS } from './endpoints';

const PLAN_BASE_URL = `${BASE_URL}${PLANS.BASE}`;

export const addPlan = (clientId: string, data: any) => {
  return axios.post(`${PLAN_BASE_URL}/?clientId=${clientId}`, data);
};

// export const fetchPlansList = (clientId: string, planType: string) => {
//   return axios.get(
//     `${PLAN_BASE_URL}/client?clientId=${clientId}&planType=${planType}`
//   );
// };
export const fetchPlanData = (data: any) => {
  return axios.post(`${PLAN_BASE_URL}/list`, data);
};
