import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

interface Props extends BoxProps {
  children: ReactNode;
}

const Vega_PageContent = ({ children, ...rest }: Props) => {
  return (
    <Box style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }} {...rest}>
      {children}
    </Box>
  );
};

export default Vega_PageContent;
