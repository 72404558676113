import { Grid } from '@mui/material';
import VegaFormInputFieldV2 from '../../../../../../../common/v2/VegaFormInputField';
import VegaMultiSelect, {
  VegaMultiSelectOption,
} from '../../../../../../../common/v2/VegaMultiSelect';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../common/v2/VegaSelectV2';

type Props = {
  selectedCondition?: string;
  selectedOptions?: VegaMultiSelectOption[];
  conditionOptions: VegaSelectOptionV2[];
  valueOptions: VegaMultiSelectOption[];
  valueTitle: string;
  onConditionChange: (value: string) => void;
  onSelectValueChange: (values: VegaMultiSelectOption[]) => void;
};

export const InclusionExclusionInput = ({
  conditionOptions,
  valueOptions,
  valueTitle,
  onConditionChange,
  onSelectValueChange,
  selectedCondition,
  selectedOptions,
}: Props) => {
  return (
    <Grid container columns={6} columnGap={2}>
      <Grid item xs={1}>
        <VegaFormInputFieldV2 label="Condition">
          <VegaSelectV2
            placeholder="Select Condition"
            options={conditionOptions}
            value={selectedCondition ?? ''}
            onChange={(e) => onConditionChange(e.target.value as string)}
          />
        </VegaFormInputFieldV2>
      </Grid>
      <Grid item xs={2}>
        <VegaFormInputFieldV2 label={valueTitle}>
          <VegaMultiSelect
            options={valueOptions}
            selectedOptions={selectedOptions}
            onSelect={onSelectValueChange}
          />
        </VegaFormInputFieldV2>
      </Grid>
    </Grid>
  );
};
