import { CommentOutlined } from '@mui/icons-material';
import { Stack, Button, CircularProgress } from '@mui/material';
import { useState } from 'react';
import VegaContainedButton from '../../common/VegaContainedButton';
import { CommentTextField } from '../CommentTextField';

export const CheckerCommentInitialView = (data: {
  didClickAddComment: (comment: string) => void;
  didClickCancel: () => void;
  initialCommentValue?: string;
  loading: boolean;
  disabled: boolean;
}) => {
  const [comment, setComment] = useState<string>(
    data.initialCommentValue ?? ''
  );

  const isInputValid = () => {
    if (data.disabled) {
      return false;
    }
    return comment.length > 0 && comment != data.initialCommentValue;
  };

  function onAddCommentClick() {
    data.didClickAddComment(comment);
  }

  const EndAdornmentView = () => {
    if (data.loading) {
      return <CircularProgress size={18} color="info" />;
    }
    return (
      <Stack direction={'row'} spacing={2}>
        <VegaContainedButton
          text={'Add Comment'}
          startIcon={<CommentOutlined />}
          isPrimary
          onClick={onAddCommentClick}
          disabled={isInputValid() == false}
        />
        <Button color="info" onClick={data.didClickCancel}>
          Cancel
        </Button>
      </Stack>
    );
  };

  return (
    <CommentTextField
      comment={comment}
      didUpdateComment={setComment}
      endAdornment={<EndAdornmentView />}
    />
  );
};
