import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { VegaCard, VegaTable } from '../../../components/common';

import { BORDER_GRAY, GREEN, GREY } from '../../../constants/style';
import VegaLabel from '../../../components/common/VegaLabel';
import { toLowerCase } from '../../../constants/commonFunction';
import { ACCESS_FEATURES } from '../../role';

const DEFAULT_DATA = [
  {
    roleName: 'HQ_USER',
    access: ACCESS_FEATURES.HQ_USER_ACCESS_FEATURES,
  },
  {
    roleName: 'BRANCH_ADMIN',
    access: ACCESS_FEATURES.BRANCH_ADMIN_ACCESS_FEATURES,
  },
  {
    roleName: 'BRANCH_USER',
    access: ACCESS_FEATURES.BRANCH_USER_ACCESS_FEATURES,
  },
  {
    roleName: 'CORPORATE_ADMIN',
    access: ACCESS_FEATURES.CORPORATE_ADMIN_ACCESS_FEATURES,
  },
];
function MCCRole() {
  const columnDef = [
    {
      field: 'roleName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Role Name
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props: any) => {
        return <Typography>{toLowerCase(props.row.roleName)}</Typography>;
      },
    },

    {
      field: 'userRoles',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Access
        </Typography>
      ),
      flex: 2,
      renderCell: (props: any) => {
        return (
          <Box
            sx={{
              border: BORDER_GRAY,
              borderRadius: '5px',
              // p: 0.5,
              my: 0.5,
              height: '90%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              overflow: 'auto',
            }}
          >
            {props.row.access.map((access: string) => (
              <VegaLabel text={toLowerCase(access)} color={'primary'} />
            ))}
          </Box>
        );
      },
    },
  ];

  return (
    <Stack spacing={3}>
      {/* ------------------------------------ROLE DETAILS------------------------------------ */}

      <VegaCard fullHeight noPad>
        <Box style={{ padding: '20px' }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: '500', color: GREY.darkest }}
          >
            Role Details
          </Typography>
        </Box>
        <VegaTable
          noTopRadius
          columnDef={columnDef}
          data={DEFAULT_DATA}
          idColumn="roleName"
        />
      </VegaCard>
    </Stack>
  );
}

export default MCCRole;
