// Role based access control
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  addEntityComment,
  getEntityStatus,
  getStatusForEntities,
  submitEntitiesToChecker,
  submitEntitiesToMaker,
  updateEntityComment,
} from '../api/entityAuth';
import {
  EntityAuthStatus,
  EntityComment,
  EntityCommentStatus,
  EntityStatus,
  UserMakerCheckerRole,
} from '../types/entityAuth';
import { useClientAuth } from './ClientProvider';
import { useProgramData } from './ProgramDataProvider';

export interface MakerCheckerUserProps {
  role: UserMakerCheckerRole;
  userId: string;
}

export type MakerCheckerEntityContextType = {
  submitEntitiesToMaker: (ids: string[]) => Promise<any>;
  submitEntitiesToChecker: (ids: string[]) => Promise<any>;
  getEntityAuthStatus: (data: {
    entityId: string;
    cachedValue: boolean;
    role?: UserMakerCheckerRole;
  }) => Promise<EntityAuthStatus>;
  areChangesToEntityAllowed: (status: EntityStatus) => boolean;
  canChangeStatusOfEntity: (status: EntityStatus) => boolean;
  fetchStatusForEntities: (
    ids: string[],
    user?: MakerCheckerUserProps | null
  ) => Promise<Map<string, EntityAuthStatus>>;
  entityAuthStatuses: Map<string, EntityAuthStatus>;
  clearEntityStatusCache: () => void;
  updateEntityStatusInCache: (data: {
    entityId: string;
    authStatus: EntityAuthStatus;
  }) => void;
  addComment: (data: {
    authorizationRequestId: string;
    comment: string;
  }) => Promise<EntityComment>;
  updateComment: (data: {
    commentId: string;
    comment: string;
  }) => Promise<EntityComment>;
  user?: MakerCheckerUserProps | null;
  controlRole: UserMakerCheckerRole;
};

const MakerCheckerEntityContext = createContext<MakerCheckerEntityContextType>({
  clearEntityStatusCache: () => {},
  updateEntityStatusInCache: () => {},
  submitEntitiesToMaker: () => Promise.reject(),
  submitEntitiesToChecker: () => Promise.reject(),
  getEntityAuthStatus: () => Promise.reject(),
  areChangesToEntityAllowed: (status: EntityStatus) => false,
  user: undefined,
  fetchStatusForEntities: () => Promise.reject(),
  entityAuthStatuses: new Map(),
  addComment: () => Promise.reject(),
  updateComment: () => Promise.reject(),
  controlRole: UserMakerCheckerRole.NOT_DEFINED,
  canChangeStatusOfEntity: () => false,
});

export const useMakerCheckerEntity = () => {
  return useContext(MakerCheckerEntityContext) as MakerCheckerEntityContextType;
};

export const MakerCheckerEntityProvider = (props: { children: ReactNode }) => {
  const [loggedInUserDetails, setLoggedinUserDetails] =
    useState<MakerCheckerUserProps | null>(null);
  const { user, isAdmin } = useClientAuth();
  const { selectedProgram } = useProgramData();
  const [entityAuthStatuses, setEntityAuthStatues] = useState<
    Map<string, EntityAuthStatus>
  >(new Map());
  const [controlRole, setControlRole] = useState<UserMakerCheckerRole>(
    UserMakerCheckerRole.NOT_DEFINED
  );

  const _getEntityAuthStatus = async (data: {
    entityId: string;
    cachedValue: boolean;
  }) => {
    try {
      if (data.cachedValue) {
        const existingValue = entityAuthStatuses.get(data.entityId);
        if (existingValue) {
          return existingValue;
        }
      }
      let role = loggedInUserDetails?.role;
      if (!role) {
        throw Error('Role not defined');
      }
      const response = await getEntityStatus({
        entityId: data.entityId,
        role: role,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const _canChangeStatusOfEntity = (entityStatus: EntityStatus) => {
    let userIsMaker = loggedInUserDetails?.role == UserMakerCheckerRole.MAKER;
    let userIsChecker =
      loggedInUserDetails?.role == UserMakerCheckerRole.CHECKER;
    const areChangesRequested = entityStatus == EntityStatus.CHANGES_REQUESTED;
    let controllingRole = controlRole;
    if (userIsChecker && controllingRole == UserMakerCheckerRole.MAKER) {
      return false;
    }

    if (userIsMaker && controlRole == UserMakerCheckerRole.CHECKER) {
      return false;
    }

    if (userIsChecker == false && userIsMaker == false) {
      return false;
    }
    if (userIsMaker) {
      return areChangesRequested;
    }
    if (userIsChecker) {
      return areChangesRequested == false;
    }
    return false;
  };

  const _areChangesToEntityAllowed = (entityStatus: EntityStatus) => {
    let userIsMaker = loggedInUserDetails?.role == UserMakerCheckerRole.MAKER;
    let userIsChecker =
      loggedInUserDetails?.role == UserMakerCheckerRole.CHECKER;
    let controllingRole = controlRole;
    if (userIsChecker && controllingRole == UserMakerCheckerRole.MAKER) {
      return false;
    }

    if (userIsMaker && controlRole == UserMakerCheckerRole.CHECKER) {
      return false;
    }

    if (userIsChecker == false && userIsMaker == false) {
      return false;
    }

    if (userIsMaker) {
      let isDraft = entityStatus == EntityStatus.DRAFT;
      let areChangesRequested = entityStatus == EntityStatus.CHANGES_REQUESTED;
      return isDraft || areChangesRequested;
    }

    if (userIsChecker) {
      return false;
    }

    return false;
  };

  const getRoleOfUser = (userId: string) => {
    if (selectedProgram && userId) {
      const isChecker = selectedProgram.checkerUserId == userId;
      const isMaker = selectedProgram.makerUserId == userId;
      if (isMaker || isAdmin) {
        return UserMakerCheckerRole.MAKER;
      } else if (isChecker) {
        return UserMakerCheckerRole.CHECKER;
      }
      return UserMakerCheckerRole.NOT_DEFINED;
    }
    return UserMakerCheckerRole.NOT_DEFINED;
  };

  async function _addCommentOnEntity(data: {
    authorizationRequestId: string;
    comment: string;
  }): Promise<EntityComment> {
    try {
      const response = await addEntityComment({
        authorizationRequestId: data.authorizationRequestId,
        comments: data.comment,
        status: EntityCommentStatus.DRAFT,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _updateCommentOnEntity(data: {
    commentId: string;
    comment: string;
  }): Promise<EntityComment> {
    try {
      const response = await updateEntityComment({
        id: data.commentId,
        comment: data.comment,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _submitEntitiesToMaker(ids: string[]) {
    try {
      const userId = user?.userId;
      if (!userId) {
        throw new Error('User not found');
      }
      const response = await submitEntitiesToMaker({
        ids: ids,
        userId: userId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _submitEntitiesToChecker(ids: string[]) {
    try {
      const userId = user?.userId;
      if (!userId) {
        throw new Error('User not found');
      }
      const response = await submitEntitiesToChecker({
        ids: ids,
        userId: userId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchStatusForEntities(
    entityIds: string[],
    user?: MakerCheckerUserProps | null
  ) {
    try {
      let role = user?.role;
      if (!role || role == UserMakerCheckerRole.NOT_DEFINED) {
        console.error('Role not found while fetching entities status ', role);
        throw Error('Role not found while fetching entities status ' + role);
      }
      const statuses = await getStatusForEntities(entityIds, role);
      const entityStatusMap = new Map<string, EntityAuthStatus>();
      statuses.forEach((item) => {
        entityStatusMap.set(item.entityId, item);
      });
      setEntityAuthStatues(entityStatusMap);
      return entityStatusMap;
    } catch (error) {
      throw error;
    }
  }

  const updateMapValue = (key: string, newValue: EntityAuthStatus) => {
    setEntityAuthStatues((prevState) => {
      const updatedMap = new Map(prevState);
      updatedMap.set(key, newValue);
      return updatedMap;
    });
  };

  function _updateEntityStatusInCache(data: {
    entityId: string;
    authStatus: EntityAuthStatus;
  }) {
    updateMapValue(data.entityId, data.authStatus);
  }

  function _clearCache() {
    setEntityAuthStatues(new Map());
  }

  useEffect(() => {
    const authStatuses = Array.from(entityAuthStatuses.values());
    const hasCheckerPendingEntities =
      authStatuses.filter((item) => item.status == EntityStatus.CHECKER_PENDING)
        .length > 0;
    if (hasCheckerPendingEntities) {
      setControlRole(UserMakerCheckerRole.CHECKER);
    }
    const hasChangesRequestedEntities =
      authStatuses.filter(
        (item) => item.status == EntityStatus.CHANGES_REQUESTED
      ).length > 0;
    if (hasChangesRequestedEntities) {
      setControlRole(UserMakerCheckerRole.MAKER);
    }
  }, [entityAuthStatuses]);

  useEffect(() => {
    if (!user) return;
    const role = getRoleOfUser(user.userId);
    setLoggedinUserDetails({
      userId: user.userId,
      role: role,
    });
  }, [user, selectedProgram]);

  return (
    <MakerCheckerEntityContext.Provider
      value={{
        getEntityAuthStatus: _getEntityAuthStatus,
        areChangesToEntityAllowed: _areChangesToEntityAllowed,
        canChangeStatusOfEntity: _canChangeStatusOfEntity,
        user: loggedInUserDetails,
        addComment: _addCommentOnEntity,
        updateComment: _updateCommentOnEntity,
        submitEntitiesToMaker: _submitEntitiesToMaker,
        submitEntitiesToChecker: _submitEntitiesToChecker,
        fetchStatusForEntities: _fetchStatusForEntities,
        entityAuthStatuses: entityAuthStatuses,
        updateEntityStatusInCache: _updateEntityStatusInCache,
        clearEntityStatusCache: _clearCache,
        controlRole: controlRole,
      }}
    >
      {props.children}
    </MakerCheckerEntityContext.Provider>
  );
};
