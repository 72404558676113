/** @format */

export const getErrorMessageFromErrorObj = (
  error: any,
  defaultMessage = 'Something went wrong'
) => {
  const errorResp = error?.response?.data?.apierror;

  if (errorResp?.status === 'FAILED') {
    return defaultMessage;
  } else {
    return errorResp?.debugMessage || errorResp?.subErrors || defaultMessage;
  }
};

export interface PaginatedResponse<T> {
  records: T[];
  pageNumber: number;
  numberOfItems: number;
  totalPages: number;
  totalItems: number;
}
