import { Typography } from '@mui/material';
import { VegaAgent } from '../../../types/collection';
import { VegaTable } from '../../common';

type Props = {
  data: VegaAgent[];
  loading: boolean;
};

const ListOfAgents = ({ data, loading }: Props) => {
  const columnDef = [
    {
      field: 'agent_name',
      headerName: <Typography variant="body2">Name</Typography>,
      flex: 1,
    },
    {
      field: 'agency_name',
      headerName: <Typography variant="body2">Agency</Typography>,
      flex: 1,
    },
    {
      field: 'delinquent_accounts',
      headerName: <Typography variant="body2">Total Count</Typography>,
      flex: 1,
    },
    {
      field: 'outstanding_amount',
      headerName: <Typography variant="body2">Total O/B</Typography>,
      flex: 1,
    },
    {
      field: 'overdue_amount',
      headerName: <Typography variant="body2">Total O/D</Typography>,
      flex: 1,
    },
    {
      field: 'recovered_amount',
      headerName: <Typography variant="body2">Amount recovered</Typography>,
      flex: 1,
    },
    {
      field: 'performance',
      headerName: <Typography variant="body2">Performance</Typography>,
      flex: 1,
    },
  ];
  return (
    <VegaTable
      border
      data={data}
      columnDef={columnDef}
      idColumn={'agent_id'}
      loading={loading}
    />
  );
};

export default ListOfAgents;
