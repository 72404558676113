import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { VegaAccordian, VegaTextField } from '../../../../components/common';
import { BORDER_GRAY, GREY, PRIMARY } from '../../../../constants/style';
import { fetchWalletTransactionFromRefId } from '../../api/wallet';
import { BACKGROUND } from '../../../../constants/style';

interface IProps {
  state: {
    transactionRefId: string
    parentTransactionId: string | null
};
  
}

const DEFULT_DATA = [
  {
    currencyType: 'Dolllar (USD)',
    amountUsed: 1000
  },
  {
    currencyType: 'Pound (GBP)',
    amountUsed: 1000
  },
  {
    currencyType: 'Euro (EUR)',
    amountUsed: 1000
  }
];

const BalanceView = ({ state }: IProps) => {
  console.log('🚀 ~ file: BalanceView.tsx:33 ~ BalanceView ~ state:', state.transactionRefId);
  const [walletDetails, setWalletDetails] = useState<any[]>([]);
  useEffect(() => {
    fetchListOfWalletTransactionFromRefId(state.transactionRefId);
  }, [state.transactionRefId]);
  const fetchListOfWalletTransactionFromRefId = (transactionRefId: string) => {
    fetchWalletTransactionFromRefId({refId: transactionRefId})
    .then((res)=> {
        console.log("res.data::: ",res.data);
        setWalletDetails(res.data);
    })
    .catch((err) => {
        console.log('error getting wallet transaction')
    });
  }

  console.log("walletDetails1: ", walletDetails);
  return (
    <Box sx={{ p: 2 }}>
    {state.parentTransactionId && <Typography>Parent Txn Id: {state.parentTransactionId}</Typography>}
      { walletDetails.length > 0 &&
          <Box sx={{ my: 2 }}>
                <>
                  <Grid
                    container
                    sx={{
                      borderTop: BORDER_GRAY,
                      borderBottom: BORDER_GRAY,
                    }}
                  >
                    <Grid
                      item
                      sm={3}
                      sx={{
                        textAlign: 'center',
                        py: 1,
                        bgcolor: PRIMARY.light,
                        color: BACKGROUND.WHITE
                      }}
                    >
                      <Typography>Wallet Currency</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        textAlign: 'center',
                        py: 1,
                        bgcolor: PRIMARY.light,
                        color: BACKGROUND.WHITE
                      }}
                    >
                      <Typography>Amount</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        textAlign: 'center',
                        py: 1,
                        bgcolor: PRIMARY.light,
                        color: BACKGROUND.WHITE
                      }}
                    >
                      <Typography>Txn Type</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        textAlign: 'center',
                        py: 1,
                        bgcolor: PRIMARY.light,
                        color: BACKGROUND.WHITE
                      }}
                    >
                      <Typography>Txn Category</Typography>
                    </Grid>
                  </Grid>
                  {
                    walletDetails.map((balance) => 
                        (<Grid container>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        borderLeft: BORDER_GRAY,
                        borderBottom: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                        color: GREY.grey_light
                      }}
                    >
                      <Typography>{balance.currency}</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        borderLeft: BORDER_GRAY,
                        borderBottom: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                        color: GREY.grey_light
                      }}
                    >
                      <Typography>{balance.amount}</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        borderLeft: BORDER_GRAY,
                        borderBottom: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                        color: GREY.grey_light
                      }}
                    >
                      <Typography>{balance.transactionIndicator}</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        borderRight: BORDER_GRAY,
                        borderLeft: BORDER_GRAY,
                        borderBottom: BORDER_GRAY,
                        textAlign: 'center',
                        py: 1,
                        color: GREY.grey_light,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                      }}
                    >
                      <Typography >{balance.transactionCode.replaceAll('_', ' ')}</Typography>
                    </Grid>
                  </Grid>)
                    )
                  }
                </>
          </Box>
        }
    </Box>
  );
};

export default BalanceView;
