/** @format */

export const ROUTE_CODE = {
  CCMS: '/ccms',
  MCC: '/mcc',
};

export const DASHBOARD_ROUTES = {
  SIMULATOR: 'simulator',
  MCC_SIMULATOR: 'simulator',
  CLIENT_DASHBOARD: 'client',
  PROGRAM_DASHBOARD: 'program',
  BACK_OFFICE: 'back_office',
  MCC_PROGRAM: 'hq',
  MCC_BACK_OFFICE: 'branch',
  MCC_CORPORATE: 'corporate',
  CIMS_BRANCH: 'branch',
  CIMS_ADMIN: 'admin',
  CIMS: 'cims',
};

export const BACK_OFFICE_ROUTES = {
  ACC_CLOSE_DETAILS: 'acc-close-details',
};

export const SCOPE_MODULE_ROUTE_MAP: Record<string, any> = {
  hq: 'FOREX_HQ',
  back_office: 'FOREX_BRANCH',
  corporate: 'FOREX_CORPORATE',
  simulator: 'FOREX_SIMULATOR',
  branch: 'FOREX_BRANCH',
  BRANCH: 'FOREX_BRANCH',
  HQ: 'FOREX_HQ',
  BACK_OFFICE: 'FOREX_BRANCH',
  CORPORATE: 'FOREX_CORPORATE',
  SIMULATOR: 'FOREX_SIMULATOR',
};

export enum RewardsRoute {
  CAMPAIGN_CONFIGURATION = 'campaign-configuration',
  REWARDS_HOME = 'rewards-loyalty',
  POINT_BASED = 'point-based',
  NON_POINT_BASED = 'non-point-base',
  BENEFIT_INVENTORY = 'benefit-inventory',
}
export enum SupportRoute {
  SUPPORT = 'support',
  ACCOUNT_DETIAILS = 'account-details',
}
export enum BinCharterRoute {
  BIN_CONFIGURATION = 'bin-configuration',
  BIN_DETAILS = 'bin-details',
}

export const NESTED_VIEW = 'nested_view';

export const ROUTES = {
  LOGIN: '/login',
  SBM_LOGIN: '/sbm-login',
  AGENT_LOGIN: '/agent/login',
  HQ_LOGIN: '/hq/login',
  COLENDER_LOGIN: '/colender/login',
  SET_NEW_PASSWORD: '/new-password',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  GETTING_STARTED: '/',
  PROGRAM: '/program',
  POLICY: '/policy',
  APPLICATION_MANAGE: '/application-manage',
  LENDER_MANAGE: '/lender-manage',
  CUSTOMERS: '/customers',
  ACCOUNTS: '/accounts',
  TRANSACTIONS: '/transactions',
  GENERAL_LEDGER: '/general-ledger',
  BILLS: '/bills',
  SUPPORT: '/support',
  SEARCH_BY_CUSTOMER: '/search-by-customer',
  COLLECTIONS: '/collections',
  REPORTS: '/reports',

  HOME: '/home',
  RESOURCES: '/resources',
  EVENT: '/event',
  USER_ACCESS: '/user-access',
  UNI_STANDARDS: '/universal-standards',
  PAYMENT_DUE: '/payment-due-dates',
  PROGRAM_MANAGE: '/program-manage',
  PARTNER_MANAGE: '/partner-manage',
  SERVICE_SETUP: '/service-setup',
  BIN_CHARTER: '/bin-charter',
  SETTING: '/setting',
  OPERATIONS: '/operations',

  ADD_ON: '/add-on',
  REWARDS: '/rewards',
  REWARDS_LOYALTY: '/rewards-loyalty',
  CARD_MANAGEMENT: '/card-management',
  ANALYTICS: '/analytics',
  CHARGE_BACK: '/carge-back',
  AGENT_VIEW: '/agent-view',
  MANAGE_TEAMS: '/manage-teams',
  ROLES_AND_ACCESS: '/roles-and-access',
  FINANCE_CENTER: '/finance-center',
  CLIENT_MORE_OPTIONS: '/client-more-options',
  FRAUD_AND_RISK_MANAGEMENT: '/fraud-and-risk-management',
};

export const SUB_ROUTES = {
  INVOICE: '/invoice',
  INCOME_EXPENSE: '/income-expense',
  PROFIT_LOSS: '/profit-loss',
};
export const NON_ROUTES = {
  COMPANY_PROFILE: '_company_profile',
  MEDIA: '_media',
  USER_PROFILE: '_user_profile',
  TEAM: '_team',
  INVOICES: '_invoices',
};

export const MCC_ROUTES = {
  // MCC_BACK_OFFICE //
  APPLICATION_MANAGEMENT: '/application-management',
  CORPORATE_MANAGEMENT: '/corporate-management',
  CORPORATE_TRANSACTION: '/corporate-transaction',
  TEAM_MANAGEMENT: '/team-management',

  USER_MANAGEMENT: '/user-management',
  INVENTORY_MANAGEMENT: '/inventory-management',
  RELEASE_TRANSACTION: '/release-transaction',
  SERVICES: '/services',
  CARD_CHECKER: '/card-checker',
  CARD_MAKER: '/card-maker',
  CARD_TRANSACTION: '/card-transaction',
  ANALYTICS: '/analytics',
  REPORT: '/report',

  // MCC_PROGRAM ROUTES
  CREATE_PROGRAM: '/create-program',
  PARTNER_MANAGEMENT: '/partner-management',
  CREATE_PARTNER: '/create-partner',
  CREATE_BRANCH: '/create-branch',
  CREATE_ROLE: '/create-role',
  POLICIES: '/policies',
  PROGRAM_ALLOCATION: '/program-allocation',
  INSTITUTIONAL_RESOURCES: '/institutional-resources',
  CHECKER_TEAM_MANAGEMENT: '/team-management',
  TEAM_MANAGEMENT_CHECKER: '/team-management-checker',
  CORPORATE_TRANSACTION_CHECKER: '/corporate-transaction-checker',
  TEAM_MANAGEMENT_MAKER: '/team-management-maker',
  CHECKER_APPLICATION_MANAGEMENT: '/application-management',
  CHECKER_CARD_TRANSACTION: '/card-transaction',
  CHECKER_INVENTORY_MANAGEMENT: '/inventory-management',

  // CORPORATE ROUTES
  CORPORATE_CARD_ANALYTICS: '/card-analytics',
  CORPORATE_CARD_SALE: '/card-sale',
  CORPORATE_APPLICATION_MANAGEMENT: '/application-management',
  CORPORATE_CARD_TRANSACTION: '/card-transaction',
  CORPORATE_INVOICES: '/invoices',

  // SIMULATOR
  SIMULATOR_TRANSACTION: '/transaction',
};

export const SOLFIN_ROUTES = {
  /////////////////////  Agent \\\\\\\\\\\\\\\\\\\\\\\\
  LEADS_FOR_AGENT: '/leads',
  APPLICATION: '/applications',
  PRE_DISBURSAL: '/pre-disbursal',
  APPLICATION_REVIEW: '/application-review',

  ////////////////////// HQ \\\\\\\\\\\\\\\\\\\\\\\\\
  LEADS: '/leads',
  ALL_APPLICATION: '/all-application',
  OFFERS: '/offers',
  PRE_DISBURSEMENT: '/pre-disbursement',
  ACTIVE_LOANS: '/active-loans',
  PORTFOLIO: '/portfolio',
  MANAGE_CO_LENDERS: '/manage-co-lenders',
  SEARCH: '/search',
  REPORT: '/report',
  AGENT: '/agent',

  ////////////////////// HQ \\\\\\\\\\\\\\\\\\\\\\\\\
  COLENDING_OFFERS: '/colending-offers',
  COLENDING_ACTIVE_LOANS: '/colending-active-loans',
  SENCTION_LETTER: '/section-letter',
};

export const CIMS_ROUTES = {
  /////////////// Branch \\\\\\\\\\\\\\\\\\\\
  // CIMS
  CARD_ALLOTMENT: '/card-allotment',
  CARD_INVENTORY: '/card-inventory',
  ASSIGN_INVENTORY: '/assign-inventory',

  CARD_ORDER_REQUESTS: '/card-order-requests',
  DELIVERY_ORDER_REQUESTS: '/delivery-order-requests',

  //////////////// Admin \\\\\\\\\\\\\\\\\\\\\
  HOME: '/home',
  UPDATE_CARD_INVENTORY: '/update-card-inventory',
  CARD_MASTER: '/card-master',
  POLICIES: '/policies',
  TEAM_MANGEMENT: '/team-management',
  BRANCH_MANGEMENT: '/branch-management',
  REPORTS: '/reports',
};

// export const CIMS_BASIC_ROUTES = {
//   ADMIN_HOME: 'ADMIN_HOME',
//   UPDATE_CARD_INVENTORY: 'UPDATE_CARD_INVENTORY',
//   CARD_INVENTORY: 'CARD_INVENTORY',
//   ASSIGN_CARDS_TO_ENTITY: 'ASSIGN_CARDS_TO_ENTITY',
//   ENTITIES_MASTER: 'ENTITIES_MASTER',
//   CARD_MASTER: 'CARD_MASTER',
//   BIN_MASTER: 'BIN_MASTER',
//   PROGRAM_MASTER: 'PROGRAM_MASTER',
//   TEAM_MANAGEMENT: 'TEAM_MANAGEMENT',
//   REPORTS: 'REPORTS',
//   POLICY: 'POLICY',
//   CARD_ALLOTMENT: 'CARD_ALLOTMENT',
// };

export const CIMS_BRANCH_SUB_MODULES = {
  CIMS_BRANCH_CARD_ALLOTMENT: '/card-allotment',
  CIMS_BRANCH_CARD_INVENTORY: '/card-inventory',
  CIMS_BRANCH_BULK_CARD_REJECT: '/bulk-card-reject',
  CIMS_BRANCH_CARD_ORDER_REQUEST: '/card-order-request',
};

export const CIMS_BC_SUB_MODULES = {
  CIMS_BC_CARD_ALLOTMENT: '/card-allotment',
  CIMS_BC_CARD_INVENTORY: '/card-inventory',
  CIMS_BC_BULK_CARD_REJECT: '/bulk-card-reject',
  CIMS_BC_ASSIGN_CARDS_TO_ENTITY: '/assign-cards-to-entity',
  CIMS_BC_REPORTS: '/reports',
  CIMS_BC_CARD_ORDER_REQUEST: '/card-order-request',
  CIMS_BC_CARD_INDENTATION_REQUEST: '/card-indentation-order',
};

export const CIMS_VENDOR_SUB_MODULES = {
  CIMS_VENDOR_CARD_ORDER_REQUESTS: '/card-order-requests',
  CIMS_VENDOR_DELIVERY_ORDER_REQUESTS: '/delivery-order-requests',
  CIMS_VENDOR_ENTITIES_MANAGEMENT: '/entity-management',
};

export const CIMS_ADMIN_SUB_MODULES = {
  CIMS_ADMIN_HOME: '/home',
  CIMS_ADMIN_ORDER_PHYSICAL_CARD: '/order-physical-card',
  CIMS_ADMIN_UPDATE_CARD_INVENTORY: '/update-card-inventory',
  CIMS_ADMIN_BULK_CARD_REJECT: '/bulk-card-reject',
  CIMS_ADMIN_CARD_INVENTORY: '/card-inventory',
  CIMS_ADMIN_ASSIGN_CARDS_TO_ENTITY: '/assign-cards-to-entity',
  CIMS_ADMIN_ENTITIES_MASTER: '/entities-master',
  CIMS_ADMIN_CARD_MASTER: '/card-master',
  CIMS_ADMIN_BIN_MASTER: '/bin-master',
  CIMS_ADMIN_PROGRAM_MASTER: '/program-master',
  CIMS_ADMIN_TEAM_MANAGEMENT: '/team-management',
  CIMS_ADMIN_ENTITIES_MANAGEMENT: '/entity-management',
  CIMS_ADMIN_DIVISION_MANAGEMENT: '/division-management',
  CIMS_ADMIN_REPORTS: '/reports',
  CIMS_ADMIN_POLICY: '/policy',
  CIMS_ADMIN_BIN_CHARTER: '/bin-charter',
  CIMS_ADMIN_CARD_REJECTION_STATUS: '/rejection-status',
  CIMS_ADMIN_CARD_ORDER_REQUEST: '/card-order-request',
  CIMS_ADMIN_CARD_INDENTATION_REQUEST: '/card-indentation-order',

  // group
  CIMS_ADMIN_MASTERS: '/masters',
};

export const CIMS_MODULES = {
  CIMS_BRANCH: Object.keys(CIMS_BRANCH_SUB_MODULES),
  CIMS_BC: Object.keys(CIMS_BC_SUB_MODULES),
  CIMS_VENDOR: Object.keys(CIMS_VENDOR_SUB_MODULES),
  CIMS_ADMIN: Object.keys(CIMS_ADMIN_SUB_MODULES),
};
