import { FilterList } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import Vega_Text from './Vega_Text';

interface IProps {
  onFilter?: () => void;
}

const Vega_DataGridFilterSection = ({ onFilter }: IProps) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'end'}
      gap={3}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        sx={{ cursor: 'pointer' }}
        onClick={onFilter ? onFilter : () => {}}
      >
        <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
        <Vega_Text text="Filter" fontSize={'0.75rem'} color={'#676B76'} />
      </Stack>
      <GridToolbarQuickFilter
        autoFocus
        variant="outlined"
        size="small"
        sx={{
          bgcolor: 'white',
          color: '#999DA8',
          height: '35px',
          minWidth: '240px',
          borderRadius: '9px',
          fontSize: 10,
        }}
        inputProps={{
          style: {
            fontSize: 12,
          },
        }}
      />
    </Stack>
  );
};

export default Vega_DataGridFilterSection;
