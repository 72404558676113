import axios from 'axios';
import { VegaWebHook } from '../types/webhook';
import { BASE_URL, WEBHOOK } from './endpoints';

const WEBHOOK_BASE_URL = BASE_URL + WEBHOOK.BASE;

export async function fetchWebhooksForProgram(data: {
  programId: string;
}): Promise<VegaWebHook[]> {
  try {
    const endpoint =
      WEBHOOK_BASE_URL +
      WEBHOOK.LIST_BY_PROGRAM_ID.replace('{programId}', data.programId);
    const response = await axios.get(endpoint);
    const webhooks = response.data as VegaWebHook[];
    return webhooks;
  } catch (error) {
    throw error;
  }
}

interface CreateWebhookRequestParams {
  programId: string;
  url: string;
  event: string;
  secret: string;
}

export async function createWebhook(
  data: CreateWebhookRequestParams
): Promise<VegaWebHook> {
  try {
    const endpoint = WEBHOOK_BASE_URL + WEBHOOK.CREATE;
    const response = await axios.post(endpoint, data);
    return response.data as VegaWebHook;
  } catch (error) {
    throw error;
  }
}

export async function unsubscribeWebhook(webhookId: string): Promise<VegaWebHook> {
  try {
    const endpoint =
      WEBHOOK_BASE_URL + WEBHOOK.UNSUBSCRIBE.replace('{webhookId}', webhookId);
    const response = await axios.put(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
