/** @format */
import { useEffect, useState } from 'react';
import { generateBillForAccount, getBillsForAccount } from '../api/bill';
import CreateBill from '../components/bills/CreateBill';
import ListOfBills from '../components/bills/ListOfBills';
import { Loading, NoResults, VegaPage } from '../components/common';
import { useCustomerAccountData } from '../providers/CustomerDataProvider';
import { useSnackbar } from '../providers/SnackbarProvider';

const Bills = () => {
  const { setSnackbar } = useSnackbar();
  const { selectedAccount, loading } = useCustomerAccountData();
  const [billDate, setBillDate] = useState<any>('');
  const [bills, setBills] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmitClick = () => {
    setIsLoading(true);
    generateBillForAccount(selectedAccount?.account.accountId, billDate)
      .then((res) => {
        setSnackbar('Bill Generated!');
        getListOfBills(selectedAccount?.account.accountId);
        setIsLoading(false);
      })
      .catch((err) => {
        setSnackbar('Failed to generate bill', 'error');
        setIsLoading(false);
      });
  };

  const getListOfBills = async (value: any) => {
    await getBillsForAccount(value)
      .then((res) => {
        setBills(res.data?.records);
      })
      .catch((err) => setSnackbar('Failed to generate bill', 'error'));
  };

  useEffect(() => {
    if (selectedAccount?.account.accountId) {
      getListOfBills(selectedAccount?.account.accountId);
    }
  }, [selectedAccount]);

  if (loading) {
    return <Loading />;
  }

  if (!selectedAccount) {
    return <NoResults text="No Account selected" subtext="Create new account?" />;
  }

  return (
    <VegaPage>
      <CreateBill isLoading={isLoading} setBillDate={setBillDate} billDate={billDate} onCreate={handleSubmitClick} />
      <ListOfBills bills={bills} />
    </VegaPage>
  );
};

export default Bills;
