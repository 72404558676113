import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { Level } from '../../../../types/client';
import { CardAllocationService } from '../../../apis';
import CimsDrawer from '../../../components/CimsDrawer';
import CimsButton from '../../../components/common/CimsButton';
import { MessageAlertIcon } from '../../../components/icon/CIMS_Icons';
import { NEUTRAL_COLORS } from '../../../theme/theme';
import AssignCardsForm, { ICardDetails } from '../AssignCardsForm';
import SelectEntity from './SelectEntity';

interface IProp {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  refetchList: any;
}
function AssignCardsModal({ isOpen, onOpen, onClose, refetchList }: IProp) {
  const hookForm = useForm<{
    entity: {
      entityId: string;
      entityLevel: string;
    };
    cardDetails: ICardDetails;
    binId: string;
    programId: string;
  }>({
    mode: 'all',
    shouldFocusError: true,
  });

  const { userDivisionId } = useClientAuth();
  const { setSnackbar } = useSnackbar();

  const onSubmit = () => {
    // hookForm.handleSubmit((data) => {
    const data = hookForm.getValues();

    CardAllocationService.add({
      binId: data.binId,
      programId: data.programId,
      cardPrintOrderId: null,
      sourceDivisionId: userDivisionId || '',
      destinationDivisionId: data.entity.entityId,
      numberOfKits: data.cardDetails.noOfCards || '0',
      assignCards: true,
    })
      .then((res) => {
        setSnackbar('Cards Assigned Successfully', 'success');
        // cardAllocationQuery.refetch();
        refetchList && refetchList();
        hookForm.reset();
      })
      .catch((err: any) => {
        setSnackbar(
          err.response.data.apierror.debugMessage || 'Error Assigning Cards',
          'error'
        );
      });
    onClose();

    // });
  };
  return (
    <CimsDrawer
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      header="Assign Cards"
      footer={
        <Stack direction={'row'} justifyContent={'end'} flex={1} p={1}>
          <CimsButton
            variant="contained"
            onClick={onSubmit}
            type="submit"
            disabled={!hookForm.formState.isValid}
            autoFocus={true}
          >
            Assign
          </CimsButton>
        </Stack>
      }
    >
      <Stack spacing={2}>
        <FormProvider {...hookForm}>
          <SelectEntity entities={[]} entityName="Division" />
          {/* <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Divider
              sx={{
                flexGrow: 1,
                borderWidth: 0.5,
                padding: 0,
              }}
            />
            <Button startIcon={<AddCircleOutlineIcon color="primary" />}>
              Add Card Detail
            </Button>
          </Stack> */}
          <AssignCardsForm />
          {/* <AssignCardsFormDetails />
        <AssignCardsFormDetails /> */}
        </FormProvider>

        <Stack
          direction={'row'}
          spacing={2}
          sx={{
            borderStyle: 'dashed',
            borderRadius: '0.5rem',
            padding: '.5rem',
            px: 2,
            borderColor: NEUTRAL_COLORS.BORDER[100],
            bgcolor: NEUTRAL_COLORS.BG[100],
          }}
        >
          <MessageAlertIcon width={20} height={20} />
          <VegaTextV2
            color={NEUTRAL_COLORS.TEXT[200]}
            text="NOTE: Next available kit will be assigned to the branch."
            fontSize={'.9rem'}
          ></VegaTextV2>
        </Stack>
      </Stack>
    </CimsDrawer>
  );
}

export default AssignCardsModal;
