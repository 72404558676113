/** @format */

import { Typography } from '@mui/material';
import { useState } from 'react';

import { VegaCard, VegaTable } from '../../../../components/common';
import { ISectionProps } from '../../../Back-office/pages/MCCUserManagement';

interface IProps {
  active: ISectionProps;
  data: any;
}

const ListOfInstituteResources = ({ active, data }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openBalance, setOpenBalance] = useState<boolean>(false);
  const [showData, setShowData] = useState<any>({});
  const [showCardData, setShowCardData] = useState<any>({});

  const KIT_COLUMN = [
    {
      field: 'kitNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          MCC Code
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Txn Type
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'programName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Dom Txn{' '}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Inter Txn
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Portal
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          lorem
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'activationDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Lorem
        </Typography>
      ),
      flex: 0.5,
    },
  ];
  const PERSONALISED_COLUMN = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Header
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'programName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Txn Type
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Lorem
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          API
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Portal
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'activationDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          App
        </Typography>
      ),
      flex: 0.5,
    },
  ];

  const REISSUE_COLUMN = [
    {
      field: 'kitNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Kit No
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'programName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Program Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer ID
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Expiry Date
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'activationDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Activation Date
        </Typography>
      ),
      flex: 0.5,
    },
  ];

  const getValue = (id: string) => {
    if (id === 'TRANSACTION_REQUIRMENT') {
      return {
        column: PERSONALISED_COLUMN,
        header: 'Card Details',
      };
    } else if (id === 'EVENT ') {
      return {
        column: REISSUE_COLUMN,
        header: 'Wallet Details',
      };
    } else {
      return {
        column: KIT_COLUMN,
        header: 'Customer Details',
      };
    }
  };

  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={getValue(active.id).column}
        idColumn={'id'}
      />
    </VegaCard>
  );
};

export default ListOfInstituteResources;
