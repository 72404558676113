import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  RoleManagementService,
  addRoleForUser,
  addUserWithRole,
  bulkProcessForUser,
  bulkSaveForUser,
  fetchPastUploadForUser,
} from '../../../api/roleManagement';
import { VegaCard, VegaPage } from '../../../components/common';
import { VegaSelectSelectOptionType } from '../../../components/common/VegaSelect';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  CIMSRoleEnums,
  IAddUserRequest,
  UserPlatformEnums,
  UserRoleEnums,
  UserTeamType,
  addRole,
  addUser,
  fetchRolesForClient,
  fetchUserForClient,
  makerCheckerForUser,
  updateStatusForUser,
} from '../../Program-Dashboard/api/roles';

import { AddCircleOutline } from '@mui/icons-material';
import { EntityService } from '../../../cims/apis';
import VegaModal from '../../../components/common/VegaModal';
import { sorting } from '../../../constants/commonFunction';
import { EntityStatusEnum } from '../../../enums/commons';
import { Level } from '../../../types/client';
import { VALIDATE_REGEX } from '../../../utils/validation.utils';
import { ACCESS_FEATURES } from '../../role';
import {
  IBulkUploadProps,
  IPastUploadFilesProps,
} from '../../types/applicationManagement';
import { STATUS } from '../../variable';
import BulkUpload from '../components/MCCAplicationManagement/BulkUpload';
import PastUpload from '../components/MCCAplicationManagement/PastUpload';
import ReviewEntity from '../components/MCCAplicationManagement/ReviewEntity';
import AddUser from '../components/MCCTeamManagement/AddUser';
import ListOfTeamManagement from '../components/MCCTeamManagement/ListOfTeamManagement';
import MCCFilterSection from '../components/MCCUserManagement/MCCFilterSection';
import SubmitFormWrapper from '../components/SubmitFormWrapper';
import { IFilterProps } from './MCCAplicationManagement';
import BulkUploadWrapper from '../components/BulkUploadWrapper';
import {
  bulkSaveCustomers,
  bulkUploadCustomers,
  fetchPastUploadForCustomers,
} from '../api/cutomer';
import { BULK_UPLOAD_TEMPLATE } from '../../constants';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../utils/FileValidator';
import {
  AddUserWithRolesPayloadType,
  UserRoleDto,
  VegaUser,
} from '../../../types/user';
import {
  CIMS_SUB_MODULES,
  CIMS_RESOURCE_MODULES,
} from '../../../constants/modules';
import { checkResourceAccess } from '../../../utils/userAccessUtils';
import { useQuery } from 'react-query';
import { upperCase } from 'lodash';

export interface IAddUserState extends IAddUserRequest {
  roleType: UserRoleEnums | CIMSRoleEnums;
  roleId?: string;
}
export interface IRolesprops {
  entityId: string | null;
  roles: string[];
}
export interface IEntityRolesProps {
  entityRoles: IRolesprops[];
  feature: string;
}
export interface IUserRoleProps {
  clientId: string | null;
  roleName: string;
  accessFeatures: IEntityRolesProps[];
  teamName: string;
  createdBy: string;
  status: string;
}

interface IExtendedUserDto extends VegaUser {
  userRoles: UserRoleDto[];
}

const INITIAL_USER_DATA: IAddUserState = {
  clientId: '',
  platformId: '',
  platformName: UserPlatformEnums.FOREX_BRANCH,
  mccUser: true,
  name: '',
  mobileNumber: '',
  email: '',
  address: '',
  officialEmailId: '',
  idType: 'PAN',
  idNumber: '',
  status: 'ACTIVE',
  team: UserTeamType.OPERATIONS,
  roleType: UserRoleEnums.HQ_USER,
  isAdmin: true,
  roleId: '',
};
export interface ITeamsProps {
  name: string;
  userId: string;
  mobileNumber: string | number;
  email: string;
  role: any;
  activationDate: any;
  status: any;
  userRoles: any;
}

export const INITIAL_DATA = {
  name: '',
  userId: '',
  mobileNumber: '',
  email: '',
  role: '',
  activationDate: '',
  status: '',
  userRoles: [],
};

export const CUSTOM_DATA = [
  {
    userId: 'Forexa1b2',
    name: 'Virat',
    mobileNumber: '9766155331',
    email: 'virat@vegaPay.tech',
    role: ['admin', 'sub admin'],
    activationDate: '01/01/2023',
    status: 'pending',
  },
  {
    userId: 'Forexa1b4',
    name: 'Ronit',
    mobileNumber: '9766155331',
    email: 'ronit@vegaPay.tech',
    role: ['admin'],
    activationDate: '01/01/2023',
    status: 'pending',
  },
] as ITeamsProps[];

const bulkUploadProps: IBulkUploadProps = {
  filename: '',
  invalidEntriesCount: null,
  invalidEntriesFileName: '',
  invalidEntriesFileUrl: '',
  totalEntries: null,
  uploadDate: '',
  validEntriesCount: null,
  validEntriesFileName: '',
  validEntriesFileUrl: '',
};

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.EXCEL, FileExtension.CSV])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

type Props = {
  isCIMS?: boolean;
};

const MCCTeamManagement = ({ isCIMS = false }: Props) => {
  const { clientId, userId, mccPlatformId: branchId, user } = useClientAuth();

  const { setSnackbar } = useSnackbar();
  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEmptyKey, setIsEmptyKey] = useState<boolean>(false);
  const [branchList, setBranchList] = useState<VegaSelectSelectOptionType[]>(
    []
  );

  const [rolesList, setRolesList] = useState<VegaSelectSelectOptionType[]>([]);
  const [branchUser, setBranchUsers] = useState<any[]>([]);
  const [branchCode, setBranchCode] = useState<string>('');

  const [userProfile, setUserProfile] =
    useState<IAddUserState>(INITIAL_USER_DATA);

  ///////////// bulkUpload state \\\\\\\\\\\\\\\\\\\\\\\
  const [fileName, setFileName] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openPastUpload, setOpenPastUpload] = useState<boolean>(false);
  const [openEntities, setOpenEntities] = useState<boolean>(false);
  const [toggleButton, setToggleButton] = useState<boolean>(false);

  const [pastUploadFiles, setPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [passedPastUploadFiles, setPassedPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [failedPastUploadFiles, setFailedPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [bulkUploadData, setBulkUploadData] = useState<IBulkUploadProps>({
    ...bulkUploadProps,
  });
  const [error, setError] = useState<string>('');

  const { data: rolesData } = useQuery({
    queryKey: ['getAllRolesForClientWithFilter', clientId],
    queryFn: () =>
      RoleManagementService.getAllRolesForClientWithFilter({
        clientId: clientId ?? '',
        size: 100,
      }),
    enabled: !!clientId,
    select(data) {
      return data.records.map((role) => upperCase(role.name));
    },
  });

  const canAddTeamMember = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.TEAM_MANAGEMENT.ADD_NEW_PROFILE,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.TEAM_MANAGEMENT,
      }),
    [user?.allResourceAccess]
  );

  ///////////// bulkUpload state \\\\\\\\\\\\\\\\\\\\\\\

  ////////////////// Allocation Role \\\\\\\\\\\\\\\\\\\\\\
  ////////////////// Allocation Role \\\\\\\\\\\\\\\\\\\\\\

  const BRANCH_ADMIN_ACCESS = ACCESS_FEATURES.BRANCH_ADMIN_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: '',
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );
  const BRANCH_USER_ACCESS = ACCESS_FEATURES.BRANCH_USER_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: branchId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );
  const HQ_USER_ACCESS = ACCESS_FEATURES.HQ_USER_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: clientId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );
  const CORPORATE_ADMIN_ACCESS =
    ACCESS_FEATURES.CORPORATE_ADMIN_ACCESS_FEATURES.map((access: string) => ({
      entityRoles: [
        {
          entityId: clientId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    }));
  const CIMS_HQ_ADMIN_ACCESS =
    ACCESS_FEATURES.CIMS_HQ_ADMIN_ACCESS_FEATURES.map((access: string) => ({
      entityRoles: [
        {
          entityId: clientId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    }));
  const CIMS_BC_ADMIN_ACCESS =
    ACCESS_FEATURES.CIMS_BC_ADMIN_ACCESS_FEATURES.map((access: string) => ({
      entityRoles: [
        {
          entityId: clientId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    }));
  const CIMS_BC_AGENT_ACCESS =
    ACCESS_FEATURES.CIMS_BC_AGENT_ACCESS_FEATURES.map((access: string) => ({
      entityRoles: [
        {
          entityId: clientId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    }));

  const DEFUALT_BRANCH_ADMIN = {
    clientId,
    roleName: `FOREX_BRANCH_ADMIN`,
    accessFeatures: BRANCH_ADMIN_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const DEFAULT_BRANCH_USERS = {
    clientId,
    roleName: 'FOREX_BRANCH_USER',
    accessFeatures: BRANCH_USER_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const DEFAUL_HQ_USERS = {
    clientId,
    roleName: 'FOREX_HQ_USER',
    accessFeatures: HQ_USER_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };
  const DEFAUL_CORPORATE_ADMIN = {
    clientId,
    roleName: 'FOREX_CORPORATE_ADMIN',
    accessFeatures: CORPORATE_ADMIN_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };
  const DEFAUL_CIMS_HQ_ADMIN = {
    clientId,
    roleName: 'CIMS_HQ',
    accessFeatures: CIMS_HQ_ADMIN_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };
  const DEFAUL_CIMS_BC_ADMIN = {
    clientId,
    roleName: 'CIMS_BC',
    accessFeatures: CIMS_BC_ADMIN_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };
  const DEFAUL_CIMS_BC_AGENT = {
    clientId,
    roleName: 'CIMS_BC_AGENT',
    accessFeatures: CIMS_BC_AGENT_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const [roleDetails, setRoleDetails] = useState<any>({
    ...DEFAUL_HQ_USERS,
  });

  const isValidInput = useMemo(() => {
    // temp validation
    // TODO : improve
    const flag =
      userProfile.email &&
      userProfile.mobileNumber &&
      userProfile.name &&
      // VALIDATE_REGEX.NAME.test(userProfile.name.replace(/\s+/g, '')) &&
      userProfile.address &&
      userProfile.idNumber;

    if (
      !roleDetails.roleName ||
      !flag ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userProfile.email) ||
      !(userProfile.mobileNumber.length === 10)
    ) {
      return false;
    }

    if (!roleDetails.roleName || !userProfile.platformId) {
      return false;
    }

    if (userProfile.idType === 'PAN' && userProfile.idNumber) {
      return /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(
        userProfile.idNumber.toUpperCase()
      )
        ? true
        : false;
    } else if (
      userProfile.idType === 'AADHAR' &&
      userProfile.idNumber.toUpperCase()
    ) {
      return /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/.test(userProfile.idNumber)
        ? true
        : false;
    }

    return true;
  }, [userProfile]);

  const handleFilter = (name: string, value: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleChange = (
    key: keyof IAddUserState,
    value: string | File | null
  ) => {
    if (key === 'mobileNumber') {
      if ((value as string)?.length > 10) {
        return;
      }
    }

    // apply filter on platformName -> UserPlatformEnums
    setUserProfile((pre) => {
      let platformId = pre.platformId;
      let platformName = pre.platformName;

      if (key === 'roleType') {
        switch (value) {
          // case UserRoleEnums.HQ_USER:
          //   platformName = UserPlatformEnums.FOREX_HQ;
          //   setRoleDetails({ ...DEFAUL_HQ_USERS });
          //   break;
          case CIMSRoleEnums.HQ_ADMIN:
            platformName = 'CIMS_HQ';
            platformId = clientId || '';
            setRoleDetails({ ...DEFAUL_CIMS_HQ_ADMIN });
            break;
          case CIMSRoleEnums.BC_AGENT:
            platformName = 'CIMS_BC_AGENT';
            platformId = '';
            setRoleDetails({ ...DEFAUL_CIMS_BC_AGENT });
            break;
          default:
            // default branch user
            platformName = 'CIMS_BC';
            platformId = '';

            setRoleDetails({ ...DEFAUL_CIMS_BC_ADMIN });
        }
      } else if (key === 'platformId') {
        platformId = value as string;
      }

      return {
        ...pre,
        [key]: value,
        platformId: platformId,
        platformName,
      };
    });
  };

  const onOpen = () => {
    setOpen(!open);
    setSelectedFile(null);
  };

  const onEntitiesOpen = () => {
    setOpenEntities(!openEntities);
    setSelectedFile(null);
  };
  const onPastOpen = () => {
    setOpenPastUpload(!openPastUpload);
    setSelectedFile(null);
  };

  const onFetchPastUpload = () => {
    fetchPastUploadForUser(branchId).then((res) => {
      const singleArray = [
        ...res.data.completed,
        ...res.data.unknown,
        ...res.data.failed,
      ];
      setPastUploadFiles(singleArray);

      setPassedPastUploadFiles(res.data.completed);

      setFailedPastUploadFiles([...res.data.unknown, ...res.data.failed]);
    });
    onPastOpen();
  };

  const onClear = () => {
    setUserProfile({ ...INITIAL_USER_DATA });
  };
  const toggleUser = () => {
    setIsOpen(!isOpen);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (
        fileType === 'text/csv' ||
        fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileSize <= 2) {
          setSelectedFile(file || null);
        } else {
          setError('File size exceeds 2MB. Please upload a smaller file.');
        }
      } else {
        setError('Please upload only CSV or XLSX files.');
      }
    }
  };

  const handleOnError = (message: string | null) => {
    setSelectedFile(null);
    setError(message ?? 'Something went wrong');
  };

  const onFileProceed = () => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    bulkProcessForUser(formData).then(async (res) => {
      setBulkUploadData(res.data);
      // const invalidFileURL = res.data.invalidEntriesFileUrl;
      setFileName(res.data.validEntriesFileName);
      setToggleButton(true);
    });
  };
  const onFileSave = (url: string) => {
    console.log('onFileProceed', selectedFile);
    if (!url) {
      return;
    }

    const obj = {
      branchId,
      fileName,
      url,
    };

    bulkSaveForUser(obj).then(async (res) => {
      console.log('bulkSaveCustomers', res.data);
      // const invalidFileURL = res.data.invalidEntriesFileUrl;
      // setToggleButton(true);
      onEntitiesOpen();
      setBulkUploadData({ ...bulkUploadProps });
      getBranchUserList();
    });
  };

  const newObjForRole = (platformId: string) => {
    const roleName = (
      roleDetails.roleName +
      '_' +
      userProfile.platformId
    ).replaceAll(' ', '_');
    const entityId = platformId;
    if (!roleName || !entityId) {
      setSnackbar('Role is not defined', 'error');
      setIsLoading(false);
      return;
    }
    const finalObj: any = {};
    const newArr = Object.keys(roleDetails).map((value: any) => {
      return {
        [value]: roleDetails[value],
      };
    });
    for (let i = 0; i < newArr.length; i++) {
      Object.assign(finalObj, newArr[i]);
    }

    const newObj = {
      ...finalObj,
      roleName: roleName,
      accessFeatures: finalObj?.accessFeatures?.map((item: any) => ({
        ...item,
        entityRoles: item?.entityRoles?.map((val: any) => ({
          ...val,
          entityId: entityId,
        })),
      })),
    };

    return newObj;
  };

  const submitRole = async () => {
    setIsLoading(true);
    const newObj = await newObjForRole(userProfile.platformId);

    addRole(newObj)
      .then((res) => {
        setSnackbar('Role added!');
        setIsLoading(false);
        addUsers(res.data.id);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add role'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const allocateRoleForUser = async (userId: string, platformId: string) => {
    const newObj = await newObjForRole(platformId);

    addRole(newObj)
      .then((res) => {
        setSnackbar('Role added!');
        setIsLoading(false);
        const roleForUser = {
          userId: userId,
          roleId: res.data.id,
          roleName: (
            roleDetails.roleName +
            '_' +
            userProfile.platformId
          ).replaceAll(' ', '_'),
          checkerMakerFlag: true,
        };
        addRoleForUser(roleForUser).then((res) => {
          getBranchUserList();
        });
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add role'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const addUsers = async (roleId: string) => {
    setIsLoading(true);
    if (!clientId) {
      setSnackbar('Client Id is not defined');
      return;
    }

    const entity =
      userProfile.roleType === CIMSRoleEnums.HQ_ADMIN || !userProfile.platformId
        ? null
        : (
            await EntityService.listWithMetaData({
              entityRequest: {
                id: userProfile.platformId,
                statusList: [EntityStatusEnum.ACTIVE],
              },
            })
          ).records[0];

    let levelHierarchy = {
      clientId: clientId,
    };
    if (entity) {
      const { id, createdAt, updatedAt, ...rest } = entity?.levelEntityDto;
      levelHierarchy = rest;
    }

    addUser({
      ...userProfile,
      platformName: null,
      clientId,
      isAdmin: true,
      apiScope: {
        currrentLevel:
          userProfile.roleType === CIMSRoleEnums.HQ_ADMIN
            ? Level.CLIENT
            : userProfile.roleType === CIMSRoleEnums.BC_ADMIN
            ? Level.l1
            : Level.l2,
        currentLevelValue: userProfile.platformId,
        levelHierarchy: levelHierarchy,
      },
    })
      .then(async (res) => {
        const roleForUser = {
          userId: res.data?.userId,
          roleId,
          roleName: roleDetails?.roleName,
          checkerMakerFlag: true,
        };
        addRoleForUser(roleForUser).then((res) => {
          getBranchUserList();
        });
        setSnackbar('User added!');
        setIsLoading(false);
        onClear();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add user'),
          'error'
        );
        setIsLoading(false);
        // onClear();
      });
  };

  const onAddUser = async () => {
    await submitRole();
  };

  const onAddCimsUser = async () => {
    setIsLoading(true);

    const payload: AddUserWithRolesPayloadType = {
      userDto: {
        name: userProfile.name,
        email: userProfile.email,
        clientId: clientId ?? '',
        mccUser: false,
        isAdmin: true,
        mobileNumber: userProfile.mobileNumber,
        address: userProfile.address,
      },
      listOfRoles: [
        {
          roleId: userProfile.roleId ?? '',
          programId: 'GLOBAL',
          divisionId: userProfile.platformId,
        },
      ],
      enableCheckerMaker: false,
    };
    addUserWithRole(payload)
      .then(() => {
        setSnackbar('User added!');
        setIsLoading(false);
        getBranchUserList();
        onClear();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add user'),
          'error'
        );
        setIsLoading(false);
        // onClear();
      });
  };

  const onSendChecker = async (data: any) => {
    makerCheckerForUser({ userId, entityId: data.id })
      .then((res) => {
        setSnackbar('Send Checker!');
        getBranchUserList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send checker'),
          'error'
        );
      });
  };
  const onUpdateSendChecker = async (data: any) => {
    updateStatusForUser({
      userId,
      status: 'CHECKER_PENDING',
      entityId: data.id,
    })
      .then((res) => {
        setSnackbar('Send Checker!');
        getBranchUserList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send checker'),
          'error'
        );
      });
  };

  const onSearch = (arr: IExtendedUserDto[]) => {
    return arr.filter((val) => {
      return (
        val.email.toLowerCase().includes(filterData.mobile.toLowerCase()) ||
        val.mobileNumber
          .toLowerCase()
          .includes(filterData.mobile.toLowerCase()) ||
        val.name.toLowerCase().includes(filterData.mobile.toLowerCase()) ||
        val.entityDto?.entityType
          .toLowerCase()
          .includes(filterData.mobile.toLowerCase()) ||
        val.entityDto?.name
          ?.toLowerCase()
          .includes(filterData.mobile.toLowerCase()) ||
        val.entityDto?.entityCode
          .toLowerCase()
          .includes(filterData.mobile.toLowerCase())
      );
    });
  };

  const filteredBranchUser = branchUser.filter((val: any) => {
    if (filterData.status === 'ALL') {
      return val;
    } else if (val?.userRoles[0]?.checkerMakerStatus === filterData.status) {
      return val;
    }
  });

  const getBranchUserList = async () => {
    setListLoading(true);
    fetchUserForClient(clientId as string)
      .then((res) => {
        // const copy = [...branchUser];
        // res.data.map((val: any) => {
        //   const arr = val.userRoles;

        //   if (!arr.length) {
        //     copy.push(val);
        //   } else {
        //     arr.map((value: any) => {
        //       copy.push({
        //         ...value,
        //         name: val.name,
        //         userId: val.userId,
        //         email: val.email,
        //       });
        //     });
        //   }

        //   setBranchUsers([...copy]);
        // });
        const sorted = sorting(res.data);

        setBranchUsers(sorted);
        setListLoading(false);
      })

      .catch((err) => {
        setListLoading(false);
      });
  };

  const getRoles = async () => {
    await fetchRolesForClient(clientId as string)
      .then((res) => {
        const newArr = res.data?.map((val: any) => ({
          name: val.roleName,
          id: val.id,
        }));
        setRolesList(newArr);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // getBranches();
    getRoles();
  }, []);
  useEffect(() => {
    getBranchUserList();
  }, []);

  return (
    <>
      <VegaPage>
        {(!isCIMS || canAddTeamMember) && (
          <VegaCard noPad>
            <SubmitFormWrapper
              titleText="Add New Profile"
              submitText="Add User"
              cancelText="Clear"
              onSubmit={onAddCimsUser}
              onClear={onClear}
              toggle={toggleUser}
              isOpen={isOpen}
              loading={isLoading}
              disabled={!isValidInput}
            >
              <AddUser
                setBranchCode={setBranchCode}
                state={userProfile}
                rolesList={rolesData ?? []}
                handleChange={handleChange}
                setIsEmptyKey={setIsEmptyKey}
              />
            </SubmitFormWrapper>
          </VegaCard>
        )}
        {/* <VegaCard>
          <BulkUploadWrapper
            onFetchPastUpload={onFetchPastUpload}
            onOpen={onOpen}
            templateUrl={BULK_UPLOAD_TEMPLATE.BRANCH_TEAM_MANAGEMENT}
          />
        </VegaCard> */}
        <MCCFilterSection
          // isStatusFilter
          statusOption={STATUS}
          state={filterData}
          handleChange={handleFilter}
          // onSearch={onSearch}
          // isSearch
          label="Search"
        />
        <ListOfTeamManagement
          state={onSearch(filteredBranchUser)}
          refetchList={getBranchUserList}
          onSendChecker={onSendChecker}
          onUpdateSendChecker={onUpdateSendChecker}
          loading={listLoading}
          setRoleDetails={setRoleDetails}
          defaultBranchUser={DEFAULT_BRANCH_USERS}
          defaultCorporateAdmin={DEFAUL_CORPORATE_ADMIN}
          allocateRoleForUser={allocateRoleForUser}
          showAction={!isCIMS}
        />
      </VegaPage>
      <VegaModal
        header={'Bulk Upload'}
        open={open}
        handleClose={onOpen}
        submitText={toggleButton ? 'Review Entity' : 'Process'}
        // disabled={!selectedFile || error.length > 0}
        isButton={true}
        handleSubmit={
          toggleButton
            ? () => {
                onOpen();
                onEntitiesOpen();
                setToggleButton(false);
              }
            : onFileProceed
        }
        buttonIcon={<AddCircleOutline />}
      >
        <BulkUpload
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          error={error}
          bulkUploadData={bulkUploadData}
          onError={(message) => {
            handleOnError(message);
          }}
          fileValidator={fileValidatorConfig}
        />
      </VegaModal>
      <VegaModal
        header={'Review Entiry'}
        open={openEntities}
        handleClose={() => {
          onEntitiesOpen();
          setBulkUploadData({ ...bulkUploadProps });
        }}
        // submitText={getComponents(status).submitText}
        width={900}
        isButton={false}
        // disabled={!selectedFile || error.length > 0}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
      >
        <ReviewEntity
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          bulkUploadData={bulkUploadData}
          onSaveFile={onFileSave}
        />
      </VegaModal>
      <VegaModal
        header={'Past Upload'}
        open={openPastUpload}
        handleClose={onPastOpen}
        // submitText={getComponents(status).submitText}
        width={900}
        isButton={false}
        // disabled={!selectedFile || error.length > 0}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
      >
        <PastUpload
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          pastUploadFiles={pastUploadFiles}
          passedPastUploadFiles={passedPastUploadFiles}
          failedPastUploadFiles={failedPastUploadFiles}
          // bulkUploadData={bulkUploadData}
        />
      </VegaModal>
    </>
  );
};

export default MCCTeamManagement;
