import { Box, Grid } from '@mui/material';
import { VegaTextField } from '../../../../../components/common';
import VegaSelect from '../../../../../components/common/VegaSelect';
import { CardRefundType } from '../../../../../enums/card';
import {
  CreateCardRefundOrderReq,
  Currency,
} from '../../../../../types/cardRefund';

interface IProps {
  isShow?: boolean;
  state: Partial<CreateCardRefundOrderReq>;
  handleChange: (
    key: keyof CreateCardRefundOrderReq,
    value: string | any
  ) => void;
}

const CardRefundDetails = ({ handleChange, state, isShow }: IProps) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={state?.accountId}
            onChange={(e) => handleChange('accountId', e.target.value)}
            key="accountId"
            label="Account ID"
            type={'text'}
            placeholder="Account ID"
            disabled={isShow}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={state?.cardNo}
            onChange={(e) => handleChange('cardNo', e.target.value)}
            key="cardNo"
            label="Card No"
            type={'text'}
            placeholder="Card No"
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={state?.name}
            onChange={(e) => handleChange('name', e.target.value)}
            key="name"
            label="Name"
            type={'text'}
            placeholder="Name"
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={['Ebix Cash 1']}
            key="program"
            selected={state?.program}
            label="Select Program"
            onChange={(value) => handleChange('program', value)}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={['CASH', 'BANK']}
            key="mode"
            selected={state?.paymentMode || 'CASH'}
            label="Select Payment Mode"
            onChange={(value) => handleChange('paymentMode', value)}
            disabled={isShow}
          />
        </Grid>

        <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={Object.keys(CardRefundType)}
            key="refuntType"
            selected={state?.refundType || CardRefundType.PARTIAL}
            label="Refund Type"
            onChange={(value) => handleChange('refundType', value)}
            disabled={isShow}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={state?.amount}
            onChange={(e) => handleChange('amount', e.target.value)}
            key="amount"
            label="Amount"
            type={'number'}
            placeholder="Amount"
            disabled={isShow}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={Object.keys(Currency)}
            key="currency"
            selected={state?.sourceCurrency || Currency.INR}
            label="Source Currency"
            onChange={(value) => handleChange('sourceCurrency', value)}
            disabled={isShow}
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={Object.keys(Currency)}
            key="destCurrency"
            selected={state?.destinationCurrency || Currency.USD}
            label="Destination Currency"
            onChange={(value) => handleChange('destinationCurrency', value)}
            disabled={isShow}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardRefundDetails;
