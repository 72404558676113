/** @format */

import { UploadFile } from '@mui/icons-material';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import {
  addOrUpdateRolesForUser,
  addUserForClient,
  fetchListOfRolesForClient,
  fetchPaginatedListOfUsers,
} from '../api/roleManagement';
import AddUser from '../components/User Access/AddNewUser/AddUser';
import ListOfUsers from '../components/User Access/AddNewUser/ListOfUsers';
import { VegaPage } from '../components/common';
import VegaDrawer from '../components/common/VegaDrawer';
import VegaOutlineButton from '../components/common/VegaOutlineButton';
import { BulkUploadIcon } from '../components/icon/Icons';
import { BORDER_GRAY, GREEN } from '../constants/style';
import { useClientAuth } from '../providers/ClientProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { IRolesProps, IUsersProps } from '../types/userAccess';
import { getErrorMessageFromErrorObj } from '../utils/api';

export interface IUserAccessProps {
  data: any;
  onChange: (name: string, value: any) => void;
  viewMore: (value: any) => void;
  onEdit: (value: any) => void;
}

interface IUserPaginatedList {
  records: IUsersProps[];
  pageNumber: number;
  numberOfItems: number;
  totalPages: number;
  totalItems: number;
}

const INITIAL_USER = {
  clientId: '',
  name: '',
  mobileNumber: '',
  email: '',
  address: '',
  officialEmailId: '',
  idType: '',
  idNumber: '',
  status: 'ACTIVE',
  team: '',
};

const ManageTeams = () => {
  const { clientId } = useClientAuth();

  const { setSnackbar } = useSnackbar();
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [addUser, setAddUser] = useState<IUsersProps>({
    ...INITIAL_USER,
    clientId,
  });

  const [usersData, setUsersData] = useState<{
    users?: IUsersProps[];
    isLoading?: boolean;
    page: number;
    size: number;
    totalItems?: number;
    totalPages?: number;
  }>({ page: 0, size: 5 });

  const [roles, setRoles] = useState<IRolesProps[]>([]);
  const ref = useRef<any>(null);
  const refBulk = useRef<any>(null);

  const onUpload = () => {
    ref.current.click();
  };

  ////////////////  for user \\\\\\\\\\\\\\\\\\\\
  const onChange = (name: string, value: any) => {
    setAddUser((preState: IUsersProps) => ({
      ...preState,
      [name]: value,
    }));
  };

  const submitUser = async (data: IUsersProps) => {
    const newObj = {
      ...data,
      clientId,
    };
    await addUserForClient(newObj)
      .then((res) => {
        setSnackbar('User added!');
        setAddUser({
          ...INITIAL_USER,
          clientId,
        });
        getUsersForClient();
      })
      .catch((err) => {
        setAddUser({
          ...INITIAL_USER,
          clientId,
        });
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  const submitUserRoles = async (data: any) => {
    console.log('updateUserRoles', data);
    await addOrUpdateRolesForUser(data)
      .then((res) => {
        setSnackbar('Updated Roles for User');
        getUsersForClient();
        setAddUser({
          ...INITIAL_USER,
          clientId,
        });
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        setAddUser({
          ...INITIAL_USER,
          clientId,
        });
      });
  };

  const getUsersForClient = async () => {
    if (clientId) {
      setUsersData((prev) => ({ ...prev, isLoading: true }));
      fetchPaginatedListOfUsers({
        clientId,
        page: usersData.page,
        size: usersData.size,
      })
        .then((res) => {
          const data = res.data as IUserPaginatedList | undefined;
          if (data) {
            setUsersData((prev) => ({
              ...prev,
              isLoading: false,
              users: data.records,
              totalItems: data.totalItems,
              totalPages: data.totalPages,
            }));
          }
        })
        .catch((err) => {
          setUsersData((prev) => ({ ...prev, isLoading: false }));
          setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        });
    }
  };

  const getRolesForClient = async () => {
    await fetchListOfRolesForClient(clientId)
      .then((res) => setRoles(res.data))
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  const isInputValid = () => {
    const isNameValid = addUser.name.length > 0;
    const isMobileValid = addUser.mobileNumber.length == 10;
    const isEmailValid = addUser.email.length > 0;
    const isTeamValid = addUser.team.length > 0;
    const isGovtIdTypeValid = addUser.idType.length > 0;
    const isGovtIdNumberValid = addUser.idNumber.length > 0;
    return (
      isNameValid &&
      isMobileValid &&
      isTeamValid &&
      isGovtIdTypeValid &&
      isEmailValid &&
      isGovtIdNumberValid
    );
  };

  useEffect(() => {
    if (clientId) {
      getUsersForClient();
      getRolesForClient();
    }
  }, []);

  useEffect(() => {
    getUsersForClient();
  }, [usersData.page, usersData.size]);

  console.log('load', usersData.isLoading);

  return (
    <>
      <VegaPage>
        <AddUser
          showBulkUpload
          error={error}
          onChange={onChange}
          setIsBulkUpload={setIsBulkUpload}
          state={addUser}
          onSubmit={submitUser}
        />
        <ListOfUsers
          data={usersData.users}
          role={roles}
          onSubmit={submitUserRoles}
          loading={usersData.isLoading}
          rowCount={usersData.totalItems}
          page={usersData.page}
          pageSize={usersData.size}
          onPageChange={(pageNum) =>
            setUsersData((prev) => ({ ...prev, page: pageNum }))
          }
          onPageSizeChange={(sizeNum) =>
            setUsersData((prev) => ({ ...prev, size: sizeNum }))
          }
          paginationMode={'server'}
        />
      </VegaPage>
      <VegaDrawer
        open={isBulkUpload}
        onClose={() => setIsBulkUpload(!isBulkUpload)}
        anchor="right"
        isSubmit={true}
        isClose={true}
        onSubmit={() => setIsBulkUpload(!isBulkUpload)}
        closeText="Got It!"
        submitText="Upload"
        header="Upload User Details In Bulk"
      >
        <Box
          sx={{
            border: BORDER_GRAY,
            borderRadius: '5px',
            p: 2,
            minWidth: '70%',
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input type="file" id="file" hidden ref={ref} />
          <VegaOutlineButton
            text="Upload File"
            isPrimary
            startIcon={<UploadFile />}
            onClick={onUpload}
          />

          <Typography my={2}>
            The file uploaded here must be in the given format
          </Typography>
          <input type="file" id="fileBulk" hidden ref={refBulk} />

          <Box display={'flex'} sx={{ cursor: 'pointer' }}>
            <Typography sx={{ color: GREEN.dark, mr: 1 }}>
              Download Bulk upload format
            </Typography>
            <BulkUploadIcon />
          </Box>
        </Box>
      </VegaDrawer>
    </>
  );
};

export default ManageTeams;
