// create a new context

import { DataGridProps, GridCallbackDetails } from '@mui/x-data-grid';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  CardAllotmentCategory,
  CardAllotmentOrderDto,
} from '../../../../types/card';
import { Level } from '../../../../types/client';
import { PaginatedResponse } from '../../../../utils/api';
import { CardAllocationService } from '../../../apis';
import { CardOrderRequestTabTypes } from '../Filter/FilterCardOrderRequest';

export interface TablePagination {
  page: number;
  pageSize: number;
  onPageChange: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
}

export interface CardOrderRequestContextProps {
  pagination: Partial<DataGridProps>;
  listAllotmentQuery: UseQueryResult<
    PaginatedResponse<CardAllotmentOrderDto>,
    unknown
  > | null;
  activeTab: CardOrderRequestTabTypes;
  setActiveTab: React.Dispatch<React.SetStateAction<CardOrderRequestTabTypes>>;
}

const defaultCardOrderRequestContext: CardOrderRequestContextProps = {
  pagination: {
    page: 0,
    pageSize: 10,
    onPageChange: (page, details) => {},
    onPageSizeChange: () => {},
  },
  listAllotmentQuery: null,
  activeTab: CardOrderRequestTabTypes.RequestCards,
  setActiveTab: () => {},
};

const CardOrderRequestContext = createContext<CardOrderRequestContextProps>(
  defaultCardOrderRequestContext
);

const useCardOrderRequest = () =>
  useContext(CardOrderRequestContext) as CardOrderRequestContextProps;

interface IProps {
  children: ReactNode;
}
const CardOrderRequestProvider = ({ children }: IProps) => {
  const { userDivisionId, userDivisionType } = useClientAuth();

  const canRequestCards = userDivisionType !== 'CLIENT';
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const [activeTab, setActiveTab] = useState(
    canRequestCards
      ? CardOrderRequestTabTypes.RequestCards
      : CardOrderRequestTabTypes.PendingOrders
  );
  const [filterData, setFilterData] = useState([]);

  const entityRequest = useMemo(() => {
    return activeTab === CardOrderRequestTabTypes.RequestCards
      ? {
          destinationDivisionId: userDivisionId,
        }
      : {
          sourceDivisionId: userDivisionId,
        };
  }, [activeTab, userDivisionId]);

  const allotmentCategoryList = useMemo(() => {
    // return activeTab === CardOrderRequestTabTypes.RequestCards
    //   ? [CardAllotmentCategory.REQUEST]
    //   : [CardAllotmentCategory.ASSIGN];
    return [CardAllotmentCategory.REQUEST];
  }, [activeTab]);

  const listAllotmentQuery = useQuery({
    queryKey: [
      'list_allotment',
      {
        ...entityRequest,
        ...filterData,
        allotmentCategoryList,
        page,
        pageSize,
      },
    ],
    queryFn: () => {
      return CardAllocationService.list({
        ...entityRequest,
        allotmentStatusList: filterData.length ? filterData : [],
        page: page,
        size: pageSize,
        allotmentCategoryList,
        populateProgram: true,
        populateDivision: true,
      });
    },
    keepPreviousData: true,
  });
  return (
    <CardOrderRequestContext.Provider
      value={{
        pagination: {
          pageSize: pageSize,
          onPageSizeChange: setPageSize,
          page: page,
          onPageChange: setPage,
        },
        listAllotmentQuery,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </CardOrderRequestContext.Provider>
  );
};

export {
  CardOrderRequestContext as CardOrderRequestContextProps,
  CardOrderRequestProvider,
  useCardOrderRequest,
};
