import CIMS_Header from '../../components/common/CIMS_Header';
import PrintCardsList from '../../modules/Vendor/PrintCards/PrintCardsList';

function CardOrderRequests() {
  return (
    <div>
      <CIMS_Header text="Print Card Order Request" />
      <PrintCardsList />
    </div>
  );
}

export default CardOrderRequests;
