import {
  Contribution,
  ContributionEntityType,
  ContributionData,
} from '../../../../../../../../types/RewardsLoyalty';
import VegaCardV2 from '../../../../../../../common/v2/VegaCardV2';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { ContributionInput } from './ContributionInput';
import { useProgramData } from '../../../../../../../../providers/ProgramDataProvider';
import { useClientAuth } from '../../../../../../../../providers/ClientProvider';
import VegaConditionalWrapper from '../../../../../../../common/VegaConditionalWrapper';
import { useCampaignRule } from '../../../../../../providers/CampaignRuleProvider';
import { VegaSelectOptionV2 } from '../../../../../../../common/v2/VegaSelectV2';
import { Merchant } from '../../../../../../../../types/resources';

const ContributionTabPanel = () => {
  const { selectedProgram } = useProgramData();
  const { client } = useClientAuth();
  const {
    merchants,
    addContribution,
    updateContribution,
    deleteContribution,
    contributionData,
  } = useCampaignRule();

  const isProgramCobrand = () => {
    if (!selectedProgram) return false;
    return (
      selectedProgram.coBrandPartnerName != null &&
      selectedProgram.coBrandPartnerName.length > 0
    );
  };

  function handleContributionStatusChange(
    type: ContributionEntityType,
    enabled: boolean,
    entityName?: string
  ) {
    if (enabled) {
      addContribution({
        contributorType: type,
        percentage: 0,
        name: entityName,
      });
    } else {
      deleteContribution(type);
    }
  }

  function handleContributionValueChange(
    type: ContributionEntityType,
    value?: number | string
  ) {
    if (!value) return;
    //  if the value is numeric then contribution is to be changed else name
    updateContribution(type, value);
  }

  return (
    <VegaCardV2>
      <Stack spacing={3}>
        <ContributionInput
          entityType={ContributionEntityType.BANK}
          entityName={client?.clientName}
          onContributionStatusChange={(enabled) =>
            handleContributionStatusChange(
              ContributionEntityType.BANK,
              enabled,
              client?.clientName
            )
          }
          onContributionValueChange={(value) =>
            handleContributionValueChange(ContributionEntityType.BANK, value)
          }
          value={getContribution({
            ofType: ContributionEntityType.BANK,
            from: contributionData,
          })}
        />

        <VegaConditionalWrapper
          condition={isProgramCobrand()}
          ifTrue={
            <ContributionInput
              entityType={ContributionEntityType.CO_BRAND}
              entityName={selectedProgram?.coBrandPartnerName}
              onContributionStatusChange={(enabled) =>
                handleContributionStatusChange(
                  ContributionEntityType.CO_BRAND,
                  enabled,
                  selectedProgram?.coBrandPartnerName
                )
              }
              onContributionValueChange={(value) =>
                handleContributionValueChange(
                  ContributionEntityType.CO_BRAND,
                  value
                )
              }
              value={getContribution({
                ofType: ContributionEntityType.CO_BRAND,
                from: contributionData,
              })}
            />
          }
        />
        <ContributionInput
          entityType={ContributionEntityType.PAYMENT_NETWORK}
          entityName={selectedProgram?.network}
          onContributionStatusChange={(enabled) =>
            handleContributionStatusChange(
              ContributionEntityType.PAYMENT_NETWORK,
              enabled,
              selectedProgram?.coBrandPartnerName
            )
          }
          onContributionValueChange={(value) =>
            handleContributionValueChange(
              ContributionEntityType.PAYMENT_NETWORK,
              value
            )
          }
          value={getContribution({
            ofType: ContributionEntityType.PAYMENT_NETWORK,
            from: contributionData,
          })}
        />
        <ContributionInput
          entityType={ContributionEntityType.MERCHANT}
          options={merchantOptions(merchants)}
          onContributionStatusChange={(enabled) =>
            handleContributionStatusChange(
              ContributionEntityType.MERCHANT,
              enabled
            )
          }
          onContributionValueChange={(value) =>
            handleContributionValueChange(
              ContributionEntityType.MERCHANT,
              value
            )
          }
          onContributionValueSelect={(value) =>
            handleContributionValueChange(
              ContributionEntityType.MERCHANT,
              value
            )
          }
          value={getContribution({
            ofType: ContributionEntityType.MERCHANT,
            from: contributionData,
          })}
        />
      </Stack>
    </VegaCardV2>
  );
};

export default ContributionTabPanel;

const merchantOptions = (merchants: Merchant[]) => {
  return merchants.map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item.id,
      value: item.id,
      label: _.startCase(_.toLower(item.name)),
    };
    return option;
  });
};

const getContribution = (data: {
  from: Partial<ContributionData>;
  ofType: ContributionEntityType;
}): Contribution | null => {
  if (!data.from) return null;
  const filteredContributions = data.from.contributionEntities?.filter(
    (item) => {
      if (item.contributorType === data.ofType) {
        return true;
      }
      return false;
    }
  );
  if (!filteredContributions || filteredContributions.length <= 0) return null;
  return filteredContributions[0];
};
