import { useEffect, useMemo, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import VegaSelector from '../../../components/common/VegaSelector';
import { CreditCardIcon, UserIcon } from '../../../components/icon/Icons';
import { sorting, toLowerCase } from '../../../constants/commonFunction';
import { BACKGROUND } from '../../../constants/style';
import { CheckerMakerStatusEnum } from '../../../enums/commons';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import ManualAdjustmentService from '../../../services/ManualAdjustmentService';
import { ICardRefundOrder } from '../../../types/cardRefund';
import { IUpdateCheckerMakerReq } from '../../../types/customers';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  fetchListFundLoad,
  listCardRefundOrder,
  updateCardRefundMakerChecker,
  updateFundLoadOrderStatus,
} from '../../Back-office/api/transaction';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { IFilterProps } from '../../Back-office/pages/MCCAplicationManagement';
import { ISectionProps } from '../../Back-office/pages/MCCUserManagement';
import { ICardFundLoadProps } from '../../types/Card';
import { IManualAdjustment } from '../../types/ManualAdjustment';
import { STATUS } from '../../variable';
import ListOfCheckerCardTransaction, {
  ITransCheckerRowData,
  ITransCheckerRowElement,
} from '../components/CheckerCardTransaction/ListOfCheckerCardTransaction';
import ManualAdjustmentCheckerList from '../components/CheckerCardTransaction/ManualAdjustmentCheckerList';
import FilterAction from '../components/FilterAction';

let tileData = [
  {
    id: 'RELOAD',
    text: 'Card Reload',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'REFUND',
    text: 'Card Refund',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'ADJUSTMENT',
    text: 'Manual Adjustment',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];
type Selected<T> = {
  selected?: boolean;
} & T;
const CheckerCardTransaction = () => {
  const { userId, clientId, mccPlatformId: branchId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardReloadData, setCardReloadData] = useState<
    Selected<ICardFundLoadProps>[]
  >([]);
  const [cardRefundData, setCardRefundData] = useState<
    Selected<ICardRefundOrder>[]
  >([]);
  const [manualAdjustData, setManualAdjustData] = useState<
    Selected<IManualAdjustment>[]
  >([]);

  const [listLoading, setListLoading] = useState<boolean>(false);
  const [activeState, setActiveState] = useState<ISectionProps>(tileData[0]);
  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });

  const filteredData = useMemo(() => {
    setCurrentPage(1);
    const data = [];
    if (activeState.id === 'RELOAD') {
      data.push(...cardReloadData);
    } else if (activeState.id === 'REFUND') {
      data.push(...cardRefundData);
    } else {
      data.push(...manualAdjustData);
    }
    return data.filter((cus) => {
      return (
        (cus?.accountConsolidatedDto?.customer?.emailId
          .toLowerCase()
          .includes(filterData.mobile.toLowerCase()) ||
          cus?.accountConsolidatedDto?.customer?.emailId
            .toLowerCase()
            .includes(filterData.mobile.toLowerCase())) &&
        (filterData.status === 'ALL' ||
          filterData.status === cus.makerCheckerStatus)
      );
    });
  }, [
    filterData,
    activeState,
    cardRefundData,
    cardReloadData,
    manualAdjustData,
  ]);

  const selectedTab = (data: any) => {
    setCurrentPage(1);
    setActiveState(data);
    setFilterData({
      mobile: '',
      status: 'ALL',
    });
  };

  const handleFilter = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const onSubmitStatus = (data: IUpdateCheckerMakerReq) => {
    if (activeState.id === 'RELOAD') {
      updateFundLoadOrderStatus({
        userId: data.userId,
        fundLoadOrderId: data.entityId,
        status: data.status,
      })
        .then((res) => {
          setSnackbar('Status change!');
          setSelectedRows([]);
          getFundList();
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Failed to change status'),
            'error'
          );
          setSelectedRows([]);
        });
    } else if (activeState.id === 'REFUND') {
      updateCardRefundMakerChecker({
        userId: data.userId,
        cardRefundOrderId: data.entityId,
        status: data.status,
      })
        .then((res) => {
          setSnackbar('Status change!');
          getRefundList();
          setSelectedRows([]);
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Failed to change status'),
            'error'
          );
          setSelectedRows([]);
        });
    } else {
      ManualAdjustmentService.updateMakerChecker({
        userId: data.userId,
        entityId: data.entityId,
        status: data.status,
      })
        .then((res) => {
          setSnackbar('Status change!');
          getManualAdjustmentList();
          setSelectedRows([]);
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Failed to change status'),
            'error'
          );
          setSelectedRows([]);
        });
    }
  };

  const onApprove = () => {
    selectedRows.forEach((val) => {
      const obj = {
        status: CheckerMakerStatusEnum.APPROVED,
        userId: userId || '',
        entityId: val || '',
      };
      onSubmitStatus(obj);
    });
    // rowMultiSelect.map((val: any) => {
    //   const obj = { status: 'APPROVED', userId, fundLoadOrderId: val };
    //   onSubmitStatus(obj);
    // });
  };
  const onReject = () => {
    selectedRows.forEach((val) => {
      const obj = {
        status: CheckerMakerStatusEnum.REJECTED,
        userId: userId || '',
        entityId: val || '',
      };
      onSubmitStatus(obj);
    });
    // rowMultiSelect.map((val: any) => {
    //   const obj = { status: 'REJECTED', userId, fundLoadOrderId: val };
    //   onSubmitStatus(obj);
    // });
  };

  const onStatusChange = async (
    data: ITransCheckerRowElement,
    status: string
  ) => {
    const obj = {
      status: status as CheckerMakerStatusEnum,
      userId: userId || '',
      entityId: data.id || '',
    };
    onSubmitStatus(obj);
  };

  const getFundList = async () => {
    setListLoading(true);
    fetchListFundLoad({
      clientId,
    })
      .then(async (res) => {
        // const getData = res.data?.map((value: any) => ({
        //   ...value,
        //   id: value?.account?.id ?? value?.customer?.id,
        // }));
        const filteredCardCustomer = await res.data
          .filter((val: any) => val.fundLoadOrderStatus !== 'DRAFT')
          .filter((val: any) => val.accountConsolidatedDto !== null);
        // .filter((val: any) => val.accountConsolidatedDto?.card !== null);
        const sortedData = sorting(filteredCardCustomer);
        setCardReloadData(
          sortedData.map((val: ICardFundLoadProps) => ({
            ...val,
            selected: false,
          }))
        );
        // setCardReloadData(
        //   res.data.filter(
        //     (val: any) => val.accountConsolidatedDto.card !== null
        //   )
        // );

        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };
  const getRefundList = () => {
    listCardRefundOrder({
      clientId: clientId || '',
      // branchId: 'f26a0157-f741-4e7d-bca6-c7ac7272e649',
      // makerCheckerStatusList: [CheckerMakerStatusEnum.APPROVED],
    })
      .then((res) => {
        setCardRefundData(res.data);
      })
      .catch((err) => {
        setSnackbar('Error in fetching the refund list ', 'error');
      });
  };
  const getManualAdjustmentList = () => {
    ManualAdjustmentService.list({
      clientId: clientId || '',
      // makerCheckerStatusList: [CheckerMakerStatusEnum.CHECKER_PENDING],
    })
      .then((res) => {
        setManualAdjustData(res);
      })
      .catch((err) => {
        setSnackbar('Error in fetching the manual adjustment list ', 'error');
      });
  };

  useEffect(() => {
    getFundList();
    getRefundList();
    getManualAdjustmentList();
  }, []);
  return (
    <VegaPage>
      <VegaSelector
        data={tileData}
        setActiveState={selectedTab}
        active={activeState}
        header={`Card ${toLowerCase(activeState.id)}`}
        subHeader={` ${toLowerCase(activeState.id)} balance in the wallet`}
      />
      <MCCFilterSection
        isStatusFilter
        statusOption={STATUS}
        state={filterData}
        handleChange={handleFilter}
        // onSearch={onSearch}
      />
      <VegaCard fullHeight noPad>
        {activeState.id === 'ADJUSTMENT' ? (
          <ManualAdjustmentCheckerList
            data={filteredData as IManualAdjustment[]}
            refetchData={getManualAdjustmentList}
          />
        ) : (
          <>
            <FilterAction
              options={['PENDING', 'APPROVED', 'REJECTED']}
              selected={'PENDING'}
              selectedCount={selectedRows.length}
              onApprove={onApprove}
              onReject={onReject}
            />
            <ListOfCheckerCardTransaction
              data={filteredData}
              onStatusChange={onStatusChange}
              loading={listLoading}
              activeState={activeState}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setCustomerData={
                activeState.id === 'RELOAD'
                  ? setCardReloadData
                  : activeState.id === 'REFUND'
                  ? setCardRefundData
                  : setManualAdjustData
              }
            />
          </>
        )}
      </VegaCard>
    </VegaPage>
  );
};

export default CheckerCardTransaction;
