import { useSupport } from '../../providers/SupportProvider';
import { SupportRoute } from '../../routes';
import AccountDetails from './AccountDetails';
import Support from './Support';

const SupportLending = () => {
  const { navigateTo, currentRoute } = useSupport();

  if (currentRoute == SupportRoute.ACCOUNT_DETIAILS) {
    return <AccountDetails />;
  }

  if (currentRoute == SupportRoute.SUPPORT) {
    return <Support />;
  }

  navigateTo(SupportRoute.SUPPORT);

  return <div>-</div>;
};

export default SupportLending;
