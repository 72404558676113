import { CancelOutlined, EditOutlined } from '@mui/icons-material';
import {
  Dialog,
  Stack,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';
import VegaContainedButton from '../common/VegaContainedButton';
import EntityCommentsList from './EntityCommentsList';

interface EntityCommentsModal {
  open: boolean;
  onClose: () => void;
  title: string;
  entityId?: string;
}

function EntityCommentsModal({
  open,
  onClose,
  title,
  entityId,
}: EntityCommentsModal) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      style={{ maxHeight: '80vh', marginTop: '7.5rem' }}
      scroll="paper"
    >
      <Stack style={{ marginLeft: '1.5rem', marginTop: '1rem' }}>
        <Typography>{title}</Typography>
      </Stack>
      <DialogContent>
        <EntityCommentsList entityId={entityId}  />
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'}>
          <VegaContainedButton
            text={'Close'}
            onClick={() => {
              onClose();
            }}
            isPrimary
            startIcon={<CancelOutlined />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default EntityCommentsModal;
