import { saveAs } from 'file-saver';
import { REPORT_NAME_URL } from './constants';

export const downloadFile = (fileUrl: string, fileName = '') => {
  const name = fileName
    ? fileName
    : fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      saveAs(blob, name);
    })
    .catch((error) => {
      console.log('Error:', error);
    });
};

export const REPORT_SAMPLE_URL = (reportName: string) => {
  return REPORT_NAME_URL?.[reportName as keyof typeof REPORT_NAME_URL] ?? '';
};
