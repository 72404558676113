import { Box, Fade } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { PRIMARY } from '../../constants/style';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: PRIMARY.light,
  },
}));

interface VegaToolTipAndCopyProps extends TypographyProps {
  value: string;
  title?: string;
  isCopy?: boolean;
}

export const VegaToolTipAndCopy = ({
  value,
  title = 'cell Value',
  isCopy = false,
  ...restProps
}: VegaToolTipAndCopyProps) => {
  const [copied, setCopied] = React.useState(false);
  return isCopy ? (
    <Typography textOverflow="ellipsis" overflow="hidden" {...restProps}>
      {value}
    </Typography>
  ) : (
    <Box
      sx={{
        cursor: 'pointer',
        overflow: 'hidden',
      }}
      onClick={() => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}
    >
      <LightTooltip
        title={!copied ? 'Copy ' + value : 'Copied : ' + value}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 300 }}
      >
        <Box
          className="font-aspekta-500"
          fontSize={14}
          sx={{
            '::selection': {
              // color : 'red',
              // bgcolor : 'blue'
            },
          }}
          style={{
            MozUserSelect: 'all',
            WebkitUserSelect: 'all',
            msUserSelect: 'text',
          }}
        >
          <Typography textOverflow="ellipsis" overflow="hidden" {...restProps}>
            {value}
          </Typography>
        </Box>
      </LightTooltip>
    </Box>
  );
};
