import Typography from '@mui/material/Typography';
import { toLowerCase } from '../../../../constants/commonFunction';
import { CardPrintingStatus } from '../../../../types/card';
import { CIMS_PRIMARY } from '../../../assets/style';

type CardOrderRequestStatusProps = {
  status: CardPrintingStatus;
};

export const cardPrintingStatusDisplay: Partial<
  Record<CardPrintingStatus, string>
> = {
  [CardPrintingStatus.PLACED]: 'Created',
  [CardPrintingStatus.PRINTING_STARTED]: 'Underprinting',
  [CardPrintingStatus.ACCEPTED]: 'Confirmed',
  [CardPrintingStatus.PRINTED]: 'Completed',
};

const mapStatusToColor: Partial<Record<CardPrintingStatus, string>> = {
  [CardPrintingStatus.PLACED]: CIMS_PRIMARY.LIGHT_BLUE,
  [CardPrintingStatus.PRINTING_STARTED]: CIMS_PRIMARY.ORANGE,
  [CardPrintingStatus.UPLOADED]: CIMS_PRIMARY.BLUE,
  [CardPrintingStatus.ACCEPTED]: CIMS_PRIMARY.GREEN,
};

function OrderPhysicalCardStatus({ status }: CardOrderRequestStatusProps) {
  return (
    <Typography
      variant="body1"
      color={mapStatusToColor[status] ?? CIMS_PRIMARY.BLACK}
    >
      {cardPrintingStatusDisplay[status] || toLowerCase(status)}
    </Typography>
  );
}

export default OrderPhysicalCardStatus;
