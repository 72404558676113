/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import CIMS_Header from '../../components/common/CIMS_Header';
import { ListDivisions } from '../../modules/DivisionManagement';
import { useMemo, useState } from 'react';
import { useClientAuth } from '../../../providers/ClientProvider';
import {
  CIMS_SUB_MODULES,
  CIMS_RESOURCE_MODULES,
} from '../../../constants/modules';
import { checkResourceAccess } from '../../../utils/userAccessUtils';
import { CreateDivisionModal } from './CreateDivision';

function DivisionManagement() {
  const { user } = useClientAuth();
  const [refetchList, setRefetchFun] = useState();

  const canCreateEntity = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.ENTITY_MANAGEMENT.CREATE_ENTITY,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.ENTITY_MANAGEMENT,
      }),
    [user?.allResourceAccess]
  );

  return (
    <div>
      <CIMS_Header
        text="Division Management"
        component={
          canCreateEntity ? (
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <CreateDivisionModal refetchList={refetchList} />
            </Stack>
          ) : undefined
        }
      />
      <ListDivisions setRefetchFun={setRefetchFun} />
    </div>
  );
}

export default DivisionManagement;
