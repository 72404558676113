import { API_BASE_URL } from '../../../api/axiosConfig';

export const BASE_URL = API_BASE_URL;
// export const BASE_URL =
//   'http://ec2-13-235-248-72.ap-south-1.compute.amazonaws.com:8510';
export const GETWAY = '/forex';

export const CREATE_CUSTOMER = {
  BASE: '/customer/retail',
};

export const CORPORATE = {
  BASE: '/customer/corporate',
};

export const TRANSACTION = {
  BASE: '/transaction',
  WALLET_TRANSFER: '/transaction/wallet/transfer',
};

export const ACCOUNT = {
  BASE: '/account',
  PLAN: '/plan',
  SEARCH: '/account/search',
  FILTER: '/account/filter',
  CONSOLIDATED_FILTER: '/account/consolidated/filter',
};

export const BIN = {
  BASE: '/bin',
};
export const RATE_MANAGER = {
  CURRENCY_CONVERT: '/currencyConversionRate',
};

export const CHARGE = {
  BASE: '/charge',
};

export const WALLET = {
  BASE: '/wallet',
  TRANSACTION_LIST_FROM_REF_ID: '/wallet/transaction/transactionReferenceId',
};
