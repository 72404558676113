import React from 'react';
import { BORDER_GRAY, COMMON, GREY } from '../../../constants/style';
import { Box, Typography, Skeleton } from '@mui/material';

interface IProps {
  theadComponent: () => void;
  rowComponent: () => React.ReactNode;
  privious?: (page: number) => void;
  next?: (page: number) => void;
  loading: boolean;
  page?: number;
  count?: number;
  onPageChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  data: any[];
}

const BinTable = ({
  theadComponent,
  rowComponent,
  loading,
  data,
  page,
  count,
  onPageChange,
  next,
  privious,
}: IProps) => {
  return (
    <Box component={'div'} sx={{ overflowX: 'auto' }}>
      <table
        className="LOStable"
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          //   borderSpacing: '0 0px',
        }}
      >
        <thead>
          <tr>
            <>{theadComponent()}</>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={12} style={{ background: 'white' }}>
                <Skeleton variant="text" height={100} />
              </td>
            </tr>
          ) : data.length ? (
            <>{rowComponent()}</>
          ) : (
            <tr>
              <td colSpan={12}>
                <Box
                  sx={{
                    height: '300px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    borderRadius: '12px',
                  }}
                >
                  <Typography
                    className="font-aspekta-450"
                    color={COMMON.darkGray}
                  >
                    No Result
                  </Typography>
                </Box>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
};

export default BinTable;

const VegaLoader = () => {
  return (
    <Box className="loader-container" sx={{ height: '500px' }}>
      <div className="loader"></div>
    </Box>
  );
};
