import { Box, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { VegaAccordian, VegaTextField } from '../../../../../components/common';
import VegaSelect from '../../../../../components/common/VegaSelect';
import { GREEN, GREY } from '../../../../../constants/style';
import { dateFormat } from '../../../../variable';
import { ICommonProps } from '../../../../../types/commonType';

interface IProps extends ICommonProps {}

const CardSummary = ({ state, handleChange }: IProps) => {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <VegaTextField
            value={state?.customer?.firstName}
            onChange={(e) => handleChange('name', e.target.value)}
            key="name"
            label="Customer Name"
            type={'text'}
            placeholder="Customer Name"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state.account.id}
            onChange={(e) => handleChange('customerId', e.target.value)}
            key="customerId"
            label="Account Id"
            type={'text'}
            placeholder="Account Id"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state.card.cardNumber}
            onChange={(e) => handleChange('cardNumber', e.target.value)}
            key="cardNumber"
            label="Card No"
            type={'text'}
            placeholder="Card No"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VegaSelect
            options={['ALLOCATED']}
            key="cardStatus"
            selected={state.card.cardStatus}
            label="Card Status"
            onChange={(value) => handleChange('cardStatus', value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={dateFormat(state.card.createdAt)}
            onChange={(e) => handleChange('activationDate', e)}
            key="activationDate"
            label="Activation Date"
            placeholder="Activation Date"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VegaTextField
            value={state.card.expiryDate}
            onChange={(e) => handleChange('expiryDate', e)}
            key="expiryDate"
            label="Expiry Date"
            placeholder="Expiry Date"
            disabled
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <VegaAccordian
          header={'Alert Oprtion'}
          content={
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: GREY.dark,
                      '&.Mui-checked': {
                        color: GREEN.dark,
                      },
                    }}
                  />
                }
                label={'Email'}
                sx={{ fontSize: '14px' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: GREY.dark,
                      '&.Mui-checked': {
                        color: GREEN.dark,
                      },
                    }}
                  />
                }
                label={'SMS'}
                sx={{ fontSize: '14px' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: GREY.dark,
                      '&.Mui-checked': {
                        color: GREEN.dark,
                      },
                    }}
                  />
                }
                label={'Whatsapp'}
                sx={{ fontSize: '14px' }}
              />
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default CardSummary;
