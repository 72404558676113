/** @format */

import { Clear, Done } from '@mui/icons-material';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { VegaTextField } from '../components/common';
import { useClientAuth } from '../providers/ClientProvider';

type Props = {};

const SetNewPassword = (props: Props) => {
  const { completeNewPassword } = useClientAuth();
  const theme = useTheme();
  const [password, setPassword] = useState('');
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const minLengthRegExp = /.{8,}/;
  const passwordLength = password.length;
  const uppercasePassword = uppercaseRegExp.test(password);
  const lowercasePassword = lowercaseRegExp.test(password);
  const digitsPassword = digitsRegExp.test(password);
  const specialCharPassword = specialCharRegExp.test(password);
  const minLengthPassword = minLengthRegExp.test(password);
  const specialCharecter = `^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~  + =`;

  const getDisable = () => {
    if (
      passwordLength &&
      uppercasePassword &&
      lowercasePassword &&
      digitsPassword &&
      specialCharPassword &&
      minLengthPassword
    ) {
      return false;
    } else {
      return true;
    }
  };

  const getText = (isValue: boolean, text: string, isSpecial = false) => {
    return (
      <>
        <Typography
          variant="caption"
          sx={{
            ...(!isValue ? { color: 'red' } : { color: 'green' }),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!isValue ? (
            <Clear color="error" fontSize="small" sx={{ mr: 1 }} />
          ) : (
            <Done color="success" fontSize="small" sx={{ mr: 1 }} />
          )}
          {text}
        </Typography>
        {isSpecial && (
          <Typography
            variant="caption"
            sx={{
              ...(!isValue ? { color: 'red' } : { color: 'green' }),
              ml: 3,
            }}
          >
            {specialCharecter}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        height: '100vh',
        p: 5,
        bgcolor: '#EBEFF4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '20px',
          py: 8,
          px: 5,
          maxWidth: '500px',
          marginX: 'auto',
          // display: 'flex',
          // justifyContent: 'space-between',
          // alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant="subtitle2" mb={5} textAlign={'center'}>
          {' '}
          Enter a new Password
        </Typography>
        <Box
          component={'form'}
          onSubmit={(e) => {
            e.preventDefault();
            completeNewPassword(password);
          }}
        >
          <Box mb={2}>
            <Typography>New Password</Typography>
            <VegaTextField
              type={'password'}
              key={'new-password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
              {getText(passwordLength !== 0, 'Password is empty')}
              {getText(minLengthPassword, '8-character minimum length')}
              {getText(
                uppercasePassword,
                'Contains at least 1 uppercase letter'
              )}
              {getText(
                lowercasePassword,
                'Contains at least 1 lowercase letter'
              )}
              {getText(digitsPassword, 'Contains at least 1 number')}
              {getText(
                specialCharPassword,
                'Contains at least 1 special character from the following set, or a non-leading, non-trailing space character',
                true
              )}
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={getDisable()}
            sx={{ bgcolor: '#7081D8', width: '100%' }}
          >
            Confirm
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default SetNewPassword;
