/** @format */

import {
  AccountBalanceOutlined,
  CurrencyExchangeOutlined,
  LegendToggleSharp,
  ReceiptLongOutlined,
  SupervisorAccountOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import Accounts from '../pages/Accounts';
import Bills from '../pages/Bills';
import Customers from '../pages/Customers';
import { GeneralLedger } from '../pages/GeneralLedger';
import Transactions from '../pages/Transactions';
import { ROUTES } from '../routes';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: ROUTES.CUSTOMERS,
    icon: <SupervisorAccountOutlined sx={{ fontSize: 18 }} />,
    label: 'Customers',
    component: <Customers />,
    isProgramSelector: true,
    isAccountSelector: false,
    isSimulatorRoutes: true,
  },
  {
    id: ROUTES.ACCOUNTS,
    icon: <AccountBalanceOutlined sx={{ fontSize: 18 }} />,
    label: 'Accounts',
    component: <Accounts />,
    isProgramSelector: true,
    isAccountSelector: false,
    isSimulatorRoutes: true,
  },
  {
    id: ROUTES.TRANSACTIONS,
    icon: <CurrencyExchangeOutlined sx={{ fontSize: 18 }} />,
    label: 'Transactions',
    component: <Transactions />,
    isProgramSelector: true,
    isAccountSelector: true,
    isSimulatorRoutes: true,
  },
  {
    id: ROUTES.GENERAL_LEDGER,
    icon: <LegendToggleSharp sx={{ fontSize: 18 }} />,
    label: 'Ledger',
    component: <GeneralLedger />,
    isProgramSelector: true,
    isAccountSelector: true,
    isSimulatorRoutes: true,
  },
  {
    id: ROUTES.BILLS,
    icon: <ReceiptLongOutlined sx={{ fontSize: 18 }} />,
    label: 'Bills',
    component: <Bills />,
    isProgramSelector: true,
    isAccountSelector: true,
    isSimulatorRoutes: true,
  },
];

const SimulatorContainer = (props: Props) => {
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  );

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default SimulatorContainer;
