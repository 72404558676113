import { Box, Button, Stack } from '@mui/material';
import {
  GridColDef,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { VegaToolTipAndCopy } from '../../../../components/common/VegaToolTipNCopy';
import CIMSDataGrid from '../../../components/common/CIMS_DataGrid';
import ChangeStatusModal from './ChangeStatusModal';
import PrintCardStatusLabel from './PrintCardStatusLabel';
import CIMS_FilterSection from '../../../components/common/CIMS_FilterSection';
import { useState } from 'react';

interface IProps {}

const actions = [
  {
    lable: 'Confirm Order',
    content: 'Are you sure you want change card inventory request to confirm? ',
  },
  {
    lable: 'Update Printing',
    content:
      'Are you sure you want change card inventory request to under printing? ',
  },
  {
    lable: 'Printing Completed',
    content:
      'Are you sure you want change card inventory request to printing completed?',
  },
];

const ActionsButton = (data: any) => {
  return (
    // <Stack direction="row" spacing={2}>
    // <Button variant="outlined">Mark as Shipped</Button>
    <ChangeStatusModal
      triggerText={actions[0].lable}
      content={actions[0].content}
    />

    // </Stack>
  );
};

export const getColumns = (props: IProps): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'S.No.',
      flex: 0.5,
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.row.id} />,
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      flex: 1,
      //   renderCell: (props) => <VegaToolTipAndCopy value={props.row.entityId} />,
    },
    {
      field: 'orderId',
      headerName: 'Order Id',
      flex: 1,
    },

    {
      field: 'cardType',
      headerName: 'Card Type',
      type: 'select',
      flex: 1,
    },
    {
      field: 'program',
      headerName: 'programName',
      flex: 1.3,
    },
    {
      field: 'binNo',
      headerName: 'Bin Number',
      flex: 0.8,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 0.8,
    },
    {
      field: 'embossa',
      type: 'action',
      headerName: 'Embossa file',
      flex: 1,
      renderCell: (props) => {
        return <Button variant="text">Download</Button>;
      },
    },
    {
      field: 'status',
      type: 'action',
      headerName: 'Status',
      flex: 1.3,
      renderCell: (props) => {
        return <PrintCardStatusLabel status={props.row.status as string} />;
      },
    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Action',
      renderCell: (props) => {
        return <ActionsButton />;
      },
      flex: 1.2,
    },
  ];
};

function PrintCardsList() {
  const [search, setSearch] = useState<string>('');
  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box mt={2}>
        <CIMSDataGrid
          data={DUMMY}
          columns={getColumns({})}
          idColumn="id"
          paginationMode="server"
          components={{
            Toolbar: (props) => {
              return (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  my={2}
                >
                  <CIMS_FilterSection search={search} setSearch={setSearch} />
                  {/* <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <GridToolbarFilterButton {...props} />
                    <GridToolbarQuickFilter
                      autoFocus
                      variant="outlined"
                      className="aspektaInput"
                      sx={{
                        bgcolor: 'white',
                        color: '#999DA8',
                        height: '35px',
                        borderRadius: '6px',
                        '&.MuiOutlinedInput-root': {
                          bgcolor: 'white !important',
                        },
                        '.Mui-focused': {
                          outline: 'none',
                          border: 'none',
                        },
                      }}
                    />
                  </Stack> */}
                </Stack>
              );
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default PrintCardsList;

const DUMMY = [
  {
    id: '1',
    createdAt: '19/07/23',
    orderId: 1212412,
    cardType: 'Instant',
    program: 'SBM Platinum Card',
    binNo: 838472,
    quantity: 213123,
    status: 'Order Created',
  },
  {
    id: '2',
    createdAt: '19/07/23',
    orderId: 1212412,
    cardType: 'Instant',
    program: 'SBM Platinum Card',
    binNo: 838472,
    quantity: 213123,
    status: 'Order Confirmed',
  },
  {
    id: '3',
    createdAt: '19/07/23',
    orderId: 334555,
    cardType: 'Personalized',
    program: 'SBM Platinum Card',
    binNo: 223343,
    quantity: 213123,
    status: 'Under Printing',
  },
  {
    id: '4',
    createdAt: '19/07/23',
    orderId: 1212412,
    cardType: 'Instant',
    program: 'SBM Platinum Card',
    binNo: 838472,
    quantity: 213123,
    status: 'Printing Completed',
  },
];
