import {
  Campaign,
  CampaignMilestoneDto,
  CampaignRuleType,
  CampaignType,
  ContributionData,
  GiftVoucher,
  InclusionExclusionConditionType,
  InlcusionExclusionData,
  MilestoneEventType,
  PointBasedMilestone,
  PointBasedRewardsData,
  RuleFilters,
  RuleRewardData,
} from '../../../types/RewardsLoyalty';
import {
  NonPointBasedMilestoneRewardsData,
  PointBasedMilestoneRewardsData,
} from './CampaignRuleProvider';

export class CampaignRuleBuilder {
  static constructGiftsList = (data: {
    giftVouchers: Partial<GiftVoucher>[];
    discountVouchers: Partial<GiftVoucher>[];
    subscriptionVouchers: Partial<GiftVoucher>[];
  }) => {
    const giftsData = [
      ...data.giftVouchers,
      ...data.discountVouchers,
      ...data.subscriptionVouchers,
    ]
      .filter((item) => item.giftId != null)
      .map((item) => {
        const voucher: GiftVoucher = {
          giftId: item.giftId!,
          vendorName: item.vendorName!,
          delayDays: item.delayDays!,
          giftType: item.giftType!,
          amount: item.amount!,
        };
        return voucher;
      });

    return giftsData;
  };

  static constructCampaignRewardsData = (data: {
    ruleType: string | null;
    rewardsData: Partial<PointBasedRewardsData>;
  }) => {
    var ruleRewardsData: RuleRewardData = {};

    if (data.ruleType == CampaignRuleType.TRANSACTIONAL) {
      ruleRewardsData = {
        points: data.rewardsData.rewardPoints,
        onSpends: data.rewardsData.perAmount,
        maximumPoint: data.rewardsData.maximumPoints,
        timeframe: data.rewardsData.iteration,
      };
    } else if (data.ruleType == CampaignRuleType.NON_TRANSACTIONAL) {
      ruleRewardsData = {
        points: data.rewardsData.rewardPoints,
        timeframe: data.rewardsData.iteration,
      };
    }

    return ruleRewardsData;
  };

  static constructContribution = (
    contributionData: Partial<ContributionData>
  ) => {
    const ruleContribution: ContributionData = {
      contributionEntities: contributionData.contributionEntities ?? [],
    };
    return ruleContribution;
  };

  static constructMilestoneList = (data: {
    ruleType: string | null;
    event: string;
    campaign: Campaign | null;
    pointBasedMilestoneData: Partial<PointBasedMilestoneRewardsData>;
    nonPointBasedMilestoneData: Partial<NonPointBasedMilestoneRewardsData>;
  }) => {
    if (data.ruleType != CampaignRuleType.MILESTONE) return;
    if (!data.campaign) return;

    var milestoneData: CampaignMilestoneDto[] = [];
    if (data.campaign?.campaignType == CampaignType.POINT_BASED) {
      const pointBasedIteration = data.pointBasedMilestoneData.iteration;
      if (!pointBasedIteration) return;
      milestoneData =
        data.pointBasedMilestoneData.milestones?.map((item) => {
          const dto: CampaignMilestoneDto = {
            values: this.mapPointBasedMilestoneToValuesDto(
              item,
              data.event,
              pointBasedIteration
            ),
            mileStoneType: data.event,
          };
          return dto;
        }) ?? [];
    }

    if (data.campaign?.campaignType == CampaignType.NON_POINT_BASED) {
      const nonPointBasedIteration = data.nonPointBasedMilestoneData.iteration;
      if (!nonPointBasedIteration) return;
      milestoneData =
        data.nonPointBasedMilestoneData.milestones?.map((item) => {
          const dto: CampaignMilestoneDto = {
            values: this.mapNonPointBasedMilestoneToValuesDto(
              item,
              data.event,
              nonPointBasedIteration
            ),
            mileStoneType: data.event,
            giftsLists: [
              ...(item.giftVouchers ?? []),
              ...(item.discountVouchers ?? []),
              ...(item.subscriptionVouchers ?? []),
            ],
          };
          return dto;
        }) ?? [];
    }
    return milestoneData;
  };

  private static mapPointBasedMilestoneToValuesDto = (
    item: Partial<PointBasedMilestone>,
    eventType: string,
    iteration: string
  ) => {
    const dto: RuleRewardData = {
      points: item.rewardPoints,
      timeframe: iteration,
    };

    switch (eventType) {
      case MilestoneEventType.BOTH:
        dto.onSpends = item.count;
        dto.volume = item.volume;
        break;
      case MilestoneEventType.BY_COUNT:
        dto.onSpends = item.count;
        break;
      case MilestoneEventType.BY_VOLUME:
        dto.volume = item.volume;
        break;
    }

    return dto;
  };

  private static mapNonPointBasedMilestoneToValuesDto = (
    item: Partial<PointBasedMilestone>,
    eventType: string,
    iteration: string
  ) => {
    const dto: RuleRewardData = {
      timeframe: iteration,
    };
    switch (eventType) {
      case MilestoneEventType.BOTH:
        dto.onSpends = item.count;
        dto.volume = item.volume;
        break;
      case MilestoneEventType.BY_COUNT:
        dto.onSpends = item.count;
        break;
      case MilestoneEventType.BY_VOLUME:
        dto.volume = item.volume;
        break;
    }

    return dto;
  };

  static constructRuleFilters = (
    inclusionExclusionData: Partial<InlcusionExclusionData>
  ) => {
    const ruleFilters: RuleFilters = {
      minimumAmount: inclusionExclusionData.transactionAmount!,
      transactionCode: {
        transactionCodeList: inclusionExclusionData.transactionTypes ?? [],
        filterType: inclusionExclusionData.isTransactionInclusive
          ? InclusionExclusionConditionType.INCLUSION
          : InclusionExclusionConditionType.EXCLUSION,
      },
      merchantCategory: {
        merchantCategoryIdList: inclusionExclusionData.categories ?? [],
        filterType: inclusionExclusionData.isCategoriesInclusive
          ? InclusionExclusionConditionType.INCLUSION
          : InclusionExclusionConditionType.EXCLUSION,
      },
      merchantId: {
        filterType: inclusionExclusionData.isMerchantInclusive
          ? InclusionExclusionConditionType.INCLUSION
          : InclusionExclusionConditionType.EXCLUSION,
        merchantIdList: inclusionExclusionData.merchants ?? [],
      },
    };

    return ruleFilters;
  };
}
