import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';
interface IVegaCerdPorps {
  children: JSX.Element;
  sx?: SxProps<Theme>;
}

const Vega_Card = ({ children, sx }: IVegaCerdPorps) => {
  return (
    <Box
      sx={{
        border: '1px solid #E1E4EB',
        borderRadius: '8px',
        bgcolor: 'white',
        p: '1rem',
        mx: '1.5rem',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Vega_Card;
