import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { AccountPolicyInterface } from '../../../types/policy';
import { IRowData, PolicySummaryTable } from './common/SummaryTable';

interface Props {
  policy?: AccountPolicyInterface | null;
}

function AccountPolicySummary({ policy }: Props) {
  const getSummaryRows = () => {
    if (!policy) return [];
    const summaryRows: IRowData[] = [];
    const overLimit = policy.overlimit;
    const paymentDeviation = policy.payment_deviation;
    const cashLimit = policy.cash_limit;
    const chargeOff = policy.days_to_mark_account_charge_off;
    const writeOff = policy.days_to_mark_account_write_off;

    if (overLimit) {
      summaryRows.push({
        rowData: [
          'Overlimit',
          _.startCase(_.toLower(overLimit.value_type)),
          overLimit.value?.toString(),
        ],
      });
    }

    if (paymentDeviation) {
      summaryRows.push({
        rowData: [
          'Payment Deviation',
          _.startCase(_.toLower(paymentDeviation.value_type)),
          paymentDeviation.value?.toString(),
        ],
      });
    }

    if (cashLimit) {
      summaryRows.push({
        rowData: [
          'Cash Limit',
          _.startCase(_.toLower(cashLimit.value_type)),
          cashLimit.value?.toString(),
        ],
      });
    }

    if (chargeOff) {
      summaryRows.push({
        rowData: ['Charge Off', 'Days', chargeOff.toString()],
      });
    }

    if (writeOff) {
      summaryRows.push({
        rowData: ['Write Off', 'Days', writeOff.toString()],
      });
    }
    return summaryRows;
  };

  return (
    <Stack spacing={2}>
      <Typography>Grace Period {policy?.grace_period ?? '-'}</Typography>

      <PolicySummaryTable
        headers={['Transaction Category', ' Value Type', 'Value']}
        rows={getSummaryRows()}
      />
    </Stack>
  );
}

export default AccountPolicySummary;
