import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import VegaOutlineButton from '../../../components/common/VegaOutlineButton';
import { BORDER_GRAY, COMMON, GREY, PRIMARY } from '../../../constants/style';
import {
  IBaseRuleProps,
  ICampaignDataProps,
  Campaign,
  ICampaignSelectorProps,
  ICreateCampaignProps,
  IPointBaseRulesProps,
  RewardType,
  RuleType,
  Timeframe,
  CampaignType,
} from '../../../types/RewardsLoyalty';
import CampaignSelector from '../../../components/RewardsLoyalty/Campaigns/CampaignSelector';
import VegaOutLineTextField from '../../../components/common/VegaOutLineTextField';
import {
  Add,
  AddCircleOutline,
  ArrowRightAltOutlined,
  ArrowRightAltRounded,
  ArrowRightRounded,
  Clear,
  Search,
} from '@mui/icons-material';
import VegaContainedButton from '../../../components/common/VegaContainedButton';
import ListOfCampaigns from '../../../components/RewardsLoyalty/Campaigns/ListOfCampaigns';
import {
  ArrowRightOutLine,
  FIleIcon,
  GiftVoucherIcon,
} from '../../../components/icon/Icons';
import VegaModal from '../../../components/common/VegaModal';
import AddNewCampaignForm from '../../../components/RewardsLoyalty/Campaigns/AddNewCampaignForm';
import { VegaCard, VegaPage } from '../../../components/common';
import { ICommonProps } from '../../../types/commonType';
import { RewardsRoute } from '../../../routes';
import VegaLabel from '../../../components/common/VegaLabel';
import { AntSwitch } from '../../../constants/commonFunction';
import VegaTextButton from '../../../components/common/VegaTextButton';
import RuleDetails from '../../../components/RewardsLoyalty/Campaigns/RulesConfiguration/Point Allocation/AddNewRule/RuleDetails';
import RuleTypeConfig from '../../../components/RewardsLoyalty/Campaigns/RulesConfiguration/Point Allocation/AddNewRule/RuleTypeConfig';
import {
  createCampaign,
  fetchCampaignByProgramId,
} from '../../../api/rewardsLoyalty';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import NoVoucherAndRewards from './NoVoucherAndRewards';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch } from '../../../store/hook';
import { setHeader } from '../../../store/common/stateSlice';
import RewardOfferings from '../../../components/RewardsLoyalty/components/RewardOfferings';
import VegaPrimaryButton from '../../../components/common/v2/VegaPrimaryButton';
import NewCampaignModal from './modals/NewCampaignModal';
import { useCampaign } from '../../../components/RewardsLoyalty/providers/CampaignProvider';
import CampaignList from '../../../components/RewardsLoyalty/components/CampaignList';
import VegaTextV2 from '../../../components/common/v2/VegaTextV2';
import { useRewardsNavigation } from '../../../providers/RewardsNavigationProvider';

interface RewardsProps {
  navigateToConfigPage: (routes: RewardsRoute, campaign?: Campaign) => void;
  campaigns: any[];
  state: ICampaignDataProps;
}

export interface IRulesTypeProps {
  rule: string;
  subTxt: string;
  id: string;
}

type ShowCampaignModalData = {
  show: boolean;
  campaignType?: string;
};
const RewardHome = () => {
  const { navigateTo } = useRewardsNavigation();
  const { campaigns, createCampaign, loading } = useCampaign();
  const [filter, setFilter] = useState({ search: '' });
  const [showNewCampaignModal, setShowNewCampaignModal] =
    useState<ShowCampaignModalData>({
      show: false,
    });

  const handleChangeFilter = (name: string, value: string) => {
    setFilter({ ...filter, [name]: value });
  };

  const navigateRoute = () => {
    navigateTo(RewardsRoute.BENEFIT_INVENTORY);
  };

  function closeCampaignModal() {
    setShowNewCampaignModal({
      show: false,
      campaignType: undefined,
    });
  }

  return (
    <>
      <Box
        px={3}
        py={2}
        sx={{
          marginTop: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1,
        }}
      >
        <Typography
          fontSize={17}
          color={COMMON.gray}
          className="font-aspekta-500"
        >
          Our Offering
        </Typography>
        <VegaOutlineButton
          py={0.7}
          className={'font-aspekta-500'}
          text={'Benefits Inventory'}
          endIcon={<ArrowRightOutLine />}
          isPurple
          onClick={navigateRoute}
        />
      </Box>

      <RewardOfferings
        onPointBasedClick={() => {
          setShowNewCampaignModal({
            show: true,
            campaignType: CampaignType.POINT_BASED,
          });
        }}
        onNonPointBasedClick={() => {
          setShowNewCampaignModal({
            show: true,
            campaignType: CampaignType.NON_POINT_BASED,
          });
        }}
      />

      <Box mt={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 2,
            px: 3,
            borderBottom: BORDER_GRAY,
          }}
        >
          <VegaTextV2 fontSize={'14px'} text=" All Campaigns" />
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <Box sx={{ flexGrow: 1 }}>
              {/* <VegaOutLineTextField
                value={filter.search}
                Icon={<Search />}
                key="search"
                height="35px"
                onChange={(e) => handleChangeFilter('search', e.target.value)}
              /> */}
            </Box>
            <Box sx={{}}>
              <VegaPrimaryButton
                text={'New Campaign'}
                size="small"
                startIcon={<Add />}
                onClick={() => {
                  setShowNewCampaignModal({ show: true });
                }}
              />
            </Box>
          </Stack>
        </Box>

        <Box>
          {loading && (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                bottom: '40%',
              }}
            >
              <CircularProgress />
            </div>
          )}
          {campaigns.length ? (
            <div style={{ height: '65vh', padding: '12px' }}>
              <CampaignList
                onCampaignClick={function (campaign: Campaign): void {
                  navigateTo(RewardsRoute.CAMPAIGN_CONFIGURATION);
                }}
              />
            </div>
          ) : (
            <NoVoucherAndRewards
              text="No Campaigns created"
              subText="Create a campaign where users can earn points or rewards is a
            program that incentivizes cardholders"
            />
          )}
        </Box>
      </Box>

      <NewCampaignModal
        open={showNewCampaignModal.show}
        campaignType={showNewCampaignModal.campaignType}
        onClose={function (): void {
          closeCampaignModal();
        }}
        onSubmit={(data) => {
          createCampaign(data);
        }}
      />
    </>
  );
};

export default RewardHome;
