import { Box, Stack } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { VPTextField, VegaUploadNPreview } from '../../../ui-components/common';
import VegaHeader from '../../common/VegaHeader';
import VegaTextFieldV2 from '../../common/v2/VegaTextFieldV2';
import { GenericModal } from './GenericModal';

interface IProps {
  onSubmit?: any;
  header?: string;
  triggerLabel?: string;
}

export function RemarkDocsModal({ onSubmit, ...props }: IProps) {
  const hookForm = useForm<{
    remarks: string;
    disputeProofs: File[];
  }>();

  return (
    <>
      <GenericModal
        onSubmit={() => {
          const data = hookForm.getValues();
          onSubmit(data);
        }}
        header={props?.header ?? 'Re-initiate Chargeback'}
        triggerLabel={props?.triggerLabel ?? 'Re-initiate Chargeback'}
        triggerProps={{
          variant: 'contained',
        }}
        hasConfirmCTA
        confirmText="Submit"
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <Stack spacing={2}>
            <Controller
              name="remarks"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Stack>
                  <VegaHeader text={'remark (optional)'} />

                  <VPTextField
                    multiline
                    maxRows={3}
                    minRows={3}
                    placeholder="Add Remark"
                    {...field}
                    isTextArea
                  />
                </Stack>
              )}
            />
            <Controller
              name="disputeProofs"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Box>
                  <VegaHeader text={'add documents'} />
                  <VegaUploadNPreview
                    value={field.value || []}
                    onChange={field.onChange}
                    preview2
                    max={5}
                    accept={{
                      'application/pdf': ['.pdf'],
                    }}
                  />
                </Box>
              )}
            />
          </Stack>
        </Box>
      </GenericModal>
    </>
  );
}
