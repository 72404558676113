import { useEffect, useState } from 'react';
import { EntityComment } from '../../../types/entityAuth';

import { CheckerCommentAddedView } from './CheckerCommentAddedView';
import { CheckerCommentEditView } from './CheckerCommentEditView';
import { CheckerCommentInitialView } from './CheckerCommentInitialView';

export enum CommentInputViewState {
  INITIAL,
  COMMENT_ADDED,
  EDIT,
}

interface CheckerCommentInputProps {
  initialValue?: EntityComment | null;
  initialState?: CommentInputViewState;
  onAddCommentClick: (comment: string) => void;
  onCommentEdit: (commentId: string, comment: string) => void;
  onCancelClick: () => void;
  onApprovedClick: () => void;
  disabled: boolean;
}

function CheckerCommentInput({
  onAddCommentClick,
  onCommentEdit,
  onCancelClick,
  onApprovedClick,
  initialValue,
  disabled,
  initialState,
}: CheckerCommentInputProps) {
  const [comment, setComment] = useState<string>('');
  const [viewState, setViewState] = useState<CommentInputViewState>(
    initialState ?? CommentInputViewState.INITIAL
  );
  const [loading, setLoading] = useState<boolean>(false);

  function updateViewState(updatedState: CommentInputViewState) {
    setViewState(updatedState);
  }

  function didClickAddComment(comment: string) {
    setComment(comment);
    updateViewState(CommentInputViewState.COMMENT_ADDED);
    onAddCommentClick(comment);
  }

  function didClickCancel() {
    switch (viewState) {
      case CommentInputViewState.EDIT:
        updateViewState(CommentInputViewState.COMMENT_ADDED);
        return;
      case CommentInputViewState.INITIAL:
        onCancelClick();
        return;
    }
  }

  function didClickEdit() {
    updateViewState(CommentInputViewState.EDIT);
  }

  function didClickApprove() {
    onApprovedClick();
  }

  function didClickSaveInEditMode(comment: string) {
    const commentId = initialValue?.id;
    if (!commentId) {
      return;
    }
    setComment(comment);
    updateViewState(CommentInputViewState.COMMENT_ADDED);
    onCommentEdit(commentId, comment);
  }

  useEffect(() => {
    if (initialState) {
      updateViewState(initialState);
    }
    setComment(initialValue?.comments ?? '');
  }, [initialValue]);

  if (viewState == CommentInputViewState.INITIAL) {
    return (
      <CheckerCommentInitialView
        didClickAddComment={didClickAddComment}
        didClickCancel={didClickCancel}
        loading={loading}
        initialCommentValue={comment}
        disabled={disabled}
      />
    );
  }

  if (viewState == CommentInputViewState.COMMENT_ADDED) {
    return (
      <CheckerCommentAddedView
        comment={comment}
        didClickEdit={didClickEdit}
        didClickApprove={didClickApprove}
        createdAt={initialValue?.createdAt}
        disabled={disabled}
      />
    );
  }

  if (viewState == CommentInputViewState.EDIT) {
    return (
      <CheckerCommentEditView
        didClickSave={didClickSaveInEditMode}
        didClickCancel={didClickCancel}
        initialCommentValue={comment}
        loading={loading}
        disabled={disabled}
      />
    );
  }

  return <></>;
}

export default CheckerCommentInput;
