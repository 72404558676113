import { styled } from '@mui/material';
import VegaTextV2, { StyledTypographyProps } from '../common/v2/VegaTextV2';

export const CardHeading = styled(VegaTextV2)<StyledTypographyProps>(
  ({ theme, bold }) => {
    return {
      fontFamily: 'Aspekta !important',
      color: '#3A4A5F',
      fontWeight: 700,
      fontSize: '.8125rem',
    };
  }
);
