/** @format */

import { Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { BACKGROUND, BORDER_GRAY, GREY, RED } from '../../constants/style';
import { useClientAuth } from '../../providers/ClientProvider';

type Props = {
  text: string;
  subtext?: string;
  name?: any;
  postInputText?: string;
  helperText?: string;
  isSelect?: boolean;
  value?: number | null | string;
  onChange: (value: number | string) => void;
  min?: number;
  max?: number;
  error?: any;
  isValidPDD?: any;
  disabled?: boolean;
};

const BILLING_CYCLE_TYPES = ['MONTHLY', 'BI_MONTHLY', 'ROLLING'];

const BillingPolicyFieldSelectors = ({
  text,
  subtext,
  postInputText,
  helperText,
  value,
  onChange,
  isSelect,
  min,
  max,
  error,
  name,
  isValidPDD,
  disabled = false,
}: Props) => {
  const { isAdmin } = useClientAuth();
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={3}
        alignItems="center"
        display={'flex'}
        flexDirection={'column'}
        sx={{ justifyContent: 'center', alignItems: 'flex-start' }}
      >
        <Typography
          sx={{ color: GREY.darkest, fontWeight: 'bold' }}
          variant="body2"
          m={0}
        >
          {text}
        </Typography>
        {subtext && (
          <Typography m={0} variant="caption" fontSize={14} color={'#656565'}>
            {subtext}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={9}>
        {isSelect ? (
          <Grid container>
            {BILLING_CYCLE_TYPES.map((val: any) => (
              <Box
                sx={{
                  px: 2,
                  py: 0.6,
                  mr: 1,
                  border: BORDER_GRAY,
                  borderRadius: '5px',
                  cursor: 'pointer',
                  color: GREY.darkest,
                  fontWeight: 'bold',
                  fontSize: 16,
                  ...(value === val
                    ? { bgcolor: BACKGROUND.LIGHT_BLUE }
                    : { bgcolor: 'white' }),
                }}
                onClick={() => onChange(val)}
              >
                {_.startCase(_.toLower(val))}
              </Box>
            ))}
          </Grid>
        ) : (
          <>
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{ position: 'relative' }}
            >
              <TextField
                size="small"
                type={'number'}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                sx={{ width: 50 }}
                InputProps={{ inputProps: { min, max } }}
                // disabled={!isAdmin || disabled}
                disabled={disabled}
              />
              {error && (
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '-20px',
                    left: 0,
                    m: 0,
                    color: RED.dark,
                  }}
                  variant="caption"
                  ml={2}
                >
                  {error}
                </Typography>
              )}

              <Typography
                variant="caption"
                fontSize={14}
                color={'#656565'}
                ml={2}
              >
                {postInputText}
              </Typography>
            </Box>
            <Typography variant="caption" fontSize={14} color={'#656565'}>
              {helperText}
            </Typography>
          </>
        )}
      </Grid>
      {/* <Grid item xs={0} sm={4}></Grid> */}
    </Grid>
  );
};

export default BillingPolicyFieldSelectors;
