import { OutlinedInput, OutlinedInputProps } from '@mui/material';

interface IProps extends OutlinedInputProps {
  fixWidth?: number | string;
}

const Vega_OutlineTextField = ({ fixWidth, sx, ...rest }: IProps) => {
  return (
    <OutlinedInput
      {...rest}
      className="aspektaInput"
      sx={{
        height: '35px',
        width: fixWidth ?? 'auto',
        borderRadius: '6px',
        '&.MuiOutlinedInput-root': {
          bgcolor: 'white !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EBEBEB',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EBEBEB',
          borderWidth: '1px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EBEBEB',
          borderWidth: '1px',
        },
        ...(sx || {}),
      }}
    />
  );
};

export default Vega_OutlineTextField;
