import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import {
  ListTransactionsFilterRequestDto,
  TransactionDto,
} from '../types/transaction';
import { BASE_URL, TRANSACTION } from './endpoints';
import { PaginatedResponse } from '../utils/api';

const TRANSACTION_BASE_URL = BASE_URL + TRANSACTION.BASE;
const TICKETS_BASE_URL = BASE_URL + TRANSACTION.TICKETS;
const TRANSACTION_DISPUTE_URL = BASE_URL + TRANSACTION.DISPUTE;

export const createTransactionForAccount = (
  accountId: string,
  code: string,
  amount: number,
  time?: string,
  mcc?: string,
  merchantLocation?: string
) => {
  var mccCode = 123;
  if (mcc) {
    mccCode = Number(mcc);
  }
  const data = {
    accountId,
    parentTransactionId: null,
    amount,
    currency: 'INR',
    transactionIndicator: 'DEBIT',
    transactionCategory: 'CARD',
    transactionCode: code,
    mid: '345',
    mcc: mccCode,
    lng: 12.0,
    lat: 10.0,
    description:
      code === 'CARD_ECOM'
        ? 'electronic commerce services'
        : 'ATM cash withdrawal',
    merchantName:
      code === 'CARD_ECOM'
        ? 'Amazonpay/Bgr/IN/123222'
        : 'SBI_ATM/Vadodara/47765',
    providerAttributesJson: '{}',
    merchantLocation: merchantLocation ?? '',
    ...(time && { transactionTime: time }),
  };
  return axios.post(TRANSACTION_BASE_URL, data);
};

export const createRepaymentForAccount = (
  accountId: string,
  amount: number,
  time?: string
) => {
  const data = {
    accountId,
    currency: 'INR',
    amount,
    paymentMode: 'Repayment - OfflineCollection',
    transactionCode: 'REPAYMENT',
    description: 'System_repayment',
    ...(!!time && { transactionTime: dayjs(time).toISOString() }),
  };
  return axios.post(`${TRANSACTION_BASE_URL}/payment`, data);
};
export const getTransactionById = (
  transactionId: string
): Promise<AxiosResponse<TransactionDto, any>> => {
  return axios.get(TRANSACTION_BASE_URL + `/${transactionId}`);
};
export const submitDisputeReason = (transactionId: string, reason?: string) => {
  const data = {
    transactionId,
    reason,
  };
  return axios.post(TRANSACTION_DISPUTE_URL, data);
};

export const listTransactionsForAccount = (
  accountId: string
): Promise<AxiosResponse<PaginatedResponse<TransactionDto>, any>> => {
  return axios.get(`${TRANSACTION_BASE_URL}/list`, { params: { accountId } });
};

export const listTransactionsForProgram = (
  programId: string
): Promise<AxiosResponse<PaginatedResponse<TransactionDto>, any>> => {
  return axios.get(`${TRANSACTION_BASE_URL}/list`, { params: { programId } });
};
export const listOfTicketsForAccount = (accountId: string) => {
  return axios.get(`${TICKETS_BASE_URL}/${accountId}`);
};
export const listOfTicketCountForAccount = (accountId: string) => {
  return axios.get(`${TICKETS_BASE_URL}/ticketStatusCount/${accountId}`);
};

export const listTransactions = (
  data: ListTransactionsFilterRequestDto
): Promise<AxiosResponse<PaginatedResponse<TransactionDto>, any>> => {
  return axios.get(`${TRANSACTION_BASE_URL}/list`, { params: data });
};
