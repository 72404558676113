import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { getIsTrue } from '../../../../../constants/commonFunction';
import { BORDER_GRAY } from '../../../../../constants/style';
import {
  IMarkupFeeComponent,
  INewPLanTypeProps,
  IPLanTypeProps,
} from '../../../../types/plan';
import { ICommonPlanProps, IPaginationProps } from '../../../pages/MCCPolicies';
import PlanPoliciesCard from '../PlanPoliciesCard';
import ListOfMarkupFees from './ListOfMarkupFees';
import MarkupFeeCard from './components/MarkupFeeCard';
import { NoResults } from '../../../../../components/common';
import { useClientAuth } from '../../../../../providers/ClientProvider';

interface IFeeRuleProps {
  [code: string]: IMarkupFeeComponent | null;
}

const DEFULT_FEES = {
  valueType: 'PERCENTAGE',
  value: 5,
};
const DEFULT_WAIVER = {
  valueType: 'PERCENTAGE',
  value: 50,
  dailyLimit: 10,
  monthlyLimit: 100,
};

const DEFULT_FEE_WAIVER = {
  transactionCode: '',
  fee: DEFULT_FEES,
  waiver: DEFULT_WAIVER,
};

const FEE_RULE = {
  CASH_AT_POS: null,
  ECOM: null,
  WALLET_TO_WALLET: null,
  POS: null,
  ATM: null,
  CONTACTLESS: null,
} as IFeeRuleProps;

const DEFUALT_PLAN = {
  clientId: '0b9fafc9-0f42-4fb9-89d4-10dccd80c643',
  planName: '',
  planType: 'MARKUP_FEE_PLAN',
  planDescription: 'planDescription',
  isDefault: true,
  effectiveStartTime: '2023-04-04T07:13:19.418147Z',
  effectiveEndTime: '2023-04-04T07:13:19.418147Z',
  status: 'ENABLE',
} as IPLanTypeProps;

const DEFUALT_PLAN2 = {
  policy_type: 'MARKUP_FEE_PLAN',
  policy_name: '',
  policy_description: 'planDescription',
  is_default: true,
  start_date: '2023-04-04T07:13:19.418147Z',
  end_date: '2023-04-04T07:13:19.418147Z',
  status: 'ENABLED',
} as INewPLanTypeProps;

export interface IPlanListProps extends ICommonPlanProps, IPaginationProps {
  plans: any[];
}

const MarkupFee = ({
  onCreate,
  plans,
  page,
  count,
  onPageChange,
}: IPlanListProps) => {
  const { clientId } = useClientAuth();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [planData, setPlanData] = useState<INewPLanTypeProps>({
    ...DEFUALT_PLAN2,
  });
  const [feesWaiverRule, setFeesWaiverRule] = useState<IFeeRuleProps>({
    ...FEE_RULE,
  });

  const handleChange = (name: string, value: any) => {
    setPlanData({ ...planData, [name]: value });
    if (value.length) {
      setErrorMsg('');
    }
  };

  const activationToggle = (code: string) => {
    const current = feesWaiverRule[code];
    const rule = { ...DEFULT_FEE_WAIVER, transactionCode: code };
    if (current === null) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setFeesWaiverRule({
      ...feesWaiverRule,
      [code]: !!current ? null : rule,
    });
  };
  const handleChangeValue = (code: string, key: string, value: any) => {
    const edited = {
      ...feesWaiverRule[code],
      [key]: value,
    } as IMarkupFeeComponent;
    setFeesWaiverRule({ ...feesWaiverRule, [code]: { ...edited } });
  };
  const onCreateMarkup = async () => {
    const copy = { ...feesWaiverRule };

    const markup_fee_component_list: IMarkupFeeComponent[] = [];

    Object.keys(copy).map((code) => {
      if (!!copy[code]) {
        markup_fee_component_list.push(copy[code] as IMarkupFeeComponent);
      }
    });
    if (planData.policy_name.length) {
      await onCreate({ ...planData, markup_fee_component_list, clientId });
      setPlanData({ ...DEFUALT_PLAN2 });
      setFeesWaiverRule({ ...FEE_RULE });
    } else {
      setErrorMsg('Please enter plan name');
    }
  };

  useEffect(() => {
    const copy = { ...feesWaiverRule };
    const newArr = Object.keys(copy).map((code) => {
      return copy[code];
    });
    const isNull = getIsTrue(null, newArr);
    if (isNull) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [feesWaiverRule]);

  return (
    <>
      <PlanPoliciesCard
        plan={planData}
        title="MARKUP_FEE_PLAN"
        handleChange={handleChange}
        disabled={disabled}
        onCreate={onCreateMarkup}
        errorMsg={errorMsg}
      >
        <Box sx={{ borderTop: BORDER_GRAY }}>
          {Object.keys(feesWaiverRule).map((code) => {
            const rule = feesWaiverRule[code];
            return (
              <MarkupFeeCard
                key={`charge-card-${code}`}
                rule={rule}
                code={code}
                onActivationClick={activationToggle}
                handleChange={handleChangeValue}
              />
            );
          })}
        </Box>
      </PlanPoliciesCard>
      {plans.length ? (
        <ListOfMarkupFees
          data={plans}
          page={page}
          count={count}
          onPageChange={onPageChange}
        />
      ) : (
        <NoResults text="You have no Markup fee plan" />
      )}
    </>
  );
};

export default MarkupFee;
