import { useQuery } from 'react-query';
import { getAllReportRunsForProgram } from '../../../api/reports';
import { IReportRun } from '../../../types/reports';

const getReportRunsForProgram = async (programId: string | undefined) => {
  if (!programId) return null;
  const { data } = await getAllReportRunsForProgram(programId);

  console.log('report runs ', data);
  const hash = await (data as IReportRun[]).reduce((pre, curr) => {
    return {
      ...pre,
      [curr.reportConfigId]: curr,
    };
  }, {} as Record<string, IReportRun>);
  return hash;
};

export default function useReportRunsForProgram(programId: string | undefined) {
  return useQuery(['reportRuns', programId], () =>
    getReportRunsForProgram(programId)
  );
}
