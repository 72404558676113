export enum TransactionIndicator {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum TransactionCode {
  CASH_AT_POS = 'CASH_AT_POS',
  ECOM = 'ECOM',
  WALLET_TO_WALLET_WATERFALL = 'WALLET_TO_WALLET_WATERFALL',
  POS = 'POS',
  ATM = 'ATM',
  CONTACTLESS = 'CONTACTLESS',
  WALLET_TO_WALLET = 'WALLET_TO_WALLET',
  RELOAD_CARD = 'RELOAD_CARD',
  MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
  CARD_REFUND = 'CARD_REFUND',
  FEE = 'FEE',
  MARKUP_FEE = 'MARKUP_FEE',
  CORPORATE_FUND_LOAD = 'CORPORATE_FUND_LOAD',
}
