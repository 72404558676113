import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useSupport } from '../../../../providers/SupportProvider';
import {
  ApplicationServices,
  CustomerApplication,
  GetAllApplicationsForHQApiData,
} from '../../../../services/ApplicationServices';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import Vega_Datagrid from '../../../common/v3/Vega_Datagrid';
import ApplicationDetailsContainer from './components/ApplicationDetailsContainer';
import Vega_Text from '../../../common/v3/Vega_Text';
import { toLowerCase } from '../../../../constants/commonFunction';
import { GREEN, YELLOW } from '../../../../constants/style';
import { useApplications } from '../../../../providers/ApplicationsProvider';
import { dateFormat } from '../../../../mcc/variable';

const SupportApplicationsDetails = () => {
  const { applications, applicationLoading } = useApplications();

  const COLUMN_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Product',
      flex: 0.7,
      minWidth: 80,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: (props) => {
        const programName = props.row.programName ?? '--';
        return <Vega_Text text={'Credit card'} />;
      },
    },
    {
      field: 'id',
      headerName: 'Application ID',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'startDateTime',
      headerName: 'Creation Date',
      flex: 0.6,
      minWidth: 80,
      renderCell: (props) => {
        const createdAt = props.row.createdAt
          ? dateFormat(props.row.createdAt)
          : '--';
        return <Vega_Text text={createdAt} />;
      },
    },
    {
      field: 'endDateTime',
      headerName: 'Program Name',
      flex: 1,
      minWidth: 150,
      renderCell: (props) => {
        const programName = props.row.programName ?? '--';
        return <Vega_Text text={'SBM Premium Credit Card'} />;
      },
    },

    {
      field: 'typeDetails',
      headerName: 'Status',
      flex: 0.6,
      minWidth: 80,
      renderCell: (props) => {
        const status = props.row.status ?? '--';
        return (
          <Vega_Text
            text={toLowerCase(status)}
            fontColor={status === 'PENDING' ? YELLOW.main : GREEN.dark}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Approval Date',
      flex: 0.6,
      minWidth: 80,
      renderCell: (props) => {
        const updatedAt = props.row.updatedAt
          ? dateFormat(props.row.updatedAt)
          : '--';
        return <Vega_Text text={updatedAt} />;
      },
    },
  ];

  return (
    <>
      <ApplicationDetailsContainer header="Application">
        <Vega_Datagrid
          data={applications}
          idColumn="id"
          columns={COLUMN_DEF}
          loading={applicationLoading}
        />
      </ApplicationDetailsContainer>
    </>
  );
};

export default SupportApplicationsDetails;
