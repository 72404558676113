import React from 'react';
import { IProgramProps } from './ListOfMCCProgram';
import { Box, Grid } from '@mui/material';
import VegaSelect from '../../../../components/common/VegaSelect';
import { VegaTextField } from '../../../../components/common';
import VegaText from '../../../../components/common/VegaText';
import { BLUE, BORDER_GRAY } from '../../../../constants/style';
import { NETWORKS } from '../../../../components/ClientHome/AddProgram';
import { Download } from '@mui/icons-material';
import { FIleIcon } from '../../../../components/icon/Icons';
import {
  FileValidator,
  FileValidatorResult,
} from '../../../../utils/FileValidator';

interface IProps {
  state: IProgramProps;
  handleChange: (name: string, value: any) => void;
  handleFileChange: (name: string, file: File | null) => void;
  onFileChangeError: (type: string, message: string | null) => void;
  fileValidator?: FileValidator;
}
const ProgramDetails = ({
  state,
  handleChange,
  handleFileChange,
  onFileChangeError,
  fileValidator,
}: IProps) => {
  async function toDataURL(url: string) {
    const blob = await fetch(url, { mode: 'no-cors' }).then((res) =>
      res.blob()
    );
    return URL.createObjectURL(blob);
  }
  const download = async (url: any) => {
    if (url) {
      const a = document.createElement('a');
      const urlBlob = await toDataURL(url);

      a.href = urlBlob;
      a.setAttribute('download', 'file.jpg');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleFileSelect = async (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (!fileValidator) {
      handleFileChange(type, selectedFile);
      return;
    }

    if (selectedFile) {
      const validationResult: FileValidatorResult =
        await fileValidator.validateFile(selectedFile);

      if (validationResult.isValid) {
        handleFileChange(type, selectedFile);
      } else {
        onFileChangeError?.(type, validationResult.errorMessage);
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} sm={12} mb={1}>
          <VegaTextField
            value={state?.programId}
            onChange={(e) => handleChange('programId', e.target.value)}
            key="programId"
            type={'text'}
            placeholder="Program ID"
            label="Program ID"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={1}>
          <VegaTextField
            value={state?.name}
            onChange={(e) => handleChange('name', e.target.value)}
            key="name"
            type={'text'}
            placeholder="Program Name"
            label="Program Name"
            disabled={state?.status !== 'DRAFT'}
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={1}>
          <VegaSelect
            label="Select Network"
            size="small"
            key={'network'}
            selected={state.network ?? ''}
            onChange={(value) => handleChange('network', value)}
            options={NETWORKS}
            disabled={state?.status === 'PROD'}
          />
        </Grid>
        <Grid item xs={12} sm={12} mb={1}>
          <VegaSelect
            label="Program Type"
            options={['CORPORATE', 'RETAIL']}
            key="programCustomerType"
            selected={state?.programCustomerType}
            placeholder="Program Type"
            onChange={(value) => handleChange('programCustomerType', value)}
            disabled={state?.status === 'PROD'}
          />
        </Grid>
        <Grid item container xs={12} sm={6} mb={1}>
          {/* <Box display={'flex'} width={'100%'} alignItems={'end'}>
            <Box sx={{ flexGrow: 1 }}>
              <VegaFileUpload
                placeholder={'Upload file'}
                file={state.tnc}
                onUpload={(file: any) => handleChange('tnc', file)}
              />
            </Box>
            <VegaContainedButton text="Upload" isPrimary />
            
          </Box> */}
          <Box>
            <Box sx={{ border: BORDER_GRAY, borderRadius: '5px', p: 2 }}>
              <Box sx={{ mb: 0.5 }}>
                <VegaText text="TNC" />
              </Box>
              <Box className="snip1205">
                <img src={state.tnc ?? ''} alt="" width={200} height={100} />
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() => download(state.tnc)}
                >
                  <Download />
                </i>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 0.5,
                }}
              >
                {/* <VegaOutlineButton
                  py={0.5}
                  text={'View'}
                  startIcon={<Visibility />}
                /> */}
                {/* <VegaOutlineButton
                  py={0.5}
                  text={'Download'}
                  startIcon={<Download />}
                  onClick={() => download(state.tnc)}
                /> */}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  p: 1,
                  my: 1,
                  border: BORDER_GRAY,
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FIleIcon />
                    <Box mx={1}>
                      <VegaText text="Visa.pdf" />
                    </Box>
                  </Box>
                  <Box>
                    <input
                      type="file"
                      id="pan"
                      hidden
                      onChange={(file: any) => handleFileSelect('tnc', file)}
                    />
                    <label
                      style={{
                        color: 'white',
                        backgroundColor: BLUE.lighter,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: 16,
                      }}
                      htmlFor="pan"
                    >
                      Upload
                    </label>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item container xs={12} sm={6} mb={1}>
          {/* <Box display={'flex'} width={'100%'} alignItems={'end'}>
            <Box sx={{ flexGrow: 1 }}>
              <VegaFileUpload
                placeholder={'Upload file'}
                file={state.agreement}
                onUpload={(file: any) => handleChange('agreement', file)}
              />
            </Box>
            <VegaContainedButton text="Upload" isPrimary />
          </Box> */}
          <Box>
            <Box sx={{ border: BORDER_GRAY, borderRadius: '5px', p: 2 }}>
              <Box sx={{ mb: 0.5 }}>
                <VegaText text="Agreement" />
              </Box>
              <Box className="snip1205">
                <img
                  src={state.agreement ?? ''}
                  alt=""
                  width={200}
                  height={100}
                />
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() => download(state.agreement)}
                >
                  <Download />
                </i>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 0.5,
                }}
              >
                {/* <VegaOutlineButton
                  py={0.5}
                  text={'View'}
                  startIcon={<Visibility />}
                /> */}
                {/* <VegaOutlineButton
                  py={0.5}
                  text={'Download'}
                  startIcon={<Download />}
                  onClick={() => download(state.agreement)}
                /> */}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  p: 1,
                  my: 1,
                  border: BORDER_GRAY,
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FIleIcon />
                    <Box mx={1}>
                      <VegaText text="Visa.pdf" />
                    </Box>
                  </Box>
                  <Box>
                    <input
                      type="file"
                      id="agreement"
                      hidden
                      onChange={(file: any) =>
                        handleFileSelect('agreement', file)
                      }
                    />
                    <label
                      style={{
                        color: 'white',
                        backgroundColor: BLUE.lighter,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: 16,
                      }}
                      htmlFor="agreement"
                    >
                      Upload
                    </label>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        py={3}
        sx={{ borderTop: BORDER_GRAY, borderBottom: BORDER_GRAY }}
      >
        <Box display={'flex'} width={'100%'} alignItems={'center'}>
          <VegaText text="Program Status" />
          <Box sx={{ flexGrow: 1, ml: 2 }}>
            <VegaSelect
              options={['PRE_PROD', 'PROD', 'DRAFT']}
              key="status"
              selected={state?.status}
              placeholder="Program Type"
              onChange={(value) => handleChange('status', value)}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default ProgramDetails;
