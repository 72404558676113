import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DataTile from '../DataTile';

type Props = {};

function ApprovalProcess(props: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography color="#1B1D22" fontSize={17} className="font-aspekta-700">
        Approval Process
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <DataTile title="Approval Process" value="Manual" />
      </Box>
    </Box>
  );
}

export default ApprovalProcess;
