import { Box, Stack, Typography } from '@mui/material';
import { GenericModal } from './GenericModal';

interface IProps {
  onSubmit?: any;
  title?: string;
  triggerLabel?: string;
  content?: string;
}

export function ChangeStatusModal({
  onSubmit,
  title = 'Change Status',
  triggerLabel = 'Acknowledge Loss',
  content = 'Do you want to acknowledge the loss?',
}: IProps) {
  return (
    <GenericModal
      triggerLabel={triggerLabel}
      onSubmit={onSubmit}
      header={title}
    >
      <Box sx={{ px: 3, pt: 2, pb: 6 }}>
        <Stack spacing={2}>
          <Typography>{content}</Typography>
        </Stack>
      </Box>
    </GenericModal>
  );
}
