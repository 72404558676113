import React from 'react';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Typography,
  Pagination,
} from '@mui/material';
import { IColumnProps } from '../../../../../types/applicationManagement';
import {
  BLUE,
  BORDER_GRAY,
  GREEN,
  ORANGE,
  PURPLE,
  YELLOW,
} from '../../../../../../constants/style';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import { getValue } from '../../MarkupFee/ListOfMarkupFees';
import { VegaCard } from '../../../../../../components/common';
import { IPaginationProps } from '../../../../pages/MCCPolicies';
import VegaLabel from '../../../../../../components/common/VegaLabel';

interface IProps extends IPaginationProps {
  data: any;
}

const ListOfCharges = ({ data, page, count, onPageChange }: IProps) => {
  const getValues = (key: string, value: number) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <VegaLabel text={`${key} : ${value}`} color="primary" />
      </Box>
    );
  };
  return (
    <>
      <VegaCard fullHeight noPad>
        <Grid container mt={6}>
          <Grid
            item
            sm={2}
            sx={{
              p: 2,
              borderRight: BORDER_GRAY,
              bgcolor: YELLOW.lightest,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography className="font-aspekta-500" variant="body2">
              {' '}
              Plan Name
            </Typography>
          </Grid>
          <Grid
            item
            sm={2}
            sx={{
              // flex: 0.3,
              // minWidth: '130px',
              // width: '180px',
              p: 2,
              borderRight: BORDER_GRAY,
              bgcolor: ORANGE.lightest,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography className="font-aspekta-500" variant="body2">
              Charges Type
            </Typography>
          </Grid>
          <Grid item sm={8} sx={{ p: 2, bgcolor: GREEN.lightest }}>
            <Grid container>
              <Grid item sm={4} p={1} textAlign={'center'}>
                <Typography className="font-aspekta-500" variant="body2">
                  Value Type
                </Typography>
              </Grid>
              <Grid item sm={4} p={1} textAlign={'center'}>
                <Typography className="font-aspekta-500" variant="body2">
                  {' '}
                  Value
                </Typography>
              </Grid>
              <Grid item sm={4} p={1} textAlign={'center'}>
                <Typography className="font-aspekta-500" variant="body2">
                  {' '}
                  Tax Type
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          {data.map((value: any, index: number) => (
            <>
              <Grid
                item
                sm={2}
                sx={{
                  py: 1,
                  borderRight: BORDER_GRAY,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // ...(index !== data.length - 1 && {
                  // }),
                  borderBottom: BORDER_GRAY,
                }}
              >
                <Typography className="font-aspekta-500" variant="body2">
                  {value?.policy_name}
                </Typography>
              </Grid>
              <Grid item container sm={10}>
                {/* loop here */}
                {value?.charges?.map((val: any, i: number) => {
                  return (
                    <>
                      <Grid
                        item
                        sm={2.4}
                        textAlign={'center'}
                        sx={{
                          py: 1,
                          borderRight: BORDER_GRAY,
                          //
                          borderBottom: BORDER_GRAY,
                        }}
                      >
                        {' '}
                        <Typography
                          className="font-aspekta-500"
                          variant="body2"
                        >
                          {val?.chargeType && toLowerCase(val?.chargeType)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        sm={9.6}
                        sx={{
                          py: 1,

                          borderBottom: BORDER_GRAY,
                        }}
                      >
                        <Grid
                          item
                          sm={4}
                          display={'flex'}
                          justifyContent={'center'}
                        >
                          {toLowerCase(val?.chargeValue?.valueType)}
                        </Grid>
                        <Grid
                          item
                          sm={4}
                          display={'flex'}
                          flexWrap={'wrap'}
                          // flexDirection={'column'}
                          alignItems={'center'}
                        >
                          {val.chargeValue.valueType === 'PERCENTAGE' &&
                            getValue(
                              val.chargeValue.valueType === 'AMOUNT',
                              val?.chargeValue?.value ?? '0',
                              val.chargeValue.valueType === 'PERCENTAGE'
                            )}
                          {val.chargeValue.valueType === 'AMOUNT' &&
                            Object.keys(val?.chargeValue?.value).map(
                              (type: any) =>
                                getValues(
                                  type,
                                  val?.chargeValue?.value[type] ?? 0
                                )
                            )}
                        </Grid>
                        <Grid
                          item
                          sm={4}
                          display={'flex'}
                          justifyContent={'center'}
                        >
                          {val.texType ?? 'Incluesive Of Taxes'}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}

                {/* loop here */}
              </Grid>
            </>
          ))}
        </Grid>
        <Grid container>
          <Box
            sx={{ p: 2, width: '100%', display: 'flex', justifyContent: 'end' }}
          >
            <Pagination
              color="primary"
              page={page}
              count={count}
              onChange={onPageChange}
            />
          </Box>
        </Grid>
      </VegaCard>
    </>
  );
};

export default ListOfCharges;
