import { Stack } from '@mui/material';
import VegaCardV2 from '../../../../../../../common/v2/VegaCardV2';
import VegaConditionalWrapper from '../../../../../../../common/VegaConditionalWrapper';
import { useCampaignRule } from '../../../../../../providers/CampaignRuleProvider';
import FinancialTenure from './FinancialTenure';
import FinancialTenureInput from './FinancialTenureInput';

const FinancialTabPanel = () => {
  const { financialData } = useCampaignRule();
  return (
    <VegaCardV2>
      <Stack spacing={2} style={{ marginTop: '8px' }}>
        {financialData.tenures?.map((item, index) => {
          return <FinancialTenure key={index} tenure={item} />;
        })}
        <VegaConditionalWrapper
          condition={(financialData.tenures ?? []).length < 3}
          ifTrue={<FinancialTenureInput />}
        />
      </Stack>
    </VegaCardV2>
  );
};

export default FinancialTabPanel;
