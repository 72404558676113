import React, { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import { Typography, Box, Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import ProgramDetails from './ProgramDetails';
import { URLtoFile, toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { updateProgramForMCC } from '../../api/Program';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getProgramDocsSignedUrl } from '../../api/Program';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../../utils/FileValidator';

export interface IProgramProps {
  programId: string;
  name?: string | any;
  network: any;
  programCustomerType: string;
  agreement: any;
  tnc: any;
  status: string;
}

const INITIAL_DATA = {
  name: '',
  programId: '',
  network: '',
  programCustomerType: '',
  agreement: '',
  tnc: '',
  status: '',
};

interface IProps {
  onPageChange?: (page: number) => void;
  updateProgram: (data: any) => void;
  onPageSizeChange?: (pageSize: number) => void;
  page: number;
  pageSize: number;
  rowCount?: any;
  loading?: boolean;
  data: any;
}

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([
    FileExtension.PNG,
    FileExtension.JPEG,
    FileExtension.JPG,
    FileExtension.PDF,
  ])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const ListOfMCCProgram = ({
  onPageChange,
  onPageSizeChange,
  page,
  pageSize,
  data,
  rowCount,
  updateProgram,
  loading = false,
}: IProps) => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [customerDetails, setCustomerDetails] = useState<IProgramProps>({
    ...INITIAL_DATA,
  });
  const [open, setOpen] = useState<boolean>(false);

  const ProgramsListColumDef: GridColDef[] = [
    {
      field: 'status',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Program Status
        </Typography>
      ),
      renderCell: (props: any) => {
        return <Box>{toLowerCase(props.row.status)}</Box>;
      },
      flex: 1,
    },
    {
      field: 'programId',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Program ID
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'name',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Program Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'network',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Network
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const networks = props.row.network;
        if (!networks || networks.length <= 0) {
          return;
        }
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      },
    },

    {
      field: 'programCustomerType',
      renderHeader: (props: any) => (
        <Typography className="font-aspekta-500" variant="body2">
          Program Type
        </Typography>
      ),
      renderCell: (props: any) => {
        return <Box>{toLowerCase(props.row.programCustomerType ?? '--')}</Box>;
      },
      flex: 1,
    },
    {
      field: 'options',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Options
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="Edit"
            isPrimary
            mx="0"
            onClick={() => editProgram(props.row)}
          />
        );
      },
      flex: 1,
    },
  ];

  const editProgram = async (data: any) => {
    const newData = { ...data, programId: data.programId };
    delete newData['id'];
    if (newData.agreement) {
      newData.agreement = (
        await getProgramDocsSignedUrl(newData.agreement)
      ).data;
    }
    if (newData.tnc) {
      newData.tnc = (await getProgramDocsSignedUrl(newData.tnc)).data;
    }
    setCustomerDetails(newData);
    setOpen(!open);
  };

  const viewProgram = () => {
    //
  };

  const handleProgramChange = async (name: string, value: any) => {
    // if (name === 'tnc' || name === 'agreement') {
    //   console.log('name, value', name, value);
    //   let file = value[0];
    //   let fileURL = URL.createObjectURL(file);
    //   file.fileURL = fileURL;

    //   setCustomerDetails({
    //     ...customerDetails,
    //     [name]: fileURL,
    //   });
    // } else {
    //   setCustomerDetails({
    //     ...customerDetails,
    //     [name]: value,
    //   });
    // }

    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };

  function handleFileChange(name: string, file: File | null) {
    var fileUrl = '';
    if (file) {
      fileUrl = URL.createObjectURL(file);
    }
    setCustomerDetails({
      ...customerDetails,
      [name]: fileUrl,
    });
  }

  function onFileChangeError(type: string, message: string | null) {
    setSnackbar(message ?? 'Something went wrong', 'error');
    handleFileChange(type, null);
  }

  const decline = () => {
    setOpen(!open);
  };

  const convertBlob = async (url: any) => {
    if (url.includes('blob')) {
      const convetedURL = await URLtoFile(url);
      return convetedURL;
    } else {
      return false;
    }
  };

  const onUpdate = async () => {
    const formData = new FormData();
    const agreementFile = await convertBlob(customerDetails.agreement);
    const tncFile = await convertBlob(customerDetails.tnc);

    const newObj: any = {
      ...customerDetails,

      agreement: agreementFile,
      tnc: tncFile,
      clientId,
      network: customerDetails.network.name
        ? customerDetails.network.name
        : customerDetails.network,
    };
    if (newObj.agreement === false) {
      delete newObj['agreement'];
    }
    if (newObj.tnc === false) {
      delete newObj['tnc'];
    }
    console.log('newObj', newObj, customerDetails);
    Object.keys(newObj).map((value: any) => {
      formData.append(value, newObj[value]);
    });
    updateProgram(formData);
    setOpen(!open);
  };
  return (
    <>
      <VegaCard
        noPad
        sx={{
          mt: 4,
        }}
      >
        <VegaTable
          columnDef={ProgramsListColumDef}
          data={data}
          idColumn="programId"
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
          loading={loading}
        />
      </VegaCard>
      <VegaDrawer
        open={open}
        header={'Program Details'}
        closeText="Clear"
        isClose
        isSubmit
        submitText="Update"
        onClose={decline}
        onSubmit={onUpdate}
      >
        <ProgramDetails
          state={customerDetails}
          handleChange={handleProgramChange}
          handleFileChange={handleFileChange}
          onFileChangeError={(type, message) => {
            onFileChangeError(type, message);
          }}
          fileValidator={fileValidatorConfig}
        />
      </VegaDrawer>
    </>
  );
};

export default ListOfMCCProgram;
