/** @format */

import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { VegaCard, VegaTextField } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaDatePicker from '../common/VegaDatePicker';

interface IRepamentProps {
  onChange: (e: any, v: any) => void;
  repayment: any;
  isLoading: boolean;
  setRepayment: React.Dispatch<React.SetStateAction<any>>;
  onCreate: () => void;
}

const Repayment = ({ onChange, onCreate, repayment, setRepayment, isLoading }: IRepamentProps) => {
  return (
    <>
      <VegaCard>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Repayment
        </Typography>

        <Grid container spacing={2} columns={24}>
          <Grid item xs={12} sm={5}>
            <VegaTextField
              value={repayment.amount}
              onChange={(e) => onChange('amount', +e.target.value)}
              key="amount-repayment"
              label="Amount"
              type={'number'}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <VegaDatePicker
              value={repayment.time}
              onChange={(e) => onChange('time', dayjs(e).hour(5).minute(30).toISOString())}
              key="repayment-time"
              label="Date"
              placeholder="Date"
            />
          </Grid>
        </Grid>
        <Grid
          mt={2}
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'flex-end' },
          }}
        >
          <VegaContainedButton text="clear" onClick={() => setRepayment({ amount: '', time: '' })} isDanger />
          <VegaContainedButton text="Create" loading={isLoading} onClick={onCreate} isPrimary />
        </Grid>
      </VegaCard>
    </>
  );
};

export default Repayment;
