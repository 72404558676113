/** @format */

import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { createNewCustomer } from '../../api/customer';
import { useClientAuth } from '../../providers/ClientProvider';
import { useProgramData } from '../../providers/ProgramDataProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import {
  CustomerIdentity,
  VegaCreateCustomersType,
} from '../../types/customers';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { VegaCard, VegaTextField } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaSelectItem from '../common/VegaSelectItem';
import { CustomerType } from '../../enums/commons';
import _ from 'lodash';
import VegaSelect, { VegaSelectSelectOptionType } from '../common/VegaSelect';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { VALIDATE_REGEX } from '../../utils/validation.utils';
import Vega_Text from '../common/v3/Vega_Text';

const INITIAL_CUSTOMERS_DATA: VegaCreateCustomersType = {
  programId: '25b11f98-e795-49f5-b0d2-5c04c2479ee6',
  clientId: '',
  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  countryCode: '+91',
  mobileNumber: '',
  emailId: '',
  identity: [
    {
      idType: 'PAN',
      idNumber: '',
      idVerified: 'true',
    },
  ],
  dob: '',
  gender: '',
  currentAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    pincode: '',
    country: 'India',
  },
  isCurrentAddressPermanent: true,
  nationality: 'Indian',
  status: 'ACTIVE',
  kycStatus: 'VERIFIED',
  customerType: CustomerType.INDIVIDUAL,
};

const title = [
  {
    name: 'Mr',
    id: 'mr',
  },
  {
    name: 'Mrs',
    id: 'mrs',
  },
];
const gender = [
  {
    name: 'Male',
    id: 'male',
  },
  {
    name: 'Female',
    id: 'female',
  },
];
type Props = {
  incrementReload: () => void;
};

enum IdInputType {
  IdType,
  IdValue,
}

const validationRules = {
  required: 'This field is required',
};
const CreateCustomers = ({ incrementReload }: Props) => {
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();
  const { clientId } = useClientAuth();
  const [customer, setCustomer] = useState<VegaCreateCustomersType>({
    ...INITIAL_CUSTOMERS_DATA,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [id, setId] = useState('');
  const [nationality, setNationality] = useState<string>('');
  const handleChange = (key: keyof VegaCreateCustomersType, value: string) => {
    if (key === 'dob') {
      setCustomer({ ...customer, [key]: dayjs(value).format('YYYY-MM-DD') });
    } else {
      setCustomer({ ...customer, [key]: value });
    }
  };

  const hookForm = useForm<VegaCreateCustomersType>({});
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = hookForm;

  const handleIdTypeChange = (type: IdInputType, value: string) => {
    const initialIdentity: CustomerIdentity = {
      idType: '',
      idNumber: '',
      idVerified: '',
    };

    setCustomer((prev) => {
      const existingIdentity = prev.identity ?? [initialIdentity];
      if (type == IdInputType.IdType) {
        existingIdentity[0].idType = value;
      } else if (type == IdInputType.IdValue) {
        setId(value);
        existingIdentity[0].idNumber = value;
      }

      return {
        ...prev,
        identity: existingIdentity,
      };
    });
  };

  const isInputValid = (newCustomer: VegaCreateCustomersType) => {
    let isFirstNameValid = newCustomer.firstName.length > 0;
    let isLastNameValid = newCustomer.lastName.length > 0;
    let isMobileNumberValid = newCustomer.mobileNumber.length > 0;
    let isEmailValid = newCustomer.emailId.length > 0;
    return (
      isFirstNameValid && isLastNameValid && isMobileNumberValid && isEmailValid
    );
  };

  const addCustomer = (newCustomer: VegaCreateCustomersType) => {
    console.log('newCustomer', newCustomer);
    let isValid = isInputValid(newCustomer);

    if (isValid == false) {
      setSnackbar('Please enter all required inputs', 'error');
      return;
    }
    setIsLoading(true);
    createNewCustomer({
      ...newCustomer,
      programId: selectedProgram?.programId as string,
    })
      .then((res) => {
        setIsLoading(false);
        incrementReload();
        setSnackbar('Customer Created!');
      })
      .catch((err) => {
        console.error('failed to create customer');
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        setIsLoading(false);
      });
  };

  const handleSubmitClick = async (validCustomer: VegaCreateCustomersType) => {
    const data = {
      clientId: clientId,
      title: customer.title,
      firstName: validCustomer.firstName.trim(),
      middleName: validCustomer.middleName.trim(),
      lastName: validCustomer.lastName.trim(),
      countryCode: '+91',
      mobileNumber: validCustomer.mobileNumber.trim(),
      emailId: validCustomer.emailId.trim(),
      identity: [
        {
          idType: 'PAN',
          idNumber: `${id}`,
          idVerified: true,
        },
      ],
      dob: customer.dob,
      gender: customer.gender,
      currentAddress: {
        line1: 'yogi chowk',
        line2: 'Athwalines',
        city: 'Surat',
        state: 'Gujarat',
        pincode: '395010',
        country: 'India',
      },
      isCurrentAddressPermanent: true,
      nationality: 'Indian',
      status: 'ACTIVE',
      kycStatus: 'VERIFIED',
      customerType: CustomerType.INDIVIDUAL,
    };
    addCustomer(data as any);
  };
  console.log('customer.gender :>> ', customer);
  return (
    <VegaCard>
      <Box>
        <Vega_Text
          text={'Personal Details'}
          fontSize={16}
          fontWeight={500}
          pb={1}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <VegaSelect
              onChange={(selected) => handleChange('title', selected)}
              key="title"
              label="Title"
              placeholder="Title"
              selected={customer.title}
              options={title}
            />
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10} xl={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: validationRules.required,
                    pattern: {
                      value: VALIDATE_REGEX.NAME,
                      message: 'Only letters (alphabets) are allowed!',
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <VegaTextField
                        {...field}
                        label="Enter First Name"
                        type="text"
                        placeholder="Enter First Name"
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                      />
                    );
                  }}
                />
                {/* <VegaTextField
                value={customer.firstName}
                onChange={(e) => handleChange('firstName', e.target.value)}
                key="firstName"
                label="First Name"
                type={'text'}
                placeholder="First Name"
              /> */}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="middleName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: validationRules.required,
                    pattern: {
                      value: VALIDATE_REGEX.NAME,
                      message: 'Only letters (alphabets) are allowed!',
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <VegaTextField
                        {...field}
                        label="Enter Middle Name"
                        type="text"
                        placeholder="Enter Middle Name"
                        error={!!errors.middleName}
                        helperText={errors.middleName?.message}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: validationRules.required,
                    pattern: {
                      value: VALIDATE_REGEX.NAME,
                      message: 'Only letters (alphabets) are allowed!',
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <VegaTextField
                        {...field}
                        label="Enter Last Name"
                        type="text"
                        placeholder="Enter Last Name"
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: '-9px' }}>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <VegaSelect
              onChange={(selected) => handleChange('gender', selected)}
              key="gender"
              label="Gender"
              placeholder="Gender"
              selected={customer.gender}
              options={gender}
            />
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10} xl={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="mobileNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: validationRules.required,
                    maxLength: {
                      value: 10,
                      message: 'Mobile number should be 10 digits',
                    },
                    minLength: {
                      value: 10,
                      message: 'Mobile number should be 10 digits',
                    },
                  }}
                  render={({ field }) => (
                    <VegaTextField
                      {...field}
                      label="Mobile Number"
                      type="number"
                      placeholder="Mobile Number"
                      error={!!errors.mobileNumber}
                      helperText={errors.mobileNumber?.message}
                    />
                  )}
                />
                {/* <VegaTextField
                  value={customer.mobileNumber}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (value.length <= 10) {
                      handleChange('mobileNumber', value);
                    }
                  }}
                  key="mobileNumber"
                  label="Phone Number"
                  type={'number'}
                  placeholder="Phone Number"
                /> */}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="emailId"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: validationRules.required,
                    pattern: {
                      value: VALIDATE_REGEX.EMAIL,
                      message: 'Invalid email address',
                    },
                  }}
                  render={({ field }) => (
                    <VegaTextField
                      {...field}
                      label="Email"
                      type="text"
                      placeholder="Email"
                      error={!!errors.emailId}
                      helperText={errors.emailId?.message}
                    />
                  )}
                />
                {/* <VegaTextField
                  value={customer.emailId}
                  onChange={(e) => handleChange('emailId', e.target.value)}
                  key="emailId"
                  label="Email"
                  type={'email'}
                  placeholder="Email"
                /> */}
              </Grid>

              <Grid item xs={12} sm={4}>
                <VegaDatePicker
                  value={customer.dob}
                  onChange={(e) => handleChange('dob', e)}
                  key="dob"
                  label="Date of Birth"
                  placeholder="Date of Birth"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 2 }}>
          ID Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              focused
              key={'idType'}
              label="ID"
              placeholder="ID"
              options={idOptions()}
              selected={customer.identity[0].idType ?? ''}
              onChange={(e) => handleIdTypeChange(IdInputType.IdType, e)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={customer.identity[0].idNumber}
              onChange={(e) =>
                handleIdTypeChange(IdInputType.IdValue, e.target.value)
              }
              key="idNumber"
              label="ID Number"
              type={'text'}
              placeholder="ID Number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              focused
              key={'nationality'}
              label="Nationality"
              placeholder="Nationality"
              options={nationalityOptions()}
              selected={nationality}
              onChange={(e) => setNationality(e)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-end' },
            }}
          >
            <VegaContainedButton
              text="clear"
              isDanger
              onClick={() => {
                setCustomer({ ...INITIAL_CUSTOMERS_DATA });
                setId('');
              }}
            />
            <VegaContainedButton
              text="Create"
              isPrimary
              loading={isLoading}
              onClick={hookForm.handleSubmit(handleSubmitClick)}
            />
          </Grid>
        </Grid>
      </Box>
    </VegaCard>
  );
};

export default CreateCustomers;

enum GovernmentIdType {
  PAN = 'PAN',
  AADHAR = 'AADHAR',
  DRIVING_LICENCES = 'DRIVING_LICENCES',
}
const idOptions = () =>
  Object.values(GovernmentIdType).map((item) => {
    var displayText = '';
    if (item == GovernmentIdType.PAN) {
      displayText = 'PAN card number';
    } else {
      displayText = _.startCase(_.toLower(item));
    }
    const option: VegaSelectSelectOptionType = {
      id: item,
      name: displayText,
    };
    return option;
  });

enum NationalityType {
  INDIAN = 'INDIAN',
  MEXICAN = 'MEXICAN',
  AFRICAN = 'AFRICAN',
}

const nationalityOptions = () =>
  Object.values(NationalityType).map((item) => {
    const option: VegaSelectSelectOptionType = {
      id: item,
      name: _.startCase(_.toLower(item)),
    };
    return option;
  });
