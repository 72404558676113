import { SVGProps, useState } from 'react';
import { Box, Grid, Typography, alpha } from '@mui/material';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { CIMS_ADMIN_SUB_MODULES } from '../../../routes';
import { CIMS_BORDER, CIMS_PRIMARY } from '../../assets/style';
import {
  AssignCardIcon,
  CardInventoryIcon,
  MastersIcon,
  ReportIcon,
  TeamManagementIcon,
} from '../../components/icon/CIMS_Icons';
import { NEUTRAL_COLORS } from '../../theme/theme';

interface IQuickAccessProps {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  name: string;
  path?: string;
  desciption?: string;
}
const quickAccess: IQuickAccessProps[] = [
  {
    icon: MastersIcon,
    name: 'Masters',
    desciption: '',
    path: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_CARD_MASTER,
  },
  {
    icon: CardInventoryIcon,
    name: 'Card Inventory',
    path: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_CARD_INVENTORY,
    desciption: '',
  },
  {
    icon: AssignCardIcon,
    name: 'Assign Cards',
    path: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_ASSIGN_CARDS_TO_ENTITY,
    desciption: '',
  },
  {
    icon: ReportIcon,
    name: `Reports `,
    path: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_REPORTS,
    desciption: '',
  },
  {
    icon: TeamManagementIcon,
    name: 'Team Management',
    path: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_TEAM_MANAGEMENT,
    desciption: '',
  },
];

const QuickAccess = () => {
  const [_, setSearchParams] = useSearchParams();
  const [hoveredItem, setHoveredItem] = useState(-1);

  return (
    <>
      <Typography className="font-aspekta-500" fontSize={14} color={'black'}>
        Quick Access
      </Typography>
      <Box mt={2} mb={1}>
        <Grid container spacing={2}>
          {quickAccess.map((access: IQuickAccessProps, index) => (
            <Grid item xs={6} sm={6} md={4} lg={2.4} xl={2.4} key={index}>
              <Box
                sx={{
                  p: 2,
                  textAlign: 'center',
                  bgcolor: 'white',
                  borderRadius: '6px',
                  height: '105px',
                  border: CIMS_BORDER.DARKGRAY,
                  cursor: access.path ? 'pointer' : 'not-allowed',
                  transition: 'transform 0.3s ease',
                  ':hover': {
                    transform: access.path && 'scale(1.1)',
                  },
                }}
                onClick={() => {
                  access.path &&
                    setSearchParams(
                      createSearchParams({
                        view: access.path,
                      })
                    );
                }}
                onMouseEnter={() => {
                  setHoveredItem(index);
                }}
                onMouseLeave={() => {
                  setHoveredItem(-1);
                }}
              >
                {
                  <access.icon
                    color={
                      hoveredItem === index
                        ? CIMS_PRIMARY.GRAY
                        : NEUTRAL_COLORS.GOLDEN
                    }
                  />
                }
                <Typography
                  color={
                    hoveredItem === index
                      ? NEUTRAL_COLORS.GOLDEN
                      : CIMS_PRIMARY.GRAY
                  }
                  className="font-aspekta-450"
                  fontSize={13}
                  lineHeight={'15.6px'}
                >
                  {access.name}
                </Typography>
                <Typography
                  color={
                    hoveredItem === index
                      ? NEUTRAL_COLORS.GOLDEN
                      : CIMS_PRIMARY.GRAY
                  }
                  variant="caption"
                  fontSize={11}
                  fontStyle={'italic'}
                >
                  {access.desciption}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default QuickAccess;
