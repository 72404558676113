import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { VegaToolTipAndCopy } from '../../../../../components/common/VegaToolTipNCopy';
import {
  CardAllotmentOrderDto,
  CardAllotmentStatusEnum,
} from '../../../../../types/card';
import CardAllotmentStatus from '../CardAllotmentStatus';
import MarkAsAcknowledged from '../MarkAsAcknowledged';
import ViewOrderDetails from '../ViewOrderDetails';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { defeaultLevelEntityMap } from '../../../../../types/client';
import { Stack } from '@mui/material';
import MarkAsLost from '../MarkAsLost';

interface IProps {
  handleMarkAsAcknowledged: (data: CardAllotmentOrderDto) => void;
  handleMarkAsLost: (data: CardAllotmentOrderDto) => void;
}

export const CARD_ALLOTMENT_LIST_COLUMNS = ({
  handleMarkAsAcknowledged,
  handleMarkAsLost,
}: IProps): GridColDef<CardAllotmentOrderDto>[] => {
  return [
    {
      field: 'id',
      headerName: 'Order ID',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.row.id} />,
    },

    {
      field: 'createdAt',
      headerName: 'Assigned on',
      flex: 1,
      minWidth: 100,
      // type: 'date',
      valueGetter: (props) => dayjs(props.value).format('DD/MM/YYYY'),
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },

    {
      field: 'totalKits',
      headerName: 'Card Assigned ',

      flex: 1,
      minWidth: 120,
      renderCell: (props) => (
        <VegaToolTipAndCopy
          isCopy
          value={(props.row.totalKits || 0)?.toString()}
        />
      ),
    },
    {
      field: 'allocatedBy',
      headerName: 'Allocated by',
      flex: 2,
      minWidth: 200,
      valueGetter: (props) => {
        return props.row.sourceDivisionDto?.divisionType;
      },
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },

    // {
    //   field: 'cardType',
    //   headerName: 'Card Type',
    //   renderHeader: () => (
    //     <Typography variant="button" fontWeight={'bold'}>
    //       Card Type
    //     </Typography>
    //   ),
    //   flex: 1.5,
    //   renderCell: (props) => <VegaToolTipAndCopy value={props.row.cardType} />,
    //   type: 'singleSelect',
    //   valueOptions: ['PREPAID', 'POSTPAID', 'FOREX', 'CREDIT'],
    // },
    {
      field: 'order_details',
      type: 'actions',
      headerName: 'Order Details',

      renderCell: (props) => {
        return <ViewOrderDetails data={props.row} />;
      },
      flex: 1.3,
      minWidth: 150,
    },
    {
      field: 'status',
      type: 'actions',
      headerName: 'Status of request',
      renderCell: (props) => {
        return <CardAllotmentStatus status={props.row.allotmentStatus} />;
      },
      flex: 1.3,
      minWidth: 150,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      renderCell: (props) => {
        return props.row.allotmentStatus ===
          CardAllotmentStatusEnum.DELIVERED ? (
          <Stack direction={'row'} spacing={2}>
            <MarkAsLost data={props.row} onSubmit={handleMarkAsLost} />
            <MarkAsAcknowledged
              data={props.row}
              onSubmit={handleMarkAsAcknowledged}
            />
          </Stack>
        ) : (
          '-'
        );
        // return (
        //   <MarkAsDelivered data={props.row} onSubmit={handleMarkAsDelivered} />
        // );
      },
      flex: 2.5,
      minWidth: 250,
    },
  ];
};
