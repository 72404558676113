import styled from '@emotion/styled';
import { Remove, Add } from '@mui/icons-material';
import { Box, Stack, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaTextV2 from '../../../../../../../../../common/v2/VegaTextV2';

const StyledTab = styled(Box)(() => {
  return {
    padding: '.75rem',
    borderRadius: '0.75rem',
    background: 'rgba(247, 247, 247, 1)',
  };
});

type Props = {
  title: string;
  itemsCount: number;
  disabledAdd?: boolean;
  onAdd: () => void;
  onRemove: () => void;
};
function VoucherTab({
  title,
  itemsCount,
  onAdd,
  onRemove,
  disabledAdd = false,
}: Props) {
  const [count, setCount] = useState<number>(0);

  function onAddClick() {
    setCount((prev) => prev + 1);
    onAdd();
  }

  function onRemoveClick() {
    setCount((prev) => prev - 1);
    onRemove();
  }

  useEffect(() => {
    setCount(itemsCount);
  }, [itemsCount]);

  return (
    <StyledTab>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <VegaTextV2
          text={title}
          style={{
            color: 'rgba(58, 74, 95, 1)',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: '140%',
          }}
        />
        <Stack direction={'row'} alignItems="center" spacing={1}>
          {count > 0 && (
            <IconButton size="small" onClick={onRemoveClick}>
              <Remove />
            </IconButton>
          )}
          {count > 0 && <VegaTextV2 text={`${count ?? '-'}`} />}
          <IconButton size="small" onClick={onAddClick} disabled={disabledAdd}>
            <Add />
          </IconButton>
        </Stack>
      </Stack>
    </StyledTab>
  );
}

export default VoucherTab;
