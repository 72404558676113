import { Grid } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { BinType } from '../../../types/card';
import { VegaProgramNetworkType } from '../../../types/program';
import VegaFormInputFieldV2 from '../../common/v2/VegaFormInputField';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../common/v2/VegaTextFieldV2';
import { getLogoForNetwork } from '../../icon/Icons';

const AddBin = () => {
  const { bin, loading, isAddBinOpen, addBin, isInputValid, onHandleChange } =
    useBinCharter();
  const handleBinChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    if (value.length <= 6) onHandleChange('binValue', value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <VegaFormInputFieldV2 label="Bin Name">
            <VegaTextFieldV2
              value={bin?.binName}
              key="binName"
              type={'text'}
              onChange={(e) => onHandleChange('binName', e.target.value)}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={12} sm={12}>
          <VegaFormInputFieldV2 label="Bin Value">
            <VegaTextFieldV2
              value={bin?.binValue}
              key="binValue"
              type={'number'}
              onChange={(e) => handleBinChange(e)}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={12} sm={12}>
          <VegaFormInputFieldV2 label="bin Type">
            <VegaSelectV2
              options={getBinTypeOptions()}
              key="binType"
              value={bin.binType ?? ''}
              onChange={(e) => {
                onHandleChange('binType', e.target.value as string);
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={12} sm={12}>
          <VegaFormInputFieldV2 label="Network">
            <VegaSelectV2
              options={getNetworkTypeOptions()}
              key="network"
              value={bin.network ?? ''}
              onChange={(e) => {
                onHandleChange('network', e.target.value as string);
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={12} sm={12}>
          <VegaFormInputFieldV2 label="bin usage type">
            <VegaSelectV2
              options={getBinUsageType()}
              key="productType"
              value={bin.productType ?? ''}
              onChange={(e) => {
                onHandleChange('productType', e.target.value as string);
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
      </Grid>
    </>
  );
};

export default AddBin;

const getBinTypeOptions = () =>
  Object.values(BinType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const getBinUsageType = () =>
  ['CREDIT', 'DEBIT'].map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const getNetworkTypeOptions = () =>
  Object.values(VegaProgramNetworkType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
      icon: getLogoForNetwork(item),
    };
    return option;
  });
