import { Box, Button, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VegaTextField } from '../components/common';
import { useSnackbar } from '../providers/SnackbarProvider';
import { ROUTES } from '../routes';

import { ArrowBack } from '@mui/icons-material';
import usePassword from '../hooks/usePassword';
type IProps = {};

function ForgotPassord(props: IProps) {
  const { setSnackbar } = useSnackbar();
  const [isEmailSentSuccess, setIsEmailSentSuccess] = useState(false);
  const { password, setPassword, renderValidations, isValidPassword } =
    usePassword();
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    code: '',
    // newPassword: '',
  });

  const navigation = useNavigate();
  const isDissabled = () => {
    return !state.email || !state.code || !isValidPassword();
  };

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(state.email);
  };
  const resendCode = () => {
    if (state.email) {
      setState((pre) => ({
        ...pre,
        code: '',
        newPassword: '',
      }));
      Auth.forgotPassword(state.email)
        .then((user) => {
          setIsEmailSentSuccess(true);
          setSnackbar(`Code send to ${state.email}`);
        })
        .catch((error) => {
          setSnackbar(`${error.message}`, 'error');
          console.error('Cannot login', error);
        });
      setIsEmailSentSuccess(false);
    } else {
      setSnackbar('Email can not be empty', 'error');
    }
  };
  const changeHandler = (key: keyof typeof state, value: string) => {
    setState((pre) => ({
      ...pre,
      [key]: value,
    }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isDissabled()) {
      Auth.forgotPasswordSubmit(state.email, state.code, password)
        .then((res) => {
          navigate(ROUTES.LOGIN);
        })
        .catch((err) => {
          setSnackbar(err.message, 'error');
          setState((pre) => ({
            ...pre,
            code: '',
            newPassword: '',
          }));
        });
    } else {
      setSnackbar('All fields are mandatory', 'error');
    }
  };

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Card
        sx={{
          minWidth: 345,
        }}
      >
        <CardContent>
          <Stack>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <IconButton
                onClick={() => {
                  navigation(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography
                variant="subtitle2"
                textAlign={'center'}
                sx={{
                  width: '100%',
                }}
              >
                Forgot Password
              </Typography>
            </Stack>

            <Box component={'form'} onSubmit={onSubmit} mt={2} p={2}>
              <Stack rowGap={2}>
                <Box>
                  <Typography>Email</Typography>
                  <VegaTextField
                    type="email"
                    key={'login-email'}
                    value={state.email}
                    onChange={(e) => changeHandler('email', e.target.value)}
                  />
                </Box>
                {isEmailSentSuccess && (
                  <Stack rowGap={1}>
                    <Box>
                      <Typography>Code</Typography>
                      <VegaTextField
                        type="text"
                        key={'login-code'}
                        value={state.code}
                        onChange={(e) => changeHandler('code', e.target.value)}
                      />
                    </Box>
                    <Box>
                      <Typography>New Password</Typography>
                      <VegaTextField
                        type={'password'}
                        key={'login-pass'}
                        value={password}
                        onChange={(e) =>
                          // changeHandler('newPassword', e.target.value)
                          setPassword(e.target.value)
                        }
                      />
                    </Box>
                  </Stack>
                )}
              </Stack>
              <Stack mt={2} flexDirection={'row'} columnGap={2}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={resendCode}
                  disabled={!isValidEmail()}
                  sx={{
                    width: '50%',
                  }}
                >
                  Send Code
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: '#7081D8', width: '50%' }}
                  disabled={isDissabled()}
                >
                  Submit
                </Button>
              </Stack>
            </Box>
            {isEmailSentSuccess && (
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                px={2}
                maxWidth={400}
              >
                {renderValidations()}
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default ForgotPassord;
