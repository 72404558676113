import { ActionMediumTypo, PageHeadingTypo } from '../../common/Typography';
import { Stack } from '@mui/material';
import Vega_OutlineTextField from '../../common/v3/Vega_OutlineTextField';
import { Search } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccountClosureTableList from './AccountClosureTableList';

interface IAccountClosureTableContainerProps {
  navigate2Details: (req_id: string) => void;
}

export const AccountClosureTableContainer = ({
  navigate2Details,
}: IAccountClosureTableContainerProps) => {
  return (
    <Stack gap={4}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <PageHeadingTypo>Account Closure Report</PageHeadingTypo>
        <Stack direction="row" gap={1.5} alignItems="center">
          <Stack
            direction="row"
            alignItems="center"
            sx={{ color: '#676B76' }}
            className="cursor-pointer"
            gap={0.5}
          >
            <FilterListIcon />
            <ActionMediumTypo>Filters</ActionMediumTypo>
          </Stack>

          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <Vega_OutlineTextField
            fullWidth
            fixWidth={240}
            startAdornment={
              <Search sx={{ color: '#999DA8', fontSize: '16px' }} />
            }
            placeholder="Search..."
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                border: '1px solid #E1E4EB',
              },
              ':hover .MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                border: '1px solid #E1E4EB',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                border: '1px solid #E1E4EB',
              },
              '&.MuiInputBase-root': {
                padding: 0,
              },
              '.MuiSvgIcon-root': {
                margin: '0 8px',
              },
            }}
          />
        </Stack>
      </Stack>
      <AccountClosureTableList navigate2details={navigate2Details} />
    </Stack>
  );
};
