/* eslint-disable react/jsx-pascal-case */
import { Box, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import CIMS_Button from '../../components/common/CIMS_Button';
import CIMS_Header from '../../components/common/CIMS_Header';
import ListOfCardRejectionStatus from '../../modules/Card_Master/ListOfCardRejectionStatus';
import { AddRejectionStatusModal } from '../../modules/Card_Master/RejectionStatus';
import {
  CIMS_SUB_MODULES,
  CIMS_RESOURCE_MODULES,
} from '../../../constants/modules';
import { useClientAuth } from '../../../providers/ClientProvider';
import { checkResourceAccess } from '../../../utils/userAccessUtils';

export function CardRejectionStatus() {
  const [refetchList, setRefetchList] = useState();
  const [open, setOpen] = useState(false);

  const { user } = useClientAuth();
  const hasAccessToAddRejectStatus = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.CARD_REJECTION_STATUS.ADD_REJECTION_STATUS,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.CARD_REJECTION_STATUS,
      }),
    [user?.allResourceAccess]
  );
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box>
        <CIMS_Header
          text="Card Rejection Master"
          component={
            hasAccessToAddRejectStatus ? (
              <Stack direction={'row'} justifyContent={'flex-end'}>
                <CIMS_Button
                  text="Add Rejection Status"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </Stack>
            ) : undefined
          }
        />
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box mt={2}>
            <ListOfCardRejectionStatus setRefetchList={setRefetchList} />
          </Box>
        </Box>
      </Box>
      <AddRejectionStatusModal
        open={open}
        handleClose={handleClose}
        refetchList={refetchList}
      />
    </>
  );
}
