import {
  AccountPolicyInterface,
  BillingPolicyInterface,
  CardThresholdPolicy,
  CardThresholdV2Policy,
  ChargePolicyInterface,
  InterestPolicyInterface,
  MadPolicyInterface,
  PolicyStatus,
  PolicyType,
  RepaymentPolicyInterface,
  TaxPolicyInterface,
  TaxSubTypeComponent,
  TransactionCode,
  TransactionPolicy,
  TxnToEmiPolicyInterface,
} from './policy';

export const DRAFT_POLICY_ID = 'DRAFT_POLICY_ID';

export const getDefaultPolicy = (data: {
  type: string;
  policyName: string;
}) => {
  switch (data.type) {
    case PolicyType.BILLING:
      return DEFAULT_BILLING_POLICY(data.policyName);
    case PolicyType.MAD:
      return DEFAULT_MAD_POLICY(data.policyName);
    case PolicyType.ACCOUNT:
      return DEFAULT_ACCOUNT_POLICY(data.policyName);
    case PolicyType.TAX:
      return DEFAULT_TAX_POLICY(data.policyName);
    case PolicyType.CHARGES:
      return DEFAULT_CHARGES_POLICY(data.policyName);
    case PolicyType.INTEREST:
      return DEFAULT_INTEREST_POLICY(data.policyName);
    case PolicyType.REPAYMENT_WATERFALL:
      return DEFAULT_REPAYMENT_POLICY(data.policyName);
    case PolicyType.TRANSACTION_TO_INSTALLMENT:
      return DEFAULT_TxnToEmiPolicy(data.policyName);
    case PolicyType.TRANSACTION:
      return DEFAULT_TRANSACTION_POLICY(data.policyName);
  }
};

export const DEFAULT_BILLING_POLICY = (
  policyName: string
): BillingPolicyInterface => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'BILLING',
    policy_description: 'BILLING policy for Credit card',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    priority: null,
    status: PolicyStatus.DRAFT,
    cycle_end_date: 4,
    billing_cycle_type: 'MONTHLY',
    days_between_end_date_and_bill_date: 1,
    due_date: 20,
    minimum_tad: 10.0,
  };
};

export const DEFAULT_MAD_POLICY = (policyName: string): MadPolicyInterface => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'MAD',
    policy_description: 'MAD policy',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    mad_components: [
      {
        transaction_category: 'BILLED_AMOUNT',
        value_type: 'PERCENTAGE',
        value: 100,
      },
      {
        transaction_category: 'UNBILLED_INTEREST',
        value_type: 'PERCENTAGE',
        value: 100,
      },
      {
        transaction_category: 'UNBILLED_RETAIL',
        value_type: 'PERCENTAGE',
        value: 5,
      },
      {
        transaction_category: 'UNBILLED_CHARGE',
        value_type: 'PERCENTAGE',
        value: 100,
      },
      {
        transaction_category: 'UNBILLED_CASH',
        value_type: 'PERCENTAGE',
        value: 5,
      },
      {
        transaction_category: 'UNBILLED_EMI',
        value_type: 'PERCENTAGE',
        value: 100,
      },
      {
        transaction_category: 'OVERLIMIT',
        value_type: 'PERCENTAGE',
        value: 100,
      },
    ],
    tad_components: [
      {
        transaction_category: 'BILLED_AMOUNT',
        value_type: 'PERCENTAGE',
        value: 100,
      },
      {
        transaction_category: 'UNBILLED_INTEREST',
        value_type: 'PERCENTAGE',
        value: 25,
      },
      {
        transaction_category: 'UNBILLED_RETAIL',
        value_type: 'PERCENTAGE',
        value: 5,
      },
      {
        transaction_category: 'UNBILLED_CHARGE',
        value_type: 'PERCENTAGE',
        value: 25,
      },
      {
        transaction_category: 'UNBILLED_CASH',
        value_type: 'PERCENTAGE',
        value: 20,
      },
      {
        transaction_category: 'UNBILLED_EMI',
        value_type: 'PERCENTAGE',
        value: 20,
      },
      {
        transaction_category: 'OVERLIMIT',
        value_type: 'PERCENTAGE',
        value: 100,
      },
    ],
    minimum_mad: 10,
    minimum_tad: 100,
    round_up: true,
    round_up_to: 10,
    interest_status: null,
    status: PolicyStatus.DRAFT,
  };
};

export const DEFAULT_ACCOUNT_POLICY = (
  policyName: string
): AccountPolicyInterface => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'ACCOUNT',
    policy_description: 'Testing ACCOUNT policy',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    overlimit: {
      value_type: 'PERCENTAGE',
      value: 5,
    },
    grace_period: 5,
    payment_deviation: {
      value_type: 'PERCENTAGE',
      value: 1,
    },
    cash_limit: {
      value_type: 'PERCENTAGE',
      value: 65,
    },
    status: PolicyStatus.DRAFT,
    days_to_mark_account_charge_off: 150,
    days_to_mark_account_write_off: 360,
  };
};

export const DEFAULT_SUB_TYPE_COMPONENTS: TaxSubTypeComponent[] = [
  {
    tax_sub_type: 'CGST',
    value_type: 'PERCENTAGE',
    value: 9.0,
  },
  {
    tax_sub_type: 'IGST',
    value_type: 'PERCENTAGE',
    value: 0.0,
  },
  {
    tax_sub_type: 'SGST',
    value_type: 'PERCENTAGE',
    value: 9.0,
  },
];

export const DEFAULT_TAX_POLICY = (policyName: string): TaxPolicyInterface => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'TAX',
    policy_description: 'Testing TAX policy',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    tax_components: [
      {
        country: 'INDIA',
        taxes: [
          {
            name: 'GST',
            description: 'Goods and Services Tax',
            value_type: 'PERCENTAGE',
            value: 18,
            tax_sub_type_components_list: DEFAULT_SUB_TYPE_COMPONENTS,
          },
        ],
      },
    ],
    status: PolicyStatus.DRAFT,
  };
};

export const DEFAULT_CHARGES_POLICY_CHARGE_RULE = {
  charge_code: '',
  charge_criteria: {
    mcc: null,
    transaction_codes: [],
  },
  charge: {
    charge_type: 'FIX_CHARGE',
    amount_type: 'TRANSACTION_AMOUNT',
    value_type: 'AMOUNT',
    value: 10,
  },
  taxation_type: 'EXCLUSIVE_OF_TAXES',
};

export const DEFAULT_CHARGES_POLICY = (
  policyName: string
): ChargePolicyInterface => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'CHARGES',
    policy_description: 'Testing CHARGE policy',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    status: PolicyStatus.DRAFT,
    charge_rules: [],
    updated_at: '',
  };
};

export const DEFAULT_INTEREST_POLICY = (
  policyName: string
): InterestPolicyInterface => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'INTEREST',
    policy_description: 'INTEREST policy',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    priority: null,
    status: PolicyStatus.DRAFT,
    rounding_method: 'ROUND_UP',
    day_count_method: 'ACTUAL_BY_ACTUAL',
    interest_liquidation: 'PAYMENT_END_DAY_BALANCE_V1',
    default_interest_rate_rule: {
      posting_category_to_interest_rate_value: {
        RETAIL_PRINCIPAL: {
          interest_rate_value_type: 'EQUAL',
          default_rate: 42.0,
        },

        CASH_PRINCIPAL: {
          interest_rate_value_type: 'EQUAL',
          default_rate: 42.0,
        },
        // same as retails
        EMI_PRINCIPAL: {
          interest_rate_value_type: 'EQUAL',
          default_rate: 42.0,
        },
      },
    },
    interest_status_to_interest_rate_rules: {
      REVOLVING: {
        posting_category_to_interest_rate_value: {
          RETAIL_PRINCIPAL: {
            interest_rate_value_type: 'EQUAL',
            default_rate: 42.0,
          },

          CASH_PRINCIPAL: {
            interest_rate_value_type: 'EQUAL',
            default_rate: 42.0,
          },
          // same as retails
          EMI_PRINCIPAL: {
            interest_rate_value_type: 'EQUAL',
            default_rate: 42.0,
          },
        },
      },
      DELINQUENT: {
        posting_category_to_interest_rate_value: {
          RETAIL_PRINCIPAL: {
            interest_rate_value_type: 'EQUAL',
            default_rate: 42.0,
          },

          CASH_PRINCIPAL: {
            interest_rate_value_type: 'EQUAL',
            default_rate: 42.0,
          },
          // same as retails
          EMI_PRINCIPAL: {
            interest_rate_value_type: 'EQUAL',
            default_rate: 42.0,
          },
        },
      },
    },
  };
};

export const DEFAULT_REPAYMENT_POLICY = (
  policyName: string
): RepaymentPolicyInterface => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'REPAYMENT_WATERFALL',
    policy_description: 'REPAYMENT_WATERFALL policy',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    auto_repayment: true,
    status: PolicyStatus.DRAFT,
    auto_debit_number_of_retries: 10,
    partial_payoff_allowed: false,
    partial_pay_off_minimum_number_of_installments: 10,
    repayment_waterfall: [
      {
        amount_type: 'BILLED_OVERLIMIT',
        priority_order: 0,
      },
      {
        amount_type: 'BILLED_CASH_CHARGES',
        priority_order: 1,
      },
      {
        amount_type: 'BILLED_CASH_INTEREST',
        priority_order: 2,
      },
      {
        amount_type: 'BILLED_CASH_PRINCIPAL',
        priority_order: 3,
      },
      {
        amount_type: 'BILLED_NON_CASH_CHARGES',
        priority_order: 4,
      },
      {
        amount_type: 'BILLED_NON_CASH_INTEREST',
        priority_order: 5,
      },
      {
        amount_type: 'BILLED_NON_CASH_PRINCIPAL',
        priority_order: 6,
      },
      {
        amount_type: 'BILLED_EMI_PRINCIPAL',
        priority_order: 7,
      },
      {
        amount_type: 'BILLED_EMI_INTEREST',
        priority_order: 8,
      },
      {
        amount_type: 'BILLED_EMI_CHARGES',
        priority_order: 9,
      },
      {
        amount_type: 'UNBILLED_OVERLIMIT',
        priority_order: 10,
      },
      {
        amount_type: 'UNBILLED_CASH_CHARGES',
        priority_order: 11,
      },
      {
        amount_type: 'UNBILLED_CASH_INTEREST',
        priority_order: 12,
      },
      {
        amount_type: 'UNBILLED_CASH_PRINCIPAL',
        priority_order: 13,
      },
      {
        amount_type: 'UNBILLED_NON_CASH_CHARGES',
        priority_order: 14,
      },
      {
        amount_type: 'UNBILLED_NON_CASH_INTEREST',
        priority_order: 15,
      },
      {
        amount_type: 'UNBILLED_NON_CASH_PRINCIPAL',
        priority_order: 16,
      },
      {
        amount_type: 'UNBILLED_EMI_PRINCIPAL',
        priority_order: 17,
      },
      {
        amount_type: 'UNBILLED_EMI_INTEREST',
        priority_order: 18,
      },
      {
        amount_type: 'UNBILLED_EMI_CHARGES',
        priority_order: 19,
      },
    ],
  };
};

export const DEFAULT_INSTALLMENT_POLICY = {
  processingFeeInPercentage: 0.0,
  maxProcessingFee: 0,
  amortizationType: 'ARREAR',
  interestChargeConfig: [
    {
      duration_in_months: 3,
      interest_rate: 12.0,
      discount: 0.0,
      effective_interest: 0.0,
    },
    {
      duration_in_months: 6,
      interest_rate: 12.0,
      discount: 0.0,
      effective_interest: 0.0,
    },
  ],
  interestType: 'REDUCING_BALANCE',
  mcc: [1001, 1002],
};

export const DEFAULT_TxnToEmiPolicy = (
  policyName: string
): TxnToEmiPolicyInterface => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'TRANSACTION_TO_INSTALLMENT',
    policy_description: 'Testing TRANSACTION TO INSTALLMENT policy',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    minimum_amount: 100,
    transaction_category: 'CARD',
    installment_policy: [{ ...DEFAULT_INSTALLMENT_POLICY }],
    status: PolicyStatus.DRAFT,
  };
};

const E_COM_LIMIT = () => {
  return {
    transaction_code: TransactionCode.E_COM,
    per_transaction_limit: 200000,
    daily_limit: 1000000,
    monthly_limit: 5000000,
    yearly_limit: 20000000,
  };
};

const POS_LIMIT = () => {
  return {
    transaction_code: TransactionCode.POS,
    per_transaction_limit: 200000,
    daily_limit: 500000,
    monthly_limit: 2500000,
    yearly_limit: 10000000,
  };
};

const CASH_LIMIT = () => {
  return {
    transaction_code: TransactionCode.CARD_CASH_WITHDRAW,
    per_transaction_limit: 100000,
    daily_limit: 200000,
    monthly_limit: 1000000,
    yearly_limit: 5000000,
  };
};

const NFC_LIMIT = () => {
  return {
    transaction_code: TransactionCode.CARD_NFC,
    per_transaction_limit: 5000,
    daily_limit: 50000,
    monthly_limit: 500000,
    yearly_limit: 2500000,
  };
};

export const DEFAULT_TRANSACTION_POLICY = (
  policyName: string
): TransactionPolicy => {
  return {
    policy_id: DRAFT_POLICY_ID,
    policy_name: policyName,
    policy_type: 'TRANSACTION',
    policy_description: 'Transction policy for program',
    is_override: false,
    start_date: '2022-10-01',
    end_date: '2026-10-01',
    status: PolicyStatus.ACTIVE,
    transaction_limits: [NFC_LIMIT(), E_COM_LIMIT(), POS_LIMIT(), CASH_LIMIT()],
  };
};

export const DEFAULT_CARD_THRESHOLD_POLICY = (
  policyName: string,
  clientId?: string
): CardThresholdV2Policy => {
  return {
    policy_name: policyName,
    policy_type: 'CARD_THRESHOLD_V2_POLICY',
    policy_description: '',
    is_override: false,
    priority: null,
    status: PolicyStatus.ACTIVE,
    cohort_id: null,
    client_id: clientId,
    // level_card_threshold_map: {
    //   [Level.CLIENT]: {
    //     card_threshold_map: {
    //       '82ba886a-fbca-4145-83a7-be682be4969e': {
    //         no_of_cards: 100,
    //         entity_code: 'string',
    //         entity_name: 'string',
    //       },
    //     },
    //   },
    // },
  } as CardThresholdV2Policy;
};
