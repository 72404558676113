/** @format */

import { AdminPanelSettings, FolderShared } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import { CampaignProvider } from '../components/RewardsLoyalty/providers/CampaignProvider';
import {
  AddOnCardConfigIcon,
  GiftOutlineIcon,
  MediaOutlineIcon,
  SecurityIcon,
  UniversalIcon,
} from '../components/icon/Icons';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import ApplicationManagment from '../pages/ApplicationManagment';
import CardManagement from '../pages/CardManagement';
import EventManual from '../pages/EventManual';
import FraudAndRiskManagement from '../pages/FraudAndRiskManagement';
import PaymentDueDate from '../pages/PaymentDueDate';
import Policy from '../pages/Policy';
import Program from '../pages/Program';
import RewardsLoyalty from '../pages/RewardLoyalty/RewardsLoyalty';
import { MakerCheckerEntityProvider } from '../providers/MakerCheckerEntityProvider';
import { PolicyProvider } from '../providers/PolicyProvider';
import { RewardsNavigationProvider } from '../providers/RewardsNavigationProvider';
import { ROUTES } from '../routes';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: ROUTES.POLICY,
    icon: <AdminPanelSettings fontSize="small" />,
    label: 'CMS Setup',
    component: (
      <MakerCheckerEntityProvider>
        <PolicyProvider>
          <Policy />
        </PolicyProvider>
      </MakerCheckerEntityProvider>
    ),

    isProgramSelector: true,
    isAccountSelector: false,
    isProgramRoutes: true,
  },
  {
    id: ROUTES.APPLICATION_MANAGE,
    icon: <FolderShared fontSize="small" />,
    label: 'Onboarding Journey',
    component: <ApplicationManagment />,
    isProgramSelector: true,
    isAccountSelector: false,
    isProgramRoutes: true,
  },
  {
    id: ROUTES.CARD_MANAGEMENT,
    icon: <AddOnCardConfigIcon fill="white" height="20" width="20" />,
    label: 'Card management',
    component: <CardManagement />,
    isProgramSelector: false,
    isAccountSelector: false,
    isProgramRoutes: true,
  },
  {
    id: ROUTES.PAYMENT_DUE,
    icon: <UniversalIcon />,
    label: 'Program Due Dates',
    component: <PaymentDueDate />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  // {
  //   id: ROUTES.REWARDS,
  //   icon: <MediaOutlineIcon />,
  //   label: 'Rewards',
  //   component: <Rewards />,
  //   // component: <RewardsLoyalty />,
  //   isProgramSelector: false,
  //   isAccountSelector: false,
  //   isProgramRoutes: true,
  //   isWhiteBgColor: true,
  // },
  {
    id: ROUTES.REWARDS_LOYALTY,
    icon: <MediaOutlineIcon />,
    label: 'Rewards & Loyalty',
    component: (
      <RewardsNavigationProvider>
        <CampaignProvider>
          <RewardsLoyalty />
        </CampaignProvider>
      </RewardsNavigationProvider>
    ),
    isProgramSelector: false,
    isAccountSelector: false,
    isProgramRoutes: true,
    isWhiteBgColor: true,
  },
  {
    id: ROUTES.EVENT,
    icon: <SecurityIcon />,
    label: 'Event Manual',
    component: <EventManual />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  // {
  //   id: DASHBOARD_ROUTES.BACK_OFFICE,
  //   icon: <ResourcesIcon />,
  //   label: 'Back Office',
  //   component: <BackOffice />,
  //   isProgramSelector: false,
  //   isAccountSelector: false,
  //   isProgramRoutes: true,
  // },
  {
    id: ROUTES.FRAUD_AND_RISK_MANAGEMENT,
    icon: <GiftOutlineIcon />,
    label: 'Fraud & Risk Management',
    component: <FraudAndRiskManagement />,
    isProgramSelector: false,
    isAccountSelector: false,
    isProgramRoutes: true,
  },
  // {
  //   id: ROUTES.ADD_ON,
  //   icon: <SettingIcon />,
  //   label: 'Add On',
  //   component: <AddOn />,
  //   isProgramSelector: false,
  //   isAccountSelector: false,
  //   isProgramRoutes: true,
  // },
];
const NAV_OPTION = {
  id: ROUTES.PROGRAM,
  icon: <FolderShared fontSize="small" />,
  label: 'Program Summary',
  component: <Program />,
  isProgramSelector: false,
  isAccountSelector: false,
  isProgramRoutes: true,
};

const ProgramContainer = (props: Props) => {
  const [selectedItem, setSelectedItem] =
    useState<SidebarListItemType>(NAV_OPTION);

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        sidebarListItem={NAV_OPTION}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default ProgramContainer;
