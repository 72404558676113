import React, { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import AddTransaction from '../components/Transactions/AddTransaction';
import ListOfSimulatorTransactions from '../components/Transactions/ListOfSimulatorTransactions';
import { fetchCustomerForMCC } from '../api/customer';
import { sorting } from '../../../constants/commonFunction';

import { fetchAccountForCustomer } from '../api/account';
import { createForexCharge, createForexTransaction, createForexWalletTransfer, getForexTransactionForAccount } from '../api/transaction';
import { useClientAuth } from '../../../providers/ClientProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import CustomerService from '../../../services/CustomerService';

export interface ITransactionsInfoProps {
  customerId: string;
  accountId: string;
  accountList: string[];
  txnType: string;
  transactionCode: string;
  chargeType: string;
  sourceCurrency: string;
  destinationCurrency: string
  amount: number;
}
const INITIAL_CUSTOMER_DATA = {
  customerId: '',
  accountId: '',
  accountList: [],
  txnType: 'ECOM',
  transactionCode: '',
  sourceCurrency: '',
  destinationCurrency: '',
  chargeType: '',
  amount: 0,
};

const DEFAULT_BRANCH_ID = '26055f58-74e1-4336-a30c-479935dfb2fa'

const Transactions = () => {
  const {mccPlatformId } = useClientAuth();
  console.log("mccPlatformId: ", mccPlatformId);
  const branchId = mccPlatformId ?? DEFAULT_BRANCH_ID;
  console.log("DEFAULT_BRANCH_ID:  ", DEFAULT_BRANCH_ID);
  console.log("branchId::, ", branchId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [accountList, setAccountList] = useState<any[]>([]);
  const [walletlist, setWalletList] = useState<any[]>([]);
  const [transactionList, setTransactionList] = useState<any[]>([]);
  const [customerData, setCustomerData] = useState<ITransactionsInfoProps>({
    ...INITIAL_CUSTOMER_DATA,
  });
  const { setSnackbar } = useSnackbar();

  const handleChange = (key: string, value: string | File | null | string[]) => {
    setCustomerData({ ...customerData, [key]: value });
    console.log("customerdata: ", customerData);
  };
  const onClear = () => {
    setCustomerData({ ...INITIAL_CUSTOMER_DATA });
  };

  const getAccountListForCustomer = async (customerId: string) => {
    if(!customerId) {
      return [];
    }
    fetchAccountForCustomer({value: customerId})
    .then((res) => {
      const accountSearchData = res.data.records.map((record: { account: any; subWallets: any }) => record.account);
      const walletSearchData = res.data.records.map((record: { account: any; subWallets: any }) => record.subWallets);
      console.log("walletSearchData: ", walletSearchData);
      setAccountList(accountSearchData);
      setWalletList(walletSearchData);
      setListLoading(false);
    })
    .catch((err) => {
      setListLoading(false);
    })
  }

  const getCustomerList = async () => {
    setListLoading(true);
    CustomerService.listCustomers({
      branchId: '26055f58-74e1-4336-a30c-479935dfb2fa',
      // status: ['DRAFT', 'UNDER_REVIEW', 'ACTIVE'],
    })
      .then((res) => {
        // const sorted: ICustomer[] = sorting(res);
        setCustomers(res);

        // setFilterData({ mobile: '' });
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(()=> {
    getCustomerList();
  }, []);

  useEffect(() => {
    getAccountListForCustomer(customerData.customerId);

    getTransactionList();
  }, [customerData])

  const onAddTransaction = () => {
    if(customerData.txnType.toUpperCase()  == 'CHARGES') {
      const chargePayload = {
        accountId : customerData.accountId,
        chargeType : customerData.chargeType.toUpperCase(),
        currency : customerData.sourceCurrency.toUpperCase()
      }
      createForexCharge(chargePayload)
      .then((res) => {
        setSnackbar(
          'Charge Added!'
        );
        getTransactionList()
      })
      .catch(err => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add Charge'),
          'error'
        );
      });
      return;
    }
    else {
      if(customerData.transactionCode === 'WALLET_TO_WALLET') {
        const walletTransferPayload = {
          accountId: customerData.accountId,
          amount: customerData.amount,
          sourceCurrency: customerData.sourceCurrency.toUpperCase(),
          destinationCurrency: customerData.destinationCurrency.toUpperCase(),
        }
        createForexWalletTransfer(walletTransferPayload)
        .then((res) => {
          setSnackbar(
            'Transaction Added!'
          );
          getTransactionList()
        })
        .catch(err => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Failed to add Transaction'),
            'error'
          );
        });
      }
      else {
        const transactionPayload = {
          accountId: customerData.accountId,
          amount: customerData.amount,
          currency: customerData.sourceCurrency.toUpperCase(),
          transactionIndicator: 'DEBIT',
          transactionCode: customerData.transactionCode.toUpperCase() 
        }
        createForexTransaction(transactionPayload)
        .then((res) => {
          setSnackbar(
            'Transaction Added!'
          );
          getTransactionList()
        })
        .catch(err => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Failed to add Transaction'),
            'error'
          );
        });
      }
    } 
    
  };

  const getTransactionList = () => {
    getForexTransactionForAccount({accountId: customerData.accountId})
    .then((res) => {
      console.log("res.data.transactions: ", res.data.records);
      setTransactionList(res.data.records);
    })
    .catch((err) => {
      console.log("error while fetching transaction")
    })
  }
  
  return (
    <VegaPage>
      <AddTransaction
        isLoading={isLoading}
        state={customerData}
        customerList={customers.map(c => {return {id: c.customerId, name: c.firstName}})}
        accountList={accountList}
        walletList={walletlist}
        handleChange={handleChange}
        onClear={onClear}
        onSubmit={onAddTransaction}
      />
      <ListOfSimulatorTransactions data={transactionList} />
    </VegaPage>
  );
};

export default Transactions;
