import { UploadFileOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import _ from 'lodash';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { CampaignType, RedemptionType } from '../../../types/RewardsLoyalty';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../utils/FileValidator';
import VegaFileInputV2 from '../../common/v2/VegaFileInputV2';
import VegaFormInputFieldV2 from '../../common/v2/VegaFormInputField';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../common/v2/VegaTextFieldV2';

type Props = {
  onNameChange: (value: string) => void;
  onCampaignTypeChange: (value: string) => void;
  onRedemptionTypeChange: (value: string) => void;
  onLogoChange: (value: File | null) => void;
  campaignType?: string;
  redemptionType?: string;
};

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([
    FileExtension.PNG,
    FileExtension.JPEG,
    FileExtension.JPG,
    FileExtension.SVG,
  ])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const AddNewCampaignForm = ({
  onNameChange,
  onCampaignTypeChange,
  onRedemptionTypeChange,
  onLogoChange,
  campaignType,
  redemptionType,
}: Props) => {
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();

  return (
    <Stack spacing={2}>
      <VegaFormInputFieldV2 label="Campaign Name">
        <VegaTextFieldV2
          onChange={(e) => onNameChange(e.target.value)}
          placeholder="Enter Campaign Name"
        />
      </VegaFormInputFieldV2>
      <VegaFormInputFieldV2 label="Program Name">
        <VegaTextFieldV2 value={selectedProgram?.name} disabled />
      </VegaFormInputFieldV2>
      <VegaFormInputFieldV2 label="Campaign Type">
        <VegaSelectV2
          options={campaignTypeOptions()}
          placeholder="Select Campaign Type"
          value={campaignType}
          onChange={(e) => {
            onCampaignTypeChange(e.target.value as string);
          }}
        />
      </VegaFormInputFieldV2>
      <VegaFormInputFieldV2 label="Redemption Type">
        <VegaSelectV2
          options={redemptionOptions()}
          placeholder="Select Redemption Type"
          value={redemptionType}
          onChange={(e) => {
            onRedemptionTypeChange(e.target.value as string);
          }}
        />
      </VegaFormInputFieldV2>
      <VegaFormInputFieldV2 label="Logo (Optional)">
        <VegaFileInputV2
          placeholder={'Select Logo'}
          acceptedExtension=".png"
          endIcon={<UploadFileOutlined fontSize="small" />}
          onFileSelect={function (file: File | null): void {
            onLogoChange(file);
          }}
          onError={(error) => {
            setSnackbar(error ?? 'Something Went Wrong', 'error');
            onLogoChange(null);
          }}
          fileValidator={fileValidatorConfig}
        />
      </VegaFormInputFieldV2>
    </Stack>
  );
};

export default AddNewCampaignForm;

const redemptionOptions = () =>
  Object.values(RedemptionType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const campaignTypeOptions = () =>
  Object.values(CampaignType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
