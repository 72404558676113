import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  SelectProps,
} from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import React from 'react';
import { COMMON } from '../../../constants/style';
import { toLowerCase } from '../../../constants/commonFunction';
import VegaHeader from '../../../components/common/VegaHeader';
import { CIMS_PRIMARY } from '../../assets/style';

export type ISelectOptionType = {
  id: string;
  name: string;
  icon?: JSX.Element;
};

interface ICIMSSelectProps extends SelectProps {
  options: string[] | ISelectOptionType[];
  header: string;
}

const CIMS_Select = ({ header, options, ...rest }: ICIMSSelectProps) => {
  return (
    <>
      <VegaHeader text={header} />
      <FormControl fullWidth>
        <Select
          {...rest}
          fullWidth
          style={{ borderRadius: '7px', color: '#AFAFAF', height: '39px' }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: '#C5C8D0',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: '#C5C8D0',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: '#C5C8D0',
            },
          }}
        >
          {options &&
            options.map((option, index) => (
              <MenuItem
                key={index}
                value={typeof option === 'string' ? option : option.id}
              >
                <Stack
                  className={''}
                  direction={'row'}
                  gap={2}
                  color={COMMON.gray}
                  alignItems="center"
                >
                  {typeof option !== 'string' && option.icon && option.icon}
                  {typeof option === 'string'
                    ? toLowerCase(option)
                    : toLowerCase(option.name)}
                </Stack>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CIMS_Select;
