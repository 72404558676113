/** @format */

import { Edit } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { VegaTextField } from '../common';
import VegaOutlineButton from '../common/VegaOutlineButton';
export type ICustomerDataType = {
  showCustomerData?: any;
  showConvertToEmiButton?: boolean;
  onConvertToEmiClick?: () => void;
};

const ViewCustomer = ({
  showCustomerData,
  showConvertToEmiButton = false,
  onConvertToEmiClick,
}: ICustomerDataType) => {
  const [show, setShow] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const { pathname } = useLocation();
  return (
    <>
      <Box sx={{ mx: 1, height: '100%', overflowX: 'auto' }}>
        {Object.keys(showCustomerData).map((value: any) => {
          return !!showCustomerData[value] ? (
            <Grid
              container
              sx={{
                my: 1,
                borderBottom: '1px solid lightgray',
                py: 1,
                px: 2,
              }}
            >
              <Grid item container spacing={2} sx={{ pt: 0 }}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold' }}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {value.split(/(?=[A-Z])/)[0]} {value.split(/(?=[A-Z])/)[1]}{' '}
                    {value.split(/(?=[A-Z])/)[2]} {value.split(/(?=[A-Z])/)[3]}{' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {Array.isArray(showCustomerData[value]) === true ? (
                    showCustomerData[value].map((item: any) => {
                      return (
                        <Typography
                          variant="body2"
                          style={{ fontWeight: 'bold' }}
                        >
                          {item?.idNumber}
                        </Typography>
                      );
                    })
                  ) : typeof showCustomerData[value] === 'object' ? (
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {showCustomerData?.[value] &&
                        Object.values(showCustomerData[value])
                          .join('\n ')
                          .trim()}
                    </Typography>
                  ) : showCustomerData[value] === 'true' ? (
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      Yes
                    </Typography>
                  ) : showCustomerData[value] === 'false' ? (
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      No
                    </Typography>
                  ) : pathname === '/accounts' && value === 'mobileNumber' ? (
                    <>
                      <div
                        className=""
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {show ? (
                          <>
                            <VegaTextField
                              autoFocus={true}
                              value={showCustomerData[value]}
                              onChange={(e) =>
                                console.log('lastName', e.target.value)
                              }
                              onBlur={() => setShow(!show)}
                              key="lastName"
                              label="Mobile Number"
                              type={'text'}
                              placeholder="Mobile Number"
                            />
                          </>
                        ) : (
                          <>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 'bold' }}
                            >
                              {showCustomerData[value]}
                            </Typography>
                            <Edit
                              sx={{ cursor: 'pointer' }}
                              onClick={() => setShow(!show)}
                            />
                          </>
                        )}
                      </div>
                    </>
                  ) : pathname === '/accounts' && value === 'emailId' ? (
                    <div
                      className=""
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {showEmail ? (
                        <>
                          <VegaTextField
                            autoFocus={true}
                            value={showCustomerData[value]}
                            onChange={(e) =>
                              console.log('lastName', e.target.value)
                            }
                            onBlur={() => setShowEmail(!showEmail)}
                            key="lastName"
                            label="Email"
                            type={'text'}
                            placeholder="Email"
                          />
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: 'bold' }}
                          >
                            {showCustomerData[value]}
                          </Typography>
                          <Edit
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setShowEmail(!showEmail)}
                          />
                        </>
                      )}
                    </div>
                  ) : (
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {value === 'createdAt' ||
                      value === 'updatedAt' ||
                      value === 'transactionTime'
                        ? dayjs(showCustomerData[value]).format(
                            'MMM D, YYYY h:mm A'
                          )
                        : showCustomerData[value]}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          );
        })}
        {showConvertToEmiButton && (
          <Stack direction={'row'} justifyContent="end" alignItems={'center'}>
            <VegaOutlineButton
              text={'Convert To EMI'}
              onClick={onConvertToEmiClick}
            />
          </Stack>
        )}
      </Box>
    </>
  );
};

export default ViewCustomer;
