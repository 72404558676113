import { IConsolidatedAccountData } from '../../../../types/Commons';
import { IManualAdjustment } from '../../../../types/ManualAdjustment';
import ManualAdjustmentMaker from './ManualAdjustmentMaker';
import ManualAdjustmentMakerList from './ManualAdjustmentMakerList';

type IProps = {
  accountsData: IConsolidatedAccountData[];
  manualAdjustmentList: IManualAdjustment[];
  refetch: () => void;
};

function ManualAdjustment({
  accountsData: accounts,
  manualAdjustmentList,
  refetch,
}: IProps) {
  return (
    <>
      <ManualAdjustmentMaker data={accounts} />
      {/*  LIST  */}
      <ManualAdjustmentMakerList
        data={manualAdjustmentList}
        refetchData={refetch}
      />
    </>
  );
}

export default ManualAdjustment;
