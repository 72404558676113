import { Box, Stack, Typography } from '@mui/material';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { VegaToolTipAndCopy } from '../../../../components/common/VegaToolTipNCopy';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardAllotmentCategory,
  CardAllotmentOrderDto,
  CardAllotmentStatusEnum,
  UpdateCardAllotmentOrderStatusRequest,
} from '../../../../types/card';
import { CardAllocationService } from '../../../apis';
import CIMSDataGrid from '../../../components/common/CIMS_DataGrid';
import CardAllotmentStatus, {
  allotmentStatusMapping,
} from '../../InventoryManagement/CardAllotment/CardAllotmentStatus';
import ViewOrderDetails from '../../InventoryManagement/CardAllotment/ViewOrderDetails';
import MarkAsShippedModal from './MarkAsShippedModal';
import MarkAsDelivered from './MarkAsDelivered';

interface IProps {
  onSubmit: (data: any) => void;
  setSelectedAllotment: React.Dispatch<
    React.SetStateAction<CardAllotmentOrderDto | null>
  >;
}

const getColumns = ({
  onSubmit,
  setSelectedAllotment,
}: IProps): GridColDef<CardAllotmentOrderDto>[] => {
  return [
    // {
    //   field: 'sr_no',
    //   headerName: 'S.No.',
    //   flex: 0.6,
    //   minWidth: 60,
    //   renderCell: (props) => (
    //     <VegaToolTipAndCopy value={props.row.sr_no.toString()} />
    //   ),
    // },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 0.8,
      minWidth: 100,
      valueGetter: (props) => {
        return dayjs(props.row.createdAt).format('DD/MM/YYYY');
      },
      renderCell: (props) => <Typography>{props.value}</Typography>,
      // renderCell: (props) => <VegaToolTipAndCopy value={props.row.createdAt} />,
    },
    {
      field: 'entityType',
      headerName: 'Entity Type',
      flex: 1.5,
      minWidth: 200,
      valueGetter(params) {
        return toLowerCase(params.row.destinationDivisionDto?.divisionType);
      },
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },
    {
      field: 'entityCode',
      headerName: 'Entity Code',
      flex: 0.8,
      minWidth: 120,
      valueGetter(params) {
        return params.row.destinationDivisionDto?.divisionType;
      },
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },

    {
      field: 'entityName',
      headerName: 'Entity Name',
      flex: 1.2,
      minWidth: 120,
      valueGetter(params) {
        return toLowerCase(params.row.destinationDivisionDto?.name || '');
      },
      renderCell: (props) => <VegaToolTipAndCopy isCopy value={props.value} />,
    },
    // {
    //   field: 'entityId',
    //   headerName: 'Entity Id',
    //   flex: 1.5,
    //   minWidth: 150,
    //   renderCell: (props) => (
    //     <VegaToolTipAndCopy value={props.row.destinationDivisionId} />
    //   ),
    // },
    {
      field: 'sourceUserEmail',
      headerName: 'Assignee Id',
      flex: 1.5,
      minWidth: 200,
      valueGetter(params) {
        return params.row.sourceUserEmail || '-';
      },
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'destinationUserEmail',
      headerName: 'Accepted by',
      flex: 1.5,
      minWidth: 200,
      valueGetter(params) {
        return params.row.receivedBy || '-';
      },
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    // {
    //   field: 'approvedBy',
    //   headerName: 'Approved by',
    //   flex: 1.2,
    //   minWidth: 120,
    //   valueGetter(params) {
    //     return params.row.approvedBy || '-';
    //   },
    //   renderCell: (props) => <Typography>{props.value}</Typography>,
    // },
    {
      field: 'totalKits',
      headerName: 'Total Cards Assigned',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <VegaToolTipAndCopy
          isCopy
          value={(props.row.totalKits || 0).toString()}
        />
      ),
    },
    {
      field: 'order_details',
      type: 'actions',
      headerName: 'Order details',
      renderCell: (props) => {
        return <ViewOrderDetails data={props.row} />;
      },
      flex: 1.5,
      minWidth: 150,
    },
    {
      field: 'allotmentStatus',
      headerName: 'Status',
      valueGetter: (props) => allotmentStatusMapping[props.row.allotmentStatus],
      renderCell: (props) => {
        return <CardAllotmentStatus status={props.row.allotmentStatus} />;
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Action',
      renderCell: (props) => {
        return (
          <div
            onClick={() => {
              setSelectedAllotment(props.row);
            }}
          >
            <MarkAction
              status={props.row.allotmentStatus}
              onSubmit={onSubmit}
              hasPrintOrderId={!!props.row.cardPrintOrderId}
              order={props.row}
            />
          </div>
        );
      },
      flex: 1.5,
      minWidth: 150,
      // hide: apiScope?.currrentLevel === Level.CLIENT,
    },
  ];
};
const MarkAction = ({
  status,
  onSubmit,
  hasPrintOrderId,
  order,
}: {
  status: CardAllotmentStatusEnum;
  hasPrintOrderId: boolean;
  onSubmit: (data: any) => void;
  order: CardAllotmentOrderDto;
}) => {
  if (status === CardAllotmentStatusEnum.PLACED && !hasPrintOrderId) {
    return <MarkAsShippedModal onSubmit={onSubmit} />;
  }

  if (status === CardAllotmentStatusEnum.DISPATCHED) {
    return <MarkAsDelivered data={order} />;
  }

  return <>-</>;
};

interface IListAssignCards {
  setRefetchList: any;
}
function ListOfAssignCards({ setRefetchList }: IListAssignCards) {
  const { userDivisionId } = useClientAuth();
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(0);

  const { setSnackbar } = useSnackbar();
  const [selectedAllotment, setSelectedAllotment] =
    useState<CardAllotmentOrderDto | null>(null);
  const cardAllocationQuery = useQuery({
    queryKey: [
      'cardAllocation',
      {
        userDivisionId,
        page,
        pageSize,
      },
    ],
    queryFn: () => {
      return CardAllocationService.list({
        sourceDivisionId: userDivisionId,
        allotmentCategoryList: [CardAllotmentCategory.ASSIGN],
        page,
        size: pageSize,
        populateDivision: true,
      });
    },
    keepPreviousData: true,
  });

  const updateAllocationStatusMutation = useMutation({
    mutationFn: (data: UpdateCardAllotmentOrderStatusRequest) => {
      return CardAllocationService.updateAllocationStatus(data);
    },
    onSuccess: () => {
      cardAllocationQuery.refetch();
      setSnackbar('Mark as shipped successfully');
    },
    onError: (error) => {
      setSnackbar('Error while updating status (Shipped)', 'error');
    },
  });

  const onSubmit = (data: {
    shipment: {
      mailerVendorId: string;
      trackingId: string;
    };
  }) => {
    if (selectedAllotment) {
      CardAllocationService.updateAllocationOrder({
        cardAllotmentOrderId: selectedAllotment?.id,
        mailerVendorId: data.shipment?.mailerVendorId,
        mailerVendorTrackingId: data.shipment.trackingId,
      });
      selectedAllotment &&
        updateAllocationStatusMutation.mutate({
          cardAllotmentOrderId: selectedAllotment?.id,
          status: CardAllotmentStatusEnum.DISPATCHED,
        });
    }
  };

  useEffect(() => {
    setRefetchList(() => cardAllocationQuery.refetch);
  }, []);

  return (
    <Box
      sx={{
        p: 2,
        pt: 1,
      }}
    >
      <Box>
        <CIMSDataGrid
          data={cardAllocationQuery.data?.records || []}
          columns={getColumns({
            onSubmit: onSubmit,
            setSelectedAllotment: setSelectedAllotment,
          })}
          //
          idColumn={'id'}
          loading={
            cardAllocationQuery.isLoading || cardAllocationQuery.isPreviousData
          }
          pageSize={pageSize}
          onPageSizeChange={(size) => {
            setPageSize(size);
          }}
          page={page}
          onPageChange={(page) => {
            setPage(page);
          }}
          rowCount={cardAllocationQuery.data?.totalItems}
          paginationMode="server"
          //
          components={{
            Toolbar: (props) => {
              return (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  my={2}
                >
                  <Stack
                    direction={'row'}
                    className="dataGrid"
                    alignItems={'center'}
                    spacing={2}
                  >
                    <GridToolbarQuickFilter
                      autoFocus
                      variant="outlined"
                      sx={{
                        bgcolor: 'white',
                        color: '#999DA8',
                        height: '35px',
                        minWidth: '320px',
                        borderRadius: '9px',
                      }}
                    />
                  </Stack>
                </Stack>
              );
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default ListOfAssignCards;
