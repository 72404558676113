import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { fetchAllPDDList } from '../../../api/policy';
import { updateProgramPDDList } from '../../../api/universalStandard';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { VegaCard } from '../../common';
import VegaContainedButton from '../../common/VegaContainedButton';
import ProgramSelector from '../../program/ProgramSelector';
import PaymentDueDates from './PaymentDueDates';
import SelectDueDates from './SelectDueDates';

const DateSelector = () => {
  const [selectDate, setSelectDate] = useState<any>([]);
  const { setSnackbar } = useSnackbar();
  const { selectedProgram }: any = useProgramData();

  const selectDueDate = (i: number) => {
    const isSelected = selectDate.some((val: number) => val === i);

    if (!isSelected) {
      setSelectDate([...selectDate, i]);
    } else {
      const newDate = selectDate.filter((val: number) => val !== i);
      setSelectDate(newDate);
    }
  };

  const updateProgramPDD = async () => {
    await updateProgramPDDList(selectedProgram?.programId, selectDate)
      .then((res) => {
        console.log('res.data', res.data);
        setSnackbar('Update Payment Due Date !!');
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };
  const fetchPDDList = () => {
    if (selectedProgram?.programId) {
      // setLoading(true);
      fetchAllPDDList(selectedProgram.programId)
        .then((res) => {
          const data = res?.data ?? [];
          setSelectDate(data || []);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    fetchPDDList();
  }, [selectedProgram?.programId]);
  return (
    <Box>
      <VegaCard>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <SelectDueDates
              selectDate={selectDate}
              selectDueDate={selectDueDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PaymentDueDates selectDate={selectDate} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <VegaContainedButton
                text="Save"
                isPrimary
                onClick={updateProgramPDD}
              />
            </Box>
          </Grid>
        </Grid>
      </VegaCard>
    </Box>
  );
};

export default DateSelector;
