import { Add } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import VegaPrimaryButton from '../../../../components/common/v2/VegaPrimaryButton';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import AddNewCampaignForm from '../../../../components/RewardsLoyalty/Campaigns/AddNewCampaignForm';
import { AddNewCampaignFormData } from '../../../../types/RewardsLoyalty';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: AddNewCampaignFormData) => void;
  campaignType?: string;
};

function NewCampaignModal({
  open,
  onClose,
  onSubmit,
  campaignType: prefilledCampaignType,
}: Props) {
  const [name, setName] = useState<string>('');
  const [campaignType, setCampaignType] = useState<string>('');
  const [redemptionType, setRedemptionType] = useState<string>('');
  const [logo, setLogo] = useState<File | null>();

  const isInputValid = () => {
    const isNameValid = name.length > 0;
    const isCampaignTypeValid = campaignType.length > 0;
    const isRedemptionTypeValid = redemptionType.length > 0;
    return isNameValid && isCampaignTypeValid && isRedemptionTypeValid;
  };

  function onCreateClick() {
    onSubmit({
      name: name,
      campaignType: campaignType,
      redemptionType: redemptionType,
      logo: logo ?? undefined,
    });
    onClose();
  }

  useEffect(() => {
    setCampaignType(prefilledCampaignType ?? '');
  }, [prefilledCampaignType]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      style={{ maxHeight: '80vh', marginTop: '7.5rem' }}
      scroll="paper"
    >
      <DialogTitle>
        <VegaTextV2 text="New Campaign" variant="h6" />
      </DialogTitle>
      <DialogContent>
        <AddNewCampaignForm
          onNameChange={setName}
          onCampaignTypeChange={setCampaignType}
          onRedemptionTypeChange={setRedemptionType}
          onLogoChange={setLogo}
          campaignType={campaignType}
          redemptionType={redemptionType}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'}>
          <VegaPrimaryButton
            text={'Cancel'}
            onClick={() => {
              onClose();
            }}
            variant="text"
          />
          <VegaPrimaryButton
            text={'Create Campaign'}
            startIcon={<Add />}
            disabled={isInputValid() == false}
            onClick={() => {
              onCreateClick();
            }}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default NewCampaignModal;
