import { Box } from '@mui/material';
import React from 'react';
import Vega_Text from '../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../constants/style';

interface IProps {
  text: string;
}

const NoDataFound = ({ text }: IProps) => {
  return (
    <Box
      sx={{
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid #E1E4EB`,
        borderRadius: '12px',
        height: '100%',
      }}
    >
      <img src={require('../../assets/images/NoResult.png')} alt="" />
      <Vega_Text
        text={text}
        fontWeight={600}
        color={NEW_PRIMARY.BLACK}
        fontSize={17}
      />
    </Box>
  );
};

export default NoDataFound;
