import React, { useState } from 'react';
import AddRuleModal from '../../../components/RewardsLoyalty/Campaigns/RulesConfiguration/Point Allocation/AddNewRule/AddRuleModal';
import { useCampaign } from '../../../components/RewardsLoyalty/providers/CampaignProvider';
import { CampaignRule } from '../../../types/RewardsLoyalty';
import CampaignRuleList from './CampaignRuleList';

type ShowEditRuleModal = {
  show: boolean;
  rule: CampaignRule | null;
};
function NonPointAllocationTabPanel() {
  const [showAddRuleModal, setShowAddRuleModal] = useState<boolean>(false);
  const [showEditRuleModal, setShowEditRuleModal] = useState<ShowEditRuleModal>(
    {
      show: false,
      rule: null,
    }
  );
  return (
    <div>
      <div style={{ flex: 1, flexGrow: 1 }}>
        <CampaignRuleList
          onEdit={function (rule: CampaignRule): void {
            setShowEditRuleModal({
              show: true,
              rule: rule,
            });
          }}
          onClick={function (rule: CampaignRule): void {}}
          onAddRuleClick={function (): void {
            setShowAddRuleModal(true);
          }}
        />
      </div>

      <AddRuleModal
        onClose={function (): void {
          setShowAddRuleModal(false);
        }}
        open={showAddRuleModal}
      />
      <AddRuleModal
        rule={showEditRuleModal.rule}
        onClose={function (): void {
          setShowEditRuleModal({
            show: false,
            rule: null,
          });
        }}
        open={showEditRuleModal.show}
      />
    </div>
  );
}

export default NonPointAllocationTabPanel;
