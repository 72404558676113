import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { BORDER, BORDER_BLUE } from '../../constants/style';
import { VegaCard } from '../common';

interface ISummaryProps {
  count: string;
  summaryType: string;
}
interface IProps {
  data: ISummaryProps[];
}

const AgentSummary = ({ data }: IProps) => {
  return (
    <VegaCard>
      <Typography mb={2}>Agent Summary</Typography>
      <Grid container spacing={2}>
        {data.map((summary: ISummaryProps) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              sx={{
                border: BORDER_BLUE,
                p: 3,
                borderRadius: '10px',
                borderLeft: '39px solid #9EBFFF',
              }}
            >
              <Typography fontSize={30} fontWeight={'bold'} sx={{}}>
                {summary.count}
              </Typography>
              <Typography>{summary.summaryType}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </VegaCard>
  );
};

export default AgentSummary;
