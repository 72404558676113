/** @format */

import BinConfiguration from '../components/Bin Charter/BinCofiguration/BinConfiguration';
import BinDetails from '../components/Bin Charter/BinDetails/BinDetails';
import { useBinCharter } from '../providers/BinCharterProvider';
import { BinCharterRoute } from '../routes';

const BinCharter = () => {
  const { selecteRoute } = useBinCharter();

  return selecteRoute === BinCharterRoute.BIN_CONFIGURATION ? (
    <BinConfiguration />
  ) : (
    <BinDetails />
  );
};

export default BinCharter;
