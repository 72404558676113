/* eslint-disable react/jsx-pascal-case */
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { listCardStatus } from '../../../../api/card';
import { listForClient } from '../../../../api/program';
import VegaHeader from '../../../../components/common/VegaHeader';
import VegaCheckBoxV2 from '../../../../components/common/v2/VegaCheckBoxV2';
import { toLowerCase } from '../../../../constants/commonFunction';
import { CardType } from '../../../../providers/CardConfigProvider';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { CardStatus, ListCardRequest } from '../../../../types/card';
import CIMS_Button from '../../../components/common/CIMS_Button';
import CIMS_Drawer from '../../../components/common/CIMS_Drawer';

interface IProps {
  isFilterShow: boolean;
  setIsFilterShow: (isFilterShow: boolean) => void;
  toggleFilter: () => void;
  setFilterData: React.Dispatch<React.SetStateAction<Partial<ListCardRequest>>>;
}

function CardInvetoryFilterModal({
  isFilterShow,
  setIsFilterShow,
  toggleFilter,
  setFilterData,
}: IProps) {
  const { clientId } = useClientAuth();

  const programsQuery = useQuery({
    queryKey: ['programs', clientId],
    queryFn: () => listForClient(clientId || '', 0, 100),
    enabled: !!clientId,
  });

  const listCardStatusQuery = useQuery({
    queryKey: 'cardStatus',
    queryFn: () => {
      return listCardStatus();
    },
    enabled: !!clientId,
  });

  const [cardType, setCardType] = useState<Record<string, boolean>>({});

  const [cardStatus, setCardStatus] = useState<Record<string, boolean>>({});

  const [selectedPrograms, setSelectedPrograms] = useState<
    Record<string, boolean>
  >({});

  const handleChangeCardType = (cardType: string, value: boolean) => {
    setCardType((pre) => ({
      ...pre,
      [cardType]: value,
    }));
  };
  const handleChangeCardStatus = (cardType: string, value: boolean) => {
    setCardStatus((pre) => ({
      ...pre,
      [cardType]: value,
    }));
  };

  const handleProgramChange = (programId: string, value: boolean) => {
    setSelectedPrograms((pre) => ({
      ...pre,
      [programId]: value,
    }));
  };

  const applyFilter = () => {
    const cardTypeFilter = Object.keys(cardType).filter(
      (key) => cardType[key]
    ) as CardType[];
    const cardStatusFilter = Object.keys(cardStatus).filter(
      (key) => cardStatus[key]
    ) as CardStatus[];

    const programFilter = Object.keys(selectedPrograms).filter(
      (key) => selectedPrograms[key]
    );

    setFilterData((pre) => ({
      ...pre,
      cardTypeList: cardTypeFilter,
      cardStatusList: cardStatusFilter,
      programId: programFilter,
    }));
    toggleFilter();
  };

  const onClear = () => {
    setCardType((pre) => ({}));
    setCardStatus((pre) => ({}));
    setSelectedPrograms((pre) => ({}));
    // setFilterData({
    //   cardTypeList: [],
    //   cardStatusList: [],
    //   programId: [],
    // });
    // toggleFilter();
  };

  return (
    <CIMS_Drawer
      open={isFilterShow}
      onClose={toggleFilter}
      header="Filters"
      clearButton={
        <CIMS_Button text={'Clear All'} variant="text" onClick={onClear} />
      }
      submitButton={<CIMS_Button text={'Submit'} onClick={applyFilter} />}
    >
      <>
        <Box>
          <VegaHeader text={'card type'} />
          <Stack direction={'row'} alignItems={'center'} gap={1} my={2}>
            {Object.keys(CardType).map((key) => {
              if (key === 'PHYSICAL') {
                return;
              }
              return (
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <VegaCheckBoxV2
                    sx={{
                      '&.MuiButtonBase-root': {
                        padding: '0px !important',
                      },
                      color: 'blue',
                    }}
                    onChange={(e, checked) =>
                      handleChangeCardType(key, checked)
                    }
                    checked={cardType[key] || false}
                  />
                  <Typography
                    fontSize={13}
                    className="font-aspekta-500"
                    color={'#1B1D22'}
                  >
                    {toLowerCase(key)}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Box>
        <Box my={5}>
          <VegaHeader text={'Program name'} />
          <Stack direction={'column'} gap={1} my={2}>
            {programsQuery.data?.data.records.map((program, key) => {
              return (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  gap={1}
                  key={key}
                >
                  <VegaCheckBoxV2
                    sx={{
                      '&.MuiButtonBase-root': {
                        padding: '0px !important',
                      },
                      color: 'blue',
                    }}
                    onChange={(e, checked) => {
                      handleProgramChange(program.programId, checked);
                    }}
                    checked={selectedPrograms[program.programId] || false}
                  />
                  <Typography
                    fontSize={13}
                    className="font-aspekta-500"
                    color={'#1B1D22'}
                  >
                    {toLowerCase(program.name || '')}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Box>

        {listCardStatusQuery.isLoading ? (
          <Stack spacing={3}>
            <Stack direction={'row'} spacing={2}>
              <Skeleton variant="rectangular" width={20} height={20} />
              <Skeleton variant="rectangular" width={210} height={20} />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <Skeleton variant="rectangular" width={20} height={20} />
              <Skeleton variant="rectangular" width={210} height={20} />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <Skeleton variant="rectangular" width={20} height={20} />
              <Skeleton variant="rectangular" width={210} height={20} />
            </Stack>
          </Stack>
        ) : (
          <Box>
            <VegaHeader text={'Card Status'} />
            <Stack
              direction={'column'}
              justifyContent={'flex-start'}
              gap={1}
              my={2}
            >
              {listCardStatusQuery.data?.map((key) => {
                return (
                  <Stack direction={'row'} alignItems={'flex-start'} gap={1}>
                    <VegaCheckBoxV2
                      sx={{
                        '&.MuiButtonBase-root': {
                          padding: '0px !important',
                        },
                        color: 'blue',
                      }}
                      onChange={(e, checked) =>
                        handleChangeCardStatus(key, checked)
                      }
                      checked={cardStatus[key] || false}
                    />
                    <Typography
                      fontSize={13}
                      className="font-aspekta-500"
                      color={'#1B1D22'}
                    >
                      {toLowerCase(key)}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
        )}
      </>
    </CIMS_Drawer>
  );
}

export default CardInvetoryFilterModal;
