import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getPostingeMetaDataForAccount } from '../api/ledger';
import { VegaCard, VegaPage, VegaTable } from '../components/common';
import { useCustomerAccountData } from '../providers/CustomerDataProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { VegaPosting } from '../types/ledger';

export const GeneralLedger = () => {
  const columnDef = [
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        const indicator = props.row.transactionIndicator;
        var color = indicator == 'DEBIT' ? 'red' : 'green';
        return (
          <Typography variant="caption" color={color}>
            {posting.amount}
          </Typography>
        );
      },
    },
    {
      field: 'transactionDate',
      headerName: 'Transaction Date',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {dayjs(posting.transactionDate).format('YYYY-MM-DD')}
          </Typography>
        );
      },
    },
    {
      field: 'currency',
      headerName: 'Currency',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return <Typography variant="caption">{posting.currency}</Typography>;
      },
    },
    {
      field: 'retailPrincipal',
      headerName: 'Retail Principal',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.retailPrincipal}</Typography>
        );
      },
    },
    {
      field: 'retailInterest',
      headerName: 'Retail Interest',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.retailInterest}</Typography>
        );
      },
    },
    {
      field: 'retailFee',
      headerName: 'Retail Fee',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return <Typography variant="caption">{posting.retailFee}</Typography>;
      },
    },
    {
      field: 'cashPrincipal',
      headerName: 'Cash Principal',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.cashPrincipal}</Typography>
        );
      },
    },
    {
      field: 'cashInterest',
      headerName: 'Cash Interest',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.cashInterest}</Typography>
        );
      },
    },
    {
      field: 'cashFee',
      headerName: 'Cash Fee',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return <Typography variant="caption">{posting.cashFee}</Typography>;
      },
    },
    {
      field: 'emiPrincipal',
      headerName: 'Emi Principal',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.emiPrincipal}</Typography>
        );
      },
    },
    {
      field: 'emiInterest',
      headerName: 'Emi Interest',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return <Typography variant="caption">{posting.emiInterest}</Typography>;
      },
    },
    {
      field: 'emiFee',
      headerName: 'Emi Fee',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return <Typography variant="caption">{posting.emiFee}</Typography>;
      },
    },
    {
      field: 'overlimit',
      headerName: 'Overlimit',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return <Typography variant="caption">{posting.overlimit}</Typography>;
      },
    },
    {
      field: 'surplusCredit',
      headerName: 'Surplus Credit',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.surplusCredit}</Typography>
        );
      },
    },
    {
      field: 'retailPrincipalBilled',
      headerName: ' Retail Principal Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.retailPrincipalBilled}
          </Typography>
        );
      },
    },
    {
      field: 'retailFeeBilled',
      headerName: 'Retail Fee Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.retailFeeBilled}</Typography>
        );
      },
    },
    {
      field: 'overlimitBilled',
      headerName: 'Overlimit Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.overlimitBilled}</Typography>
        );
      },
    },
    {
      field: 'retailPrincipalUnbilled',
      headerName: 'Retail Principal Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.retailPrincipalUnbilled}
          </Typography>
        );
      },
    },
    {
      field: 'retailInterestUnbilled',
      headerName: 'Retail Interest Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.retailInterestUnbilled}
          </Typography>
        );
      },
    },
    {
      field: 'retailFeeUnbilled',
      headerName: 'Retail Fee Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.retailFeeUnbilled}</Typography>
        );
      },
    },
    {
      field: 'cashPrincipalBilled',
      headerName: 'Cash Principal Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.cashPrincipalBilled}
          </Typography>
        );
      },
    },
    {
      field: 'cashInterestBilled',
      headerName: 'Cash Interest Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.cashInterestBilled}
          </Typography>
        );
      },
    },
    {
      field: 'cashFeeBilled',
      headerName: 'Cash Fee Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.cashFeeBilled}</Typography>
        );
      },
    },
    {
      field: 'cashPrincipalUnbilled',
      headerName: 'Cash Principal Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.cashPrincipalUnbilled}
          </Typography>
        );
      },
    },
    {
      field: 'cashInterestUnbilled',
      headerName: 'Cash Interest Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.cashInterestUnbilled}
          </Typography>
        );
      },
    },
    {
      field: 'cashFeeUnbilled',
      headerName: 'Cash Fee Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.cashFeeUnbilled}</Typography>
        );
      },
    },
    {
      field: 'emiPrincipalBilled',
      headerName: 'Emi Principal Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.emiPrincipalBilled}
          </Typography>
        );
      },
    },
    {
      field: 'emiInterestBilled',
      headerName: 'Emi Interest Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.emiInterestBilled}</Typography>
        );
      },
    },
    {
      field: 'emiFeeBilled',
      headerName: 'Emi Fee Billed',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.emiFeeBilled}</Typography>
        );
      },
    },
    {
      field: 'emiPrincipalUnbilled',
      headerName: 'Emi Principal Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.emiPrincipalUnbilled}
          </Typography>
        );
      },
    },
    {
      field: 'emiInterestUnbilled',
      headerName: 'Emi Interest Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">
            {posting.emiInterestUnbilled}
          </Typography>
        );
      },
    },
    {
      field: 'emiFeeUnbilled',
      headerName: 'Emi Fee Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.emiFeeUnbilled}</Typography>
        );
      },
    },
    {
      field: 'overlimitUnbilled',
      headerName: 'Overlimit Unbilled',
      renderCell: (props: any) => {
        const posting = getPostingData(props);
        return (
          <Typography variant="caption">{posting.overlimitUnbilled}</Typography>
        );
      },
    },
  ];

  const { selectedAccount } = useCustomerAccountData();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState({
    rows: [],
    rowCount: 0,
    pageSize: 10,
    page: 0,
  });
  const getPostingData = (props: any) => {
    return props.row.postingDto as VegaPosting;
  };

  function getPostings(accountId: string, page: number, pageSize: number) {
    setLoading(true);
    getPostingeMetaDataForAccount({
      accountId: accountId,
      page: page,
      pageSize: pageSize,
    })
      .then((res) => {
        setData((prevState) => {
          return {
            ...prevState,
            rows: res.records,
            rowCount: res.totalItems,
          };
        });
      })
      .catch((err) => setSnackbar('Failed to fetch Postings', 'error'))
      .finally(() => setLoading(false));
  }

  function fetchPostingsForSelectedAccount() {
    const accountId = selectedAccount?.account.accountId;
    if (!accountId) return;
    getPostings(accountId, data.page, data.pageSize);
  }

  function fetchDataForPage(page: number) {
    const accountId = selectedAccount?.account.accountId;
    if (!accountId) return;
    setData((prev) => {
      return {
        ...prev,
        page: page,
      };
    });
    getPostings(accountId, page, data.pageSize);
  }

  function updatePageSize(pageSize: number) {
    const accountId = selectedAccount?.account.accountId;
    if (!accountId) return;
    setData((prev) => {
      return {
        ...prev,
        pageSize: pageSize,
      };
    });
    getPostings(accountId, data.page, pageSize);
  }

  useEffect(() => {
    fetchPostingsForSelectedAccount();
  }, [selectedAccount]);

  return (
    <VegaCard noPad>
      <VegaTable
        data={data.rows}
        columnDef={columnDef}
        idColumn={'id'}
        loading={loading}
        paginationMode="server"
        onPageChange={(page) => {
          fetchDataForPage(page);
        }}
        onPageSizeChange={(pageSize) => {
          updatePageSize(pageSize);
        }}
        page={data.page}
        pageSize={data.pageSize}
        rowCount={data.rowCount}
      />
    </VegaCard>
  );
};
