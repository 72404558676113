import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import {
  ResourceType,
  useInstitutionalResources,
} from '../../hooks/useInstitutionalResources';
import { MccCode, Mcg } from '../../types/resources';
import VegaSearchBy from '../common/VegaSearchBy';
import VegaTable from '../common/VegaTable';
import { getLogoForNetwork } from '../icon/Icons';
import { VegaCard } from '../common';

function MccTable() {
  const { loading, fetchResource } = useInstitutionalResources();
  const [mccData, setMccData] = useState<MccCode[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [filter, setFilter] = useState<{ search: string; searchBy: string }>({
    search: '',
    searchBy: MCC_CLOUMNS[0].value,
  });
  const isCurrentSelectionMccCode = filter.searchBy === 'code';

  async function fetchMccCodes() {
    try {
      const response = await fetchResource<MccCode>({
        type: ResourceType.MCC,
        page: page,
        pageSize: pageSize,
        searchBy: filter.searchBy,
        searchQuery: filter.search,
      });
      setMccData(response.records);
      setRowCount(response.totalItems);
    } catch (error) {}
  }

  const onFilterChange = (name: string, value: string) => {
    if (name === 'searchBy') {
      setFilter((preVal: any) => ({
        ...preVal,
        ['search']: '',
        ['searchBy']: value,
      }));
    } else {
      if (!isCurrentSelectionMccCode || /^\d+$/.test(value) || value === '') {
        setFilter((preVal: any) => ({
          ...preVal,
          [name]: value,
        }));
      }
    }
  };

  useEffect(() => {
    fetchMccCodes();
  }, [page, pageSize, filter.search, filter.searchBy]);

  return (
    <Fragment>
      <VegaSearchBy
        placeholder={isCurrentSelectionMccCode ? 'Search By Code' : 'Search'}
        data={MCC_CLOUMNS}
        searchByname="searchBy"
        searchName="search"
        key="search"
        state={filter}
        handleChange={onFilterChange}
      />
      <VegaCard noPad>
        <VegaTable
          data={mccData}
          columnDef={columnMCC}
          idColumn={'code'}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
          loading={loading}
        />
      </VegaCard>
    </Fragment>
  );
}

export default MccTable;

const columnMCC = [
  {
    field: 'code',
    headerName: 'Code',
    flex: 0.5,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1.5,
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    renderCell: (props: any) => {
      const category = props.row.category as Mcg;
      return <Typography>{_.startCase(_.camelCase(category.name))}</Typography>;
    },
  },
  {
    field: 'paymentBrands',
    headerName: 'Payment Brands',
    flex: 0.5,
    renderCell: (props: any) => {
      const networks: string[] = props.row.paymentBrands;
      if (!networks || networks.length <= 0) {
        return;
      }
      return (
        <Stack direction={'row'} alignItems="center" spacing={2}>
          {networks.map((item) => (
            <div>{getLogoForNetwork(item)}</div>
          ))}
        </Stack>
      );
    },
  },
];

const MCC_CLOUMNS = [
  {
    text: 'Code',
    value: 'code',
  },
  {
    text: 'Description',
    value: 'description',
  },
];
