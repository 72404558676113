import { Save } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import { CardType, useCardConfig } from '../../../providers/CardConfigProvider';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { VegaProgramIssuanceType } from '../../../types/program';
import VegaCardV2 from '../../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../common/v2/VegaFormInputField';
import VegaFullScreenDialog from '../../common/v2/VegaFullScreenDialog';
import VegaPrimaryButton from '../../common/v2/VegaPrimaryButton';
import VegaSwitchV2 from '../../common/v2/VegaSwitchV2';
import VegaTextFieldV2 from '../../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import CardBackgroundColorSelector from '../CardBackgroundColorSelector';
import CardBankLogoSelector from '../CardBankLogoSelector';
import CardCobrandLogoSelector from '../CardCobrandLogoSelector';
import CardNetworkLogoSelector from '../CardNetworkLogoSelector';
import CardNfcLogoSelector from '../CardNfcLogoSelector';
import CardTextColorSelector from '../CardTextColorSelector';
import ProgramCardBack from '../ProgramCard/ProgramCardBack';
import ProgramCardFront from '../ProgramCard/ProgramCardFront';

type Props = {
  open: boolean;
  onClose: () => void;
  cardType: CardType;
};

function CardConfigModal({ open, onClose, cardType }: Props) {
  const {
    onTopDescriptionChange,
    onBottomDescriptionChange,
    physicalCardConfig,
    virtualCardConfig,
    canEnableSaveButton,
    onNfcLogoChange,
    onNfcStatusChange,
    onSave,
  } = useCardConfig();

  const { selectedProgram } = useProgramData();

  const isProgramCobrand = () => {
    const isCobrand =
      selectedProgram?.issuanceType == VegaProgramIssuanceType.CO_BRAND;
    return isCobrand;
  };

  const getConfig = () => {
    return cardType == CardType.PHYSICAL
      ? physicalCardConfig
      : virtualCardConfig;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const value = getConfig().signaturePanelText;
      const currentLines = value?.split('\n').length ?? 0;
      if (currentLines >= 5) return;
      const updatedText = value + '\n';
      onBottomDescriptionChange(updatedText, cardType);
      event.preventDefault();
    }
  };

  const handleSignatureTextChange = (value: string) => {
    const currentLines = value.split('\n').length;
    if (currentLines > 5) return;
    if (value.length <= 120) {
      onBottomDescriptionChange(value, cardType);
    }
  };

  return (
    <VegaFullScreenDialog
      title={
        cardType == CardType.PHYSICAL
          ? 'Configure Physical Card'
          : 'Configure Virtual Card'
      }
      open={open}
      onClose={onClose}
      footerActions={
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent="flex-end"
          alignItems={'center'}
          marginY={'.5rem'}
          marginX={'1.5rem'}
        >
          <VegaPrimaryButton onClick={onClose} text={'Close'} variant="text" />
          <VegaPrimaryButton
            disabled={canEnableSaveButton == false}
            onClick={() => {
              onSave(cardType);
              onClose();
            }}
            text={'Save'}
            startIcon={<Save />}
          />
        </Stack>
      }
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          height: '100%',
        }}
      >
        <Stack flex={1} style={{ padding: 12 }} spacing={4}>
          <VegaCardV2>
            <VegaTextV2 text="Card Color Configuration" />
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <VegaFormInputFieldV2 label="Background color">
                  <CardBackgroundColorSelector cardType={cardType} />
                </VegaFormInputFieldV2>
              </Grid>
              <Grid item xs={5}>
                <VegaFormInputFieldV2 label="Text color">
                  <CardTextColorSelector cardType={cardType} />
                </VegaFormInputFieldV2>
              </Grid>
            </Grid>
          </VegaCardV2>

          <VegaCardV2>
            <VegaTextV2 text="Front Card" />
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <VegaFormInputFieldV2 label="Bank Logo">
                  <CardBankLogoSelector cardType={cardType} />
                </VegaFormInputFieldV2>
              </Grid>
              {isProgramCobrand() && (
                <Grid item xs={5}>
                  <VegaFormInputFieldV2 label="Cobrand Logo">
                    <CardCobrandLogoSelector cardType={cardType} />
                  </VegaFormInputFieldV2>
                </Grid>
              )}
              <Grid item xs={5}>
                <VegaFormInputFieldV2 label="Network Logo">
                  <CardNetworkLogoSelector cardType={cardType} />
                </VegaFormInputFieldV2>
              </Grid>
              <Grid item xs={5}>
                <VegaFormInputFieldV2
                  label="NFC Logo"
                  endAdornment={
                    <VegaSwitchV2
                      size="small"
                      checked={getConfig().isNfcEnabled}
                      onChange={(e) => {
                        onNfcStatusChange(e.currentTarget.checked, cardType);
                      }}
                    />
                  }
                >
                  <CardNfcLogoSelector cardType={cardType} />
                </VegaFormInputFieldV2>
              </Grid>
            </Grid>
          </VegaCardV2>

          {cardType == CardType.PHYSICAL && (
            <VegaCardV2>
              <VegaTextV2 text="Back Card" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <VegaFormInputFieldV2 label="Upper Text">
                    <VegaTextFieldV2
                      placeholder="Enter Upper text (Max 60 character)"
                      value={physicalCardConfig.aboveMagneticPanelText}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 60) {
                          onTopDescriptionChange(e.target.value, cardType);
                        }
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
                <Grid item xs={12}>
                  <VegaFormInputFieldV2 label="Lower text">
                    <VegaTextFieldV2
                      multiline
                      maxRows={5}
                      placeholder="Enter Upper text (Max 120 character)"
                      value={physicalCardConfig.signaturePanelText}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleSignatureTextChange(value);
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
              </Grid>
            </VegaCardV2>
          )}
        </Stack>

        <Box
          flex={1}
          style={{
            display: 'flex',
            paddingTop: '12px',
            height: '100%',
          }}
        >
          <VegaCardV2 height={'100%'} flex={2} flexGrow={1}>
            <VegaTextV2 text="Card Preview" />
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                paddingTop: '3rem',
                paddingBottom: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                overflow: 'scroll',
              }}
            >
              <ProgramCardFront cardType={cardType} />
              {cardType == CardType.PHYSICAL && <ProgramCardBack />}
            </Box>
          </VegaCardV2>
        </Box>
      </Box>
    </VegaFullScreenDialog>
  );
}

export default CardConfigModal;
