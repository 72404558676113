import axios from 'axios';
import { VoucherInventory } from '../types/RewardsLoyalty';

const ENDPOINTS = {
  BASE: '/inventory',
  GET_FOR_PROGRAM: '/program/{programId}',
};

const BASE_RULE_URL = ENDPOINTS.BASE;
export class VoucherInventoryService {
  static async getForProgram(programId: string): Promise<VoucherInventory[]> {
    try {
      const endpoint =
        BASE_RULE_URL +
        ENDPOINTS.GET_FOR_PROGRAM.replace('{programId}', programId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
