/* eslint-disable react/jsx-pascal-case */
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardAllotmentOrderDto,
  CardIndentationOrderStatus,
} from '../../../../types/card';
import { CardAllocationService } from '../../../apis';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import { useCardIndentationOrder } from '../context';

type RejectRequestModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  orderData?: CardAllotmentOrderDto;
};

function RejectRequestModal({
  isOpen,
  handleClose,
  orderData,
}: RejectRequestModalProps) {
  const hookForm = useForm<{
    rejectionReason: string;
  }>();
  const { setSnackbar } = useSnackbar();
  const { listIndentationOrderQuery } = useCardIndentationOrder();
  const onSubmit = () => {
    const data = hookForm.getValues();
    CardAllocationService.updateCardIndentationOrderStatus({
      cardIndentationOrderId: orderData?.id || '',
      status: CardIndentationOrderStatus.REJECTED,
    })
      .then((res) => {
        setSnackbar(`Order ID ${orderData?.id} has been rejected`);
      })
      .catch((err) => {
        setSnackbar(`Error in rejecting order id - ${orderData?.id} `, 'error');
      })
      .finally(() => {
        listIndentationOrderQuery?.refetch();
        hookForm.reset();
      });
  };

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Reject',
      // disabled: !hookForm.formState.isValid,
      onSubmit: () => {
        onSubmit && onSubmit();
        handleClose();
      },
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <CIMS_Modal
      maxWidth="sm"
      open={isOpen}
      handleClose={handleClose}
      header="Reject Request"
      buttonGroup={buttons}
    >
      <Box sx={{ px: 3, py: 2 }}>
        <Stack spacing={1}>
          {/* <Controller
            name="rejectionReason"
            control={hookForm.control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <CIMS_TextField
                placeholder="Add Rejection Reason"
                isTextArea
                multiline
                rows={5}
                header="Reason for Rejection"
                variant="outlined"
                {...field}
              />
            )}
          /> */}
          <Typography>Are you sure you want to reject this request?</Typography>
        </Stack>
      </Box>
    </CIMS_Modal>
  );
}

export default RejectRequestModal;
