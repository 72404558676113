import { Close } from '@mui/icons-material';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  DialogProps,
} from '@mui/material';
import React from 'react';

export interface DialogTitleProps {
  id: string;
  isCloseIcon?: boolean;
  children?: React.ReactNode;
  onClose: (e: any) => void;
}

interface IDialogProps extends DialogProps {
  handleClose: (e: any) => void;
  isCloseIcon?: boolean;
  open: boolean;
  bgColor?: string;
  children: JSX.Element;
  buttonComponent: () => React.ReactNode;
  headerComponent: () => React.ReactNode;
}

const DialogTitleBox = (props: DialogTitleProps) => {
  const { children, isCloseIcon, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {isCloseIcon ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close
            sx={{
              color: '#3A4A5F',
              fontSize: 20,
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Vega_Dialog = ({
  handleClose,
  open,
  children,
  bgColor = '',
  isCloseIcon = false,
  buttonComponent,
  headerComponent,
  ...rest
}: IDialogProps) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...rest}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
        },
        ...rest.sx,
      }}
    >
      <DialogTitleBox
        id="customized-dialog-title"
        onClose={handleClose}
        isCloseIcon={isCloseIcon}
      >
        {headerComponent && headerComponent()}
      </DialogTitleBox>
      <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
      {buttonComponent && (
        <DialogActions sx={{ p: 0 }}>{buttonComponent()}</DialogActions>
      )}
    </Dialog>
  );
};

export default Vega_Dialog;
