import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaSelect from '../../../components/common/VegaSelect';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { CatalogType } from '../../../providers/ReportsProvider';
import ReportBody from '../components/MCCReport/ReportBody';
import ScheduleHeaders from '../components/MCCReport/ScheduleHeaders';

interface IProps {
  authId: string | null;
  platform: string
}

const ReportsTypeOptions = Object.keys(CatalogType).map((key) => ({
  id: key,
  name: CatalogType[key as keyof typeof CatalogType].title,
}));

const MCCReport = ({ authId, platform }: IProps) => {
  const { selectedProgram } = useProgramData();

  const [selectedCatalog, setSelectedCatalog] = useState('report_catalog');

  useEffect(() => {
    setSelectedCatalog('report_catalog');
  }, [selectedProgram]);

  return (
    <>
      <Box my={2}>
        <VegaSelect
          options={ReportsTypeOptions}
          onChange={(value) => setSelectedCatalog(value)}
          selected={selectedCatalog}
          textFieldWidth={200}
          size="small"
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '9px',
            color: 'primary',
          }}
        />
      </Box>
      {selectedCatalog === 'report_catalog' ? (
        <ReportBody authId={authId} platform= {platform} />
      ) : (
        <ScheduleHeaders authId={authId} />
      )}
    </>
  );
};

export default MCCReport;
