import React, { useState } from 'react';
import VegaTabBar from '../../../../components/common/v2/VegaTabBar';
import VegaTabBarItem from '../../../../components/common/v2/VegaTabBarItem';
import { Stack, Typography, Box, Grid, Checkbox } from '@mui/material';
import { BLUE, GREY } from '../../../../constants/style';
import { VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import {
  IBulkUploadProps,
  IColumnProps,
} from '../../../types/applicationManagement';
import VegaLabel from '../../../../components/common/VegaLabel';
import { dateFormat } from '../../../variable';
import { toLowerCase } from '../../../../constants/commonFunction';
import { downloadFile } from '../../../utils';

const COLUMN = [
  {
    key: 'filename',
    header: 'File Name',
    flex: 1,
  },
  {
    key: 'uploadDate',
    header: 'Upload Date',
    flex: 1,
  },
  {
    key: 'totalEntries',
    header: 'Entries',
    flex: 1,
  },
  {
    key: 'download',
    header: 'Download',
    flex: 1,
  },
];

interface IProps {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  selectedTab: number;
  bulkUploadData: IBulkUploadProps;
  onSaveFile: (file: string) => void;
}

const ReviewEntity = ({
  setSelectedTab,
  selectedTab,
  bulkUploadData,
  onSaveFile,
}: IProps) => {
  return (
    <>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent="space-between"
        alignItems={'center'}
        style={{ paddingRight: 8 }}
      >
        <VegaTabBar
          value={selectedTab}
          color={BLUE.lighter}
          onChange={(e, selected) => {
            setSelectedTab(selected);
          }}
        >
          <VegaTabBarItem color={BLUE.lighter} label="All"></VegaTabBarItem>
          <VegaTabBarItem color={BLUE.lighter} label="Passed"></VegaTabBarItem>
          <VegaTabBarItem color={BLUE.lighter} label="Failed"></VegaTabBarItem>
        </VegaTabBar>

        {/* <VegaPrimaryButton onClick={() => {}} text={'Submit'} size="small" /> */}
      </Stack>
      {/* <VegaTable
        data={getTableComponent().data}
        columnDef={getTableComponent().column}
        idColumn={getTableComponent().idColumn}
        loading={getTableComponent().loading}
      /> */}
      <Box sx={{ pb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            bgcolor: GREY.light,
          }}
        >
          {COLUMN.filter((c)=> !(selectedTab === 0 && c.key === 'download')
          ).map((column: IColumnProps) => (
            <Box
              sx={{
                flex: column.flex,
                py: 2,
                px: 2,
                fontWeight: 600,
                color: BLUE.dark,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {toLowerCase(column.header)}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {COLUMN.map((column: IColumnProps) => {
            // const kycURL = getURL(value.kycUrl);

            return (
              <>
                {selectedTab === 0 ? (
                  <Box
                    sx={{
                      flex: column.flex,
                      py: 2,
                      px: 2,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {column.key === 'filename' ? (
                      <Typography>{bulkUploadData.filename}</Typography>
                    ) : column.key === 'uploadDate' ? (
                      <Typography>
                        {dateFormat(bulkUploadData.uploadDate)}
                      </Typography>
                    ) : column.key === 'totalEntries' ? (
                      <Typography>{bulkUploadData.totalEntries}</Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                ) : selectedTab === 1 ? (
                  <Box
                    sx={{
                      flex: column.flex,
                      py: 2,
                      px: 2,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {column.key === 'filename' ? (
                      <Typography>
                        {bulkUploadData.validEntriesFileName}
                      </Typography>
                    ) : column.key === 'uploadDate' ? (
                      <Typography>
                        {dateFormat(bulkUploadData.uploadDate)}
                      </Typography>
                    ) : column.key === 'totalEntries' ? (
                      <Typography>
                        {bulkUploadData.validEntriesCount}
                      </Typography>
                    ) : column.key === 'download' ? (
                      <VegaContainedButton isPurple text='Download' onClick={() => downloadFile(bulkUploadData.validEntriesFileUrl, bulkUploadData.validEntriesFileName)}/>
                    ) : (
                      ''
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      flex: column.flex,
                      py: 2,
                      px: 2,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {column.key === 'filename' ? (
                      <Typography>
                        {bulkUploadData.invalidEntriesFileName}
                      </Typography>
                    ) : column.key === 'uploadDate' ? (
                      <Typography>
                        {dateFormat(bulkUploadData.uploadDate)}
                      </Typography>
                    ) : column.key === 'totalEntries' ? (
                      <Typography>
                        {bulkUploadData.invalidEntriesCount}
                      </Typography>
                    ) : column.key === 'download' ? (
                      <VegaContainedButton isPurple text='Download' onClick={() => downloadFile(bulkUploadData.invalidEntriesFileUrl, bulkUploadData.invalidEntriesFileName)}/>
                    ) : (
                      ''
                    )}
                  </Box>
                )}
              </>
            );
          })}
        </Box>
      </Box>
      {selectedTab === 1 && (
        <Box textAlign={'end'}>
          <VegaContainedButton
            text="Add profile"
            isPurple
            disabled={bulkUploadData.validEntriesCount === 0}
            onClick={() => onSaveFile(bulkUploadData.validEntriesFileUrl)}
          />
        </Box>
      )}
    </>
  );
};

export default ReviewEntity;
