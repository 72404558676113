import { Box, Stack } from '@mui/material';
import InclusionExclusionTabPanel from './components/InclusionExclusion/InclusionExclusionTabPanel';
import ContributionTabPanel from './components/Contribution/ContributionTabPanel';
import FinancialTabPanel from './components/Financial/FinancialTabPanel';
import RewardsTabPanel from './components/Rewards/RewardsTabPanel';
import { useEffect, useState } from 'react';
import VegaTextV2 from '../../../../../common/v2/VegaTextV2';
import RuleTabs from './components/RuleTabs';
import { useCampaignRule } from '../../../../providers/CampaignRuleProvider';
import { CampaignRuleType } from '../../../../../../types/RewardsLoyalty';

enum CampaignRuleTab {
  Rewards = 0,
  InclusionExclusion = 1,
  Contribution = 2,
  Financial = 3,
}
const RuleTypeConfig = () => {
  const { selectedRuleType } = useCampaignRule();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [acutualSelectedTab, setActualSelectedTab] = useState<CampaignRuleTab>(
    CampaignRuleTab.Rewards
  );

  const isInclusionExclusionTabVisible = () => {
    const isTransactional = selectedRuleType == CampaignRuleType.TRANSACTIONAL;
    const isMilestone = selectedRuleType == CampaignRuleType.MILESTONE;
    return isTransactional || isMilestone;
  };

  useEffect(() => {
    setActualSelectedTab(getActualTab(selectedTab));
  }, [selectedTab]);

  function getActualTab(selectedTab: number) {
    let tab: CampaignRuleTab = CampaignRuleTab.Rewards;
    if (isInclusionExclusionTabVisible()) {
      switch (selectedTab) {
        case 0:
          tab = CampaignRuleTab.Rewards;
          break;
        case 1:
          tab = CampaignRuleTab.InclusionExclusion;
          break;
        case 2:
          tab = CampaignRuleTab.Contribution;
          break;
        case 3:
          tab = CampaignRuleTab.Financial;
          break;
      }
    } else {
      switch (selectedTab) {
        case 0:
          tab = CampaignRuleTab.Rewards;
          break;
        case 1:
          tab = CampaignRuleTab.Contribution;
          break;
        case 2:
          tab = CampaignRuleTab.Financial;
          break;
      }
    }
    return tab;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Stack direction={'row'}>
        <Box
          style={{
            width: '232px',
            height: '508px',
            background: '#FFFFFF',
            borderRadius: '12px',
            paddingTop: '12px',
            paddingBottom: '12px',
            marginRight: '26px',
          }}
        >
          <RuleTabs
            onTabSelect={function (value: number): void {
              setSelectedTab(value);
            }}
          />
        </Box>
        <div style={{ flex: 1 }}>
          <VegaTextV2 style={{ marginBottom: '24px' }}>
            {getTabName(acutualSelectedTab)}
          </VegaTextV2>
          {acutualSelectedTab == CampaignRuleTab.Rewards && <RewardsTabPanel />}
          {acutualSelectedTab == CampaignRuleTab.InclusionExclusion && (
            <InclusionExclusionTabPanel />
          )}
          {acutualSelectedTab == CampaignRuleTab.Contribution && (
            <ContributionTabPanel />
          )}
          {acutualSelectedTab == CampaignRuleTab.Financial && (
            <FinancialTabPanel />
          )}
        </div>
      </Stack>
    </Box>
  );
};

export default RuleTypeConfig;

const getTabName = (selectedIndex: number): string => {
  if (selectedIndex == CampaignRuleTab.Rewards) return 'Rewards';
  else if (selectedIndex == CampaignRuleTab.InclusionExclusion)
    return 'Inclusion/Exclusion';
  else if (selectedIndex == CampaignRuleTab.Contribution) return 'Contribution';
  else if (selectedIndex == CampaignRuleTab.Financial) return 'Financial';
  return '';
};
