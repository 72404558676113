import { Money } from '@mui/icons-material';
import { Box, Grid, InputAdornment } from '@mui/material';
import _ from 'lodash';
import { IterationType } from '../../../../../../../../../types/RewardsLoyalty';
import VegaCardV2 from '../../../../../../../../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../../../../../../common/v2/VegaFormInputField';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../../../../../../../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../../../../../../../common/v2/VegaTextV2';
import { MoneyIcon, RuppesIcon } from '../../../../../../../../icon/Icons';
import {
  RewardsInputType,
  useCampaignRule,
} from '../../../../../../../providers/CampaignRuleProvider';

const PointBasedTransactionRewards = () => {
  const { rewardsData, onRuleRewardsDataChange } = useCampaignRule();

  return (
    <VegaCardV2>
      <Grid container spacing={3} columns={1}>
        <Grid item xs={1}>
          <Grid container alignItems={'end'} spacing={2} columns={4}>
            <Grid item xs={2}>
              <Grid container columns={5}>
                <Grid item xs={2.25}>
                  <VegaFormInputFieldV2 label="Reward Points">
                    <VegaTextFieldV2
                      placeholder="Enter Reward Points"
                      type={'number'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MoneyIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={
                        rewardsData.rewardPoints != null
                          ? rewardsData.rewardPoints
                          : ''
                      }
                      onChange={(e) => {
                        onRuleRewardsDataChange({
                          inputType: RewardsInputType.REWARD_POINTS,
                          value: e.target.value,
                        });
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
                <Grid
                  item
                  xs={0.5}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent="center"
                >
                  <Box style={{ marginTop: '16px' }}>
                    <VegaTextV2 text="Per" variant="caption" />
                  </Box>
                </Grid>
                <Grid item xs={2.25}>
                  <VegaFormInputFieldV2 label="Enter Amount">
                    <VegaTextFieldV2
                      placeholder="Enter Amount"
                      type={'number'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RuppesIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={
                        rewardsData.perAmount != null
                          ? rewardsData.perAmount
                          : ''
                      }
                      onChange={(e) => {
                        onRuleRewardsDataChange({
                          inputType: RewardsInputType.PER_AMOUNT,
                          value: e.target.value,
                        });
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Grid container alignItems={'end'} spacing={2} columns={4}>
            <Grid item xs={1}>
              <VegaFormInputFieldV2 label="Maximum Points">
                <VegaTextFieldV2
                  placeholder="Enter Maximum Points"
                  type={'number'}
                  value={
                    rewardsData.maximumPoints != null
                      ? rewardsData.maximumPoints
                      : ''
                  }
                  onChange={(e) => {
                    onRuleRewardsDataChange({
                      inputType: RewardsInputType.MAXIMUM_POINTS,
                      value: e.target.value,
                    });
                  }}
                />
              </VegaFormInputFieldV2>
            </Grid>

            <Grid item xs={1}>
              <VegaFormInputFieldV2 label="Iteration">
                <VegaSelectV2
                  placeholder="Select Iteration"
                  options={iterationOptions()}
                  value={rewardsData.iteration ?? ''}
                  onChange={(e) => {
                    onRuleRewardsDataChange({
                      inputType: RewardsInputType.ITERATION,
                      value: e.target.value as string,
                    });
                  }}
                />
              </VegaFormInputFieldV2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </VegaCardV2>
  );
};

export default PointBasedTransactionRewards;

const iterationOptions = () =>
  Object.values(IterationType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
