import { UploadFile } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BORDER_GRAY, GREY } from '../../../constants/style';
import {
  FileValidator,
  FileValidatorResult,
} from '../../../utils/FileValidator';
import VegaOutlineButton from '../../common/VegaOutlineButton';
import VegaText from '../../common/VegaText';
import Vega_Text from '../../common/v3/Vega_Text';

interface IProps {
  handleChange: (value: any) => void;
  onUpload: () => void;
  fileUrl: string | null;
  customRef: any;
  headerText: string;
  name: string;
  text: string;
  onError: (errorMessage: string | null) => void;
  fileValidator: FileValidator;
}

/**
 * THIS WAS REQUIRED AS BROWSER WAS CACHING IMAGES
 *
 *
 * Adds a timestamp query parameter to an AWS URL, if the URL is from AWS.
 *
 * @param url - The URL to check and modify.
 * @returns The modified URL with a timestamp query parameter, or the original URL if it's not from AWS.
 */
function addTimestampIfAwsUrl(url: string): string {
  const awsUrlPattern = /^https?:\/\/.*\.amazonaws\.com/;
  if (awsUrlPattern.test(url)) {
    const timestamp = Date.now();
    return `${url}?t=${timestamp}`;
  }
  return url;
}

const UploadLogoContainer = ({
  handleChange,
  fileUrl,
  customRef,
  onUpload,
  headerText,
  text,
  name,
  onError,
  fileValidator,
}: IProps) => {
  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (!fileValidator) {
      handleChange(event);
      return;
    }

    if (selectedFile) {
      const validationResult: FileValidatorResult =
        await fileValidator.validateFile(selectedFile);

      if (validationResult.isValid) {
        handleChange(event);
      } else {
        onError(validationResult.errorMessage);
      }
    }
  };

  return (
    <>
      <Vega_Text fontSize={14} fontWeight={500} text={headerText} mb={1} />
      <Box
        sx={{
          border: BORDER_GRAY,
          borderRadius: '9px',
          p: 3,
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Box>
              <input
                type="file"
                accept="image/*"
                id="file"
                name={name}
                hidden
                ref={customRef}
                onChange={handleFileSelect}
              />
              <VegaOutlineButton
                text="Select Logo"
                isPrimary
                startIcon={<UploadFile />}
                onClick={onUpload}
              />

              <Vega_Text
                text={text}
                fontSize={12}
                my={2}
                sx={{ color: GREY.lighter }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                width: '100px',
                height: '100px',
                borderRadius: '6px',
                bgcolor: GREY.light,
              }}
            >
              {fileUrl && (
                <img
                  width={100}
                  height={100}
                  src={addTimestampIfAwsUrl(fileUrl)}
                  style={{ borderRadius: '6px' }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UploadLogoContainer;
