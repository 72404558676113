import { ControllerRenderProps, FieldPath } from 'react-hook-form';

const sanitizePhoneNumber = (phoneNumber: string): string => {
  const sanitizedNumber = phoneNumber?.replace(/[-()\s ]/gi, '');

  return sanitizedNumber?.startsWith('+91')
    ? sanitizedNumber?.slice(3)
    : sanitizedNumber?.length < 10
    ? sanitizedNumber
    : sanitizedNumber?.slice(sanitizedNumber?.length - 10);
};

function useMobileNumber<
  TFieldValues extends Record<string, any>,
  TName extends FieldPath<TFieldValues>
>() {
  const onChange = (
    e: any,
    field: ControllerRenderProps<TFieldValues, TName>
  ) => {
    const parsedNumber = parseInt(e.target.value);
    const phone = isNaN(parsedNumber) ? '' : parsedNumber.toString();
    const value = sanitizePhoneNumber(phone.substring(0, 10));
    if (value !== field.value) {
      field.onChange({
        ...e,
        target: {
          ...e.target,
          value,
        },
      });
    }
  };

  return {
    onChange,
  };
}

export default useMobileNumber;
