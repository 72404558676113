import { Typography } from '@mui/material';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import {
  IBinAllocationOrderListProps,
  IBinAllocationOrderProps,
} from '../../../types/Bin';
import { ISectionProps } from '../../pages/MCCUserManagement';
import OrderInventory from './OrderInventory';

import { useClientAuth } from '../../../../providers/ClientProvider';

interface IProps {
  active: ISectionProps;
  data: IBinAllocationOrderListProps[];
  loading: boolean;
  onSendChecker: (data: IBinAllocationOrderProps) => void;
  onUpdateSendChecker: (data: IBinAllocationOrderProps) => void;
  handleUpdate: (data: IBinAllocationOrderProps) => void;
}

const DEFAUT_DATA = {
  id: '',
  binId: '',
  binEntityType: 'BRANCH',
  numberOfKits: '',
};

const ListOfBinAllocationOrder = ({
  active,
  data,
  loading,
  onSendChecker,
  onUpdateSendChecker,
  handleUpdate,
}: IProps) => {
  const { mccPlatformId: branchId } = useClientAuth();

  const [open, setOpen] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [showData, setShowData] = useState<IBinAllocationOrderProps>({
    ...DEFAUT_DATA,
    entityId: branchId,
  });

  const getButtons = (data: IBinAllocationOrderListProps) => {
    if (data.binAllocationOrderStatus === 'DRAFT') {
      return {
        action: (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => onSendChecker(data)}
            isPrimary
          />
        ),
        viewEdit: (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(data, false)}
            isPrimary
          />
        ),
      };
    }
    if (
      data.binAllocationOrderStatus === 'UNDER_REVIEW' &&
      data.makerCheckerStatus === 'CHECKER_PENDING'
    ) {
      return {
        action: (
          <VegaContainedButton text="Sent to Checker" isPrimary disabled />
        ),
        viewEdit: (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(data, true)}
            isPrimary
          />
        ),
      };
    }
    if (
      data.binAllocationOrderStatus === 'UNDER_REVIEW' &&
      data.makerCheckerStatus === 'CHANGES_REQUESTED'
    ) {
      return {
        action: (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => onUpdateSendChecker(data)}
            isPrimary
          />
        ),
        viewEdit: (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(data, false)}
            isPrimary
          />
        ),
      };
    }
  };

  const INVENTORY_COLUMN = [
    {
      field: 'binId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bin No
        </Typography>
      ),
      flex: 1,
    },
    // {
    //   field: 'programName',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Program Name</Typography>,
    //   flex: 1,
    // },
    {
      field: 'numberOfKits',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          No. of Kit
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'action',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Action
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return getButtons(props.row)?.action;
      },
    },
    {
      field: 'action2',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          View/Edit
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return getButtons(props.row)?.viewEdit;
      },
    },
  ];

  const showDetails = (data: any, show: boolean) => {
    setIsShow(show);
    setOpen(!open);
    setShowData(data);
  };

  const onChange = (name: string, value: any) => {
    setShowData({ ...showData, [name]: value });
  };

  const onClose = () => {
    setOpen(!open);
  };

  const onSubmit = async () => {
    await handleUpdate(showData);
    onClose();
  };
  return (
    <VegaCard fullHeight noPad>
      <VegaTable data={data} columnDef={INVENTORY_COLUMN} idColumn={'id'} />
      <VegaDrawer
        open={open}
        isClose
        isSubmit={!isShow}
        header={'Bin Details'}
        closeText={isShow ? 'Got it !!' : 'Cancel'}
        submitText={'Submit'}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <OrderInventory
          state={showData}
          handleChange={onChange}
          isShow={isShow}
        />
      </VegaDrawer>
    </VegaCard>
  );
};

export default ListOfBinAllocationOrder;
