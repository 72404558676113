import { DASHBOARD_ROUTES } from '../routes';

export const URL = {
  CLIENT: 'www.client.vegapay.tech',
  PROGRAM: 'www.program.vegapay.tech',
  PROTOTYPE: 'www.simulator.vegapay.tech',
  BACK_OFFICE: 'www.back-office.vegapay.tech',
  DEV: 'www.dev.vegapay.tech',
  DEV_BASE: 'dev.vegapay.tech',
  CURRENT: window.location.hostname,
  LOCAL: 'localhost',
  FOREX_BRANCH: 'www.branch.vegapay.tech',
  FOREX_CORPORATE: 'www.corporate.vegapay.tech',
  CIMS: 'sbm.vegapay.tech',
  CIMS_UAT: 'sbmcms-uat.esbeeyem.com',
  CIMS_UAT_CLOUDFRONT: 'do89ufvhoxvyf.cloudfront.net',
  CIMS_ALPHA: 'cims-dev.vegapay.tech',
  CIMS_PROD: 'sbmcms.sbmbank.co.in',
};

export const HOSTNAME = {
  CLIENT: 'client.vegapay.tech',
  PROGRAM: 'program.vegapay.tech',
  PROTOTYPE: 'simulator.vegapay.tech',
  BACK_OFFICE: 'back-office.vegapay.tech',
  DEV: 'dev.vegapay.tech',
  DEV_BASE: 'dev.vegapay.tech',
  CURRENT: window.location.hostname,
  LOCAL: 'localhost',
  FOREX_BRANCH: 'branch.vegapay.tech',
  FOREX_CORPORATE: 'corporate.vegapay.tech',
};

export const ACCESS_MAP: any = {
  'www.client.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'client.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'www.program.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'program.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'www.simulator.vegapay.tech': DASHBOARD_ROUTES.SIMULATOR,
  'simulator.vegapay.tech': DASHBOARD_ROUTES.SIMULATOR,
  'www.back-office.vegapay.tech': DASHBOARD_ROUTES.BACK_OFFICE,
  'back-office.vegapay.tech': DASHBOARD_ROUTES.BACK_OFFICE,
  'www.dev.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'dev.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'branch.vegapay.tech': DASHBOARD_ROUTES.MCC_BACK_OFFICE,
  'www.branch.vegapay.tech': DASHBOARD_ROUTES.MCC_BACK_OFFICE,
  'corporate.vegapay.tech': DASHBOARD_ROUTES.MCC_CORPORATE,
  'www.corporate.vegapay.tech': DASHBOARD_ROUTES.MCC_CORPORATE,
  'sbm.vegapay.tech': DASHBOARD_ROUTES.CIMS,
  'sbmcms-uat.esbeeyem.com': DASHBOARD_ROUTES.CIMS,
  'sbmcms.sbmbank.co.in': DASHBOARD_ROUTES.CIMS,
  'do89ufvhoxvyf.cloudfront.net': DASHBOARD_ROUTES.CIMS,
  'cims-dev.vegapay.tech': DASHBOARD_ROUTES.CIMS,
  localhost: DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  // localhost: DASHBOARD_ROUTES.SIMULATOR,
};

export const DATE_FORMATE = 'DD-MM-YYYY';

export type DashboardURLs = keyof typeof URL;
export const getFormattedHostname = () => {
  const { hostname } = window.location;
  const host = hostname.replace(/www./gi, '');
  return host;
};
export const currentHost = getFormattedHostname();

export const isDev =
  currentHost === URL.DEV ||
  currentHost === URL.DEV_BASE ||
  currentHost === URL.CIMS_ALPHA ||
  currentHost === URL.LOCAL;

export const isLocalPath = URL.CURRENT === URL.LOCAL;

export const isCurrentPathClient = URL.CURRENT === URL.CLIENT;

export const isCurrentPathPrototype = URL.CURRENT === URL.PROTOTYPE;

export const isCurrentPathProgram = URL.CURRENT === URL.PROGRAM;

export const isCurrentPathBackOffice = URL.CURRENT === URL.BACK_OFFICE;

export const isCurrentPathCims =
  URL.CURRENT === URL.CIMS ||
  URL.CURRENT === URL.CIMS_UAT ||
  URL.CURRENT === URL.CIMS_UAT_CLOUDFRONT ||
  URL.CURRENT === URL.CIMS_ALPHA ||
  ACCESS_MAP[URL.CURRENT] === DASHBOARD_ROUTES.CIMS;

export const isCimsUAT =
  URL.CURRENT === URL.CIMS_UAT || URL.CURRENT === URL.CIMS_UAT_CLOUDFRONT;

export const isCimsProd = URL.CURRENT === URL.CIMS_PROD;

export const getUrl = (hostname: DashboardURLs) => {
  return `https://${URL[hostname]}`;
};
