/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toLowerCase } from '../../../../constants/commonFunction';
import { EntityStatusEnum } from '../../../../enums/commons';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { EditFormFiled } from '../../../../components/common/deprecated/EditFormFiled';
import { LosSelectOption } from '../../../../components/common/deprecated/LosSelect';
import { Level, LevelEntityMapping } from '../../../../types/client';
import { EntityService } from '../../../apis';
import { DivisionService } from '../../../../api/division';
import { DivisionType } from '../../../../types/division';

interface IProps {
  entityName: string;
  entityLevel?: Level;
  entities: LosSelectOption[];
}

function SelectEntity({ entityName, entities }: IProps) {
  const { clientId, userDivisionId, userDivisionType } = useClientAuth();

  const hookForm = useFormContext<{
    entity: {
      entityId: string;
      entityLevel: string;
    };
  }>();
  const entityLevel = hookForm.watch('entity.entityLevel');

  const divisionQuery = useQuery({
    queryKey: ['fetchDivisions', { clientId, entityLevel }],
    queryFn: () =>
      DivisionService.fetchChildDivisions({
        divisionType: entityLevel as DivisionType,
        divisionId: userDivisionId ?? '',
        includeItself: false,
        pageSize: 100,
      }),
    enabled: !!clientId && !!entityLevel,
    select(data) {
      return data.records.map((division) => ({
        value: division.id,
        label: `${division.divisionCode.toUpperCase()} ( ${division.name} )`,
      }));
    },
  });

  return (
    <Stack spacing={2}>
      <Controller
        name="entity.entityLevel"
        control={hookForm.control}
        rules={{ required: true }}
        render={({ field }) => (
          <EditFormFiled
            title={'DIVISION TYPE'}
            description={field.value}
            onChange={(val) => {
              field.onChange(val);
              hookForm.setValue('entity.entityId', '');
            }}
            placeHolder={'Select Division type'}
            options={getDivisionTypeOptions(userDivisionType) || []}
          />
        )}
      />

      {entityLevel && (
        <Controller
          name="entity.entityId"
          control={hookForm.control}
          rules={{ required: true }}
          render={({ field }) => (
            <EditFormFiled
              title={entityName}
              description={field.value}
              onChange={(val) => {
                field.onChange(val);
              }}
              placeHolder={'Select ' + toLowerCase(entityName)}
              options={divisionQuery.data ?? []}
            />
          )}
        />
      )}

      {/* {entityLevel === Level.l2 && (
        <Box>
          <VegaHeader text={'Branch'} />
          <Controller
            name="entity.entityId"
            control={hookForm.control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CIMS_SearchableDropdown
                  options={entityQuery.data}
                  placeholder={'Select ' + toLowerCase(entityName)}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                />
              );
            }}
          />
        </Box>
      )} */}
    </Stack>
  );
}

export default SelectEntity;

const getEntityTypeOptions = (
  levelEntityMapping: LevelEntityMapping,
  currentLevel: Level
) => {
  switch (currentLevel) {
    case Level.CLIENT:
      return [Level.l1, Level.l2].map((level) => ({
        label: toLowerCase(levelEntityMapping[level]),
        value: level as string,
      }));

    case Level.l1:
      return [Level.l2].map((level) => ({
        label: toLowerCase(levelEntityMapping[level]),
        value: level as string,
      }));

    default:
      return [];
  }
};

const getDivisionTypeOptions = (divisionType?: DivisionType) => {
  switch (divisionType) {
    case 'CLIENT':
      return (['BUSINESS_CORRESPONDENT', 'BC_AGENT'] as DivisionType[]).map(
        (type) => ({
          label: toLowerCase(type),
          value: type,
        })
      );
    case 'BUSINESS_CORRESPONDENT':
      return (['BC_AGENT'] as DivisionType[]).map((type) => ({
        label: toLowerCase(type),
        value: type,
      }));

    default:
      return [];
  }
};
