import { RewardsRoute } from '../../routes';
import BenefitsInventory from './components/BenefitsInventory';
import CampaignConfiguration from './components/CampaignConfiguration/CampaignConfiguration';
import RewardHome from './components/RewardHome';
import { VoucherInventoryProvider } from '../../components/RewardsLoyalty/providers/VoucherInventoryProvider';
import { useRewardsNavigation } from '../../providers/RewardsNavigationProvider';

const RewardsLoyalty = () => {
  const { navigateTo, currentRoute } = useRewardsNavigation();

  if (currentRoute == RewardsRoute.CAMPAIGN_CONFIGURATION) {
    return (
      <VoucherInventoryProvider>
        <CampaignConfiguration />
      </VoucherInventoryProvider>
    );
  }

  if (currentRoute == RewardsRoute.BENEFIT_INVENTORY) {
    return <BenefitsInventory />;
  }

  if (currentRoute == RewardsRoute.REWARDS_HOME) {
    return <RewardHome />;
  }

  navigateTo(RewardsRoute.REWARDS_HOME);

  return <div>-</div>;
};

export default RewardsLoyalty;
