import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { Tax, TaxPolicyInterface } from '../../../types/policy';
import type { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import ProgramDataGrid from './ProgramDataGrid';
import { useAppSelector } from '../../../store/hook';
import { convertCamelCase } from '../../../utils/stringUtils';

const getTaxPolicyTableColumns = (): GridColDef<Tax>[] => [
  {
    field: 'name',
    headerName: 'Position Type',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'value_type',
    headerName: 'Interest Rate Value Type',
    flex: 1,
    sortable: false,
    minWidth: 180,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      convertCamelCase(params.value),
  },
  {
    field: 'value',
    headerName: 'Default APR',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
];

function ViewTaxPolicy() {
  const columnsRef = useMemo(() => getTaxPolicyTableColumns(), []);

  const activePolicy = useAppSelector(
    (state) => state.programManagement.activePolicy
  ) as TaxPolicyInterface;

  return (
    <Box
      sx={{
        p: {
          xs: 1,
          sm: 3,
        },
        flex: 1,
      }}
    >
      {activePolicy?.tax_components?.map((component) => (
        <ProgramDataGrid
          data={component.taxes}
          columns={columnsRef}
          idColumn={'name'}
        />
      ))}
    </Box>
  );
}

export default ViewTaxPolicy;
