import { Box, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ChargebackDto } from '../../types/chargeBack';
import { VPButton } from '../../ui-components/common';
import VegaHeader from '../common/VegaHeader';
import { StatusDisplayText } from './StatusChangeCards/StatusChangeCards';
import { useChargeBack } from './hooks';

interface IProps {
  pastRequests: ChargebackDto[];
}
export const PastChargeBackRequest = ({ pastRequests }: IProps) => {
  const { selectedChargeBack, setSelectedChargeBack } = useChargeBack();

  const sortedInDescOrder = pastRequests.sort((a, b) =>
    dayjs(b.createdAt).diff(dayjs(a.createdAt))
  );

  if (
    sortedInDescOrder.length < 2 ||
    sortedInDescOrder[1]?.id === selectedChargeBack?.id
  ) {
    return <></>;
  }
  const onClickHandle = () => {
    setSelectedChargeBack(pastRequests[1]);
  };
  return (
    <Box
      bgcolor={'rgba(252, 248, 230, 1)'}
      borderColor={'rgba(228, 200, 57, 1)'}
      borderRadius={3}
      p={2}
    >
      <Stack spacing={0.5}>
        <Typography
          fontSize={14}
          fontWeight={500}
          color={'rgba(27, 29, 34, 1)'}
        >
          Past requests
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <VegaHeader text="ChargeBack Id" />
              <Typography noWrap>{sortedInDescOrder[1]?.id || '--'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <VegaHeader text="status" />
                <StatusDisplayText
                  status={sortedInDescOrder[1].chargebackStatus}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <VegaHeader text="Raised on" />
                <Typography>
                  {sortedInDescOrder[1].createdAt || '--'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <VPButton variant="text" onClick={onClickHandle}>
                  View past requests
                </VPButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
