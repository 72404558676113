import axios from 'axios';
import { CampaignRule } from '../types/RewardsLoyalty';

const ENDPOINTS = {
  BASE: '/rule',
  CREATE: '/',
  UPDATE: '/',
};

const BASE_RULE_URL = ENDPOINTS.BASE;
export class CampaignRuleService {
  static async createRule(rule: CampaignRule): Promise<CampaignRule> {
    try {
      const endpoint = BASE_RULE_URL + ENDPOINTS.CREATE;
      const response = await axios.post(endpoint, rule);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateRule(rule: CampaignRule): Promise<CampaignRule> {
    try {
      const endpoint = BASE_RULE_URL + ENDPOINTS.UPDATE;
      const response = await axios.put(endpoint, rule);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
