/** @format */

import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import { BACKGROUND } from '../../constants/style';
import VegaTabs from './VegaTabs';

export type VegaHorizontalSelectorDataType = {
  image?: ReactNode;
  text: string;
  id: string;
  bgColor?: string;
  labelText?: string;
  isLabel?: boolean;
  endIcon?: ReactNode;
};

type Props = {
  onChange: (item: VegaHorizontalSelectorDataType) => void;
  selected?: VegaHorizontalSelectorDataType;
  data: VegaHorizontalSelectorDataType[];
  hideScroll?: boolean;
  hideArrow?: boolean;
  ignoreCaseForLodash?: string[];
};

const VegaHorizontalSelector = ({
  data,
  selected,
  onChange,
  hideScroll,
  hideArrow,
  ignoreCaseForLodash = [],
}: Props) => {
  const tileRef = useRef<any>(null);
  const scrollContainer = useRef<any>(null);
  const [count, setCount] = useState<any>(0);
  console.log('VegaHorizontalSelector', data);
  // const isFirstSelected = data[0].id === selected?.id;
  // const isLastSelected = data[data.length - 1].id === selected?.id;

  const lastIndex = count + 4;
  const firstIndex = lastIndex - 4;
  const currentData = data.slice(count, lastIndex);

  const handleScroll = (move: 'RIGHT' | 'LEFT') => {
    const currentIndex = data.findIndex(
      (element) => element.id === selected?.id
    );
    if (move === 'RIGHT') {
      setCount(count + 1);

      // const newData = data.slice(count, 4 + count);
      // setNewVal(newData);
      // const newSelected = data[currentIndex + 1];
      scrollContainer.current.scrollLeft += 230;
      // onChange({ ...newSelected });
      return;
    }
    if (move === 'LEFT') {
      setCount(count - 1);
      // const newData = data.slice(count, 4 - count);

      // setNewVal(newData);
      // const newSelected = data[currentIndex - 1];
      scrollContainer.current.scrollLeft += -200;
      // onChange({ ...newSelected });
      return;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={2} xs={12} md={11}>
        {currentData.map((tile: any, index: any) => (
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <VegaTabs
              ignoreCase={ignoreCaseForLodash.includes(tile.text)}
              ref={tileRef}
              onChange={() => onChange({ ...tile })}
              value={tile.text}
              id={tile.text}
              bg={tile.bgColor}
              active={selected?.text}
              icon={tile.image}
              isLabel={tile?.isLabel}
              lableText={tile?.labelText}
              activeBg={BACKGROUND.LIGHT_BLUE}
              endIcon={tile.endIcon}
            />
          </Grid>
        ))}
      </Grid>
      {!hideArrow && data.length > 4 && (
        <Grid
          item
          xs={12}
          md={1}
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            color="primary"
            disabled={count === 0}
            onClick={() => handleScroll('LEFT')}
          >
            <ArrowCircleLeftOutlined />
          </IconButton>
          <IconButton
            color="primary"
            disabled={count + 4 > data.length - 1}
            onClick={() => handleScroll('RIGHT')}
          >
            <ArrowCircleRightOutlined />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default VegaHorizontalSelector;
