export interface MccCode {
  code: number;
  description: string;
  category: Mcg;
  paymentBrands: string[];
}

export interface Merchant {
  id: string;
  brandName: string;
  name: string;
  logo: string;
  mids: MerchantMid[];
  tids: MerchantTid[];
  mccCodes: MccCode[];
}

export interface MerchantMid {
  id: string;
  mid: number;
}

export interface MerchantTid {
  id: string;
  tid: number;
}

export enum MccCategory {
  AGRICULTURAL_SERVICES = 'AGRICULTURAL_SERVICES',
  CONTRACTED_SERVICES = 'CONTRACTED_SERVICES',
  TRANSPORTATION_SERVICES = 'TRANSPORTATION_SERVICES',
  UTILITY_SERVICES = 'UTILITY_SERVICES',
  RETAIL_OUTLET_SERVICES = 'RETAIL_OUTLET_SERVICES',
  CLOTHING_STORES = 'CLOTHING_STORES',
  MISCELLANEOUS_STORES = 'MISCELLANEOUS_STORES',
  BUSINESS_SERVICES = 'BUSINESS_SERVICES',
  PROFESSIONAL_SERVICES_AND_MEMBERSHIP_ORGANIZATIONS = 'PROFESSIONAL_SERVICES_AND_MEMBERSHIP_ORGANIZATIONS',
  GOVERNMENT_SERVICES = 'GOVERNMENT_SERVICES',
  AIRLINES = 'AIRLINES',
  CAR_RENTAL = 'CAR_RENTAL',
  LODGING = 'LODGING',
}

export interface Mcg {
  id: string;
  name: string;
  riskProfile: RiskProfiles;
}

export enum RiskProfiles {
  HIGH_RISK = 'HIGH_RISK',
  LOW_RISK = 'LOW_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
}
