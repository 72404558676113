import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { getIsTrue } from '../../../../../constants/commonFunction';
import { BORDER_GRAY } from '../../../../../constants/style';
import { INewPLanTypeProps, IPLanTypeProps } from '../../../../types/plan';
import { IPlanListProps } from '../MarkupFee/MarkupFee';
import PlanPoliciesCard from '../PlanPoliciesCard';
import ChargesCard from './components/ChargesCard';
import ListOfCharges from './components/ListOfCharges';
import { NoResults } from '../../../../../components/common';
import { useClientAuth } from '../../../../../providers/ClientProvider';

export interface ICharges {
  valueType: string;
  value: number;
}

export interface IFeeDetailsProps {
  taxType: string;
  // frequency: string;
  chargeValue: any;
}

interface IChareRulesProps {
  [code: string]: IFeeDetailsProps | null;
}

const DEFUALT_PLAN = {
  clientId: '0b9fafc9-0f42-4fb9-89d4-10dccd80c643',
  planName: '',
  planType: 'CHARGES_PLAN',
  planDescription: 'planDescription',
  isDefault: true,
  effectiveStartTime: '2023-04-04T07:13:19.418147Z',
  effectiveEndTime: '2023-04-04T07:13:19.418147Z',
  status: 'ACTIVE',
} as IPLanTypeProps;

const DEFUALT_PLAN2 = {
  policy_type: 'CHARGES_PLAN',
  policy_name: '',
  policy_description: 'planDescription',
  is_default: true,
  start_date: '2023-04-04T07:13:19.418147Z',
  end_date: '2023-04-04T07:13:19.418147Z',
  status: 'ENABLED',
} as INewPLanTypeProps;

const DEFULT_FEES = {
  taxType: 'EXCLUSIVE_OF_TAXES',
  frequency: 'MONTHLY',
  valueType: 'AMOUNT',
  value: 5,
};

const CHARGE_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  FIXED_ALL_CURRENCY: 'FIXED_ALL_CURRENCY',
  FIXED: 'FIXED',
};

export const PERCENTAGE_CHARGES = {
  valueType: 'PERCENTAGE',
  value: 5,
};
export const FIXED_CHARGES = {
  valueType: 'AMOUNT',
  value: {
    USD: 5,
  },
};
export const FIXED_ALL_CHARGES = {
  valueType: 'AMOUNT',
  value: {
    USD: 5,
    INR: 500,
    GBP: 10,
    EUR: 5,
    CAD: 5,
    AUD: 5,
    SGD: 5,
    SAR: 5,
    CHF: 5,
    THB: 5,
    AED: 5,
  },
};

const DEFULT_FEE_WAIVER = {
  taxType: 'EXCLUSIVE_OF_TAXES',
  // frequency: 'MONTHLY',
  chargeValue: {},
};

const CHARGE_RULE = {
  CASH_AT_POS_FEE: null,
  CASH_WITHDRAWAL_FEE: null,
  BALANCE_ENQUIRY_FEE: null,
  DOMESTIC_REPLACEMENT_CARD_FEE: null,
  INTERNATIONAL_REPLACEMENT_CARD_FEE: null,
  CHARGE_SLIP_RETRIEVAL_FEE: null,
  CHARGEBACK_FEE: null,
  INACTIVITY_FEE: null,
  MONTHLY_SMS_FEE: null,
} as IChareRulesProps;

const Charges = ({
  onCreate,
  plans,
  page,
  count,
  onPageChange,
}: IPlanListProps) => {
  const { clientId } = useClientAuth();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [planData, setPlanData] = useState<INewPLanTypeProps>({
    ...DEFUALT_PLAN2,
  });
  const [chargesRules, setChargesRules] = useState<IChareRulesProps>({
    ...CHARGE_RULE,
  });

  const handleChange = (name: string, value: any) => {
    setPlanData({ ...planData, [name]: value });
    if (value.length) {
      setErrorMsg('');
    }
  };

  const getChargeType = (type: string) => {
    if (type === 'FIXED') {
      return FIXED_CHARGES;
    } else if (type === 'FIXED_ALL_CURRENCY') {
      return FIXED_ALL_CHARGES;
    } else return PERCENTAGE_CHARGES;
  };

  const activationToggle = (code: string, type: any) => {
    const current = chargesRules[code];

    const rule = {
      ...DEFULT_FEE_WAIVER,
      chargeType: code,
      chargeValue: getChargeType(type),
    };
    setChargesRules({
      ...chargesRules,
      [code]: !!current ? null : rule,
    });
  };
  const handleChangeValue = (code: string, key: string, value: any) => {
    const edited = {
      ...chargesRules[code],
      [key]: value,
    } as IFeeDetailsProps;
    setChargesRules({ ...chargesRules, [code]: { ...edited } });
  };
  const onCreateCharges = () => {
    const charges: IFeeDetailsProps[] = [];
    const copy = { ...chargesRules };
    Object.keys(copy).map((code) => {
      if (!!copy[code]) {
        charges.push(copy[code] as any);
      }
    });
    const obj = { ...planData, charges, clientId };
    if (planData.policy_name.length) {
      onCreate(obj);
      setPlanData({ ...DEFUALT_PLAN2 });
      setChargesRules({ ...CHARGE_RULE });
    } else {
      setErrorMsg('Please enter plan name');
    }
  };

  useEffect(() => {
    const copy = { ...chargesRules };
    const newArr = Object.keys(copy).map((code) => {
      return copy[code];
    });
    const isNull = getIsTrue(null, newArr);
    if (isNull) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [chargesRules]);
  return (
    <>
      <PlanPoliciesCard
        title="CHARGES_PLAN"
        plan={planData}
        handleChange={handleChange}
        disabled={disabled}
        errorMsg={errorMsg}
        onCreate={onCreateCharges}
      >
        <Box sx={{ borderTop: BORDER_GRAY }}>
          {Object.keys(chargesRules).map((code, i) => {
            const rule = chargesRules[code];
            return (
              <ChargesCard
                key={i}
                rule={rule}
                code={code}
                onActivationClick={activationToggle}
                handleChange={handleChangeValue}
              />
            );
          })}
        </Box>
      </PlanPoliciesCard>
      {plans.length ? (
        <ListOfCharges
          data={plans}
          page={page}
          count={count}
          onPageChange={onPageChange}
        />
      ) : (
        <NoResults text="You have no Charges plan" />
      )}
    </>
  );
};

export default Charges;
