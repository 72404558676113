import { KeyboardArrowLeft } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
} from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/system';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { CIMS_PRIMARY } from '../../cims/assets/style';
// import { SBM_logo } from '../../cims/components/icon/CIMS_SidebarIcons';
import { BORDER_GRAY_DARK } from '../../constants/style';
import { useClientAuth } from '../../providers/ClientProvider';
import { setHeader } from '../../store/common/stateSlice';
import { useAppDispatch } from '../../store/hook';
import { VegaListItem } from '../common';
import GroupListItems from './GroupListItems';
import { SbmLogo } from '../../cims/components/icon/CIMS_Icons';
import CimsHeader from '../header/CimsHeader';

export type SidebarListItemType = {
  id: string;
  component?: ReactNode;
  icon?: string | ReactNode;
  activIcon?: string | ReactNode;
  label?: string | ReactNode;
  description?: string | ReactNode;
  noRender?: boolean;
  isProgramSelector?: boolean;
  isAccountSelector?: boolean;
  isClientRoutes?: boolean;
  hideForNonAdmin?: boolean;
  hideForAdmin?: boolean;
  isSimulatorRoutes?: boolean;
  isProgramRoutes?: boolean;
  isChecker?: boolean;
  isCrmRoutes?: boolean;
  isSetting?: boolean;
  isWhiteBgColor?: boolean;
  sx?: SxProps<Theme>;
  children?: SidebarListItemType[];
  resourceName?: string;
} & (
  | {
      icon: string | ReactNode;
    }
  | {
      label: string | ReactNode;
    }
);

type Props = {
  sidebarListItems: SidebarListItemType[];
  selectedItem?: any;
  setSelectedItem?: any;
};

const SELECTED_VIEW = 'view';
const drawerWidth = 250;
const shrinkedDrawerWidth = 80;

const CIMSSidebarLayout = ({
  sidebarListItems,
  selectedItem,
  setSelectedItem,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isAdmin, client, scope } = useClientAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listItems, setListItems] = useState<SidebarListItemType[]>([
    ...sidebarListItems,
  ]);

  const { pathname } = useLocation();
  const mccPath = pathname.split('/')[1];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const container =
    window !== undefined ? () => window.document.body : undefined;
  const [open, setOpen] = React.useState(false);

  const [showSelector, setShowSelector] = useState<boolean>(true);

  React.useEffect(() => {
    setSearchParams(
      createSearchParams({
        [SELECTED_VIEW]: selectedItem.id,
      })
    );
  }, []);

  const handleDrawerClose = () => {
    const shouldCloseDrawer: boolean = !open;
    setShowSelector(shouldCloseDrawer == false);
    setOpen(shouldCloseDrawer);
  };

  const handleItemClick = (item: SidebarListItemType) => {
    dispatch(setHeader(''));

    // setSelectedItem(item);
    setSearchParams(createSearchParams({ [SELECTED_VIEW]: item.id }));
  };

  const canAccessSubModule = (subModule: string) => {
    // const module = Object.keys(scope.scope)[0] as keyof typeof CIMS_MODULES;
    // // return CIMS_MODULES[module].includes(subModule);
    // return CIMS_MODULES[module].includes(subModule);
    return true;
  };

  const getFilteredListItems = () => {
    const filteredList = sidebarListItems.filter((item) => {
      return canAccessSubModule(item.id);
    });
    if (filteredList.length === 0) return sidebarListItems;
    else return filteredList;
  };

  function findObjectById(
    arr: SidebarListItemType[],
    targetId: string
  ): SidebarListItemType | null {
    for (const obj of arr) {
      if (obj.id === targetId) {
        return obj;
      }

      if (obj.children && obj.children.length > 0) {
        const foundInChildren = findObjectById(obj.children, targetId);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  }

  useEffect(() => {
    setListItems(getFilteredListItems());
  }, []);

  useEffect(() => {
    const selectedId = searchParams.get(SELECTED_VIEW) || '';
    const selectedItem = findObjectById(listItems, selectedId);
    setSelectedItem(selectedItem ? selectedItem : listItems[0]);
  }, [searchParams.get(SELECTED_VIEW)]);

  if (!selectedItem) return <></>;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{
          bgcolor: 'primary.main',
          width: { md: open ? shrinkedDrawerWidth : drawerWidth },
          height: '100vh',
          flexShrink: { md: 0 },
          position: 'relative',
          borderRadius: '0px 12px 12px 0px',
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant={isMobile || isSmallScreen ? 'temporary' : 'permanent'}
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            height: '100vh',
            borderRadius: '0px 0px 12px 0px',
            bgcolor: CIMS_PRIMARY.MAIN,
            '& .MuiDrawer-paper': {
              color: 'text.secondary',
              bgcolor: CIMS_PRIMARY.MAIN,
              boxSizing: 'border-box',
              borderRight: 'none',
              borderRadius: '0px 12px 12px 0px',
              ...(!open
                ? { width: drawerWidth }
                : isMobile || isSmallScreen
                ? { width: drawerWidth }
                : { width: shrinkedDrawerWidth }),
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box>
              <List sx={{ borderBottom: BORDER_GRAY_DARK }}>
                <ListItem
                  sx={{
                    display: 'flex',
                    transition: 'all 500ms ease-in-out',
                    ...(!open
                      ? { px: 5, py: 2, justifyContent: 'space-between' }
                      : isMobile || isSmallScreen
                      ? { px: 5, py: 2, justifyContent: 'space-between' }
                      : { px: 1, py: 2 }),
                  }}
                >
                  {!open ? (
                    <Box onClick={() => handleItemClick(listItems[0])}>
                      <SbmLogo />
                    </Box>
                  ) : isMobile || isSmallScreen ? (
                    <Box onClick={() => handleItemClick(listItems[0])}>
                      <SbmLogo />
                    </Box>
                  ) : (
                    <Box sx={{ height: '25px' }}></Box>
                  )}

                  <IconButton
                    onClick={handleDrawerClose}
                    sx={{
                      bgcolor: CIMS_PRIMARY.ACTIVE_MAIN,
                      ':hover': { bgcolor: CIMS_PRIMARY.ACTIVE_MAIN },
                      mx: open ? 'auto' : 'inherit',
                    }}
                  >
                    <KeyboardArrowLeft
                      fontSize="small"
                      sx={{
                        color: CIMS_PRIMARY.GOLD,
                        transition: '0.75s',
                        ...(!open
                          ? { transform: 'rotate(0deg)' }
                          : { transform: 'rotate(180deg)' }),
                      }}
                    />
                  </IconButton>
                </ListItem>

                {listItems.map((item, index) =>
                  (!isAdmin && item.hideForNonAdmin) ||
                  (isAdmin && item.hideForAdmin) ? (
                    <></>
                  ) : item.children ? (
                    <GroupListItems
                      item={item}
                      index={index}
                      isDrawerOpen={open}
                    />
                  ) : (
                    <VegaListItem
                      key={index}
                      isMobile={isMobile}
                      isSamllScreen={isSmallScreen}
                      open={open}
                      id={item.id}
                      isChecker={item.isChecker}
                      icon={item.icon}
                      activIcon={item.activIcon}
                      label={item.label}
                      description={item.description}
                      selected={searchParams.get(SELECTED_VIEW) === item.id}
                      onItemClick={() => handleItemClick(item)}
                    />
                  )
                )}
              </List>
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          height: '100vh !important',
          // overflowX: 'scroll',
          position: 'relative',
          bgcolor: '#F7F7F7',
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },

          // scrollbarWidth: 'thin',
          // scrollbarColor: 'transparent transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
          },
        }}
      >
        <CimsHeader
          setOpen={setOpen}
          isSmallScreen={isSmallScreen}
          isMobile={isMobile}
        />
        <Box px={0} pb={0}>
          {selectedItem.component}
        </Box>
      </Box>
    </Box>
  );
};

export default CIMSSidebarLayout;
