import {
  Grid,
  Stack,
  SxProps,
  Theme,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { ReactNode } from 'react';
import Vega_Text from './Vega_Text';

type Props = {
  title?: string;
  renderLeftView?: () => ReactNode;
  renderRightView?: () => ReactNode;
  sx?: SxProps<Theme>;
};
const Vega_PageHeader = ({
  title,
  renderLeftView,
  renderRightView,
  sx,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultSxStyles = {
    paddingTop: '1rem',
    paddingRight: '1.5rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',
    borderBottom: '1px solid #DBDBDB',
    bgcolor: 'transparent',
    minHeight: '4rem',
  };

  const mergedSxStyles = { ...defaultSxStyles, ...sx };

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent={'space-between'}
      alignItems="center"
      sx={mergedSxStyles}
    >
      <Stack direction={'row'} alignItems="center" flex={1}>
        {title && (
          <Vega_Text
            text={title}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        )}
        {renderLeftView?.()}
      </Stack>
      {renderRightView?.()}
    </Stack>
  );
};

export default Vega_PageHeader;
