import { Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';

import { VegaCard, VegaTable } from '../../../../../components/common';
import VegaContainedButton from '../../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../../components/common/VegaDrawer';
import VegaLabel from '../../../../../components/common/VegaLabel';
import { getLogoForNetwork } from '../../../../../components/icon/Icons';
import {
  CardRefundOrderStatus,
  CardRefundType,
  PaymentMode,
} from '../../../../../enums/card';
import { CheckerMakerStatusEnum } from '../../../../../enums/commons';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import {
  CreateCardRefundOrderReq,
  ICardRefundOrder,
} from '../../../../../types/cardRefund';
import { getCustomerName } from '../../../../../utils/common.utils';
import {
  initiateCardRefundMakerChecker,
  updateCardRefundMakerChecker,
  updateCardRefundOrder,
} from '../../../api/transaction';
import CardRefundDetails from '../Components/CardRefundDetails';

interface IProps {
  cardRefundData: ICardRefundOrder[];
  getRefundList: () => void;
}

function CardRefundList({ cardRefundData, getRefundList }: IProps) {
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showData, setShowData] = useState<Partial<ICardRefundOrder>>({});

  const showDetails = (data: ICardRefundOrder, isShow: boolean) => {
    const { accountConsolidatedDto, ...rest } = data;
    setShowData({
      ...rest,
    });
    setOpen(!open);
    setShow(isShow);
  };

  const getViewEditButtons = (
    status: CardRefundOrderStatus,
    makerCheckerStatus: string,
    data: any
  ) => {
    if (
      status === CardRefundOrderStatus.CARD_REFUNDED ||
      makerCheckerStatus === 'APPROVED' ||
      status === CardRefundOrderStatus.UNDER_REVIEW ||
      makerCheckerStatus === 'CHECKER_PENDING'
    ) {
      return (
        <VegaContainedButton
          text="View"
          onClick={() => showDetails(data, true)}
          isPrimary
        />
      );
    }

    if (status === 'DRAFT') {
      return (
        <VegaContainedButton
          text="Edit"
          isPrimary
          onClick={() => showDetails(data, false)}
        />
      );
    }
  };

  const initiateCheckerMaker = (data: ICardRefundOrder) => {
    initiateCardRefundMakerChecker({
      cardRefundOrderId: data.id,
      userId: userId || '',
    })
      .then((res) => {
        getRefundList();
        setSnackbar('Send to checker');
      })
      .catch((err) => {
        setSnackbar('Failed to send to checker  ', 'error');
      });
  };
  const updateCheckerMaker = (
    data: ICardRefundOrder,
    status: CheckerMakerStatusEnum = CheckerMakerStatusEnum.CHECKER_PENDING
  ) => {
    updateCardRefundMakerChecker({
      cardRefundOrderId: data.id,
      userId: userId || '',
      status: status,
    })
      .then((res) => {
        getRefundList();
        setSnackbar('Updated status ' + status.toLocaleLowerCase());
      })
      .catch((err) => {
        setSnackbar(
          'Failed to update status ' + status.toLocaleLowerCase(),
          'error'
        );
      });
  };

  const getButtons = (
    status: CardRefundOrderStatus,
    makerCheckerStatus: CheckerMakerStatusEnum,
    data: ICardRefundOrder
  ) => {
    if (status === CardRefundOrderStatus.DRAFT && makerCheckerStatus === null) {
      return (
        <VegaContainedButton
          text="Send to Checker"
          onClick={() => initiateCheckerMaker(data)}
          isPrimary
        />
      );
    }
    if (
      status === CardRefundOrderStatus.UNDER_REVIEW &&
      makerCheckerStatus === CheckerMakerStatusEnum.REVIEWER_PENDING
    ) {
      return (
        <VegaContainedButton
          text="Send to Checker"
          onClick={() => updateCheckerMaker(data)}
          isPrimary
        />
      );
    }
    if (status === 'UNDER_REVIEW' && makerCheckerStatus === 'CHECKER_PENDING') {
      return <VegaContainedButton text="Sent to checker" isPrimary disabled />;
    }
    if (
      status === 'UNDER_REVIEW' &&
      makerCheckerStatus === CheckerMakerStatusEnum.CHANGES_REQUESTED
    ) {
      return (
        <VegaContainedButton
          text="Update Fund"
          isPrimary
          onClick={() => showDetails(data, false)}
        />
      );
    }
  };

  //   const onSendChecker = (data: IReloadCardData) => {
  //     console.log('data', data);
  //     if (active.id === 'RELOAD') {
  //       const obj = {
  //         fundLoadOrderId: data.id,
  //         userId,
  //       };
  //       if (data) {
  //         data.corporateId
  //           ? onStatusChange(data, 'CHECKER_PENDING')
  //           : initiateCheckerMakerForfundLoad(obj)
  //               .then((res) => {
  //                 setSnackbar('Submitted review!');
  //                 getFundList();
  //               })
  //               .catch((err) => {
  //                 setSnackbar(
  //                   getErrorMessageFromErrorObj(err, 'Failed to submitted'),
  //                   'error'
  //                 );
  //               });
  //       }
  //     } else if (active.id === 'REFUND') {
  //     }
  //   };

  //   const onStatusChange = async (data: any, status: string) => {
  //     console.log('data in change status', data);
  //     const obj = { status, userId, fundLoadOrderId: data.id };
  //     updateFundLoadOrderStatus(obj)
  //       .then((res) => {
  //         setSnackbar('Approved and sent to checker!');
  //         getFundList();
  //       })
  //       .catch((err) => {
  //         setSnackbar(
  //           getErrorMessageFromErrorObj(err, 'Failed to change status'),
  //           'error'
  //         );
  //       });
  //   };
  const handleCardRefundReqChange = (
    key: keyof CreateCardRefundOrderReq,
    value: string | any
  ) => {
    setShowData((pre) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  };

  const onClose = () => {
    setOpen(!open);
  };
  const onSubmit = () => {
    if (!showData?.amount || showData?.amount <= 0) {
      setSnackbar('Amount must be greater than 0', 'error');
      return;
    }

    updateCardRefundOrder({
      cardRefundOrderId: showData.id || '',
      accountId: showData.accountId || '',
      branchId: showData.branchId || '',
      sourceCurrency: showData?.sourceCurrency || '',
      destinationCurrency: showData.destinationCurrency || '',
      amount: showData.amount || 0,
      paymentMode: showData.paymentMode || PaymentMode.CASH,
      refundType: showData.refundType || CardRefundType.PARTIAL,
      transactionTime: showData.transactionTime,
      userId: userId || '',
    })
      .then((res) => {
        getRefundList();
        setSnackbar('Updated Refund  order');
        onClose();
      })
      .catch((err) => {
        setSnackbar('Error in updating Refund  order', 'error');
      });
  };
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={cardRefundData}
        columnDef={getRefundColumns(getButtons, getViewEditButtons)}
        idColumn={'id'}
        // loading={loading}
      />
      <VegaDrawer
        open={open}
        isClose
        isSubmit
        header={'Card Refund '}
        closeText={'Cancel'}
        submitText={'Submit'}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <CardRefundDetails
          state={showData}
          handleChange={handleCardRefundReqChange}
          isShow={show}
        />
      </VegaDrawer>
    </VegaCard>
  );
}

export default CardRefundList;

const getRefundColumns = (
  getButtons: (
    status: CardRefundOrderStatus,
    makerCheckerStatus: CheckerMakerStatusEnum,
    data: ICardRefundOrder
  ) => JSX.Element | undefined,
  getViewEditButtons: (
    status: CardRefundOrderStatus,
    makerCheckerStatus: string,
    data: any
  ) => JSX.Element | undefined
): GridColDef<ICardRefundOrder>[] => [
  {
    field: 'name ',
    renderHeader: () => <Typography variant="body2">Customer Name</Typography>,
    flex: 1,
    renderCell: (props) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        return (
          <Typography>
            {getCustomerName(accountConsolidatedDto.customer)}
          </Typography>
        );
      }
    },
  },
  {
    field: 'cardNo',
    renderHeader: () => <Typography variant="body2">Card No</Typography>,
    flex: 1,
    renderCell: (props) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        const card = accountConsolidatedDto?.card;
        if (!card || card === null) {
          return;
        } else {
          return <VegaLabel text={card?.cardNumber ?? '--'} color="primary" />;
        }
      }
    },
  },

  {
    field: 'expiryDate',
    renderHeader: () => <Typography variant="body2">Card Expiry</Typography>,
    flex: 0.5,
    renderCell: (props) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        const card = accountConsolidatedDto?.card;
        if (!card || card === null) {
          return;
        } else {
          return <VegaLabel text={card?.expiryDate ?? '--'} color="primary" />;
        }
      }
    },
  },

  {
    field: 'cardNetwork',
    renderHeader: () => <Typography variant="body2">Card Network</Typography>,
    flex: 0.5,
    renderCell: (props) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        const networks = accountConsolidatedDto.card?.cardNetwork;
        if (!networks) {
          return;
        }
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      }
    },
  },
  {
    field: 'status',
    renderHeader: () => <Typography variant="body2"> Status</Typography>,
    flex: 0.7,
    renderCell: (props) => {
      return props.row.cardRefundOrderStatus ===
        CardRefundOrderStatus.CARD_REFUNDED ? (
        <VegaContainedButton
          text="Refunded"
          // onClick={() => showBalance(props.row)}
          isSuccess
          disabled
        />
      ) : props.row.cardRefundOrderStatus === CardRefundOrderStatus.REJECTED ? (
        <VegaContainedButton
          text="Rejected"
          // onClick={() => showBalance(props.row)}
          isDanger
          px={2.5}
          disabled
        />
      ) : (
        <VegaContainedButton
          text="Pending"
          // onClick={() => showBalance(props.row)}
          isWarning
          px={2.7}
          disabled
        />
      );
    },
  },

  {
    field: 'Request',
    renderHeader: () => <Typography variant="body2"> Action</Typography>,
    flex: 0.7,
    renderCell: (props) => {
      return getButtons(
        props.row.cardRefundOrderStatus,
        props.row.makerCheckerStatus,
        props.row
      );
    },
  },
  {
    field: 'view',
    renderHeader: () => <Typography variant="body2"> View/Edit</Typography>,
    flex: 0.7,
    renderCell: (props) => {
      return getViewEditButtons(
        props.row.cardRefundOrderStatus,
        props.row.makerCheckerStatus,
        props.row
      );
    },
  },
];
