import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';
import React from 'react';
import styled from '@mui/material/styles/styled';

export interface IProps extends ButtonProps {
  text: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  textColor?: string;
  bgColor?: string;
  loading?: boolean;
}

interface StyledLoaderProps extends CircularProgressProps {
  buttonVariant?: string;
}

const StyledLoader = styled(CircularProgress)<StyledLoaderProps>(
  ({ theme, buttonVariant = 'contained', size }) => {
    if (buttonVariant == 'contained') {
      return {
        size: size,
        color: 'white',
      };
    }
    return {
      size: size,
      color: '#001aa1',
    };
  }
);

// TODO: Need to make it more generic abi bgColor empty string pass krna pdta h if we outlined button
// use styled components refer to LosButton from solfin @Shailendra
const Vega_Button = ({
  text,
  textColor = 'white',
  bgColor = '#1047DC',
  fontSize = '0.75rem',
  fontWeight = '400',
  loading,
  ...rest
}: IProps) => {
  return (
    <Button
      {...rest}
      className={`font-aspekta-${fontWeight}`}
      disabled={rest.disabled || loading}
      startIcon={
        loading ? (
          <StyledLoader size={24} buttonVariant={rest.variant} />
        ) : (
          rest.startIcon
        )
      }
      sx={{
        textTransform: 'capitalize',
        borderRadius: '4px',

        ...(rest.variant === 'outlined'
          ? {
              bgcolor: `transparent`,
            }
          : { bgcolor: bgColor }),
        color: textColor,

        fontSize,
        ':hover': {
          bgcolor: bgColor,
          color: textColor,
          ...(rest.variant === 'outlined' && {
            border: `1px solid ${textColor}`,
          }),
        },
        '&.Mui-disabled': {
          opacity: 0.5,
          color: textColor,
        },
        ...(!text.length && {
          '& .MuiButton-startIcon': {
            margin: '0px',
          },
          '&.MuiButton-root': {
            padding: '6px',
            minWidth: '30px',
          },
        }),
        ...(rest.variant === 'outlined' && {
          border: `1px solid ${textColor}`,
        }),
        ...rest.sx,
      }}
    >
      {text}
    </Button>
  );
};

export default Vega_Button;
