// @note this is a workaround for changing the zindex property of main header as it was causing issues with the Dialog

import { createContext, useContext, useState } from 'react';

type VegaFullScreenDialogContextType = {
  visiblityState: VegaFullScreenDialogState;
  onVisiblityChanged: (updatedState: VegaFullScreenDialogState) => void;
};

const VegaFullScreenDialogContext =
  createContext<VegaFullScreenDialogContextType | null>(null);

export const useVegaFullScreenDialog = () =>
  useContext(VegaFullScreenDialogContext) as VegaFullScreenDialogContextType;

export enum VegaFullScreenDialogState {
  Visible,
  Hidden,
}
export const VegaFullScreenDialogProvider = ({ children }: any) => {
  const [viewState, setViewState] = useState<VegaFullScreenDialogState>(
    VegaFullScreenDialogState.Hidden
  );

  function _updateViewState(state: VegaFullScreenDialogState) {
    setViewState(state);
  }

  return (
    <VegaFullScreenDialogContext.Provider
      value={{
        visiblityState: viewState,
        onVisiblityChanged: _updateViewState,
      }}
    >
      {children}
    </VegaFullScreenDialogContext.Provider>
  );
};
