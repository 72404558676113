/** @format */

import { createContext, useContext, useEffect, useState } from 'react';
import { GetAllApplicationsForHQApiData } from '../types/support';
import {
  ApplicationServices,
  CustomerApplication,
} from '../services/ApplicationServices';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useSupport } from './SupportProvider';
import { useSnackbar } from './SnackbarProvider';

export interface ApplicationContextProps {
  page: number;
  pageSize: number;
  rowCount: number;
  applicationLoading: boolean;
  applications: CustomerApplication[];
}

export const ApplicationContext = createContext<ApplicationContextProps | null>(
  null
);

const ApplicationsProvider = ({ children }: any) => {
  const { filter, filteredCustomer } = useSupport();
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [applicationLoading, setApplicationLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [applications, setApplications] = useState<CustomerApplication[]>([]);

  const getApplications = async (filter: string) => {
    try {
      setApplicationLoading(true);
      var params: Partial<GetAllApplicationsForHQApiData> = {
        page: page,
        pageSize: pageSize,
        sortBy: 'createdAt',
        sortDir: 'desc',
        customerId: filteredCustomer?.customerId,
      };

      const response = await ApplicationServices.getApplicationForCustomer(
        params
      );
      setApplications(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
      setApplicationLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setApplicationLoading(false);
    }
  };

  useEffect(() => {
    if (filter.length) {
      getApplications(filter);
    }
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        page,
        pageSize,
        rowCount,
        applicationLoading,
        applications,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplications = () =>
  useContext(ApplicationContext) as ApplicationContextProps;

export default ApplicationsProvider;
