import { Box } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import Vega_Datagrid from '../../../../common/v3/Vega_Datagrid';
import RewardsContainer from './components/RewardsContainer';
import { getRewardsForAccount } from '../../../../../api/rewards';
import { useState, useEffect } from 'react';
import { useSupport } from '../../../../../providers/SupportProvider';
import { dateFormat } from '../../../../../mcc/variable';
import Vega_Text from '../../../../common/v3/Vega_Text';

interface TransactionInfo {
  id: string;
  accountId: string;
  transactionId: string | null;
  postingType: string;
  moneyToRewardsRatio: number | null;
  amount: number;
  transactionDate: string;
  createdAt: string;
  updatedAt: string;
}

interface IrewardsListStateProps {
  data: TransactionInfo[];
  loading: boolean;
}

const Rewards = () => {
  const { accountData } = useSupport();
  const [rewardListState, setRewardListState] =
    useState<IrewardsListStateProps>({
      data: [],
      loading: false,
    });
  const COLUMN_DEF: GridColumns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',

      renderCell: (props) => {
        return <Vega_Text text={dateFormat(props.row.createdAt)} />;
      },
    },

    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'transactionId',
      headerName: 'Source',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'moneyToRewardsRatio',
      headerName: 'Money Ratio',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const moneyRetio = props.row.moneyToRewardsRatio
          ? props.row.moneyToRewardsRatio
          : 0;
        return <Vega_Text text={moneyRetio} />;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 100,
    },
  ];

  const getAllRewardsForAccount = async (accountId: string) => {
    setRewardListState({ data: [], loading: true });
    try {
      const response = await getRewardsForAccount(accountId);
      setRewardListState({ data: response.data, loading: false });
    } catch (error) {
      setRewardListState({ data: [], loading: false });
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getAllRewardsForAccount(accountData.accountId);
    }
  }, []);
  return (
    <>
      <RewardsContainer header="Rewards">
        <Box mt={2}>
          <Vega_Datagrid
            data={rewardListState.data}
            idColumn="id"
            columns={COLUMN_DEF}
            loading={rewardListState.loading}
          />
        </Box>
      </RewardsContainer>
    </>
  );
};

export default Rewards;
