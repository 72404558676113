import React, { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import { Typography, Box, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';

import { toLowerCase } from '../../../../constants/commonFunction';
import BranchDetails from './BranchDetails';
import { IBranchProps } from '../../pages/MCCBranch';
import VegaSwitch from '../../../../components/common/VegaSwitch';
import { updateBranchStatus } from '../../api/Branch';
import { IResourcesProps } from '../../../../pages/Resources';

const INITIAL_DATA = {
  branchName: '',
  branchCode: '',
  branchAddress: '',
  totalUsers: '',
  status: '',
  pincode: '',
};

interface IListBranchProps extends IResourcesProps {
  onUpdateBranch: (body: IBranchProps) => void;
  getBranchList: () => void;
}

const ListOfMCCBranch = ({
  rowCount,
  onPageChange,
  onPageSizeChange,
  page,
  pageSize,
  data,
  loading,
  onUpdateBranch,
  getBranchList,
}: IListBranchProps) => {
  const [branchDetails, setBranchDetails] = useState<IBranchProps>({
    ...INITIAL_DATA,
  });
  const [open, setOpen] = useState<boolean>(false);

  const ProgramsListColumDef: GridColDef[] = [
    {
      field: 'branchName',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Branch Name
        </Typography>
      ),
      flex: 0.6,
    },
    {
      field: 'branchCode',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Branch Code
        </Typography>
      ),
      flex: 0.6,
    },
    {
      field: 'branchAddress',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Address
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'status',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <VegaSwitch
            checked={props.row.status === 'ACTIVE'}
            handleChecked={(e) => handleChecked(e, props.row)}
          />
        );
      },
      flex: 0.7,
    },

    {
      field: 'options',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="Edit"
            isPrimary
            mx="0"
            onClick={() => editProgram(props.row)}
          />
        );
      },
      flex: 0.5,
    },
  ];

  const handleChecked = async (e: any, data: any) => {
    console.log('e.target.checked', e.target.checked, branchDetails);
    await updateBranchStatus({
      id: data.id,
      status: e.target.checked ? 'ACTIVE' : 'INACTIVE',
    });
    getBranchList();
  };

  const editProgram = (data: IBranchProps) => {
    console.log('data', data);
    setBranchDetails(data);
    setOpen(!open);
  };

  const viewProgram = () => {
    //
  };
  const handleProgramChange = (name: string, value: any) => {
    setBranchDetails({ ...branchDetails, [name]: value });
  };
  const decline = () => {
    setOpen(!open);
  };
  const onUpdate = () => {
    const {
      id,
      branchName,
      branchCode,
      branchAddress,
      pincode,
      state,
      status,
    } = branchDetails;
    const body = {
      id,
      branchName,
      branchCode,
      branchAddress,
      pincode,
      state,
      status,
    };
    onUpdateBranch(body);
    setOpen(!open);
  };
  return (
    <>
      <VegaCard
        noPad
        sx={{
          mt: 4,
        }}
      >
        <VegaTable
          columnDef={ProgramsListColumDef}
          loading={loading}
          data={data}
          idColumn="id"
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
        />
      </VegaCard>
      <VegaDrawer
        open={open}
        header={'Program Details'}
        closeText="Clear"
        isClose
        isSubmit
        submitText="Update"
        onClose={decline}
        onSubmit={onUpdate}
      >
        <BranchDetails
          state={branchDetails}
          handleChange={handleProgramChange}
        />
      </VegaDrawer>
    </>
  );
};

export default ListOfMCCBranch;
