import { Stack } from '@mui/material';
import _ from 'lodash';
import { MadPolicyInterface } from '../../../types/policy';
import { IRowData, PolicySummaryTable } from './common/SummaryTable';

interface Props {
  policy?: MadPolicyInterface | null;
}

function MadTadPolicySummary({ policy }: Props) {
  const madComponentsRows = () => {
    const summaryRows: IRowData[] = [];
    if (!policy) {
      return [];
    }
    const components = policy.mad_components.map((item) => {
      return [
        _.startCase(_.toLower(item.transaction_category)),
        _.startCase(_.toLower(item.value_type)),
        item.value.toString(),
      ];
    });
    components.forEach((item) => {
      summaryRows.push({ rowData: item });
    });
    return summaryRows;
  };

  return (
    <Stack spacing={2}>
      <PolicySummaryTable
        headers={[]}
        rows={[
          {
            rowData: [
              'Minimum Mad Amount',
              policy?.minimum_mad?.toString() ?? '-',
            ],
          },
          {
            rowData: [
              'Minimum Tad Amount',
              policy?.minimum_tad?.toString() ?? '-',
            ],
          },
        ]}
      />
      <PolicySummaryTable
        headers={['Transaction Category', ' Value Type', 'Value']}
        rows={madComponentsRows()}
      />
    </Stack>
  );
}

export default MadTadPolicySummary;
