/** @format */

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-scroll';
import { PRIMARY } from '../../constants/style';

type Props = {
  icon?: string | ReactNode;
  label?: string | ReactNode;
  id: string;
  open?: boolean;
  isMobile?: boolean;
  isSamllScreen?: boolean;
  sx?: SxProps<Theme>;
  selected?: boolean;
  onItemClick?: () => void;
} & (
  | {
      icon: string | ReactNode;
    }
  | {
      label: string | ReactNode;
    }
);

const VegaScrollListItem = ({
  icon,
  label,
  id,
  sx,
  selected,
  open,
  onItemClick,
  isMobile,
  isSamllScreen,
}: Props) => {
  console.log('VegaScrollListItem', id);
  return (
    <Link
      to={id}
      spy={true}
      smooth={true}
      offset={-100}
      className="navLink"
      activeClass="navActive"
    >
      <ListItem key={id} disableGutters>
        <ListItemButton
          sx={{
            paddingLeft: !open ? 4 : isMobile || isSamllScreen ? 5 : 2,
            justifyContent: open ? 'initial' : 'center',
            // transition: 'all 500ms ease-in-out',
          }}
          onClick={onItemClick}
        >
          {icon && open ? (
            <Tooltip
              title={label}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: '16px',
                    bgcolor: PRIMARY.main,
                    '& .MuiTooltip-arrow': {
                      color: 'common.black',
                    },
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 'unset',
                  paddingRight: 2,
                  my: 0.5,
                  color: 'inherit',
                  bgcolor: 'inherit',
                }}
              >
                {icon}
              </ListItemIcon>
            </Tooltip>
          ) : (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                paddingRight: 2,
                my: 0.5,
                color: 'inherit',
                bgcolor: 'inherit',
              }}
            >
              {icon}
            </ListItemIcon>
          )}
          {label && !open ? (
            <ListItemText
              sx={{ color: 'inherit', bgcolor: 'inherit' }}
              primary={label}
            />
          ) : isMobile === true || isSamllScreen === true ? (
            <ListItemText
              sx={{ color: 'inherit', bgcolor: 'inherit' }}
              primary={label}
            />
          ) : (
            ''
          )}
        </ListItemButton>
      </ListItem>
    </Link>
    // <Link
    //   to="media"
    //   spy={true}
    //   smooth={true}
    //   className="cursor-pointer"
    //   // onClick={() => setActive(route.activePath)}
    // >
    //   {label}
    // </Link>
  );
};

export default VegaScrollListItem;
