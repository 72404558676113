import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export interface VPFormSelectOption {
  value: string;
  label: string;
  icon?: JSX.Element;
}

interface VPFormSelectProps {
  placeholder?: string;
  hideDropDownIcon?: boolean;
  dropDownIcon?: ReactNode;
  options?: VPFormSelectOption[];
  renderOptions?: () => ReactNode;
  renderSelectedValue?: (selected: string) => ReactNode;
  hidePlaceholder?: boolean;
}

const StyledPlaceholder = styled(Typography)(({ theme }) => {
  return {
    fontSize: '0.8125rem',
    fontFamily: 'Aspekta !important',
    fontWeight: 450,
    lineHeight: '120%',
  };
});

function VPFormSelect({
  options,
  placeholder,
  renderOptions,
  renderSelectedValue,
  hideDropDownIcon,
  hidePlaceholder = false,
  ...rest
}: VPFormSelectProps & SelectProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getLabelForSelectedValue = (selected: string) => {
    const selectedOption =
      options?.filter((item) => item.value == selected) ?? [];
    console.log('selectedOption666', options);
    if (selectedOption.length > 0) {
      return selectedOption[0].label;
    }
    return selected;
  };

  return (
    <FormControl fullWidth>
      {rest.label && (
        <InputLabel
          size="small"
          sx={{ color: '#AFAFAF' }}
          htmlFor={rest.labelId}
        >
          {rest.label}
        </InputLabel>
      )}
      <Select
        {...rest}
        sx={{
          borderRadius: '.5rem',
          maxHeight: '40px',

          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          ...(isMobile == false && {
            backgroundColor: 'white',
          }),
          ...rest.sx,
        }}
        displayEmpty
        // inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={hideDropDownIcon ? () => null : undefined}
        renderValue={(selected: any) => {
          console.log('selected', selected);
          if (
            hidePlaceholder == false &&
            (!selected || selected?.length <= 0)
          ) {
            return (
              <StyledPlaceholder variant="caption">
                {placeholder ?? ''}
              </StyledPlaceholder>
            );
          }
          if (renderSelectedValue) {
            return renderSelectedValue(selected);
          }
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                height: '30px',
              }}
            >
              <Typography style={{ fontSize: '13px', lineHeight: '120%' }}>
                {getLabelForSelectedValue(selected)}
              </Typography>
            </div>
          );
        }}
      >
        {renderOptions && renderOptions()}
        {options?.map((option, index) => {
          return (
            <MenuItem
              key={index}
              value={option.value}
              style={{ fontFamily: 'Aspekta !important' }}
            >
              <Stack direction={'row'} gap={2} alignItems="center">
                {option.icon && option.icon}
                <Typography style={{ fontSize: '14px' }}>
                  {option.label}
                </Typography>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default VPFormSelect;
