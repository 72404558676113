/* eslint-disable react/jsx-pascal-case */
import { Box, useMediaQuery, useTheme, Grid, Stack } from '@mui/material';
import React from 'react';
import Vega_Text from '../../components/common/v3/Vega_Text';
import MainHeader from '../../components/header/MainHeader';
import BackOfficeRouteCard from './BackOfficeRouteCard';
import { SidebarListItemType } from '../../components/layouts/SidebarLayout';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../routes';
import {
  Analytics_Icon,
  Collection_Icon,
  Notification_Icon,
  Operation_Icon,
  Report_Icon,
  Support_Icon,
} from '../../components/icon/BackOfficeIcon';

const SELECTED_VIEW = 'view';

interface IProps {
  setSelectedItem: React.Dispatch<
    React.SetStateAction<SidebarListItemType | null>
  >;
}

const BackOfficeLendingPage = ({ setSelectedItem }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const NAV_OPTIONS: SidebarListItemType[] = [
    {
      id: ROUTES.SEARCH_BY_CUSTOMER,
      icon: <Support_Icon />,
      label: 'Support Center',
    },

    {
      id: ROUTES.COLLECTIONS,
      icon: <Collection_Icon />,
      label: 'Collections',
    },
    {
      id: '',
      icon: <Notification_Icon />,
      label: 'Notification Center',
    },
    {
      id: ROUTES.REPORTS,
      icon: <Report_Icon />,
      label: 'Reports',
    },
    {
      id: ROUTES.OPERATIONS,
      icon: <Operation_Icon />,
      label: 'Operations',
    },
    {
      id: ROUTES.ANALYTICS,
      icon: <Analytics_Icon />,
      label: 'Analytics',
    },
  ];
  const handleItemClick = (item: SidebarListItemType) => {
    setSelectedItem(item);
    setSearchParams(createSearchParams({ [SELECTED_VIEW]: item.id }));
  };

  return (
    <>
      <MainHeader
        setOpen={setOpen}
        isSmallScreen={isSmallScreen}
        isMobile={isMobile}
      />
      <Box height={'100%'} pt={'69px'}>
        <Box
          sx={{
            maxWidth: '1290px',
            mx: 'auto',
            px: 5,
            py: 4,
          }}
        >
          <Vega_Text
            text={'Welcome back, Rahul!'}
            color={'#1B1D22'}
            fontWeight={600}
            fontSize={24}
            sx={{
              pb: 2,
              borderBottom: '1px solid #C5C8D0',
            }}
          />
          <Box mt={5}>
            <Vega_Text
              text={'Access the Vegapay’s Backoffice!'}
              color={'#1B1D22'}
              fontWeight={600}
              fontSize={17}
            />
            <Box mt={2}>
              <Grid container spacing={2}>
                {NAV_OPTIONS.map((route: SidebarListItemType) => (
                  <Grid key={route.id} item xs={12} sm={6} md={4}>
                    <BackOfficeRouteCard
                      route={route}
                      onSelect={handleItemClick}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BackOfficeLendingPage;
