import { GridCallbackDetails } from '@mui/x-data-grid';
import { useState } from 'react';
import { VegaPage } from '../components/common';
import {
  CorporateCardIcon,
  CreditCardIcon,
  UserIcon,
} from '../components/icon/Icons';
import CountryCodeTable from '../components/Resources/CountryCodeTable';
import CurrencyCodeTable from '../components/Resources/CurrencyCodeTable';
import MccTable from '../components/Resources/MccTable';
import McgTable from '../components/Resources/McgTable';
import MerchantTable from '../components/Resources/MerchantTable';
import PincodeTable from '../components/Resources/PincodeTable';
import ResourcesSection from '../components/Resources/ResourcesSection';
import TimeZoneTable from '../components/Resources/TimeZoneTable';
import { BACKGROUND } from '../constants/style';

export interface ITileDataProps {
  id: string;
  text: string;
  image?: JSX.Element;
  bgColor?: string;
  color?: string;
  selected?: string;
}

export interface IProgramDataProps {
  program: string;
  data: ITileDataProps[];
}

export interface IResourcesProps {
  active?: any;
  data?: ITileDataProps[];
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
  page: number;
  pageSize: number;
  rowCount?: any;
  loading?: boolean;
}

let tileData = [
  {
    id: 'merchant',
    text: 'Merchant',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'mccCodes',
    text: 'MCC',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'mcg',
    text: 'MCG',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'country',
    text: 'Country Code',
    image: <CorporateCardIcon />,
    bgColor: BACKGROUND.BLUE,
    color: 'white',
  },
  {
    id: 'currency',
    text: 'Currency Code',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'pincode',
    text: 'Pin Code Mapper',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'timezone',
    text: 'Timezones',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
] as ITileDataProps[];

const Resources = () => {
  const [activeState, setActiveState] = useState<ITileDataProps>(tileData[0]);

  return (
    <VegaPage>
      <ResourcesSection
        data={tileData}
        setState={setActiveState}
        active={activeState}
      />

      <VegaPage gap={1}>
        {activeState.id == 'mcg' && <McgTable />}
        {activeState.id == 'merchant' && <MerchantTable />}
        {activeState.id == 'mccCodes' && <MccTable />}
        {activeState.id == 'country' && <CountryCodeTable />}
        {activeState.id == 'currency' && <CurrencyCodeTable />}
        {activeState.id == 'pincode' && <PincodeTable />}
        {activeState.id == 'timezone' && <TimeZoneTable />}
      </VegaPage>
    </VegaPage>
  );
};

export default Resources;
