import {
  FRMCriteria,
  FRMCriteriaConfig,
  IdentityFRMPolicy,
  PolicyStatus,
} from './policy';

export enum AcceptanceOptions {
  LESS_THAN_EQUAL_TO = '<=',
  GREATER_THAN_EQUAL_TO = '>=',
  GREATER_THAN = '>',
  LESS_THAN = '<',
  EQUAL_TO = '=',
}

export enum CriteriaType {
  APPROVE,
  REJECT,
}

export enum OperandType {
  MIN = 'min',
  MAX = 'max',
  EXACT = 'exact',
}

export interface FrmCriteriaCondition {
  check: string;
  criteria: FRMCriteria;
}

export const getOperandType = (
  acceptanceOption: string
): OperandType | undefined => {
  switch (acceptanceOption) {
    case AcceptanceOptions.GREATER_THAN_EQUAL_TO:
    case AcceptanceOptions.GREATER_THAN:
      return OperandType.MIN;
    case AcceptanceOptions.LESS_THAN_EQUAL_TO:
    case AcceptanceOptions.LESS_THAN:
      return OperandType.MAX;
    case AcceptanceOptions.EQUAL_TO:
      return OperandType.EXACT;
    default:
      return undefined;
  }
};

export const getSelectedOperator = (criteria?: FRMCriteriaConfig | null) => {
  if (!criteria) {
    return '';
  }
  const minValue = criteria.min;
  const maxValue = criteria.max;
  const exactValue = criteria.exact;

  if (typeof exactValue == 'number' && !isNaN(exactValue)) {
    return AcceptanceOptions.EQUAL_TO;
  }

  if (typeof minValue == 'number' && !isNaN(minValue)) {
    const minInclusive = criteria.min_inclusive;
    return minInclusive
      ? AcceptanceOptions.GREATER_THAN_EQUAL_TO
      : AcceptanceOptions.GREATER_THAN;
  }

  if (typeof maxValue == 'number' && !isNaN(maxValue)) {
    const maxInclusive = criteria.max_inclusive;
    return maxInclusive
      ? AcceptanceOptions.LESS_THAN_EQUAL_TO
      : AcceptanceOptions.LESS_THAN;
  }
  return '';
};

export const getUpdatedConfig = (
  acceptanceOption: string,
  value?: number | null
) => {
  var config: FRMCriteriaConfig = {
    min: null,
    max: null,
    exact: null,
    max_inclusive: false,
    min_inclusive: false,
  };
  if (acceptanceOption == AcceptanceOptions.GREATER_THAN) {
    config.min = value;
  } else if (acceptanceOption == AcceptanceOptions.GREATER_THAN_EQUAL_TO) {
    config.min = value;
    config.min_inclusive = true;
  } else if (acceptanceOption == AcceptanceOptions.LESS_THAN) {
    config.max = value;
  } else if (acceptanceOption == AcceptanceOptions.LESS_THAN_EQUAL_TO) {
    config.max = value;
    config.max_inclusive = true;
  } else if (acceptanceOption == AcceptanceOptions.EQUAL_TO) {
    config.exact = value;
  }
  return config;
};
