import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaLabel from '../../../../components/common/VegaLabel';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { CheckerMakerStatusEnum } from '../../../../enums/commons';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { IReloadCardData } from '../../../corporate/components/CorporateCardReload/Card';
import {
  initiateCheckerMakerForfundLoad,
  updateCheckerMakerForfundLoad,
  updateFundLoadOrderStatus,
} from '../../api/transaction';
import { ISectionProps } from '../../pages/MCCUserManagement';
import BalanceView from '../MCCUserManagement/Components/BalanceView';
import CardRefundDetails from './Components/CardRefundDetails';
import CardReloadDetails from './Components/CardReloadDetails';

interface IProps {
  active: ISectionProps;
  manualAdjustData: any;
  cardRefundData: any;
  cardReloadData: any;
  loading: boolean;
  getFundList: () => void;
}

export interface IDetailsProps {
  id?: string;
  sourceCurrency: string;
  destinationCurrency: string;
  markupValueType: string;
  amount: number;
  markupValue: number;
  accountId: string;
  customerId?: string;
  cardNo?: string;
  name?: string;
  transactionCode?: string;
  transactionTime?: any;
  accountConsolidatedDto?: any;
}

export const DEFAUL_DATA = {
  sourceCurrency: 'INR',
  destinationCurrency: 'USD',
  markupValueType: 'PERCENTAGE',
  amount: 0,
  markupValue: 0,
  accountId: '',
  customerId: '',
  cardNo: '',
  name: '',
  transactionCode: 'CASH_AT_POS',
  transactionTime: '',
};

const ListOfCardTransaction = ({
  active,
  manualAdjustData,
  cardRefundData,
  cardReloadData,
  loading,
  getFundList,
}: IProps) => {
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const [showData, setShowData] = useState<IDetailsProps>({ ...DEFAUL_DATA });

  const getButtons = (
    status: string,
    makerCheckerStatus: string,
    data: any
  ) => {
    if (status === 'DRAFT' || makerCheckerStatus === 'REVIEWER_PENDING') {
      return (
        <VegaContainedButton
          text="Send to Checker"
          onClick={() => onSendChecker(data)}
          isPrimary
        />
      );
    }
    if (status === 'UNDER_REVIEW' && makerCheckerStatus === 'CHECKER_PENDING') {
      return <VegaContainedButton text="sent to checker" isPrimary disabled />;
    }
    if (
      status === 'UNDER_REVIEW' &&
      makerCheckerStatus === 'CHANGE_REQUESTED'
    ) {
      return (
        <VegaContainedButton
          text="Update Fund"
          isPrimary
          onClick={() => showDetails(data, false)}
        />
      );
    }
  };
  const getViewEditButtons = (
    fundLoadStatus: string,
    makerCheckerStatus: string,
    data: any
  ) => {
    if (
      fundLoadStatus === 'FUND_RELOADED' ||
      makerCheckerStatus === 'APPROVED' ||
      fundLoadStatus === 'UNDER_REVIEW' ||
      makerCheckerStatus === 'CHECKER_PENDING'
    ) {
      return (
        <VegaContainedButton
          text="View"
          onClick={() => showDetails(data, true)}
          isPrimary
        />
      );
    }

    if (fundLoadStatus === 'DRAFT') {
      return (
        <VegaContainedButton
          text="Edit"
          isPrimary
          onClick={() => showDetails(data, false)}
        />
      );
    }
  };

  const RELOAD_COLUMN = [
    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
        if (!accountConsolidatedDto || accountConsolidatedDto === null) {
          return;
        } else {
          return (
            <Typography>
              {accountConsolidatedDto?.customer?.firstName || ''}
            </Typography>
          );
        }
      },
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
        if (!accountConsolidatedDto || accountConsolidatedDto === null) {
          return;
        } else {
          const card = accountConsolidatedDto?.card;
          if (!card || card === null) {
            return;
          } else {
            return (
              <VegaLabel text={card?.cardNumber ?? '--'} color="primary" />
            );
          }
        }
      },
    },

    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card Expiry
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props: any) => {
        const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
        if (!accountConsolidatedDto || accountConsolidatedDto === null) {
          return;
        } else {
          const card = accountConsolidatedDto?.card;
          if (!card || card === null) {
            return;
          } else {
            return (
              <VegaLabel text={card?.expiryDate ?? '--'} color="primary" />
            );
          }
        }
      },
    },
    // {
    //   field: 'cardstatus',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Card Status</Typography>,
    //   flex: 0.6,
    //   renderCell: (props: any) => {
    //     const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
    //     if (!accountConsolidatedDto || accountConsolidatedDto === null) {
    //       return;
    //     } else {
    //       const card = accountConsolidatedDto?.card;
    //       if (!card || card === null) {
    //         return;
    //       } else {
    //         return (
    //           <VegaLabel
    //             text={toLowerCase(card?.cardStatus ?? '--')}
    //             color="primary"
    //           />
    //         );
    //       }
    //     }
    //   },
    // },
    // {
    //   field: 'accountId',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Account Id</Typography>,
    //   flex: 1,
    //   renderCell: (props: any) => {
    //     const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
    //     if (!accountConsolidatedDto || accountConsolidatedDto === null) {
    //       return;
    //     } else {
    //       const account = accountConsolidatedDto?.account;
    //       if (!account || account === null) {
    //         return;
    //       } else {
    //         return <Typography>{account?.id ?? '--'}</Typography>;
    //       }
    //     }
    //   },
    // },
    // {
    //   field: 'activationDate',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Activation Date</Typography>,
    //   flex: 0.5,
    //   renderCell: (props: any) => {
    //     const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
    //     if (!accountConsolidatedDto || accountConsolidatedDto === null) {
    //       return;
    //     } else {
    //       const card = accountConsolidatedDto?.card;
    //       if (!card || card === null) {
    //         return;
    //       } else {
    //         return (
    //           <VegaLabel
    //             text={dateFormat(card?.createdAt ?? '--')}
    //             color="primary"
    //           />
    //         );
    //       }
    //     }
    //   },
    // },
    {
      field: 'cardNetwork',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card Network
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props: any) => {
        const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
        if (!accountConsolidatedDto || accountConsolidatedDto === null) {
          return;
        } else {
          const networks = accountConsolidatedDto.card?.cardNetwork;
          if (!networks) {
            return;
          }
          return (
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <div>{getLogoForNetwork(networks)}</div>
            </Stack>
          );
        }
      },
    },

    {
      field: 'Request',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Action
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return getButtons(
          props.row.fundLoadOrderStatus,
          props.row.makerCheckerStatus,
          props.row
        );
      },
    },
    {
      field: 'view',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          View/Edit
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return getViewEditButtons(
          props.row.fundLoadOrderStatus,
          props.row.makerCheckerStatus,
          props.row
        );
      },
    },
  ];

  const REFUND_COLUMN = [
    {
      field: 'customerId',
      headerName: <Typography variant="body2">Customer Name</Typography>,
      flex: 1,
      renderCell: (props: any) => {
        const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
        if (!accountConsolidatedDto || accountConsolidatedDto === null) {
          return;
        } else {
          return (
            <Typography>
              {accountConsolidatedDto?.customer?.firstName || ''}
            </Typography>
          );
        }
      },
    },
    {
      field: 'cardNo',
      headerName: <Typography variant="body2">Card No</Typography>,
      flex: 1,
      renderCell: (props: any) => {
        const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
        if (!accountConsolidatedDto || accountConsolidatedDto === null) {
          return;
        } else {
          const card = accountConsolidatedDto?.card;
          if (!card || card === null) {
            return;
          } else {
            return (
              <VegaLabel text={card?.cardNumber ?? '--'} color="primary" />
            );
          }
        }
      },
    },

    {
      field: 'expiryDate',
      headerName: <Typography variant="body2">Card Expiry</Typography>,
      flex: 0.5,
      renderCell: (props: any) => {
        const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
        if (!accountConsolidatedDto || accountConsolidatedDto === null) {
          return;
        } else {
          const card = accountConsolidatedDto?.card;
          if (!card || card === null) {
            return;
          } else {
            return (
              <VegaLabel text={card?.expiryDate ?? '--'} color="primary" />
            );
          }
        }
      },
    },

    {
      field: 'cardNetwork',
      headerName: <Typography variant="body2">Card Network</Typography>,
      flex: 0.5,
      renderCell: (props: any) => {
        const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
        if (!accountConsolidatedDto || accountConsolidatedDto === null) {
          return;
        } else {
          const networks = accountConsolidatedDto.card?.cardNetwork;
          if (!networks) {
            return;
          }
          return (
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <div>{getLogoForNetwork(networks)}</div>
            </Stack>
          );
        }
      },
    },
    {
      field: 'status',
      headerName: <Typography variant="body2"> Status</Typography>,
      flex: 0.7,
      renderCell: (props: any) => {
        return props.row.status === 'APPROVED' ? (
          <VegaContainedButton
            text="Approved"
            // onClick={() => showBalance(props.row)}
            isSuccess
            disabled
          />
        ) : props.row.status === 'REJECTED' ? (
          <VegaContainedButton
            text="Rejected"
            // onClick={() => showBalance(props.row)}
            isDanger
            px={2.5}
            disabled
          />
        ) : (
          <VegaContainedButton
            text="Pending"
            // onClick={() => showBalance(props.row)}
            isWarning
            px={2.7}
            disabled
          />
        );
      },
    },

    {
      field: 'Request',
      headerName: <Typography variant="body2"> Action</Typography>,
      flex: 0.7,
      renderCell: (props: any) => {
        return getButtons(
          props.row.cardRefundOrderStatus,
          props.row.makerCheckerStatus,
          props.row
        );
      },
    },
    {
      field: 'view',
      headerName: <Typography variant="body2"> View/Edit</Typography>,
      flex: 0.7,
      renderCell: (props: any) => {
        return getViewEditButtons(
          props.row.fundLoadOrderStatus,
          props.row.makerCheckerStatus,
          props.row
        );
      },
    },
  ];

  const REFUND_COLUMN1 = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return <Typography>{props?.row?.name}</Typography>;
      },
    },

    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer ID
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Status
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return props.row.status === 'APPROVED' ? (
          <VegaContainedButton
            text="Approved"
            // onClick={() => showBalance(props.row)}
            isSuccess
            disabled
          />
        ) : props.row.status === 'REJECTED' ? (
          <VegaContainedButton
            text="Rejected"
            // onClick={() => showBalance(props.row)}
            isDanger
            px={2.5}
            disabled
          />
        ) : (
          <VegaContainedButton
            text="Pending"
            // onClick={() => showBalance(props.row)}
            isWarning
            px={2.7}
            disabled
          />
        );
      },
    },
    {
      field: 'totalBalance',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Total Balance
        </Typography>
      ),
      flex: 0.9,
    },
    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Expiry Date
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'details',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Details
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="Refund"
            onClick={() => showDetails(props.row, false)}
            isPrimary
          />
        );
      },
    },
  ];

  const ADJUSTMENT_COLUMN = [
    {
      field: 'kitNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Kit No
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return <Typography>{props?.row?.name}</Typography>;
      },
    },

    {
      field: 'customerId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer ID
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Status
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return props.row.status === 'APPROVED' ? (
          <VegaContainedButton
            text="Approved"
            // onClick={() => showBalance(props.row)}
            isSuccess
            disabled
          />
        ) : props.row.status === 'REJECTED' ? (
          <VegaContainedButton
            text="Rejected"
            // onClick={() => showBalance(props.row)}
            isDanger
            px={2.5}
            disabled
          />
        ) : (
          <VegaContainedButton
            text="Pending"
            // onClick={() => showBalance(props.row)}
            isWarning
            px={2.7}
            disabled
          />
        );
      },
    },
    {
      field: 'totalBalance',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Total Balance
        </Typography>
      ),
      flex: 0.9,
    },
    {
      field: 'expiryDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Expiry Date
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'details',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Details
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="Adjustment"
            onClick={() => showDetails(props.row, false)}
            isPrimary
          />
        );
      },
    },
  ];

  const onSendChecker = (data: IReloadCardData) => {
    console.log('data', data);
    if (active.id === 'RELOAD') {
      const obj = {
        fundLoadOrderId: data.id,
        userId,
      };
      if (data) {
        if (data.fundLoadOrderStatus === 'DRAFT') {
          initiateCheckerMakerForfundLoad(obj)
            .then((res) => {
              setSnackbar('Submitted review!');
              getFundList();
            })
            .catch((err) => {
              setSnackbar(
                getErrorMessageFromErrorObj(err, 'Failed to submitted'),
                'error'
              );
            });
        } else if (data.fundLoadOrderStatus === 'UNDER_REVIEW') {
          onStatusChange(data, 'CHECKER_PENDING');
        }
      }
    } else if (active.id === 'REFUND') {
    }
  };

  const onStatusChange = async (data: any, status: string) => {
    console.log('data in change status', data);
    const obj = {
      status: status as CheckerMakerStatusEnum,
      userId: userId || '',
      fundLoadOrderId: data.id || '',
    };
    updateFundLoadOrderStatus(obj)
      .then((res) => {
        setSnackbar('Approved and sent to checker!');
        getFundList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to change status'),
          'error'
        );
      });
  };

  const updateCheckerMakerStatus = (data: IDetailsProps) => {
    console.log('data', data);
    const obj = {
      fundLoadOrderId: '07907582-f4ed-42c2-80ff-09c026a2aa33',
      accountId: 'd1457291-aa9d-4a0d-8877-1db3fb4bf6ad',
      branchId: '',
      corporateId: '',
      sourceCurrency: 'USD',
      destinationCurrency: 'INR',
      amount: 205,
      markupValueType: 'AMOUNT',
      markupValue: 5,
      transactionTime: '2023-04-18T05:30:10.001Z',
      userId: '{{$randomUUID}}',
    };
    updateCheckerMakerForfundLoad(obj)
      .then((res) => {
        setSnackbar('Submitted review!');
        getFundList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to submitted'),
          'error'
        );
      });
  };

  const showDetails = (data: any, isShow: boolean) => {
    setShowData({ ...data, accountId: data.id });
    setOpen(!open);
    setShow(isShow);
  };

  const onClose = () => {
    setOpen(!open);
  };

  const onSubmit = async () => {};
  const onChange = (name: string, value: any) => {
    setShowData({ ...showData, [name]: value });
  };

  const getValue = (id: string) => {
    if (id === 'REFUND') {
      return {
        column: REFUND_COLUMN,
        header: 'Refund Details',
        component: (
          <CardRefundDetails
            state={showData?.accountConsolidatedDto}
            handleChange={onChange}
          />
        ),
        idColumn: 'id',
        data: cardRefundData,
      };
    } else if (id === 'ADJUSTMENT') {
      return {
        column: ADJUSTMENT_COLUMN,
        header: 'Adjustment Details',
        // component: <IssueDrawer state={showData} handleChange={onChange} />,
        idColumn: 'id',
        data: manualAdjustData,
      };
    } else {
      return {
        column: RELOAD_COLUMN,
        header: 'Reload Details',
        component: (
          <CardReloadDetails
            state={showData}
            handleChange={onChange}
            isShow={show}
          />
        ),
        idColumn: 'id',
        data: cardReloadData.filter(
          (val: any) =>
            val.accountConsolidatedDto !== null &&
            // && (val.corporateId==null || (val.corporateId!=null && val.fundLoadOrderStatus))
            val.accountConsolidatedDto.card !== null
        ),
        // .filter((val: any) => val.accountConsolidatedDto.card !== null),
      };
    }
  };
  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable
          data={getValue(active.id).data}
          columnDef={getValue(active.id).column}
          idColumn={getValue(active.id).idColumn}
          loading={loading}
        />
        <VegaDrawer
          open={open}
          isClose
          isSubmit
          header={getValue(active.id).header}
          closeText={'Cancel'}
          submitText={'Submit'}
          onClose={onClose}
          onSubmit={onSubmit}
        >
          {getValue(active.id).component}
        </VegaDrawer>
        {/* <VegaDrawer
          open={openBalance}
          isClose
          isSubmit
          header={'Balance Details'}
          closeText={'Cancel'}
          submitText={'Submit'}
          onClose={() => setOpenBalance(!openBalance)}
        >
          <BalanceView state={showData} handleChange={onChange} />
        </VegaDrawer> */}
      </VegaCard>
    </>
  );
};

export default ListOfCardTransaction;
