import { Box, Typography } from '@mui/material';
import React, { useRef, ChangeEvent } from 'react';
import { BLUE, GREEN } from '../../../../constants/style';
import VegaTextButton from '../../../../components/common/VegaTextButton';
import { UploadBulkIcon } from '../../../../components/icon/Icons';
import { IBulkUploadProps } from '../../../types/applicationManagement';
import VegaAspektaText from '../../../../components/common/VegaAspektaText';
import {
  FileValidator,
  FileValidatorResult,
} from '../../../../utils/FileValidator';

interface IProps {
  selectedFile: File | null;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error: string;
  bulkUploadData: IBulkUploadProps;
  fileValidator?: FileValidator;
  onError?: (errorMessage: string | null) => void;
}

const BulkUpload = ({
  selectedFile,
  handleFileChange,
  error,
  bulkUploadData,
  fileValidator,
  onError,
}: IProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (!fileValidator) {
      handleFileChange(event);
      return;
    }

    if (selectedFile) {
      const validationResult: FileValidatorResult =
        await fileValidator.validateFile(selectedFile);

      if (validationResult.isValid) {
        handleFileChange(event);
      } else {
        onError?.(validationResult.errorMessage);
      }
    }
  };

  return (
    <>
      <Box my={2} sx={{ width: '90%', mx: 'auto' }}>
        <label
          style={{
            border: selectedFile
              ? `2px dashed ${GREEN.dark}`
              : `2px dashed ${BLUE.lighter}`,
            borderRadius: '20px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100px',
          }}
          htmlFor="bulkUpload"
        >
          {selectedFile ? (
            <Box>
              <Typography sx={{ color: GREEN.dark, textAlign: 'center' }}>
                {selectedFile.name} uploded Successfully
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: GREEN.dark,
                  textAlign: 'center',
                }}
              >
                file size : {(selectedFile.size / 1024 / 1024).toFixed(2)} mb
              </Typography>
            </Box>
          ) : (
            <VegaTextButton
              text="Upload File"
              color={BLUE.lighter}
              onClick={handleButtonClick}
              startIcon={<UploadBulkIcon strokeColor={BLUE.lighter} />}
            />
          )}
        </label>
        <input
          type="file"
          id="bulkUpload"
          hidden
          ref={fileInputRef}
          accept=".csv, .xlsx"
          onChange={handleFileSelect}
        />
      </Box>
      <Box>
        {error.length ? (
          <Typography color={'red'} textAlign={'center'}>
            {error}
          </Typography>
        ) : (
          ''
        )}
        <Typography textAlign={'center'}>
          You can upload files in xlsx and csv format
        </Typography>
        {bulkUploadData.totalEntries !== null && (
          <Box textAlign={'center'}>
            <VegaAspektaText
              text={`${bulkUploadData.invalidEntriesCount}/${bulkUploadData.totalEntries} entries are  in invalid format`}
              fontSize={14}
              fontWeight={'500'}
              color="red"
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default BulkUpload;
