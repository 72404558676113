import React, { useEffect, useState } from 'react';
import VegaHeader from '../../common/VegaHeader';
import { BLUE, COMMON, PRIMARY } from '../../../constants/style';
import { toLowerCase } from '../../../constants/commonFunction';
import { Typography, IconButton, Stack, Box } from '@mui/material';
import BinTable from './BinTable';
import {
  AddCircleOutlineOutlined,
  AddOutlined,
  ArrowBackIos,
  KeyboardArrowDown,
} from '@mui/icons-material';
import VegaTextButton from '../../common/VegaTextButton';
import BinCardsDetails from './BinCardsDetails';
import VegaContainedButton from '../../common/VegaContainedButton';
import { AddPlusIcon, SuccessIcon } from '../../icon/Icons';
import VegaDialogV2 from '../../common/v2/VegaDialogV2';
import IncreaseCardRange from './IncreaseCardRange';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { IBinProgramListProps } from '../../../types/binType';
import { listBinAllocations } from '../../../api/bin';

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

interface IProps {
  loading: boolean;
}

interface IBinListProps {
  id: number;
  programName: string;
  totalCardRange: number;
  totalCardIssued: number;
  availableCardRange: number;
  status: string;
}

const dummyData = [
  {
    id: 1,
    programName: 'Kotak PVR Tickets 4x Rewards',
    totalCardRange: '25000',
    totalCardIssued: '10000',
    availableCardRange: '10000',
    status: 'LIVE',
  },
  {
    id: 2,
    programName: 'HDFC TATA 2x Rewards',
    totalCardRange: '25000',
    totalCardIssued: '10000',
    availableCardRange: '10000',
    status: 'DRAFT',
  },
];

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: 'id',
    header: 'S.No',
    width: '100px',
    minWidth: '100px',
  },
  {
    key: 'programName',
    header: 'Program Name',
    width: 'auto',
    minWidth: '250px',
  },

  {
    key: 'totalCardRange',
    header: 'Total Card Range',
    width: 'auto',
    minWidth: '150px',
  },

  {
    key: 'totalCardIssued',
    header: 'Total Card Issued',
    width: 'auto',
    minWidth: '150px',
  },
  {
    key: 'availableCardRange',
    header: 'Available Card Range',
    width: 'auto',
    minWidth: '150px',
  },

  {
    key: 'action',
    header: 'Action',
    width: 'auto',
    minWidth: '180px',
  },
  //   {
  //     key: 'action2',
  //     header: '',
  //     width: 1,
  //     minWidth: '50px',
  //   },
];

const cellStyles = {
  color: COMMON.darkGray,
  backgroundColor: 'white',
  fontSize: 12,
  padding: '0px 10px',
};

const ListOfBinProgram = () => {
  const { selecteddBinId, getBinDetails, binDetails } = useBinCharter();
  const [binProgramList, setBinProgramList] = useState<IBinListProps[]>([]);
  const [collapsedRows, setCollapsedRows] = useState<number[]>([]);
  const [isIncreaseCardRange, setIsIncreaseCardRange] =
    useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleCollapse = (rowId: any) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((id) => id !== rowId));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const getBgColor = (status: string) => {
    if (status === 'ACTIVE') {
      return '#5EA765';
    } else {
      return '#4AACE4';
    }
  };

  const toggleCardRangeModal = () => {
    setIsIncreaseCardRange(!isIncreaseCardRange);
  };
  const toggleSuccessModal = () => {
    setIsSuccess(!isSuccess);
  };

  const openIncreaseCardRange = (id: string | number) => {
    toggleCardRangeModal();
  };
  const onIncreaseCardRange = () => {
    toggleCardRangeModal();
    toggleSuccessModal();
  };

  const customizeRow = (column: IColumnProps, bin: IBinListProps) => {
    const idCellStyles = {
      color: COMMON.darkGray,
      fontSize: 12,
      // width: '100px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    };
    switch (column.key) {
      case 'id':
        return (
          <td
            className={`${
              !collapsedRows.includes(bin.id)
                ? 'customTD'
                : 'borderTopLeftRadius'
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Typography
              className="font-aspekta-400"
              sx={{ color: COMMON.darkGray, fontSize: 12 }}
            >
              {bin[column.key]}
            </Typography>
          </td>
        );
      case 'programName':
        return (
          <td
            className={`${
              !collapsedRows.includes(bin.id)
                ? 'customTD'
                : 'borderTopLeftRadius'
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={'row'} gap={2}>
              <Typography className="font-aspekta-400" fontSize={12}>
                {bin[column.key]}
              </Typography>
              <Typography
                className="font-aspekta-600"
                color={'white'}
                fontSize={8}
                borderRadius={'12px'}
                px={1}
                py={0.5}
                display={'flex'}
                alignItems={'center'}
                bgcolor={getBgColor(bin['status'])}
              >
                {bin['status']}
              </Typography>
            </Stack>
          </td>
        );
      case 'totalCardRange':
      case 'totalCardIssued':
      case 'availableCardRange':
        return (
          <td
            className={`${
              !collapsedRows.includes(bin.id)
                ? 'customTD'
                : 'borderTopLeftRadius'
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Typography className="font-aspekta-400" sx={idCellStyles}>
              {bin[column.key]}
            </Typography>
          </td>
        );
      case 'action':
        return (
          <td style={cellStyles} key={column.key}>
            <VegaTextButton
              text="Increase Card Range"
              color={PRIMARY.darkBlue}
              onClick={() => openIncreaseCardRange(bin.id)}
              startIcon={
                <AddCircleOutlineOutlined
                  sx={{
                    color: PRIMARY.darkBlue,
                    fontSize: 16,
                  }}
                />
              }
            />
          </td>
        );

      default:
        return null;
    }
  };

  const getRow = () => {
    const leadsData = binProgramList.map(
      (value: IBinListProps, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                borderBottom: collapsedRows.includes(value.id)
                  ? 'none'
                  : `5px solid #EBEFF4`,
              }}
            >
              {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
              <td
                className={`${
                  !collapsedRows.includes(value.id)
                    ? 'customTDLast'
                    : 'borderTopRightRadius'
                }`}
                style={cellStyles}
              >
                <IconButton onClick={() => toggleCollapse(value.id)}>
                  <KeyboardArrowDown
                    sx={{
                      transition: 'all 500ms ease-in-out',
                      ...(collapsedRows.includes(value.id) && {
                        rotate: '180deg',
                      }),
                    }}
                  />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td
                colSpan={12}
                style={{
                  marginBottom: 0,
                  padding: 0,
                  borderBottom: !collapsedRows.includes(value.id)
                    ? 'none'
                    : `5px solid #EBEFF4`,
                }}
              >
                {collapsedRows.includes(value.id) && <BinCardsDetails />}
              </td>
            </tr>
          </React.Fragment>
        );
      }
    );
    return leadsData;
  };

  const getHeader = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: '15px 10px',
            textAlign: 'start',
          }}
        >
          <Typography
            className="font-aspekta-500"
            color={COMMON.gray}
            fontSize={13}
          >
            {column.header}
          </Typography>
        </th>
      </>
    ));
    return headers;
  };

  const getBinProgramData = async (binId: number) => {
    const response = await listBinAllocations(binId);
    const data = response.data.records;
    if (data.length) {
      const newData = data.map((bin: IBinProgramListProps, index: number) => ({
        id: index + 1,
        programName: bin.programDto ? bin.programDto.name : '--',
        totalCardRange: bin.maxNoOfKits,
        totalCardIssued: bin.noOfKitsSold,
        availableCardRange: bin.noOfKitsAvailable,
        status: bin.programDto ? bin.programDto.status : '--',
      })) as IBinListProps[];
      setBinProgramList(newData);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (selecteddBinId) {
      setLoading(true);
      getBinProgramData(selecteddBinId);
    }
  }, [selecteddBinId]);

  return (
    <>
      <BinTable
        theadComponent={getHeader}
        rowComponent={getRow}
        loading={loading}
        data={binProgramList}
      />
      <VegaDialogV2
        title="Increase Card  Range"
        maxWidth="sm"
        fullWidth
        open={isIncreaseCardRange}
        handleClose={toggleCardRangeModal}
        buttonComponent={() => {
          return (
            <>
              <Stack
                direction={'row'}
                gap={1}
                justifyContent="end"
                alignItems={'center'}
                mb={2}
              >
                <VegaContainedButton
                  isPurple
                  text="Increase Range"
                  startIcon={<AddCircleOutlineOutlined />}
                  // loading={loading}
                  onClick={onIncreaseCardRange}
                  py={1}
                  // disabled={disabled == false}
                />
              </Stack>
            </>
          );
        }}
      >
        <IncreaseCardRange />
      </VegaDialogV2>
      <VegaDialogV2
        title=""
        maxWidth="xs"
        fullWidth
        open={isSuccess}
        handleClose={toggleSuccessModal}
        buttonComponent={() => {
          return <></>;
        }}
      >
        <Box textAlign={'center'}>
          <SuccessIcon />
          <Typography
            className="font-aspekta-600"
            fontSize={20}
            color={COMMON.darkGray}
          >
            Your card range is successfully increased
          </Typography>
          <Typography
            className="font-aspekta-600"
            fontSize={8}
            color={'#7B8694'}
            mt={1}
          >
            Increased Card Range: 634667
          </Typography>
        </Box>
      </VegaDialogV2>
    </>
  );
};

export default ListOfBinProgram;
