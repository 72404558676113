import { Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';
import { VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import { VegaToolTipAndCopy } from '../../../../components/common/VegaToolTipNCopy';
import { IConsolidatedAccountData } from '../../../types/Commons';
import {
  IReloadCardData,
  IReloadCardRequest,
  PAYMENT_MODE,
  VALUE_TYPE,
} from './Card';
import ReloadCardDetails from './ReloadCardDetails';

interface IProps {
  listOfCorporateAccounts: Array<IConsolidatedAccountData>;
  handleReloadAccount: (data: IReloadCardRequest) => void;
  setBulkSelections: React.Dispatch<React.SetStateAction<string[]>>;
  reloadCardData: IReloadCardData;
  setReloadCardData: React.Dispatch<React.SetStateAction<IReloadCardData>>;
  setInitialReloadData: (data?: IConsolidatedAccountData) => IReloadCardData;
  handleReloadDataChange: (
    key: keyof IReloadCardData,
    value: string | PAYMENT_MODE | VALUE_TYPE | number
  ) => void;
}

function ListOfCorporateCustomerForCardReload({
  listOfCorporateAccounts,
  handleReloadAccount,
  setBulkSelections,
  reloadCardData,
  setReloadCardData,
  setInitialReloadData,
  handleReloadDataChange,
}: IProps) {
  const [open, setOpen] = useState<boolean>(false);

  columnDef[columnDef.length - 1].renderCell = (props: any) => {
    return (
      <VegaContainedButton
        text="Reload"
        onClick={() => openReloadTab(props.row)}
        isPrimary
      />
    );
  };

  const openReloadTab = (consolidatedAccountData: IConsolidatedAccountData) => {
    setReloadCardData(setInitialReloadData(consolidatedAccountData));
    setOpen(!open);
  };

  const clearHandle = () => {
    setInitialReloadData();
    setOpen(false);
  };
  const submitHandle = () => {
    const { customerName, programName, paymentMode, ...rest } = reloadCardData;
    handleReloadAccount(rest);
    setOpen(false);
  };

  const rowSelectHandler = (selections: string[]) => {
    setBulkSelections(selections);
  };
  return (
    <>
      <VegaTable
        data={listOfCorporateAccounts}
        columnDef={columnDef}
        idColumn={'id'}
        // loading={loading}
        checkboxSelection
        disableSelectionOnClick
        onRowSelect={(e) => rowSelectHandler(e)}
      />

      <VegaDrawer
        open={open}
        header={'Reload Card'}
        closeText="Clear"
        isClose
        isSubmit
        submitText="Submit"
        onClose={clearHandle}
        onSubmit={submitHandle}
      >
        <ReloadCardDetails
          reloadCardData={reloadCardData}
          handleReloadDataChange={handleReloadDataChange}
        />
      </VegaDrawer>
    </>
  );
}
export default ListOfCorporateCustomerForCardReload;

const columnDef: GridColumns<IConsolidatedAccountData> = [
  {
    field: 'accountId',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Account Id
      </Typography>
    ),
    flex: 1.3,
    renderCell: (props) => {
      return <VegaToolTipAndCopy value={props.row.account?.id || ''} />;
      //   return <VegaToolTipAndCopy value={'sdlkfjndsbkf'} />;
    },
  },
  {
    field: 'customerName',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Customer Name
      </Typography>
    ),
    flex: 0.8,
    renderCell: (props) => {
      return <VegaToolTipAndCopy value={props.row.customer?.firstName || ''} />;
    },
  },
  {
    field: 'cardNo',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Card Number
      </Typography>
    ),
    flex: 1,
    renderCell: (props) => {
      return (
        <VegaToolTipAndCopy
          value={'**** **** ' + (props.row.card?.lastFourDigits || '****')}
        />
      );
    },
  },
  {
    field: 'expiryDate',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Expiry Date
      </Typography>
    ),
    flex: 0.8,
    renderCell: (props) => {
      return <VegaToolTipAndCopy value={props.row.card?.expiryDate || ''} />;
    },
  },
  {
    field: 'cardStatus',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Card Status
      </Typography>
    ),
    flex: 0.6,
    renderCell: (props) => {
      return <VegaToolTipAndCopy value={props.row.card?.cardStatus || ''} />;
    },
  },
  {
    field: 'activationDate',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Activation Date
      </Typography>
    ),
    flex: 0.8,
    renderCell: (props) => {
      return (
        <VegaToolTipAndCopy
          value={dayjs(props.row.card?.createdAt || dayjs()).format(
            'MMMM D, YYYY'
          )}
        />
      );
    },
  },
  {
    field: 'cardNetwork',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Card Network
      </Typography>
    ),
    flex: 0.8,
    renderCell: (props) => {
      return <VegaToolTipAndCopy value={props.row.card?.cardNetwork || ''} />;
    },
  },
  {
    field: 'action',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Action
      </Typography>
    ),
    flex: 0.7,
  },
];
