/** @format */

import { Button, Grid, Typography } from '@mui/material';
import { PRIMARY } from '../../constants/style';
import { VegaCard } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaDatePicker from '../common/VegaDatePicker';

interface IBillsProps {
  onCreate: () => void;
  setBillDate: React.Dispatch<React.SetStateAction<any>>;
  billDate: any;
  isLoading: boolean;
}

const CreateBill = ({ setBillDate, billDate, onCreate,isLoading }: IBillsProps) => {
  return (
    <VegaCard>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Create Bill
      </Typography>

      <Grid container spacing={2} columns={24}>
        <Grid item xs={12} sm={5}>
          <VegaDatePicker
            value={billDate}
            onChange={(e) => setBillDate(e)}
            key="bill0-date"
            label="Date"
            placeholder=""
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'flex-end' },
        }}
      >
        <VegaContainedButton text='Create' loading={isLoading}  onClick={onCreate} isPrimary />
      </Grid>
    </VegaCard>
  );
};

export default CreateBill;
