export interface IPLanTypeProps {
  id?: string;
  clientId: string | any;
  planName: string;
  planType: string;
  planDescription?: string;
  isDefault: boolean;
  effectiveStartTime: string | null;
  effectiveEndTime: string | null;
  status: string;
  updatedAt?: string;
}
export interface INewPLanTypeProps {
  id?: string;
  clientId: string | any;
  policy_name: string;
  policy_type: string;
  policy_description?: string;
  is_default: boolean;
  start_date: string | null;
  end_date: string | null;
  status: string;
  updatedAt?: string;
  waterfall_allowed?: boolean;
}

export interface IPlanAllocationProps {
  planId: string;
  entityType: string;
  entityId: string;
}
export type AnyPlanTypes = IMarkupFeeProps | IOpenCreditCharge;
export type AnyPlans = IPLanTypeProps | IOpenCreditCharge;

export interface IFeesProps {
  valueType: string;
  value: number;
}
export interface IWaiverProps {
  value: number;
  dailyLimit: number;
  monthlyLimit: number;
}
export interface IMarkupFeeComponent {
  transactionCode: string;
  fee: IFeesProps;
  waiver: IWaiverProps;
}

export interface IMarkupFeeProps extends IPLanTypeProps {
  markupFeeComponentList: IMarkupFeeComponent[];
}
export interface IChargeRateRule {
  interest_rate_value_type: string;
  default_rate: number;
  [key: string]: string | number;
}

export interface IOpenCreditCharge extends IPLanTypeProps {
  roundingMethod: string;
  dayCountMethod: string;
  chargeRateValue: IChargeRateRule;
  [key: string]: any;
}
export const RoundingMethodArr = ['NEAR', 'ROUND_UP', 'ROUND_DOWN'];
export const DayCountMethodArr = [
  'ACTUAL_BY_ACTUAL',
  'ACTUAL_BY_365',
  'ACTUAL_BY_360',
];
export const InterestRateValueTypeArr = ['EQUAL', 'AMOUNT_SLAB_BASED'];
export enum RoundingMethod {
  NEAR = 'NEAR',
  ROUND_UP = 'ROUND_UP',
  ROUND_DOWN = 'ROUND_DOWN',
}
export enum DayCountMethod {
  ACTUAL_BY_ACTUAL = 'ACTUAL_BY_ACTUAL',
  ACTUAL_BY_365 = 'ACTUAL_BY_365',
  ACTUAL_BY_360 = 'ACTUAL_BY_360',
}
export enum InterestRateValueType {
  EQUAL = 'EQUAL',
  AMOUNT_SLAB_BASED = 'AMOUNT_SLAB_BASED',
}
