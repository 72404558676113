/* eslint-disable react/jsx-pascal-case */
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardIndentationOrderDto,
  CardIndentationOrderStatus,
  UpdateCardIndentationOrderStatusRequest,
} from '../../../../types/card';
import { CardAllocationService } from '../../../apis';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import { useMutation } from 'react-query';
import { useCardIndentationOrder } from '../context';

type MarkAsAcknowledgedProps = {
  isOpen: boolean;
  handleClose: () => void;
  orderData?: CardIndentationOrderDto;
};

function MarkAsAcknowledged({
  isOpen,
  handleClose,
  orderData,
}: MarkAsAcknowledgedProps) {
  const { setSnackbar } = useSnackbar();
  const { listIndentationOrderQuery } = useCardIndentationOrder();

  const updateAllocationStatusMutation = useMutation({
    mutationFn: (data: UpdateCardIndentationOrderStatusRequest) => {
      return CardAllocationService.updateCardIndentationOrderCardIndentationStatus(
        data
      );
    },
    onSuccess: () => {
      listIndentationOrderQuery?.refetch();
      setSnackbar('Successfully updated the request');
      handleClose();
    },
    onError: (error) => {
      setSnackbar('Error while updating status', 'error');
      handleClose();
    },
  });

  const onSubmit = () => {
    if (orderData) {
      updateAllocationStatusMutation.mutate({
        cardIndentationOrderId: orderData?.id,
        status: CardIndentationOrderStatus.ACCEPTED,
      });
    }
  };

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Cancel',
      onSubmit: () => {
        handleClose();
      },
      icon: '',
      isOutline: true,
    },
    {
      text: 'Mark as Acknowledged',
      onSubmit: onSubmit,
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <CIMS_Modal
      maxWidth="sm"
      open={isOpen}
      handleClose={handleClose}
      header="Mark as Acknowledged"
      buttonGroup={buttons}
    >
      <Stack sx={{ px: 3, pt: 2, pb: 6 }} spacing={1}>
        <Typography fontSize={13}>
          Are you sure you want to mark this request as acknowledged?
        </Typography>
      </Stack>
    </CIMS_Modal>
  );
}

export default MarkAsAcknowledged;
