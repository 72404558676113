import { Box, Button, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { VegaTextField } from '../components/common';

import { ArrowBack } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePassword from '../hooks/usePassword';
import { useSnackbar } from '../providers/SnackbarProvider';
import { ROUTES } from '../routes';
import { revokeToken } from '../api/roleManagement';
import { useClientAuth } from '../providers/ClientProvider';

function ResetPassword() {
  const { logout } = useClientAuth();
  const { password, setPassword, renderValidations, isValidPassword } =
    usePassword();
  const [state, setState] = useState({
    oldPassword: '',
    // newPassword: '',
  });

  const navigation = useNavigate();
  const { setSnackbar } = useSnackbar();
  const isDissabled = () => {
    return !state.oldPassword || !isValidPassword();
  };

  const changeHandler = (key: keyof typeof state, value: string) => {
    setState((pre) => ({
      ...pre,
      [key]: value,
    }));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const user = await Auth.currentAuthenticatedUser();
      const changePasswordResult = await Auth.changePassword(
        user,
        state.oldPassword,
        password
      );
      setSnackbar('Updated Successfully');
      logout();
    } catch (error) {
      console.log(error);
      setState({
        oldPassword: '',
      });
      setSnackbar('Failed to change password', 'error');
    }
  };

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Card
        sx={{
          minWidth: 345,
        }}
      >
        <CardContent>
          <Stack>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <IconButton
                onClick={() => {
                  navigation(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography
                variant="subtitle2"
                textAlign={'center'}
                sx={{
                  width: '100%',
                }}
              >
                Reset Password
              </Typography>
            </Stack>

            <Box component={'form'} onSubmit={onSubmit} mt={2} p={2}>
              <Stack rowGap={2}>
                <Box>
                  <Typography>Old Password</Typography>
                  <VegaTextField
                    type="password"
                    key={'login-old-pass'}
                    value={state.oldPassword}
                    onChange={(e) =>
                      changeHandler('oldPassword', e.target.value)
                    }
                  />
                </Box>
                <Box>
                  <Typography>New Password</Typography>
                  <VegaTextField
                    type="password"
                    key={'login-new-pass'}
                    value={password}
                    onChange={(e) =>
                      //   changeHandler('newPassword', e.target.value)
                      setPassword(e.target.value)
                    }
                  />
                </Box>
              </Stack>
              <Stack mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: '#7081D8' }}
                  disabled={isDissabled()}
                >
                  Submit
                </Button>
              </Stack>
              <Box mt={1} maxWidth={400}>
                {renderValidations()}
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default ResetPassword;
