import { configureStore } from '@reduxjs/toolkit';
import stateSlice from './common/stateSlice';
import programManagementSlice from './common/programManagementSlice';
import accountClosureSlice from './common/accountClosureSlice';
import authSlice from './CIMS/authSlice';

export const store = configureStore({
  reducer: {
    header: stateSlice.reducer,
    programManagement: programManagementSlice.reducer,
    accountClosure: accountClosureSlice,
    cimsAuth: authSlice.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
