import { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import AllocatePolicies from '../components/MCCProgramAllocation/AllocatePolicies';
import ListOfAllocatePolicies from '../components/MCCProgramAllocation/ListOfAllocatePolicies';

import { useClientAuth } from '../../../providers/ClientProvider';
import {
  addPlanAllocation,
  fetchALlPlanAllocation,
} from '../api/PlanAllocation';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

const DEFAULT_DATA = {
  entityId: '',
  MARKUP_FEE_PLAN: '',
  TRANSACTION_LIMIT_PLAN: '',
  WALLET_CONFIGURATION_PLAN: '',
  CHARGES_PLAN: '',
};

export const getValidation = (state: any) => {
  let errorMsg: any = {
    error: {},
    isValid: true,
  };
  if (!state.entityId) {
    errorMsg.isValid = false;
    errorMsg.error['entityId'] = 'Please select program';
  }
  if (!state.MARKUP_FEE_PLAN) {
    errorMsg.isValid = false;
    errorMsg.error['MARKUP_FEE_PLAN'] = 'Please select markup plan';
  }
  if (!state.TRANSACTION_LIMIT_PLAN) {
    errorMsg.isValid = false;
    errorMsg.error['TRANSACTION_LIMIT_PLAN'] = 'Please select transaction plan';
  }
  if (!state.WALLET_CONFIGURATION_PLAN) {
    errorMsg.isValid = false;
    errorMsg.error['WALLET_CONFIGURATION_PLAN'] =
      'Please select wallet cofiguration plan';
  }
  if (!state.CHARGES_PLAN) {
    errorMsg.isValid = false;
    errorMsg.error['CHARGES_PLAN'] = 'Please select charges plan';
  }

  return errorMsg;
};

const MCCProgramAllocation = () => {
  const { setSnackbar } = useSnackbar();

  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<any>({});
  const [planAllocationList, setPlanAllocationList] = useState<any>([]);
  const [mapProgram, setMapProgram] = useState<any>({ ...DEFAULT_DATA });
  const [programsList, setProgram] = useState<any[]>([]);

  const handleChange = (name: string, value: any) => {
    console.log('name,handleChange value', name, value);
    setMapProgram({ ...mapProgram, [name]: value });
  };
  const onMapProgram = async () => {
    const validation = getValidation(mapProgram);
    setErrorMsg(validation.error);
    if (validation.isValid) {
      setLoading(true);
      await Object.keys(mapProgram)
        .map((value: string) => {
          const newObj = {
            planId: mapProgram[value],
            planType: value,
            entityType: 'PROGRAM',
            entityId:
              mapProgram.entityId ?? '0c7845c8-25d7-428b-9236-bfa06f264e3f',
          };
          return newObj;
        })
        .filter((value: any) => value?.planType !== 'entityId')
        .map((value: any) => {
          addPlanAllocation(value)
            .then((res) => {
              setLoading(false);
            })
            .then(() => {
              setSnackbar('Plan Allocated!', 'success');
            })
            .catch((err) => {
              setLoading(false);
              setSnackbar(getErrorMessageFromErrorObj(err), 'error');
            });
          setMapProgram({ ...DEFAULT_DATA });
        });
      getPlanAllocationList();
    }
  };

  const getPlanAllocationList = () => {
    fetchALlPlanAllocation(clientId as string)
      .then((res) => {
        const filterData = res.data.programPlanDtoList.filter(
          (val: any) => val.planDtoList.length > 0
        );
        setPlanAllocationList(filterData);
      })
      .catch((err) => {
        // setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  useEffect(() => {
    getPlanAllocationList();
  }, []);

  return (
    <VegaPage>
      <AllocatePolicies
        state={mapProgram}
        handleChange={handleChange}
        onMapProgram={onMapProgram}
        loading={loading}
        errorMsg={errorMsg}
      />
      <ListOfAllocatePolicies data={planAllocationList} />
    </VegaPage>
  );
};

export default MCCProgramAllocation;
