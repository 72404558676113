import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: (e: any) => void;
}

interface IDialogProps extends DialogProps {
  handleClose: (e: any) => void;

  open: boolean;
  title: string;
  children: JSX.Element;
  buttonComponent: () => React.ReactNode;
}

function DialogTitleBox(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close
            sx={{
              color: '#3A4A5F',
              fontSize: 16,
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const VegaDialogV2 = ({
  handleClose,
  open,
  children,
  title,
  buttonComponent,
  ...rest
}: IDialogProps) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...rest}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitleBox id="customized-dialog-title" onClose={handleClose}>
        <Stack>
          <Typography fontSize={13} className="font-aspekta-500">
            {title}
          </Typography>
        </Stack>
      </DialogTitleBox>
      <DialogContent
        sx={{
          px: 2,
          pb: 2,
        }}
      >
        {children}
      </DialogContent>
      {buttonComponent && (
        <DialogActions
          sx={{
            pb: 2,
          }}
        >
          {buttonComponent()}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default VegaDialogV2;
