import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import VegaText from '../../../../../../components/common/VegaText';
import VegaSelect from '../../../../../../components/common/VegaSelect';
import { VegaTextField } from '../../../../../../components/common';
import { IFeeDetailsProps } from '../Charges';
import { IChareRulesProps } from './ChargesCard';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import VegaLabel from '../../../../../../components/common/VegaLabel';
import {
  BLUE,
  BORDER_GRAY,
  BORDER_GRAY_DARK,
} from '../../../../../../constants/style';

const FixedAllCurrencyChargeDetails = ({
  rule,
  code,
  handleChange,
}: IChareRulesProps) => {
  const handleCharges = (name: string, value: any) => {
    let newCharge = { ...rule['chargeValue'], [name]: value };
    handleChange('chargeValue', newCharge);
  };
  const handleValue = (name: string, value: any) => {
    const chargeValue = { ...rule['chargeValue'] };

    const valueObj = {
      ...chargeValue['value'],
      [name]: value,
    };
    const newObj = {
      ...rule['chargeValue'],
      value: valueObj,
    };
    handleChange('chargeValue', newObj);
  };
  return (
    <Box sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={2} px={1}>
          <Box sx={{ mb: 2 }}>
            <VegaText text="Value Type" />
          </Box>
          <VegaTextField
            isFixedHeight
            value={toLowerCase(rule?.chargeValue?.valueType) ?? ''}
            onChange={(e) => handleCharges('valueType', e)}
            key="valueType"
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={3} px={1}>
          <Box sx={{ mb: 2 }}>
            <VegaText text="Tax Type" />
          </Box>
          <VegaSelect
            isFixedHeight
            size="small"
            key={'taxType'}
            selected={rule?.taxType ?? ''}
            onChange={(value: string) => handleChange('taxType', value)}
            options={['INCLUSIVE_OF_TAXES', 'EXCLUSIVE_OF_TAXES', 'NO_TAX']}
          />
        </Grid>
      </Grid>
      <Grid container my={2}>
        {Object.keys(rule?.chargeValue?.value).map((val: string) => (
          <Grid item xs={12} sm={3} px={1} mb={2}>
            <Box
              sx={{
                position: 'relative',
                borderRadius: '9px',
                border: BORDER_GRAY,
                display: 'flex',
                alignItems: 'center',
                height: '32px',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <VegaLabel text={val} color="primary" />
              </Box>
              <Box sx={{ mx: 1 }}>
                <input
                  className="customInput"
                  // isFixedHeight
                  value={rule?.chargeValue?.value[val] ?? ''}
                  onChange={(e) =>
                    handleValue(
                      val,
                      e.target.value && parseFloat(e.target.value)
                    )
                  }
                  key={val}
                  name={val}
                  type="number"
                />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FixedAllCurrencyChargeDetails;
