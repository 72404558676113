import { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import VegaSelector from '../../../components/common/VegaSelector';
import { CreditCardIcon, UserIcon } from '../../../components/icon/Icons';
import { BACKGROUND } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { CHECKER_MAKER_STATUS } from '../../types/Commons';
import {
  allocateCard,
  createAccount,
  fetchAccountList,
  generateOTPForCard,
  initiateCheckerMakerForAccount,
  updateCheckerMakerStatusForAccount,
} from '../api/account';
import MCCFilterSection from '../components/MCCUserManagement/MCCFilterSection';
import ListOfCardSaleMaker from '../components/MCCcardSaleMaker/ListOfCardSaleMaker';
import { ISectionProps } from './MCCUserManagement';

let tileData = [
  {
    id: 'KIT',
    text: 'Kit',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'PERSONALISED_SALE',
    text: 'Personalised Sale',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'REISSUE',
    text: 'Reissue',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];

const PERSONALISED_DATA = [
  {
    id: 1,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'APPROVED',
  },
  {
    id: 2,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'REJECTED',
  },
  {
    id: 3,

    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'PENDING',
  },
];
const REISSUE_DATA = [
  {
    id: 1,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'APPROVED',
  },
  {
    id: 2,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'REJECTED',
  },
  {
    id: 3,
    kitNo: 'GDYG37287382',
    name: 'Ronit Bhandari',
    programName: 'Ebix Cash 1',
    customerId: 'ABCD1234',
    cardNo: '1234 5678 **** ',
    expiryDate: '21/09/2022',
    activationDate: '21/09/2022',
    status: 'PENDING',
  },
];

const MCCcardSaleMaker = () => {
  const { setSnackbar } = useSnackbar();
  const { userId, mccPlatformId: branchId } = useClientAuth();
  const [accountData, setAccountData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({});
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [isMapCardShow, setIsMapCardShow] = useState<boolean>(false);
  const [hide, setHide] = useState<boolean>(false);
  const [activeState, setActiveState] = useState<ISectionProps>(tileData[0]);

  const handleChange = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const selectedTab = (data: any) => {
    setActiveState(data);
    setAccountData([]);
  };

  const onCreate = (data: any) => {
    const obj = {
      customerId: data.customer.customerId,
      branchId: data.customer.branchId,
      programId: data.programId,
      corporateCustomerId: data.corporateCustomerId,
    };

    createAccount(obj)
      .then((res) => {
        setSnackbar('Account Created!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to create account'),
          'error'
        );
      });
  };
  const onGenerateOTP = (data: any) => {
    setHide(true);
    const obj = {
      binId: data.binId,
      accountId: data.id,
    };

    generateOTPForCard(obj)
      .then((res) => {
        setSnackbar('OTP generated successfully for card!!');
        getCustomerList();
        setIsMapCardShow(true);
      })
      .catch((err) => {
        setIsMapCardShow(false);
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to generate the OTP'),
          'error'
        );
      });
    // setTimeout(() => {
    //   setIsMapCardShow(false);
    //   setHide(false);
    // }, 30000);
  };

  const onAllocateCard = (data: any) => {
    const obj = {
      binId: data.binId,
      accountId: data.id,
      otp: data.otp,
    };

    allocateCard(obj)
      .then((res) => {
        setSnackbar('Card allocate successfully !!');
        getCustomerList();
        setIsMapCardShow(false);
      })
      .catch((err) => {
        setIsMapCardShow(false);
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to allocate card'),
          'error'
        );
      });
  };

  const onCheckerStatus = (data: any) => {
    const obj = {
      userId,
      entityId: data.id,
    };

    initiateCheckerMakerForAccount(obj)
      .then((res) => {
        setSnackbar('Send Checker successfully!!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send checker'),
          'error'
        );
      });
  };
  const onUpdateCheckerStatus = (
    data: any,
    status: CHECKER_MAKER_STATUS = CHECKER_MAKER_STATUS.CHECKER_PENDING
  ) => {
    const obj = {
      status: status,
      entityId: data.id,
      userId,
    };
    console.log('obj', obj);
    updateCheckerMakerStatusForAccount(obj)
      .then((res) => {
        setSnackbar('Send Checker successfully!!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send checker'),
          'error'
        );
      });
  };
  const getCustomerList = async () => {
    setListLoading(true);
    if (activeState.id === 'PERSONALISED_SALE') {
      setAccountData([...PERSONALISED_DATA]);
      setListLoading(false);
    } else if (activeState.id === 'REISSUE') {
      setAccountData([...REISSUE_DATA]);
      setListLoading(false);
    } else {
      fetchAccountList({
        value: filterData.mobile ? filterData.mobile : branchId,
        page: 0,
        pageSize: 50,
      })
        .then((res) => {
          const getData = res.data?.records?.map((value: any) => ({
            ...value,
            id: value?.account?.id ?? value?.customer?.customerId,
          }));
          setAccountData(getData);

          setListLoading(false);
        })
        .catch((err) => {
          setListLoading(false);
        });
    }
  };
  useEffect(() => {
    getCustomerList();
  }, [activeState]);
  return (
    <VegaPage>
      <VegaSelector
        data={tileData}
        setActiveState={selectedTab}
        active={activeState}
        header="New Card Sale"
        subHeader="Issue New Card"
      />
      <MCCFilterSection
        state={filterData}
        handleChange={handleChange}
        onSearch={getCustomerList}
        isSearch
      />
      <ListOfCardSaleMaker
        onCheckerStatus={onCheckerStatus}
        onUpdateCheckerStatus={onUpdateCheckerStatus}
        data={accountData}
        active={activeState}
        loading={listLoading}
        onCreate={onCreate}
        onGenerateOTP={onGenerateOTP}
        onAllocateCard={onAllocateCard}
        isMapCardShow={isMapCardShow}
        hide={hide}
      />
    </VegaPage>
  );
};

export default MCCcardSaleMaker;
