/** @format */

import axios from 'axios';
import {
  AddUserWithRolesPayloadType,
  VegaUser,
  VegaUserStatus,
} from '../types/user';
import {
  AddCognitoUserForClientParams,
  FetchRolesFilterType,
  IRolesProps,
  IUserPaginatedReqProps,
  IUsersProps,
  ResourceWithAccess,
  Role,
  RoleStatus,
} from '../types/userAccess';
import { BASE_URL, ROLE_MANAGEMENT, UAM } from './endpoints';
import { PaginatedResponse } from '../utils/api';

const ROLE_MANAGEMENT_BASE_URL = BASE_URL + ROLE_MANAGEMENT.BASE;

export const fetchListOfUsersForClient = (
  clientId: string | null,
  includeRolesAndResources = false
) => {
  return axios.get(
    `${ROLE_MANAGEMENT_BASE_URL}/listUsersByClient/${clientId}`,
    { params: { includeRolesAndResources } }
  );
};

export const fetchPaginatedListOfUsers = ({
  clientId,
  page,
  size,
}: IUserPaginatedReqProps) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/listUsers`, {
    clientId,
    page,
    size,
  });
};

export const fetchListOfRolesForClient = (clientId: string | null) => {
  return axios.get(
    `${ROLE_MANAGEMENT_BASE_URL}/listRolesForClient/${clientId}`
  );
};
export const addUserForClient = (user: IUsersProps) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/addUser`, {
    ...user,
    platformId: user.clientId,
    mccUser: false,
    officialEmailId: user.email,
    status: 'ACTIVE',
    isAdmin: true,
    apiScope: {
      currrentLevel: 'CLIENT',
      currentLevelValue: user.clientId,
      levelHierarchy: {
        clientId: user.clientId,
      },
    },
  });
};
export const addRoleForClient = (data: IRolesProps) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/addRole`, data);
};
export const updateRoleForClient = (data: any) => {
  return axios.put(`${ROLE_MANAGEMENT_BASE_URL}/updateRole`, data);
};

export const addRoleForUser = (data: any) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/addRoleForUser`, data);
};
export const updateRoleForUser = (data: any) => {
  return axios.put(`${ROLE_MANAGEMENT_BASE_URL}/updateRoleForUser`, data);
};
export const updateUserStatus = async (data: {
  userId: string;
  status: VegaUserStatus;
}) => {
  const response = await axios.patch(
    `${ROLE_MANAGEMENT_BASE_URL}/user/status`,
    data
  );
  return response.data as VegaUser;
};

export async function getUserByEmail(data: {
  email: string;
}): Promise<VegaUser> {
  const endpoint =
    ROLE_MANAGEMENT_BASE_URL +
    ROLE_MANAGEMENT.GET_BY_EMAIL.replace('{email}', data.email);
  try {
    const response = await axios.get(endpoint);
    return response.data as VegaUser;
  } catch (error) {
    throw error;
  }
}

export async function revokeToken(refreshToken: string) {
  try {
    const endpoint =
      ROLE_MANAGEMENT_BASE_URL + ROLE_MANAGEMENT.REVOKE_REFRESH_TOKEN;
    const response = await axios.post(endpoint, { token: refreshToken });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addCognitoUserForClient(
  data: AddCognitoUserForClientParams
): Promise<VegaUser> {
  const endpoint = ROLE_MANAGEMENT_BASE_URL + ROLE_MANAGEMENT.ADD_COGNITO_USER;
  try {
    const response = await axios.post(endpoint, data);
    return response.data as VegaUser;
  } catch (error) {
    throw error;
  }
}

export const addOrUpdateRolesForUser = (updateUserRoleRequest: any) => {
  return axios.put(
    `${ROLE_MANAGEMENT_BASE_URL}${ROLE_MANAGEMENT.UPDATE_ROLE_FOR_USER}`,
    updateUserRoleRequest
  );
};

////////////////////// Bulk Upload \\\\\\\\\\\\\\\\\\\\
export const bulkProcessForUser = (formData: any) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/bulk/user/process`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const bulkSaveForUser = (data: any) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/bulk/user/save`, data);
};

export const fetchPastUploadForUser = (clientId: string) => {
  return axios.post(
    `${ROLE_MANAGEMENT_BASE_URL}/bulk/past/uploads/${clientId}`
  );
};

export const addUserWithRole = (data: AddUserWithRolesPayloadType) => {
  return axios.post(
    `${ROLE_MANAGEMENT_BASE_URL}${ROLE_MANAGEMENT.ADD_USER_WITH_ROLES}`,
    data
  );
};

////////////////////// Bulk Upload \\\\\\\\\\\\\\\\\\\\
const UAM_BASE_URL = BASE_URL + UAM.BASE;

export class RoleManagementService {
  static async getAllRolesForClient(clientId: string) {
    const { data } = await axios.get<Role[]>(`${UAM_BASE_URL}/role/all`, {
      headers: {
        'x-client-id': clientId,
      },
    });
    return data;
  }

  static async getAllRolesForClientWithFilter(body: FetchRolesFilterType) {
    const endpoint = UAM_BASE_URL + UAM.FETCH_ROLES_WITH_FILTER;
    const { data } = await axios.post<PaginatedResponse<Role>>(endpoint, body);
    return data;
  }

  static async fetchRole(clientId: string, roleId: string) {
    const endpoint = UAM_BASE_URL + UAM.FETCH_ROLE.replace('{roleId}', roleId);

    const { data } = await axios.get<Role>(endpoint, {
      headers: {
        'x-client-id': clientId,
      },
    });
    return data;
  }

  static async fetchResourceAccessOfRole(clientId: string, roleId: string) {
    const endpoint =
      UAM_BASE_URL + UAM.FETCH_ROLE_RESOURCES.replace('{roleId}', roleId);

    const { data } = await axios.get<ResourceWithAccess[]>(endpoint, {
      headers: {
        'x-client-id': clientId,
      },
    });
    return data;
  }

  static updateRoleStatus(
    clientId: string,
    roleId: string,
    roleStatus: RoleStatus
  ) {
    const endpoint =
      UAM_BASE_URL + UAM.UPDATE_ROLE_STATUS.replace('{roleId}', roleId);
    return axios.put(endpoint, null, {
      params: { status: roleStatus },
    });
  }
}
