/* eslint-disable react/jsx-pascal-case */
import { Box } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import Vega_Datagrid from '../../../../../components/common/v3/Vega_Datagrid';
import Vega_Text from '../../../../../components/common/v3/Vega_Text';
import { GREEN, RED } from '../../../../../constants/style';

export interface InvoicePorps {
  clientId: string;
  year: number;
  month: number;
  invoiceS3Link: string;
}
const dailyProfitLoss = [
  {
    date: '12/09/23',
    activeAccount: 2,
    income: 52000,
    cost: 12000,
    gst: 12312,
    id: 1,
  },
  {
    date: '12/09/23',
    activeAccount: 4,
    income: 52000,
    cost: 62000,
    gst: 12312,
    id: 2,
  },
];

const ListOfDailyProfitLoss = () => {
  return (
    <>
      <Box mt={2}>
        <Vega_Datagrid
          data={dailyProfitLoss}
          idColumn="id"
          columns={COLUMN_DEF}
          classes={{ row: 'customRow' }}
          loading={false}
        />
      </Box>
    </>
  );
};

export default ListOfDailyProfitLoss;

const COLUMN_DEF: GridColumns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'activeAccount',
    headerName: 'Total Active Accounts',
    flex: 1,
    minWidth: 100,
  },

  {
    field: 'income',
    headerName: 'Income',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    flex: 1,
    minWidth: 100,
  },

  {
    field: 'profitLoss',
    headerName: 'P&L',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      const income = props.row.income;
      const cost = props.row.cost;
      const pnl = income - cost;
      return (
        <Vega_Text
          text={pnl}
          fontColor={income < cost ? RED.dark : GREEN.dark}
        />
      );
    },
  },
  {
    field: 'gst',
    headerName: 'GST Collected',
    flex: 1,
    minWidth: 100,
  },
];
