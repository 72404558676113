import {
  ButtonProps as MuiButtonProps,
  Button as MuiButton,
  CircularProgress,
} from '@mui/material';
import { ActionMediumTypo } from '../common/Typography';
import { BACKGROUND, PRIMARY } from '../../constants/style';

interface IButtonProps extends MuiButtonProps {
  isLoading?: boolean;
  loadComponent?: React.ReactElement;
}

const Button = (props: IButtonProps) => {
  const {
    children,
    value,
    variant,
    sx,
    disableRipple,
    isLoading,
    loadComponent,
  } = props;
  const backgroundColor = getBGColor(variant);
  const opacity = getOpacity(props.disabled);
  const color = getTextColor(variant);
  const padding = getPadding(variant);

  return (
    <MuiButton
      {...props}
      variant={variant || 'contained'}
      disableRipple={disableRipple ?? true}
      sx={{
        backgroundColor,
        opacity,
        color,
        padding,
        width: 'fit-content',
        borderRadius: '4px',
        textTransform: 'none',
        boxShadow: 'none',
        ':hover': {
          backgroundColor,
          boxShadow: 'none',
        },
        ':disabled': {
          backgroundColor,
          color,
        },
        ...sx,
      }}
    >
      {isLoading ? (
        loadComponent || (
          <CircularProgress
            sx={{ width: '15px !important', height: '15px !important' }}
          />
        )
      ) : (
        <ActionMediumTypo>{children || value}</ActionMediumTypo>
      )}
    </MuiButton>
  );
};

const getBGColor = (variant: MuiButtonProps['variant']) => {
  switch (variant) {
    case 'contained':
      return '#1047DC';
    default:
      return 'transparent';
  }
};

const getOpacity = (disabled?: boolean) => {
  return disabled ? 0.5 : 1;
};

const getTextColor = (variant: MuiButtonProps['variant']) => {
  switch (variant) {
    case 'contained':
      return BACKGROUND.WHITE;
    default:
      return '#1047DC';
  }
};

const getPadding = (variant: MuiButtonProps['variant']) => {
  return '8px 16px';
};

export default Button;
