import Breadcrumbs from '@mui/material/Breadcrumbs';
import Vega_Text from './Vega_Text';

type VegaCrumbProps<T> = {
  label: string;
  onClick?: () => void;
  color?: string;
};

interface VegaBreadCrumbProps<T> {
  items: Array<VegaCrumbProps<T>>;
  separator?: string;
}

function Vega_BreadCrumb<T>({
  items,
  separator = '>',
}: VegaBreadCrumbProps<T>) {
  return (
    <Breadcrumbs separator={<Vega_Text text={separator} />}>
      {items.map((item, index) => {
        let color = 'rgba(27, 29, 34, 1)';

        if (item.color) {
          color = item.color;
        } else {
          if (!item.onClick) {
            color = 'rgba(27, 29, 34, 1)';
          } else {
            color = '#1047DC';
          }
        }

        return (
          <div
            key={index}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              cursor: item.onClick != null ? 'pointer' : 'default',
            }}
            onClick={item.onClick}
          >
            <Vega_Text
              text={item.label}
              color={color}
              fontSize="0.75rem"
              fontWeight={500}
              lineHeight="0.975rem"
            />
          </div>
        );
      })}
    </Breadcrumbs>
  );
}

export default Vega_BreadCrumb;
