import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { VPAccordion } from '../../../ui-components/common';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import { useAppSelector } from '../../../store/hook';
import { AddOnCardConfiguration } from '../../../types/card';
import VegaHeader from '../../common/VegaHeader';

type Props = {};

type FieldsType = {
  label: string;
  value: string;
};

const applicationFields = [
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Relationship',
    value: 'relationShip',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Gender',
    value: 'gender',
  },
];

const kycDocFields = [
  { label: 'PAN Card', value: 'panNo' },
  {
    label: 'Aadhaar Number',
    value: 'aadhaarNo',
  },
];

const transactionTypes = [
  { label: 'E-Commerce', value: 'addOnCardEcommerceStatus' },
  { label: 'POS', value: 'addOnCardPosStatus' },
  { label: 'Cash Withdrawal', value: 'addOnCardCashWithdrawalStatus' },
];

const getAddonCardFieldData = (
  addOnCardConfig: AddOnCardConfiguration | null,
  fields: FieldsType[]
) => {
  if (!addOnCardConfig) return '-';
  return fields
    .filter(
      ({ value }) => !!addOnCardConfig[value as keyof AddOnCardConfiguration]
    )
    .map(({ label }) => label)
    .join(', ');
};

function AddOnCard({}: Props) {
  const { addonCardConfig } = useAppSelector(
    (state) => state.programManagement
  );

  const isAddonCardNotConfigured = !addonCardConfig;
  const isSupplementaryCardNotConfigured =
    !addonCardConfig?.supplementaryCardSchemeName;
  const applicationDetailsFields = getAddonCardFieldData(
    addonCardConfig,
    applicationFields
  );
  const kycDocumentFields = getAddonCardFieldData(
    addonCardConfig,
    kycDocFields
  );
  const transactionTypesFields = getAddonCardFieldData(
    addonCardConfig,
    transactionTypes
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Typography className="font-aspekta-600" fontSize={17}>
        Add on Card
      </Typography>
      <VPAccordion
        title="Add on Card Details"
        expandable={false}
        disabled={isAddonCardNotConfigured}
      >
        <VPAccordion.TailEnd>
          <>
            {isAddonCardNotConfigured ? (
              <Box
                sx={{
                  px: 2,
                }}
              >
                <VegaHeader text="Not Configured" />
              </Box>
            ) : null}
          </>
        </VPAccordion.TailEnd>
        <VPAccordion.Body>
          <Grid container rowSpacing={5} columnSpacing={3}>
            <Grid item xs={6} lg={4}>
              <DataTile
                title="Scheme Name"
                value={addonCardConfig?.addOnCardSchemeName ?? '-'}
              />
            </Grid>
            <Grid item xs={12} sm={8} lg={4}>
              <DataTile
                title="Application Form Details"
                value={applicationDetailsFields}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <DataTile title="KYC Document" value={kycDocumentFields} />
            </Grid>
            <Grid item xs={6} lg={4}>
              <DataTile
                title="MAX cards to be allotted"
                value={`${addonCardConfig?.addOnCardCount ?? '-'}`}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <DataTile
                title="transaction type"
                value={transactionTypesFields}
              />
            </Grid>
          </Grid>
        </VPAccordion.Body>
      </VPAccordion>
      <VPAccordion
        title="Supplementary Card Details"
        expandable={false}
        disabled={isSupplementaryCardNotConfigured}
      >
        <VPAccordion.TailEnd>
          <>
            {isSupplementaryCardNotConfigured ? (
              <Box
                sx={{
                  px: 2,
                }}
              >
                <VegaHeader text="Not Configured" />
              </Box>
            ) : null}
          </>
        </VPAccordion.TailEnd>
        <VPAccordion.Body>
          <Grid container rowSpacing={5} columnSpacing={3}>
            <Grid item xs={6} lg={4}>
              <DataTile
                title="Scheme Name"
                value={addonCardConfig?.supplementaryCardSchemeName ?? '-'}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <DataTile
                title="MAX cards to be allotted"
                value={`${addonCardConfig?.supplementaryCardCount ?? '-'}`}
              />
            </Grid>
          </Grid>
        </VPAccordion.Body>
      </VPAccordion>
    </Box>
  );
}

export default AddOnCard;
