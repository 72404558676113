import React from 'react';
import ActiveCardOrdersContainer from './components/ActiveCardOrdersContainer';
import { Box } from '@mui/material';
import ListOfActiveCardOrders from './components/ListOfActiveCardOrders';
import PastCardOrdersContainer from './components/PastCardOrdersContainer';
import ListOfPastCardOrders from './components/ListOfPastCardOrders';

const PhysicalCard = () => {
  return (
    <>
      <ActiveCardOrdersContainer header="Active Card Orders">
        <Box mt={2}>
          <ListOfActiveCardOrders />
        </Box>
      </ActiveCardOrdersContainer>
      <PastCardOrdersContainer header="Past Card Orders">
        <Box mt={2}>
          <ListOfPastCardOrders />
        </Box>
      </PastCardOrdersContainer>
    </>
  );
};

export default PhysicalCard;
