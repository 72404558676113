import React from 'react';
import { Box } from '@mui/material';
import { BACKGROUND, BORDER_GRAY, GREY } from '../../../constants/style';

interface IProps {
  selectDate: any;
  selectDueDate: (e: number) => void;
}

const SelectDueDates = ({ selectDate, selectDueDate }: IProps) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  return (
    <Box sx={{ border: BORDER_GRAY, borderRadius: '9px' }}>
      <Box
        sx={{
          bgcolor: BACKGROUND.LIGHT_BLUE,
          pl: 5,
          py: 2,
          borderTopLeftRadius: '9px',
          borderTopRightRadius: '9px',
        }}
      >
        Select the Payment Due dates in a month
      </Box>
      <Box
        sx={{
          px: 4,
          py: 2,
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {[...Array(27)].map((e, i) => {
          return (
            <Box
              sx={{
                bgcolor: GREY.light,
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                mx: 3.5,
                my: 2,
                cursor: 'pointer',
              }}
              className={selectDate.map(
                (val: number) => val === i + 1 && 'active'
              )}
              key={i}
              onClick={() => selectDueDate(i + 1)}
            >
              {i + 1}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SelectDueDates;
