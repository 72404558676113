/* eslint-disable react/jsx-pascal-case */
import { GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useClientAuth } from '../../../providers/ClientProvider';
import { BinProgramDto } from '../../../types/card';
import { BinService } from '../../apis';
import CIMS_DataGrid from '../../components/common/CIMS_DataGrid';
import { Typography } from '@mui/material';

const ListOfCardType = () => {
  const { clientId } = useClientAuth();
  const [pageSize, setPageSize] = useState<number>(10);
  const listBinProgramQuery = useQuery({
    queryKey: 'listBinProgram',
    queryFn: () => {
      return BinService.listBinProgram({
        clientId: clientId || '',
        page: 0,
        size: 100,
        populateBin: true,
        populateProgram: true,
      });
    },
    enabled: !!clientId,
    // select: (data) => {
    //   return data.records.map((item, index: number) => {
    //     return {
    //       // cardType: 'DEBIT',
    //       type: toLowerCase(item + ' Card'),
    //       no: index + 1,
    //     };
    //   });
    // },
  });

  const COLUMN_DEF: GridColumns<BinProgramDto> = [
    // {
    //   field: 'no',
    //   headerName: 'S.No.',
    //   flex: 0.5,
    //   minWidth: 80,
    //   maxWidth: 100,
    //   cellClassName: 'padding',
    //   headerClassName: 'padding',
    // },
    {
      field: 'binValue',
      headerName: 'Bin',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.binDto.binValue;
      },
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'network',
      headerName: 'Network',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.binDto.network;
      },
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },

    {
      field: 'productType',
      headerName: 'Product',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.binDto.productType;
      },
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.programDto.name;
      },
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
  ];
  return (
    <>
      <CIMS_DataGrid
        data={listBinProgramQuery.data?.records || []}
        columns={COLUMN_DEF}
        loading={listBinProgramQuery.isLoading}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        idColumn={'id'}
        paginationMode="server"
        //   onPageChange={changePage}
      />
    </>
  );
};

export default ListOfCardType;
