import { Stack, Typography } from '@mui/material';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { UseQueryResult } from 'react-query';
import VegaHeader from '../../../../components/common/VegaHeader';
import { PaginatedResponse } from '../../../../mcc/types/Commons';
import { CardThresholdV2Policy } from '../../../../types/policy';
import UnstyledDataGrid from '../../../components/UnstyledDataGrid';
import { DivisionDto } from '../../../../types/division';

interface IProps {
  listPolicyQuery: UseQueryResult<CardThresholdV2Policy, unknown>;
  divisionQuery: UseQueryResult<PaginatedResponse<DivisionDto>, unknown>;
}
export const ViewThresholdPolicies = ({
  listPolicyQuery,
  divisionQuery,
}: IProps) => {
  return (
    <UnstyledDataGrid
      columns={getViewColumns(listPolicyQuery.data)}
      rows={divisionQuery.data?.records || []}
      density="compact"
      components={{
        Toolbar: (props) => {
          return (
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              my={2}
            >
              <Stack
                direction={'row'}
                className="dataGrid"
                alignItems={'center'}
                spacing={2}
              >
                <GridToolbarQuickFilter
                  variant="outlined"
                  sx={{
                    bgcolor: 'white',
                    color: '#999DA8',
                    height: '35px',
                    minWidth: '320px',
                    borderRadius: '9px',
                  }}
                />
              </Stack>
            </Stack>
          );
        },
      }}
    />
  );
};

const getViewColumns = (
  cardThresholdPolicy?: CardThresholdV2Policy
): GridColDef<DivisionDto>[] => [
  {
    field: 'code',
    renderHeader: () => <VegaHeader text={'code'} />,
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      return props.row.divisionCode;
      // return dayjs(props.row.createdAt).format('DD/MM/YYYY');
    },
    renderCell: (props) => <Typography>{props.value}</Typography>,
  },
  {
    field: 'name',
    renderHeader: () => <VegaHeader text={'name'} />,

    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      return props.row.name;
    },
    renderCell: (props) => <Typography>{props.value}</Typography>,
  },
  {
    field: 'minThreshold',
    renderHeader: () => <VegaHeader text={'MIN THRESHOLD'} />,
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      const divisionId = props.row.id;
      return cardThresholdPolicy?.card_threshold_map &&
        cardThresholdPolicy.card_threshold_map[divisionId] &&
        !isNaN(
          cardThresholdPolicy.card_threshold_map[divisionId]?.min_threshold
        )
        ? cardThresholdPolicy.card_threshold_map[divisionId]?.min_threshold
        : 'NA';
    },
    renderCell: (props) => <Typography>{props.value}</Typography>,
  },
  {
    field: 'maxThreshold',
    renderHeader: () => <VegaHeader text={'MAX THRESHOLD'} />,
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      const divisionId = props.row.id;
      return cardThresholdPolicy?.card_threshold_map &&
        cardThresholdPolicy.card_threshold_map[divisionId] &&
        !isNaN(
          cardThresholdPolicy.card_threshold_map[divisionId]?.max_threshold
        )
        ? cardThresholdPolicy.card_threshold_map[divisionId]?.max_threshold
        : 'NA';
    },
    renderCell: (props) => <Typography>{props.value}</Typography>,
  },
];
