/** @format */

import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';

import { Add, KeyboardArrowRight } from '@mui/icons-material';
import _ from 'lodash';
import { BORDER_GRAY, GREY } from '../../../constants/style';
import { AccessForProgram } from '../../../pages/RolesAndAccess';
import { VegaCard, VegaTextField } from '../../common';
import VegaContainedButton from '../../common/VegaContainedButton';
import VegaLabel from '../../common/VegaLabel';
import AccessFeaturesControl from './AccessFeaturesControl';
interface IAddUserProps {
  onChange: (name: string, value: any) => void;
  state: any;
  onSubmitRole: () => void;
  featuresForProgram: AccessForProgram;
  onAccessFeaturesChange: (
    programId: string,
    features: string[],
    removeProgram?: boolean
  ) => void;
  isInputValid?: boolean;
}

const AddRole = ({
  onChange,
  state,
  onSubmitRole,
  featuresForProgram,
  onAccessFeaturesChange,
  isInputValid = false,
}: IAddUserProps) => {
  const [showAccessDrawer, setShowAccessDrawer] = useState(false);

  const handleProgramAccordianStateChange = (programId: string) => {
    const featuresExists = !!featuresForProgram[programId];
    onAccessFeaturesChange(programId, [], featuresExists);
  };

  const handleFeaturesChangeForProgram = (
    programId: string,
    value: string,
    isSelected: boolean
  ) => {
    const existingFeaturesForProgram = featuresForProgram[programId];
    const newFeatuesListForProgram = isSelected
      ? [...existingFeaturesForProgram, value]
      : existingFeaturesForProgram.filter((feature) => feature != value);
    onAccessFeaturesChange(programId, newFeatuesListForProgram);
  };

  const getGivenAccessString = () => {
    const access = new Set<string>();
    for (const programId in featuresForProgram) {
      const users = featuresForProgram[programId];
      users.forEach((item) => {
        access.add(item);
      });
    }
    if (access.size <= 0) {
      return 'Access';
    }

    return Array.from(access).join(', ');
  };

  return (
    <>
      <VegaCard>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Roles
            </Typography>
            <VegaTextField
              value={state.roleName}
              onChange={(e) => onChange('roleName', e.target.value)}
              key="roleName"
              placeholder="Enter Role Name"
              type={'text'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Select Program
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box
                  component={'div'}
                  sx={{
                    border: BORDER_GRAY,
                    borderRadius: '7px',
                    px: 1.5,
                    py: 0.8,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    height: '50px',
                  }}
                  onClick={() => setShowAccessDrawer(true)}
                >
                  <Box sx={{ display: 'flex' }}>
                    {state.accessFeatures.length ? (
                      state.accessFeatures.map((role: any) => (
                        <VegaLabel
                          text={_.startCase(_.toLower(role.feature))}
                          color="primary"
                        />
                      ))
                    ) : (
                      <Box sx={{ color: GREY.dark }}>
                        <Typography
                          noWrap
                          overflow={'hidden'}
                          textOverflow="ellipsis"
                        >
                          {getGivenAccessString()}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <KeyboardArrowRight style={{ color: GREY.dark }} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} display={'flex'} justifyContent={'end'}>
            <VegaContainedButton
              text="Add Role"
              isPrimary
              startIcon={<Add />}
              onClick={onSubmitRole}
              mx="0"
              disabled={isInputValid == false}
            />
          </Grid>
        </Grid>
      </VegaCard>
      {showAccessDrawer && (
        <AccessFeaturesControl
          open={showAccessDrawer}
          onClose={() => setShowAccessDrawer(false)}
          onSubmit={() => setShowAccessDrawer(false)}
          featuresForProgram={featuresForProgram}
          onProgramAccordianStateChange={handleProgramAccordianStateChange}
          onFeaturesChangeForProgram={handleFeaturesChangeForProgram}
        />
      )}
    </>
  );
};

export default AddRole;
