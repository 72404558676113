import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { ChargebackService } from '../../services/ChargeBackService';
import { VPBORDER } from '../../ui-components/theme';
import { ChargeBackStatusCard } from './StatusChangeCards/StatusChangeCards';
import { useChargeBack } from './hooks';

export const StatusChangeTimeLine = () => {
  const { selectedChargeBack } = useChargeBack();
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);

  const chargeBackAuditQuery = useQuery({
    queryKey: ['chargeBackAudit', selectedChargeBack?.id],
    queryFn: () =>
      ChargebackService.listChargebackAudit({
        chargebackId: selectedChargeBack?.id,
        pageNumber: page,
        pageSize,
      }),
    enabled: !!selectedChargeBack?.id,
  });

  return (
    <Box
      border={1}
      borderRadius={4}
      minWidth={376}
      sx={{
        border: VPBORDER.GRAY,
        overflow: 'hidden',
      }}
    >
      <Stack>
        <Box bgcolor={'white'} p={1.5} px={2}>
          <Typography
            className="font-aspekta-600"
            color={'#222222'}
            fontSize={17}
          >
            Timeline
          </Typography>
        </Box>
        <Divider />
        {chargeBackAuditQuery.isLoading ? (
          <Stack justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Stack>
        ) : (
          <Stack p={2} spacing={1} overflow={'scroll'}>
            {chargeBackAuditQuery.data?.records.map((audit) => {
              return <ChargeBackStatusCard chargebackAuditDto={audit} />;
            })}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
