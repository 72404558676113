import {
  CustomerDedupeRequest,
  ICustomer,
  IInitiateCheckerMakerReq,
  IUpdateCheckerMakerReq,
  ListCustomersReq,
  UpdateCustomerIdentityInStepsRequestData,
  UpdateCustomerInfo,
  UploadCustomerDocsReq,
  VectorCheckDedupeRes,
} from '../types/customers';

import axios from 'axios';
import { BASE_URL } from '../api/endpoints';
import { CREATE_CUSTOMER } from '../mcc/Back-office/api/endpoints';
import { getFormData } from '../utils/common.utils';

const CUSTOMER_BASE_URL = `${BASE_URL}${CREATE_CUSTOMER.BASE}`;

const CustomerEndpoints = {
  GET: '/{customerId}',
  CREATE: '',
  UPLOAD_DOCS: '/upload_docs',

  DEDUPE_CHECK: '/dedupe',
  INITIATE_CHECKER_MAKER: '/checkermaker',
  UPDATE_CHECKER_MAKER: '/checkermaker/status',
  LIST_CUSTOMERS: '/filter',
};

const CustomerInStepsEndpoint = {
  CREATE_CUSTOMER: '/create/id',
  UPDATE_CUSTOMER_INFO: '/update/info',
  UPDATE_CUSTOMER_ADDRESS: '/update/address',
  UPDATE_CUSTOMER_IDENTITY: '/update/identity',
};

class CustomerService {
  static async vectorCheckDedupe(
    data: CustomerDedupeRequest
  ): Promise<VectorCheckDedupeRes> {
    const endpoint = CUSTOMER_BASE_URL + CustomerEndpoints.DEDUPE_CHECK;
    const response = await axios.post(endpoint, data);
    const dedupeResponse: VectorCheckDedupeRes = {
      exists: response.data.exists,
      existingCustomer: response.data.existingCustomer,
    };

    return dedupeResponse;
  }

  static async getCustomerById(customerId: string): Promise<ICustomer> {
    const endpoint =
      CUSTOMER_BASE_URL +
      CustomerEndpoints.GET.replace('{customerId}', customerId);
    const response = await axios.get(endpoint);
    const responseData: ICustomer = response.data;
    return responseData;
  }

  static async createCustomer(data: Partial<ICustomer>): Promise<ICustomer> {
    const endpoint = CUSTOMER_BASE_URL + CustomerEndpoints.CREATE;
    const response = await axios.post(endpoint, data);
    const responseData: ICustomer = response.data;
    return responseData;
  }

  static async updateCustomerIdentityInSteps(
    data: UpdateCustomerIdentityInStepsRequestData
  ): Promise<ICustomer> {
    const endpoint =
      CUSTOMER_BASE_URL + CustomerInStepsEndpoint.UPDATE_CUSTOMER_IDENTITY;
    const response = await axios.put(endpoint, data);

    const responseData: ICustomer = response.data;
    return responseData;
  }

  static async updateCustomerInfo(
    data: UpdateCustomerInfo
  ): Promise<ICustomer> {
    const endpoint =
      CUSTOMER_BASE_URL + CustomerInStepsEndpoint.UPDATE_CUSTOMER_INFO;
    const response = await axios.put(endpoint, data);

    const responseData: ICustomer = response.data;
    return responseData;
  }
  static async uploadCustomerDocs(
    data: UploadCustomerDocsReq
  ): Promise<ICustomer> {
    const endpoint = CUSTOMER_BASE_URL + CustomerEndpoints.UPLOAD_DOCS;
    const response = await axios.post(endpoint, getFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const responseData: ICustomer = response.data;
    return responseData;
  }

  static async listCustomers(
    data: Partial<ListCustomersReq>
  ): Promise<ICustomer[]> {
    const endpoint = CUSTOMER_BASE_URL + CustomerEndpoints.LIST_CUSTOMERS;
    const response = await axios.post(endpoint, data);

    const responseData = response.data.records;
    return responseData;
  }

  static async initiateCheckerMaker(data: IInitiateCheckerMakerReq) {
    const endpoint =
      CUSTOMER_BASE_URL + CustomerEndpoints.INITIATE_CHECKER_MAKER;

    return axios.post(endpoint, data);
  }
  static async updateCheckerMaker(data: IUpdateCheckerMakerReq) {
    const endpoint = CUSTOMER_BASE_URL + CustomerEndpoints.UPDATE_CHECKER_MAKER;
    return axios.patch(endpoint, data);
  }
}

export default CustomerService;
