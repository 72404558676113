import { Box, Stack } from '@mui/material';
import VegaTextFieldV2 from '../VegaTextFieldV2';

type Props = {
  value?: string;
  onChange: (value: string) => void;
};

function HexInput({ value, onChange }: Props) {
  return (
    <Stack direction={'row'} spacing={2} alignItems="center">
      <Box>HEX</Box>
      <VegaTextFieldV2
        defaultValue={value}
        style={{ maxWidth: '9rem' }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        inputProps={{
          maxLength: 7,
          pattern: '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$',
        }}
        placeholder="Enter Hex color"
      />
    </Stack>
  );
}

export default HexInput;
