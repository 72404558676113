import { Search } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Vega_Text from './v3/Vega_Text';

export interface ISearchByProps {
  key: string;
  searchByname?: string | undefined;
  searchName?: string;
  state?: any;

  placeholder: string;
  data?: any[] | undefined;
  handleChange?: ((name: string, value: string) => void) | any;
}

const VegaSearchBy = ({
  key,
  data,
  handleChange,
  placeholder,
  searchName,
  searchByname,
  state,
}: ISearchByProps) => {
  return (
    <>
      <Grid container alignItems={'center'}>
        <Vega_Text text={'Search By'} fontSize={14} sx={{ mr: 2 }} />
        <Grid
          item
          spacing={1}
          container
          xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={4}
          py={1}
          alignItems={'center'}
        >
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Select
                className="font-aspekta-450"
                value={state?.searchBy}
                name={searchByname}
                onChange={(e: any) =>
                  handleChange(searchByname, e.target.value)
                }
                fullWidth
                size="medium"
                style={{
                  borderRadius: '7px',
                  height: '32px',
                  backgroundColor: 'white',
                  fontSize: 13,
                }}
                sx={{
                  '&.Mui-focused fieldset': {
                    borderColor: '#C5C8D0',
                    borderWidth: '1px',
                    borderRadius: '6px',
                  },
                  ':hover fieldset': {
                    borderColor: '#C5C8D0',
                  },
                }}
                id={'code'}
              >
                {data?.map((value: any) => (
                  <MenuItem
                    className="font-aspekta-500"
                    sx={{ fontSize: 12 }}
                    value={value.value}
                  >
                    {value.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              fullWidth
              id={key}
              name={searchName}
              value={state?.search}
              onChange={(e: any) => handleChange(searchName, e.target.value)}
              placeholder={placeholder ? placeholder : 'Search'}
              size="medium"
              sx={{ borderRadius: '7px', height: '32px', bgcolor: 'white' }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={7} xl={8}></Grid>
      </Grid>
    </>
  );
};

export default VegaSearchBy;
