import {
  CardConfigProperties,
  CardType,
  useCardConfig,
} from '../../../providers/CardConfigProvider';
import {
  ProgramCardContainer,
  ProgramCardCvvContainer,
  ProgramCardCvvNumber,
  ProgramCardCvvTextContainer,
  ProgramCardCvvTitle,
  ProgramCardHologram,
  ProgramCardDescriptionContainer,
  ProgramCardRightPattern,
  ProgramCardStrip,
  ProgramCardStripsContainer,
  ProgramCardMagneticStrip,
  ProgramCardTopHollowContainer,
  CardSizeVariant,
  ProgramCardText,
  CARD_AUTHORIZED_SIGNRATURE_FONT_SIZE,
  TextContainer,
} from './CardComponents';

type Props = {
  cardConfig: Partial<CardConfigProperties>;
  variant?: CardSizeVariant;
};
function ProgramCardBack({
  variant = CardSizeVariant.NORMAL,
  cardConfig,
}: Props) {
  return (
    <ProgramCardContainer
      backgroundColor={cardConfig.background}
      variant={variant}
    >
      <TextContainer>
        <ProgramCardTopHollowContainer
          hide={Boolean(cardConfig.aboveMagneticPanelText)}
          variant={variant}
          color={cardConfig.textColor}
        >
          {cardConfig.aboveMagneticPanelText}
        </ProgramCardTopHollowContainer>
      </TextContainer>

      <ProgramCardMagneticStrip />

      <TextContainer>
        <ProgramCardText
          color={cardConfig.textColor}
          variant={variant}
          left="5%"
          bottom="5%"
          lineHeight="140%"
          fontWeight={500}
        >
          Credit Card
        </ProgramCardText>
      </TextContainer>
      <ProgramCardHologram />

      <TextContainer>
        <ProgramCardText
          color={cardConfig.textColor}
          variant={variant}
          fontSize={CARD_AUTHORIZED_SIGNRATURE_FONT_SIZE[variant].fontSize}
          left="4%"
          bottom="38%"
          fontWeight={700}
          textTransform="capitalize"
        >
          AUTHORIZED SIGNATURE
        </ProgramCardText>
      </TextContainer>

      <TextContainer>
        <ProgramCardDescriptionContainer
          hide={Boolean(cardConfig.signaturePanelText)}
          variant={variant}
          color={cardConfig.textColor}
        >
          {cardConfig.signaturePanelText}
        </ProgramCardDescriptionContainer>
      </TextContainer>

      <ProgramCardRightPattern left="-60%" />
      <ProgramCardRightPattern left="-70%" />

      <ProgramCardCvvContainer>
        <ProgramCardStripsContainer>
          <ProgramCardStrip />
          <ProgramCardStrip />
          <ProgramCardStrip />
          <ProgramCardStrip />
          <ProgramCardStrip />
          <ProgramCardStrip />
        </ProgramCardStripsContainer>
        <ProgramCardCvvTextContainer>
          <ProgramCardCvvTitle variant={variant}>CVV</ProgramCardCvvTitle>
          <ProgramCardCvvNumber variant={variant}>123</ProgramCardCvvNumber>
        </ProgramCardCvvTextContainer>
      </ProgramCardCvvContainer>
    </ProgramCardContainer>
  );
}

export default ProgramCardBack;
