/** @format */

import { Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState } from 'react';
import { BORDER, PRIMARY } from '../../../constants/style';
import { DATE_FORMATE } from '../../../constants/url';
import { NoResults, VegaCard, VegaTable } from '../../common';
import VegaDrawer from '../../common/VegaDrawer';
import VegaLabel from '../../common/VegaLabel';
import SelectorWithObjOptions from '../SelectorWithObjOptions';
import { IRolesProps, IUsersProps } from '../../../types/userAccess';

interface IListOfUsersProps {
  data?: IUsersProps[];
  role?: IRolesProps[];
  onSubmit: (...params: any) => any;
  loading?: boolean;
  rowCount?: number;
  page?: number;
  pageSize?: number;
  onPageChange?: (...param: any) => any;
  onPageSizeChange?: (...param: any) => any;
  paginationMode?: string;
}

const ListOfUsers = ({
  data,
  role,
  onSubmit,
  loading,
  rowCount,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  paginationMode,
}: IListOfUsersProps) => {
  const newRoleData = role?.map((val: any) => ({
    id: val.id,
    name: val.roleName,
  }));
  const [show, setShow] = useState<boolean>(false);
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [userRoles, setUserRoles] = useState<any>({});
  const columnRef = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),

      flex: 0.5,
    },

    {
      field: 'email',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Email
        </Typography>
      ),

      flex: 0.6,
    },
    {
      field: 'mobileNumber',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Mobile No
        </Typography>
      ),

      flex: 0.6,
    },
    {
      field: 'userRoles',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Add Role
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Box
            sx={{
              border: BORDER,
              borderRadius: '5px',
              px: 0.5,
              py: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {props.row.userRoles.length && getCheck(props.row.userRoles) ? (
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {props.row.userRoles
                  .filter((filterVal: any) => filterVal.status === 'ACTIVE')
                  .map((val: any) => (
                    <VegaLabel
                      key={val.id}
                      text={val.roleName}
                      color="primary"
                    />
                  ))}
              </Box>
            ) : (
              <Typography variant="caption">Add New Role</Typography>
            )}
            <Box
              sx={{
                px: 1,
                bgcolor: PRIMARY.light,
                color: 'white',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                minWidth: '55px',
              }}
              onClick={() => showRole(props.row)}
            >
              <Typography variant="caption" color={'white'}>
                {props.row.userRoles.length && getCheck(props.row.userRoles)
                  ? 'Update'
                  : 'Add'}
              </Typography>
              {/* <Add fontSize="small" /> */}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Activation Date
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.createdAt &&
              dayjs(props.row.createdAt).format(DATE_FORMATE)}
          </div>
        );
      },
      flex: 0.6,
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <VegaLabel
            text={props.row.status && _.startCase(_.toLower(props.row.status))}
            color="success"
          />
        );
      },
      flex: 0.5,
    },
  ];
  if ((!data || data.length === 0) && !loading) {
    return <NoResults text="You have no User" subtext="Add new User?" />;
  }

  const getCheck = (arr: any) => {
    const isActive = arr.some((val: any) => val.status === 'ACTIVE');
    return isActive;
  };

  const showRole = (data: any) => {
    console.log('🚀 ~ file: ListOfUsers.tsx:127 ~ showRole ~ data', data);
    if (data.userRoles) {
      const arr = data.userRoles
        .filter((value: any) => value.status === 'ACTIVE')
        .map((val: any) => ({
          id: val.roleId,
          name: val.roleName,
        }));
      setSelectedRoles(arr);
    }
    setShow(!show);
    setUserRoles(data);
  };

  const onRoleChange = (isSelect: boolean, value: any) => {
    console.log(
      'Selected role: ',
      isSelect,
      value,
      selectedRoles,
      selectedRoles.length
    );
    if (isSelect) {
      const updatedRolesArr = [
        ...(!!selectedRoles.length ? selectedRoles : []),
        { id: value.id, name: value.name },
      ];
      console.log('Updated Roles arr', updatedRolesArr);
      setSelectedRoles([...updatedRolesArr]);
    } else {
      const newArr = selectedRoles.filter((val: any) => val.id !== value.id);
      setSelectedRoles([...newArr]);
    }
  };

  const handleSubmitClick = () => {
    console.log('Selected Role', selectedRoles);
    const userRoleUpdateRequest = {
      userId: userRoles.userId,
      status: 'ACTIVE',
      roles: selectedRoles.map((role: any) => ({
        roleId: role.id,
        roleName: role.name,
      })),
    };
    onSubmit?.(userRoleUpdateRequest);
    setShow(!show);
  };

  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable
          data={data || []}
          columnDef={columnRef}
          idColumn={'userId'}
          loading={loading}
          page={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          paginationMode={paginationMode}
          rowCount={rowCount}
        />
      </VegaCard>
      <VegaDrawer
        open={show}
        onClose={() => setShow(!show)}
        anchor="right"
        isSubmit={true}
        isClose={true}
        onSubmit={async () => {
          handleSubmitClick();
        }}
        closeText="Got It!"
        submitText={selectedRoles.length ? 'Update' : 'Add'}
        header="Select Roles for Virat sharma"
      >
        <SelectorWithObjOptions
          options={newRoleData || []}
          onSelect={onRoleChange}
          selected={selectedRoles}
        />
      </VegaDrawer>
    </>
  );
};

export default ListOfUsers;
