import { Menu } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { usePolicy } from '../../providers/PolicyProvider';
import { UserMakerCheckerRole } from '../../types/entityAuth';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaOutlineButton from '../common/VegaOutlineButton';

export const PolicyMakerCheckerActions = (data: {
  onSubmitToCheckerClick: () => void;
  onSubmitToMakerClick: () => void;
  onAllPolicyClick: () => void;
}) => {
  const {
    canEnableCheckerActions,
    canEnableMakerActions,
    areAllPoliciesApproved,
    loggedInMakerCheckerUser,
    controlRole,
    policySelections,
  } = usePolicy();

  const [showActions, setShowActions] = useState<boolean>(false);

  const canShowViewAllPolicyButton = () => {
    const items = Object.values(policySelections);
    var hasPolicy = false;
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element) {
        hasPolicy = true;
        break;
      }
    }
    return hasPolicy;
  };

  const MakerButtons = () => {
    let isControlRoleChecker = controlRole == UserMakerCheckerRole.CHECKER;
    return (
      <Stack direction={'row'} alignItems="center" justifyContent={'end'}>
        <div hidden={canShowViewAllPolicyButton() == false}>
          <VegaOutlineButton
            text={'View All Policies'}
            isPrimary
            startIcon={<Menu />}
            onClick={data.onAllPolicyClick}
          />
        </div>
        <VegaContainedButton
          text={
            isControlRoleChecker ? 'Submitted to checker' : 'Submit to checker'
          }
          isPrimary
          onClick={data.onSubmitToCheckerClick}
          disabled={canEnableMakerActions() == false}
        />
      </Stack>
    );
  };

  const CheckerButtons = () => {
    let isControlRoleMaker = controlRole == UserMakerCheckerRole.MAKER;
    return (
      <VegaContainedButton
        text={isControlRoleMaker ? 'Submitted to maker' : 'Submit to maker'}
        isPrimary
        onClick={data.onSubmitToMakerClick}
        disabled={canEnableCheckerActions() == false}
      />
    );
  };

  useEffect(() => {
    if (areAllPoliciesApproved) {
      setShowActions(false);
      return;
    }
    let role = loggedInMakerCheckerUser?.role;
    let isMaker = role == UserMakerCheckerRole.MAKER;
    let isChecker = role == UserMakerCheckerRole.CHECKER;
    setShowActions(isMaker || isChecker);
  }, [areAllPoliciesApproved, loggedInMakerCheckerUser]);

  return (
    <VegaConditionalWrapper
      condition={showActions}
      ifTrue={
        <VegaConditionalWrapper
          condition={
            loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER
          }
          ifTrue={<MakerButtons />}
          ifFalse={<CheckerButtons />}
        />
      }
    />
  );
};
