import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { NoResults, VegaTextField } from '../../../../../components/common';
import VegaSelect from '../../../../../components/common/VegaSelect';
import { BORDER_GRAY } from '../../../../../constants/style';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import {
  DayCountMethodArr,
  IChargeRateRule,
  IOpenCreditCharge,
  InterestRateValueTypeArr,
  RoundingMethodArr,
} from '../../../../types/plan';
import { IPlanListProps } from '../MarkupFee/MarkupFee';
import PlanPoliciesCard from '../PlanPoliciesCard';
import ListOfOpenCreditCharge from './ListOfOpenCreditCharge';

const DEFAULT_CHARGE_RATE_RULE = {
  interest_rate_value_type: 'EQUAL',
  default_rate: 90,
};

const DEFUALT_PLAN = {
  clientId: '',
  planName: '',
  planType: 'OPEN_CREDIT_CHARGE_PLAN',
  planDescription: 'planDescription',
  isDefault: true,
  effectiveStartTime: null,
  effectiveEndTime: null,
  status: 'ACTIVE',
  updatedAt: '2023-04-04T07:13:19.418147Z',
  roundingMethod: 'NEAR',
  dayCountMethod: 'ACTUAL_BY_360',
  chargeRateValue: DEFAULT_CHARGE_RATE_RULE,
} as IOpenCreditCharge;

const OpenCreditCharge = ({
  onCreate,
  plans,
  page,
  count,
  onPageChange,
}: IPlanListProps) => {
  console.log('plans', plans);
  const { clientId } = useClientAuth();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [planData, setPlanData] = useState<IOpenCreditCharge>({
    ...DEFUALT_PLAN,
  });

  const handleChange = (name: string, value: any) => {
    setPlanData({ ...planData, [name]: value });
    if (value.length) {
      setErrorMsg('');
    }
  };
  const handleChargeRateChange = (name: keyof IChargeRateRule, value: any) => {
    const newObj = {
      ...planData['chargeRateValue'],
      [name]: value,
    };
    handleChange('chargeRateValue', newObj);
  };

  const onCreateCharges = () => {
    if (planData.planName.length) {
      Object.assign(planData, { clientId });
      onCreate(planData);
      setPlanData({ ...DEFUALT_PLAN });
    } else {
      setErrorMsg('Please enter plan name');
    }
  };

  return (
    <>
      <PlanPoliciesCard
        title="OPEN_CREDIT_CHARGE_PLAN"
        plan={planData}
        handleChange={handleChange}
        disabled={false}
        errorMsg={errorMsg}
        onCreate={onCreateCharges}
      >
        <Box sx={{ borderTop: BORDER_GRAY, py: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <VegaSelect
                isFixedHeight
                size="small"
                key={'roundingMethod'}
                selected={planData.roundingMethod ?? ''}
                onChange={(value: string) =>
                  handleChange('roundingMethod', value)
                }
                options={RoundingMethodArr}
                headerText="Rounding Method"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <VegaSelect
                isFixedHeight
                size="small"
                key={'dayCountMethod'}
                selected={planData.dayCountMethod ?? ''}
                onChange={(value: string) =>
                  handleChange('dayCountMethod', value)
                }
                options={DayCountMethodArr}
                headerText="Day Count Method"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <VegaSelect
                isFixedHeight
                size="small"
                key={'dayCountMethod'}
                selected={
                  planData.chargeRateValue.interest_rate_value_type ?? ''
                }
                onChange={(value: string) =>
                  handleChargeRateChange('interest_rate_value_type', value)
                }
                options={InterestRateValueTypeArr}
                headerText="Interest rate value type"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <VegaTextField
                isFixedHeight
                value={planData.chargeRateValue.default_rate ?? 0}
                onChange={(e) => {
                  let value: any = e.target.value;

                  handleChargeRateChange(
                    'default_rate',
                    value && parseFloat(value)
                  );
                }}
                key="default_rate"
                type={'number'}
                headerText="Default rate"
              />
            </Grid>
          </Grid>
        </Box>
      </PlanPoliciesCard>
      {plans.length ? (
        <ListOfOpenCreditCharge
          data={plans}
          page={page}
          count={count}
          onPageChange={onPageChange}
        />
      ) : (
        <NoResults text="You have no OpenCreditCharge plan" />
      )}
    </>
  );
};

export default OpenCreditCharge;
