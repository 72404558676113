import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { VegaProgramWithMetaDataType } from '../../types/program';
import { VegaPage } from '../common';
import VegaBreadcrumbs from '../common/v2/VegaBreadcrumbs';
import VegaTextV2 from '../common/v2/VegaTextV2';
import { VPButton } from '../../ui-components/common';
import { getProgramMITCLink } from '../../api/program';
import { useSnackbar } from '../../providers/SnackbarProvider';
import ProgramStatus from './ProgramStatus';
import { VPPRIMARY } from '../../ui-components/theme';
import VegaTab from '../common/VegaTab';
import {
  Analytics,
  CardDetailsAndDesign,
  FRM,
  OnboardingJourney,
  Policies,
  ProgramDetails,
  ProgramDueDates,
} from './tabs';
import DataTile from './DataTile';
import { AnyPolicyType } from '../../types/policy';
import PolicyView from './containers/PolicyView';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  clearActivePolicy,
  setActivePolicy,
  setPolicies,
} from '../../store/common/programManagementSlice';
import { AxiosError } from 'axios';
import { fetchAllPolicesForProgram } from '../../api/policy';

type ProgramDetailPageProps = {
  handleOnBreadcrumbClick: () => void;
};

enum ProgramDetailPageTabs {
  ProgramDetails = 'Program Details',
  Policies = 'Policies',
  OnboardingJourney = 'Onboarding Journey',
  FRM = 'FRM',
  CardDetailsAndDesign = 'Card Details & Design',
  ProgramDueDates = 'Program Due Dates',
  Analytics = 'Analytics',
}
const fetchPolicies = (programId: string) => {
  return new Promise((resolve, reject) => {
    fetchAllPolicesForProgram(programId)
      .then((res) => {
        const allPolicies = res?.data?.all_policies;
        if (!allPolicies) {
          reject(new AxiosError('Failed to fetch policies of program'));
        }
        resolve(allPolicies);
      })
      .catch(reject);
  });
};

function ProgramDetailPage({
  handleOnBreadcrumbClick,
}: ProgramDetailPageProps) {
  const { setSnackbar } = useSnackbar();
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState(
    ProgramDetailPageTabs.ProgramDetails
  );
  const activePolicy = useAppSelector(
    (state) => state.programManagement.activePolicy
  );

  const dispatch = useAppDispatch();
  const { policies, selectedProgram: program } = useAppSelector(
    (state) => state.programManagement
  );

  useEffect(() => {
    if (!policies.length && program) {
      fetchPolicies(program.programId)
        .then((res) => {
          dispatch(setPolicies(res as AnyPolicyType[]));
        })
        .catch((err) => {
          setSnackbar('Failed to fetch policies!', 'error');
        })
        .finally(() => {});
    }
  }, []);

  const downloadMitcForProgram = () => {
    if (!program) return;
    setDownloadButtonDisabled(true);
    getProgramMITCLink(program.programId)
      .then((response) => {
        const url = response.data.mitcUrl;
        if (!url) {
          setSnackbar('Failed to download MITC', 'error');
          return;
        }
        window.open(url, '__BLANK__');
      })
      .catch((error) => {
        setSnackbar('Failed to download MITC', 'error');
      })
      .finally(() => {
        setDownloadButtonDisabled(false);
      });
  };

  const onTabChange = (select: any) => {
    setActiveTab(select);
  };

  const viewPolicy = (policy: AnyPolicyType) => {
    dispatch(setActivePolicy(policy));
  };

  if (activePolicy && activePolicy.policy_type) {
    return (
      <PolicyView
        policy={activePolicy}
        handleGoBack={() => {
          dispatch(clearActivePolicy());
        }}
      />
    );
  }

  if (!program) {
    return null;
  }

  return (
    <VegaPage
      sx={{
        minHeight: '100vh',
        gap: 2,
      }}
    >
      <Box
        sx={{
          px: 3.25,
          bgcolor: 'white',
          pt: 3,
          pb: 1.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          borderBottom: '1px solid #EEE',
        }}
      >
        <VegaBreadcrumbs
          separator="›"
          sx={{
            '& .MuiBreadcrumbs-separator': {
              color: '#1047DC',
            },
          }}
        >
          <VegaTextV2
            onClick={handleOnBreadcrumbClick}
            color={'#777B86'}
            text="Program Management"
            fontSize={12}
            sx={{
              cursor: 'pointer',
            }}
          />

          <VegaTextV2 fontSize={12} color="#1047DC" text={program.name} />
        </VegaBreadcrumbs>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            gap: {
              xs: 2,
              sm: 1,
            },
            py: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              gap: {
                xs: 1,
                sm: 0,
              },
            }}
          >
            <DataTile
              title="Program name"
              value={program.name || '-'}
              hasSeparator
              containerSx={{
                width: '100%',
              }}
            />
            <DataTile
              title="Program ID"
              value={program.programId || '-'}
              hasSeparator
              containerSx={{
                width: '100%',
              }}
            />
            <DataTile
              title="Co-Brand Partner"
              value={program.coBrandPartnerName || '-'}
              hasSeparator
              containerSx={{
                width: '100%',
              }}
            />
            <DataTile title="Status">
              {program.status ? <ProgramStatus status={program.status} /> : '-'}
            </DataTile>
          </Box>
          <VPButton
            variant="outlined"
            title="Download MITC"
            disabled={downloadButtonDisabled}
            sx={{
              color: VPPRIMARY.BLUE,
              borderColor: VPPRIMARY.BLUE,
            }}
            onClick={downloadMitcForProgram}
          >
            Download MITC
          </VPButton>
        </Box>
      </Box>
      <Box
        sx={{
          px: 3.25,
        }}
      >
        <VegaTab
          placement="vertical"
          color={VPPRIMARY.BLUE}
          tabData={Object.values(ProgramDetailPageTabs)}
          selected={activeTab}
          onSelect={onTabChange}
          forceTextCase
        />
      </Box>
      <Box
        sx={{
          bgcolor: 'white',
          mx: 3.25,
          display: 'flex',
          borderRadius: 1.5,
          mb: { xs: 2, sm: 8 },
          flex: 1,
          border: '1px solid #EEE',
        }}
      >
        <ActiveProgramTab
          activeTab={activeTab}
          program={program}
          viewPolicy={viewPolicy}
        />
      </Box>
    </VegaPage>
  );
}

function ActiveProgramTab({
  activeTab,
  program,
  viewPolicy,
}: {
  activeTab: ProgramDetailPageTabs;
  program: VegaProgramWithMetaDataType;
  viewPolicy: (policy: AnyPolicyType) => void;
}) {
  switch (activeTab) {
    case ProgramDetailPageTabs.ProgramDetails:
      return <ProgramDetails program={program} />;
    case ProgramDetailPageTabs.Policies:
      return <Policies programId={program.programId} viewPolicy={viewPolicy} />;
    case ProgramDetailPageTabs.OnboardingJourney:
      return <OnboardingJourney />;
    case ProgramDetailPageTabs.FRM:
      return <FRM />;
    case ProgramDetailPageTabs.CardDetailsAndDesign:
      return <CardDetailsAndDesign program={program} />;
    case ProgramDetailPageTabs.ProgramDueDates:
      return <ProgramDueDates />;
    case ProgramDetailPageTabs.Analytics:
      return <Analytics />;
    default:
      return null;
  }
}
export default ProgramDetailPage;
