import React from 'react';
import { VegaAccordian, VegaCard } from '../../common';
import { Box, Grid } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import VegaOutlineButton from '../../common/VegaOutlineButton';
import { DownloadIcon } from '../../icon/Icons';
import VegaText from '../../common/VegaText';
import { IData } from './Invoices';
import { DEFAULT_YEAR } from '../../../pages/Settings';
import { month } from '../../policy/BillingPolicy';

interface IProps {
  expanded: number | false;
  handleChange: (panel: any) => void;
  data: IData[];
  text: string;
}
const VegaPayInvoices = ({ expanded, handleChange, data, text }: IProps) => {
  const downloadFile = async (blob: any) => {
    // const href = URL.createObjectURL(blob);
    window.open(blob, '_blank');
    // const href = blob;
    // const a = Object.assign<HTMLAnchorElement, {}>(
    //   document.createElement('a'),
    //   {
    //     href,
    //     style: 'display: none',
    //     download: 'invoice.pdf',
    //   }
    // );
    // document.body.appendChild(a);
    // a.click();
    // URL.revokeObjectURL(href);
    // a.remove();
  };

  console.log('VegaPayInvoices', data);
  return (
    <VegaCard>
      <VegaText fw={500} text={text} />
      {data.map((item: any) => (
        <Box sx={{ my: 2 }}>
          <VegaAccordian
            header={item.year}
            expandIcon={<KeyboardArrowDown />}
            expanded={expanded === item.year}
            onChange={handleChange(item.year)}
            content={
              <Box sx={{ py: 1, display: 'flex', flexWrap: 'wrap' }}>
                {item?.data.map((value: IData) => {
                  return (
                    <Box sx={{ my: 1 }}>
                      <VegaOutlineButton
                        text={month[value.month - 1]}
                        startIcon={<DownloadIcon />}
                        onClick={() => downloadFile(value.invoiceS3Link)}
                      />
                    </Box>
                  );
                })}
              </Box>
            }
          />
        </Box>
      ))}
    </VegaCard>
  );
};

export default VegaPayInvoices;
