/** @format */

import { Box, Checkbox, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { GREY } from '../../constants/style';
import { usePolicy } from '../../providers/PolicyProvider';
import { DEFAULT_REPAYMENT_POLICY } from '../../types/defaultPolicy';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import {
  PolicyComponentType,
  PolicyType,
  RepaymentPolicyInterface,
  RepaymentWaterfall,
} from '../../types/policy';
import { VegaTextField } from '../common';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import PolicyCard from './PolicyCard';
import PolicyCommentCard from './PolicyCommentCard';
import { PolicyWrapper } from './PolicyWrapper';

const RepaymentPolicy = ({
  initialValue,
  onSubmit,
  isLoading,
  onApprove,
  onReject,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    checkPolicyEditable,
    checkPolicyStatusUpdatable,
    entityAuthStatuses,
    loggedInMakerCheckerUser,
    addCommentOnPolicy,
    updateCommentOnPolicy,
    areAllPoliciesApproved,
    getDefaultPolicy,
  } = usePolicy();
  const [policy, setPolicy] = useState<RepaymentPolicyInterface | null>();
  const [firsthalf, setFirstHalf] = useState<RepaymentWaterfall[]>([]);
  const [secondhalf, setSecondHalf] = useState<RepaymentWaterfall[]>([]);
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );

  const handleChange = (
    key: keyof RepaymentPolicyInterface,
    value: string | number | RepaymentWaterfall[] | boolean
  ) => {
    if (!policy) return;
    setPolicy({ ...policy, [key]: value });
  };

  const handleValueChange2 = (index: any, value: number | any) => {
    if (!policy) return;
    const current = policy.repayment_waterfall;
    const newRepayment = current.map((val: RepaymentWaterfall) => {
      if (val.amount_type === index) {
        return {
          amount_type: index,
          priority_order: value,
        };
      } else return val;
    });

    handleChange('repayment_waterfall', [...newRepayment]);
  };

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = policy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(policyId);
    const canUpdateStatus = await checkPolicyStatusUpdatable(policyId);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  function didSubmit() {
    if (policy) {
      onSubmit(policy);
    }
  }

  function didClickApprove() {
    if (policy) onApprove(policy);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  function fetchDefaultPolicy() {
    getDefaultPolicy(PolicyType.REPAYMENT_WATERFALL)
      .then((defaultPolicy) => {
        if (defaultPolicy) {
          const repaymentPolicy = defaultPolicy as RepaymentPolicyInterface;
          setPolicy(repaymentPolicy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (initialValue) setPolicy(initialValue);
    else {
      fetchDefaultPolicy();
    }
  }, [initialValue]);

  useEffect(() => {
    if (!policy) return;
    const [first, second] = _.chunk(
      policy.repayment_waterfall,
      policy.repayment_waterfall.length / 2
    );
    setFirstHalf(first);
    setSecondHalf(second);
    getEntityStatusAndCheckIfCanEdit();
  }, [policy, loggedInMakerCheckerUser]);

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={policy?.policy_id}
      policyView={
        <Box>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                onPastCommentClick={() => {
                  onPastCommentsClick(policy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
          />

          <PolicyCard
            policy={initialValue}
            onSaveClick={didSubmit}
            isLoading={isLoading}
            isSaveDisabled={canUpdatePolicyStatus == false}
            onApproveClick={didClickApprove}
            viewFor={loggedInMakerCheckerUser?.role}
            onCommentAddClick={addCommentOnEntity}
            policyEntityAuth={entityAuthStatus}
            onPastCommentsClick={onPastCommentsClick}
            onCommentEdit={updateEntityComment}
          >
            <Typography mb={3}>Repayment Configuration</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 5,
              }}
            >
              <Typography mr={2}>Auto Debit no of Retries</Typography>
              <VegaTextField
                key="repayemnt-auto-debit"
                size="small"
                type={'number'}
                textFieldWidth={100}
                value={policy?.auto_debit_number_of_retries ?? ''}
                onChange={(e) =>
                  handleChange('auto_debit_number_of_retries', e.target.value)
                }
                disabled={canEditPolicy == false}
              />
              <Typography ml={4} mr={2}>
                Min no. of Installments
              </Typography>
              <VegaTextField
                key="repayemnt-auto-debit"
                size="small"
                type={'number'}
                textFieldWidth={100}
                value={
                  policy?.partial_pay_off_minimum_number_of_installments ?? ''
                }
                onChange={(e) =>
                  handleChange(
                    'partial_pay_off_minimum_number_of_installments',
                    e.target.value
                  )
                }
                disabled={canEditPolicy == false}
              />
              <Checkbox
                sx={{
                  color: 'primary.main',
                  ml: 4,
                }}
                checked={policy?.partial_payoff_allowed ?? false}
                onChange={(e) =>
                  handleChange('partial_payoff_allowed', e.target.checked)
                }
                color="success"
                disabled={canEditPolicy == false}
              />
              <Typography>Minimum MAD amount</Typography>
            </Box>
            <Grid
              container
              alignItems={'center'}
              justifyContent={'center'}
              spacing={2}
            >
              <Grid
                item
                container
                xs={12}
                sm={6}
                alignItems="center"
                spacing={1}
              >
                <Grid
                  item
                  xs={7}
                  sx={{
                    bgcolor: GREY.light,
                    p: 0.5,
                    borderRadius: '9px',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  Amount Type
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    bgcolor: GREY.light,
                    p: 0.5,
                    borderRadius: '9px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  Priority Order
                </Grid>
                {firsthalf.map((data, index) => {
                  return (
                    <>
                      <Grid item xs={7}>
                        {_.startCase(_.toLower(data.amount_type))}
                      </Grid>
                      <Grid item xs={5}>
                        <VegaTextField
                          key={`repayment${data.amount_type}${index}`}
                          value={data.priority_order}
                          onChange={(e) =>
                            handleValueChange2(
                              data.amount_type,
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                          type="number"
                          textFieldWidth={120}
                          disabled={canEditPolicy == false}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                alignItems="center"
                spacing={1}
              >
                <Grid
                  item
                  xs={7}
                  sx={{
                    bgcolor: GREY.light,
                    p: 0.5,
                    borderRadius: '9px',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  Amount Type
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    bgcolor: GREY.light,
                    p: 0.5,
                    borderRadius: '9px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  Priority Order
                </Grid>
                {secondhalf.map((data, index) => {
                  return (
                    <>
                      <Grid item xs={7}>
                        {_.startCase(_.toLower(data.amount_type))}
                      </Grid>
                      <Grid item xs={5}>
                        <VegaTextField
                          key={`repayment`}
                          value={data.priority_order}
                          onChange={(e) =>
                            handleValueChange2(
                              data.amount_type,
                              e.target.value && parseFloat(e.target.value)
                            )
                          }
                          type="number"
                          textFieldWidth={120}
                          disabled={canEditPolicy == false}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </PolicyCard>
        </Box>
      }
    />
  );
};

export default RepaymentPolicy;
