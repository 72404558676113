import { CardChipIcon } from '../../icon/Icons';
import {
  ProgramCardContainer,
  ProgramCardNumberContainer,
  ProgramCardNumberText,
  ProgramCardImage,
  ProgramCardRightPattern,
  CARD_HOLDER_FONT_SIZE,
  CARD_HOLDER_VALID_FONT_SIZE,
  CardSizeVariant,
  ProgramCardImagePlaceholder,
  ProgramCardText,
  TextContainer,
} from './CardComponents';
import {
  CardConfigProperties,
  CardType,
} from '../../../providers/CardConfigProvider';
import {
  VegaProgramIssuanceType,
  VegaProgramWithMetaDataType,
} from '../../../types/program';

type ProgramCardFrontProps = {
  program: VegaProgramWithMetaDataType;
  cardConfig: Partial<CardConfigProperties>;
  variant?: CardSizeVariant;
  cardType: CardType;
};
function ProgramCardFront({
  program,
  cardConfig,
  variant = CardSizeVariant.NORMAL,
  cardType,
}: ProgramCardFrontProps) {
  const isProgramCobrand =
    program?.issuanceType === VegaProgramIssuanceType.CO_BRAND;

  const getTopLeftImage = isProgramCobrand
    ? cardConfig.cobrandLogo
    : cardConfig.bankLogo;

  return (
    <ProgramCardContainer
      backgroundColor={cardConfig.background}
      variant={variant}
    >
      <ProgramCardRightPattern left={'65%'} />
      <ProgramCardRightPattern left={'80%'} />
      <ProgramCardRightPattern left={'95%'} />
      <ProgramCardImagePlaceholder
        hide={Boolean(getTopLeftImage)}
        variant={variant}
        topAligned
        leftAligned
      >
        {getTopLeftImage && (
          <ProgramCardImage
            src={getTopLeftImage}
            leftAligned
            topAligned
            variant={variant}
          ></ProgramCardImage>
        )}
      </ProgramCardImagePlaceholder>
      {isProgramCobrand && (
        <ProgramCardImagePlaceholder
          hide={Boolean(cardConfig.bankLogo)}
          variant={variant}
          topAligned
          rightAligned
        >
          {cardConfig.bankLogo && (
            <ProgramCardImage
              src={cardConfig.bankLogo}
              rightAligned
              topAligned
              variant={variant}
            ></ProgramCardImage>
          )}
        </ProgramCardImagePlaceholder>
      )}
      <ProgramCardImagePlaceholder
        hide={Boolean(cardConfig.nfcLogo)}
        variant={variant}
        topAligned
        top={cardType === CardType.PHYSICAL ? '25%' : '50%'}
        rightAligned
      >
        {cardConfig.nfcLogo && (
          <ProgramCardImage
            src={cardConfig.nfcLogo}
            variant={variant}
            height={variant === CardSizeVariant.NORMAL ? '50px' : '10px'}
            width={variant === CardSizeVariant.NORMAL ? '50px' : '10px'}
          ></ProgramCardImage>
        )}
      </ProgramCardImagePlaceholder>
      <ProgramCardImagePlaceholder
        hide={Boolean(cardConfig.networkLogo)}
        variant={variant}
        rightAligned
        bottomAligned
      >
        {cardConfig.networkLogo && (
          <ProgramCardImage
            src={cardConfig.networkLogo}
            rightAligned
            variant={variant}
          ></ProgramCardImage>
        )}
      </ProgramCardImagePlaceholder>
      {cardType === CardType.PHYSICAL && (
        <ProgramCardImagePlaceholder
          hide
          top="25%"
          left="5%"
          topAligned
          variant={variant}
        >
          <CardChipIcon
            height={variant === CardSizeVariant.SMALL ? '12' : '32'}
            width={variant === CardSizeVariant.SMALL ? '20' : '44'}
          />
        </ProgramCardImagePlaceholder>
      )}
      <ProgramCardNumberContainer
        top={cardType === CardType.PHYSICAL ? '48.5%' : '30%'}
      >
        <ProgramCardNumberText color={cardConfig.textColor} variant={variant}>
          9999
        </ProgramCardNumberText>
        <ProgramCardNumberText color={cardConfig.textColor} variant={variant}>
          9999
        </ProgramCardNumberText>
        <ProgramCardNumberText color={cardConfig.textColor} variant={variant}>
          9999
        </ProgramCardNumberText>
        <ProgramCardNumberText color={cardConfig.textColor} variant={variant}>
          9999
        </ProgramCardNumberText>
      </ProgramCardNumberContainer>
      <TextContainer>
        <ProgramCardText
          color={cardConfig.textColor}
          fontSize={CARD_HOLDER_FONT_SIZE[variant].fontSize}
          variant={variant}
          left="5%"
          bottom={cardType === CardType.PHYSICAL ? '10%' : '40%'}
          lineHeight="140%"
          fontWeight={500}
        >
          Card holder name
        </ProgramCardText>
      </TextContainer>
      <TextContainer>
        <ProgramCardText
          color={cardConfig.textColor}
          fontSize={CARD_HOLDER_VALID_FONT_SIZE[variant].fontSize}
          variant={variant}
          bottom={cardType === CardType.PHYSICAL ? '20%' : '5%'}
          right={cardType === CardType.PHYSICAL ? '48.5%' : '87%'}
          fontWeight={900}
        >
          04/23
        </ProgramCardText>
      </TextContainer>
      <TextContainer>
        <ProgramCardText
          color={cardConfig.textColor}
          variant={variant}
          bottom={cardType === CardType.PHYSICAL ? '28%' : '13%'}
          right={cardType === CardType.PHYSICAL ? '40%' : '80%'}
          fontWeight={900}
        >
          VALID THRU
        </ProgramCardText>
      </TextContainer>
      {cardType === CardType.VIRTUAL && (
        <ProgramCardText
          color={cardConfig.textColor}
          variant={variant}
          bottom={'13%'}
          left={'25%'}
        >
          CVV
        </ProgramCardText>
      )}
      {cardType === CardType.VIRTUAL && (
        <ProgramCardText
          color={cardConfig.textColor}
          variant={variant}
          bottom={'5%'}
          left={'25%'}
        >
          123
        </ProgramCardText>
      )}
    </ProgramCardContainer>
  );
}

export default ProgramCardFront;
