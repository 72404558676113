/* eslint-disable react/jsx-pascal-case */
import { Box, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { listCardsForCurrentLevel } from '../../../../api/card';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardAllotmentOrderDto,
  CardAllotmentStatusEnum,
  CardStatus,
} from '../../../../types/card';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { CardAllocationService } from '../../../apis';
import CIMS_Modal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import { useCardOrderRequest } from '../context';
import { min } from 'lodash';
import { EditFormFiled } from '../../../../components/common/deprecated/EditFormFiled';

type ApproveRequestModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  orderData?: CardAllotmentOrderDto;
};

function ApproveRequestModal({
  isOpen,
  handleClose,
  orderData,
}: ApproveRequestModalProps) {
  const hookForm = useForm<{
    numberOfKits: number;
  }>({
    mode: 'all',
  });
  const { clientId, userDivisionId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const { listAllotmentQuery } = useCardOrderRequest();

  const listCardQuery = useQuery({
    queryKey: [
      'list_card_currentLevel',
      { clientId, programId: orderData?.programId, userDivisionId },
    ],
    queryFn: () => {
      return listCardsForCurrentLevel({
        divisionId: userDivisionId,
        cardStatusList: [CardStatus.IN_STOCK],
        programId: [orderData?.programId || ''],
        pageNumber: 0,
        pageSize: 10,
      });
    },
    enabled: !!clientId && !!orderData?.programId,
    onError: (data) => {
      setSnackbar(
        getErrorMessageFromErrorObj(
          data,
          'Error in fetching cards for programId - ' + orderData?.programId
        )
      );
    },
  });

  const onSubmit = () => {
    const data = hookForm.getValues();
    if (orderData?.id) {
      CardAllocationService.updateAllocationStatus({
        cardAllotmentOrderId: orderData?.id || '',
        numberOfKits: data.numberOfKits,
        status: CardAllotmentStatusEnum.APPROVED,
      })
        .then((res) => {
          setSnackbar('Allotment order approved');
          listAllotmentQuery?.refetch();
          hookForm.reset();
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Error in aproving the request'),
            'error'
          );
        });
    } else {
      setSnackbar('Allotment Order is not defined ', 'error');
    }
  };

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Approve',
      disabled: !hookForm.formState.isValid,
      onSubmit: () => {
        onSubmit && onSubmit();
        handleClose();
      },
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <CIMS_Modal
      maxWidth="sm"
      open={isOpen}
      handleClose={handleClose}
      header="Approve Request"
      buttonGroup={buttons}
    >
      <Box sx={{ px: 3, py: 2 }}>
        <Stack spacing={2}>
          <Controller
            name="numberOfKits"
            control={hookForm.control}
            rules={{
              required: 'Required',
              min: 1,
              max:
                listCardQuery.data?.totalItems &&
                orderData?.requestedKits &&
                Math.min(
                  listCardQuery.data?.totalItems,
                  orderData?.requestedKits
                ),
            }}
            render={({ field }) => (
              <EditFormFiled
                title="CARDS TO BE ALLOTED"
                type="number"
                description={field.value ? field.value.toString() : ''}
                onChange={field.onChange}
                placeHolder={'Enter Number of Cards to be allotted'}
              />
            )}
          />
          {listCardQuery.isLoading ? (
            <Skeleton />
          ) : (
            <>
              <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                <Typography fontSize={13} color="#676B76">
                  {'Requested Kits:'}
                </Typography>
                <Typography fontSize={13} color={'#222222'}>
                  {orderData?.requestedKits}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                <Typography fontSize={13} color="#676B76">
                  {'Available Kits:'}
                </Typography>
                <Typography fontSize={13} color={'#222222'}>
                  {listCardQuery.data?.totalItems}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </CIMS_Modal>
  );
}

export default ApproveRequestModal;
