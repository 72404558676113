/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Controller, UseFormReturn } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import VegaHeader from '../../../../components/common/VegaHeader';
import { PaginatedResponse } from '../../../../mcc/types/Commons';
import { Level } from '../../../../types/client';
import { EntityDto } from '../../../../types/entity';
import {
  CardThresholdMap,
  CardThresholdPolicy,
  CardThresholdV2Policy,
} from '../../../../types/policy';
import UnstyledDataGrid from '../../../components/UnstyledDataGrid';
import CIMS_TextField from '../../../components/common/CIMS_TextField';
import { DivisionDto } from '../../../../types/division';

interface IProps {
  listPolicyQuery: UseQueryResult<CardThresholdV2Policy, unknown>;
  divisionQuery: UseQueryResult<PaginatedResponse<DivisionDto>, unknown>;
  hookForm: UseFormReturn<CardThresholdV2Policy, any, undefined>;
}
export const EditThresholdPolicies = ({
  listPolicyQuery,
  divisionQuery,
  hookForm,
}: IProps) => {
  return (
    <UnstyledDataGrid
      columns={getColumns(listPolicyQuery.data, hookForm)}
      rows={divisionQuery.data?.records || []}
      components={{
        Toolbar: (props) => {
          return (
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              my={2}
            >
              <Stack
                direction={'row'}
                className="dataGrid"
                alignItems={'center'}
                spacing={2}
              >
                <GridToolbarQuickFilter
                  variant="outlined"
                  sx={{
                    bgcolor: 'white',
                    color: '#999DA8',
                    height: '35px',
                    minWidth: '320px',
                    borderRadius: '9px',
                  }}
                />
              </Stack>
            </Stack>
          );
        },
      }}
    />
  );
};
const getColumns = (
  cardThresholdMap: CardThresholdV2Policy | undefined,
  hookForm: UseFormReturn<CardThresholdV2Policy, any, undefined>
): GridColDef<DivisionDto>[] => [
  {
    field: 'code',
    renderHeader: () => <VegaHeader text={'code'} />,
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      return props.row.divisionCode;
      // return dayjs(props.row.createdAt).format('DD/MM/YYYY');
    },
    renderCell: (props) => {
      const division = props.row;
      return (
        <Controller
          name={`card_threshold_map.${division.id}.division_code`}
          control={hookForm.control}
          defaultValue={division.divisionCode as any}
          render={({ field }) => (
            <CIMS_TextField isFixedHeight {...field} disabled />
          )}
        />
      );
    },
  },
  {
    field: 'name',
    renderHeader: () => <VegaHeader text={'name'} />,

    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      return props.row.name;
    },
    renderCell: (props) => {
      const division = props.row;
      return (
        <Controller
          name={`card_threshold_map.${division.id}.division_name`}
          control={hookForm.control}
          defaultValue={division.name as any}
          render={({ field }) => (
            <CIMS_TextField isFixedHeight {...field} disabled />
          )}
        />
      );
    },
  },
  {
    field: 'minThreshold',
    renderHeader: () => <VegaHeader text={'MIN THRESHOLD'} />,
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      const entityId = props.row.id;
      return cardThresholdMap?.card_threshold_map &&
        cardThresholdMap.card_threshold_map[entityId] &&
        !isNaN(cardThresholdMap.card_threshold_map[entityId]?.min_threshold)
        ? cardThresholdMap.card_threshold_map[entityId]?.min_threshold
        : 'NA';
    },
    renderCell: (props) => {
      const entity = props.row;
      const maxThreshold = hookForm.getValues(
        `card_threshold_map.${entity.id}.max_threshold`
      );
      return (
        <Controller
          name={`card_threshold_map.${entity.id}.min_threshold`}
          control={hookForm.control}
          rules={{
            min: { message: 'Minimum value should be 0', value: 0 },
            ...(maxThreshold
              ? {
                  max: {
                    message: 'Invalid min threshold',
                    value: maxThreshold,
                  },
                }
              : {}),
          }}
          render={({ field, fieldState }) => (
            <CIMS_TextField
              type="number"
              isFixedHeight
              {...field}
              onChange={(e) => {
                const val = e.target.value;
                const parsedVal = parseInt(val || '');
                const count = isNaN(parsedVal) ? '' : parsedVal.toString();
                field.onChange(count);
              }}
              labelText=" "
              {...(fieldState.error && {
                error: true,
                labelText: fieldState.error.message,
              })}
            />
          )}
        />
      );
    },
  },
  {
    field: 'maxThreshold',
    renderHeader: () => <VegaHeader text={'MAX THRESHOLD'} />,
    flex: 1,
    minWidth: 100,
    valueGetter: (props) => {
      const entityId = props.row.id;
      return cardThresholdMap?.card_threshold_map &&
        cardThresholdMap.card_threshold_map[entityId] &&
        !isNaN(cardThresholdMap.card_threshold_map[entityId]?.max_threshold)
        ? cardThresholdMap.card_threshold_map[entityId]?.max_threshold
        : 'NA';
    },
    renderCell: (props) => {
      const entity = props.row;
      return (
        <Controller
          name={`card_threshold_map.${entity.id}.max_threshold`}
          control={hookForm.control}
          rules={{
            min: {
              message: 'Invalid max threshold',
              value:
                hookForm.getValues(
                  `card_threshold_map.${entity.id}.min_threshold`
                ) ?? 0,
            },
          }}
          render={({ field, fieldState }) => (
            <CIMS_TextField
              type="number"
              isFixedHeight
              {...field}
              onChange={(e) => {
                const val = e.target.value;
                const parsedVal = parseInt(val || '');
                const count = isNaN(parsedVal) ? '' : parsedVal.toString();
                field.onChange(count);
              }}
              labelText=" "
              {...(fieldState.error && {
                error: true,
                labelText: fieldState.error.message,
              })}
            />
          )}
        />
      );
    },
  },
];
