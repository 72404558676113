import React from 'react';
import Box from '@mui/material/Box';
import DataTile from '../DataTile';
import { useAppSelector } from '../../../store/hook';
import { BillingPolicyInterface } from '../../../types/policy';
import { convertCamelCase } from '../../../utils/stringUtils';

type BillingPolicyProps = {};

const containerSx = {
  flex: 1,
};

function ViewBillingPolicy({}: BillingPolicyProps) {
  const activePolicy = useAppSelector(
    (state) => state.programManagement.activePolicy
  ) as BillingPolicyInterface;
  return (
    <Box
      sx={{
        p: {
          xs: 1,
          sm: 3,
        },
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          gap: 3,
          flex: 1,
        }}
      >
        <DataTile
          title="Billing Cycle Type"
          value={convertCamelCase(activePolicy.billing_cycle_type)}
          containerSx={containerSx}
        />
        <DataTile
          title="Cycle End Day"
          value={`${activePolicy.cycle_end_date} day of the month`}
          containerSx={containerSx}
        />
        <DataTile
          title="Bill Generation"
          value={`${activePolicy.days_between_end_date_and_bill_date} day(s) after cycle end date`}
          containerSx={containerSx}
        />
        <DataTile
          title="Due Date"
          value={`${activePolicy.due_date} of every month`}
          containerSx={containerSx}
        />
      </Box>
    </Box>
  );
}

export default ViewBillingPolicy;
