import axios from 'axios';
import {
  AggregatedBinAllocationDto,
  BinAllocationDto,
  BinAllocationNotificationDto,
  BinDto,
  BinProgramDto,
  ListBinAllocationNotificationRequest,
  ListBinAllocationRequest,
  ListBinProgramRequest,
} from '../../../types/card';
import { PaginatedResponse } from '../../../utils/api';
import { CARD_BASE } from '../../../api/endpoints';

// const BIN_BASE_URL = BASE_URL + 'bin';
const BIN_BASE_URL = `${CARD_BASE}/bin`;

const ENDPOINTS = {
  LIST_BY_IDS: '/listByIds',
  LIST_FOR_CLIENT: '/listForClient',
  LIST_BIN_ALLOCATION: '/listBinAllocation',
  LIST_BIN_ALLOCATION_AGGREGATED: '/binAllocation/aggregated',
  LIST_BIN_PROGRAM: '/binProgram/list',
  LIST_BIN_NOTIFICATIONS: '/listNotification',
};
export class BinService {
  static async listByIds(data: {
    ids: number[];
    page: number;
    size: number;
  }): Promise<PaginatedResponse<BinDto>> {
    const endpoint = BIN_BASE_URL + ENDPOINTS.LIST_BY_IDS;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async listForClient(data: {
    clientId: string;
    page: number;
    size: number;
  }): Promise<PaginatedResponse<BinDto>> {
    const endpoint = BIN_BASE_URL + ENDPOINTS.LIST_FOR_CLIENT;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async listBinAllocation(
    data: Partial<ListBinAllocationRequest>
  ): Promise<PaginatedResponse<BinAllocationDto>> {
    const endpoint = BIN_BASE_URL + ENDPOINTS.LIST_BIN_ALLOCATION;
    const response = await axios.post(endpoint, data);
    return response.data;
  }

  static async listBinAllocationAggregated(
    data: Partial<ListBinAllocationRequest>
  ): Promise<AggregatedBinAllocationDto> {
    const endpoint = BIN_BASE_URL + ENDPOINTS.LIST_BIN_ALLOCATION_AGGREGATED;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async listBinProgram(
    data: Partial<ListBinProgramRequest>
  ): Promise<PaginatedResponse<BinProgramDto>> {
    const endpoint = BIN_BASE_URL + ENDPOINTS.LIST_BIN_PROGRAM;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async listBinNotification(
    data: Partial<ListBinAllocationNotificationRequest>
  ): Promise<PaginatedResponse<BinAllocationNotificationDto>> {
    const endpoint = BIN_BASE_URL + ENDPOINTS.LIST_BIN_NOTIFICATIONS;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
}
