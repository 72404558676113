import Box from '@mui/material/Box';
import VegaHeader from '../common/VegaHeader';
import VegaTextV2 from '../common/v2/VegaTextV2';
import { SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

type DataTileProps = {
  title: string;
  value?: string;
  children?: React.ReactNode;
  hasSeparator?: boolean;
  containerSx?: SxProps<Theme>;
};
const Separator = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mx: 3,
      }}
    >
      <Box sx={{ width: '1px', height: 12, bgcolor: '#E1E4EB' }} />
    </Box>
  );
};

function DataTile({
  title,
  value,
  children,
  hasSeparator,
  containerSx,
}: DataTileProps) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          whiteSpace: 'nowrap',
          ...containerSx,
        }}
      >
        <VegaHeader text={title} />
        {value ? (
          <VegaTextV2
            fontSize={13}
            color="#222"
            maxWidth="280px"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: 'inherit',
            }}
          >
            {value}
          </VegaTextV2>
        ) : null}
        {!value && children ? children : null}
      </Box>
      {hasSeparator ? <Separator /> : null}
    </>
  );
}

export default DataTile;
