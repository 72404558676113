import React, { useState } from 'react';

export interface ISelecteAccountProps {
  programName: string;
  lastUpdated: string;
  progrmaId: string;
  productType: string;
  liveOn: string;
  status: string;
  income: number;
  cost: number;
}

type ProfitLossContextType = {
  isSelectedProgram: boolean;
  isOpenDownloadReportDialog: boolean;
  selecteAccount: Partial<ISelecteAccountProps>;
  setIsSelectedProgram: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenDownloadReportDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectAccount: React.Dispatch<
    React.SetStateAction<Partial<ISelecteAccountProps>>
  >;
};

export const ProfitLossContext =
  React.createContext<ProfitLossContextType | null>(null);

export const useProfitLoss = () =>
  React.useContext(ProfitLossContext) as ProfitLossContextType;

export const ProfitLossProvider = (props: any) => {
  const [isSelectedProgram, setIsSelectedProgram] = useState(false);
  const [isOpenDownloadReportDialog, setIsOpenDownloadReportDialog] =
    useState(false);
  const [selecteAccount, setSelectAccount] = useState<
    Partial<ISelecteAccountProps>
  >({});

  return (
    <ProfitLossContext.Provider
      value={{
        isSelectedProgram,
        isOpenDownloadReportDialog,
        selecteAccount,
        setSelectAccount,
        setIsSelectedProgram,
        setIsOpenDownloadReportDialog,
      }}
    >
      {props.children}
    </ProfitLossContext.Provider>
  );
};
