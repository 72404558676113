import React, { useState } from 'react';
import {
  Stack,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
} from '@mui/material';
import { GREY } from '../../../../constants/style';
import { ArrowForwardIos } from '@mui/icons-material';
import { OPEARATION_ROUTES } from '../../../../pages/Operations';
import ApplicationSummary from './ApplicationSummary';
import VegaTab from '../../../common/VegaTab';
import { dateFormat } from '../../../../mcc/variable';
import { VegaTextField } from '../../../common';
import BasicDetails from './BasicDetails';
import KYC_SelfieDetails from './KYC_SelfieDetails';
import DocumentsDetails from './DocumentsDetails';
import RiskScoreDetails from './RiskScoreDetails';
import VegaContainedButton from '../../../common/VegaContainedButton';
import VegaSelect from '../../../common/VegaSelect';
import VegaTextArea from '../../../common/VegaTextArea';
import VegaOutlineButton from '../../../common/VegaOutlineButton';
import { useApplicationManagmentConfig } from '../../../../providers/ApplicationManagmentProvider';
import CustomerLoadingPage from '../../../../pages/Support/CustomerLoadingPage';

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<string>>;
}

export enum ApplicationDetailsType {
  BASIC_DETAILS = 'Basic Details',
  KYC_SELFIE = 'KYC & Selfie',
  DOCUMENTS = 'Documents',
  RISK_SCORE = 'Risk Score',
}

const ApplicationViewWrapper = ({ setShow }: IProps) => {
  const { didAcceptLimitOffer, didRejectLimitOffer, setCustomer, loading } =
    useApplicationManagmentConfig();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selected, setSelected] = useState<string>(
    ApplicationDetailsType.BASIC_DETAILS
  );
  const [isApprove, setIsApprove] = useState<boolean>(false);
  const [isReject, setIsReject] = useState<boolean>(false);

  const toggleApprove = () => {
    setIsApprove(!isApprove);
  };
  const toggleReject = () => {
    setIsReject(!isReject);
  };

  // const onApprove = () => {
  //   toggleApprove();
  // };
  // const onReject = () => {
  //   toggleReject();
  // };

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <Typography
          onClick={() => {
            setShow(OPEARATION_ROUTES.MAIN);
            setCustomer(null);
          }}
          className="font-opensans-400"
          sx={{
            fontSize: 14,
            cursor: 'pointer',
          }}
          color={GREY.grey_light}
        >
          Application Management{' '}
        </Typography>
        <ArrowForwardIos sx={{ fontSize: 12, color: GREY.grey_light }} />
        <Typography
          className="font-opensans-700"
          fontSize={14}
          color={GREY.grey_light}
        >
          Application
        </Typography>
      </Stack>
      <Box sx={{ mt: 2 }}>
        <ApplicationSummary onApprove={toggleApprove} onRject={toggleReject} />
      </Box>
      <Box mt={3}>
        <Box
          sx={{ mb: 2, borderRadius: '16px', bgcolor: 'white', px: 4, pt: 2 }}
        >
          <Stack
            direction={'row'}
            sx={{ overflow: 'auto' }}
            gap={isMobile ? 1 : 4}
          >
            {[
              ApplicationDetailsType.BASIC_DETAILS,
              ApplicationDetailsType.KYC_SELFIE,
              ApplicationDetailsType.DOCUMENTS,
              ApplicationDetailsType.RISK_SCORE,
            ].map((detail: string) => {
              return (
                <Stack
                  sx={{
                    pb: 2,
                    cursor: 'pointer',
                    ...(detail === selected && {
                      borderBottom: `2px solid ${GREY.darkest}`,
                    }),
                  }}
                  onClick={() => setSelected(detail)}
                >
                  <Typography
                    className={`${
                      detail === selected
                        ? 'font-opensans-700'
                        : 'font-opensans-400'
                    }`}
                    fontSize={isMobile ? 12 : 18}
                    sx={{ color: GREY.darkest }}
                  >
                    {detail}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Box>
        <Box
          sx={{ mb: 2, borderRadius: '20px', bgcolor: 'white', px: 4, pt: 3 }}
        >
          {Component(selected, loading)}
        </Box>
      </Box>
      <Dialog maxWidth="sm" open={isApprove} onClose={toggleApprove}>
        <Box sx={{ px: 4, py: 3 }}>
          <Typography
            className="font-opensans-700"
            fontSize={24}
            color={GREY.darkest}
          >
            Are you sure you want to Approve?
          </Typography>

          <Stack direction={'row'} gap={2} mt={2}>
            <VegaContainedButton
              text="Approve"
              isPrimary
              sx={{ width: '100%' }}
              onClick={() => {
                didAcceptLimitOffer();
                toggleApprove();
              }}
            />
            <VegaOutlineButton
              text="Cancel"
              isPrimary
              sx={{ width: '100%' }}
              py={1.5}
              onClick={toggleApprove}
            />
          </Stack>
        </Box>
      </Dialog>
      <Dialog maxWidth="sm" open={isReject} onClose={toggleReject}>
        <Box sx={{ px: 4, py: 3 }}>
          <Typography
            className="font-opensans-700"
            fontSize={24}
            color={GREY.darkest}
          >
            Are you sure you want to reject?
          </Typography>
          <Stack
            direction={'column'}
            gap={2}
            sx={{
              py: 2,
            }}
          >
            <VegaSelect
              options={['Reason']}
              selected={'Reason'}
              onChange={() => {}}
            />
            <Stack direction={'column'} gap={1}>
              <Typography
                className="font-opensans-400"
                fontSize={14}
                color={GREY.darkest}
              >
                Other remarks
              </Typography>
              <VegaTextField />
            </Stack>
          </Stack>
          <Stack direction={'row'} gap={2} mt={2}>
            <VegaContainedButton
              text="Submit"
              isPrimary
              sx={{ width: '100%' }}
              py={1.5}
              onClick={() => {
                didRejectLimitOffer();
                toggleReject();
              }}
            />
            <VegaOutlineButton
              text="Cancel"
              isPrimary
              sx={{ width: '100%' }}
              py={1.5}
              onClick={toggleReject}
            />
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default ApplicationViewWrapper;

const Component = (selected: string, loading: boolean) => {
  switch (selected) {
    case ApplicationDetailsType.KYC_SELFIE:
      return <KYC_SelfieDetails />;
    case ApplicationDetailsType.DOCUMENTS:
      return <DocumentsDetails />;
    case ApplicationDetailsType.RISK_SCORE:
      return <RiskScoreDetails />;
    default:
      return loading ? <CustomerLoadingPage /> : <BasicDetails />;
  }
};
