import { Box, Grid } from '@mui/material';
import VegaTextV2 from '../../../../../common/v2/VegaTextV2';
import VegaFormInputFieldV2 from '../../../../../common/v2/VegaFormInputField';
import VegaTextFieldV2 from '../../../../../common/v2/VegaTextFieldV2';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../common/v2/VegaSelectV2';
import { useCampaignRule } from '../../../../providers/CampaignRuleProvider';
import _ from 'lodash';
import {
  CampaignRuleType,
  MilestoneEventType,
  NonTransactionalEventType,
  TransactionalEventType,
} from '../../../../../../types/RewardsLoyalty';

const RuleDetails = () => {
  const {
    selectedRuleType,
    onRuleNameChange,
    onRuleTypeChange,
    ruleName,
    onRuleEventChange,
    selectedEvent,
  } = useCampaignRule();

  function onRuleTypeSelect(ruleType: string) {
    onRuleTypeChange(ruleType);
  }

  return (
    <Box
      style={{
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '12px',
        paddingBottom: '12px',
        backgroundColor: '#FFFFFF',
      }}
    >
      <VegaTextV2 text="Rule Details"></VegaTextV2>
      <Grid container columnSpacing={2} style={{ marginTop: '0.75rem' }}>
        <Grid item xs={12} sm={3}>
          <VegaFormInputFieldV2 label="Rule Name">
            <VegaTextFieldV2
              placeholder="Enter Rule Name"
              value={ruleName}
              onChange={(e) => {
                onRuleNameChange(e.target.value);
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={12} sm={3}>
          <VegaFormInputFieldV2 label="Segment">
            <VegaSelectV2
              placeholder="Select Segment"
              options={segmentOptions()}
              value={'ALL'}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={12} sm={3}>
          <VegaFormInputFieldV2 label="Rule Type">
            <VegaSelectV2
              placeholder="Select Rule Type"
              options={ruleTypeOptions()}
              value={selectedRuleType}
              onChange={(e) => {
                onRuleTypeSelect(e.target.value as string);
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={12} sm={3}>
          <VegaFormInputFieldV2 label="Event">
            <VegaSelectV2
              placeholder="Select Event Type"
              options={eventTypeOptions(selectedRuleType)}
              value={selectedEvent ?? ''}
              onChange={(e) => {
                onRuleEventChange(e.target.value as string);
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RuleDetails;

const segmentOptions = () =>
  ['ALL'].map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const ruleTypeOptions = () =>
  Object.values(CampaignRuleType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const eventTypeOptions = (ruleType: string | null) => {
  if (!ruleType) return [];
  switch (ruleType) {
    case CampaignRuleType.TRANSACTIONAL:
      return transactionalEventOptions();
    case CampaignRuleType.NON_TRANSACTIONAL:
      return nonTransactionalEventOptions();
    case CampaignRuleType.MILESTONE:
      return mileStoneEventOptions();
  }
};

const transactionalEventOptions = () =>
  Object.values(TransactionalEventType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const nonTransactionalEventOptions = () =>
  Object.values(NonTransactionalEventType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const mileStoneEventOptions = () =>
  Object.values(MilestoneEventType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
