import { VegaKycBaseStep } from '../components/ApplicationManagment/KYCflow/KYCFlowDataTypes';

const addOrderToStepsIfRequired = (steps: VegaKycBaseStep[]) => {
  return steps.map((item, index) => {
    var mappedSubSteps = item.metaData.subSteps;
    if (item.metaData.subSteps) {
      mappedSubSteps = item.metaData.subSteps.map((subItem, subItemIndex) => {
        if (subItem.order) return subItem;
        return { ...subItem, order: subItemIndex };
      });
    }

    if (item.order) {
      return {
        ...item,
        metaData: {
          ...item.metaData,
          subSteps: mappedSubSteps,
        },
      };
    }

    return {
      ...item,
      order: index,
      metaData: {
        ...item.metaData,
        subSteps: mappedSubSteps,
      },
    };
  });
};

function sortKycSteps(steps: VegaKycBaseStep[]): VegaKycBaseStep[] {
  return steps
    .sort((a, b) => a.order - b.order)
    .map((step) => {
      if (step.metaData.subSteps && step.metaData.subSteps.length > 0) {
        step.metaData.subSteps = sortKycSteps(step.metaData.subSteps);
      }
      return step;
    });
}

export const formatOnboardingFlow = (flow: VegaKycBaseStep[]) => {
  const orderedFlow = addOrderToStepsIfRequired(flow);
  const sortedFlow = sortKycSteps(orderedFlow);

  return sortedFlow;
};
