import _ from 'lodash';
import {
  RuleFinancialData,
  ContributionData,
  InlcusionExclusionData,
  PointBasedRewardsData,
  GiftVoucher,
  CampaignRuleType,
  CampaignType,
  MilestoneEventType,
  ContributionEntityType,
} from '../../../types/RewardsLoyalty';
import {
  NonPointBasedMilestoneRewardsData,
  PointBasedMilestoneRewardsData,
} from './CampaignRuleProvider';

export class CampaignRuleValidator {
  static validateFincancialData(
    isRulePaid: boolean,
    data: Partial<RuleFinancialData>
  ): string[] {
    const errors: string[] = [];
    if (isRulePaid) {
      const isFinancialDataValid = (data.tenures ?? []).length > 0;
      if (isFinancialDataValid == false) {
        errors.push('Financial data is required');
      }
    }
    return errors;
  }

  static validateContributionData(data: Partial<ContributionData>): string[] {
    const errors: string[] = [];
    const hasContributions = (data.contributionEntities ?? []).length > 0;
    if (hasContributions) {
      const entities = data.contributionEntities ?? [];
      var totalPercentage = 0;
      entities.forEach((item) => {
        if ((item?.percentage ?? 0) <= 0)
          errors.push(
            'Percentage must be greater than zero for ' + item.contributorType
          );
        totalPercentage += item.percentage ?? 0;
        if (
          !item.name &&
          item.contributorType != ContributionEntityType.PAYMENT_NETWORK
        ) {
          errors.push(
            'Contribution entity name is required for ' + item.contributorType
          );
        }
      });
      if (totalPercentage != 100) {
        errors.push(
          'Contribution percentage should be equal to 100. Current value: ' +
            totalPercentage
        );
      }
    }
    return errors;
  }

  static validateInclusionExclusionData(
    data: Partial<InlcusionExclusionData>
  ): string[] {
    const errors: string[] = [];
    const isTransactionAmountValid = (data.transactionAmount ?? 0) > 0;
    if (!isTransactionAmountValid)
      errors.push('Transaction Amount must be greater than zero');

    if (data.isTransactionInclusive == null)
      errors.push('Transaction Condition is required');
    if (data.isMerchantInclusive == null)
      errors.push('Merchant Condition is required');
    if (data.isCategoriesInclusive == null)
      errors.push('Category Condition is required');

    const hasTransactionsValues = (data.transactionTypes ?? []).length > 0;
    if (!hasTransactionsValues)
      errors.push('Transaction types should be specified');
    const hasMerchantValues = (data.merchants ?? []).length > 0;
    if (!hasMerchantValues) errors.push('Merchants should be specified');
    const hasCategoryValues = (data.categories ?? []).length > 0;
    if (!hasCategoryValues) errors.push('Categories should be specified');

    return errors;
  }

  static validateCommonData(data: {
    ruleName?: string;
    ruleType?: string | null;
    event?: string;
  }): string[] {
    const errors: string[] = [];
    if (data.ruleName == null || data.ruleName.length <= 0)
      errors.push('Rule name is required');
    if (data.ruleType == null || data.ruleType.length <= 0)
      errors.push('Rule Type is required');
    if (data.event == null || data.event.length <= 0)
      errors.push('Rule Event is required');
    return errors;
  }

  static validateRewardsData(data: {
    rewardsData: Partial<PointBasedRewardsData>;
    giftsList: Partial<GiftVoucher>[];
    milestoneData: Partial<PointBasedMilestoneRewardsData>;
    nonPointBasedMilestoneData: Partial<NonPointBasedMilestoneRewardsData>;
    ruleType?: string | null;
    campaignType?: string;
    eventType: string;
  }): string[] {
    if (!data.ruleType || !data.campaignType) return [];
    if (data.campaignType == CampaignType.POINT_BASED) {
      switch (data.ruleType) {
        case CampaignRuleType.TRANSACTIONAL:
          return this.validatePointBasedTransactionalRewardsData(
            data.rewardsData
          );
        case CampaignRuleType.NON_TRANSACTIONAL:
          return this.validatePointBasedNonTransactionalRewardsData(
            data.rewardsData
          );
        case CampaignRuleType.MILESTONE:
          return this.validatePointBasedMilestonesData(
            data.milestoneData,
            data.eventType
          );
      }
    } else if (data.campaignType == CampaignType.NON_POINT_BASED) {
      switch (data.ruleType) {
        case CampaignRuleType.TRANSACTIONAL:
          return this.validateNonPointBasedTransactionsData({
            giftsList: data.giftsList,
            rewardsData: data.rewardsData,
          });
        case CampaignRuleType.NON_TRANSACTIONAL:
          return this.validateNonPointBasedNonTransactionsData({
            giftsList: data.giftsList,
          });
        case CampaignRuleType.MILESTONE:
          return this.validateNonPointBasedMilestonesData(
            data.nonPointBasedMilestoneData,
            data.eventType
          );
      }
    }
    return [];
  }

  private static validateGiftsData(
    giftsData: Partial<GiftVoucher>[]
  ): string[] {
    const errors: string[] = [];

    const selectedGifts = giftsData.filter((item) => item.giftId != null);
    if (selectedGifts.length <= 0) {
      errors.push('Altleast one Voucher is required');
    }
    return errors;
  }

  private static validateNonPointBasedMilestonesData(
    data: Partial<NonPointBasedMilestoneRewardsData>,
    event: string
  ): string[] {
    const errors: string[] = [];
    if (data.iteration == null || data.iteration.length <= 0)
      errors.push('Iteration is required for Milestones');
    if (data.milestones == null || data.milestones.length <= 0)
      errors.push('Milestones are required');

    data.milestones?.forEach((item, index) => {
      const count = item.count ?? 0;
      const volume = item.volume ?? 0;
      if (event.length > 0) {
        if (event == MilestoneEventType.BY_COUNT && count <= 0) {
          errors.push(`Count for Milestone ${index} is required`);
        } else if (event == MilestoneEventType.BY_VOLUME && volume <= 0) {
          errors.push(`Volume for Milestone ${index} is required`);
        } else if (event == MilestoneEventType.BOTH) {
          if (count <= 0)
            errors.push(`Count for Milestone ${index} is required`);
          if (volume <= 0)
            errors.push(`Volume for Milestone ${index} is required`);
        }
      }
    });

    return errors;
  }

  private static validateNonPointBasedTransactionsData(data: {
    giftsList: Partial<GiftVoucher>[];
    rewardsData: Partial<PointBasedRewardsData>;
  }): string[] {
    var errors: string[] = [];
    const iteration = data.rewardsData.iteration;
    const amount = data.rewardsData.perAmount;
    if (iteration == null || iteration.length <= 0)
      errors.push('Iteration is required for Non Point Based Transactions');
    if (amount == null || amount <= 0) {
      errors.push(
        'Transaction Amount is required for Non Point Based Transactions'
      );
    }
    const giftsErrors = this.validateGiftsData(data.giftsList);
    errors = [...errors, ...giftsErrors];
    return errors;
  }

  private static validateNonPointBasedNonTransactionsData(data: {
    giftsList: Partial<GiftVoucher>[];
  }): string[] {
    var errors: string[] = [];
    const giftsErrors = this.validateGiftsData(data.giftsList);
    errors = [...giftsErrors];
    return errors;
  }

  private static validatePointBasedMilestonesData(
    data: Partial<PointBasedMilestoneRewardsData>,
    event: string
  ): string[] {
    const errors: string[] = [];
    if (data.iteration == null || data.iteration.length <= 0)
      errors.push('Iteration is required for Milestones');
    if (data.milestones == null || data.milestones.length <= 0)
      errors.push('Milestones are required');

    data.milestones?.forEach((item, index) => {
      if (item.rewardPoints == null || item.rewardPoints <= 0)
        errors.push(`Reward Points for Milestone ${index} are required`);
      const count = item.count ?? 0;
      const volume = item.volume ?? 0;
      if (event.length > 0) {
        if (event == MilestoneEventType.BY_COUNT && count <= 0) {
          errors.push(`Count for Milestone ${index} is required`);
        } else if (event == MilestoneEventType.BY_VOLUME && volume <= 0) {
          errors.push(`Volume for Milestone ${index} is required`);
        } else if (event == MilestoneEventType.BOTH) {
          if (count <= 0)
            errors.push(`Count for Milestone ${index} is required`);
          if (volume <= 0)
            errors.push(`Volume for Milestone ${index} is required`);
        }
      }
    });

    return errors;
  }

  private static validatePointBasedTransactionalRewardsData(
    data: Partial<PointBasedRewardsData>
  ): string[] {
    const errors: string[] = [];
    if (data.iteration == null || data.iteration.length <= 0)
      errors.push('Iteration required for transactional rule');
    if (data.maximumPoints == null || data.maximumPoints <= 0)
      errors.push('Maximum points is required for transactional rule');
    if (data.perAmount == null || data.perAmount <= 0)
      errors.push('Per amount is required for transactional rule');
    if (data.rewardPoints == null || data.rewardPoints <= 0)
      errors.push('Reward Point is required for transactional rule');
    return errors;
  }

  private static validatePointBasedNonTransactionalRewardsData(
    data: Partial<PointBasedRewardsData>
  ): string[] {
    const errors: string[] = [];
    if (data.iteration == null || data.iteration.length <= 0)
      errors.push('Iteration is required for Non transactional rule');
    if (data.rewardPoints == null || data.rewardPoints <= 0)
      errors.push('Reward Point is required for Non transactional rule');
    return errors;
  }
}
