import { CheckerMakerStatusEnum } from '../../../../enums/commons';
import { IConsolidatedAccountData } from '../../../types/Commons';

export enum PAYMENT_MODE {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum VALUE_TYPE {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export interface IReloadCardData extends IReloadCardRequest {
  id?: string;
  customerName: string;
  programName: string;
  paymentMode: PAYMENT_MODE;

  makerCheckerId: string;
  userId: string;
  fundLoadOrderStatus:
    | 'DRAFT'
    | 'UNDER_REVIEW'
    | 'FUND_RELOADED'
    | 'REJECTED'
    | 'FAILED';
  makerCheckerStatus: CheckerMakerStatusEnum;
  branchId: string;
  corporateId: string;
  corporateTransactionId: string;
  openCreditId: string;
  accountConsolidatedDto: IConsolidatedAccountData;
  corporateConsolidatedDto: any;
  createdAt: string;
  updatedAt: string;
}
export interface IReloadCardRequest {
  accountId: string;
  branchId: string;
  corporateId: string;
  sourceCurrency: string;
  destinationCurrency: string;
  amount: number;
  markupValueType: VALUE_TYPE;
  markupValue: number;
  transactionTime: string;
}
