import { Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  InstallmentPolicy,
  TxnToEmiPolicyInterface,
} from '../../../types/policy';
import { IRowData, PolicySummaryTable } from './common/SummaryTable';

interface Props {
  policy?: TxnToEmiPolicyInterface | null;
}

/**
 * {
    "policy_id": "a3ab4287-f33a-4420-ae02-33ed6b90806e",
    "policy_name": "TRANSACTION TO INSTALLMENT policy",
    "policy_type": "TRANSACTION_TO_INSTALLMENT",
    "policy_description": "Testing TRANSACTION TO INSTALLMENT policy",
    "is_override": false,
    "start_date": "2022-10-01",
    "end_date": "2026-10-01",
    "priority": null,
    "status": "APPROVED",
    "cohort_id": null,
    "updated_at": "2023-05-09T12:41:58.364+00:00",
    "minimum_amount": 100,
    "installment_policy": [
        {
            "processingFeeInPercentage": 0,
            "maxProcessingFee": 0,
            "amortizationType": "ARREAR",
            "interestChargeConfig": [
                {
                    "duration_in_months": 3,
                    "interest_rate": 12,
                    "discount": 0,
                    "effective_interest": 0
                },
                {
                    "duration_in_months": 6,
                    "interest_rate": 12,
                    "discount": 0,
                    "effective_interest": 0
                }
            ],
            "interestType": "REDUCING_BALANCE",
            "mcc": [
                1001,
                1002,
                123
            ]
        }
    ],
    "next_bill_date_threshold_days": null,
    "transaction_category": "CARD"
}
 */
function TransactionToEmiPolicySummary({ policy }: Props) {
  const [installmentPolicy, setInstallmentPolicy] =
    useState<InstallmentPolicy>();

  useEffect(() => {
    if (policy?.installment_policy && policy.installment_policy.length > 0) {
      const firstPolicy = policy.installment_policy[0];
      setInstallmentPolicy(firstPolicy);
    } else {
      setInstallmentPolicy(undefined);
    }
  }, [policy]);

  const getChargeConfigRows = () => {
    const summaryRows: IRowData[] = [];

    installmentPolicy?.interestChargeConfig.forEach((item) => {
      summaryRows.push({
        rowData: [
          item.duration_in_months.toString(),
          item.interest_rate.toString(),
        ],
      });
    });
    return summaryRows;
  };

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} spacing={2}>
        <Stack flex={1}>
          <PolicySummaryTable
            headers={['Minimum Amount', 'MCC']}
            rows={[
              {
                rowData: [
                  policy?.minimum_amount?.toString() ?? '-',
                  installmentPolicy?.mcc.join(',') ?? '-',
                ],
              },
            ]}
          />
        </Stack>
        <Stack flex={1}>
          <PolicySummaryTable
            headers={['Amortization Type', 'Interest Type']}
            rows={[
              {
                rowData: [
                  _.startCase(
                    installmentPolicy?.amortizationType?.toString()
                  ) ?? '-',
                  _.startCase(
                    _.toLower(installmentPolicy?.interestType?.toString())
                  ) ?? '-',
                ],
              },
            ]}
          />
        </Stack>
      </Stack>

      <PolicySummaryTable
        headers={['Duration in months', 'Interest Rate']}
        rows={getChargeConfigRows()}
      />
    </Stack>
  );
}

export default TransactionToEmiPolicySummary;
