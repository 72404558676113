import React from 'react';
import { Grid, Box } from '@mui/material';
import { VegaCard, VegaTextField } from '../../../../components/common';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import { ICommonProps } from '../../../../types/commonType';

interface IProps extends ICommonProps {}
const CustomerDetails = ({ handleChange, state }: IProps) => {
  return (
    <VegaCard>
      <Grid container alignItems={'end'} spacing={2}>
        <Grid item xs={12} sm={4}>
          <VegaTextField
            value={state?.cardNo}
            onChange={(e) => handleChange('cardNo', e.target.value)}
            key="cardNo"
            label="Card No"
            type={'text'}
            placeholder="Card No"
            disabled
          />
        </Grid>
        <Grid item container xs={12} sm={6} spacing={1}>
          <Grid>
            <VegaOutlineButton px={4} text={'Reset Password'} isPrimary />
          </Grid>
          <Grid>
            <VegaOutlineButton px={4} text={'Forgot User ID'} isPrimary />
          </Grid>
          <Grid>
            <VegaOutlineButton text={'Reset Pin'} isPrimary />
          </Grid>
        </Grid>
      </Grid>
    </VegaCard>
  );
};

export default CustomerDetails;
