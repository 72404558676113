import { DeleteOutline } from '@mui/icons-material';
import { Grid, IconButton, Stack } from '@mui/material';
import _ from 'lodash';
import {
  FinancialTenureData,
  FinancialTenureTaxType,
  IterationType,
} from '../../../../../../../../types/RewardsLoyalty';
import VegaPill from '../../../../../../../common/v2/VegaPill';
import VegaTextV2 from '../../../../../../../common/v2/VegaTextV2';
import { useCampaignRule } from '../../../../../../providers/CampaignRuleProvider';

type Props = {
  tenure: FinancialTenureData;
};

function FinancialTenure({ tenure }: Props) {
  const { deleteFinancialTenure } = useCampaignRule();

  return (
    <Stack spacing={1}>
      <Grid container columns={6} columnGap={2} alignItems="center">
        <Grid item xs={1}>
          <InputDetail heading="Tenure Type" value={tenure.tenureType} />
        </Grid>
        <Grid item xs={1}>
          <InputDetail heading="Price" value={tenure.price.toString()} />
        </Grid>
        <Grid item xs={1}>
          <InputDetail
            heading="Tax Type"
            value={_.startCase(_.toLower(tenure.taxationType))}
          />
        </Grid>
        <Grid item xs={1}>
          <div>
            <Stack direction={'row'} spacing={1} alignItems="center">
              <IconButton
                onClick={() => {
                  deleteFinancialTenure(tenure);
                }}
              >
                <DeleteOutline fontSize="small" />
              </IconButton>
              <VegaPill
                backgroundColor="rgba(238, 234, 251, 1)"
                textColor="rgba(80, 41, 219, 1)"
              >
                <VegaTextV2
                  text={`${getAmountForTaxType(tenure)} / ${getAmountSuffix(
                    tenure
                  )}`}
                  fontSize="0.5rem"
                  fontWeight={600}
                />
              </VegaPill>
            </Stack>
          </div>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default FinancialTenure;

const InputDetail = (data: { heading: string; value: string }) => {
  return (
    <Stack spacing={1} flex={1.25}>
      <VegaTextV2
        text={data.heading}
        style={{ fontWeight: 700, fontSize: '0.75rem', lineHeight: '124%' }}
      />
      <VegaTextV2 text={data.value} variant="caption" />
    </Stack>
  );
};

const getAmountForTaxType = (tenure: FinancialTenureData) => {
  switch (tenure.taxationType) {
    case FinancialTenureTaxType.INCLUSIVE:
      return tenure.price;
    case FinancialTenureTaxType.EXCLUSIVE:
      const effectivePrice = tenure.price + tenure.price * 0.18;
      return effectivePrice.toFixed(2);
  }
};

const getAmountSuffix = (tenure: FinancialTenureData) => {
  switch (tenure.tenureType) {
    case IterationType.MONTHLY:
      return 'Month';
    case IterationType.ANNUALLY:
      return 'Year';
    case IterationType.QUARTERLY:
      return 'Quater';
  }
};
