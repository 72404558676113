import {
  CampaignRuleType,
  CampaignType,
} from '../../../../../../../../types/RewardsLoyalty';
import VegaTextV2 from '../../../../../../../common/v2/VegaTextV2';
import { useCampaignRule } from '../../../../../../providers/CampaignRuleProvider';
import PointBasedMilestoneRewards from './Milestone/PointBasedMilestoneRewards';
import NonPointBasedMilestoneRewards from './NonPoint/NonPointBasedMilestoneRewards';
import NonPointBasedNonTransactionalRewards from './NonPoint/NonPointBasedNonTransactionalRewards';
import NonPointBasedTransactionalRewards from './NonPoint/NonPointBasedTransactionalRewards';

import PointBasedNonTransactionalRewards from './NonTransactional/PointBasedNonTransactionalRewards';
import PointBasedTransactionRewards from './Transactional/PointBasedTransactionRewards';

function RewardsTabPanel() {
  const { selectedRuleType, campaign } = useCampaignRule();
  const campaignType = campaign?.campaignType;

  if (selectedRuleType == CampaignRuleType.MILESTONE) {
    if (campaignType == CampaignType.POINT_BASED) {
      return <PointBasedMilestoneRewards />;
    } else if (campaignType == CampaignType.NON_POINT_BASED) {
      return <NonPointBasedMilestoneRewards />;
    }
  }

  if (selectedRuleType == CampaignRuleType.TRANSACTIONAL) {
    if (campaignType == CampaignType.POINT_BASED) {
      return <PointBasedTransactionRewards />;
    } else if (campaignType == CampaignType.NON_POINT_BASED) {
      return <NonPointBasedTransactionalRewards />;
    }
  }

  if (selectedRuleType == CampaignRuleType.NON_TRANSACTIONAL) {
    if (campaignType == CampaignType.POINT_BASED) {
      return <PointBasedNonTransactionalRewards />;
    } else if (campaignType == CampaignType.NON_POINT_BASED) {
      return <NonPointBasedNonTransactionalRewards />;
    }
  }

  return <VegaTextV2 text="No Rule type selected" />;
}

export default RewardsTabPanel;
