import axios from 'axios';
import { BASE_URL, CORPORATE, GETWAY, TRANSACTION } from './endpoints';
import { ICreateCorporateAccountProps } from '../../types/CorporateCustomer';

const CORPORATE_CUSTOMER_URL = `${BASE_URL}${GETWAY}${CORPORATE.CUSTOMER}`;
const CORPORATE_ACCOUNT_URL = `${BASE_URL}${GETWAY}${CORPORATE.ACCOUNT}`;
const TRANSACTION_CORPORATE_URL = `${BASE_URL}${GETWAY}${TRANSACTION.CORPORATE}`;

export const createCorporateEntityForMCC = (data: any) => {
  return axios.post(CORPORATE_CUSTOMER_URL, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const fetchCorporate̦AccountByCorporateId = (corporateId: string) => {
  return axios.get(`${CORPORATE_ACCOUNT_URL}/corporateId/${corporateId}`);
};
export const fetchCorporate̦EntityForMCC = (data: any) => {
  return axios.post(CORPORATE_CUSTOMER_URL + '/filter', data);
};
export const fetchAccountListForCorporate = (branchId: any) => {
  return axios.post(CORPORATE_ACCOUNT_URL + '/filter', branchId);
};
export const createCorporateAccount = (data: ICreateCorporateAccountProps) => {
  return axios.post(CORPORATE_ACCOUNT_URL, data);
};

export const fetchCorporateFunloadList = (data: any) => {
  return axios.post(TRANSACTION_CORPORATE_URL + '/fundload/list', data);
};

export const initiateCorporateCheckerMakerForfundLoad = (data: any) => {
  return axios.post(
    TRANSACTION_CORPORATE_URL + '/fundload/initiateCheckerMaker',
    data
  );
};
export const updateCorporateCheckerMakerForfundLoad = (data: any) => {
  return axios.patch(
    TRANSACTION_CORPORATE_URL + '/fundload/updateMakerChecker',
    data
  );
};
