import React from 'react';
import { FilterList } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

function FilterPhysicalCardsOrders() {
  return (
    <>
      <Stack
        direction={'row'}
        className="dataGrid"
        alignItems={'center'}
        justifyContent={'flex-end'}
        spacing={2}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={1}
          sx={{ cursor: 'pointer' }}
        >
          <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
          <VegaTextV2 fontSize={12} color={'#676B76'}>
            Filter
          </VegaTextV2>
        </Stack>
        <GridToolbarQuickFilter
          autoFocus
          variant="outlined"
          sx={{
            bgcolor: 'white',
            color: '#999DA8',
            height: '35px',
            minWidth: '320px',
            borderRadius: '9px',
          }}
        />
      </Stack>
    </>
  );
}

export default FilterPhysicalCardsOrders;
