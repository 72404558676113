import { VegaProgramNetworkType } from './program';

export interface VegaClientInterface {
  clientId: string;
  clientName: string;
  clientUrl: string;
  clientEmail: string;
  clientMob: string;
  agreement: string;
  status: string;
  logo: string;
  crmLogo: string;
  statementAppLogo: string;
  mobileAppLogo: string;
  createdAt: Date;
  updatedAt: Date;
  address?: string | null;
  businessType?: string | null;
  cinNumber?: string | null;
  gstNumber?: string | null;
  panNumber?: string | null;
  role?: string | null;
}

export interface IVegaClientScope {
  scope: IVegaClientScopeGroup;
}
export interface IVegaClientScopeGroup {
  [featureGroup: string]: IVegaClientScopeGroupTypeRule;
}

export interface IVegaClientScopeGroupTypeRule {
  [featureType: string]: IVegaClientScopeProgramRoles;
}
export interface IVegaClientScopeProgramRoles {
  [programId: string]: string[];
}

export interface ILoggedInUser {
  email: string;
  mobileNumber?: string;
  loginTime: Date;
  idToken: string;
  accessToken: string;
  refreshToken: string;
  name?: string;
  user?: any;
}

export enum VegaClientLogoType {
  COMPANY = 'COMPANY',
  STATEMENT_APP = 'STATEMENT_APP',
  CRM = 'CRM',
  MOBILE_APP = 'MOBILE_APP',
}

export interface UpdateClientLogoRequestData {
  logoType: VegaClientLogoType;
  logo: File;
  clientId: string;
}

export const getNameForClientLogoType = (logoType: VegaClientLogoType) => {
  switch (logoType) {
    case VegaClientLogoType.COMPANY:
      return 'Logo';
    case VegaClientLogoType.CRM:
      return 'CRM Logo';
    case VegaClientLogoType.MOBILE_APP:
      return 'Mobile App Logo';
    case VegaClientLogoType.STATEMENT_APP:
      return 'Statement App Logo';
  }
};

export enum VegaBinType {
  SHARED = 'SHARED',
  DEDICATED = 'DEDICATED',
}
export enum VegaBinUsageType {
  CREDTI_CARD = 'CREDTI_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
}

export interface VegaBin {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  binType: VegaBinType;
  binUsageType?: VegaBinUsageType;
  productType?: VegaBinUsageType;
  network: VegaProgramNetworkType;
  cardFormFactorId: string;
  startKitNumber: number;
  endKitNumber: number;
  lastAllocatedKitNumber: number;
  totalKits: number;
  allocatedKits: number;
  unallocatedKits: number;
  onHoldKits: number;
  availableKits: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface VegaNewBin {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  binType: VegaBinType;
  network: VegaProgramNetworkType;
  productType: string;
  numberOfKits: number;
  noOfKitsSold: number;
  noOfKitsDamaged: number;
  noOfKitsAllocated: number;
  noOfKitsAvailable: number;
  cardProcessorConfigId: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface AddBinToProgramRequestData {
  binId: number;
  programId: string;
  binRangeRegex: string;
}

export const DEFAULT_BIN_RANGE_REGEX =
  '(41473800100[0-9]|4147380010[1-9][0-9]|414738001[1-9][0-9]{2})';

export interface ApiScope {
  currrentLevel: Level;

  currentLevelValue: string;
  programIds?: string[];
  levelHierarchy: LevelHierarchy;
}

export enum Level {
  CLIENT = 'CLIENT',
  l1 = 'l1',
  l2 = 'l2',
  l3 = 'l3',
  l4 = 'l4',
  l5 = 'l5',
  l6 = 'l6',
  l7 = 'l7',
  l8 = 'l8',
  l9 = 'l9',
}

export interface LevelHierarchy {
  clientId: string;
  l1?: string;
  l2?: string;
  l3?: string;
  l4?: string;
  l5?: string;
  l6?: string;
  l7?: string;
  l8?: string;
  l9?: string;
}

export enum EntityType {
  CLIENT = 'HQ',
  BC = 'BC',
  BC_AGENT = 'BC_AGENT',
  VENDOR = 'VENDOR',
}

export const defeaultLevelEntityMap = {
  [Level.CLIENT]: EntityType.CLIENT,
  [Level.l1]: EntityType.BC,
  [Level.l2]: EntityType.BC_AGENT,
  [Level.l3]: EntityType.VENDOR,
  [Level.l4]: '',
  [Level.l5]: '',
  [Level.l6]: '',
  [Level.l7]: '',
  [Level.l8]: '',
  [Level.l9]: '',
};

export type LevelEntityMapping = Record<Level, EntityType | string>;

export enum FeatureGroup {
  CLIENT = 'CLIENT',
  PROGRAM = 'PROGRAM',
  PROTOTYPE = 'PROTOTYPE',
  BACK_OFFICE = 'BACK_OFFICE',
  FOREX_HQ = 'FOREX_HQ',
  FOREX_BRANCH = 'FOREX_BRANCH',
  FOREX_CORPORATE = 'FOREX_CORPORATE',
  CIMS_ADMIN = 'CIMS_ADMIN',
  CIMS_BRANCH = 'CIMS_BRANCH',
  CIMS_BC = 'CIMS_BC',
  CIMS_VENDOR = 'CIMS_VENDOR',
}
