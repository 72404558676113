import React from 'react';
import { Typography, Box } from '@mui/material';
import { VegaCard, VegaTable } from '../../../../components/common';

const ListOfServices = ({ data }: any) => {
  const COLUMN = [
    {
      field: 'userId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          User ID
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'programName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Program Name
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'services',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Service Used
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'requestDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Request Date
        </Typography>
      ),
      flex: 1,
    },
  ];
  return (
    <VegaCard fullHeight noPad>
      <VegaTable data={data} columnDef={COLUMN} idColumn={'id'} />
    </VegaCard>
  );
};

export default ListOfServices;
