import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { VegaToolTipAndCopy } from '../../../../components/common/VegaToolTipNCopy';
import {
  CardAllotmentOrderDto,
  CardAllotmentStatusEnum,
  CardIndentationOrderDto,
} from '../../../../types/card';
import CardAllotmentStatus from '../../InventoryManagement/CardAllotment/CardAllotmentStatus';
import ViewCardIndentationOrderDetailsModal from '../Modals/ViewCardIndentationOrderDetailsModal';
import { CardIndentationRenderActions } from './actions';

export enum CardIndentationRequestAction {
  MarkAsShipped,
  ApproveRequest,
  RejectRequest,
  Acknowledge,
  RevokeRequest,
  MarkAsDelivered,
  MarkAsLost,
}

type ColumnsProps = {
  // activeTab: CardOrderRequestTabTypes;
  triggerAction: (
    action: CardIndentationRequestAction,
    data: CardAllotmentOrderDto
  ) => void;
};

type CardOrderRequestColumnsType = GridColDef<CardIndentationOrderDto>[];

// const additionalColumnsForPendingOrders: CardOrderRequestColumnsType = [
//   {
//     field: 'requestedBy',
//     headerName: 'Requested By',
//     flex: 0.4,
//     minWidth: 100,
//     valueGetter: (props) => props.row.sourceUserEmail,
//     renderCell: (props) => (
//       <VegaToolTipAndCopy value={props.value} variant="body1" />
//     ),
//   },
// ];

const getRemarks = (data: CardAllotmentOrderDto) => {
  return data.allotmentStatus === CardAllotmentStatusEnum.REJECTED
    ? data.rejectionReason
    : data.allotmentStatus === CardAllotmentStatusEnum.APPROVED
    ? `Approved Qty (${data.requestedKits})`
    : data.allotmentStatus === CardAllotmentStatusEnum.DISPATCHED
    ? data.mailerVendorId +
      (data.mailerVendorTrackingId ? ` (${data.mailerVendorTrackingId})` : '')
    : '-';
};

export const getCardOrderRequestColumns = ({
  triggerAction,
}: ColumnsProps): CardOrderRequestColumnsType => {
  return [
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => {
        return dayjs(props.row.createdAt).format('DD/MM/YYYY');
      },
      renderCell: (props) => (
        <Typography variant="body1">{props.value}</Typography>
      ),
    },
    {
      field: 'requestId',
      headerName: 'Request ID',
      flex: 1.2,
      minWidth: 120,
      valueGetter: (props) => props.row.id,
      renderCell: (props) => (
        <VegaToolTipAndCopy isCopy value={props.value} variant="body1" />
      ),
    },
    // ...(showPendingOrderColumns ? additionalColumnsForPendingOrders : []),

    {
      field: 'programId',
      headerName: 'Program Name',
      flex: 1.8,
      minWidth: 180,
      valueGetter: (props) => props.row.programDto?.name,
      renderCell: (props) => (
        <Typography variant="body1" sx={{ textOverflow: 'ellipsis' }}>
          {props.value}
        </Typography>
      ),
    },
    {
      field: 'requestedKits',
      headerName: 'Requested Kits',
      flex: 1,
      minWidth: 120,
      valueGetter: (props) => props.row.requestedKits,
      renderCell: (props) => (
        <VegaToolTipAndCopy isCopy value={props.value} variant="body1" />
      ),
    },
    // {
    //   field: 'totalKits',
    //   headerName: 'Assigned Kits',
    //   flex: 0.8,
    //   minWidth: 80,
    //   valueGetter: (props) => props.row.totalKits || '-',
    //   renderCell: (props) => (
    //     <VegaToolTipAndCopy isCopy value={props.value} variant="body1" />
    //   ),
    // },
    {
      field: 'requestedBy',
      headerName: 'Requested By',
      flex: 1.5,
      minWidth: 200,
      valueGetter: (props) => props.row.createdBy ?? '-',
      renderCell: (props) => (
        <VegaToolTipAndCopy isCopy value={props.value} variant="body1" />
      ),
    },
    // {
    //   field: 'destinationUserEmail',
    //   headerName: 'Modified By',
    //   flex: 1.5,
    //   minWidth: 150,
    //   valueGetter(params) {
    //     return params.row.destinationUserEmail || '-';
    //   },
    //   renderCell: (props) => <Typography>{props.value}</Typography>,
    // },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => props.row.indentationStatus,
      renderCell: (props) => <CardAllotmentStatus status={props.value} />,
    },
    // {
    //   field: 'remarks',
    //   headerName: 'Remarks',
    //   flex: 2,
    //   minWidth: 200,
    //   valueGetter: (props) => getRemarks(props.row),
    //   renderCell: (props) => {
    //     return (
    //       <Typography
    //         variant="body1"
    //         sx={{
    //           color: '#5F6D7E',
    //           overflow: 'hidden',
    //           textOverflow: 'ellipsis',
    //           whiteSpace: 'nowrap',
    //         }}
    //       >
    //         {props.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: 'orderDetails',
      headerName: 'Order Details',
      type: 'actions',
      flex: 1.5,
      minWidth: 150,
      renderCell: (props) => {
        const allowedStatus = [
          CardAllotmentStatusEnum.APPROVED,
          CardAllotmentStatusEnum.DISPATCHED,
          CardAllotmentStatusEnum.DELIVERED,
          CardAllotmentStatusEnum.ACCEPTED,
        ];

        return <ViewCardIndentationOrderDetailsModal data={props.row} />;
      },
    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Action',
      flex: 2,
      minWidth: 260,
      renderCell: (props) => (
        <CardIndentationRenderActions
          data={props.row}
          triggerAction={triggerAction}
        />
      ),
    },
  ];
};
