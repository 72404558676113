import { Stack, Box } from '@mui/material';
import Vega_Text from './Vega_Text';

type VegaPillProps = {
  text: string;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
};

export const Vega_Pill = ({
  text,
  backgroundColor = '#EBF4EC',
  borderColor = '#36913F',
  textColor = '#36913F',
}: VegaPillProps) => {
  const hasBackground = backgroundColor !== undefined;
  const hasBorder = borderColor !== undefined;
  const hasText = textColor !== undefined;

  const pillStyles = {
    border: hasBorder ? `1px solid ${borderColor}` : 'none',
    borderRadius: '60px',
    px: 1,
    py: 0.3,
    bgcolor: hasBackground ? backgroundColor : 'transparent',
  };

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={pillStyles}>
      <Vega_Text
        text={text}
        sx={{
          textTransform: 'uppercase',
          fontWeight: 700,
          fontSize: '0.625rem !important',
          letterSpacing: '1.25px !important',
          color: hasText ? `${textColor} !important` : 'inherit',
        }}
      />
    </Stack>
  );
};
