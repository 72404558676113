/* eslint-disable react/jsx-pascal-case */
import { Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { getPresignedUrl } from '../../api/card';
import { toLowerCase } from '../../constants/commonFunction';
import { BLUE, PRIMARY } from '../../constants/style';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import {
  CardBlockingStatus,
  CardRejectOrderDto,
  UpdateBulkCardRejectOrderRequest,
} from '../../types/card';
import { Level } from '../../types/client';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { downloadFileFromURI } from '../../utils/file';
import { CardAllocationService } from '../apis';
import { CIMS_BORDER, CIMS_PRIMARY } from '../assets/style';
import CIMS_BulkUpload from '../components/common/CIMS_BulkUpload';
import CIMS_Button from '../components/common/CIMS_Button';
import CIMS_Header from '../components/common/CIMS_Header';
import CIMS_Modal, { IButtonGroupProps } from '../components/common/CIMS_Modal';
import { ListOfBulkCardReject } from '../modules/BulkCardReject';
import {
  CIMS_SUB_MODULES,
  CIMS_RESOURCE_MODULES,
} from '../../constants/modules';
import { checkResourceAccess } from '../../utils/userAccessUtils';

export const BulkRejectCard = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const [isShowUpload, setIsShowUpload] = useState<boolean>(false);
  const [isUploaded, setIsuploaded] = useState<boolean>(false);
  const { clientId, userDivisionId, user } = useClientAuth();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadResponse, setUploadResponse] =
    useState<CardRejectOrderDto | null>(null);
  const { setSnackbar } = useSnackbar();

  const hasAccessToDownload = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.BULK_REJECT_CARD.DOWNLOAD_TEMPLATE,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.BULK_REJECT_CARD,
      }),
    [user?.allResourceAccess]
  );

  const hasAccessToUpload = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.BULK_REJECT_CARD.UPLOAD_FILE,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.BULK_REJECT_CARD,
      }),
    [user?.allResourceAccess]
  );

  const [refetchList, setRefetchList] = useState<any>(null);

  const hookForm = useForm<{
    binId: string;
    programId?: string;
  }>({});

  const bulkUploadMutation = useMutation({
    mutationFn: (data: UpdateBulkCardRejectOrderRequest) => {
      return CardAllocationService.bulkRejectCard(data);
    },
    onSuccess: (data, variables, context) => {
      setUploadResponse(data);
    },
    onError: (data, variables, context) => {
      setSnackbar(getErrorMessageFromErrorObj(data), 'error');
    },
    onSettled: () => {
      refetchList && refetchList();
    },
  });

  const isUploadDisabled = useMemo(() => {
    return !selectedFile || !hookForm.formState.isValid || loading;
  }, [selectedFile, hookForm.formState.isValid, loading]);

  const toggleUpload = () => {
    setIsShowUpload(!isShowUpload);
    setSelectedFile(null);
    setUploadProgress(0);
  };
  const toggleUploaded = () => {
    setIsuploaded(!isUploaded);
  };

  const onUploadInventory = () => {
    toggleUpload();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (
        fileType === 'text/csv' ||
        fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileSize <= 2) {
          let uploadedSize = 0;
          setLoading(true);
          const interval = setInterval(() => {
            uploadedSize += 10000;
            const progress = (uploadedSize / file.size) * 100;
            setUploadProgress(progress);

            if (uploadedSize >= file.size) {
              clearInterval(interval);
              setLoading(false);
            }
          }, 200);
          setError('');
          setSelectedFile(file || null);
        } else {
          setError('File size exceeds 2MB. Please upload a smaller file.');
        }
      } else {
        setError('Please upload only CSV or XLSX files.');
      }
    }
  };

  function onFileSelectError(error: string | null) {
    setSelectedFile(null);
    setError(error ?? 'Something went wrong');
  }

  const onUploadFile = () => {
    const data = hookForm.getValues();

    selectedFile &&
      bulkUploadMutation.mutate({
        clientId: clientId || '',
        divisionId: userDivisionId || '',
        bulkCardRejectFile: selectedFile,
      });

    toggleUploaded();
  };

  const onRunInBackground = () => {
    clearStateData();
    refetchList && refetchList();
  };

  const clearStateData = () => {
    refetchList && refetchList();
    setSelectedFile(null);
    setIsShowUpload(false);
    setIsuploaded(false);
    uploadResponse && setUploadResponse(null);
    bulkUploadMutation.reset();
    hookForm.reset();
  };

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Upload',
      disabled: isUploadDisabled,

      onSubmit: async () => {
        await onUploadFile();
      },
      icon: '',
      isOutline: false,
    },
  ];

  const downloadButton: IButtonGroupProps[] = [
    {
      text: 'Download report',
      disabled: false,
      onSubmit: async () => {
        if (uploadResponse?.reportFileUrl) {
          const url = await getPresignedUrl(uploadResponse?.reportFileUrl);
          downloadFileFromURI(url.data, uploadResponse.reportFileName);
        }
      },
      icon: '',
      isOutline: true,
    },
  ];
  const runInBackgroundbuttons: IButtonGroupProps[] = [
    {
      text: 'Run in background',
      disabled: false,
      onSubmit: async () => {
        await onRunInBackground();
      },
      icon: '',
      isOutline: false,
    },
  ];
  const failedButtons: IButtonGroupProps[] = [
    {
      text: 'Cancel',
      disabled: false,
      onSubmit: async () => {
        // await toggleUpload();
        clearStateData();
      },
      icon: '',
      isOutline: false,
    },
  ];
  const getButton = () => {
    return isUploaded
      ? bulkUploadMutation.isError
        ? failedButtons
        : uploadResponse?.status === CardBlockingStatus.SUCCESS
        ? downloadButton
        : uploadResponse?.status === CardBlockingStatus.FAILED
        ? failedButtons
        : runInBackgroundbuttons
      : buttons;
  };

  const delayedApiCall = debounce(async () => {
    CardAllocationService.getBulkRejectCard(uploadResponse?.id || '')
      .then((bulkRejectOrder) => {
        if (uploadResponse?.status !== bulkRejectOrder.status) {
          setUploadResponse(bulkRejectOrder);
          if (bulkRejectOrder.status === CardBlockingStatus.FAILED) {
            setSnackbar(
              'Bulk order ' + toLowerCase(bulkRejectOrder.status),
              'error'
            );
          }
        }
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(
            err,
            'Error in fetching the bulk order for id- ' + uploadResponse?.id
          ),
          'error'
        );
        setUploadResponse((pre) =>
          pre
            ? {
                ...pre,
                status: CardBlockingStatus.FAILED,
              }
            : null
        );
      });
  }, 2000);

  useEffect(() => {
    if (uploadResponse?.id) {
      delayedApiCall();
    }
    return delayedApiCall.cancel;
  }, [uploadResponse?.id]);

  return (
    <>
      <Box>
        <CIMS_Header
          text="Bulk Reject Card"
          component={
            <Stack
              direction={'row'}
              justifyContent={'end'}
              alignItems={'center'}
              gap={2}
            >
              {hasAccessToDownload && (
                <a
                  href={require('../assets/files/bulkCardRejectTemplate.csv')}
                  download
                  className="font-aspekta-500"
                  style={{
                    cursor: 'pointer',
                    fontSize: 12,
                    color: CIMS_PRIMARY.BLUE,
                    textDecoration: 'none',
                  }}
                >
                  Download Template
                </a>
              )}
              {hasAccessToUpload && (
                <CIMS_Button text="Upload File" onClick={onUploadInventory} />
              )}
            </Stack>
          }
        />
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box>
            <ListOfBulkCardReject setRefetchList={setRefetchList} />
          </Box>
        </Box>
      </Box>
      <CIMS_Modal
        maxWidth="sm"
        open={isShowUpload}
        handleClose={clearStateData}
        header="Bulk Reject"
        buttonGroup={getButton()}
      >
        <>
          {isUploaded ? (
            bulkUploadMutation.isError ? (
              <Stack sx={{ px: 3, pt: 2, pb: 6 }} spacing={2}>
                <Typography fontSize={14}>
                  {getErrorMessageFromErrorObj(
                    bulkUploadMutation.error,
                    'Error in uploading the file'
                  )}
                </Typography>
              </Stack>
            ) : uploadResponse?.status === CardBlockingStatus.SUCCESS ? (
              <Box sx={{ px: 3, pt: 2, pb: 4 }}>
                <Typography
                  fontSize={14}
                  className="font-aspekta-600"
                  color={'#222222'}
                >
                  Summary
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <Stack
                        direction={'row'}
                        gap={1}
                        sx={{ borderRight: CIMS_BORDER.DARKGRAY }}
                      >
                        <Typography
                          className="font-aspekta-500"
                          fontSize={13}
                          color={CIMS_PRIMARY.GRAY}
                        >
                          Total Entries :
                        </Typography>
                        <Typography
                          className="font-aspekta-500"
                          fontSize={13}
                          color={'#222222'}
                        >
                          {uploadResponse.totalKitEntries}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={4}>
                      <Stack
                        direction={'row'}
                        gap={1}
                        sx={{ borderRight: CIMS_BORDER.DARKGRAY }}
                      >
                        <Typography
                          className="font-aspekta-500"
                          fontSize={13}
                          color={CIMS_PRIMARY.GRAY}
                        >
                          Total Success :
                        </Typography>
                        <Typography
                          className="font-aspekta-500"
                          fontSize={13}
                          color={'#222222'}
                        >
                          {uploadResponse.successRejectCount}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={4}>
                      <Stack direction={'row'} gap={1}>
                        <Typography
                          className="font-aspekta-500"
                          fontSize={13}
                          color={CIMS_PRIMARY.GRAY}
                        >
                          Total Failure :
                        </Typography>
                        <Typography
                          className="font-aspekta-500"
                          fontSize={13}
                          color={'#222222'}
                        >
                          {uploadResponse.failureCount}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : uploadResponse?.status === CardBlockingStatus.FAILED ? (
              <Stack sx={{ px: 3, pt: 2, pb: 6 }} spacing={2}>
                Processing Failed
              </Stack>
            ) : (
              <Box sx={{ px: 3, pt: 2, pb: 4 }}>
                <Box
                  sx={{
                    border: `2px dashed ${BLUE.lighter}`,
                    borderRadius: '8px',
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60px',
                  }}
                >
                  <Stack
                    sx={{}}
                    flex={1}
                    spacing={1}
                    p={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Typography color={PRIMARY.darkBlue}>
                      File is under processing...
                    </Typography>

                    <LinearProgress sx={{ width: '50%' }} />
                  </Stack>
                </Box>
              </Box>
            )
          ) : (
            // {/* ---------------------UPLOAD FILE ------------------------- */}

            <Stack sx={{ px: 3, pt: 2, pb: 6 }} spacing={2}>
              {/* <FormProvider {...hookForm}>
                <SelectBinAndProgram />
              </FormProvider> */}
              <CIMS_BulkUpload
                handleFileChange={handleFileChange}
                selectedFile={selectedFile}
                error={error}
                uploadProgress={uploadProgress}
                loading={loading}
                onFileSelectError={onFileSelectError}
              />
            </Stack>
          )}
        </>
      </CIMS_Modal>
      {/* ---------------------–SUMMARY------------------------- */}

      {/* <CIMS_Modal
        maxWidth="sm"
        open={isShowSummary}
        handleClose={toggleShowSummary}
        header="Bulk Upload"
        buttonGroup={downloadButton}
      >
        <Box sx={{ px: 3, pt: 2, pb: 4 }}>
          <Typography
            fontSize={14}
            className="font-aspekta-600"
            color={'#222222'}
          >
            Summary
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Stack
                  direction={'row'}
                  gap={1}
                  sx={{ borderRight: CIMS_BORDER.DARKGRAY }}
                >
                  <Typography
                    className="font-aspekta-500"
                    fontSize={13}
                    color={CIMS_PRIMARY.GRAY}
                  >
                    Total Entries :
                  </Typography>
                  <Typography
                    className="font-aspekta-500"
                    fontSize={13}
                    color={'#222222'}
                  >
                    654566
                  </Typography>
                </Stack>
              </Grid>
              <Grid item sm={4}>
                <Stack
                  direction={'row'}
                  gap={1}
                  sx={{ borderRight: CIMS_BORDER.DARKGRAY }}
                >
                  <Typography
                    className="font-aspekta-500"
                    fontSize={13}
                    color={CIMS_PRIMARY.GRAY}
                  >
                    Total Success :
                  </Typography>
                  <Typography
                    className="font-aspekta-500"
                    fontSize={13}
                    color={'#222222'}
                  >
                    2345
                  </Typography>
                </Stack>
              </Grid>
              <Grid item sm={4}>
                <Stack direction={'row'} gap={1}>
                  <Typography
                    className="font-aspekta-500"
                    fontSize={13}
                    color={CIMS_PRIMARY.GRAY}
                  >
                    Total Failure :
                  </Typography>
                  <Typography
                    className="font-aspekta-500"
                    fontSize={13}
                    color={'#222222'}
                  >
                    455
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CIMS_Modal> */}
    </>
  );
};
