import { Typography } from '@mui/material';
import React, { SetStateAction } from 'react';
import { GREY } from '../../constants/style';
import VegaCard from './VegaCard';
import VegaHorizontalSelector from './VegaHorizontalSelector';
import VegaText from './VegaText';
import Vega_HeaderWrapper from './v3/Vega_HeaderWrapper';

interface ISelectorProps {
  data: any;
  active: any;
  setActiveState: React.Dispatch<SetStateAction<any>>;
  header: string;
  subHeader: string;
}

const VegaSelector = ({
  data,
  active,
  header,
  subHeader,
  setActiveState,
}: ISelectorProps) => {
  return (
    <VegaCard>
      <Vega_HeaderWrapper text={header} subText={subHeader} />

      <VegaHorizontalSelector
        // hideArrow
        hideScroll
        data={data}
        selected={active}
        onChange={setActiveState}
        ignoreCaseForLodash={['MCC']}
      />
    </VegaCard>
  );
};

export default VegaSelector;
