import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { VPAccordion, VPButton, VPModal } from '../../../ui-components/common';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import VegaHeader from '../../common/VegaHeader';
import {
  ProgramCardBack,
  ProgramCardFront,
} from '../../common/ProgramCardView';
import { VegaProgramWithMetaDataType } from '../../../types/program';
import { CardType } from '../../../providers/CardConfigProvider';
import { CardSizeVariant } from '../../common/ProgramCardView/CardComponents';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { getCardDesignConfiguration, getCardSettings } from '../../../api/card';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  setCardSettings,
  setPhysicalCardConfig,
  setVirtualCardConfig,
} from '../../../store/common/programManagementSlice';
import { convertCamelCase } from '../../../utils/stringUtils';

type CardSettingProps = {
  program: VegaProgramWithMetaDataType;
};

function CardSetting({ program }: CardSettingProps) {
  const [showDownloadModal, setShowDownloadModal] = useState<
    'physical' | 'virtual' | null
  >(null);
  const { setSnackbar } = useSnackbar();

  const {
    selectedProgram,
    cardSettings,
    physicalCardConfig,
    virtualCardConfig,
  } = useAppSelector((state) => state.programManagement);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    setShowDownloadModal(null);
  };

  useEffect(() => {
    if (!selectedProgram) return;

    if (!cardSettings) {
      getCardSettings({ programId: selectedProgram.programId })
        .then((res) => {
          dispatch(setCardSettings(res));
        })
        .catch((err) => {
          const debugMessage = err.response?.data?.apierror?.debugMessage;
          if (
            !debugMessage ||
            debugMessage !== 'No Card Setting Found For ProgramId'
          ) {
            setSnackbar('Failed to fetch card settings', 'error');
          }
        });
    }

    if (!physicalCardConfig) {
      getCardDesignConfiguration({
        programId: selectedProgram.programId,
        cardType: CardType.PHYSICAL,
      })
        .then((res) => {
          if (res) {
            dispatch(setPhysicalCardConfig(res));
          }
        })
        .catch((err) => {
          setSnackbar('Failed to fetch card config', 'error');
        });
    }
    if (!virtualCardConfig) {
      getCardDesignConfiguration({
        programId: selectedProgram.programId,
        cardType: CardType.VIRTUAL,
      })
        .then((res) => {
          if (res) {
            dispatch(setVirtualCardConfig(res));
          }
        })
        .catch((err) => {
          setSnackbar('Failed to fetch card config', 'error');
        });
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: 3,
          pb: 2,
        }}
      >
        <Typography className="font-aspekta-600" fontSize={17}>
          Card Setting
        </Typography>
        <VPAccordion title="Card Details" expandable={false}>
          <VPAccordion.Body>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 5,
              }}
            >
              <DataTile
                title="Card Digit"
                value={`${cardSettings?.cardDigit ?? '-'}`}
              />
              <DataTile
                title="Expiry month"
                value={`${cardSettings?.expiryMonth ?? '-'}`}
              />
              <DataTile
                title="CVV Type"
                value={`${
                  cardSettings?.cvvType
                    ? convertCamelCase(cardSettings.cvvType)
                    : '-'
                }`}
              />
              <DataTile
                title="CVV Digit"
                value={`${cardSettings?.cvvLength ?? '-'}`}
              />
            </Box>
          </VPAccordion.Body>
        </VPAccordion>
        <VPAccordion title="Card Activation Process" expandable={false}>
          <VPAccordion.Body>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 5,
              }}
            >
              <DataTile
                title="Physical Card"
                value={`${
                  cardSettings?.physicalCardActivationProcess
                    ? convertCamelCase(
                        cardSettings?.physicalCardActivationProcess
                      )
                    : '-'
                }`}
              />
              <DataTile
                title="virtual card"
                value={`${
                  cardSettings?.virtualCardActivationProcess
                    ? convertCamelCase(
                        cardSettings?.virtualCardActivationProcess
                      )
                    : '-'
                }`}
              />
            </Box>
          </VPAccordion.Body>
        </VPAccordion>
        <VPAccordion title="Card Design" expandable={false}>
          <VPAccordion.Body>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <VegaHeader text="Physical Card" />
                    <Typography
                      className="font-aspekta-700"
                      sx={{
                        fontSize: 12,
                        color: '#1047DC',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowDownloadModal('physical')}
                    >
                      View
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: {
                        xs: 'column',
                        md: 'row',
                      },
                      justifyContent: {
                        xs: 'center',
                      },
                      alignItems: {
                        xs: 'center',
                        md: 'flex-start',
                      },
                      p: {
                        xs: 1,
                        md: 3,
                      },
                      gap: 1,
                    }}
                  >
                    <ProgramCardFront
                      program={program}
                      cardConfig={physicalCardConfig ?? {}}
                      cardType={CardType.PHYSICAL}
                      variant={CardSizeVariant.SMALL}
                    />
                    <ProgramCardBack
                      cardConfig={physicalCardConfig ?? {}}
                      variant={CardSizeVariant.SMALL}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      flex: 1,
                    }}
                  >
                    <VegaHeader text="Virtual Card" />
                    <Typography
                      className="font-aspekta-700"
                      sx={{
                        fontSize: 12,
                        color: '#1047DC',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowDownloadModal('virtual')}
                    >
                      View
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: {
                        xs: 'column',
                        md: 'row',
                      },
                      justifyContent: {
                        xs: 'center',
                      },
                      alignItems: {
                        xs: 'center',
                        md: 'flex-start',
                      },
                      p: {
                        xs: 1,
                        md: 3,
                      },
                      gap: 1,
                    }}
                  >
                    <ProgramCardFront
                      program={program}
                      cardConfig={virtualCardConfig ?? {}}
                      cardType={CardType.VIRTUAL}
                      variant={CardSizeVariant.SMALL}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </VPAccordion.Body>
        </VPAccordion>
      </Box>
      <VPModal
        maxWidth="lg"
        open={!!showDownloadModal}
        header={
          showDownloadModal === 'physical'
            ? 'View Physical Card'
            : 'View Virtual Card'
        }
        handleClose={handleCloseModal}
        // footer={
        //   <Box>
        //     <VPButton
        //       title="Download"
        //       variant="contained"
        //       startIcon={<ArrowDownwardRoundedIcon />}
        //       sx={{
        //         bgcolor: '#1047DC',
        //       }}
        //     >
        //       Download Design
        //     </VPButton>
        //   </Box>
        // }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            justifyContent: {
              xs: 'center',
            },
            p: {
              xs: 1,
              md: 5,
            },
            gap: 1,
          }}
        >
          <ProgramCardFront
            program={program}
            cardConfig={
              showDownloadModal === 'physical'
                ? physicalCardConfig ?? {}
                : virtualCardConfig ?? {}
            }
            cardType={
              showDownloadModal === 'physical'
                ? CardType.PHYSICAL
                : CardType.VIRTUAL
            }
            variant={CardSizeVariant.NORMAL}
          />
          {showDownloadModal === 'physical' && (
            <ProgramCardBack
              cardConfig={physicalCardConfig ?? {}}
              variant={CardSizeVariant.NORMAL}
            />
          )}
        </Box>
      </VPModal>
    </>
  );
}

export default CardSetting;
