/* eslint-disable react/jsx-pascal-case */
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { addDiscardReason } from '../../../../api/card';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { AddCardDiscardReasonRequest } from '../../../../types/card';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import CIMS_Modal from '../../../components/common/CIMS_Modal';
import CIMS_TextField from '../../../components/common/CIMS_TextField';
import CimsButton from '../../../components/common/CimsButton';

interface IProps {
  handleClose: () => void;
  open: boolean;
  refetchList: any;
}

export function AddRejectionStatusModal({
  open,
  handleClose,
  refetchList,
}: IProps) {
  const { setSnackbar } = useSnackbar();
  const { clientId } = useClientAuth();

  const hookForm = useForm<AddCardDiscardReasonRequest>({});

  const onSubmit = () => {
    const data = hookForm.getValues();

    if (clientId) {
      addDiscardReason({
        ...data,
        clientId: clientId,
      })
        .then((res) => {
          setSnackbar('New discard Reason added');
          refetchList && refetchList();
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(
              err,
              'Error while adding new discard reason'
            ),
            'error'
          );
        })
        .finally(() => {
          handleClose();
          hookForm.reset();
        });
    } else {
      setSnackbar('Data not valid', 'error');
    }
  };

  return (
    <CIMS_Modal
      maxWidth="sm"
      open={open}
      handleClose={handleClose}
      header="Add Rejection Status"
      footer={
        <CimsButton disabled={!hookForm.formState.isValid} onClick={onSubmit}>
          Submit
        </CimsButton>
      }
    >
      <Box sx={{ px: 3, pt: 2, pb: 6 }}>
        <Box my={2}>
          <Controller
            name="discardReasonCode"
            control={hookForm.control}
            rules={{
              required: 'Required',
            }}
            render={({ field }) => (
              <CIMS_TextField
                header="Rejection code"
                {...field}
                placeholder="Add Rejection Code"
              />
            )}
          />
        </Box>
        <Box my={2}>
          <Controller
            name="discardReasonDisplayText"
            control={hookForm.control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <CIMS_TextField
                header="Rejection reason"
                {...field}
                placeholder="Add Discard Reason"
              />
            )}
          />
        </Box>
        <Box my={2}>
          <Controller
            name="discardReasonDescription"
            control={hookForm.control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <CIMS_TextField
                header="remark "
                {...field}
                placeholder="Add Description"
              />
            )}
          />
        </Box>
      </Box>
    </CIMS_Modal>
  );
}
