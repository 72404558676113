import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DotIcon } from '../../ui-components/icon/VPIcons';

const ProgramStatus = ({ status }: { status: string }) => {
  const statusText = status === 'ACTIVE' ? 'Live' : status;
  const statusColor = status === 'ACTIVE' ? '#36913F' : '#C9A907';
  const bgColor = status === 'ACTIVE' ? '#EBF4EC' : '#FCF8E6';

  return (
    <Box
      sx={{
        display: 'flex',
        paddingX: 1.25,
        paddingY: 0.5,
        gap: 0.5,
        border: `1px solid ${statusColor}`,
        borderRadius: '60px',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: bgColor,
      }}
    >
      <DotIcon color={statusColor} />
      <Typography
        className="font-aspekta-700 "
        sx={{ fontSize: 10, textTransform: 'uppercase', color: statusColor }}
      >
        {statusText}
      </Typography>
    </Box>
  );
};

export default ProgramStatus;
