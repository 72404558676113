import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import {
  createWebhook,
  fetchWebhooksForProgram,
  unsubscribeWebhook,
} from '../api/webhook';
import { VegaPage } from '../components/common';
import VegaSearchBy from '../components/common/VegaSearchBy';
import { AddWebhookEventDetailsDialog } from '../components/Event/AddWebhookEventDetailsDialog';
import ListOfEvent from '../components/Event/ListOfEvent';
import { useProgramData } from '../providers/ProgramDataProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { VegaWebHook, VegaWebhookStatus } from '../types/webhook';

enum SearchByType {
  EVENT = 'event',
  DESCRIPTION = 'description',
  URL = 'url',
}

const COLUMNS = [
  {
    text: 'Event name',
    value: SearchByType.EVENT,
  },
  {
    text: 'Description',
    value: SearchByType.DESCRIPTION,
  },
  {
    text: 'URL',
    value: SearchByType.URL,
  },
];

const DEFAULT_FILTER = {
  search: '',
  searchBy: COLUMNS[0].value,
};

const EventManual = () => {
  const [filter, setFilter] = useState<any>({ ...DEFAULT_FILTER });
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedProgram } = useProgramData();
  const [webhooks, setWebhooks] = useState<VegaWebHook[]>([]);
  const [selectedWebhook, setSelectedWebhook] = useState<VegaWebHook | null>(
    null
  );
  const [showAddSecretDialog, setShowAddSecretDialog] =
    useState<boolean>(false);
  const onChange = (name: string, value: string) => {
    setFilter((preVal: any) => ({
      ...preVal,
      [name]: value,
    }));
  };

  function updateWebhookInList(webhook: VegaWebHook) {
    const updatedData = webhooks.map((item) => {
      if (item.event == webhook.event) return webhook;
      return item;
    });
    setWebhooks(updatedData);
  }

  function fetchWebhooks(programId: string) {
    setLoading(true);
    fetchWebhooksForProgram({ programId: programId })
      .then((response) => setWebhooks(response))
      .catch((error) => {
        setSnackbar('Failed to get webhooks', 'error');
      })
      .finally(() => setLoading(false));
  }

  function createWebhookEvent(webhook: VegaWebHook) {
    const url = webhook.url;
    const secret = webhook.secret;
    if (!url || !secret) {
      setSnackbar('Invalid input for webhook', 'error');
      return;
    }
    setLoading(true);
    createWebhook({
      programId: webhook.programId,
      url: url,
      event: webhook.event,
      secret: secret,
    })
      .then((res) => {
        updateWebhookInList(res);
        setSnackbar('Subscribed to event', 'success');
      })
      .catch((error) => {
        setSnackbar('Failed to subscribe to event', 'error');
      })
      .finally(() => setLoading(false));
  }

  function unSubscribeWebhookEvent(webhookId: string) {
    setLoading(true);
    unsubscribeWebhook(webhookId)
      .then((updatedWebhook) => {
        setSnackbar('Unsubscribed from event', 'success');
        const updatedData = webhooks.map((item) => {
          if (item.id == updatedWebhook.id) {
            return updatedWebhook;
          }
          return item;
        });
        setWebhooks(updatedData);
      })
      .catch((err) => {
        setSnackbar('Failed to Unsbscribe from event', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function openAddDetailsDialogForWebhook(webhook: VegaWebHook) {
    setSelectedWebhook(webhook);
    setShowAddSecretDialog(true);
  }

  function closeAddSecretDialog() {
    setShowAddSecretDialog(false);
    setSelectedWebhook(null);
  }

  function filteredData(): VegaWebHook[] {
    const searchQuery: string = filter.search.toLowerCase();
    if (searchQuery.length <= 0) {
      return webhooks;
    }
    const searchBy = filter.searchBy;
    const filtered = webhooks.filter((item) => {
      switch (searchBy) {
        case SearchByType.URL:
          return item.url?.toLowerCase().includes(searchQuery);
        case SearchByType.EVENT:
          return item.event?.toLowerCase().includes(searchQuery);
        case SearchByType.DESCRIPTION:
          return item.description?.toLowerCase().includes(searchQuery);
      }
      return false;
    });

    return filtered;
  }

  useEffect(() => {
    const programId = selectedProgram?.programId;
    if (!programId) return;
    fetchWebhooks(programId);
  }, [selectedProgram]);

  return (
    <Box>
      <VegaSearchBy
        placeholder="Serach"
        data={COLUMNS}
        searchByname="searchBy"
        searchName="search"
        key="search"
        state={filter}
        handleChange={onChange}
      />
      <VegaPage>
        <ListOfEvent
          data={filteredData()}
          onSubscribeClick={openAddDetailsDialogForWebhook}
          loading={loading}
          onUnsubscribeClick={(webhookId: string) => {
            unSubscribeWebhookEvent(webhookId);
          }}
        />

        <AddWebhookEventDetailsDialog
          open={showAddSecretDialog}
          onClose={closeAddSecretDialog}
          webhookEvent={selectedWebhook ?? null}
          onSubmit={createWebhookEvent}
        />
      </VegaPage>
    </Box>
  );
};

export default EventManual;
