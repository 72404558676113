import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { GREEN, PRIMARY, RED, YELLOW } from '../../constants/style';
import { Box } from '@mui/system';
import { BLUE } from '../../constants/style';

interface IButtonProps {
  bgColor?: string;
  mx?: string;
  px?: number;
  text: string;
  isPrimary?: boolean;
  variant: 'contained' | 'text' | 'outlined';
  isDanger?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isLeftBorder?: boolean;
  isRightBorder?: boolean;
  startIcon?: JSX.Element;
  onClick?: (e: any) => void;
}

const VegaButton = ({
  bgColor,
  text,
  mx,
  px,
  startIcon,
  isDanger,
  variant,
  isPrimary,
  isSuccess,
  isWarning,
  loading,
  disabled,
  isRightBorder,
  isLeftBorder,
  onClick,
}: IButtonProps) => {
  return (
    <div>
      <LoadingButton
        variant={variant}
        
        loading={loading}
        loadingPosition="start"
        disabled={disabled}
        // loadingIndicator="Loading..."
        startIcon={startIcon ?? ''}
        sx={{
          textTransform: 'capitalize',
          mx: mx ?? 1,
          px: px ?? 2,
          py: 0.5,
          borderRadius: '5px',
          ...(isLeftBorder && {
            borderTopLeftRadius: '5px !important',
            borderBottomLeftRadius: '5px !important',
            borderTopRightRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
          }),
          ...(isRightBorder && {
            borderTopLeftRadius: '0px !important',
            borderBottomLeftRadius: '0px !important',
            borderTopRightRadius: '5px !important',
            borderBottomRightRadius: '5px !important',
          }),
          ...( isPrimary && variant == 'text' ? 
          { 
            color: PRIMARY.light,
            '&.Mui-disabled': {
              color: PRIMARY.light,
              bgcolor: PRIMARY.lighter,
            },
          }
            :
            isPrimary
            ? {
                bgcolor: PRIMARY.light,
                ':hover': { bgcolor: PRIMARY.light },
                '&.Mui-disabled': {
                  color: PRIMARY.light,
                  bgcolor: PRIMARY.lighter,
                },
              }
            : isDanger
            ? {
                bgcolor: RED.dark,
                ':hover': { bgcolor: RED.dark },
                '&.Mui-disabled': {
                  color: RED.dark,
                  bgcolor: RED.light,
                },
              }
            : isWarning
            ? {
                bgcolor: YELLOW.main,
                boxShadow: `0px 3px 1px -2px rgb(255 200 0 / 20%), 0px 2px 2px 0px rgb(253 222 2 / 14%), 0px 1px 5px 0px rgb(235 197 12 / 12%);`,
                ':hover': { bgcolor: YELLOW.main },
                '&.Mui-disabled': {
                  color: YELLOW.main,
                  bgcolor: YELLOW.light,
                },
              }
            : isSuccess
            ? {
                bgcolor: GREEN.dark,
                ':hover': { bgcolor: GREEN.dark },
                '&.Mui-disabled': {
                  color: GREEN.dark,
                  bgcolor: GREEN.light,
                },
              }
            : {
                bgcolor: '#B6B6B6',
                ':hover': { bgcolor: '#B6B6B6' },
                '&.Mui-disabled': {
                  color: 'black',
                  bgcolor: '#8080807d',
                },
              }),
        }}
        onClick={onClick}
      >
        <Box
          component={'span'}
          sx={{ ...(loading && { ml: 2 }), lineHeight: 1.5 }}
        >
          {text}
        </Box>
      </LoadingButton>
    </div>
  );
};

export default VegaButton;
