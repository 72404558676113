import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import VegaSelect from '../components/common/VegaSelect';
import ReportsBody from '../components/Reporting/reports/ReportsBody';
import ScheduleHeaders from '../components/Reporting/schedules/ScheduleHeaders';
import { useProgramData } from '../providers/ProgramDataProvider';
import { CatalogType } from '../providers/ReportsProvider';

const ReportsTypeOptions = Object.keys(CatalogType).map((key) => ({
  id: key,
  name: CatalogType[key as keyof typeof CatalogType].title,
}));

function Reports() {
  const { selectedProgram } = useProgramData();

  const [selectedCatalog, setSelectedCatalog] = useState('report_catalog');

  useEffect(() => {
    setSelectedCatalog('report_catalog');
  }, [selectedProgram]);

  return (
    <>
      <Box my={2}>
        <VegaSelect
          options={ReportsTypeOptions}
          onChange={(value) => setSelectedCatalog(value)}
          selected={selectedCatalog}
          textFieldWidth={200}
          size="small"
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '9px',
            color: 'primary',
          }}
        />
      </Box>
      {selectedCatalog === 'report_catalog' ? (
        <ReportsBody />
      ) : (
        <ScheduleHeaders />
      )}
    </>
  );
}

export default Reports;
