import React, { useState } from 'react';
import {
  Box,
  Stack,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BORDER_GRAY, COMMON, PRIMARY } from '../../../constants/style';
import VegaTextButton from '../../common/VegaTextButton';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import VegaDialogV2 from '../../common/v2/VegaDialogV2';
import VegaContainedButton from '../../common/VegaContainedButton';
import OrderPrintedCard from './OrderPrintedCard';
import { SuccessIcon } from '../../icon/Icons';

const BinCardsDetails = () => {
  const [isOrderPrintCards, setIsOrderPrintCards] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const toggleOrderPrintCardsModal = () => {
    setIsOrderPrintCards(!isOrderPrintCards);
  };
  const openPrintCardModal = () => {
    setIsOrderPrintCards(!isOrderPrintCards);
  };
  const onOrderPrintCardsRange = () => {
    setIsOrderPrintCards(!isOrderPrintCards);
    toggleSuccessModal();
  };

  const toggleSuccessModal = () => {
    setIsSuccess(!isSuccess);
  };
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          borderTop: `1px solid #EEEEEE`,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        <Stack direction={'row'}>
          <Box sx={{ width: '100px' }}></Box>

          <Grid container>
            <Grid item xs={4} sm={4}>
              <Box
                sx={{
                  borderRight: `1px solid #EEEEEE`,
                  px: '15px',
                  py: 2,
                }}
              >
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={COMMON.darkGray}
                >
                  Total Cards Issued
                </Typography>
                <Stack direction={'row'} mt={1}>
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Typography
                      className="font-aspekta-500"
                      fontSize={12}
                      color={COMMON.gray}
                    >
                      Virtual card{' '}
                    </Typography>
                    <Typography
                      className="font-aspekta-400"
                      fontSize={12}
                      color={COMMON.darkGray}
                    >
                      2500
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Typography
                      className="font-aspekta-500"
                      fontSize={12}
                      color={COMMON.gray}
                    >
                      Physical card{' '}
                    </Typography>
                    <Typography
                      className="font-aspekta-400"
                      fontSize={12}
                      color={COMMON.darkGray}
                    >
                      2500
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={8} sm={8}>
              <Box
                sx={{
                  borderRight: `1px solid #EEEEEE`,
                  px: '15px',
                  py: 2,
                }}
              >
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={COMMON.darkGray}
                >
                  Physical cards inventory
                </Typography>
                <Grid container>
                  <Grid item xs={7.6} sm={7.6}>
                    <Stack direction={'row'} gap={5} mt={1}>
                      <Box>
                        <Typography
                          className="font-aspekta-500"
                          fontSize={12}
                          color={COMMON.gray}
                        >
                          Printed card TD
                        </Typography>
                        <Typography
                          className="font-aspekta-400"
                          fontSize={12}
                          color={COMMON.darkGray}
                        >
                          2500
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className="font-aspekta-500"
                          fontSize={12}
                          color={COMMON.gray}
                        >
                          Card issued
                        </Typography>
                        <Typography
                          className="font-aspekta-400"
                          fontSize={12}
                          color={COMMON.darkGray}
                        >
                          2500
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className="font-aspekta-500"
                          fontSize={12}
                          color={COMMON.gray}
                        >
                          Card in stock
                        </Typography>
                        <Typography
                          className="font-aspekta-400"
                          fontSize={12}
                          color={COMMON.darkGray}
                        >
                          2500
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={4.4} sm={4.4}>
                    <Box ml={0.5}>
                      <VegaTextButton
                        text="Order Printed Cards"
                        color={PRIMARY.darkBlue}
                        onClick={openPrintCardModal}
                        startIcon={
                          <AddCircleOutlineOutlined
                            sx={{
                              color: PRIMARY.darkBlue,
                              fontSize: 16,
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <VegaDialogV2
        title="Print Cards"
        maxWidth="sm"
        fullWidth
        open={isOrderPrintCards}
        handleClose={toggleOrderPrintCardsModal}
        buttonComponent={() => {
          return (
            <>
              <Stack
                direction={'row'}
                gap={1}
                justifyContent="end"
                alignItems={'center'}
                mb={2}
              >
                <VegaContainedButton
                  isPurple
                  text="Order Cards"
                  startIcon={<AddCircleOutlineOutlined />}
                  // loading={loading}
                  onClick={onOrderPrintCardsRange}
                  py={1}
                  // disabled={disabled == false}
                />
              </Stack>
            </>
          );
        }}
      >
        <OrderPrintedCard />
      </VegaDialogV2>
      <VegaDialogV2
        title=""
        maxWidth="xs"
        fullWidth
        open={isSuccess}
        handleClose={toggleSuccessModal}
        buttonComponent={() => {
          return <></>;
        }}
      >
        <Box textAlign={'center'}>
          <SuccessIcon />
          <Typography
            className="font-aspekta-600"
            fontSize={20}
            color={COMMON.darkGray}
          >
            Your card order is placed
          </Typography>
          <Typography
            className="font-aspekta-600"
            fontSize={8}
            color={'#7B8694'}
            mt={1}
          >
            It will take 5 days to get ready
          </Typography>
        </Box>
      </VegaDialogV2>
    </>
  );
};

export default BinCardsDetails;
