import {
  DialogProps as MuiDialogProps,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  ButtonProps,
  Box,
} from '@mui/material';
import Button from './Button';
import { PageHeadingTypo } from '../common/Typography';
import CloseIcon from '@mui/icons-material/Close';

interface IDialogProps extends MuiDialogProps {
  actionBtnPrimaryText?: string | React.ReactNode;
  actionBtnPrimaryProps?: ButtonProps;
  actionBtnSecondaryText?: string | React.ReactNode;
  actionBtnSecondaryProps?: ButtonProps;
  closeBtnRequired?: boolean;
}

const Dialog = (props: IDialogProps) => {
  const {
    open,
    onClose,
    title,
    children,
    actionBtnPrimaryText,
    actionBtnSecondaryText,
    actionBtnPrimaryProps,
    actionBtnSecondaryProps,
    closeBtnRequired,
    sx,
    ...rest
  } = props;

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      sx={{
        '.MuiDialog-container': {
          background: 'rgba(19, 20, 50, 0.50)',
          backdropFilter: 'blur(12.5px)',
        },
        '.MuiPaper-root': {
          borderRadius: '8px',
          maxWidth: 'none',
          minWidth: '630px',
        },
        '.MuiDialogTitle-root .MuiTypography-root': {
          padding: '24px 40px',
        },
        '.MuiDialogTitle-root': {
          background: '#E5F7FC',
          padding: 0,
        },
        zIndex: 20000,
        ...sx,
      }}
      {...rest}
    >
      {title && (
        <MuiDialogTitle sx={{ position: 'relative' }}>
          <PageHeadingTypo>{title}</PageHeadingTypo>

          {closeBtnRequired && (
            <Box
              sx={{ position: 'absolute', top: '16px', right: '16px' }}
              onClick={() => onClose?.({}, 'backdropClick')}
              className="cursor-pointer"
            >
              <CloseIcon />
            </Box>
          )}
        </MuiDialogTitle>
      )}
      <MuiDialogContent sx={{ padding: 0 }}>{children}</MuiDialogContent>

      {(Boolean(actionBtnSecondaryText) || Boolean(actionBtnSecondaryText)) && (
        <MuiDialogActions
          sx={{ padding: '24px 44px', borderTop: '1px solid #E1E4EB' }}
        >
          {Boolean(actionBtnPrimaryText) && (
            <Button {...actionBtnPrimaryProps}>{actionBtnPrimaryText}</Button>
          )}
          {Boolean(actionBtnSecondaryText) && (
            <Button variant="contained" {...actionBtnSecondaryProps}>
              {actionBtnSecondaryText}
            </Button>
          )}
        </MuiDialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
