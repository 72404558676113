import { Stack } from '@mui/material';
import { BillingPolicyInterface } from '../../../types/policy';
import { PolicySummaryTable } from './common/SummaryTable';

interface Props {
  policy?: BillingPolicyInterface | null;
}

function BillingPolicySummary({ policy }: Props) {
  return (
    <Stack>
      <PolicySummaryTable
        headers={[]}
        rows={[
          {
            rowData: ['Billing cycle Type', policy?.billing_cycle_type ?? '-'],
          },
          {
            rowData: [
              'Cycle End Day',
              `${policy?.cycle_end_date?.toString() ?? '-'} day of the month`,
            ],
          },
          {
            rowData: [
              'Bill Generation',
              `${
                policy?.days_between_end_date_and_bill_date?.toString() ?? '-'
              } day(s) after cycle end date`,
            ],
          },
          {
            rowData: [
              'Due date',
              `${policy?.due_date?.toString() ?? '-'} of every month`,
            ],
          },
        ]}
      />
    </Stack>
  );
}

export default BillingPolicySummary;
