import React from 'react';
import ReactApexChart from 'react-apexcharts';

type IProps = {
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  options?: ApexCharts.ApexOptions | undefined;
};

function ColumnCharts({ series, options }: IProps) {
  return (
    <ReactApexChart
      options={{
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            // endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
          ],
        },
        yaxis: {
          title: {
            text: '$ (thousands)',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '$ ' + val + ' thousands';
            },
          },
        },
      }}
      series={series}
      type="bar"
      height={350}
    />
  );
}

export default ColumnCharts;
