import { Box, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../services/ChargeBackService';
import { CreateNetworkChargebackRequest } from '../../../types/chargeBack';
import { VPTextField } from '../../../ui-components/common';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useChargeBack } from '../hooks';
import { GenericModal } from './GenericModal';

interface IProps {
  onSubmit?: any;
}

export function CreateChargebackWithNetworkModal({}: IProps) {
  const {
    selectedChargeBack,
    setSelectedChargeBack,
    listChargeBackForProgram,
  } = useChargeBack();

  const hookForm = useForm<CreateNetworkChargebackRequest>({
    defaultValues: {
      chargebackId: selectedChargeBack?.id,
    },
  });
  const { setSnackbar } = useSnackbar();

  const onSubmit = () => {
    const data = hookForm.getValues();
    ChargebackService.createNetworkChargeback(data)
      .then((res) => {
        setSnackbar('Successfully created', 'success');
        setSelectedChargeBack(res);
        listChargeBackForProgram.refetch();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Error while creating with network'),
          'error'
        );
      });
  };
  return (
    <>
      <GenericModal
        triggerLabel="Open with Network"
        header="Create Chargeback with Network"
        onSubmit={onSubmit}
        hasConfirmCTA
        confirmText="Submit Request"
        confirmCTAProps={{
          disabled: !hookForm.formState.isValid,
        }}
        hasCancelCTA={false}
        triggerProps={{
          variant: 'contained',
        }}
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <Stack spacing={2}>
            <Controller
              name="networkClaimId"
              control={hookForm.control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <VPTextField
                  header="Claim id / chargeback id"
                  variant="outlined"
                  {...field}
                  placeholder="Enter Claim ID / Chargeback ID"
                />
              )}
            />
            <Controller
              name="remarks"
              control={hookForm.control}
              render={({ field }) => (
                <VPTextField
                  header="remark (optional)"
                  variant="outlined"
                  {...field}
                  placeholder="Add Remark"
                />
              )}
            />
          </Stack>
        </Box>
      </GenericModal>
    </>
  );
}
