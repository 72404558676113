import { Chip, Divider, Skeleton, Stack } from '@mui/material';
import React, { useState } from 'react';
import {
  ActionMediumTypo,
  ComponentBody1Typo,
  ComponentBody2Typo,
  ListGroupHeadingTypo,
  SectionHeadingTypo,
} from '../../common/Typography';
import CallIcon from '../../icon/CallIcon';
import MessageIcon from '../../icon/MessageIcon';
import EmailIcon from '../../icon/EmailIcon';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Dialog from '../../primitives/Dialog';
import { useAppSelector } from '../../../store/hook';
import {
  AccountClosureChecklist,
  AccountClosureStatus,
} from '../../../enums/accountClosureEnums';

interface IAccountClosePreReqChecksProps {
  isIssuePresent?: boolean;
}

const AccountClosePreReqChecks = ({
  isIssuePresent,
}: IAccountClosePreReqChecksProps) => {
  const { status } = useAppSelector(({ accountClosure }) => ({
    status: accountClosure.accountClosureRequestDetails.payload?.status,
  }));

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <SectionHeadingTypo>Prerequisite Checks</SectionHeadingTypo>
            {status === AccountClosureStatus.PENDING && isIssuePresent && (
              <Chip
                label="Pending"
                component="a"
                href="#basic-chip"
                sx={{
                  '.MuiChip-label': {
                    fontSize: '10px',
                    letterSpacing: '0.7px',
                    textTransform: 'uppercase',
                    fontWeight: 700,
                  },
                  width: 'fit-content',
                  color: '#9D8406',
                  backgroundColor: '#FCF8E6',
                  height: '20px',
                }}
              />
            )}
          </Stack>
          <CustomerContactInfo />
        </Stack>
        <PreReqChecks />
      </Stack>
    </Stack>
  );
};

const PreReqChecks = () => {
  const [openSend2AccDialog, setOpenSend2AccDialog] = useState(false);
  const { preChecks, status, isLoading } = useAppSelector(
    ({ accountClosure }) => ({
      preChecks:
        accountClosure.accountClosureRequestDetails.payload
          ?.preRequisiteCheckList,
      status: accountClosure.accountClosureRequestDetails.payload?.status,
      isLoading:
        accountClosure.accountClosureRequestDetails.apiStatus?.isLoading,
    })
  );

  const setSend2AccDialogView = (open: boolean) => () => {
    // setOpenSend2AccDialog(open);
  };

  return (
    <Stack gap={1.5}>
      {(Object.keys(preChecksDesc) as AccountClosureChecklist[])?.map(
        (checkKey, idx) => {
          const check = preChecks?.find(
            (el) => el.preRequisiteCheckEnum === checkKey
          );
          const checkDetails = preChecksDesc[checkKey];
          console.log('checks', check);

          return (
            <Stack key={idx} gap={1.5}>
              <Stack gap={1} direction="row">
                {isLoading || !preChecks ? (
                  <ErrorOutlineOutlinedIcon
                    sx={{ fontSize: '16px', color: '#9D8406' }}
                  />
                ) : check ? (
                  checkDetails.pendingIcon
                ) : (
                  <DoneIcon sx={{ fontSize: '16px', color: '#318439' }} />
                )}
                {isLoading ? (
                  <Skeleton
                    variant="rectangular"
                    width="40%"
                    height="15px"
                    sx={{ borderRadius: '5px' }}
                  />
                ) : (
                  <ComponentBody1Typo>{checkDetails.desc}</ComponentBody1Typo>
                )}
              </Stack>
              {status !== AccountClosureStatus.APPROVED &&
                (check?.flags?.length || 0) > 0 &&
                Boolean(check) &&
                !isLoading && (
                  <Stack
                    sx={{
                      minWidth: '600px',
                      width: 'fit-content',
                      backgroundColor: '#FAFAFA',
                      border: '1px solid #EEE',
                      borderRadius: '8px',
                      padding: '12px 24px',
                    }}
                    direction="row"
                    alignItems="center"
                    gap={1.5}
                  >
                    {check?.flags?.map((issue, idx) => (
                      <React.Fragment key={idx}>
                        {idx !== 0 && (
                          <Divider
                            orientation="vertical"
                            sx={{ backgroundColor: '#E1E4EB', height: '12px' }}
                          />
                        )}
                        <Stack direction="row" gap={1} alignItems="center">
                          <ListGroupHeadingTypo sx={{ color: '#7B8694' }}>
                            {issue.item}
                          </ListGroupHeadingTypo>
                          {Boolean(issue.value) && (
                            <ComponentBody2Typo>
                              {issue.value}
                            </ComponentBody2Typo>
                          )}
                          {Boolean(
                            [
                              AccountClosureChecklist.ACCOUNT_BALANCE,
                              AccountClosureChecklist.DISPUTE_TRANSACTION,
                            ].includes(check.preRequisiteCheckEnum)
                          ) && (
                            <>
                              <Divider
                                orientation="vertical"
                                sx={{
                                  backgroundColor: '#E1E4EB',
                                  height: '12px',
                                }}
                              />
                              <ActionMediumTypo
                                onClick={setSend2AccDialogView(true)}
                                sx={{ color: '#1047DC' }}
                                className="cursor-pointer"
                              >
                                {check.preRequisiteCheckEnum ===
                                AccountClosureChecklist.ACCOUNT_BALANCE
                                  ? 'Send to account'
                                  : 'Check all disputes'}
                              </ActionMediumTypo>
                            </>
                          )}
                        </Stack>
                      </React.Fragment>
                    ))}
                  </Stack>
                )}
            </Stack>
          );
        }
      )}
      <Dialog
        open={openSend2AccDialog}
        title="Send to Account"
        onClose={setSend2AccDialogView(false)}
        actionBtnSecondaryText={'Approve'}
        actionBtnPrimaryText={'Reject'}
        closeBtnRequired
        actionBtnSecondaryProps={{
          onClick: setSend2AccDialogView(false),
        }}
        actionBtnPrimaryProps={{
          onClick: setSend2AccDialogView(false),
        }}
      >
        <SectionHeadingTypo padding={'20px 40px'}>
          Do you want to approve request for sending ₹
          {preChecks?.[1]?.flags?.[0]?.value} to the user's account?
        </SectionHeadingTypo>
      </Dialog>
    </Stack>
  );
};

const CustomerContactInfo = () => {
  return (
    <Stack gap={1} alignItems="flex-end">
      <ListGroupHeadingTypo sx={{ color: '#7B8694' }}>
        contact customer
      </ListGroupHeadingTypo>
      <Stack direction="row" gap={1.5} alignItems="center">
        <Stack direction="row" gap={0.5} className="cursor-pointer">
          <CallIcon />
          <ActionMediumTypo sx={{ color: '#1047DC' }}>Call</ActionMediumTypo>
        </Stack>
        <Divider
          orientation="vertical"
          sx={{ color: '#E1E4EB', height: '12px' }}
        />
        <Stack direction="row" gap={0.5} className="cursor-pointer">
          <MessageIcon />
          <ActionMediumTypo sx={{ color: '#1047DC' }}>Message</ActionMediumTypo>
        </Stack>
        <Divider
          orientation="vertical"
          sx={{ color: '#E1E4EB', height: '12px' }}
        />
        <Stack direction="row" gap={0.5} className="cursor-pointer">
          <EmailIcon />
          <ActionMediumTypo sx={{ color: '#1047DC' }}>Email</ActionMediumTypo>
        </Stack>
      </Stack>
    </Stack>
  );
};

const preChecksDesc = {
  [AccountClosureChecklist.NO_DUES]: {
    desc: 'No Dues should be pending.',
    pendingIcon: <CloseIcon sx={{ fontSize: '16px', color: '#BA1C1C' }} />,
  },
  [AccountClosureChecklist.ACCOUNT_BALANCE]: {
    desc: 'Account balance should be less than or equal to zero.',
    pendingIcon: <CloseIcon sx={{ fontSize: '16px', color: '#BA1C1C' }} />,
  },
  [AccountClosureChecklist.DISPUTE_TRANSACTION]: {
    desc: 'No dispute transaction should be pending.',
    pendingIcon: <CloseIcon sx={{ fontSize: '16px', color: '#BA1C1C' }} />,
  },
  [AccountClosureChecklist.AUTOMATIC_BILL_PAYMENT]: {
    desc: 'Automatic bill payment & transfer should be stopped.',
    pendingIcon: <CloseIcon sx={{ fontSize: '16px', color: '#BA1C1C' }} />,
  },
  [AccountClosureChecklist.PENDING_REWARDS]: {
    desc: 'Pending reward points should be settled.',
    pendingIcon: (
      <ErrorOutlineOutlinedIcon sx={{ fontSize: '16px', color: '#9D8406' }} />
    ),
  },
};

export default AccountClosePreReqChecks;
