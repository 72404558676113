export const INDIA_TOPO = {
  type: 'Topology',
  objects: {
    india: {
      type: 'GeometryCollection',
      geometries: [
        {
          type: 'Polygon',
          properties: { name: null },
          id: '-99',
          arcs: [[0]],
        },
        {
          type: 'MultiPolygon',
          id: 'AN',
          properties: { name: 'Andaman & Nicobar Island' },
          arcs: [
            [[0]],
            [[1]],
            [[2]],
            [[3]],
            [[4]],
            [[5]],
            [[6]],
            [[7]],
            [[8]],
            [[9]],
            [[10]],
            [[11]],
            [[12]],
          ],
        },
        {
          type: 'MultiPolygon',
          id: 'AP',
          properties: { name: 'Andhra Pradesh' },
          arcs: [[[13]], [[14, 15, 16, 17, 18]]],
        },
        {
          type: 'Polygon',
          id: 'AR',
          properties: { name: 'Arunanchal Pradesh' },
          arcs: [[19, 20, 21]],
        },
        {
          type: 'Polygon',
          id: 'AS',
          properties: { name: 'Assam' },
          arcs: [[22, 23, 24, 25, 26, 27, 28, 29, 30, -21]],
        },
        {
          type: 'Polygon',
          id: 'BR',
          properties: { name: 'Bihar' },
          arcs: [[31, 32, 33, 34]],
        },
        {
          type: 'Polygon',
          id: 'CT',
          properties: { name: 'Chhattisgarh' },
          arcs: [[35, 36, 37, 38, 39, 40]],
        },
        {
          type: 'MultiPolygon',
          id: 'PY',
          properties: { name: 'Puducherry' },
          arcs: [
            [[41, 42]],
            [[-44]],
            [[44]],
            [[45, 46]],
            [[47]],
            [[48]],
            [[49]],
            [[50]],
            [[51, 52], [53]],
            [[54, 55]],
          ],
        },
        {
          type: 'Polygon',
          id: 'PB',
          properties: { name: 'Punjab' },
          arcs: [[56, 57, 58, 59, 60, 61, 62]],
        },
        {
          type: 'Polygon',
          id: 'RJ',
          properties: { name: 'Rajasthan' },
          arcs: [[63, 64, 65, 66, 67, -60]],
        },
        {
          type: 'Polygon',
          id: 'SK',
          properties: { name: 'Sikkim' },
          arcs: [[68, 69]],
        },
        {
          type: 'MultiPolygon',
          id: 'TN',
          properties: { name: 'Tamil Nadu' },
          arcs: [
            [[-54]],
            [
              [70, -47, 71, -43, 72, 73, 74, -16, 75, -55, 76, -53],
              [-49],
              [-51],
              [-50],
              [-48],
              [-45],
            ],
          ],
        },
        {
          type: 'Polygon',
          id: 'CH',
          properties: { name: 'Chandigarh' },
          arcs: [[77, -58]],
        },
        {
          type: 'Polygon',
          id: 'TS',
          properties: { name: 'Telangana' },
          arcs: [[-37, 78, -18, 79, 80]],
        },
        {
          type: 'Polygon',
          id: 'TR',
          properties: { name: 'Tripura' },
          arcs: [[-26, 81, 82]],
        },
        {
          type: 'Polygon',
          id: 'UP',
          properties: { name: 'Uttar Pradesh' },
          arcs: [[83, -34, 84, -40, 85, -65, 86, 87, 88, 89, 90]],
        },
        {
          type: 'Polygon',
          id: 'UK',
          properties: { name: 'Uttarakhand' },
          arcs: [[-91, 91, 92]],
        },
        {
          type: 'MultiPolygon',
          id: 'WB',
          properties: { name: 'West Bengal' },
          arcs: [
            [[93]],
            [[94]],
            [[95]],
            [[96]],
            [[97]],
            [[98]],
            [[99]],
            [[100]],
            [[101]],
            [[102]],
            [[103]],
            [[104]],
            [[105]],
            [[106]],
            [[107, -30, 108, 109, 110, -32, 111, -69]],
          ],
        },
        {
          type: 'Polygon',
          id: 'OD',
          properties: { name: 'Odisha' },
          arcs: [[-110, 112, -19, -79, -36, 113]],
        },
        {
          type: 'Polygon',
          id: 'DN',
          properties: { name: 'Dadara & Nagar Havelli' },
          arcs: [[114, 115]],
        },
        {
          type: 'Polygon',
          id: 'DD',
          properties: { name: 'Daman & Diu' },
          arcs: [[116, 117]],
        },
        {
          type: 'Polygon',
          id: 'GA',
          properties: { name: 'Goa' },
          arcs: [[118, 119, 120]],
        },
        {
          type: 'MultiPolygon',
          id: 'GJ',
          properties: { name: 'Gujarat' },
          arcs: [
            [[121]],
            [[122]],
            [[123]],
            [[124]],
            [[125]],
            [[-67, 126, 127, -116, 128, 129, -118, 130]],
          ],
        },
        {
          type: 'Polygon',
          id: 'HR',
          properties: { name: 'Haryana' },
          arcs: [[-89, 131, -87, -64, -59, -78, -57, 132]],
        },
        {
          type: 'Polygon',
          id: 'HP',
          properties: { name: 'Himachal Pradesh' },
          arcs: [[133, -92, -90, -133, -63, 134]],
        },
        {
          type: 'Polygon',
          id: 'JK',
          properties: { name: 'Jammu & Kashmir' },
          arcs: [[-135, -62, 135]],
        },
        {
          type: 'Polygon',
          id: 'JH',
          properties: { name: 'Jharkhand' },
          arcs: [[-111, -114, -41, -85, -33]],
        },
        {
          type: 'Polygon',
          id: 'KA',
          properties: { name: 'Karnataka' },
          arcs: [[-80, -17, -75, 136, 137, -119, 138]],
        },
        {
          type: 'Polygon',
          id: 'KL',
          properties: { name: 'Kerala' },
          arcs: [[-74, 139, -137], [43]],
        },
        {
          type: 'MultiPolygon',
          id: 'LD',
          properties: { name: 'Lakshadweep' },
          arcs: [[[140]], [[141]]],
        },
        {
          type: 'Polygon',
          id: 'MP',
          properties: { name: 'Madhya Pradesh' },
          arcs: [[-39, 142, -127, -66, -86]],
        },
        {
          type: 'Polygon',
          id: 'MH',
          properties: { name: 'Maharashtra' },
          arcs: [[-38, -81, -139, -121, 143, -129, -115, -128, -143]],
        },
        {
          type: 'Polygon',
          id: 'MN',
          properties: { name: 'Manipur' },
          arcs: [[144, 145, -24, 146]],
        },
        {
          type: 'Polygon',
          id: 'ML',
          properties: { name: 'Meghalaya' },
          arcs: [[147, -28]],
        },
        {
          type: 'Polygon',
          id: 'MZ',
          properties: { name: 'Mizoram' },
          arcs: [[-146, 148, -82, -25]],
        },
        {
          type: 'Polygon',
          id: 'NL',
          properties: { name: 'Nagaland' },
          arcs: [[149, -147, -23, -20]],
        },
        {
          type: 'Polygon',
          id: 'DL',
          properties: { name: 'NCT of Delhi' },
          arcs: [[-88, -132]],
        },
      ],
    },
    places: {
      type: 'GeometryCollection',
      geometries: [
        {
          type: 'Point',
          properties: { name: 'Delhi' },
          coordinates: [3093, 7227],
        },
        {
          type: 'Point',
          properties: { name: 'Hyderabad' },
          coordinates: [3521, 3510],
        },
        {
          type: 'Point',
          properties: { name: 'Pune' },
          coordinates: [1936, 3883],
        },
        {
          type: 'Point',
          properties: { name: 'Nagpur' },
          coordinates: [3730, 4754],
        },
        {
          type: 'Point',
          properties: { name: 'Jaipur' },
          coordinates: [2607, 6650],
        },
        {
          type: 'Point',
          properties: { name: 'Kanpur' },
          coordinates: [4151, 6498],
        },
        {
          type: 'Point',
          properties: { name: 'Patna' },
          coordinates: [5798, 6223],
        },
        {
          type: 'Point',
          properties: { name: 'Chennai' },
          coordinates: [4137, 2089],
        },
        {
          type: 'Point',
          properties: { name: 'Ahmedabad' },
          coordinates: [1501, 5367],
        },
        {
          type: 'Point',
          properties: { name: 'Surat' },
          coordinates: [1590, 4764],
        },
        {
          type: 'Point',
          properties: { name: 'New Delhi' },
          coordinates: [3083, 7203],
        },
        {
          type: 'Point',
          properties: { name: 'Bangalore' },
          coordinates: [3206, 2050],
        },
        {
          type: 'Point',
          properties: { name: 'Mumbai' },
          coordinates: [1596, 4044],
        },
        {
          type: 'Point',
          properties: { name: 'Kolkata' },
          coordinates: [6892, 5191],
        },
      ],
    },
  },
  arcs: [
    [
      [8784, 159],
      [11, -12],
      [2, -38],
      [15, -26],
      [-15, -69],
      [-18, -14],
      [-21, 36],
      [-9, 32],
      [-24, 17],
      [-7, 37],
      [12, 21],
      [16, 0],
      [38, 16],
    ],
    [
      [8737, 226],
      [15, -22],
      [-11, -22],
      [-33, -13],
      [2, 33],
      [25, 8],
      [2, 16],
    ],
    [
      [8624, 417],
      [5, -17],
      [22, -12],
      [-29, -17],
      [-23, 26],
      [25, 20],
    ],
    [
      [8673, 486],
      [-5, -30],
      [14, -22],
      [-23, -7],
      [-10, 41],
      [24, 18],
    ],
    [
      [8423, 819],
      [12, -25],
      [-10, -15],
      [-23, 1],
      [-3, 32],
      [24, 7],
    ],
    [
      [8328, 1365],
      [14, -11],
      [13, -54],
      [-20, -28],
      [10, -13],
      [-23, -21],
      [-28, 12],
      [1, 22],
      [-13, 18],
      [-1, 31],
      [47, 44],
    ],
    [
      [8370, 1567],
      [15, -24],
      [2, -21],
      [-32, -8],
      [5, 36],
      [10, 17],
    ],
    [
      [8484, 1743],
      [27, -46],
      [-40, 27],
      [13, 19],
    ],
    [
      [8389, 1808],
      [21, -21],
      [-7, -30],
      [17, -72],
      [-11, -49],
      [-1, -36],
      [-8, -30],
      [-28, 1],
      [-19, 49],
      [-13, 18],
      [-1, 31],
      [-13, 10],
      [17, 32],
      [17, 4],
      [11, 72],
      [18, 21],
    ],
    [
      [8433, 1829],
      [16, -1],
      [1, -31],
      [-17, -13],
      [-11, -27],
      [-18, 5],
      [10, 43],
      [19, 24],
    ],
    [
      [8458, 2031],
      [10, -10],
      [14, -48],
      [-4, -48],
      [7, -17],
      [-12, -31],
      [-14, -11],
      [-24, -37],
      [-45, 10],
      [0, 88],
      [13, 13],
      [-5, 62],
      [34, 23],
      [26, 6],
    ],
    [
      [8389, 2056],
      [8, -5],
      [0, -34],
      [-19, 3],
      [11, 36],
    ],
    [
      [8503, 2247],
      [1, -48],
      [13, -26],
      [-32, 1],
      [28, -32],
      [-9, -25],
      [1, -31],
      [-10, -17],
      [-19, -1],
      [-4, -18],
      [-49, -19],
      [-3, 33],
      [14, 64],
      [0, 39],
      [15, 47],
      [54, 33],
    ],
    [
      [4519, 3158],
      [-40, -18],
      [-3, 11],
      [43, 7],
    ],
    [
      [5675, 4064],
      [-27, -37],
      [-37, -33],
      [-9, -27],
      [-22, -17],
      [-44, -52],
      [-18, -8],
      [-10, -19],
      [-50, -48],
      [-6, -16],
      [-76, -34],
      [-9, -9],
      [-57, -23],
      [-47, -33],
      [-4, -13],
      [-27, -17],
      [-20, -34],
      [-68, -72],
      [-60, -24],
      [-17, -15],
      [-82, -34],
      [-66, -37],
      [-40, -30],
      [-49, -46],
      [-18, -42],
      [40, -23],
      [-5, -34],
      [-18, -51],
      [-40, -22],
      [-73, -35],
      [-39, -9],
      [-44, -20],
      [-26, 10],
      [-42, 4],
      [-42, -5],
      [-8, 8],
      [-42, -14],
      [2, -17],
      [-14, -13],
      [-24, -83],
      [-37, -26],
      [-17, -24],
      [5, -16],
      [-17, -13],
      [-42, -10],
      [-12, 5],
      [4, 29],
      [-29, 26],
      [-75, -10],
      [-8, -10],
      [-49, -25],
      [-36, -45],
      [-15, -50],
      [-22, -27],
      [-18, -41],
      [3, -13],
      [-14, -55],
      [23, -105],
      [28, -55],
      [-8, -72],
      [-15, -54],
      [6, -42],
      [35, -88],
      [-4, -35],
      [11, -40],
    ],
    [
      [4134, 2244],
      [-15, -26],
      [-71, 6],
      [-15, -12],
      [-12, -38],
      [-43, -11],
      [-44, -22],
      [-1, 15],
      [-36, -11],
      [-10, 20],
      [-40, 4],
      [1, -45],
      [-25, -7],
      [-17, -16],
      [-25, 6],
      [-25, -42],
      [-59, 23],
      [-12, -5],
      [-50, 4],
      [-62, -33],
      [2, -20],
      [-25, -72],
      [-32, -11],
      [1, -15],
      [-32, -2],
      [-48, 26],
      [-2, 21],
    ],
    [
      [3437, 1981],
      [6, 31],
      [22, 0],
      [14, 26],
      [39, 12],
      [-2, 23],
      [18, 7],
      [1, 22],
      [15, 23],
      [5, 27],
      [-23, 2],
      [-50, 25],
      [6, 12],
      [3, 63],
      [-45, -3],
      [-36, 25],
      [-22, 0],
      [13, 42],
      [-4, 25],
      [-23, 11],
      [-44, 3],
      [-17, 10],
      [-12, -22],
      [-34, -19],
      [-39, -14],
      [-30, -18],
      [-22, 12],
      [-13, 28],
      [-41, 10],
      [-23, 12],
      [-26, -26],
      [1, -20],
      [-45, 5],
      [-23, 13],
      [23, 38],
      [-14, 19],
      [-18, 43],
      [-18, 15],
      [45, 5],
      [1, -40],
      [33, -3],
      [5, -15],
      [49, 3],
      [12, 6],
      [12, -42],
      [25, -1],
      [-13, 37],
      [-15, 16],
      [20, 16],
      [-2, 20],
      [41, 3],
      [-4, 29],
      [-36, 22],
      [-12, -19],
      [-27, 2],
      [0, 18],
      [-29, -6],
      [-15, 8],
      [-35, -32],
      [-39, 0],
      [2, 21],
      [-15, 5],
      [-6, 23],
      [31, 29],
      [-37, -1],
      [-22, 17],
      [-14, 23],
      [4, 26],
      [21, 36],
      [11, 59],
      [-35, 2],
      [12, 40],
      [27, -21],
      [31, -5],
      [49, 6],
      [19, 46],
      [-6, 38],
      [-38, 24],
      [-3, 25],
      [-1, 64],
      [19, 8],
      [-12, 24],
      [10, 61],
      [23, 10],
      [37, 6],
      [60, -5],
      [29, -7],
    ],
    [
      [3191, 3024],
      [60, -16],
      [39, -2],
      [31, 10],
      [73, -23],
      [22, 7],
      [5, 17],
      [22, 12],
      [8, 33],
      [17, -2],
      [30, 17],
      [53, -8],
      [27, 11],
      [35, -23],
      [33, 22],
      [29, 12],
      [-4, 22],
      [38, 18],
      [54, -9],
      [16, 11],
      [-4, 37],
      [6, 52],
      [12, 13],
      [55, 7],
      [25, 16],
      [51, 11],
      [44, 22],
      [30, -10],
      [27, -22],
      [36, 37],
      [8, 23],
      [-27, 16],
      [19, 34],
      [13, -1],
      [38, 18],
      [23, -2],
      [13, -18],
      [6, -28],
      [34, -9],
      [13, -18],
      [37, -9],
      [-3, 21],
      [10, 33],
      [-18, 8],
      [-31, -2],
      [2, 24],
      [17, 28],
      [22, 12],
      [42, -23],
      [48, -10],
      [12, 4],
      [4, 32],
      [12, 18],
      [27, -7],
      [61, 17],
      [10, 32],
      [26, -3],
      [19, 23],
      [21, -11],
      [20, 9],
      [17, 22],
      [4, 46],
      [41, 57],
      [17, 2],
      [41, 25],
      [-12, 16],
    ],
    [
      [4647, 3675],
      [15, 14],
      [46, 16],
      [30, 24],
      [48, -5],
      [26, -20],
      [6, 18],
      [27, 4],
      [-1, 56],
      [9, 63],
      [41, 35],
      [15, -4],
      [4, -31],
      [22, -14],
      [-4, -36],
      [61, 25],
      [11, 35],
      [61, -27],
      [31, 12],
      [-14, 57],
      [20, 30],
      [104, 71],
      [-26, 31],
      [7, 13],
      [48, -2],
      [45, 31],
      [5, 16],
      [36, -55],
      [22, 11],
      [28, -63],
      [61, -11],
      [14, -13],
      [43, 14],
      [40, 2],
      [28, 48],
      [33, 33],
      [29, -6],
      [22, 34],
      [35, -17],
    ],
    [
      [9259, 6570],
      [-18, 59],
      [15, 7],
      [-11, 52],
    ],
    [
      [9245, 6688],
      [18, -4],
      [40, 27],
      [32, 7],
      [0, 25],
      [26, 20],
      [19, -14],
      [128, 29],
      [17, 19],
      [-14, 20],
      [-31, 3],
      [0, 36],
      [-29, 18],
      [-13, 41],
      [22, 26],
      [36, 26],
      [17, 26],
      [-52, 3],
      [-75, -6],
      [-31, -25],
      [-45, -13],
      [-23, 9],
      [-148, -42],
      [-49, -26],
      [-20, 0],
      [-63, -28],
      [-27, 8],
      [-18, -8],
      [-27, 6],
      [-10, -24],
      [-35, -15],
      [-33, -38],
      [-21, -9],
      [-66, -60],
      [10, -25],
      [-40, -19],
      [-15, -16],
      [-71, -10],
      [-41, 8],
      [-113, -16],
      [-34, 16],
      [-16, 15],
      [-35, 8],
      [-38, 2],
      [-4, -17],
      [-21, -8],
      [-48, -1],
      [-86, -23],
      [-29, 1],
    ],
    [
      [8189, 6640],
      [-2, 38],
      [-26, 23],
      [15, 66],
      [-5, 40],
      [-13, 19],
      [-57, 9],
      [-26, -6],
      [-41, 6],
      [-32, 33],
      [-1, 16],
      [23, 24],
      [1, 32],
      [14, 18],
      [27, -18],
      [27, 2],
      [18, -28],
      [36, 15],
      [24, -3],
      [35, 25],
      [34, 9],
      [17, -28],
      [37, 15],
      [50, -1],
      [15, 19],
      [22, 7],
      [24, 30],
      [-26, 24],
      [6, 18],
      [35, 22],
      [39, 7],
      [43, 31],
      [35, 9],
      [24, -3],
      [13, 27],
      [-14, 9],
      [29, 26],
      [20, 42],
      [55, 17],
      [39, -10],
      [42, 11],
      [15, -6],
      [24, 12],
      [31, -10],
      [20, 55],
      [55, 37],
      [21, 43],
      [25, 21],
      [21, 1],
      [28, 25],
      [43, 0],
      [25, 40],
      [47, -32],
      [10, -29],
      [26, 7],
      [45, -5],
      [40, -18],
      [44, -2],
      [0, -14],
      [70, -10],
      [31, 59],
      [14, 0],
      [66, 46],
      [35, 12],
      [26, -3],
      [42, 26],
      [24, -21],
      [11, -30],
      [18, -21],
      [54, 4],
      [-16, -28],
      [-44, -11],
      [-23, -28],
      [26, -41],
      [41, 34],
      [31, 1],
      [29, -24],
      [5, -26],
      [24, -29],
      [-7, -25],
      [-40, -12],
      [8, -14],
      [-32, -27],
      [-26, -10],
      [15, -17],
      [-35, -21],
      [36, -7],
      [47, 7],
      [31, 16],
      [22, 0],
      [32, -17],
      [6, -13],
      [42, 3],
      [34, -23],
      [19, 14],
      [25, -1],
      [15, -17],
      [19, -1],
      [51, -25],
      [-15, -21],
      [23, -42],
      [-7, -39],
      [-42, -2],
      [-33, -29],
      [-49, -27],
      [-9, -23],
      [-32, -17],
      [14, -30],
      [-9, -22],
      [18, -16],
      [13, -28],
      [31, -27],
      [27, -37],
      [-38, -8],
      [-26, 18],
      [-41, 11],
      [7, 23],
      [-28, 28],
      [-63, 7],
      [-30, -25],
      [-34, 4],
      [-68, -8],
      [-68, -29],
      [-27, -42],
      [-27, -16],
      [-21, 1],
      [-19, -20],
      [-2, -18],
      [-40, -10],
      [-9, -19],
      [-23, 6],
      [-42, -47],
      [-41, -13],
      [-22, 10],
    ],
    [
      [9245, 6688],
      [-37, -30],
      [-35, -11],
      [-34, 5],
      [-29, -42],
      [-39, -24],
      [-49, -7],
      [-46, -31],
      [-5, -29],
      [-35, -16],
      [-4, 27],
      [-33, -34],
      [-7, -33],
      [-20, -11],
      [-32, -46],
      [-20, -59],
      [9, -27],
      [-44, -21],
      [-25, -5],
      [2, 37],
      [-34, -16],
      [7, -19],
      [-46, -32],
      [-31, -36],
      [-22, -8],
      [-29, -24],
      [42, -35],
      [6, -44],
    ],
    [
      [8655, 6117],
      [-24, -15],
      [-17, -27],
      [-6, -29],
      [-31, -30],
      [2, -28],
      [-20, -37],
      [-28, -1],
      [-3, -18],
      [-6, -33],
      [4, -22],
      [-15, -12],
      [-7, -38],
      [-11, -9],
    ],
    [
      [8493, 5818],
      [-58, -1],
      [-23, 40],
      [-25, -54],
      [-22, -7],
      [-5, -27],
      [-21, -2],
      [-7, -24],
      [-32, -7],
      [-5, 33],
      [-43, 0],
    ],
    [
      [8252, 5769],
      [-29, -1],
      [21, 46],
      [-14, 38],
    ],
    [
      [8230, 5852],
      [3, 48],
      [9, 21],
      [2, 39],
      [-8, 26],
      [35, -7],
      [17, -11],
      [33, 5],
      [-22, 53],
    ],
    [
      [8299, 6026],
      [31, 36],
      [34, -8],
      [19, 21],
      [23, 9],
      [16, 26],
      [-11, 16],
      [-37, 32],
      [-15, -5],
      [-7, 45],
      [-10, 19],
      [-21, 5],
      [-27, 38],
      [-62, -11],
      [-23, -14],
      [-5, 49],
      [9, 19],
      [-5, 31],
      [31, 21],
      [-27, 17],
      [-15, -12],
      [-86, -5],
      [-2, 23],
      [-19, 7],
      [-31, -20],
      [-3, -35],
      [-31, -3],
      [1, 26],
      [-22, 2],
      [-32, -57],
      [-2, 1],
      [-47, -9],
      [-20, -28],
      [-17, -8],
      [-24, 42],
      [-30, -8],
      [-37, 36],
      [-26, 6],
      [-48, -12],
      [-10, 12],
      [-29, -5],
      [-32, 7],
      [-19, 19],
      [-17, -9],
      [-22, 7],
      [-14, -12],
      [-38, -6],
      [-34, 1],
      [-54, -60],
      [-21, -16],
      [37, -47],
      [-40, -9],
      [-11, -27],
    ],
    [
      [7417, 6173],
      [9, 46],
      [-19, 42],
      [4, 45],
      [11, 32],
      [-29, 25],
      [-12, 33],
      [-10, 4],
    ],
    [
      [7371, 6400],
      [0, 31],
      [47, 41],
      [1, 1],
      [-2, 34],
      [9, 32],
      [-7, 39],
    ],
    [
      [7419, 6578],
      [11, 7],
      [53, 1],
      [49, 14],
      [13, 29],
      [29, -3],
      [34, 17],
      [51, -33],
      [31, -9],
      [138, 2],
      [12, 13],
      [59, -5],
      [26, -8],
      [24, 18],
      [24, -14],
      [50, 9],
      [35, -2],
      [59, 34],
      [30, -19],
      [24, -4],
      [18, 15],
    ],
    [
      [6819, 6524],
      [48, -30],
      [10, -24],
      [-17, -29],
      [-64, -37],
      [-27, -30],
      [-16, 2],
      [-24, -15],
      [-12, -38],
      [6, -19],
      [30, -7],
      [-4, -24],
      [51, -31],
      [-8, -43],
      [-54, -17],
      [-28, -18],
      [-2, -36],
      [24, -14],
      [-22, -25],
    ],
    [
      [6710, 6089],
      [-27, 12],
      [-8, 18],
      [-40, 7],
      [-32, -30],
      [-52, -6],
      [-11, -44],
      [-39, -9],
      [-8, -24],
      [-2, -42],
      [-24, -18],
      [2, -27],
      [-13, -34],
      [-37, 2],
      [-28, -26],
      [-22, 22],
      [-42, -19],
      [-23, 13],
      [-38, -38],
      [-9, -35],
      [-16, 1],
      [-36, 42],
      [-6, 26],
      [-44, -1],
      [-13, 9],
      [-7, 41],
      [-20, 14],
      [-33, -15],
      [-25, 22],
      [-35, 0],
      [-13, 8],
      [-11, -31],
      [-16, -18],
      [2, -31],
      [-19, 1],
      [-26, -17],
      [-13, 6],
      [-74, -10],
      [-38, -30],
      [-18, -7],
      [-29, 6],
      [-21, -17],
      [-23, -1],
      [-28, 50],
      [-49, -22],
      [-7, -21],
      [-29, 6],
      [-27, -41],
      [-13, 17],
      [-41, 19],
      [-15, 18],
      [10, 13],
      [-21, 15],
      [-30, 2],
      [-21, -20],
      [-22, 43],
      [-18, 8],
      [-22, -29],
      [-21, -5],
      [-3, 1],
      [-27, -4],
      [-11, -5],
      [-86, 6],
    ],
    [
      [5242, 5860],
      [7, 7],
      [0, 2],
      [6, 20],
      [-23, 39],
      [-2, 0],
      [-26, 16],
      [-23, 77],
      [11, 60],
      [20, 17],
      [69, 27],
      [34, 21],
      [25, 1],
      [47, 54],
      [54, 25],
      [-3, 19],
      [34, 8],
      [17, -19],
      [34, 3],
      [3, 22],
      [19, -1],
      [4, -2],
      [42, -17],
      [36, 38],
      [-32, 28],
      [-33, 5],
      [-27, 22],
      [-21, -4],
      [-83, 50],
      [-16, 30],
      [53, 23],
      [5, 37],
      [-37, 6],
      [-16, 17],
      [-39, 2],
      [2, 19],
      [48, 12],
      [13, 22],
      [23, 10],
      [41, -13],
      [49, 9],
      [-5, 14],
      [-26, 5],
      [-33, 61],
      [-33, -5],
      [-27, 12],
      [-1, 33],
      [-9, 17],
      [-28, 25],
      [15, 21],
      [-29, 23],
      [2, 26],
      [-17, 5],
    ],
    [
      [5366, 6789],
      [-7, 32],
      [61, -2],
      [32, 28],
      [47, -23],
      [7, -18],
      [120, -19],
      [22, -38],
      [-1, -22],
      [-14, -36],
      [40, -15],
      [22, 7],
      [46, -20],
      [24, -29],
      [56, -2],
      [-6, -17],
      [11, -18],
      [43, -7],
      [25, 17],
      [16, -4],
      [53, 25],
      [23, -4],
      [20, -16],
      [-4, -38],
      [6, -11],
      [38, -13],
      [61, 20],
      [56, -17],
      [52, 0],
      [77, -39],
      [56, -24],
      [55, 12],
      [13, 17],
      [28, 7],
      [19, 17],
      [7, -44],
      [23, -15],
      [37, 0],
      [25, -19],
      [17, 23],
      [30, 7],
      [38, -15],
      [22, -1],
      [9, 14],
      [30, -8],
      [10, 19],
      [43, -12],
      [31, -25],
      [30, 26],
      [4, 35],
    ],
    [
      [5415, 5199],
      [13, -18],
      [-14, -32],
      [-48, -8],
      [-39, -34],
      [-34, -5],
      [-31, -42],
      [-7, -44],
      [22, -18],
      [-37, -33],
      [-3, -23],
      [-14, -30],
      [-21, -12],
      [-13, -42],
      [17, -28],
      [-6, -20],
      [-36, 12],
      [-26, -78],
      [-25, -12],
      [-48, 18],
      [-48, 2],
      [-70, -5],
      [-5, -37],
      [-23, -31],
      [-24, -14],
      [-2, -17],
      [-26, 5],
      [-23, -8],
      [3, -46],
      [8, -23],
      [-15, -25],
      [20, -14],
      [10, -36],
      [-6, -22],
      [7, -44],
      [-12, -19],
      [6, -31],
      [64, -19],
      [44, -3],
      [-5, -51],
      [-76, 24],
      [-30, -6],
      [-16, -17],
      [-27, 46],
      [-27, 4],
      [-43, 22],
      [-15, -10],
      [-27, 26],
      [-26, -25],
      [-7, -24],
      [7, -16],
      [35, -15],
      [7, -20],
      [24, -1],
      [-5, -29],
      [3, -54],
      [16, -10],
      [9, -28],
      [18, 2],
      [3, -32],
      [-10, -22],
      [6, -43],
      [14, -19],
      [11, -55],
      [-24, -6],
      [-9, -39],
      [-65, -31],
      [-3, -40],
      [-63, -48],
      [-6, -22],
      [-29, -2],
      [-45, -27],
      [-2, -33],
      [-17, -42],
      [1, -18],
      [-27, -29],
      [-4, -27],
    ],
    [
      [4519, 3646],
      [-45, 2],
      [-32, 13],
      [-30, -21],
      [-23, 17],
      [-19, 93],
      [-30, 4],
      [-44, 12],
      [17, 24],
      [-24, 53],
      [-28, 38],
      [-49, 35],
      [-50, -13],
      [-26, 43],
    ],
    [
      [4136, 3946],
      [-9, 10],
      [36, 21],
      [-4, 14],
      [-24, 28],
      [1, 16],
      [39, 63],
      [2, 21],
      [20, 9],
      [9, 19],
      [30, 23],
      [15, -28],
      [50, -9],
      [31, 23],
      [-18, 23],
      [36, 13],
      [-5, 18],
      [-54, 29],
      [-20, 0],
      [1, 28],
      [-43, 27],
      [-17, 39],
      [19, 29],
      [-7, 47],
      [-25, 7],
      [-24, 32],
      [44, 9],
      [37, 18],
      [-11, 22],
      [6, 14],
      [3, 52],
      [-24, 66],
      [-2, 46],
      [-27, -1],
      [-12, 26],
      [12, 54],
      [35, 11],
      [27, 16],
      [6, 25],
    ],
    [
      [4269, 4806],
      [25, 47],
      [-7, 66],
      [11, 27],
      [24, -2],
      [10, 42],
      [-2, 23],
      [28, 58],
      [29, -20],
      [9, 45],
      [30, 32],
      [-2, 48],
      [21, 16],
      [18, -12],
      [34, 24],
      [20, -7],
      [3, -20],
      [43, 31],
      [32, 0],
      [24, 37],
      [30, 9],
      [6, 29],
      [-5, 36],
      [32, 6],
      [27, 21],
      [-8, 28],
      [7, 12],
      [61, 9],
      [12, 12],
      [-3, 28],
      [16, 32],
      [-31, 24],
      [-26, -4],
      [-18, 13],
      [-18, 36],
      [-31, -7],
      [-33, 17],
      [-13, -13],
      [-30, -4],
      [3, 49],
      [25, 20],
      [-30, 43],
      [22, 24],
      [14, -20],
      [37, -18],
      [48, 14],
      [4, 2],
      [28, -12],
      [53, 3],
      [33, -7],
      [57, 1],
      [28, -6],
      [29, 15],
      [9, 22],
      [32, 5],
      [20, 14],
    ],
    [
      [5006, 5674],
      [50, -30],
      [59, 6],
      [22, 10],
      [9, 23],
      [21, 11],
      [16, 25],
    ],
    [
      [5183, 5719],
      [34, -5],
      [29, -19],
      [1, -20],
      [24, -34],
      [25, -6],
      [25, -30],
      [-5, -23],
      [21, -28],
      [55, -12],
      [1, 20],
      [3, 1],
      [25, -8],
      [-4, -34],
      [-13, -17],
      [-1, -24],
      [35, -14],
      [-14, -58],
      [64, -59],
      [21, -5],
      [32, 5],
      [2, -34],
      [-19, -8],
      [-12, -28],
      [-21, -9],
      [2, -16],
      [-28, -17],
      [-24, 0],
      [-25, -22],
      [-1, -16],
    ],
    [
      [3991, 1395],
      [-1, -53],
    ],
    [
      [3990, 1342],
      [-10, 20],
      [-20, 6],
      [-1, 1],
      [1, 2],
      [-6, -1],
      [1, 2],
      [-1, 0],
      [0, 2],
      [-1, 0],
      [-1, -1],
      [0, 2],
      [-4, 1],
      [1, 1],
      [0, 1],
      [-2, -1],
      [1, 3],
      [2, 0],
      [2, 3],
      [0, 2],
      [-4, -2],
      [-1, 0],
      [-1, 5],
      [3, 1],
      [10, -3],
      [2, 0],
      [0, 2],
      [-2, 5],
      [-1, 1],
      [-3, 1],
      [0, 3],
      [0, 1],
      [3, 2],
      [2, -2],
      [28, 0],
      [0, -1],
      [3, -3],
    ],
    [
      [2520, 1636],
      [-6, 10],
      [-1, 1],
      [7, -11],
    ],
    [
      [3931, 1664],
      [0, 1],
      [0, 3],
      [4, -1],
      [-4, -3],
    ],
    [
      [3975, 1673],
      [-3, -15],
    ],
    [
      [3972, 1658],
      [-5, -1],
      [0, -1],
      [-2, 1],
      [-2, 0],
      [-17, 5],
      [1, 2],
      [1, 0],
      [0, 2],
      [3, 0],
      [0, 1],
      [0, 3],
      [-1, 1],
      [-1, 4],
      [1, 1],
      [1, 0],
      [2, 1],
      [5, -2],
      [3, -2],
      [0, -2],
      [3, -1],
      [7, 2],
      [1, 0],
      [-1, 2],
      [2, 0],
      [0, 1],
      [1, -1],
      [1, -1],
    ],
    [
      [3929, 1668],
      [-8, 0],
      [-2, 3],
      [10, -3],
    ],
    [
      [3915, 1690],
      [2, 0],
      [1, 2],
      [4, -2],
      [5, -7],
      [-2, -2],
      [-1, -1],
      [-3, -1],
      [-6, 6],
      [-3, -1],
      [0, -1],
      [-3, 0],
      [-3, -1],
      [10, 10],
      [-1, -2],
    ],
    [
      [3935, 1718],
      [-1, -4],
      [-7, -1],
      [-3, 1],
      [2, 1],
      [0, 2],
      [9, 1],
    ],
    [
      [3914, 1739],
      [10, -2],
      [-1, -1],
      [-1, -5],
      [-2, -2],
      [2, -13],
      [0, -2],
      [-1, -1],
      [-1, -2],
      [10, -10],
      [-1, 0],
      [-2, 0],
      [-3, -3],
      [-4, 1],
      [-1, -1],
      [0, -2],
      [-3, 0],
      [-2, 1],
      [-2, 7],
      [0, 5],
      [0, 2],
      [3, 1],
      [0, 2],
      [0, 4],
      [-4, 1],
      [1, 12],
      [0, 1],
      [3, 4],
      [-1, 3],
    ],
    [
      [3987, 1714],
      [-11, -40],
    ],
    [
      [3976, 1674],
      [-1, 5],
      [-3, 2],
      [-5, 0],
      [0, -2],
      [-2, -4],
      [-1, 0],
      [-2, 1],
      [-1, 3],
      [3, 5],
      [-1, 3],
      [-9, -9],
      [-3, 6],
      [-9, 2],
      [1, 6],
      [-5, 0],
      [0, -1],
      [-1, 0],
      [-2, 0],
      [3, 3],
      [0, 2],
      [1, 1],
      [-1, 2],
      [7, 1],
      [-3, -6],
      [1, 17],
      [0, -2],
      [1, -3],
      [-2, 2],
      [-5, 5],
      [0, 1],
      [8, 4],
      [-2, 3],
      [1, 3],
      [-4, 1],
      [-1, 0],
      [-2, 0],
      [-9, -1],
      [-2, 7],
      [0, 2],
      [16, 4],
      [-1, -3],
      [4, -5],
      [1, -2],
      [16, -1],
      [-2, -2],
      [-3, -2],
      [-5, 1],
      [-1, 0],
      [-2, -4],
      [8, -9],
      [0, -6],
      [0, -1],
      [1, 0],
      [0, 1],
      [3, 2],
      [0, 4],
      [15, 7],
      [0, 3],
      [11, -6],
    ],
    [
      [3964, 1688],
      [2, 1],
      [1, 0],
      [0, -2],
      [-1, -3],
      [2, -1],
      [2, 5],
      [-6, 0],
    ],
    [
      [3988, 1733],
      [1, 7],
      [0, 2],
      [10, 1],
    ],
    [
      [3999, 1743],
      [-11, -10],
    ],
    [
      [2941, 7963],
      [19, -25],
      [1, -22],
    ],
    [
      [2961, 7916],
      [-24, 11],
      [-24, -13],
      [9, -15],
      [36, -9],
    ],
    [
      [2958, 7890],
      [32, -29],
      [1, -30],
      [-1, -3],
      [-17, -30],
      [-40, 6],
      [-3, -22],
      [-51, -35],
      [16, -15],
      [-4, -17],
      [-32, -24],
      [-28, 6],
      [-9, 15],
      [-33, -12],
      [-27, 3],
      [-18, -27],
      [4, -64],
      [-2, 1],
      [-56, -32],
      [-36, -5],
      [-25, 6],
      [-15, 21],
      [-39, -2],
      [-13, -12],
      [-38, -2],
      [-38, 7],
      [-44, -38],
      [-33, -22],
      [-16, 8],
      [8, 34],
      [12, 7],
      [-17, 28],
      [-27, -12],
      [-13, 26],
      [-26, -8],
      [-22, 28],
      [-43, 17],
      [-73, -25],
      [-23, 9],
    ],
    [
      [2169, 7646],
      [-68, 4],
      [-11, 1],
      [-4, -1],
      [-132, 6],
      [2, 27],
      [22, 24],
      [1, 23],
    ],
    [
      [1979, 7730],
      [-2, 22],
      [-21, 29],
      [21, 24],
      [1, 21],
      [33, 11],
      [12, 26],
      [33, 22],
      [26, 30],
      [18, 30],
      [33, 21],
      [12, 19],
      [31, 9],
      [5, 19],
      [35, 0],
      [8, 25],
      [-59, 2],
      [2, 45],
      [10, 31],
      [35, 31],
      [-24, 20],
      [12, 15],
      [-23, 17],
      [-23, 34],
      [27, 12],
      [2, 24],
      [33, 26],
      [51, 17],
      [43, 35],
      [23, -8],
      [43, 13],
      [36, 3],
      [15, 9],
      [17, 33],
      [2, 40],
    ],
    [
      [2446, 8437],
      [49, -8],
      [43, 21],
      [41, 12],
      [12, 20],
      [20, 3],
      [23, 29],
    ],
    [
      [2634, 8514],
      [-6, -25],
      [24, -29],
      [-28, -11],
      [-37, -34],
      [-39, -13],
      [12, -29],
      [-16, -16],
      [63, -37],
      [34, -12],
      [19, -34],
      [-9, -10],
      [27, -57],
      [28, -36],
      [42, -76],
      [42, 13],
      [10, 23],
      [24, -30],
      [71, -37],
      [-16, -10],
      [12, -24],
      [-5, -35],
      [35, -13],
      [20, -19],
    ],
    [
      [2169, 7646],
      [11, -26],
      [-21, -12],
      [-7, -28],
      [46, 3],
      [-11, -65],
      [13, -8],
      [-14, -41],
      [27, -11],
      [43, -5],
      [26, 14],
      [26, -12],
      [9, -27],
      [24, 3],
      [19, -20],
      [103, 10],
      [7, -24],
      [-13, -16],
      [6, -24],
      [19, -17],
      [26, -2],
      [-8, -50],
      [23, -81],
      [19, -3],
      [6, -20],
      [51, -29],
      [9, -14],
      [41, -15],
      [33, -29],
      [-4, -10],
      [15, -9],
      [-26, -46],
      [-7, -32],
      [7, -41],
      [38, 2],
      [29, -21],
      [12, 14],
      [-18, 47],
      [8, 22],
      [25, 4],
      [30, -13],
      [8, 37],
      [38, 2],
      [23, -36],
      [-3, -20],
      [44, 13],
      [-4, 24],
      [49, 22],
      [15, 22],
      [44, -27],
      [-15, -48],
      [3, -54],
      [-13, -38],
      [28, -20],
      [23, 53],
      [21, -11],
      [17, 9],
      [29, -9],
      [14, 7],
    ],
    [
      [3112, 6940],
      [10, -6],
      [13, -45],
      [-1, -42],
      [31, -21],
      [1, -21],
      [48, -23],
      [24, -29],
      [4, -21],
      [-38, -20],
      [5, -22],
      [34, -9],
      [12, -14],
      [-72, -25],
      [-23, -20],
      [16, -24],
      [20, 17],
      [30, 4],
      [37, 16],
      [17, 18],
      [36, -10],
      [59, 3],
      [15, -5],
      [18, 19],
      [32, -8],
      [-8, -32],
    ],
    [
      [3432, 6620],
      [-11, -12],
      [-28, -4],
      [-3, -34],
      [-33, 6],
      [-61, -49],
      [-49, -15],
      [-51, -31],
      [-32, -3],
      [-38, -20],
      [-17, -23],
      [-25, -13],
      [-28, -2],
      [-1, -2],
      [-40, -28],
      [-34, -14],
      [-17, -19],
      [-2, 0],
      [-15, -31],
      [-22, -14],
      [-31, 1],
      [-38, -35],
      [-7, -42],
      [3, -47],
      [28, -46],
      [59, -24],
      [24, 4],
      [40, -11],
      [40, 14],
      [26, -8],
      [52, 40],
      [35, -69],
      [-7, -34],
      [-29, -13],
      [-13, 12],
      [-32, -2],
      [-32, -18],
      [-32, 6],
      [-39, -22],
      [1, -18],
      [26, -27],
      [-18, -11],
      [-32, -5],
      [15, -18],
      [59, -18],
      [14, -24],
      [-3, -39],
      [-31, -21],
      [-15, 24],
      [-35, 0],
      [12, -21],
      [-5, -36],
      [13, -30],
      [24, -24],
      [-15, -22],
      [-37, -2],
      [-40, 23],
      [9, 18],
      [-29, 5],
      [-32, -33],
      [-21, 21],
      [-17, -6],
      [-21, 13],
      [-26, -11],
      [-24, 6],
      [-28, -9],
      [4, -37],
      [-32, -7],
      [-28, -16],
      [5, -30],
      [-42, -13],
      [-70, -31],
      [-23, 0],
      [-7, 19],
      [-36, 21],
      [3, 20],
      [17, 22],
      [64, -26],
      [21, 9],
      [6, 22],
      [-13, 25],
      [24, 53],
      [-16, 3],
      [-10, 31],
      [17, 18],
      [15, -12],
      [25, 8],
      [8, 30],
      [-25, 26],
      [-5, 38],
      [-33, 10],
      [-44, -24],
      [-11, 11],
      [-44, -9],
      [-77, 14],
      [-12, 17],
      [17, 24],
      [20, -15],
      [12, 49],
      [-9, 27],
      [-53, -6],
      [-12, -17],
      [-1, -29],
      [-26, -9],
      [-33, 6],
      [-27, 19],
      [-14, -12],
      [11, -29],
      [53, -6],
      [-5, -28],
      [-19, -15],
      [-24, 2],
      [-19, 32],
      [-14, -26],
      [-22, -59],
      [1, 0],
      [34, -5],
      [15, -14],
      [-26, -28],
      [-10, -29],
      [4, -2],
      [38, 2],
      [8, -34],
      [20, -21],
      [12, -27],
      [-27, -39],
      [-3, -14],
      [12, -44],
      [-6, -22],
      [-25, -37],
      [-82, -31],
      [-27, -46],
      [5, -12],
      [29, -7],
      [12, -21],
      [-46, -33],
      [-23, -5],
      [-26, 9],
      [-24, -17],
    ],
    [
      [2097, 5376],
      [-25, 39],
      [-23, -9],
      [-19, 9],
      [3, 28],
      [-30, 10],
      [-8, 13],
      [-7, 1],
      [-38, 0],
      [-23, 36],
      [-66, 2],
      [9, 57],
      [-31, 10],
      [-58, 43],
      [-14, 23],
      [21, 23],
      [-2, 42],
      [-30, 4],
      [-26, -17],
      [-17, 11],
      [8, 18],
      [-35, 14],
      [-14, 14],
      [12, 26],
      [24, 24],
      [-35, 19],
      [3, 33],
      [-39, -14],
      [-1, -30],
      [-18, -11],
      [-19, 13],
      [-47, -1],
      [-25, 31],
      [-40, 16],
      [-27, -32],
      [-36, 48],
      [-22, -4],
      [-22, 31],
      [-15, -8],
      [-35, 14],
      [-11, 18],
      [-21, -16],
      [-71, 4],
      [-43, -12],
      [-15, 13],
      [-44, 1],
      [-37, -17],
      [-29, -5],
      [-65, 25],
    ],
    [
      [994, 5913],
      [-55, 84],
      [-18, 69],
      [-46, 43],
      [-29, 38],
      [3, 90],
      [-28, 14],
      [-23, -9],
      [-46, -4],
      [-39, 12],
      [-14, 22],
      [-23, 18],
      [-24, 35],
      [-4, 50],
      [29, 47],
      [4, 43],
      [-4, 63],
      [-42, 16],
      [-58, -7],
      [-27, 6],
      [-31, 21],
      [-67, 27],
      [-10, 21],
      [10, 68],
      [26, 56],
      [39, 34],
      [57, 41],
      [24, 30],
      [29, 20],
      [37, 81],
      [31, 30],
      [51, 37],
      [42, 8],
      [33, -8],
      [29, -29],
      [4, -31],
      [27, -34],
      [47, -5],
      [104, 40],
      [69, 14],
      [85, 0],
      [82, 29],
      [12, 55],
      [23, 28],
      [44, 31],
      [26, 27],
      [37, 98],
      [32, 32],
      [40, 16],
      [77, 39],
      [69, 27],
      [17, 36],
      [29, 31],
      [67, 107],
      [39, 122],
      [5, 5],
      [73, 26],
      [62, 16],
      [59, 41],
    ],
    [
      [7041, 6724],
      [-55, 16],
      [-55, -38],
      [-43, 16],
      [-52, -6],
      [-22, 9],
      [-10, 25],
      [-17, 1],
    ],
    [
      [6787, 6747],
      [15, 37],
      [3, 32],
      [-8, 22],
      [36, 58],
      [16, 40],
      [1, 20],
      [-23, 8],
      [5, 29],
      [35, -5],
      [33, 13],
      [17, -3],
      [32, 22],
      [18, -3],
      [14, 17],
      [60, -4],
      [31, -28],
      [-2, -19],
      [15, -12],
      [-8, -26],
      [-3, -49],
      [-28, -34],
      [6, -36],
      [25, -23],
      [14, -37],
      [-33, -9],
      [-17, -33],
    ],
    [
      [3976, 1674],
      [-1, -1],
    ],
    [
      [3972, 1658],
      [-12, -57],
      [1, -25],
      [26, -60],
      [6, -67],
      [-2, -54],
    ],
    [
      [3990, 1342],
      [8, -160],
      [3, -17],
      [-28, -6],
      [-123, 18],
      [-18, -1],
      [-38, -28],
      [-17, -44],
      [13, -23],
      [-14, -9],
      [-33, -39],
      [-1, -10],
      [-45, -48],
      [-23, -42],
      [-8, -43],
      [29, -34],
      [37, -31],
      [-37, 5],
      [-37, -6],
      [-70, -20],
      [-8, -12],
      [-26, -10],
      [-52, -5],
      [-48, -30],
      [-24, -20],
      [-14, -27],
      [1, -37],
      [-17, -44],
      [3, -45],
      [-32, -46],
      [-23, -8],
      [-35, -24],
      [-19, -5],
      [-28, -25],
      [-49, -9],
      [-12, -21],
      [-81, 14],
      [-75, 56],
    ],
    [
      [3049, 506],
      [37, 50],
      [1, 18],
      [25, 16],
      [-23, 34],
      [-8, 31],
      [25, 32],
      [-40, 59],
      [41, 44],
      [5, 49],
      [18, 19],
      [1, 18],
      [20, 19],
      [-14, 38],
      [-40, -1],
      [-20, 24],
      [14, 25],
      [6, 40],
      [-9, 7],
      [21, 32],
      [-4, 18],
      [-21, 26],
      [22, 5],
      [-1, 47],
      [-30, 31],
      [-62, -42],
      [-31, 4],
      [-25, 20],
      [7, 22],
      [-8, 26],
      [5, 48],
      [14, 15],
      [9, 46],
      [-28, 30],
      [-29, 7],
      [-29, 15],
      [32, 60],
      [-41, 25],
      [-62, 5],
      [24, 20],
      [9, 30],
      [-31, 9],
      [-30, 22],
      [-36, 9],
      [-11, 30],
      [25, 0],
      [42, 20],
      [1, 11],
    ],
    [
      [2820, 1619],
      [28, 13],
      [17, -28],
      [19, -4],
      [83, 0],
      [-7, 16],
      [24, 44],
      [36, 7],
      [24, -26],
      [9, 13],
      [48, 13],
      [65, -10],
      [20, 52],
      [29, -2],
      [31, 10],
      [35, 51],
      [-14, 19],
      [-42, 9],
      [-32, -3],
      [-15, 6],
      [47, 50],
      [7, 40],
      [-16, 10],
      [7, 38],
      [52, 22],
      [26, 56],
      [35, -2],
      [18, -14],
      [29, 7],
      [19, -20],
      [35, -5],
    ],
    [
      [4134, 2244],
      [11, -28],
      [-4, -24],
      [13, -6],
      [7, -24],
      [-30, -124],
      [-2, -56],
      [-40, -110],
      [-40, -50],
      [-50, -79],
    ],
    [
      [3988, 1733],
      [-1, -19],
    ],
    [
      [2961, 7916],
      [-3, -26],
    ],
    [
      [4519, 3646],
      [78, 3],
      [17, 21],
      [33, 5],
    ],
    [
      [3191, 3024],
      [-2, 50],
      [-6, 14],
      [0, 44],
      [36, 21],
      [-25, 19],
      [-37, -3],
      [-43, 13],
      [-12, 22],
      [40, 5],
      [16, 11],
      [14, 30],
      [-13, 18],
      [7, 33],
      [12, 11],
      [-7, 37],
      [16, 32],
      [-12, 32],
      [-30, 11],
      [1, 27],
      [26, 42],
      [22, 5],
      [-3, 22],
      [21, 1],
      [29, 30],
      [-74, 20],
      [6, 38],
      [28, 9],
      [-12, 19],
      [7, 14],
      [31, 25],
      [13, 22],
      [-16, 16],
      [-3, 44],
      [-17, 46],
    ],
    [
      [3204, 3804],
      [-7, 16],
      [19, 34],
      [5, 35],
      [20, -7],
      [31, 20],
      [-5, 29],
      [18, 6],
      [18, 37],
      [21, 20],
      [-16, 28],
      [-29, 10],
      [-2, 26],
      [29, 17],
      [5, 62],
      [30, 13],
      [33, -23],
      [48, -10],
      [1, 51],
      [13, 13],
      [29, 7],
      [0, 49],
      [-8, 28],
      [20, 8],
      [18, 40],
      [44, -4],
      [110, -21],
      [4, -34],
      [38, -2],
      [3, -23],
      [14, -13],
      [29, -4],
      [29, -23],
      [17, 23],
      [6, 28],
      [74, -38],
      [25, 17],
      [80, 16],
      [17, -20],
      [29, -14],
      [21, -30],
      [-18, -58],
      [8, -16],
      [-29, -24],
      [23, -20],
      [4, -61],
      [-2, -24],
      [28, -6],
      [32, -29],
      [55, 13],
    ],
    [
      [8252, 5769],
      [10, -15],
      [2, -97],
      [-25, -32],
      [3, -30],
    ],
    [
      [8242, 5595],
      [-45, -5],
      [-19, -19],
      [-48, 5],
      [13, -60],
      [-29, -16],
      [-36, -42],
      [11, -69],
      [-36, -37],
      [-32, -14],
      [-26, 18],
      [-10, 48],
      [-20, 38],
      [-25, -21],
      [8, -34],
      [-18, 1],
      [-10, 47],
      [-26, 83],
      [-30, 37],
      [0, 48],
      [22, -1],
      [9, 31],
      [-7, 16],
      [18, 31],
      [27, 1],
      [22, 41],
      [43, -11],
      [31, 15],
      [6, 22],
      [36, -13],
      [-6, 32],
      [34, -13],
      [22, -21],
      [11, 44],
      [-6, 21],
      [16, 15],
      [41, -3],
      [26, 15],
      [-6, 27],
      [27, 0],
    ],
    [
      [4066, 7279],
      [22, -2],
      [39, -22],
      [42, -41],
      [31, -3],
      [33, 22],
      [50, -38],
      [23, -4],
      [47, -35],
      [37, -3],
      [32, -24],
      [36, -6],
      [9, -25],
      [27, -26],
      [4, -21],
      [33, 11],
      [17, -29],
      [53, -27],
      [23, -4],
      [28, -23],
      [37, -21],
      [28, 24],
      [35, -2],
      [18, -19],
      [28, -6],
      [61, -44],
      [25, -12],
      [79, 13],
      [19, -28],
      [0, -43],
      [62, 0],
      [43, -18],
      [45, 2],
      [44, -41],
      [32, 23],
      [-1, 26],
      [71, -2],
      [73, -32],
      [15, -10],
    ],
    [
      [5242, 5860],
      [-36, -9],
      [-4, -15],
      [17, -17],
      [2, -18],
      [-21, -12],
      [9, -15],
      [-18, -46],
      [-8, -9],
    ],
    [
      [5006, 5674],
      [-21, 16],
      [-13, 23],
      [6, 46],
      [13, 23],
      [-1, 27],
      [-12, 17],
      [-3, 43],
      [29, -1],
      [-12, 29],
      [-26, 3],
      [-9, 16],
      [-46, -15],
      [-38, 17],
      [-3, -35],
      [-38, 10],
      [-19, 24],
      [-12, 32],
      [-33, -2],
      [-37, 19],
      [-17, -4],
      [-18, 15],
      [0, 32],
      [-38, 9],
      [-49, 22],
      [-39, 36],
      [-14, -12],
      [-3, -24],
      [-46, 30],
      [-28, -1],
      [-2, -32],
      [-35, -36],
      [-104, -7],
      [5, 44],
      [-18, 20],
      [-23, 4],
      [-27, -29],
      [-32, 11],
      [-25, -15],
      [-10, -16],
      [-44, 48],
      [26, 11],
      [-8, 16],
      [-30, 20],
      [-1, 36],
      [-42, 5],
      [-21, -22],
      [-36, 1],
      [-8, -24],
      [-52, -11],
      [5, -27],
      [-12, -18],
      [-30, 14],
      [-25, -6],
      [-39, 14],
      [-21, -28],
      [-35, 12],
      [-1, 17],
      [17, 23],
      [-35, 30],
      [-25, -7],
      [28, -26],
      [-22, -7],
      [13, -19],
      [-13, -11],
      [-49, -3],
      [-37, 41],
      [-54, -15],
      [-26, 34],
      [47, 45],
      [4, 34],
      [-22, -3],
      [-52, -20],
      [14, -11],
      [-1, -23],
      [-55, 19],
      [-28, -43],
      [25, -43],
      [1, -19],
      [15, -12],
      [-5, -33],
      [14, -20],
      [33, -13],
      [3, -52],
      [-7, -34],
      [44, 13],
      [22, -29],
      [-1, -35],
      [16, -6],
      [-7, -23],
      [-21, -19],
      [-17, -29],
      [-25, -9],
      [-10, 20],
      [-19, -4],
      [-28, 21],
      [-14, 20],
      [-26, 12],
      [-23, -32],
      [-17, -8],
      [-20, 21],
      [12, 14],
      [-32, 22],
      [-4, 35],
      [4, 39],
      [-33, 71],
      [54, 37],
      [-1, 28],
      [40, 24],
      [-20, 13],
      [-18, 46],
      [-13, 11],
      [14, 21],
      [30, 16],
      [-4, 17],
      [24, 16],
      [58, -5],
      [10, 9],
      [40, 8],
      [6, 19],
      [-18, 15],
      [19, 36],
      [44, 53],
      [0, 15],
      [20, 15],
      [0, 24],
      [19, 43],
      [20, 23],
      [-23, 35],
      [4, 14],
      [-21, 16],
      [-5, 44],
      [-41, 6],
      [-18, 20],
      [-33, 12],
      [-51, -14],
      [-62, 23],
      [-8, 14],
      [-52, -11],
    ],
    [
      [3112, 6940],
      [22, 17],
      [25, 0],
      [37, 24],
      [5, 20],
      [-19, 19],
      [-2, 18],
      [22, 28],
      [-35, 80],
      [-33, 25],
    ],
    [
      [3134, 7171],
      [-15, 18],
      [11, 43],
      [-45, 36],
      [-3, 22],
    ],
    [
      [3082, 7290],
      [10, 45],
      [-31, 32],
      [2, 56],
      [8, 15],
      [-23, 72],
      [0, 22],
      [19, 38],
      [13, 61],
      [25, 29],
      [7, 19],
      [44, 22],
      [59, 65],
      [1, 24],
    ],
    [
      [3216, 7790],
      [-4, 7],
    ],
    [
      [3212, 7797],
      [25, 2],
      [26, -24],
      [70, -27],
      [-7, -23],
      [-47, -46],
      [-19, -59],
      [21, -28],
      [-3, -22],
      [27, -15],
      [13, 10],
      [25, -5],
      [10, -15],
      [1, -29],
      [64, 41],
      [23, 9],
      [34, 30],
      [53, -18],
      [9, -30],
      [22, -23],
      [40, -24],
      [71, -17],
      [-15, -24],
      [-45, -24],
      [30, -20],
      [17, -1],
      [14, -28],
      [58, -4],
      [21, -23],
      [49, -38],
      [42, -11],
      [3, -25],
      [42, 11],
      [46, -14],
      [37, 15],
      [22, -28],
      [51, -30],
      [24, 39],
    ],
    [
      [3212, 7797],
      [81, 35],
      [2, 16],
      [-25, 16],
      [15, 11],
      [-30, 42],
      [33, 34],
      [-10, 35],
      [24, 9],
      [-7, 16],
      [34, 35],
      [65, 11],
      [19, 13],
      [29, 1],
      [23, 17],
      [24, 0],
      [34, -28],
      [45, 11],
      [17, -13],
      [50, 3],
      [8, -19],
      [21, -15],
      [40, 5],
      [-31, 47],
    ],
    [
      [3673, 8079],
      [8, 26],
      [17, 6],
      [26, 38],
      [25, -11],
      [41, -47],
      [17, -49],
      [29, -10],
      [10, -27],
      [30, 1],
      [11, -22],
      [20, -10],
      [51, 21],
      [39, -9],
      [21, -29],
      [44, -16],
      [20, -21],
      [20, 11],
      [26, -24],
      [-9, -52],
      [23, -2],
      [87, -38],
      [21, 7],
      [47, -26],
      [28, -27],
      [62, -17],
      [12, -22],
      [-60, -8],
      [2, -14],
      [-38, -27],
      [-28, -29],
      [-37, -12],
      [-6, -23],
      [-22, -20],
      [-28, -1],
      [-13, -22],
      [18, -29],
      [-3, -13],
      [-38, -37],
      [-9, -30],
      [13, -23],
      [-6, -29],
      [-15, -6],
      [6, -24],
      [-28, -4],
      [-18, -20],
      [-6, -29],
      [-18, -22],
      [1, -29],
    ],
    [
      [7144, 4935],
      [11, -28],
      [-23, 2],
      [12, 26],
    ],
    [
      [6857, 4948],
      [31, -29],
      [-7, -36],
      [-26, 18],
      [2, 47],
    ],
    [
      [7066, 4950],
      [9, -41],
      [-17, -1],
      [8, 42],
    ],
    [
      [6894, 4981],
      [11, -21],
      [-20, -12],
      [-13, 17],
      [22, 16],
    ],
    [
      [6828, 4986],
      [12, -43],
      [-6, -31],
      [-13, -9],
      [-26, 8],
      [1, 23],
      [32, 52],
    ],
    [
      [6934, 4995],
      [15, -18],
      [-16, -15],
      [-18, 10],
      [19, 23],
    ],
    [
      [7067, 5000],
      [27, 3],
      [21, -20],
      [-29, -18],
      [-19, 35],
    ],
    [
      [7059, 5033],
      [30, -2],
      [3, -27],
      [-18, -4],
      [-36, 13],
      [21, 20],
    ],
    [
      [7129, 5044],
      [21, -22],
      [-5, -17],
      [-30, 20],
      [14, 19],
    ],
    [
      [7106, 5089],
      [12, -13],
      [-9, -29],
      [12, -51],
      [-21, 12],
      [-10, 40],
      [2, 23],
      [14, 18],
    ],
    [
      [7124, 5092],
      [10, -34],
      [-17, -5],
      [7, 39],
    ],
    [
      [7019, 5096],
      [32, -13],
      [-34, -27],
      [-19, 6],
      [21, 34],
    ],
    [
      [7088, 5208],
      [3, -45],
      [-15, 0],
      [2, 39],
      [10, 6],
    ],
    [
      [7096, 5213],
      [12, -4],
      [15, -41],
      [-25, -7],
      [-11, 19],
      [9, 33],
    ],
    [
      [7041, 6724],
      [40, -13],
      [3, -23],
      [36, -39],
      [35, -7],
      [15, -29],
      [42, 2],
      [23, 12],
      [71, -11],
      [39, -24],
      [74, -14],
    ],
    [
      [7371, 6400],
      [-28, 17],
      [-15, -18],
      [8, -32],
      [-24, -22],
      [-66, 5],
      [-26, 13],
      [-20, 24],
      [-21, 5],
      [-8, 34],
      [-11, 15],
      [-5, 37],
      [-36, 6],
      [6, -41],
      [-27, -3],
      [-57, 8],
      [-18, 9],
      [-7, 24],
      [-39, 16],
      [-61, 52],
      [-12, -35],
      [44, -16],
      [12, -26],
      [-24, -7],
      [-30, -22],
      [-12, -29],
      [-26, -5],
      [-31, -28],
      [6, -28],
      [-19, -18],
      [-11, -24],
      [8, -32],
      [33, -4],
      [20, 8],
      [10, -17],
      [26, -14],
      [25, -37],
      [36, -29],
      [32, -11],
      [41, 5],
      [23, -5],
      [4, -38],
      [15, -13],
      [39, -9],
      [-29, -44],
      [-24, 14],
      [-14, -12],
      [-42, 12],
      [-40, -11],
      [-28, 13],
      [-15, -5],
      [3, -55],
      [-19, -29],
      [-17, -2],
      [-4, -22],
      [-20, 6],
      [-19, 22],
      [-16, -4],
      [-5, -31],
      [-50, -60],
      [30, -40],
      [32, -27],
      [26, -8],
      [25, -21],
      [28, 1],
      [50, -19],
      [53, -8],
      [5, -12],
      [-4, -55],
      [2, -59],
      [-53, -18],
      [-5, -71],
      [28, -12],
      [6, -19],
      [28, -23],
      [6, -37],
      [-22, -25],
      [30, -25],
      [44, 4],
      [16, -13],
      [-31, -31],
      [0, -42],
      [13, -30],
      [20, -11],
      [-16, -36],
      [13, -14],
      [-10, -18],
      [2, -23],
      [-23, -8],
      [-8, -38],
      [5, -22],
      [26, -22],
      [-12, -40],
      [-79, 9],
      [-18, -32],
      [6, -21],
      [-13, -25],
      [1, -20],
      [-25, -18],
      [-38, 16],
      [-11, -20],
      [-25, 5],
      [-33, -30],
      [-18, 38],
      [-3, 26],
      [20, 25],
      [-16, 13],
      [-39, -20],
      [-31, -62],
      [-43, -36],
      [-58, -27],
      [-61, -10],
    ],
    [
      [6606, 4896],
      [-5, 36],
      [-9, 18],
      [-56, 12],
      [-12, 19],
      [-3, 32],
      [-49, -15],
      [-21, -14],
      [-11, 14],
      [12, 27],
      [-6, 17],
      [-20, 14],
      [-52, 10],
      [-5, 13],
      [-26, -4],
      [2, 24],
    ],
    [
      [6345, 5099],
      [24, 1],
      [10, 14],
      [8, 45],
      [-27, 8],
      [8, 17],
      [-11, 33],
      [-36, 1],
      [-5, 26],
      [-30, 18],
      [-24, 4],
      [-21, 18],
      [5, 46],
      [-13, 19],
      [-39, 12],
      [-23, -6],
      [-33, 33],
      [-27, 17],
      [-42, -5],
      [-27, 21],
      [-3, 23],
      [12, 13],
      [-1, 49],
      [27, 1],
      [36, 12],
      [-2, 31],
      [35, -5],
      [5, -22],
      [24, -22],
      [44, 9],
      [2, 26],
      [30, 29],
      [28, 0],
      [63, 22],
      [27, -3],
      [9, 29],
      [27, 34],
      [55, -21],
      [31, -7],
      [-4, 24],
      [30, -3],
      [24, 11],
      [-1, 21],
      [-20, 23],
      [34, 2],
      [37, -17],
      [21, 14],
      [-2, 31],
      [26, -9],
      [3, 24],
      [37, -4],
      [-2, 32],
      [19, 14],
      [20, 31],
      [0, 59],
      [31, 0],
      [9, 33],
      [-4, 20],
      [-21, 5],
      [20, 35],
      [23, 12],
      [-1, 18],
      [-34, 34],
      [-31, 20],
      [5, 40],
    ],
    [
      [6819, 6524],
      [26, 59],
      [-5, 49],
      [-13, 37],
      [-30, 19],
      [-18, 23],
      [8, 36],
    ],
    [
      [6606, 4896],
      [-32, -20],
      [-54, -2],
      [-30, -10],
      [-69, -51],
      [-28, -43],
      [-8, -21],
      [11, -38],
      [36, -60],
      [-2, -22],
      [14, -36],
      [-78, -51],
      [-19, -39],
      [17, -10],
      [1, -18],
      [-60, -33],
      [-28, -20],
      [-34, -37],
      [-21, -35],
      [-47, -18],
      [-63, -17],
      [-51, -8],
      [-69, -22],
      [-118, -55],
      [-47, -30],
      [-42, -37],
      [-68, -45],
      [-29, -30],
      [-13, -24],
    ],
    [
      [5415, 5199],
      [46, -17],
      [-1, -16],
      [20, -16],
      [33, -11],
      [47, 5],
      [26, 21],
      [44, 4],
      [38, -5],
      [20, 10],
      [27, -9],
      [59, 21],
      [18, -62],
      [-27, -45],
      [-1, -14],
      [26, -5],
      [38, -18],
      [52, 37],
      [16, 0],
      [62, -26],
      [65, -13],
      [1, -19],
      [38, 0],
      [17, 13],
      [28, 55],
      [-19, 19],
      [16, 20],
      [2, 26],
      [-24, 24],
      [6, 15],
      [34, 14],
      [11, -17],
      [34, -7],
      [30, -15],
      [22, -26],
      [24, -13],
      [26, 11],
      [51, -34],
      [25, -7],
    ],
    [
      [1716, 4408],
      [-18, -17],
      [-55, 15],
      [-11, 31],
    ],
    [
      [1632, 4437],
      [-9, 27],
      [34, 0],
      [4, 11],
      [42, -5],
      [-26, -27],
      [6, -23],
      [21, 14],
      [12, -26],
    ],
    [
      [1578, 4491],
      [14, 33],
    ],
    [
      [1592, 4524],
      [18, -18],
      [-5, -17],
      [-27, 2],
    ],
    [
      [2028, 2934],
      [45, 1],
      [0, -54],
      [12, -36],
      [13, -5],
      [5, -24],
      [-23, -6],
      [16, -30],
      [-14, -27],
      [7, -21],
      [-21, -30],
      [-19, 2],
      [-31, -20],
    ],
    [
      [2018, 2684],
      [-16, 6],
      [1, 24],
      [-21, 13],
      [-15, 31],
      [0, 19],
      [-16, 53],
      [-44, 53],
      [-21, 63],
      [-9, 12],
    ],
    [
      [1877, 2958],
      [34, 0],
      [28, 24],
      [27, -18],
      [11, -38],
      [9, -6],
      [42, 14],
    ],
    [
      [56, 5622],
      [21, -27],
      [-25, -41],
      [-18, -7],
      [-8, 24],
      [23, 26],
      [7, 25],
    ],
    [
      [69, 5646],
      [15, -13],
      [-14, -16],
      [-19, 15],
      [18, 14],
    ],
    [
      [117, 5669],
      [16, -11],
      [-21, -19],
      [-12, 11],
      [17, 19],
    ],
    [
      [104, 5676],
      [7, -15],
      [-22, -14],
      [-4, 29],
      [19, 0],
    ],
    [
      [56, 5678],
      [-7, -51],
      [-22, -8],
      [-12, -38],
      [-15, 15],
      [5, 28],
      [24, 35],
      [22, 2],
      [5, 17],
    ],
    [
      [2097, 5376],
      [17, -27],
      [3, -23],
      [28, 1],
      [1, -32],
      [-21, -28],
      [-7, -29],
      [-36, 2],
      [-22, -27],
      [-44, -19],
      [6, -25],
      [46, 2],
      [12, -15],
      [-26, -17],
      [-22, -3],
      [-21, 9],
      [1, -46],
      [16, -3],
      [5, -52],
      [-12, -17],
      [15, -16],
    ],
    [
      [2036, 5011],
      [-42, -16],
      [-67, -31],
      [6, -23],
      [15, -10],
      [-1, -22],
      [-21, -15],
      [12, -34],
      [71, 21],
      [48, -10],
      [29, 10],
      [5, -26],
      [-29, -7],
      [-57, -1],
      [-3, -12],
      [-29, -9],
      [-5, -31],
      [-19, -12],
      [-21, 2],
      [-3, -32],
      [-28, -1],
      [-36, -12],
      [55, -21],
      [19, -23],
      [32, -52],
      [-2, -24],
      [-33, -22],
      [1, -25],
      [-34, -19],
      [-38, 4],
      [-5, 15],
      [-56, 30],
      [-7, -40],
      [14, -3],
      [-2, -30],
      [-21, -37],
      [8, -33],
      [-4, -26],
      [-39, 2],
      [-6, -18],
      [-27, -10],
    ],
    [
      [1632, 4437],
      [-30, 5],
      [-19, -23],
      [-30, -7],
    ],
    [
      [1553, 4412],
      [3, 36],
      [22, 43],
    ],
    [
      [1592, 4524],
      [10, 10],
      [3, 34],
      [-34, 96],
      [-28, 53],
      [5, 26],
      [-17, 5],
      [-25, 52],
      [24, 37],
      [-2, 15],
      [12, 19],
      [-27, 10],
      [5, 19],
      [41, 9],
      [25, 5],
      [-73, 8],
      [-21, -8],
      [-10, 16],
      [12, 28],
      [20, 23],
      [-32, 6],
      [-3, 38],
      [18, 60],
      [18, 13],
      [49, -14],
      [15, 22],
      [-37, -1],
      [-19, 16],
      [-44, -18],
      [-32, 18],
      [-16, -15],
      [-18, -45],
      [-20, -70],
      [1, -55],
      [16, -32],
      [-13, -18],
      [-10, -35],
      [-38, -43],
      [-16, -30],
      [11, -14],
      [-23, -14],
      [-86, -34],
      [-55, -28],
      [-43, -10],
      [-24, -24],
      [-40, -8],
      [-59, -24],
      [-2, -5],
      [-45, -6],
      [-7, -3],
      [-28, 2],
      [-31, -15],
      [-80, 30],
      [-55, 27],
      [-49, 30],
      [-63, 49],
      [-32, 32],
      [-95, 103],
      [-168, 140],
      [-61, 62],
      [-36, 41],
      [-6, 21],
      [24, 41],
      [21, -14],
      [42, 9],
      [-8, -41],
      [19, -13],
      [23, 6],
      [17, 18],
      [27, -5],
      [35, 21],
      [33, -10],
      [28, 15],
      [14, 21],
      [18, -18],
      [28, 14],
      [25, 27],
      [28, 7],
      [49, 2],
      [44, 67],
      [14, 30],
      [28, 27],
      [17, 27],
      [-26, 4],
      [-1, -21],
      [-15, -10],
      [-39, 16],
      [-35, -12],
      [-10, -11],
      [-53, -6],
      [-43, -12],
      [-25, -12],
      [-19, -25],
      [-56, 14],
      [-30, -3],
      [-43, 18],
      [-42, 2],
      [-186, 105],
      [-23, 22],
      [20, 8],
      [0, 25],
      [-13, 19],
      [-36, 31],
      [-4, 53],
      [24, 28],
      [24, 13],
      [14, 34],
      [-34, 6],
      [-7, 29],
      [69, 0],
      [6, 100],
      [20, 15],
      [8, -32],
      [31, 27],
      [22, -26],
      [31, 16],
      [33, -11],
      [39, 14],
      [61, -4],
      [35, 7],
      [49, -39],
      [98, -1],
      [16, 9],
      [15, 32],
      [91, 20],
      [69, 22],
      [-5, -22],
      [5, -33],
      [50, -12],
      [54, 10],
      [-2, 16],
      [39, 23],
      [17, -4],
      [26, 14],
      [-1, 13],
      [-37, 3],
      [-4, 53],
      [37, 27],
    ],
    [
      [3082, 7290],
      [-47, 2],
      [-6, -12],
      [-30, -6],
      [1, -45],
      [-39, -33],
      [16, -19],
      [47, 3],
      [31, -13],
      [20, -29],
      [21, 21],
      [38, 12],
    ],
    [
      [2941, 7963],
      [28, -12],
      [17, 9],
      [38, -52],
      [30, -3],
      [18, -14],
      [1, -30],
      [-15, -10],
      [-1, 0],
      [31, -20],
      [0, -3],
      [38, -15],
      [12, 3],
      [30, -19],
      [20, 4],
      [28, -11],
    ],
    [
      [3494, 8498],
      [19, -24],
      [28, -13],
      [-20, -27],
      [1, -19],
      [44, -20],
      [-6, -20],
      [55, -51],
      [12, -1],
      [-28, -68],
      [15, -34],
      [32, -21],
      [-10, -17],
      [-26, -11],
      [25, -30],
      [11, -40],
      [27, -23],
    ],
    [
      [2634, 8514],
      [18, 23],
      [-4, 37],
      [-43, 45],
      [28, 12],
      [23, -15],
      [28, 11],
      [26, 29],
      [50, 9],
      [13, 24],
      [37, 26],
      [29, -1],
      [27, 9],
      [27, -15],
      [59, 3],
      [27, -19],
      [14, -27],
      [27, -15],
      [48, -3],
      [7, -22],
      [24, -6],
      [32, -24],
      [20, 19],
      [26, -6],
      [43, 25],
      [45, 11],
      [27, -22],
      [-10, -13],
      [34, -16],
      [19, -20],
      [-5, -21],
      [25, -33],
      [20, 0],
      [20, 23],
      [31, 0],
      [39, 16],
      [31, -29],
      [-31, -15],
      [4, -34],
      [25, 18],
    ],
    [
      [2446, 8437],
      [-27, 8],
      [-24, 18],
      [-20, -5],
      [-7, 22],
      [-27, 7],
      [-29, -16],
      [-30, 13],
      [-26, -5],
      [-35, 6],
      [-10, 24],
      [-1, 46],
      [-11, 19],
      [-25, -4],
      [-31, 13],
      [-24, -9],
      [-29, 22],
      [-41, 20],
      [-42, 9],
      [-34, 25],
      [-46, 6],
      [-6, -11],
      [-40, 38],
      [-19, 0],
      [9, 39],
      [-18, 15],
      [-16, 39],
      [20, 69],
      [-19, 32],
      [10, 26],
      [-12, 10],
      [10, 38],
      [-24, 35],
      [-9, 46],
      [1, 25],
      [-23, 42],
      [-9, 4],
      [17, 70],
      [58, 4],
      [25, 19],
      [-7, 16],
      [35, 35],
      [43, 8],
      [45, 19],
      [15, 20],
      [-3, 36],
      [13, 32],
      [-20, 0],
      [-44, 22],
      [-47, 10],
      [-18, 12],
      [-9, 26],
      [13, 23],
      [20, 11],
      [-4, 21],
      [-50, 15],
      [-28, -5],
      [-26, 7],
      [-25, -4],
      [-13, 13],
      [-33, 9],
      [-46, 29],
      [5, 41],
      [-26, 14],
      [-14, -10],
      [-37, -3],
      [-9, 12],
      [-61, -10],
      [-11, -6],
      [-54, 27],
      [21, 37],
      [-22, 15],
      [14, 25],
      [-7, 10],
      [15, 28],
      [23, 0],
      [39, 15],
      [39, 29],
      [-5, 13],
      [63, 33],
      [18, 24],
      [-10, 24],
      [79, 8],
      [42, 12],
      [90, -20],
      [9, 9],
      [60, -1],
      [-13, 27],
      [-47, 14],
      [-5, 27],
      [57, -6],
      [60, -29],
      [32, 13],
      [9, 19],
      [30, -6],
      [58, 34],
      [35, -1],
      [60, 30],
      [7, -17],
      [36, 0],
      [28, -39],
      [35, 23],
      [41, -7],
      [44, 32],
      [27, -17],
      [35, -40],
      [11, -24],
      [47, -15],
      [45, -57],
      [60, -29],
      [50, -8],
      [36, -29],
      [28, -14],
      [33, 4],
      [27, -17],
      [17, -32],
      [74, -25],
      [10, -18],
      [26, -10],
      [-27, -32],
      [28, -34],
      [45, -22],
      [33, -2],
      [30, 6],
      [36, -26],
      [46, 1],
      [27, -26],
      [12, -39],
      [-33, 9],
      [0, -23],
      [25, -5],
      [23, 8],
      [71, -4],
      [35, 11],
      [27, -13],
      [21, 34],
      [27, 4],
      [31, -15],
      [22, 37],
      [24, 5],
      [1, 18],
      [40, 5],
      [11, 16],
      [34, -7],
      [47, 29],
      [50, 1],
      [46, 8],
      [61, -14],
      [21, 11],
      [4, 25],
      [17, 13],
      [50, 1],
      [13, -31],
      [32, -4],
      [78, -27],
      [42, -6],
      [45, 18],
      [39, -53],
      [42, -11],
      [20, -13],
      [12, -43],
      [-16, -28],
      [-19, -53],
      [-15, -20],
      [-5, -59],
      [-33, -53],
      [-1, -37],
      [-43, -11],
      [-29, 2],
      [-30, -22],
      [-5, -22],
      [12, -23],
      [-73, 2],
      [-24, -6],
      [17, -20],
      [9, -57],
      [-31, -20],
      [0, -14],
      [-41, -34],
      [-61, 6],
      [-39, -10],
      [-23, 10],
      [-26, -9],
      [-12, -16],
      [40, -66],
      [0, -23],
      [24, -9],
      [5, -21],
      [-40, -1],
      [-24, 7],
      [7, -35],
      [-2, -31],
      [20, -34],
      [36, -12],
      [49, 2],
      [73, -13],
      [-5, -23],
      [-21, -35],
      [2, -37],
      [27, -17],
      [38, -45],
      [18, -15],
      [-61, -68],
      [-37, 20],
      [-50, -36],
      [-27, -33],
      [-53, -5],
      [-46, 23],
      [-17, 45],
      [6, 14],
      [-15, 21],
      [-30, -9],
      [-15, -19],
      [-28, 4],
      [-47, -27],
    ],
    [
      [2820, 1619],
      [-6, 30],
      [-24, -7],
      [-21, 24],
      [-19, -1],
      [-12, 21],
      [-26, -4],
      [-1, 39],
      [-45, -15],
      [-34, 5],
      [-18, 15],
      [-10, 29],
      [-27, -4],
      [-28, 27],
      [-20, 2],
      [-33, 45],
      [-18, 2],
      [-18, 31],
      [-3, 32],
      [-22, 5],
      [-12, 18],
      [-19, 7],
      [-5, 17],
      [-30, 16],
      [-23, -5],
      [-15, 18],
      [1, 23],
      [-49, -10],
    ],
    [
      [2283, 1979],
      [-19, 45],
      [-20, 96],
      [-12, 34],
      [-14, 103],
      [-20, 77],
      [-16, 38],
      [-18, 13],
      [-24, 96],
      [-11, 10],
      [-5, 32],
      [-41, 70],
      [-5, 34],
      [-21, 3],
      [-32, 18],
      [6, 15],
      [-13, 21],
    ],
    [
      [2028, 2934],
      [15, 32],
      [42, -3],
      [21, 8],
      [12, 27],
      [-6, 10],
      [23, 25],
      [18, 45],
      [7, 44],
      [-55, 23],
      [-9, 20],
      [5, 33],
      [-21, 22],
      [75, 36],
      [20, 1],
      [7, -26],
      [71, 64],
      [47, 13],
      [-1, 23],
      [31, 30],
      [31, -9],
      [47, -26],
      [18, 11],
      [1, 26],
      [20, 0],
      [43, 11],
      [8, -14],
      [27, 6],
      [25, -4],
      [9, 11],
      [1, 43],
      [-14, 15],
      [9, 37],
      [-18, 10],
      [10, 58],
      [61, -36],
      [3, 17],
      [25, -5],
      [13, -27],
      [65, 14],
      [14, -8],
      [23, 7],
      [16, -10],
      [36, -7],
      [-6, 21],
      [-11, 73],
      [29, 3],
      [24, 21],
      [13, 30],
      [28, -1],
      [30, -17],
      [55, 64],
      [21, 13],
      [1, 31],
      [12, 2],
      [1, 48],
      [35, -11],
      [28, 19],
      [23, 23],
      [12, 43],
      [25, 10],
      [30, -14],
      [3, -32],
      [19, -13],
      [29, 10],
    ],
    [
      [3049, 506],
      [-34, 28],
      [-37, 41],
      [-95, 117],
      [-21, 12],
      [-10, 34],
      [-55, 118],
      [-9, 23],
      [-16, 82],
      [-4, 45],
      [-12, 53],
      [-14, 27],
      [-12, 46],
      [-10, 14],
      [-12, 44],
      [-68, 142],
      [-24, 104],
      [-13, 22],
      [-20, 59],
      [-21, 31],
      [-19, 8],
      [-29, 72],
      [-3, 4],
      [-49, 51],
      [-18, 11],
      [-29, 40],
      [-16, 1],
      [-38, 89],
      [-37, 66],
      [-41, 89],
    ],
    [
      [2022, 1467],
      [-30, 12],
      [-16, 21],
      [9, 15],
      [33, -18],
      [4, -30],
    ],
    [
      [1250, 1675],
      [-22, 0],
      [-16, 41],
      [18, 14],
      [20, -55],
    ],
    [
      [4269, 4806],
      [-22, -2],
      [-25, 22],
      [-24, 4],
      [-27, 39],
      [-38, 32],
      [-24, 5],
      [-24, -8],
      [-19, -18],
      [-47, -1],
      [-24, -7],
      [-42, 23],
      [-54, -19],
      [-24, 15],
      [-6, 28],
      [-32, 0],
      [-55, 13],
      [-6, -21],
      [-24, 3],
      [-15, -16],
      [-44, 2],
      [-21, -18],
      [6, -25],
      [-58, 1],
      [-13, -8],
      [-35, 4],
      [-39, 17],
      [-24, -8],
      [-5, 32],
      [-22, -8],
      [-61, -6],
      [-4, -20],
      [-37, -20],
      [-56, -20],
      [-36, 4],
      [-43, -6],
      [-38, 9],
      [-41, 24],
      [-5, 23],
      [28, 4],
      [21, -8],
      [-3, 46],
      [-21, 26],
      [-75, 4],
      [-24, -22],
      [-48, 9],
      [-60, -40],
      [-34, -1],
      [-11, -24],
      [10, -11],
      [-20, -27],
      [-37, -26],
      [12, -16],
      [-13, -29],
      [-45, 2],
      [-13, -28],
      [-26, -12],
      [-66, 4],
      [-9, 56],
      [-21, 38],
      [-15, -7],
      [-29, 13],
      [-47, -3],
      [-73, 3],
      [-25, -7],
      [-27, 7],
      [-29, -3],
      [-56, 9],
      [-39, 19],
      [-17, 32],
      [-67, 22],
      [-13, -7],
      [-81, 18],
      [-26, 19],
      [-3, 28],
      [8, 33],
      [-48, 37],
      [-33, -28],
      [-29, -3],
      [-21, 9],
    ],
    [
      [1877, 2958],
      [-16, 43],
      [-14, 17],
      [-35, 22],
      [-4, 40],
      [-30, 86],
      [-25, 60],
      [13, 27],
      [-16, 40],
      [4, 18],
      [-12, 32],
      [4, 27],
      [-4, 45],
      [-21, 54],
      [7, 14],
      [-18, 21],
      [-2, 41],
      [-13, 14],
      [-10, 47],
      [8, 9],
      [-22, 52],
      [-14, 20],
      [-8, 36],
      [-14, 27],
      [2, 23],
      [-15, 27],
      [-14, 59],
      [6, 39],
      [-14, 14],
      [-5, 24],
      [4, 37],
      [-18, 29],
      [2, 76],
      [-11, 8],
      [-1, 50],
      [-9, 25],
      [-3, 37],
      [-13, 22],
      [4, 22],
      [-16, 31],
      [4, 26],
      [-14, 15],
      [30, 23],
      [-10, 57],
      [9, 18],
    ],
    [
      [9068, 6166],
      [-28, -57],
      [1, -31],
      [49, -19],
      [-1, -37],
      [-17, -41],
      [-18, -17],
      [-10, -42],
      [-21, -2],
      [-10, -32],
      [-19, -14],
      [-70, -134],
      [-6, -41],
      [-26, -38],
      [-2, -25],
      [-49, 27],
      [-42, 7],
      [-30, -7],
      [-16, 23],
      [-50, 2],
      [-2, -12],
      [-47, -1],
      [-19, 36],
      [-27, -8],
    ],
    [
      [8608, 5703],
      [-71, 3],
      [-49, 22],
      [13, 29],
      [4, 33],
      [-12, 28],
    ],
    [
      [8655, 6117],
      [10, -19],
      [36, -17],
      [27, 43],
      [32, 31],
      [19, 45],
      [44, -2],
      [16, 6],
      [28, -14],
      [33, -1],
      [11, -11],
      [28, -1],
      [42, 20],
      [16, 22],
      [42, 22],
      [-14, -34],
      [1, -24],
      [42, -17],
    ],
    [
      [8299, 6026],
      [-69, 22],
      [-53, 29],
      [-112, -4],
      [-36, -16],
      [-13, 16],
      [-38, -13],
      [-27, 13],
      [-24, 0],
      [-27, 11],
      [-69, -5],
      [-53, -10],
      [-74, 0],
      [-62, 2],
      [-23, -8],
      [-39, 16],
      [-75, 11],
      [-70, 27],
      [-13, -6],
      [-19, 27],
      [14, 35],
    ],
    [
      [8608, 5703],
      [0, -24],
      [21, -17],
      [0, -35],
      [11, -80],
      [-8, -24],
      [-10, -58],
      [5, -36],
      [-12, -48],
      [-19, -23],
      [-17, 0],
      [-16, 16],
      [-25, -3],
      [10, -44],
      [-22, -34],
      [0, -36],
      [16, -38],
      [-10, -10],
      [6, -27],
      [19, -15],
      [4, -49],
      [-17, -10],
      [4, -22],
      [-38, 7],
      [-2, -21],
      [-16, -28],
      [-36, -14],
      [-26, 28],
      [-38, 19],
      [-6, -37],
      [-30, -10],
      [3, 38],
      [-9, 62],
      [-9, 34],
      [-12, 94],
      [-22, 38],
      [1, 19],
      [-29, 20],
      [7, 38],
      [-13, 61],
      [12, 9],
      [-5, 36],
      [-15, 31],
      [-23, 85],
    ],
    [
      [9259, 6570],
      [-28, -22],
      [-1, -13],
      [-29, -37],
      [23, -26],
      [-3, -95],
      [20, -6],
      [-27, -38],
      [-27, -16],
      [-4, -16],
      [12, -36],
      [-40, -32],
      [-5, -16],
      [-29, -22],
      [-8, -18],
      [-45, -11],
    ],
  ],
  transform: {
    scale: [0.0029221772689268836, 0.0028752730004000387],
    translate: [68.14340254000012, 6.74555084800015],
  },
};
