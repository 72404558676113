import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { toUpper } from 'lodash';
import { useEffect, useState } from 'react';
import { BORDER_GRAY, GREEN, GREY } from '../../../../../constants/style';
import { INewPLanTypeProps, IPLanTypeProps } from '../../../../types/plan';
import { IPlanListProps } from '../MarkupFee/MarkupFee';
import PlanPoliciesCard from '../PlanPoliciesCard';
import ListOfWalletConfig from './ListOfWalletConfig';
import NumberOfWallet from './components/NumberOfWallet';
import { NoResults } from '../../../../../components/common';
import { useClientAuth } from '../../../../../providers/ClientProvider';

const DEFUALT_PLAN = {
  clientId: '0b9fafc9-0f42-4fb9-89d4-10dccd80c643',
  planName: '',
  planType: 'WALLET_CONFIGURATION_PLAN',
  planDescription: 'planDescription',
  isDefault: true,
  effectiveStartTime: '',
  effectiveEndTime: '',
  status: 'ACTIVE',
  waterfallAllowed: false,
} as IPLanTypeProps;

const DEFUALT_PLAN2 = {
  policy_type: 'WALLET_CONFIGURATION_PLAN',
  policy_name: '',
  policy_description: 'planDescription',
  is_default: true,
  start_date: '2023-04-04T07:13:19.418147Z',
  end_date: '2023-04-04T07:13:19.418147Z',
  status: 'ENABLED',
  waterfall_allowed: false,
} as INewPLanTypeProps;

export interface ICurrencyProps {
  currency: string;
  index?: number;
  priority?: number;
}

const WalletConfiguration = ({
  onCreate,
  plans,
  page,
  count,
  onWalletPageChange,
}: IPlanListProps) => {
  const { clientId } = useClientAuth();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [planData, setPlanData] = useState<INewPLanTypeProps>({
    ...DEFUALT_PLAN2,
  });
  const [walletData, setWalletData] = useState<ICurrencyProps[]>([]);

  const handleChange = (name: string, value: any) => {
    setPlanData({ ...planData, [name]: value });
    if (value.length) {
      setErrorMsg('');
    }
  };
  const onCreateWalletList = () => {
    const wallet_list = walletData
      .filter((currency: ICurrencyProps) => currency.currency !== '')
      .map((currency: ICurrencyProps, priority: number) => ({
        currency: toUpper(currency.currency),
        priority,
      }));
    if (planData.policy_name.length) {
      onCreate({ ...planData, wallet_list, clientId });
      setPlanData({ ...DEFUALT_PLAN2 });
    } else {
      setErrorMsg('Please enter plan name');
    }
  };

  const getWalletConfig = () => {};

  useEffect(() => {
    getWalletConfig();
  }, []);

  return (
    <>
      <PlanPoliciesCard
        title="WALLET_CONFIGURATION_PLAN"
        plan={planData}
        handleChange={handleChange}
        disabled={disabled}
        errorMsg={errorMsg}
        onCreate={onCreateWalletList}
      >
        <>
          <Box sx={{ borderTop: BORDER_GRAY }}>
            <NumberOfWallet
              setState={setWalletData}
              state={walletData}
              setDisabled={setDisabled}
            />
          </Box>
          <Grid container justifyContent={'end'}>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  sx={{
                    color: GREY.dark,
                    '&.Mui-checked': {
                      color: GREEN.dark,
                    },
                  }}
                  onChange={(e: any) =>
                    handleChange('waterfall_allowed', e.target.checked)
                  }
                />
                <Box>
                  <Typography fontWeight={'bold'}>Allow Waterfall</Typography>
                  <Typography className="font-aspekta-500" variant="body2">
                    In case the txn amount is greater than the default wallet
                    balance the remaining amount will be converted into txn
                    currency and consumed in the transaction
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </>
      </PlanPoliciesCard>
      {plans.length ? (
        <ListOfWalletConfig
          data={plans}
          onWalletPageChange={onWalletPageChange}
          page={page}
          count={count}
        />
      ) : (
        <NoResults text="You have no Wallet Configuration" />
      )}
    </>
  );
};

export default WalletConfiguration;
