import { Typography } from '@mui/material';
import { GREY } from '../../constants/style';
import { VegaCard } from '../common';
import VegaHorizontalSelector from '../common/VegaHorizontalSelector';
import Vega_HeaderWrapper from '../common/v3/Vega_HeaderWrapper';

function OperationsSelction({ data, setActiveState, active }: any) {
  return (
    <VegaCard>
      <Vega_HeaderWrapper
        text="Operations"
        subText="Manage All customer Requests here"
      />

      <VegaHorizontalSelector
        hideScroll
        data={data}
        selected={active}
        onChange={setActiveState}
      />
    </VegaCard>
  );
}

export default OperationsSelction;
