import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { listBinsByIds } from '../../../api/bin';
import { ORANGE } from '../../../constants/style';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { BinCharterRoute } from '../../../routes';
import { NewCardBin } from '../../../types/card';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import BinDetailsComponent from './BinDetailsComponent';
import BinSummary from './BinSummary';
import ListOfBinProgram from './ListOfBinProgram';
import ViewPastActivities from './ViewPastActivities';

export interface IBinProps {
  key: string;
  value: string | any;
}

const BinDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setIsBinDetails, selecteddBinId, getBinDetails, navigateTo } =
    useBinCharter();
  const { setSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [binData, setBinData] = useState<NewCardBin[]>([]);

  const getBinData = async (binId: number) => {
    try {
      const bin = await listBinsByIds([binId]);

      setBinData(bin);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selecteddBinId) {
      setLoading(true);
      getBinData(selecteddBinId);
    }
  }, [selecteddBinId]);

  return (
    <>
      <Box
        sx={{
          borderBottom: `1px solid #EEEEEE`,
          bgcolor: 'white',
          px: 3,
          py: 1.5,
        }}
      >
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Typography
            className="font-aspekta-500"
            fontSize={12}
            color={'#333333'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => navigateTo(BinCharterRoute.BIN_CONFIGURATION)}
          >
            Bin Charter
          </Typography>
          <ArrowForwardIos sx={{ fontSize: 12, color: ORANGE.dark }} />
          <Typography
            className="font-aspekta-500"
            fontSize={12}
            color={'#333333'}
          >
            Bin Details
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          borderBottom: `1px solid #EEEEEE`,
          bgcolor: 'white',
          px: 3,
          py: 2,
        }}
      >
        <BinDetailsComponent loading={loading} binData={binData} />
      </Box>
      <Box
        mx={3}
        mt={3}
        sx={{
          bgcolor: 'white',
          borderRadius: '12px',
          px: 2,
          py: 1.5,
        }}
      >
        <BinSummary loading={loading} binData={binData} />
      </Box>
      <Box mx={3}>
        <ListOfBinProgram />
      </Box>
      <Box mx={3} mt={3}>
        <ViewPastActivities />
      </Box>
    </>
  );
};

export default BinDetails;
