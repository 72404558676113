import { Box, Stack, Grid, styled, BoxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import VegaText from '../../common/VegaText';
import { DateBox } from './DateBox';

type Props = {
  selectedDates: number[];
  onDateSelected?: (date: number) => void;
  onMonthChange?: (value: number) => void;
  month: number;
  year: number;
  disabled?: boolean;
};

export const Calendar = ({
  selectedDates: initialSelectedDate,
  onDateSelected,
  month,
  year,
  onMonthChange,
  disabled = false,
}: Props) => {
  const [selectedDates, setSelectedDates] = useState<number[]>([]);

  const getDaysForMonth = (data: { year: number; month: number }) => {
    const calendarDays = [];

    const daysInMonth = new Date(data.year, data.month, 0).getDate();

    for (let i = 1; i <= daysInMonth; i++) {
      calendarDays.push(i);
    }

    return calendarDays;
  };

  const isSelected = (value: number) => selectedDates.includes(value);

  function onDateClick(value: number) {
    if (disabled) return;
    setSelectedDates((prev) => [...prev, value]);
    onDateSelected?.(value);
  }

  useEffect(() => {
    setSelectedDates(initialSelectedDate);
  }, [initialSelectedDate]);

  return (
    <Box>
      <RootContainer>
        <Stack direction={'row'} spacing={2} alignItems="center">
          <VegaSelectV2
            options={getMonthOptions()}
            onChange={(e) => onMonthChange?.(e.target.value as number)}
            value={month ?? ''}
          />
          <VegaText text="2023" />
        </Stack>
      </RootContainer>
      <CalendarContainer container>
        {getDaysForMonth({ year: year, month: month }).map((item, index) => {
          return (
            <Grid item key={index} xs={2}>
              <DateBox
                disabled={disabled}
                selected={isSelected(item)}
                onClick={() => onDateClick(item)}
              >
                <VegaTextV2 text={item.toString()} textAlign="center" />
              </DateBox>
            </Grid>
          );
        })}
      </CalendarContainer>
    </Box>
  );
};

const getMonthOptions = () =>
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item.toString(),
      label: item.toString(),
      value: item.toString(),
    };
    return option;
  });

const CalendarContainer = styled(Grid)(({ theme }) => {
  return {
    padding: '20px',
    border: '1px solid rgba(209, 209, 209, 1)',
    borderCollapse: 'collapse',
    borderTop: 'none',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    maxHeight: '420px',
    minHeight: '420px',
  };
});

const RootContainer = styled(Box)(({ theme }) => {
  return {
    backgroundColor: 'rgba(239, 242, 247, 1)',
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    justifyContent: 'center',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    border: '1px solid rgba(209, 209, 209, 1)',
    borderCollapse: 'collapse',
    borderBottom: 'none',
  };
});
