import './Styles/__styles.css';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  styled,
} from '@mui/material';
import _ from 'lodash';
import VegaTextV2 from './VegaTextV2';
import { height } from '@mui/system';
import VegaCheckBoxV2 from './VegaCheckBoxV2';

export interface VegaCheckboxOption {
  id: string;
  label: string;
  value: string;
}

interface VegaCheckboxGroupProps {
  options: VegaCheckboxOption[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
}

function VegaCheckboxGroup({
  options,
  selectedValues,
  onChange,
}: VegaCheckboxGroupProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      onChange([...selectedValues, value]);
    } else {
      onChange(
        selectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  return (
    <FormGroup>
      <Grid container>
        {options.map((option) => (
          <Grid item key={option.value}>
            <FormControlLabel
              key={option.value}
              control={
                <VegaCheckBoxV2
                  checked={selectedValues.includes(option.value)}
                  onChange={handleChange}
                  value={option.value}
                />
              }
              label={
                <VegaTextV2
                  text={option.label}
                  color="rgba(123, 134, 148, 1)"
                  fontWeight={500}
                  fontSize={'.8125rem'}
                  lineHeight={'0.975rem'}
                />
              }
              style={{
                fontFamily: 'Aspekta !important',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
}

export default VegaCheckboxGroup;
