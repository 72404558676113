import React from 'react';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Typography,
  Pagination,
} from '@mui/material';
import { IPaginationProps } from '../../../pages/MCCPolicies';
import {
  BORDER_GRAY,
  GREEN,
  ORANGE,
  YELLOW,
} from '../../../../../constants/style';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { getValue } from '../MarkupFee/ListOfMarkupFees';
import { VegaCard } from '../../../../../components/common';
import VegaLabel from '../../../../../components/common/VegaLabel';
import { IChargeRateRule, IOpenCreditCharge } from '../../../../types/plan';

interface IProps extends IPaginationProps {
  data: IOpenCreditCharge[];
}

interface IColumnProps {
  key: keyof IOpenCreditCharge | keyof IChargeRateRule;
  header: string;
  bgColor: string;
}

const columnArr = [
  { key: 'planName', header: 'Plan Name', bgColor: YELLOW.lightest },
  {
    key: 'roundingMethod',
    header: 'Round Method',
    bgColor: ORANGE.lightest,
  },
  {
    key: 'dayCountMethod',
    header: 'Day Count Method',
    bgColor: ORANGE.lightest,
  },
  {
    key: 'interest_rate_value_type',
    header: 'Interest Rate Type',
    bgColor: ORANGE.lightest,
  },
  {
    key: 'default_rate',
    header: 'Default Rate',
    bgColor: ORANGE.lightest,
  },
] as IColumnProps[];

const ListOfOpenCreditCharge = ({
  data,
  page,
  count,
  onPageChange,
}: IProps) => {
  const columns = (column: IColumnProps, index: number) => {
    return (
      <Grid
        item
        sm={2.4}
        sx={{
          p: 2,
          ...(columnArr.length - 1 !== index && {
            borderRight: BORDER_GRAY,
          }),
          bgcolor: column.bgColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          {column.header}
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      <VegaCard fullHeight noPad>
        <Grid container mt={6}>
          {columnArr.map((col: IColumnProps, index: number) => {
            return columns(col, index);
          })}
        </Grid>
        <Grid container>
          {data.map((value: IOpenCreditCharge, index: number) =>
            columnArr.map((col: IColumnProps, inx: number) => {
              if (
                col.key === 'interest_rate_value_type' ||
                col.key === 'default_rate'
              ) {
                return (
                  <Grid
                    item
                    sm={2.4}
                    sx={{
                      py: 1,
                      ...(columnArr.length - 1 !== inx && {
                        borderRight: BORDER_GRAY,
                      }),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // ...(index !== data.length - 1 && {
                      // }),
                      borderBottom: BORDER_GRAY,
                    }}
                  >
                    <Typography className="font-aspekta-500" variant="body2">
                      {/* {typeof value.chargeRateValue[col.key] === 'string'
                        ? toLowerCase(value.chargeRateValue[col.key])
                        : value.chargeRateValue[col.key]} */}
                      {value?.chargeRateValue?.[col?.key] ?? ''}
                    </Typography>
                  </Grid>
                );
              } else {
                return (
                  <Grid
                    item
                    sm={2.4}
                    sx={{
                      py: 1,
                      borderRight: BORDER_GRAY,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // ...(index !== data.length - 1 && {
                      // }),
                      borderBottom: BORDER_GRAY,
                    }}
                  >
                    <Typography className="font-aspekta-500" variant="body2">
                      {typeof value[col.key] === 'string'
                        ? toLowerCase(value[col.key])
                        : value[col.key]}
                    </Typography>
                  </Grid>
                );
              }
            })
          )}
        </Grid>
        <Grid container>
          <Box
            sx={{ p: 2, width: '100%', display: 'flex', justifyContent: 'end' }}
          >
            <Pagination
              color="primary"
              page={page}
              count={count}
              onChange={onPageChange}
            />
          </Box>
        </Grid>
      </VegaCard>
    </>
  );
};

export default ListOfOpenCreditCharge;
