import { useEffect, useMemo, useState } from 'react';

import { listReportCategories } from '../../../api/reports';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { IReportCategory } from '../../../types/reports';
import { VegaPage } from '../../common';
import { VegaHorizontalSelectorDataType } from '../../common/VegaHorizontalSelector';

import VegaSelector from '../../common/VegaSelector';
import { CATEGORIES_DATA } from '../catalog/ReportsIconMap';
import ReportsTable from './ReportsTable';

function ReportsBody() {
  const { selectedProgram } = useProgramData();

  const [selectedCat, setSelectedCat] =
    useState<VegaHorizontalSelectorDataType | null>(null);

  const [reportCategoriesMap, setReportCategoriesMap] = useState<Record<
    string,
    IReportCategory
  > | null>(null);

  const arrOfReportCategories = useMemo(() => {
    let temp: any = [];
    if (reportCategoriesMap) {
      temp = Object.keys(reportCategoriesMap).reduce((pre, key: string) => {
        const curr = reportCategoriesMap[key];
        return [
          ...pre,
          {
            id: curr.categoryId,
            text: curr.reportCategory,
            image: CATEGORIES_DATA[curr.reportCategory]?.icon,
            bgColor: CATEGORIES_DATA[curr.reportCategory]?.color,
          },
        ];
      }, [] as VegaHorizontalSelectorDataType[]);
    }
    setSelectedCat(temp[0]);

    return temp;
  }, [reportCategoriesMap]);

  const onTabChange = (data: VegaHorizontalSelectorDataType) => {
    if (data.id === 'add_catalog') {
      return;
    } else {
      setSelectedCat(data);
    }
  };

  const fetchReportCategories = async () => {
    const programId =
      selectedProgram?.programId || '0d64eb27-037f-458d-b761-fc8bea267547';
    // const programId = '0d64eb27-037f-458d-b761-fc8bea267547';
    const res = await listReportCategories(programId);
    const temp = res.data.reduce(
      (pre: Record<string, IReportCategory>, curr: IReportCategory) => {
        return {
          ...pre,
          [curr.categoryId]: {
            ...curr,
            reportCategory:
              curr.reportCategory === 'CMS'
                ? 'Customer Management System'
                : curr.reportCategory,
          },
        };
      },
      {} as Record<string, IReportCategory>
    );
    setReportCategoriesMap(temp);
  };

  useEffect(() => {
    fetchReportCategories();
  }, [selectedProgram]);

  return (
    <VegaPage gap={0.1}>
      {reportCategoriesMap && arrOfReportCategories.length && (
        <>
          <VegaSelector
            data={arrOfReportCategories}
            setActiveState={onTabChange}
            active={selectedCat}
            header="Reports Catalog"
            subHeader="Create multi-level, flexible reports at ease.."
          />
          {selectedCat && (
            <ReportsTable
              // selectedCategoryId={selectedCat.id}
              selectedCategory={reportCategoriesMap[selectedCat.id]}
              reportCategoriesMap={reportCategoriesMap}
            />
          )}
        </>
      )}
    </VegaPage>
  );
}

export default ReportsBody;
