/** @format */

import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { usePolicy } from '../../providers/PolicyProvider';
import {
  DEFAULT_CHARGES_POLICY,
  DEFAULT_CHARGES_POLICY_CHARGE_RULE,
} from '../../types/defaultPolicy';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import {
  ChargeCodeToRuleType,
  ChargePolicyInterface,
  ChargeRuleInterface,
  PolicyComponentType,
  SlabChargesInterface,
} from '../../types/policy';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import ChargePolicyCard from './ChargePolicyCard';
import PolicyCommentCard from './PolicyCommentCard';
import { PolicyFooterActions } from './PolicyFooterActions';
import { PolicyWrapper } from './PolicyWrapper';
import { CHARGE_CODES_TO_RULE } from '../../constants/policy';

export const DEFAULT_SLAB_CHARGE: SlabChargesInterface = {
  value_type: 'PERCENTAGE',
  value: 4.5,
  start_amount: 0,
  end_amount: 100,
};

export const SLAB_DEFAULT_VALUE_INITIAL: SlabChargesInterface = {
  value_type: 'PERCENTAGE',
  value: 5,
  start_amount: 0,
  end_amount: 0,
};

const KEYS_TO_DELETE_BEFORE_RENDER = [
  'INSTALLMENT_DUE_FEE',
  'BILL_TO_EMI_FEE',
  'TRANSACTION_TO_EMI_FEE',
];

const ChargesPolicy = ({
  initialValue,
  onSubmit,
  isLoading,
  onApprove,
  onReject,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    entityAuthStatuses,
    checkPolicyStatusUpdatable,
    checkPolicyEditable,
    loggedInMakerCheckerUser,
    updateCommentOnPolicy,
    addCommentOnPolicy,
    areAllPoliciesApproved,
  } = usePolicy();
  const [policy, setPolicy] = useState<ChargePolicyInterface | null>(
    DEFAULT_CHARGES_POLICY('Default Charges Policy')
  );
  const [chargeCodeToRule, setChargeCodeToRule] =
    useState<ChargeCodeToRuleType>({ ...CHARGE_CODES_TO_RULE });
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );

  const activationToggle = (code: string) => {
    const current = chargeCodeToRule[code];
    const rule = { ...DEFAULT_CHARGES_POLICY_CHARGE_RULE, charge_code: code };
    setChargeCodeToRule({
      ...chargeCodeToRule,
      [code]: !!current ? null : rule,
    });
  };

  const handleChange = (
    code: string,
    key: keyof ChargeRuleInterface,
    value: any
  ) => {
    const edited = {
      ...chargeCodeToRule[code],
      [key]: value,
    } as ChargeRuleInterface;
    setChargeCodeToRule({ ...chargeCodeToRule, [code]: { ...edited } });
    console.log(chargeCodeToRule);
  };

  const handleSaveClick = () => {
    if (!policy) return;
    const chargeRules: ChargeRuleInterface[] = [];
    const copy = { ...chargeCodeToRule };
    Object.keys(copy).forEach((code) => {
      if (!!copy[code]) {
        chargeRules.push(copy[code] as ChargeRuleInterface);
      }
    });
    onSubmit({ ...policy, charge_rules: chargeRules });
  };

  const getCheck = (
    value: keyof ChargeCodeToRuleType,
    arr: ChargeRuleInterface[]
  ) => {
    return arr.some((val: ChargeRuleInterface) => val.charge_code === value);
  };

  useEffect(() => {
    if (initialValue) {
      setPolicy(initialValue);
      const codeToRuleCopy = { ...chargeCodeToRule };
      initialValue?.charge_rules?.forEach((rule: ChargeRuleInterface) => {
        codeToRuleCopy[rule.charge_code] = rule;
      });
      Object.keys(codeToRuleCopy).map((item: keyof ChargeCodeToRuleType) => {
        const isCheck = getCheck(item, initialValue?.charge_rules);

        if (isCheck) {
          const newVal = initialValue?.charge_rules.find(
            (val: any) => val.charge_code === item
          );

          codeToRuleCopy[item] = newVal;
        } else {
          codeToRuleCopy[item] = null;
        }
      });

      KEYS_TO_DELETE_BEFORE_RENDER.forEach((key) => {
        delete codeToRuleCopy[`${key}`];
      });
      setChargeCodeToRule(codeToRuleCopy);
    }
  }, [initialValue]);

  useEffect(() => {
    getEntityStatusAndCheckIfCanEdit();
  }, [policy, loggedInMakerCheckerUser]);

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = policy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(policyId);
    const canUpdateStatus = await checkPolicyStatusUpdatable(policyId);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  function didClickApprove() {
    if (policy) onApprove(policy);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={policy?.policy_id}
      policyView={
        <>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                noMargin
                onPastCommentClick={() => {
                  onPastCommentsClick(policy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
          />

          {Object.keys(chargeCodeToRule).map((code) => {
            const rule = chargeCodeToRule[code];
            return (
              <ChargePolicyCard
                key={`charge-card-${code}`}
                rule={rule}
                code={code}
                onActivationClick={activationToggle}
                handleChange={handleChange}
                disabled={canEditPolicy == false}
              />
            );
          })}
          <PolicyFooterActions
            role={loggedInMakerCheckerUser?.role}
            policy={initialValue}
            onSaveClick={handleSaveClick}
            onApproveClick={didClickApprove}
            isLoading={isLoading ?? false}
            isSaveDisabled={canUpdatePolicyStatus == false}
            onCommentAddClick={addCommentOnEntity}
            policyEntityAuth={entityAuthStatus}
            onCommentEdit={updateEntityComment}
            onPastCommentsClick={onPastCommentsClick}
          />
        </>
      }
    />
  );
};
export default ChargesPolicy;
