/* eslint-disable react/jsx-pascal-case */
/** @format */
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { allocateBin } from '../api/bin';
import AddProgram from '../components/ClientHome/AddProgram';
import EditProgram from '../components/ClientHome/EditProgram';
import { VegaCard, VegaPage } from '../components/common';
import VegaCenterSelector from '../components/common/VegaCenterSelector';
import VegaDrawer from '../components/common/VegaDrawer';
import VegaHorizontalSelector from '../components/common/VegaHorizontalSelector';
import VegaOutlineButton from '../components/common/VegaOutlineButton';
import VegaTabs from '../components/common/VegaTabs';
import {
  AddPlusIcon,
  AnalyticsIcon,
  CollectionImg,
  CorporateCardIcon,
  CreditCardIcon,
  NavigateIcon,
  NotificationCenterIcon,
  OperationCenterIcon,
  ReportCenterImg,
  SupportCenterImg,
  UserIcon,
} from '../components/icon/Icons';
import { BACKGROUND, GREEN, GREY, NEW_PRIMARY } from '../constants/style';
import { DashboardURLs, getUrl, isDev } from '../constants/url';
import { useProgramData } from '../providers/ProgramDataProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { DASHBOARD_ROUTES, ROUTES, ROUTE_CODE } from '../routes';
import {
  VegaCreateProgramType,
  VegaProgramCategoryType,
  VegaProgramIssuanceType,
  VegaProgramNetworkType,
} from '../types/program';
import Vega_Text from '../components/common/v3/Vega_Text';
import Vega_Button from '../components/common/v3/Vega_Button';
import Vega_HeaderWrapper from '../components/common/v3/Vega_HeaderWrapper';

type Props = {};

export interface INewProgramState {
  text: string;
  cobrandName?: string;
  id: string;
  labelText: string;
  bgColor: string;
  isLabel: boolean;
  image?: JSX.Element;
  date?: number | string;
  status?: string;
  programCode?: string;
}

export interface ICenterData {
  id: string;
  text: string;
  image?: any;
}
const SUPPORTED_PROGRAMS = [
  {
    id: 'corporate-card',
    text: 'Credit Card',
    image: <CorporateCardIcon />,
    bgColor: BACKGROUND.BLUE,
    color: 'white',
  },
  {
    id: 'platinum_cc',
    text: 'Forex Card',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'loan',
    text: 'PPI Wallet',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'loan',
    text: 'Digital Lending',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },

  // {
  //   id: 'add_program',
  //   text: 'Overdraft',
  //   image: <AddPlusIcon />,
  //   bg: GREEN.light,
  // },
];
export const CENTER = [
  {
    id: ROUTES.SUPPORT,
    text: 'Support',
    image: <SupportCenterImg />,
  },
  {
    id: ROUTES.REPORTS,
    text: 'Reports',
    image: <ReportCenterImg />,
  },
  {
    id: ROUTES.COLLECTIONS,
    text: 'Collections',
    image: <CollectionImg />,
  },
  {
    id: 'notifications',
    text: 'Notifications',
    image: <NotificationCenterIcon />,
  },
  {
    id: ROUTES.OPERATIONS,
    text: 'Operations',
    image: <OperationCenterIcon />,
  },
  {
    id: ROUTES.ANALYTICS,
    text: 'Analytics',
    image: <AnalyticsIcon />,
  },
];
const INITIAL_PROGRAM_DATA: VegaCreateProgramType = {
  clientId: '',
  name: '',
  agreement: '',
  tnc: '',
  type: '',
  category: '',
  issuanceType: '',
  programCode: '',
};
export const INITIAL_CENTER = {
  id: '',
  text: '',
  image: '',
};

export interface AddProgramFormState {
  programName: string;
  programCode: string;
  productType: string;
  category?: VegaProgramCategoryType;
  maker?: string;
  checker?: string;
  network?: VegaProgramNetworkType;
  coBrandPartner?: string;
  issuanceType?: VegaProgramIssuanceType;
  bin?: number; // bin id
  termsAndCondition?: File;
  agreement?: File;
}

const initialAddProgramFormState: AddProgramFormState = {
  programName: '',
  productType: '',
  programCode: '',
};

export interface AddProgramHelperFormState {
  programCode?: string;
}

const ClientDashBoard = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { programs } = useProgramData();
  const newProgram: INewProgramState[] = programs
    // .filter((item) => item.status == VegaProgramStatusType.ACTIVE)
    .map((val: any) => {
      return {
        text: val.name,
        cobrandName: val.coBrandPartnerName,
        id: val.programId,
        labelText: val.status,
        isLabel: true,
        bgColor: BACKGROUND.BLUE,
        image: <CreditCardIcon />,
        date: val.createdAt,
        status: val.status,
        programCode: val.programCode,
      };
    });

  const [activeState, setActiveState] = useState<INewProgramState>(
    newProgram[0]
  );
  const [activeSupportProgram, setActiveSupportProgram] = useState<any>(
    SUPPORTED_PROGRAMS[0]
  );
  const [open, setOpen] = useState<boolean>(false);
  const [openProgram, setOpenProgram] = useState<boolean>(false);
  const [selected, setSelected] = useState<ICenterData>({ ...INITIAL_CENTER });
  const { isLoading, addProgram } = useProgramData();
  const [program, setProgram] = useState<VegaCreateProgramType>({
    ...INITIAL_PROGRAM_DATA,
  });

  const [addProgramFormData, setAddProgramFormData] =
    useState<AddProgramFormState>(initialAddProgramFormState);
  const [addProgramHelperFormData, setAddProgramHelperFormData] =
    useState<AddProgramHelperFormState>({});

  const { setSnackbar } = useSnackbar();
  const handleChange = (key: string, value: string | File | null) => {
    setProgram({ ...program, [key]: value });
  };

  const handleAddProgramFormChange = (
    key: string,
    value: string | File | null | undefined,
    isHelper?: boolean
  ) => {
    if (isHelper) {
      const prevState: any = { ...addProgramHelperFormData };
      if (value === null || value === undefined) {
        delete prevState[key];
        setAddProgramHelperFormData(prevState);
      } else {
        setAddProgramHelperFormData((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    } else {
      setAddProgramFormData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setProgram({
      ...INITIAL_PROGRAM_DATA,
    });
    setAddProgramFormData({ ...initialAddProgramFormState });
  };

  async function didClickCreateProgram() {
    const canAddProgram = isRequiredDataToAddProgramPresent();
    if (canAddProgram == false) {
      setSnackbar('Please fill in all required values', 'error');
      return;
    }

    const createdProgram = await createNewProgram();

    setAddProgramFormData(initialAddProgramFormState);
    handleClose();

    // add program to bin
    const binId = Number(createdProgram.binId);
    if (!binId) return;
    await allocateBin(binId, createdProgram.programId, 0);
  }

  function createNewProgram() {
    return addProgram({
      clientId: '', // this is being plugged-in in provider
      name: addProgramFormData.programName,
      programCode: addProgramFormData.programCode || undefined,
      agreement: addProgramFormData.agreement,
      tnc: addProgramFormData.termsAndCondition,
      type: addProgramFormData.productType,
      category: addProgramFormData.category!,
      issuanceType: addProgramFormData.issuanceType!,
      binId: addProgramFormData.bin?.toString(),
      network: addProgramFormData.network,
      makerUserId: addProgramFormData.maker,
      checkerUserId: addProgramFormData.checker,
      coBrandPartnerName: addProgramFormData.coBrandPartner,
    });
  }

  const goToDashboard = (dashboard: DashboardURLs, suffix?: String) => {
    const url = `${getUrl(dashboard)}${suffix ? suffix : ''}`;
    window.open(url);
  };
  const selectedTab = (data: any) => {
    setActiveState(data);
    setOpenProgram(!openProgram);
  };
  const selectedSupportProgram = (data: any) => {
    setActiveSupportProgram(data);
    if (isDev) {
      navigate(`${ROUTE_CODE.CCMS}/${DASHBOARD_ROUTES.SIMULATOR}`);
    }
  };

  const onSelect = (value: ICenterData) => {
    const idToExclude = ['notifications'];
    const id = value?.id;
    if (id && !idToExclude.includes(id)) {
      const serachParam = createSearchParams({ view: id });
      if (isDev) {
        navigate({
          pathname: `${ROUTE_CODE.CCMS}/${DASHBOARD_ROUTES.BACK_OFFICE}`,
          search: `?${serachParam}`,
        });
      } else {
        goToDashboard(
          'BACK_OFFICE',
          `${ROUTE_CODE.CCMS}/${
            DASHBOARD_ROUTES.BACK_OFFICE
          }?${serachParam.toString()}`
        );
      }
    }
    setSelected(value);
  };

  // NOTE: To handle errors can add a library, FORMIK. Will discuss with team to see if we can add a library
  const isRequiredDataToAddProgramPresent = () => {
    const isProgramNamePresent = Boolean(addProgramFormData.programName);
    const isProductTypePresent = Boolean(addProgramFormData.productType);
    const isCategoryPresent = Boolean(addProgramFormData.category);
    const isIssuanceTypePresent = Boolean(addProgramFormData.issuanceType);

    let isCoBrandPartnerPresent = true;
    if (addProgramFormData.issuanceType === VegaProgramIssuanceType.CO_BRAND) {
      isCoBrandPartnerPresent = Boolean(addProgramFormData.coBrandPartner);
    }

    let isBinOrNetworkPresent = true;
    if (addProgramFormData.category != VegaProgramCategoryType.LOANS) {
      isBinOrNetworkPresent = Boolean(addProgramFormData.network);
    }

    const areAllMandatoryFieldsFilled =
      isProgramNamePresent &&
      isProductTypePresent &&
      isCategoryPresent &&
      isIssuanceTypePresent &&
      isCoBrandPartnerPresent &&
      isBinOrNetworkPresent &&
      Object.keys(addProgramHelperFormData).length === 0;

    return areAllMandatoryFieldsFilled;
  };

  return (
    <>
      <VegaPage>
        <VegaCard>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            pb={2}
          >
            <Vega_HeaderWrapper
              text="Programs"
              subText="Create your programs here. Go to Program Centre to edit your programs."
            />

            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Vega_Button
                text="Program Center"
                variant="outlined"
                bgColor="transaparant"
                sx={{
                  py: 0.5,
                  ...(isMobile && { px: 1, width: '100%' }),
                }}
                textColor={NEW_PRIMARY.GRAY}
                startIcon={<NavigateIcon />}
                onClick={() => {
                  if (isDev) {
                    navigate(
                      ROUTE_CODE.CCMS + '/' + DASHBOARD_ROUTES.PROGRAM_DASHBOARD
                    );
                  } else {
                    goToDashboard('PROGRAM');
                  }
                }}
              />
              <Vega_Button
                text="Add Program"
                variant="outlined"
                bgColor="transaparant"
                sx={{
                  py: 0.5,
                  ...(isMobile && { px: 1, width: '100%' }),
                }}
                textColor={NEW_PRIMARY.GRAY}
                startIcon={<AddPlusIcon strokeColor="black" />}
                onClick={() => setOpen(!open)}
              />
            </Stack>
          </Stack>

          {!programs.length ? (
            <VegaTabs
              onChange={() => setOpen(!open)}
              value={'Add Program'}
              id={'Add Program'}
              bg={GREEN.light}
              active={null}
              icon={<AddPlusIcon />}
              isLabel={false}
              activeBg={BACKGROUND.LIGHT_BLUE}
            />
          ) : (
            <VegaHorizontalSelector
              hideScroll
              data={newProgram}
              selected={activeState}
              onChange={selectedTab}
            />
          )}
        </VegaCard>
        <VegaCenterSelector
          data={CENTER}
          isButton
          buttonText="Go to Back Office"
          header="Centers"
          subHeader="Configure world class dashboards for team access for your programs"
          onClick={() => {
            if (isDev) {
              navigate(ROUTE_CODE.CCMS + '/' + DASHBOARD_ROUTES.BACK_OFFICE);
            } else {
              console.log('first');
              goToDashboard('BACK_OFFICE');
            }
          }}
          onSelect={onSelect}
          selected={selected}
        />

        <VegaCard>
          <Vega_HeaderWrapper
            text="Supported Programs"
            subText="Create various categories of products using Vegapay’s DIY program
              dashboards"
          />

          <VegaHorizontalSelector
            hideScroll
            data={SUPPORTED_PROGRAMS}
            selected={activeSupportProgram}
            onChange={selectedSupportProgram}
            ignoreCaseForLodash={['PPI Wallet']}
          />
        </VegaCard>
      </VegaPage>
      <VegaDrawer
        open={open}
        onClose={handleClose}
        anchor="right"
        isSubmit={true}
        isClose={true}
        loading={isLoading}
        onSubmit={didClickCreateProgram}
        submitText="Create"
        closeText="Cancel"
        header="Create Program"
        disableSubmitButton={!isRequiredDataToAddProgramPresent()}
      >
        <AddProgram
          handleChange={handleAddProgramFormChange}
          state={addProgramFormData}
          helperState={addProgramHelperFormData}
        />
      </VegaDrawer>
      <VegaDrawer
        open={openProgram}
        onClose={() => setOpenProgram(!openProgram)}
        anchor="right"
        isClose={true}
        loading={isLoading}
        closeText="Got It!"
        header={`Program name - ${activeState?.text}`}
      >
        <EditProgram state={activeState} handleChange={handleChange} />
      </VegaDrawer>
    </>
  );
};

export default ClientDashBoard;
