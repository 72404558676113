import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import _ from 'lodash';
import { GREY, GREEN, RED } from '../../../constants/style';
import VegaRadio from '../../common/VegaRadio';
import { KycStepStatus, VegaKycStepPartner } from './KYCFlowDataTypes';

export interface IKycServicePartnersProps {
  selectedWorkFlow: string | null;
  onWorkFlowChange: (value: string) => void;
  addPartner: (value: VegaKycStepPartner) => void;
  removePartner: (value: VegaKycStepPartner) => void;
  workFlows: any;
  disabledWorkFlows?: any;
  partners: VegaKycStepPartner[];
}

export const KycServicePartners = ({
  partners,
  onWorkFlowChange,
  selectedWorkFlow,
  addPartner,
  removePartner,
  workFlows,
  disabledWorkFlows,
}: IKycServicePartnersProps) => {
  const linkedPartners = partners.filter((item) => item.linked == true);
  const linkedPartnersExists = linkedPartners.length > 0;

  if (linkedPartnersExists == false) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" style={{ color: RED.dark }}>
          No Partners are linked
        </Typography>
      </div>
    );
  }

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{ color: GREY.lightGray, fontWeight: 400 }}
      >
        Partners
      </Typography>

      {partners.map((item) => {
        return (
          <FormControlLabel
            key={item.name}
            control={
              <Checkbox
                checked={item.status == KycStepStatus.ACTIVE}
                disabled={item.linked == false}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    addPartner(item);
                  } else {
                    removePartner(item);
                  }
                }}
                sx={{
                  color: GREY.dark,
                  '&.Mui-checked': {
                    color: GREEN.dark,
                  },
                }}
              />
            }
            label={_.startCase(_.toLower(item.name))}
            sx={{ fontSize: '14px' }}
          />
        );
      })}
      <Typography
        variant="body1"
        sx={{ color: GREY.lightGray, fontWeight: 400 }}
      >
        Select workflow decisioning
      </Typography>
      <VegaRadio
        name="approval"
        radioData={workFlows}
        onChange={(e: any) => onWorkFlowChange(e.target.value)}
        value={selectedWorkFlow}
        labelPlacement="end"
        color={GREEN.dark}
        disabledRadioOptions={disabledWorkFlows}
      />
    </Box>
  );
};
