import { useEffect, useState } from 'react';
import {
  AddOnCardIcon,
  CardEditIcon,
  SettingIcon,
} from '../../../ui-components/icon/VPIcons';
import VPMenuDrivenContent from '../../../ui-components/common/VPMenuDrivenContent';
import { AddOnCard, BinSetting, CardSetting } from '../containers';
import { VegaProgramWithMetaDataType } from '../../../types/program';
import { getAddOnCardConfiguration } from '../../../api/card';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { setAddOnCardConfig } from '../../../store/common/programManagementSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hook';

type CardDetailsAndDesignProps = {
  program: VegaProgramWithMetaDataType;
};

const listItems = [
  { id: 'binSetting', title: 'Bin Setting', Icon: SettingIcon },
  { id: 'cardSetting', title: 'Card Setting', Icon: CardEditIcon },
  { id: 'addOnCard', title: 'Add on Card', Icon: AddOnCardIcon },
];
const ActiveTab = ({
  id,
  program,
}: {
  id: string;
  program: VegaProgramWithMetaDataType;
}) => {
  switch (id) {
    case 'binSetting':
      return <BinSetting />;

    case 'cardSetting':
      return <CardSetting program={program} />;
    case 'addOnCard':
      return <AddOnCard />;
    default:
      return null;
  }
};
function CardDetailsAndDesign({ program }: CardDetailsAndDesignProps) {
  const [selectedListItem, setSelectedListItem] = useState(listItems[0].id);
  const { setSnackbar } = useSnackbar();
  const { selectedProgram, addonCardConfig } = useAppSelector(
    (state) => state.programManagement
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedProgram) return;
    if (!addonCardConfig) {
      getAddOnCardConfiguration({ programId: selectedProgram.programId })
        .then((res) => {
          dispatch(setAddOnCardConfig(res));
        })
        .catch((err) => {
          const debugMessage = err.response?.data?.apierror?.debugMessage;
          if (
            !debugMessage ||
            debugMessage !== 'No Add on Card Configuration For ProgramId'
          ) {
            setSnackbar('Failed to fetch addon card config!', 'error');
          }
        });
    }
  }, []);

  return (
    <VPMenuDrivenContent
      menuItems={listItems}
      selectedMenuItem={selectedListItem}
      onMenuItemChange={setSelectedListItem}
    >
      <ActiveTab id={selectedListItem} program={program} />
    </VPMenuDrivenContent>
  );
}

export default CardDetailsAndDesign;
