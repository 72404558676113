import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  Radio,
  Stack,
  Typography,
} from '@mui/material';

import { useState } from 'react';
import { BLUE, BORDER_GRAY } from '../../constants/style';
import { EMIPreview, TransactionToEmiPreview } from '../../types/transaction';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaOutlineButton from '../common/VegaOutlineButton';

interface TransactionToEMIPreviewDialogProps {
  show: boolean;
  onSubmit: (option: EMIPreview) => void;
  preview?: TransactionToEmiPreview;
  onClose: () => void;
}

export function TransactionToEMIPreviewDialog({
  show,
  onSubmit,
  preview,
  onClose,
}: TransactionToEMIPreviewDialogProps) {
  const [selectedPlan, setSelectedPlan] = useState<EMIPreview | null>(null);

  function didClickSubmit() {
    if (!selectedPlan) return;
    onSubmit(selectedPlan);
    setSelectedPlan(null);
    onClose();
  }

  const isSelected = (plan: EMIPreview) => {
    if (!selectedPlan) return false;
    let isDurationSame = selectedPlan.durationInMonths == plan.durationInMonths;
    let isAmountSame = selectedPlan.installmentAmount == plan.installmentAmount;
    let isRateSame = selectedPlan.interestRate == plan.interestRate;
    const isSelected = isDurationSame && isAmountSame && isRateSame;
    return isSelected;
  };

  const EMIListItem = (data: { plan: EMIPreview }) => {
    const isPlanSelected = isSelected(data.plan);
    return (
      <Card
        elevation={0}
        style={{
          border: BORDER_GRAY,
          borderRadius: 8,
          marginTop: 4,
          marginBottom: 8,
          padding: 8,
          cursor: 'pointer',
          backgroundColor: isPlanSelected ? BLUE.lightest : '',
        }}
        onClick={() => setSelectedPlan(data.plan)}
      >
        <Stack direction={'row'} spacing={2} alignItems="center">
          <Radio checked={isPlanSelected} />
          <Stack
            direction={'row'}
            style={{ width: '100%', padding: 2 }}
            justifyContent="space-between"
            spacing={14}
          >
            <Stack spacing={0}>
              <Typography>{data.plan.interestRate}% PA</Typography>
              <Typography>{data.plan.durationInMonths} months</Typography>
            </Stack>
            <Typography>{data.plan.installmentAmount} RS - EMIs</Typography>
          </Stack>
        </Stack>
      </Card>
    );
  };

  return (
    <Dialog
      open={show}
      style={{ borderRadius: 24, padding: 12 }}
      onClose={onClose}
    >
      <DialogContent>
        <Typography> Select EMI Plan</Typography>
        <Divider style={{ marginBottom: 24, marginTop: 12 }} />
        <List style={{ overflowY: 'scroll', maxHeight: '400px' }}>
          {preview?.options.map((item) => {
            return <EMIListItem plan={item} />;
          })}
        </List>
        <Divider style={{ marginBottom: 24, marginTop: 12 }} />
        <Stack
          direction={'row'}
          style={{ marginBottom: 12 }}
          justifyContent="end"
        >
          <VegaOutlineButton text={'Cancel'} onClick={onClose} />
          <VegaContainedButton
            text="Submit"
            disabled={selectedPlan == null}
            isPrimary
            onClick={didClickSubmit}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
