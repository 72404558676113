import React from 'react';
import VPDataGrid, {
  type VPDataGridProps,
} from '../../../ui-components/common/VPDataGrid';

function ProgramDataGrid({
  data,
  columns,
  idColumn,
  ...rest
}: VPDataGridProps) {
  return (
    <VPDataGrid
      data={data}
      columns={columns}
      idColumn={idColumn}
      pageSize={100}
      bgColor="transparent"
      getRowClassName={() => 'vegatable--white'}
      disableColumnFilter
      hideFooter
      sx={{
        border: 'none',
        // disable cell selection style
        '.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cellContent': {
          color: '#1B1D22',
          fontSize: '12px',
          fontFamily: `var(--font-Aspekta-500) !important`,
        },
      }}
      wrapperSx={{
        alignItems: 'flex-start',
      }}
      {...rest}
    />
  );
}

export default ProgramDataGrid;
