import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { usePolicy } from '../../providers/PolicyProvider';
import { MakerCheckerUserProps } from '../../providers/MakerCheckerEntityProvider';
import { DRAFT_POLICY_ID } from '../../types/defaultPolicy';
import { UserMakerCheckerRole } from '../../types/entityAuth';
import { VegaCard } from '../common';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';

interface PolicyWrapperProps {
  user?: MakerCheckerUserProps | null;
  policyView: ReactNode;
  policyId?: string;
}

export const PolicyWrapper = ({
  user,
  policyView,
  policyId,
}: PolicyWrapperProps) => {
  const { areAllPoliciesApproved, entityAuthStatuses, controlRole } =
    usePolicy();

  const shouldShowPolicyForChecker = () => {
    if (areAllPoliciesApproved) {
      return true;
    }
    const isControlRoleChecker = controlRole == UserMakerCheckerRole.CHECKER;
    if (isControlRoleChecker) return true;

    if (!policyId) return false;
    if (policyId == DRAFT_POLICY_ID) return false;
    const entityAuth = entityAuthStatuses.get(policyId);
    if (entityAuth) return true;
    return false;
  };

  const shouldShowPolicyForMaker = () => {
    return true;
  };

  const showPolicy = () => {
    const role = user?.role;
    if (!role) return false;
    let isMaker = role == UserMakerCheckerRole.MAKER;
    let isChecker = role == UserMakerCheckerRole.CHECKER;
    if (isMaker) return shouldShowPolicyForMaker();
    else if (isChecker) return shouldShowPolicyForChecker();
    return false;
  };

  return (
    <VegaConditionalWrapper
      condition={showPolicy()}
      ifTrue={policyView}
      ifFalse={
        <VegaCard>
          <Typography textAlign={'center'}>No Policy Configured</Typography>
        </VegaCard>
      }
    />
  );
};
