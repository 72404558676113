import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { VPAccordion } from '../../../ui-components/common';
import Grid from '@mui/material/Grid';
import DueDateCalendar from '../DueDateCalendar';
import { useAppSelector } from '../../../store/hook';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getSettlementCalendar } from '../../../api/program';
import { VegaSettlementCalendarDate } from '../../../types/program';
import { DateUtility } from '../../../utils/dateTime';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

type Props = {};

const ChangedDateListItem = (data: {
  key: string;
  title: string;
  description: string;
}) => {
  return (
    <Box
      key={data.key}
      sx={{
        display: 'inline-flex',
        gap: 0.5,
      }}
    >
      <VegaTextV2
        text={data.title}
        fontSize={'.875rem'}
        lineHeight={'19.6px'}
      />
      <VegaTextV2 text={data.description} variant="caption" />
    </Box>
  );
};

function mapSettlementCalendarToEffectiveDates(
  settlementCalendarDate: VegaSettlementCalendarDate[]
) {
  const selectedDatesMap = new Map<number, number[]>();
  const changedDatesMap = new Map<number, VegaSettlementCalendarDate[]>();
  settlementCalendarDate.forEach((item) => {
    const currentMonth = DateUtility.monthNameToNumber(item.effectiveMonth);
    const actualDate = item.actualDay;
    const actualMonth = item.actualMonth;
    const effectiveDate = item.effectiveDay;
    const effectiveMonth = item.effectiveMonth;
    const existingDates = selectedDatesMap.get(currentMonth) ?? [];
    existingDates.push(item.effectiveDay);
    selectedDatesMap.set(currentMonth, existingDates);

    if (actualDate !== effectiveDate || actualMonth !== effectiveMonth) {
      const existingChangedDates = changedDatesMap.get(currentMonth) ?? [];
      existingChangedDates.push(item);
      changedDatesMap.set(currentMonth, existingChangedDates);
    }
  });

  return { selectedDates: selectedDatesMap, changedDates: changedDatesMap };
}

const selectOptions = [...Array(12)].map((_, index) => {
  const item = `${index + 1}`;
  const option: VegaSelectOptionV2 = {
    id: item,
    value: item,
    label: item,
  };
  return option;
});

function FinalSettlementCalendar({}: Props) {
  const { setSnackbar } = useSnackbar();
  const { selectedProgram } = useAppSelector(
    (state) => state.programManagement
  );
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedDates, setSelectedDates] = useState(
    new Map<number, number[]>()
  );
  const [changedDates, setChangedDates] = useState(
    new Map<number, VegaSettlementCalendarDate[]>()
  );

  useEffect(() => {
    if (!selectedProgram) return;
    getSettlementCalendar({
      programId: selectedProgram.programId,
      financialYear: new Date().getFullYear(),
    })
      .then((res) => {
        const { selectedDates, changedDates } =
          mapSettlementCalendarToEffectiveDates(res);
        setSelectedDates(selectedDates);
        setChangedDates(changedDates);
      })
      .catch((err) => {
        setSnackbar('Failed to fetch settlement calendar', 'error');
      });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Typography className="font-aspekta-600" fontSize={17}>
        Payment Due Date
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <VPAccordion title="Due Dates" expandable={false}>
            <VPAccordion.TailEnd>
              <>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  <VegaSelectV2
                    options={selectOptions}
                    value={selectedMonth}
                    onChange={(e) => {
                      setSelectedMonth(Number(e.target.value));
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 450,
                      fontSize: 13,
                    }}
                  >
                    {new Date().getFullYear()}
                  </Typography>
                </Box>
              </>
            </VPAccordion.TailEnd>
            <VPAccordion.Body>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <DueDateCalendar
                  selectDate={selectedDates.get(selectedMonth) ?? []}
                  month={selectedMonth}
                />
              </Box>
            </VPAccordion.Body>
          </VPAccordion>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,

              borderRadius: '8px',
              bgcolor: '#FAFAFA',
              gap: 2,
            })}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 1.5,
                bgcolor: '#EBEDEF',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
            >
              <Typography
                className="font-aspekta-500"
                sx={{
                  fontSize: 14,
                  color: '#1B1D22',
                }}
              >
                Due Dates
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
              }}
            >
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                {changedDates.get(selectedMonth)?.map((item, index) => (
                  <li key={index}>
                    <ChangedDateListItem
                      key={item.id}
                      title={`${item.actualDay} ${startCase(
                        toLower(item.actualMonth)
                      )} -> ${item.effectiveDay} ${startCase(
                        toLower(item.effectiveMonth)
                      )}`}
                      description={item.reason ? `(${item.reason})` : '-'}
                    />
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FinalSettlementCalendar;
