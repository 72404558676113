import { Grid, InputAdornment } from '@mui/material';
import _ from 'lodash';
import { IterationType } from '../../../../../../../../../types/RewardsLoyalty';
import VegaCardV2 from '../../../../../../../../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../../../../../../common/v2/VegaFormInputField';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../../../../../../../common/v2/VegaTextFieldV2';
import { MoneyIcon } from '../../../../../../../../icon/Icons';
import {
  RewardsInputType,
  useCampaignRule,
} from '../../../../../../../providers/CampaignRuleProvider';

const PointBasedNonTransactionalRewards = () => {
  const { rewardsData, onRuleRewardsDataChange } = useCampaignRule();

  return (
    <VegaCardV2>
      <Grid container alignItems={'end'} spacing={2} columns={4}>
        <Grid item xs={1}>
          <VegaFormInputFieldV2 label="Reward Points">
            <VegaTextFieldV2
              placeholder="Enter Reward Points"
              type={'number'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MoneyIcon />
                  </InputAdornment>
                ),
              }}
              value={
                rewardsData.rewardPoints != null ? rewardsData.rewardPoints : ''
              }
              onChange={(e) => {
                onRuleRewardsDataChange({
                  inputType: RewardsInputType.REWARD_POINTS,
                  value: e.target.value,
                });
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
        <Grid item xs={1}>
          <VegaFormInputFieldV2 label="Iteration">
            <VegaSelectV2
              placeholder="Select Iteration"
              value={rewardsData.iteration ?? ''}
              options={iterationOptions()}
              onChange={(e) => {
                onRuleRewardsDataChange({
                  inputType: RewardsInputType.ITERATION,
                  value: e.target.value as string,
                });
              }}
            />
          </VegaFormInputFieldV2>
        </Grid>
      </Grid>
    </VegaCardV2>
  );
};

export default PointBasedNonTransactionalRewards;

const iterationOptions = () =>
  Object.values(IterationType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
