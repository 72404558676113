import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Box, Stack, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { ChargeBackProvider } from '../../components/ChargeBack/hooks';
import AccountDetailsAction from '../../components/Support_New/ApplcaitonDetails/AccountDetailsAction';
import Bills from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/Bills/Bills';
import CardControls from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/CardControls/CardControls';
import Delinquency from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/Delinquency/Delinquency';
import LoanEmi from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/LonaEmi/LoanEmi';
import PhysicalCard from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/PhysicalCard/PhysicalCard';
import Rewards from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/Rewards/Rewards';
import Summary from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/Summary/Summary';
import TCS from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/TCS/TCS';
import Tickets from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/Tickets/Tickets';
import Transactions from '../../components/Support_New/ApplcaitonDetails/AccountDetailsStep/Transactions/Transactions';
import Vega_Tab from '../../components/common/v3/Vega_Tab';
import Vega_Text from '../../components/common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../constants/style';
import { useSupport } from '../../providers/SupportProvider';
import { SupportRoute } from '../../routes';
import { VP_THEME } from '../../ui-components/theme';

export enum AccountDetailsStep {
  SUMMARY = 'SUMMARY',
  PHYSICAL_CARD = 'PHYSICAL_CARD',
  CARD_CONTROLS = 'CARD_CONTROLS',
  TRANSACTIONS = 'TRANSACTIONS',
  BILLS = 'BILLS',
  REWARDS = 'REWARDS',
  TCS = 'TCS',
  TICKETS = 'TICKETS',
  DELINQUENCY = 'DELINQUENCY',
  LOAN = 'LOAN',
}

export const accountDetailsStepOptions = [
  'SUMMARY',
  'PHYSICAL_CARD',
  'CARD_CONTROLS',
  'TRANSACTIONS',
  'BILLS',
  'REWARDS',
  'TCS',
  'TICKETS',
  'DELINQUENCY',
  'LOAN',
];

const AccountDetails = () => {
  const [selected, setSelected] = useState<string>(AccountDetailsStep.SUMMARY);
  const { navigateTo, elementHeight } = useSupport();

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        sx={{
          height: '40px',
          bgcolor: 'white',
          px: 2.5,
          py: 1.5,
          borderBottom: '1px solid #EEEEEE',
        }}
      >
        <Vega_Text
          text={'Support'}
          fontWeight={400}
          color={'#333333'}
          fontSize={12}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => navigateTo(SupportRoute.SUPPORT)}
        />
        <KeyboardArrowRightOutlined
          sx={{ color: NEW_PRIMARY.BLUE, fontSize: 14 }}
        />
        <Vega_Text
          text={'Account Details'}
          fontWeight={400}
          color={NEW_PRIMARY.BLUE}
          fontSize={12}
        />
      </Stack>
      <AccountDetailsAction />
      <Box
        sx={{
          height: `calc(100vh - (40px + ${elementHeight}px + 28px + 69px))`,

          py: 2,
          px: 2.5,
        }}
      >
        <Vega_Tab
          tabData={accountDetailsStepOptions}
          selected={selected}
          onSelect={(value) => setSelected(value)}
          color={NEW_PRIMARY.BLUE}
        />
        <Box
          sx={{
            height: `calc(100% -  28px )`,
            border: `1px solid #E1E4EB`,
            borderRadius: '12px',
            bgcolor: 'white',
            py: 2,
            px: 2.5,
            mt: 1,
            overflow: 'scroll',
          }}
        >
          {getStepContainer(selected)}
        </Box>
      </Box>
    </>
  );
};

export default AccountDetails;

const getStepContainer = (step: string) => {
  switch (step) {
    case AccountDetailsStep.PHYSICAL_CARD:
      return <PhysicalCard />;
    case AccountDetailsStep.CARD_CONTROLS:
      return <CardControls />;
    case AccountDetailsStep.TRANSACTIONS:
      return (
        <ThemeProvider theme={VP_THEME}>
          <Transactions />
        </ThemeProvider>
      );
    case AccountDetailsStep.BILLS:
      return <Bills />;
    case AccountDetailsStep.REWARDS:
      return <Rewards />;
    case AccountDetailsStep.TCS:
      return <TCS />;
    case AccountDetailsStep.TICKETS:
      return (
        <ChargeBackProvider>
          <ThemeProvider theme={VP_THEME}>
            <Tickets />
          </ThemeProvider>
        </ChargeBackProvider>
      );
    case AccountDetailsStep.DELINQUENCY:
      return <Delinquency />;
    case AccountDetailsStep.LOAN:
      return <LoanEmi />;
    default:
      return <Summary />;
  }
};
