import { Stack, Typography } from '@mui/material';
import {
  ChargebackDto,
  ChargebackStatus,
  UpdateChargebackStatusRequest,
} from '../../types/chargeBack';
import { VPButton } from '../../ui-components/common';
import {
  CancelRequestModal,
  ChangeStatusModal,
  CreateChargebackWithNetworkModal,
  FileArbitrageModal,
  ReInitiateChargebackModal,
  ReopenTicketModal,
} from './Modals';
import ResolutionMenu from './Modals/resolutions/ResolutionMenu';
import ArbritrationResolutions from './Modals/ArbritrationResolutions';
import { ChargebackService } from '../../services/ChargeBackService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useChargeBack } from './hooks';
import { RejectedByNetworkModal } from './Modals/RejectedByNetworkModal';

interface IProps {
  chargeBackDto: ChargebackDto | null;
}
export const ChargeBackModalActions = ({}: IProps) => {
  const { selectedChargeBack, setSelectedChargeBack } = useChargeBack();

  const { setSnackbar } = useSnackbar();

  const withDrawRequestHandle = () => {
    ChargebackService.updateChargebackStatus({
      chargebackId: selectedChargeBack?.id,
      status: ChargebackStatus.CHARGEBACK_CANCELLED,
    })
      .then((res) => {
        setSnackbar('Chargeback status updated successfully', 'success');
        setSelectedChargeBack(res);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(
            err,
            'Error while updating chargeback status'
          ),
          'error'
        );
      });
  };
  const withDrawArbtitPendingHandle = () => {
    ChargebackService.updateChargebackStatus({
      chargebackId: selectedChargeBack?.id,
      status: ChargebackStatus.ARBITRATION_REJECTED,
    })
      .then((res) => {
        setSnackbar('Chargeback status updated successfully', 'success');
        setSelectedChargeBack(res);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(
            err,
            'Error while updating chargeback status'
          ),
          'error'
        );
      });
  };
  const ackLossHandle = () => {
    ChargebackService.updateChargebackStatus({
      chargebackId: selectedChargeBack?.id,
      status: ChargebackStatus.ISSUER_LOST,
    })
      .then((res) => {
        setSnackbar('Successfully acknowledged loss', 'success');
        setSelectedChargeBack(res);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Error while acknowledging loss'),
          'error'
        );
      });
  };

  if (!selectedChargeBack) {
    return <Typography>Chargeback is not defined</Typography>;
  }

  return selectedChargeBack.chargebackStatus ===
    ChargebackStatus.REQUEST_CREATED ? (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <CancelRequestModal />
      <CreateChargebackWithNetworkModal />
    </Stack>
  ) : selectedChargeBack.chargebackStatus ===
    ChargebackStatus.REQUEST_CANCELLED ? (
    <ReopenTicketModal />
  ) : selectedChargeBack.chargebackStatus ===
    ChargebackStatus.CHARGEBACK_CREATED ? (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <ChangeStatusModal
        title="Change Status"
        triggerLabel="Withdraw Request"
        content="Do you really want to withdraw the chargeback request "
        onSubmit={withDrawRequestHandle}
      />
      <RejectedByNetworkModal
        initialStatus={ChargebackStatus.REJECTED_BY_NETWORK}
      />
      <ResolutionMenu />
    </Stack>
  ) : selectedChargeBack.chargebackStatus ===
    ChargebackStatus.FIRST_PRESENTMENT ? (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <ChangeStatusModal
        triggerLabel="Acknowledge Loss"
        onSubmit={ackLossHandle}
      />
      <ReInitiateChargebackModal />
    </Stack>
  ) : selectedChargeBack.chargebackStatus ===
    ChargebackStatus.CHARGEBACK_REINITIATED ? (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <ResolutionMenu />
    </Stack>
  ) : selectedChargeBack.chargebackStatus ===
    ChargebackStatus.ARBITRATION_PENDING ? (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <ChangeStatusModal
        title="Change Status"
        triggerLabel="Withdraw Request"
        content="Do you really want to withdraw the chargeback request "
        onSubmit={withDrawArbtitPendingHandle}
      />
      <RejectedByNetworkModal
        initialStatus={ChargebackStatus.ARBITRATION_REJECTED}
      />
      <ArbritrationResolutions />
    </Stack>
  ) : selectedChargeBack.chargebackStatus ===
    ChargebackStatus.SECOND_PRESENTMENT ? (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <ChangeStatusModal
        triggerLabel="Acknowledge Loss"
        onSubmit={ackLossHandle}
      />
      <FileArbitrageModal />
    </Stack>
  ) : (
    <></>
  );
};

/**
 * CHARGEBACK_ACCEPTED
 * REQUEST CANCELLED
 * REJECTED BY NETWORK
 * ISSUER LOST
 * ARBITRATION ACCEPTED
 * ARBITRATION DECLINED
 */
