/** @format */

import { Box, Collapse, Grid } from '@mui/material';
import {
  ChargeInterface,
  ChargeRuleInterface,
  ChargeRuleInterfaceKeyTypes,
  ChargeRuleInterfaceValueTypes,
} from '../../types/policy';
import VegaSelect from '../common/VegaSelect';
import VegaText from '../common/VegaText';
import { CHARGE_TYPES, TAXATION_TYPES } from './ChargePolicyCard';
import { DEFAULT_SLAB_CHARGE, SLAB_DEFAULT_VALUE_INITIAL } from './ChargesPolicy';
import ChargesSlabChargeSelector from './ChargesSlabChargeSelector';
import OneTimeChargeSelector from './OneTimeChargeSelector';

export type IProps = {
  chargeRule: ChargeRuleInterface;
  code: string;
  disabled?: boolean;
  handleChange: (key: ChargeRuleInterfaceKeyTypes, value: ChargeRuleInterfaceValueTypes) => void;
};

const SlabCharge = ({ handleChange, code, chargeRule, disabled }: IProps) => {
  const handleChargeTypeChange = (newType: string) => {
    let newCharge = { ...chargeRule?.charge, charge_type: newType };
    if (newType === 'SLAB_CHARGES') {
      newCharge = {
        ...newCharge,
        charges: [{ ...DEFAULT_SLAB_CHARGE }],
        default_slab: { ...SLAB_DEFAULT_VALUE_INITIAL },
      };
    }
    handleChange('charge', newCharge);
  };

  const handleChargeChange = (key: keyof ChargeInterface, value: ChargeInterface[keyof ChargeInterface]) => {
    const newCharge = { ...chargeRule?.charge, [key]: value };
    handleChange('charge', newCharge);
  };

  return (
    <Box sx={{ mx: 3 }}>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center' }}>
          <VegaText text="Charge Type" mr={2} />
          <VegaSelect
            options={CHARGE_TYPES}
            selected={chargeRule.charge.charge_type}
            onChange={(e) => handleChargeTypeChange(e)}
            textFieldWidth={200}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center' }}>
          <VegaText text="Tax type" mr={2} />
          <VegaSelect
            options={TAXATION_TYPES}
            selected={chargeRule.taxation_type}
            onChange={(value) => handleChange('taxation_type', value)}
            textFieldWidth={200}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} spacing={2} mb={1}>
        <Grid item xs={12} sm={10}>
          <Collapse in={chargeRule.charge.charge_type === 'SLAB_CHARGES'} mountOnEnter unmountOnExit>
            <ChargesSlabChargeSelector charge={chargeRule.charge} onChange={handleChargeChange} />
          </Collapse>
          <Collapse in={chargeRule.charge.charge_type !== 'SLAB_CHARGES'} mountOnEnter unmountOnExit>
            <OneTimeChargeSelector
              code={code}
              charge={chargeRule.charge}
              onChange={(charge) => handleChange('charge', charge)}
              isDisabled={disabled}
            />
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SlabCharge;
