import { CampaignStatus, CampaignType } from '../../../../types/RewardsLoyalty';
import { Box, Link } from '@mui/material';
import { CampaignRuleProvider } from '../../../../components/RewardsLoyalty/providers/CampaignRuleProvider';
import { useCampaign } from '../../../../components/RewardsLoyalty/providers/CampaignProvider';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import VegaBreadcrumbs from '../../../../components/common/v2/VegaBreadcrumbs';
import CampaignSummaryHeader from './CampaignSummaryHeader';
import CampaignConfigurationTabBar from './CampaignConfigurationTabBar';
import { useEffect, useState } from 'react';
import RedemptionTabPanel from '../RedemptionTabPanel';
import PointAllocationTabPanel from '../PointAllocationTabPanel';
import EditCampaignModal from '../modals/EditCampaignModal';
import NonPointAllocationTabPanel from '../NonPointAllocationTabPanel';
import { useRewardsNavigation } from '../../../../providers/RewardsNavigationProvider';
import { RewardsRoute } from '../../../../routes';

const CampaignConfiguration = () => {
  const { navigateTo } = useRewardsNavigation();
  const {
    selectedCampaign,
    fetchCampaignRules,
    editCampaign,
    uppdateCampaignStatus,
  } = useCampaign();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [showEditCampaignModal, setShowEditCampaignModal] =
    useState<boolean>(false);

  useEffect(() => {
    fetchCampaignRules();
  }, []);

  const isPointBased = () =>
    selectedCampaign?.campaignType == CampaignType.POINT_BASED;

  function onSubmitClick() {
    uppdateCampaignStatus(CampaignStatus.ACTIVE);
    navigateTo(RewardsRoute.REWARDS_HOME);
  }

  return (
    <CampaignRuleProvider>
      <Box
        style={{
          backgroundColor: 'white',
          paddingTop: '12px',
          paddingBottom: '12px',
          paddingLeft: '24px',
        }}
      >
        <VegaBreadcrumbs>
          <Link
            underline="hover"
            key="1"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigateTo(RewardsRoute.REWARDS_HOME);
            }}
          >
            <VegaTextV2
              fontSize={'14px'}
              lineHeight="19.6px"
              color={'rgba(51, 51, 51, 1)'}
              text=" All Campaigns"
            />
          </Link>
          <VegaTextV2
            fontSize={'14px'}
            lineHeight="19.6px"
            color={'rgba(51, 51, 51, 1)'}
            text={selectedCampaign?.campaignName ?? '-'}
          />
        </VegaBreadcrumbs>
      </Box>
      <CampaignSummaryHeader
        onEditClick={() => {
          setShowEditCampaignModal(true);
        }}
        onSubmitClick={onSubmitClick}
      />
      <div
        style={{
          marginLeft: '12px',
          paddingTop: '12px',
          marginRight: '24px',
        }}
      >
        {isPointBased() && (
          <CampaignConfigurationTabBar onTabSelected={setSelectedTab} />
        )}

        <div
          style={{
            marginLeft: '12px',
            marginTop: '20px',
          }}
        >
          {isPointBased() == false && <NonPointAllocationTabPanel />}
          {selectedTab == 0 && isPointBased() && <PointAllocationTabPanel />}
          {selectedTab == 1 && isPointBased() && <RedemptionTabPanel />}
        </div>
      </div>

      <EditCampaignModal
        onClose={() => {
          setShowEditCampaignModal(false);
        }}
        onSave={editCampaign}
        open={showEditCampaignModal}
      />
    </CampaignRuleProvider>
  );
};

export default CampaignConfiguration;
