import React, { useState } from 'react';
import { VegaCard, VegaPage, VegaTextField } from '../../common';
import { Box, IconButton, Typography, Grid } from '@mui/material';
import { AmazoneIcon } from '../../icon/Icons';
import { BORDER_GRAY, GREY, PRIMARY } from '../../../constants/style';
import VegaContainedButton from '../../common/VegaContainedButton';
import { AddCircleOutline } from '@mui/icons-material';
import VegaModal from '../../common/VegaModal';
import VegaOutlineButton from '../../common/VegaOutlineButton';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../common/VegaSelect';
import ListOfSubscription from './components/ListOfSubscription';
import {
  GIFT_VOUCHER,
  IinventotyProps,
} from '../../../pages/RewardLoyalty/components/BenefitsInventory';
import NoVoucherAndRewards from '../../../pages/RewardLoyalty/components/NoVoucherAndRewards';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import {
  ICreateInventoryProps,
  TIMEFRAME,
} from '../../../types/RewardsLoyalty';
import VegaHeader from '../../common/VegaHeader';
import { AntSwitch, toLowerCase } from '../../../constants/commonFunction';
import VendorList from '../components/VendorList';
import AddCoupons from '../components/AddCoupons';

const SUBSCRIPTION = {
  programId: '',
  vendorId: '1231231',
  vendorName: 'Amazon',
  giftType: 'SUBSCRIPTION',
  subscribtion: {
    number: 1,
    timeframe: 'ANNUALLY',
  },
  quantity: 0,
  totalValue: 2000000,
  assigned: 0,
  remaining: 0,
  autoRenew: true,
} as ICreateInventoryProps;

const Subscription = ({
  data,
  onVoucherSubmit,
  updateRenewInventory,
}: IinventotyProps) => {
  const { selectedProgram } = useProgramData();
  const [open, setOpen] = useState<boolean>(false);
  const [subscribtion, setSubscribtion] = useState<ICreateInventoryProps>({
    ...SUBSCRIPTION,
  });
  const handleClose = () => {
    setOpen(!open);
  };

  const handleChange = (name: string, value: any) => {
    setSubscribtion({ ...subscribtion, [name]: value });
  };

  const handleAmountChange = (name: string, value: any) => {
    const newObj = {
      ...subscribtion['subscribtion'],
      [name]: value,
    };
    console.log('newObj', newObj);
    handleChange('subscribtion', newObj);
  };
  const onSubmit = async () => {
    const findVendor = await GIFT_VOUCHER.find(
      (voucher: VegaSelectSelectOptionType) =>
        voucher.id === subscribtion.vendorId
    );

    Object.assign(subscribtion, {
      programId: selectedProgram?.programId,
      vendorName: findVendor?.name,
    });
    await onVoucherSubmit(subscribtion);
    handleClose();
  };

  const isInputValid = () => {
    const isSubscriptionNumberValid =
      (subscribtion.subscribtion?.number ?? 0) > 0;
    const isTimeFrameValid =
      (subscribtion.subscribtion?.timeframe ?? '').length > 0;
    const isQuantityValid = subscribtion.quantity > 0;
    return isSubscriptionNumberValid && isTimeFrameValid && isQuantityValid;
  };

  return (
    <VegaPage>
      <VendorList />
      <VegaCard noPad sx={{ boxShadow: 'none' }}>
        <AddCoupons
          buttonText="Add Subscription"
          onClick={() => setOpen(!open)}
        />
        {data.length ? (
          <ListOfSubscription
            data={data}
            updateRenewInventory={updateRenewInventory}
          />
        ) : (
          <NoVoucherAndRewards text="No Subscription" />
        )}
      </VegaCard>
      <VegaModal
        header="New Subscription"
        submitText="Add Subscription"
        handleSubmit={onSubmit}
        buttonIcon={<AddCircleOutline />}
        open={open}
        handleClose={handleClose}
        switchText="Auto Renew"
        disabled={isInputValid() == false}
        switchComponent={
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            <Typography sx={{ fontSize: 12, color: GREY.dark, mr: 1 }}>
              Auto Renew
            </Typography>
            <AntSwitch
              checked={subscribtion.autoRenew}
              onChange={(e) => handleChange('autoRenew', e.target.checked)}
            />
          </Box>
        }
      >
        <Box sx={{ mx: 2 }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <VegaSelect
                className="font-aspekta-400"
                isFixedHeight
                options={GIFT_VOUCHER}
                key="vendorId"
                selected={subscribtion.vendorId}
                placeholder="Select Vendor"
                onChange={(value) => handleChange('vendorId', value)}
                headerText="select vendor"
              />
            </Grid>
            <Grid item sm={12}>
              <VegaHeader text="Select plan" />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VegaTextField
                    isFixedHeight
                    value={subscribtion.subscribtion?.number ?? 0}
                    onChange={(e) =>
                      handleAmountChange(
                        'number',
                        e.target.value && parseInt(e.target.value)
                      )
                    }
                    key="number"
                    type={'number'}
                    placeholder="Enter Amount"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VegaSelect
                    className="font-aspekta-400"
                    isFixedHeight
                    options={timeframeOptions()}
                    key="timeframe"
                    selected={subscribtion.subscribtion?.timeframe ?? ''}
                    placeholder="Select Plan"
                    onChange={(value) => handleAmountChange('timeframe', value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <VegaTextField
                isFixedHeight
                value={subscribtion.quantity}
                onChange={(e) =>
                  handleChange(
                    'quantity',
                    e.target.value && parseInt(e.target.value)
                  )
                }
                key="quantity"
                type={'number'}
                placeholder="Enter Quantity"
                headerText="Enter Quantity"
              />
            </Grid>
          </Grid>
          {/* <NewCampaign state={state} handleChange={handleChange} /> */}
        </Box>
      </VegaModal>
    </VegaPage>
  );
};

export default Subscription;
const timeframeOptions = () => {
  return ['MONTHLY', 'ANNUALLY'].map((item) => {
    const option: VegaSelectSelectOptionType = {
      id: item,
      name: item === 'MONTHLY' ? 'Month' : 'Year',
    };
    return option;
  });
};
