import axios from 'axios';
import dayjs from 'dayjs';
import { BASE_URL, UNIVERSSAL_STANDARD } from './endpoints';

const UNIVERSSAL_STANDARD_BASE_URL = BASE_URL + UNIVERSSAL_STANDARD.BASE;

export const updateProgramPDDList = (programId: string, pddList: any) => {
  const data = {
    programId,
    pddList,
  };
  return axios.patch(`${UNIVERSSAL_STANDARD_BASE_URL}/paymentDueDates`, data);
};
