import { Edit, Save, WarningAmberOutlined } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { saveSupplementaryCardConfiguration } from '../../../api/card';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { AddOnCardConfiguration } from '../../../types/card';
import VegaAccordianV2 from '../../common/v2/VegaAccordianV2';
import VegaFormInputFieldV2 from '../../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../common/v2/VegaPrimaryButton';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import { AddOnCardConfigIcon, WarningRoundedOutlined } from '../../icon/Icons';

type Props = {
  programId?: string;
  initial?: Partial<AddOnCardConfiguration> | null;
};
function SupplementaryCardDetailsAccordian({ programId, initial }: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [schemeName, setSchemeName] = useState<string>();
  const [maxCardsToBeAllocated, setMaxCardsToBeAllocated] = useState<string>();
  const [hasSupplementaryCardData, setHasSupplementaryCardData] =
    useState<boolean>(false);
  const [settings, setSettings] = useState<Partial<AddOnCardConfiguration>>();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  function toggleExpansion() {
    setIsExpanded(!isExpanded);
  }

  function isInputValid() {
    let isSchemenNameValid = Boolean(schemeName);
    let maxCardsIsValid = (maxCardsToBeAllocated ?? '').length > 0;
    let isValid = isSchemenNameValid && maxCardsIsValid;
    return isValid;
  }

  function onSaveClick() {
    setLoading(true);
    const data: Partial<AddOnCardConfiguration> = {
      programId: programId,
      supplementaryCardSchemeName: schemeName,
    };

    if (maxCardsToBeAllocated) {
      data.supplementaryCardCount = Number(maxCardsToBeAllocated);
    }

    saveSupplementaryCardConfiguration(data)
      .then((res) => {
        setSnackbar('Settings saved');
        setSettings(res);
      })
      .catch((err) => {
        setSnackbar('Failed to save settings', 'error');
      })
      .finally(() => {
        setLoading(false);
        toggleExpansion();
      });
  }

  function processSettings(cardSettings?: Partial<AddOnCardConfiguration>) {
    setSchemeName(cardSettings?.supplementaryCardSchemeName);
    setHasSupplementaryCardData(
      Boolean(cardSettings?.supplementaryCardSchemeName)
    );
    const cardCount = cardSettings?.supplementaryCardCount;
    if (cardCount) {
      setMaxCardsToBeAllocated(cardCount.toString());
    }
  }

  useEffect(() => {
    if (!initial) return;
    setSettings(initial);
  }, [initial]);

  useEffect(() => {
    if (!settings) return;
    processSettings(settings);
  }, [settings]);

  return (
    <VegaAccordianV2
      expanded={isExpanded}
      collapsedView={
        <div>
          <Stack flex={2}>
            <VegaTextV2 text="Supplementary Card" />
            <VegaTextV2
              text="Additional card to be issue to primary holder"
              variant="caption"
            />
          </Stack>
        </div>
      }
      collapsedViewActions={
        <VegaPrimaryButton
          variant="outlined"
          onClick={() => {
            toggleExpansion();
          }}
          text={
            hasSupplementaryCardData ? 'Edit' : 'Configure Supplementary card'
          }
          startIcon={
            hasSupplementaryCardData ? <Edit /> : <AddOnCardConfigIcon />
          }
        />
      }
      expandedViewActions={
        <Stack direction={'row'} spacing={2}>
          <VegaPrimaryButton
            variant="text"
            onClick={() => {
              toggleExpansion();
            }}
            text={'Cancel'}
          />
          <VegaPrimaryButton
            disabled={isInputValid() == false}
            loading={loading}
            onClick={() => {
              onSaveClick();
            }}
            text={'Save'}
            startIcon={<Save />}
          />
        </Stack>
      }
      expandedView={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <VegaFormInputFieldV2 label="Scheme Name">
                  <VegaTextFieldV2
                    value={schemeName}
                    onChange={(e) => setSchemeName(e.target.value)}
                  />
                </VegaFormInputFieldV2>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <VegaFormInputFieldV2 label="Max cards to be alloted">
                  <VegaSelectV2
                    options={cardCountOptions()}
                    value={maxCardsToBeAllocated ?? ''}
                    onChange={(e) => {
                      setMaxCardsToBeAllocated(e.target.value as string);
                    }}
                  />
                </VegaFormInputFieldV2>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <WarningRoundedOutlined />
              <VegaTextV2
                text="You will need to configure charging of add  on card in Program CMS setup  >  Charges"
                variant="caption"
              />
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
}

export default SupplementaryCardDetailsAccordian;
const cardCountOptions = () =>
  ['1', '2', '3', '4', '5'].map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: item,
    };
    return option;
  });
