import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  FinancialTenureTaxType,
  IterationType,
} from '../../../../../../../../types/RewardsLoyalty';
import VegaFormInputFieldV2 from '../../../../../../../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../../../../../../common/v2/VegaPrimaryButton';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../../../../../../common/v2/VegaTextFieldV2';
import { useCampaignRule } from '../../../../../../providers/CampaignRuleProvider';

function FinancialTenureInput() {
  const { addFinancialTenure, financialData } = useCampaignRule();

  const [tenureType, setTenureType] = useState<string>('');
  const [taxType, setTaxType] = useState<string>('');
  const [price, setPrice] = useState<number>();

  const isInputValid = () => {
    const isTenureValid = tenureType.length > 0;
    const isTaxValid = taxType.length > 0;
    const isPriceValid = price != null && price > 0;
    return isTenureValid && isTaxValid && isPriceValid;
  };

  const filterDataForTenure = (type: IterationType) => {
    const data =
      financialData.tenures?.filter((item) => item.tenureType == type) ?? [];
    if (data.length > 0) {
      return data[0];
    }
  };

  const getPriceForTenure = (type: IterationType) => {
    if (tenureType == type) {
      return price ?? 0;
    }
    return filterDataForTenure(IterationType.MONTHLY)?.price ?? 0;
  };

  // need to pick up
  const checkIfPriceIsValidForTenure = () => {
    if (price == null || price <= 0) {
      console.log('Price not specified');
      return false;
    }
    const monthTenurePrice = getPriceForTenure(IterationType.MONTHLY);
    const annualTenurePrice = getPriceForTenure(IterationType.ANNUALLY);
    const quaterTenurePrice = getPriceForTenure(IterationType.QUARTERLY);

    var isMonthlyValid = true;
    var isQuaterlyValid = false;
    var isAnnuallyValid = false;

    if (monthTenurePrice > 0) {
      isMonthlyValid =
        monthTenurePrice >= quaterTenurePrice &&
        quaterTenurePrice >= annualTenurePrice;
    }

    if (quaterTenurePrice) {
      return;
    }
  };

  const getFilteredTenureOptions = () => {
    const options = getTenureTypeOptions();
    const existingTenures =
      financialData.tenures?.map((item) => item.tenureType) ?? [];

    const filteredOptions = options.filter(
      (item) => !existingTenures.includes(item.value)
    );

    return filteredOptions;
  };

  function resetInputs() {
    setTenureType('');
    setTaxType('');
    setPrice(undefined);
  }

  function onAddClick() {
    if (!price) return;
    addFinancialTenure({
      tenureType: tenureType,
      price: price,
      taxationType: taxType,
    });
    resetInputs();
  }

  useEffect(() => {
    checkIfPriceIsValidForTenure();
  }, [price, tenureType]);

  return (
    <Grid container columns={6} alignItems="end" columnGap={2}>
      <Grid item xs={1}>
        <VegaFormInputFieldV2 label="Tenure Type" flex={1}>
          <VegaSelectV2
            options={getFilteredTenureOptions()}
            placeholder="Select Tenure type"
            value={tenureType ?? ''}
            onChange={(e) => {
              setTenureType(e.target.value as string);
            }}
          />
        </VegaFormInputFieldV2>
      </Grid>
      <Grid item xs={1}>
        <VegaFormInputFieldV2 label="Price" flex={1}>
          <VegaTextFieldV2
            placeholder="Enter price"
            value={price != null ? price : ''}
            onChange={(e) => {
              const value = e.target.value;
              const numberValue = Number(value);
              if (numberValue >= 0) {
                setPrice(numberValue);
              }
            }}
          />
        </VegaFormInputFieldV2>
      </Grid>
      <Grid item xs={1}>
        <VegaFormInputFieldV2 label="Tax Type" flex={1}>
          <VegaSelectV2
            options={getTaxTypeOptions()}
            placeholder="Select Tax Type"
            value={taxType ?? ''}
            onChange={(e) => {
              setTaxType(e.target.value as string);
            }}
          />
        </VegaFormInputFieldV2>
      </Grid>
      <Grid item xs={1}>
        <VegaPrimaryButton
          size="small"
          variant="outlined"
          text={'Add'}
          startIcon={<Add />}
          onClick={onAddClick}
          disabled={isInputValid() == false}
        />
      </Grid>
    </Grid>
  );
}

export default FinancialTenureInput;

const getTaxTypeOptions = () =>
  Object.values(FinancialTenureTaxType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const getTenureTypeOptions = () =>
  Object.values(IterationType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
