import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Contribution,
  ContributionEntityType,
} from '../../../../../../../../types/RewardsLoyalty';
import VegaFormInputFieldV2 from '../../../../../../../common/v2/VegaFormInputField';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../common/v2/VegaSelectV2';
import VegaSwitchV2 from '../../../../../../../common/v2/VegaSwitchV2';
import VegaTextFieldV2 from '../../../../../../../common/v2/VegaTextFieldV2';
import VegaConditionalWrapper from '../../../../../../../common/VegaConditionalWrapper';

type Props = {
  options?: VegaSelectOptionV2[];
  entityType: ContributionEntityType;
  entityName?: string;
  value: Partial<Contribution> | null;
  onContributionStatusChange: (value: boolean) => void;
  onContributionValueChange: (value?: number) => void;
  onContributionValueSelect?: (value: string) => void;
};

enum InputType {
  STATUS,
  CONTRIBUTION,
  SELECT_VALUE,
}

export const ContributionInput = ({
  entityName,
  entityType,
  onContributionStatusChange,
  onContributionValueChange,
  options,
  onContributionValueSelect,
  value,
}: Props) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [text, setText] = useState<number>();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const entityDisplayText = _.startCase(_.toLower(entityType));

  const hasOptions = () => (options ?? []).length > 0;

  function onChange(type: InputType, value?: number | boolean | string) {
    switch (type) {
      case InputType.STATUS:
        setEnabled(value as boolean);
        onContributionStatusChange(value as boolean);
        break;
      case InputType.CONTRIBUTION:
        if (value) {
          setText(value as number);
          onContributionValueChange(value as number);
        } else {
          setText(undefined);
          onContributionValueChange(undefined);
        }
        break;
      case InputType.SELECT_VALUE:
        setSelectedValue(value as string);
        onContributionValueSelect?.(value as string);
        break;
    }
  }

  useEffect(() => {
    setText(value?.percentage);
    setEnabled(!!value);
    setSelectedValue(value?.name ?? '');
  }, [value]);

  return (
    <Grid container columns={5} alignItems="center" columnGap={2}>
      <Grid item xs={1.5}>
        <VegaFormInputFieldV2
          label={entityDisplayText}
          endAdornment={
            <VegaSwitchV2
              size="small"
              checked={enabled}
              onChange={(e) => {
                const enable = e.target.checked;
                onChange(InputType.STATUS, enable);
                if (enable == false) {
                  onChange(InputType.CONTRIBUTION, undefined);
                }
              }}
            />
          }
        >
          <VegaConditionalWrapper
            condition={hasOptions()}
            ifTrue={
              <VegaSelectV2
                disabled={enabled == false}
                placeholder="Select"
                options={options}
                value={selectedValue}
                onChange={(e) => {
                  onChange(InputType.SELECT_VALUE, e.target.value as string);
                }}
              />
            }
            ifFalse={<VegaTextFieldV2 disabled value={entityName} />}
          />
        </VegaFormInputFieldV2>
      </Grid>
      <Grid item xs={1.5}>
        <VegaFormInputFieldV2 label={'Contribution'}>
          <VegaTextFieldV2
            value={text != null ? text : ''}
            disabled={enabled == false}
            type={'number'}
            placeholder={`Enter ${entityDisplayText} contribution`}
            onChange={(e) => {
              const value = e.target.value;
              const numberValue = Number(value);
              onChange(InputType.CONTRIBUTION, numberValue);
            }}
          />
        </VegaFormInputFieldV2>
      </Grid>
    </Grid>
  );
};
