import { Box } from '@mui/material';
import { CardType, useCardConfig } from '../../providers/CardConfigProvider';
import VegaImageSelector from '../common/v2/ImageSelector/VegaImageSelector';
import VegaSelectV2 from '../common/v2/VegaSelectV2';

type Props = {
  cardType: CardType;
};

function CardBankLogoSelector({ cardType }: Props) {
  const { onBankLogoChange, physicalCardConfig, virtualCardConfig } =
    useCardConfig();

  const getBankLogo = () => {
    const logo =
      cardType == CardType.PHYSICAL
        ? physicalCardConfig.bankLogo
        : virtualCardConfig.bankLogo;
    return logo;
  };

  return (
    <VegaSelectV2
      placeholder="Upload Bank Logo"
      renderOptions={() => (
        <VegaImageSelector
          title={'Bank Logo'}
          images={[
            'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/abc_bank_dark.png',
            'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/abc_bank_light.png',
          ]}
          onAddClick={function (): void {}}
          onImageSelect={function (value: string): void {
            onBankLogoChange(value, cardType);
          }}
          selectedImage={getBankLogo()}
        />
      )}
      hidePlaceholder={Boolean(getBankLogo())}
      renderSelectedValue={() => {
        return (
          <Box
            style={{
              height: '20px',
              maxWidth: '80px',
              backgroundColor: '#f1f1f1f1',
              padding: 0,
              margin: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{ objectFit: 'contain' }}
              height={'80%'}
              width={'80%'}
              src={getBankLogo()}
            ></img>
          </Box>
        );
      }}
    />
  );
}

export default CardBankLogoSelector;
