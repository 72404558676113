/** @format */

export interface IUsersProps {
  clientId: any;
  name: string;
  mobileNumber: string;
  email: string;
  address: string;
  idType: string;
  idNumber: string;
  status: string;
  team: string;
}

export interface IUserPaginatedReqProps {
  clientId: string;
  page: number;
  size: number;
}

export interface IAccessProps {
  programRoles?: IProgramRoles[];
  feature: string;
}

export interface IRolesProps {
  programId?: string;
  clientId: any;
  roleName: string;
  accessFeatures: IAccessProps[];
  createdBy: string;
  status: string;
}

export interface IProgramRoles {
  roles: string[];
  programId: string;
}

export enum VegaTeamType {
  OPERATIONS = 'OPERATIONS',
  COLLECTIONS = 'COLLECTIONS',
  FINANCE = 'FINANCE',
  SETTLEMENT = 'SETTLEMENT',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  DESIGN = 'DESIGN',
  DEVELOPER = 'DEVELOPER',
  CXOS = 'CXOS',
  MARKETING_AND_BRANDING = 'MARKETING_AND_BRANDING',
  SUPPORT = 'SUPPORT',
  BUSINESS_INTELLIGENCE = 'BUSINESS_INTELLIGENCE',
  BUSINESS = 'BUSINESS',
  RISK = 'RISK',
  AGENCY_COLLECTION = 'AGENCY_COLLECTION',
  AGENCY_SUPPORT = 'AGENCY_SUPPORT',
  AGENCY_OPERATIONS = 'AGENCY_OPERATIONS',
}

export interface AddCognitoUserForClientParams {
  clientId: any;
  name: string;
  mobileNumber?: string;
  email: string;
  status: string;
}

export type Resource = {
  type: string;
  interFace?: string | null;
  module?: string | null;
  subModule?: string | null;
  apiUri?: string | null;
  apiMethod?: string | null;
  createdAt?: string;
  updatedAt?: string;
  id?: string | null;
};

const roleTypes = {
  user: 'USER',
  admin: 'ADMIN',
} as const;

const roleEntityTypes = {
  coBrand: 'CO_BRANDED_PARTNER',
  client: 'CLIENT',
} as const;

const roleStatuses = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
} as const;

export const RoleAccesses = {
  view: 'VIEW',
  edit: 'EDIT',
  maker: 'MAKER',
  checker: 'CHECKER',
  all: 'ALL',
} as const;

export type RoleType = (typeof roleTypes)[keyof typeof roleTypes];

export type RoleEntityType =
  (typeof roleEntityTypes)[keyof typeof roleEntityTypes];

export type RoleStatus = (typeof roleStatuses)[keyof typeof roleStatuses];

export type RoleAccess = (typeof RoleAccesses)[keyof typeof RoleAccesses];

export type Role = {
  id: string;
  clientId: string;
  name: string;
  description?: string;
  type: RoleType;
  entityType: RoleEntityType;
  status: RoleStatus;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ResourceWithAccess = {
  resource: Resource;
  access: RoleAccess;
};

export type FetchRolesFilterType = {
  clientId: string;
  name?: string;
  type?: RoleType;
  entityType?: RoleEntityType;
  status?: RoleStatus;
  page?: number;
  size?: number;
};

export type RoleProgramEntityType = {
  programId: string;
  roleId: string;
  entityId?: string;
  divisionId?: string;
};
