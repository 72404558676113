import axios from 'axios';
import {
  EntityCommentStatus,
  EntityAuthStatus,
  EntityComment,
  EntityStatus,
} from '../types/entityAuth';
import { BASE_URL, ENTITY_AUTH } from './endpoints';

const ENTITY_AUTH_BASE_URL = BASE_URL + ENTITY_AUTH.BASE;

export async function getEntityStatus(data: {
  entityId: string;
  role: string;
}): Promise<EntityAuthStatus> {
  const endpoint =
    ENTITY_AUTH_BASE_URL +
    ENTITY_AUTH.GET_STATUS.replace('{entityId}', data.entityId);
  try {
    const response = await axios.get(endpoint, {
      params: { owner: data.role },
    });
    return response.data as EntityAuthStatus;
  } catch (error) {
    throw error;
  }
}

interface UpdateEntityStatusPrams {
  userId: string;
  programId: string;
  entityId: string;
  status: EntityStatus;
}
export async function updateEntityStatus(
  data: UpdateEntityStatusPrams
): Promise<EntityAuthStatus> {
  const endpoint = ENTITY_AUTH_BASE_URL + ENTITY_AUTH.UPDATE_STATUS;
  try {
    const response = await axios.put(endpoint, data);
    return response.data as EntityAuthStatus;
  } catch (error) {
    throw error;
  }
}

interface AddEntityCommentProps {
  comments: string;
  authorizationRequestId: string;
  status: EntityCommentStatus;
}

export async function addEntityComment(
  data: AddEntityCommentProps
): Promise<EntityComment> {
  try {
    const endpoint = ENTITY_AUTH_BASE_URL + ENTITY_AUTH.ADD_COMMENT;
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

interface UpdateEntityCommentProps {
  id: string;
  comment: string;
}
export async function updateEntityComment(
  data: UpdateEntityCommentProps
): Promise<EntityComment> {
  try {
    const endpoint =
      ENTITY_AUTH_BASE_URL +
      ENTITY_AUTH.UPDATE_COMMENT.replace('{commentId}', data.id);
    const response = await axios.put(endpoint, { comments: data.comment });
    return response.data;
  } catch (error) {
    throw error;
  }
}

interface SubmitEntitiesProps {
  ids: string[];
  userId: string;
}

export async function submitEntitiesToMaker(
  data: SubmitEntitiesProps
): Promise<any> {
  try {
    const endpoint = ENTITY_AUTH_BASE_URL + ENTITY_AUTH.SUBMIT_TO_MAKER;
    const response = await axios.post(
      endpoint,
      { id: data.ids },
      {
        params: { checkerUserId: data.userId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function submitEntitiesToChecker(
  data: SubmitEntitiesProps
): Promise<any> {
  try {
    const endpoint = ENTITY_AUTH_BASE_URL + ENTITY_AUTH.SUBMIT_TO_CHECKER;
    const response = await axios.post(
      endpoint,
      { id: data.ids },
      {
        params: { makerUserId: data.userId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getStatusForEntities(
  ids: string[],
  role: string
): Promise<EntityAuthStatus[]> {
  try {
    const endpoint = ENTITY_AUTH_BASE_URL + ENTITY_AUTH.LIST_BY_ENTITY_IDS;
    const response = await axios.post(
      endpoint,
      { ids: ids },
      { params: { owner: role } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
