import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { NEW_PRIMARY } from '../../constants/style';
import Vega_Text from '../common/v3/Vega_Text';
import SupportCustomerDetails from './components/SupportCustomerDetails';
import SupportQuickActions from './components/SupportQuickActions';

const SupportLeftContainer = () => {
  return (
    <>
      <Box
        sx={{
          border: `1px solid #E1E4EB`,
          borderRadius: '12px',
          bgcolor: 'white',
          p: 2,
          height: '100%',
        }}
      >
        <SupportCustomerDetails />
        <hr
          style={{
            border: '1px solid #EBEDEF',
            marginTop: '10px',
            marginBottom: '15px',
          }}
        />
        <SupportQuickActions />
        <hr
          style={{
            border: '1px solid #EBEDEF',
            marginTop: '10px',
            marginBottom: '15px',
          }}
        />
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Vega_Text
            text={'View Security Questions'}
            color={NEW_PRIMARY.BLUE}
            fontSize={13}
            fontWeight={450}
          />
          <KeyboardArrowRightOutlined
            sx={{ color: NEW_PRIMARY.BLUE, fontSize: 16 }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default SupportLeftContainer;
