import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid';
import { IdentityFRMPolicy } from '../../../types/policy';
import ProgramDataGrid from './ProgramDataGrid';
import { useAppSelector } from '../../../store/hook';
import { convertCamelCase } from '../../../utils/stringUtils';
import {
  OperandType,
  getOperandType,
  getSelectedOperator,
} from '../../../types/frm';

type Props = {};

type IdentityFrmTableType = {
  check: string;
  acceptance_criteria: string;
  reject_criteria: string;
  failsafe: boolean;
};

const getIdentityFrmTableColumns = (): GridColDef<IdentityFrmTableType>[] => [
  {
    field: 'check',
    headerName: 'Check',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'acceptance_criteria',
    headerName: 'Auto Accept',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'reject_criteria',
    headerName: 'Auto Reject',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'failsafe',
    headerName: 'Failsafe',
    flex: 1,
    sortable: false,
    minWidth: 180,
    valueGetter: (props) => (props.row.failsafe ? 'Yes' : 'No'),
  },
];

const canShowPercentageSuffix = (check: string) => {
  return check !== 'IP_UNIQUENESS' && check !== 'DEVICE_UNIQUENESS';
};

const getFormattedData = (frmPolicy?: IdentityFRMPolicy) => {
  if (!frmPolicy || !frmPolicy.identity_frm_criteria) return [];
  const criteriaData = Object.keys(frmPolicy.identity_frm_criteria).map(
    (key) => {
      const criteria = frmPolicy.identity_frm_criteria[key];
      let acceptanceCriteria = '-';
      if (criteria?.acceptance_criteria) {
        const acceptanceOperator = getSelectedOperator(
          criteria?.acceptance_criteria
        );
        const acceptanceType = getOperandType(acceptanceOperator);
        if (acceptanceType) {
          const acceptanceValue = criteria?.acceptance_criteria[acceptanceType];
          acceptanceCriteria =
            acceptanceType === OperandType.EXACT
              ? acceptanceValue
                ? 'Yes'
                : 'No'
              : `${acceptanceOperator} ${acceptanceValue}${
                  canShowPercentageSuffix(key) ? '%' : ''
                }`;
        }
      }

      let rejectCriteria = '-';
      if (criteria?.reject_criteria) {
        const rejectOperator = getSelectedOperator(criteria?.reject_criteria);
        const rejectType = getOperandType(rejectOperator);
        if (rejectType) {
          const rejectValue = criteria?.reject_criteria[rejectType];
          rejectCriteria =
            rejectType === OperandType.EXACT
              ? rejectValue
                ? 'Yes'
                : 'No'
              : `${rejectOperator} ${rejectValue}${
                  canShowPercentageSuffix(key) ? '%' : ''
                }`;
        }
      }
      return {
        check: convertCamelCase(key),
        acceptance_criteria: acceptanceCriteria,
        reject_criteria: rejectCriteria,
        failsafe: criteria?.failsafe,
      };
    }
  );
  return criteriaData;
};

function IdentityFrm({}: Props) {
  const columnsRef = useMemo(() => getIdentityFrmTableColumns(), []);
  const policies = useAppSelector((state) => state.programManagement.policies);
  const frmPolicy = useMemo(
    () =>
      policies.find((policy) => policy.policy_type === 'FRM_POLICY') as
        | IdentityFRMPolicy
        | undefined,
    [policies]
  );

  const tableData = getFormattedData(frmPolicy);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography color="#1B1D22" fontSize={17} className="font-aspekta-700">
        Identity FRM
      </Typography>
      <ProgramDataGrid
        data={tableData}
        columns={columnsRef}
        idColumn={'check'}
      />
    </Box>
  );
}

export default IdentityFrm;
