import { Box, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { BORDER_GRAY, GREY, PRIMARY } from '../../../../constants/style';
import { useApplicationManagmentConfig } from '../../../../providers/ApplicationManagmentProvider';
import { CustomerIdentificationInfoDto } from '../../../../types/customers';
import { VegaTextField } from '../../../common';
import VegaContainedButton from '../../../common/VegaContainedButton';
import DocumentImages from './DocumentImages';
import download from 'downloadjs';

const KYC_SelfieDetails = () => {
  const { customer } = useApplicationManagmentConfig();
  const [isButtonVisible, setButtonVisibility] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  return (
    <Box pb={7}>
      <Grid container spacing={3}>
        {customer?.identity.length
          ? customer?.identity.map(
              (identity: CustomerIdentificationInfoDto, index: number) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography
                      className="font-opensans-400"
                      fontSize={14}
                      color={GREY.grey_light}
                    >
                      {identity.idType} Card
                    </Typography>
                    <Box sx={{ flex: 1 }}>
                      <VegaTextField
                        fullWidth
                        key={identity.id}
                        value={identity.idNumber}
                      />
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      border: BORDER_GRAY,
                      borderRadius: '14px',

                      position: 'relative',
                      mt: 2,
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                      onMouseEnter={() => {
                        setSelectedIndex(index);
                        setButtonVisibility(true);
                      }}
                      onMouseLeave={() => {
                        setSelectedIndex(null);
                        setButtonVisibility(false);
                      }}
                    >
                      {!identity.url ? (
                        noDocumentComponnt('No Image')
                      ) : (
                        <DocumentImages url={identity.url} />
                      )}
                      {identity.url && (
                        <Stack
                          direction={'row'}
                          sx={{
                            position: 'absolute',
                            top: 70,
                            opacity:
                              isButtonVisible && index === selectedIndex
                                ? 1
                                : 0,
                            transition: 'opacity 0.3s ease-in-out',
                          }}
                        >
                          <VegaContainedButton
                            text="Download"
                            isPrimary
                            onClick={() => download(identity?.url ?? '')}
                          />
                        </Stack>
                      )}
                    </Box>
                    <Typography
                      className="font-opensans-500"
                      fontSize={12}
                      color={GREY.darkest}
                      sx={{
                        position: 'absolute',
                        top: -9,
                        bgcolor: 'white',
                        px: 0.5,
                      }}
                    >
                      {identity.idType}
                    </Typography>
                  </Box>
                </Grid>
              )
            )
          : noDocumentComponnt('No Document')}
        {customer?.selfieUrl ? (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Typography
                className="font-opensans-400"
                fontSize={14}
                color={GREY.grey_light}
              >
                Selfie
              </Typography>
              <Box sx={{ flex: 1 }}>
                <VegaTextField fullWidth key={''} value={'Selfie.jpg'} />
              </Box>
            </Stack>
            <Box
              sx={{
                border: BORDER_GRAY,
                borderRadius: '14px',

                position: 'relative',
                mt: 2,
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                }}
                onMouseEnter={() => {
                  setSelectedIndex(10);
                  setButtonVisibility(true);
                }}
                onMouseLeave={() => {
                  setSelectedIndex(null);
                  setButtonVisibility(false);
                }}
              >
                {!customer?.selfieUrl ? (
                  noDocumentComponnt('No Image')
                ) : (
                  <img
                    style={{
                      borderRadius: '9px',
                    }}
                    src={customer?.selfieUrl}
                    width={'80%'}
                    height={170}
                  />
                )}
                <Stack
                  direction={'row'}
                  sx={{
                    position: 'absolute',
                    top: 70,
                    opacity: isButtonVisible && 10 === selectedIndex ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                >
                  <VegaContainedButton
                    text="Download"
                    isPrimary
                    onClick={() => download(customer.selfieUrl)}
                  />
                </Stack>
              </Box>
              <Typography
                className="font-opensans-500"
                fontSize={12}
                color={GREY.darkest}
                sx={{
                  position: 'absolute',
                  top: -9,
                  bgcolor: 'white',
                  px: 0.5,
                }}
              >
                Selfie
              </Typography>
            </Box>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Box>
  );
};

export default KYC_SelfieDetails;

const noDocumentComponnt = (text: string) => {
  return (
    <Box
      height={170}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Typography
        className="font-font-opensans-500"
        color={'black'}
        fontSize={16}
      >
        {text}
      </Typography>
    </Box>
  );
};
