import { Typography } from '@mui/material';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaModal from '../../../../components/common/VegaModal';
import { FIleIcon } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { ICardFundLoadProps } from '../../../types/Card';
import { IConsolidatedAccountData } from '../../../types/Commons';
import { IReloadCardProps } from '../../../types/CorporateTransaction';
import { dateFormat } from '../../../variable';
import { ICheckerMakerProps } from '../../pages/MCCcorporateTransaction';
import BalanceView from '../MCCUserManagement/Components/BalanceView';

interface IProps {
  data: ICardFundLoadProps[];
  loading: boolean;
  submitInitialCheckerMaker: (data: ICheckerMakerProps) => void;
}

const ListOfPrepaidCorporateTransaction = ({
  data,
  loading,
  submitInitialCheckerMaker,
}: IProps) => {
  const { userId } = useClientAuth();
  const [customerDetails, setCustomerDetails] =
    useState<IConsolidatedAccountData | null>(null);
  const [openViewBalance, setOpenViewBalance] = useState<boolean>(false);
  const [openReload, setOpenReload] = useState<boolean>(false);
  const [reloadDetails, setReloadDetails] = useState<IReloadCardProps>({
    accountId: '',
    amount: 0,
    sourceCurrency: 'INR',
    destinationCurrency: 'USD',
  });

  const statusButtons = (
    fundLoadStatus: string,
    makerCheckerStatus: string,
    data: ICardFundLoadProps
  ) => {
    if (
      fundLoadStatus === 'FUND_RELOADED' ||
      makerCheckerStatus === 'APPROVED' ||
      fundLoadStatus === 'UNDER_REVIEW' ||
      makerCheckerStatus === 'CHECKER_PENDING'
    ) {
      return (
        <VegaContainedButton
          text={toLowerCase(makerCheckerStatus)}
          disabled
          //   onClick={() => showDetails(data, true)}
          isPrimary
          className="font-aspekta-500"
        />
      );
    }

    if (fundLoadStatus === 'DRAFT') {
      return (
        <VegaContainedButton
          text="Send to Checker"
          className="font-aspekta-500"
          isPrimary
          onClick={() => showDetails(data)}
        />
      );
    }
  };

  const REFUND_COLUMN = [
    {
      field: 'sourceCurrency',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Source Currency
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const corporateAccount: ICardFundLoadProps = props.row;
        return (
          <Typography className="font-aspekta-500" fontSize={14}>
            {corporateAccount.sourceCurrency}
          </Typography>
        );
      },
    },
    {
      field: 'destinationCurrency',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Destination Currency
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const corporateAccount: ICardFundLoadProps = props.row;
        return (
          <Typography className="font-aspekta-500" fontSize={14}>
            {corporateAccount.destinationCurrency}
          </Typography>
        );
      },
    },

    {
      field: 'amount',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Amount
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const corporateAccount: ICardFundLoadProps = props.row;
        return (
          <Typography className="font-aspekta-500" fontSize={14}>
            {corporateAccount.amount}
          </Typography>
        );
      },
    },
    {
      field: 'transactionTime',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Transaction Time
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const corporateAccount: ICardFundLoadProps = props.row;
        return (
          <Typography className="font-aspekta-500" fontSize={14}>
            {dateFormat(corporateAccount.transactionTime)}
          </Typography>
        );
      },
    },

    {
      field: 'action',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const corporateAccount: ICardFundLoadProps = props.row;
        return statusButtons(
          corporateAccount.fundLoadOrderStatus,
          corporateAccount.makerCheckerStatus,
          corporateAccount
        );
      },
    },
  ];

  const showDetails = (data: ICardFundLoadProps) => {
    // setReloadDetails({ ...reloadDetails, accountId: data.accountId });
    // isOpenReload();
    const newObj: ICheckerMakerProps = {
      entityId: data.id,
      userId,
    };
    submitInitialCheckerMaker(newObj);
  };
  const isOpen = () => {
    setOpenViewBalance(!openViewBalance);
  };
  const isOpenReload = () => {
    setOpenReload(!openReload);
  };

  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable
          data={data}
          columnDef={REFUND_COLUMN}
          idColumn={'id'}
          loading={loading}
        />
      </VegaCard>
      <VegaModal
        header="Balance Details"
        open={openViewBalance}
        handleClose={isOpen}
        submitText="Save"
        handleSubmit={isOpen}
        buttonIcon={<FIleIcon strokeColor="white" />}
      >
        <BalanceView state={customerDetails} isPhoneNumber />
      </VegaModal>
    </>
  );
};

export default ListOfPrepaidCorporateTransaction;
