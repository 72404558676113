import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DataTile from '../DataTile';
import { useAppSelector } from '../../../store/hook';
import { getLogoForNetwork } from '../../icon/Icons';
import { convertCamelCase } from '../../../utils/stringUtils';

function BinSetting() {
  const selectedProgram = useAppSelector(
    (state) => state.programManagement.selectedProgram
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Typography className="font-aspekta-600" fontSize={17}>
        Bin Setting
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={6} sm={4} lg={3}>
          <DataTile title="Bin" value={selectedProgram?.bin?.binName ?? '-'} />
        </Grid>
        <Grid item xs={6} sm={4} lg={3}>
          <DataTile
            title="number of cards"
            value={`${selectedProgram?.bin?.totalKits ?? '-'}`}
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={3}>
          <DataTile title="Network">
            {selectedProgram?.network
              ? getLogoForNetwork(selectedProgram?.network)
              : '-'}
          </DataTile>
        </Grid>
        <Grid item>
          <DataTile
            title="Bin Type"
            value={
              selectedProgram?.bin?.binType
                ? convertCamelCase(selectedProgram.bin.binType)
                : '-'
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BinSetting;
