import {
  AnyPolicyType,
  PolicyType,
  PolicySelections,
} from '../../types/policy';
import AccountPolicy from './AccountPolicy';
import BillingPolicy from './BillingPolicy';
import ChargesPolicy from './ChargesPolicy';
import InterestPolicy from './InterestPolicy';
import MadTadPolicy from './MadTadPolicy';
import RepaymentPolicy from './RepaymentPolicy';
import TaxPolicy from './TaxPolicy';
import TransactionLimit from './TransactionLimit';
import TxnToEmiPolicy from './TxnToEmiPolicy';

interface SelectedPolicyCardProps {
  isLoading: boolean;
  selectedPolicy: string;
  addPolicy: (policy: AnyPolicyType) => void;
  approvePolicy: (policy: AnyPolicyType) => void;
  rejectPolicy: (policy: AnyPolicyType) => void;
  onPastCommentsClick: (entityId?: string) => void;
  policySelections: PolicySelections;
}
export function SelectedPolicyCard({
  isLoading = true,
  selectedPolicy,
  addPolicy,
  policySelections,
  approvePolicy,
  rejectPolicy,
  onPastCommentsClick,
}: SelectedPolicyCardProps) {
  switch (selectedPolicy) {
    case PolicyType.BILLING:
      return (
        <BillingPolicy
          initialValue={policySelections.BILLING}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
    case PolicyType.MAD:
      return (
        <MadTadPolicy
          initialValue={policySelections.MAD}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
    case PolicyType.ACCOUNT:
      return (
        <AccountPolicy
          initialValue={policySelections.ACCOUNT}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
    case PolicyType.TAX:
      return (
        <TaxPolicy
          initialValue={policySelections.TAX}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
    case PolicyType.CHARGES:
      return (
        <ChargesPolicy
          initialValue={policySelections.CHARGES}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
    case PolicyType.INTEREST:
      return (
        <InterestPolicy
          initialValue={policySelections.INTEREST}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
    case PolicyType.REPAYMENT_WATERFALL:
      return (
        <RepaymentPolicy
          initialValue={policySelections.REPAYMENT_WATERFALL}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
    case PolicyType.TRANSACTION_TO_INSTALLMENT:
      return (
        <TxnToEmiPolicy
          initialValue={policySelections.TRANSACTION_TO_INSTALLMENT}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
    case PolicyType.TRANSACTION:
      return (
        <TransactionLimit
          initialValue={policySelections.TRANSACTION}
          onSubmit={addPolicy}
          isLoading={isLoading}
          onReject={rejectPolicy}
          onApprove={approvePolicy}
          onPastCommentsClick={onPastCommentsClick}
        />
      );
  }
  return <div></div>;
}
