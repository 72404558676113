/* eslint-disable react/jsx-pascal-case */
import { FiberManualRecord } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { toLowerCase } from '../../../constants/commonFunction';
import { useClientAuth } from '../../../providers/ClientProvider';
import { BinAllocationNotificationStatus } from '../../../types/card';
import { Dayjs } from '../../../utils/dayjsUtil';
import { BinService } from '../../apis';
import { CIMS_BORDER, CIMS_PRIMARY } from '../../assets/style';
import CIMS_Button from '../../components/common/CIMS_Button';
import CIMS_Modal from '../../components/common/CIMS_Modal';

function extractEntityCardsNMiddle(notification: string) {
  const words = notification.split(' ');

  const entity = words.slice(0, 2).join(' ');
  const cards = words.slice(-2).join(' ');
  const middle = words.slice(2, -2).join(' ');

  return { entity, cards, middle };
}

const Notifications = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { clientId } = useClientAuth();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const notificationsQuery = useQuery({
    queryKey: ['notificationsQuery'],
    queryFn: () => {
      return BinService.listBinNotification({
        clientId: clientId || '',
        page,
        size: pageSize,
        status: BinAllocationNotificationStatus.ACTIVE,
      });
    },
  });

  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ borderBottom: CIMS_BORDER.GRAY, px: 2, py: 0.5 }}
      >
        <Stack direction={'row'} spacing={1}>
          <Typography
            className="font-aspekta-500"
            fontSize={14}
            color={'black'}
          >
            Notifications
          </Typography>
        </Stack>
        <CIMS_Button
          variant="text"
          text="View all"
          sx={{ px: 0 }}
          onClick={toggleOpen}
        />
      </Stack>
      <Box
        sx={{
          pt: 2,
          px: 2,
        }}
      >
        <Box sx={{}}>
          {notificationsQuery.data?.records &&
          notificationsQuery.data?.records?.length > 0 ? (
            notificationsQuery.data?.records.slice(0, 2).map((notification) => {
              const { entity, cards, middle } = extractEntityCardsNMiddle(
                notification.message
              );
              return (
                <Box my={0.5}>
                  <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FiberManualRecord
                      sx={{ fontSize: 10, color: CIMS_PRIMARY.GOLD }}
                    />
                    <Typography
                      className="font-aspekta-500"
                      color={CIMS_PRIMARY.GRAY}
                      fontSize={13}
                    >
                      <span
                        className="font-aspekta-600"
                        style={{ color: CIMS_PRIMARY.BLACK }}
                      >
                        {toLowerCase(entity)}
                      </span>{' '}
                      {middle}{' '}
                      <span
                        className="font-aspekta-600"
                        style={{ color: CIMS_PRIMARY.RED }}
                      >
                        {cards}.
                      </span>{' '}
                    </Typography>
                  </Stack>
                  <Typography
                    className="font-aspekta-400"
                    color={CIMS_PRIMARY.GRAY}
                    fontSize={10}
                    ml={2.5}
                  >
                    {Dayjs(notification.updatedAt).format('HH:mm DD/MM/YYYY')}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <>
              <Typography fontStyle={'italic'}>No Notifications</Typography>
            </>
          )}
        </Box>
      </Box>
      <CIMS_Modal
        maxWidth="sm"
        header="Notifications"
        open={open}
        handleClose={toggleOpen}
        buttonGroup={[]}
      >
        <Box sx={{ px: 3, py: 3, height: '475px' }}>
          {notificationsQuery.data?.records &&
          notificationsQuery.data?.records.length > 0 ? (
            notificationsQuery.data?.records.map((notification) => {
              const { entity, cards, middle } = extractEntityCardsNMiddle(
                notification.message
              );
              return (
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ pb: 2 }}
                  my={0.5}
                >
                  <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FiberManualRecord
                      sx={{ fontSize: 10, color: CIMS_PRIMARY.GOLD }}
                    />
                    <Typography
                      className="font-aspekta-500"
                      color={CIMS_PRIMARY.GRAY}
                      fontSize={13}
                    >
                      <span
                        className="font-aspekta-600"
                        style={{ color: CIMS_PRIMARY.BLACK }}
                      >
                        {toLowerCase(entity)}
                      </span>{' '}
                      {middle}f{' '}
                      <span
                        className="font-aspekta-600"
                        style={{ color: CIMS_PRIMARY.RED }}
                      >
                        {cards}.
                      </span>{' '}
                    </Typography>
                  </Stack>
                  <Typography
                    className="font-aspekta-400"
                    color={CIMS_PRIMARY.GRAY}
                    fontSize={10}
                    ml={2.5}
                  >
                    {Dayjs(notification.updatedAt).format('HH:mm DD/MM/YYYY')}
                  </Typography>
                </Stack>
              );
            })
          ) : (
            <>
              <Typography fontStyle={'italic'}>No Notifications</Typography>
            </>
          )}
        </Box>
      </CIMS_Modal>
    </>
  );
};

export default Notifications;
