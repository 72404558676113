/** @format */

import { Box, Button, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { PRIMARY } from '../../../../constants/style';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import { ICategory, IReportConfigReponse } from '../../../../types/reports';
import { convertCamelCase } from '../../../../utils/stringUtils';
import { VegaCard, VegaTable } from '../../../common';
import { useAllReports, useReportRuns, useReportsConfig } from '../../hooks';
import ReportsConfigModal from '../../reports/ReportsConfigModal';
import { getNextSchedule } from '../../utils/schedule.utils';

type IProps = {
  reportCategories: Record<string, ICategory> | null;
  authId?: string | null;
};

function Schedules({ reportCategories, authId }: IProps) {
  const { selectedProgram } = useProgramData();
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [initialReportConfig, setInitialReportConfig] =
    useState<IReportConfigReponse | null>(null);
  const programId = authId ?? selectedProgram?.programId;
  const { data: allReports } = useAllReports(programId);
  const { data: reportsConfig, refetch: refetchReportConfigs } =
    useReportsConfig(programId || '');

  const { data: reportRuns } = useReportRuns(programId);

  const scheduleRows = useMemo(() => {
    if (allReports && reportsConfig && reportRuns && reportCategories) {
      const rows = Object.keys(reportsConfig).reduce((pre, key) => {
        return [
          ...pre,
          {
            ...reportsConfig[key],
            catalogue: reportCategories[
              allReports[reportsConfig[key].reportId]?.categoryId
            ]
              ? reportCategories[
                  allReports[reportsConfig[key].reportId].categoryId
                ].reportCategory
              : 'CATALOGUE',
            reportName: allReports[reportsConfig[key].reportId]
              ? allReports[reportsConfig[key].reportId].reportName
              : 'NOT_FOUND',
            lastRunOn: dayjs()
              .hour(reportsConfig?.[key]?.hours)
              .minute(reportsConfig?.[key]?.minutes)
              .second(0),
            nextScheduleRun: getNextSchedule(reportsConfig[key]),
          },
        ];
      }, [] as any);

      rows.sort((a: any, b: any) => {
        const keyA = new Date(a.lastRunOn);
        const keyB = new Date(b.lastRunOn);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      return rows;
    }

    return [];
  }, [allReports, reportsConfig, reportCategories, reportRuns]);

  const columnDef: GridColDef[] = [
    {
      field: 'catalogue',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Catalogue
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return <Typography>{convertCamelCase(props.row.catalogue)}</Typography>;
      },
    },
    {
      field: 'reportName',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Report
        </Typography>
      ),
      flex: 1.5,
      renderCell: (props) => {
        return (
          <Typography>{convertCamelCase(props.row.reportName)}</Typography>
        );
      },
    },
    {
      field: 'createdBy',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Created By
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'lastRunOn',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Last Run On
        </Typography>
      ),
      flex: 1.2,
      renderCell: (props: any) => {
        console.log('Last run on date: ', props.row);
        return (
          <Typography variant="body1">
            {dayjs(props.row.lastRunOn).format('DD/MM/YYYY HH:mm')}
          </Typography>
        );
      },
    },
    {
      field: 'nextScheduleRun',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Next Schedule Run
        </Typography>
      ),
      flex: 1.3,
      renderCell: (props: any) => {
        return (
          <Typography variant="body1">
            {props.row.nextScheduleRun.format('DD/MM/YYYY HH:mm')}
          </Typography>
        );
      },
    },
    {
      field: 'status',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Status
        </Typography>
      ),
      flex: 0.6,
      renderCell: (props) => {
        return (
          <Typography>
            {convertCamelCase(
              props.row.status === 'COMPLETED' ? 'Success' : props.row.status
            )}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      renderHeader: () => (
        <Typography variant="body2" fontWeight={'bold'}>
          Actions
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Box>
            <Button
              size="small"
              sx={{
                bgcolor: PRIMARY.light,
                ':hover': { bgcolor: PRIMARY.light },
                '&.Mui-disabled': {
                  color: PRIMARY.light,
                  bgcolor: PRIMARY.lighter,
                },
                color: 'white',
              }}
              onClick={() => {
                setOpenScheduleModal(true);
                setInitialReportConfig(props.row);
              }}
            >
              Update
            </Button>
          </Box>
        );
      },
      flex: 1,
    },
  ];

  return (
    <>
      <VegaCard fullHeight noPad sx={{ mt: 4 }}>
        <VegaTable
          data={scheduleRows}
          columnDef={columnDef}
          idColumn={'reportConfigId'}
        />
      </VegaCard>
      <ReportsConfigModal
        openScheduleModal={openScheduleModal}
        setOpenScheduleModal={setOpenScheduleModal}
        initialReportConfig={initialReportConfig}
        fetchReportsConfig={refetchReportConfigs}
      />
    </>
  );
}

export default Schedules;
