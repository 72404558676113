import { Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  RepaymentPolicyInterface,
  RepaymentWaterfall,
} from '../../../types/policy';
import { IRowData, PolicySummaryTable } from './common/SummaryTable';

interface Props {
  policy?: RepaymentPolicyInterface | null;
}

function RepaymentPolicySummary({ policy }: Props) {
  const [billedSummary, setBilledSummary] = useState<RepaymentWaterfall[]>([]);
  const [unbilledSummary, setUnbilledSummary] = useState<RepaymentWaterfall[]>(
    []
  );

  useEffect(() => {
    if (policy) {
      const [first, second] = _.chunk(
        policy.repayment_waterfall,
        policy.repayment_waterfall.length / 2
      );
      setBilledSummary(first);
      setUnbilledSummary(second);
    }
  }, [policy]);

  const getSummaryRows = (repaymentWaterfall: RepaymentWaterfall[]) => {
    const summaryData: IRowData[] = [];
    repaymentWaterfall.forEach((item) => {
      summaryData.push({
        rowData: [
          _.startCase(_.toLower(item.amount_type)),
          item.priority_order.toString(),
        ],
      });
    });
    return summaryData;
  };

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} spacing={1}>
        <div style={{ flex: 1 }}>
          <PolicySummaryTable
            headers={[]}
            rows={[{ rowData: ['Auto Debit no of Retries', '0'] }]}
          />
        </div>
        <div style={{ flex: 1 }}>
          <PolicySummaryTable
            headers={[]}
            rows={[{ rowData: ['Min no. of Installments', '10'] }]}
          />
        </div>
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <div style={{ flex: 1 }}>
          <PolicySummaryTable
            headers={['Amount Type', 'Priority Order']}
            rows={getSummaryRows(billedSummary)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <PolicySummaryTable
            headers={['Amount Type', 'Priority Order']}
            rows={getSummaryRows(unbilledSummary)}
          />
        </div>
      </Stack>
    </Stack>
  );
}

export default RepaymentPolicySummary;
