import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo } from 'react';
import { VPAccordion } from '../../../ui-components/common';
import DataTile from '../DataTile';
import { fetchKYCFlow } from '../../../api/los';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { formatOnboardingFlow } from '../../../utils/onboardingUtils';
import { setOnboardingKycFlow } from '../../../store/common/programManagementSlice';
import type {
  VegaKycBaseStep,
  VegaKycStepPartner,
} from '../../ApplicationManagment/KYCflow/KYCFlowDataTypes';
import { convertCamelCase } from '../../../utils/stringUtils';
import Grid from '@mui/material/Grid';
import capitalize from 'lodash/capitalize';
import { useSnackbar } from '../../../providers/SnackbarProvider';

type Props = {};

const getPartnersNames = (partners: VegaKycStepPartner[] | undefined) => {
  return partners
    ? partners
        .filter((partner) => partner.status === 'ACTIVE')
        .map((partner) => capitalize(partner.name))
        .join(', ')
    : '-';
};

const KycAccordion = ({
  title,
  isActive,
  children,
}: {
  title: string;
  isActive: boolean;
  children?: React.ReactElement;
}) => (
  <VPAccordion title={title} disabled={!isActive}>
    <VPAccordion.TailEnd>
      <Box
        sx={{
          px: 2,
          py: 0.5,
          backgroundColor: isActive ? '#1047DC1A' : 'transparent',
          borderRadius: '60px',
        }}
      >
        <Typography
          className="font-aspekta-700"
          fontSize={10}
          textTransform="uppercase"
          color={isActive ? '#1047DC' : '#7B8694'}
        >
          {isActive ? 'Active' : 'Inactive'}
        </Typography>
      </Box>
    </VPAccordion.TailEnd>
    <VPAccordion.Body>{children}</VPAccordion.Body>
  </VPAccordion>
);

const KYCFlowBody = ({ step }: { step: VegaKycBaseStep }) => {
  const partners = getPartnersNames(step.metaData.partners);
  const workflow = step.workFlowDecision
    ? convertCamelCase(step.workFlowDecision)
    : '-';
  const subSteps = step.metaData.subSteps;
  const isApplicationForm = step.name === 'APPLICATION_FORM';

  if (isApplicationForm) {
    const forms = step.metaData.subSteps?.map((subStep) => ({
      title: convertCamelCase(subStep.name),
      value: subStep.metaData?.fields?.map((field) => field.name).join(', '),
    }));
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 5,
        }}
      >
        {forms?.map((form, index) => (
          <DataTile key={index} title={form.title} value={form.value} />
        ))}
      </Box>
    );
  }

  if (subSteps) {
    return (
      <>
        <Grid container rowSpacing={2}>
          {subSteps.map((subStep, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 13,
                    color: '#1B1D22',
                  }}
                >
                  {convertCamelCase(subStep.name)}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    pt: 1.5,
                  }}
                >
                  <DataTile
                    title="Partners"
                    value={getPartnersNames(subStep.metaData.partners)}
                  />
                  <DataTile
                    title="Workflow Decisioning"
                    value={
                      subStep.workFlowDecision
                        ? convertCamelCase(subStep.workFlowDecision)
                        : '-'
                    }
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 5,
      }}
    >
      <DataTile title="Partners" value={partners ?? '-'} />
      <DataTile title="Workflow Decisioning" value={workflow ?? '-'} />
    </Box>
  );
};

const formatTableData = (flow: VegaKycBaseStep[]) => {
  return flow.map((item) => {
    return {
      order: item.order + 1,
      title: convertCamelCase(item.name),
      isActive: item.status && item.status === 'ACTIVE',
      children: <KYCFlowBody step={item} />,
    };
  });
};

function KycFlow({}: Props) {
  const { selectedProgram, onboardingKycFlow } = useAppSelector(
    (state) => state.programManagement
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const programId = selectedProgram?.programId;

  const tableData = useMemo(
    () => (onboardingKycFlow ? formatTableData(onboardingKycFlow) : []),
    [onboardingKycFlow]
  );

  useEffect(() => {
    if (!programId || onboardingKycFlow) return;
    fetchKYCFlow(programId)
      .then((res) => {
        const formattedFlow = formatOnboardingFlow(res.flow);
        if (formattedFlow.length) {
          dispatch(setOnboardingKycFlow(formattedFlow));
        }
      })
      .catch((err) => {
        setSnackbar('Failed to fetch onboarding flow!', 'error');
      });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography color="#1B1D22" fontSize={17} className="font-aspekta-700">
        KYC Flow
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {tableData.map(({ title, isActive, children, order }, index) => (
          <KycAccordion
            title={`Step ${order}: ${title}`}
            isActive={isActive}
            key={title}
          >
            {children}
          </KycAccordion>
        ))}
      </Box>
    </Box>
  );
}

export default KycFlow;
