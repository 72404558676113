import { Box, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../services/ChargeBackService';
import {
  ChargebackStatus,
  RemarksType,
  UpdateChargebackStatusRequest,
} from '../../../types/chargeBack';
import { VPTextField } from '../../../ui-components/common';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useChargeBack } from '../hooks';
import { GenericModal } from './GenericModal';

interface IProps {
  onSubmit?: any;
  triggerLabel?: string;
  initialStatus?: ChargebackStatus;
}

export function RejectedByNetworkModal({
  triggerLabel = 'Rejected by Network',
  initialStatus,
}: IProps) {
  const { selectedChargeBack, listChargeBackForProgram } = useChargeBack();
  const { setSnackbar } = useSnackbar();

  const hookForm = useForm<UpdateChargebackStatusRequest>({
    defaultValues: {
      chargebackId: selectedChargeBack?.id,
      remarksType: RemarksType.REMARKS,
      status: initialStatus || ChargebackStatus.REJECTED_BY_NETWORK,
    },
  });

  const handleCancelChargeback = (data: UpdateChargebackStatusRequest) => {
    ChargebackService.updateChargebackStatus(data)
      .then((res) => {
        setSnackbar('Successfully cancelled chargeback', 'success');
        listChargeBackForProgram.refetch();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Error while cancelling chargeback'),
          'error'
        );
      });
  };

  return (
    <>
      <GenericModal
        onSubmit={() => handleCancelChargeback(hookForm.getValues())}
        hasConfirmCTA
        triggerLabel={triggerLabel}
        header="Cancel Request"
        confirmCTAProps={{
          disabled: !hookForm.formState.isValid,
        }}
        confirmText="Cancel"
        hasCancelCTA={false}
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <Stack spacing={2}>
            <Controller
              name="remarks"
              control={hookForm.control}
              render={({ field }) => (
                <VPTextField
                  header="remark (optional)"
                  variant="outlined"
                  {...field}
                  placeholder="Add Remark"
                />
              )}
            />
          </Stack>
        </Box>
      </GenericModal>
    </>
  );
}
