import ReactApexChart from 'react-apexcharts';

type IProps = {
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  options?: ApexCharts.ApexOptions | undefined;
};

function BarCharts({ options, series }: IProps) {
  return (
    <ReactApexChart
      options={{
        chart: {
          type: 'bar',
          height: 380,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '60%',
            // endingShape: 'rounded',
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            // formatter: function (val) {
            //   return 'Q' + dayjs(val).quarter();
            // },
          },
          group: {
            style: {
              fontSize: '10px',
              fontWeight: 700,
            },
            groups: [
              { title: '2019', cols: 4 },
              { title: '2020', cols: 4 },
            ],
          },
        },
        title: {
          text: 'Grouped Labels on the X-axis',
        },
        tooltip: {
          x: {
            // formatter: function (val) {
            //   return (
            //     'Q' + dayjs(val).quarter() + ' ' + dayjs(val).format('YYYY')
            //   );
            // },
          },
        },
      }}
      series={series}
      type="bar"
      height={380}
    />
  );
}

export default BarCharts;
