import { TextField, InputAdornment } from '@mui/material';
import { ReactNode } from 'react';

export const CommentTextField = (data: {
  comment?: string;
  didUpdateComment: (comment: string) => void;
  endAdornment: ReactNode;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '.75rem',
        backgroundColor: '#F7F7F7',
        borderRadius: 12,
      }}
    >
      <TextField
        fullWidth
        size="medium"
        variant="standard"
        placeholder="Add a comment"
        focused
        value={data.comment}
        onChange={(e) => data.didUpdateComment(e.target.value)}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end" style={{ margin: '.125rem' }}>
              {data.endAdornment}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
