import {
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import React from 'react';

interface IProps {
  bgColor: string;
}

const MaxLimit = ({ bgColor }: IProps) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? bgColor : '#308fe8',
    },
  }));
  return (
    <>
      <Typography>No of Credit Card Reached at Max Limit</Typography>
      <BorderLinearProgress variant="determinate" value={50} />
      <Typography>200000</Typography>
      <Typography>Available Credit Limit </Typography>
    </>
  );
};

export default MaxLimit;
