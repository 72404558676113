import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { PRIMARY } from '../../../../constants/style';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import { ICategory } from '../../../../types/reports';
import { downloadURI } from '../../../../utils/download';
import { convertCamelCase } from '../../../../utils/stringUtils';
import { VegaCard, VegaTable } from '../../../common';
import { useAllReports, useReportRuns } from '../../hooks';

type IProps = {
  reportCategories: Record<string, ICategory> | null;
};

function PastReports({ reportCategories }: IProps) {
  const { selectedProgram } = useProgramData();

  const { data: allReports } = useAllReports(selectedProgram?.programId);
  const { data: reportRuns } = useReportRuns(selectedProgram?.programId);

  const pastReportsRows = useMemo(() => {
    if (allReports && reportRuns && reportCategories) {
      const rows = Object.keys(reportRuns).reduce((pre, key) => {
        return [
          ...pre,
          {
            ...reportRuns[key],
            reportName: allReports[reportRuns[key]?.reportId]
              ? allReports[reportRuns[key].reportId].reportName
              : 'NOT_FOUND',
            catalogue: reportCategories[
              allReports[reportRuns[key]?.reportId]?.categoryId
            ]
              ? reportCategories[
                  allReports[reportRuns[key].reportId].categoryId
                ].reportCategory
              : 'LENDING',
            generatedBy: 'system@system.gmail',
          },
        ];
      }, [] as any);

      rows.sort((a: any, b: any) => {
        const keyA = new Date(a.generatedOn);
        const keyB = new Date(b.generatedOn);

        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      return rows;
    }
    return [];
  }, [allReports, reportRuns, reportCategories]);

  return (
    <VegaCard fullHeight noPad sx={{ mt: 4 }}>
      <VegaTable
        data={pastReportsRows}
        columnDef={pastReportsColumnDef}
        idColumn={'reportRunId'}
      />
    </VegaCard>
  );
}

export default PastReports;

export const pastReportsColumnDef: GridColDef[] = [
  {
    field: 'generatedOn',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Generated On
      </Typography>
    ),
    flex: 1.3,
    renderCell: (props: any) => {
      return (
        <Typography variant="body1">
          {dayjs(props.row.generatedOn).format('DD/MM/YYYY HH:mm')}
        </Typography>
      );
    },
  },
  {
    field: 'generatedReports',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'} textAlign="start">
        Generated Reports
      </Typography>
    ),
    renderCell: (props: any) => {
      return (
        <Box textAlign={'center'} width="100%">
          - -
        </Box>
      );
    },
    flex: 1,
  },
  {
    field: 'reportName',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Report Name
      </Typography>
    ),
    flex: 1.5,
    renderCell: (props) => {
      return <Typography>{convertCamelCase(props.row.reportName)}</Typography>;
    },
  },
  {
    field: 'catalogue',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Catalogue
      </Typography>
    ),
    flex: 1,
    renderCell: (props) => {
      return <Typography>{convertCamelCase(props.row.catalogue)}</Typography>;
    },
  },
  {
    field: 'generatedBy',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Generated By
      </Typography>
    ),
    flex: 1.5,
  },
  {
    field: 'status',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Status
      </Typography>
    ),
    flex: 1,
    renderCell: (props) => {
      return <Typography>{convertCamelCase(props.row.status)}</Typography>;
    },
  },
  {
    field: 'reportLink',
    renderHeader: () => (
      <Typography variant="body2" fontWeight={'bold'}>
        Actions
      </Typography>
    ),
    renderCell: (props: any) => {
      return (
        <Button
          size="small"
          sx={{
            bgcolor: PRIMARY.light,
            ':hover': { bgcolor: PRIMARY.light },
            '&.Mui-disabled': {
              color: PRIMARY.light,
              bgcolor: PRIMARY.lighter,
            },
            textTransform: 'capitalize',
            cursor: props.row.status === 'FAILED' ? 'no-drop' : 'pointer',
            color: 'white',
          }}
          onClick={() => {
            downloadURI(props.row.reportLink, props.row.reportName);
          }}
          disabled={props.row.status === 'FAILED'}
        >
          Download
        </Button>
      );
    },
    flex: 1,
  },
];
