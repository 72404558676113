import CIMS_Header from '../../components/common/CIMS_Header';
import { CardIndentationOrderProvider } from '../../modules/CardIndentationOrder';
import CardIndentationOrderList from '../../modules/CardIndentationOrder/List/CardIndentationOrderList';
import ListDeliveryOrders from '../../modules/Vendor/Delivery/ListDeliveryOrders';

function DeliveryOrderRequests() {
  return (
    <div>
      <CIMS_Header text="Delivery Order Request" />
      <CardIndentationOrderProvider>
        <CardIndentationOrderList />
      </CardIndentationOrderProvider>
    </div>
  );
}

export default DeliveryOrderRequests;
