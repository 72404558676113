import { Box, Grid, Typography } from '@mui/material';
import VegaLabel from '../../../../components/common/VegaLabel';
import { VegaCard, VegaTable } from '../../../../components/common';
import { toLowerCase } from '../../../../constants/commonFunction';
import { BLUE, BORDER_GRAY, GREY } from '../../../../constants/style';
import { dateFormat } from '../../../variable';

interface IProps {
  onStatusChange: (data: any, status: string) => void;
  state: any;
}

const ListOfTeamManagementHQ = ({ state, onStatusChange }: IProps) => {
  const columnDef = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 0.6,
    },
    {
      field: 'userId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          User ID
        </Typography>
      ),

      flex: 0.5,
    },
    {
      field: 'email',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Email
        </Typography>
      ),

      flex: 1,
    },
    {
      field: 'userRoles',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Alloted Roles
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Box
            sx={{
              border: BORDER_GRAY,
              borderRadius: '5px',
              // p: 0.5,
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {props.row.userRoles.length ? (
              props.row.userRoles.map((role: any) => (
                <div className="" title={toLowerCase(role?.status)}>
                  <VegaLabel
                    text={toLowerCase(role?.roleName)}
                    color={'primary'}
                  />
                </div>
              ))
            ) : (
              <VegaLabel text="No Roles" color={'primary'} />
            )}
          </Box>
        );
      },
    },
    {
      field: 'activationDate',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Activation Date
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <VegaLabel text={dateFormat(props.row.createdAt)} color={'success'} />
        );
      },
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        const checkerMakerStatus = props.row.userRoles[0]?.checkerMakerStatus;
        return (
          <VegaLabel
            text={toLowerCase(checkerMakerStatus ?? '-')}
            color={'primary'}
          />
        );
      },
    },
  ];

  return (
    <VegaCard fullHeight noPad>
      <VegaTable data={state} columnDef={columnDef} idColumn={'userId'} />
    </VegaCard>
  );
};

export default ListOfTeamManagementHQ;
