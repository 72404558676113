import { Add } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import {
  IterationType,
  PointBasedMilestone,
} from '../../../../../../../../../types/RewardsLoyalty';
import VegaFormInputFieldV2 from '../../../../../../../../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../../../../../../../common/v2/VegaPrimaryButton';
import VegaSelectV2, {
  VegaSelectOptionV2,
} from '../../../../../../../../common/v2/VegaSelectV2';
import { useCampaignRule } from '../../../../../../../providers/CampaignRuleProvider';
import PointBasedMilestoneInput from './PointBasedMilestoneInput';

function PointBasedMilestoneRewards() {
  const {
    milestoneData,
    addMilestone,
    deleteMilestone,
    updateMilestone,
    updateMilestoneIteration,
  } = useCampaignRule();

  function onMilestoneDataChange(
    index: number,
    data: Partial<PointBasedMilestone>
  ) {
    updateMilestone({ index: index, updatedValue: data });
  }

  function onAddMilestoneClick() {
    addMilestone({});
  }

  function onIterationChange(value: string) {
    updateMilestoneIteration(value);
  }

  useEffect(() => {
    if ((milestoneData.milestones ?? []).length <= 0) {
      addMilestone({});
    }
  }, [milestoneData]);

  return (
    <Stack spacing={2}>
      <Grid container columns={6}>
        <Grid item xs={1}>
          <VegaFormInputFieldV2 label="Iteration">
            <VegaSelectV2
              sx={{ backgroundColor: 'white' }}
              options={iterationOptions()}
              onChange={(e) => onIterationChange(e.target.value as string)}
              value={milestoneData.iteration ?? ''}
            />
          </VegaFormInputFieldV2>
        </Grid>
      </Grid>

      {milestoneData.milestones?.map((item, index) => {
        return (
          <PointBasedMilestoneInput
            index={index}
            onSubmit={(data) => {
              onMilestoneDataChange(index, data);
            }}
            value={item}
            onDelete={function (): void {
              deleteMilestone(index);
            }}
          />
        );
      })}
      <Stack direction={'row'} alignItems="center" justifyContent={'start'}>
        <VegaPrimaryButton
          variant="text"
          text="Add Milestone"
          startIcon={<Add />}
          onClick={onAddMilestoneClick}
          sx={{
            color: 'rgba(255, 123, 1, 1)',
          }}
        />
      </Stack>
    </Stack>
  );
}

export default PointBasedMilestoneRewards;

const iterationOptions = () =>
  Object.values(IterationType).map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item,
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
