import { CARD_NETWORK } from './Card';

export interface IProgramStatus {
  DRAFT: 'DRAFT';
  PRE_PROD: 'PRE_PROD';
  PROD: 'PROD';
}

export enum IProgramTypeEnum {
  ALL = 'ALL',
  RETAIL = 'RETAIL',
  CORPORATE = 'CORPORATE',
}

export interface IProgram {
  id: string;
  clientId: string;
  name: string;
  programType: IProgramTypeEnum;
  cardNetwork: CARD_NETWORK;
  agreement: string | null;
  tnc: string | null;
  status: IProgramStatus;
  createdAt: string;
  updatedAt: string;
}
