import {
  Box,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';
import { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import { PRIMARY } from '../../constants/style';
import VegaHeader from './VegaHeader';

export type InputProps = {
  value: number | string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  key: string;
  type?: HTMLInputTypeAttribute;
  size?: 'small' | 'medium' | undefined;
  textFieldWidth?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
  multiline?: boolean;
  rows?: number;
  startAdornment?: React.ReactNode;
  maxNumber?: number;
  fontSize?: number;
  minNumber?: number;
  maxLength?: number;
  focused?: boolean;
  error?: boolean;
  isFixedHeight?: boolean;
  errorHelperText?: string;
  headerText?: string;
  name?: any;
  errorText?: any;
};

export type IProps = {
  textFieldWidth?: number;
  startAdornment?: React.ReactNode;
  maxNumber?: number;
  minNumber?: number;
  maxLength?: number;
  isFixedHeight?: boolean;
  headerText?: string;
  errorText?: string;
  name?: any;
  errorTextSx?: SxProps;
} & TextFieldProps;

const VegaTextField = ({
  className,
  textFieldWidth,
  startAdornment,
  maxNumber,
  minNumber,
  maxLength,
  headerText,
  isFixedHeight,
  errorText,
  errorTextSx,
  name,
  ...rest
}: IProps) => {
  const { type, size, autoComplete, sx, ...restProps } = rest;
  return (
    <Box sx={{ position: 'relative' }}>
      {headerText?.length && <VegaHeader text={headerText} />}
      <TextField
        className="font-aspekta-500"
        onWheel={(e: any) => e.target.blur()}
        autoComplete={autoComplete || 'false'}
        fullWidth={!textFieldWidth}
        InputLabelProps={{
          style: { color: '#AFAFAF' },
        }}
        InputProps={
          isFixedHeight
            ? {
                style: {
                  borderRadius: '9px',
                  height: '35px',
                },
                startAdornment: startAdornment,
              }
            : {
                style: {
                  borderRadius: '9px',
                  height: '50px',
                },
                startAdornment: startAdornment,
              }
        }
        type={type}
        inputProps={
          type === 'number'
            ? {
                max: maxNumber,
                min: minNumber,
              }
            : {
                maxLength: maxLength,
              }
        }
        size={size ?? 'medium'}
        sx={{
          '& .MuiInputBase-input': {
            fontFamily: `var(--font-Aspekta-450) !important`,
            fontSize: '13px',
            lineHeight: '120%',
            color: '#1B1D22',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px',
            '&.Mui-focused fieldset': {
              borderColor: '#C5C8D0',
              borderWidth: '1px',
              borderRadius: '6px',
            },
            ':hover fieldset': {
              borderColor: '#C5C8D0',
            },
          },
          ...(isFixedHeight
            ? {
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, 6px) scale(1)',
                  // fontFamily: 'Aspekta-450 !important',
                  // fontSize: 13,
                },
              }
            : {
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, 13px) scale(1)',
                },
              }),
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
          ...(textFieldWidth && { width: textFieldWidth }),
          '& .MuiFormLabel-root': {
            fontFamily: 'var(--font-Aspekta-500) !important',
            fontSize: 13,
            '&.Mui-focused': {
              color: `${PRIMARY.main} !important`,
            },
          },

          ...sx,
        }}
        {...restProps}
      />
      {errorText && (
        <Typography
          className="font-aspekta-500"
          color={'red'}
          fontSize={13}
          sx={{
            position: 'absolute',
            right: 0,
            bottom: '-13px',
            ...errorTextSx,
          }}
        >
          {errorText}
        </Typography>
      )}
    </Box>
  );
};

export default VegaTextField;
