/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { listOfTicketsForAccount } from '../../../../../api/transaction';
import { NEW_PRIMARY } from '../../../../../constants/style';
import { dateFormat } from '../../../../../mcc/variable';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useSupport } from '../../../../../providers/SupportProvider';
import { ChargebackService } from '../../../../../services/ChargeBackService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import Vega_DataGridFilterSection from '../../../../common/v3/Vega_DataGridFilterSection';
import Vega_Datagrid from '../../../../common/v3/Vega_Datagrid';
import Vega_Select from '../../../../common/v3/Vega_Select';
import Vega_Text from '../../../../common/v3/Vega_Text';
import { ViewDisputeDetailsModal } from '../../../../support/ChargeBack/ViewDisputeDetails';

interface ITicketsProps {
  data: any;
  loading: boolean;
}

const Tickets = () => {
  const { accountData } = useSupport();
  const { clientId } = useClientAuth();
  const [selectedTicketType, setSelectedTicketType] =
    useState<string>('CHARGEBACK');
  const { setSnackbar } = useSnackbar();
  const [ticketListState, setTicketListState] = useState<ITicketsProps>({
    data: null,
    loading: false,
  });

  const listChargeBackForAccountId = useQuery({
    queryKey: ['listChargeBackforAccountId', accountData.accountId],
    queryFn: () => {
      return ChargebackService.listChargeback({
        accountId: accountData.accountId,
        clientId: clientId || '',
      });
    },
    enabled: !!clientId && !!accountData.accountId,
    keepPreviousData: true,
  });

  const rowData = useMemo(() => {
    if (selectedTicketType === 'CHARGEBACK') {
      return listChargeBackForAccountId.data?.records;
    }
    return [];
  }, [
    selectedTicketType,
    clientId,
    accountData.accountId,
    listChargeBackForAccountId.data?.records,
  ]);

  const COLUMN_DEF: GridColumns = [
    {
      field: 'createdAt',
      headerName: 'Raised on',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
      valueGetter: (props) => dateFormat(props.row.createdAt),
      renderCell: (props) => {
        return <Vega_Text text={dateFormat(props.row.createdAt)} />;
      },
    },

    {
      field: 'raisedBy',
      headerName: 'Raised by',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'id',
      headerName: 'Chargeback ID',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'tatStatus',
      headerName: 'Transaction Status',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'Transaction Status',
      headerName: 'Dispute details',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        // return <Vega_Button text="View Details" />;
        return (
          <ViewDisputeDetailsModal
            triggerLabel="View Details"
            chargeBackDto={props.row}
          />
        );
      },
    },
  ];

  const getTicketsForAccount = async (accountId: string) => {
    setTicketListState({ data: [], loading: true });
    try {
      const response = await listOfTicketsForAccount(accountId);
      setTicketListState({ data: response, loading: false });
    } catch (error) {
      setTicketListState({ data: [], loading: false });
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getTicketsForAccount(accountData.accountId);
    }
  }, []);
  return (
    <>
      <Vega_Datagrid
        data={rowData || []}
        idColumn="id"
        columns={COLUMN_DEF}
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Vega_Text
                    text={'Tickets'}
                    fontWeight={600}
                    color={NEW_PRIMARY.BLACK}
                    fontSize={17}
                  />
                  <Vega_Select
                    sx={{ width: '140px' }}
                    options={['CHARGEBACK']}
                    value={selectedTicketType}
                    onChange={(e) =>
                      setSelectedTicketType(e.target.value as string)
                    }
                  />
                </Stack>
                <Vega_DataGridFilterSection />
              </Stack>
            );
          },
        }}
      />
    </>
  );
};

export default Tickets;
