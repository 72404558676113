import { Box, Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaLabel from '../../../../components/common/VegaLabel';
import VegaText from '../../../../components/common/VegaText';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { initiateCheckerMakerForfundLoad } from '../../../Back-office/api/transaction';
import {
  DEFAUL_DATA,
  IDetailsProps,
} from '../../../Back-office/components/MCCcardTransaction/ListOfCardTransaction';
import { dateFormat } from '../../../variable';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import CardReloadDetails from '../../../Back-office/components/MCCcardTransaction/Components/CardReloadDetails';

interface IProps {
  reloadCardDraftsList: Array<any>;
  getFundList: () => Promise<void>;
}

function ListOfReloadCardDraftRequests({
  reloadCardDraftsList,
  getFundList,
}: IProps) {
  const { userId, mccPlatformId: corporateId } = useClientAuth();
  const [listLoading, setListLoading] = useState(false);
  const { setSnackbar } = useSnackbar();
  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [showData, setShowData] = useState<IDetailsProps>({ ...DEFAUL_DATA });

  // const [reloadCardDraftsList, setReloadCardDraftsList] = useState([]);

  columnDef[columnDef.length - 2].renderCell = (props) => {
    return (
      <>
        {props.row.fundLoadOrderStatus === 'UNDER_REVIEW' ? (
          <VegaContainedButton text="Requested" isPrimary disabled />
        ) : props.row.fundLoadOrderStatus === 'DRAFT' ? (
          <VegaContainedButton
            text="Send Reviewer"
            onClick={() => onSendChecker(props.row)}
            isPrimary
          />
        ) : (
          ''
        )}
      </>
    );
  };
  columnDef[columnDef.length - 1].renderCell = (props) => {
    const fundLoadStatus = props.row.fundLoadOrderStatus;
    const makerCheckerStatus = props.row.makerCheckerStatus;
    return (
      <>
        {(fundLoadStatus === 'DRAFT' || fundLoadStatus === 'FUND_RELOADED' ||
          makerCheckerStatus === 'APPROVED' ||
          fundLoadStatus === 'UNDER_REVIEW' ||
          makerCheckerStatus === 'CHECKER_PENDING') && (
          <VegaContainedButton
            text="View"
            onClick={() => showDetails(props.row, true)}
            isPrimary
          />
        )}
      </>
    );
  };

  const showDetails = (data: any, isShow: boolean) => {
    setShowData({ ...data, accountId: data.id });
    setOpen(!open);
    setShow(isShow);
  };

  const onSendChecker = (data: IDetailsProps) => {
    console.log('data', data);
    const obj = {
      fundLoadOrderId: data.id,
      userId,
      initialStatus: 'REVIEWER_PENDING',
    };
    initiateCheckerMakerForfundLoad(obj)
      .then((res) => {
        setSnackbar('Submitted for review successfully!');
        setListLoading(true);
        getFundList().finally(() => {
          setListLoading(false);
        });
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to submit'),
          'error'
        );
      });
  };

  return (
    <>
      <VegaCard noPad>
        <Box p={2}>
          <VegaText text="List of draft/under-review requests" />
        </Box>
        <VegaTable
          columnDef={columnDef}
          data={reloadCardDraftsList}
          idColumn="id"
        />
      </VegaCard>
      <VegaDrawer
        open={open}
        isClose
        header={'Reload Details'}
        closeText={'Cancel'}
        onClose={() => setOpen(!open)}
        onSubmit={() => {}}
      >
        <CardReloadDetails
          state={showData}
          handleChange={() => {}}
          isShow={true}
        />
      </VegaDrawer>
    </>
  );
}

export default ListOfReloadCardDraftRequests;

const columnDef: GridColumns = [
  {
    field: 'customerId',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Customer Name
      </Typography>
    ),
    flex: 1,
    renderCell: (props) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        return (
          <Typography>{accountConsolidatedDto?.customer?.firstName}</Typography>
        );
      }
    },
  },
  {
    field: 'cardNo',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Card No
      </Typography>
    ),
    flex: 1,
    renderCell: (props: any) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        const card = accountConsolidatedDto?.card;
        if (!card || card === null) {
          return;
        } else {
          return <VegaLabel text={card?.cardNumber ?? '--'} color="primary" />;
        }
      }
    },
  },

  {
    field: 'expiryDate',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Card Expiry
      </Typography>
    ),
    flex: 0.5,
    renderCell: (props: any) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        const card = accountConsolidatedDto?.card;
        if (!card || card === null) {
          return;
        } else {
          return <VegaLabel text={card?.expiryDate ?? '--'} color="primary" />;
        }
      }
    },
  },
  {
    field: 'cardstatus',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Card Status
      </Typography>
    ),
    flex: 0.6,
    renderCell: (props: any) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        const card = accountConsolidatedDto?.card;
        if (!card || card === null) {
          return;
        } else {
          return (
            <VegaLabel
              text={toLowerCase(card?.cardStatus ?? '--')}
              color="primary"
            />
          );
        }
      }
    },
  },
  // {
  //   field: 'accountId',
  //   renderHeader: () => (
  //     <Typography className="font-aspekta-500" variant="body2">
  //       Account Id
  //     </Typography>
  //   ),
  //   flex: 1,
  //   renderCell: (props: any) => {
  //     const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
  //     if (!accountConsolidatedDto || accountConsolidatedDto === null) {
  //       return;
  //     } else {
  //       const account = accountConsolidatedDto?.account;
  //       if (!account || account === null) {
  //         return;
  //       } else {
  //         return <Typography>{account?.id ?? '--'}</Typography>;
  //       }
  //     }
  //   },
  // },
  // {
  //   field: 'activationDate',
  //   renderHeader: () => (
  //     <Typography className="font-aspekta-500" variant="body2">
  //       Activation Date
  //     </Typography>
  //   ),
  //   flex: 0.5,
  //   renderCell: (props: any) => {
  //     const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
  //     if (!accountConsolidatedDto || accountConsolidatedDto === null) {
  //       return;
  //     } else {
  //       const card = accountConsolidatedDto?.card;
  //       if (!card || card === null) {
  //         return;
  //       } else {
  //         return (
  //           <VegaLabel
  //             text={dateFormat(card?.createdAt ?? '--')}
  //             color="primary"
  //           />
  //         );
  //       }
  //     }
  //   },
  // },
  {
    field: 'cardNetwork',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        Card Network
      </Typography>
    ),
    flex: 0.5,
    renderCell: (props: any) => {
      const accountConsolidatedDto = props?.row?.accountConsolidatedDto;
      if (!accountConsolidatedDto || accountConsolidatedDto === null) {
        return;
      } else {
        const networks = accountConsolidatedDto.card?.cardNetwork;
        if (!networks) {
          return;
        }
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      }
    },
  },
  // {
  //   field: 'status',
  //   renderHeader: () => (
  //     <Typography className="font-aspekta-500" variant="body2">
  //       {' '}
  //       Status
  //     </Typography>
  //   ),
  //   flex: 0.8,
  //   renderCell: (props: any) => {
  //     return (
  //       <VegaLabel
  //         text={toLowerCase(props.row.fundLoadOrderStatus)}
  //         color="primary"
  //       />
  //     );
  //   },
  // },

  {
    field: 'Request',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        {' '}
        Action
      </Typography>
    ),
    flex: 0.7,
  },
  {
    field: 'view',
    renderHeader: () => (
      <Typography className="font-aspekta-500" variant="body2">
        {' '}
        View/Edit
      </Typography>
    ),
    flex: 0.7,
  },
];
