import axios from "axios";
import { BASE_URL, DISPUTE } from "./endpoints";

const DISPUTE_BASE = BASE_URL + DISPUTE.BASE;

export const filterDisputeByFilters = (data: {
  filter: string;
  value: string;
}) => {
  return axios.post(DISPUTE_BASE + DISPUTE.FILTER, {
    ...data,
    page: 0,
    pageSize: 10,
  });
};

export const updateResolutionStatus = (data: {
  disputeId: string;
  resolutionStatus: "CUSTOMER_FAILURE" | "CUSTOMER_SUCCESS";
}) => {
  console.log(
    "resolution api ",

    DISPUTE_BASE +
      DISPUTE.STATUS +
      "/" +
      data.disputeId +
      "/" +
      data.resolutionStatus
  );
  return axios.put(
    DISPUTE_BASE +
      DISPUTE.STATUS +
      "/" +
      data.disputeId +
      "/" +
      data.resolutionStatus
  );
};

export const updateDisputeStatus = (disputeId: string) => {
  return axios.put(DISPUTE_BASE + DISPUTE.ACQUIRE_DISPUTE + "/" + disputeId);
};
