import { Grid } from '@mui/material';
import React from 'react';
import VegaSelect from '../../../../../components/common/VegaSelect';
import { BILLING_OPTIONS } from '../../../pages/MCCCorporateManagement';
import { getOrdinalForNo } from '../../../../../utils/numberUtils';
import { VegaTextField } from '../../../../../components/common';
import { IAccountDataProps } from '../ListOfMCCCorporatecustomer';
import MarkupFeePlanSelector from '../../../../components/MarkupFeePlanSelector';
import WalletFeePlanSelector from '../../../../components/WalletFeePlanSelector';
import OpenCreditChargePlanSelector from '../../../../components/OpenCreditChargePlanSelector';

interface IProps {
  handleChange: (name: keyof IAccountDataProps, value: string | number) => void;
  accountState: IAccountDataProps;
}

const AccountAdd = ({ handleChange, accountState }: IProps) => {
  return (
    <Grid container spacing={2} p={3}>
      <Grid item xs={12} sm={12}>
        <VegaSelect
          options={['PREPAID', 'POSTPAID']}
          key="accountType"
          selected={accountState.accountType ?? ''}
          label="Accont Type"
          placeholder="Account Type"
          onChange={(value) => handleChange('accountType', value)}
        />
      </Grid>

      {accountState.accountType === 'PREPAID' ? (
        <>
          <Grid item xs={12} sm={6}>
            <MarkupFeePlanSelector
              name="MARKUP_FEE_PLAN"
              selected={accountState.markupFeePlanId}
              handleChange={(value) => handleChange('markupFeePlanId', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WalletFeePlanSelector
              name="WALLET_CONFIGURATION_PLAN"
              selected={accountState.walltetCofigurationPlanId}
              handleChange={(value) =>
                handleChange('walltetCofigurationPlanId', value)
              }
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={12}>
            <OpenCreditChargePlanSelector
              name="OPEN_CREDIT_CHARGE_PLAN"
              selected={accountState.openCreditChargePlanId}
              handleChange={(value) =>
                handleChange('openCreditChargePlanId', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaSelect
              options={BILLING_OPTIONS.map(
                (opt) => `${opt} + days`
              )}
              key="billingCycle"
              selected={accountState.billingCycle ?? ''}
              label="Billing Cycle"
              placeholder="Billing Cycle"
              onChange={(value) => {
                handleChange('billingCycle', value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaTextField
              value={accountState.accountLimit}
              onChange={(e) => handleChange('accountLimit', e.target.value)}
              key="accountLimit"
              label={'Limit Amount'}
              type={'number'}
              placeholder="Enter Limit Amount In USD"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AccountAdd;
