import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { GREY } from '../../constants/style';
import { NON_ROUTES } from '../../routes';
import { VegaClientInterface } from '../../types/client';
import { VegaCard, VegaTextField } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaText from '../common/VegaText';
import Vega_HeaderWrapper from '../common/v3/Vega_HeaderWrapper';

interface IProps {
  state: VegaClientInterface | null;
  handleChange: (name: string, value: any, type?: string) => void;
}

const CompanyProfile = ({ state, handleChange }: IProps) => {
  return (
    <Box id={NON_ROUTES.COMPANY_PROFILE}>
      <VegaCard>
        <Vega_HeaderWrapper
          text={'Settings'}
          subText={'All resources which are used in the Vegapay ecosystem'}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <VegaTextField
              value={state?.clientName ?? ''}
              onChange={(e) =>
                handleChange('clientName', e.target.value, 'text')
              }
              key="clientName"
              label="Company Name"
              placeholder="Company Name"
              type={'text'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <VegaTextField
              value={state?.clientEmail ?? ''}
              onChange={(e) =>
                handleChange('clientEmail', e.target.value, 'email')
              }
              key="clientEmail"
              label="Company Email"
              placeholder="Company Email"
              type={'email'}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
            <VegaTextField
              value={state?.address ?? ''}
              onChange={(e) => handleChange('address', e.target.value, 'text')}
              key="address"
              label="Enter Registered Address"
              placeholder="Enter Registered Address"
              type={'text'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <VegaTextField
              value={state?.gstNumber ?? ''}
              onChange={(e) =>
                handleChange('gstNumber', e.target.value, 'text')
              }
              key="gstNumber"
              label="GST number"
              placeholder="GST number"
              type={'text'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <VegaTextField
              value={state?.panNumber ?? ''}
              onChange={(e) =>
                handleChange('panNumber', e.target.value, 'text')
              }
              key="panNumber"
              label="PAN Number"
              placeholder="PAN Number"
              type={'text'}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Stack alignItems={'end'} justifyContent="end">
              <VegaContainedButton text="Save" isPrimary />
            </Stack>
          </Grid>
        </Grid>
      </VegaCard>
    </Box>
  );
};

export default CompanyProfile;
