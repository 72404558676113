import React, { useState } from 'react';
import { IdentityFrm, TransactionFrm } from '../containers';
import {
  FileReturnIcon,
  ShieldCheckIcon,
} from '../../../ui-components/icon/VPIcons';
import VPMenuDrivenContent from '../../../ui-components/common/VPMenuDrivenContent';

type FRMProps = {};

const listItems = [
  { id: 'identityFrm', title: 'Identity FRM', Icon: ShieldCheckIcon },
  { id: 'transactionFrm', title: 'Transaction FRM', Icon: FileReturnIcon },
];

const ActiveTab = ({ id }: { id: string }) => {
  switch (id) {
    case 'identityFrm':
      return <IdentityFrm />;
    case 'transactionFrm':
      return <TransactionFrm />;

    default:
      return null;
  }
};
function FRM(props: FRMProps) {
  const [selectedListItem, setSelectedListItem] = useState(listItems[0].id);

  const handleListItemClick = (id: string) => {
    setSelectedListItem(id);
  };

  return (
    <VPMenuDrivenContent
      menuItems={listItems}
      selectedMenuItem={selectedListItem}
      onMenuItemChange={handleListItemClick}
    >
      <ActiveTab id={selectedListItem} />
    </VPMenuDrivenContent>
  );
}

export default FRM;
