/** @format */

import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Loading } from '../common';
export type ICustomerDataType = {
  showCustomerData?: any;
  loading?: boolean;
};

const ViewAccount = ({ showCustomerData, loading = false }: ICustomerDataType) => {
  if (!!loading) {
    return <Loading />;
  }
  if (!showCustomerData) {
    return <></>;
  }
  return (
    <>
      <Box sx={{ mx: 1, height: '100%', overflowX: 'auto' }}>
        {Object.keys(showCustomerData).map((value: any) => {
          return !showCustomerData[value] && showCustomerData[value] != 0 ? (
            <></>
          ) : (
            <Grid
              container
              sx={{
                my: 1,
                borderBottom: '1px solid lightgray',
                py: 1,
                px: 2,
              }}
            >
              <Grid item container spacing={2} sx={{ pt: 0 }}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }} sx={{ textTransform: 'capitalize' }}>
                    {value.split(/(?=[A-Z])/)[0]} {value.split(/(?=[A-Z])/)[1]} {value.split(/(?=[A-Z])/)[2]}{' '}
                    {value.split(/(?=[A-Z])/)[3]}{' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {value === 'createdAt' || value === 'updatedAt'
                      ? dayjs(showCustomerData[value]).format('MMMM D, YYYY')
                      : `${showCustomerData[value]}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </>
  );
};

export default ViewAccount;
