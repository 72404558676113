import { ArrowCircleDown } from '@mui/icons-material';
import { Box } from '@mui/material';
import _, { replace, startCase } from 'lodash';
import {
  BORDER_GRAY,
  BORDER_GRAY_DARK,
  ORANGE,
} from '../../../constants/style';
import VegaContainedButton from '../../common/VegaContainedButton';
import { getSelected } from './LeadsByDPDbucket';

interface IBucketProps {
  data: any[];
  state: any[];
  onSelect: (value: any, index: number) => void;
  onDownloadClick: () => void;
  disableDownloadButton: boolean;
}

const DPDbucketSelector = ({
  data,
  onSelect,
  state,
  onDownloadClick,
  disableDownloadButton,
}: IBucketProps) => {
  const getBucketName = (item: any) => {
    var bucketName = item.dpd;
    bucketName = bucketName.replace('BUCKET_', 'DPD ');
    bucketName = bucketName.replace('NOT_DELINQUENT', 'Not Deleinquent');
    bucketName = bucketName.replace('ABOVE_', 'Above ');
    bucketName = bucketName.replace('_', '-');
    return bucketName;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', flex: 1, flexWrap: 'wrap', gap: '8px' }}>
        {data.map((item: any, index: number) => (
          <Box
            sx={{
              minWidth: '150px',
              border: BORDER_GRAY_DARK,
              borderRadius: '10px',
              py: 1,
              mx: 1,
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '13px',
              ...(getSelected(item.dpd, state)
                ? {
                    bgcolor: ORANGE.main,
                    color: 'white',
                    border: `1px solid ${ORANGE.main}`,
                  }
                : ''),
            }}
            onClick={() => onSelect(item.dpd, index)}
          >
            {getBucketName(item)}
          </Box>
        ))}
      </Box>
      <Box sx={{ borderLeft: BORDER_GRAY }}>
        <VegaContainedButton
          text="Download"
          startIcon={<ArrowCircleDown />}
          isPrimary
          disabled={disableDownloadButton}
          onClick={onDownloadClick}
        />
      </Box>
    </Box>
  );
};

export default DPDbucketSelector;
