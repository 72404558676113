import { Stack } from '@mui/material';
import _ from 'lodash';
import { TransactionPolicy } from '../../../types/policy';
import { IRowData, PolicySummaryTable } from './common/SummaryTable';

interface Props {
  policy?: TransactionPolicy | null;
}

function TransactionLimitPolicySummary({ policy }: Props) {
  const getSummaryRows = () => {
    const summaryRows: IRowData[] = [];
    if (!policy) return [];
    policy.transaction_limits.forEach((item) => {
      summaryRows.push({
        rowData: [
          _.startCase(_.toLower(item.transaction_code)),
          item.per_transaction_limit.toString(),
          item.monthly_limit.toString(),
          item.yearly_limit.toString(),
        ],
      });
    });
    return summaryRows;
  };

  return (
    <Stack>
      <PolicySummaryTable
        headers={[
          'Mode',
          'Transaction Limit (₹)',
          'Monthly Limit (₹)',
          'Annual Limit (₹)',
        ]}
        rows={getSummaryRows()}
      />
    </Stack>
  );
}

export default TransactionLimitPolicySummary;
