import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { listReportCategories } from '../../../api/reports';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { ICategory } from '../../../types/reports';
import { VegaHorizontalSelectorDataType } from '../../common/VegaHorizontalSelector';
import { CATEGORIES_DATA } from '../catalog/ReportsIconMap';
import ScheduleSelection from './ScheduleSelection';
import PastReports from './Tables/PastReports';
import Schedules from './Tables/Schedules';

const tileData = [
  {
    id: 'schedules',
    text: 'Schedules',
    image: CATEGORIES_DATA.schedules.icon,
    bgColor: CATEGORIES_DATA.schedules.color,
    // color: "white",
  },
  {
    id: 'past_reports',
    text: 'Past Reports',
    image: CATEGORIES_DATA.past_reports.icon,
    bgColor: CATEGORIES_DATA.past_reports.color,
  },
  {
    id: 'custom_mertrics',
    text: 'Custom Metrics',
    image: CATEGORIES_DATA.custom_metrics.icon,
    bgColor: CATEGORIES_DATA.custom_metrics.color,
  },
  {
    id: 'custom_attributes',
    text: 'Custom Attributes',
    image: CATEGORIES_DATA.custom_attributes.icon,
    bgColor: CATEGORIES_DATA.custom_attributes.color,
  },
];

function ScheduleHeaders() {
  const [active, setActive] = useState<VegaHorizontalSelectorDataType>(
    tileData[0]
  );

  console.log('🚀 --------------------------------------------------🚀');
  console.log('🚀 ~ file: ScheduleHeaders.tsx:45 ~ active:', active);
  console.log('🚀 --------------------------------------------------🚀');

  const [reportCategories, setReportCategories] = useState<Record<
    string,
    ICategory
  > | null>(null);

  const { selectedProgram } = useProgramData();
  const fetchReportCategories = async () => {
    // const programId = selectedProgram?.programId
    // const programId = '0d64eb27-037f-458d-b761-fc8bea267547';
    const res = await listReportCategories(
      selectedProgram?.programId || '0d64eb27-037f-458d-b761-fc8bea267547'
    );

    const temp = res.data.reduce((pre: Record<string, string>, curr: any) => {
      return {
        ...pre,
        [curr.categoryId]: curr,
      };
    }, {} as Record<string, string>);
    setReportCategories(temp);
  };

  const onTabChange = (data: VegaHorizontalSelectorDataType) => {
    if (data.id === 'custom_mertrics' || data.id === 'custom_attributes')
      return;
    setActive(data);
  };

  useEffect(() => {
    fetchReportCategories();
  }, []);

  return (
    <Box>
      <ScheduleSelection
        data={tileData}
        setActiveState={onTabChange}
        active={active}
      />

      <>
        {active.id === 'schedules' ? (
          <Schedules reportCategories={reportCategories} />
        ) : active.id === 'past_reports' ? (
          <PastReports reportCategories={reportCategories} />
        ) : (
          ''
        )}
      </>
    </Box>
  );
}

export default ScheduleHeaders;
