import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getPresignedUrl } from '../../../../api/card';
import { listTransactions } from '../../../../api/transaction';
import { ChargebackService } from '../../../../services/ChargeBackService';
import { ChargebackDto } from '../../../../types/chargeBack';
import { TransactionDto } from '../../../../types/transaction';
import { FileIcon } from '../../../../ui-components/icon/VPIcons';
import { VPPRIMARY } from '../../../../ui-components/theme';
import { DATE_TIME_FORMATS } from '../../../../utils/dateTime';
import { ChargeBackStatusColorMap } from '../../../ChargeBack/StatusChangeCards/StatusChangeCards';
import VegaHeader from '../../../common/VegaHeader';
import { useChargeBack } from '../../../ChargeBack/hooks';

interface IProps {
  transactionDto: TransactionDto;
}
export const ChargeBackDetails = ({ transactionDto }: IProps) => {
  const { selectedChargeBack } = useChargeBack();

  const disputeReasonQuery = useQuery({
    queryKey: ['disputeReason', selectedChargeBack?.disputeReasonId],
    queryFn: () => {
      return ChargebackService.getNetworkChargebackDisputeReason(
        selectedChargeBack?.disputeReasonId || ''
      );
    },
    enabled: !!selectedChargeBack?.disputeReasonId,
  });

  const listTransactionsQuery = useQuery({
    queryKey: ['list_transactions', selectedChargeBack?.disputeReasonId],
    queryFn: () => {
      return listTransactions({
        accountId: transactionDto.accountId,
        parentTransactionId: transactionDto.id,
      });
    },
    enabled: !!transactionDto.accountId && !!transactionDto.id,
  });

  return (
    <Box py={2} bgcolor={'white'}>
      <Stack spacing={2}>
        {/* --------------------------------Chargeback details------------------------------*/}
        <Stack spacing={1}>
          <Stack direction={'row'} spacing={1}>
            <Typography
              className="font-aspekta-600"
              color={'#222222'}
              fontSize={17}
            >
              Chargeback details
            </Typography>
            <Chip
              label={selectedChargeBack?.chargebackStatus}
              size="small"
              // color="secondary"

              sx={{
                fontSize: 10,
                lineHeight: '12px',
                backgroundColor: selectedChargeBack?.chargebackStatus
                  ? ChargeBackStatusColorMap[
                      selectedChargeBack?.chargebackStatus
                    ]
                  : VPPRIMARY.ORANGE,
                color: 'white',
              }}
            />
          </Stack>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <VegaHeader text="chargeback id" />
                <Typography noWrap>{selectedChargeBack?.id || '--'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Raised on" />
                  <Typography>
                    {selectedChargeBack?.createdAt
                      ? dayjs(selectedChargeBack?.createdAt).format(
                          DATE_TIME_FORMATS.SHORT_DATE
                        )
                      : '--'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Raised by" />
                  <Typography>{selectedChargeBack?.raisedBy}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="TAT" />
                  <Typography>{`${selectedChargeBack?.tatValue} ${
                    selectedChargeBack?.tatValue === 1 ? 'day' : 'days'
                  }  `}</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="chargeback type" />
                  <Typography>
                    {selectedChargeBack?.chargebackType || '--'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Dispute amount" />
                  <Typography>
                    {selectedChargeBack?.disputeAmount || '--'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Network claim id" />
                  <Typography>
                    {selectedChargeBack?.networkClaimId || '--'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Reason code" />
                  <Typography>
                    {disputeReasonQuery.data?.disputeReasonCode || '--'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="reason" />
                  <Typography>
                    {disputeReasonQuery.data?.reason || '--'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Uploded Files" />
                  <Stack direction={'row'} columnGap={1} flexWrap={'wrap'}>
                    {selectedChargeBack?.disputeProofs.map((fileUrl) => {
                      return (
                        <IconButton
                          onClick={async () => {
                            const presignedUrlRes = await getPresignedUrl(
                              fileUrl || ''
                            );
                            window.open(presignedUrlRes.data, '_blank');
                          }}
                        >
                          <FileIcon />
                        </IconButton>
                      );
                    })}
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Remarks" />
                  <Typography>{selectedChargeBack?.issue || '--'}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>

        <Divider
          sx={{
            my: 2,
          }}
        />
        {/* --------------------------------Transaction details------------------------------*/}
        <Stack spacing={1}>
          <Stack direction={'row'} spacing={1}>
            <Typography
              className="font-aspekta-600"
              color={'#222222'}
              fontSize={17}
            >
              Transaction details
            </Typography>
          </Stack>
          <Stack spacing={0.5}>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={'rgba(27, 29, 34, 1)'}
            >
              Parent Transaction details
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VegaHeader text="Date" />
                  <Typography noWrap>
                    {transactionDto?.createdAt
                      ? dayjs(transactionDto.createdAt).format(
                          DATE_TIME_FORMATS.DATE_TIME
                        )
                      : '--'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <VegaHeader text="ID" />
                    <Typography>{transactionDto.id || '--'}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <VegaHeader text="description" />
                    <Typography>{transactionDto.narration || '--'}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <VegaHeader text="amount" />
                    <Typography>₹ {transactionDto.amount || '--'}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <Stack spacing={0.5}>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={'rgba(27, 29, 34, 1)'}
            >
              Sub Transaction details
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VegaHeader text="Transaction Date" />
                  {listTransactionsQuery.data?.data.records.map(
                    (transactionDto, index) => {
                      return (
                        <Typography noWrap key={index}>
                          {dayjs(transactionDto.createdAt).format(
                            DATE_TIME_FORMATS.SHORT_DATE
                          )}
                        </Typography>
                      );
                    }
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <VegaHeader text="Transaction ID" />
                    {listTransactionsQuery.data?.data.records.map(
                      (transactionDto, index) => {
                        return (
                          <Typography noWrap key={index}>
                            {transactionDto.id}
                          </Typography>
                        );
                      }
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <VegaHeader text="Transaction description" />
                    {listTransactionsQuery.data?.data.records.map(
                      (transactionDto, index) => {
                        return (
                          <Typography noWrap key={index}>
                            {transactionDto.narration}
                          </Typography>
                        );
                      }
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <VegaHeader text="Transaction amount" />
                    {listTransactionsQuery.data?.data.records.map(
                      (transactionDto, index) => {
                        return (
                          <Typography noWrap key={index}>
                            {transactionDto.amount}
                          </Typography>
                        );
                      }
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Stack>
        {/* --------------------------------Customer details------------------------------*/}
        {/* <Stack spacing={1}>
          <Stack direction={'row'} spacing={1}>
            <Typography
              className="font-aspekta-600"
              color={'#222222'}
              fontSize={17}
            >
              Customer details
            </Typography>
          </Stack>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <VegaHeader text="Account ID" />
                <Typography noWrap>21223-234-455-334</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="cardholder’s name" />
                  <Typography>Sanchit Agarwal</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Program and Program Number" />
                  <Typography>
                    Flipkart Axis Bank Card (324353845823)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Network" />
                  {getLogoForNetwork('VISA')}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Email ID" />
                  <Typography noWrap>sanchit@gmail.com</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VegaHeader text="Mobile Number" />
                  <Typography>+91 9897123465</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <VPButton endIcon={<ChevronRightOutlined />} variant="text">
                    View All
                  </VPButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack> */}
      </Stack>
    </Box>
  );
};
