import { Box } from '@mui/material';
import { CardType, useCardConfig } from '../../providers/CardConfigProvider';
import VegaImageSelector from '../common/v2/ImageSelector/VegaImageSelector';
import VegaSelectV2 from '../common/v2/VegaSelectV2';

type Props = {
  cardType: CardType;
};
function CardNfcLogoSelector({ cardType }: Props) {
  const { onNfcLogoChange, physicalCardConfig, virtualCardConfig } =
    useCardConfig();

  const getConfig = () =>
    cardType == CardType.PHYSICAL ? physicalCardConfig : virtualCardConfig;

  const getNfcLogo = () => {
    return getConfig().nfcLogo;
  };

  const isNfcEnabled = () => {
    const isEnabled = getConfig().isNfcEnabled;
    return isEnabled ?? false;
  };

  return (
    <VegaSelectV2
      placeholder="Upload NFC Logo"
      disabled={isNfcEnabled() == false}
      renderOptions={() => (
        <VegaImageSelector
          title={'Nfc Logo'}
          images={[
            'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/NFC+(1).png',
            'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/NFC.png',
          ]}
          onAddClick={function (): void {}}
          onImageSelect={function (value: string): void {
            onNfcLogoChange(value, cardType);
          }}
          selectedImage={getNfcLogo()}
        />
      )}
      hidePlaceholder={Boolean(getNfcLogo())}
      renderSelectedValue={() => {
        return (
          <Box
            style={{
              height: '20px',
              maxWidth: '80px',
              backgroundColor: '#f1f1f1f1',
              padding: 0,
              margin: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{ objectFit: 'contain' }}
              height={'80%'}
              width={'80%'}
              src={getNfcLogo()}
            ></img>
          </Box>
        );
      }}
    />
  );
}

export default CardNfcLogoSelector;
