import { styled } from '@mui/material';

export enum CardSizeVairant {
  SMALL = 'small',
  NORMAL = 'normal',
}

interface AlignmentProps {
  leftAligned?: boolean;
  rightAligned?: boolean;
  topAligned?: boolean;
  bottomAlligned?: boolean;
}

interface RelativePositionProps {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

interface VariantProps {
  variant: CardSizeVairant;
}

interface ContainerProps extends VariantProps {
  backgroundColor?: string;
  width?: string;
  height?: string;
}

interface TextProps extends VariantProps {
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: string;
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana';
}

interface PlaceholderContainerProps {
  hide?: boolean;
}

interface TopImagePlaceHolder
  extends AlignmentProps,
    PlaceholderContainerProps,
    VariantProps,
    RelativePositionProps {}

interface ImageProps extends PlaceholderContainerProps, VariantProps {}

export const ProgramCardContainer = styled('div')<ContainerProps>(
  ({ theme, backgroundColor = 'rgba(97, 110, 127, 1)', variant }) => {
    const height = CARD_CONTAINER_SIZE[variant].height;
    const width = CARD_CONTAINER_SIZE[variant].width;
    return {
      width: width,
      minWidth: width,
      minHeight: height,
      height: height,
      background: backgroundColor,
      borderRadius: '.75rem',
      position: 'relative',
      overflow: 'hidden',
    };
  }
);

export const ProgramCardImagePlaceholder = styled('div')<TopImagePlaceHolder>(
  ({
    theme,
    hide,
    topAligned,
    bottomAlligned,
    rightAligned,
    leftAligned,
    top = '5%',
    bottom = '5%',
    left = '5%',
    right = '5%',
  }) => {
    return {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24%',
      height: '11.5%',
      borderRadius: '0.5rem',
      background: hide ? 'transparent' : 'rgba(255, 255, 255, 0.25)',
      ...(topAligned && { top: top }),
      ...(leftAligned && { left: left }),
      ...(rightAligned && { right: right }),
      ...(bottomAlligned && { bottom: bottom }),
      zIndex: 1,
    };
  }
);

export const ProgramCardMircoChip = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    top: '25.5%',
    left: '4.5%',
    zIndex: 1,
  };
});

interface ImageProps extends VariantProps, AlignmentProps {
  height?: string;
  width?: string;
}

export const ProgramCardImage = styled('img')<ImageProps>(
  ({
    theme,
    leftAligned = false,
    rightAligned = true,
    topAligned = true,
    bottomAlligned = false,
    height,
    width,
  }) => {
    return {
      position: 'absolute',
      ...(height && { height: height }),
      ...(width && { width: width }),
      maxWidth: '80%',
      maxHeight: '80%',
      borderRadius: '0.2rem',
      objectFit: 'scale-down',
      ...(leftAligned && { left: 0 }),
      ...(rightAligned && { right: 0 }),
      ...(topAligned && { top: 0 }),
      ...(bottomAlligned && { bottom: 0 }),
      zIndex: 1,
    };
  }
);

export const ProgramCardHolderName = styled('div')<TextProps>(
  ({ theme, color = '#fdfdfd', variant }) => {
    const fontSize = CARD_HOLDER_FONT_SIZE[variant].fontSize;
    return {
      position: 'absolute',
      fontFamily: 'Aspekta !important',
      fontSize: fontSize,
      fontWeight: 'bolder',
      lineHeight: '140%',
      textTransform: 'capitalize',
      color: color,
      zIndex: 1,
    };
  }
);

interface CardTextProps extends TextProps, RelativePositionProps {}

export const ProgramCardText = styled('div')<CardTextProps>(
  ({
    color = '#fdfdfd',
    variant,
    top,
    bottom,
    left,
    right,
    fontWeight = '450',
    lineHeight = '120%',
    textTransform = 'capitalize',
    fontSize,
  }) => {
    const responsivevFontSize =
      fontSize ?? CARD_HOLDER_VALID_FONT_SIZE[variant].fontSize;
    return {
      fontFamily: 'Aspekta !important',
      position: 'absolute',
      fontWeight: fontWeight,
      fontSize: responsivevFontSize,
      ...(lineHeight && { lineHeight: lineHeight }),
      ...(bottom && { bottom: bottom }),
      ...(top && { top: top }),
      ...(right && { right: right }),
      ...(left && { left: left }),
      ...(textTransform && { textTransform: textTransform }),
      color: color,
      zIndex: 1,
    };
  }
);

interface CardNumberContainerProps extends RelativePositionProps {}
export const ProgramCardNumberContainer = styled(
  'div'
)<CardNumberContainerProps>(({ theme, top = '43%', left = '6%' }) => {
  return {
    fontFamily: 'Aspekta !important',
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '5.4%',
    width: '89.14%',
    height: '13.5%',
    left: left,
    top: top,
    color: 'white',
    zIndex: 1,
  };
});

export const ProgramCardNumberText = styled('div')<TextProps>(
  ({ theme, color = '#fdfdfd', variant }) => {
    const fontSize = CARD_NUMBER_FONT_SIZE[variant].fontSize;
    return {
      fontWeight: 600,
      fontSize: fontSize,
      lineHeight: '120%',
      color: color,
      fontFamily: 'Aspekta !important',
    };
  }
);

type PatternProps = {
  left?: string;
};

export const ProgramCardRightPattern = styled('div')<PatternProps>(
  ({ theme, left = '65%' }) => {
    return {
      position: 'absolute',
      width: '70%',
      height: '150%',
      left: `calc(${left})`,
      top: 'calc(-10%)',
      background: 'rgba(254, 254, 254, 0.15)',
      opacity: '0.4',
      borderRadius: '50px',
      transform: 'rotate(135deg)',
      overflow: 'hidden',
    };
  }
);

export const ProgramCardLeftPattern = styled('div')<PatternProps>(
  ({ theme, left = '-60%' }) => {
    return {
      position: 'absolute',
      width: '80%',
      height: '120%',
      left: `calc(${left})`,
      top: 'calc(-10%)',
      background: 'rgba(254, 254, 254, 0.15)',
      opacity: '0.4',
      borderRadius: '50px',
      transform: 'rotate(135deg)',
      overflow: 'hidden',
    };
  }
);

export const ProgramCardMagneticStrip = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    width: '100%',
    height: '17%',
    left: '0',
    top: '9.5%',
    background: '#1d1d1d',
  };
});

export const ProgramCardHologram = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    width: '12.5%',
    height: '14.64%',
    left: '3.6%',
    bottom: '20%',
    background: '#1d1d1d',
  };
});

export const ProgramCardCvvContainer = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '59.04%',
    height: '15.71%',
    left: '3.6%',
    top: '32.1%',
    background: '#dddddd',
    fontFamily: 'Aspekta !important',
  };
});

export const ProgramCardCvvTextContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: '5%',
    alignItems: 'center',
    fontFamily: 'Aspekta !important',
    background: 'white',
  };
});

export const ProgramCardStripsContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '5%',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    backgroundColor: 'white',
  };
});

export const ProgramCardStrip = styled('div')(({ theme }) => {
  return {
    width: '100%',
    height: '14%',
    background: '#cbb88f',
  };
});

export const ProgramCardCvvTitle = styled('div')<TextProps>(
  ({ theme, color = '#3a4a5f', variant }) => {
    const fontSize = CARD_HOLDER_VALID_FONT_SIZE[variant].fontSize;

    return {
      fontWeight: 700,
      fontSize: fontSize,
      lineHeight: '124%',
      letterSpacing: '0.07em',
      textTransform: 'uppercase',
      color: color,
      fontFamily: 'Aspekta !important',
    };
  }
);

export const ProgramCardCvvNumber = styled('div')<TextProps>(
  ({ theme, color = '#3a4a5f', variant }) => {
    const fontSize = CARD_HOLDER_VALID_FONT_SIZE[variant].fontSize;
    return {
      fontWeight: 450,
      fontSize: fontSize,
      lineHeight: '120%',
      color: color,
      fontFamily: 'Aspekta !important',
    };
  }
);

interface DescriptionContainerProps
  extends TextProps,
    PlaceholderContainerProps {}

export const ProgramCardDescriptionContainer = styled(
  'div'
)<DescriptionContainerProps>(({ theme, color = 'white', hide, variant }) => {
  const fontSize = CARD_HOLDER_VALID_FONT_SIZE[variant].fontSize;
  return {
    backgroundColor: 'red',
    position: 'absolute',
    width: '55%',
    maxWidth: '55%',
    minWidth: '55%',
    height: hide ? '30%' : '3.5%',
    maxHeight: hide ? '30%' : '3.5%',
    minHeight: hide ? '30%' : '3.5%',
    left: '30%',
    top: '65%',
    fontSize: fontSize,
    fontWeight: 450,
    lineHeight: '120%',
    wordWrap: 'break-word',
    background: hide ? 'transparent' : 'rgba(255, 255, 255, 0.25)',
    borderRadius: '12px',
    color: color,
    whiteSpace: 'pre-line',
    textOverflow: 'ellipsis !important',
    overflow: 'hidden !important',
    fontFamily: 'Aspekta !important',
    textWrap: 'nowrap',
  };
});

export const ProgramCardTopHollowContainer = styled(
  'div'
)<DescriptionContainerProps>(({ theme, color = 'white', hide, variant }) => {
  const fontSize = variant == CardSizeVairant.NORMAL ? '8px' : '4px';
  return {
    position: 'absolute',
    width: '54.2%',
    height: '3.5%',
    left: '4%',
    top: '4%',
    fontSize: fontSize,
    fontWeight: 600,
    lineHeight: '120%',
    background: hide ? 'transparent' : 'rgba(255, 255, 255, 0.25)',
    borderRadius: '12px',
    color: color,
    fontFamily: 'Aspekta !important',
  };
});

export const TextContainer = styled('div')(({}) => {
  return {
    color: 'white',
    fontFamily: 'Aspekta !important',
  };
});

//  STYLES

export const CARD_CONTAINER_SIZE = {
  normal: { height: '280px', width: '442px' },
  small: { height: '100px', width: '156px' },
};

export const CARD_IMAGE_SIZE = {
  normal: { height: '32px', width: '32px' },
  small: { height: '10px', width: '10px' },
};

export const CARD_NUMBER_FONT_SIZE = {
  normal: { fontSize: '35px' },
  small: { fontSize: '12px' },
};

export const CARD_HOLDER_FONT_SIZE = {
  normal: { fontSize: '14px' },
  small: { fontSize: '6px' },
};

export const CARD_AUTHORIZED_SIGNRATURE_FONT_SIZE = {
  normal: { fontSize: '15px' },
  small: { fontSize: '5px' },
};

export const CARD_HOLDER_VALID_FONT_SIZE = {
  normal: { fontSize: '13px' },
  small: { fontSize: '5px' },
};
