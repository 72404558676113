import React from 'react';
import { Box, Typography } from '@mui/material';
import { BACKGROUND, BORDER_GRAY } from '../../../constants/style';

const PaymentDueDates = ({ selectDate }: any) => {
  return (
    <Box
      sx={{
        border: BORDER_GRAY,
        borderRadius: '9px',
        minHeight: '100%',
      }}
    >
      <Box
        sx={{
          bgcolor: BACKGROUND.LIGHT_BLUE,
          pl: 5,
          py: 2,
          borderTopLeftRadius: '9px',
          borderTopRightRadius: '9px',
        }}
      >
        Payment Due Dates
      </Box>
      <Box sx={{ maxHeight: '500px', overflow: 'scroll' }}>
        {selectDate.length
          ? selectDate.map((val: number) => (
              <Box sx={{ pl: 5, py: 2, borderBottom: BORDER_GRAY }}>
                <Typography className="font-aspekta-500" variant="body2">
                  {val} of the month
                </Typography>
              </Box>
            ))
          : ''}
      </Box>
    </Box>
  );
};

export default PaymentDueDates;
