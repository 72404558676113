import React from 'react';
import { toLowerCase } from '../../constants/commonFunction';
import { IRulesTypeProps } from '../../pages/RewardLoyalty/components/RewardHome';
import { COMMON, GREY, ORANGE, PRIMARY } from '../../constants/style';
import { Box, SxProps, Theme, Typography } from '@mui/material';

interface ITabProps {
  tabData: string[] | IRulesTypeProps[];
  selected: string;
  color?: string;
  width?: string;
  isSubText?: boolean;
  forceTextCase?: boolean;
  onSelect: (select: any) => void;
  placement: 'vertical' | 'horizontal';
  tabStyle?: SxProps<Theme>;
}

const VegaTab = ({
  tabData,
  selected,
  onSelect,
  placement,
  isSubText,
  forceTextCase,
  tabStyle,
  color = '#FF7B01',
  width = 'auto',
}: ITabProps) => {
  const getValue = (rule: string | IRulesTypeProps) => {
    if (typeof rule === 'string') {
      return rule;
    } else {
      return rule.id;
    }
  };
  return (
    <Box
      sx={{
        ...(placement === 'vertical' && {
          display: 'flex',
          overflowX: 'scroll',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none' /* IE and Edge */,
          scrollbarWidth: 'none' /* Firefox */,
        }),
        mb: 1,
      }}
    >
      {tabData.map((rule: any) => {
        return (
          <Box
            sx={{
              px: 2,
              pt: 2,
              pb: 1,
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',

              ...(placement === 'vertical' && {
                alignItems: 'center',
              }),

              ...(selected === getValue(rule) &&
                placement === 'vertical' && {
                  borderBottom: `2px solid ${color}`,
                }),
              ...(selected === getValue(rule) &&
                placement === 'horizontal' && {
                  borderRight: `3px solid ${color}`,
                }),
              ...tabStyle,
            }}
            onClick={() => onSelect(getValue(rule))}
          >
            <Typography
              className="font-aspekta-500"
              fontSize={13}
              sx={{
                textAlign: 'center',
                width: `${width} !important`,
                ...(selected === getValue(rule)
                  ? { color: color }
                  : { color: COMMON.gray }),
              }}
            >
              {typeof rule === 'string'
                ? forceTextCase
                  ? rule
                  : toLowerCase(rule)
                : toLowerCase(rule.id)}
            </Typography>
            {isSubText && (
              <Typography
                className="font-aspekta-600"
                sx={{ fontSize: 10, color: GREY.dark }}
              >
                {rule.subTxt}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default VegaTab;
