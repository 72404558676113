import React from 'react';
import TCSContainer from './components/TCSContainer';
import { Box } from '@mui/material';
import ListOfTCS from './components/ListOfTCS';
import ListOfTcsSummary from './components/ListOfTcsSummary';

const TCS = () => {
  return (
    <>
      <TCSContainer header="TCS Summary" sx={{ mb: 2 }} isDropdown>
        <Box mt={2}>
          <ListOfTcsSummary />
        </Box>
      </TCSContainer>
      <TCSContainer header="TCS ">
        <Box mt={2}>
          <ListOfTCS />
        </Box>
      </TCSContainer>
    </>
  );
};

export default TCS;
