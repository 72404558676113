/* eslint-disable react/jsx-pascal-case */
import { GridColumns } from '@mui/x-data-grid';
import Vega_Card from '../../../../components/common/v3/Vega_Card';
import Vega_Datagrid from '../../../../components/common/v3/Vega_Datagrid';
import Vega_Select, {
  ISelectOptionType,
} from '../../../../components/common/v3/Vega_Select';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import Vega_Text from '../../../../components/common/v3/Vega_Text';
import Vega_Button from '../../../../components/common/v3/Vega_Button';
import { BLUE, GREEN } from '../../../../constants/style';
import { fetchInvoice } from '../../../../api/setting';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

export interface InvoicePorps {
  clientId: string;
  year: number;
  month: number;
  invoiceS3Link: string;
}
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const VegaPayInvoice = () => {
  const { client, clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [invoices, setInvoices] = useState<InvoicePorps[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>('2022');
  const [loading, setLoading] = useState<boolean>(false);

  const getInvoice = async () => {
    setLoading(true);
    try {
      const response = await fetchInvoice(clientId, selectedYear);
      setInvoices(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };
  useEffect(() => {
    getInvoice();
  }, [selectedYear]);

  return (
    <>
      <Vega_Card sx={{ height: 'calc(100% - 160px)' }}>
        <>
          <Vega_Select
            sx={{ width: 100 }}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value as string)}
            options={yearsOptions()}
          />
          <Box mt={2}>
            <Vega_Datagrid
              data={invoices}
              idColumn="month"
              columns={COLUMN_DEF}
              loading={loading}
            />
          </Box>
        </>
      </Vega_Card>
    </>
  );
};

export default VegaPayInvoice;

const COLUMN_DEF: GridColumns = [
  {
    field: 'invoiceDate',
    headerName: 'Invoice Month',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      const month = props.row.month;
      const getMonthName = months[month - 1];
      return <Vega_Text text={getMonthName} />;
    },
  },
  {
    field: 'partnerNmae',
    headerName: 'Partner Name',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      const month = props.row.month;
      const getMonthName = months[month - 1];
      return <Vega_Text text={getMonthName} />;
    },
  },

  {
    field: 'invoiceNumber',
    headerName: 'Invoice Number',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      return <Vega_Text text={'2234'} />;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      return <Vega_Text text={'33455'} />;
    },
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      return <Vega_Text text={'22/05/23'} />;
    },
  },
  {
    field: 'invoice',
    headerName: 'Invoice',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      const invoiceS3Link = props.row.invoiceS3Link;
      return (
        <Vega_Button
          variant="text"
          text={'Download'}
          bgColor=""
          textColor={BLUE.lighter}
          sx={{ px: 0 }}
          onClick={() => window.open(invoiceS3Link, '_blank')}
        />
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      return <Vega_Text text={'Paid'} fontColor={GREEN.dark} />;
    },
  },
];
const yearsOptions = () =>
  ['2022', '2023'].map((item) => {
    const option: ISelectOptionType = {
      id: item,
      name: `FY ${item}`,
    };
    return option;
  });
