import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { GREY } from '../../constants/style';
import { toLowerCase } from '../../constants/commonFunction';

export interface IRadioProps {
  name: string;
  value: any;
  radioData: any;
  disabledRadioOptions?: any;
  labelPlacement: any;
  color?: any;
  onChange: (e: any) => void;
  isRow?: boolean;
}

const VegaRadio = ({
  name,
  value,
  onChange,
  radioData,
  labelPlacement,
  disabledRadioOptions,
  color,
  isRow = true,
}: IRadioProps) => {
  return (
    <FormControl fullWidth>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={name}
        value={value}
        onChange={onChange}
        row={isRow}
        // style={{ display: "flex", justifyContent: "space-between" }}
      >
        {radioData &&
          radioData.map((radio: any, i: any) => (
            <FormControlLabel
              sx={{ color: GREY.darkest }}
              value={radio.value}
              control={
                <Radio
                  disabled={disabledRadioOptions?.includes(radio)}
                  sx={{
                    color: GREY.dark,
                    '&.Mui-checked': {
                      color: color,
                    },
                  }}
                />
              }
              label={toLowerCase(radio.label)}
              labelPlacement={labelPlacement}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default VegaRadio;
