import './Styles/__styles.css';
import { styled, Switch, SwitchProps } from '@mui/material';

const StyledSwitch = styled(Switch)({
  fontFamily: 'Aspekta !important',
  '& .MuiSwitch-switchBase': {
    fontFamily: 'Aspekta !important',
    color: '#3A4A5F', // Color when switch is on
    '&.Mui-checked': {
      color: '#D1D1D6', // Color when switch is off
    },
    '&.Mui-disabled': {
      color: '#D3D3D3', // Color when switch is disabled
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'white', // Color of the thumb
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#D1D1D6', // Color of the track
  },
});

interface VegaSwitchV2Props extends SwitchProps {}
function VegaSwitchV2({ ...rest }: VegaSwitchV2Props) {
  return <StyledSwitch {...rest}></StyledSwitch>;
}

export default VegaSwitchV2;
