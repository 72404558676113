import {
  Button,
  ButtonProps,
  CircularProgress,
  alpha,
  useTheme,
} from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    soft: true;
  }
}

interface CIMS_ButtonProps extends ButtonProps {
  isLoading?: boolean;
  variant?: 'text' | 'outlined' | 'contained' | 'soft';
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
}

const CimsButton = ({
  isLoading,
  startIcon,
  children,
  variant = 'contained',
  sx,
  color = 'primary',
  ...rest
}: CIMS_ButtonProps) => {
  const theme = useTheme();

  const colorScheme = color;

  return (
    <Button
      {...rest}
      disabled={rest.disabled || isLoading}
      startIcon={
        isLoading ? (
          // <StyledLoader size={24} buttonVariant={variant} color={colorScheme} />
          <CircularProgress size={20} color={colorScheme} />
        ) : (
          startIcon
        )
      }
      disableElevation
      sx={{
        cursor: rest.disabled || isLoading ? 'not-allowed' : 'pointer',
        textTransform: 'capitalize',
        fontSize: '12px',
        borderRadius: '4px',
        fontFamily: `var(--font-Aspekta-500) !important`,
        ...(variant === 'soft' && {
          color: alpha(theme.palette[colorScheme].main, 0.9),
          bgcolor: alpha(theme.palette[colorScheme].main, 0.15),
          ':hover': {
            bgcolor: alpha(theme.palette[colorScheme].main, 0.32),
          },
          ':active': {
            bgcolor: alpha(theme.palette[colorScheme].main, 0.48),
          },
        }),
        ':disabled': {
          bgcolor: alpha(theme.palette[colorScheme].main, 0.12),
          color: alpha(theme.palette[colorScheme].main, 0.72),
          opacity: 0.5,
        },

        ...sx,
      }}
      color={colorScheme}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default CimsButton;
