import React from 'react';

interface IProps {
  width?: string;
  height?: string;
}

const VegapayLogo = ({ width = '234', height = '80' }: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 234 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.0229 66.7992C39.0801 66.7992 35.9364 65.627 33.592 63.2826C31.2832 60.9382 30.1287 57.5104 30.1287 52.9992C30.1287 50.7259 30.4307 48.4348 31.0345 46.1259C31.6384 43.7815 32.5975 41.6324 33.9117 39.6788C35.226 37.7251 36.8955 36.1622 38.9202 34.99C40.9449 33.8178 43.3781 33.2317 46.2198 33.2317C48.9194 33.2317 51.2461 33.8355 53.1998 35.0433C55.1889 36.251 56.6276 38.0803 57.5156 40.5313C58.4036 42.9823 58.5279 46.0904 57.8886 49.8556L57.5689 51.7205H35.9897L36.1496 46.6587H50.5889C51.0152 43.9946 50.7488 42.0054 49.7897 40.6911C48.8662 39.3413 47.5519 38.6664 45.8469 38.6664C44.3195 38.6664 43.0407 39.1637 42.0106 40.1583C40.9805 41.1529 40.1635 42.4317 39.5596 43.9946C38.9913 45.5575 38.565 47.1738 38.2808 48.8433C38.0322 50.5128 37.9079 52.0402 37.9079 53.4255C37.9079 56.0185 38.4052 57.9544 39.3998 59.2332C40.4299 60.4765 41.7442 61.0981 43.3426 61.0981C44.5148 61.0981 45.5272 60.8139 46.3797 60.2456C47.2322 59.6772 47.9604 58.949 48.5642 58.061C49.2036 57.173 49.7364 56.2139 50.1627 55.1838L56.2901 59.1266C55.5086 60.512 54.4963 61.7907 53.253 62.9629C52.0453 64.0996 50.5889 65.0232 48.8839 65.7336C47.2144 66.444 45.2608 66.7992 43.0229 66.7992Z"
        fill="#061F62"
      />
      <path
        d="M3.67289 65.543L0 33.6496V33.543H7.50548L10.1138 59.0896H11.3913L21.0621 41.801H28.7272V41.9077L15.8626 65.543H3.67289Z"
        fill="#061F62"
      />
      <path
        d="M33.1679 33.543L28.8883 41.2849L23.8955 33.543H33.1679Z"
        fill="#FF912C"
      />
      <path
        d="M73.2564 77.4556C69.9529 77.4556 67.2355 76.8695 65.1043 75.6973C62.973 74.5251 61.2325 73.2286 59.8826 71.8077L64.3583 66.5328C65.4595 67.9537 66.7915 69.1259 68.3545 70.0494C69.9529 71.0085 71.729 71.488 73.6826 71.488C75.9205 71.488 77.6077 70.8486 78.7444 69.5699C79.9166 68.2911 80.7336 66.4973 81.1954 64.1884L82.1012 59.9259H80.8224C80.0054 61.4178 78.9043 62.6432 77.5189 63.6023C76.1691 64.5614 74.4641 65.0409 72.4039 65.0409C69.4201 65.0409 67.0224 63.9575 65.2108 61.7907C63.4348 59.6239 62.5467 56.6402 62.5467 52.8394C62.5467 50.4595 62.8842 48.1151 63.5591 45.8062C64.2695 43.4973 65.2819 41.4193 66.5962 39.5722C67.9104 37.6896 69.4911 36.1977 71.3382 35.0965C73.1853 33.9599 75.2811 33.3915 77.6255 33.3915C79.5436 33.3915 81.0178 33.7467 82.0479 34.4572C83.078 35.1676 83.895 36.0911 84.4989 37.2278H85.7776L86.3104 34.0309H93.2371L88.0687 65.0409C87.3938 69.1969 85.7243 72.305 83.0602 74.3652C80.4317 76.4255 77.1637 77.4556 73.2564 77.4556ZM74.7483 59.3398C76.3112 59.3398 77.661 58.7182 78.7977 57.4749C79.9344 56.1961 80.8757 54.5622 81.6216 52.573C82.4031 50.5483 82.9714 48.3815 83.3267 46.0726L84.1792 40.9575C83.5043 40.3182 82.705 39.8386 81.7815 39.5189C80.8579 39.1637 79.9877 38.9861 79.1707 38.9861C77.4301 38.9861 75.9027 39.59 74.5884 40.7977C73.2741 41.9699 72.244 43.5684 71.4981 45.5931C70.7521 47.6178 70.3792 49.9089 70.3792 52.4664C70.3792 54.8108 70.7877 56.5514 71.6047 57.688C72.4216 58.7892 73.4695 59.3398 74.7483 59.3398Z"
        fill="#061F62"
      />
      <path
        d="M105.622 66.6394C102.567 66.6394 100.134 65.5382 98.3222 63.3359C96.5107 61.1336 95.6049 57.99 95.6049 53.905C95.6049 51.4185 95.9423 48.9676 96.6172 46.5521C97.2921 44.1367 98.2867 41.9344 99.601 39.9452C100.951 37.956 102.585 36.3753 104.503 35.2031C106.421 33.9954 108.623 33.3915 111.11 33.3915C113.206 33.3915 114.822 33.7823 115.959 34.5637C117.095 35.3097 117.93 36.1977 118.463 37.2278H119.742L120.274 34.0309H127.254L123.365 57.6348C123.223 58.5583 123.081 59.4996 122.938 60.4587C122.832 61.4178 122.743 62.3768 122.672 63.3359C122.637 64.2595 122.619 65.1475 122.619 66H115.159C115.195 65.2896 115.266 64.4903 115.372 63.6023C115.515 62.6788 115.657 61.8263 115.799 61.0448H114.467C113.792 62.4301 112.744 63.7089 111.323 64.8811C109.938 66.0533 108.037 66.6394 105.622 66.6394ZM108.339 60.7251C109.973 60.7251 111.376 60.0502 112.548 58.7004C113.721 57.3506 114.698 55.6278 115.479 53.5321C116.26 51.4008 116.847 49.1985 117.237 46.9251L118.196 40.9575C117.521 40.4247 116.74 39.9807 115.852 39.6255C114.964 39.2703 113.987 39.0927 112.921 39.0927C111.039 39.0927 109.387 39.7676 107.966 41.1174C106.581 42.4317 105.498 44.19 104.716 46.3923C103.935 48.5946 103.544 50.9923 103.544 53.5853C103.544 56.0718 103.988 57.8834 104.876 59.0201C105.799 60.1568 106.954 60.7251 108.339 60.7251Z"
        fill="#061F62"
      />
      <path
        d="M129.176 76.6564L134.984 41.7035C135.268 40.105 135.499 38.5954 135.677 37.1745C135.854 35.7182 135.943 34.6703 135.943 34.0309H142.976C142.976 34.7413 142.923 35.505 142.817 36.322C142.746 37.1035 142.639 37.9382 142.497 38.8263H143.776C144.451 37.512 145.481 36.2865 146.866 35.1498C148.287 34.0131 150.205 33.4448 152.62 33.4448C155.675 33.4448 158.091 34.546 159.867 36.7483C161.678 38.9151 162.584 42.0409 162.584 46.1259C162.584 48.6124 162.247 51.0633 161.572 53.4788C160.932 55.8942 159.956 58.0965 158.641 60.0857C157.362 62.0749 155.764 63.6734 153.846 64.8811C151.963 66.0533 149.814 66.6394 147.399 66.6394C145.374 66.6394 143.793 66.2486 142.657 65.4672C141.52 64.6857 140.685 63.7977 140.152 62.8031H138.874C138.838 63.3359 138.767 64.0641 138.661 64.9876C138.59 65.8757 138.412 67.2788 138.128 69.1969L137.062 76.6564H129.176ZM145.268 60.9382C147.186 60.9382 148.837 60.2811 150.223 58.9668C151.644 57.617 152.745 55.8409 153.526 53.6386C154.308 51.4363 154.698 49.0386 154.698 46.4456C154.698 43.9236 154.237 42.1297 153.313 41.0641C152.425 39.9629 151.288 39.4124 149.903 39.4124C148.269 39.4124 146.848 40.0873 145.64 41.4371C144.468 42.7514 143.491 44.4564 142.71 46.5521C141.929 48.6479 141.36 50.8324 141.005 53.1058L139.993 58.9668C140.668 59.5351 141.449 60.0147 142.337 60.4054C143.261 60.7606 144.237 60.9382 145.268 60.9382Z"
        fill="#061F62"
      />
      <path
        d="M176.712 66.6394C173.657 66.6394 171.224 65.5382 169.412 63.3359C167.6 61.1336 166.695 57.99 166.695 53.905C166.695 51.4185 167.032 48.9676 167.707 46.5521C168.382 44.1367 169.377 41.9344 170.691 39.9452C172.041 37.956 173.675 36.3753 175.593 35.2031C177.511 33.9954 179.713 33.3915 182.2 33.3915C184.295 33.3915 185.912 33.7823 187.048 34.5637C188.185 35.3097 189.02 36.1977 189.553 37.2278H190.831L191.364 34.0309H198.344L194.455 57.6348C194.312 58.5583 194.17 59.4996 194.028 60.4587C193.922 61.4178 193.833 62.3768 193.762 63.3359C193.726 64.2595 193.709 65.1475 193.709 66H186.249C186.285 65.2896 186.356 64.4903 186.462 63.6023C186.604 62.6788 186.746 61.8263 186.889 61.0448H185.556C184.882 62.4301 183.834 63.7089 182.413 64.8811C181.028 66.0533 179.127 66.6394 176.712 66.6394ZM179.429 60.7251C181.063 60.7251 182.466 60.0502 183.638 58.7004C184.811 57.3506 185.787 55.6278 186.569 53.5321C187.35 51.4008 187.936 49.1985 188.327 46.9251L189.286 40.9575C188.611 40.4247 187.83 39.9807 186.942 39.6255C186.054 39.2703 185.077 39.0927 184.011 39.0927C182.129 39.0927 180.477 39.7676 179.056 41.1174C177.671 42.4317 176.587 44.19 175.806 46.3923C175.024 48.5946 174.634 50.9923 174.634 53.5853C174.634 56.0718 175.078 57.8834 175.966 59.0201C176.889 60.1568 178.044 60.7251 179.429 60.7251Z"
        fill="#061F62"
      />
      <path
        d="M202.184 76.6564V76.3367C203.463 74.7737 204.76 72.9799 206.074 70.9552C207.424 68.966 208.578 66.9768 209.537 64.9876L203.783 34.0309H211.402L213.747 47.6711C213.924 48.7722 214.102 49.9267 214.279 51.1344C214.492 52.3421 214.635 53.7985 214.706 55.5035H215.984C216.517 53.7274 217.032 52.2 217.53 50.9212C218.062 49.6425 218.542 48.5413 218.968 47.6178L225.362 34.0309H233.088L220.247 60.1923C218.329 64.0996 216.517 67.4564 214.812 70.2625C213.143 73.1042 211.775 75.2355 210.709 76.6564H202.184Z"
        fill="#061F62"
      />
    </svg>
  );
};

export default VegapayLogo;
