import { SxProps, Theme, Box } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { BLUE, BORDER_GRAY } from '../../../constants/style';
import VegaContainedButton from '../VegaContainedButton';

type IProps = {
  headingText?: string;
  isRightHeader?: boolean;
  children: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
};
const ChartsWrapper = ({
  headingText = 'Heading',
  isRightHeader = false,
  sx,
  children,
}: IProps) => {
  return (
    <Box
      borderRadius="15px"
      border={BORDER_GRAY}
      sx={{
        overflow: 'hidden',
        width: '100%',
        // height: '100%',
        ...sx,
      }}
    >
      <Stack
        direction="row"
        sx={{
          bgcolor: BLUE.light,
          minHeight: '35px',
        }}
        justifyContent="space-between"
        p={2}
        // px={4}
      >
        {headingText}
        {isRightHeader && <VegaContainedButton text="Modify" isPrimary />}
      </Stack>
      <Stack p={3} overflow="scroll">
        {children}
      </Stack>
    </Box>
  );
};

export default ChartsWrapper;
