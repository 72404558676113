import { Search } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import VegaContainedButton from '../../../components/common/VegaContainedButton';
import VegaModal from '../../../components/common/VegaModal';
import VegaOutLineTextField from '../../../components/common/VegaOutLineTextField';
import VegaSelect from '../../../components/common/VegaSelect';
import VegaTab from '../../../components/common/VegaTab';
import { FIleIcon } from '../../../components/icon/Icons';
import { BLUE } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { ICardFundLoadProps } from '../../types/Card';
import { ICorporateAccountWalletDataProps } from '../../types/CorporateCustomer';
import {
  IRePaymentProps,
  IReloadCardProps,
  InvoiceListPostPaidCardProps,
} from '../../types/CorporateTransaction';
import {
  fetchCorporateFunloadList,
  fetchCorporate̦AccountByCorporateId,
  initiateCorporateCheckerMakerForfundLoad,
} from '../api/corporateEntity';
import { addFundForCorporate, fetchListFundLoad } from '../api/transaction';
import CorporateTransactionFilter from '../components/MCCcorporateTransaction/CorporateTransactionFilter';
import ListOfCorporateTransaction from '../components/MCCcorporateTransaction/ListOfCorporateTransaction';
import ListOfPrepaidCorporateTransaction from '../components/MCCcorporateTransaction/ListOfPrepaidCorporateTransaction';
import { IFilterProps } from './MCCAplicationManagement';
import { fetchMCCCorporatesListForAccount } from '../../corporate/api/Corporate';
import {
  fetchInvoiceListForPostpaidCard,
  submitRePayment,
} from '../api/open-credit';
import CardReload from '../components/MCCcorporateTransaction/components/CardReload';
import { IConsolidatedAccountData } from '../../types/Commons';
import ListOfFunloadOrderList from '../components/MCCcorporateTransaction/ListOfFunloadOrderList';
import VegaDrawer from '../../../components/common/VegaDrawer';
import BalanceView from '../components/MCCcorporateTransaction/components/BalanceView';

interface ISummaryProps {
  count: string | number;
  summaryType: string;
  border: string;
}
export interface ICorporateFilterProps {
  corporateId: string;
  fromDate?: string;
  toDate?: string;
}

export interface ICheckerMakerProps {
  entityId: string | undefined;
  userId: string | null;
}

const POSTPAID = [
  {
    count: '50,000',
    summaryType: 'Total Due',
    border: '#E8505B',
  },
  {
    count: '50',
    summaryType: 'Total Invoices',
    border: '#9EBFFF',
  },
  {
    count: '20',
    summaryType: 'Total Invoices overdue',
    border: '#E8505B',
  },
  {
    count: '10,000',
    summaryType: 'Charges',
    border: '#E8505B',
  },
] as ISummaryProps[];

const PREPAID = [
  {
    count: '500',
    summaryType: 'Total Cards Issued',
    border: '#E8505B',
  },
  {
    count: '500000',
    summaryType: 'Total funds in issued cards',
    border: '#9EBFFF',
  },
  {
    count: '2',
    summaryType: 'Empty Card',
    border: '#E8505B',
  },
] as ISummaryProps[];

const MCCcorporateTransaction = () => {
  const { mccPlatformId: branchId, clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [selectedCard, setSelectedCard] = useState<string>('POSTPAID_CARD');
  const [loading, setLoading] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [corporates, setCorporates] = useState<
    Array<ICorporateAccountWalletDataProps>
  >([]);
  const [corporateSelected, setCorporateSelected] = useState<ICorporateAccountWalletDataProps>({account: null, corporate: null, subWallets: null});
  const [invoiceList, setInvoiceList] = useState<
    InvoiceListPostPaidCardProps[]
  >([]);
  const [corporateTransactionData, setCorporateTransactionData] = useState<
    ICardFundLoadProps[]
  >([]);
  const [corporateFundLoadOrderData, setCorporateFundLoadOrderData] = useState<
    ICardFundLoadProps[]
  >([]);

  const [filterReloadCustomers, setFilterReloadCustomers] =
    useState<IFilterProps>({
      mobile: '',
    });
  const [filterData, setFilterData] = useState<ICorporateFilterProps>({
    corporateId: '',
    fromDate: '',
    toDate: '',
  });
  const [openReload, setOpenReload] = useState<boolean>(false);
  const [openBalance, setOpenBalance] = useState<boolean>(false);
  const [reloadDetails, setReloadDetails] = useState<IReloadCardProps>({
    accountId: '',
    amount: 0,
    sourceCurrency: 'INR',
    destinationCurrency: 'USD',
    linkedOpenCreditAccountId: null,
  });

  const handleFilter = (name: keyof ICorporateFilterProps, value: any) => {
    if (name === 'corporateId') {
      fetchCorporate̦AccountByCorporateId(value)
        .then((res) => {
          setReloadDetails({
            ...reloadDetails,
            accountId: res.data.id,
            linkedOpenCreditAccountId: res.data.linkedOpenCreditAccountId,
          });
          const corporate = corporates?.filter(c => c.corporate?.id == value)
          setCorporateSelected(corporate[0]);
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Failed to add Funds'),
            'error'
          );
        });
    }
    setFilterData({ ...filterData, [name]: value });
  };
  const handleFilterReloadCustomer = (name: string, value: any) => {
    setFilterReloadCustomers({ ...filterReloadCustomers, [name]: value });
  };

  const handleReloadChange = (
    name: keyof IReloadCardProps,
    value: string | number
  ) => {
    setReloadDetails({ ...reloadDetails, [name]: value });
  };

  const onReload = (data: IReloadCardProps) => {
    addFundForCorporate({
      ...data,
      branchId,
      corporateId: filterData.corporateId,
      markupValueType: 'PERCENTAGE',
      markupValue: 0,
    })
      .then((res) => {
        setSnackbar('Funds added!');
        // setIsLoading(false);
        setFilterReloadCustomers({ mobile: '' });
        // getFundList();
        setCorporateTransactionData([...corporateTransactionData, res.data]);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add Funds'),
          'error'
        );
      });
  };

  const isOpenReload = () => {
    setOpenReload(!openReload);
  };

  const isOpenBalance = () => {
    setOpenBalance(!openBalance);
  };

  const onReloadDetailsSubmit = async () => {
    await onReload(reloadDetails);

    isOpenReload();
  };

  const showDetails = () => {
    isOpenReload();
  };

  const showBalance = () => {
    isOpenBalance();
  };

  const submitInitialCheckerMaker = (data: ICheckerMakerProps) => {
    initiateCorporateCheckerMakerForfundLoad(data)
      .then((res) => {
        // setCorporateTransactionData();
        getCorporateTransactionList(reloadDetails.accountId);
        setSnackbar('Send to checker!');
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send to checker'),
          'error'
        );
      });
  };

  const onRePayment = (payment: IRePaymentProps) => {
    submitRePayment(payment)
      .then((res) => {
        reloadDetails.linkedOpenCreditAccountId &&
          getInvoicesList(reloadDetails.linkedOpenCreditAccountId);
        setSnackbar('Re-payment successfully!');
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed re-payment'),
          'error'
        );
      });
  };

  const getCorporateTransactionList = (id: string) => {
    setLoading(true);
    fetchCorporateFunloadList({
      accountId: id,
      // accountId: '9ba7d7fc-2b41-4b76-9051-5e1d4cb6bce8',
    })
      .then((res) => {
        setCorporateTransactionData(res.data);
        // setSnackbar('Funds added!');
        setLoading(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add Funds'),
          'error'
        );
        setLoading(false);
      });
  };

  const getTransactionFundLoadOrderList = (id: string) => {
    setListLoading(true);
    fetchListFundLoad({
      corporateId: id,
      // accountId: '9ba7d7fc-2b41-4b76-9051-5e1d4cb6bce8',
    })
      .then((res) => {
        const fundLoadOrderList = res.data.filter(
          (account: ICardFundLoadProps) =>
            account.accountConsolidatedDto !== null
        );
        setCorporateFundLoadOrderData(fundLoadOrderList);
        // setSnackbar('Funds added!');
        setListLoading(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add Funds'),
          'error'
        );
        setListLoading(false);
      });
  };

  const getCorporatesList = async () => {
    // commented for now
    const data = {
      branchId,
    };

    fetchMCCCorporatesListForAccount(data).then((res) => {
      const filterData = res.data.filter(
        (corporate: ICorporateAccountWalletDataProps) =>
          corporate.account !== null
      );

      setCorporates(filterData);
    });
  };

  const getInvoicesList = async (accountId: string) => {
    // commented for now
    const data = {
      accountId,
    };

    fetchInvoiceListForPostpaidCard(data).then((res) => {
      const filterData = res.data;
      setInvoiceList(res.data);
      // setCorporates(filterData);
    });
  };

  useEffect(() => {
    selectedCard === 'PREPAID_CARD' &&
      filterData?.corporateId.length &&
      getTransactionFundLoadOrderList(filterData?.corporateId);
  }, [filterData.corporateId, selectedCard]);

  useEffect(() => {
    selectedCard === 'PREPAID_CARD' &&
      reloadDetails.accountId.length &&
      getCorporateTransactionList(reloadDetails.accountId);
  }, [reloadDetails.accountId, selectedCard]);

  useEffect(() => {
    getCorporatesList();
  }, []);
  useEffect(() => {
    reloadDetails.linkedOpenCreditAccountId &&
      selectedCard === 'POSTPAID_CARD' &&
      getInvoicesList(reloadDetails.linkedOpenCreditAccountId);
  }, [reloadDetails]);
  console.log('corporates', reloadDetails);
  return (
    <>
      <VegaTab
        tabData={['POSTPAID_CARD', 'PREPAID_CARD']}
        selected={selectedCard}
        onSelect={(select) => {
          setReloadDetails({ ...reloadDetails, accountId: '' });
          setFilterData({
            corporateId: '',
            fromDate: '',
            toDate: '',
          });
          setSelectedCard(select);
        }}
        placement="vertical"
        color={BLUE.lighter}
      />
      <>
        {selectedCard === 'POSTPAID_CARD' ? (
          <VegaPage>
            <CorporateTransactionFilter
              state={filterData}
              handleChange={handleFilter}
              corporates={corporates.filter(
                (corporate: ICorporateAccountWalletDataProps) =>
                  corporate.account?.accountType !== 'PREPAID'
              )}
              // onSearch={onSearch}
            />
            {/* <Grid container spacing={3}>
              {POSTPAID.map((summary: ISummaryProps) => (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box
                    sx={{
                      bgcolor: 'white',
                      p: 3,
                      borderRadius: '20px',
                      borderLeft: `20px solid ${summary.border}`,
                    }}
                  >
                    <Typography>{summary.summaryType}</Typography>
                    <Typography fontSize={30} fontWeight={'bold'} sx={{}}>
                      {summary.count}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid> */}
            <ListOfCorporateTransaction
              invoiceList={invoiceList}
              onRePayment={onRePayment}
            />
          </VegaPage>
        ) : (
          <VegaPage>
            <VegaCard>
              <Grid container spacing={2}>
                <Grid item container spacing={2} xs={12} sm={6}>
                  <Grid item xs={12} sm={6}>
                    <VegaSelect
                      placeholder="Corportate Name"
                      label="Corporate Name"
                      size="small"
                      key={'corporateId'}
                      selected={filterData.corporateId}          
                      onChange={(value) => handleFilter('corporateId', value)}
                      options={corporates
                        .filter(
                          (corporate: ICorporateAccountWalletDataProps) =>
                            corporate.account?.accountType !== 'POSTPAID'
                        )
                        .map((corporate: ICorporateAccountWalletDataProps) => ({
                          id: corporate.corporate?.id as string,
                          name: corporate.corporate?.corporateName as string,
                        }))}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </VegaCard>
            {/* <Grid container spacing={3}>
              {PREPAID.map((summary: ISummaryProps) => (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box
                    sx={{
                      bgcolor: 'white',
                      p: 3,
                      borderRadius: '20px',
                      borderLeft: `20px solid ${summary.border}`,
                    }}
                  >
                    <Typography>{summary.summaryType}</Typography>
                    <Typography fontSize={30} fontWeight={'bold'} sx={{}}>
                      {summary.count}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid> */}
            <VegaCard>
              <Grid
                container
                spacing={2}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Grid item xs={12} sm={3}>
                  <VegaOutLineTextField
                    value={filterReloadCustomers.mobile}
                    onChange={(e) =>
                      handleFilterReloadCustomer('mobile', e.target.value)
                    }
                    key="mobile"
                    type={'text'}
                    placeholder={'Search by Mobile or Customer ID or Emaill'}
                    Icon={<Search />}
                  />
                </Grid>
                {filterData.corporateId.length ? (
                  <Grid
                    item
                    display={'flex'}
                    justifyContent={'end'}
                    xs={12}
                    sm={3}
                  >
                    <VegaContainedButton
                      className="font-aspekta-500"
                      text="Reload"
                      isPrimary
                      onClick={showDetails}
                    />
                    <VegaContainedButton
                      className="font-aspekta-500"
                      text="View Balance"
                      isPrimary
                      onClick={showBalance}
                    />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </VegaCard>

            <ListOfPrepaidCorporateTransaction
              loading={loading}
              data={corporateTransactionData}
              submitInitialCheckerMaker={submitInitialCheckerMaker}
            />
            {/* <ListOfFunloadOrderList data={corporateFundLoadOrderData} /> */}
          </VegaPage>
        )}
        <VegaModal
          header="Reload settings"
          open={openReload}
          handleClose={isOpenReload}
          submitText="Save"
          handleSubmit={onReloadDetailsSubmit}
          buttonIcon={<FIleIcon strokeColor="white" />}
        >
          <CardReload handleChange={handleReloadChange} state={reloadDetails} />
        </VegaModal>
        <VegaDrawer
        open={openBalance}
        onClose={isOpenBalance}
        anchor="right"
        isSubmit={false}
        isClose={false}
        onSubmit={showBalance}
        submitText="Got It!"
        closeText="Close"
        header="Wallet Balance"
      >
          <BalanceView state={corporateSelected} />
      </VegaDrawer>
      </>
    </>
  );
};

export default MCCcorporateTransaction;
