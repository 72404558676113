import React from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { COMMON } from '../../../constants/style';
import { VegaTextField } from '../../common';

const IncreaseCardRange = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const cardData = [
    {
      key: 'Total Card Range',
      value: 524869,
    },
    {
      key: 'Card Issued',
      value: 524869,
    },
    {
      key: 'Total Card Range Available',
      value: 524869,
    },
  ];
  return (
    <>
      <Stack direction={isMobile ? 'column' : 'row'} gap={3}>
        {cardData.map((card) => (
          <Stack key={card.key} direction={'row'} gap={1}>
            <Typography
              className="font-aspekta-500"
              fontSize={12}
              color={COMMON.darkGray}
            >
              {card.key}:
            </Typography>
            <Typography
              className="font-aspekta-400"
              fontSize={12}
              color={'#616E7F'}
            >
              {card.value}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Box mt={2}>
        <VegaTextField
          type="number"
          key={''}
          isFixedHeight
          headerText="No. of cards you want to increase"
        />
      </Box>
    </>
  );
};

export default IncreaseCardRange;
