import { Box, Grid, Typography } from '@mui/material';
import { VegaTextField } from '../../../../../../components/common';
import VegaLabel from '../../../../../../components/common/VegaLabel';
import VegaSelect from '../../../../../../components/common/VegaSelect';
import { BORDER_GRAY, GREEN, PURPLE } from '../../../../../../constants/style';
import { IMarkupFeeComponent } from '../../../../../types/plan';

interface IProps {
  rule: IMarkupFeeComponent | null | any;
  code: string;
  handleChange: (key: string, value: any) => void;
}

const FeesWaiverTable = ({ rule, code, handleChange }: IProps) => {
  const handleChargeChange = (
    key: string,
    name: string,
    value: string | number
  ) => {
    let newCharge = { ...rule[key], [name]: value };
    handleChange(key, newCharge);
  };
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: 0.4 }}>
          <Box sx={{ p: 2, pl: 4, bgcolor: GREEN.lightest }}>Fees</Box>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              px={2}
              my={2}
              sx={{
                borderRight: BORDER_GRAY,
                textAlign: 'center',
              }}
            >
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Value Type
                </Typography>
              </Box>
              <VegaSelect
                isFixedHeight
                size="small"
                key={'valueType'}
                selected={rule?.fee.valueType ?? ''}
                onChange={(value: string) =>
                  handleChargeChange('fee', 'valueType', value)
                }
                options={['PERCENTAGE', 'AMOUNT']}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              px={2}
              my={2}
              textAlign={'center'}
            >
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Value
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mr: 1 }}>
                  {rule?.fee.valueType === 'AMOUNT' ? '$' : ''}
                </Typography>
                <VegaTextField
                  isFixedHeight
                  value={rule?.fee.value ?? ''}
                  onChange={(e) => {
                    let value: any = e.target.value;
                    if (rule?.fee.valueType === 'PERCENTAGE') {
                      if (value > 100) value = 100;
                      if (value < 0) value = 0;
                    }
                    handleChargeChange(
                      'fee',
                      'value',
                      value && parseFloat(value)
                    );
                  }}
                  key="value"
                  type={'number'}
                />
                <Typography sx={{ ml: 1 }}>
                  {rule?.fee.valueType === 'PERCENTAGE' ? '%' : ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flex: 0.6 }}>
          <Box sx={{ p: 2, pl: 4, bgcolor: PURPLE.lightest }}>Waiver</Box>
          {/* <Box
            sx={{
              my: 2,
              px: 2,
              borderBottom: BORDER_GRAY,
              position: 'relative',
            }}
          >
            <Box sx={{ position: 'absolute', left: 0 }}>
              <VegaLabel text="Waiver Type " color="primary" />
            </Box> */}
          <Grid
            container
            sx={{ borderLeft: BORDER_GRAY, position: 'relative' }}
          >
            {/* ?????????????? */}
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: '-5px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <VegaLabel text="Waiver Criteria " color="primary" />
            </Box>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              my={2}
              px={2}
              sx={{
                textAlign: 'center',
              }}
            >
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Value Type
                </Typography>
              </Box>
              <VegaSelect
                isFixedHeight
                size="small"
                key={'valueType'}
                selected={rule?.waiver.valueType ?? ''}
                onChange={(value: string) =>
                  handleChargeChange('waiver', 'valueType', value)
                }
                options={['PERCENTAGE', 'AMOUNT']}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              my={2}
              px={2}
              textAlign={'center'}
            >
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Value
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mr: 1 }}>
                  {rule?.waiver.valueType === 'AMOUNT' ? '$' : ''}
                </Typography>
                <VegaTextField
                  isFixedHeight
                  value={rule?.waiver.value ?? ''}
                  onChange={(e) => {
                    let value: any = e.target.value;
                    if (rule?.waiver.valueType === 'PERCENTAGE') {
                      if (value > 100) value = 100;
                      if (value < 0) value = 0;
                    }
                    handleChargeChange(
                      'waiver',
                      'value',
                      value && parseFloat(value)
                    );
                  }}
                  key="value"
                  type={'number'}
                />
                <Typography sx={{ ml: 1 }}>
                  {rule?.waiver.valueType === 'PERCENTAGE' ? '%' : ''}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              p={2}
              sx={{
                textAlign: 'center',
              }}
            >
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Daily Limit
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mr: 1 }}>$</Typography>
                <VegaTextField
                  isFixedHeight
                  value={rule?.waiver.dailyLimit ?? ''}
                  onChange={(e) =>
                    handleChargeChange(
                      'waiver',
                      'dailyLimit',
                      e.target.value && parseInt(e.target.value)
                    )
                  }
                  key="value"
                  type={'text'}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              p={2}
              textAlign={'center'}
            >
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Monthly Limit
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mr: 1 }}>$</Typography>
                <VegaTextField
                  isFixedHeight
                  value={rule?.waiver.monthlyLimit ?? ''}
                  onChange={(e) =>
                    handleChargeChange(
                      'waiver',
                      'monthlyLimit',
                      e.target.value && parseInt(e.target.value)
                    )
                  }
                  key="value"
                  type={'text'}
                />
              </Box>
            </Grid>
            {/* ????????????? */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                      Value Type
                    </Typography>
                  </Box>
                  <Box sx={{ width: '130px' }}>
                    <VegaSelect
                      isFixedHeight
                      size="small"
                      key={'valueType'}
                      selected={rule?.waiver.valueType ?? ''}
                      onChange={(value: string) =>
                        handleChargeChange('waiver', 'valueType', value)
                      }
                      options={['PERCENTAGE', 'AMOUNT']}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                      Value
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 1 }}>
                      {rule?.waiver.valueType === 'AMOUNT' ? '$' : ''}
                    </Typography>
                    <Box sx={{ width: '100px' }}>
                      <VegaTextField
                        isFixedHeight
                        value={rule?.waiver.value ?? ''}
                        onChange={(e) =>
                          handleChargeChange(
                            'waiver',
                            'value',
                            e.target.value && parseInt(e.target.value)
                          )
                        }
                        key="value"
                        type={'text'}
                      />
                    </Box>
                    <Typography sx={{ ml: 1 }}>
                      {rule?.waiver.valueType === 'PERCENTAGE' ? '%' : ''}
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}
          </Grid>
          {/* </Box> */}
          {/* <Box sx={{ mt: 2, px: 2, pb: 2, position: 'relative' }}>
            <Box sx={{ position: 'absolute', left: 0 }}>
              <VegaLabel text="Waiver Criteria " color="primary" />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                      Daily Limit
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100px' }}>
                    <VegaTextField
                      isFixedHeight
                      value={rule?.waiver.dailyLimit ?? ''}
                      onChange={(e) =>
                        handleChargeChange(
                          'waiver',
                          'dailyLimit',
                          e.target.value && parseInt(e.target.value)
                        )
                      }
                      key="value"
                      type={'text'}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                      Monthly Limit
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100px' }}>
                    <VegaTextField
                      isFixedHeight
                      value={rule?.waiver.monthlyLimit ?? ''}
                      onChange={(e) =>
                        handleChargeChange(
                          'waiver',
                          'monthlyLimit',
                          e.target.value && parseInt(e.target.value)
                        )
                      }
                      key="value"
                      type={'text'}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default FeesWaiverTable;
