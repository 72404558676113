import React from 'react';
import { NEW_PRIMARY } from '../../../constants/style';
import Vega_Text from './Vega_Text';

interface IProps {
  text: string;
  subText?: string;
}

const Vega_HeaderWrapper = ({ text, subText }: IProps) => {
  return (
    <div className="" style={{ paddingBottom: '20px' }}>
      <Vega_Text text={text} fontSize={16} fontWeight={500} />
      {subText && (
        <Vega_Text
          text={subText}
          fontSize={12}
          color={NEW_PRIMARY.GRAY}
          fontWeight={500}
        />
      )}
    </div>
  );
};

export default Vega_HeaderWrapper;
