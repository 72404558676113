import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { listReportCategories } from '../../../../api/reports';
import { CATEGORIES_DATA } from '../../../../components/Reporting/catalog/ReportsIconMap';
import { VegaHorizontalSelectorDataType } from '../../../../components/common/VegaHorizontalSelector';
import VegaSelector from '../../../../components/common/VegaSelector';
import { IReportCategory } from '../../../../types/reports';
import { useCimsProgram } from '../../../provider/CimsReportProvider';
import CimsReportsTable from './CimsReportsTable';

function CimsReportsBody() {
  const { selectedProgram } = useCimsProgram();

  const [selectedCat, setSelectedCat] =
    useState<VegaHorizontalSelectorDataType | null>(null);

  const [reportCategoriesMap, setReportCategoriesMap] = useState<Record<
    string,
    IReportCategory
  > | null>(null);

  const arrOfReportCategories = useMemo(() => {
    let temp: any = [];
    if (reportCategoriesMap) {
      temp = Object.keys(reportCategoriesMap).reduce((pre, key: string) => {
        const curr = reportCategoriesMap[key];
        return [
          ...pre,
          {
            id: curr.categoryId,
            text: curr.reportCategory,
            // image: CATEGORIES_DATA[curr.reportCategory]?.icon,
            bgColor: CATEGORIES_DATA[curr.reportCategory]?.color,
          },
        ];
      }, [] as VegaHorizontalSelectorDataType[]);
    }
    setSelectedCat(temp[0]);

    return temp;
  }, [reportCategoriesMap]);

  const onTabChange = (data: VegaHorizontalSelectorDataType) => {
    if (data.id === 'add_catalog') {
      return;
    } else {
      setSelectedCat(data);
    }
  };

  const fetchReportCategories = async () => {
    const programId =
      selectedProgram?.value || '0d64eb27-037f-458d-b761-fc8bea267547';
    // const programId = '0d64eb27-037f-458d-b761-fc8bea267547';
    const res = await listReportCategories(programId);
    const temp = res.data.reduce(
      (pre: Record<string, IReportCategory>, curr: IReportCategory) => {
        if (curr.reportCategory === 'UNKNOWN') {
          return pre;
        }
        return {
          ...pre,
          [curr.categoryId]: {
            ...curr,
            reportCategory:
              curr.reportCategory === 'CMS'
                ? 'Customer Management System'
                : curr.reportCategory,
          },
        };
      },
      {} as Record<string, IReportCategory>
    );
    setReportCategoriesMap(temp);
  };

  useEffect(() => {
    fetchReportCategories();
  }, [selectedProgram]);

  return (
    <Box>
      {reportCategoriesMap && arrOfReportCategories.length && (
        <>
          <Box mt={2}>
            <VegaSelector
              data={arrOfReportCategories}
              setActiveState={onTabChange}
              active={selectedCat}
              header="Reports Catalog"
              subHeader="Create multi-level, flexible reports at ease."
            />
          </Box>

          {selectedCat && (
            <CimsReportsTable
              selectedCategory={reportCategoriesMap[selectedCat.id]}
              reportCategoriesMap={reportCategoriesMap}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default CimsReportsBody;
