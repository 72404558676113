import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { fetchListOfResources } from '../../../../api/resources';
import { VegaCard, VegaTextField } from '../../../../components/common';
import VegaText from '../../../../components/common/VegaText';
import VegaSelect from '../../../../components/common/VegaSelect';
import VegaDatePicker from '../../../../components/common/VegaDatePicker';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import { ICommonProps } from '../../../../types/commonType';

interface IProps extends ICommonProps {}

const FilterReleaseTransction = ({ handleChange, state }: IProps) => {
  const [currency, setCurrency] = useState<any>([]);
  const [page, setPage] = useState<number>(0);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight - 1
    ) {
      setPage(page + 1);
    }
  };

  const getListAll = () => {
    fetchListOfResources(page, 10, 'currency', '', '')
      .then((res) => {
        const currancyData = res.data.records.map((currency: any) => ({
          name: currency.isoCode,
          id: currency.isoCode,
        }));
        setCurrency([...currency, ...currancyData]);
      })
      .catch((err) => {
        // updateData('loading', false);
        // setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };
  useEffect(() => {
    getListAll();
  }, [page]);
  return (
    <VegaCard>
      <Box mb={2}>
        <VegaText text="Customer Details" />
      </Box>
      <Grid container spacing={2}>
        <Grid item container spacing={2} xs={12} sm={7}>
          <Grid item xs={12} sm={6}>
            <VegaTextField
              value={state?.cardNo}
              onChange={(e) => handleChange('cardNo', e.target.value)}
              key="cardNo"
              label="Card No"
              type={'text'}
              placeholder="Card No"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaSelect
              onScroll={loadMoreItems}
              options={currency}
              onChange={(e: any) => handleChange('currency', e)}
              selected={state?.name}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={12} sm={5}>
          <Grid item xs={12} sm={6}>
            <VegaDatePicker
              label="From Date"
              onChange={(e: any) => handleChange('fromDate', e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaDatePicker
              label="To Date"
              onChange={(e: any) => handleChange('toDate', e)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={2} justifyContent={'end'}>
        <VegaContainedButton mx="0" text="Search" isPrimary />
      </Grid>
    </VegaCard>
  );
};

export default FilterReleaseTransction;
