import React from 'react';
import AccountSummary from './components/AccountSummary';
import ActiveCard from './components/ActiveCard';

const Summary = () => {
  return (
    <>
      <AccountSummary />
      <ActiveCard />
    </>
  );
};

export default Summary;
