import axios from 'axios';

const ENDPOINT = {
  BASE: '/delinquency',
  DELINQUENCY_HISTORY_BY_ID: '/history/accountId',
};

export class DelinquencyServices {
  static async getDelinquencyHistoryByAccountId(
    accountId: string
  ): Promise<IDelinquencyHistoryProps> {
    try {
      const endpoint =
        ENDPOINT.BASE +
        ENDPOINT.DELINQUENCY_HISTORY_BY_ID.replace('accountId', accountId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export interface IDelinquencyHistoryProps {
  accountId: string;
  currentDpd: number;
  currentAmountDue: number;
  maxDpd: number;
  dpdCount: number;
  maxDpd3Months: number;
  maxDpd6Months: number;
}
