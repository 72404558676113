/** @format */

export const VEGA_BOX_SHADOW = '-3px 3px 20px rgba(0, 0, 0, 0.04)';
export const PRIMARY = {
  main: '#131432',
  light: '#4785FF',
  lighter: '#4585ff5c',
  blue: '#293543',
  grey: '#EEEEEE',
  darkBlue: '#1047DC',
};
export const BACKGROUND = {
  BLUE: '#303263',
  GERY: '#EBEFF4',
  GERY_LIGHT: '#C9C8C8',
  WHITE: '#FFFFFF',
  LIGHT_BLUE: '#EFF2F7',
  PURPLE: '#131432',
  PURPLE_LIGHT: '#AAADFF',
};
export const GREY = {
  darkest: '#202020',
  dark: '#8D8D8D',
  lightGray: '#777777',
  light: '#F9F9F9',
  lighter: '#565656',
  grey: '#D1D1D1',
  grey_light: '#5F5F5F',
};
export const BLACK = {
  dark: '#414141',
};
export const RED = {
  dark: '#FF0000',
  light: '#FFCCCB',
  lightDark: '#E8505B',
  lightRed: '#F8E8E8',
  red: '#BA1C1C',
};
export const SUCCESS = {
  light: '#1DC9A0',
};
export const BLUE = {
  main: '#4E83AA',
  dark: '#002054',
  light: '#D8E7FF',
  lighter: '#4585FF',
  lightest: '#F3F7FF',
  brightBlue: '#E8F5FC',
};
export const PINK = {
  light: '#FFCBE7',
};
export const VIOLET = {
  dark: '#5029DB',
};
export const PURPLE = {
  main: '#9589DA',
  dark: '#4E60AA',
  lightest: '#D2D9F4',
  violet: '#EEEAFB',
};

export const ORANGE = {
  main: '#F6AC77',
  dark: '#f26424',
  light: '#FFDBCB',
  lightest: '#F4E3D2',
};
export const GREEN = {
  main: '#4EAA9F',
  dark: '#36913F',
  light: '#D0FFD2',
  lighter: '#EBFBFF',
  lightest: '#CBFFE3',
};
export const YELLOW = {
  dark: '#DDBA08',
  main: '#bd8300',
  light: '#ffff65',
  lighter: '#FFF9DC',
  lightest: '#E7F4D2',
  indicative: '#FCF8E6',
};

export const COMMON = {
  text100: '#676B76',
  gray: '#3A4A5F',
  darkGray: '#181F28',
  black: '#1B1D22',
};

export const NEW_PRIMARY = {
  MAIN: '#05162A',
  ACTIVE_MAIN: '#031120',
  GOLD: '#C8A864',
  GRAY: '#676B76',
  BLUE: '#1047DC',
  BLACK: '#1B1D22',
  RED: '#E80101',
  GREEN: '#318439',
  YELLOW: '#C9A907',
  ORANGE: '#E87001',
  LIGHT_BLUE: '#1A89C9',
};

export const STROKE_COLOR = '#5F5F5F';

export const BORDER = '1px solid #BFABDB';
export const BORDER_BLUE = '1px solid #9EBFFF';
export const BORDER_GRAY = '1px solid #D1D1D1';
export const BORDER_GRAY_DARK = '1px solid #323336DE';

export const LINEAR_GRADIANT_COLOR = {
  BACKGROUND: `linear-gradient(to right, #E7EDFC80 , #E7EDFC40)`,
};
