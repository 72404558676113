import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import VegaText from '../../../../../../components/common/VegaText';
import VegaSelect from '../../../../../../components/common/VegaSelect';
import { VegaTextField } from '../../../../../../components/common';
import { IFeeDetailsProps, PERCENTAGE_CHARGES } from '../Charges';
import { IChareRulesProps } from './ChargesCard';
import { toLowerCase } from '../../../../../../constants/commonFunction';

const PercentageChargesDetails = ({
  rule,
  code,
  handleChange,
}: IChareRulesProps) => {
  const handleCharges = (name: string, value: any) => {
    let newCharge = { ...rule['chargeValue'], [name]: value };
    handleChange('chargeValue', newCharge);
  };
  console.log('ssss', rule);
  return (
    <Box sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={2} px={1}>
          <Box sx={{ mb: 2 }}>
            <VegaText text="Value Type" />
          </Box>
          <VegaTextField
            isFixedHeight
            value={toLowerCase(rule?.chargeValue?.valueType) ?? ''}
            onChange={(e) => {}}
            key="value"
            type={'text'}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={2} px={1}>
          <Box sx={{ mb: 2 }}>
            <VegaText text="Value" />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <VegaTextField
              isFixedHeight
              value={rule?.chargeValue?.value ?? ''}
              onChange={(e) => {
                let value: any = e.target.value;
                if (rule?.chargeValue?.valueType === 'PERCENTAGE') {
                  if (value > 100) value = 100;
                  if (value < 0) value = 0;
                }
                handleCharges('value', value && parseFloat(value));
              }}
              key="value"
              type={'number'}
            />
            <Typography sx={{ ml: 1 }}>%</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} px={1}>
          <Box sx={{ mb: 2 }}>
            <VegaText text="Tax Type" />
          </Box>
          <VegaSelect
            isFixedHeight
            size="small"
            key={'taxType'}
            selected={rule?.taxType ?? ''}
            onChange={(value: string) => handleChange('taxType', value)}
            options={['INCLUSIVE_OF_TAXES', 'EXCLUSIVE_OF_TAXES', 'NO_TAX']}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PercentageChargesDetails;
