/** @format */

import { ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  AccessForProgram,
  ACCESS_FEATURE_OPTIONS,
} from '../../../pages/RolesAndAccess';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { VegaProgramType } from '../../../types/program';
import { VegaAccordian } from '../../common';
import VegaDrawer from '../../common/VegaDrawer';
import VegaSelectorList from '../../common/VegaSelectorList';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  featuresForProgram: AccessForProgram;
  onProgramAccordianStateChange?: (programId: string) => void;
  onFeaturesChangeForProgram?: (
    programId: string,
    value: string,
    isSelected: boolean
  ) => void;
  nonEditable?: boolean;
};

const AccessFeaturesControl = ({
  open,
  onClose,
  onSubmit,
  featuresForProgram,
  onProgramAccordianStateChange,
  onFeaturesChangeForProgram,
  nonEditable = false,
}: Props) => {
  const { programs } = useProgramData();
  const normalisedPrograms = programs.map((value: VegaProgramType) => ({
    text: value.name,
    value: value.programId,
  }));

  return (
    <VegaDrawer
      open={open}
      onClose={onClose}
      anchor="right"
      isSubmit={!nonEditable}
      isClose={true}
      onSubmit={onSubmit}
      closeText="Close"
      submitText={'Submit'}
      header="Allow Access"
    >
      <Box sx={{ mb: 2 }}>
        <VegaAccordian
          expandIcon={<ExpandMore fontSize="small" />}
          expanded={!!featuresForProgram['ALL']}
          onChange={() => {
            !nonEditable && onProgramAccordianStateChange?.('ALL');
          }}
          header={'All'}
          content={
            featuresForProgram['ALL'] && (
              <VegaSelectorList
                options={ACCESS_FEATURE_OPTIONS}
                onSelect={(isSelected, value) => {
                  !nonEditable &&
                    onFeaturesChangeForProgram?.('ALL', value, !!isSelected);
                }}
                selected={featuresForProgram['ALL']}
              />
            )
          }
        />
      </Box>
      {normalisedPrograms.map((program: any) => (
        <Box sx={{ mb: 2 }}>
          <VegaAccordian
            expandIcon={<ExpandMore fontSize="small" />}
            expanded={!!featuresForProgram[program?.value]}
            onChange={() => {
              !nonEditable && onProgramAccordianStateChange?.(program?.value);
            }}
            header={program.text}
            content={
              <VegaSelectorList
                options={ACCESS_FEATURE_OPTIONS}
                onSelect={(isSelected, value) => {
                  !nonEditable &&
                    onFeaturesChangeForProgram?.(
                      program.value,
                      value,
                      !!isSelected
                    );
                }}
                selected={featuresForProgram[program.value]}
              />
            }
          />
        </Box>
      ))}
    </VegaDrawer>
  );
};

export default AccessFeaturesControl;
