import { Box } from '@mui/material';
import { useState } from 'react';
import { Program_manage, ServiceIcon } from '../components/icon/Icons';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import MCCReport from '../mcc/Back-office/pages/MCCReport';
import CheckerApplicationManagement from '../mcc/Program-Dashboard/pages/CheckerApplicationManagement';
import CheckerCardTransaction from '../mcc/Program-Dashboard/pages/CheckerCardTransaction';
import CheckerCorporateTransaction from '../mcc/Program-Dashboard/pages/CheckerCorporateTransaction';
import CheckerInventoryManagement from '../mcc/Program-Dashboard/pages/CheckerInventoryManagement';
import HQAnalytics from '../mcc/Program-Dashboard/pages/HQAnalytics';
import MCCBranch from '../mcc/Program-Dashboard/pages/MCCBranch';
import MCCInstitutionalResources from '../mcc/Program-Dashboard/pages/MCCInstitutionalResources';
import MCCPolicies from '../mcc/Program-Dashboard/pages/MCCPolicies';
import MCCProgram from '../mcc/Program-Dashboard/pages/MCCProgram';
import MCCProgramAllocation from '../mcc/Program-Dashboard/pages/MCCProgramAllocation';
import MCCRole from '../mcc/Program-Dashboard/pages/MCCRole';
import MCCTeamManagementChecker from '../mcc/Program-Dashboard/pages/MCCTeamManagementChecker';
import MCCTeamManagementForHQ from '../mcc/Program-Dashboard/pages/MCCTeamManagementForHQ';
import MCCcardSaleChecker from '../mcc/Program-Dashboard/pages/MCCcardSaleChecker';
import PartnerManagement from '../mcc/Program-Dashboard/pages/PartnerManagement';
import { useClientAuth } from '../providers/ClientProvider';
import { MCC_ROUTES } from '../routes';

type Props = {};

const MCCProgramContainer = (props: Props) => {
  const { clientId } = useClientAuth();

  const NAV_OPTIONS: SidebarListItemType[] = [
    {
      id: MCC_ROUTES.CREATE_PROGRAM,
      icon: <Program_manage />,
      label: 'Create Program',
      component: <MCCProgram />,
    },
    {
      // id: MCC_ROUTES.CREATE_PARTNER,
      id: MCC_ROUTES.PARTNER_MANAGEMENT,
      icon: <Program_manage />,
      label: 'Partner Management',
      component: <PartnerManagement />,
    },
    {
      id: MCC_ROUTES.ANALYTICS,
      icon: <Program_manage />,
      label: 'Analytics',
      component: <HQAnalytics />,
    },

    {
      id: MCC_ROUTES.REPORT,
      icon: <Program_manage />,
      label: 'Report',
      component: <MCCReport authId={clientId} platform='HQ' />,
    },
    {
      id: MCC_ROUTES.CREATE_BRANCH,
      icon: <Program_manage />,
      label: 'Create Branch',
      component: <MCCBranch />,
    },
    {
      id: MCC_ROUTES.CREATE_ROLE,
      icon: <Program_manage />,
      label: 'Roles & Access',
      component: <MCCRole />,
    },
    {
      id: MCC_ROUTES.TEAM_MANAGEMENT_MAKER,
      icon: <Program_manage />,
      label: 'Team Management',
      component: <MCCTeamManagementForHQ />,
    },
    {
      id: MCC_ROUTES.POLICIES,
      icon: <ServiceIcon />,
      label: 'Policies',
      component: <MCCPolicies />,
    },
    {
      id: MCC_ROUTES.PROGRAM_ALLOCATION,
      icon: <ServiceIcon />,
      label: 'Program Allocation',
      component: <MCCProgramAllocation />,
    },
    {
      id: MCC_ROUTES.INSTITUTIONAL_RESOURCES,
      icon: <ServiceIcon />,
      label: 'Institutional  Resources',
      component: <MCCInstitutionalResources />,
    },
    {
      id: MCC_ROUTES.TEAM_MANAGEMENT_CHECKER,
      icon: <Program_manage />,
      label: 'Team Management',
      component: <MCCTeamManagementChecker />,
      isChecker: true,
    },
    {
      id: MCC_ROUTES.CORPORATE_TRANSACTION_CHECKER,
      // id: MCC_ROUTES.CHECKER_CARD_TRANSACTION,
      icon: <Program_manage />,
      label: 'Corporate Transaction',
      component: <CheckerCorporateTransaction />,
      isChecker: true,
    },
    {
      id: MCC_ROUTES.CARD_CHECKER,
      icon: <ServiceIcon />,
      label: 'Card Sale',
      component: <MCCcardSaleChecker />,
      isChecker: true,
    },
    // {
    //   id: MCC_ROUTES.CHECKER_TEAM_MANAGEMENT,
    //   icon: <ServiceIcon />,
    //   label: 'Team Management',
    //   component: <CheckerTeamManagement />,
    // },
    {
      id: MCC_ROUTES.CHECKER_APPLICATION_MANAGEMENT,
      icon: <ServiceIcon />,
      label: 'Application Management',
      component: <CheckerApplicationManagement />,
      isChecker: true,
    },
    {
      id: MCC_ROUTES.CHECKER_CARD_TRANSACTION,
      icon: <ServiceIcon />,
      label: 'Card Transaction',
      component: <CheckerCardTransaction />,
      isChecker: true,
    },
    {
      id: MCC_ROUTES.CHECKER_INVENTORY_MANAGEMENT,
      icon: <ServiceIcon />,
      label: 'Inventory Management',
      component: <CheckerInventoryManagement />,
      isChecker: true,
    },
  ];
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  );

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={
          //   isAdmin ? NAV_OPTIONS : [...NON_ADMIN, ...NAV_OPTIONS]
          NAV_OPTIONS
        }
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default MCCProgramContainer;
