/** @format */

import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import { Box, IconButton, Slide, Snackbar, Typography } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { AxiosError } from 'axios';
import _ from 'lodash';
import React, { createContext, ReactNode, useContext } from 'react';
import { VEGA_BOX_SHADOW } from '../constants/style';
import { getErrorMessageFromErrorObj } from '../utils/api';

const SUCCESS_GREEN = '#37C968';
const ERROR_RED = '#C93737';

type Props = {
  children: ReactNode;
};

export type SnackbarContextType = {
  onClose?: () => void;
  setSnackbar: (content: string, type?: AlertColor) => void;
  setSnackbarError: (error: any) => void;
};

type SnackbarStateInterface = {
  isOpen: boolean;
  type: AlertColor;
  content: string;
};

const SnackbarContext = createContext<SnackbarContextType | null>(null);
export const useSnackbar = () => {
  return useContext(SnackbarContext) as SnackbarContextType;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref: any
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarProvider = ({ children }: Props) => {
  const [snackbarState, setSnackbarState] =
    React.useState<SnackbarStateInterface>({
      isOpen: false,
      type: 'success',
      content: '',
    });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
    // type: AlertColor
  ) => {
    console.log('reason', reason);
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ isOpen: false, content: '', type: type });
  };

  const setSnackbar = (snackbarContent: string, snackbarType?: AlertColor) => {
    setSnackbarState({
      isOpen: true,
      type: snackbarType || 'success',
      content: snackbarContent,
    });
  };

  //  Need to make VegaError Type
  const _setSnackbarError = (error: any) => {
    setSnackbarState({
      isOpen: true,
      type: 'error',
      content: _.startCase(_.toLower(getErrorMessageFromErrorObj(error))),
    });
  };

  const { isOpen, type, content } = snackbarState;
  const isSuccess = type == 'success';
  const typeColor = isSuccess ? SUCCESS_GREEN : ERROR_RED;
  return (
    <>
      <SnackbarContext.Provider
        value={{ setSnackbar, setSnackbarError: _setSnackbarError }}
      >
        {children}
      </SnackbarContext.Provider>

      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={Slide}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          zIndex: 100000,
        }}
      >
        <Box
          sx={{
            height: '100%',
            minWidth: 250,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            boxShadow: VEGA_BOX_SHADOW,
            borderRadius: '9px',
            bgcolor: 'background.paper',
            borderLeft: `5px solid ${typeColor}`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isSuccess ? (
              <CheckCircleOutline
                htmlColor={SUCCESS_GREEN}
                fontSize={'large'}
              />
            ) : (
              <CancelOutlined htmlColor={ERROR_RED} fontSize={'large'} />
            )}
            <Box
              ml={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subtitle2"
                color={typeColor}
                lineHeight={'unset'}
              >
                {type == 'success' ? 'Success' : 'Error'}
              </Typography>
              <Typography variant="caption">{content}</Typography>
            </Box>
          </Box>
          <IconButton onClick={handleClose}>
            <CancelOutlined fontSize="small" color="disabled" />
          </IconButton>
        </Box>
      </Snackbar>
    </>
  );
};

export default SnackbarProvider;
