import { GridColumns } from '@mui/x-data-grid';
import React from 'react';
import Vega_Datagrid from '../../../../../common/v3/Vega_Datagrid';
import { IDelinquencyHistoryStateProps } from '../Delinquency';

interface IProps {
  delinquencyHistoryState: IDelinquencyHistoryStateProps;
}

const ListOfDelinquncyHistory = ({ delinquencyHistoryState }: IProps) => {
  const delinquencyHistoryData = [
    {
      accountId: delinquencyHistoryState.data?.accountId,
      currentDpd: delinquencyHistoryState.data?.currentDpd,
      entryDate: '-',
      exitDate: '-',
      currentAmountDue: delinquencyHistoryState.data?.currentAmountDue,
      billDate: '-',
      id: 1,
    },
  ];
  const COLUMN_DEF: GridColumns = [
    {
      field: 'accountId',
      headerName: 'Serial No.',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },

    {
      field: 'currentDpd',
      headerName: 'DPD',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'entryDate',
      headerName: 'DPD Entry Date',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'exitDate',
      headerName: 'DPD Exit Date',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'currentAmountDue',
      headerName: 'Amount Due',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'billDate',
      headerName: 'Bill Date',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 100,
    },
  ];
  return (
    <>
      <Vega_Datagrid
        data={delinquencyHistoryData}
        loading={delinquencyHistoryState.loading}
        idColumn="id"
        columns={COLUMN_DEF}
      />
    </>
  );
};

export default ListOfDelinquncyHistory;
