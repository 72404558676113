import React, { createContext, ReactNode, useContext, useState } from 'react';
import { fetchCustomerByCustomerId } from '../api/customer';
import {
  fetchApplicationByApplicationId,
  fetchLimitOffersForProgramId,
  updateLimitOfferStatus,
} from '../api/los';
import { ICustomer } from '../types/customers';
import { VegaLimitOfferStatus, VegaLosLimitOffer } from '../types/los';
import { useProgramData } from './ProgramDataProvider';
import { useSnackbar } from './SnackbarProvider';

type SelectedOfferData = {
  customerId: string | null;
  offerId: string | null;
  offerStatus: VegaLimitOfferStatus | null;
  offer: VegaLosLimitOffer | null;
};

const EMPTY_DATA: SelectedOfferData = {
  customerId: null,
  offerId: null,
  offerStatus: null,
  offer: null,
};

export interface ApplicationManagementContextProps {
  limitOffers: VegaLosLimitOffer[];
  selectedOfferData: Partial<VegaLosLimitOffer>;
  customer: ICustomer | null;
  loading: boolean;
  didAcceptLimitOffer: () => void;
  didRejectLimitOffer: () => void;
  fetchOffers: () => void;
  canApproveSelectedOffer: () => void;
  getCustomerByCustomerId: (customerId: string) => void;
  getApplicationByApplicationId: (applicationId: string) => void;
  setSelectedOfferData: React.Dispatch<
    React.SetStateAction<Partial<VegaLosLimitOffer>>
  >;
  setCustomer: React.Dispatch<React.SetStateAction<ICustomer | null>>;
}

const ApplicationManagementContext =
  createContext<ApplicationManagementContextProps | null>(null);

export const useApplicationManagmentConfig = () => {
  return useContext(
    ApplicationManagementContext
  ) as ApplicationManagementContextProps;
};

function ApplicationManagmentProvider(props: { children: ReactNode }) {
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();
  const [limitOffers, setLimitOffers] = useState<VegaLosLimitOffer[]>([]);
  const [selectedOfferData, setSelectedOfferData] = useState<
    Partial<VegaLosLimitOffer>
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldOpenDrawer, setShouldOpenDrawer] = useState<boolean>(false);
  const [customer, setCustomer] = useState<ICustomer | null>(null);

  function didAcceptLimitOffer() {
    setShouldOpenDrawer(false);
    const offerId = selectedOfferData.id;
    const acceptedLimit = selectedOfferData?.offerAmount;
    if (!offerId || !acceptedLimit) {
      setSnackbar('Failed to update Application Status', 'error');
      return;
    }
    updateLimitOfferStatus({
      updatedStatus: VegaLimitOfferStatus.ACCEPTED,
      limitOfferId: offerId,
      acceptedLimit,
    })
      .then((updatedOffer) => {
        const updatedOffers = limitOffers.map((offer) => {
          if (offer.id === offerId) {
            return updatedOffer;
          }
          return offer;
        });
        setLimitOffers(updatedOffers);
        setSelectedOfferData(updatedOffer);
      })
      .catch((error) => {
        setSnackbar('Failed To update Limit offer status', 'error');
      });
  }

  function didRejectLimitOffer() {
    setShouldOpenDrawer(false);
    const offerId = selectedOfferData.id;
    if (!offerId) {
      setSnackbar('Failed to update Application Status', 'error');
      return;
    }
    updateLimitOfferStatus({
      updatedStatus: VegaLimitOfferStatus.REJECTED,
      limitOfferId: offerId,
      rejectionReason: 'Rejection reason', // TODO: someone remove this and do it properly when bandwidth comes
    })
      .then((updatedOffer) => {
        const updatedOffers = limitOffers.map((offer) => {
          if (offer.id === offerId) {
            return updatedOffer;
          }
          return offer;
        });
        setLimitOffers(updatedOffers);
      })
      .catch((error) => {
        setSnackbar('Failed To update Limit offer status', 'error');
      });
  }

  const canApproveSelectedOffer = () => {
    const status = selectedOfferData.offerStatus;
    return status == VegaLimitOfferStatus.PENDING;
  };

  function fetchOffers() {
    const programId = selectedProgram?.programId;
    if (!programId) return;
    fetchLimitOffersForProgramId({ programId: programId })
      .then((offers) => {
        setLimitOffers(offers.records);
      })
      .catch((error) => {
        setSnackbar('Failed To Fetch Limit offers for program', 'error');
      });
  }

  const getCustomerByCustomerId = async (customerId: string) => {
    try {
      const response = await fetchCustomerByCustomerId(customerId);
      setCustomer(response.data);
    } catch (error) {
      setSnackbar('Failed to load customer', 'error');
    }
  };
  const getApplicationByApplicationId = async (applicationId: string) => {
    setLoading(true);
    try {
      const response = await fetchApplicationByApplicationId(applicationId);
      await getCustomerByCustomerId(response.customerId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbar('Failed to load customer', 'error');
    }
  };

  return (
    <ApplicationManagementContext.Provider
      value={{
        loading,
        limitOffers: limitOffers,
        selectedOfferData: selectedOfferData,
        customer: customer,
        didRejectLimitOffer: didRejectLimitOffer,
        canApproveSelectedOffer: canApproveSelectedOffer,
        didAcceptLimitOffer: didAcceptLimitOffer,
        setSelectedOfferData: setSelectedOfferData,
        getCustomerByCustomerId: getCustomerByCustomerId,
        setCustomer: setCustomer,
        fetchOffers,
        getApplicationByApplicationId,
      }}
    >
      {props.children}
    </ApplicationManagementContext.Provider>
  );
}

export default ApplicationManagmentProvider;
