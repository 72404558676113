/** @format */

import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DEFAULT_ACCOUNT_POLICY } from '../../types/defaultPolicy';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import { useSnackbar } from '../../providers/SnackbarProvider';
import {
  ACCOUNT_POLICY_KEYS,
  AccountPolicyInterface,
  AccountPolicyValueType,
  AnyPolicyType,
  PolicyComponentType,
  PolicyType,
} from '../../types/policy';
import AccountInputRows from './AccountInputRows';
import ColumnsInputGreyHeader from './ColumnsInputGreyHeader';
import PolicyCard from './PolicyCard';
import { DEFAULT_COL_WIDTHS } from './ThreeColumnInputFields';
import { PolicyWrapper } from './PolicyWrapper';
import PolicyCommentCard from './PolicyCommentCard';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import { usePolicy } from '../../providers/PolicyProvider';

const HEADERS = ['Category', 'Value Type', 'Value'];
const AccountPolicy = ({
  initialValue,
  onSubmit,
  isLoading,
  onApprove,
  onReject,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    entityAuthStatuses,
    checkPolicyStatusUpdatable,
    checkPolicyEditable,
    addCommentOnPolicy,
    updateCommentOnPolicy,
    loggedInMakerCheckerUser,
    areAllPoliciesApproved,
    getDefaultPolicy,
  } = usePolicy();
  const [policy, setPolicy] = useState<AccountPolicyInterface | null>();
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );
  const { setSnackbar } = useSnackbar();

  const handleChange = (
    key: ACCOUNT_POLICY_KEYS,
    value: string | number | AccountPolicyValueType
  ) => {
    if (!policy) return;
    setPolicy({ ...policy, [key]: value });
  };

  const handleValueChange = (
    key: ACCOUNT_POLICY_KEYS,
    subkey: keyof AccountPolicyValueType,
    value: string | number
  ) => {
    if (!policy) return;
    const current = policy[key];
    const editedVal = { ...current, [subkey]: value };
    handleChange(key, editedVal);
  };

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = policy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(policyId);
    console.log('================checkPolicyEditable====================');
    console.log(response);
    console.log('====================================');
    const canUpdateStatus = await checkPolicyStatusUpdatable(policyId);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  function didSubmit() {
    if (policy) {
      const {
        days_to_mark_account_write_off: writeOffDays,
        days_to_mark_account_charge_off: chargeOffDays,
      } = policy;
      const errorMessage =
        writeOffDays <= 0 || chargeOffDays <= 0
          ? 'Days should be greater than or equal to 1'
          : chargeOffDays >= writeOffDays
          ? 'Charge Off Days cannot be Greater Or Equal to Write Off'
          : null;
      if (errorMessage) {
        setSnackbar(errorMessage, 'error');
        return;
      }
      const updatedPolicy: AccountPolicyInterface = {
        ...policy,
      };
      onSubmit(updatedPolicy);
    }
  }

  function didClickApprove() {
    if (policy) onApprove(policy);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  function fetchDefaultPolicy() {
    getDefaultPolicy(PolicyType.ACCOUNT)
      .then((defaultPolicy) => {
        if (defaultPolicy) {
          const accountPolicy = defaultPolicy as AccountPolicyInterface;
          setPolicy(accountPolicy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (initialValue) setPolicy(initialValue);
    else {
      fetchDefaultPolicy();
    }
  }, [initialValue]);

  useEffect(() => {
    getEntityStatusAndCheckIfCanEdit();
  }, [policy, loggedInMakerCheckerUser]);

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={policy?.policy_id}
      policyView={
        <Box>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                onPastCommentClick={() => {
                  onPastCommentsClick(policy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
          />
          <PolicyCard
            policy={policy as AnyPolicyType}
            onSaveClick={didSubmit}
            isLoading={isLoading}
            isSaveDisabled={canUpdatePolicyStatus == false}
            onApproveClick={didClickApprove}
            viewFor={loggedInMakerCheckerUser?.role}
            onCommentAddClick={addCommentOnEntity}
            policyEntityAuth={entityAuthStatus}
            onPastCommentsClick={onPastCommentsClick}
            onCommentEdit={updateEntityComment}
          >
            <Typography mb={3}>Account Configuration</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 3,
              }}
            >
              <Typography mr={2}>Grace Period</Typography>
              <TextField
                size="small"
                type={'number'}
                sx={{ width: 120 }}
                value={policy?.grace_period}
                onChange={(e) =>
                  handleChange(
                    'grace_period',
                    e.target.value && parseFloat(e.target.value)
                  )
                }
                disabled={canEditPolicy == false}
              />
            </Box>
            <ColumnsInputGreyHeader
              headerTexts={HEADERS}
              columnWidths={DEFAULT_COL_WIDTHS}
            />
            <AccountInputRows
              label="Overlimit"
              valueType={policy?.overlimit.value_type}
              value={policy?.overlimit.value}
              onChange={(key, value) =>
                handleValueChange('overlimit', key, value)
              }
              disabled={canEditPolicy == false}
            />
            <AccountInputRows
              label="Payment Deviation"
              valueType={policy?.payment_deviation.value_type}
              value={policy?.payment_deviation.value}
              onChange={(key, value) =>
                handleValueChange('payment_deviation', key, value)
              }
              disabled={canEditPolicy == false}
            />
            <AccountInputRows
              label="Cash Limit"
              valueType={policy?.cash_limit.value_type}
              value={policy?.cash_limit.value}
              onChange={(key, value) =>
                handleValueChange('cash_limit', key, value)
              }
              disabled={canEditPolicy == false}
            />
            <AccountInputRows
              label="Charge Off"
              valueType={'DAYS'}
              value={policy?.days_to_mark_account_charge_off ?? 0}
              onChange={(key, value) =>
                handleChange('days_to_mark_account_charge_off', value)
              }
              disabled={canEditPolicy == false}
            />
            <AccountInputRows
              label="Write Off"
              valueType={'DAYS'}
              value={policy?.days_to_mark_account_write_off ?? 0}
              onChange={(key, value) =>
                handleChange('days_to_mark_account_write_off', value)
              }
              disabled={canEditPolicy == false}
            />
          </PolicyCard>
        </Box>
      }
    />
  );
};

export default AccountPolicy;
