import axios from 'axios';
import { BASE_URL, CHARGE, GETWAY, TRANSACTION } from './endpoints';

const CREATE_TRANSACTION_URL = `${BASE_URL}${GETWAY}${TRANSACTION.BASE}`;
const CREATE_WALLET_TRANSFER_URL = `${BASE_URL}${GETWAY}${TRANSACTION.WALLET_TRANSFER}`;
const CREATE_CHARGE_URL = `${BASE_URL}${GETWAY}${CHARGE.BASE}`;

export const createForexTransaction = (data: any) => {
    return axios.post(CREATE_TRANSACTION_URL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
};

export const createForexWalletTransfer = (data: any) => {
    return axios.post(CREATE_WALLET_TRANSFER_URL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
};

export const createForexCharge = (data: any) => {
    return axios.post(CREATE_CHARGE_URL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
};

export const getForexTransactionForAccount = (data: any) => {
    return axios.post(CREATE_TRANSACTION_URL + '/list', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
}