import { useEffect } from 'react';
import { useCampaign } from '../../../components/RewardsLoyalty/providers/CampaignProvider';
import { CampaignRedemptionRule } from '../../../types/RewardsLoyalty';
import CampaignRedemptionInput from './CampaignRedemptionInput';

function RedemptionTabPanel() {
  const { selectedCampaign, saveRedemptionRule } = useCampaign();

  return (
    <CampaignRedemptionInput
      value={selectedCampaign?.redemptionRule}
      onSubmit={function (data: CampaignRedemptionRule): void {
        saveRedemptionRule(data);
      }}
    />
  );
}

export default RedemptionTabPanel;
