import { ReactNode } from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import {
  RED,
  GREEN,
} from '../../../components/common/deprecated/colors/fontColor';

interface CIMS_ButtonProps extends ButtonProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  text: string;
  loading?: boolean;
  statusColor?: 'primary' | 'success' | 'rejected' | null;
}
interface StyledLoaderProps extends CircularProgressProps {
  buttonVariant?: string;
}

const StyledLoader = styled(CircularProgress)<StyledLoaderProps>(
  ({ theme, buttonVariant = 'contained', size }) => {
    if (buttonVariant == 'contained') {
      return {
        size: size,
        color: 'white',
      };
    }
    return {
      size: size,
      color: '#001aa1',
    };
  }
);

const CIMS_Button = ({
  text,
  loading,
  startIcon,
  endIcon,
  statusColor,
  onClick,
  sx,
  ...rest
}: CIMS_ButtonProps) => {
  return (
    <Button
      {...rest}
      disabled={rest.disabled || loading}
      startIcon={
        loading ? (
          <StyledLoader size={24} buttonVariant={rest.variant} />
        ) : (
          startIcon
        )
      }
      endIcon={endIcon}
      onClick={onClick}
      sx={{
        textTransform: 'capitalize',
        fontSize: '12px',
        borderRadius: '4px',
        fontFamily: `var(--font-Aspekta-500) !important`,
        ...(rest.variant !== 'text'
          ? statusColor === 'success'
            ? {
                bgcolor: GREEN.lightgreen,
                color: GREEN.green,
                ':hover': { bgcolor: GREEN.lightgreen, color: GREEN.green },
                '&.Mui-disabled': {
                  opacity: 0.5,
                  color: GREEN.green,
                },
              }
            : statusColor === 'rejected'
            ? {
                bgcolor: RED.lightred,
                color: RED.red,
                ':hover': { bgcolor: RED.lightred, color: RED.red },
                '&.Mui-disabled': {
                  opacity: 0.5,
                  color: RED.red,
                },
              }
            : {
                bgcolor: '#1047DC',
                color: 'white',
                ':hover': { bgcolor: '#1047DC', color: 'white' },
                '&.Mui-disabled': {
                  opacity: 0.5,
                  color: 'white',
                },
              }
          : {
              color: '#1047DC',
              bgcolor: 'white',
              ':hover': { color: '#1047DC', bgcolor: 'white' },
            }),
        ...sx,
      }}
    >
      {text}
    </Button>
  );
};

export default CIMS_Button;
