import { Box, Stack, Typography } from '@mui/material';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../../services/ChargeBackService';
import { ChargebackStatus } from '../../../../types/chargeBack';
import { VPButton, VPModal } from '../../../../ui-components/common';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useChargeBack } from '../../hooks';

type IProps = {
  isOpen: boolean;
  handleClose: () => void;
};
export const AquirerAccepts = ({ isOpen, handleClose }: IProps) => {
  const { selectedChargeBack, setSelectedChargeBack } = useChargeBack();
  const { setSnackbar } = useSnackbar();

  const onSubmit = () => {
    ChargebackService.updateChargebackStatus({
      chargebackId: selectedChargeBack?.id,
      status: ChargebackStatus.CHARGEBACK_ACCEPTED,
    })
      .then((res) => {
        setSnackbar('Chargeback status updated successfully', 'success');
        setSelectedChargeBack(res);
        handleClose();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(
            err,
            'Error while updating chargeback status'
          ),
          'error'
        );
      });
  };
  return (
    <Box>
      <VPModal
        maxWidth="sm"
        open={isOpen}
        handleClose={handleClose}
        header={' Change Status'}
        footer={
          <Stack spacing={1} direction={'row'}>
            <VPButton onClick={onSubmit}>Confirm</VPButton>
          </Stack>
        }
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <Stack spacing={2}>
            <Typography>
              {' '}
              Do you want to confirm Issuer has won the chargeback
            </Typography>
          </Stack>
        </Box>
      </VPModal>
    </Box>
  );
};
