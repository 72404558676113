import { useEffect, useMemo, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import { sorting } from '../../../constants/commonFunction';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import CustomerService from '../../../services/CustomerService';
import { ICustomer, IUpdateCheckerMakerReq } from '../../../types/customers';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { Grid, Pagination, Box } from '@mui/material';
import { CheckerMakerStatusEnum } from '../../../enums/commons';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { IFilterProps } from '../../Back-office/pages/MCCAplicationManagement';
import { STATUS } from '../../variable';
import ListOfApplicationManagement from '../components/CheckerApplicationManagement/ListOfApplicationManagement';
import FilterAction from '../components/FilterAction';
import { fetchAllCustomers } from '../api/customer';
import { GREY } from '../../../constants/style';

interface IExtendedCus extends ICustomer {
  selected: boolean;
}

const CheckerApplicationManagement = () => {
  const { userId, mccPlatformId, clientId } = useClientAuth();
  const branchId = mccPlatformId;
  const { setSnackbar } = useSnackbar();
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(0);

  const [customerData, setCustomerData] = useState<IExtendedCus[]>([]);
  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });

  const filteredCustomers = useMemo(() => {
    return customerData.filter((cus) => {
      return (
        (cus.emailId?.toLowerCase().includes(filterData.mobile.toLowerCase()) ||
          cus.mobileNumber
            ?.toLowerCase()
            .includes(filterData.mobile.toLowerCase())) &&
        (filterData.status === 'ALL' ||
          filterData.status === cus.makerCheckerStatus)
      );
    });
  }, [filterData, customerData]);

  const handleFilter = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const onSubmitStatus = (data: IUpdateCheckerMakerReq) => {
    CustomerService.updateCheckerMaker(data)
      .then((res) => {
        setSnackbar('Status change!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to change status'),
          'error'
        );
      });
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1);
  };

  const isCheckArr = customerData.filter((val) => val.selected);

  const onApprove = () => {
    selectedRows.map((val) => {
      const obj = {
        status: CheckerMakerStatusEnum.APPROVED,
        userId: userId || '',
        entityId: val,
      };
      onSubmitStatus(obj);
    });
  };
  const onReject = () => {
    selectedRows.map((val) => {
      const obj = {
        status: CheckerMakerStatusEnum.REJECTED,
        userId: userId || '',
        entityId: val,
      };
      onSubmitStatus(obj);
    });
  };

  const onStatusChange = async (data: IExtendedCus, status: string) => {
    const obj = {
      status: status as CheckerMakerStatusEnum,
      userId: userId || '',

      entityId: data.customerId,
    };
    onSubmitStatus(obj);
  };

  const getCustomerList = () => {
    fetchAllCustomers({
      clientId: clientId || '',
      page: currentPage,
      pageSize: 10,
      // status: ['UNDER_REVIEW', 'ACTIVE'],
    })
      .then((res) => {
        const sorted = sorting(res.data.records);
        setTotalPage(res.data.totalPages);
        setCustomerData(sorted);

        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getCustomerList();
  }, [currentPage]);

  return (
    <VegaPage>
      <MCCFilterSection
        isStatusFilter
        statusOption={STATUS}
        state={filterData}
        handleChange={handleFilter}
        // onSearch={onSearch}
      />
      <VegaCard fullHeight noPad>
        <FilterAction
          options={['PENDING', 'APPROVED', 'REJECTED']}
          selected={'PENDING'}
          selectedCount={isCheckArr.length}
          onApprove={onApprove}
          onReject={onReject}
        />
        <ListOfApplicationManagement
          // data={getFilterData(filteredCustomers)}
          data={filteredCustomers}
          onStatusChange={onStatusChange}
          setCustomerData={setCustomerData}
          loading={listLoading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
        <Grid container>
          <Box
            sx={{
              p: 2,
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              bgcolor: GREY.light,
              borderRadius: '0px 0px 20px 20px',
            }}
          >
            <Pagination
              color="primary"
              page={currentPage + 1}
              count={totalPages}
              onChange={onPageChange}
            />
          </Box>
        </Grid>
      </VegaCard>
    </VegaPage>
  );
};

export default CheckerApplicationManagement;
