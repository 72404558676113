/* eslint-disable react/jsx-pascal-case */
import { GridColumns } from '@mui/x-data-grid';
import Vega_Card from '../../../../components/common/v3/Vega_Card';
import Vega_Datagrid from '../../../../components/common/v3/Vega_Datagrid';
import Vega_Select, {
  ISelectOptionType,
} from '../../../../components/common/v3/Vega_Select';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import Vega_Text from '../../../../components/common/v3/Vega_Text';
import Vega_Button from '../../../../components/common/v3/Vega_Button';
import { BLUE, GREEN } from '../../../../constants/style';
import { fetchInvoice } from '../../../../api/setting';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

export interface InvoicePorps {
  clientId: string;
  year: number;
  month: number;
  invoiceS3Link: string;
}
const thirdPartyInvoice = [
  {
    invoiceDate: '22/05/23',
    partnerNmae: 'Signzy',
    invoiceNumber: '2234',
    amount: '33455',
    dueDate: '22/05/23',
    status: 'Paid',
  },
  {
    invoiceDate: '22/05/23',
    partnerNmae: 'Hyperverge',
    invoiceNumber: '2234',
    amount: '33455',
    dueDate: '22/05/23',
    status: 'Paid',
  },
  {
    invoiceDate: '22/05/23',
    partnerNmae: 'Gupshup',
    invoiceNumber: '2234',
    amount: '33455',
    dueDate: '22/05/23',
    status: 'Paid',
  },
  {
    invoiceDate: '22/05/23',
    partnerNmae: 'Quicksilver',
    invoiceNumber: '2234',
    amount: '33455',
    dueDate: '22/05/23',
    status: 'Paid',
  },
];

const ThirdPartyInvoice = () => {
  const [selectedYear, setSelectedYear] = useState<string>('2022');

  return (
    <>
      <Vega_Card sx={{ height: 'calc(100% - 160px)' }}>
        <>
          <Vega_Select
            sx={{ width: 100 }}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value as string)}
            options={yearsOptions()}
          />
          <Box mt={2}>
            <Vega_Datagrid
              data={thirdPartyInvoice}
              idColumn="partnerNmae"
              columns={COLUMN_DEF}
              loading={false}
            />
          </Box>
        </>
      </Vega_Card>
    </>
  );
};

export default ThirdPartyInvoice;

const COLUMN_DEF: GridColumns = [
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'partnerNmae',
    headerName: 'Partner Name',
    flex: 1,
    minWidth: 100,
  },

  {
    field: 'invoiceNumber',
    headerName: 'Invoice Number',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'invoice',
    headerName: 'Invoice',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      return (
        <Vega_Button
          variant="text"
          text={'Download'}
          bgColor=""
          textColor={BLUE.lighter}
          sx={{ px: 0 }}
        />
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    renderCell: (props) => {
      return <Vega_Text text={'Paid'} fontColor={GREEN.dark} />;
    },
  },
];
const yearsOptions = () =>
  ['2022', '2023'].map((item) => {
    const option: ISelectOptionType = {
      id: item,
      name: `FY ${item}`,
    };
    return option;
  });
