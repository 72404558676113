import { Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaLabel from '../../../../components/common/VegaLabel';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  CreateCardRefundOrderReq,
  Currency,
} from '../../../../types/cardRefund';
import { dateFormat } from '../../../variable';
import { ISectionProps } from '../../pages/MCCUserManagement';
import CardRefundDetails from './Components/CardRefundDetails';
import CardReloadDetails from './Components/CardReloadDetails';
import { IDetailsProps } from './ListOfCardTransaction';

import { CardRefundType, PaymentMode } from '../../../../enums/card';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { IConsolidatedAccountData } from '../../../types/Commons';
import { createCardRefundOrder } from '../../api/transaction';

interface IProps {
  data: IConsolidatedAccountData[];
  //   loading?: boolean;
  onReload: (data: any) => void;
  activeState: ISectionProps;
  getRefundList: () => void;
}

export const checkNullValue = (value: any) => {
  if (value !== null || value !== undefined) {
    return value;
  } else return false;
};

const DEFAUL_DATA = {
  sourceCurrency: 'INR',
  destinationCurrency: 'USD',
  markupValueType: 'PERCENTAGE',
  amount: 0,
  markupValue: 0,
  accountId: '',
  customerId: '',
  // cardNo: '',
  // name: '',
  // transactionCode: 'CASH_AT_POS',
  // transactionTime: '',
};

const ListOfCustomerAccount = ({
  data,
  onReload,
  activeState,
  getRefundList,
}: IProps) => {
  const { clientId, mccPlatformId: branchId } = useClientAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [checkValue, setCheckValue] = useState<string>('');
  const [openBalance, setOpenBalance] = useState<boolean>(false);
  const [showData, setShowData] = useState<IDetailsProps>({ ...DEFAUL_DATA });
  const [showCardData, setShowCardData] = useState<any>({});
  const [cardRefundReq, setCardRefundReq] = useState<
    Partial<CreateCardRefundOrderReq>
  >({
    clientId: clientId || '',
  });
  const { setSnackbar } = useSnackbar();

  const KIT_COLUMN = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Typography>
            {checkNullValue(props.row.customer?.firstName)}
          </Typography>
        );
      },
    },

    {
      field: 'cardNo',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const card = props.row?.card;
        if (!card || card === null) {
          return;
        } else {
          return <VegaLabel text={card?.cardNumber ?? '--'} color="primary" />;
        }
      },
    },
    {
      field: 'expiry',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Expiry Date
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const card = props.row?.card;
        if (!card || card === null) {
          return;
        } else {
          return <VegaLabel text={card?.expiryDate ?? '--'} color="primary" />;
        }
      },
    },
    {
      field: 'cardStatus',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card Status
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const card = props.row?.card;
        if (!card || card === null) {
          return;
        } else {
          return (
            <VegaLabel
              text={toLowerCase(card?.cardStatus ?? '--')}
              color="primary"
            />
          );
        }
      },
    },
    {
      field: 'accountId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Account Id
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const account = props.row?.account;
        if (!account || account === null) {
          return;
        } else {
          return <Typography>{account?.id ?? '--'}</Typography>;
        }
      },
    },
    {
      field: 'activation',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Activation Date
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const card = props.row?.card;

        return (
          <VegaLabel
            text={dateFormat(card?.createdAt ?? '--')}
            color="primary"
          />
        );
      },
    },
    {
      field: 'cardNetwork',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Card Network
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props: any) => {
        const networks = props.row.card.cardNetwork;
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      },
    },

    {
      field: 'Action',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Details
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        const accountData = props.row.account;
        return (
          checkNullValue(accountData) && (
            <VegaContainedButton
              text={activeState.id.toLocaleLowerCase()}
              onClick={() => showDetails(props.row)}
              isPrimary
            />
          )
        );
      },
    },
  ];

  const showDetails = (data: IConsolidatedAccountData) => {
    setOpen(!open);
    setShowData({ ...showData, accountId: data.account?.id || '' });
    setCardRefundReq((pre) => ({
      ...pre,
      accountId: data.account?.id || '',
      branchId,
      refundType: CardRefundType.PARTIAL,
      paymentMode: PaymentMode.CASH,
      sourceCurrency: Currency.INR,
      destinationCurrency: Currency.USD,
    }));
  };

  const onClose = () => {
    setOpen(!open);
  };

  // const onSubmit = async () => {

  // };

  const onChange = (name: string, value: any) => {
    setShowData({ ...showData, [name]: value });
  };

  const handleCardRefundReqChange = (
    key: keyof CreateCardRefundOrderReq,
    value: string | any
  ) => {
    setCardRefundReq((pre) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  };

  const getContent = () => {
    switch (activeState.id) {
      case 'RELOAD':
        return {
          drawerComp: (
            <CardReloadDetails state={showData} handleChange={onChange} />
          ),
          drawerTitle: 'Reload Card ',
          handleSubmit: async () => {
            const body = { ...showData, branchId };
            console.log(
              '🚀 ~ file: ListOfCustomerAccount.tsx:188 ~ onSubmit ~ body:',
              body
            );
            if (body.amount <= 0) {
              setSnackbar('Amount must be greater than zero', 'error');
              return;
            }
            await onReload(body);
            onClose();
          },
        };

      case 'REFUND':
        return {
          drawerComp: (
            <CardRefundDetails
              state={cardRefundReq}
              handleChange={handleCardRefundReqChange}
            />
          ),
          drawerTitle: 'Refund Card ',
          handleSubmit: async () => {
            if (cardRefundReq?.amount && cardRefundReq.amount > 0) {
              createCardRefundOrder(cardRefundReq as CreateCardRefundOrderReq)
                .then((res) => {
                  setSnackbar('Successfull created refund order');
                  getRefundList();
                })
                .catch((err) => {
                  setSnackbar('Error in creating refund order', 'error');
                });
              onClose();
            } else {
              setSnackbar('Amount must be greater than zero', 'error');
            }
          },
        };

      default:
        return {
          drawerComp: (
            <CardReloadDetails state={showData} handleChange={onChange} />
          ),
          drawerTitle: 'Manual Adjustment ',
          handleSubmit: async () => {
            onClose();
          },
        };
    }
  };

  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={KIT_COLUMN}
        idColumn={'id'}
        // loading={loading}
      />
      <VegaDrawer
        open={open}
        isClose
        isSubmit
        header={getContent()?.drawerTitle}
        closeText={'Cancel'}
        submitText={'Submit'}
        onClose={onClose}
        onSubmit={getContent().handleSubmit}
      >
        {getContent()?.drawerComp}
      </VegaDrawer>
    </VegaCard>
  );
};

export default ListOfCustomerAccount;
