import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { fetchListOfVendor } from '../api/vendorService';
import { VegaPage } from '../components/common';
import VegaSearchBy from '../components/common/VegaSearchBy';
import ListOfServiceSetup from '../components/Service Setup/ListOfServiceSetup';
import { useSnackbar } from '../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../utils/api';

export interface IVendorProps {
  serviceType: string;
  services: string[];
  vendorName: string;
}

enum FilterBy {
  PARTNER = 'vendorName',
  SERVICES = 'services',
}

const FilterByColumns = [
  {
    text: 'Partner',
    value: FilterBy.PARTNER,
  },
  {
    text: 'Services',
    value: FilterBy.SERVICES,
  },
];

const DEFAULT_FILTER = {
  search: '',
  searchBy: FilterByColumns[0].value,
};

const ServicesSetup = () => {
  const { setSnackbar } = useSnackbar();
  const [vendors, setVendors] = useState<IVendorProps[]>([]);
  const [filter, setFilter] = useState<any>({ ...DEFAULT_FILTER });

  const getListOfVendor = async () => {
    await fetchListOfVendor()
      .then((res) => {
        setVendors(res.data);
      })
      .catch((err) => setSnackbar(getErrorMessageFromErrorObj(err), 'error'));
  };

  const onChange = (name: string, value: string) => {
    setFilter((preVal: any) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const filterData = () => {
    const query = filter.search.toLowerCase();
    if (query === '') {
      return vendors;
    }

    const filterBy = filter.searchBy;
    if (filterBy == FilterBy.PARTNER) {
      return vendors.filter((item) =>
        item.vendorName.toLowerCase().includes(query)
      );
    } else if (filterBy == FilterBy.SERVICES) {
      return vendors.filter((item) => {
        const services = item.services.filter((subItem) =>
          subItem.toLowerCase().includes(query)
        );
        return services.length > 0;
      });
    }
    return vendors;
  };

  useEffect(() => {
    getListOfVendor();
  }, []);

  return (
    <Box>
      <VegaSearchBy
        placeholder="Serach"
        data={FilterByColumns}
        searchByname="searchBy"
        searchName="search"
        key="search"
        state={filter}
        handleChange={onChange}
      />
      <VegaPage>
        <ListOfServiceSetup data={filterData()} />
      </VegaPage>
    </Box>
  );
};

export default ServicesSetup;
