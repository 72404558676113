import { Edit } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaPrimaryButton from '../common/v2/VegaPrimaryButton';
import VegaSelectV2, { VegaSelectOptionV2 } from '../common/v2/VegaSelectV2';
import VegaTextV2 from '../common/v2/VegaTextV2';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';

type Props = {
  onGenerateCalendarClick: () => void;
  onDateSkipsSelected: (value: string) => void;
  selectedDateSkips: number;
  isCalendarGenerated: boolean;
  loading?: boolean;
};

function SettlementCalendarTopActions({
  onGenerateCalendarClick,
  onDateSkipsSelected,
  selectedDateSkips,
  isCalendarGenerated,
  loading,
}: Props) {
  const [disableDateSkipsSelector, setDisableDateSkipsSelector] =
    useState<boolean>(isCalendarGenerated ?? false);

  return (
    <Stack direction={'row'} spacing={2} alignItems="center">
      <VegaTextV2 text="Maximum consecutive date skips" />
      <VegaSelectV2
        fullWidth
        disabled={isCalendarGenerated}
        options={getDateSkipsOptions()}
        value={selectedDateSkips ?? ''}
        onChange={(e) => onDateSkipsSelected(e.target.value as string)}
      />
      <VegaConditionalWrapper
        condition={isCalendarGenerated == false}
        ifTrue={
          <VegaPrimaryButton
            variant="outlined"
            text="Generate Calender"
            loading={loading}
            onClick={() => {
              onGenerateCalendarClick();
            }}
          />
        }
      />
    </Stack>
  );
}

export default SettlementCalendarTopActions;

const getDateSkipsOptions = () =>
  [1, 2].map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item.toString(),
      value: item.toString(),
      label: `${item} ${item > 1 ? 'Days' : 'Day'}`,
    };
    return option;
  });
