import Box from '@mui/material/Box';
import { useMemo } from 'react';
import {
  TransactionCode,
  TransactionLimit,
  TransactionPolicy,
} from '../../../types/policy';
import type { GridColDef } from '@mui/x-data-grid';
import ProgramDataGrid from './ProgramDataGrid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../../store/hook';

const PrependCurrencyWithLimit = ({ amount }: { amount: number }) => {
  return (
    <Typography fontSize={12} color="#1B1D22">
      <Typography component="span" color="#676B76" mr={0.5} fontSize={12}>
        INR
      </Typography>
      {amount}
    </Typography>
  );
};
const getTransactionMode = (code: TransactionCode) => {
  switch (code) {
    case TransactionCode.E_COM:
      return 'Card E-com';
    case TransactionCode.POS:
      return 'Card Pos';
    case TransactionCode.CARD_CASH_WITHDRAW:
      return 'Card Cash Withdraw';
    case TransactionCode.CARD_NFC:
      return 'Card NFC';
    default:
      return '-';
  }
};

const getTTransactionLimitPolicyColumns =
  (): GridColDef<TransactionLimit>[] => [
    {
      field: 'transaction_code',
      headerName: 'Mode',
      flex: 1,
      sortable: false,
      minWidth: 180,
      renderCell: ({ value }) => (
        <Typography fontSize={12} color="#1B1D22" textTransform="capitalize">
          {getTransactionMode(value)}
        </Typography>
      ),
    },
    {
      field: 'per_transaction_limit',
      headerName: 'Transaction Limit',
      flex: 1,
      sortable: false,
      minWidth: 180,
      renderCell: ({ value }) => <PrependCurrencyWithLimit amount={value} />,
    },
    {
      field: 'daily_limit',
      headerName: 'Daily Limit',
      flex: 1,
      sortable: false,
      minWidth: 180,
      renderCell: ({ value }) => <PrependCurrencyWithLimit amount={value} />,
    },
    {
      field: 'monthly_limit',
      headerName: 'Monthly Limit',
      flex: 1,
      sortable: false,
      minWidth: 180,
      renderCell: ({ value }) => <PrependCurrencyWithLimit amount={value} />,
    },
    {
      field: 'yearly_limit',
      headerName: 'Annual limit',
      flex: 1,
      sortable: false,
      minWidth: 180,
      renderCell: ({ value }) => <PrependCurrencyWithLimit amount={value} />,
    },
  ];

function ViewTransactionLimitPolicy() {
  const columnsRef = useMemo(() => getTTransactionLimitPolicyColumns(), []);
  const activePolicy = useAppSelector(
    (state) => state.programManagement.activePolicy
  ) as TransactionPolicy;

  return (
    <Box
      sx={{
        p: {
          xs: 1,
          sm: 3,
        },
        flex: 1,
      }}
    >
      <ProgramDataGrid
        data={activePolicy?.transaction_limits ?? []}
        columns={columnsRef}
        idColumn="transaction_code"
      />
    </Box>
  );
}

export default ViewTransactionLimitPolicy;
