import React from 'react';
import { Typography } from '@mui/material';

interface IProps {
  text: string;
  color?: string;
}

const CIMS_Text = ({ text, color }: IProps) => {
  return (
    <Typography
      className="font-aspekta-500"
      fontSize={12}
      color={color ?? '#1B1D22'}
    >
      {text}
    </Typography>
  );
};

export default CIMS_Text;
