import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { createContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { isDev } from '../constants/url';
import { useClientAuth } from './ClientProvider';

interface InactivityTimeoutContextProps {}
const InactivityTimeoutContext =
  createContext<InactivityTimeoutContextProps | null>(null);

interface IProps {
  children: React.ReactNode;
}

const INACTIVE_TIME = isDev ? 60 * 60 * 1000 : 5 * 60 * 1000; // 60mins (dev) and 5 mins (in prod)
const PROMT_BEFORE_IDLE_TIME = 1 * 60 * 1000;
// const INACTIVE_TIME = 5000;
// const PROMT_BEFORE_IDLE_TIME = 1 * 1000;

const InactivityTimeoutProvider = ({ children }: IProps) => {
  const [remaining, setRemaining] = useState<number>(PROMT_BEFORE_IDLE_TIME);
  const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
  const { logout, isAuth } = useClientAuth();

  const onIdle = () => {
    // setIsSessionExpired(false);
    logout();
  };

  const onActive = () => {
    setIsSessionExpired(false);
  };

  const onPrompt = () => {
    setIsSessionExpired(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: INACTIVE_TIME,
    promptBeforeIdle: PROMT_BEFORE_IDLE_TIME,
    throttle: 500,
    disabled: !isAuth,
    events: ['keydown', 'mousedown', 'touchstart'],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleOnClick = () => {
    if (isAuth) {
      activate();
    } else {
      setIsSessionExpired(false);
    }
  };

  return (
    <InactivityTimeoutContext.Provider value={{}}>
      {children}
      {/* ----------------DIALOG FOR SESSION DISPLAY---------------- */}
      <Dialog open={isSessionExpired} disableEscapeKeyDown>
        <DialogTitle>Session Expired</DialogTitle>
        <DialogContent>
          <Typography>Your session has expired due to inactivity.</Typography>

          {isAuth ? (
            <Typography>Logging out in {remaining} seconds</Typography>
          ) : (
            <Typography>Please log in again.</Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              handleOnClick();
            }}
            variant="contained"
          >
            {isAuth ? 'Im still here' : 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    </InactivityTimeoutContext.Provider>
  );
};

export default InactivityTimeoutProvider;
