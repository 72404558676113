import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Pagination,
  Typography,
} from '@mui/material';
import React, { SetStateAction, useState } from 'react';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaLoader from '../../../../components/common/VegaLoader';
import { toLowerCase } from '../../../../constants/commonFunction';
import { BLUE, COMMON, GREEN, GREY, RED } from '../../../../constants/style';
import StatusModal from '../../../components/StatusModal';
import { ICardFundLoadProps } from '../../../types/Card';
import { STATUS_DATA } from '../../../variable';
import CorporateTxnDetails from './CorporateTxnDetails';

interface IProps {
  data: ICardFundLoadProps[];
  loading: boolean;
  onStatusChange: (data: any, status: string) => void;
  setSelectedRows: React.Dispatch<SetStateAction<any>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  selectedRows: any;
  currentPage: number;
}

interface ICorporateColumnProps {
  key: keyof ICardFundLoadProps | string | number;
  header: string;
  flex: number;
}

const COLUMN: ICorporateColumnProps[] = [
  {
    key: 'customerName',
    header: 'Customer Name',
    flex: 1,
  },
  {
    key: 'customerType',
    header: 'Customer Type',
    flex: 1,
  },
  {
    key: 'cardNetwork',
    header: 'Card Network',
    flex: 1,
  },
  {
    key: 'makerCheckerStatus',
    header: 'Status',
    flex: 1,
  },
  {
    key: 'action',
    header: 'Action',
    flex: 0.7,
  },
];

const ListOfCheckerCorporateTransaction = ({
  data,
  onStatusChange,
  setSelectedRows,
  setCurrentPage,
  selectedRows,
  currentPage,
  loading,
}: IProps) => {
  console.log("data!@: ", data);
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [fundLoadDetails, setFundLoadDetails] = useState<any>({});

  const [collapsedRows, setCollapsedRows] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const startIndex = (currentPage - 1) * 15;
  const endIndex = startIndex + 15;

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const filteredData = data.filter(
    (val: ICardFundLoadProps) => val.makerCheckerStatus === 'CHECKER_PENDING'
  );

  const checkStatus = (
    status: string,
    makerCheckerStatus: string,
    value: any
  ) => {
    if (
      status === 'DRAFT' ||
      (status === 'UNDER_REVIEW' && makerCheckerStatus === 'CHECKER_PENDING')
    ) {
      return (
        <VegaContainedButton
          text="Edit"
          onClick={() => showDetails(value)}
          isPrimary
        />
      );
    } else {
      return <VegaContainedButton text="Sent" isPrimary disabled />;
    }
  };

  const showDetails = (data: any) => {
    setFundLoadDetails(data);
    setOpen(!open);
  };

  const approve = async () => {
    await onStatusChange(fundLoadDetails, status);
    setOpen(!open);
    setStatus('');
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: any
  ) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id: string) => id !== rowId));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectAll(event.target.checked);

    if (event.target.checked) {
      const allRowIds = data
        .filter(
          (corporate: ICardFundLoadProps) =>
            corporate.makerCheckerStatus === 'CHECKER_PENDING'
        )
        .map((row: ICardFundLoadProps) => row.id);

      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
  };

  const toggleCollapse = (rowId: any) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((id) => id !== rowId));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const getStyle = (corporate: ICardFundLoadProps) => {
    if (corporate.id === undefined) {
      return;
    }
    if (selectedRows.includes(corporate.id)) {
      return {
        backgroundColor: '#F6F9FF',
      };
    }
    if (collapsedRows.includes(corporate.id)) {
      return {
        backgroundColor: '#F6F9FF',
        shadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      };
    }
  };

  const customizeRow = (
    column: ICorporateColumnProps,
    corporate: ICardFundLoadProps,
    index: number
  ) => {
    if (column.key === 'makerCheckerStatus') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          <Box
            className="font-aspekta-400"
            fontSize={14}
            py={0.5}
            sx={{
              ...(corporate.makerCheckerStatus === 'APPROVED'
                ? {
                    bgcolor: GREEN.light,
                    color: GREEN.dark,
                  }
                : corporate.makerCheckerStatus === 'REJECTED'
                ? { bgcolor: RED.light, color: RED.dark }
                : {
                    bgcolor: BLUE.light,
                    color: BLUE.lighter,
                  }),

              borderRadius: '5px',
            }}
          >
            {toLowerCase(corporate[column.key])}
          </Box>
        </td>
      );
    } else if (column.key === 'action') {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          {checkStatus(
            corporate['fundLoadOrderStatus'],
            corporate['makerCheckerStatus'],
            corporate
          )}
        </td>
      );
    } else {
      return (
        <td
          style={{
            minWidth: '150px',

            textAlign: 'center',
          }}
        >
          {corporate[column.key]}
        </td>
      );
    }
  };

  return (
    <>
      <Box component={'div'} sx={{ overflowX: 'auto' }}>
        <table
          style={{
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              background: GREY.light,
            }}
          >
            <tr>
              <th>
                <Checkbox
                  disabled={!filteredData.length}
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  sx={{
                    color: GREY.dark,
                    '&.Mui-checked': {
                      color: GREEN.dark,
                    },
                  }}
                />
              </th>
              {COLUMN.map((column: ICorporateColumnProps) => (
                <th style={{ minWidth: '150px' }}>
                  <Typography fontSize={14} className="font-aspekta-500">
                    {column.header}
                  </Typography>
                </th>
              ))}
              <th className="font-aspekta-400"></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={12}>
                  <VegaLoader />
                </td>
              </tr>
            ) : data.length ? (
              data
                .slice(startIndex, endIndex)
                .map((value: ICardFundLoadProps, index: number) => (
                  <React.Fragment key={index}>
                    <tr
                      style={{
                        backgroundColor: getStyle(value)?.backgroundColor,
                        borderBottom: collapsedRows.includes(value.id)
                          ? 'none'
                          : `2px solid ${GREY.light}`,
                      }}
                    >
                      <td
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          disabled={
                            value.makerCheckerStatus !== 'CHECKER_PENDING'
                          }
                          checked={selectedRows.includes(value?.id)}
                          onChange={(event) =>
                            handleRowCheckboxChange(event, value.id)
                          }
                          sx={{
                            color: GREY.dark,
                            '&.Mui-checked': {
                              color: GREEN.dark,
                            },
                          }}
                        />
                      </td>
                      {COLUMN.map((val: ICorporateColumnProps) =>
                        customizeRow(val, value, index)
                      )}
                      <td style={{}}>
                        <IconButton onClick={() => toggleCollapse(value.id)}>
                          <KeyboardArrowDown
                            sx={{
                              transition: 'all 500ms ease-in-out',
                              ...(collapsedRows.includes(value.id) && {
                                rotate: '180deg',
                              }),
                            }}
                          />
                        </IconButton>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderBottom: !collapsedRows.includes(value.id)
                          ? 'none'
                          : `2px solid ${BLUE.light}`,
                      }}
                    >
                      <td colSpan={12}>
                        {collapsedRows.includes(value.id) && (
                          <CorporateTxnDetails
                            value={value}
                            collapsedRows={collapsedRows}
                          />
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))
            ) : (
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      height: '500px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      className="font-aspekta-450"
                      color={COMMON.darkGray}
                    >
                      No Result
                    </Typography>
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Grid container>
        <Box
          sx={{ p: 2, width: '100%', display: 'flex', justifyContent: 'end' }}
        >
          <Pagination
            color="primary"
            page={currentPage}
            count={Math.ceil(data.length / 15)}
            onChange={onPageChange}
          />
        </Box>
      </Grid>

      <StatusModal
        header="Status "
        onSubmit={approve}
        open={open}
        radioData={STATUS_DATA}
        name="status"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        labelPlacement={'right'}
        handleClose={() => {
          setOpen(!open);
          setStatus('');
        }}
        color={GREEN.dark}
      />
    </>
  );
};

export default ListOfCheckerCorporateTransaction;
