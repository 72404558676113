export const CIMS_SUB_MODULES = {
  HOME: {
    QUICK_ACCESS: 'quick_access',
    SUMMARY_TABLE: 'summary_table',
    NOTIFICATIONS: 'notifications',
  },
  UPDATE_CARD_INVENTORY: {
    DOWNLOAD_TEMPLATE: 'download_template',
    UPLOAD_INVENTORY: 'upload_inventory',
  },
  BULK_REJECT_CARD: {
    DOWNLOAD_TEMPLATE: 'download_template',
    UPLOAD_FILE: 'upload_file',
  },
  ASSIGN_CARDS: {
    ASSIGN_CARDS: 'assign_cards',
  },
  ENTITY_MANAGEMENT: {
    CREATE_ENTITY: 'create_entity',
  },
  CARD: {
    CARD_TYPE: 'card_type',
    CARD_STATUS: 'card_status',
  },
  CARD_REJECTION_STATUS: {
    ADD_REJECTION_STATUS: 'add_rejection_status',
  },
  CARD_ORDER_REQUEST: {
    PENDING_ORDERS: 'pending_orders',
    REQUEST_CARDS: 'request_cards',
  },
  CARD_INDENTATION_ORDER: {
    REQUEST_CARDS: 'request_cards',
  },
  TEAM_MANAGEMENT: {
    ADD_NEW_PROFILE: 'add_new_profile',
  },
  POLICIES: {
    EDIT: 'edit',
  },
};

export const CIMS_RESOURCE_MODULES = {
  HOME: 'home',
  UPDATE_CARD_INVENTORY: 'update_card_inventory',
  BULK_REJECT_CARD: 'bulk_reject_card',
  CARD_INVENTORY: 'card_inventory',
  ASSIGN_CARDS: 'assign_cards',
  ENTITY_MANAGEMENT: 'entity_management',
  CARD: 'card',
  CARD_REJECTION_STATUS: 'card_rejection_status',
  CARD_ORDER_REQUEST: 'card_order_request',
  CARD_INDENTATION_ORDER: 'card_indentation_order',
  TEAM_MANAGEMENT: 'team_management',
  REPORTS: 'reports',
  POLICIES: 'policies',
  CARD_ALLOTMENT: 'card_allotment',
  DELIVERY_ORDER_REQUEST: 'delivery_order_request',
};
