import { Box, Typography } from '@mui/material';
import { GREEN, GREY } from '../../../constants/style';
import { AcceptFileType } from '../../../types/acceptFileType';
import { VegaDocument } from '../../../types/program';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../utils/FileValidator';
import { VegaCard } from '../../common';
import VegaDropZone from '../../common/VegaDropZone/VegaDropZone';
import { BulkUploadIcon } from '../../icon/Icons';
import Vega_HeaderWrapper from '../../common/v3/Vega_HeaderWrapper';

interface SanctionLetterProps {
  showLoaderForFileCard: boolean;
  showUploadButtonForFileCard: boolean;
  showDownloadFormatButton: boolean;
  sanctionLetterFiles: VegaDocument[] | null;
  onFilesSelected: (file: File[] | null) => void;
  onCancelFileButtonClick: () => void;
  onUploadFileButtonClick: () => void;
  onDownloadFormatButtonClick: () => void;
  onDownloadFileButtonClick: () => void;
  onFileSelectedError: (message: string | null) => void;
}

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.EXCEL])
  .withMultipleExtensionsCheck()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const SanctionLatterFormat = (props: SanctionLetterProps) => {
  return (
    <>
      <VegaCard>
        <Vega_HeaderWrapper text="Upload Word Document" />
        <VegaDropZone
          acceptFileType={AcceptFileType.Excel_xlsx}
          documents={props.sanctionLetterFiles}
          onFileSelected={props.onFilesSelected}
          showLoaderForFileCard={props.showLoaderForFileCard}
          showUploadButtonForFileCard={props.showUploadButtonForFileCard}
          onCancelFileButtonClick={props.onCancelFileButtonClick}
          onUploadFileButtonClick={props.onUploadFileButtonClick}
          onDownloadFileButtonClick={props.onDownloadFileButtonClick}
          fileValidator={fileValidatorConfig}
          onFileSelectedError={props.onFileSelectedError}
        />
        {props.showDownloadFormatButton && (
          <Box
            display={'flex'}
            sx={{
              cursor: 'pointer',
              marginTop: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={props.onDownloadFormatButtonClick}
          >
            <Typography sx={{ color: GREEN.dark, mr: 1, textAlign: 'center' }}>
              Download Sanction Letter Format
            </Typography>
            <BulkUploadIcon />
          </Box>
        )}
      </VegaCard>
    </>
  );
};

export default SanctionLatterFormat;
