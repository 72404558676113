import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaLabel from '../../../../components/common/VegaLabel';
import { toLowerCase } from '../../../../constants/commonFunction';
import { GREEN } from '../../../../constants/style';
import StatusModal from '../../../components/StatusModal';
import { IBinAllocationOrderListProps } from '../../../types/Bin';
import { STATUS_DATA } from '../../../variable';

interface IProps {
  data: IBinAllocationOrderListProps[];
  loading: boolean;
  onSendMaker: (data: IBinAllocationOrderListProps, status: string) => void;
  setRowMultiSelect: React.Dispatch<React.SetStateAction<string[]>>;
}

const ListOfCheckerInventoryManagement = ({
  data,
  loading,
  onSendMaker,
  setRowMultiSelect,
}: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [showData, setShowData] = useState<any>({});

  const getButtons = (data: IBinAllocationOrderListProps) => {
    if (
      data.binAllocationOrderStatus === 'UNDER_REVIEW' &&
      data.makerCheckerStatus === 'CHANGES_REQUESTED'
    ) {
      return (
        <VegaContainedButton
          text="Send to Maker"
          // onClick={() => showDetails(data, true)}
          isPrimary
          px={1}
          disabled
        />
      );
    } else if (data.makerCheckerStatus === 'APPROVED') {
      return (
        <VegaContainedButton
          text={'Sent'}
          // onClick={() => showDetails(data, true)}
          isPrimary
          disabled
        />
      );
    } else {
      return (
        <VegaContainedButton
          text="Edit"
          onClick={() => showDetails(data)}
          isPrimary
          px={1}
        />
      );
    }
  };

  const INVENTORY_COLUMN = [
    {
      field: 'binId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bin No
        </Typography>
      ),
      flex: 1,
    },
    // {
    //   field: 'programName',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Program Name</Typography>,
    //   flex: 1,
    // },
    {
      field: 'numberOfKits',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          No. of Kit
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const status = props.row.makerCheckerStatus;
        return (
          <VegaLabel
            text={toLowerCase(status)}
            color={
              status === 'APPROVED'
                ? 'success'
                : status === 'REJECTED'
                ? 'danger'
                : 'primary'
            }
          />
        );
      },
    },

    {
      field: 'action',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Action
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return getButtons(props.row);
      },
    },
  ];

  const showDetails = (data: any) => {
    setShowData(data);
    setOpen(!open);
  };

  const handleSubmit = async () => {
    await onSendMaker(showData, status);
    setOpen(!open);
    setStatus('');
  };
  const handleStatusChange = (e: any) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <VegaTable
        noTopRadius
        data={data}
        columnDef={INVENTORY_COLUMN}
        idColumn="id"
        loading={loading}
        disableSelectionOnClick={true}
        checkboxSelection={true}
        onRowSelect={(e) => setRowMultiSelect(e)}
        isSelected={(parm: any) => {
          if (parm.row.makerCheckerStatus === 'CHECKER_PENDING') {
            return true;
          } else {
            return false;
          }
        }}
      />
      <StatusModal
        header="Status "
        onSubmit={handleSubmit}
        open={open}
        radioData={STATUS_DATA}
        name="status"
        value={status}
        onChange={handleStatusChange}
        labelPlacement={'right'}
        handleClose={() => {
          setOpen(!open);
          setStatus('');
        }}
        color={GREEN.dark}
      />
    </>
  );
};

export default ListOfCheckerInventoryManagement;
