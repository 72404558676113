import { CardChipIcon } from '../../icon/Icons';
import {
  ProgramCardContainer,
  ProgramCardNumberContainer,
  ProgramCardNumberText,
  ProgramCardImage,
  ProgramCardRightPattern,
  CARD_HOLDER_FONT_SIZE,
  CARD_HOLDER_VALID_FONT_SIZE,
  CardSizeVairant,
  ProgramCardImagePlaceholder,
  ProgramCardText,
  TextContainer,
} from './CardComponents';
import { CardType, useCardConfig } from '../../../providers/CardConfigProvider';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { VegaProgramIssuanceType } from '../../../types/program';

type Props = { variant?: CardSizeVairant; cardType: CardType };
function ProgramCardFront({
  variant = CardSizeVairant.NORMAL,
  cardType,
}: Props) {
  const { physicalCardConfig, virtualCardConfig } = useCardConfig();
  const { selectedProgram } = useProgramData();

  const getCardConfig = () => {
    return cardType == CardType.PHYSICAL
      ? physicalCardConfig
      : virtualCardConfig;
  };

  const isProgramCobrand = () => {
    const isCobrand =
      selectedProgram?.issuanceType == VegaProgramIssuanceType.CO_BRAND;
    return isCobrand;
  };

  const getTopLeftImage = () => {
    let isCobrand = isProgramCobrand();
    return isCobrand ? getCardConfig().cobrandLogo : getCardConfig().bankLogo;
  };

  return (
    <ProgramCardContainer
      backgroundColor={getCardConfig().background}
      variant={variant}
    >
      <ProgramCardRightPattern left={'65%'} />
      <ProgramCardRightPattern left={'80%'} />
      <ProgramCardRightPattern left={'95%'} />
      <ProgramCardImagePlaceholder
        hide={Boolean(getTopLeftImage())}
        variant={variant}
        topAligned
        leftAligned
      >
        {getTopLeftImage() && (
          <ProgramCardImage
            src={getTopLeftImage()}
            leftAligned
            topAligned
            variant={variant}
          ></ProgramCardImage>
        )}
      </ProgramCardImagePlaceholder>
      {isProgramCobrand() && (
        <ProgramCardImagePlaceholder
          hide={Boolean(getCardConfig().bankLogo)}
          variant={variant}
          topAligned
          rightAligned
        >
          {getCardConfig().bankLogo && (
            <ProgramCardImage
              src={getCardConfig().bankLogo}
              rightAligned
              topAligned
              variant={variant}
            ></ProgramCardImage>
          )}
        </ProgramCardImagePlaceholder>
      )}
      <ProgramCardImagePlaceholder
        hide={Boolean(getCardConfig().nfcLogo)}
        variant={variant}
        topAligned
        top={cardType == CardType.PHYSICAL ? '25%' : '50%'}
        rightAligned
      >
        {getCardConfig().nfcLogo && (
          <ProgramCardImage
            src={getCardConfig().nfcLogo}
            variant={variant}
            height={variant == CardSizeVairant.NORMAL ? '50px' : '10px'}
            width={variant == CardSizeVairant.NORMAL ? '50px' : '10px'}
          ></ProgramCardImage>
        )}
      </ProgramCardImagePlaceholder>
      <ProgramCardImagePlaceholder
        hide={Boolean(getCardConfig().networkLogo)}
        variant={variant}
        rightAligned
        bottomAlligned
      >
        {getCardConfig().networkLogo && (
          <ProgramCardImage
            src={getCardConfig().networkLogo}
            rightAligned
            variant={variant}
          ></ProgramCardImage>
        )}
      </ProgramCardImagePlaceholder>
      {cardType == CardType.PHYSICAL && (
        <ProgramCardImagePlaceholder
          hide
          top="25%"
          left="5%"
          topAligned
          variant={variant}
        >
          <CardChipIcon
            height={variant == CardSizeVairant.SMALL ? '12' : '32'}
            width={variant == CardSizeVairant.SMALL ? '20' : '44'}
          />
        </ProgramCardImagePlaceholder>
      )}
      <ProgramCardNumberContainer
        top={cardType == CardType.PHYSICAL ? '48.5%' : '30%'}
      >
        <ProgramCardNumberText
          color={getCardConfig().textColor}
          variant={variant}
        >
          9999
        </ProgramCardNumberText>
        <ProgramCardNumberText
          color={getCardConfig().textColor}
          variant={variant}
        >
          9999
        </ProgramCardNumberText>
        <ProgramCardNumberText
          color={getCardConfig().textColor}
          variant={variant}
        >
          9999
        </ProgramCardNumberText>
        <ProgramCardNumberText
          color={getCardConfig().textColor}
          variant={variant}
        >
          9999
        </ProgramCardNumberText>
      </ProgramCardNumberContainer>
      <TextContainer>
        <ProgramCardText
          color={getCardConfig().textColor}
          fontSize={CARD_HOLDER_FONT_SIZE[variant].fontSize}
          variant={variant}
          left="5%"
          bottom={cardType == CardType.PHYSICAL ? '10%' : '40%'}
          lineHeight="140%"
          fontWeight={500}
        >
          Card holder name
        </ProgramCardText>
      </TextContainer>
      <TextContainer>
        <ProgramCardText
          color={getCardConfig().textColor}
          fontSize={CARD_HOLDER_VALID_FONT_SIZE[variant].fontSize}
          variant={variant}
          bottom={cardType == CardType.PHYSICAL ? '20%' : '5%'}
          right={cardType == CardType.PHYSICAL ? '48.5%' : '87%'}
          fontWeight={900}
        >
          04/23
        </ProgramCardText>
      </TextContainer>
      <TextContainer>
        <ProgramCardText
          color={getCardConfig().textColor}
          variant={variant}
          bottom={cardType == CardType.PHYSICAL ? '28%' : '13%'}
          right={cardType == CardType.PHYSICAL ? '40%' : '80%'}
          fontWeight={900}
        >
          VALID THRU
        </ProgramCardText>
      </TextContainer>
      {cardType == CardType.VIRTUAL && (
        <ProgramCardText
          color={getCardConfig().textColor}
          variant={variant}
          bottom={'13%'}
          left={'25%'}
        >
          CVV
        </ProgramCardText>
      )}
      {cardType == CardType.VIRTUAL && (
        <ProgramCardText
          color={getCardConfig().textColor}
          variant={variant}
          bottom={'5%'}
          left={'25%'}
        >
          123
        </ProgramCardText>
      )}
    </ProgramCardContainer>
  );
}

export default ProgramCardFront;
