import { Stack } from '@mui/material';
import _ from 'lodash';
import {
  GiftType,
  GiftVoucher,
  GiftVoucherType,
} from '../../../../../../../../../../types/RewardsLoyalty';
import VegaCardV2 from '../../../../../../../../../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../../../../../../../common/v2/VegaFormInputField';
import VegaTextV2 from '../../../../../../../../../common/v2/VegaTextV2';
import { useVoucherInventory } from '../../../../../../../../providers/VoucherInventoryProvider';

type Props = {
  voucher: Partial<GiftVoucher>;
};

function VoucherReadOnlyItem({ voucher }: Props) {
  const { giftVouchers, discountVouchers, subscriptionsVouchers } =
    useVoucherInventory();

  const getAmountHeading = () => {
    switch (voucher.giftType) {
      case GiftType.DISCOUNT_VOUCHER:
        return 'Discount';
      case GiftType.GIFT_VOUCHER:
        return 'Amount';
      case GiftType.SUBSCRIPTION:
        return 'Plan';
    }
  };

  const getValueTypeShortHand = (type?: string) => {
    switch (type) {
      case 'AMOUNT':
        return '';
      case 'PERCENTAGE':
        return '%';
      case 'TIRED':
        return '';
    }
    return '';
  };

  const getAmountSuffix = () => {
    const id = voucher.giftId;
    if (!id) return;
    const currentInventoryVoucher = getVoucherById(id);
    switch (voucher.giftType) {
      case GiftType.DISCOUNT_VOUCHER:
        return getValueTypeShortHand(
          currentInventoryVoucher?.discountVoucher.valueType
        );
      case GiftType.SUBSCRIPTION:
        const timeFrame = currentInventoryVoucher?.subscribtion.timeFrame;
        const displayText =
          timeFrame != null ? _.startCase(_.toLower(timeFrame)) : '-';

        return displayText;
    }
    return '';
  };

  const getAmount = () => {
    const id = voucher.giftId;
    const giftType = voucher.giftType;
    if (!id || !giftType) return;
    const currentInventoryVoucher = getVoucherById(id);
    switch (giftType) {
      case GiftVoucherType.GIFT_VOUCHER:
        return currentInventoryVoucher?.giftVoucherAmount.amount;
      case GiftVoucherType.DISCOUNT_VOUCHER:
        return currentInventoryVoucher?.discountVoucher.number;
      case GiftVoucherType.SUBSCRIPTION:
        return currentInventoryVoucher?.subscribtion.number;
    }
  };

  const getVoucherById = (id: string) => {
    const giftType = voucher.giftType;
    if (!giftType) return;
    const filteredVouchers = getAllVouchersOfType(giftType).filter(
      (item) => item.id == id
    );
    if (filteredVouchers.length <= 0) return;
    const selectedVoucher = filteredVouchers[0];
    return selectedVoucher;
  };

  const getAllVouchersOfType = (type: string) => {
    switch (type) {
      case GiftVoucherType.GIFT_VOUCHER:
        return giftVouchers;
      case GiftVoucherType.DISCOUNT_VOUCHER:
        return discountVouchers;
      case GiftVoucherType.SUBSCRIPTION:
        return subscriptionsVouchers;
    }
    return [];
  };

  return (
    <VegaCardV2>
      <div
        style={{
          maxHeight: '80px !important',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <VegaTextV2
          text={_.startCase(_.toLower(voucher.giftType))}
          fontWeight={500}
        />
        <Stack direction={'row'} spacing={1} justifyContent="space-between">
          <VegaFormInputFieldV2 label="Company" isReadOnly>
            <VegaTextV2
              text={voucher.vendorName}
              variant="caption"
              style={{ textOverflow: 'ellipsis' }}
            />
          </VegaFormInputFieldV2>
          <VegaFormInputFieldV2 label={getAmountHeading() ?? ''} isReadOnly>
            <VegaTextV2
              text={`${getAmount() ?? '-'} ${getAmountSuffix()}`}
              variant="caption"
              style={{ textOverflow: 'ellipsis' }}
            />
          </VegaFormInputFieldV2>
          <VegaFormInputFieldV2 label="Delay" isReadOnly>
            <VegaTextV2
              text={`${voucher.delayDays ?? '-'}`}
              variant="caption"
              style={{ textOverflow: 'ellipsis' }}
            />
          </VegaFormInputFieldV2>
        </Stack>
      </div>
    </VegaCardV2>
  );
}

export default VoucherReadOnlyItem;
