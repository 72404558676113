import { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import CreateBranch from '../components/MCCBranch/CreateBranch';
import {
  createBranch,
  fetchBranchList,
  updateBranch,
  updateBranchStatus,
} from '../api/Branch';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import ListOfMCCBranch from '../components/MCCBranch/ListOfMCCBranch';
import { useClientAuth } from '../../../providers/ClientProvider';
import { fetchStateOnPincode } from '../../../api/resources';

export interface IBranchProps {
  id?: string;
  branchName: string;
  branchCode: string;
  branchAddress: string;
  // totalUsers?: any;
  status: any;
  pincode: string;
  state?: string;
  city?: string;
  description?: string;
  isHq?: boolean;
}

const INITIAL_BRANCH = {
  branchName: '',
  branchCode: '',
  branchAddress: '',
  // totalUsers: '',
  status: '',
  pincode: '',
  state: '',
  city: '',
  description: '',
};

function MCCBranch() {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [branchList, setBranchList] = useState<IBranchProps[]>([]);
  const [branchDetails, setBranchDetails] = useState({ ...INITIAL_BRANCH });

  const [data, setData] = useState({
    loading: true,
    rows: [],
    totalRows: 0,
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 10,
    page: 0,
  });

  const updateData = (k: any, v: any) =>
    setData((prev) => ({ ...prev, [k]: v }));

  const onPageChange = (pageNumber: number) => {
    updateData('page', pageNumber);
  };
  const onPageSizeChange = (pageSizeNumber: number) => {
    updateData('pageSize', pageSizeNumber);
  };

  const handleChange = (name: string, value: any) => {
    console.log('name, value', name, value);

    if (name === 'pincode' && value.length > 6) {
      return;
    }
    setBranchDetails({ ...branchDetails, [name]: value });
  };

  const onClear = () => {
    setBranchDetails({ ...INITIAL_BRANCH });
  };

  const onCreateBranch = () => {
    setIsLoading(true);
    const body = { ...branchDetails, clientId, status: 'ACTIVE', isHq: false };
    createBranch(body)
      .then((res) => {
        setSnackbar('Branch added!');
        setIsLoading(false);
        getBranchList();
        onClear();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add branch'),
          'error'
        );
        setIsLoading(false);
        // onClear();
      });
  };

  const onUpdateBranch = (body: IBranchProps) => {
    updateBranch(body)
      .then((res) => {
        setSnackbar('Updated Branch!');
        setIsLoading(false);
        getBranchList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to update branch'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const getBranchList = async () => {
    updateData('loading', true);
    setTimeout(() => {
      fetchBranchList({ clientId, page: data.page, pageSize: data.pageSize })
        .then((res) => {
          updateData('loading', false);
          updateData('rows', res.data.records);
          updateData('rowCount', res.data.totalItems);
        })
        .catch((err) => {
          updateData('loading', false);
          setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        });
    }, 1000);
  };

  useEffect(() => {
    getBranchList();
  }, [data.page]);

  useEffect(() => {
    if (branchDetails.pincode) {
      const getListAll = setTimeout(() => {
        fetchStateOnPincode(branchDetails.pincode)
          .then((res) => {
            const stateList = res.data?.records[0]?.state;
            const cityList = res.data?.records[0]?.city;
            setBranchDetails({
              ...branchDetails,
              state: stateList,
              city: cityList,
            });
          })
          .catch((err) => {
            setSnackbar(getErrorMessageFromErrorObj(err), 'error');
          });
      }, 1000);
      return () => clearTimeout(getListAll);
    }
  }, [branchDetails.pincode]);

  return (
    <VegaPage>
      <CreateBranch
        state={branchDetails}
        handleChange={handleChange}
        onCreate={onCreateBranch}
        loading={isLoading}
      />
      <ListOfMCCBranch
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        page={data.page}
        pageSize={data.pageSize}
        data={data.rows}
        rowCount={data.rowCount}
        loading={data.loading}
        onUpdateBranch={onUpdateBranch}
        getBranchList={getBranchList}
      />
    </VegaPage>
  );
}

export default MCCBranch;
