/** @format */

import axios from 'axios';
import { BASE_URL, VENDOR_SERVICE } from './endpoints';

const VENDOR_BASE_URL = BASE_URL + VENDOR_SERVICE.BASE;

export const fetchListOfVendor = () => {
  return axios.get(`${VENDOR_BASE_URL}/listVendors`);
};
export const fetchListOfVendorAccountType = (vendorId: any) => {
  return axios.get(`${VENDOR_BASE_URL}/listVendorAccountTypes/${vendorId}`);
};
export const fetchListOfVendorAccountCredentialSpecs = (
  vendorAccountTypeId: any
) => {
  return axios.get(
    `${VENDOR_BASE_URL}/getVendorAccountCredentialSpecs/${vendorAccountTypeId}`
  );
};
export const fetchClientVendorAccountCredentials = (
  clientVendorAccountLinkId: any
) => {
  return axios.get(
    `${VENDOR_BASE_URL}/getClientVendorAccountCredentials/${clientVendorAccountLinkId}`
  );
};
export const fetchVendorAccountLink = (
  clientId: any,
  vendorAccountTypeId: any
) => {
  return axios.get(
    `${VENDOR_BASE_URL}/getClientVendorAccountLink?clientId=${clientId}&vendorAccountTypeId=${vendorAccountTypeId}`
  );
};
export const addClientVendorAccountLink = (
  clientId: any,
  vendorAccountTypeId: string,
  accountLinkStatus: string
) => {
  const data = {
    clientId,
    vendorAccountTypeId,
    accountLinkStatus,
  };
  return axios.post(`${VENDOR_BASE_URL}/addClientVendorAccountLink/`, data);
};
export const addClientVendorAccountCredentials = (value: any) => {
  const data = {
    clientVendorAccountCredentials: value,
  };
  return axios.post(
    `${VENDOR_BASE_URL}/addClientVendorAccountCredentials/`,
    data
  );
};
