import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import VegaCheckBoxV2 from '../../../../components/common/v2/VegaCheckBoxV2';
import { CardAllotmentOrderDto } from '../../../../types/card';
import CIMSModal, {
  IButtonGroupProps,
} from '../../../components/common/CIMS_Modal';
import CimsButton from '../../../components/common/CimsButton';
import LosText from '../../../../components/common/deprecated/LosText';

interface IProps {
  data: CardAllotmentOrderDto;
  onSubmit: (data: CardAllotmentOrderDto) => void;
}

const MarkAsLost = ({ data, onSubmit }: IProps) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const buttons: IButtonGroupProps[] = [
    {
      text: 'Confirm',
      disabled: !selected,
      onSubmit: () => {
        onSubmit(data);
        setOpen(false);
      },
      icon: '',
      isOutline: false,
    },
  ];
  return (
    <Box>
      <CimsButton variant="soft" onClick={handleClickOpen} color="error">
        Mark As Lost
      </CimsButton>

      <CIMSModal
        maxWidth="sm"
        open={open}
        handleClose={handleClose}
        header="Confirm"
        subTitleContainer={<></>}
        buttonGroup={buttons}
      >
        <Box sx={{ px: 3, pt: 2, pb: 6 }}>
          <LosText text="I confirm that:" fontWeight={500} />

          <Stack mt={2}>
            <Stack direction={'row'} spacing="1" alignItems={'start'}>
              <VegaCheckBoxV2
                sx={{
                  '&.MuiButtonBase-root': {
                    paddingLeft: '0px !important',
                    paddingTop: '0px !important',
                  },
                  color: 'blue',
                }}
                checked={selected}
                onChange={(e, checked) => {
                  setSelected(checked);
                }}
              />
              <LosText style={{ fontSize: '.9rem', lineHeight: '130%' }}>
                I didn't receive the cards . It seems to have lost .
              </LosText>
            </Stack>
          </Stack>
        </Box>
      </CIMSModal>
    </Box>
  );
};

export default MarkAsLost;
