import { CheckCircleOutlineRounded, EditOutlined } from '@mui/icons-material';
import { Stack, Typography, Button } from '@mui/material';
import dayjs from 'dayjs';
import VegaOutlineButton from '../../common/VegaOutlineButton';

export const CheckerCommentAddedView = (data: {
  comment: string;
  createdAt?: Date;
  didClickEdit: () => void;
  didClickApprove: () => void;
  disabled: boolean;
}) => {
  return (
    <Stack>
      <Typography>Comments</Typography>
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} spacing={2} alignItems="center">
          <Typography variant="caption">{data.comment}</Typography>
          <Button
            startIcon={<EditOutlined fontSize="small" />}
            color="info"
            onClick={data.didClickEdit}
            disabled={data.disabled}
          >
            Edit
          </Button>
        </Stack>
        <VegaOutlineButton
          text={'Change to Approve'}
          isSuccess
          startIcon={<CheckCircleOutlineRounded />}
          onClick={data.didClickApprove}
          disabled={data.disabled}
        />
      </Stack>
    </Stack>
  );
};
