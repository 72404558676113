/** @format */

import axios from 'axios';
import {
  AccountDelinquencySummary,
  DelinquencyReport,
  DelinquencySummary,
  VegaAgent,
} from '../types/collection';
import { BASE_URL, COLLECTION } from './endpoints';

const COLLECTION_BASE = BASE_URL + COLLECTION.BASE;

export const getCollection = (data: any) => {
  console.log('collection req', {
    ...data,
    isDownload: false,
    page: 0,
    pageSize: 10000,
  });
  return axios.post(COLLECTION_BASE + COLLECTION.QUERY_COLLECTIONS, {
    ...data,
    isDownload: false,
    page: 0,
    pageSize: 10000,
  });
  // const pageSize = 100;

  // const data = [
  //   {
  //     customerName: 'Alexandra Bauch',
  //     dpdDays: 14,
  //     dueAmount: 1104.0,
  //     agentName: 'Ashok',
  //     contactNumber: '823942311',
  //     pan: 'AAPPH4049L',
  //     customerId: 'c538115f-715f-4c9d-8092-1d8952c618dc',
  //     accountId: '0cade72b-ae1d-4158-805b-b380071622ba',
  //   },
  //   {
  //     customerName: 'Harshit Agrawal',
  //     dpdDays: 14,
  //     dueAmount: 507.0,
  //     agentName: 'Ashok',
  //     contactNumber: '823942312',
  //     pan: 'PYOPC0667G',
  //     customerId: 'f7fd871b-661e-4e52-864b-ab8ed038a726',
  //     accountId: '11167dd0-c1d6-4777-9fea-76bb2019961e',
  //   },
  //   {
  //     customerName: 'Abbie Bergstrom',
  //     dpdDays: 14,
  //     dueAmount: 610.0,
  //     agentName: 'Ashok',
  //     contactNumber: '823942313',
  //     pan: 'AAPPH7498L',
  //     customerId: 'feb0d0c3-f442-40ba-8062-8dc1f4a5f5f2',
  //     accountId: '812a9d57-c5cc-4b6d-a0d7-2bd45e6bc03d',
  //   },
  //   {
  //     customerName: 'Pattie Konopelski',
  //     dpdDays: 14,
  //     dueAmount: 6000.0,
  //     agentName: 'Ashok',
  //     contactNumber: '823942314',
  //     pan: 'AAPPH2993L',
  //     customerId: '40b8294c-f69a-483c-b4e1-c9b1884134a0',
  //     accountId: 'c64ad5c3-08d2-4673-ae35-66947398fcd8',
  //   },
  //   {
  //     customerName: 'himanshu agg',
  //     dpdDays: 20,
  //     dueAmount: 500.0,
  //     agentName: 'Ashok',
  //     contactNumber: '823942315',
  //     pan: 'ABCPD1234A',
  //     customerId: 'db81f702-7bed-4d0f-a962-1bcdd2d84877',
  //     accountId: 'cf65c84e-49b0-4efe-b1d1-59f507b41899',
  //   },
  //   {
  //     customerName: 'Destany Dibbert',
  //     dpdDays: 79,
  //     dueAmount: 108.0,
  //     agentName: 'Ashok',
  //     contactNumber: '823942316',
  //     pan: 'AAPPH8621L',
  //     customerId: '6c923cc1-a948-4602-b266-a4e4d3bf7288',
  //     accountId: 'dabd83b0-364d-4a3a-a126-623b0dcf6ced',
  //   },
  // ];

  // let filter = data.filter(
  //   (n) =>
  //     n.pan.includes(searchString) ||
  //     n.contactNumber.includes(searchString) ||
  //     n.dpdDays.toString().includes(searchString) ||
  //     n.customerName.includes(searchString)
  // );
  // const hasResult = !!(filter?.length > 0);
  // const response = {
  //   status: hasResult ? 200 : 404,
  //   data: filter,
  // };
  // // let response = await APIPost(BASE_URL.accountManagement + COLLECTION_QUERY, {
  // //   programId,
  // //   phone,
  // //   dpdDays,
  // //   panNumber,
  // //   isDownload,
  // //   page,
  // //   pageSize
  // // });
  // return modifyResponse(response);
};

export const modifyResponse = (response: any) => {
  if (response.status === 200) {
    return { success: true, result: response.data };
  } else {
    return { success: false, error: 'message', result: null };
  }
};

export async function getProgramDelinquency(data: {
  programId: string;
}): Promise<DelinquencyReport[]> {
  try {
    const endpoint =
      COLLECTION_BASE +
      COLLECTION.GET_PROGRAM_DELINQUENCY.replace('{programId}', data.programId);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getDelinquencySummaryByBucket(data: {
  dpd: string;
}): Promise<DelinquencySummary> {
  try {
    const endpoint =
      COLLECTION_BASE +
      COLLECTION.GET_SUMMARY_BY_DPD.replace('{dpd}', data.dpd);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAgents(): Promise<VegaAgent[]> {
  try {
    const endpoint = COLLECTION_BASE + COLLECTION.GET_AGENTS;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getDpdBuckets(): Promise<string[]> {
  try {
    const endpoint = COLLECTION_BASE + COLLECTION.GET_BUCKETS;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAccountDelinquencySummary(data: {
  accountId: string;
}): Promise<AccountDelinquencySummary> {
  try {
    const endpoint = COLLECTION.ACCOUNT_DELINQUENCY_SUMMARY.replace(
      '{accountId}',
      data.accountId
    );
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
