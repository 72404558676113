import React from 'react';
import { Box, Grid } from '@mui/material';
import { VegaTextField } from '../../../../../components/common';
import VegaDatePicker from '../../../../../components/common/VegaDatePicker';
import { ICommonProps } from '../../../../../types/commonType';
interface IProps extends ICommonProps {}

const IssueDetails = ({ handleChange, state }: IProps) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} mt={2}>
          <VegaTextField
            value={state?.customerId}
            onChange={(e) => handleChange('customerId', e.target.value)}
            key="customerId"
            label="Customer ID"
            type={'text'}
            placeholder="Customer ID"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} mt={2}>
          <VegaTextField
            value={state.name}
            onChange={(e) => handleChange('name', e.target.value)}
            key="name"
            label="Name on Card"
            type={'text'}
            placeholder="Name on Card"
          />
        </Grid>
        <Grid item xs={12} sm={12} mt={2}>
          <VegaTextField
            value={state.cardNo}
            onChange={(e) => handleChange('cardNo', e.target.value)}
            key="cardNo"
            label="Old Card Number"
            type={'text'}
            placeholder="Old Card Number"
          />
        </Grid>
        <Grid item xs={12} sm={12} mt={2}>
          <VegaTextField
            value={state.kitNo}
            onChange={(e) => handleChange('kitNo', e.target.value)}
            key="kitNo"
            label="Kit Number"
            type={'text'}
            placeholder="Kit Number"
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={state.cardNo}
            onChange={(e) => handleChange('cardNo', e.target.value)}
            key="cardNo"
            label="Card No"
            type={'text'}
            placeholder="Card No"
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaDatePicker
            value={state?.expiryDate ?? ''}
            onChange={(e) => {}}
            disabled
            key="expiryDate"
            label="Expiry Date"
            placeholder="Expiry Date"
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaDatePicker
            value={state?.activationDate ?? ''}
            onChange={(e) => {}}
            disabled
            key="activationDate"
            label="Activation Date"
            placeholder="Activation Date"
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={'lost'}
            onChange={(e) => handleChange('reIssue', e.target.value)}
            key="reIssue"
            label="Reason for Re-Issuance"
            type={'text'}
            placeholder="Reason for Re-Issuance"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default IssueDetails;
