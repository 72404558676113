import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { VegaCard, VegaTable } from '../../common';
import { Close, Info } from '@mui/icons-material';
import {
  BLUE,
  GREEN,
  GREY,
  NEW_PRIMARY,
  PRIMARY,
  RED,
  YELLOW,
} from '../../../constants/style';
import VegaTextButton from '../../common/VegaTextButton';
import VegaContainedButton from '../../common/VegaContainedButton';
import BackOfficeBulkUpload from './BackOfficeBulkUpload';
import {
  IBulkCardBlockProps,
  IBulkCardBodyProps,
  getBlockCardStatus,
  getBulkCardBlockList,
  getPresignedUrl,
  postBuklCardBlock,
} from '../../../api/card';
import { useClientAuth } from '../../../providers/ClientProvider';
import {
  DownloadReportIcon,
  FailureCardIcon,
  ListMenuIcon,
  SuccessCardIcon,
} from '../../icon/Icons';
import { downloadFileFromURI } from '../../../utils/file';
import { debounce } from 'lodash';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { toLowerCase } from '../../../constants/commonFunction';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import Vega_Text from '../../common/v3/Vega_Text';
import { dateFormat } from '../../../mcc/variable';
import { CardBlockInfo } from '../../../types/card';
import Vega_Button from '../../common/v3/Vega_Button';

const BulkCardBlock = () => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bulkCardClockSummary, setBulkCardClockSummary] =
    useState<IBulkCardBlockProps | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [bulkList, setBulkList] = useState<CardBlockInfo[]>([]);
  const isDisabled = loading || !selectedFile || uploadProgress === 0;

  const bulkUploadColumnRef: GridColDef[] = [
    {
      field: 'id',
      headerName: 'request Id',
      flex: 1.5,

      // renderCell: (props) => <VegaToolTipAndCopy value={props.row.id.toString()} />,
    },
    {
      field: 'updatedAt',
      headerName: 'Updoad Date',
      flex: 1.5,

      renderCell: (props) => {
        return <Vega_Text text={dateFormat(props.row.updatedAt)} />;
      },
    },
    {
      field: 'reportFileName',
      headerName: 'File Name',
      flex: 1,
    },
    {
      field: 'totalCardsCount',
      headerName: 'Total Entities',
      flex: 1,
    },
    {
      field: 'successBlockedCount',
      headerName: 'Successfully Blocked',
      flex: 1.2,
    },
    {
      field: 'customerEmail',
      headerName: 'Failure',
      flex: 1.2,
      renderCell: (props) => {
        const blockedCard = props.row.alreadyBlockedCount
          ? props.row.alreadyBlockedCount
          : 0;
        const doesNotExitsCard = props.row.doesNotExistCount
          ? props.row.doesNotExistCount
          : 0;
        const failure = blockedCard + doesNotExitsCard;
        return <Vega_Text text={failure} />;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.2,
      renderCell: (props) => {
        const status = props.row.status;
        const embossingFileUrl = props.row.embossingFileUrl;
        return status === 'SUCCESS' ? (
          <Vega_Button
            variant="outlined"
            textColor={NEW_PRIMARY.BLUE}
            bgColor="transparant"
            startIcon={<DownloadReportIcon strokeColor={NEW_PRIMARY.BLUE} />}
            text="Report"
            onClick={() => {
              if (!embossingFileUrl) return;
              onDownloadReport(embossingFileUrl);
            }}
          />
        ) : (
          <Vega_Text
            text={toLowerCase(status)}
            color={
              status === 'FAILED'
                ? RED.dark
                : status === 'SUCCESS'
                ? GREEN.dark
                : YELLOW.main
            }
          />
        );
      },
    },
  ];

  const toggleBulkUpload = () => {
    setIsBulkUpload(!isBulkUpload);
    setSelectedFile(null);
  };
  const toggleSummaryModal = () => {
    setIsSummaryOpen(!isSummaryOpen);
    setSelectedFile(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setUploadProgress(0);
      simulateFileUpload(file);
    }
  };

  const simulateFileUpload = (file: File) => {
    const totalSize = file.size;
    let uploadedSize = 0;
    setLoading(true);
    const interval = setInterval(() => {
      uploadedSize += 10000;
      const progress = (uploadedSize / totalSize) * 100;
      setUploadProgress(progress);

      if (uploadedSize >= totalSize) {
        clearInterval(interval);
        setLoading(false);
      }
    }, 200);
  };

  const onBulCardBlock = async () => {
    const formData = new FormData();
    if (!selectedFile) return;
    formData.append('bulkCardBlockFile', selectedFile);
    formData.append('clientId', clientId as string);
    try {
      const response = await postBuklCardBlock(formData);
      setBulkCardClockSummary(response);
      toggleBulkUpload();
      toggleSummaryModal();
    } catch (error) {
      toggleBulkUpload();
      throw error;
    }
  };

  const onDownloadReport = async (url: string) => {
    try {
      const response = await getPresignedUrl(url);
      await downloadFileFromURI(response.data, url);
    } catch (error) {}
  };

  const getCount = () => {
    if (!bulkCardClockSummary) return 0;
    if (
      bulkCardClockSummary.alreadyBlockedCount ||
      bulkCardClockSummary.doesNotExistCount
    ) {
      const alredyBlock = bulkCardClockSummary.alreadyBlockedCount
        ? bulkCardClockSummary.alreadyBlockedCount
        : 0;
      const doesNotExits = bulkCardClockSummary.doesNotExistCount
        ? bulkCardClockSummary.doesNotExistCount
        : 0;
      const totalFailure = alredyBlock + doesNotExits;
      return totalFailure;
    }
  };

  const delayedApiCall = debounce(async (requestId: string) => {
    try {
      const response = await getBlockCardStatus(requestId);
      if (bulkCardClockSummary?.status !== response.status) {
        setBulkCardClockSummary(response);
        if (response.status === 'FAILED') {
          setSnackbar('Card Print ' + toLowerCase(response.status), 'error');
        }
      }
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(
          error,
          'Error in fetching the print order for id- ' +
            bulkCardClockSummary?.id
        ),
        'error'
      );
      if (!bulkCardClockSummary) throw error;
      setBulkCardClockSummary({ ...bulkCardClockSummary, status: 'FAILED' });
    }
  }, 2000);

  useEffect(() => {
    if (bulkCardClockSummary?.id) {
      delayedApiCall(bulkCardClockSummary.id);
    }
    return delayedApiCall.cancel;
  }, [bulkCardClockSummary?.id]);

  const getBulkCardBlock = async () => {
    const body = {
      page,
      size: pageSize,
      clientId,
    } as IBulkCardBodyProps;
    try {
      const response = await getBulkCardBlockList(body);
      setBulkList(response.data.records);
      setRowCount(response.data.totalItems);
    } catch (error) {}
  };

  useEffect(() => {
    getBulkCardBlock();
  }, [page, pageSize]);

  return (
    <>
      <VegaCard fullHeight noPad sx={{ mt: 4 }}>
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Info sx={{ color: YELLOW.main }} />
                <Typography
                  fontSize={13}
                  //   className="font-aspekta-500"
                  color={GREY.dark}
                >
                  {' '}
                  Bulk Upload
                </Typography>
              </Stack>
              <Typography fontSize={10} fontWeight={'bold'}>
                Block multiple cards by uploading csv file
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={'row'}
                justifyContent={'end'}
                alignItems={'center'}
                gap={1}
              >
                {/* <VegaTextButton
                  text="Download sample report"
                  color={BLUE.lighter}

                /> */}
                <a
                  href={require('../../../assets/docs/bulkCardBlock.csv')}
                  download
                  style={{ color: BLUE.lighter }}
                >
                  Download Sample report
                </a>
                <VegaContainedButton
                  text="Upload Bulk file"
                  isPrimary
                  py={1}
                  onClick={toggleBulkUpload}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <VegaTable
          data={bulkList}
          noTopRadius
          columnDef={bulkUploadColumnRef}
          idColumn="id"
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setPageSize(size)}
          paginationMode={'server'}
        />
      </VegaCard>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isBulkUpload}
        onClose={toggleBulkUpload}
      >
        <DialogTitle sx={{ m: 0, px: 2, pt: 2 }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography>Upload Bulk file</Typography>

            <IconButton aria-label="close" onClick={toggleBulkUpload}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Box px={3}>
          <BackOfficeBulkUpload
            handleFileChange={handleFileChange}
            selectedFile={selectedFile}
            uploadProgress={uploadProgress}
            loading={loading}
          />
        </Box>
        <Stack direction={'row'} p={2} justifyContent={'end'}>
          <VegaContainedButton
            disabled={isDisabled}
            text={loading ? 'Run in background' : 'Submit'}
            isPrimary
            py={1}
            onClick={onBulCardBlock}
          />
        </Stack>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isSummaryOpen}
        onClose={toggleSummaryModal}
      >
        <DialogTitle sx={{ m: 0, px: 2, pt: 2 }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography>Response Summary</Typography>

            <IconButton aria-label="close" onClick={toggleSummaryModal}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Box px={3}>
          <Stack direction={'row'} mb={1} gap={2}>
            <ListMenuIcon />
            <Typography color={'#616E7F'} fontSize={12}>
              Total Entries
            </Typography>
            <Typography color={'#293543'} fontSize={12}>
              {bulkCardClockSummary?.totalCardsCount ?? 0}
            </Typography>
          </Stack>
          <Stack direction={'row'} mb={1} gap={2}>
            <SuccessCardIcon />
            <Typography color={'#26672D'} fontSize={12}>
              Successfully Blocked
            </Typography>
            <Typography color={'#293543'} fontSize={12}>
              {bulkCardClockSummary?.successBlockedCount ?? 0}
            </Typography>
          </Stack>
          <Stack direction={'row'} mb={1} gap={2}>
            <FailureCardIcon />
            <Typography color={'#841414'} fontSize={12}>
              Failure
            </Typography>
            <Typography color={'#293543'} fontSize={12}>
              {getCount()}
            </Typography>
          </Stack>
        </Box>
        <Stack direction={'row'} p={2} justifyContent={'end'}>
          <VegaContainedButton
            text={'Download Report'}
            startIcon={<DownloadReportIcon />}
            isPrimary
            py={1}
            onClick={() => {
              if (!bulkCardClockSummary) return;
              if (!bulkCardClockSummary.embossingFileUrl) return;
              onDownloadReport(bulkCardClockSummary.embossingFileUrl);
              toggleSummaryModal();
            }}
          />
        </Stack>
      </Dialog>
    </>
  );
};

export default BulkCardBlock;
