import { Button, CircularProgress, Grid } from '@mui/material';
import { VegaDocument } from '../../../../types/program';
import { FileCard } from './FileCard';

interface FilesSelectedViewProps {
  documents: VegaDocument[];
  showLoader?: boolean;
  showUploadButton?: boolean;
  showDownloadButton?: boolean;
  onUploadButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onDownloadButtonClick?: () => void;
  loading?: boolean;
}

export function FilesSelectedView({
  documents,
  showLoader,
  showDownloadButton,
  showUploadButton,
  onCancelButtonClick,
  onDownloadButtonClick,
  onUploadButtonClick,
  loading = false,
}: FilesSelectedViewProps) {
  return (
    <div>
      {documents.map((item) => {
        let fileSize = '';

        if (item.file) {
          fileSize = `${Math.round(item.file.size / 1024)}KB`;
        }

        return (
          <Grid
            container
            alignItems={'center'}
            justifyContent="center"
            spacing={'0.5rem'}
            columns={4}
          >
            <Grid item lg={3} md={3} sm={3}>
              <FileCard
                fileName={item.name}
                fileSize={fileSize}
                loading={showLoader ?? false}
                onCancelButtonClick={onCancelButtonClick}
              />
            </Grid>
            {showUploadButton && (
              <Grid item lg={1} md={1} sm={3}>
                {loading == true && <CircularProgress />}
                {loading == false && (
                  <Button variant="outlined" onClick={onUploadButtonClick}>
                    Upload
                  </Button>
                )}
              </Grid>
            )}
            {showDownloadButton && (
              <Grid item lg={1} md={1} sm={3}>
                <Button variant="outlined" onClick={onDownloadButtonClick}>
                  Download
                </Button>
              </Grid>
            )}
          </Grid>
        );
      })}
    </div>
  );
}
