import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useClientAuth } from '../../providers/ClientProvider';
import { ROUTES } from '../../routes';

export function PrivateRoute({ children }: PropsWithChildren) {
  const { isAuth } = useClientAuth();

  if (isAuth) return <>{children}</>;

  return <Navigate to={ROUTES.LOGIN} />;
}
