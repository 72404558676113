import React from 'react';
import ReactApexChart from 'react-apexcharts';

const categoriesData = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
];

type IProps = {
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  options?: ApexCharts.ApexOptions | undefined;
  height?: string | number;
  width?: string | number;
  categories?: string[];
};

function LineCharts({
  series,
  options,
  height = 350,
  width = 400,
  categories = categoriesData,
}: IProps) {
  return (
    <ReactApexChart
      options={{
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'straight',
        },
        title: {
          //   text: 'Product Trends by Month',
          //   align: 'left',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: categories,
        },
        ...options,
      }}
      series={series}
      type="line"
      height={height}
      width={width}
    />
  );
}

export default LineCharts;
