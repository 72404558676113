import { useState } from 'react';
import {
  CalendarType1Icon,
  CalendarType2Icon,
} from '../../../ui-components/icon/VPIcons';
import VPMenuDrivenContent from '../../../ui-components/common/VPMenuDrivenContent';
import { FinalSettlementCalendar, PaymentDueDate } from '../containers';

type ProgramDueDatesProps = {};
const listItems = [
  { id: 'paymentDueDate', title: 'Payment Due Date', Icon: CalendarType1Icon },
  {
    id: 'finalSettlement',
    title: 'Final Settlement Calendar',
    Icon: CalendarType2Icon,
  },
];
const ActiveTab = ({ id }: { id: string }) => {
  switch (id) {
    case 'paymentDueDate':
      return <PaymentDueDate />;

    case 'finalSettlement':
      return <FinalSettlementCalendar />;

    default:
      return null;
  }
};
function ProgramDueDates({}: ProgramDueDatesProps) {
  const [selectedListItem, setSelectedListItem] = useState(listItems[0].id);

  return (
    <VPMenuDrivenContent
      menuItems={listItems}
      selectedMenuItem={selectedListItem}
      onMenuItemChange={setSelectedListItem}
    >
      <ActiveTab id={selectedListItem} />
    </VPMenuDrivenContent>
  );
}

export default ProgramDueDates;
