import { ChargeCodeToRuleType } from '../types/policy';

export const CHARGE_CODES_TO_RULE = {
  ANNUAL_MEMBERSHIP_FEE: null,
  LATE_FEE: null,
  OVERLIMIT_FEE: null,
  CASH_ADVANCE_FEE: null,
  LOAN_PROCESSING_FEE: null,
  PHYSICAL_STATEMENT_FEE: null,
  REWARDS_REDEMPTION_FEE: null,
  DISPUTE_FEE: null,
  ADDITIONAL_CARD_FEE: null,
  REISSUE_CARD_FEE: null,
  LOAN_PRE_CLOSURE_FEE: null,
  FOREIGN_CURRENCY_FEE: null,
  BALANCE_TRANSFER_PROCESSING_FEE: null,
  CHEQUE_RETURN_FEE: null,
  CHARGE_SUMMARY_RETRIEVAL_FEE: null,
  CARD_VALIDATION_FEE: null,
  FOREIGN_CURRENCY_DCC: null,
  FOREIGN_CURRENCY_CCC: null,
} as ChargeCodeToRuleType;
