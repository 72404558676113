/** @format */

import { createContext, useContext, useState } from 'react';

export interface AppBarContextProps {
  appBarHeight: number;
  setAppBarHeight: (value: number) => void;
}

export const AppBarContext = createContext<AppBarContextProps>({
  appBarHeight: 0,
  setAppBarHeight: function (value: number): void {},
});

const OFFSET = 1.8;

const AppBarProvider = ({ children }: any) => {
  const [appBarHeight, setAppBarHeight] = useState<number>(0);

  function didUpdateAppBarHeight(value: number) {
    setAppBarHeight(value + OFFSET);
  }

  return (
    <AppBarContext.Provider
      value={{
        appBarHeight: appBarHeight,
        setAppBarHeight: didUpdateAppBarHeight,
      }}
    >
      {children}
    </AppBarContext.Provider>
  );
};

export const useAppBar = () => useContext(AppBarContext) as AppBarContextProps;

export default AppBarProvider;
