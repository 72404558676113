import { styled, Tab, Tabs, TabsProps } from '@mui/material';
import { ReactNode } from 'react';

interface VegaTabBarProps extends TabsProps {
  children: ReactNode;
}

function VegaTabBar({ children, ...rest }: VegaTabBarProps) {
  const { color = '#FF7B01' } = rest;
  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          backgroundColor: color,
        },
      }}
      {...rest}
    >
      {children}
    </Tabs>
  );
}

export default VegaTabBar;
