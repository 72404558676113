import { Download, Visibility } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { VegaAccordian, VegaTextField } from '../../../../components/common';
import VegaText from '../../../../components/common/VegaText';
import { FIleIcon } from '../../../../components/icon/Icons';
import { BLUE, BORDER_GRAY } from '../../../../constants/style';
import {
  FileValidator,
  FileValidatorResult,
} from '../../../../utils/FileValidator';

interface IProps {
  customer: any;
  handleChange: (name: string, value: any) => void;
  handleFileChange: (name: string, value: File | null) => void;
  fileValidator?: FileValidator;
  onError?: (type: string, message: string | null) => void;
}

const ViewCorporateCustomer = ({
  customer,
  handleChange,
  fileValidator,
  onError,
  handleFileChange,
}: IProps) => {
  function toDataURL(url: string) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
  const download = async (url: string) => {
    if (url) {
      const a = document.createElement('a');
      const urlBlob = await toDataURL(url);

      a.href = urlBlob;
      a.download = 'myImage.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleFileSelect = async (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (!fileValidator) {
      handleFileChange(type, selectedFile);
      return;
    }

    if (selectedFile) {
      const validationResult: FileValidatorResult =
        await fileValidator.validateFile(selectedFile);

      if (validationResult.isValid) {
        handleFileChange(type, selectedFile);
      } else {
        onError?.(type, validationResult.errorMessage);
      }
    }
  };

  return (
    <div>
      <Box my={2}>
        <VegaAccordian
          header={'Basic Details'}
          content={
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <VegaTextField
                    label="Corporate Name"
                    key="corporateName"
                    value={customer.corporateName}
                    onChange={(e) =>
                      handleChange('corporateName', e.target.value)
                    }
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <VegaSelect
                    label="Select Program"
                    key="mobileNumber"
                    options={['Ebix cash 1', 'Ebix cash 1']}
                    selected={customer?.mobileNumber}
                    onChange={() => console.log('first')}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <VegaTextField
                    label="Mobile Number"
                    key="mobileNumber"
                    value={customer.mobileNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <VegaTextField
                    label="Customer Email"
                    key="emailId"
                    value={customer.emailId}
                  />
                </Grid>
              </Grid>
            </Box>
          }
        />
      </Box>
      <Box my={2}>
        <VegaAccordian
          header={'Documents'}
          content={
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Box
                      sx={{ border: BORDER_GRAY, borderRadius: '5px', p: 2 }}
                    >
                      <Box sx={{ mb: 0.5 }}>
                        <VegaText text="GST File" />
                      </Box>
                      <Box className="snip1205">
                        <img
                          src={customer.gstFileUrl ?? ''}
                          alt=""
                          width={200}
                          height={100}
                        />
                        <i
                          style={{ cursor: 'pointer' }}
                          onClick={() => download(customer.gstFileUrl)}
                        >
                          <Download />
                        </i>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          my: 0.5,
                        }}
                      ></Box>
                    </Box>

                    <Box>
                      <Box
                        sx={{
                          p: 1,
                          my: 1,
                          border: BORDER_GRAY,
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FIleIcon />
                            <Box mx={1}>
                              <VegaText text="Visa.pdf" />
                            </Box>
                          </Box>
                          <Box>
                            <input
                              type="file"
                              id="pan"
                              hidden
                              onChange={(file: any) =>
                                handleFileSelect('gstFileUrl', file)
                              }
                            />
                            <label
                              style={{
                                color: 'white',
                                backgroundColor: BLUE.lighter,
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                paddingRight: '8px',
                                paddingLeft: '8px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: 16,
                              }}
                              htmlFor="pan"
                            >
                              Upload
                            </label>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Box
                      sx={{ border: BORDER_GRAY, borderRadius: '5px', p: 2 }}
                    >
                      <Box sx={{ mb: 0.5 }}>
                        <VegaText text="Balance Sheet File" />
                      </Box>
                      <Box className="snip1205">
                        <img
                          src={customer.balanceSheetFileUrl ?? ''}
                          alt=""
                          width={200}
                          height={100}
                        />
                        <i
                          style={{ cursor: 'pointer' }}
                          onClick={() => download(customer.balanceSheetFileUrl)}
                        >
                          <Download />
                        </i>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          my: 0.5,
                        }}
                      ></Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          p: 1,
                          my: 1,
                          border: BORDER_GRAY,
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FIleIcon />
                            <Box mx={1}>
                              <VegaText text="Visa.pdf" />
                            </Box>
                          </Box>
                          <Box>
                            <input
                              type="file"
                              id="pan"
                              hidden
                              onChange={(file: any) =>
                                handleFileSelect('balanceSheetFileUrl', file)
                              }
                            />
                            <label
                              style={{
                                color: 'white',
                                backgroundColor: BLUE.lighter,
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                paddingRight: '8px',
                                paddingLeft: '8px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: 16,
                              }}
                              htmlFor="pan"
                            >
                              Upload
                            </label>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <Box>
                      <Box
                        sx={{ border: BORDER_GRAY, borderRadius: '5px', p: 2 }}
                      >
                        <Box sx={{ mb: 0.5 }}>
                          <VegaText text="Declaration Form" />
                        </Box>
                        <Box>
                          <img
                            src={customer.declaration}
                            alt=""
                            width={200}
                            height={100}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            my: 0.5,
                          }}
                        >
                          <VegaOutlineButton
                            py={0.5}
                            text={'View'}
                            startIcon={<Visibility />}
                          />
                          <VegaOutlineButton
                            py={0.5}
                            text={'Download'}
                            startIcon={<Download />}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            p: 1,
                            my: 1,
                            border: BORDER_GRAY,
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FIleIcon />
                              <Box mx={1}>
                                <VegaText text="Visa.pdf" />
                              </Box>
                            </Box>
                            <Box>
                              <input type="file" id="pan" hidden />
                              <label
                                style={{
                                  color: 'white',
                                  backgroundColor: BLUE.lighter,
                                  paddingTop: '2px',
                                  paddingBottom: '2px',
                                  paddingRight: '8px',
                                  paddingLeft: '8px',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  fontSize: 16,
                                }}
                                htmlFor="pan"
                              >
                                Upload
                              </label>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid> */}
              </Grid>
            </Box>
          }
        />
      </Box>
    </div>
  );
};

export default ViewCorporateCustomer;
