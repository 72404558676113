import LaunchIcon from '@mui/icons-material/Launch';
import { Box, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getReportsConfig } from '../../../api/reports';
import {
  IReport,
  IReportConfig,
  ReportConfigDto,
} from '../../../types/reports';
import { downloadURI } from '../../../utils/download';
import { convertCamelCase } from '../../../utils/stringUtils';
import { Loading, VegaCard, VegaTable } from '../../common';
import VegaContainedButton from '../../common/VegaContainedButton';
import ReportsConfigModal from './ReportsConfigModal';
import { REPORT_SAMPLE_URL } from '../../../mcc/utils';

type IProps = {
  selectedReport: IReport;
};

const convertToDoubleDigitStr = (num: number) => {
  if (num < 10) {
    return '0' + num;
  }
  return num;
};

const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
export const ordinals = (number: number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
const getScheduledTime = (data: IReportConfig) => {
  if (data.frequency === 'DAILY') {
    return `${convertCamelCase(data.frequency)} - ${convertToDoubleDigitStr(
      data.hours
    )}:${convertToDoubleDigitStr(data.minutes)}`;
  } else if (data.frequency === 'WEEKLY') {
    // return `${convertCamelCase(data.frequency)} - ${DAYS[data.dayOfWeek]} ${
    //   data.hours
    // }:${data.minutes}`;
    return `${convertCamelCase(data.frequency)} - ${DAYS[data.dayOfWeek]}`;
  } else {
    return `${convertCamelCase(data.frequency)} - ${
      data.dayOfMonth + 1
    }${ordinals(data.dayOfMonth + 1)}`;
  }
};

function ReporConfig({ selectedReport }: IProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [reportsConfig, setReportsConfig] = useState<IReportConfig[] | null>(
    null
  );
  const [readReportsConfig, setReadReportsConfig] =
    useState<ReportConfigDto | null>(null);

  const [openScheduleModal, setOpenScheduleModal] = useState(false);

  const fetchReportsConfig = () => {
    setLoading(true);
    setError(false);
    if (!selectedReport.reportId) return;
    getReportsConfig(selectedReport.reportId)
      .then((res) => {
        setReportsConfig(res.data);
      })
      .catch((err) => {
        console.log(
          'getReportsConfig Error occured in fetching the reports config',
          err
        );
        setError(true);
        setReportsConfig(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columnDef = [
    {
      field: 'createdAt',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Created At
        </Typography>
      ),
      flex: 1.5,
      renderCell: (props: any) => {
        return (
          <Typography variant="body1">
            {/* {convertToDateTime(props.row.createdAt)} */}
            {dayjs(props.row.createdAt).format('YYYY-MM-DD HH:MM:ss')}
          </Typography>
        );
      },
    },
    {
      field: 'reportId',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Report ID
        </Typography>
      ),
      flex: 2,
    },
    {
      field: 'createdBy',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Created By
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'sampleReportLink',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Sample Report
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Box
          // display={'flex'}
          // alignItems="center"
          // width={'100%'}
          // height="100%"
          >
            <VegaContainedButton
              text="Download"
              isPrimary
              onClick={() => {
                downloadURI(
                  REPORT_SAMPLE_URL(selectedReport?.reportName),
                  'Report' + new Date().toISOString()
                );
              }}
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: 'schedule',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Schedule
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Box display={'flex'} alignItems="center">
            <Typography variant="body1">
              {getScheduledTime(props.row)}
            </Typography>
          </Box>
        );
      },
      flex: 1.5,
    },
  ];

  const createScheduleDef = [
    {
      field: 'createdAt',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Created At
        </Typography>
      ),
      flex: 1.5,
      renderCell: (props: any) => {
        return (
          <Typography variant="body1">
            {dayjs(props.row.createdAt).format('YYYY-MM-DD HH:MM:ss')}
          </Typography>
        );
      },
    },
    {
      field: 'reportId',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Report ID
        </Typography>
      ),
      flex: 2,
    },
    {
      field: 'createdBy',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Created By
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'sampleReportLink',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Sample Report
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Box
            display={'flex'}
            alignItems="center"
            width={'100%'}
            height="100%"
          >
            <VegaContainedButton
              text="Download"
              isPrimary
              onClick={() => {
                downloadURI(
                  REPORT_SAMPLE_URL(selectedReport?.reportName),
                  'Report_' + new Date().toISOString()
                );
              }}
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: 'schedule',
      headerName: (
        <Typography variant="body2" fontWeight={'bold'}>
          Schedule
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Box display={'flex'} alignItems="center">
            <Typography variant="body1">Schedule Report</Typography>
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => {
                setReadReportsConfig(null);
                setTimeout(() => {
                  setOpenScheduleModal(true);
                }, 10);
              }}
            >
              <LaunchIcon />
            </IconButton>
          </Box>
        );
      },
      flex: 1.5,
    },
  ];

  useEffect(() => {
    fetchReportsConfig();
  }, [selectedReport.reportId]);

  useEffect(() => {
    if (!openScheduleModal) {
      fetchReportsConfig();
      setReadReportsConfig(null);
    }
  }, [openScheduleModal]);

  console.log('reportsConfig', reportsConfig);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {reportsConfig && reportsConfig.length ? (
            <VegaCard fullHeight noPad>
              <VegaTable
                data={reportsConfig.slice(-1)}
                columnDef={columnDef}
                idColumn={'reportConfigId'}
              />
            </VegaCard>
          ) : (
            <VegaCard fullHeight noPad>
              <VegaTable
                data={[selectedReport]}
                columnDef={createScheduleDef}
                idColumn={'reportId'}
              />
            </VegaCard>
          )}
        </>
      )}

      <ReportsConfigModal
        openScheduleModal={openScheduleModal}
        setOpenScheduleModal={setOpenScheduleModal}
        selectedReportId={selectedReport.reportId}
        fetchReportsConfig={fetchReportsConfig}
        initialReportConfig={readReportsConfig}
        selectedReportName={selectedReport.reportName}
      />
    </>
  );
}

export default ReporConfig;
