/* eslint-disable react/jsx-pascal-case */
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { useClientAuth } from '../../../providers/ClientProvider';
import { EntityType } from '../../../types/client';
import CIMS_Button from '../../components/common/CIMS_Button';
import CIMS_Header from '../../components/common/CIMS_Header';
import {
  CardIndentationOrderProvider,
  RequestCardModal,
} from '../../modules/CardIndentationOrder';
import CardIndentationOrderList from '../../modules/CardIndentationOrder/List/CardIndentationOrderList';

function CardIndentationOrder() {
  const { userDivisionType } = useClientAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const canRequestCards =
    userDivisionType === 'BUSINESS_CORRESPONDENT' ||
    userDivisionType === 'BC_AGENT';

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div>
      <CIMS_Header
        text="Card Indentation Request"
        component={
          canRequestCards ? (
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <CIMS_Button text="Request cards" onClick={handleOpenDrawer} />
            </Stack>
          ) : undefined
        }
      />
      <CardIndentationOrderProvider>
        <RequestCardModal isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
        <CardIndentationOrderList />
      </CardIndentationOrderProvider>
    </div>
  );
}

export default CardIndentationOrder;
