import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { VegaCard } from '../../../components/common';
import VegaDatePicker from '../../../components/common/VegaDatePicker';
import VegaOutLineTextField from '../../../components/common/VegaOutLineTextField';
import VegaOutlineButton from '../../../components/common/VegaOutlineButton';
import VegaSelect from '../../../components/common/VegaSelect';

interface IFilterProps {
  handleChange: (name: string, value: any) => void;
  state: any;
  programOption?: any;
  isSearch?: boolean;
  label?: string;
}

const CorporateFilterSection = ({
  handleChange,
  state,
  isSearch,
  programOption,
  label = 'Search by Mobile or Customer ID or Emaill',
}: IFilterProps) => {
  return (
    <VegaCard>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {isSearch ? (
            <VegaOutLineTextField
              value={state.mobile}
              onChange={(e) => handleChange('mobile', e.target.value)}
              key="mobile"
              label={label}
              type={'text'}
              placeholder={label}
              Icon={<Search />}
            />
          ) : (
            <VegaSelect
              options={programOption}
              key="programId"
              selected={state.programId}
              placeholder="Select Program"
              onChange={(value) => handleChange('programId', value)}
            />
          )}
        </Grid>
        <Grid item container spacing={2} xs={12} sm={4}>
          {isSearch ? (
            <Grid item xs={12} sm={6}>
              <VegaSelect
                options={programOption}
                key="programId"
                selected={state.programId}
                label="Select Program"
                onChange={(value) => handleChange('programId', value)}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              {' '}
            </Grid>
          )}
          {isSearch ? (
            <Grid item xs={12} sm={6}>
              <VegaDatePicker
                value={state.fromDate}
                onChange={(e) => handleChange('fromDate', e)}
                key="fromDate"
                label="Select Month or Year: Card Expiry"
                placeholder="Select Month or Year: Card Expiry"
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              {' '}
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={2} xs={12} sm={4} alignItems={'end'}>
          {isSearch ? (
            <Grid item xs={12} sm={8}>
              <VegaDatePicker
                value={state.toDate}
                onChange={(e) => handleChange('toDate', e)}
                key="toDate"
                label="Select Month or Year: Card Expiry"
                placeholder="Select Month or Year: Card Expiry"
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={8}>
              {' '}
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <VegaOutlineButton text={'Search'} isPrimary />
          </Grid>
        </Grid>
      </Grid>
    </VegaCard>
  );
};

export default CorporateFilterSection;
