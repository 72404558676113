import { useQuery } from 'react-query';
import { getAllReportRunsForReport } from '../../../api/reports';
import { IReportRun } from '../../../types/reports';

const getReportRunsForReport = async (reportId: string | undefined) => {
  if (!reportId) return null;
  const { data } = await getAllReportRunsForReport(reportId);

  // const hash = await (data as IReportRun[]).reduce((pre, curr) => {
  //   return {
  //     ...pre,
  //     [curr.reportConfigId]: curr,
  //   };
  // }, {} as Record<string, IReportRun>);
  // return hash;

  return data as IReportRun[];
};

export default function useReportRunsForReport(reportId: string | undefined) {
  return useQuery({
    queryKey: ['report_runs_report', reportId],
    queryFn: () => getReportRunsForReport(reportId),
    enabled: !!reportId,
  });
}
