import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useQuery } from 'react-query';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  CardAllotmentCategory,
  CardAllotmentOrderDto,
  CardAllotmentStatusEnum,
  CardAllotmentStatusMappingReverse,
} from '../../../../types/card';
import { CardAllocationService } from '../../../apis';
import CIMSDataGrid from '../../../components/common/CIMS_DataGrid';
import CIMSHeader from '../../../components/common/CIMS_Header';
import { CARD_ALLOTMENT_LIST_COLUMNS } from '../../../modules/InventoryManagement/CardAllotment/List/columns';
import FilterCardAllotment, {
  CardAllotmentFilter,
} from '../../../modules/InventoryManagement/FilterCardAllotment';

const getFilterStatusList = (filterData: CardAllotmentFilter) => {
  return Object.keys(filterData.status).reduce((pre, curr) => {
    if (filterData.status[curr]) {
      return [...pre, CardAllotmentStatusMappingReverse[curr]];
    }
    return pre;
  }, [] as CardAllotmentStatusEnum[]);
};

export function CardAlloment() {
  const { userDivisionId } = useClientAuth();

  const { setSnackbar } = useSnackbar();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(0);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const hookForm = useForm<CardAllotmentFilter>({
    defaultValues: {
      cardType: {
        PREPAID: false,
        CREDIT: false,
        DEBIT: false,
      },
      status: {
        ASSIGNED: false,
        SHIPPED: false,
        DELIVERED: false,
      },
    },
  });

  const [filterData, setFilterData] = useState<CardAllotmentStatusEnum[]>([]);

  const listAllotmentQuery = useQuery({
    queryKey: [
      'allotment',
      ...filterData,
      {
        page,
        pageSize,
        userDivisionId,
      },
    ],
    queryFn: () => {
      return CardAllocationService.list({
        destinationDivisionId: userDivisionId,
        allotmentStatusList: filterData.length ? filterData : [],
        allotmentCategoryList: [CardAllotmentCategory.ASSIGN],
        page: page,
        size: pageSize,
        populateDivision: true,
      });
    },
    keepPreviousData: true,
  });
  const handleMarkAsAcknowledged = (data: CardAllotmentOrderDto) => {
    CardAllocationService.updateAllocationStatus({
      cardAllotmentOrderId: data.id,
      status: CardAllotmentStatusEnum.ACCEPTED,
    })
      .then(() => {
        setSnackbar('The card has been accepted ', 'success');
        listAllotmentQuery.refetch();
      })
      .catch(() => {
        setSnackbar('Failed to accept the card', 'error');
      });
  };
  const handleMarkAsLost = (data: CardAllotmentOrderDto) => {
    CardAllocationService.updateAllocationStatus({
      cardAllotmentOrderId: data.id,
      status: CardAllotmentStatusEnum.NOT_DELIVERED,
    })
      .then(() => {
        listAllotmentQuery.refetch();
        setSnackbar('The card has been mark as not delivered ', 'success');
      })
      .catch(() => {
        setSnackbar('Failed to update status as not delivered', 'error');
      });
  };

  const handFilter = () => {
    const statusList = getFilterStatusList(hookForm.getValues());
    setFilterData(statusList);
  };
  return (
    <Box>
      <CIMSHeader text="Card Allotment" />
      <Box
        sx={{
          p: 2,
        }}
      >
        <Box mt={2}>
          <CIMSDataGrid
            data={listAllotmentQuery.data?.records || []}
            columns={CARD_ALLOTMENT_LIST_COLUMNS({
              handleMarkAsAcknowledged,
              handleMarkAsLost,
            })}
            idColumn={'id'}
            loading={
              listAllotmentQuery.isLoading || listAllotmentQuery.isPreviousData
            }
            pageSize={pageSize}
            onPageSizeChange={(size) => {
              setPageSize(size);
            }}
            page={page}
            onPageChange={(page) => {
              setPage(page);
            }}
            rowCount={listAllotmentQuery.data?.totalItems}
            paginationMode="server"
            components={{
              Toolbar: (props) => {
                return (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    my={2}
                  >
                    <Stack direction={'row'} alignItems={'center'}>
                      <FormProvider {...hookForm}>
                        <FilterCardAllotment
                          isOpen={isDrawerOpen}
                          onOpen={handleOpenDrawer}
                          onClose={handleCloseDrawer}
                          handFilter={handFilter}
                        />
                      </FormProvider>
                    </Stack>
                  </Stack>
                );
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
