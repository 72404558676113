/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import CIMS_Button from '../../components/common/CIMS_Button';
import CIMS_Header from '../../components/common/CIMS_Header';
import AssignCardsModal from '../../modules/AssignCards/AssignCardsModal/AssignCardsModal';
import ListOfAssignCards from '../../modules/AssignCards/List/ListOfAssignCards';
import { useClientAuth } from '../../../providers/ClientProvider';
import {
  CIMS_SUB_MODULES,
  CIMS_RESOURCE_MODULES,
} from '../../../constants/modules';
import { checkResourceAccess } from '../../../utils/userAccessUtils';

function AssignToEntity() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [refetchList, setRefetchList] = useState<any>();
  const { user } = useClientAuth();
  const hasAccessToAssignCards = useMemo(
    () =>
      checkResourceAccess({
        name: CIMS_SUB_MODULES.ASSIGN_CARDS.ASSIGN_CARDS,
        resourceAccess: user?.allResourceAccess,
        accessLevel: 'subModule',
        moduleName: CIMS_RESOURCE_MODULES.ASSIGN_CARDS,
      }),
    [user?.allResourceAccess]
  );
  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div>
      <CIMS_Header
        text="Assign Cards"
        component={
          hasAccessToAssignCards ? (
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <CIMS_Button text="Assign cards" onClick={handleOpenDrawer} />
            </Stack>
          ) : undefined
        }
      />

      <AssignCardsModal
        isOpen={isDrawerOpen}
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
        refetchList={refetchList}
      />

      <ListOfAssignCards setRefetchList={setRefetchList} />
    </div>
  );
}

export default AssignToEntity;
