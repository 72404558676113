import { DeleteOutline, Edit, Save } from '@mui/icons-material';
import { Divider, Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  GiftVoucher,
  GiftVoucherType,
  MilestoneEventType,
  NonPointBasedMilestone,
} from '../../../../../../../../../types/RewardsLoyalty';
import VegaCardV2 from '../../../../../../../../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../../../../../../../../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../../../../../../../common/v2/VegaPrimaryButton';
import VegaTextFieldV2 from '../../../../../../../../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../../../../../../../common/v2/VegaTextV2';
import VegaConditionalWrapper from '../../../../../../../../common/VegaConditionalWrapper';
import { useCampaignRule } from '../../../../../../../providers/CampaignRuleProvider';
import { useVoucherInventory } from '../../../../../../../providers/VoucherInventoryProvider';

import VoucherLists from './Voucher/VoucherLists';
import VoucherReadOnlyItem from './Voucher/VoucherReadOnlyItem';

type Props = {
  index: number;
  value: Partial<NonPointBasedMilestone>;
};

enum ViewState {
  Edit,
  Readonly,
}
function NonPointBasedMilestoneInput({ index, value }: Props) {
  const {
    selectedEvent,
    updateNonPointBasedMilestone,
    deleteNonPointBasedMilestone,
  } = useCampaignRule();
  const {
    giftVouchers: allGiftVouchers,
    discountVouchers: allDiscountVouchers,
    subscriptionsVouchers: allSubscriptionVouchers,
  } = useVoucherInventory();

  const [viewState, setViewState] = useState<ViewState>(ViewState.Edit);
  const [count, setCount] = useState<number>();
  const [volume, setVolume] = useState<number>();
  const [giftVouchers, setGiftVouchers] = useState<Partial<GiftVoucher>[]>([]);
  const [discountVouchers, setDiscountVouchers] = useState<
    Partial<GiftVoucher>[]
  >([]);
  const [subscriptionVouchers, setSubscriptionVouchers] = useState<
    Partial<GiftVoucher>[]
  >([]);

  const showCountInput = () => {
    return (
      selectedEvent == MilestoneEventType.BY_COUNT ||
      selectedEvent == MilestoneEventType.BOTH
    );
  };

  const showVolumeInput = () => {
    return (
      selectedEvent == MilestoneEventType.BY_VOLUME ||
      selectedEvent == MilestoneEventType.BOTH
    );
  };

  function updateViewState(viewState: ViewState) {
    setViewState(viewState);
  }

  const hasAnyValidVouchers = () => {
    const hasGiftsVouchers = giftVouchers.length > 0;
    const hasSubscriptionVouchers = subscriptionVouchers.length > 0;
    const hasDiscountVouchers = discountVouchers.length > 0;
    const hasAnyVoucher =
      hasGiftsVouchers || hasSubscriptionVouchers || hasDiscountVouchers;
    if (hasAnyVoucher == false) return false;
    const vouchers = [
      ...giftVouchers,
      ...subscriptionVouchers,
      ...discountVouchers,
    ];
    const selectedGifts = vouchers.filter((item) => item.giftId != null);
    return selectedGifts.length > 0;
  };

  const isInputValid = () => {
    if (selectedEvent.length <= 0) return false;
    var isValid = false;
    const isCountValid = count != null && count > 0;
    const isVolumeValid = volume != null && volume > 0;

    switch (selectedEvent) {
      case MilestoneEventType.BY_COUNT:
        isValid = isCountValid;
        break;
      case MilestoneEventType.BY_VOLUME:
        isValid = isVolumeValid;
        break;
      case MilestoneEventType.BOTH:
        isValid = isVolumeValid && isCountValid;
        break;
    }

    const hasValidVoucher = hasAnyValidVouchers();

    return isValid && hasValidVoucher;
  };

  function addVoucher(data: {
    voucher: Partial<GiftVoucher>;
    type: GiftVoucherType;
  }) {
    switch (data.type) {
      case GiftVoucherType.GIFT_VOUCHER:
        setGiftVouchers((prev) => [...prev, data.voucher]);
        break;
      case GiftVoucherType.DISCOUNT_VOUCHER:
        setDiscountVouchers((prev) => [...prev, data.voucher]);
        break;
      case GiftVoucherType.SUBSCRIPTION:
        setSubscriptionVouchers((prev) => [...prev, data.voucher]);
        break;
    }
  }

  function updateVoucher(data: {
    voucher: Partial<GiftVoucher>;
    index: number;
    type: GiftVoucherType;
  }) {
    switch (data.type) {
      case GiftVoucherType.GIFT_VOUCHER:
        setGiftVouchers((prev) => {
          return prev.map((item, currentIndex) => {
            if (data.index == currentIndex) {
              return data.voucher;
            }
            return item;
          });
        });
        break;
      case GiftVoucherType.DISCOUNT_VOUCHER:
        setDiscountVouchers((prev) => {
          return prev.map((item, currentIndex) => {
            if (data.index == currentIndex) {
              return data.voucher;
            }
            return item;
          });
        });
        break;
      case GiftVoucherType.SUBSCRIPTION:
        setSubscriptionVouchers((prev) => {
          return prev.map((item, currentIndex) => {
            if (data.index == currentIndex) {
              return data.voucher;
            }
            return item;
          });
        });
        break;
    }
  }

  function removeVoucher(data: { type: GiftVoucherType }) {
    switch (data.type) {
      case GiftVoucherType.GIFT_VOUCHER:
        setGiftVouchers((prev) => {
          return prev.slice(0, prev.length - 1);
        });
        break;
      case GiftVoucherType.DISCOUNT_VOUCHER:
        setDiscountVouchers((prev) => {
          return prev.slice(0, prev.length - 1);
        });
        break;
      case GiftVoucherType.SUBSCRIPTION:
        setSubscriptionVouchers((prev) => {
          return prev.slice(0, prev.length - 1);
        });
        break;
    }
  }

  function onSaveClick() {
    const validGiftVouchers: GiftVoucher[] = giftVouchers
      .filter((item) => item.giftId != null)
      .map((item) => mapToGiftVoucher(item))
      .filter((item): item is GiftVoucher => item !== undefined);
    const validDiscountVouchers = discountVouchers
      .filter((item) => item.giftId != null)
      .map((item) => mapToGiftVoucher(item))
      .filter((item): item is GiftVoucher => item !== undefined);
    const validSubscriptionVouchers = subscriptionVouchers
      .filter((item) => item.giftId != null)
      .map((item) => mapToGiftVoucher(item))
      .filter((item): item is GiftVoucher => item !== undefined);

    const data: Partial<NonPointBasedMilestone> = {
      count: count,
      volume: volume,
      giftVouchers: validGiftVouchers,
      subscriptionVouchers: validSubscriptionVouchers,
      discountVouchers: validDiscountVouchers,
    };

    updateNonPointBasedMilestone({ index: index, updatedValue: data });
    updateViewState(ViewState.Readonly);
  }

  useEffect(() => {
    if (!value) return;
    const hasCount = value.count != null && value.count > 0;
    const hasVolume = value.volume != null && value.volume > 0;
    if (hasCount || hasVolume) {
      setVolume(value.volume);
      setCount(value.count);
      setGiftVouchers(value.giftVouchers ?? []);
      setSubscriptionVouchers(value.subscriptionVouchers ?? []);
      setDiscountVouchers(value.discountVouchers ?? []);
      updateViewState(ViewState.Readonly);
    }
  }, [value]);

  if (viewState == ViewState.Readonly) {
    return (
      <ReadOnlyView
        milestone={value}
        onEdit={function (): void {
          updateViewState(ViewState.Edit);
        }}
        canShowCount={showCountInput()}
        canShowVolumes={showVolumeInput()}
        index={index}
        onDelete={() => {
          deleteNonPointBasedMilestone(index);
        }}
      />
    );
  }

  return (
    <VegaCardV2>
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        spacing={1}
      >
        <Stack direction={'row'} spacing={3}>
          <VegaFormInputFieldV2 label="Milestone No">
            <VegaTextV2 text={`${index + 1}`} />
          </VegaFormInputFieldV2>
          <VegaConditionalWrapper
            condition={showCountInput()}
            ifTrue={
              <VegaFormInputFieldV2 label="Count">
                <VegaTextFieldV2
                  type={'number'}
                  placeholder="Enter Count"
                  value={count != null ? count : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numberValue = Number(value);
                    setCount(numberValue);
                  }}
                />
              </VegaFormInputFieldV2>
            }
          />

          <VegaConditionalWrapper
            condition={showVolumeInput()}
            ifTrue={
              <VegaFormInputFieldV2 label="Volume">
                <VegaTextFieldV2
                  type={'number'}
                  placeholder="Enter Volume"
                  value={volume != null ? volume : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numberValue = Number(value);
                    setVolume(numberValue);
                  }}
                />
              </VegaFormInputFieldV2>
            }
          />
        </Stack>
        <Stack direction={'row'} spacing={2} alignItems="end">
          <VegaPrimaryButton
            text="Save"
            size="small"
            startIcon={<Save />}
            disabled={isInputValid() == false}
            onClick={onSaveClick}
          />
        </Stack>
      </Stack>
      <Divider
        sx={{
          marginY: '12px',
        }}
      />
      <VoucherLists
        addVoucher={addVoucher}
        deleteLastVoucher={removeVoucher}
        updateVoucher={updateVoucher}
        giftVouchers={giftVouchers}
        discountVouchers={discountVouchers}
        subscriptionVouchers={subscriptionVouchers}
        allGiftVouchers={allGiftVouchers}
        allDiscountVouchers={allDiscountVouchers}
        allSubscriptionVouchers={allSubscriptionVouchers}
      />
    </VegaCardV2>
  );
}

export default NonPointBasedMilestoneInput;

type ReadOnlyViewProps = {
  milestone?: Partial<NonPointBasedMilestone>;
  canShowCount: boolean;
  canShowVolumes: boolean;
  index: number;
  onEdit: () => void;
  onDelete: () => void;
};
const ReadOnlyView = ({
  milestone,
  onEdit,
  index,
  canShowCount,
  canShowVolumes: canShowVolume,
  onDelete,
}: ReadOnlyViewProps) => {
  const vouchers = [
    ...(milestone?.giftVouchers ?? []),
    ...(milestone?.discountVouchers ?? []),
    ...(milestone?.subscriptionVouchers ?? []),
  ];

  return (
    <VegaCardV2>
      <Stack
        direction={'row'}
        alignItems="end"
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} alignItems="center" spacing={2}>
          <VegaFormInputFieldV2 label="Milestone no" isReadOnly>
            <VegaTextV2 text={`${index + 1}`} />
          </VegaFormInputFieldV2>
          <VegaConditionalWrapper
            condition={canShowCount}
            ifTrue={
              <VegaFormInputFieldV2 label="Count" isReadOnly>
                <VegaTextV2 text={`${milestone?.count ?? '-'}`} />
              </VegaFormInputFieldV2>
            }
          />

          <VegaConditionalWrapper
            condition={canShowVolume}
            ifTrue={
              <VegaFormInputFieldV2 label="Volume" isReadOnly>
                <VegaTextV2 text={`${milestone?.volume ?? '-'}`} />
              </VegaFormInputFieldV2>
            }
          />
        </Stack>
        <Stack direction={'row'}>
          <IconButton color="error" onClick={onDelete}>
            <DeleteOutline
              fontSize="small"
              style={{
                strokeWidth: '8px',
              }}
            />
          </IconButton>
          <VegaPrimaryButton
            size="small"
            text="Edit"
            startIcon={<Edit />}
            onClick={onEdit}
            variant="text"
            sx={{
              color: 'rgba(255, 123, 1, 1)',
            }}
          />
        </Stack>
      </Stack>
      <Divider></Divider>
      <Grid container columnGap={2} rowGap={2}>
        {vouchers.map((item, index) => {
          return (
            <Grid key={index} item xs={12} sm={5} lg={3}>
              <VoucherReadOnlyItem voucher={item} />
            </Grid>
          );
        })}
      </Grid>
    </VegaCardV2>
  );
};

const mapToGiftVoucher = (data: Partial<GiftVoucher>) => {
  const giftId = data.giftId;
  const vendorName = data.vendorName;
  const delayDays = data.delayDays;
  const amount = data.amount;
  const giftType = data.giftType;
  if (giftId != null && vendorName != null && giftType != null) {
    const option: GiftVoucher = {
      giftId: giftId,
      vendorName: vendorName,
      delayDays: delayDays,
      giftType: giftType,
      amount: amount!,
    };
    return option;
  }
};
