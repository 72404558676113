import React, { useState } from 'react';

type ISearchHeaderContext = {
  searchValue: string | number;
  onSearchValueChange: any;
  isHeaderSearch: boolean;
  setIsHeaderSearch: React.Dispatch<React.SetStateAction<boolean>>;
  headerPlaceHolder: string;
  setHeaderPlaceHolder: React.Dispatch<React.SetStateAction<string>>;
};

export const SearchHeaderContext =
  React.createContext<ISearchHeaderContext | null>(null);

export const useSearchHeader = () =>
  React.useContext(SearchHeaderContext) as ISearchHeaderContext;

export const SearchHeaderProvider = (props: any) => {
  const [val, setVal] = useState<string | number>('');
  const [isHeaderSearch, setIsHeaderSearch] = useState(false);
  const [headerPlaceHolder, setHeaderPlaceHolder] = useState<string>('');

  const onSearchValueChange = (val: string | number) => {
    setVal(val);
  };
  return (
    <SearchHeaderContext.Provider
      value={{
        searchValue: val,
        onSearchValueChange: onSearchValueChange,
        isHeaderSearch: isHeaderSearch,
        setIsHeaderSearch: setIsHeaderSearch,
        headerPlaceHolder: headerPlaceHolder,
        setHeaderPlaceHolder: setHeaderPlaceHolder,
      }}
    >
      {props.children}
    </SearchHeaderContext.Provider>
  );
};
