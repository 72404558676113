import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import TransactionLimitCard from './components/TransactionLimitCard';
// import ListOfMarkupFees from './ListOfMarkupFees';
import { getIsTrue } from '../../../../../constants/commonFunction';
import { BORDER_GRAY } from '../../../../../constants/style';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { INewPLanTypeProps, IPLanTypeProps } from '../../../../types/plan';
import { IPlanListProps } from '../MarkupFee/MarkupFee';
import PlanPoliciesCard from '../PlanPoliciesCard';
import ListOfTransactionLimit from './ListOfTransactionLimit';
import { NoResults } from '../../../../../components/common';

export interface ITimeProps {
  timePeriod: string;
  transactionAmountLimit?: number;
  transactionCountLimit?: number;
}
interface IAmountLimitProps {
  minTransactionAmount: number;
  maxTransactionAmount: number;
  transactionAmountLimitRuleList: ITimeProps[];
}

export interface ITransactionRulesProps {
  velocityRuleList: ITimeProps[];
  transactionLimitRule: IAmountLimitProps;
}

interface ITransactionLimitRulesProps {
  [code: string]: ITransactionRulesProps | null;
}

const VELOCITY_LIMIT = [
  {
    timePeriod: 'DAILY',
    transactionCountLimit: 10,
  },
  {
    timePeriod: 'MONTHLY',
    transactionCountLimit: 100,
  },
  {
    timePeriod: 'YEARLY',
    transactionCountLimit: 100,
  },
];
const AMOUNT_LIMIT = [
  {
    timePeriod: 'DAILY',
    transactionAmountLimit: 10,
  },
  {
    timePeriod: 'MONTHLY',
    transactionAmountLimit: 100,
  },
  {
    timePeriod: 'YEARLY',
    transactionAmountLimit: 100,
  },
];

const DEFULT_AMOUNT_LIMIT = {
  minTransactionAmount: 50,
  maxTransactionAmount: 50,
  transactionAmountLimitRuleList: AMOUNT_LIMIT,
};

const DEFULT_FEE_WAIVER = {
  transactionCode: '',
  velocityRuleList: VELOCITY_LIMIT,
  transactionLimitRule: DEFULT_AMOUNT_LIMIT,
};

const FEE_RULE = {
  CASH_AT_POS: null,
  ECOM: null,
  WALLET_TO_WALLET: null,
  POS: null,
  ATM: null,
  CONTACTLESS: null,
} as ITransactionLimitRulesProps;

const DEFUALT_PLAN = {
  clientId: '',
  planName: '',
  planType: 'TRANSACTION_LIMIT_PLAN',
  planDescription: 'planDescription',
  isDefault: true,
  effectiveStartTime: '',
  effectiveEndTime: '',
  status: 'ACTIVE',
} as IPLanTypeProps;

const DEFUALT_PLAN2 = {
  policy_type: 'TRANSACTION_LIMIT_PLAN',
  policy_name: '',
  policy_description: 'planDescription',
  is_default: true,
  start_date: '2023-04-04T07:13:19.418147Z',
  end_date: '2023-04-04T07:13:19.418147Z',
  status: 'ENABLED',
} as INewPLanTypeProps;

const TransactionLimit = ({
  onCreate,
  plans,
  page,
  count,
  onPageChange,
}: IPlanListProps) => {
  const { clientId } = useClientAuth();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [planData, setPlanData] = useState<INewPLanTypeProps>({
    ...DEFUALT_PLAN2,
    clientId,
  });
  const [transactionLimitRules, setTransactionLimitRules] =
    useState<ITransactionLimitRulesProps>({
      ...FEE_RULE,
    });

  const handleChange = (name: string, value: any) => {
    setPlanData({ ...planData, [name]: value });
    if (value.length) {
      setErrorMsg('');
    }
  };

  const activationToggle = (code: string) => {
    const current = transactionLimitRules[code];
    const rule = { ...DEFULT_FEE_WAIVER, transactionCode: code };
    setTransactionLimitRules({
      ...transactionLimitRules,
      [code]: !!current ? null : rule,
    });
  };
  const handleChangeValue = (code: string, key: string, value: any) => {
    const edited = {
      ...transactionLimitRules[code],
      [key]: value,
    } as ITransactionRulesProps;
    setTransactionLimitRules({
      ...transactionLimitRules,
      [code]: { ...edited },
    });
  };
  const onCreateTransactionLimit = async () => {
    const transaction_limit_component_list: ITransactionRulesProps[] = [];
    const copy = { ...transactionLimitRules };
    Object.keys(copy).map((code) => {
      if (!!copy[code]) {
        transaction_limit_component_list.push(
          copy[code] as ITransactionRulesProps
        );
      }
    });
    if (planData.policy_name.length) {
      await onCreate({
        ...planData,
        transaction_limit_component_list,
        clientId,
      });
      setPlanData({ ...DEFUALT_PLAN2, clientId });
      setTransactionLimitRules({ ...FEE_RULE });
    } else {
      setErrorMsg('Please enter plan name');
    }
  };

  useEffect(() => {
    const copy = { ...transactionLimitRules };
    const newArr = Object.keys(copy).map((code) => {
      return copy[code];
    });
    const isNull = getIsTrue(null, newArr);
    if (isNull) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [transactionLimitRules]);
  return (
    <>
      <PlanPoliciesCard
        title="TRANSACTION_LIMIT_PLAN"
        plan={planData}
        handleChange={handleChange}
        disabled={disabled}
        errorMsg={errorMsg}
        onCreate={onCreateTransactionLimit}
      >
        <Box sx={{ borderTop: BORDER_GRAY }}>
          {Object.keys(transactionLimitRules).map((code) => {
            const rule = transactionLimitRules[code];
            console.log('Rule here', rule);
            return (
              <TransactionLimitCard
                key={`charge-card-${code}`}
                rule={rule}
                code={code}
                onActivationClick={activationToggle}
                handleChange={handleChangeValue}
              />
            );
          })}
        </Box>
      </PlanPoliciesCard>
      {plans.length ? (
        <ListOfTransactionLimit
          data={plans}
          page={page}
          count={count}
          onPageChange={onPageChange}
        />
      ) : (
        <NoResults text="You have no Trasnsaction limit plan" />
      )}
    </>
  );
};

export default TransactionLimit;
