import { Box, Skeleton } from '@mui/material';
import { NEW_PRIMARY } from '../../../../constants/style';
import Vega_FormWrapper from '../../../common/v3/Vega_FormWrapper';
import Vega_Text from '../../../common/v3/Vega_Text';

interface IProps {
  name: string;
  value: number | string | undefined;
  isBorder?: boolean;
  loading?: boolean;
}

export const TextWrapper = ({ name, value, isBorder, loading }: IProps) => {
  return (
    <Vega_FormWrapper
      header={name}
      sx={{
        position: 'relative',
      }}
    >
      <>
        {loading ? (
          <Skeleton />
        ) : (
          <Vega_Text
            text={value ?? '--'}
            fontWeight={500}
            color={NEW_PRIMARY.BLACK}
            fontSize={12}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          />
        )}
        {isBorder && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              height: '12px',
              width: '1px',
              bgcolor: '#E1E4EB',
              top: '30%',
            }}
          ></Box>
        )}
      </>
    </Vega_FormWrapper>
  );
};
