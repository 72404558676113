import { Box } from '@mui/material';
import React from 'react';

import { ToggleOff } from '@mui/icons-material';
import { VegaAccordian } from '../../../../../../components/common';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import { IFeeDetailsProps } from '../Charges';
import FixedChargeDetails from './FixedChargeDetails';
import FixedAllCurrencyChargeDetails from './FixedAllCurrencyChargeDetails';
import PercentageChargesDetails from './PercentageChargesDetails';

export interface IProps {
  rule: IFeeDetailsProps | null;
  headerPrefix?: string;
  code: string;
  onActivationClick: (code: string, type: any) => void;
  // isDisabled?: boolean;
  handleChange: (code: string, key: string, value: any) => void;
}

export interface IChareRulesProps {
  rule: IFeeDetailsProps | null | any;
  code: string;
  handleChange: (key: string, value: any) => void;
}

export const CHARGE_CODE_TO_TYPE = {
  CASH_AT_POS_FEE: 'PERCENTAGE',
  CASH_WITHDRAWAL_FEE: 'FIXED_ALL_CURRENCY',
  BALANCE_ENQUIRY_FEE: 'FIXED_ALL_CURRENCY',
  DOMESTIC_REPLACEMENT_CARD_FEE: 'FIXED',
  INTERNATIONAL_REPLACEMENT_CARD_FEE: 'FIXED',
  CHARGE_SLIP_RETRIEVAL_FEE: 'FIXED_ALL_CURRENCY',
  CHARGEBACK_FEE: 'FIXED_ALL_CURRENCY',
  INACTIVITY_FEE: 'FIXED',
  MONTHLY_SMS_FEE: 'FIXED_ALL_CURRENCY',
} as { [key: string]: 'PERCENTAGE' | 'FIXED_ALL_CURRENCY' | 'FIXED' };

const ChargesCard = ({
  rule,
  code,
  onActivationClick,
  handleChange,
}: IProps) => {
  console.log('CHARGE_CODE_TO_TYPE[code] ', rule);
  return (
    <Box sx={{ my: 2 }}>
      <VegaAccordian
        expandIcon={
          <ToggleOff fontSize="large" color={!!rule ? 'success' : 'disabled'} />
        }
        expanded={!!rule}
        onChange={() => onActivationClick(code, CHARGE_CODE_TO_TYPE[code])}
        header={toLowerCase(code)}
        noPad
        content={
          rule && (
            <>
              {CHARGE_CODE_TO_TYPE[code] === 'FIXED' ? (
                <FixedChargeDetails
                  rule={rule}
                  code={code}
                  handleChange={(key, value) => handleChange(code, key, value)}
                />
              ) : CHARGE_CODE_TO_TYPE[code] === 'FIXED_ALL_CURRENCY' ? (
                <FixedAllCurrencyChargeDetails
                  rule={rule}
                  code={code}
                  handleChange={(key, value) => handleChange(code, key, value)}
                />
              ) : (
                <PercentageChargesDetails
                  rule={rule}
                  code={code}
                  handleChange={(key, value) => handleChange(code, key, value)}
                />
              )}
            </>
          )
        }
      />
    </Box>
  );
};

export default ChargesCard;
