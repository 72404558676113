import { Box, type SxProps, type Theme } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridColumns,
  GridFeatureMode,
} from '@mui/x-data-grid';
import { VPPRIMARY } from '../theme';

export type VPDataGridProps = {
  data: readonly any[];
  columns: GridColumns<any>;
  page?: number;
  pageSize?: number;
  rowCount?: number;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
  loading?: boolean;
  idColumn: string;
  bgColor?: string;
  paginationMode?: GridFeatureMode;
  wrapperSx?: SxProps<Theme>;
} & Partial<DataGridProps>;

function VPDataGrid({
  data,
  columns,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  loading,
  rowCount,
  paginationMode,
  idColumn,
  bgColor,
  sx,
  wrapperSx,
  ...rest
}: VPDataGridProps) {
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          '& .vegatable--blue': {
            bgcolor: '#F7F7F7',
            '&:hover': {
              bgcolor: '#F7F7F7',
            },
          },
          '& .vegatable--white': {
            bgcolor: '#FFFFFF',
            '&:hover': {
              bgcolor: '#FFFFFF',
            },
          },
          ...wrapperSx,
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            overflowX: 'scroll',
            border: 'none',
            '& ::-webkit-scrollbar': {
              width: '2px !important',
              height: '2px !important',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: 'transparent !important',
            },
            '& .MuiDataGrid-main': {
              border: '1px solid #E1E4EB',
              borderRadius: '0.75rem',
            },
            '& .MuiDataGrid-row:hover': {
              bgcolor: 'white',
            },
            '& .MuiDataGrid-cellContent': {
              color: '#1B1D22',
              fontSize: '13px',
              fontFamily: `var(--font-Aspekta-500) !important`,
            },
            '& .MuiDataGrid-columnHeaders': {
              borderTop: 'none !important',
              borderBottom: '1px solid #E1E4EB',
              backgroundColor: '#F7F7F7',
              maxHeight: '2.75rem !important',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '13px',
              textTransform: 'capitalize',

              lineHeight: '124%',
              color: '#676B76',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none !important',
            },
            '& .MuiDataGrid-sortIcon': {
              display: 'none !important',
            },
            '& .MuiTablePagination-toolbar': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '13px',
              '& .MuiTablePagination-select': {
                marginTop: '2px',
                paddingBottom: '0px',
              },
            },

            '& .MuiTablePagination-selectLabel': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '10px',
              color: VPPRIMARY.GRAY,
            },
            '& .MuiTablePagination-displayedRows': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '10px',
              color: VPPRIMARY.GRAY,
            },
            '& .MuiDataGrid-footerContainer': {
              border: 'none !important',
              backgroundColor: bgColor ?? '#F7F7F7',
              margin: '0px !important',
              padding: '0px !important',
            },
            ...sx,
          }}
          //   components={{
          //     Pagination: CustomPagination,
          //   }}
          showCellRightBorder={false}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          rowHeight={56}
          classes={{ withBorder: 'no-border' }}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          rowSpacingType="margin"
          onRowClick={(params) => {}}
          page={page}
          pageSize={pageSize ?? 5}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          loading={loading}
          rowCount={rowCount}
          paginationMode={paginationMode ?? 'client'}
          rowsPerPageOptions={paginationMode ? [5, 10, 15] : []}
          pagination={true}
          getRowId={(row: any) => {
            return row[idColumn];
          }}
          getRowClassName={(params) => {
            return `${
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'vegatable--white'
                : 'vegatable--blue'
            }`;
          }}
          {...rest}
        />
      </Box>
    </div>
  );
}

export default VPDataGrid;

// function CustomPagination() {
//   const apiRef = useGridApiContext();
//   const page = useGridSelector(apiRef, gridPageSelector);
//   const pageCount = useGridSelector(apiRef, gridPageCountSelector);
//   const rowCount = useGridSelector(apiRef, gridRowCountSelector);

//   const rowsPerPage = apiRef.current?.state.pagination.pageSize || 0;
//   const startIndex = page * rowsPerPage + 1;
//   const endIndex = Math.min((page + 1) * rowsPerPage, rowCount);

//   const canGoBack = () => {
//     if (page <= 0) return false;
//     return true;
//   };
//   const canGoForward = () => {
//     if (page + 1 >= pageCount) return false;
//     return true;
//   };

//   const getPageCountDisplayText = () => {
//     if (pageCount == 0) return 1;
//     return pageCount;
//   };

//   return (
//     <Stack direction={'row'} justifyContent="space-between" width={'100%'}>
//       <Box
//         sx={{
//           px: 2,
//           py: 1,
//           display: 'flex',
//           justifyContent: 'end',
//           alignItems: 'center',
//         }}
//       >
//         <LosText
//           text={`Showing data ${startIndex} to ${endIndex} of ${rowCount} entries`}
//           variant="caption"
//         />
//       </Box>

//       <Stack
//         direction={'row'}
//         alignItems="center"
//         spacing={'0.2rem'}
//         marginRight="1rem"
//       >
//         <IconButton
//           disabled={canGoBack() == false}
//           onClick={() => {
//             apiRef.current.setPage(page - 1);
//           }}
//         >
//           <ArrowBackIos sx={{ fontSize: 12 }} />
//         </IconButton>

//         <LosText
//           style={{
//             color: 'rgba(103, 107, 118, 1)',
//             fontSize: '0.625rem',
//             fontWeight: 600,
//             lineHeight: '120%',
//           }}
//         >
//           Page {page + 1} of {getPageCountDisplayText()}
//         </LosText>
//         <IconButton
//           disabled={canGoForward() == false}
//           onClick={() => {
//             apiRef.current.setPage(page + 1);
//           }}
//         >
//           <ArrowForwardIos sx={{ fontSize: 12 }} />
//         </IconButton>
//       </Stack>
//     </Stack>
//   );
// }
