import { Add, Save } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import VegaFileInputV2 from '../../../../components/common/v2/VegaFileInputV2';
import VegaFormInputFieldV2 from '../../../../components/common/v2/VegaFormInputField';
import VegaPrimaryButton from '../../../../components/common/v2/VegaPrimaryButton';
import VegaTextFieldV2 from '../../../../components/common/v2/VegaTextFieldV2';
import VegaTextV2 from '../../../../components/common/v2/VegaTextV2';
import { useCampaign } from '../../../../components/RewardsLoyalty/providers/CampaignProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { EditCampaignFormData } from '../../../../types/RewardsLoyalty';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../../utils/FileValidator';

type Props = {
  onClose: () => void;
  onSave: (data: EditCampaignFormData) => void;
  open: boolean;
};

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([
    FileExtension.PNG,
    FileExtension.JPEG,
    FileExtension.JPG,
    FileExtension.SVG,
  ])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

function EditCampaignModal({ onClose, open, onSave }: Props) {
  const { selectedCampaign } = useCampaign();
  const { setSnackbar } = useSnackbar();
  const [name, setName] = useState<string>('');
  const [logo, setLogo] = useState<string>('');
  const [file, setFile] = useState<File | null>();

  const isInputValid = () => {
    const isNameValid = name.length > 0;
    return isNameValid;
  };

  useEffect(() => {
    setName(selectedCampaign?.campaignName ?? '');
    setLogo(selectedCampaign?.campaignImageLink ?? '');
  }, [selectedCampaign]);

  function onSubmitClick() {
    onSave({
      name: name,
      logo: file ?? undefined,
    });
    onClose();
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      style={{ maxHeight: '80vh', marginTop: '7.5rem' }}
      scroll="paper"
    >
      <DialogTitle>
        <VegaTextV2 text="Edit Campaign" variant="h6" />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <VegaFormInputFieldV2 label="Name">
            <VegaTextFieldV2
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </VegaFormInputFieldV2>
          <VegaFormInputFieldV2 label="Logo">
            <VegaFileInputV2
              acceptedExtension=".png"
              placeholder={'Select Logo'}
              onFileSelect={function (file: File | null): void {
                setFile(file);
              }}
              onError={(message) => {
                setSnackbar(message ?? 'Something went wrong', 'error');
                setFile(null);
              }}
              fileValidator={fileValidatorConfig}
            />
          </VegaFormInputFieldV2>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'}>
          <VegaPrimaryButton
            text={'Close'}
            onClick={() => {
              onClose();
            }}
            variant="text"
          />
          <VegaPrimaryButton
            text={'Save'}
            startIcon={<Save />}
            disabled={isInputValid() == false}
            onClick={() => {
              onSubmitClick();
            }}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default EditCampaignModal;
