import { Typography } from '@mui/material';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import {
  ResourceType,
  useInstitutionalResources,
} from '../../hooks/useInstitutionalResources';
import VegaSearchBy from '../common/VegaSearchBy';
import VegaTable from '../common/VegaTable';
import { VegaCard } from '../common';

function TimeZoneTable() {
  const { loading, fetchResource } = useInstitutionalResources();
  const [countryCodes, setCountryCodes] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [filter, setFilter] = useState<{ search: string; searchBy: string }>({
    search: '',
    searchBy: TIMEZONE_COLUMNS[0].value,
  });

  async function fetchTimeZone() {
    try {
      const response = await fetchResource<any>({
        type: ResourceType.TIMEZONE,
        page: page,
        pageSize: pageSize,
        searchBy: filter.searchBy,
        searchQuery: filter.search,
      });
      setCountryCodes(response.records);
      setRowCount(response.totalItems);
    } catch (error) {}
  }

  const onFilterChange = (name: string, value: string) => {
    if (name === 'searchBy') {
      setFilter((preVal: any) => ({
        ...preVal,
        ['search']: '',
        ['searchBy']: value,
      }));
    } else {
      setFilter((preVal: any) => ({
        ...preVal,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    fetchTimeZone();
  }, [page, pageSize, filter.search, filter.searchBy]);

  return (
    <Fragment>
      <VegaSearchBy
        placeholder="Search"
        data={TIMEZONE_COLUMNS}
        searchByname="searchBy"
        searchName="search"
        key="search"
        state={filter}
        handleChange={onFilterChange}
      />
      <VegaCard noPad>
        <VegaTable
          data={countryCodes}
          columnDef={columnTimeZone}
          idColumn={'id'}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
          loading={loading}
        />
      </VegaCard>
    </Fragment>
  );
}

export default TimeZoneTable;

const TIMEZONE_COLUMNS = [
  {
    text: 'Country Name',
    value: 'countryName',
  },
  {
    text: 'Timezone',
    value: 'timezone',
  },
  {
    text: 'Timezone Code',
    value: 'timezoneOffset',
  },
];

const columnTimeZone = [
  {
    field: 'countryName',
    headerName: '  Country Name',
    renderCell: (props: any) => {
      return <Typography>{_.capitalize(props.row.countryName)}</Typography>;
    },
    flex: 1,
  },

  {
    field: 'timezone',
    headerName: '  Timezone',
    renderCell: (props: any) => {
      return <Typography>{_.capitalize(props.row.timezone)}</Typography>;
    },
    flex: 1,
  },
  {
    field: 'timezoneOffset',
    headerName: '  Timezone Code',

    flex: 1,
  },
];
