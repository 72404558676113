import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import {
  BLUE,
  BORDER,
  BORDER_BLUE,
  BORDER_GRAY,
  GREEN,
  GREY,
  VEGA_BOX_SHADOW,
} from '../../constants/style';
import { ISummaryListProps } from '../../pages/AgentView';
import { VegaTextField } from '../common';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaOutLineTextField from '../common/VegaOutLineTextField';
import VegaText from '../common/VegaText';

interface IProps {
  data: ISummaryListProps[];
}

const table = [
  'Customer Id',
  ' Customer Name',
  'Last Call',
  'Next Call',
  'O/D Balance',
  'DPD bucket',
];

const ListOfAgent = ({ data }: IProps) => {
  const [IDs, setIDs] = useState<number>(-1);
  const [open, setOpen] = useState<boolean>(false);
  const onSelect = (index: number) => {
    if (index === IDs) {
      setIDs(-1);
      return;
    }
    setIDs(index);
  };
  return (
    <Box
      sx={{
        boxShadow: VEGA_BOX_SHADOW,
        borderRadius: '20px',
        bgcolor: 'background.paper',
      }}
    >
      <Box
        sx={{
          bgcolor: GREY.light,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          display: 'flex',
        }}
      >
        <Box sx={{ py: 1.5, px: 2, flex: 0.6 }}>Customer Id</Box>
        <Box sx={{ py: 1.5, px: 2, flex: 1 }}>Customer Name</Box>
        <Box sx={{ py: 1.5, px: 2, flex: 1 }}>Last Call</Box>
        <Box sx={{ py: 1.5, px: 2, flex: 1 }}>Next Call</Box>
        <Box sx={{ py: 1.5, px: 2, flex: 0.6 }}>O/D Balance</Box>
        <Box sx={{ py: 1.5, px: 2, flex: 0.6 }}>DPD bucket</Box>
        <Box sx={{ py: 1.5, px: 2, flex: 0.3 }}></Box>
      </Box>
      {data.map((summary: ISummaryListProps, index: number) => (
        <Box
          sx={{
            // height: 'auto',
            mx: 1,
            my: 1.5,
            transition: 'all 1s ease-in-out',
            borderRadius: '5px',
            ...(IDs === index && {
              border: BORDER_GRAY,
              transition: 'all 1s ease-in-out',
            }),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              bgcolor: BLUE.light,
              borderRadius: '5px',
            }}
          >
            <Box sx={{ px: 2, py: 1, flex: 0.6 }}>{summary.customerId}</Box>
            <Box sx={{ px: 2, py: 1, flex: 1 }}>{summary.customerName}</Box>
            <Box sx={{ px: 2, py: 1, flex: 1 }}>{summary.lastCall}</Box>
            <Box sx={{ px: 2, py: 1, flex: 1 }}>{summary.nextCall}</Box>
            <Box sx={{ px: 2, py: 1, flex: 0.6 }}>{summary.ODBalance}</Box>
            <Box sx={{ px: 2, py: 1, flex: 0.6 }}>{summary.DPDBucket}</Box>
            <Box sx={{ px: 2, flex: 0.3 }}>
              <IconButton onClick={() => onSelect(index)}>
                <KeyboardArrowDown
                  sx={{
                    transition: 'all 500ms ease-in-out',
                    ...(IDs === index && { rotate: '180deg' }),
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          {IDs === index && (
            <Box sx={{ p: 4, transition: 'all 1s ease-in-out' }}>
              <Grid container mb={4}>
                <Grid item xs={12} sm={2} px={2} borderRight={BORDER}>
                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      mr: 1,
                      border: BORDER_GRAY,
                      borderRadius: '5px',
                      cursor: 'pointer',
                      color: GREY.darkest,
                      textAlign: 'center',
                      fontSize: 16,
                      //   ...(value === val
                      //     ? { bgcolor: BACKGROUND.LIGHT_BLUE }
                      //     : { bgcolor: 'white' }),
                    }}
                    // onClick={() => onChange(val)}
                  >
                    Call
                  </Box>
                </Grid>
                <Grid item xs={12} sm={10} px={2}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box width={200}>
                      <VegaDatePicker
                        label="Select Date"
                        onChange={() => console.log('first')}
                      />
                    </Box>
                    <Box mx={3}>
                      <Typography>( Select Date to Set Reminder )</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container mb={4}>
                <Grid item xs={12} sm={2} px={2} borderRight={BORDER}>
                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      mr: 1,
                      border: BORDER_GRAY,
                      borderRadius: '5px',
                      cursor: 'pointer',
                      color: GREY.darkest,
                      textAlign: 'center',
                      fontSize: 16,
                    }}
                    // onClick={() => onChange(val)}
                  >
                    Schedule
                  </Box>
                </Grid>
                <Grid item xs={12} sm={10} px={2}>
                  <Box sx={{ display: 'flex', alignItems: 'end' }}>
                    <Box width={200}>
                      <VegaDatePicker
                        label="Select Date"
                        onChange={() => console.log('first')}
                      />
                    </Box>
                    <Box mx={2}>
                      <Typography>To</Typography>
                    </Box>
                    <Box width={200}>
                      <VegaDatePicker
                        label="Select Date"
                        onChange={() => console.log('first')}
                      />
                    </Box>
                    <Box
                      sx={{
                        px: 2,
                        ml: 1,
                        border: BORDER_BLUE,
                        color: BLUE.lighter,
                        borderRadius: '5px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        fontSize: 16,
                      }}
                    >
                      Confirm
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container mb={4}>
                <Grid item xs={12} sm={2} px={2} borderRight={BORDER}>
                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      mr: 1,
                      border: BORDER_GRAY,
                      borderRadius: '5px',
                      cursor: 'pointer',
                      color: GREY.darkest,
                      textAlign: 'center',
                      fontSize: 16,
                    }}
                    // onClick={() => onChange(val)}
                  >
                    Payment Link
                  </Box>
                </Grid>
                <Grid item xs={12} sm={10} px={2}>
                  <Box sx={{ display: 'flex', alignItems: 'end' }}>
                    <Box
                      width={200}
                      sx={{
                        px: 2,
                        mr: 1,
                        border: BORDER_GRAY,
                        borderRadius: '5px',
                        cursor: 'pointer',
                        color: GREY.darkest,
                        textAlign: 'center',
                        fontSize: 16,
                      }}
                      // onClick={() => onChange(val)}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: GREY.dark,
                              '&.Mui-checked': {
                                color: GREEN.dark,
                              },
                            }}
                          />
                        }
                        label={'Send SMS'}
                        sx={{ fontSize: '14px' }}
                      />
                    </Box>

                    <Box mx={2.8}></Box>
                    <Box
                      width={200}
                      sx={{
                        px: 2,
                        border: BORDER_GRAY,
                        borderRadius: '5px',
                        cursor: 'pointer',
                        color: GREY.darkest,
                        textAlign: 'center',
                        fontSize: 16,
                      }}
                      // onClick={() => onChange(val)}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: GREY.dark,
                              '&.Mui-checked': {
                                color: GREEN.dark,
                              },
                            }}
                          />
                        }
                        label={'Email'}
                        sx={{ fontSize: '14px' }}
                      />
                    </Box>
                    <Box
                      sx={{
                        px: 2,
                        ml: 1,
                        border: BORDER_BLUE,
                        color: BLUE.lighter,
                        borderRadius: '5px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        fontSize: 16,
                      }}
                    >
                      Send
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ListOfAgent;
