import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  BLUE,
  BORDER_GRAY,
  GREEN,
  GREY,
  RED,
} from '../../../../constants/style';
import { toLowerCase } from '../../../../constants/commonFunction';

interface IRiskScoreProps {
  condition: string;
  score: string | number;
  status: string;
}

const RiskScoreDetails = () => {
  const Risk_Score: IRiskScoreProps[] = [
    {
      condition: 'Name Match',
      score: '25%',
      status: 'APPROVED',
    },
    {
      condition: 'Selfie Match',
      score: '3/10',
      status: 'NOT_APPROVED',
    },
    {
      condition: 'Liveness',
      score: '35%',
      status: 'APPROVED',
    },
    {
      condition: 'ID Uniqueness',
      score: '25%',
      status: 'APPROVED',
    },
    {
      condition: 'Device Uniqueness',
      score: '3',
      status: 'APPROVED',
    },
    {
      condition: 'IP Uniqueness',
      score: '4',
      status: 'NOT_APPROVED',
    },
  ];

  return (
    <Box pb={4}>
      <Box
        sx={{
          width: 514,
          border: BORDER_GRAY,
          borderRadius: '8px',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            bgcolor: BLUE.lightest,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
          {Object.keys(Risk_Score[0]).map((key, index) => (
            <Box
              sx={{
                p: 2,

                width: '100%',

                borderTopLeftRadius: '8px',
                ...(Object.keys(Risk_Score[0]).length !== index + 1 && {
                  borderRight: BORDER_GRAY,
                }),
              }}
            >
              <Typography
                className="font-opensans-700"
                fontSize={14}
                color={GREY.darkest}
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {key}
              </Typography>
            </Box>
          ))}
        </Stack>
        {Risk_Score.map((score: IRiskScoreProps, index: number) => (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{
              bgcolor: index % 2 ? BLUE.lightest : 'white',
              ...(Risk_Score.length === index + 1 && {
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              }),
            }}
          >
            <Box
              sx={{
                p: 2,

                width: '100%',
                borderRight: BORDER_GRAY,
                borderTopLeftRadius: '8px',
              }}
            >
              <Typography
                className="font-opensans-400"
                fontSize={14}
                color={GREY.grey_light}
              >
                {score.condition}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 2,
                borderRight: BORDER_GRAY,
                width: '100%',
              }}
            >
              <Typography
                className="font-opensans-400"
                fontSize={14}
                color={GREY.grey_light}
              >
                {score.score}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 2,

                width: '100%',
                borderTopRightRadius: '8px',
              }}
            >
              <Typography
                className="font-opensans-400"
                fontSize={14}
                color={score.status === 'APPROVED' ? GREEN.dark : RED.dark}
              >
                {toLowerCase(score.status)}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default RiskScoreDetails;
