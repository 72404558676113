/* eslint-disable react/jsx-pascal-case */
import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  UpdateCardPreferanceBody,
  getCardsList,
  updateCustomerPreference,
} from '../../../../../api/card';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { NEW_PRIMARY } from '../../../../../constants/style';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useSupport } from '../../../../../providers/SupportProvider';
import { ICardsForAccountProps } from '../../../../../types/support';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import Vega_Datagrid from '../../../../common/v3/Vega_Datagrid';
import { Vega_Switch } from '../../../../common/v3/Vega_Switch';
import Vega_Text from '../../../../common/v3/Vega_Text';
import CardControlsContainer from './components/CardControlsContainer';

export interface CardInfo {
  id: string;
  binId: number;
  productType: string;
  network: string;
  cardCategory: string;
  cardType: string;
  cardStatus: string;
  cardNumber: string;
  kitId: string;
  cardProcessorVendor: string;
  lastFourDigits: string;
  expiryDate: string;
  customerId: string | null;
  accountId: string;
  programId: string;
  externalCustomerId: string | null;
  externalAccountId: string | null;
  pinStatus: string;
  replacedCardId: string | null;
  replacementReason: string | null;
  discardReasonId: string | null;
  cardAllotmentOrderId: string | null;
  cardDeliveryTrackingId: string | null;
  nameOnCard: string;
  domesticAtm: string | null;
  domesticPos: string | null;
  domesticEcom: string | null;
  domesticContactless: string | null;
  internationalAtm: string | null;
  internationalPos: string | null;
  internationalEcom: string | null;
  internationalContactless: string | null;
  domesticAtmLimit: number | null;
  domesticPosLimit: number | null;
  domesticEcomLimit: number | null;
  domesticContactlessLimit: number | null;
  internationalAtmLimit: number | null;
  internationalPosLimit: number | null;
  internationalEcomLimit: number | null;
  internationalContactlessLimit: number | null;
  dualOrSingleMessage: string;
  levelEntityDto: any | null; // You may define a more specific type here if known
  programDto: any | null; // You may define a more specific type here if known
  entityCode: string | null;
  issuedBy: string | null;
  rejectedBy: string | null;
  createdAt: string;
  updatedAt: string;
}

interface IrewardsListStateProps {
  data: Promise<ICardsForAccountProps>;
  loading: boolean;
}

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

enum CardControlStatus {
  DOMESTIC = 'DOMESTIC',
  INTERNATION = 'INTERNATION',
}

const Tickets = () => {
  const { accountData } = useSupport();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [selecteControl, setSelecteControl] = useState<string>(
    CardControlStatus.DOMESTIC
  );
  const [activeCardsListState, setActiveCardsListState] = useState<CardInfo[]>(
    []
  );

  const cardControlList = [
    {
      transactionType: 'ATM',
      perTransaction:
        selecteControl === CardControlStatus.DOMESTIC
          ? activeCardsListState[0]?.domesticAtmLimit ?? 0
          : activeCardsListState[0]?.internationalAtmLimit ?? 0,
      status:
        selecteControl === CardControlStatus.DOMESTIC
          ? activeCardsListState[0]?.domesticAtm
          : activeCardsListState[0]?.internationalAtm,
    },
    {
      transactionType: 'CONTACTLESS',
      perTransaction:
        selecteControl === CardControlStatus.DOMESTIC
          ? activeCardsListState[0]?.domesticContactlessLimit ?? 0
          : activeCardsListState[0]?.internationalContactlessLimit ?? 0,
      status:
        selecteControl === CardControlStatus.DOMESTIC
          ? activeCardsListState[0]?.domesticContactless
          : activeCardsListState[0]?.internationalContactless,
    },
    {
      transactionType: 'POS',
      perTransaction:
        selecteControl === CardControlStatus.DOMESTIC
          ? activeCardsListState[0]?.domesticPosLimit ?? 0
          : activeCardsListState[0]?.internationalPosLimit ?? 0,
      status:
        selecteControl === CardControlStatus.DOMESTIC
          ? activeCardsListState[0]?.domesticPos
          : activeCardsListState[0]?.internationalPos,
    },
    {
      transactionType: 'ECOM',
      perTransaction:
        selecteControl === CardControlStatus.DOMESTIC
          ? activeCardsListState[0]?.domesticEcomLimit ?? 0
          : activeCardsListState[0]?.internationalEcomLimit ?? 0,
      status:
        selecteControl === CardControlStatus.DOMESTIC
          ? activeCardsListState[0]?.domesticEcom
          : activeCardsListState[0]?.internationalEcom,
    },
    // {
    //   transactionType: 'INTERNATIONAL',
    //   perTransaction: activeCardsListState[0]?.domesticAtmLimit??0,
    //   status: activeCardsListState[0]?.international,
    // },

    // {
    //   transactionType: 'DCC',
    //   perTransaction: activeCardsListState[0]?.domesticAtmLimit??0,
    //   status: activeCardsListState[0]?.dome,
    // },
  ];

  const handleControlChange = (value: string) => {
    setSelecteControl(value);
  };

  const handleBlockChange = async (
    customerPreferenceStatus: string,
    transactionType: string,
    limit: number
  ) => {
    const body = {
      cardId: activeCardsListState[0]?.id,
      type: transactionType,
      limit,
      locationType: selecteControl,
      customerPreferenceStatus,
    } as UpdateCardPreferanceBody;
    try {
      const response = await updateCustomerPreference(body);
      setSnackbar('Update Successfully !!!');
      accountData.accountId && getAllActiveCards(accountData.accountId);
    } catch (error) {}
  };

  const getAllActiveCards = async (accountId: string) => {
    setLoading(true);
    try {
      const response = await getCardsList({ accountId });
      const primaryCard = response.filter(
        (card: CardInfo) => card.cardCategory === 'PRIMARY_CARD'
      );
      setActiveCardsListState(primaryCard);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getAllActiveCards(accountData.accountId);
    }
  }, []);

  const COLUMN_DEF: GridColumns = [
    {
      field: 'transactionType',
      headerName: 'Transaction Type',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: (props) => {
        const transactionType = props.row.transactionType;
        return <Vega_Text text={toLowerCase(transactionType)} />;
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const status = props.row.status;
        return <Vega_Text text={!status ? '--' : toLowerCase(status)} />;
      },
    },
    {
      field: 'perTransaction',
      headerName: 'Per Transaction',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const perTransaction = props.row.perTransaction;
        return <Vega_Text text={!perTransaction ? 0 : perTransaction} />;
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        const status = props.row.status;
        const transactionType = props.row.transactionType;
        const perTransaction = props.row.perTransaction;
        return !status ? (
          '--'
        ) : (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <Vega_Switch
              checked={status === 'ENABLED'}
              onChange={(e) =>
                handleBlockChange(
                  status === 'ENABLED' ? 'DISABLED' : 'ENABLED',
                  transactionType,
                  perTransaction
                )
              }
            />
            <Vega_Text
              text={status === 'ENABLED' ? 'Block' : 'Unblock'}
              fontWeight={500}
              fontColor={
                status === 'ENABLED' ? NEW_PRIMARY.BLUE : NEW_PRIMARY.GRAY
              }
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <CardControlsContainer
        header="Card Controls"
        handleChange={handleControlChange}
        selected={selecteControl}
      >
        <Box mt={2}>
          <Vega_Datagrid
            data={cardControlList}
            idColumn="transactionType"
            columns={COLUMN_DEF}
            loading={loading}
          />
        </Box>
      </CardControlsContainer>
    </>
  );
};

export default Tickets;
