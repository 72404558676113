/** @format */

import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import { configureAxiosDefaults } from './api/axiosConfig';
import { COGNITO, DEV_COGNITO, SBM_COGNITO, UAT_COGNITO } from './aws';
import RoutingComponent from './components/routing/RoutingComponent';
import { isCimsProd, isCimsUAT, isDev } from './constants/url';
import AppBarProvider from './providers/AppBarProvider';
import ClientProvider from './providers/ClientProvider';
import CustomerDataProvider from './providers/CustomerDataProvider';
import InactivityTimeoutProvider from './providers/InactivityTimeoutProvider';
import { PolicyProvider } from './providers/PolicyProvider';
import ProgramDataProvider from './providers/ProgramDataProvider';
import { SearchHeaderProvider } from './providers/SearchHeader';
import SnackbarProvider from './providers/SnackbarProvider';
import { VegaFullScreenDialogProvider } from './providers/VegaFullScreenDialogProvider';
import { CookiesProvider } from 'react-cookie';
import OidcProvider from './providers/OidcProvider';

const queryClient = new QueryClient();

function App() {
  // configure AXIOS base
  configureAxiosDefaults();

  Amplify.configure({
    aws_cognito_region: isDev
      ? DEV_COGNITO.REGION
      : isCimsProd
      ? SBM_COGNITO.REGION
      : isCimsUAT
      ? UAT_COGNITO.REGION
      : COGNITO.REGION,
    aws_user_pools_id: isDev
      ? DEV_COGNITO.USER_POOL_ID
      : isCimsProd
      ? SBM_COGNITO.USER_POOL_ID
      : isCimsUAT
      ? UAT_COGNITO.USER_POOL_ID
      : COGNITO.USER_POOL_ID,
    aws_user_pools_web_client_id: isDev
      ? DEV_COGNITO.APP_CLIENT_ID
      : isCimsProd
      ? SBM_COGNITO.APP_CLIENT_ID
      : isCimsUAT
      ? UAT_COGNITO.APP_CLIENT_ID
      : COGNITO.APP_CLIENT_ID,
  });

  return (
    <VegaFullScreenDialogProvider>
      <SnackbarProvider>
        <OidcProvider>
          <ClientProvider>
            <ProgramDataProvider>
              <PolicyProvider>
                <CustomerDataProvider>
                  <AppBarProvider>
                    <SearchHeaderProvider>
                      <QueryClientProvider client={queryClient}>
                        <CookiesProvider>
                          <InactivityTimeoutProvider>
                            <RoutingComponent />
                          </InactivityTimeoutProvider>
                        </CookiesProvider>
                      </QueryClientProvider>
                    </SearchHeaderProvider>
                  </AppBarProvider>
                </CustomerDataProvider>
              </PolicyProvider>
            </ProgramDataProvider>
          </ClientProvider>
        </OidcProvider>
      </SnackbarProvider>
    </VegaFullScreenDialogProvider>
  );
}

export default App;
