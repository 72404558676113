import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DataTile from '../DataTile';
import Link, { LinkProps } from '@mui/material/Link';
import { useAppSelector } from '../../../store/hook';
import { getPresignedUrl } from '../../../api/card';

type Props = {};

const LinkText = (props: LinkProps) => {
  return (
    <Link
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
      }}
      {...props}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography
        className="font-aspekta-700"
        sx={{
          fontSize: 12,
          color: '#1047DC',
          textDecorationLine: 'underline',
          textTransform: 'lowercase',
        }}
      >
        {props.title}
      </Typography>
    </Link>
  );
};

function AgreementProcess(props: Props) {
  const program = useAppSelector(
    (state) => state.programManagement.selectedProgram
  );
  const [downloadableLinksData, setDownloadableLinksData] = useState<{
    links?: {
      agreementTwo?: string;
      sanctionLetterFormat?: string;
      loanEmiAgreement?: string;
    };
    isLoading: boolean;
  }>({
    isLoading: false,
  });

  const agreementTwo = program?.agreementTwo;
  const sanctionLetterFormat = program?.sanctionLetterFormat;
  const loanEmiAgreement = program?.loanEmiAgreement;

  useEffect(() => {
    const letters = [
      agreementTwo ? getPresignedUrl(agreementTwo) : undefined,
      sanctionLetterFormat ? getPresignedUrl(sanctionLetterFormat) : undefined,
      loanEmiAgreement ? getPresignedUrl(loanEmiAgreement) : undefined,
    ];
    setDownloadableLinksData({ isLoading: true });
    Promise.all(letters)
      .then((links) => {
        setDownloadableLinksData({
          links: {
            agreementTwo: links[0]?.data,
            sanctionLetterFormat: links[1]?.data,
            loanEmiAgreement: links[2]?.data,
          },
          isLoading: false,
        });
      })
      .catch((e) => {
        setDownloadableLinksData({ isLoading: false });
      });
  }, [agreementTwo, sanctionLetterFormat, loanEmiAgreement]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography color="#1B1D22" fontSize={17} className="font-aspekta-700">
        Agreements & Sanction Letter
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        <DataTile title="Approval Process">
          {downloadableLinksData?.links?.agreementTwo ? (
            <LinkText
              title="agreements.pdf"
              href={downloadableLinksData.links.agreementTwo}
            />
          ) : (
            '-'
          )}
        </DataTile>
        <DataTile title="Sanction Letter">
          {downloadableLinksData?.links?.sanctionLetterFormat ? (
            <LinkText
              title="sanctionLetter"
              href={downloadableLinksData.links.sanctionLetterFormat}
            />
          ) : (
            '-'
          )}
        </DataTile>
        <DataTile title="Emi agreements">
          {downloadableLinksData?.links?.loanEmiAgreement ? (
            <LinkText
              title="emiAgreement"
              href={downloadableLinksData.links.loanEmiAgreement}
            />
          ) : (
            '-'
          )}
        </DataTile>
      </Box>
    </Box>
  );
}

export default AgreementProcess;
