/** @format */
import { VegaBin } from './client';
import { VegaUser } from './user';

/** @format */
export type VegaProgramType = {
  programId: string;
  clientId: string;
  programCode: string;
  name: string;
  email: string;
  mobile: string;
  agreement?: string;
  tnc?: string;
  createdAt?: Date;
  updatedAt?: Date;
  status?: string;
  agreementTwo?: string;
  sanctionLetterFormat?: string;
  loanEmiAgreement?: string;
  category?: VegaProgramCategoryType;
  type?: string;
  network?: string;
  checkerUserId?: string;
  makerUserId?: string;
  issuanceType?: string;
  coBrandPartnerName?: string;
  binId?: string;
  metaData: MetaDataDto;
};

export type VegaProgramWithMetaDataType = {
  programId: string;
  clientId: string;
  name: string;
  email: string;
  mobile: string;
  agreement?: string;
  tnc?: string;
  createdAt?: Date;
  updatedAt?: Date;
  status?: string;
  agreementTwo?: string;
  sanctionLetterFormat?: string;
  loanEmiAgreement?: string;
  category?: VegaProgramCategoryType;
  type?: string;
  network?: VegaProgramNetworkType;
  checker?: VegaUser;
  maker?: VegaUser;
  issuanceType?: VegaProgramIssuanceType;
  coBrandPartnerName?: string;
  bin?: VegaBin;
  pddList?: number[];
};

export type VegaCreateProgramType = {
  clientId: string;
  name: string;
  programCode?: string;
  agreement?: any;
  tnc?: any;
  type: string;
  category: string;
  network?: string;
  issuanceType: string;
  coBrandPartnerName?: string;
  binId?: string;
  makerUserId?: string;
  checkerUserId?: string;
  maxPddShiftAllowed?: number;
};

export type VegaUpdateProgramType = {
  programId: string;
  email: string;
  mobile: string;
};

export type VegaUpdateProgramDocument = {
  programId: string;
  file: File;
};

export type VegaDocument = {
  name: string;
  file?: File;
};

export type VegaDeleteProgramDocument = {
  programId: string;
};

export enum VegaProgramDocumentType {
  SANCTION_LETTER = 'SANCTION_LETTER',
  LOAN_EMI_AGREEMENT = 'LOAN_EMI_AGREEMENT',
  BILL_STATEMENT = 'BILL_STATEMENT',
  AGREEMENT_TWO = 'AGREEMENT_TWO',
}

export type VegaProgramDocumentUrlRequest = {
  documentType: VegaProgramDocumentType;
  programId: string;
};

export enum VegaProgramCategoryType {
  CREDIT_CARD = 'CREDIT_CARD',
  PREPAID_CARD = 'PREPAID_CARD',
  FOREX_CARD = 'FOREX_CARD',
  LOANS = 'LOANS',
}

export enum VegaProgramNetworkType {
  MASTER_CARD = 'MASTERCARD',
  RUPAY = 'RUPAY',
  VISA = 'VISA',
}

export enum VegaProgramIssuanceType {
  SELF = 'SELF',
  CO_BRAND = 'CO_BRAND',
}

export enum VegaLoanProductType {
  HOME_LOAN = 'HOME_LOAN',
  TWO_WHEELER_LOAN = 'TWO_WHEELER_LOAN',
  EDUCATION_LOAN = 'EDUCATION_LOAN',
}

export enum VegaCreditCardProductType {
  CHARGE_CARD = 'CHARGE_CARD',
  CORPORATE_CARD = 'CORPORATE_CARD',
  RETAIL_UNSECURED = 'RETAIL_UNSECURED_CARD',
  RETAIL_SECURED = 'RETAIL_SECURED_CARD',
}

export enum VegaPrepaidCardProductType {
  GIFT_CARD = 'GIFT_CARD',
  TRAVEL_CARD = 'TRAVEL_CARD',
  FUEL_CARD = 'FUEL_CARD',
  MEAL_CARD = 'MEAL_CARD',
}

export enum VegaForexCardProductType {
  CORPORATE_CARD = 'CORPORATE_CARD',
  RETAIL_CARD = 'RETAIL_CARD',
}

export enum VegaProgramStatusType {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}

export type VegaSettlementCalendarDate = {
  id: string;
  createdAt: string;
  updatedAt: string;
  programId: string;
  actualDay: number;
  actualMonth: string;
  effectiveMonth: string;
  financialYear: number;
  effectiveDay: number;
  reason: string;
};

export interface MetaDataDto {
  id: string;
  clientId: string;
  entityId: string;
  entityDto: string;
  divisionId: string;
  partnerId: string;
  corporateId: string;
  branchId: string;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface ListProgramWithMetadataRequest {
  metaDataDto: Partial<MetaDataDto>;
  clientId: string;
  status: string;
  name: string;
  category: string;
  type: string;
  page: number;
  size: number;
}

export interface ProgramMetaDataMapRequest {
  entityId?: string;
  programId: string;
  clientId: string;
  corporateId?: string;
  divisionId: string;
}
