export interface VegaLosLimitOffer {
  id: string;
  createdAt: string;
  updatedAt: string;
  programId: string;
  applicationId: string;
  customerName: string | null;
  customerMobileNumber: string | null;
  customerEmail: string | null;
  offerAmount: number;
  offerOverLimit: number;
  offerReduction: boolean;
  acceptedOfferAmount: number | null;
  coolingPeriod: string | null;
  cashLimit: string | null;
  offerActivationTime: string | null;
  offerRejectionTime: string | null;
  rejectionReason: string | null;
  offerExpiryTime: string;
  offerStatus: VegaLimitOfferStatus;
}

export enum VegaLimitOfferStatus {
  PENDING = 'ACTIVE',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export interface PassportVerificationResponse {
  service: string;
  itemId: string;
  task: string;
  essentials: Essentials;
  accessToken: string;
  id: string;
  response: Response;
}

export interface Essentials {}

export interface Response {
  files: string[];
  id: number;
  instance: Instance;
  result: Result;
}

export interface Instance {}

export interface Result {
  parentsGuardianName: string;
  issueDate: string;
  expiryDate: string;
  birthDate: string;
  name: string;
  country: string[];
  nationality: string;
  sex: string;
  address: string;
  pincode: string;
  passportNumber: string;
  fileNumber: string;
  placeOfBirth: string;
  placeOfIssue: string;
  splitAddress: SplitAddress;
  summary: Summary;
}

export interface SplitAddress {
  district: string[];
  state: string[][];
  city: string[];
  pincode: string;
  country: string[];
  addressLine: string;
}

export interface Summary {
  number: string;
  name: string;
  dob: string;
  address: string;
  splitAddress: SplitAddress2;
  gender: string;
  guardianName: string;
  issueDate: string;
  expiryDate: string;
}

export interface SplitAddress2 {
  district: string[];
  state: string[][];
  city: string[];
  pincode: string;
  country: string[];
  addressLine: string;
}
