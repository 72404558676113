/* eslint-disable react/jsx-pascal-case */
/** @format */

import { Box, Grid } from '@mui/material';
import React, { SetStateAction, useCallback } from 'react';

import { Add } from '@mui/icons-material';
import { IUsersProps, VegaTeamType } from '../../../types/userAccess';
import { VegaCard, VegaTextField } from '../../common';
import VegaContainedButton from '../../common/VegaContainedButton';
import { UploadBulkIcon } from '../../icon/Icons';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../common/VegaSelect';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { VALIDATE_REGEX } from '../../../utils/validation.utils';
import Vega_HeaderWrapper from '../../common/v3/Vega_HeaderWrapper';
import { GovernmentIdType } from '../../../enums/commons';

const validationRules = {
  required: 'This field is required',
};

interface IAddUserProps {
  onChange: (name: string, value: any) => void;
  onSubmit: (data: IUsersProps) => void;
  showBulkUpload: boolean;
  setIsBulkUpload?: React.Dispatch<SetStateAction<boolean>>;
  state: IUsersProps;
  isInputValid?: boolean;
  error?: any;
}

const AddUser = ({
  state,
  onChange,
  setIsBulkUpload,
  onSubmit,
  showBulkUpload,
  isInputValid = true,
  error,
}: IAddUserProps) => {
  const hookForm = useForm<IUsersProps>({});
  const {
    control,
    formState: { errors },
    watch,
    clearErrors,
  } = hookForm;
  const idType = watch('idType') as GovernmentIdType;

  const getValidMessage = useCallback(() => {
    switch (idType) {
      case GovernmentIdType.AADHAR:
        return 'Not a valid ID number. Example Pattern: 2345 1234 5678';
      case GovernmentIdType.DRIVING_LICENCES:
        return 'Not a valid ID number. Example Pattern: RJ-1320120123456 or RJ13 20120123456';
      case GovernmentIdType.PAN:
        return 'Not a valid ID number. Example Pattern: ABCTY1234D';
    }
  }, [idType]);

  return (
    <VegaCard>
      <Vega_HeaderWrapper text="Add a team member" />
      <Box mb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            {/* <VegaTextField
            focused
            value={state?.name ? state?.name : ''}
            onChange={(e) => onChange('name', e.target.value)}
            key="name"
            label="Name"
            type={'text'}
            // errorText={state?.name && error['name']}
          /> */}
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: validationRules.required,
                pattern: {
                  value: VALIDATE_REGEX.FULL_NAME,
                  message: 'Only letters (alphabets) and space are allowed!',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="Enter Name"
                  type="text"
                  placeholder="Enter Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="mobileNumber"
              control={control}
              defaultValue=""
              rules={{
                required: validationRules.required,
                maxLength: {
                  value: 10,
                  message: 'Mobile number should be 10 digits',
                },
                minLength: {
                  value: 10,
                  message: 'Mobile number should be 10 digits',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="Mobile Number"
                  type="number"
                  placeholder="Mobile Number"
                  error={!!errors.mobileNumber}
                  helperText={errors.mobileNumber?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: validationRules.required,
                pattern: {
                  value: VALIDATE_REGEX.EMAIL,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="Email"
                  type="text"
                  placeholder="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{ required: validationRules.required }}
              render={({ field }) => (
                <VegaTextField
                  {...field}
                  label="Address"
                  type="text"
                  placeholder="Address"
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="team"
              control={control}
              defaultValue=""
              rules={{
                required: validationRules.required,
              }}
              render={({ field }) => (
                <VegaSelect
                  {...field}
                  label="Select Team"
                  placeholder="Select Team"
                  error={!!errors.team}
                  errorHelperText={errors.team?.message || ''}
                  options={Object.values(VegaTeamType)}
                  selected={field.value}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Vega_HeaderWrapper text="Government ID Proof" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="idType"
            control={control}
            defaultValue=""
            rules={{
              required: validationRules.required,
            }}
            render={({ field: { onChange, ...rest } }) => {
              return (
                <VegaSelect
                  {...rest}
                  error={!!errors.idType}
                  errorHelperText={errors.idType?.message || ''}
                  label="ID"
                  placeholder="ID"
                  options={idOptions()}
                  selected={rest.value}
                  onChange={(e) => {
                    clearErrors('idNumber');
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Grid>

        {Boolean(idType) && (
          <Grid item xs={12} sm={4}>
            <Controller
              name="idNumber"
              control={control}
              defaultValue=""
              rules={{
                required: validationRules.required,
                pattern: {
                  value: VALIDATE_REGEX[idType],
                  message: getValidMessage(),
                },
              }}
              render={({ field }) => {
                const { value, ...rest } = field;
                return (
                  <VegaTextField
                    {...rest}
                    value={value ? value.toUpperCase() : value}
                    label="ID Number"
                    placeholder="ID Number ( mandatory )"
                    type={'text'}
                    error={!!errors.idNumber}
                    helperText={errors.idNumber?.message}
                  />
                );
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent={'end'} py={2}>
        {showBulkUpload && (
          <Grid item>
            <VegaContainedButton
              text="Upload Bulk"
              isSuccess
              startIcon={<UploadBulkIcon />}
              onClick={() => {
                if (setIsBulkUpload) {
                  setIsBulkUpload((preVal: boolean) => !preVal);
                }
              }}
            />
          </Grid>
        )}

        <Grid item>
          <VegaContainedButton
            text="Add User"
            isPrimary
            startIcon={<Add />}
            disabled={!isInputValid}
            onClick={hookForm.handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>
    </VegaCard>
  );
};

export default AddUser;

const idOptions = () =>
  Object.values(GovernmentIdType).map((item) => {
    var displayText = '';
    if (item == GovernmentIdType.PAN) {
      displayText = 'PAN card number';
    } else {
      displayText = _.startCase(_.toLower(item));
    }
    const option: VegaSelectSelectOptionType = {
      id: item,
      name: displayText,
    };
    return option;
  });
