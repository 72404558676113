import { Stack } from '@mui/system';
import { scaleQuantile } from 'd3-scale';
import { useEffect, useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ProjectionConfig,
} from 'react-simple-maps';
import { INDIA_TOPO } from './data/india.topo';
import { getHeatMapData } from './data/sample.data';
import Labels from './Labels';

const DEFAULT_COLOR = '#EEE';

const PROJECTION_CONFIG: ProjectionConfig = {
  scale: 300,
  center: [78.9629, 22.5937],
};
const COLOR_RANGE: any = ['#7DC4F7', '#8DC1E7', '#D1E1EB', '#CCF3BA'];

const geographyStyle = {
  default: {
    outline: 'none',
  },
  hover: {
    fill: '#ccc',
    transition: 'all 250ms',
    outline: 'none',
  },
  pressed: {
    outline: 'none',
  },
};
export const ChoroplethMap = () => {
  const [data, setData] = useState<
    {
      id: string;
      state: string;
      value: number;
    }[]
  >([]);

  const [tooltipContent, setTooltipContent] = useState('');
  const gradientData = {
    fromColor: COLOR_RANGE[0],
    toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
    min: 0,
    max: data.reduce((max, item) => (item.value > max ? item.value : max), 0),
  };

  const colorScale = scaleQuantile()
    .domain(data.map((d) => d.value))
    .range(COLOR_RANGE);

  const onMouseEnter = (geo: any, current = { value: 'NA' }) => {
    return () => {
      setTooltipContent(`${geo.properties.name}: ${current.value}`);
    };
  };

  const onMouseLeave = () => {
    setTooltipContent('');
  };

  const onChangeButtonClick = () => {
    setData(getHeatMapData());
  };

  useEffect(() => {
    setData(getHeatMapData());
  }, []);

  return (
    <Stack direction={'row'} alignItems="center">
      <svg width={600} height={450}>
        <ComposableMap
          projectionConfig={PROJECTION_CONFIG}
          projection="geoMercator"
          width={400}
          height={300}
          data-tip=""
        >
          <Geographies geography={INDIA_TOPO}>
            {({ geographies }) =>
              geographies.map((geo) => {
                //console.log(geo.id);
                const current: any = data.find((s) => s.id === geo.id);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={
                      current
                        ? (colorScale(current.value) as any)
                        : DEFAULT_COLOR
                    }
                    style={geographyStyle}
                    onMouseEnter={onMouseEnter(geo, current)}
                    onMouseLeave={onMouseLeave}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      </svg>

      <Labels
        labels={['Lable 1', 'Label 2', 'Label 3', 'Lable 4']}
        colors={COLOR_RANGE}
      />
    </Stack>
  );
};
