/** @format */

import { CircularProgress, Typography } from '@mui/material';
import VegaCard from './VegaCard';

type Props = {
  text?: string;
};

const Loading = ({ text }: Props) => {
  return (
    <VegaCard
      fullHeight
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <CircularProgress />
      {text && (
        <Typography color={'primary.main'} variant="subtitle1" my={1}>
          {text}
        </Typography>
      )}
    </VegaCard>
  );
};

export default Loading;
