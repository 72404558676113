/* eslint-disable react/jsx-pascal-case */
import { GridColumns } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { listCardStatus } from '../../../api/card';
import { toLowerCase } from '../../../constants/commonFunction';
import { useClientAuth } from '../../../providers/ClientProvider';
import CIMS_DataGrid from '../../components/common/CIMS_DataGrid';
import { useState } from 'react';
import { Typography } from '@mui/material';

const ListOfCardIndentation = () => {
  const { clientId } = useClientAuth();
  const [pageSize, setPageSize] = useState<number>(10);
  const listCardStatusQuery = useQuery({
    queryKey: 'cardStatus',
    queryFn: () => {
      return listCardStatus();
    },
    enabled: !!clientId,
    select: (data) => {
      return data.map((item: string, index: number) => {
        return {
          no: index + 1,
          indentation: item,
        };
      });
    },
  });

  const COLUMN_DEF: GridColumns = [
    {
      field: 'no',
      headerName: 'S.No.',
      flex: 0.5,
      minWidth: 80,
      maxWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
    // {
    //   field: 'code',
    //   headerName: 'Code',
    //   flex: 0.6,
    //   minWidth: 150,
    //   maxWidth: 150,
    // },
    {
      field: 'indentation',
      headerName: 'Card Status',
      flex: 1,
      valueFormatter: (props) => toLowerCase(props.value),
      renderCell: (props) => <Typography>{props.value}</Typography>,
    },
  ];
  return (
    <>
      <CIMS_DataGrid
        data={listCardStatusQuery.data || []}
        columns={COLUMN_DEF}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        loading={listCardStatusQuery.isLoading}
        idColumn={'no'}
        paginationMode="server"
        //   onPageChange={changePage}
      />
    </>
  );
};

export default ListOfCardIndentation;
