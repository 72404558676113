import { Box, Stack, SxProps, Theme } from '@mui/material';
import Vega_Text from './Vega_Text';
interface IFormInputWrapperProps {
  header: string;
  children: JSX.Element;
  sx?: SxProps<Theme>;
}

const Vega_FormWrapper = ({ header, children, sx }: IFormInputWrapperProps) => {
  return (
    <Box sx={sx}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'relative'}
      >
        <Vega_Text
          fontWeight={700}
          sx={{
            textTransform: 'uppercase',
            fontSize: '0.625rem',
            letterSpacing: '0.07em',
            color: '#676B76',
            mb: 0.5,
          }}
          text={header}
        />
      </Stack>
      {children}
    </Box>
  );
};

export default Vega_FormWrapper;
