import React from 'react';
import { SxProps, Typography, Theme } from '@mui/material';

interface ITextProps {
  fontWeight: string | number;
  fontSize: string | number;
  color: string;
  text: string;
  sx?: SxProps<Theme>;
}

const VegaAspektaText = ({
  fontWeight,
  fontSize,
  color,
  text,
  sx,
}: ITextProps) => {
  return (
    <Typography
      className={`font-aspekta-${fontWeight}`}
      color={color}
      fontSize={fontSize}
      sx={{ ...sx }}
    >
      {text}
    </Typography>
  );
};

export default VegaAspektaText;
