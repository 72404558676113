import React from 'react';
import { SupportStep } from '../../../../pages/Support/Support';
import SupportApplicationsDetails from '../SupportApplicationDetails/SupportApplicationsDetails';
import SupportKycDetails from '../SupportKycDetails/SupportKycDetails';

interface IProps {
  selected: string;
}

const SupportApplcaitionKycContainer = ({ selected }: IProps) => {
  return (
    <div>
      {selected === SupportStep.APPLICATIONS ? (
        <SupportApplicationsDetails />
      ) : (
        <SupportKycDetails />
      )}
    </div>
  );
};

export default SupportApplcaitionKycContainer;
