import React from 'react';
import { Box } from '@mui/material';
import { COMMON } from '../../../constants/style';

interface IProps {
  bgcolor?: string;
  onClick: () => void;
  open: boolean;
}

const VegaHamburgerMenu = ({ bgcolor, onClick, open }: IProps) => {
  return (
    <Box
      sx={{
        width: '2.5rem',
        height: '2rem',
        cursor: 'pointer',
        position: 'relative',
        '&.MuiBox-root:focus': {
          outline: 'none',
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'block',
          width: '1.25rem',
          position: 'absolute',
          right: '4px',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box
          component={'span'}
          sx={{
            display: 'block',
            width: '1.25rem',
            height: '0.125rem',
            position: 'absolute',

            transition: 'all 500ms ease-in-out',
            bgcolor: bgcolor ?? COMMON.darkGray,
            ...(open
              ? { transform: 'rotate(45deg)', top: '0px' }
              : { transform: 'translateY(-1.5px)', top: '-3px' }),
          }}
        ></Box>
        <Box
          component={'span'}
          sx={{
            display: 'block',
            width: '1.25rem',
            height: '0.125rem',
            position: 'absolute',
            transition: 'all 500ms ease-in-out',
            bgcolor: bgcolor ?? COMMON.darkGray,
            ...(open && { opacity: 0 }),
          }}
        ></Box>
        <Box
          component={'span'}
          sx={{
            display: 'block',
            width: '1.25rem',
            height: '0.125rem',
            position: 'absolute',
            transition: 'all 500ms ease-in-out',
            bgcolor: bgcolor ?? COMMON.darkGray,
            ...(open
              ? { transform: 'rotate(-45deg)', top: '0px' }
              : { transform: 'translateY(1.5px)', top: '3px' }),
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default VegaHamburgerMenu;
