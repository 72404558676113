import { Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import { Fragment, useState } from 'react';
import { useProgramData } from '../../../../../../../providers/ProgramDataProvider';
import {
  CampaignRuleType,
  CampaignType,
  ContributionEntityType,
} from '../../../../../../../types/RewardsLoyalty';
import VegaTabBar from '../../../../../../common/v2/VegaTabBar';
import VegaTabBarItem from '../../../../../../common/v2/VegaTabBarItem';
import VegaTextV2 from '../../../../../../common/v2/VegaTextV2';
import { useCampaignRule } from '../../../../../providers/CampaignRuleProvider';

type Props = {
  onTabSelect: (value: number) => void;
};

function RuleTabs({ onTabSelect }: Props) {
  const {
    rewardsData,
    inclusionExclusionData,
    financialData,
    contributionData,
    isRulePaid,
    selectedRuleType,
    milestoneData,
    campaign,
    giftVouchers,
    subscriptionVouchers,
    discountVouchers,
    nonPointBasedMilestoneData,
    merchants,
  } = useCampaignRule();
  const { selectedProgram } = useProgramData();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const getNonTransactionalRewardsDescription = () => {
    if (campaign?.campaignType == CampaignType.POINT_BASED) {
      const rewardPoints = rewardsData.rewardPoints;
      if (rewardPoints) {
        return `${rewardPoints} reward points `;
      }
      return 'Add Reward points';
    } else if (campaign?.campaignType == CampaignType.NON_POINT_BASED) {
      const voucher = getFirstVoucher();
      const vendorName = voucher?.vendorName;
      if (vendorName) {
        return `${voucher.vendorName} - ${voucher.amount}`;
      }
    }
  };

  const getMilestoneRewardsDescription = () => {
    if (campaign?.campaignType == CampaignType.POINT_BASED) {
      const milestones = milestoneData.milestones ?? [];
      const hasMilestones = milestones.length > 0;

      if (hasMilestones) {
        const firstMilestone = milestones[0];
        const count = firstMilestone.count;
        const volume = firstMilestone.volume;
        var displayText = 'Milestone 1';
        if (count) {
          displayText += ' ' + count;
        }
        if (volume) {
          displayText += ' ' + volume;
        }
        return displayText;
      }
      return 'Add Milestones';
    } else if (campaign?.campaignType == CampaignType.NON_POINT_BASED) {
      const milestones = nonPointBasedMilestoneData.milestones ?? [];
      const hasMilestones = milestones.length > 0;

      if (hasMilestones) {
        const firstMilestone = milestones[0];
        const count = firstMilestone.count;
        const volume = firstMilestone.volume;
        var displayText = 'Milestone 1';
        if (count) {
          displayText += ' ' + count;
        }
        if (volume) {
          displayText += ' ' + volume;
        }
        return displayText;
      }
      return 'Add Milestones';
    }
  };

  const getFirstVoucher = () => {
    const vouchers = [
      ...giftVouchers,
      ...discountVouchers,
      ...subscriptionVouchers,
    ];
    if (vouchers.length > 0) {
      return vouchers[0];
    }
  };

  const getTransactionalRewardsDescription = () => {
    if (campaign?.campaignType == CampaignType.POINT_BASED) {
      const rewardPoints = rewardsData.rewardPoints;
      const perAmount = rewardsData.perAmount;
      if (rewardPoints || perAmount) {
        return `${rewardPoints} reward points per ${perAmount ?? '-'}`;
      }
    } else if (campaign?.campaignType == CampaignType.NON_POINT_BASED) {
      const voucher = getFirstVoucher();
      const vendorName = voucher?.vendorName;
      if (vendorName) {
        return `${voucher.vendorName} - ${voucher.amount}`;
      }
    }
  };

  const getRewardsDescription = () => {
    switch (selectedRuleType) {
      case CampaignRuleType.TRANSACTIONAL:
        return getTransactionalRewardsDescription();
      case CampaignRuleType.NON_TRANSACTIONAL:
        return getNonTransactionalRewardsDescription();
      case CampaignRuleType.MILESTONE:
        return getMilestoneRewardsDescription();
    }
    return 'Add Rewards';
  };

  const getInclusionExclusionDescription = () => {
    const transactionAmount = inclusionExclusionData.transactionAmount;
    if (transactionAmount) {
      return `Transaction Amount >= ${transactionAmount}`;
    }
    return 'Add Filters';
  };

  const getFinancialSummary = () => {
    const tenures = financialData.tenures ?? [];
    if (tenures.length > 0) {
      const firstTenure = tenures[0];
      var displayText = '';
      displayText += `${_.startCase(_.toLower(firstTenure.tenureType))} Plan ${
        firstTenure.price
      }`;

      return displayText;
    }
    return '';
  };

  const getFinancialDescription = () => {
    const tenures = financialData.tenures ?? [];
    if (tenures.length > 0) {
      var displayText = '';
      tenures.map((item) => {
        displayText += `${_.startCase(_.toLower(item.tenureType))} Plan ${
          item.price
        }`;
        displayText += '\n';
      });
      return displayText.slice(0, -1);
    }
    return '';
  };

  const getContributionSummary = () => {
    const contributions = contributionData.contributionEntities ?? [];
    if (contributions.length > 0) {
      const firstContribution = contributions[0];
      var displayText = '';
      displayText += `${_.startCase(_.toLower(firstContribution.name))} - ${
        firstContribution.percentage
      }%`;

      return displayText;
    }
    return '';
  };

  const getMerchant = (id?: string) => {
    if (!id) return;
    const filtered = merchants.filter((item) => item.id == id);
    if (filtered.length > 0) return filtered[0];
    return;
  };

  const getContributionDescription = () => {
    const contributions = contributionData.contributionEntities ?? [];

    if (contributions.length > 0) {
      var displayText = '';
      contributions.forEach((item) => {
        var name = item.name;

        if (item.contributorType == ContributionEntityType.PAYMENT_NETWORK) {
          name = selectedProgram?.network;
        } else if (item.contributorType == ContributionEntityType.MERCHANT) {
          const merchant = getMerchant(name);
          name = merchant != null ? merchant.name : 'Merchant';
        }
        displayText += `${_.startCase(_.toLower(name))} - ${item.percentage}%`;
        displayText += '\n';
      });

      return displayText.slice(0, -1);
    }
    return '';
  };

  function onTabChange(value: number) {
    setSelectedTab(value);
    onTabSelect(value);
  }

  const canShowInclusionExclusionTab = () => {
    const isTransactional = selectedRuleType == CampaignRuleType.TRANSACTIONAL;
    const isMilestone = selectedRuleType == CampaignRuleType.MILESTONE;
    return isTransactional || isMilestone;
  };

  return (
    <VegaTabBar
      orientation="vertical"
      value={selectedTab}
      variant="fullWidth"
      onChange={(e, selected) => {
        onTabChange(selected);
      }}
    >
      <VegaTabBarItem
        sx={{
          minHeight: '60px !important',
        }}
        label={
          <TabBarLabel
            title={'Rewards'}
            subtitle={getRewardsDescription()}
            toolTipText={getRewardsDescription()}
          />
        }
      />
      {canShowInclusionExclusionTab() && (
        <VegaTabBarItem
          sx={{
            minHeight: '60px !important',
          }}
          label={
            <TabBarLabel
              title={'Inclusion/Exclusion'}
              subtitle={getInclusionExclusionDescription()}
              toolTipText={getInclusionExclusionDescription()}
            />
          }
        />
      )}
      <VegaTabBarItem
        sx={{
          minHeight: '60px !important',
        }}
        label={
          <TabBarLabel
            title={'Contribution'}
            subtitle={getContributionSummary()}
            toolTipText={getContributionDescription()}
          />
        }
      />
      {isRulePaid && (
        <VegaTabBarItem
          sx={{
            minHeight: '60px !important',
          }}
          label={
            <TabBarLabel
              title={'Financial'}
              subtitle={getFinancialSummary()}
              toolTipText={getFinancialDescription()}
            />
          }
        />
      )}
    </VegaTabBar>
  );
}

export default RuleTabs;

const TabBarLabel = (data: {
  title: string;
  subtitle?: string;
  toolTipText?: string;
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (data.toolTipText != null && data.toolTipText.length > 0) setOpen(true);
  };
  return (
    <Tooltip
      title={
        <VegaTextV2 fontSize={'10px'}>
          {data.toolTipText?.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </VegaTextV2>
      }
      arrow
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Stack
        style={{ width: '100%', height: '100%', padding: '12px' }}
        alignItems="start"
        justifyContent={'center'}
      >
        <VegaTextV2
          text={data.title}
          color={'rgba(58, 74, 95, 1)'}
          fontSize={'13px'}
          variant="caption"
        />
        <VegaTextV2 text={data.subtitle} variant="caption" fontSize={'10px'} />
      </Stack>
    </Tooltip>
  );
};
