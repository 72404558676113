import { Box, Stack } from '@mui/material';
import CimsReportsBody from './components/CimsReportsBody';

const CimsReport = () => {
  return (
    <Box p={2}>
      <Stack direction={'row'} gap={3}></Stack>
      <CimsReportsBody />
    </Box>
  );
};

export default CimsReport;
