import React, { useState } from 'react';
import { VegaCard, VegaPage, VegaTextField } from '../../common';
import { Box, IconButton, Typography, Grid } from '@mui/material';
import { AmazoneIcon } from '../../icon/Icons';
import { BORDER_GRAY, GREY, PRIMARY } from '../../../constants/style';
import VegaContainedButton from '../../common/VegaContainedButton';
import { AddCircleOutline } from '@mui/icons-material';
import VegaModal from '../../common/VegaModal';
import VegaOutlineButton from '../../common/VegaOutlineButton';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../common/VegaSelect';
import ListOfDiscountVoucher from './components/ListOfDiscountVoucher';
import {
  GIFT_VOUCHER,
  IinventotyProps,
} from '../../../pages/RewardLoyalty/components/BenefitsInventory';
import NoVoucherAndRewards from '../../../pages/RewardLoyalty/components/NoVoucherAndRewards';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { ICreateInventoryProps } from '../../../types/RewardsLoyalty';
import VegaHeader from '../../common/VegaHeader';
import { AntSwitch, toLowerCase } from '../../../constants/commonFunction';
import { COMMON } from '../../../constants/style';
import VendorList from '../components/VendorList';
import AddCoupons from '../components/AddCoupons';

const DISOUNT_VOUCHER = {
  programId: '',
  vendorId: '1231231',
  vendorName: 'AMAZON',
  giftType: 'DISCOUNT_VOUCHER',
  discountVoucher: {
    number: 50,
    valueType: 'PERCENTAGE',
  },
  quantity: 0,
  totalValue: 2000000,
  assigned: 0,
  remaining: 0,
  autoRenew: true,
};

const DiscountVoucher = ({
  data,
  onVoucherSubmit,
  updateRenewInventory,
}: IinventotyProps) => {
  const { selectedProgram } = useProgramData();
  const [open, setOpen] = useState<boolean>(false);
  const [discountVoucher, setDiscountVoucher] = useState<ICreateInventoryProps>(
    {
      ...DISOUNT_VOUCHER,
    }
  );
  const handleClose = () => {
    setOpen(!open);
  };

  const handleChange = (name: string, value: any) => {
    setDiscountVoucher({ ...discountVoucher, [name]: value });
  };

  const handleAmountChange = (name: string, value: any) => {
    const newObj = {
      ...discountVoucher['discountVoucher'],
      [name]: value,
    };
    console.log('newObj', newObj);
    handleChange('discountVoucher', newObj);
  };
  const onSubmit = async () => {
    const findVendor = await GIFT_VOUCHER.find(
      (voucher: VegaSelectSelectOptionType) =>
        voucher.id === discountVoucher.vendorId
    );

    Object.assign(discountVoucher, {
      programId: selectedProgram?.programId,
      vendorName: findVendor?.name,
    });
    await onVoucherSubmit(discountVoucher);
    handleClose();
  };

  const isInputValid = () => {
    const isNumberValid = (discountVoucher.discountVoucher?.number ?? 0) > 0;
    const isValueTypeValid =
      (discountVoucher.discountVoucher?.valueType ?? '').length > 0;
    const isQuantityValid = discountVoucher.quantity > 0;
    return isNumberValid && isValueTypeValid && isQuantityValid;
  };

  return (
    <VegaPage>
      <VendorList />
      <VegaCard noPad sx={{ boxShadow: 'none' }}>
        <AddCoupons
          buttonText="Add Discount Coupon"
          onClick={() => setOpen(!open)}
        />
        {data.length ? (
          <ListOfDiscountVoucher
            data={data}
            updateRenewInventory={updateRenewInventory}
          />
        ) : (
          <NoVoucherAndRewards text="No Discount Voucher" />
        )}
      </VegaCard>
      <VegaModal
        header="New Discount Voucher"
        submitText="Add Discount Coupon"
        handleSubmit={onSubmit}
        buttonIcon={<AddCircleOutline />}
        open={open}
        handleClose={handleClose}
        switchText="Auto Renew"
        disabled={isInputValid() == false}
        switchComponent={
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            <Typography sx={{ fontSize: 12, color: GREY.dark, mr: 1 }}>
              Auto Renew
            </Typography>
            <AntSwitch
              checked={discountVoucher.autoRenew}
              onChange={(e) => handleChange('autoRenew', e.target.checked)}
            />
          </Box>
        }
      >
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <VegaSelect
                isFixedHeight
                options={GIFT_VOUCHER}
                key="vendorId"
                selected={discountVoucher.vendorId}
                placeholder="Select Vendor"
                onChange={(value) => handleChange('vendorId', value)}
                headerText="select vendor"
              />
            </Grid>
            <Grid item sm={12}>
              <VegaHeader text="Select plan" />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VegaTextField
                    isFixedHeight
                    value={discountVoucher.discountVoucher?.number ?? 0}
                    onChange={(e) =>
                      handleAmountChange(
                        'number',
                        e.target.value && parseInt(e.target.value)
                      )
                    }
                    key="number"
                    type={'number'}
                    placeholder="Enter Amount"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VegaSelect
                    isFixedHeight
                    options={['PERCENTAGE', 'AMOUNT']}
                    key="valueType"
                    selected={discountVoucher.discountVoucher?.valueType ?? ''}
                    placeholder="Select Plan"
                    onChange={(value) => handleAmountChange('valueType', value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <VegaTextField
                isFixedHeight
                value={discountVoucher.quantity}
                onChange={(e) =>
                  handleChange(
                    'quantity',
                    e.target.value && parseInt(e.target.value)
                  )
                }
                key="quantity"
                type={'number'}
                placeholder="Enter Quantity"
                headerText="Enter Quantity"
              />
            </Grid>
          </Grid>
          {/* <NewCampaign state={state} handleChange={handleChange} /> */}
          {/* <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
            <VegaOutlineButton text="Cancle" isDanger onClick={handleClose} />
            <VegaContainedButton
              text={'Add Discount Coupon'}
              startIcon={<AddCircleOutline />}
              isPurple
              onClick={onSubmit}
            />
          </Box> */}
        </Box>
      </VegaModal>
    </VegaPage>
  );
};

export default DiscountVoucher;
