import { Box, Typography } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { UploadBulkIcon } from '../../../components/icon/Icons';
import { BLUE, GREEN, GREY } from '../../../constants/style';
import CIMS_Button from './CIMS_Button';
import { CIMS_PRIMARY } from '../../assets/style';
import {
  FileValidatorBuilder,
  FileExtension,
  FileValidatorResult,
} from '../../../utils/FileValidator';

interface IProps {
  selectedFile: File | null;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error: string;
  uploadProgress: number;
  loading?: boolean;
  onFileSelectError: (error: string | null) => void;
}
const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([
    FileExtension.EXCEL,
    FileExtension.CSV,
    FileExtension.SVG,
  ])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const CIMS_BulkUpload = ({
  selectedFile,
  handleFileChange,
  error,
  uploadProgress,
  loading,
  onFileSelectError,
}: IProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const sizeMb = selectedFile && uploadProgress * selectedFile?.size;
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (!fileValidatorConfig) {
      handleFileChange(event);
      return;
    }

    if (selectedFile) {
      const validationResult: FileValidatorResult =
        await fileValidatorConfig.validateFile(selectedFile);
      console.log('ValidationResult', validationResult);
      if (validationResult.isValid) {
        handleFileChange(event);
      } else {
        onFileSelectError?.(validationResult.errorMessage);
      }
    }
  };

  return (
    <>
      <Box my={2} sx={{}}>
        <label
          style={{
            border:
              !loading && selectedFile
                ? `2px dashed ${GREEN.dark}`
                : `2px dashed ${BLUE.lighter}`,
            borderRadius: '8px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60px',
          }}
          htmlFor="bulkUpload"
        >
          <Box>
            {loading && uploadProgress && uploadProgress > 0 ? (
              <Box>
                <Typography
                  className="font-aspekta-400"
                  fontSize={12}
                  mb={1}
                  sx={{ color: CIMS_PRIMARY.BLUE, textAlign: 'center' }}
                >
                  File is being uploaded...
                </Typography>
                <Box
                  sx={{
                    width: '200px',
                    height: '8px',
                    borderRadius: '20px',
                    backgroundColor: BLUE.light, // Use your desired color here.
                    marginTop: '5px',
                    overflow: 'hidden',
                    mx: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: `${uploadProgress}%`,
                      height: '100%',
                      borderRadius: '20px',
                      backgroundColor: CIMS_PRIMARY.BLUE, // Use your desired color here.
                    }}
                  />
                </Box>
              </Box>
            ) : (
              ''
            )}
            {!loading && selectedFile ? (
              <Box>
                <Typography
                  className="font-aspekta-400"
                  fontSize={12}
                  sx={{ color: GREEN.dark, textAlign: 'center' }}
                >
                  {selectedFile.name} Upload Successfully
                </Typography>
                <Typography
                  className="font-aspekta-400"
                  fontSize={12}
                  sx={{
                    color: GREEN.dark,
                    textAlign: 'center',
                  }}
                >
                  file size : {sizeMb && (sizeMb / 1024 / 1024).toFixed(2)} mb
                </Typography>
              </Box>
            ) : (
              !loading && (
                <CIMS_Button
                  variant="text"
                  text="Upload File"
                  onClick={handleButtonClick}
                  startIcon={<UploadBulkIcon strokeColor={BLUE.lighter} />}
                />
              )
            )}
          </Box>
        </label>
        <input
          type="file"
          id="bulkUpload"
          hidden
          ref={fileInputRef}
          // accept=".csv, .xlsx"
          onChange={handleFileSelect}
        />
      </Box>
      <Box>
        {error.length ? (
          <Typography
            className="font-aspekta-600"
            fontSize={10}
            color={'red'}
            textAlign={'center'}
          >
            {error}
          </Typography>
        ) : (
          ''
        )}
        <Typography
          textAlign={'center'}
          className="font-aspekta-600"
          fontSize={10}
          color={'#7B8694'}
        >
          You can upload files in xlsx and csv format
        </Typography>
        {/* {bulkUploadData.totalEntries !== null && (
          <Box textAlign={'center'}>
            <VegaAspektaText
              text={`${bulkUploadData.invalidEntriesCount}/${bulkUploadData.totalEntries} entries are  in invalid format`}
              fontSize={14}
              fontWeight={'500'}
              color="red"
            />
          </Box>
        )} */}
      </Box>
    </>
  );
};

export default CIMS_BulkUpload;
