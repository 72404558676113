/** @format */

import { Box, Grid } from '@mui/material';
import { useClientAuth } from '../../providers/ClientProvider';
import { ChargeInterface } from '../../types/policy';
import { VegaTextField } from '../common';
import VegaSelect from '../common/VegaSelect';
import ColumnsInputGreyHeader from './ColumnsInputGreyHeader';

type Props = {
  code?: string;
  charge: ChargeInterface;
  onChange: (value: ChargeInterface) => void;
  isDisabled?: boolean;
};

const COL_WIDTHS = { xs: [3, 3, 3, 3], sm: [3, 3, 3, 3] };
const ONLY_AMOUNT_TYPE_FEES = [
  'ANNUAL_MEMBERSHIP_FEE',
  'CARD_VALIDATION_FEE',
  'ADDITIONAL_CARD_FEE',
  'REISSUE_CARD_FEE',
];

const OneTimeChargeSelector = ({
  charge,
  onChange,
  isDisabled,
  code,
}: Props) => {
  const handleChange = (key: string, value: string | number) => {
    onChange({ ...charge, [key]: value });
  };

  return (
    <Box>
      <ColumnsInputGreyHeader
        headerTexts={
          charge.value_type === 'PERCENTAGE'
            ? ['Value Type', 'Value', 'Min', 'Max']
            : ['Value Type', 'Value']
        }
        columnWidths={COL_WIDTHS}
      />
      <Grid container>
        <Grid item xs={3}>
          <VegaSelect
            disabled={isDisabled}
            selected={charge.value_type as string}
            onChange={(e) => handleChange('value_type', e)}
            key={`non-slab-charge-value_type${charge.value_type}`}
            size="small"
            options={
              code && ONLY_AMOUNT_TYPE_FEES.includes(code)
                ? ['AMOUNT']
                : ['PERCENTAGE', 'AMOUNT']
            }
            textFieldWidth={150}
          />
        </Grid>
        <Grid item xs={3}>
          <VegaTextField
            value={charge.value as number}
            onChange={(e) =>
              handleChange(
                'value',
                e.target.value && parseFloat(e.target.value)
              )
            }
            type="number"
            // key={`non-slab-charge.value${charge.value}`}
            key={`value`}
            size="small"
            textFieldWidth={100}
            disabled={isDisabled}
          />
        </Grid>
        {charge.value_type === 'PERCENTAGE' && (
          <>
            <Grid item xs={3}>
              <VegaTextField
                value={charge.min as number}
                onChange={(e) =>
                  handleChange(
                    'min',
                    e.target.value && parseFloat(e.target.value)
                  )
                }
                type="number"
                // key={`non-slab-charge.value${charge.value}`}
                key={`min`}
                size="small"
                textFieldWidth={100}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <VegaTextField
                value={charge.max as number}
                onChange={(e) =>
                  handleChange(
                    'max',
                    e.target.value && parseFloat(e.target.value)
                  )
                }
                type="number"
                // key={`non-slab-charge.value${charge.value}`}
                key={`max`}
                size="small"
                textFieldWidth={100}
                disabled={isDisabled}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default OneTimeChargeSelector;
