/** @format */

import { AdminPanelSettings, FolderShared } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import { Program_manage, ServiceIcon } from '../components/icon/Icons';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import MCCAplicationManagement from '../mcc/Back-office/pages/MCCAplicationManagement';
import CrmProgram from '../pages/CrmProgram';

import MCCCorporateManagement from '../mcc/Back-office/pages/MCCCorporateManagement';
import MCCInventoryManagement from '../mcc/Back-office/pages/MCCInventoryManagement';
import MCCReleaseTransaction from '../mcc/Back-office/pages/MCCReleaseTransaction';
import MCCServices from '../mcc/Back-office/pages/MCCServices';
import MCCTeamManagement from '../mcc/Back-office/pages/MCCTeamManagement';
import MCCUserManagement from '../mcc/Back-office/pages/MCCUserManagement';
import MCCcardSaleMaker from '../mcc/Back-office/pages/MCCcardSaleMaker';
import MCCcardTransaction from '../mcc/Back-office/pages/MCCcardTransaction';
import Policy from '../pages/Policy';
import { useClientAuth } from '../providers/ClientProvider';
import { MCC_ROUTES, ROUTES } from '../routes';
import MCCcorporateTransaction from '../mcc/Back-office/pages/MCCcorporateTransaction';
import MCCAnalytics from '../mcc/Back-office/pages/MCCAnalytics';
import MCCReport from '../mcc/Back-office/pages/MCCReport';

type Props = {};

const NON_ADMIN = [
  {
    id: ROUTES.PROGRAM,
    icon: <FolderShared />,
    label: 'Programs',
    component: <CrmProgram />,
    hideForAdmin: true,
  },
  {
    id: ROUTES.POLICY,
    icon: <AdminPanelSettings />,
    label: 'Policy',
    component: <Policy />,
    hideForAdmin: true,
  },
];

const MCCBackOfficeContainer = (props: Props) => {
  const { isAdmin, mccPlatformId } = useClientAuth();
  
  const NAV_OPTIONS: SidebarListItemType[] = [
    {
      id: MCC_ROUTES.ANALYTICS,
      icon: <Program_manage />,
      label: 'Analytics',
      component: <MCCAnalytics />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.REPORT,
      icon: <Program_manage />,
      label: 'Report',
      component: <MCCReport authId={mccPlatformId} platform='BRANCH' />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.TEAM_MANAGEMENT,
      icon: <Program_manage />,
      label: 'Team Management',
      component: <MCCTeamManagement />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },

    {
      id: MCC_ROUTES.APPLICATION_MANAGEMENT,
      icon: <Program_manage />,
      label: 'Application Management',
      component: <MCCAplicationManagement />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.CORPORATE_MANAGEMENT,
      icon: <Program_manage />,
      label: 'Corporate Management',
      component: <MCCCorporateManagement />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.CORPORATE_TRANSACTION,
      icon: <Program_manage />,
      label: 'Corporate Transaction',
      component: <MCCcorporateTransaction />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.USER_MANAGEMENT,
      icon: <Program_manage />,
      label: 'User Management',
      component: <MCCUserManagement />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },

    {
      id: MCC_ROUTES.CARD_MAKER,
      icon: <ServiceIcon />,
      label: 'Card Sale',
      component: <MCCcardSaleMaker />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.CARD_TRANSACTION,
      icon: <ServiceIcon />,
      label: 'Card Transaction',
      component: <MCCcardTransaction />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.INVENTORY_MANAGEMENT,
      icon: <ServiceIcon />,
      label: 'Inventory Management',
      component: <MCCInventoryManagement />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.RELEASE_TRANSACTION,
      icon: <ServiceIcon />,
      label: 'Release Transaction',
      component: <MCCReleaseTransaction />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
    {
      id: MCC_ROUTES.SERVICES,
      icon: <ServiceIcon />,
      label: 'Services',
      component: <MCCServices />,
      isProgramSelector: true,
      isAccountSelector: false,
      isCrmRoutes: true,
    },
  ];
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  );

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={
          isAdmin ? NAV_OPTIONS : [...NON_ADMIN, ...NAV_OPTIONS]
        }
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default MCCBackOfficeContainer;
