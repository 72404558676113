import React, { useState, useEffect } from 'react';
import { VegaCard, VegaPage, VegaTable } from '../../../components/common';
import { Box, Grid, Stack, Typography } from '@mui/material';
import StartAndEndDateFilter, {
  IStartAndEndDateFilter,
} from '../../components/filter-options/StartAndEndDateFilter';
import VegaOutlineButton from '../../../components/common/VegaOutlineButton';
import { GridColDef } from '@mui/x-data-grid';
import { VegaToolTipAndCopy } from '../../../components/common/VegaToolTipNCopy';
import VegaContainedButton from '../../../components/common/VegaContainedButton';

import MonthWiseInvoices from '../components/CorporateInvoices/MonthWiseInvoices';
import ListOfCorporateTransaction from '../../Back-office/components/MCCcorporateTransaction/ListOfCorporateTransaction';
import { InvoiceListPostPaidCardProps } from '../../types/CorporateTransaction';
import { fetchInvoiceListForPostpaidCard } from '../../Back-office/api/open-credit';
import { useClientAuth } from '../../../providers/ClientProvider';
import ListOfCorporateInvoice from '../components/CorporateInvoice/ListOfCorporateInvoice';

interface IInvoiceFilterData {
  startDate?: string;
  endDate?: string;
  startLable?: string;
  endLable?: string;
}

interface ISummaryProps {
  count: string | number;
  summaryType: string;
  border: string;
}

const POSTPAID = [
  {
    count: '50,000',
    summaryType: 'Total Due',
    border: '#E8505B',
  },
  {
    count: '50',
    summaryType: 'Total Invoices',
    border: '#9EBFFF',
  },
  {
    count: '20',
    summaryType: 'Total Invoices overdue',
    border: '#E8505B',
  },
  {
    count: '10,000',
    summaryType: 'Charges',
    border: '#E8505B',
  },
] as ISummaryProps[];

function CorporateInvoices() {
  const { mccPlatformId } = useClientAuth();
  const [invoiceList, setInvoiceList] = useState<
    InvoiceListPostPaidCardProps[]
  >([]);

  const getInvoicesList = async () => {
    // commented for now
    const data = {
      accountId: mccPlatformId,
    };

    fetchInvoiceListForPostpaidCard(data).then((res) => {
      const filterData = res.data;
      setInvoiceList(res.data);
      // setCorporates(filterData);
    });
  };

  useEffect(() => {
    mccPlatformId && getInvoicesList();
  }, []);

  return (
    // <Stack spacing={4}>
    //   <VegaCard noPad>
    //     {/* Filter options */}
    //     <Stack p={3}>
    //       <Typography>Vegapay Invoices</Typography>
    //       <Grid container xs={12} mt={2}>
    //         <Grid container xs={12} sm={8}>
    //           <StartAndEndDateFilter
    //             {...(invoiceFilterData as IStartAndEndDateFilter)}
    //             handleChange={handleChange}
    //           />
    //         </Grid>
    //         <Grid
    //           container
    //           xs={12}
    //           sm={4}
    //           justifyContent={'flex-end'}
    //           alignItems={'flex-end'}
    //         >
    //           <Box>
    //             <VegaOutlineButton text={'Search'} isPrimary />
    //           </Box>
    //         </Grid>
    //       </Grid>
    //     </Stack>

    //     {/* ---------------------- INVOICE LIST ---------------------- */}
    //     <Box>
    //       <VegaTable
    //         data={DUMMY_INVOICES}
    //         columnDef={columnDef}
    //         idColumn="id"
    //       />
    //     </Box>
    //     {/* ---------------------- INVOICES ---------------------- */}
    //   </VegaCard>
    //   <MonthWiseInvoices />
    // </Stack>
    <VegaPage>
      <Grid container spacing={3}>
        {POSTPAID.map((summary: ISummaryProps) => (
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box
              sx={{
                bgcolor: 'white',
                p: 3,
                borderRadius: '20px',
                borderLeft: `20px solid ${summary.border}`,
              }}
            >
              <Typography>{summary.summaryType}</Typography>
              <Typography fontSize={30} fontWeight={'bold'} sx={{}}>
                {summary.count}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <ListOfCorporateInvoice invoiceList={invoiceList} />
    </VegaPage>
  );
}

export default CorporateInvoices;

// const columnDef: GridColDef[] = [
//   {
//     field: 'id',
//     renderHeader: () => (
//       <Typography variant="button" fontWeight={'bold'}>
//         Invoice ID
//       </Typography>
//     ),
//     flex: 1,
//     renderCell: (props) => <VegaToolTipAndCopy value={props.row.id} />,
//   },
//   {
//     field: 'date',
//     renderHeader: () => (
//       <Typography variant="button" fontWeight={'bold'}>
//         Date
//       </Typography>
//     ),
//     flex: 1,
//     renderCell: (props) => <VegaToolTipAndCopy value={props.row.date} />,
//   },

//   {
//     field: 'action',
//     renderHeader: () => (
//       <Typography className="font-aspekta-500" variant="body2">
//         Action
//       </Typography>
//     ),
//     renderCell: (props: any) => {
//       return (
//         <VegaContainedButton text="Download" onClick={() => {}} isPrimary />
//       );
//     },
//     flex: 1,
//   },
// ];

// const DUMMY_INVOICES = [
//   {
//     id: 'ABCD1234',
//     date: '23 March 2023',
//   },
//   {
//     id: 'nksdjfnskj223',
//     date: '12 Apr 2020',
//   },
//   {
//     id: 'sdfsdf344r34',
//     date: '05 Jan 2021',
//   },
//   {
//     id: 'hhdsff3443r4',
//     date: '31 Nov 2023',
//   },
// ];
