import { Box } from '@mui/material';
import { SidebarListItemType } from '../../components/layouts/CIMSSidebarLayout';
import {
  CIMS_ADMIN_SUB_MODULES,
  CIMS_BC_SUB_MODULES,
  CIMS_BRANCH_SUB_MODULES,
  CIMS_VENDOR_SUB_MODULES,
} from '../../routes';

import {
  AssignCardIcon,
  BulkRejectCardIcon,
  CardUpIcon,
  EntityManagementIcon,
  MultipleCardsIcon,
  ReportIcon,
  TeamManagementIcon,
} from '../../cims/components/icon/CIMS_Icons';
import {
  AddCardInventoryIcon,
  CIMS_HomeIcon,
  CardInventoryIcon,
  CardMasterIcon,
  PoliciesIcon,
  UpdateCardInventoryIcon,
} from '../../cims/components/icon/CIMS_SidebarIcons';

import {
  AssignToEntity,
  BulkRejectCard,
  CIMS_Home,
  CardAlloment,
  CardInventory,
  CardOrderRequest,
  CardRejectionStatus,
  Card_Master,
  DeliveryOrderRequests,
  DivisionManagement,
  Policies,
  Update_Card_Inventory,
} from '../../cims/pages';
import CimsReport from '../../cims/pages/Reports/CimsReport';
import CimsReportProvider from '../../cims/provider/CimsReportProvider';
import MCCTeamManagement from '../../mcc/Back-office/pages/MCCTeamManagement';
import { CardIndentationOrder } from '../../cims/pages/CardIndentationOrder';
import { CIMS_RESOURCE_MODULES } from '../../constants/modules';

const iconStyle = {
  width: 16,
  height: 16,
  color: 'white',
};
const activeIconStyle = {
  ...iconStyle,
  color: '#C8A864',
};

export const CIMS_SUB_MODULE_ROUTES: SidebarListItemType[] = [
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_HOME,
    icon: <CIMS_HomeIcon />,
    activIcon: <CIMS_HomeIcon color="#C8A864" />,
    label: 'Home',
    component: <CIMS_Home />,
    resourceName: CIMS_RESOURCE_MODULES.HOME,
  },
  {
    id: CIMS_BRANCH_SUB_MODULES.CIMS_BRANCH_CARD_ALLOTMENT,
    icon: <AssignCardIcon {...iconStyle} />,
    activIcon: <AssignCardIcon {...activeIconStyle} />,
    label: 'Card Allotment',
    component: <CardAlloment />,
    resourceName: CIMS_RESOURCE_MODULES.CARD_ALLOTMENT,
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_UPDATE_CARD_INVENTORY,
    icon: <UpdateCardInventoryIcon />,
    activIcon: <UpdateCardInventoryIcon color="#C8A864" />,
    label: 'Update Card Inventory',
    component: <Update_Card_Inventory />,
    resourceName: CIMS_RESOURCE_MODULES.UPDATE_CARD_INVENTORY,
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_BULK_CARD_REJECT,
    icon: <BulkRejectCardIcon {...iconStyle} />,
    activIcon: <BulkRejectCardIcon {...activeIconStyle} />,
    label: 'Bulk Reject Card',
    // description: 'Coming Soon',
    component: <BulkRejectCard />,
    resourceName: CIMS_RESOURCE_MODULES.BULK_REJECT_CARD,
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_CARD_INVENTORY,
    icon: <CardInventoryIcon />,
    activIcon: <CardInventoryIcon color="#C8A864" />,
    label: 'Card Inventory',
    component: <CardInventory />,
    resourceName: CIMS_RESOURCE_MODULES.CARD_INVENTORY,
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_ASSIGN_CARDS_TO_ENTITY,
    icon: <EntityManagementIcon {...iconStyle} />,
    activIcon: <EntityManagementIcon {...activeIconStyle} />,
    label: 'Assign Cards ',
    component: <AssignToEntity />,
    resourceName: CIMS_RESOURCE_MODULES.ASSIGN_CARDS,
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_MASTERS,
    icon: <CardMasterIcon />,
    activIcon: <CardMasterIcon color="#C8A864" />,
    label: 'Masters',
    children: [
      {
        id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_CARD_MASTER,
        label: 'Card',
        component: <Card_Master />,
        resourceName: CIMS_RESOURCE_MODULES.CARD,
      },
      {
        id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_CARD_REJECTION_STATUS,
        label: 'Card Rejection Status',
        component: <CardRejectionStatus />,
        resourceName: CIMS_RESOURCE_MODULES.CARD_REJECTION_STATUS,
      },
    ],
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_CARD_ORDER_REQUEST,
    icon: <AddCardInventoryIcon {...iconStyle} />,
    activIcon: <AddCardInventoryIcon {...activeIconStyle} />,
    label: 'Card Order Request',
    component: <CardOrderRequest />,
    resourceName: CIMS_RESOURCE_MODULES.CARD_ORDER_REQUEST,

    // description: '(Coming soon)',
  },

  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_CARD_INDENTATION_REQUEST,
    icon: <AddCardInventoryIcon {...iconStyle} />,
    activIcon: <AddCardInventoryIcon {...activeIconStyle} />,
    label: 'Card Indentation Order',
    component: <CardIndentationOrder />,
    resourceName: CIMS_RESOURCE_MODULES.CARD_INDENTATION_ORDER,
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_TEAM_MANAGEMENT,
    icon: <TeamManagementIcon {...iconStyle} />,
    activIcon: <TeamManagementIcon {...activeIconStyle} />,
    label: 'Team Management',
    component: (
      <Box
        sx={{
          padding: 4,
        }}
      >
        <MCCTeamManagement isCIMS />,
      </Box>
    ),
    resourceName: CIMS_RESOURCE_MODULES.TEAM_MANAGEMENT,
  },

  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_REPORTS,
    icon: <ReportIcon {...iconStyle} />,
    activIcon: <ReportIcon {...activeIconStyle} />,
    label: 'Reports',
    // description: 'Coming Soon',
    component: (
      <Box
        sx={
          {
            // padding: 4,
          }
        }
      >
        <CimsReportProvider>
          <CimsReport />
        </CimsReportProvider>
      </Box>
    ),
    resourceName: CIMS_RESOURCE_MODULES.REPORTS,
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_POLICY,
    icon: <PoliciesIcon />,
    activIcon: <PoliciesIcon color="#C8A864" />,
    label: 'Policies',
    component: <Policies />,
    resourceName: CIMS_RESOURCE_MODULES.POLICIES,
  },

  // VENDOR
  {
    id: CIMS_VENDOR_SUB_MODULES.CIMS_VENDOR_DELIVERY_ORDER_REQUESTS,
    icon: <CardUpIcon {...iconStyle} />,
    activIcon: <CardUpIcon {...activeIconStyle} />,
    label: 'Delivery Order Requests',
    component: <DeliveryOrderRequests />,
    resourceName: CIMS_RESOURCE_MODULES.DELIVERY_ORDER_REQUEST,
  },
  {
    id: CIMS_ADMIN_SUB_MODULES.CIMS_ADMIN_DIVISION_MANAGEMENT,
    icon: <EntityManagementIcon {...iconStyle} />,
    activIcon: <EntityManagementIcon {...activeIconStyle} />,
    label: 'Division Management',
    component: <DivisionManagement />,
    resourceName: CIMS_RESOURCE_MODULES.ENTITY_MANAGEMENT,
  },
];
