import { Box } from '@mui/material';
import { CardType, useCardConfig } from '../../providers/CardConfigProvider';
import { useProgramData } from '../../providers/ProgramDataProvider';
import { VegaProgramNetworkType } from '../../types/program';
import VegaImageSelector from '../common/v2/ImageSelector/VegaImageSelector';
import VegaSelectV2 from '../common/v2/VegaSelectV2';

type Props = {
  cardType: CardType;
};

function CardNetworkLogoSelector({ cardType }: Props) {
  const { onNetworkLogoChange, physicalCardConfig, virtualCardConfig } =
    useCardConfig();
  const { selectedProgram } = useProgramData();

  const getNetworkLogo = () => {
    return cardType == CardType.PHYSICAL
      ? physicalCardConfig.networkLogo
      : virtualCardConfig.networkLogo;
  };

  const getProgramNetworkLogos = () => {
    const programNetwork = selectedProgram?.network;
    if (!programNetwork) return [];
    switch (programNetwork) {
      case VegaProgramNetworkType.MASTER_CARD:
        return [
          'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/mastercard_dark.png',
          'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/mastercard_light.png',
        ];
      case VegaProgramNetworkType.RUPAY:
        return [
          'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/rupay_dark.png',
          'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/rupay_light.png',
        ];
      case VegaProgramNetworkType.VISA:
        return [
          'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/Group+28749.png',
          'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/card_resources/Group+28750.png',
        ];
    }
    return [];
  };

  return (
    <VegaSelectV2
      placeholder="Upload Network Logo"
      renderOptions={() => (
        <VegaImageSelector
          title={'Network Logo'}
          images={getProgramNetworkLogos()}
          onAddClick={function (): void {}}
          onImageSelect={function (value: string): void {
            onNetworkLogoChange(value, cardType);
          }}
          selectedImage={getNetworkLogo()}
        />
      )}
      hidePlaceholder={Boolean(getNetworkLogo())}
      renderSelectedValue={() => {
        return (
          <Box
            style={{
              height: '20px',
              maxWidth: '80px',
              backgroundColor: '#f1f1f1f1',
              padding: 0,
              margin: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{ objectFit: 'contain' }}
              height={'80%'}
              width={'80%'}
              src={getNetworkLogo()}
            ></img>
          </Box>
        );
      }}
    />
  );
}

export default CardNetworkLogoSelector;
