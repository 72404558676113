import { Stack, TextField } from '@mui/material';
import Button from '../../primitives/Button';
import Dialog from '../../primitives/Dialog';
import { useState } from 'react';
import {
  ListGroupHeadingTypo,
  SectionHeadingTypo,
} from '../../common/Typography';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  cancelCloseAccountRequestAPI,
  closeAccountAPI,
  closeAccountRequestDetailsAPI,
} from '../../../api/accountClosureAPIs';
import { useClientAuth } from '../../../providers/ClientProvider';

interface IAccountClosureActionBtnsProps {
  isIssuePresent?: boolean;
}

enum CloseAccReqFormData {
  REASON = 'reason',
  REMARKS = 'remarks',
}

type FormValues = {
  remarks: string;
  reason: string;
};

const AccountClosureActionBtns = ({
  isIssuePresent,
}: IAccountClosureActionBtnsProps) => {
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [openCancelReqDialog, setOpenCancelReqDialog] = useState(false);
  const { user } = useClientAuth();
  const [openApproveReqDialog, setOpenApproveReqDialog] = useState(false);
  const { reqId } = useAppSelector(({ accountClosure }) => ({
    reqId: accountClosure.accountClosureRequestDetails.payload?.id,
  }));

  const setCancelReqDialogView = (open: boolean) => () => {
    setOpenCancelReqDialog(open);
  };

  const setApproveReqDialogView = (open: boolean) => () => {
    setOpenApproveReqDialog(open);
  };

  const onSubmitCancelReq = () => {
    const { reason, remarks } = getValues();
    if (!reqId || !reason || !user?.email) return;

    dispatch(
      cancelCloseAccountRequestAPI({
        payload: {
          updatedBy: user.email,
          id: reqId,
          cancelReason: reason,
          cancelRemarks: remarks,
        },
        postSuccessFunc: () => {
          setOpenCancelReqDialog(false);
          dispatch(
            closeAccountRequestDetailsAPI({
              payload: {
                id: reqId,
              },
            })
          );
        },
        setSnackbar,
      })
    );
  };

  const submitCloseAcc = () => {
    if (!reqId || !user?.email) return;

    dispatch(
      closeAccountAPI({
        payload: {
          updatedBy: user.email,
          id: reqId,
        },
        postSuccessFunc: () => {
          setOpenApproveReqDialog(false);
          dispatch(
            closeAccountRequestDetailsAPI({
              payload: {
                id: reqId,
              },
            })
          );
        },
        setSnackbar,
      })
    );
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm<FormValues>();

  return (
    <Stack p={3} sx={{ borderTop: '1px solid #EEE' }}>
      <Stack direction="row" justifyContent="flex-end">
        <Button onClick={setCancelReqDialogView(true)}>Cancel Request</Button>
        <Button
          onClick={setApproveReqDialogView(true)}
          variant="contained"
          disabled={isIssuePresent}
        >
          Close Account
        </Button>
      </Stack>
      <Dialog
        open={openCancelReqDialog}
        title="Cancel Account Closure Request"
        onClose={setCancelReqDialogView(false)}
        actionBtnSecondaryText={'Cancel Request'}
        closeBtnRequired
        actionBtnSecondaryProps={{
          onClick: handleSubmit(onSubmitCancelReq),
          disabled: !isValid,
        }}
      >
        <Stack sx={{ padding: '24px 40px 40px' }} gap={3}>
          <Stack gap={1}>
            <ListGroupHeadingTypo>Reason</ListGroupHeadingTypo>
            <TextField
              {...register(CloseAccReqFormData.REASON, { required: true })}
              id="outlined-textarea"
              placeholder="Add Reason"
              multiline
              sx={{
                width: '550px',
                '.MuiInputBase-input': {
                  '&::placeholder': {
                    fontSize: '13px',
                    fontWeight: 450,
                    fontFamily: 'Aspekta',
                  },
                },
              }}
            />
          </Stack>
          <Stack gap={1}>
            <ListGroupHeadingTypo>Remarks (Optional)</ListGroupHeadingTypo>
            <TextField
              {...register(CloseAccReqFormData.REMARKS)}
              id="outlined-textarea"
              placeholder="Add Remarks"
              multiline
              rows={3}
              sx={{
                width: '550px',
                '.MuiInputBase-input': {
                  '&::placeholder': {
                    fontSize: '13px',
                    fontWeight: 450,
                    fontFamily: 'Aspekta',
                  },
                },
              }}
            />
          </Stack>
        </Stack>
      </Dialog>
      <Dialog
        open={openApproveReqDialog}
        title="Close Account"
        onClose={setApproveReqDialogView(false)}
        actionBtnSecondaryText={'Yes'}
        actionBtnPrimaryText={'Cancel'}
        closeBtnRequired
        actionBtnSecondaryProps={{
          onClick: submitCloseAcc,
        }}
        actionBtnPrimaryProps={{
          onClick: setApproveReqDialogView(false),
        }}
      >
        <SectionHeadingTypo padding={'20px 40px'}>
          Do you want to go ahead and approve the closure of account?
        </SectionHeadingTypo>
      </Dialog>
    </Stack>
  );
};

export default AccountClosureActionBtns;
