import { Stack } from '@mui/material';
import _ from 'lodash';
import VegaCardV2 from '../../../../../../../../common/v2/VegaCardV2';
import { useCampaignRule } from '../../../../../../../providers/CampaignRuleProvider';
import { useVoucherInventory } from '../../../../../../../providers/VoucherInventoryProvider';
import VoucherLists from './Voucher/VoucherLists';

function NonPointBasedNonTransactionalRewards() {
  const {
    addVoucher,
    updateVoucher,
    deleteLastVoucher,
    giftVouchers,
    subscriptionVouchers,
    discountVouchers,
  } = useCampaignRule();
  const {
    giftVouchers: allGiftVouchers,
    discountVouchers: allDiscountVouchers,
    subscriptionsVouchers: allSubscriptionVouchers,
  } = useVoucherInventory();

  return (
    <Stack>
      <VegaCardV2>
        <VoucherLists
          addVoucher={addVoucher}
          deleteLastVoucher={deleteLastVoucher}
          updateVoucher={updateVoucher}
          giftVouchers={giftVouchers}
          discountVouchers={discountVouchers}
          subscriptionVouchers={subscriptionVouchers}
          allGiftVouchers={allGiftVouchers}
          allDiscountVouchers={allDiscountVouchers}
          allSubscriptionVouchers={allSubscriptionVouchers}
        />
      </VegaCardV2>
    </Stack>
  );
}

export default NonPointBasedNonTransactionalRewards;
