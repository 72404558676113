import { Box } from '@mui/material';

import { ToggleOff } from '@mui/icons-material';
import { VegaAccordian } from '../../../../../../components/common';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import { IMarkupFeeComponent } from '../../../../../types/plan';
import FeesWaiverTable from './FeesWaiverTable';

export interface IProps {
  rule: IMarkupFeeComponent | null;
  headerPrefix?: string;
  code: string;
  onActivationClick: (code: string) => void;
  // isDisabled?: boolean;
  handleChange: (code: string, key: string, value: any) => void;
}

const MarkupFeeCard = ({
  rule,
  code,
  onActivationClick,
  handleChange,
}: IProps) => {
  return (
    <Box sx={{ my: 2 }}>
      <VegaAccordian
        expandIcon={
          <ToggleOff fontSize="large" color={!!rule ? 'success' : 'disabled'} />
        }
        expanded={!!rule}
        onChange={() => onActivationClick(code)}
        header={toLowerCase(code)}
        noPad
        content={
          rule && (
            <>
              <FeesWaiverTable
                rule={rule}
                code={code}
                handleChange={(key, value) => handleChange(code, key, value)}
              />
            </>
          )
        }
      />
    </Box>
  );
};

export default MarkupFeeCard;
