import React, { useEffect, useState } from 'react';
import { useClientAuth } from '../../providers/ClientProvider';
import { fetchMCCProgramList } from '../Program-Dashboard/api/Program';
import VegaSelect from '../../components/common/VegaSelect';

interface IProps {
  selected: any;
  handleChange: (value: any) => void;
  name: string;
}

const ProgramSelect = ({ name, selected, handleChange }: IProps) => {
  const { clientId } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [programsList, setProgram] = useState<any[]>([]);

  const loadMoreItems = (event: React.UIEvent<HTMLDivElement>) => {
    if (
      event.currentTarget.scrollTop + event.currentTarget.clientHeight ===
      event.currentTarget.scrollHeight
    ) {
      if (totalItem !== programsList.length) {
        setPage(page + 1);
      }
    }
  };

  const getProgramList = async () => {
    fetchMCCProgramList(clientId as string, page, 10).then((res) => {
      setProgram([...programsList, ...res.data.records]);
      setTotalItem(res.data.totalItems);
    });
  };

  useEffect(() => {
    getProgramList();
  }, [page]);

  return (
    <>
      <VegaSelect
        placeholder="Program Name"
        label="Program Name"
        size="small"
        key={name}
        selected={selected ?? ''}
        onChange={(value) => handleChange(value)}
        options={programsList.map((program: any) => ({
          id: program.programId,
          name: program.name,
        }))}
        onScroll={loadMoreItems}
      />
    </>
  );
};

export default ProgramSelect;
