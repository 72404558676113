/** @format */

import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { createRepaymentForAccount, createTransactionForAccount, listTransactionsForAccount } from '../api/transaction';
import { Loading, NoResults, VegaPage } from '../components/common';
import VegaSearchBy from '../components/common/VegaSearchBy';
import CreateTransaction from '../components/transactions/CreateTransaction';
import ListOfTransaction from '../components/transactions/ListOfTransaction';
import Repayment from '../components/transactions/Repayment';
import { useCustomerAccountData } from '../providers/CustomerDataProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../utils/api';

const INITIAL_TRANSACTION_DATA = {
  code: '',
  time: '',
  amount: '',
  mcc: '',
  merchantLocation: '',
};
const INITIAL_REPAYMENT_DATA = {
  time: '',
  amount: '',
};

enum FilterByType {
  INDICATOR = 'transactionIndicator',
  AMOUNT = 'amount',
  STATUS = 'status',
  MERCHANT_NAME = 'merchantName',
  CATEGORY = 'transactionCategory',
  CODE = 'transactionCode',
}

const FILTER_BY_COLUMNS = [
  {
    text: 'Indicator',
    value: FilterByType.INDICATOR,
  },
  {
    text: 'Amount',
    value: FilterByType.AMOUNT,
  },
  {
    text: 'Status',
    value: FilterByType.STATUS,
  },
  {
    text: 'Merchant',
    value: FilterByType.MERCHANT_NAME,
  },
  {
    text: 'Category',
    value: FilterByType.CATEGORY,
  },
  {
    text: 'Code',
    value: FilterByType.CODE,
  },
];
const DEFAULT_FILTER = {
  search: '',
  searchBy: FilterByType.INDICATOR,
};
const Transactions = () => {
  const { selectedAccount, loading } = useCustomerAccountData();
  const { setSnackbar } = useSnackbar();

  const [transaction, setTransaction] = useState<any>({
    ...INITIAL_TRANSACTION_DATA,
  });
  const [repayment, setRepayment] = useState({ ...INITIAL_REPAYMENT_DATA });
  const [customerTransaction, setCustomerTransaction] = useState<any>([]);
  const [showCustomerData, setShowCustomerData] = useState<any>({});
  const [disputeReason, setDisputeReason] = useState<any>({});
  const [isDispute, setIsDispute] = useState(false);
  const [isLoadingTxn, setIsLoadingTxn] = useState(false);
  const [isLoadingPay, setIsLoadingPay] = useState(false);
  const [filter, setFilter] = useState<any>({ ...DEFAULT_FILTER });

  const handleTransaction = (key: string, value: number | string) => {
    setTransaction({ ...transaction, [key]: value });
  };

  const handleRePayment = (key: string, value: number | string) => {
    setRepayment({ ...repayment, [key]: value });
  };

  function onFilterChange(name: string, value: string) {
    setFilter((preVal: any) => ({
      ...preVal,
      [name]: value,
    }));
  }

  const getFilteredTransactions = () => {
    const query = filter.search;
    if (query.length <= 0) return customerTransaction;
    const filterBy = filter.searchBy;

    return customerTransaction.filter((item: any) => {
      return item[filterBy].toString().toLowerCase().includes(query.toLowerCase());
    });
  };

  const createTransaction = () => {
    setIsLoadingTxn(true);
    createTransactionForAccount(
      selectedAccount?.account.accountId,
      transaction.code,
      transaction.amount,
      transaction.time || undefined,
      transaction.mcc,
      transaction.merchantLocation
    )
      .then((res) => {
        setSnackbar('Transaction created!');
        getListOfTransaction(selectedAccount?.account.accountId);
        setIsLoadingTxn(false);
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        setIsLoadingTxn(false);
      });
  };

  const createRepayment = () => {
    setIsLoadingPay(true);
    createRepaymentForAccount(selectedAccount?.account.accountId, +repayment.amount, repayment.time || undefined)
      .then((res) => {
        setSnackbar('Repayment created!');
        getListOfTransaction(selectedAccount?.account.accountId);
        setIsLoadingPay(false);
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        setIsLoadingPay(false);
      });
  };

  const submitDispute = () => {
    // submitDisputeReason(showCustomerData?.id, disputeReason.reason).then(
    //   (res) => {
    //     console.log('res', res);
    //   }
    // );
    setIsDispute(!isDispute);
  };

  const getListOfTransaction = async (value: any) => {
    await listTransactionsForAccount(value).then((res: any) => {
      setCustomerTransaction(res.data?.records);
    });
  };

  useEffect(() => {
    if (selectedAccount?.account.accountId) {
      getListOfTransaction(selectedAccount?.account.accountId);
    }
  }, [selectedAccount]);

  if (loading) {
    return <Loading />;
  }
  if (!selectedAccount) {
    return <NoResults text="No Account selected" subtext="Create new account?" />;
  }
  return (
    <VegaPage>
      <CreateTransaction
        onChange={handleTransaction}
        onCreate={createTransaction}
        transaction={transaction}
        isLoading={isLoadingTxn}
        setTransaction={setTransaction}
      />
      <Repayment
        onChange={handleRePayment}
        onCreate={createRepayment}
        repayment={repayment}
        isLoading={isLoadingPay}
        setRepayment={setRepayment}
      />
      <Stack>
        <VegaSearchBy
          placeholder="Search"
          data={FILTER_BY_COLUMNS}
          searchByname="searchBy"
          searchName="search"
          key="search"
          state={filter}
          handleChange={onFilterChange}
        />
        <ListOfTransaction
          customerTransaction={getFilteredTransactions()}
          setShowCustomerData={setShowCustomerData}
          setDisputeReason={setDisputeReason}
          submitDispute={submitDispute}
          disputeReason={disputeReason}
          setIsDispute={setIsDispute}
          isDispute={isDispute}
          showCustomerData={showCustomerData}
        />
      </Stack>
    </VegaPage>
  );
};

export default Transactions;
