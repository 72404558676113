/** @format */

import { useState } from 'react';
import VegaPage from '../components/common/VegaPage';
import CreateCustomers from '../components/customers/CreateCustomers';
import ListOfCustomers from '../components/customers/ListOfCustomers';

const Customers = () => {
  const [reload, setReload] = useState<number>(0);
  return (
    <VegaPage>
      <CreateCustomers incrementReload={() => setReload(reload + 1)} />
      <ListOfCustomers reload={reload} />
    </VegaPage>
  );
};

export default Customers;
