import {
  InputAdornment,
  SelectChangeEvent,
  TextFieldProps,
} from '@mui/material';
import { ReactNode } from 'react';
import LosFormInputField from './LosFormInputField';
import LosSelect, { LosSelectOption } from './LosSelect';
import LosTextField from './LosTextField';

type IProps = Partial<Omit<TextFieldProps, 'onChange'>> & {
  title?: string;
  description?: string;
  onChange: (
    value?: string,
    event?:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>
  ) => void;
  options?: LosSelectOption[];
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  placeHolder?: string;
  isLoadingOptions?: boolean;
};

export const EditFormFiled = (data: IProps) => {
  const {
    title,
    description,
    onChange,
    options,
    startAdornment,
    endAdornment,
    placeHolder,
    isLoadingOptions = false,
    ...rest
  } = data;

  if (options && options.length === 0) {
    return (
      <LosSelect
        options={[
          {
            label: isLoadingOptions ? 'Loading...' : 'No Data',
            value: '',
          },
        ]}
        value={description}
        onChange={(e) => {
          onChange(e.target.value as string, e);
        }}
        placeholder={placeHolder}
      />
    );
  }
  const isSelectField = options ? options?.length > 0 : false;

  return (
    <LosFormInputField label={title}>
      {isSelectField && (
        <LosSelect
          options={options}
          value={description}
          onChange={(e) => {
            onChange(e.target.value as string, e);
          }}
          placeholder={placeHolder}
          // disabled
        />
      )}
      {isSelectField === false && (
        <LosTextField
          value={description}
          onChange={(e) => onChange(e.target.value, e)}
          placeholder={placeHolder}
          InputProps={{
            ...(startAdornment && {
              startAdornment: (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
            }),
            ...(endAdornment && {
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }),
          }}
          {...rest}
        />
      )}
    </LosFormInputField>
  );
};
