import { Stack } from '@mui/material';
import { CardType, useCardConfig } from '../../providers/CardConfigProvider';
import ColorSelectorItem from '../common/v2/ColorSelector/ColorSelectorItem';
import VegaColorSelector from '../common/v2/ColorSelector/VegaColorSelector';
import VegaSelectV2 from '../common/v2/VegaSelectV2';

type Props = {
  cardType: CardType;
};
function CardBackgroundColorSelector({ cardType }: Props) {
  const {
    physicalCardConfig,
    virtualCardConfig,
    onBackgroundChange,
    availableCardColors,
    availableCardGradients,
  } = useCardConfig();

  const getBackground = () => {
    const color =
      cardType == CardType.PHYSICAL
        ? physicalCardConfig.background
        : virtualCardConfig.background;
    return color;
  };

  return (
    <VegaSelectV2
      placeholder="Select Background color"
      hidePlaceholder={Boolean(getBackground())}
      renderOptions={() => (
        <VegaColorSelector
          showHexInput
          rows={[
            {
              title: 'Predefined Colors',
              colors: availableCardColors,
            },
            {
              title: 'Predefined Gradients',
              colors: availableCardGradients,
            },
          ]}
          selectedColor={getBackground()}
          onChange={function (selectedColor: string): void {
            onBackgroundChange(selectedColor, cardType);
          }}
        />
      )}
      renderSelectedValue={(selected) => {
        return (
          <Stack direction={'row'} spacing={2} alignItems="center">
            <ColorSelectorItem background={getBackground() ?? ''} />
          </Stack>
        );
      }}
      value={getBackground()}
    />
  );
}

export default CardBackgroundColorSelector;
