import React, { useEffect, useState } from 'react';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import { Grid, Box } from '@mui/material';
import { TextWrapper } from '../../../../components/common/TextWrapper';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { getAccount } from '../../../../../../api/account';
import { AccountDataDto } from '../../../../../../types/account';
import { dateFormat } from '../../../../../../mcc/variable';
import { BillInfo } from '../../Bills/Bills';
import { getLatestBill } from '../../../../../../api/bill';

interface IAccountSummaryState {
  data: AccountDataDto | null;
  loading: boolean;
}
interface IBillState {
  data: BillInfo | null;
  loading: boolean;
}

const AccountSummary = () => {
  const { accountData } = useSupport();
  const [accountSummaryState, setaccountSummaryState] =
    useState<IAccountSummaryState>({
      data: null,
      loading: false,
    });
  const [billState, setBillState] = useState<IBillState>({
    data: null,
    loading: false,
  });
  const getAccountSummry = async (accountId: string) => {
    setaccountSummaryState({ data: null, loading: true });
    try {
      const response = await getAccount(accountId);
      setaccountSummaryState({ data: response.data, loading: false });
    } catch (error) {
      setaccountSummaryState({ data: null, loading: false });
    }
  };
  const getBillByAccountId = async (accountId: string) => {
    setBillState({ data: null, loading: true });
    try {
      const response = await getLatestBill(accountId);
      setBillState({ data: response.data, loading: false });
    } catch (error) {
      setBillState({ data: null, loading: false });
    }
  };
  useEffect(() => {
    if (accountData.accountId) {
      getAccountSummry(accountData.accountId);
      getBillByAccountId(accountData.accountId);
    }
  }, []);

  return (
    <>
      <Vega_Text
        text={'Account Summary'}
        fontWeight={600}
        color={NEW_PRIMARY.BLACK}
        fontSize={17}
      />
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="account activation date"
              value={
                accountSummaryState.data?.activationDate
                  ? dateFormat(accountSummaryState.data?.createdAt)
                  : '--'
              }
              isBorder
              loading={accountSummaryState.loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="account creation date"
              value={dateFormat(accountSummaryState.data?.createdAt)}
              loading={accountSummaryState.loading}
            />
          </Grid>
        </Grid>
      </Box>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="total limit"
              value={accountSummaryState.data?.accountLimit}
              isBorder
              loading={accountSummaryState.loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="available limit"
              value={accountSummaryState.data?.availableLimit}
              isBorder
              loading={accountSummaryState.loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="used limit"
              value={accountSummaryState.data?.creditLimitUsed}
              isBorder
              loading={accountSummaryState.loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="customer id"
              value={accountSummaryState.data?.customerId}
              loading={accountSummaryState.loading}
            />
          </Grid>
        </Grid>
      </Box>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="total due"
              value={accountSummaryState.data?.totalAmountDue}
              isBorder
              loading={accountSummaryState.loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="due now"
              value={billState.data?.dueAmount}
              isBorder
              loading={billState.loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="paid penalty"
              value={billState.data?.paidCharge}
              isBorder
              loading={billState.loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextWrapper
              name="due penalty"
              value={billState.data?.dueCharge}
              loading={billState.loading}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AccountSummary;
