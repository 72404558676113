/** @format */

import { DeleteOutline } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { BLUE, GREY } from '../../constants/style';
import { ChargeInterface, SlabChargesInterface } from '../../types/policy';
import { VegaTextField } from '../common';
import { SLAB_DEFAULT_VALUE_INITIAL } from './ChargesPolicy';
import ColumnsInputGreyHeader from './ColumnsInputGreyHeader';

type Props = {
  charge: ChargeInterface;
  code?: string;
  onChange: (key: keyof ChargeInterface, value: ChargeInterface[keyof ChargeInterface]) => void;
};

const COL_WIDTHS = { xs: [3, 3, 3, 3], sm: [3, 3, 3, 3] };

export const DEFAULT_SLAB_CHARGE: SlabChargesInterface = {
  value_type: 'PERCENTAGE',
  value: 0,
  start_amount: 0,
  end_amount: 100,
};

const ChargesSlabChargeSelector = ({ charge, onChange }: Props) => {
  const slabCharges = charge?.charges ?? [];

  const handleSlabChargesChage = (newSlabCharges: SlabChargesInterface[]) => {
    onChange('charges', newSlabCharges);
  };

  const handleDefaultSlabChargeChange = (
    key: keyof SlabChargesInterface,
    value: SlabChargesInterface[keyof SlabChargesInterface]
  ) => {
    const modifiedDefaultValue = { ...(charge?.default_slab ?? { ...SLAB_DEFAULT_VALUE_INITIAL }), [key]: value };
    onChange('default_slab', modifiedDefaultValue);
  };

  const handleChange = (index: number, key: keyof SlabChargesInterface, value: number | string) => {
    const edited = { ...slabCharges[index], [key]: value };
    const copy = [...slabCharges];
    copy.splice(index, 1, edited);
    handleSlabChargesChage(copy);
  };
  const handleDeleteSlab = (index: number) => {
    const copy = [...slabCharges];
    copy.splice(index, 1);
    handleSlabChargesChage(copy);
  };
  const handleAddSlab = () => {
    const currentEnd = slabCharges[slabCharges.length - 1].end_amount;
    const newRow = {
      ...DEFAULT_SLAB_CHARGE,
      start_amount: currentEnd + 1,
      end_amount: currentEnd + 100,
    };
    handleSlabChargesChage([...slabCharges, newRow]);
  };

  return (
    <Box>
      <ColumnsInputGreyHeader
        headerTexts={['From Amount', 'To Amount', 'Value Type', 'Value']}
        columnWidths={COL_WIDTHS}
      />
      {slabCharges?.map((charge, index) => {
        return (
          <Grid container mb={1} key={`charge-slab-${index}`}>
            <Grid item xs={3}>
              <VegaTextField
                value={charge.start_amount}
                onChange={(e) => handleChange(index, 'start_amount', e.target.value && parseFloat(e.target.value))}
                type="number"
                // key={`slab-${index}start_amount${charge.start_amount}`}
                key={`start_amount`}
                size="small"
                textFieldWidth={120}
              />
            </Grid>
            <Grid item xs={3}>
              <VegaTextField
                value={charge.end_amount}
                onChange={(e) => handleChange(index, 'end_amount', e.target.value && parseFloat(e.target.value))}
                type="number"
                // key={`slab-${index}end_amount${charge.end_amount}`}
                key={`end_amount`}
                size="small"
                textFieldWidth={120}
              />
            </Grid>
            <Grid item xs={3}>
              <VegaTextField
                value={charge.value_type}
                onChange={(e) => handleChange(index, 'value_type', e.target.value)}
                type="text"
                // key={`slab-${index}value_type${charge.value_type}`}
                key={`value_type`}
                size="small"
                disabled
                textFieldWidth={150}
              />
            </Grid>
            <Grid item xs={2.5}>
              <VegaTextField
                value={charge.value}
                onChange={(e) => handleChange(index, 'value', e.target.value && parseFloat(e.target.value))}
                type="number"
                // key={`slab-${index}value${charge.value}`}
                key={`value`}
                size="small"
                textFieldWidth={150}
              />
            </Grid>
            <Grid item xs={0.5}>
              {index > 0 && (
                <IconButton onClick={() => handleDeleteSlab(index)}>
                  <DeleteOutline fontSize="small" color={'error'} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Box sx={{ display: 'flex', width: '100', justifyContent: 'flex-end' }}>
        <Button onClick={handleAddSlab} sx={{ color: 'primary.light' }}>
          + slab
        </Button>
      </Box>
      <Grid container alignItems={'center'} mb={2} key={`charge-slab-${4}`}>
        <Grid item xs={6}>
          <Box
            sx={{
              width: '90%',
              py: 0.5,
              borderRadius: '10px',
              bgcolor: BLUE.light,
              textAlign: 'center',
            }}
          >
            <Typography>Remaining Customer</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <VegaTextField
            value={'PERCENTAGE'}
            onChange={(e) => handleDefaultSlabChargeChange('value_type', e.target.value)}
            disabled
            textFieldWidth={150}
            key={'defalult-slab-type-selector'}
          />
        </Grid>
        <Grid item xs={2.5}>
          <VegaTextField
            value={charge.default_slab?.value ?? ''}
            onChange={(e) => handleDefaultSlabChargeChange('value', e.target.value && parseFloat(e.target.value))}
            type="number"
            // key={`slab-${index}value${charge.value}`}
            key={`value`}
            size="small"
            textFieldWidth={150}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
      </Grid>
      <Grid container alignItems={'center'} mb={1} key={`charge-slab-${4}`}>
        <Grid item xs={6}>
          <Box
            sx={{
              width: '90%',
              py: 3,
              px: 3,
              borderRadius: '10px',
              bgcolor: BLUE.light,
              textAlign: 'center',
            }}
          >
            <Typography>Overall Min and Max Charge Values</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            sx={{
              borderRadius: '5px',
              bgcolor: GREY.light,
            }}
          >
            <Grid item xs={12} sm={6} px={1} py={1}>
              Min Value(INR)
            </Grid>
            <Grid item xs={12} sm={6} py={1}>
              Max Value(INR)
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              // width: '90%',
              py: 0.5,
            }}
          >
            <Grid item xs={12} sm={6} px={1}>
              <VegaTextField
                value={charge.min ?? ''}
                onChange={(e) => onChange('min', e.target.value && parseFloat(e.target.value))}
                type="number"
                // key={`slab-${index}value${charge.value}`}
                key={`value`}
                size="small"
                textFieldWidth={150}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VegaTextField
                value={charge.max ?? ''}
                onChange={(e) => onChange('max', e.target.value && parseFloat(e.target.value))}
                type="number"
                // key={`slab-${index}value${charge.value}`}
                key={`value`}
                size="small"
                textFieldWidth={150}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChargesSlabChargeSelector;
