import { Box, Grid } from '@mui/material';

import VegaTextField from '../../../../components/common/VegaTextField';

import { useEffect, useState } from 'react';
import VegaSelect from '../../../../components/common/VegaSelect';
import { BLUE, YELLOW } from '../../../../constants/style';
import { convertCurrency } from '../../../Back-office/api/transaction';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_INR } from '../../../variable';
import { IReloadCardData, PAYMENT_MODE, VALUE_TYPE } from './Card';

interface IProps {
  isBulkLoad?: boolean;
  reloadCardData: IReloadCardData;
  handleReloadDataChange: (
    key: keyof IReloadCardData,
    value: string | PAYMENT_MODE | VALUE_TYPE | number
  ) => void;
}
const convertValue = (
  markupValueType: string,
  value: number,
  convert: number
) => {
  const toFixedValue: any = value?.toFixed(2);

  if (markupValueType === 'PERCENTAGE') {
    const percentValue = toFixedValue - toFixedValue * (convert / 100);
    return percentValue?.toFixed(2);
  } else {
    const amountValue = toFixedValue - convert;
    return amountValue;
  }
};

function ReloadCardDetails(props: IProps) {
  const { reloadCardData, handleReloadDataChange, isBulkLoad } = props;
  const [exchangeRate, setExchangeRate] = useState<number>(1);

  const converter = () => {
    const obj = {
      sourceCurrency: reloadCardData.sourceCurrency,
      destinationCurrency: reloadCardData.destinationCurrency,
      timestamp: new Date(),
    };
    convertCurrency(obj).then((res) =>
      setExchangeRate(res.data.conversionRate)
    );
  };

  useEffect(() => {
    if (reloadCardData.sourceCurrency && reloadCardData.destinationCurrency) {
      // fetch(
      //   `${BASE_URL}?base=${reloadCardData.sourceCurrency}&symbols=${reloadCardData.destinationCurrency}`
      // )
      //   .then((res) => res.json())
      //   .then((data) => {
      //     console.log('data', data);
      //     setExchangeRate(data.rates[reloadCardData.destinationCurrency]);
      //   });
      converter();
    }
  }, [reloadCardData.sourceCurrency, reloadCardData.destinationCurrency]);

  return (
    <Box>
      <Grid container spacing={2}>
        {!isBulkLoad && (
          <Grid item xs={12} sm={6} mt={2}>
            <VegaTextField
              value={reloadCardData?.accountId}
              // onChange={(e) => handleReloadDataChange('accountId', e.target.value)}
              key="accountId"
              label="Account ID"
              type={'text'}
              placeholder="Account ID"
              disabled
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={DEFAULT_CURRENCY_INR}
            key="sourceCurrency"
            isCurrency
            selected={reloadCardData.sourceCurrency}
            label="Select Source Currency"
            onChange={(value) =>
              handleReloadDataChange('sourceCurrency', value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <VegaTextField
            value={reloadCardData.amount}
            onChange={(e) =>
              handleReloadDataChange(
                'amount',
                e.target.value && parseFloat(e.target.value)
              )
            }
            key="amount"
            label="Amount"
            type={'number'}
            placeholder="Amount"
          />
        </Grid>

        <Grid item xs={12} sm={6} mt={2}>
          <VegaSelect
            options={DEFAULT_CURRENCY}
            key="destinationCurrency"
            isCurrency
            selected={reloadCardData.destinationCurrency}
            label="Select Destination Currency"
            onChange={(value) =>
              handleReloadDataChange('destinationCurrency', value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Box
            sx={{
              bgcolor: YELLOW.lighter,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            Amount :{' '}
            {convertValue(
              reloadCardData.markupValueType,
              reloadCardData.amount * exchangeRate,
              reloadCardData.markupValue
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <Box
            sx={{
              bgcolor: BLUE.lightest,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {`Conversion 1 ${
              reloadCardData.sourceCurrency
            } = ${exchangeRate?.toFixed(2)} ${
              reloadCardData.destinationCurrency
            }`}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReloadCardDetails;
