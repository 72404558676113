import React, { useState } from 'react';
import { VegaCard, VegaPage, VegaTextField } from '../components/common';
import VegaDrawer from '../components/common/VegaDrawer';
import { CorporateCardIcon } from '../components/icon/Icons';

import { BACKGROUND } from '../constants/style';
import { Grid } from '@mui/material';
import ListOfGazettedHolidays from '../components/UniversalStandards/GazettedHolidays/ListOfGazettedHolidays';
import ListOfBankHolidays from '../components/UniversalStandards/BankHolidays/ListOfBankHolidays';

import VegaDatePicker from '../components/common/VegaDatePicker';
import VegaSelector from '../components/common/VegaSelector';
import ListOfSundayHolidays from '../components/UniversalStandards/ListOfSundayHolidays/ListOfSundayHolidays';
import ListOfSecondAndFourthSatHolidays from '../components/UniversalStandards/ListOfSecondAndFourthSatHolidays/ListOfSecondAndFourthSatHolidays';

export interface IUniversalStandardsProps {
  data: any;
  onChange: (name: string, value: any) => void;
}

let tileData = [
  {
    id: 'BANK_HOLIDAY_CALENDAR',
    text: 'Bank Holiday Calendar',
    image: <CorporateCardIcon />,
    bgColor: BACKGROUND.BLUE,
    color: 'white',
  },
];

const UniversalStandards = () => {
  const [activeState, setActiveState] = useState<any>(tileData[0]);
  const [isAddHoliday, setIsAddHoliday] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);

  const addHoliday = () => {
    setIsAddHoliday(!isAddHoliday);
  };

  return (
    <>
      <VegaPage>
        <>
          <VegaSelector
            data={tileData}
            setActiveState={setActiveState}
            active={activeState}
            header="Universal Standard"
            subHeader="All resources which are used in the Vegapay ecosystem"
          />

          <VegaCard fullHeight noPad>
            <ListOfGazettedHolidays />
          </VegaCard>
          <VegaCard fullHeight noPad>
            <ListOfSundayHolidays />
          </VegaCard>
          <VegaCard fullHeight noPad>
            <ListOfSecondAndFourthSatHolidays />
          </VegaCard>
        </>
      </VegaPage>
      <VegaDrawer
        open={isAddHoliday}
        onClose={() => setIsAddHoliday(!isAddHoliday)}
        anchor="right"
        isSubmit={true}
        isClose={true}
        onSubmit={() => setIsAddHoliday(!isAddHoliday)}
        closeText="Cancel"
        submitText="Submit"
        header="Add Bank Holiday"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <VegaTextField
              value={''}
              type={'text'}
              placeholder="Name of Holiday"
              label="Name of Holiday"
              key="holiday"
              onChange={() => console.log('first')}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <VegaDatePicker
              key="date"
              value={''}
              onChange={() => console.log('first')}
              placeholder="Select Date"
              label="Select Date"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <VegaTextField
              value={''}
              type={'text'}
              placeholder="Description"
              label="Description"
              key="holiday"
              onChange={() => console.log('first')}
            />
          </Grid>
        </Grid>
      </VegaDrawer>
    </>
  );
};

export default UniversalStandards;
