/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import Vega_Button from '../../../../../components/common/v3/Vega_Button';
import Vega_FilterSection from '../../../../../components/common/v3/Vega_FilterSection';
import Vega_PageHeader from '../../../../../components/common/v3/Vega_PageHeader';
import ListOfAllAccount from './ListOfAllAccount';
import { useProfitLoss } from '../../../../../providers/ProfitLossProvider';
import ProfitLossDownloadReportDialog from '../ProfitLossDownloadReportDialog';

const ProfitLossAccounts = () => {
  const { setIsOpenDownloadReportDialog } = useProfitLoss();
  return (
    <>
      <Vega_PageHeader
        sx={{ borderBottom: 'none', px: 0 }}
        title="All Account"
        renderRightView={() => (
          <Stack direction={'row'} gap={2}>
            <Vega_FilterSection setSearch={() => {}} />
            <Vega_Button
              text="Download Report"
              onClick={() => setIsOpenDownloadReportDialog(true)}
            />
          </Stack>
        )}
      />
      <ListOfAllAccount />
      <ProfitLossDownloadReportDialog />
    </>
  );
};

export default ProfitLossAccounts;
