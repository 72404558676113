import { createContext, useContext, useEffect, useState } from 'react';
import { CampaignService } from '../../../api/campaign';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  AddBaseRuleFormData,
  AddNewCampaignFormData,
  Campaign,
  CampaignRedemptionRule,
  CampaignRule,
  CampaignStatus,
  EditCampaignFormData,
} from '../../../types/RewardsLoyalty';

type CampaignContextType = {
  loading: boolean;
  campaigns: Campaign[];
  selectedCampaign: Campaign | null;
  isCampaignActive: boolean;
  hasBaseRule: boolean;
  hasRedemptionRule: boolean;
  onCampaignSelected: (campaign: Campaign) => void;
  createCampaign: (data: AddNewCampaignFormData) => void;
  editCampaign: (data: EditCampaignFormData) => void;
  saveRedemptionRule: (data: CampaignRedemptionRule) => void;
  saveBaseRule: (data: Partial<AddBaseRuleFormData>) => void;
  fetchCampaignRules: () => void;
  addRuleToSelectedCampaign: (data: CampaignRule) => void;
  updateRuleOfSelectedCampaign: (data: CampaignRule) => void;
  uppdateCampaignStatus: (updatedStatus: CampaignStatus) => void;
  deleteCampaign: (data: Campaign) => void;
};

const CampaignContext = createContext<CampaignContextType | null>(null);

export const useCampaign = () =>
  useContext(CampaignContext) as CampaignContextType;

export const CampaignProvider = ({ children }: any) => {
  const { selectedProgram } = useProgramData();
  const { setSnackbar, setSnackbarError } = useSnackbar();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );

  function _onCampaignSelect(campaign: Campaign) {
    setSelectedCampaign(campaign);
  }

  function _addRuleToSelectedCampaign(rule: CampaignRule) {
    if (!selectedCampaign) return;
    const updatedCampaign = { ...selectedCampaign };
    const existingRules = updatedCampaign.ruleList ?? [];
    existingRules.push(rule);
    updatedCampaign.ruleList = existingRules;
    setSelectedCampaign(updatedCampaign);
  }

  function _updateRuleOfSelectedCampaign(rule: CampaignRule) {
    if (!selectedCampaign) return;
    const updatedCampaign = { ...selectedCampaign };
    const existingRules = updatedCampaign.ruleList ?? [];
    const updatedRules = existingRules.map((item) => {
      if (item.id == rule.id) return rule;
      return item;
    });
    updatedCampaign.ruleList = updatedRules;
    setSelectedCampaign(updatedCampaign);
  }

  async function _getCampaignById(id: string) {
    try {
      setLoading(true);
      const response = await CampaignService.getCampaign(id);
      _updateSelectedCampaignInCache(response);
    } catch (error) {
      console.error(error);
      setSnackbar('Failed to get campaign details', 'error');
    } finally {
      setLoading(false);
    }
  }

  async function _fetchCampaigns(programId: string) {
    try {
      setCampaigns([]);
      setLoading(true);
      const response = await CampaignService.getCampaignForProgram(programId);
      setCampaigns(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function _createCampaign(data: AddNewCampaignFormData) {
    try {
      const programId = selectedProgram?.programId;
      if (!programId) return;
      setLoading(true);
      const response = await CampaignService.createCampaign({
        campaignName: data.name,
        programId: programId,
        campaignType: data.campaignType,
        redemptionType: data.redemptionType,
      });

      if (data.logo) {
        const updatedLogoResponse = await CampaignService.updateCampaignLogo({
          campaignId: response.id,
          logo: data.logo,
        });
        setCampaigns((prev) => [...prev, updatedLogoResponse]);
        return;
      }

      setCampaigns((prev) => [...prev, response]);
    } catch (error) {
      setSnackbarError(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function _saveRedemptionRule(data: CampaignRedemptionRule) {
    if (!selectedCampaign) return;
    const updatedCampaignData = { ...selectedCampaign };
    updatedCampaignData.redemptionRule = data;
    _updateCampaign(updatedCampaignData);
  }

  function _saveBaseRule(data: Partial<AddBaseRuleFormData>) {
    if (!selectedCampaign) return;
    const updatedCampaignData = { ...selectedCampaign };
    updatedCampaignData.rewardPoint = data.rewardPoint;
    updatedCampaignData.timeframe = data.iteration;
    updatedCampaignData.perAmount = data.perAmount;
    updatedCampaignData.maximumPoint = data.maximumPoint;
    _updateCampaign(updatedCampaignData);
  }

  function _updateSelectedCampaignInCache(udpatedCampaign: Campaign) {
    setCampaigns((prev) => {
      const updatedCampaigns = prev.map((item) => {
        if (item.id == udpatedCampaign.id) return udpatedCampaign;
        return item;
      });
      return updatedCampaigns;
    });
    setSelectedCampaign(udpatedCampaign);
  }

  const hasBaseRule = () => {
    const isRewardPointsValid = (selectedCampaign?.rewardPoint ?? 0) > 0;
    const isTimeFrameValid = (selectedCampaign?.timeframe ?? '').length > 0;
    const isPerAmountValid = (selectedCampaign?.perAmount ?? 0) > 0;
    const isMaxPointValid = (selectedCampaign?.maximumPoint ?? 0) > 0;
    return (
      isRewardPointsValid &&
      isTimeFrameValid &&
      isPerAmountValid &&
      isMaxPointValid
    );
  };

  const hasRedemptionRule = () => {
    return selectedCampaign?.redemptionRule != null;
  };

  const isCampaignActive = () => {
    return selectedCampaign?.status == CampaignStatus.ACTIVE;
  };

  async function _updateCampaignStatus(updatedStatus: CampaignStatus) {
    try {
      const campaignId = selectedCampaign?.id;
      if (!campaignId) return;
      setLoading(true);
      const response = await CampaignService.updateCampaignStatus({
        status: updatedStatus,
        campaignId: campaignId,
      });
      const filteredCampaigns = campaigns.filter(
        (item) => item.id == response.id
      );
      if (filteredCampaigns.length > 0) {
        const updatedCampaign = { ...filteredCampaigns[0] };
        updatedCampaign.status = response.status;
        _updateSelectedCampaignInCache(updatedCampaign);
      }
    } catch (error) {
      setSnackbar('Failed to update campaign status', 'error');
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function _updateCampaign(updatedCampaign: Campaign) {
    try {
      setLoading(true);
      const response = await CampaignService.updateCampaign(updatedCampaign);
      setCampaigns((prev) => {
        const updatedCampaigns = prev.map((item) => {
          if (item.id == response.id) return response;
          return item;
        });
        return updatedCampaigns;
      });
      setSelectedCampaign(response);
    } catch (error) {
      setSnackbar('Failed to update campaign', 'error');
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function _editCampaign(data: EditCampaignFormData) {
    if (!selectedCampaign) return;
    const name = data.name;
    const logo = data.logo;
    const updatedCampaignData = { ...selectedCampaign };
    if (name && name.length > 0) {
      updatedCampaignData.campaignName = name;
      _updateCampaign(updatedCampaignData);
    }
    if (logo && logo.size > 0) {
      const updatedLogoResponse = await CampaignService.updateCampaignLogo({
        campaignId: updatedCampaignData.id,
        logo: logo,
      });

      const filteredCampaigns = campaigns.filter(
        (item) => item.id == updatedLogoResponse.id
      );
      if (filteredCampaigns.length > 0) {
        const updatedCampaign = { ...filteredCampaigns[0] };
        updatedCampaign.campaignImageLink =
          updatedLogoResponse.campaignImageLink;
        _updateSelectedCampaignInCache(updatedCampaign);
      }
      return;
    }
  }

  function _fetchCampaignRules() {
    const campaignId = selectedCampaign?.id;
    if (!campaignId) return;
    _getCampaignById(campaignId);
  }

  async function _deleteCampaign(campaign: Campaign) {
    const campaignId = campaign.id;
    if (!campaignId || campaignId.length > 0) {
      try {
        const response = await CampaignService.deleteCampaign(campaignId);
        if (response) {
          const updatedCampaigns = campaigns.filter(
            (item) => item.id != campaignId
          );
          setCampaigns(updatedCampaigns);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    const programId = selectedProgram?.programId;
    if (!programId) return;
    _fetchCampaigns(programId);
  }, [selectedProgram]);

  return (
    <CampaignContext.Provider
      value={{
        loading: loading,
        campaigns: campaigns,
        isCampaignActive: isCampaignActive(),
        hasBaseRule: hasBaseRule(),
        hasRedemptionRule: hasRedemptionRule(),
        selectedCampaign: selectedCampaign,
        onCampaignSelected: _onCampaignSelect,
        createCampaign: _createCampaign,
        saveRedemptionRule: _saveRedemptionRule,
        saveBaseRule: _saveBaseRule,
        fetchCampaignRules: _fetchCampaignRules,
        addRuleToSelectedCampaign: _addRuleToSelectedCampaign,
        updateRuleOfSelectedCampaign: _updateRuleOfSelectedCampaign,
        editCampaign: _editCampaign,
        uppdateCampaignStatus: _updateCampaignStatus,
        deleteCampaign: _deleteCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
