/** @format */

import { Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState } from 'react';
import {
  convertTransactionToEmi,
  fetchPreviewTransactionToEMI,
} from '../../api/installment';
import { DATE_FORMATE } from '../../constants/url';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { EMIPreview, TransactionToEmiPreview } from '../../types/transaction';
import { NoResults, VegaCard, VegaTable } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaDrawer from '../common/VegaDrawer';
import ViewCustomer from '../customers/ViewCustomer';
import CreateDispute from './CreateDispute';
import { TransactionToEMIPreviewDialog } from './TransactionToEMIPreviewDialog';

const ListOfTransaction = ({
  customerTransaction,
  setShowCustomerData,
  setDisputeReason,
  submitDispute,
  showCustomerData,
  disputeReason,
  setIsDispute,
  isDispute,
}: any) => {
  const [open, setOpen] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [emiPreview, setEMIPreview] = useState<TransactionToEmiPreview | null>(
    null
  );
  const { setSnackbar } = useSnackbar();
  if (!customerTransaction || customerTransaction.length === 0) {
    return (
      <NoResults
        text="You have no transaction"
        subtext="Create new transaction?"
      />
    );
  }

  const columnDef = [
    {
      field: 'transactionTime',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Txn time
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.transactionTime &&
              dayjs(props.row.transactionTime).format(DATE_FORMATE) +
                ' ' +
                dayjs(props.row.transactionTime).format('h:mm:ss A')}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'amount',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Amount
        </Typography>
      ),

      flex: 0.5,
    },
    {
      field: 'currency',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Currency
        </Typography>
      ),

      flex: 0.5,
    },
    {
      field: 'transactionIndicator',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Indicator
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div>{_.startCase(_.toLower(props.row.transactionIndicator))}</div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.status && _.startCase(_.toLower(props.row.status))}
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'merchantName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Merchant Name
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.merchantName &&
              _.startCase(_.toLower(props.row.merchantName))}
          </div>
        );
      },
      flex: 0.7,
    },
    {
      field: 'transactionCategory',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Category
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.transactionCategory &&
              _.startCase(_.toLower(props.row.transactionCategory))}
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'transactionCode',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Code
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.transactionCode &&
              _.startCase(_.toLower(props.row.transactionCode))}
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'other',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Txn Information
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Button
            color="info"
            variant="text"
            onClick={() => showCustomer(props.row)}
          >
            View More
          </Button>
        );
      },
      flex: 0.5,
    },
    {
      field: 'action',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          props.row.transactionIndicator === 'DEBIT' && (
            <VegaContainedButton
              text="Dispute"
              onClick={() => showDispute(props.row)}
              isPrimary
            />
          )
        );
      },
      flex: 0.7,
    },
  ];

  const showCustomer = (data: any) => {
    setOpen(!open);
    setShowCustomerData(data);
  };
  const showDispute = (data: any) => {
    setIsDispute(!isDispute);
    setShowCustomerData(data);
  };

  const onHandleDispute = (name: any, value: string) => {
    setDisputeReason((preVal: any) => ({
      ...preVal,
      [name]: value,
    }));
  };

  function onConvertToEmiClick() {
    setOpen(false);
    getTransactionToEmiPreview();
  }

  async function convertTransactionToInstallment(option: EMIPreview) {
    if (!emiPreview) {
      setSnackbar('Failed to convert transction to EMI', 'error');
      return;
    }
    const transactionId = emiPreview.transactionId;
    setLoading(true);
    convertTransactionToEmi({
      transactionId: transactionId,
      tenure: option.durationInMonths,
    })
      .then((res) => {
        setSnackbar('Converted Transaction to EMI', 'success');
      })
      .catch((err) => {
        setSnackbar('Failed to convert to EMI', 'error');
      })
      .finally(() => setLoading(false));
  }

  async function getTransactionToEmiPreview() {
    const transactionId = showCustomerData.id;
    if (!transactionId) return;
    setLoading(true);
    fetchPreviewTransactionToEMI({
      transactionId: transactionId,
    })
      .then((preview) => {
        setEMIPreview(preview);
        setShowPreviewDialog(true);
      })
      .catch((error) => {
        setSnackbar('Failed to get Preview', 'error');
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable
          data={customerTransaction}
          columnDef={columnDef}
          idColumn={'id'}
          loading={loading}
        />
      </VegaCard>
      <VegaDrawer
        open={open}
        onClose={() => setOpen(!open)}
        anchor="right"
        isSubmit={true}
        onSubmit={() => setOpen(!open)}
        submitText="Got It!"
        header="Txn Details"
      >
        <ViewCustomer
          showCustomerData={{ ...showCustomerData, attributes: null }}
          showConvertToEmiButton={showCustomerData.emiConvertible ?? false}
          onConvertToEmiClick={onConvertToEmiClick}
        />
      </VegaDrawer>
      <VegaDrawer
        open={isDispute}
        onClose={() => setIsDispute(!isDispute)}
        anchor="right"
        isSubmit={true}
        onSubmit={submitDispute}
        submitText="Submit"
        header="Create Dispute"
      >
        <CreateDispute
          onHandleChange={onHandleDispute}
          disputeReason={disputeReason}
        />
      </VegaDrawer>

      <TransactionToEMIPreviewDialog
        show={showPreviewDialog}
        onClose={() => setShowPreviewDialog(false)}
        onSubmit={(option) => convertTransactionToInstallment(option)}
        preview={emiPreview!}
      />
    </>
  );
};

export default ListOfTransaction;
