/* eslint-disable react/jsx-pascal-case */
import { FilterList, Search } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Vega_OutlineTextField from './Vega_OutlineTextField';
import Vega_Text from './Vega_Text';

interface IProps {
  onFilter?: () => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  search?: string;
}

const Vega_FilterSection = ({ onFilter, search, setSearch }: IProps) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'end'}
      gap={3}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        sx={{ cursor: 'pointer' }}
        onClick={onFilter ? onFilter : () => {}}
      >
        <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
        <Vega_Text text="Filter" fontSize={'0.75rem'} color={'#676B76'} />
      </Stack>
      <Vega_OutlineTextField
        fixWidth={240}
        placeholder="Search"
        name="search"
        startAdornment={
          <Search sx={{ color: '#999DA8', fontSize: 16, mr: 1 }} />
        }
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </Stack>
  );
};

export default Vega_FilterSection;
