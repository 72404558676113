/** @format */

import { Search } from '@mui/icons-material';

import { Button, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { PRIMARY } from '../../../constants/style';
import { OPEARATION_ROUTES } from '../../../pages/Operations';
import { useApplicationManagmentConfig } from '../../../providers/ApplicationManagmentProvider';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { VegaLimitOfferStatus } from '../../../types/los';
import { VegaCard, VegaTable, VegaTextField } from '../../common';
import VegaSelect from '../../common/VegaSelect';
import Vega_HeaderWrapper from '../../common/v3/Vega_HeaderWrapper';
import useApplicationMng from './useApplicationMng';

const FREQ_OPTIONS = [
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'yearly',
    name: 'Yearly',
  },
];
const DOC_TYPE_OPTIONS = [
  {
    id: 'manual',
    name: 'Manual',
  },
  {
    id: 'auto',
    name: 'Auto',
  },
];

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<string>>;
}

function ApplicationManagement({ setShow }: IProps) {
  const { selectedProgram } = useProgramData();
  const { limitOffers, setSelectedOfferData, fetchOffers } =
    useApplicationManagmentConfig();

  const { filterOpts, onFilterChange } = useApplicationMng();
  useEffect(() => {
    fetchOffers();
  }, [selectedProgram?.programId]);

  const LosApplicationColumnDef: GridColDef[] = [
    {
      field: 'id',
      headerName: '  Application ID',
      flex: 1.5,

      // renderCell: (props) => <VegaToolTipAndCopy value={props.row.id.toString()} />,
    },
    {
      field: 'customerName',
      headerName: '  Name',
      flex: 1,
    },
    {
      field: 'customerMobileNumber',
      headerName: '  Mobile No',
      flex: 1,
    },
    {
      field: 'customerEmail',
      headerName: '  Email ID',
      flex: 1.2,
    },
    {
      field: 'offerStatus',
      headerName: '  Application Status',
      flex: 0.6,
      renderCell(params) {
        let status = params.row.offerStatus;
        var textColor = 'green';
        if (status == VegaLimitOfferStatus.ACCEPTED) {
          textColor = '#66BB6A';
        } else if (status == VegaLimitOfferStatus.PENDING) {
          textColor = '#42A5F5';
        } else if (status == VegaLimitOfferStatus.REJECTED) {
          textColor = '#E57373';
        } else if (status == VegaLimitOfferStatus.EXPIRED) {
          textColor = '#E57373';
        }
        var displayText = status;
        if (status == VegaLimitOfferStatus.PENDING) {
          displayText = 'Pending';
        } else {
          displayText = status;
        }
        return (
          <Typography variant="caption" fontWeight={'bold'} color={textColor}>
            {displayText}
          </Typography>
        );
      },
    },
    {
      field: 'DoC',
      headerName: '  DoC',
      renderCell(params) {
        return <Box>Auto </Box>;
      },
      flex: 0.6,
    },
    {
      field: 'actions',
      headerName: '  Action',
      renderCell: (props: any) => {
        return (
          <Button
            size="small"
            sx={{
              bgcolor: PRIMARY.light,
              ':hover': { bgcolor: PRIMARY.light },
              '&.Mui-disabled': {
                color: PRIMARY.light,
                bgcolor: PRIMARY.lighter,
              },
              color: 'white',
            }}
            onClick={() => {
              setSelectedOfferData(props.row);
              // setShouldOpenDrawer(true);
              setShow(OPEARATION_ROUTES.APPLICATION_VIEW);
            }}
          >
            View
          </Button>
        );
      },
      flex: 0.8,
    },
  ];

  useEffect(() => {
    console.log('====================================');
    console.log(limitOffers);
    console.log('====================================');
  }, [limitOffers]);

  return (
    <Box>
      <VegaCard>
        <Vega_HeaderWrapper text="Apply Filter" />

        <Box
          display={'flex'}
          style={{
            columnGap: 8,
          }}
        >
          <VegaTextField
            key={'customerId'}
            value={filterOpts.customerId}
            onChange={(e) => onFilterChange('customerId', e.target.value)}
            placeholder={'Search by Customer ID'}
            //   size="small"
            type={'text'}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            textFieldWidth={300}
          />

          {/* <VegaSelect
          options={FREQ_OPTIONS}
          onChange={(value) => onChange("freq", value)}
          selected={filterOpts.freq}
          textFieldWidth={200}
          size="small"
        /> */}
          <VegaSelect
            options={DOC_TYPE_OPTIONS}
            onChange={(value) => onFilterChange('docType', value)}
            selected={filterOpts.docType}
            textFieldWidth={200}
            size="small"
          />
        </Box>
      </VegaCard>

      <VegaCard fullHeight noPad sx={{ mt: 4 }}>
        <VegaTable
          data={limitOffers}
          columnDef={LosApplicationColumnDef}
          idColumn="id"
        />
      </VegaCard>

      {/* <VegaDrawer
        open={shouldOpenDrawer}
        onClose={(e, buttonClicked) => {
          if (buttonClicked && 
            ()) {
            didRejectLimitOffer();
          } else {
            setShouldOpenDrawer(false);
          }
        }}
        anchor="right"
        isSubmit={canApproveSelectedOffer()}
        isClose={true}
        onSubmit={didAcceptLimitOffer}
        submitText="Approve Application"
        closeText={canApproveSelectedOffer() ? 'Reject' : 'Got It'}
        header={
          <div>
            Customer ID : {selectedOfferData.customerId}
          
          </div>
        }
      >
        <ApplicationDetails customerId={selectedOfferData.customerId} />
      </VegaDrawer> */}
    </Box>
  );
}

export default ApplicationManagement;
