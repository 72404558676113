import './Styles/__styles.css';
import { Typography, TypographyProps, styled } from '@mui/material';
import { createTheme } from '@mui/material/styles';
export interface StyledTypographyProps extends TypographyProps {
  bold?: boolean;
}

// const theme = createTheme({
//   typography: {
//     // fontFamily: 'Aspekta',
//     fontWeightMedium: {
//       fontFamily: 'AnotherFont, Arial, sans-serif',
//       fontWeight: 500,
//     },
//     // fontWeightBold: {
//     //   fontFamily: 'CustomFont, Arial, sans-serif',
//     //   fontWeight: 700,
//     // },
//     // Add more font weights and families as needed
//   },
// });

// const getFontFamily = (fontWeight?: number): string => {
//   if (fontWeight >= 700) {
//     return 'Aspekta-bold !important';
//   }
//   if (fontWeight >= 500) {
//     return 'Aspekta-medium !important';
//   }
//   if (fontWeight == 400) {
//     return 'Aspekta-400 !important';
//   }
//   return 'Aspekta !important';
// };

const StyledTypography = styled(Typography)<StyledTypographyProps>(
  ({ theme, fontWeight }) => {
    return {
      fontFamily: 'Aspekta !important',
      textOverflow: 'ellipsis',
    };
  }
);

/**
 *   ...(bold && {
        color: '#3A4A5F',
        fontWeight: 700,
        fontFamily: 'Aspekta-bold !important',
      }),
 */
export interface VegaTextV2Props extends StyledTypographyProps {
  text?: string;
}

function VegaTextV2({ text, children, ...rest }: VegaTextV2Props) {
  return <StyledTypography {...rest}>{children || text}</StyledTypography>;
}

export default VegaTextV2;
