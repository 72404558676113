/* eslint-disable react/jsx-pascal-case */
import { Stack, Typography } from '@mui/material';
import { GridColumns, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useClientAuth } from '../../../providers/ClientProvider';
import { BinAllocationDto } from '../../../types/card';
import { Level } from '../../../types/client';
import { BinService } from '../../apis';
import CIMS_DataGrid from '../../components/common/CIMS_DataGrid';
import SummaryTab from './SummaryTab';

interface IProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}
const ListOfHq = ({ selectedTab, setSelectedTab }: IProps) => {
  const [pageSize, setPageSize] = useState<number>(5);
  const [page, setPage] = useState(0);

  const { clientId } = useClientAuth();
  const listBinAllocationQuery = useQuery({
    queryKey: 'listBinAllocation',
    queryFn: () => {
      return BinService.listBinAllocation({
        clientId: clientId || '',
        depth: 0, // Level l0
        page: page,
        size: pageSize,
        populateBin: true,
        populateProgram: true,
      });
    },
  });

  const COLUMN_DEF: GridColumns<BinAllocationDto> = [
    // {
    //   field: 'code',
    //   headerName: 'BC Code',
    //   flex: 2,
    //   minWidth: 150,
    //   valueGetter: (props) => {
    //     return props.row.entityDto?.entityCode;
    //   },
    //   renderCell: (props) => {
    //     return (
    //       <Typography variant="body1">
    //         {props.row.entityDto?.entityCode || '-'}
    //       </Typography>
    //     );
    //   },
    // },
    // {
    //   field: 'bcName',
    //   headerName: 'BC Name',
    //   flex: 2,
    //   minWidth: 150,
    //   valueGetter: (props) => {
    //     return props.row.entityDto?.name;
    //   },
    //   renderCell: (props) => {
    //     return (
    //       <Typography variant="body1">
    //         {props.row.entityDto?.name || '-'}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: 'binValue',
      headerName: 'Bin Value',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.binDto?.binValue;
      },
      renderCell: (props) => {
        return <Typography variant="body1">{props.value}</Typography>;
      },
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 2,
      minWidth: 150,
      valueGetter: (props) => {
        return props.row.programDto?.name || '-';
      },
      renderCell: (props) => {
        return <Typography variant="body1">{props.value}</Typography>;
      },
    },
    {
      field: 'totalKits',
      headerName: 'Total Cards',
      flex: 2.5,
      minWidth: 250,
      renderCell: (props) => {
        return (
          <Typography variant="body1">{props.row.totalKits || 0}</Typography>
        );
      },
    },
    // {
    //   field: 'noOfKitsAllocated',
    //   headerName: 'Allocated to Branches',
    //   flex: 2,
    //   minWidth: 150,
    //   renderCell: (props) => {
    //     return (
    //       <Typography variant="body1">
    //         {props.row.noOfKitsAllocated || 0}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: 'noOfKitsAllocated',
      headerName: 'Cards Issued',
      flex: 2,
      minWidth: 150,
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {/* {props.row.totalKits||
              0 - (props.row.noOfKitsAvailable || 0)} */}
            {props.row.noOfKitsSold || 0}
          </Typography>
        );
      },
    },

    {
      field: 'noOfKitsAvailable',
      headerName: 'Cards Available',
      flex: 2,
      minWidth: 150,
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.noOfKitsAvailable || 0}
          </Typography>
        );
      },
    },
    {
      field: 'noOfKitsDamaged',
      headerName: 'Cards Damaged',
      flex: 2,
      minWidth: 150,
      renderCell: (props) => {
        return (
          <Typography variant="body1">
            {props.row.noOfKitsDamaged || 0}
          </Typography>
        );
      },
    },
  ];
  return (
    <>
      <CIMS_DataGrid
        data={listBinAllocationQuery.data?.records || []}
        columns={COLUMN_DEF}
        idColumn={'id'}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        paginationMode="client"
        bgColor="white"
        loading={listBinAllocationQuery.isLoading}
        components={{
          Toolbar: (props) => {
            return (
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                justifyContent={'space-between'}
                alignItems={'center'}
                py={1}
              >
                {/* <VegaTab
                  placement="vertical"
                  color={'#1047DC'}
                  tabData={['HQ', 'Bc Agent', 'Business Correspondent']}
                  selected={selectedTab}
                  onSelect={(select) => setSelectedTab(select)}
                /> */}
                <SummaryTab
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
                <GridToolbarQuickFilter
                  autoFocus
                  variant="outlined"
                  sx={{
                    bgcolor: 'white',
                    color: '#999DA8',
                    height: '35px',
                    minWidth: '320px',
                    borderRadius: '9px',
                  }}
                />
              </Stack>
            );
          },
        }}
      />
    </>
  );
};

export default ListOfHq;
