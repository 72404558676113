import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDialog from '../../../../components/common/VegaDialog';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import { TransferType } from '../../../../enums/commons';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  IBinAllocationOrderProps,
  IBinProps,
  IStockTranseferListProps,
} from '../../../types/Bin';
import { ISectionProps } from '../../pages/MCCUserManagement';
import OrderInventory from './OrderInventory';
import StockTransfer from './StockTransfer';

interface IProps {
  active: ISectionProps;
  binData: IBinProps[];
  loading: boolean;
  onReoder: (data: IBinAllocationOrderProps) => void;
  addStockTransfer: (data: IStockTranseferListProps) => void;
  page: number;
  size: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}

const DEFAUT_DATA = {
  binId: '',
  binEntityType: 'BRANCH',
  entityId: '',
  numberOfKits: '',
};

const ListOfInventoryManagement = ({
  active,
  binData,
  loading,
  addStockTransfer,
  onReoder,
  page,
  size,
  rowCount,
  onPageChange,
  onPageSizeChange,
}: IProps) => {
  const { mccPlatformId: branchId } = useClientAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [branchName, setBranchName] = useState<string>('');
  const [showData, setShowData] = useState<any>({});

  const [transferType, setTransferType] = useState<TransferType>(
    TransferType.OUTBOUND
  );

  const getAction = (data: IStockTranseferListProps) => {
    if (data.binAllocationTransferStatus === 'DRAFT') {
      return {
        action: <VegaContainedButton text="Send to Checker" />,
        viewEdit: <VegaContainedButton text="View" />,
      };
    } else if (
      data.binAllocationTransferStatus === 'UNDER_REVIEW' &&
      data.makerCheckerStatus === 'CHECKER_PENDING'
    ) {
      return {
        action: <VegaContainedButton text="Sent to Checker" disabled />,
        viewEdit: <VegaContainedButton text="View" />,
      };
    }
  };

  const getKitsValue = (value: number | null) => {
    if (value === null) {
      return 0;
    }
    return value;
  };

  const INVENTORY_COLUMN = [
    {
      field: 'binId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bin No
        </Typography>
      ),
      flex: 1,
    },
    // {
    //   field: 'programName',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Program Name</Typography>,
    //   flex: 1,
    // },
    {
      field: 'noOfKitsAvailable',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Unsold
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const unSoldKits =
          getKitsValue(props.row.noOfKitsAvailable) +
          getKitsValue(props.row.noOfKitsAllocated) -
          (getKitsValue(props.row.noOfKitsDamaged) +
            getKitsValue(props.row.noOfKitsSold));
        console.log(
          '🚀 ~ file: ListOfInventoryManagementForClient.tsx:50 ~ unSoldKits:',
          unSoldKits
        );
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {unSoldKits}
          </Typography>
        );
      },
    },
    {
      field: 'noOfKitsSold',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Sold
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        console.log('first', props.row.noOfKitsSold);
        // if (!props.row.noOfKitsSold) return '--';
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {getKitsValue(props.row.noOfKitsSold)}
          </Typography>
        );
      },
    },

    {
      field: 'totalKits',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Total
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        if (!props.row.totalKits) return '--';
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {props.row.totalKits}
          </Typography>
        );
      },
    },
  ];
  const STOCK_TRANSFER = [
    {
      field: 'binId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bin No
        </Typography>
      ),
      flex: 1,
    },
    // {
    //   field: 'programName',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Program Name</Typography>,
    //   flex: 1,
    // },
    {
      field: 'noOfKitsAvailable',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Unsold
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const unSoldKits =
          getKitsValue(props.row.noOfKitsAvailable) +
          getKitsValue(props.row.noOfKitsAllocated) -
          (getKitsValue(props.row.noOfKitsDamaged) +
            getKitsValue(props.row.noOfKitsSold));
        console.log(
          '🚀 ~ file: ListOfInventoryManagementForClient.tsx:50 ~ unSoldKits:',
          unSoldKits
        );
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {unSoldKits}
          </Typography>
        );
      },
    },
    {
      field: 'noOfKitsSold',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Sold
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        console.log('first', props.row.noOfKitsSold);
        // if (!props.row.noOfKitsSold) return '--';
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {getKitsValue(props.row.noOfKitsSold)}
          </Typography>
        );
      },
    },

    {
      field: 'totalKits',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          {' '}
          Total
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        if (!props.row.totalKits) return '--';
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {props.row.totalKits}
          </Typography>
        );
      },
    },
    {
      field: 'action',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      flex: 1.5,
      renderCell: (props: any) => {
        return (
          <Stack direction={'row'}>
            <VegaContainedButton
              text="Transfer"
              onClick={() => showDetails(props.row, TransferType.OUTBOUND)}
              isPrimary
            />
            <VegaOutlineButton
              text="Request"
              onClick={() => showDetails(props.row, TransferType.INBOUND)}
              isPrimary
            />
          </Stack>
        );
      },
    },
  ];

  const showDetails = (data: any, transferType: TransferType) => {
    setOpen(!open);
    setTransferType(transferType);
    setShowData(data);
  };

  const onChange = (name: string, value: any) => {
    setShowData({ ...showData, [name]: value });
  };

  const getValue = (id: string) => {
    if (id === 'ORDER_INVENTORY') {
      return {
        column: INVENTORY_COLUMN,
        header: 'Reoder',
        data: binData,
        component: (
          <OrderInventory
            state={showData}
            handleChange={onChange}
            isShow={false}
          />
        ),
      };
    } else {
      return {
        column: STOCK_TRANSFER,
        header: transferType === TransferType.INBOUND ? 'Request' : 'Transfer',
        data: binData,
        component: (
          <StockTransfer
            handleChange={onChange}
            isShow={false}
            setBranchName={setBranchName}
            showData={showData}
            transferType={transferType}
          />
        ),
      };
    }
  };

  const onClose = () => {
    setOpen(!open);
  };
  const onSubmit = async () => {
    console.log('showData11', showData);

    const obg = { ...showData, entityId: branchId, entityLevel: 'l1' };
    const stockObg = {
      ...showData,
      sourceDivisionId:
        transferType === TransferType.OUTBOUND
          ? branchId
          : showData.destinationDivisionId,
      destinationDivisionId:
        transferType === TransferType.OUTBOUND
          ? showData.destinationDivisionId
          : branchId,
      sourceEntityLevel: 'l1',
      // destinationBinEntityType: branchName,
      destinationEntityLevel: 'l1',
    };

    if (active.id === 'ORDER_INVENTORY') {
      await onReoder(obg);
    } else {
      await addStockTransfer(stockObg);
    }

    onClose();
  };
  return (
    <VegaCard fullHeight noPad>
      {active.id === 'ORDER_INVENTORY' && (
        <Box sx={{ p: 2, textAlign: 'end' }}>
          <VegaContainedButton
            text="Order"
            onClick={() => setOpen(!open)}
            isPrimary
          />
        </Box>
      )}
      <VegaTable
        noTopRadius={active.id === 'ORDER_INVENTORY'}
        data={getValue(active.id).data}
        columnDef={getValue(active.id).column}
        idColumn={'id'}
        paginationMode={'server'}
        page={page}
        pageSize={size}
        rowCount={rowCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      {/* <VegaDrawer
        open={open}
        isClose
        isSubmit
        header={getValue(active.id).header}
        closeText={'Cancel'}
        submitText={'Submit'}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        {getValue(active.id).component}
      </VegaDrawer> */}

      <VegaDialog
        handleClose={onClose}
        open={open}
        title={getValue(active.id).header}
        fullWidth
        maxWidth="xs"
        onSubmit={onSubmit}
      >
        {getValue(active.id).component}
      </VegaDialog>
    </VegaCard>
  );
};

export default ListOfInventoryManagement;
