import { Add } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import VegaPrimaryButton from '../VegaPrimaryButton';
import VegaTextV2 from '../VegaTextV2';
import ImageSelectorItem from './ImageSlectorItem';

type Props = {
  title: string;
  buttonTitle?: string;
  images: string[];
  selectedImage?: string;
  onAddClick: () => void;
  onImageSelect: (value: string) => void;
};

function VegaImageSelector({
  title,
  images,
  onAddClick,
  buttonTitle,
  onImageSelect,
  selectedImage,
}: Props) {
  return (
    <Box style={{ padding: 12, maxWidth: '40vw' }}>
      <Stack spacing={2}>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          spacing={2}
        >
          <VegaTextV2 text={title} />
          <VegaPrimaryButton
            variant="text"
            onClick={onAddClick}
            text={buttonTitle ?? 'Add'}
            startIcon={<Add></Add>}
          />
        </Stack>
        <Stack direction={'row'} style={{ overflowX: 'scroll' }} spacing={1}>
          {images.map((item, index) => {
            return (
              <ImageSelectorItem
                key={index}
                url={item}
                isSelected={selectedImage == item}
                handleClick={function (): void {
                  onImageSelect(item);
                }}
              />
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
}

export default VegaImageSelector;
