import { Grid, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import VegaText from '../../../../../../components/common/VegaText';
import { VegaTextField } from '../../../../../../components/common';
import VegaSelect from '../../../../../../components/common/VegaSelect';
import { fetchListOfResources } from '../../../../../../api/resources';
import VegaContainedButton from '../../../../../../components/common/VegaContainedButton';
import { ICurrencyProps } from '../WalletConfiguration';
import { DEFAULT_CURRENCY } from '../../../../../variable';
import VegaLabel from '../../../../../../components/common/VegaLabel';

interface IProps {
  setState: React.Dispatch<React.SetStateAction<any>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  state: ICurrencyProps[];
}

const NumberOfWallet = ({ setState, setDisabled, state }: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [currency, setCurrency] = useState<any>([]);
  const [error, setError] = useState<string>('');
  const [numberOfWallet, setNumberOfWallet] = useState<any>(6);

  const handleCreateWallet = (value: any) => {
    if (value > 10) value = 10;
    if (value < 0) value = 0;
    if (!value.length) {
      setDisabled(true);
    }
    setNumberOfWallet(value);
  };

  const selectedCurrency = (value: string, arr: any[]) => {
    return arr.some((val: any) => value === val.currency);
  };

  const handleCurrency = (e: any, i: number) => {
    const arr = [...state];
    const isSelected = selectedCurrency(e, arr);
    if (isSelected) {
      setError('Select Diffrent Currency');
    } else {
      const obj = {
        currency: e,
        index: i + 1,
      };
      setError('');
      arr.splice(i, 1, obj);
      setState(arr);
    }

    setDisabled(false);
  };
  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      setPage(page + 1);
    }
  };

  const getListAll = () => {
    fetchListOfResources(page, 10, 'currency', '', '')
      .then((res) => {
        const currancyData = res.data.records.map((currency: any) => ({
          name: currency.isoCode,
          id: currency.isoCode,
        }));
        setCurrency([...currency, ...currancyData]);
      })
      .catch((err) => {
        // updateData('loading', false);
        // setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };
  useEffect(() => {
    getListAll();
  }, [page]);
  useEffect(() => {
    if (numberOfWallet) {
      const newArr = [...Array(numberOfWallet)].map(
        (number: any, index: number) => {
          return {
            currency: '',
            index: index + 1,
          };
        }
      );
      setState(newArr);
    } else {
      setState([]);
    }
  }, [numberOfWallet]);
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={10} md={8} lg={7} xl={5}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: 4,
            }}
          >
            <VegaText text="Total Number of Wallet" />
            <Box sx={{ width: '100px' }}>
              <VegaTextField
                isFixedHeight
                value={numberOfWallet}
                onChange={(e) =>
                  handleCreateWallet(e.target.value && parseInt(e.target.value))
                }
                key="customerId"
                type={'number'}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box>{error && <VegaLabel text={error} color="danger" />}</Box>
      <Grid container>
        <Grid item xs={12} sm={10} md={8} lg={7} xl={5}>
          {state.length
            ? state.map((wallet: any, i: number) => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      my: 2,
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <VegaText text={`Select Currency ${wallet?.index}`} />
                      {i === 0 && (
                        <VegaContainedButton
                          text="Default"
                          isPrimary
                          disabled
                        />
                      )}
                    </Box>
                    <Box sx={{ width: '100px' }}>
                      <VegaSelect
                        isFixedHeight
                        isCurrency
                        onScroll={loadMoreItems}
                        options={DEFAULT_CURRENCY}
                        onChange={(e: any) => handleCurrency(e, i)}
                        selected={wallet?.currency ?? ''}
                      />
                    </Box>
                  </Box>
                );
              })
            : ''}
        </Grid>
      </Grid>
    </>
  );
};

export default NumberOfWallet;
