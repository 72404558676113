/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import Vega_Text from '../../../../../components/common/v3/Vega_Text';

interface IProps {
  title: string;
  renderRightView?: () => ReactNode;
}

const GraphFilterSection = ({ title, renderRightView }: IProps) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Vega_Text text={title} fontWeight={600} fontSize={17} />.
      {renderRightView?.()}
    </Stack>
  );
};

export default GraphFilterSection;
