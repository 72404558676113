import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '..';

export interface IProps {
  header: string;
  iterationValue: string;
  isNavigate: boolean;
  showLendingPage: boolean;
  error: any;
}
const getShowLendingState = sessionStorage.getItem('isShowLending');
const initialState: IProps = {
  header: '',
  iterationValue: 'MONTHLY',
  isNavigate: false,
  showLendingPage: getShowLendingState
    ? getShowLendingState === 'false'
      ? false
      : true
    : true,

  error: {},
};

const stateSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeader: (state: IProps, action) => {
      const { payload } = action;
      state.header = payload;
    },
    setIsNavigate: (state: IProps, action) => {
      const { payload } = action;
      state.isNavigate = payload;
    },

    setError: (state: IProps, action) => {
      const { payload } = action;
      state.error = payload;
    },
    setIterationValue: (state: IProps, action) => {
      const { payload } = action;
      state.iterationValue = payload;
    },
    setShowLendingPage: (state: IProps, action) => {
      const { payload } = action;
      state.showLendingPage = payload;
      sessionStorage.setItem('isShowLending', payload);
    },
  },
});

export const {
  setHeader,
  setIsNavigate,
  setError,
  setIterationValue,
  setShowLendingPage,
} = stateSlice.actions;
export const getState = (state: AppState) => state.header;
export default stateSlice;
