import { Typography } from '@mui/material';
import { useState } from 'react';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';

import { GridColDef } from '@mui/x-data-grid';
import VegaLabel from '../../../../components/common/VegaLabel';
import { URLtoFile } from '../../../../constants/commonFunction';
import { CheckerMakerStatusEnum } from '../../../../enums/commons';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import CustomerService from '../../../../services/CustomerService';
import { ICustomer } from '../../../../types/customers';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { ICustomerDetailsProps } from '../../../Back-office/components/MCCAplicationManagement/ListOfMCCcustomer';
import ViewCustomer from '../../../Back-office/components/MCCAplicationManagement/ViewCustomer';
import { dateFormat } from '../../../variable';

interface IProps {
  data: ICustomer[];
  loading: boolean;
  getCustomerList: () => void;
}

const ListOfCustomersInCorporate = ({
  data,
  loading,
  getCustomerList,
}: IProps) => {
  const { userId = '' } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [customerDetails, setCustomerDetails] =
    useState<ICustomerDetailsProps | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');

  const columnDef: GridColDef[] = [
    {
      field: 'firstName',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Customer Name
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        const fName = props.row.firstName !== null ? props.row.firstName : '';
        const mName = props.row.middleName !== null ? props.row.middleName : '';
        const lName = props.row.lastName !== null ? props.row.lastName : '';
        const customerName = fName + ' ' + mName + ' ' + lName;
        return <Typography>{customerName}</Typography>;
      },
    },
    // {
    //   field: 'afterTransactionBalance',
    //   renderHeader : () => <Typography variant="body2" className="font-aspekta-500">Balance After Txn</Typography>,

    //   flex: 0.7,
    // },
    {
      field: 'mobileNumber',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Mobile No
        </Typography>
      ),
      flex: 0.5,
    },
    {
      field: 'emailId',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Email ID
        </Typography>
      ),
      flex: 0.7,
    },
    {
      field: 'dob',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          DOB
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <VegaLabel text={dateFormat(props.row?.dob ?? '-')} color="primary" />
        );
      },
    },
    // {
    //   field: 'status',
    //   renderHeader : () => <Typography variant="body2" className="font-aspekta-500">Status</Typography>,
    //   flex: 0.7,
    //   renderCell: (props: any) => {
    //     return (
    //       <VegaLabel
    //         text={toLowerCase(props.row?.status ?? '-')}
    //         color="primary"
    //       />
    //     );
    //   },
    // },

    {
      field: 'action2',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return props.row?.status === 'DRAFT' ? (
          <VegaContainedButton
            text={'Send to Reviewer'}
            onClick={() => sendToReviwer(props.row)}
            isPrimary
            px={2.3}
          />
        ) : props.row?.status === 'UNDER_REVIEW' &&
          props.row?.checkerMakerStatus === 'CHANGES_REQUESTED' ? (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => updateChecker(props.row)}
            isPrimary
            px={3}
          />
        ) : props.row?.status === 'UNDER_REVIEW' &&
          props.row?.checkerMakerStatus === 'REVIEWER_PENDING' ? (
          <VegaContainedButton text="Sent to Reviewer" isPrimary disabled />
        ) : props.row?.status === 'ACTIVE' &&
          props.row?.makerCheckerStatus === 'APPROVED' ? (
          <VegaContainedButton text="Approved" isSuccess disabled />
        ) : (
          <VegaContainedButton text="Sent to Checker" isPrimary disabled />
        );
      },
      flex: 0.7,
    },

    {
      field: 'action',
      renderHeader: () => (
        <Typography variant="body2" className="font-aspekta-500">
          View/Edit
        </Typography>
      ),
      renderCell: (props: any) => {
        return props.row?.checkerMakerStatus === 'CHECKER_PENDING' ? (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(props.row, 'view')}
            isPrimary
          />
        ) : (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(props.row, 'edit')}
            isPrimary
          />
        );
      },
      flex: 0.5,
    },
  ];

  const sendToReviwer = async (data: ICustomer) => {
    CustomerService.initiateCheckerMaker({
      userId: userId || '',
      entityId: data.customerId,
      initialStatus: CheckerMakerStatusEnum.REVIEWER_PENDING,
    })
      .then((res) => {
        setSnackbar('Send Successfully!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send'),
          'error'
        );
      });
  };
  const sendChecker = async (data: ICustomer) => {
    await CustomerService.initiateCheckerMaker({
      userId: userId || '',

      entityId: data.customerId,
    })
      .then((res) => {
        setSnackbar('Send Successfully!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send'),
          'error'
        );
      });
  };
  const updateChecker = async (data: ICustomer) => {
    CustomerService.updateCheckerMaker({
      userId: userId || '',
      entityId: data.customerId,
      status: CheckerMakerStatusEnum.CHECKER_PENDING,
    })
      .then((res) => {
        setSnackbar('Update status Successfully!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to update'),
          'error'
        );
      });
  };

  // const handleChecked = async (e: any, data: any) => {
  //   updateCustomerStatusForMCC({
  //     customerId: data.id,
  //     status: e.target.checked ? 'ACTIVE' : 'INACTIVE',
  //   })
  //     .then((res) => {
  //       setSnackbar('Change Status Successfully!');
  //       getCustomerList();
  //     })
  //     .catch((err) => {
  //       setSnackbar(
  //         getErrorMessageFromErrorObj(err, 'Failed to status change'),
  //         'error'
  //       );
  //     });
  // };

  const handleChange = (name: keyof ICustomerDetailsProps, value: any) => {
    if (name === 'passportFile' || name === 'kycFile' || name === 'panFile') {
      let file = value[0];
      const fileURL = URL.createObjectURL(file);
      file.fileURL = fileURL;

      setCustomerDetails((pre) => {
        return pre
          ? {
              ...pre,
              [name]: fileURL,
            }
          : null;
      });
    } else {
      setCustomerDetails((pre) => {
        return pre
          ? {
              ...pre,
              [name]: value,
            }
          : null;
      });
    }
  };

  const showDetails = (data: ICustomerDetailsProps, status: string) => {
    setStatus(status);
    setCustomerDetails(data);
    setOpen(!open);
  };
  const decline = () => {
    setOpen(!open);
  };
  const OnUpdate = async () => {
    const formData = new FormData();
    console.log('customerDetails', customerDetails);
    const panFile = await URLtoFile(customerDetails?.panFile);
    const kycFile = await URLtoFile(customerDetails?.kycFile);
    const passportFile = await URLtoFile(customerDetails?.passportFile);
    const newObj: any = {
      id: customerDetails?.customerId,
      emailId: customerDetails?.emailId,
      firstName: customerDetails?.firstName,
      mobileNumber: customerDetails?.mobileNumber,
      panFile,
      kycFile,
      passportFile,
    };
    Object.keys(newObj).map((value: any) => {
      formData.append(value, newObj[value]);
    });

    try {
      await CustomerService.updateCustomerInfo({
        customerId: customerDetails?.customerId || '',
        title: customerDetails?.title || '',
        firstName: customerDetails?.firstName || '',
        middleName: customerDetails?.middleName || '',
        lastName: customerDetails?.lastName || '',
        countryCode: customerDetails?.countryCode || '',
        mobileNumber: customerDetails?.mobileNumber || '',
        emailId: customerDetails?.emailId || '',
        dob: customerDetails?.dob || '',
        gender: customerDetails?.gender || '',
      });
      if (customerDetails) {
        await CustomerService.uploadCustomerDocs({
          customerId: customerDetails.customerId,
          panFile: customerDetails.panFile,
          passportFile: customerDetails.passportFile,
          kycFile: customerDetails.kycFile,
          ticketFile: customerDetails.ticketFile,
          visaFile: customerDetails.visaFile,
        });
      }
      setSnackbar('Update Successfully!');
    } catch (err) {
      setSnackbar('Error in customer update', 'error');
    } finally {
      setOpen(!open);
      getCustomerList();
    }

    // updateCustomerForMCC(newObj)
    //   .then((res) => {
    //     setSnackbar('Update Successfully!');
    //     getCustomerList();
    //   })
    //   .catch((err) => {
    //     setSnackbar(
    //       getErrorMessageFromErrorObj(err, 'Failed to update'),
    //       'error'
    //     );
    //   });
    // setOpen(!open);
  };
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={data}
        columnDef={columnDef}
        idColumn={'customerId'}
        loading={loading}
      />
      <VegaDrawer
        open={open}
        header={'Customer Details'}
        closeText="Decline"
        isClose
        isSubmit={status === 'edit'}
        submitText={status === 'edit' && 'Save'}
        onClose={decline}
        onSubmit={OnUpdate}
      >
        <ViewCustomer
          customer={customerDetails}
          handleChange={handleChange}
          show={status === 'view'}
        />
      </VegaDrawer>
    </VegaCard>
  );
};

export default ListOfCustomersInCorporate;
