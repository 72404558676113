import { ISVGProps } from '../../mcc/types/Commons';

const MessageIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? '16'}
      height={props.height ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.8001 4.8001H10.4001M4.8001 8.0001H8.0001M7.7914 11.061L4.45227 14.4001V11.061H3.2001C2.31644 11.061 1.6001 10.3446 1.6001 9.46097V3.2001C1.6001 2.31644 2.31644 1.6001 3.2001 1.6001H12.8001C13.6838 1.6001 14.4001 2.31644 14.4001 3.2001V9.46096C14.4001 10.3446 13.6838 11.061 12.8001 11.061H7.7914Z"
        stroke="#1047DC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MessageIcon;
