/* eslint-disable react/jsx-pascal-case */
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useClientAuth } from '../../providers/ClientProvider';
import {
  CardThresholdPolicy,
  CardThresholdV2Policy,
  PolicyType,
} from '../../types/policy';
import { PolicyService } from '../apis';
import { CIMS_BORDER, CIMS_PRIMARY } from '../assets/style';
import CIMS_Header from '../components/common/CIMS_Header';
import CimsButton from '../components/common/CimsButton';
import BCCardThreshold from '../modules/Policies/BCCardThreshold';

export const Policies = () => {
  const [toggleState, setToggleState] = useState<string>('POLICIES');
  const { clientId } = useClientAuth();

  const listPolicyQuery = useQuery({
    queryKey: ['list_policies'],
    queryFn: () => {
      return PolicyService.listPolicies({
        clientId: clientId || '',
        policyType: PolicyType.CARD_THRESHOLD_V2_POLICY,
        page: 0,
        pageSize: 1,
      });
    },
    select: (data) => {
      return data.records[0] as CardThresholdV2Policy;
    },
    enabled: !!clientId,
  });

  const onToggle = (stateValue: string) => {
    setToggleState(stateValue);
  };

  return (
    <>
      <Box>
        <CIMS_Header
          text="Policies"
          nestedText={
            toggleState === 'CONFIG_POLICIES' ? 'Configure Policy' : ''
          }
          onClick={() => onToggle('POLICIES')}
        />
      </Box>

      {toggleState === 'CONFIG_POLICIES' ? (
        <Box
          sx={{
            p: 2,
          }}
        >
          <BCCardThreshold />
        </Box>
      ) : (
        <Box
          sx={{
            mx: 2,
            mt: 4,
            p: 2,
            border: CIMS_BORDER.DARKGRAY,
            borderRadius: '6px',
            bgcolor: 'white',
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack>
              <Typography
                className="font-aspekta-500"
                fontSize={14}
                color={CIMS_PRIMARY.BLACK}
              >
                Threshold Policy
              </Typography>
              <Typography
                fontSize={12}
                className="font-aspekta-300"
                color={CIMS_PRIMARY.BLACK}
              >
                Configure alert for low card inventory
              </Typography>
            </Stack>

            {listPolicyQuery.data ? (
              <CimsButton onClick={() => onToggle('CONFIG_POLICIES')}>
                View / Edit
              </CimsButton>
            ) : (
              <CimsButton
                variant="outlined"
                onClick={() => onToggle('CONFIG_POLICIES')}
              >
                Configure Policy
              </CimsButton>
            )}
          </Stack>
          <Divider
            sx={{
              mt: 1.5,
            }}
          />
          <Box
            sx={{
              py: 2,
            }}
          >
            <Stack direction={'row'} gap={1}>
              <Typography
                className="font-aspekta-500"
                fontSize={13}
                color={CIMS_PRIMARY.GRAY}
              >
                Auto Order :
              </Typography>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography
                  className="font-aspekta-500"
                  fontSize={13}
                  color={'#222222'}
                >
                  Not Activated
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};
