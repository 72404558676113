import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { GREY, GREEN } from '../../../constants/style';
import {
  VegaKycBaseStep,
  RADIO_OPTIONS,
  KycStepStatus,
  VegaKycStepPartner,
  VegaKycStepField,
} from './KYCFlowDataTypes';
import { KycServicePartners } from './KycServicePartners';
import { KycStepCard } from './KycStepCard';

export interface IKycStepAccordianContentProps {
  step: VegaKycBaseStep;
  updateWorkFlowDescion: (selectedWorkFlow: string | null) => void;
  updateSubStepData: (updatedStepData: VegaKycBaseStep) => void;
  updatePartnerStatus: (
    selectedParter: VegaKycStepPartner,
    updatedStatus: KycStepStatus
  ) => void;
}

export function KycStepAccordianContent({
  step,
  updatePartnerStatus,
  updateWorkFlowDescion,
  updateSubStepData,
}: IKycStepAccordianContentProps) {
  const [partners, setPartners] = useState<VegaKycStepPartner[]>([]);
  const [fileds, setFields] = useState<VegaKycStepField[]>([]);
  const [subSteps, setSubSteps] = useState<VegaKycBaseStep[]>([]);

  const getDisabledWorkFlowOptions = () => {
    if (!partners) return [];
    const partnersSelectedCount = partners.filter(
      (item) => item.status == KycStepStatus.ACTIVE
    ).length;
    if (partnersSelectedCount > 1) {
      return [RADIO_OPTIONS[0]];
    } else if (partnersSelectedCount == 1) {
      return [RADIO_OPTIONS[1], RADIO_OPTIONS[2]];
    }
    return RADIO_OPTIONS;
  };

  useEffect(() => {
    setPartners(step.metaData.partners ?? []);
    setSubSteps(step.metaData.subSteps ?? []);
    setFields(step.metaData.fields ?? []);
  }, [step]);

  return (
    <Box>
      {partners.length > 0 && (
        <KycServicePartners
          partners={partners}
          selectedWorkFlow={step.workFlowDecision}
          onWorkFlowChange={(selectedWorkFlow) =>
            updateWorkFlowDescion(selectedWorkFlow)
          }
          workFlows={RADIO_OPTIONS}
          disabledWorkFlows={getDisabledWorkFlowOptions()}
          addPartner={(partner) => {
            updatePartnerStatus(partner, KycStepStatus.ACTIVE);
          }}
          removePartner={(partner) => {
            updatePartnerStatus(partner, KycStepStatus.INACTIVE);
          }}
        />
      )}

      {subSteps.map((item) => {
        return (
          <Grid spacing={2} container sx={{ py: 2 }} key={item.name}>
            <KycStepCard
              step={item}
              showStepCard={false}
              updateStepData={updateSubStepData}
            />
          </Grid>
        );
      })}

      {fileds.map((item) => {
        return (
          <FormControlLabel
            key={item.name}
            control={
              <Checkbox
                checked={true}
                sx={{
                  color: GREY.dark,
                  '&.Mui-checked': {
                    color: GREEN.dark,
                  },
                }}
              />
            }
            label={_.startCase(_.toLower(item.name))}
            sx={{ fontSize: '14px' }}
          />
        );
      })}
    </Box>
  );
}
