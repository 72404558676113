import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getDelinquencySummaryByBucket } from '../../../api/collections';
import { BLUE, BORDER, GREEN, GREY } from '../../../constants/style';
import { DelinquencySummary } from '../../../types/collection';
import { VegaCard } from '../../common';
import VegaText from '../../common/VegaText';
import DPDSelector from './DPDSelector';
import SummaryCards from './SummaryCards';

const DPD_DEFAULT = [90, 180, 360];

const Summary = () => {
  const [selectedDPD, setSelectedDPD] = useState(DPD_DEFAULT[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDpdSummary, setSelectedDpdSummary] =
    useState<DelinquencySummary | null>(null);

  const onSelectDPD = (selected: number) => {
    setSelectedDPD(selected);
  };

  function fetchSummaryForDpd(dpd: number) {
    setLoading(true);
    getDelinquencySummaryByBucket({ dpd: dpd.toString() })
      .then((res) => {
        setSelectedDpdSummary(res);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSummaryForDpd(selectedDPD);
  }, [selectedDPD]);

  return (
    <VegaCard fullHeight noPad>
      <Box
        sx={{
          px: 3,
          py: 2,
          bgcolor: GREY.light,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        }}
      >
        <VegaText text="Summary (Till Date)" />
      </Box>
      <Box sx={{ p: 3 }}>
        <DPDSelector
          data={DPD_DEFAULT}
          onSelect={onSelectDPD}
          state={selectedDPD}
        />
      </Box>
      <Box sx={{ p: 3 }}>
        <SummaryCards summary={selectedDpdSummary} loading={loading} />
      </Box>
    </VegaCard>
  );
};

export default Summary;
