import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { VegaCard } from '../../../../components/common';
import { IBinProps } from '../../../types/Bin';
import ListOfAllocatedBinsForAClient from './ListOfAllocatedBinsForAClient';
import ListOfInventoryManagementForClient from './ListOfInventoryManagementForClient';
import { BinAllocation } from '../../../../types/card';
import { IFilterProps } from '../../../Back-office/pages/MCCAplicationManagement';

const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: 'black',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type IExtAllocatedBins = {
  branchName: string;
} & BinAllocation;

interface IProps {
  data: IBinProps[];
  loading: boolean;
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  page: number;
  size: number;
  rowCount: number;
  filterData: IFilterProps;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}

const ListOfInventoryMangement = ({
  data,
  loading,
  tabValue,
  handleTabChange,
  page,
  size,
  rowCount,
  filterData,
  onPageChange,
  onPageSizeChange,
}: IProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{}}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <StyledTab label="Headquater" />
          <StyledTab label="By Branch" />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <VegaCard fullHeight noPad>
          <ListOfInventoryManagementForClient
            page={page}
            size={size}
            rowCount={rowCount}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            data={data}
            loading={loading}
          />
        </VegaCard>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <VegaCard fullHeight noPad>
          <ListOfAllocatedBinsForAClient
            loading={loading}
            filterData={filterData}
          />
        </VegaCard>
      </TabPanel>
    </Box>
  );
};
export default ListOfInventoryMangement;
