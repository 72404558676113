import { styled, TextField, TextFieldProps } from '@mui/material';

interface VegaTextFieldPropsV2 {}

const StyledTextField = styled(TextField)(({ theme, multiline }) => {
  return {
    '& .MuiInputBase-root': {
      minHeight: '32px',
      maxHeight: '32px',
      borderRadius: '.5rem',
      ...(multiline && {
        maxHeight: 'none',
      }),
      '& .MuiInputBase-input': {
        fontFamily: `var(--font-Aspekta-450) !important`,
        lineHeight: '120%',
        fontSize: '13px',
        color: 'rgba(58, 74, 95, 1)',
      },
      '&:focus fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
      },
      '&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
      },
      '&:hover fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
      },
    },
  };
});

function VegaTextFieldV2({ ...rest }: VegaTextFieldPropsV2 & TextFieldProps) {
  return <StyledTextField {...rest} />;
}

export default VegaTextFieldV2;
