import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { Grid } from '@mui/material';
import { useClientAuth } from '../../../providers/ClientProvider';
import { fetchReportData } from '../../Back-office/api/report';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const HQAnalytics = () => {
  const [listLoading, setListLoading] = useState<boolean>(false);
  let { clientId, scope } = useClientAuth();
  const [fundReportData, setFundReportData] = useState<string[][]>([[]]);
  const [accountReportData, setAccountReportData] = useState<string[][]>([[]]);
  const [refundReportData, setRefundReportData] = useState<string[][]>([[]]);
  const getReportData = async () => {
    setListLoading(true);
    fetchReportData({
      reportName: 'HQ_FUND_RELOAD',
      entityId: clientId
    })
      .then((res) => {
        setFundReportData(res.data);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  const getCardRefundData = async () => {
    setListLoading(true);
    fetchReportData({
      reportName: 'HQ_CARD_REFUND',
      entityId: clientId
    })
      .then((res) => {
        setRefundReportData(res.data);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getReportData();
    getCardRefundData();
  }, []);
  console.log("clientIdAnalytics: ", clientId);
  console.log("fundReportData:: ",fundReportData);
  console.log('refundReportData: ', refundReportData);
  const pieChartData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  let DRAFT_TRANSACTIONS = 0, UNDER_REVIEW_TRANSACTION = 0, APPROVED_TRANSACTION = 0;
  
  if(fundReportData.length > 0) {
    const fundStatusIndex = (fundReportData[0])?.indexOf('fund_load_order_status')
    fundReportData.map(d => {
      if(d[fundStatusIndex] == 'DRAFT') {
        DRAFT_TRANSACTIONS++;
      }
      if(d[fundStatusIndex] == 'UNDER_REVIEW') {
        UNDER_REVIEW_TRANSACTION++;
      }
      if(d[fundStatusIndex] == 'FUND_RELOADED') {
        APPROVED_TRANSACTION++;
      }
    })

  }

  // DRAFT, UNDER_REVIEW, CARD_REFUNDED, REJECTED
  let DRAFT_REFUND = 0, UNDER_REVIEW_REFUND = 0, APPROVED_REFUND = 0, REJECTED_REFUND = 0;
  
  if(refundReportData.length > 0) {
    const refundStatusIndex = (refundReportData[0])?.indexOf('fund_load_order_status')
    refundReportData.map(d => {
      if(d[refundStatusIndex] == 'DRAFT') {
        DRAFT_REFUND++;
      }
      if(d[refundStatusIndex] == 'UNDER_REVIEW') {
        UNDER_REVIEW_REFUND++;
      }
      if(d[refundStatusIndex] == 'CARD_REFUNDED') {
        APPROVED_REFUND++;
      }
      if(d[refundStatusIndex] == 'REJECTED') {
        REJECTED_REFUND++;
      }
    })

  }

  const transactionStatusLabels = ['Draft', 'Under Review', 'Fund Loaded'];
  const accountStatusLabels = [
    'Draft',
    'Under Review',
    'Active',
    'Inactive',
    'Rejected'
  ];
  const refundStatusLabels = ['Draft', 'Under Review', 'Approved', 'Rejected']

  const transactionData = {
    labels: transactionStatusLabels,
    datasets: [
      {
        label: 'Fund Load Request',
        data: [DRAFT_TRANSACTIONS, UNDER_REVIEW_TRANSACTION, APPROVED_TRANSACTION],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }
    ],
  };


  const refundData = {
    labels: refundStatusLabels,
    datasets: [
      {
        label: 'Refunds',
        data: [DRAFT_REFUND, UNDER_REVIEW_REFUND, APPROVED_REFUND, REJECTED_REFUND],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };

  
  const transactionOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Card Reload',
      },
    },
  };

  const refundtransactionOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Card Refund',
      },
    },
  };

  const accountOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Account',
      },
    },
  };

  return (
    <div>
      <Grid container spacing={4}  mb={6}>
        <Grid item xs={6} bgcolor={'white'}>
          <Bar options={transactionOptions} data={transactionData} />
        </Grid>
        <Grid item xs={6} bgcolor={'white'}>
        <Bar options={refundtransactionOptions} data={refundData} />
        </Grid>
      </Grid>
      {/* <Grid container spacing={4} columns={{ xs: 4, md: 12 }}>
        <Grid item xs={6} bgcolor={'white'}> 
          <Bar options={refundtransactionOptions} data={refundData} />
        </Grid>
        <Grid item xs={6} bgcolor={'white'}>
        <Pie data={pieChartData} width={"10%"} options={{ maintainAspectRatio: false }}/>
        </Grid>
      </Grid> */}
      
    </div>
  );
}

export default HQAnalytics;
