import { Box } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridColumns,
  GridFeatureMode,
} from '@mui/x-data-grid';

type Props = {
  data: readonly any[];
  columns: GridColumns<any>;
  page?: number;
  pageSize?: number;
  rowCount?: number;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
  loading?: boolean;
  idColumn: string;
  bgColor?: string;
  paginationMode?: GridFeatureMode;
} & Partial<DataGridProps>;

function Vega_Datagrid({
  data,
  columns,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  loading,
  rowCount,
  paginationMode,
  idColumn,
  bgColor,
  ...rest
}: Props) {
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            overflowX: 'scroll',
            border: 'none',
            '& ::-webkit-scrollbar': {
              width: '2px !important',
              height: '2px !important',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: 'transparent !important',
            },
            '& .MuiDataGrid-main': {
              border: '1px solid #E1E4EB',
              borderRadius: '0.75rem',
            },
            '& .MuiDataGrid-row': {
              bgcolor: 'white',
            },
            '& .MuiDataGrid-row:hover': {
              bgcolor: 'white',
            },
            '& .MuiDataGrid-cellContent, .MuiDataGrid-cell p ': {
              color: '#1B1D22',
              fontSize: '13px',
              fontFamily: `var(--font-Aspekta-500) !important`,
            },

            '& .MuiDataGrid-columnHeaders': {
              borderTop: 'none !important',
              borderBottom: '1px solid #E1E4EB',
              backgroundColor: '#F7F7F7',
              maxHeight: '2.75rem !important',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '13px',
              textTransform: 'capitalize',

              lineHeight: '124%',
              color: '#676B76',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none !important',
            },
            '& .MuiDataGrid-sortIcon': {
              display: 'none !important',
            },
            '& .MuiTablePagination-toolbar': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '13px',
              '& .MuiTablePagination-select': {
                marginTop: '2px',
                paddingBottom: '0px',
              },
            },

            '& .MuiTablePagination-selectLabel': {
              fontFamily: `var(--font-Aspekta-700) !important`,
              fontSize: '10px',
              color: '#5F6D7E',
            },

            '& .MuiTablePagination-displayedRows, .MuiTablePagination-select, .MuiTablePagination-menuItem':
              {
                fontFamily: `var(--font-Aspekta-600) !important`,
                fontSize: '10px',
                color: '#5F6D7E',
              },

            '& .MuiDataGrid-footerContainer': {
              border: 'none !important',
              backgroundColor: bgColor ?? '#FFFFF',
              margin: '0px !important',
              padding: '0px !important',
              minHeight: '40px !important',
            },
          }}
          //   components={{
          //     Pagination: CustomPagination,
          //   }}
          showCellRightBorder={false}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          rowHeight={56}
          classes={{ withBorder: 'no-border' }}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          rowSpacingType="margin"
          onRowClick={(params) => {}}
          page={page}
          pageSize={pageSize ?? 10}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          loading={loading}
          rowCount={rowCount}
          paginationMode={paginationMode ?? 'client'}
          rowsPerPageOptions={paginationMode ? [5, 10, 15] : []}
          pagination={true}
          getRowId={(row: any) => {
            return row[idColumn];
          }}
          getRowClassName={(params) => {
            return `${
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'vegatable--white'
                : 'vegatable--blue'
            }`;
          }}
          {...rest}
        />
      </Box>
    </div>
  );
}

export default Vega_Datagrid;
