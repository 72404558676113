// Hack to navigate back to rewards home if program selection changes

import { createContext, useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { NESTED_VIEW, RewardsRoute } from '../routes';
import { useProgramData } from './ProgramDataProvider';

type RewardsNavigationContextType = {
  currentRoute: string;
  navigateTo: (route: RewardsRoute) => void;
};

const RewardsNavigationContext =
  createContext<RewardsNavigationContextType | null>(null);

export const useRewardsNavigation = () =>
  useContext(RewardsNavigationContext) as RewardsNavigationContextType;

export const RewardsNavigationProvider = ({ children }: any) => {
  const [currentRoute, setCurrentRoute] = useState<string>(
    RewardsRoute.REWARDS_HOME
  );
  const { selectedProgram } = useProgramData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  function _navigateTo(route: RewardsRoute) {
    setSearchParams(createSearchParams({ [NESTED_VIEW]: route }));
  }

  useEffect(() => {
    const params = new URLSearchParams(search);
    const queryParams = Object.fromEntries(params.entries());
    const view = queryParams.nested_view;
    setCurrentRoute(view);
  }, [searchParams]);

  useEffect(() => {
    _navigateTo(RewardsRoute.REWARDS_HOME);
  }, [selectedProgram]);

  return (
    <RewardsNavigationContext.Provider
      value={{
        currentRoute: currentRoute,
        navigateTo: _navigateTo,
      }}
    >
      {children}
    </RewardsNavigationContext.Provider>
  );
};
