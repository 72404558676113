import { Merchant } from './resources';

export enum RewardType {
  POINT_BASED = 'POINT_BASED',
  NON_POINT_BASED = 'NON_POINT_BASED',
  REDEMPTION = 'REDEMPTION',
}
export const VOUCHERS_TYPE = [
  'GIFT_VOUCHER',
  'DISCOUNT_VOUCHER',
  'SUBSCRIPTION',
];
export const TIMEFRAME = ['MONTHLY', 'QUARTERLY', 'ANNUALLY'];
export const TIME = ['DAY', 'MONTH', 'YEAR'];
export const RULE_TYPE = ['TRANSACTIONAL', 'NON_TRANSACTIONAL', 'MILESTONE'];
export const CONDITION_TYPE = ['INCLUSION', 'EXCLUSION'];
export const TAX_TYPE = ['INCLUSIVE_OF_TAXES', 'EXCLUSIVE_OF_TAXES'];
export const MILESTONE_TYPE = ['BY_VOLUME', 'BY_COUNT', 'BOTH'];
export const EVENT_TYPE = [
  'SPENDS',
  'USUAGE',
  'ACCOUNT_CREATION',
  'ANNUAL_MEMBERSHIP_PAID',
  'FIRST_TRANSACTION_DONE',
  'FIRST_BILL_PAID ',
  'SIX_BILLS_TADPAID ',
];

export enum VouchersType {
  GIFT_VOUCHER = 'GIFT_VOUCHER',
  DISCOUNT_COUPON = 'DISCOUNT_VOUCHER',
  SUBSCRIPTION = 'SUBSCRIPTION',
}
export enum ConditionType {
  INCLUSION = 'INCLUSION',
  EXCLUSION = 'EXCLUSION',
}
export enum TaxType {
  INCLUSIVE_OF_TAXES = 'INCLUSIVE_OF_TAXES',
  EXCLUSIVE_OF_TAXES = 'EXCLUSIVE_OF_TAXES',
}
export enum Timeframe {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}
export enum RuleType {
  TRANSACTIONAL = 'TRANSACTIONAL',
  NON_TRANSACTIONAL = 'NON_TRANSACTIONAL',
  MILESTONE = 'MILESTONE',
}
export enum MilestoneType {
  BY_VOLUME = 'BY_VOLUME',
  BY_COUNT = 'BY_COUNT',
  BOTH = 'BOTH',
}

export enum EventType {
  SPENDS = 'SPENDS',
  USUAGE = 'USUAGE',
  ACCOUNT_CREATION = 'ACCOUNT_CREATION',
  ANNUAL_MEMBERSHIP_PAID = 'ANNUAL_MEMBERSHIP_PAID',
  FIRST_TRANSACTION_DONE = 'FIRST_TRANSACTION_DONE',
  FIRST_BILL_PAID = 'FIRST_BILL_PAID',
  SIX_BILLS_TADPAID = 'SIX_BILLS_TADPAID',
}

export interface ICampaignSelectorProps {
  title: string;
  text: string;
  IconImage: React.ReactNode;
  bgColor: string;
  path?: any;
}
export interface IBaseRuleProps {
  rewardPoint: number;
  amount: number;
  maximumPoint: number;
  iteration: string;
}
export interface IRedemptionDetailsProps {
  minimumRedeptionPoint: number;
  maximumRedeptionPoint: number;
  redemptionDelay: any;
  expiry: any;
  iteration: string;
}

export interface ICampaignDataProps {
  id?: string;
  name?: string;
  campaignImageLink?: string | null;
  programName: string;
  programId?: string;
  campaignName?: string | undefined;
  campaignType?: string;
  redemptionName?: string;
  redemptionType?: string;
  logo?: any;
  createdAt?: string;
  status: string;
}

export interface IRuleValuesProps {
  points: number;
  onSpends: number;
  volume: number | string | null;
  maximumPoint: number | string;
  timeframe: string;
  status: string;
}

export interface IMilestoneRuleProps {
  values: IRuleValuesProps | null;
  giftsLists: IGiftListProps[] | any;
  event: string;
  status: string;
  mileStoneType: string;
}

export interface IFinancialListPorps {
  tenureType: string;
  price: number;
  taxationType: string;
}
export interface IContributionListPorps {
  enable: boolean;
  contributorType: string;
  name: string;
  percentage: number;
}
export interface IContributionProps {
  contributionEntities: IContributionListPorps[];
}
export interface IVouchersProps {
  company: string;
  price: number;
  delay: string;
}

export interface ITransactionCodeProps {
  transactionCodeList: string[];
  filterType: string;
}
export interface IMerchantCategoryProps {
  merchantCategoryList: string[];
  filterType: string;
}
export interface IMCCProps {
  midList: string[] | number[];
  filterType: string;
}

export interface IFilterDtoProps {
  minimumAmount: number;
  transactionCode: ITransactionCodeProps;
  merchantCategory: IMerchantCategoryProps;
  mid: IMCCProps;
}
export interface IGiftListProps {
  giftId: string;
  vendorName: string;
  description: string;
  giftType: string;
  delayDays: number;
  amount?: number;
  isGiftCanAvailMultipleTimes: boolean;
}

export interface IPointBaseRulesProps {
  id?: string;
  ruleName: string;
  campaignId: string;
  ruleType: string;
  event: string;
  isCampaignLimitApplied: boolean;
  paymentType: string;
  values: IRuleValuesProps | any;
  mileStoneList: IMilestoneRuleProps[] | any;
  giftsLists: IGiftListProps[] | any;
  // giftsVouchersLists: IVouchersProps[] | any[];
  // discountCouponsLists: any;
  // subscriptionsLists: any;
  filters: IFilterDtoProps | any;
  financialList: IFinancialListPorps[];
  contribution: IContributionProps;
  status: string;
  segment?: string;
}

export interface ITimeselectorProps {
  enable: boolean;
  number: number;
  temporalUnit: string;
}
export interface IRedemptionRuleProps {
  minRedemptionPoint: number;
  maxRedemptionPoint: number;
  redemptionDelay: ITimeselectorProps;
  duration: string;
  expiry: ITimeselectorProps;
}

export interface ICreateCampaignProps {
  programName?: string;
  campaignName: string;
  programId: string;
  campaignType: string;
  rewardPoint: number;
  perAmount: number;
  maximumPoint: number;
  timeframe: string;
  redemptionType: string;
  status: string;
  redemptionRule: IRedemptionRuleProps;
}
export interface Campaign {
  id: string;
  campaignImageLink?: string | null;
  campaignName: string;
  programId: string;
  programName?: string;
  rewardPoint?: number;
  perAmount?: number;
  maximumPoint?: number;
  timeframe?: string;
  campaignType: string;
  redemptionType: string;
  status: string;
  imageLink: any;
  redemptionRule: CampaignRedemptionRule;
  ruleList: CampaignRule[];
  createdAt?: string;
}

export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}

export interface CampaignRedemptionRule {
  minRedemptionPoint: number;
  maxRedemptionPoint: number;
  duration: string;
  redemptionDelay?: RedemptionTimeConfiguration;
  expiry?: RedemptionTimeConfiguration;
}

export type AddBaseRuleFormData = {
  rewardPoint: number;
  perAmount: number;
  maximumPoint: number;
  iteration: string;
};

export interface RedemptionTimeConfiguration {
  enable: boolean;
  number?: number;
  temporalUnit?: string;
}

////////////////////////// MERCHENT \\\\\\\\\\\\\\\\\\\\\\\\\\\
export interface IMidsProps {
  id: string;
  mid: number;
}
export interface ITidsProps {
  id: string;
  tid: number;
}
export interface IMccCodesProps {
  code: number;
  description: string;
  category: string;
  paymentBrands: string[];
  logo: string | null;
}
export interface IMerchantDataProps {
  id: string;
  name: string;
  brandName: string | null;
  logo: string | null;
  mids: IMidsProps[];
  tids: ITidsProps[];
  mccCodes: IMccCodesProps[];
}
////////////////////////// MERCHENT \\\\\\\\\\\\\\\\\\\\\\\\\\\

////////////////////////// INVENTORY \\\\\\\\\\\\\\\\\\\\\\\\\\\

export const GIFT_TYPE = ['GIFT_VOUCHER', 'DISCOUNT_VOUCHER', 'SUBSCRIPTION'];
export enum GiftType {
  GIFT_VOUCHER = 'GIFT_VOUCHER',
  DISCOUNT_VOUCHER = 'DISCOUNT_VOUCHER',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface IGiftVoucherProps {
  amount: number;
}
export interface IDiscountVoucherProps {
  number: number;
  valueType: string;
}
export interface ISubscriptionProps {
  number: number;
  timeframe: string;
}

export interface ICreateInventoryProps {
  id?: string | undefined;
  programId: string;
  vendorId: string;
  vendorName: string;
  giftType: string;
  giftVoucherAmount?: IGiftVoucherProps;
  discountVoucher?: IDiscountVoucherProps;
  subscribtion?: ISubscriptionProps;
  quantity: number;
  totalValue: number;
  assigned: number;
  remaining: number;
  autoRenew: boolean;
  [key: string]: any;
}

export enum CampaignType {
  POINT_BASED = 'POINT_BASED',
  NON_POINT_BASED = 'NON_POINT_BASED',
}

export enum CampaignRuleType {
  TRANSACTIONAL = 'TRANSACTIONAL',
  NON_TRANSACTIONAL = 'NON_TRANSACTIONAL',
  MILESTONE = 'MILESTONE',
}

export enum TransactionalEventType {
  SPENDS = 'SPENDS',
}

export enum NonTransactionalEventType {
  ACCOUNT_CREATION = 'ACCOUNT_CREATION',
  ANNUAL_MEMBERSHIP_PAID = 'ANNUAL_MEMBERSHIP_PAID',
  FIRST_TRANSACTION_DONE = 'FIRST_TRANSACTION_DONE',
  FIRST_BILL_PAID = 'FIRST_BILL_PAID',
  SIX_BILLS_TAD_PAID = 'SIX_BILLS_TAD_PAID',
}

export enum MilestoneEventType {
  BY_COUNT = 'BY_COUNT',
  BY_VOLUME = 'BY_VOLUME',
  BOTH = 'BOTH',
}

export interface PointBasedRewardsData {
  iteration: string;
  rewardPoints: number;
  perAmount?: number;
  maximumPoints?: number;
}

export interface RuleFinancialData {
  tenures: FinancialTenureData[];
}

export interface FinancialTenureData {
  tenureType: string;
  price: number;
  taxationType: string;
}

export enum FinancialTenureTaxType {
  INCLUSIVE = 'INCLUSIVE_OF_TAXES',
  EXCLUSIVE = 'EXCLUSIVE_OF_TAXES',
}

export interface ContributionData {
  contributionEntities: Contribution[];
}

export interface Contribution {
  contributorType: ContributionEntityType;
  percentage?: number;
  name?: string;
  enabled?: boolean;
}

export enum ContributionEntityType {
  BANK = 'BANK',
  CO_BRAND = 'CO_BRAND',
  PAYMENT_NETWORK = 'PAYMENT_NETWORK',
  MERCHANT = 'MERCHANT',
}

export enum IterationType {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}

export enum TransactionCategoryRewards {
  CARD_POS = 'CARD_POS',
  CARD_ECOM = 'CARD_ECOM',
  CARD_CASH_WITHDRAW = 'CARD_CASH_WITHDRAW',
  CARD_NFC = 'CARD_NFC',
}

export enum InclusionExclusionConditionType {
  INCLUSION = 'INCLUSION',
  EXCLUSION = 'EXCLUSION',
}

export interface InlcusionExclusionData {
  transactionAmount: number;
  isTransactionInclusive: boolean;
  isMerchantInclusive: boolean;
  isCategoriesInclusive: boolean;
  transactionTypes: string[];
  merchants: string[];
  categories: string[];
}

export interface PointBasedMilestone {
  count: number;
  volume: number;
  rewardPoints: number;
}

export interface NonPointBasedMilestone {
  count: number;
  volume: number;
  giftVouchers: GiftVoucher[];
  subscriptionVouchers: GiftVoucher[];
  discountVouchers: GiftVoucher[];
}

export enum RuleMonetizationType {
  FREE = 'FREE',
  PAID = 'PAID',
}

export interface CampaignRule {
  id?: string;
  ruleName: string;
  campaignId: string;
  ruleType: string;
  event?: string;
  paymentType: RuleMonetizationType;
  mileStoneList: CampaignMilestoneDto[];
  giftsLists: GiftVoucher[];
  filters: RuleFilters;
  values: RuleRewardData; // this is filled for all point based rules it can be transactional non transactional and milestone based rules
  financialList: FinancialTenureData[];
  contribution: ContributionData;
  createdAt?: string;
  updatedAt?: string;
}

export interface CampaignMilestoneDto {
  values: RuleRewardData;
  mileStoneType: string;
  giftsLists?: GiftVoucher[];
}

export interface RuleRewardData {
  points?: number;
  onSpends?: number;
  volume?: number;
  maximumPoint?: number;
  timeframe?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface RuleFilters {
  minimumAmount: number;
  transactionCode: {
    transactionCodeList: string[];
    filterType: InclusionExclusionConditionType;
  };
  merchantCategory: {
    merchantCategoryIdList: string[];
    filterType: InclusionExclusionConditionType;
  };
  merchantId: {
    merchantIdList: string[];
    filterType: InclusionExclusionConditionType;
  };
}

export interface GiftVoucher {
  giftId: string;
  vendorName: string;
  delayDays?: number;
  giftType: string;
}

export enum GiftVoucherType {
  GIFT_VOUCHER = 'GIFT_VOUCHER',
  DISCOUNT_VOUCHER = 'DISCOUNT_VOUCHER',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface VoucherInventory {
  id: string;
  programId: string;
  vendorId: string;
  vendorName: string;
  giftType: string;
  giftVoucherAmount: GiftVoucher;
  discountVoucher: DiscountVoucher;
  subscribtion: SubscriptionVoucher;
  quantity: number;
  totalValue: number;
  assigned: number;
  remaining: number;
  autoRenew: boolean;
}

export interface GiftVoucher {
  amount: number;
}
export interface DiscountVoucher {
  number: number;
  valueType: string;
}
export interface SubscriptionVoucher {
  number: number;
  timeFrame: string;
}

export enum RedemptionType {
  INSTANT = 'INSTANT',
  LATER = 'LATER',
}

export type AddNewCampaignFormData = {
  name: string;
  campaignType: string;
  redemptionType: string;
  logo?: File;
};

export type EditCampaignFormData = {
  name?: string;
  logo?: File;
};

export type AddRedemptionRuleFormData = {};
