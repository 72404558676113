import { API_BASE_URL } from '../../../api/axiosConfig';

export const BASE_URL = API_BASE_URL;
// export const BASE_URL =
//   'http://ec2-13-235-248-72.ap-south-1.compute.amazonaws.com:8511';
export const GETWAY = '/forex';

export const BRANCH = {
  BASE: '/branch',
};
export const PROGRAM = {
  BASE: '/program',
};
export const PLANS = {
  BASE: '/program/policy',
};
export const ROLES = {
  BASE: '/credential/v1/credential/role-management',
};
export const CUSTOMER = {
  BASE: '/customer',
};
