/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import Vega_PageHeader from '../../../components/common/v3/Vega_PageHeader';
import VegaTabBar from '../../../components/common/v2/VegaTabBar';
import VegaTabBarItem from '../../../components/common/v2/VegaTabBarItem';
import { BLUE } from '../../../constants/style';
import ThirdPartyInvoice from './modules/ThirdPartyInvoice';
import VegaPayInvoice from './modules/VegaPayInvoice';

const Invoice = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  return (
    <>
      <Vega_PageHeader sx={{ pb: 0.5, pt: 3 }} title="Invoice" />
      <Vega_PageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => (
          <VegaTabBar
            sx={{
              '&.MuiTabs-root, .MuiTabs-scroller, .MuiTabs-flexContainer': {
                minHeight: '30px',
              },
              '& .MuiButtonBase-root': {
                minHeight: '30px',
              },
            }}
            value={selectedTab}
            color={BLUE.lighter}
            onChange={(e, selected) => {
              setSelectedTab(selected);
            }}
          >
            <VegaTabBarItem
              color={BLUE.lighter}
              label="Vegapay Invoices "
            ></VegaTabBarItem>
            <VegaTabBarItem
              color={BLUE.lighter}
              label="3rd Party Invoices "
            ></VegaTabBarItem>
          </VegaTabBar>
        )}
      />
      {selectedTab === 1 ? <ThirdPartyInvoice /> : <VegaPayInvoice />}
    </>
  );
};

export default Invoice;
