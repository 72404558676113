export enum AcceptFileType {
  All = "*/*",
  Images = "image/*",
  Audio = "audio/*",
  Video = "video/*",
  Text = "text/*",
  PDF = "application/pdf",
  Excel = "application/vnd.ms-excel",
  Excel_xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  Word = "application/msword",
  PowerPoint = "application/vnd.ms-powerpoint",
  ZIP = "application/zip",
  JSON = "application/json",
  XML = "application/xml",
  CSV = "text/csv",
}
