/* eslint-disable react/jsx-pascal-case */
import { toLowerCase } from '../../../../constants/commonFunction';
import { CardAllotmentStatusEnum } from '../../../../types/card';
import CIMS_Text from '../../../components/common/CIMS_Text';
import { STATUS_COLORS } from '../../../theme/theme';

export const allotmentStatusMapping: Record<string, string> = {
  PLACED: 'ASSIGNED',
  REQUESTED: 'REQUESTED',
  REVOKED: 'REVOKED',
  DISPATCHED: 'SHIPPED',
  APPROVED: 'APPROVED',
  DELIVERED: 'DELIVERED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
};

export const allotmentStatusColorMapping: Record<string, string> = {
  PLACED: STATUS_COLORS.BRIGHT_BLUE,
  REQUESTED: STATUS_COLORS.BRIGHT_BLUE,
  REVOKED: STATUS_COLORS.RED,
  DISPATCHED: STATUS_COLORS.ORAGNE,
  APPROVED: STATUS_COLORS.VIOLET,
  DELIVERED: STATUS_COLORS.GREEN,
  REJECTED: STATUS_COLORS.RED,
  ACCEPTED: STATUS_COLORS.DARK_BLUE,
  NOT_DELIVERED: STATUS_COLORS.RED,
};

// const getColorForAStatus = (status: string) => {
//   let statusColor = PRIMARY.darkBlue;

//   switch (status) {
//     case 'REJECTED':
//       statusColor = RED.red;
//       break;
//     case 'ASSIGNED':
//       statusColor = 'rgba(26, 137, 201, 1)';
//       break;
//     case 'SHIPPED':
//       statusColor = 'rgba(232, 112, 1, 1)';
//       break;
//     case 'DELIVERED':
//       statusColor = 'rgba(49, 132, 57, 1)';
//       break;
//     default:
//       break;
//   }
//   return statusColor;
// };

interface IProps {
  status: CardAllotmentStatusEnum;
}

function CardAllotmentStatus({ status }: IProps) {
  const displayText = toLowerCase(allotmentStatusMapping[status] || status);

  return (
    <CIMS_Text text={displayText} color={allotmentStatusColorMapping[status]} />
  );
}

export default CardAllotmentStatus;
