import axios from 'axios';
import { CARD_PROCESSOR } from '../../../api/endpoints';
import {
  AddBulkCardUploadOrderRequest,
  AddCardAllotmentOrderRequest,
  AddCardIndentationOrderRequest,
  AddCardPrintOrderRequest,
  ApproveCardAllotmentOrderRequest,
  CardAllotmentOrderDto,
  CardIndentationOrderDto,
  CardPrintOrderDto,
  CardRejectOrderDto,
  ListBulkRejectRequest,
  ListCardAllotmentOrderRequest,
  ListCardIndentationOrderRequest,
  ListCardPrintOrderRequest,
  RejectCardAllotmentOrderRequest,
  UpdateBulkCardRejectOrderRequest,
  UpdateCardAllotmentOrderRequest,
  UpdateCardAllotmentOrderStatusRequest,
  UpdateCardIndentationOrderStatusRequest,
} from '../../../types/card';
import { PaginatedResponse } from '../../../utils/api';
import { getFormData } from '../../../utils/common.utils';

// const CARD_MANAGEMENT_BASE_URL = BASE_URL + CARD_PROCESSOR.CARD_MANAGEMENT_BASE;
const CARD_MANAGEMENT_BASE_URL = CARD_PROCESSOR.CARD_MANAGEMENT_BASE;

const ENDPOINTS = {
  //   GET: '/allotmentOrder/{allotmentOrderId}',
  ADD: '/allotmentOrder',
  LIST: '/allotmentOrder/list',
  LIST_ALLOTMENT_FOR_VENDOR: '/allotmentOrder/list/vendor',
  //   UPDATE: '/allotmentOrder/{allotmentOrderId}',
  UPDATE_STATUS: '/allotmentOrder/status',
  APPROVE: '/allotmentOrder/approve',
  REJECT: '/allotmentOrder/reject',

  GET_INDENTATION_ORDER: '/indentationOrder',
  ADD_INDENTATION_ORDER: '/indentationOrder',
  UPDATE_INDENTATION_ORDER_STATUS: '/indentationOrder/status',
  UPDATE_CARD_INDENTATION_ORDER_STATUS: '/indentationOrder/cardIndentation/status',
  LIST_INDENTATION_ORDER: '/indentationOrder/list',

  BULK_UPLOAD: '/bulkCardUpload',
  BULK_CARD_REJECT: '/bulk/card/reject',
  GET_BULK_CARD_REJECT: '/getOrder/reject',
  ADD_PRINT_ORDER: '/printOrder',
  LIST_PRINT_ORDER: '/printOrder/list',
  LIST_BULK_REJECT: '/getOrder/reject/list',
  GET_PRINT_ORDER: '/printOrder/{printOrderId}',
};

export class CardAllocationService {
  static async add(data: AddCardAllotmentOrderRequest) {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.ADD;

    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async addRequest(data: AddCardAllotmentOrderRequest) {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.ADD;

    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async list(
    data: Partial<ListCardAllotmentOrderRequest>
  ): Promise<PaginatedResponse<CardAllotmentOrderDto>> {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.LIST;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async listAllotmentOrderForVendors(
    data: Partial<ListCardPrintOrderRequest>
  ): Promise<PaginatedResponse<CardAllotmentOrderDto>> {
    // return { response: data };
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + ENDPOINTS.LIST_ALLOTMENT_FOR_VENDOR;
    const response = await axios.post(endpoint, data);
    return response.data;
  }

  /// Card Indentation

  static async addCardIndentationOrder(
    data: Partial<AddCardIndentationOrderRequest>
  ) {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.ADD_INDENTATION_ORDER;
    const response = await axios.post(endpoint, data);
    return response.data as CardIndentationOrderDto;
  }

  static async updateCardIndentationOrderStatus(
    data: UpdateCardIndentationOrderStatusRequest
  ) {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + ENDPOINTS.UPDATE_INDENTATION_ORDER_STATUS;
    const response = await axios.put(endpoint, data);
    return response.data as CardIndentationOrderDto;
  }


  static async updateCardIndentationOrderCardIndentationStatus(
    data: UpdateCardIndentationOrderStatusRequest
  ) {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + ENDPOINTS.UPDATE_CARD_INDENTATION_ORDER_STATUS;
    const response = await axios.put(endpoint, data);
    return response.data as CardIndentationOrderDto;
  }
  static async listCardIndentationOrder(
    data: Partial<ListCardIndentationOrderRequest>
  ) {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + ENDPOINTS.LIST_INDENTATION_ORDER;
    const response = await axios.post(endpoint, data);
    return response.data as PaginatedResponse<CardIndentationOrderDto>;
  }

  static async approveAllocationOrder(
    data: ApproveCardAllotmentOrderRequest
  ): Promise<CardAllotmentOrderDto> {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.APPROVE;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async rejectAllocationOrder(
    data: RejectCardAllotmentOrderRequest
  ): Promise<CardAllotmentOrderDto> {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.REJECT;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async updateAllocationOrder(
    data: UpdateCardAllotmentOrderRequest
  ): Promise<CardAllotmentOrderDto> {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.ADD;
    const response = await axios.put(endpoint, data);
    return response.data;
  }
  static async updateAllocationStatus(
    data: UpdateCardAllotmentOrderStatusRequest
  ): Promise<CardAllotmentOrderDto> {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.UPDATE_STATUS;
    const response = await axios.patch(endpoint, data);
    return response.data;
  }
  static async bulkUploadInventory(
    data: AddBulkCardUploadOrderRequest
  ): Promise<CardPrintOrderDto> {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.BULK_UPLOAD;
    const response = await axios.post(endpoint, getFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }
  static async bulkRejectCard(
    data: UpdateBulkCardRejectOrderRequest
  ): Promise<CardRejectOrderDto> {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.BULK_CARD_REJECT;
    const response = await axios.post(endpoint, getFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }
  static async getBulkRejectCard(
    cardRejectOrderId: string
  ): Promise<CardRejectOrderDto> {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL +
      ENDPOINTS.GET_BULK_CARD_REJECT +
      '/' +
      cardRejectOrderId;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getPrintOrder(printOrderId: string): Promise<CardPrintOrderDto> {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL +
      ENDPOINTS.GET_PRINT_ORDER.replace('{printOrderId}', printOrderId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async addPrintOrder(
    data: AddCardPrintOrderRequest
  ): Promise<CardPrintOrderDto> {
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.ADD_PRINT_ORDER;
    const response = await axios.post(endpoint, getFormData(data));
    return response.data;
  }
  static async listPrintOrder(
    data: Partial<ListCardPrintOrderRequest>
  ): Promise<PaginatedResponse<CardPrintOrderDto>> {
    // return { response: data };
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.LIST_PRINT_ORDER;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async listBulkReject(
    data: Partial<ListBulkRejectRequest>
  ): Promise<PaginatedResponse<CardRejectOrderDto>> {
    // return { response: data };
    const endpoint = CARD_MANAGEMENT_BASE_URL + ENDPOINTS.LIST_BULK_REJECT;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
}
